import React, { useState, useEffect } from 'react'
import {
  Paper,
  Typography,
  Button,
  Checkbox,
  InputBase,
  Box,
  Chip
} from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp, Close } from '@mui/icons-material'
import { locationData, locationStateData, locationCityData } from 'src/assets/data.js'
import { FilterDropdown } from '../filterComponents'
import { Location } from 'src/icons/location'
import { useTheme } from '@emotion/react'
import { OffCheckboxCustomIcon } from 'src/assets/general/OffCheckboxCustomIcon'
import { OnCheckboxCustomIcon } from 'src/assets/general/OnCheckboxCustomIcon'

const checkboxOfIcon = <OffCheckboxCustomIcon />
const checkboxOnIcon = <OnCheckboxCustomIcon />

const formatLocationData = (countryData, stateData, cityData) => {
  const countries = countryData.map((country, index) => ({
    id: `country-${index}`,
    value: country,
    label: country,
    type: 'country'
  }))

  const states = stateData.map((state, index) => {
    const [stateName, country] = state.split(', ')
    return {
      id: `state-${index}`,
      value: state,
      label: stateName,
      country,
      type: 'state'
    }
  })

  const cities = cityData.map((city, index) => ({
    id: `city-${index}`,
    value: city,
    label: city,
    type: 'city'
  }))

  return [...countries, ...states, ...cities]
}

const allLocationOptions = formatLocationData(
  locationData[0].children,
  locationStateData[0].children,
  locationCityData[0].children
)

const LocationFilter = ({ type, handleFilterChange, clearLocation, setClearLocation, value, isOpen, onToggle }) => {
  const theme = useTheme()
  const [isMainDropdownOpen, setIsMainDropdownOpen] = useState(false)
  const [isExcludeDropdownOpen, setIsExcludeDropdownOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [excludeSearchQuery, setExcludeSearchQuery] = useState('')
  const [selectedLocations, setSelectedLocations] = useState(() => {
    const { geo = [], cGeo = [], oGeo = [] } = value
    const all = formatLocationData(geo, oGeo, cGeo)
    return all
  })
  const [excludedLocations, setExcludedLocations] = useState(() => {
    const { excludeGeo = [], excludeCGeo = [], excludeOGeo = [] } = value
    const all = formatLocationData(excludeGeo, excludeOGeo, excludeCGeo)
    return all
  })
  const [km] = useState(0)
  // const [isInputBoxOpen, setIsInputBoxOpen] = useState(selectedLocations.length > 0)
  const [isInputExcludeBoxOpen, setIsInputExcludeBoxOpen] = useState(false)
  const [selectedCount, setSelectedCount] = useState(1)
  const [excludedCount, setExcludedCount] = useState(1)

  const filteredOptions = allLocationOptions.filter(
    (option) =>
      option.label.toLowerCase().includes(searchQuery.toLowerCase()) &&
      !excludedLocations.find((loc) => loc.id === option.id)
  )

  useEffect(() => {
    const { geo = [], cGeo = [], oGeo = [], excludeGeo = [], excludeCGeo = [], excludeOGeo = [] } = value
    const all = formatLocationData(geo, oGeo, cGeo)
    const allExclude = formatLocationData(excludeGeo, excludeOGeo, excludeCGeo)
    setSelectedLocations(all)
    setExcludedLocations(allExclude)
    // setIsInputBoxOpen(all.length > 0)
  }, [value])

  useEffect(() => {
    if (km > 0) {
      const updatedLocations = selectedLocations.map((location) => {
        if (location.type === 'city') {
          return {
            ...location,
            value: `${location.value}::~${km}km`
          }
        }
        return location
      })
      setSelectedLocations(updatedLocations)
    }
  }, [km])

  const filteredExcludeOptions = allLocationOptions.filter(
    (option) =>
      option.label.toLowerCase().includes(excludeSearchQuery.toLowerCase())
    //  &&
      // !selectedLocations.find((loc) => loc.id === option.id) &&
      // !excludedLocations.find((loc) => loc.id === option.id)
  )

  const formatValue = (loc) => {
    if (loc.type === 'city' && km > 0) {
      return `${loc.value}::~${km}km`
    }
    if (loc.type === 'state' && loc.country) {
      return `${loc.label}, ${loc.country}`
    }
    return loc.value
  }

  const handleLocationSelect = (location) => {
    let locations = []
    if (selectedLocations.find((loc) => loc.value === location.value)) {
      locations = selectedLocations.filter((loc) => loc.value !== location.value)
    } else {
      const newLocation = {
        ...location,
        value: formatValue(location)
      }
      locations = [...selectedLocations, newLocation]
    }
    setSearchQuery('')
    setSelectedLocations(locations)
  }
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!e.target.closest('.dropdown-container')) {
        setIsMainDropdownOpen(false)
        setIsExcludeDropdownOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside, { capture: true })

    return () => {
      document.removeEventListener('click', handleClickOutside, { capture: true })
    }
  }, [])

  useEffect(() => {
    handleFilterChange(
      'geo',
      selectedLocations.filter((loc) => loc.type === 'country').map((loc) => loc.value)
    )
    handleFilterChange(
      'oGeo',
      selectedLocations.filter((loc) => loc.type === 'state').map((loc) => loc.value)
    )
    handleFilterChange(
      'cGeo',
      selectedLocations.filter((loc) => loc.type === 'city').map(formatValue)
    )
  }, [selectedLocations])

  useEffect(() => {
    handleFilterChange(
      'excludeGeo',
      excludedLocations.filter((loc) => loc.type === 'country').map((loc) => loc.value)
    )
    handleFilterChange(
      'excludeOGeo',
      excludedLocations.filter((loc) => loc.type === 'state').map((loc) => loc.value)
    )
    handleFilterChange(
      'excludeCGeo',
      excludedLocations.filter((loc) => loc.type === 'city').map(formatValue)
    )
  }, [excludedLocations])

  useEffect(() => {
    if (clearLocation) {
      clearAllLocations()
      setClearLocation(false)
    }
  }, [clearLocation, setClearLocation])

  const handleExcludeSelect = (location) => {
    if (excludedLocations.find((loc) => loc.value === location.value)) {
      setExcludedLocations(excludedLocations.filter((loc) => loc.value !== location.value))
    } else {
      setExcludedLocations([...excludedLocations, location])
    }
    setExcludeSearchQuery('')
  }

  const clearSelectedLocations = () => {
    setSelectedLocations([])
    setSearchQuery('')
    setSelectedCount(1)
  }
  const clearExcludedLocations = () => {
    setExcludedLocations([])
    setExcludeSearchQuery('')
    setExcludedCount(1)
  }

  const clearAllLocations = () => {
    clearSelectedLocations()
    clearExcludedLocations()
  }

  const selectedLocationShort = filteredOptions.slice(0, 25 * selectedCount)
  const excludedLocationShort = filteredExcludeOptions.slice(0, 25 * excludedCount)

  const preview = (
    <>
      {selectedLocations.length > 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
          {selectedLocations.length > 0 &&
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
              <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
              <Typography variant='body2' sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600, paddingLeft: 1 }}>Contact region</Typography>
              {selectedLocations.map((loc) => (
                <Chip
                  key={loc.id}
                  label={loc.label}
                  onDelete={() => handleLocationSelect(loc)}
                  deleteIcon={<Close fontSize='small' />}
                  size='small'
                  sx={{
                    background: '#E6F0FF',
                    fontWeight: 500,
                    fontSize: '14px',
                    borderRadius: '43px',
                    border: '1px solid #3F4FF836',
                    color: 'black',
                    '& .MuiChip-deleteIcon': {
                      color: 'black'
                    }
                  }}
                />
              ))}
            </Box>}
        </Box>
      )}
      {excludedLocations.length > 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
            <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
            <Typography variant='body2' sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600, paddingLeft: 1 }}>Contact region excluded</Typography>
            {excludedLocations.map((loc) => (
              <Chip
                key={loc.id}
                label={loc.label}
                onDelete={() => handleExcludeSelect(loc)}
                deleteIcon={<Close fontSize='small' />}
                size='small'
                sx={{
                  background: '#ffcdd2',
                  fontWeight: 500,
                  fontSize: '14px',
                  borderRadius: '43px',
                  border: '1px solid #ff000036',
                  color: 'black',
                  textDecoration: 'line-through',
                  '& .MuiChip-deleteIcon': {
                    color: 'black'
                  }
                }}
              />
            ))}
          </Box>
        </Box>
      )}
    </>
  )

  return (
    <FilterDropdown
      title={type === 'person' ? 'People Location' : 'Company Location'}
      filterCount={(selectedLocations.length + excludedLocations.length)}
      clearAll={clearAllLocations}
      icon={<Location sx={{ stroke: `${theme.palette.primary.main}` }} />}
      preview={preview}
      isOpen={isOpen}
      onToggle={onToggle}
    >
      {/* <Box sx={{ width: '100%', border: ' 1px solid #D7D9E6', p: 2, borderRadius: 2, mx: 'auto', my: 2 }}> */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 1
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {/* <Radio
              checked={isInputBoxOpen}
              size='small'
              sx={{ p: 0 }}
              onClick={() => setIsInputBoxOpen(!isInputBoxOpen)}
            /> */}
          <Typography sx={{ fontWeight: 700, fontSize: '14px' }}>Select region</Typography>
        </Box>
        {selectedLocations.length > 0 && (
          <Button
            onClick={clearSelectedLocations}
            sx={{
              textTransform: 'none',
              fontSize: '12px',
              p: 0,
              minWidth: 0
            }}
          >
            Clear ({selectedLocations.length})
          </Button>
        )}
      </Box>

      {/* Location Search & Select */}
      {/* {isInputBoxOpen && ( */}
      <Box sx={{ mb: 2, position: 'relative' }} className='dropdown-container'>
        <Paper
          variant='outlined'
          onClick={() => setIsMainDropdownOpen(!isMainDropdownOpen)}
          sx={{
            border: '1px solid #E4E4E5',
            borderRadius: '8px',
            p: 1,
            cursor: 'pointer',
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1,
            minHeight: '40px'
          }}
        >
          {selectedLocations.map((loc) => (
            <Chip
              key={loc.id}
              label={loc.label}
              onDelete={() => handleLocationSelect(loc)}
              deleteIcon={<Close fontSize='small' />}
              size='small'
              sx={{
                background: '#E6F0FF',
                fontWeight: 500,
                fontSize: '14px',
                borderRadius: '43px',
                border: '1px solid #3F4FF836',
                color: 'black',
                '& .MuiChip-deleteIcon': {
                  color: 'black'
                }
              }}
            />
          ))}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flex: '1 1 100%'
            }}
          >
            <InputBase
              placeholder={
                    selectedLocations.length === 0 ? 'State, Country, City, ZIP...' : ''
                  }
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onClick={(e) => {
                e.stopPropagation()
                setIsMainDropdownOpen(true)
              }}
              sx={{
                flex: 1,
                minWidth: '100px',
                fontSize: '12px'
              }}
            />
            <KeyboardArrowDown
              sx={{
                color: 'action.active',
                rotate: isMainDropdownOpen ? '180deg' : '0deg',
                transition: '0.2s linear rotate',
                cursor: 'pointer'
              }}
            />
          </Box>
        </Paper>

        {isMainDropdownOpen && (
          <Paper
            sx={{
              position: 'absolute',
              width: '100%',
              mt: 0.5,
              boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
              zIndex: 10
            }}
          >
            <Box>
              <Box sx={{ maxHeight: '192px', overflow: 'auto' }}>
                {selectedLocationShort.map((option) => (
                  <Box
                    key={option.id}
                    onClick={() => handleLocationSelect(option)}
                    sx={{
                      p: 1,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      cursor: 'pointer',
                      '&:hover': { backgroundColor: '#f5f5f5' }
                    }}
                  >
                    <Checkbox
                      icon={checkboxOfIcon}
                      checkedIcon={checkboxOnIcon}
                      checked={selectedLocations.some((loc) => loc.value === option.value)}
                      size='small'
                    />
                    <Typography sx={{ fontSize: '13px', fontWeight: '500px', lineHeight: '16.38px', color: theme.palette.primary.primaryText }}>{option.label}</Typography>

                  </Box>
                ))}
                {selectedLocationShort.length === 0 && (
                  <Typography variant='body2' color='text.secondary' sx={{ p: 1 }}>
                    No results found
                  </Typography>
                )}
              </Box>

              {selectedLocationShort.length < filteredOptions.length && (
                <Button
                  onClick={() => {
                    setSelectedCount(selectedCount + 1)
                  }}
                >
                  Show more ...
                </Button>
              )}
            </Box>
          </Paper>
        )}
      </Box>
      {/* // )} */}

      {/* Exclude Locations Section */}
      <Box sx={{ mt: 2 }}>
        <Button
          endIcon={isInputExcludeBoxOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          sx={{
            textTransform: 'none',
            fontWeight: 700,
            fontSize: '13px',
            p: 0,
            minWidth: 0,
            mb: 1
          }}
          onClick={() => {
            setIsInputExcludeBoxOpen(!isInputExcludeBoxOpen)
          }}
        >
          Exclude locations
        </Button>
      </Box>
      {isInputExcludeBoxOpen && (
        <Box className='dropdown-container'>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 1
            }}
          >
            <Box sx={{ gap: 1 }}>
              <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>
                Locations to exclude
              </Typography>
            </Box>
            {excludedLocations.length > 0 && (
              <Button
                onClick={clearExcludedLocations}
                sx={{
                  textTransform: 'none',
                  fontSize: '12px',
                  p: 0,
                  minWidth: 0
                }}
              >
                Clear ({excludedLocations.length})
              </Button>
            )}
          </Box>

          <Box sx={{ mb: 2, position: 'relative' }}>
            <Paper
              variant='outlined'
              onClick={() => setIsExcludeDropdownOpen(!isExcludeDropdownOpen)}
              sx={{
                border: ' 1px solid #E4E4E5',
                borderRadius: '8px',
                p: 1,
                cursor: 'pointer',
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1,
                minHeight: '40px'
              }}
            >
              {excludedLocations.map((loc) => (
                <Chip
                  key={loc.id}
                  label={loc.label}
                  onDelete={() => handleExcludeSelect(loc)}
                  deleteIcon={<Close fontSize='small' />}
                  size='small'
                  sx={{
                    background: '#ffcdd2',
                    fontWeight: 500,
                    fontSize: '14px',
                    borderRadius: '43px',
                    border: '1px solid #ff000036',
                    color: 'black',
                    textDecoration: 'line-through',
                    '& .MuiChip-deleteIcon': {
                      color: 'black'
                    }
                  }}
                />
              ))}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flex: '1 1 100%'
                }}
              >
                <InputBase
                  placeholder={
                      excludedLocations.length === 0 ? 'State, Country, City, ZIP...' : ''
                    }
                  value={excludeSearchQuery}
                  onChange={(e) => setExcludeSearchQuery(e.target.value)}
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsExcludeDropdownOpen(true)
                  }}
                  sx={{
                    flex: 1,
                    minWidth: '100px',
                    fontSize: '12px'
                  }}
                />
                <KeyboardArrowDown
                  sx={{
                    color: 'action.active',
                    rotate: isExcludeDropdownOpen ? '180deg' : '0deg',
                    transition: '0.2s linear rotate',
                    cursor: 'pointer'
                  }}
                />
              </Box>
            </Paper>

            {isExcludeDropdownOpen && (
              <Paper
                sx={{
                  position: 'absolute',
                  width: '100%',
                  mt: 0.5,
                  boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                  zIndex: 10
                }}
              >
                <Box sx={{ maxHeight: '192px', overflow: 'auto' }}>
                  {excludedLocationShort.map((option) => (
                    <Box
                      key={option.id}
                      onClick={() => handleExcludeSelect(option)}
                      sx={{
                        p: 1,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        cursor: 'pointer',
                        '&:hover': { backgroundColor: '#f5f5f5' }
                      }}
                    >
                      <Checkbox
                        icon={checkboxOfIcon}
                        checkedIcon={checkboxOnIcon}
                        checked={excludedLocations.some((loc) => loc.value === option.value)}
                        size='small'
                      />
                      <Typography sx={{ fontSize: '13px', fontWeight: '500px', lineHeight: '16.38px', color: theme.palette.primary.primaryText }}>{option.label}</Typography>
                    </Box>
                  ))}
                  {excludedLocationShort.length === 0 && (
                    <Typography variant='body2' color='text.secondary' sx={{ p: 1 }}>
                      No results found
                    </Typography>
                  )}
                </Box>

                {excludedLocationShort.length < filteredExcludeOptions.length && (
                  <Button
                    onClick={() => {
                      setExcludedCount(excludedCount + 1)
                    }}
                  >
                    Show more ...
                  </Button>
                )}
              </Paper>
            )}
          </Box>
        </Box>
      )}
      {/* </Box> */}
    </FilterDropdown>
  )
}

export default LocationFilter
