import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import LoaderCircle from 'src/pages/LoaderCircle'

const SuccessAIRoute = ({ element: Component, ...rest }) => {
  const user = useSelector((state) => state.user)
  if (user.length === 0 || user === undefined) {
    return <LoaderCircle />
  }

  if (user?.isPartner || user?.partnerAccountId) {
    return <Navigate to='/accounts' />
  }

  return <Component {...rest} />
}

export default SuccessAIRoute
