import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Typography,
  Divider,
  LinearProgress,
  Grid,
  Chip,
  Card,
  TextField,
  Modal,
  CardContent,
  CircularProgress
} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import InsightsIcon from '@mui/icons-material/Insights'
import EditIcon from '@mui/icons-material/Edit'
import VerifiedIcon from '@mui/icons-material/Verified'
import MailIcon from '@mui/icons-material/Mail'
import EmailIcon from '@mui/icons-material/Email'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
// import { addOns } from './UsageData'

import UpgradePlanPopup from './UpgradePlan'
import CancellationPlans from './CancellationPlans'
import FeedbackPopup from './FeedbackPopup'
import { useTheme } from '@emotion/react'
import { useBillingUsageQuery, useBuyAddOnsMutation, useGetPaymentMethodQuery, useInvoicesQuery, useUpdatePaymentMutation, useBulkWorkspacesMutation } from 'src/services/billing-service'
import moment from 'moment-timezone'
import { Link, useNavigate } from 'react-router-dom'
import { config } from 'src/config'
import { useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import ContactSalesDialog from './ContactSalesDialog'
import { addOns } from './UsageData'

const UsagePage = ({ setValue }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [selectedAddOn, setSelectedAddOn] = useState(null)
  const [subscriptionType, setSubscriptionType] = useState('monthly')
  const [accountCount, setAccountCount] = useState(selectedAddOn?.id === 'workspace' ? 1 : 1000)
  const [buyAddOns] = useBuyAddOnsMutation()
  const [bulkWorkspaces] = useBulkWorkspacesMutation()
  const workspace = useSelector((state) => state.workspace)
  const [workspaceId, setWorkspaceId] = useState(null)
  const [selectedPlan, setSelectedPlan] = useState(0)

  const openDialog = (id) => {
    const selected = addOnsData.find((addOn) => addOn.id === id)
    setSelectedAddOn(selected)

    // Corrected conditional check
    const defaultCount = selected?.id === 'workspace' ? 2 : selected?.id === 'additionalLinkedin' ? 1 : 1000
    setAccountCount(defaultCount)

    setOpen(true)
  }

  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedAddon, setSelectedAddon] = useState(null)

  const openContactDialog = (id) => {
    const addon = addOns.find((addon) => addon.id === id)
    if (addon) {
      setSelectedAddon(addon)
      setDialogOpen(true)
    }
  }

  const handleClose = () => {
    setAccountCount(1000)
    setOpen(false)
    setSelectedAddOn(null)
    setSelectedPlan(0)
  }

  const {
    data: invoices = [],
    isLoading,
    refetch: refetchInvoice
  } = useInvoicesQuery()
  const { data: usage, refetch: refetchUsage } = useBillingUsageQuery()
  const { data, isLoading: isCardLoading } = useGetPaymentMethodQuery()
  const [UpdatePayment] = useUpdatePaymentMutation()
  const latestPaymentMethod = Array.isArray(data) && data.length > 0
    ? [...data].sort((a, b) => b.id.localeCompare(a.id))[0]
    : null

  const isCardExpired = (expMonth, expYear) => {
    const cardExpiry = moment(`${expYear}-${expMonth}`, 'YYYY-M').endOf('month')
    return moment().isAfter(cardExpiry)
  }
  const [selectedTier, setSelectedTier] = useState(null)

  const tiers = [
    { credits: 1000, price: 8 },
    { credits: 5000, price: 35 },
    { credits: 10000, price: 60 },
    { credits: 50000, price: 250 },
    { credits: 100000, price: 400 },
    { credits: 250000, price: 750 },
    { credits: 500000, price: 1250 },
    { credits: 1000000, price: 2000 }
  ]

  const priceTiers = [
    { min: 1, max: 4999, price: 0.004 },
    { min: 5000, max: 9999, price: 0.0035 },
    { min: 10000, max: 49999, price: 0.003 },
    { min: 50000, max: 99999, price: 0.0025 },
    { min: 100000, max: 249999, price: 0.002 },
    { min: 250000, max: 499999, price: 0.0015 },
    { min: 500000, max: 999999, price: 0.00125 },
    { min: 1000000, max: Infinity, price: 0.001 }
  ]

  const handleQuantityChange = (value) => {
    const numValue = Number(value)
    if (numValue > 1000000) {
      toast.error('Please contact support for quantities over 1,000,000 credits')
      return
    }
    if (numValue < 1) {
      toast.error('Minimum purchase quantity should be greater then 0')
      return
    }
    setAccountCount(numValue)
    setSelectedTier(null) // Clear tier selection when manually entering value
  }

  const handleTierSelect = (tier) => {
    setSelectedTier(tier)
    setAccountCount(tier.credits)
  }

  const handleStepIncrement = (e, addon) => {
    const step = ['workspace', 'additionalLinkedin'].includes(addon?.id) ? 1 : 1000
    const minValue = addon?.id === 'workspace' ? 2 : addon?.id === 'additionalLinkedin' ? 1 : 1000
    const currentValue = parseInt(accountCount, 10) || 0

    if (e.key === 'ArrowUp') {
      setAccountCount(currentValue + step)
      e.preventDefault()
    } else if (e.key === 'ArrowDown') {
      setAccountCount(Math.max(minValue, currentValue - step))
      e.preventDefault()
    }
  }

  const CreditData = [
    {
      id: 'activeContacts',
      title: 'Active Contacts',
      usage: usage?.usedActiveLeads || 0,
      total: usage?.activeLeads || 0,
      progress: ((usage?.usedActiveLeads || 0) / (usage?.activeLeads || 1)) * 100,
      icon: PersonIcon,
      disabled: false,
      showTotal: true
    },
    {
      title: 'Lead Finder Usage Overview',
      usage: usage?.leadsCredits || 0,
      total: usage?.leadsCredits || 0,
      progress: ((usage?.leadsCredits || 0) / (usage?.leadsCredits || 1)) * 100,
      icon: InsightsIcon,
      disabled: true,
      showTotal: false
    },
    {
      id: 'aiWriter',
      title: 'AI Writer Credits',
      usage: Math.max(0, usage?.usedAiCredit || 0),
      total: Math.max(1, usage?.aiWriterLimit || 0),
      progress: Math.min(100, ((usage?.usedAiCredit || 0) / (usage?.aiWriterLimit || 1)) * 100),
      icon: EditIcon,
      disabled: false,
      showTotal: true
    },
    {
      id: 'emailVerification',
      title: 'Email Verification Credits',
      usage: usage?.bounceCredit || 0,
      total: usage?.bounceCredit || 0,
      progress: ((usage?.bounceCredit || 0) / (usage?.bounceCredit || 1)) * 100,
      icon: VerifiedIcon,
      disabled: false,
      showTotal: false
    },
    {
      title: 'Email Export Credits (per day)',
      usage: usage?.dailyLeadLimit || 0,
      total: 100000 || 0,
      progress: ((usage?.dailyLeadLimit || 0) / 100000) * 100,
      icon: MailIcon,
      disabled: true,
      showTotal: true
    },
    {
      id: 'emailExport',
      title: 'Emails per Month',
      usage: usage?.usedEmailCredit || 0,
      total: usage?.montlyEmailCredit || 0,
      progress: ((usage?.usedEmailCredit || 0) / (usage?.montlyEmailCredit || 1)) * 100,
      icon: EmailIcon,
      disabled: false,
      showTotal: true
    }
  ]

  const addOnsData = [
    {
      id: 'emailVerification',
      title: 'Email Verification Credits',
      credits: 'Verification Credits',
      costPerCredit: 0.01,
      priceIdMonthly: config.EMAIL_VERIFICATION_CREDIT_PRICE_ID,
      priceIdAnnual: config.YEARLY_EMAIL_VERIFICATION_CREDIT_PRICE_ID
    },
    {
      id: 'activeContacts',
      title: 'Active Contacts',
      credits: 'Active Contacts',
      costPerCredit: 0.01,
      priceIdMonthly: config.ACTIVE_CONTACTS_PRICE_ID,
      priceIdAnnual: config.YEARLY_ACTIVE_CONTACTS_PRICE_ID
    },
    {
      id: 'emailExport',
      title: 'Email Accounts / Mailboxes',
      credits: 'Email Accounts',
      costPerCredit: 1,
      priceIdMonthly: config.EMAIL_ACCOUNT_PRICE_ID,
      priceIdAnnual: config.YEARLY_EMAIL_ACCOUNT_PRICE_ID
    },
    {
      id: 'linkedIn',
      title: 'LinkedIn Account',
      credits: 'LinkedIn Account',
      costPerCredit: 20,
      priceIdMonthly: config.LINKEDIN_ACCOUNT_PRICE_ID,
      priceIdAnnual: config.YEARLY_LINKEDIN_ACCOUNT_PRICE_ID
    },
    {
      id: 'aiWriter',
      title: 'AI Writer Credits',
      credits: 'AI Writer Credits',
      costPerCredit: 0.01,
      priceIdMonthly: config.AI_WRITER_PRICE_ID,
      priceIdAnnual: config.YEARLY_AI_WRITER_PRICE_ID
    },
    {
      id: 'workspace',
      title: 'Workspace',
      credits: 'Workspace',
      costPerCredit: 'Depends on the plan',
      planName: ['-Select-', 'Starter', 'Growth', 'Infinity'],
      monthlypirce: [59, 99, 149],
      yearlyPrice: [35, 59, 89],
      priceIdMonthly: [null, config.SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID, config.SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID, config.SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID],
      priceIdAnnual: [null, config.SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID, config.SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID, config.SENDING_WARMUP_YEARLY_SCALE_PRICE_ID]
    },
    {
      id: 'additionalLinkedin',
      title: 'LinkedIn Credits',
      credits: 'LinkedIn Credits',
      costPerCredit: 20,
      priceIdMonthly: config.AI_WRITER_PRICE_ID,
      priceIdAnnual: config.YEARLY_AI_WRITER_PRICE_ID
    }
  ]

  const calculateTotalPrice = (credits, addOnsData, subscriptionType, selectedPlan) => {
    if (addOnsData?.id === 'emailVerification' || addOnsData?.title === 'Email Verification Credits') {
      const tier = priceTiers.find(t => credits >= t.min && credits <= t.max)
      return tier ? tier.price * credits : priceTiers[0].price * credits
    }

    if (addOnsData?.id === 'workspace') {
      if (selectedPlan === 0) {
        return 0
      }

      const prices = subscriptionType === 'monthly'
        ? addOnsData.monthlypirce
        : addOnsData.yearlyPrice

      const planPrice = prices[selectedPlan - 1]

      return planPrice * credits
    }

    return addOnsData?.costPerCredit * credits
  }
  const getWorkspacePriceId = (selectedPlan, subscriptionType, addOn) => {
    if (!addOn || addOn.id !== 'workspace') return null

    const priceIds = subscriptionType === 'monthly' ? addOn.priceIdMonthly : addOn.priceIdAnnual
    return priceIds[selectedPlan]
  }

  const [cancellationPlansOpen, setCancellationPlansOpen] = useState(false)
  const [feedbackPopupOpen, setFeedbackPopupOpen] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [displayCount, setDisplayCount] = useState(4)
  const [isDownloading, setIsDownloading] = useState(false)
  const visibleInvoices = invoices?.slice(0, displayCount) || []
  const remainingCount = (invoices?.length || 0) - displayCount
  const hasMoreInvoices = remainingCount > 0

  const handleShowMore = () => {
    setDisplayCount(prev => prev + 4)
  }
  const handleOpenDialog = () => {
    setIsDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
  }

  const handleOpenFeedbackPopup = () => {
    setFeedbackPopupOpen(true)
    handleClosecancellationPlans()
  }

  const handleCloseFeedbackPopup = () => {
    setFeedbackPopupOpen(false)
  }

  const handleOpencancellationPlans = () => {
    setCancellationPlansOpen(true)
    handleCloseDialog()
  }

  const handleClosecancellationPlans = () => {
    setCancellationPlansOpen(false)
  }

  const handleDownloadAll = async () => {
    try {
      setIsDownloading(true)

      for (const invoice of invoices) {
        try {
          const response = await fetch(invoice.pdfUrl)
          const blob = await response.blob()

          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `invoice-${moment(invoice.createdAt).format('YYYY-MM-DD')}.pdf`

          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          window.URL.revokeObjectURL(link.href)
        } catch (err) {
          console.error(`Error downloading invoice: ${invoice.pdfUrl}`, err)
        }
      }
    } catch (error) {
      console.error('Error downloading invoices:', error)
    } finally {
      setIsDownloading(false)
    }
  }

  const handelBillingCheckout = async () => {
    const { data: url } = await UpdatePayment()
    window.location.href = url
  }

  const handleBuyAddonClick = async (selectedAddOn) => {
    if (accountCount <= 0) {
      toast.error('Please select a quantity greater than zero.')
      return
    }

    // For workspace, get price based on selected plan index
    const priceId = selectedAddOn.id === 'workspace'
      ? getWorkspacePriceId(selectedPlan, subscriptionType, selectedAddOn)
      : subscriptionType === 'monthly' ? selectedAddOn.priceIdMonthly : selectedAddOn.priceIdAnnual

    if (!priceId) {
      toast.error('Please select a valid plan.')
      return
    }
    if (selectedAddOn.id === 'workspace') {
      try {
        const response = await bulkWorkspaces({ priceId, quantity: accountCount }).unwrap()

        if (response?.errorMsg) {
          toast.error(response.errorMsg, { duration: 5000 })
        } else if (response?.url) {
          window.location.href = response.url
        } else {
          toast.error('Unexpected response from the server. Please try again.', { duration: 5000 })
        }
      } catch (error) {
        toast.error(error, { duration: 5000 })
      }
    } else {
      try {
        const session = await buyAddOns({
          priceId,
          workspaceId,
          quantity: accountCount
        }).unwrap()

        if (session?.url) {
          window.location.href = session.url
        } else if (session?.errorMsg) {
          toast.error(session.errorMsg, { duration: 5000 })
        } else {
          toast.success('Purchase successful', { duration: 2000 })
        }
      } catch (error) {
        console.error('Error processing payment:', error)
        toast.error('Failed to process payment.')
      }
    }
  }

  useEffect(() => {
    refetchUsage()
    refetchInvoice()
  }, [refetchUsage, refetchInvoice])

  useEffect(() => {
    setWorkspaceId(workspace?._id)
  }, [workspace])

  return (
    <>
      <Box sx={{ padding: '16px', backgroundColor: '#f9f9f9' }}>
        {/* Payment Method Section */}
        <Grid container spacing={2}>
          {/* Payment Method Card */}
          <Grid item xs={12} sm={6} md={6}>
            <Card sx={{ padding: '16px', px: 4 }}>
              <Typography variant='h6' sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
                Payment Method
              </Typography>
              <Typography sx={{ color: '#6b6b6b', marginBottom: '16px' }}>
                This information is general and applies across the entire platform.
              </Typography>

              {isCardLoading
                ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                    <CircularProgress />
                  </Box>
                  )
                : !latestPaymentMethod
                    ? (
                      <Box sx={{ padding: '16px', textAlign: 'center' }}>
                        <Typography>No payment method found</Typography>
                        <Button
                          variant='contained'
                          sx={{
                            mt: 2,
                            backgroundColor: `${theme.palette.primary.main}`,
                            color: '#fff',
                            '&:hover': {
                              backgroundColor: `${theme.palette.primary.main}`
                            }
                          }}
                          onClick={handelBillingCheckout}
                        >
                          Add Payment Method
                        </Button>
                      </Box>
                      )
                    : (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          backgroundColor: '#fff',
                          padding: '16px',
                          borderRadius: '8px',
                          border: '1px solid #ddd'
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src='https://upload.wikimedia.org/wikipedia/commons/a/a4/Mastercard_2019_logo.svg'
                            alt='Mastercard'
                            style={{ width: '40px', marginRight: '16px' }}
                          />
                          <Box>
                            <Box sx={{ display: 'flex' }}>
                              <Typography sx={{ fontWeight: 'bold' }}>
                                {latestPaymentMethod?.brand} ending in {latestPaymentMethod?.last4}
                              </Typography>
                              {isCardExpired(latestPaymentMethod?.exp_month, latestPaymentMethod?.exp_year) && (
                                <Chip
                                  label='Expired'
                                  sx={{
                                    backgroundColor: '#ffdede',
                                    color: '#d8000c',
                                    fontWeight: 'bold',
                                    ml: 2
                                  }}
                                />
                              )}
                            </Box>
                            <Typography sx={{ fontSize: '14px', color: '#6b6b6b' }}>
                              {latestPaymentMethod?.name} · Exp. date {latestPaymentMethod?.exp_month}/{latestPaymentMethod?.exp_year}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                          <Button
                            variant='text'
                            sx={{
                              color: `${theme.palette.primary.main}`,
                              fontWeight: 600,
                              fontSize: '14px'
                            }}
                            onClick={handelBillingCheckout}
                          >
                            Update
                          </Button>
                        </Box>
                      </Box>
                      )}
            </Card>

            <Card sx={{ padding: '16px', mt: 2, px: 4 }}>
              <Typography sx={{ fontWeight: 700, fontSize: '16px', marginBottom: '2px' }}>
                Manage Success.ai subscription
              </Typography>
              <Typography
                sx={{ color: '#8181B0', marginBottom: '16px', fontWeight: 400, fontSize: '13px' }}
              >
                Update your Success.ai plan settings or explore other options below.
              </Typography>
              <Box sx={{ display: 'flex', gap: '16px', justifyContent: 'space-between' }}>
                <Button
                  variant='outlined'
                  sx={{
                    borderColor: '#E4E4E5',
                    color: '#FF4545',
                    fontWeight: 'bold'
                  }}
                  onClick={handleOpenDialog}
                >
                  Cancel my plan
                </Button>
                <Button
                  variant='contained'
                  sx={{
                    backgroundColor: `${theme.palette.primary.main}`,
                    color: '#fff',
                    fontWeight: 'bold',
                    '&:hover': { backgroundColor: `${theme.palette.primary.main}` }
                  }}
                  onClick={() => setValue(0)}
                >
                  Upgrade my plan
                </Button>
              </Box>
            </Card>
          </Grid>
          <UpgradePlanPopup
            isOpen={isDialogOpen}
            onClose={handleCloseDialog}
            handleOpencancellationPlans={handleOpencancellationPlans}
          />

          <CancellationPlans
            cancellationPlansOpen={cancellationPlansOpen}
            handleClosecancellationPlans={handleClosecancellationPlans}
            handleOpenFeedbackPopup={handleOpenFeedbackPopup}
          />

          <FeedbackPopup isOpen={feedbackPopupOpen} onClose={handleCloseFeedbackPopup} />

          {/* Invoices (35) Card */}
          <Grid item xs={12} sm={6} md={6}>
            <Card sx={{ marginBottom: '24px', padding: '16px', height: '100%', px: 4 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                  Invoices ({invoices?.length || 0})
                </Typography>
                <Button
                  variant='outlined'
                  onClick={handleDownloadAll}
                  disabled={isDownloading || !invoices?.length}
                  startIcon={isDownloading ? <CircularProgress size={20} /> : <FileDownloadIcon />}
                  sx={{
                    fontWeight: 'bold',
                    color: 'black',
                    border: '1px solid #E4E4E5',
                    '&:disabled': {
                      color: 'grey.400',
                      borderColor: 'grey.300'
                    }
                  }}
                >
                  {isDownloading ? 'Downloading...' : `Download all (${invoices?.length || 0})`}
                </Button>
              </Box>
              <Box sx={{ marginTop: '16px' }}>
                {isLoading
                  ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '200px' // Prevents layout shift
                      }}
                    >
                      <CircularProgress sx={{ color: '#3F4FF8' }} /> {/* Matches your theme color */}
                    </Box>
                    )
                  : (
                    <>
                      {visibleInvoices.map((invoice, index) => (
                        <Box key={index}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              padding: '8px 0'
                            }}
                          >
                            <Box sx={{ display: 'flex' }}>
                              <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>
                                <span style={{ marginRight: '8px', color: '#8181B0' }}>📄</span>{' '}
                                {moment(invoice.createdAt).format('MM/DD/YYYY')}
                              </Typography>
                              <Chip
                                label={invoice?.status === 'paid' ? 'Paid' : 'Not paid'}
                                sx={{
                                  backgroundColor: invoice?.status === 'paid' ? '#e0f6e0' : '#FFDFDF', // Green for "Paid", Red for "Not paid"
                                  color: invoice?.status === 'paid' ? '#008a00' : '#d8000c', // Text color matching the background
                                  fontWeight: 'bold',
                                  ml: 2
                                }}
                              />
                            </Box>
                            <Box>
                              <Button
                                variant='text'
                                sx={{ color: '#3F4FF8', fontWeight: 600, fontSize: '14px' }}
                              >
                                <Link to={invoice.pdfUrl} target='_blank'>
                                  Download
                                </Link>
                              </Button>
                            </Box>
                          </Box>
                          {/* Add Divider only if it's not the last item */}
                          {index < visibleInvoices.length - 1 && (
                            <Divider
                              sx={{
                                margin: '8px 0',
                                borderColor: '#e0e0e0',
                                borderWidth: '1px',
                                width: '100%',
                                position: 'relative',
                                '::before, ::after': {
                                  borderColor: '#e0e0e0'
                                },
                                color: '#555',
                                fontWeight: 500
                              }}
                            />
                          )}
                        </Box>
                      ))}
                      {hasMoreInvoices && (
                        <Typography
                          onClick={handleShowMore}
                          sx={{
                            color: '#3F4FF8',
                            fontWeight: 600,
                            cursor: 'pointer',
                            textAlign: 'center',
                            fontSize: '14px'
                          }}
                        >
                          Show more ({remainingCount})
                        </Typography>
                      )}
                    </>
                    )}
              </Box>
            </Card>
          </Grid>
        </Grid>

        {/* Credits & Usage Section */}

        <Box
          sx={{
            marginTop: '24px',
            border: '1px solid #D7D9E6',
            bgcolor: '#FFFFFF',
            borderRadius: '12px'
          }}
        >
          <Box sx={{ px: 4, py: 4 }}>
            <Typography
              variant='h5'
              sx={{ marginBottom: '2px', fontWeight: 700, fontSize: '16px' }}
            >
              Credits & Usage
            </Typography>
            <Typography
              variant='body1'
              sx={{ marginBottom: '20px', color: '#8181B0', fontWeight: 400, fontSize: '13px' }}
            >
              Enhance your plan with powerful Add-ons to maximize efficiency and streamline your
              workflows.
            </Typography>
            {CreditData.map((item, index) => (
              <Box
                key={index}
                sx={{
                  border: '1px solid #D7D9E6',
                  borderRadius: '8px',
                  padding: '16px',
                  marginBottom: '20px',
                  backgroundColor: '#fff'
                }}
              >
                <Box
                  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    {/* Render the icon dynamically */}
                    {item.icon && <item.icon sx={{ fontSize: '20px', color: '#8181B0' }} />}
                    <Typography
                      variant='h6'
                      sx={{ fontWeight: 700, fontSize: '14px', color: '#101010' }}
                    >
                      {item.title}
                    </Typography>
                  </Box>
                  <Typography
                    variant='body2'
                    sx={{ fontWeight: 600, fontSize: '13px', color: '#8181B0', mr: 18 }}
                  >
                    {item.usage.toLocaleString()}
                    {item.showTotal && ` / ${item.total.toLocaleString()}`} {/* Conditionally render total */}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '16px'
                  }}
                >
                  <LinearProgress
                    variant='determinate'
                    value={item.progress}
                    sx={{
                      flexGrow: 1,
                      height: '8px',
                      borderRadius: '4px',
                      backgroundColor: '#eee',
                      '& .MuiLinearProgress-bar': {
                        backgroundColor:
                          item.progress >= 50 ? `${theme.palette.primary.main}` : '#f44336'
                      }
                    }}
                  />
                  <Button
                    variant='contained'
                    sx={{
                      backgroundColor: `${theme.palette.primary.main}`,
                      color: `${theme.palette.primary.contrastText}`,
                      fontSize: '14px',
                      fontWeight: 600,
                      textTransform: 'none',
                      minWidth: '120px',
                      '&:hover': { backgroundColor: '#1560bd' }
                    }}
                    onClick={() => openDialog(item.id)}
                    disabled={item.disabled}
                  >
                    Add credits
                  </Button>
                </Box>

                <Typography variant='body2' sx={{ marginBottom: '10px', color: '#777' }}>
                  Quickly connect and manage extra accounts with ease.
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>

        {/* Add-ons Section */}

        <Box
          sx={{
            marginTop: '24px',
            border: '1px solid #D7D9E6',
            bgcolor: '#FFFFFF',
            borderRadius: '12px'
          }}
        >
          <Box sx={{ p: 4 }}>
            <Typography variant='h5' sx={{ fontWeight: 700, fontSize: '16px' }}>
              Add-ons
            </Typography>
            <Typography
              variant='body2'
              sx={{ mb: 4, color: '#8181B0', fontWeight: 400, fontSize: '13px' }}
            >
              Blocklist helps you avoid sending emails to invalid or spammy addresses, keeping your
              reputation clean.
            </Typography>
            <Grid container spacing={2}>
              {addOns.map((addOn, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    sx={{
                      // p: 1,
                      border: '1px solid #e0e0e0',
                      boxShadow: 'none',
                      borderRadius: 2,
                      backgroundColor: '#f9f9f9'
                    }}
                  >
                    <CardContent>
                      <Box
                        sx={{
                          height: '100%',
                          borderRadius: 2,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          mb: 2,
                          overflow: 'hidden' // Ensures the image doesn't spill out of the box
                        }}
                      >
                        <Box
                          component='img'
                          src={addOn.image}
                          alt={addOn.title}
                          sx={{
                            // bgcolor: `${theme.palette.primary.main}`,
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover' // Ensures the image covers the box
                          }}
                        />
                      </Box>

                      <Typography
                        variant='subtitle1'
                        sx={{ mb: 1, fontWeight: 600, fontSize: '14px' }}
                      >
                        {addOn.title}
                      </Typography>
                      <Typography variant='body2' sx={{ mb: 2, color: 'gray' }}>
                        {addOn.description}
                      </Typography>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        {addOn.price && (
                          <Typography
                            variant='body1'
                            sx={{
                              mb: 2,
                              fontWeight: 600,
                              bgcolor: '#E7F0FF',
                              p: 1,
                              fontSize: '13px',
                              color: `${theme.palette.primary.main}`,
                              borderRadius: '6px'
                            }}
                          >
                            {addOn.price}
                          </Typography>
                        )}
                        <Button
                          sx={{
                            width: addOn.comingSoon ? '30%' : '20%',
                            borderRadius: '8px',
                            bgcolor: `${theme.palette.primary.main}`,
                            color: `${theme.palette.primary.contrastText}`,
                            '&:hover': {
                              bgcolor: `${theme.palette.primary.main}`
                            }
                          }}
                          variant='contained'
                          color={addOn.comingSoon ? 'secondary' : 'primary'}
                          disabled={addOn.comingSoon}
                          fullWidth
                          onClick={() => {
                            if (addOn.title === 'DFY (Done-for-you) Accounts Setup') {
                              navigate('/emailaccounts/orderstatus/add', { state: { from: '/settings' } })
                            } else if (addOn.title === 'workspace' || addOn.title === 'Connecting additional Linkedin accounts') {
                              openDialog(addOn.id)
                            } else {
                              openContactDialog(addOn.id)
                            }
                          }}
                        >
                          {addOn.buttonText}
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            {selectedAddon && (
              <ContactSalesDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                addon={selectedAddon}
              />
            )}
          </Box>
        </Box>
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '800px',
            backgroundColor: '#fff',
            borderRadius: '16px',
            boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.2)',
            padding: '32px',
            maxHeight: '90vh',
            overflowY: 'auto'
          }}
        >
          {/* Header */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant='h6' sx={{ fontWeight: 'bold', fontSize: '18px' }}>
              {selectedAddOn?.title ? `Add ${selectedAddOn.title}` : 'Select Add-ons'}
            </Typography>
            <Box
              component='span'
              onClick={handleClose}
              sx={{
                cursor: 'pointer',
                color: '#6c757d',
                fontSize: '20px',
                '&:hover': { color: '#3F4FF8' }
              }}
            >
              &#x2715;
            </Box>
          </Box>

          {/* Subheading */}
          <Typography variant='body2' sx={{ my: 2, color: '#6c757d' }}>
            Enhance your plan with powerful add-ons to maximize efficiency and streamline your workflows.
          </Typography>

          {/* Billing Toggle */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
              mb: 3,
              backgroundColor: '#f5f5f5',
              p: 1,
              borderRadius: '12px'
            }}
          >
            <Typography
              variant='body1'
              sx={{
                fontWeight: subscriptionType === 'monthly' ? 600 : 400,
                color: subscriptionType === 'monthly' ? '#3F4FF8' : '#6c757d',
                cursor: 'pointer'
              }}
              onClick={() => setSubscriptionType('monthly')}
            >
              Monthly
            </Typography>
            <Box
              sx={{
                width: '48px',
                height: '24px',
                backgroundColor: '#3F4FF8',
                borderRadius: '12px',
                position: 'relative',
                cursor: 'pointer'
              }}
              onClick={() => setSubscriptionType(subscriptionType === 'monthly' ? 'yearly' : 'monthly')}
            >
              <Box
                sx={{
                  width: '20px',
                  height: '20px',
                  backgroundColor: '#fff',
                  borderRadius: '50%',
                  position: 'absolute',
                  top: '2px',
                  left: subscriptionType === 'yearly' ? '26px' : '2px',
                  transition: 'left 0.2s'
                }}
              />
            </Box>
            <Typography
              variant='body1'
              sx={{
                fontWeight: subscriptionType === 'yearly' ? 600 : 400,
                color: subscriptionType === 'yearly' ? '#3F4FF8' : '#6c757d',
                cursor: 'pointer'
              }}
              onClick={() => setSubscriptionType('yearly')}
            >
              Yearly
            </Typography>
          </Box>

          {/* Selected Add-on Details */}
          {selectedAddOn && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                border: '1px solid #3F4FF8',
                borderRadius: '12px',
                p: 3,
                mb: 2,
                backgroundColor: '#fff'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box>
                  <Typography variant='subtitle1' sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                    {selectedAddOn.title}
                  </Typography>
                  <Typography variant='body2' color='text.secondary'>
                    Add additional credits to your account
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <TextField
                  type='number'
                  size='small'
                  value={accountCount}
                  onChange={(e) => handleQuantityChange(e.target.value)}
                  onKeyDown={(e) => handleStepIncrement(e, selectedAddOn)}
                  InputProps={{
                    inputProps: { min: 0 },
                    sx: { borderRadius: '8px' }
                  }}
                  sx={{
                    width: '100px',
                    '& .MuiOutlinedInput-root': { borderColor: '#E0E0E0' }
                  }}
                />
                <Typography variant='body2' sx={{ fontWeight: 'bold', color: '#333', minWidth: '120px' }}>
                  Total: ${(calculateTotalPrice(accountCount, selectedAddOn, subscriptionType, selectedPlan)).toFixed(2)}
                </Typography>
              </Box>
            </Box>
          )}

          {/* Tiered Pricing Options - Moved to bottom */}
          {selectedAddOn?.id === 'emailVerification' && (
            <Box sx={{ mb: 3 }}>
              <Typography variant='subtitle1' sx={{ mb: 2, fontWeight: 600 }}>
                Choose credits package or customize
              </Typography>
              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
                {tiers.map((tier) => (
                  <Box
                    key={tier.credits}
                    onClick={() => handleTierSelect(tier)}
                    sx={{
                      border: '1px solid',
                      borderColor: selectedTier?.credits === tier.credits ? '#3F4FF8' : '#e0e0e0',
                      borderRadius: '12px',
                      p: 2,
                      cursor: 'pointer',
                      backgroundColor: selectedTier?.credits === tier.credits ? '#f8f9ff' : '#fff',
                      '&:hover': {
                        borderColor: '#3F4FF8'
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography>{tier.credits.toLocaleString()} credits</Typography>
                      <Typography sx={{ fontWeight: 600 }}>
                        ${(calculateTotalPrice(tier.credits, selectedAddOn)).toFixed(2)}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {/* Dropdown for workspace */}
            {selectedAddOn?.id === 'workspace' && (
              <TextField
                select
                label='Select Plan'
                value={selectedPlan}
                onChange={(e) => setSelectedPlan(e.target.value)}
                sx={{
                  marginTop: '16px',
                  width: '100%'
                }}
                SelectProps={{
                  native: true
                }}
              >
                {selectedAddOn?.planName?.map((plan, index) => (
                  <option key={index} value={index}>
                    {plan}
                  </option>
                ))}
              </TextField>
            )}
          </Box>

          {/* Footer Buttons */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
            <Button
              variant='outlined'
              onClick={handleClose}
              sx={{
                color: '#6c757d',
                borderColor: '#e0e0e0',
                textTransform: 'none',
                px: 3,
                py: 1.5,
                fontWeight: 600,
                '&:hover': { borderColor: '#3F4FF8', color: '#3F4FF8' }
              }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              sx={{
                backgroundColor: '#3F4FF8',
                color: '#fff',
                textTransform: 'none',
                px: 3,
                py: 1.5,
                fontWeight: 600,
                '&:hover': { backgroundColor: '#2C3AB8' }
              }}
              onClick={() => handleBuyAddonClick(selectedAddOn)}
              disabled={!selectedAddOn}
            >
              Add Credits & Continue
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default UsagePage
