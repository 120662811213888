import React, { useState } from 'react'
import { MenuItem, Select, TableCell } from '@mui/material'
import { useUpdateAssignedPlanMutation } from 'src/services/partnerUser-service'
import { useSelector } from 'react-redux'
import { useGetCustomPlansQuery } from 'src/services/customPlan-service'
import toast from 'react-hot-toast'
import ConfirmActionModal from './confirmActionModal'

const PlanSelector = ({ row, setRefetchUser }) => {
  const user = useSelector((state) => state.user)
  const { data: planNames } = useGetCustomPlansQuery({ PartnerId: user._id })
  const [updateAssignedPlan] = useUpdateAssignedPlanMutation()
  const [openPlanConfirmModal, setOpenPlanConfirmModal] = useState(false)
  const [currentPlan, setCurrentPlan] = useState(row.plan?.customPlanId || '')

  const handleOpenCofirmModal = (event) => {
    setCurrentPlan(event.target.value)
    setOpenPlanConfirmModal(true)
  }

  const handleCloseCofirmModal = () => {
    setOpenPlanConfirmModal(false)
    setRefetchUser(true)
  }

  const handlePlanChange = async () => {
    try {
      const { message } = await updateAssignedPlan({
        userId: row.id,
        planId: currentPlan
      }).unwrap()
      handleCloseCofirmModal()
      toast.success(message || 'Plan Assigned')
    } catch ({ data: error }) {
      toast.error(`${error.status || 'Error'}: ${error.message || 'Falied to update Plan!'}`)
    }
  }

  return (
    <TableCell align='center'>
      <Select
        value={currentPlan}
        onChange={handleOpenCofirmModal}
        size='small'
        displayEmpty
        sx={{
          fontSize: '14px',
          minWidth: '120px',
          '& .MuiSelect-select': {
            padding: '6px 10px'
          }
        }}
      >
        <MenuItem value='' sx={{ fontSize: '14px' }} disabled>
          Choose Plan
        </MenuItem>
        {planNames?.customPlan?.map((plan) => (
          <MenuItem
            key={plan._id}
            value={plan._id}
            sx={{ fontSize: '14px' }}
          >
            {plan.planName}
          </MenuItem>
        ))}
      </Select>
      <ConfirmActionModal
        open={openPlanConfirmModal}
        onClose={handleCloseCofirmModal}
        title='Confirmation'
        description='Are you sure you want to Update Plan?'
        confirmButtonText='Confirm'
        onConfirm={handlePlanChange}
      />
    </TableCell>
  )
}

export default PlanSelector
