import React, { useEffect, useState } from 'react'
import { TextField, Alert, IconButton, Tooltip, Typography, Paper, Grid } from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import VisibilityIcon from '@mui/icons-material/Visibility'
import EditIcon from '@mui/icons-material/Edit'
import { useTheme } from '@mui/system'

const ScriptContent = ({ value, onChange }) => {
  const theme = useTheme()
  const [error, setError] = useState('')
  const [preview, setPreview] = useState('')
  const [isPreviewMode, setIsPreviewMode] = useState(false)

  const validateTrackingCode = (code = '') => {
    if (!code) return 'Code cannot be empty'
    const hasScriptTag = /<script[\s\S]*?>[\s\S]*?<\/script>/i.test(code)
    const validDomains = ['googletagmanager.com', 'google-analytics.com', 'facebook.net', 'intercomcdn.com', 'hotjar.com', 'analytics.tiktok.com']
    const validPatterns = ['gtag', 'fbq', 'dataLayer', 'analytics', 'intercomSettings', 'hotjar', 'snaptr', 'ttq']
    const hasValidTracking = validDomains.some(domain => code?.includes(domain)) || validPatterns.some(pattern => code?.includes(pattern))
    const hasMaliciousPatterns = /(<\s*script[^>]*src\s*=\s*['"][^'"]*(data:|javascript:|vbscript:))/i.test(code)

    if (!hasScriptTag) return 'Code must be wrapped in <script> tags'
    if (!hasValidTracking) return 'Code does not appear to be a valid tracking snippet'
    if (hasMaliciousPatterns) return 'Code contains potentially harmful patterns'
    return ''
  }

  const handleInputChange = (e) => {
    const inputValue = e.target.value
    onChange(inputValue)
    setError(validateTrackingCode(inputValue))
    setPreview(validateTrackingCode(inputValue) ? '' : inputValue)
  }

  useEffect(() => {
    setPreview(validateTrackingCode(value) ? '' : value)
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ pt: '0px !important' }}>
        <Tooltip title={isPreviewMode ? 'Edit' : 'Preview'}>
          <IconButton color={theme.palette.primary.main} onClick={() => setIsPreviewMode(!isPreviewMode)}>
            {isPreviewMode ? <EditIcon /> : <VisibilityIcon />}
          </IconButton>
        </Tooltip>
        <Tooltip title='Copy'>
          <IconButton color={theme.palette.primary.main} onClick={() => navigator.clipboard.writeText(value)}>
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>

        {isPreviewMode
          ? (
            <Paper sx={{ p: 2, backgroundColor: 'grey.100', overflowX: 'auto' }}>
              <Typography component='pre' sx={{ fontFamily: 'monospace', margin: 0 }}>{preview}</Typography>
            </Paper>
            )
          : (
            <TextField
              fullWidth
              multiline
              rows={6}
              variant='outlined'
              value={value}
              onChange={handleInputChange}
              placeholder='Add tracking scripts code here (e.g., GTM, Analytics, Intercom).'
              sx={{ fontFamily: 'monospace' }}
            />
            )}

        {error && <Alert severity='error' icon={<WarningIcon />} sx={{ mt: 2 }}>{error}</Alert>}
      </Grid>
    </Grid>
  )
}

export default ScriptContent
