import React, { useState } from 'react'
import {
  Box,
  TextField,
  Typography,
  Grid,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  useTheme,
  IconButton,
  Checkbox,
  ButtonBase,
  useMediaQuery,
  InputAdornment
} from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { EACloseIcon } from 'src/assets/emailAccounts/EACloseIcon'
import { useRegisterMutation } from 'src/services/auth-service'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import TermsAndConditionsModal from 'src/pages/auth/termsAndConditionsModal'
import PrivacyPolicyModal from 'src/pages/auth/privacyPolicyModal'
import { useSelector } from 'react-redux'
import toast from 'react-hot-toast'

const AddUser = ({ onClose, open, title }) => {
  const theme = useTheme()
  const user = useSelector((state) => state.user)
  const { theme: whiteLabelTheme } = useSelector((state) => state.partnerDetails)
  const [register] = useRegisterMutation()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [openTermsAndConditionsModal, setOpenTermsAndConditionsModal] = useState(false)
  const [openPrivacyPolicyModal, setOpenPrivacyPolicyModal] = useState(false)
  const companyName = whiteLabelTheme?.companyName || 'Success.ai'
  const companyWebsite = whiteLabelTheme?.companyWebsite || 'www.success.ai'
  const supportEmail = whiteLabelTheme?.supportEmail || 'support@success.ai'
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const defaultValues = {
    name: {
      first: '',
      last: ''
    },
    email: '',
    password: '',
    confirmPassword: '',
    terms: false
  }

  const validationSchema = () => {
    const schema = Yup.object({
      name: Yup.object({
        first: Yup.string()
          .required('First Name is required')
          .matches(/^[aA-zZ]+$/, 'Only alphabets are allowed'),
        last: Yup.string()
          .required('Last Name is required')
          .matches(/^[aA-zZ]+$/, 'Only alphabets are allowed')
      }),
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required')
        .test('no-plus', 'Please add a valid email address', (value) => {
          return !value || !value.includes('+')
        }),
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .max(255)
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/\d/, 'Password must contain at least one number')
        .matches(/[@$!%*?&]/, 'Password must contain at least one special character')
        .required('Password is required'),
      confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
      terms: Yup.bool().oneOf([true], 'You must accept the Terms and Conditions')
    })

    return schema
  }

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        const { terms, ...submissionData } = values
        await handleSignUp({ partnerAccountId: user._id, ...submissionData }, helpers)
      } catch (err) {
        helpers.setErrors({ submit: err.response?.data?.error?.message || err.message })
        toast.error(err.response?.data?.error?.message || err.message)
      }
      formik.resetForm()
    }
  })

  const handleSignUp = async (values, helpers) => {
    try {
      const { confirmPassword, ...rest } = values
      values = rest
      await register(values).unwrap()
      toast.success('User Registerd Successfully')
      handleClose()
    } catch (err) {
      helpers.setErrors({ submit: err.data.error.message })
      toast.error(err.data.error.message)
    }
  }

  const handleClose = () => {
    onClose()
    formik.setValues({
      ...defaultValues
    })
  }

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              width: '100%'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column'
              }}
            >
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '28px',
                  color: `${theme.palette.primary.primaryText}`
                }}
              >
                {title}
              </Typography>
            </Box>
            <IconButton
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              onClick={onClose}
            >
              <EACloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Grid container columnSpacing={2}>
              <Grid item xs={isMdUp ? 6 : 12}>
                {' '}
                <Typography
                  sx={{
                    width: '100%',
                    textAlign: 'left',
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '20px',
                    color: '#101010',
                    mt: 2
                  }}
                >
                  First name *
                </Typography>
                <TextField
                  variant='outlined'
                  error={!!(formik.touched.name?.first && formik.errors.name?.first)}
                  fullWidth
                  helperText={formik.touched.name?.first && formik.errors.name?.first}
                  name='name.first'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={
                    formik?.values?.name?.first
                  }
                  placeholder='Enter first name'
                  sx={{
                    mt: 1,
                    width:
                      isMdUp || user?.name?.first || formik?.values?.name?.first
                        ? '100%'
                        : '60%',
                    // height: 40,
                    backgroundColor: 'white',
                    '& div': { pl: 0.3 },
                    '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                    '& div input': {
                      py: 1.5,
                      fontSize: '13px',
                      fontWeight: 400,
                      letterSpacing: '0em',
                      '&::placeholder': {
                        color: 'rgba(40, 40, 123, 0.5)'
                      }
                    }
                  }}
                />
              </Grid>
              <Grid item xs={isMdUp ? 6 : 12}>
                {' '}
                <Typography
                  sx={{
                    width: '100%',
                    textAlign: 'left',
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '20px',
                    color: '#101010',
                    mt: 2
                  }}
                >
                  Last name *
                </Typography>
                <TextField
                  variant='outlined'
                  error={!!(formik.touched.name?.last && formik.errors.name?.last)}
                  fullWidth
                  helperText={formik.touched.name?.last && formik.errors.name?.last}
                  name='name.last'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik?.values?.name?.last}
                  placeholder='Enter last name'
                  sx={{
                    mt: 1,
                    width:
                      isMdUp || user?.name?.last || formik?.values?.name?.last
                        ? '100%'
                        : '60%',
                    // height: 40,
                    backgroundColor: 'white',
                    '& div': { pl: 0.3 },
                    '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                    '& div input': {
                      py: 1.5,
                      fontSize: '13px',
                      fontWeight: 400,
                      letterSpacing: '0em',
                      '&::placeholder': {
                        color: 'rgba(40, 40, 123, 0.5)'
                      }
                    }
                  }}
                />
              </Grid>
              <Grid xs={12} item>
                {' '}
                <Typography
                  sx={{
                    width: '100%',
                    textAlign: 'left',
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '20px',
                    color: '#101010',
                    mt: 2
                  }}
                >
                  Email *
                </Typography>
                <TextField
                  variant='outlined'
                  error={!!(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  name='email'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type='email'
                  value={formik.values.email}
                  placeholder='Enter email'
                  // disabled={!!invitedEmail}
                  sx={{
                    mt: 1,
                    width: '100%',
                    // height: 40,
                    backgroundColor: 'white',
                    '& div': { pl: 0.3 },
                    '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                    '& div input': {
                      py: 1.5,
                      fontSize: '13px',
                      fontWeight: 400,
                      letterSpacing: '0em',
                      '&::placeholder': {
                        color: 'rgba(40, 40, 123, 0.5)'
                      }
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                {' '}
                <Typography
                  sx={{
                    width: '100%',
                    textAlign: 'left',
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '20px',
                    color: '#101010',
                    mt: 2
                  }}
                >
                  Password *
                </Typography>
                <TextField
                  variant='outlined'
                  error={!!(formik.touched.password && formik.errors.password)}
                  fullWidth
                  helperText={formik.touched.password && formik.errors.password}
                  name='password'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type={showPassword ? 'text' : 'password'}
                  value={formik.values.password}
                  placeholder='Enter password'
                  sx={{
                    mt: 1,
                    width: '100%',
                    backgroundColor: 'white',
                    '& div': { pl: 0.3 },
                    '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                    '& div input': {
                      py: 1.5,
                      fontSize: '13px',
                      fontWeight: 400,
                      letterSpacing: '0em',
                      '&::placeholder': {
                        color: 'rgba(40, 40, 123, 0.5)'
                      }
                    },
                    '& .MuiFormHelperText-root': { textAlign: 'right', mx: 0 }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge='end'
                          aria-label='toggle password visibility'
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                {' '}
                <Typography
                  sx={{
                    width: '100%',
                    textAlign: 'left',
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '20px',
                    color: '#101010',
                    mt: 2
                  }}
                >
                  Confirm password *
                </Typography>
                <TextField
                  variant='outlined'
                  error={!!(formik.touched.password && formik.errors.confirmPassword)}
                  fullWidth
                  helperText={formik.touched.password && formik.errors.confirmPassword}
                  name='confirmPassword'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={formik.values.confirmPassword}
                  placeholder='Confirm password'
                  sx={{
                    mt: 1,
                    width: '100%',
                    backgroundColor: 'white',
                    '& div': { pl: 0.3 },
                    '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                    '& div input': {
                      py: 1.5,
                      fontSize: '13px',
                      fontWeight: 400,
                      letterSpacing: '0em',
                      '&::placeholder': {
                        color: 'rgba(40, 40, 123, 0.5)'
                      }
                    },
                    '& .MuiFormHelperText-root': { textAlign: 'right', mx: 0 }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          edge='end'
                          aria-label='toggle password visibility'
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid
                item
                sx={{ paddingTop: '20px', display: 'flex', justifyContent: 'space-between' }}
                xs={12}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    name='terms'
                    checked={formik.values.terms}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    sx={{
                      padding: '0',
                      marginRight: '6px',
                      transform: 'scale(0.9)'
                    }}
                  />
                  <Typography
                    sx={{
                      color: '#101010',
                      cursor: 'pointer',
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: '16px',
                      display: 'inline'
                    }}
                  >
                    I have read the{' '}
                    <ButtonBase
                      variant='text'
                      onClick={() => setOpenTermsAndConditionsModal(true)}
                      sx={{
                        display: 'inline',
                        color: '#0071F6',
                        fontSize: '14px',
                        fontWeight: 700,
                        fontFamily: 'inherit',
                        verticalAlign: 'baseline'
                      }}
                    >
                      Terms and Conditions
                    </ButtonBase>
                    <TermsAndConditionsModal open={openTermsAndConditionsModal} onClose={() => setOpenTermsAndConditionsModal(false)} companyName={companyName} companyWebsite={companyWebsite} supportEmail={supportEmail} isPartner />
                    {' '}
                    and
                    {' '}
                    <ButtonBase
                      variant='text'
                      onClick={() => setOpenPrivacyPolicyModal(true)}
                      sx={{
                        display: 'inline',
                        color: '#0071F6',
                        fontSize: '14px',
                        fontWeight: 700,
                        fontFamily: 'inherit',
                        verticalAlign: 'baseline'
                      }}
                    >
                      Privacy Policy.
                    </ButtonBase>
                    <PrivacyPolicyModal open={openPrivacyPolicyModal} onClose={() => setOpenPrivacyPolicyModal(false)} companyName={companyName} companyWebsite={companyWebsite} supportEmail={supportEmail} isPartner />
                  </Typography>
                  {formik.touched.terms && formik.errors.terms && (
                    <Typography color='error' sx={{ fontSize: '14px', fontWeight: '400', mt: 1 }}>
                      {formik.errors.terms}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>

            {formik.errors.submit && (
              <Typography color='error' sx={{ mt: 3, textAlign: 'right' }} variant='body2'>
                {formik.errors.submit}
              </Typography>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '20px'
              }}
            >
              <Button
                fullWidth
                sx={{
                  mt: 2,
                  py: 2,
                  fontSize: '14px',
                  fontWeight: 700,
                  borderRadius: '14px'
                }}
                // disabled={!rememberMe}
                type='submit'
                variant='contained'
              >
                {formik.isSubmitting
                  ? (
                    <CircularProgress size={20} color='inherit' />
                    )
                  : (
                    <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>
                      Create an account
                    </Typography>
                    )}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default AddUser
