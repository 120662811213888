import React, { useEffect, useState } from 'react'
import {
  Box,
  Tabs,
  Tab,
  Grid,
  Card,
  Typography,
  Button,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Paper,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  SvgIcon
} from '@mui/material'
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { sections } from './BilingPageData'
import { useTheme } from '@mui/material/styles'
import { FreeIcon, GrowthIcon, InfinityIcon, LightningSvg } from './settingsImages/SvgPlanIcons'
import { config, planNames, allowedPrice, yearlyPriceIds } from 'src/config'
import {
  useGetCurrentPlanQuery,
  useUpdateFreePlanMutation,
  useUpdateFreeTrialPlanMutation,
  useUpdatePlanMutation
} from 'src/services/billing-service.js'
import { toast } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import UserVerification from '../emailAccounts/UserVerification'
import CloseIcon from '@mui/icons-material/Close'
import { Logo } from 'src/components/logo'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import { CheckCircleIcon } from 'src/assets/general/CheckCircleIcon'
import {
  useDeleteWorkSpaceMutation,
  useGetWorkSpacesMutation,
  useUpdateWorkSpaceStatusMutation,
  setWorkspace
} from 'src/services/workSpace-service'
import RemoveWorkSpaceDialog from './RemoveWorkspaceDialog'
import { ArrowDropDown } from '@mui/icons-material'
import PopoverMenu from './PopoverMenu'
import { useUpdateUserNameMutation } from 'src/services/user-service'

function CustomTabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ width: '100%' }}
    >
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

export function BilingPage ({ tab, fromCreateWorkspace, mode }) {
  const [value, setValue] = useState(
    tab === 'billing' ? 0 : tab === 'account' ? 1 : tab === 'blocklist' ? 2 : 0
  )
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [billingMonthly, setBillingMonthly] = useState(true)
  const { data: fetchedCurrentPlan, refetch: refetchCurrentPlan } = useGetCurrentPlanQuery()
  const [currentPlan, setCurrentPlan] = useState(fetchedCurrentPlan)
  const [updatePlan] = useUpdatePlanMutation()
  const [updateFreePlan] = useUpdateFreePlanMutation()
  const { isFreeUser, appSumoPlanNames } = UserVerification()
  const [updateFreeTrialPlan] = useUpdateFreeTrialPlanMutation()
  const [updateWorkSpaceStatus] = useUpdateWorkSpaceStatusMutation()
  const [workspaceId, setWorkspaceId] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [planToUpdate, setPlanToUpdate] = useState(null)
  const workspace = useSelector((state) => state.workspace)
  const [anchorEl1, setAnchorEl1] = useState(null)
  const [timelineButtons, setTimeLineButtons] = useState([])
  const [timeline, setTimeline] = useState(null)
  const [openRemoveWorkspaceDialog, setOpenRemoveWorkspaceDialog] = useState(false)
  const [deleteWorkSpace, { isLoading: isDeletingWorkspace }] = useDeleteWorkSpaceMutation()
  const [getWorkSpaces] = useGetWorkSpacesMutation()
  const [activeWorkspace, setActiveWorkspace] = useState({})

  const isCurrentSendingWarmupPlan = (priceId) => {
    return currentPlan?.subscription?.sendingWarmup?.planId === priceId
  }

  const handleReload = () => {
    navigate(0)
  }

  const handleUpdatePlanClick = async (priceId) => {
    if (isCurrentSendingWarmupPlan(priceId)) return
    setPlanToUpdate(priceId)
    setOpenModal(true)
  }

  const handleUpdateFreePlan = () => {
    updateFreePlan({ workspaceId })
      .then((response) => {
        toast.success(response?.data?.data?.message)
        refetchCurrentPlan()
        navigate('/settingsUpdated')
      })
      .catch((error) => {
        console.error('Error updating plan:', error)
      })
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
    if (newValue === 0) {
      setBillingMonthly(true)
    } else {
      setBillingMonthly(false)
    }
  }
  const url = new URL(window.location.href)
  const params = new URLSearchParams(new URL(url).search)
  const priceIdUrl = params.get('priceID')
  const priceId = priceIdUrl && allowedPrice.includes(priceIdUrl) ? priceIdUrl : null

  useEffect(() => {
    if (fromCreateWorkspace) {
      setCurrentPlan({})
    } else {
      setCurrentPlan(fetchedCurrentPlan)
    }

    setWorkspaceId(workspace?._id)

    if (priceId && yearlyPriceIds.includes(priceId)) {
      setValue(1)
      setBillingMonthly(false)
    }
  }, [fromCreateWorkspace, fetchedCurrentPlan, workspace, priceId, yearlyPriceIds])

  const heroPlans = [
    {
      title: (
        <>
          <Box
            sx={{
              display: 'inline-flex',
              mr: 1,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <SvgIcon
              component={FreeIcon}
              stroke={selectedPlan === 0 ? '#ffffff' : theme.palette.primary.main}
            />
          </Box>
          Free
        </>
      ),
      price: '$0',
      description: 'Free Forever, No credit card required',
      features: ['Unlimited Campaigns', 'Basic Campaign Analytics', 'Email Support'],
      buttonLabel: currentPlan?.resetPlanDate ? 'Your current plan' : 'Choose plan',
      handleUpdateFreePlan,
      highlighted: !!currentPlan?.resetPlanDate,
      disable: !!currentPlan?.resetPlanDate
    },
    {
      title: (
        <>
          <Box
            sx={{ display: 'inline-flex', mr: 1, alignItems: 'center', justifyContent: 'center' }}
          >
            <SvgIcon
              component={LightningSvg}
              stroke={selectedPlan === 1 ? '#ffffff' : theme.palette.primary.main}
              sx={{
                verticalAlign: 'middle',
                mr: 1
              }}
            />
          </Box>
          Starter
        </>
      ),
      price: '$59',
      discountPrice: billingMonthly ? null : '$35',
      description: billingMonthly ? 'Per month, billed monthly' : 'Per month, billed annually',
      planId: billingMonthly
        ? config.SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID
        : config.SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID,
      isCurrentPlan: isCurrentSendingWarmupPlan(
        billingMonthly
          ? config.SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID
          : config.SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID
      ),
      features: [
        'Unlimited Inbox & CRM Pipeline',
        'People/Company Enrichment',
        'Live Chat Support'
      ],
      handleUpdatePlanClick,
      buttonLabel: isCurrentSendingWarmupPlan(
        billingMonthly
          ? config.SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID
          : config.SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID
      )
        ? 'Your current plan'
        : 'Choose plan',
      highlighted: isCurrentSendingWarmupPlan(
        billingMonthly
          ? config.SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID
          : config.SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID
      ),
      text: ' Everything in',
      sortText: 'Starter',
      disable: false,
      planType: config.YEARLY_STARTER_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID,
      planTypeName: 'starter_trial_seven_days',
      freeTrialdisbaled:
        currentPlan?.subscription?.sendingWarmup?.planId ===
        config.YEARLY_STARTER_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID,
      freeTrialButtonLabel:
        currentPlan?.subscription?.sendingWarmup?.planId ===
          config.YEARLY_STARTER_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID
          ? 'Current Plan'
          : '7 days free trial'
    },
    {
      title: (
        <>
          <Box
            sx={{ display: 'inline-flex', mr: 1, alignItems: 'center', justifyContent: 'center' }}
          >
            <SvgIcon
              component={GrowthIcon}
              stroke={selectedPlan === 2 ? '#ffffff' : theme.palette.primary.main}
            />
          </Box>
          Growth
        </>
      ),
      price: '$99',
      discountPrice: billingMonthly ? null : '$59',
      description: billingMonthly ? 'Per month, billed monthly' : 'Per month, billed annually',
      planId: billingMonthly
        ? config.SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID
        : config.SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID,
      isCurrentPlan: isCurrentSendingWarmupPlan(
        billingMonthly
          ? config.SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID
          : config.SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID
      ),
      features: ['Buyer Intent Data', 'Multi-Channel Outreach', 'Team Functionality'],
      buttonLabel: isCurrentSendingWarmupPlan(
        billingMonthly
          ? config.SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID
          : config.SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID
      )
        ? 'Your current plan'
        : 'Choose plan',
      highlighted: isCurrentSendingWarmupPlan(
        billingMonthly
          ? config.SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID
          : config.SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID
      ),
      text: ' Everything in',
      sortText: 'Starter',
      disable: false,
      planType: config.YEARLY_GROWTH_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID,
      planTypeName: 'growth_trial_seven_days',
      freeTrialdisbaled:
        currentPlan?.subscription?.sendingWarmup?.planId ===
        config.YEARLY_GROWTH_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID,
      freeTrialButtonLabel:
        currentPlan?.subscription?.sendingWarmup?.planId ===
          config.YEARLY_GROWTH_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID
          ? 'Current Plan'
          : '7 days free trial'
    },
    {
      title: (
        <>
          <Box
            sx={{ display: 'inline-flex', mr: 1, alignItems: 'center', justifyContent: 'center' }}
          >
            <SvgIcon
              component={InfinityIcon}
              stroke={selectedPlan === 3 ? '#ffffff' : theme.palette.primary.main}
            />
          </Box>
          Infinity
        </>
      ),
      price: '$149',
      discountPrice: billingMonthly ? null : '$89',
      description: billingMonthly ? 'Per month, billed monthly' : 'Per month, billed annually',

      features: ['Unlimited Contact Exports', 'Unlimited Phone Numbers', 'Webhooks & APIs'],
      planId: billingMonthly
        ? config.SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID
        : config.SENDING_WARMUP_YEARLY_SCALE_PRICE_ID,
      isCurrentPlan: isCurrentSendingWarmupPlan(
        billingMonthly
          ? config.SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID
          : config.SENDING_WARMUP_YEARLY_SCALE_PRICE_ID
      ),
      buttonLabel: isCurrentSendingWarmupPlan(
        billingMonthly
          ? config.SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID
          : config.SENDING_WARMUP_YEARLY_SCALE_PRICE_ID
      )
        ? 'Your current plan'
        : 'Choose plan',
      highlighted: isCurrentSendingWarmupPlan(
        billingMonthly
          ? config.SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID
          : config.SENDING_WARMUP_YEARLY_SCALE_PRICE_ID
      ),
      text: ' Everything in',
      sortText: 'Starter + Growth',
      disable: false,
      planType: config.YEARLY_INFINITY_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID,
      planTypeName: 'infinity_trial_seven_days',
      freeTrialdisbaled:
        currentPlan?.subscription?.sendingWarmup?.planId ===
        config.YEARLY_INFINITY_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID,
      freeTrialButtonLabel:
        currentPlan?.subscription?.sendingWarmup?.planId ===
          config.YEARLY_INFINITY_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID
          ? 'Current Plan'
          : '7 days free trial',
      chip: (
        <Chip
          label='Best Value'
          color='primary'
          size='small'
          sx={{
            position: 'absolute',
            // top: 4,
            top: { xs: '75%', sm: '50%', md: 4 },
            right: { xs: '30%', sm: '10%', md: '4%', lg: '7%' },
            transform: 'rotate(-5deg)',

            backgroundColor: `${theme.palette.primary.main}`,
            color: '#FFFFFF',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            paddingX: { md: 2.5, lg: 3, sm: 3, xs: 3 },
            paddingY: { md: 2.5, lg: 3, sm: 3, xs: 3 },
            borderRadius: '6px',
            zIndex: 1000,
            fontWeight: 700,
            fontSize: '18px',
            border: '2.5px solid #FFFFFF '
          }}
        />
      )
    }
  ]
  const filteredHeroPlans = fromCreateWorkspace ? heroPlans.slice(1) : heroPlans
  const plans = [
    {
      name: (
        <Box
          sx={{
            display: 'inline-flex',

            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              display: 'inline-flex',
              mr: 1,
              alignItems: 'center',
              width: 15,
              height: 15
            }}
          >
            <SvgIcon component={FreeIcon} stroke={theme.palette.primary.main} />
          </Box>
          <Box>Free</Box>
        </Box>
      ),
      price: '$0',
      features: ['✖', '✖', '✖', '✖', '✖', '✖'],
      currentPlan: true,
      description: billingMonthly ? 'billed monthly' : 'billed annually',
      handleUpdatePlanClick,
      buttonLabel: !currentPlan?.resetPlanDate ? 'Your current plan' : 'Choose plan',
      highlighted: !!currentPlan?.resetPlanDate,
      disable: !!currentPlan?.resetPlanDate
    },
    {
      name: (
        <Box
          sx={{
            display: 'inline-flex',

            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              display: 'inline-flex',
              mr: 1,
              alignItems: 'center',
              width: 15,
              height: 15
            }}
          >
            <SvgIcon component={LightningSvg} stroke={theme.palette.primary.main} />
          </Box>
          <Box>Starter</Box>
        </Box>
      ),
      price: '$59',
      discountPrice: billingMonthly ? null : '$35',
      features: ['Unlimited', '1,200', '1,200', '✔', '✖', '✔'],
      planId: billingMonthly
        ? config.SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID
        : config.SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID,
      isCurrentPlan: isCurrentSendingWarmupPlan(
        billingMonthly
          ? config.SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID
          : config.SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID
      ),
      description: billingMonthly ? 'billed monthly' : 'billed annually',
      handleUpdatePlanClick,
      buttonLabel: isCurrentSendingWarmupPlan(
        billingMonthly
          ? config.SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID
          : config.SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID
      )
        ? 'Your current plan'
        : 'Choose plan',
      highlighted: isCurrentSendingWarmupPlan(
        billingMonthly
          ? config.SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID
          : config.SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID
      )
    },
    {
      name: (
        <Box
          sx={{
            display: 'inline-flex',

            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              display: 'inline-flex',
              mr: 1,
              alignItems: 'center',
              width: 15,
              height: 15
            }}
          >
            <SvgIcon component={GrowthIcon} stroke={theme.palette.primary.main} />
          </Box>
          <Box>Growth</Box>
        </Box>
      ),

      price: '$99',
      discountPrice: billingMonthly ? null : '$59',
      features: ['Unlimited', '5,000', '5,000', '✔', '✔', '✔'],
      planId: billingMonthly
        ? config.SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID
        : config.SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID,
      isCurrentPlan: isCurrentSendingWarmupPlan(
        billingMonthly
          ? config.SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID
          : config.SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID
      ),
      description: billingMonthly ? 'billed monthly' : 'billed annually',
      handleUpdatePlanClick,
      buttonLabel: isCurrentSendingWarmupPlan(
        billingMonthly
          ? config.SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID
          : config.SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID
      )
        ? 'Your current plan'
        : 'Choose plan',
      highlighted: isCurrentSendingWarmupPlan(
        billingMonthly
          ? config.SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID
          : config.SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID
      )
    },
    {
      name: (
        <Box
          sx={{
            display: 'inline-flex',

            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              display: 'inline-flex',
              mr: 1,
              alignItems: 'center',
              width: 15,
              height: 15
            }}
          >
            <SvgIcon component={InfinityIcon} stroke={theme.palette.primary.contrastText} />
          </Box>
          <Box>Infinity</Box>
        </Box>
      ),

      price: '$149',
      discountPrice: billingMonthly ? null : '$89',
      features: ['Unlimited', 'Unlimited', 'Unlimited', '✔', '✔', '✔'],
      bestValue: true,
      planId: billingMonthly
        ? config.SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID
        : config.SENDING_WARMUP_YEARLY_SCALE_PRICE_ID,
      isCurrentPlan: isCurrentSendingWarmupPlan(
        billingMonthly
          ? config.SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID
          : config.SENDING_WARMUP_YEARLY_SCALE_PRICE_ID
      ),
      description: billingMonthly ? 'billed monthly' : 'billed annually',
      buttonLabel: isCurrentSendingWarmupPlan(
        billingMonthly
          ? config.SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID
          : config.SENDING_WARMUP_YEARLY_SCALE_PRICE_ID
      )
        ? 'Your current plan'
        : 'Choose plan',
      highlighted: isCurrentSendingWarmupPlan(
        billingMonthly
          ? config.SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID
          : config.SENDING_WARMUP_YEARLY_SCALE_PRICE_ID
      )
    }
  ]

  // 3. Add new handleConfirmPlanUpdate function
  const handleConfirmPlanUpdate = async () => {
    const priceId = planToUpdate
    let planName = null
    const matchedPlanName = planNames[priceId]
    if (matchedPlanName) {
      planName = matchedPlanName.toLowerCase().split(' ')[1]
    }
    const toastId = toast.loading('Updating...', { duration: Infinity })

    const session = await updatePlan({ priceId, workspaceId, planName }).unwrap()
    if (session.url) {
      window.location.href = session.url
    } else if (session.errorMsg) {
      toast.error(session.errorMsg, { id: toastId, duration: 5000 })
    } else {
      toast.success('Plan Updated', { id: toastId, duration: 2000 })
      await refetchCurrentPlan()
    }
    setOpenModal(false)
  }

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric'
    })
  }
  const addDays = (days) => {
    const currentDate = new Date()
    const newDate = new Date(currentDate)
    newDate.setDate(currentDate.getDate() + days)
    return newDate
  }

  const currentDate = new Date()
  const datePlus4 = addDays(4)
  const datePlus7 = addDays(7)

  const [openfreeTrialModal, setOpenfreeTrialModal] = useState(false)
  const [valuePlan, setValuePlan] = useState(null)
  const [trialValuePlan, setTrialValuePlan] = useState(null) // Store the selected plan type

  const handleOpenModal = (planType, value) => {
    setTrialValuePlan(planType)
    setValuePlan(value)
    setOpenfreeTrialModal(true)
  }

  const handleCloseModal = () => {
    setOpenfreeTrialModal(false)
    setTrialValuePlan(null) // Reset the selected plan type
    setValuePlan(null)
  }
  const [updateUserName] = useUpdateUserNameMutation()
  const user = useSelector((state) => state.user)
  const [open, setOpen] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [companyName, setCompanyName] = useState('')

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    const isExceedingLength = (value) => value && value.length > 15

    if ([firstName, lastName, companyName].some(isExceedingLength)) {
      toast.error('First name, last name, and company name cannot exceed 15 characters.')
      return
    }

    const emptyFields = [
      !firstName?.trim() && 'First name',
      !lastName?.trim() && 'Last name',
      !companyName?.trim() && 'Company name'
    ].filter(Boolean)

    if (emptyFields.length > 0) {
      toast.error(`${emptyFields.join(', ')} cannot be empty.`)
      return
    }

    const userId = user?._id
    updateUserName({ firstName, lastName, companyName, userId }).then((response) => {
      toast.success('User updated successfully')
      handleClose()
    })
  }

  const handleUpdateFreeTrialPlan = () => {
    if (!selectedPlan) return // Safety check
    updateFreeTrialPlan({ workspaceId, planType: trialValuePlan, value: valuePlan })
      .then((response) => {
        refetchCurrentPlan()
        navigate('/onboardinghub')
        handleReload()
        handleCloseModal() // Close the modal after success
      })
      .catch((error) => {
        handleCloseModal()
        throw error
      })
  }
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget)
  }

  const handleClose1 = () => {
    setAnchorEl1(null)
  }

  const handleWorkspaceChange = async (newTimeline) => {
    const { data } = await updateWorkSpaceStatus({ workspaceId: newTimeline?._id })
    const updatedWorkspace = data?.updatedWorkspace
    const currentPlan = data?.currentPlan
    refetchCurrentPlan()
    if (currentPlan || updatedWorkspace?.appSumoCode) {
      navigate('/accounts')
      // handleReload()
    } else {
      navigate('/workSpace/billing', { state: { fromCreateWorkspace: true }, replace: true })
    }
    setTimeline(updatedWorkspace?.data?.workSpace)
    dispatch(setWorkspace(updatedWorkspace?.data?.workSpace))
  }

  const handleRemoveWorkspaceClick = async () => {
    const { workSpace } = await deleteWorkSpace(timeline._id).unwrap()
    setTimeline(workSpace.defaultWorkspace)
    dispatch(setWorkspace(workSpace.defaultWorkspace))
    setOpenRemoveWorkspaceDialog(false)
    navigate('/accounts')
    refetchCurrentPlan()
  }

  const navigateToCreateWorkspace = () => {
    navigate('/new/workSpace/create', { state: { from: location.pathname } })
  }

  useEffect(() => {
    if (fromCreateWorkspace) {
      const timer = setTimeout(async () => {
        const docs = await getWorkSpaces().unwrap()
        setTimeLineButtons(docs?.uniqueWorkspaces)
        setActiveWorkspace(docs?.currentWorkspace)
      }, 500)
      return () => clearTimeout(timer)
    }
  }, [getWorkSpaces])

  useEffect(() => {
    if (activeWorkspace?._id) {
      dispatch(setWorkspace(activeWorkspace))
      setTimeline(activeWorkspace)
      window?.localStorage.setItem('workspace', JSON.stringify(activeWorkspace))
    }
  }, [timelineButtons])

  const getUserPlan = (user) => {
    const plan = user?.EarlyBirdPlan?.replace('EarlyBird', '') ?? ''
    return `You Are Currently On ${plan} Plan.`
  }

  return (
    <>
      {fromCreateWorkspace && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 4 }}>
          <Box sx={{ display: 'flex', width: '100%', maxWidth: '800px' }}>
            <Button
              sx={{
                backgroundColor: '#007bff',
                color: 'white',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 'bold',
                width: '40%',
                mb: 1,
                mt: 4,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                  backgroundColor: '#007bff'
                }
              }}
              onClick={handleClick1}
            >
              {timeline?.name}
              <ArrowDropDown sx={{ fontSize: '32px', ml: 2 }} />
            </Button>

            <PopoverMenu
              anchorEl={anchorEl1}
              handleClose={handleClose1}
              timelineButtons={timelineButtons}
              setTimeline={handleWorkspaceChange}
              navigateToCreateWorkspace={navigateToCreateWorkspace}
              timeline={timeline}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '800px' }}>
            <Typography sx={{ mb: 1, fontWeight: 'bold', fontSize: '32px' }}>
              Let&apos;s get your new workspace ready
            </Typography>
            <Typography sx={{ color: '#6B7280', fontSize: '20px', mb: 3 }}>
              Upgrade to one of our plans to continue.
            </Typography>
          </Box>
        </Box>
      )}
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 3, mt: 2 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor='black'
            indicatorColor='primary'
            sx={{
              display: 'flex',
              justifyContent: 'center',

              background: '#E1E3EC',
              border: '1ps solid #D7D9E6',
              borderRadius: '8px',
              '& .MuiTab-root': {
                textTransform: 'none',
                fontWeight: 600,

                borderRadius: '8px'
              },
              '& .Mui-selected': {
                backgroundColor: '#ffffff',
                color: `${theme.palette.primary.main}`,
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
              },
              '& .MuiTabs-indicator': {
                display: 'none'
              }
            }}
          >
            <Tab
              sx={{ my: '5px', mx: '5px', px: 6, py: '4px', fontSize: '14px', fontWeight: 600 }}
              label='Monthly '
            />
            <Tab
              sx={{ my: '5px', mx: '5px', px: 6, py: '4px', fontSize: '14px', fontWeight: 600 }}
              label='Annual  🎁 40% OFF'
            />
          </Tabs>
        </Box>

        {!fromCreateWorkspace &&
          workspace?.assignedPlan &&
          workspace?.assignedPlan.length > 0 &&
          !workspace?.isAppSumoRefund && (
            <Box
              sx={{
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '26px',
                color: `${theme.palette.primary.primaryText}`,
                ml: 1.5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                boxShadow: '0px 12px 15px 0px #4B71970D',
                borderRadius: '12px',
                backgroundColor: 'white',
                width: '100%',
                p: { xs: 1, sm: 2, md: 3 }

              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '26px',
                  color: '#28287B',
                  ml: 1.5

                }}
              >
                You Are Currently On App Sumo <i> {appSumoPlanNames[workspace?.assignedPlan]} </i>{' '}
                Plan.
              </Typography>
            </Box>
        )}
        {!fromCreateWorkspace &&
          workspace?.isEarlyBirdAccessUser &&
          workspace?.EarlyBirdPlan.length > 0 &&
          !workspace?.isEarlyBirdDealRefund && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                boxShadow: '0px 12px 15px 0px #4B71970D',
                borderRadius: '12px',
                backgroundColor: 'white',
                width: '100%',
                p: { xs: 1, sm: 2, md: 3 },
                marginBottom: '20px'
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '26px',
                  color: `${theme.palette.primary.primaryText}`,
                  ml: 1.5
                }}
              >
                {getUserPlan(workspace)}
              </Typography>
            </Box>
        )}

        {/* Pricing Cards */}

        <Grid container spacing={3} justifyContent='center' position='relative'>
          {filteredHeroPlans?.map((plan, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              {plan.chip && plan.chip}
              <Card
                sx={{
                  height: '100%',
                  cursor: 'pointer',
                  border: selectedPlan === index ? '2px solid #3F4FF8' : '1px solid #e0e0e0',
                  boxShadow:
                    selectedPlan === index
                      ? ' 0 4px 15px rgba(0, 112, 243, 0.5)'
                      : '0 2px 10px rgba(0,0,0,0.1)',
                  borderRadius: 2,
                  textAlign: 'center',
                  padding: 2,
                  transition: 'box-shadow 0.3s, border 0.3s',
                  position: 'relative'
                }}
                onClick={() => setSelectedPlan(index)}
              >
                <Box
                  sx={{
                    background:
                      selectedPlan === index ? `${theme.palette.primary.main}` : '#E7F0FF',
                    py: 4,
                    borderRadius: '6px',
                    height: '100%',
                    maxHeight: {
                      xs: '36%',
                      sm: '35%',
                      md: '29%',
                      lg: '35%',
                      xl: '40%'
                    }

                  }}
                >
                  <></>
                  <Typography
                    variant='h6'
                    sx={{
                      fontWeight: 700,
                      fontSize: '24px',

                      color:
                        selectedPlan === index
                          ? `${theme.palette.primary.contrastText}`
                          : plan.highlighted
                            ? '#101010'
                            : '#101010'
                    }}
                  >
                    {plan.title}
                  </Typography>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      marginY: 2
                    }}
                  >
                    {plan.discountPrice && (
                      <Typography
                        sx={{
                          fontSize: '24px',
                          fontWeight: 400,
                          color: selectedPlan === index ? '#FFFFFF' : '#8181B0',
                          textDecoration: 'line-through',
                          marginRight: 1
                        }}
                      >
                        {plan.price}
                      </Typography>
                    )}
                    <Typography
                      sx={{
                        fontSize: '37px',
                        fontWeight: 700,
                        color:
                          selectedPlan === index
                            ? `${theme.palette.primary.contrastText}`
                            : `${theme.palette.primary.main}`,
                        marginRight: 1
                      }}
                    >
                      {plan.discountPrice || plan.price}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 600,
                        color:
                          selectedPlan === index
                            ? `${theme.palette.primary.contrastText}`
                            : '#8181B0'
                      }}
                    >
                      /month
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      fontSize: '13px',
                      fontWeight: 500,
                      color:
                        selectedPlan === index
                          ? `${theme.palette.primary.contrastText}`
                          : '#8181B0',
                      marginBottom: 2
                    }}
                  >
                    {plan.description}
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#101010',
                    marginBottom: 2,
                    textAlign: 'center',
                    mx: 6,
                    mt: 2
                  }}
                >
                  <span style={{ fontWeight: 700 }}>Unlimited</span>: Contact Profiles, Email
                  Sending, and Email Warm Up
                </Typography>
                <Divider
                  sx={{
                    margin: '10px auto 0 auto',
                    borderColor: '#e0e0e0',
                    borderWidth: '1px',
                    width: '90%',
                    position: 'relative',
                    '::before, ::after': {
                      borderColor: '#e0e0e0'
                    },
                    color: '#555',

                    mb: '15px',

                    fontWeight: 500,
                    fontSize: '13px'
                  }}
                >
                  plus
                </Divider>
                <Box sx={{

                  height:
                    fromCreateWorkspace ||
                      !isFreeUser ||
                      (currentPlan?.subscription?.sendingWarmup?.planType === 'weekly' &&
                        new Date(currentPlan?.subscription?.sendingWarmup?.expiresAt) < new Date())
                      ? {
                          xs: '22%',
                          sm: '25%',
                          md: '27%',
                          lg: '27%',
                          xl: '24%'
                        }
                      : {

                          xs: '22%',
                          sm: '22%',
                          md: '24%',
                          lg: '23%',
                          xl: '18.5%'
                        }

                }}
                >
                  <Typography
                    mb={2}
                    sx={{ textAlign: 'start', paddingX: 2, fontWeight: 600, fontSize: '14px' }}
                  >
                    {plan.text} &nbsp;
                    {plan.sortText && (
                      <span
                        style={{
                          color: `${theme.palette.primary.main}`,
                          backgroundColor: '#E7F0FF',
                          padding: '6px',
                          borderRadius: '4px',
                          fontWeight: 600,
                          fontSize: '14px',
                          marginLeft: '10px'
                        }}
                      >
                        {plan.sortText} &nbsp;
                      </span>
                    )}
                  </Typography>

                  {plan.features.map((feature, idx) => (
                    <Typography
                      key={idx}
                      sx={{
                        fontSize: { xs: 12, sm: 13, md: 13, lg: 15 },
                        color: 'black',
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'start',

                        marginY: 0.5,
                        paddingX: 2
                      }}
                    >
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          borderRadius: '50%',
                          backgroundColor: '#4caf50',
                          color: `${theme.palette.primary.contrastText}`,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: 14,
                          marginRight: 1,
                          flexShrink: 0
                        }}
                      >
                        ✔
                      </Box>

                      {feature}
                    </Typography>
                  ))}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%'
                  }}
                >
                  <Button
                    fullWidth
                    disabled={plan.disable}
                    variant={!plan.highlighted ? 'contained' : 'outlined'}
                    sx={{
                      marginTop: plan.buttonLabel === 'Your current plan' ? 2 : 2,
                      backgroundColor: !plan.highlighted
                        ? `${theme.palette.primary.main}`
                        : '#F2F4F6',
                      border: !plan.highlighted
                        ? `1px solid ${theme.palette.primary.main}`
                        : ' 1px solid #D7D9E6',
                      color: !plan.highlighted
                        ? `${theme.palette.primary.contrastText}`
                        : '#92979C',
                      fontWeight: 'bold',
                      py: 1.5,
                      '&:hover': {
                        backgroundColor: !plan.highlighted
                          ? `${theme.palette.primary.main}`
                          : '#F2F4F6'
                      }
                    }}
                    onClick={() => {
                      if (plan.price === '$0') {
                        handleUpdateFreePlan()
                      } else if (plan.planId) {
                        handleUpdatePlanClick(plan.planId)
                      }
                    }}
                  >
                    {plan.disable ? 'Choose Plan' : plan.buttonLabel}
                  </Button>
                </Box>

                {index === 0 ||
                  fromCreateWorkspace ||
                  !isFreeUser ||
                  (currentPlan?.subscription?.sendingWarmup?.planType === 'weekly' &&
                    new Date(currentPlan?.subscription?.sendingWarmup?.expiresAt) < new Date())
                  ? (
                    <></>
                    )
                  : (
                    <Button
                      fullWidth
                      variant={plan.highlighted ? 'contained' : 'outlined'}
                      disabled={plan.freeTrialdisbaled}
                      sx={{
                        marginTop: plan.buttonLabel === 'Your current plan' ? 6.5 : '12px',
                        backgroundColor: plan.highlighted
                          ? `${theme.palette.primary.main}`
                          : '#F2F4F6',
                        border: plan.highlighted
                          ? `1px solid ${theme.palette.primary.main}`
                          : ' 1px solid #D7D9E6',
                        color: plan.highlighted ? `${theme.palette.primary.contrastText}` : '#92979C',
                        fontWeight: 'bold',
                        py: 1.5,
                        '&:hover': {
                          backgroundColor: plan.highlighted
                            ? `${theme.palette.primary.main}`
                            : '#F2F4F6'
                        }
                      }}
                      onClick={() => handleOpenModal(plan.planType, plan.planTypeName)}
                    >
                      {plan.freeTrialButtonLabel}
                    </Button>
                    )}
              </Card>
            </Grid>
          ))}
        </Grid>
        <Dialog open={openModal} onClose={() => setOpenModal(false)}>
          <DialogTitle>Confirm Plan Change</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to change your plan? This will update your billing immediately.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenModal(false)} color='primary'>
              Cancel
            </Button>
            <Button onClick={handleConfirmPlanUpdate} color='primary' variant='contained'>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        {/* free trial dialog  */}
        <Dialog open={openfreeTrialModal} onClose={handleCloseModal}>
          <DialogContent
            sx={{
              maxWidth: '525px'
            }}
          >
            <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
              <Button variant='ghost' size='icon' onClick={handleCloseModal}>
                <CloseIcon sx={{ width: 16, height: 16 }} />
              </Button>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mb: 2
              }}
            >
              <Logo sx={{ color: '#103776' }} logoTextColor='#103776' />

              <Box
                sx={{
                  width: '32px',
                  height: '32px',
                  backgroundColor: 'blue.600',
                  borderRadius: '50%'
                }}
              />
            </Box>
            <DialogTitle
              sx={{
                textAlign: 'center',
                fontSize: '1.25rem',
                fontWeight: 600
              }}
            >
              Confirm your 7-day free trial
            </DialogTitle>

            <Box
              sx={{
                position: 'relative',
                mt: 6,
                mb: 8
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  borderRadius: '10px',
                  left: 0,
                  right: 0,
                  height: '45px',
                  background: 'linear-gradient(to right, #d4efdf, #fcf3cf, #f5b7b1)'
                }}
              />
              <Box
                sx={{
                  top: '10px',
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                {[
                  {
                    icon: <LockOutlinedIcon sx={{ fontSize: '24px', color: '#1ab54d' }} />,
                    label: 'Trial Start',
                    date: formatDate(currentDate)
                  },
                  {
                    icon: (
                      <NotificationsNoneOutlinedIcon sx={{ fontSize: '24px', color: '#b0790e' }} />
                    ),
                    label: 'Reminder',
                    date: formatDate(datePlus4)
                  },
                  {
                    icon: <CalendarMonthOutlinedIcon sx={{ fontSize: '24px', color: '#fe5d59' }} />,
                    label: 'Trial End',
                    date: formatDate(datePlus7)
                  }
                ].map((item, index) => (
                  <Box key={index} sx={{ textAlign: 'center' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>{item.icon}</Box>
                    <Box sx={{ fontSize: '0.875rem', fontWeight: 700 }}>{item.label}</Box>
                    <Box
                      sx={{
                        fontSize: '0.645rem',
                        color: 'text.secondary'
                      }}
                    >
                      {item.date}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>

            <Box sx={{ spaceY: 6 }}>
              <Box
                sx={{
                  textAlign: 'center',
                  fontSize: '0.875rem',
                  color: 'text.secondary',
                  top: '10px'
                }}
              >
                Pay nothing today and get free access to Infinity plan features for the next seven days.
              </Box>

              <Box sx={{ spaceY: 2, marginTop: '20px', marginBottom: '20px' }}>
                {['No credit card required', 'Cancel anytime'].map((text, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '20px',
                        width: '20px',
                        borderRadius: '50%',
                        backgroundColor: 'green.100'
                      }}
                    >
                      <Box
                        sx={{
                          height: '8px',
                          width: '8px',
                          borderRadius: '50%',
                          backgroundColor: 'green.600'
                        }}
                      />
                    </Box>
                    <CheckCircleIcon color='#03aa3a' sx={{ fontSize: '24px' }} />
                    <Box sx={{ fontSize: '0.855rem', fontWeight: 700 }}>{text}</Box>
                  </Box>
                ))}
              </Box>

              <Button
                sx={{
                  width: '100%',
                  backgroundColor: '#3f50f8',
                  '&:hover': {
                    backgroundColor: '#3f50f8'
                  },
                  color: '#FFFFFF'
                }}
                onClick={handleUpdateFreeTrialPlan}
              >
                Confirm 7-Day Free Trial
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
        {/* Plan Comparison Table */}
        {!fromCreateWorkspace && mode !== 'trial' && (
          <Box sx={{ marginY: 4 }}>
            <Divider
              sx={{
                margin: '50px auto 0 auto',
                borderColor: '#e0e0e0',
                borderWidth: '1px',
                width: '90%',

                position: 'relative',
                '::before, ::after': {
                  borderColor: '#e0e0e0'
                },
                color: '#555',
                fontWeight: 500
              }}
            >
              <Button
                sx={{
                  paddingY: 2,
                  paddingX: 4,
                  border: '1px solid #E4E4E5',
                  borderRadius: '12px',
                  background: '#FFFFFF',
                  color: '#101010',
                  textTransform: 'none',
                  display: 'flex',
                  justifyContent: 'center',
                  margin: 'auto'
                }}
              >
                View Plan Comparison
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <ExpandMoreIcon />
                </Box>
              </Button>
            </Divider>
          </Box>
        )}
        {!fromCreateWorkspace && mode !== 'trial' &&
          sections.map((section, index) => (
            <Accordion key={index} sx={{ marginBottom: 2 }}>
              {/* Accordion Summary */}
              <AccordionSummary
                expandIcon={
                  <Box
                    sx={{
                      border: '1px solid #ddd',
                      borderRadius: '20%',
                      padding: '2px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <ExpandMoreIcon />
                  </Box>
                }
                sx={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  alignItems: 'center'
                }}
              >
                <Typography sx={{ fontWeight: 700, fontSize: '20px', marginLeft: 2 }}>
                  {section.title}
                </Typography>
              </AccordionSummary>

              {/* Accordion Details */}
              <AccordionDetails>
                <Box>
                  {
                    <Chip
                      label='Best Value'
                      color='primary'
                      size='small'
                      sx={{
                        position: 'absolute',
                        display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' }, // Display on medium screens and larger
                        top: { md: 55 },
                        right: { md: 80 },
                        transform: 'rotate(-5deg)',
                        backgroundColor: `${theme.palette.primary.main}`,
                        color: `${theme.palette.primary.contrastText}`,
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                        paddingX: 2,
                        paddingY: 2,
                        borderRadius: '6px',
                        zIndex: 1000,
                        border: `2.5px solid ${theme.palette.primary.contrastText}`,
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: 600,
                        fontSize: '14px',
                        textAlign: 'center',
                        lineHeight: 1.2
                      }}
                    />
                  }
                  <TableContainer
                    component={Paper}
                    elevation={0}
                    sx={{ border: '1px solid #D7D9E6' }}
                  >
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell />
                          {plans.map((plan, index) => (
                            <TableCell
                              key={index}
                              align='center'
                              sx={{
                                backgroundColor: plan.bestValue
                                  ? `${theme.palette.primary.main}`
                                  : '#E7F0FF',
                                borderBottom: 'none',
                                borderLeft: index === 0 ? '1px solid #e0e0e0' : '1px solid #e0e0e0',
                                position: 'relative',
                                padding: '16px',
                                margin: '0 8px'
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: 1,
                                  alignItems: 'center'
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: plan.bestValue
                                      ? `${theme.palette.primary.contrastText}`
                                      : `${theme.palette.primary.main}`,
                                    fontSize: '23px',
                                    fontWeight: 700
                                  }}
                                >
                                  {plan.discountPrice && (
                                    <Typography
                                      component='span'
                                      sx={{
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        color: plan.bestValue
                                          ? `${theme.palette.primary.contrastText}`
                                          : '#8181B0',
                                        textDecoration: 'line-through',
                                        marginRight: 1
                                      }}
                                    >
                                      {plan.price}
                                    </Typography>
                                  )}
                                  {plan.discountPrice || plan.price}
                                  <span
                                    style={{
                                      color: plan.bestValue
                                        ? `${theme.palette.primary.contrastText}`
                                        : '#8181B0',
                                      fontSize: '14px',
                                      fontWeight: 600
                                    }}
                                  >
                                    /month
                                  </span>
                                </Typography>
                                <Typography
                                  sx={{
                                    color: plan.bestValue
                                      ? `${theme.palette.primary.contrastText}`
                                      : '#8181B0',
                                    fontSize: '13px',
                                    fontWeight: 500
                                  }}
                                >
                                  {plan.description}
                                </Typography>
                              </Box>
                            </TableCell>
                          ))}
                        </TableRow>

                        {section.Points &&
                          section.Points.map((feature, featureIndex) => (
                            <TableRow key={`${featureIndex}`}>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  backgroundColor: '#fff',
                                  width: '30%',
                                  borderBottom: '1px solid #e0e0e0'
                                }}
                              >
                                {feature}
                              </TableCell>
                              {plans.map((plan, planIndex) => (
                                <TableCell
                                  key={planIndex}
                                  align='center'
                                  sx={{
                                    backgroundColor: '#fff',
                                    borderBottom:
                                      featureIndex === section.Points.length - 1
                                        ? 'none'
                                        : '1px solid #e0e0e0'
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                    }}
                                  >
                                    {(() => {
                                      const featureValue =
                                        section.featuresValue[planIndex][featureIndex]
                                      if (featureValue === '✔') {
                                        return (
                                          <Chip
                                            small
                                            label='✔'
                                            sx={{
                                              borderRadius: '50%',
                                              color: `${theme.palette.primary.contrastText}`,
                                              bgcolor: '#00AA38',
                                              fontSize: '12px',
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center'
                                            }}
                                          />
                                        )
                                      } else if (featureValue === '✖') {
                                        return (
                                          <Chip
                                            label='✖'
                                            sx={{
                                              borderRadius: '50%',
                                              fontSize: '14px',
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center'
                                            }}
                                          />
                                        )
                                      } else if (featureValue === 'Unlimited') {
                                        return (
                                          <Box
                                            sx={{
                                              padding: '4px 8px',
                                              border: '1px solid #E7F0FF',
                                              borderRadius: '8px',
                                              backgroundColor: '#E7F0FF',
                                              fontWeight: 600,
                                              fontSize: '13px',
                                              textAlign: 'center',
                                              color: `${theme.palette.primary.main}`
                                            }}
                                          >
                                            <Typography variant='body2'>{featureValue}</Typography>
                                          </Box>
                                        )
                                      } else {
                                        return (
                                          <Box
                                            sx={{
                                              fontWeight: 500,
                                              fontSize: '13px',
                                              color: 'black'
                                            }}
                                          >
                                            <Typography variant='body2'>{featureValue}</Typography>
                                          </Box>
                                        )
                                      }
                                    })()}
                                  </Box>
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}

                        <TableRow>
                          <TableCell />
                          {plans.map((plan, index) => (
                            <TableCell
                              key={index}
                              align='center'
                              sx={{
                                backgroundColor: '#fff',
                                borderBottom: 'none',
                                paddingX: '4px',
                                paddingY: '4px'
                              }}
                            >
                              <Button
                                sx={{
                                  borderRadius: '6px',
                                  width: '100%',
                                  padding: '0.8rem 1.5rem',
                                  fontSize: '14px',
                                  fontWeight: 600,
                                  backgroundColor: !plan.highlighted
                                    ? `${theme.palette.primary.main}`
                                    : '#F2F4F6',
                                  border: !plan.highlighted
                                    ? `1px solid ${theme.palette.primary.main}`
                                    : ' 1px solid #D7D9E6',
                                  color: !plan.highlighted
                                    ? `${theme.palette.primary.contrastText}`
                                    : '#92979C',
                                  py: 1.5,
                                  '&:hover': {
                                    backgroundColor: !plan.highlighted
                                      ? `${theme.palette.primary.main}`
                                      : '#F2F4F6'
                                  }
                                }}
                                onClick={() => plan.planId && handleUpdatePlanClick(plan.planId)}
                                disabled={plan.disable}
                              >
                                {plan.buttonLabel}
                              </Button>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Welcome to Success.ai</DialogTitle>
          <DialogContent>
            <Box sx={{ mt: 2 }}>
              <TextField
                autoFocus
                margin='dense'
                id='firstName'
                label='First Name'
                type='text'
                fullWidth
                variant='outlined'
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextField
                margin='dense'
                id='lastName'
                label='Last Name'
                type='text'
                fullWidth
                variant='outlined'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              <TextField
                margin='dense'
                id='companyName'
                label='Company Name'
                type='text'
                fullWidth
                variant='outlined'
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color='primary'>
              Cancel
            </Button>
            <Button onClick={handleSubmit} color='primary' variant='contained'>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      {fromCreateWorkspace && timelineButtons.length > 1 && (
        <Box sx={{ display: 'flex', mt: 4 }}>
          <Button
            sx={{
              borderColor: '#dc3545',
              color: '#dc3545',
              textTransform: 'none',
              fontSize: '16px',
              fontWeight: 'bold',
              '&:hover': {
                borderColor: '#c82333',
                color: 'white',
                backgroundColor: '#dc3545'
              }
            }}
            variant='outlined'
            onClick={() => {
              setOpenRemoveWorkspaceDialog(true)
            }}
          >
            Delete workspace
          </Button>
        </Box>
      )}
      <RemoveWorkSpaceDialog
        open={openRemoveWorkspaceDialog}
        onClose={() => {
          setOpenRemoveWorkspaceDialog(false)
        }}
        onClick={handleRemoveWorkspaceClick}
        isLoading={isDeletingWorkspace}
      />
    </>
  )
}

export default BilingPage
