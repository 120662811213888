import { useEffect } from 'react'
import moment from 'moment'
import { useGetCurrentPlanQuery } from 'src/services/billing-service.js'
import { useGetMeQuery } from 'src/services/user-service'

const SubscriptionChecker = ({ navigate }) => {
  const { data: currentPlan } = useGetCurrentPlanQuery()
  const { data: user } = useGetMeQuery()

  useEffect(() => {
    if (!currentPlan) return

    const currentDate = moment()
    const freeTrialExpiresAt = moment(currentPlan.freeTrialExpiresAt)

    const haveFreeTrial = freeTrialExpiresAt.isAfter(currentDate)
    if (haveFreeTrial) return

    const expiresSubscription = moment(
      currentPlan?.subscription?.sendingWarmup?.expiresAt ||
      currentPlan?.subscription?.leads?.expiresAt
    )
    const haveSubscription =
      currentPlan?.subscription?.sendingWarmup?.active ||
      currentPlan?.subscription?.leads?.active ||
      (user?.assignedPlan && user?.assignedPlan.length > 0 && !user?.isAppSumoRefund)

    const isWeeklyPlan =
      currentPlan?.subscription?.sendingWarmup?.planType === 'weekly' &&
      expiresSubscription.isAfter(currentDate)

    if (haveSubscription && isWeeklyPlan) return

    if (expiresSubscription.isBefore(currentDate)) {
      navigate('/settingsUpdated/expired')
    }
  }, [
    currentPlan?._id,
    user?.assignedPlan?.length,
    navigate
  ])

  return null
}

export default SubscriptionChecker
