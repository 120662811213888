import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import {
  Box,
  Typography,
  TextField,
  Chip,
  Stack
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { FundingFilterIcon } from 'src/icons/fundingFilterIcon'
import { useTheme } from '@emotion/react'
import { LockedFilter, FilterDropdown } from '../filterComponents'

const FundingFilter = ({ handleFilterChange, clear, setClear, isLocked, plan, isOpen, onToggle }) => {
  const theme = useTheme()
  const [fundingRange, setFundingRange] = useState({ min: '', max: '' })
  const [dateRange, setDateRange] = useState({ start: '', end: '' })
  const ref = useRef({ internalStateChange: false })

  // Redux selectors
  const fundingData = useSelector((state) => state.search.filter.funding_range)
  const fundingDate = useSelector((state) => state.search.filter.funding_date)

  const handleFundingChange = useCallback((field, value) => {
    const newRange = { ...fundingRange, [field]: value }
    setFundingRange(newRange)

    // Only update Redux if both min and max are provided
    if (newRange.min && newRange.max) {
      handleFilterChange('funding_range', [`${newRange.min}, ${newRange.max}`])
      ref.current.internalStateChange = true
    }
  }, [fundingRange, handleFilterChange])

  const handleDateChange = useCallback((field, value) => {
    const newRange = { ...dateRange, [field]: value }
    setDateRange(newRange)

    // Only update Redux if both start and end dates are provided
    if (newRange.start && newRange.end) {
      handleFilterChange('funding_date', [`${newRange.start}, ${newRange.end}`])
      ref.current.internalStateChange = true
    }
  }, [dateRange, handleFilterChange])

  const clearFundingRange = useCallback((e) => {
    e?.stopPropagation()
    setFundingRange({ min: '', max: '' })
    handleFilterChange('funding_range', [])
    ref.current.internalStateChange = true
  }, [handleFilterChange])

  const clearDateRange = useCallback((e) => {
    e?.stopPropagation()
    setDateRange({ start: '', end: '' })
    handleFilterChange('funding_date', [])
    ref.current.internalStateChange = true
  }, [handleFilterChange])

  const clearAll = useCallback((e) => {
    e?.stopPropagation()
    setFundingRange({ min: '', max: '' })
    setDateRange({ start: '', end: '' })
    handleFilterChange('funding_range', [])
    handleFilterChange('funding_date', [])
    ref.current.internalStateChange = true
  }, [handleFilterChange])

  useEffect(() => {
    if (clear) {
      clearAll()
      setClear(false)
    }
  }, [clear, setClear, clearAll])

  useEffect(() => {
    if (ref.current.internalStateChange) {
      ref.current.internalStateChange = false
      return
    }

    // Update local state from Redux
    if (fundingData?.[0]) {
      const [min, max] = fundingData[0].split(',').map(item => item.trim())
      setFundingRange({ min, max })
    } else {
      setFundingRange({ min: '', max: '' })
    }

    if (fundingDate?.[0]) {
      const [start, end] = fundingDate[0].split(',').map(item => item.trim())
      setDateRange({ start, end })
    } else {
      setDateRange({ start: '', end: '' })
    }
  }, [fundingData, fundingDate])

  const getFilterCount = () => {
    let count = 0
    if (fundingRange.min && fundingRange.max) count++
    if (dateRange.start && dateRange.end) count++
    return count
  }

  const preview = (
    <>
      {(fundingRange.min && fundingRange.max) && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
          <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
          <Typography variant='body2' sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600, paddingLeft: 1 }}>
            Funding amount
          </Typography>
          <Chip
            label={`${fundingRange.min} - ${fundingRange.max}`}
            onDelete={clearFundingRange}
            deleteIcon={<Close fontSize='small' />}
            size='small'
            sx={{
              background: '#E6F0FF',
              fontWeight: 500,
              fontSize: '14px',
              borderRadius: '43px',
              border: '1px solid #3F4FF836',
              color: 'black',
              '& .MuiChip-deleteIcon': {
                color: 'black'
              }
            }}
          />
        </Box>
      )}
      {(dateRange.start && dateRange.end) && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
          <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
          <Typography variant='body2' sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600, paddingLeft: 1 }}>
            Funding date
          </Typography>
          <Chip
            label={`${dateRange.start} - ${dateRange.end}`}
            onDelete={clearDateRange}
            deleteIcon={<Close fontSize='small' />}
            size='small'
            sx={{
              background: '#E6F0FF',
              fontWeight: 500,
              fontSize: '14px',
              borderRadius: '43px',
              border: '1px solid #3F4FF836',
              color: 'black',
              '& .MuiChip-deleteIcon': {
                color: 'black'
              }
            }}
          />
        </Box>
      )}
    </>
  )

  if (isLocked) {
    return (
      <LockedFilter
        title='Funding'
        icon={<FundingFilterIcon sx={{ stroke: `${theme.palette.primary.main}` }} />}
        plan={plan}
      />
    )
  }

  return (
    <FilterDropdown
      title='Funding'
      filterCount={getFilterCount()}
      clearAll={clearAll}
      icon={<FundingFilterIcon sx={{ stroke: `${theme.palette.primary.main}` }} />}
      preview={preview}
      isOpen={isOpen}
      onToggle={onToggle}
    >
      <Stack direction='column' gap={2} mt={2}>
        {/* Funding Amount Section */}
        <Box>
          <Typography variant='subtitle1' sx={{ mb: 1, fontSize: '14px', fontWeight: 700 }}>
            Funding amount
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 1 }}>
            <TextField
              placeholder='Min'
              value={fundingRange.min}
              onChange={(e) => handleFundingChange('min', e.target.value)}
              type='number'
              size='small'
              fullWidth
            />
            <Typography>-</Typography>
            <TextField
              placeholder='Max'
              value={fundingRange.max}
              onChange={(e) => handleFundingChange('max', e.target.value)}
              type='number'
              size='small'
              fullWidth
            />
          </Box>
          {(fundingRange.min || fundingRange.max) && (
            <Typography
              onClick={clearFundingRange}
              sx={{
                color: 'primary.main',
                cursor: 'pointer',
                fontSize: '13px',
                fontWeight: 700,
                textAlign: 'right'
              }}
            >
              Clear
            </Typography>
          )}
        </Box>

        {/* Funding Date Section */}
        <Box>
          <Typography variant='subtitle1' sx={{ mb: 1, fontSize: '14px', fontWeight: 700 }}>
            Funding date
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 1 }}>
            <TextField
              type='date'
              value={dateRange.start}
              onChange={(e) => handleDateChange('start', e.target.value)}
              size='small'
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
            <Typography>-</Typography>
            <TextField
              type='date'
              value={dateRange.end}
              onChange={(e) => handleDateChange('end', e.target.value)}
              size='small'
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          {(dateRange.start || dateRange.end) && (
            <Typography
              onClick={clearDateRange}
              sx={{
                color: 'primary.main',
                cursor: 'pointer',
                fontSize: '13px',
                fontWeight: 700,
                textAlign: 'right'
              }}
            >
              Clear
            </Typography>
          )}
        </Box>
      </Stack>
    </FilterDropdown>
  )
}

export default FundingFilter
