import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { config } from 'src/config'
import { useGetMeQuery } from 'src/services/user-service'
const TrialConfirmation = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const encodedEmail = searchParams.get('token')
  const priceId = localStorage.getItem('priceId')
  const { data: user } = useGetMeQuery()
  useEffect(() => {
    if (user?._id) {
      if (priceId && priceId !== config?.FREE_USER_PRICE) {
        navigate(`/settingsUpdated?priceID=${priceId}&email_address=${user?.email}&user_id=${user?._id}`)
        localStorage.removeItem('priceId');
      }
      else if (priceId === config?.FREE_USER_PRICE) {
        navigate(`/onboardinghub?email_address=${user?.email}&user_id=${user?._id}`);
      }
      else {
        navigate(`/onboardinghub?email_address=${user?.email}&user_id=${user?._id}`)
      }
    }
  }, [navigate, encodedEmail, window.location, priceId, user])

  return (
    <></>
  )
}

export default TrialConfirmation