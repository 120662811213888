import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Stack, Typography, RadioGroup, Tabs, Tab, Tooltip, IconButton, TextField, Divider } from '@mui/material'
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material'
import { ComboBox, Tag, FilterDropdown, FilterAccordion } from '../filterComponents'
import { isEqual } from 'lodash'
import { Domain } from 'src/icons/domain'
import { useTheme } from '@emotion/react'

export default function DomainFilter ({ onChange, type, isOpen, onToggle }) {
  const theme = useTheme()
  const [selectedTab, setSelectedTab] = useState('classique')
  const [selectedMethod, setSelectedMethod] = useState('')
  const [includedDomains, setIncludedDomains] = useState([])
  const [excludedDomains, setExcludedDomains] = useState([])
  const [manualInclude, setManualInclude] = useState('')
  const [manualExclude, setManualExclude] = useState('')
  const ref = useRef({ internalStateChange: false })

  const domainData = useSelector((state) => state.search[type === 'company' ? 'companyFilter' : 'filter'].link)
  const excludeDomainData = useSelector((state) => state.search[type === 'company' ? 'companyFilter' : 'filter'].exclude_link)
  const countIncluded = manualInclude.split('\n').filter(Boolean).length
  const countExcluded = manualExclude.split('\n').filter(Boolean).length

  const handleIncludedDomain = useCallback((newValue, updateRedux = true) => {
    setIncludedDomains(newValue)
    if (updateRedux) onChange('link', newValue)
    ref.current.internalStateChange = true
  }, [onChange])

  const handleExcludedDomains = useCallback((newValue, updateRedux = true) => {
    setExcludedDomains(newValue)
    if (updateRedux) onChange('exclude_link', newValue)
    ref.current.internalStateChange = true
  }, [onChange])

  const clearAll = useCallback(() => {
    setSelectedMethod('')
    setSelectedTab('classique')
    handleIncludedDomain([])
    handleExcludedDomains([])
    setManualInclude('')
    setManualExclude('')
  }, [handleIncludedDomain, handleExcludedDomains])

  const handleRadioBtn = useCallback((e) => {
    setSelectedMethod(e.target.value)
    handleIncludedDomain([])
    handleExcludedDomains([])
  }, [handleIncludedDomain, handleExcludedDomains])

  useEffect(() => {
    if (ref.current.internalStateChange) {
      ref.current.internalStateChange = false
      return
    }
    setIncludedDomains(domainData ?? [])
    setExcludedDomains(excludeDomainData ?? [])
    setSelectedMethod(domainData ? 'isAnyOf' : '')
  }, [domainData, excludeDomainData])

  const cleanDomain = (domain) => {
    return domain
      .trim()
      .toLowerCase()
      .replace(/^https?:\/\//, '') // Remove http:// or https://
      .replace(/^www\./, '') // Remove www.
      .split('/')[0] // Remove everything after first slash
      .split('?')[0] // Remove query parameters
      .split('#')[0] // Remove hash
  }

  const cleanDomainList = (domains) => {
    return domains
      .filter(Boolean)
      .map(cleanDomain)
      .filter((domain) => domain.length)
  }

  useEffect(() => {
    if (selectedTab === 'manual') {
      const includeList = cleanDomainList(manualInclude.split('\n'))
      const excludeList = cleanDomainList(manualExclude.split('\n'))

      if (includeList.length) {
        handleIncludedDomain(includeList)
      }

      if (excludeList.length) {
        handleExcludedDomains(excludeList)
      }
    }
  }, [manualInclude, manualExclude, selectedTab, handleIncludedDomain, handleExcludedDomains])

  const preview = (
    <>
      {((selectedMethod && selectedMethod !== 'isAnyOf') ||
        includedDomains.length > 0 ||
        excludedDomains.length > 0) &&
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
            {includedDomains.length > 0 &&
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
                <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
                <Typography variant='body2' sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600, paddingLeft: 1 }}>Include</Typography>
                {includedDomains.map((lang, index) => <Tag key={index} label={lang} onDelete={() => handleIncludedDomain(includedDomains.filter(l => !isEqual(l, lang)))} />)}
              </Box>}
            {excludedDomains.length > 0 &&
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
                <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
                <Typography variant='body2' sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600, paddingLeft: 1 }}>Exclude</Typography>
                {excludedDomains.map((lang, index) => <Tag key={index} label={lang} onDelete={() => handleExcludedDomains(excludedDomains.filter(l => !isEqual(l, lang)))} isExclude />)}
              </Box>}
            {selectedMethod === 'isKnown' &&
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
                <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
                <Typography variant='body2' sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600, paddingLeft: 1 }}>Is known</Typography>
              </Box>}
            {selectedMethod === 'isUnKnown' &&
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
                <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
                <Typography variant='body2' sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600, paddingLeft: 1 }}>Is unknown</Typography>
              </Box>}
          </Box>}
    </>
  )

  return (
    <FilterDropdown
      title='Company Name / Domain'
      filterCount={includedDomains.length + excludedDomains.length}
      clearAll={clearAll}
      icon={<Domain sx={{ stroke: `${theme.palette.primary.main}` }} />}
      preview={preview}
      isOpen={isOpen}
      onToggle={onToggle}
    >
      <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)} variant='fullWidth' centered>
        <Tab label='Search' value='classique' sx={{ fontSize: '14px', fontWeight: 700 }} />
        <Tab label='Import' value='manual' sx={{ fontSize: '14px', fontWeight: 700 }} />
      </Tabs>

      {selectedTab === 'classique' &&
        <Stack direction='column' gap={2} mt={2}>
          <RadioGroup
            value={selectedMethod}
            onChange={handleRadioBtn}
          >
            <Stack direction='column' gap={1}>
              {/* <RadioBtnCollapse selected={selectedMethod} value='isAnyOf' label='Is any of'> */}
              <Typography sx={{ fontWeight: 700, fontSize: '14px' }}>Include Companies</Typography>
              <ComboBox
                freeSolo
                placeholder='Search companies'
                  // options={companies}
                options={[]}
                value={includedDomains}
                onChange={(e, newValue) => { handleIncludedDomain(newValue) }}
              />
              <FilterAccordion label='Exclude companies'>
                <ComboBox
                  freeSolo
                  placeholder='Search companies'
                    // options={companies}
                  options={[]}
                  value={excludedDomains}
                  onChange={(e, newValue) => { handleExcludedDomains(newValue) }}
                  isExclude
                />
              </FilterAccordion>
              {/* </RadioBtnCollapse> */}
              {/* <RadioBtnCollapse selected={selectedMethod} value='isKnown' label='Is known' />
              <RadioBtnCollapse selected={selectedMethod} value='isUnKnown' label='Is unknown' /> */}
            </Stack>
          </RadioGroup>
        </Stack>}

      {selectedTab === 'manual' && (
        <>
          <ManualImport count={countIncluded} value={manualInclude} setValue={setManualInclude} heading='Include list of companies' />
          <Divider sx={{ marginY: 2 }} />
          <ManualImport count={countExcluded} value={manualExclude} setValue={setManualExclude} heading='Exclude list of companies' />
        </>
      )}
    </FilterDropdown>
  )
}

function ManualImport ({ count, value, setValue, heading }) {
  return (
    <Box sx={{ marginBottom: 2, mx: '10px' }}>
      <Box sx={{ display: 'flex', alignItem: 'center', justifyContent: 'space-between' }}>
        <Box>
          <Typography
            variant='subtitle1'
            sx={{ display: 'flex', alignItems: 'center', fontWeight: 500, fontSize: '14px' }}
          >
            {heading}
            <Tooltip
              title='Paste the relevant companies directly into the input field. Separate each entry with a line break.'
              arrow
            >
              <IconButton size='small' sx={{ marginLeft: 0.5 }}>
                <InfoOutlinedIcon fontSize='16px' />
              </IconButton>
            </Tooltip>
          </Typography>
        </Box>
        {count
          ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant='body2'
                color='primary'
                sx={{ cursor: 'pointer', marginRight: 0.5 }}
                onClick={() => setValue('')}
              >
                Clear ({count})
              </Typography>
            </Box>
            )
          : null}
      </Box>
      <TextField
        multiline
        minRows={4}
        placeholder='e.g.\nhttp://google.com\ncisco.com\ncontact@microsoft.com\nwww.ebay.com'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        fullWidth
        sx={{ marginTop: 1, border: ' 1px solid #DCDEE0', borderRadius: '8px' }}
      />
      <Box
        sx={{
          textAlign: 'right',
          color: 'text.secondary',
          fontSize: '0.875rem',
          marginTop: 0.5
        }}
      >
        {count}
      </Box>
    </Box>
  )
}
