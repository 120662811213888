import {
  Box,
  Link,
  Typography,
  useTheme,
  alpha,
  useMediaQuery
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Logo } from 'src/components/logo'
import ResetPasswordImage from '../../assets/auth/reset-password.png'
import { useCheckDomainQuery } from 'src/services/theme-service'
import { ForgetPasswordForm } from 'src/components/auth/forgetPasswordForm'

const ForgetPassword = () => {
  let currentDomain
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const isWhiteLabel = searchParams.get('isWhiteLabel')
  // currentDomain = searchParams.get('domain') //for localhost
  const [whiteLabelTheme, setWhiteLabelTheme] = useState(null)

  if (typeof window !== 'undefined') {
    currentDomain = window.location.hostname
  }

  const { data: whiteLabelUser } = useCheckDomainQuery({ domain: currentDomain })

  useEffect(() => {
    setWhiteLabelTheme(whiteLabelUser?.data?.theme)
  }, [whiteLabelUser])

  console.log('currentDomain Forget', currentDomain) // for dubug

  const loginRedirect = () => {
    navigate(`${isWhiteLabel ? '/w-login' : '/login'}`)
  }

  const theme = useTheme()
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',

        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F2F4F6'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          width: isMdUp || !isWhiteLabel ? '500px' : '100%',
          height: '100%',
          p: 4,
          backgroundColor: theme.palette.background.default
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            width: '160px',
            height: '30px'
          }}
        >
          {whiteLabelTheme?.logo ? <img src={whiteLabelTheme?.logo} style={{ height: '36px', width: 'auto' }} /> : <Logo />}
        </Box>
        <ForgetPasswordForm
          whiteLabelTheme={whiteLabelTheme}
          onCancel={loginRedirect}
        />
        <Box>
          <Typography
            sx={{
              mt: 3,
              // fontFamily: "Noto Serif Vithkuqi, serif",
              color: `${theme.palette.primary.primaryText}`,
              fontSize: '13px',
              fontWeight: 500,
              lineHeight: '20px'
            }}
          >
            Don't have an account?
            <Link
              href={`${isWhiteLabel ? '/w-register' : '/register'}`}
              sx={{
                textDecoration: 'none',
                '&:hover': {
                  color: whiteLabelTheme?.colors ? whiteLabelTheme?.colors?.dark : theme.palette.primary.dark
                },
                color: whiteLabelTheme?.colors ? whiteLabelTheme?.colors?.main : theme.palette.primary.main,
                ml: 0.3,
                fontSize: '13px',
                fontWeight: 700,
                lineHeight: '20px'
              }}
            >
              Sign Up
            </Link>
          </Typography>
        </Box>
      </Box>
      {isMdUp && !isWhiteLabel && (
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'calc(100vw - 500px)',
            height: '100%',
            overflow: 'hidden',
            backgroundColor: theme.palette.primary.main
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: -300,
              left: -150,
              width: 600,
              height: 800,

              // backgroundColor: alpha(theme.palette.background.default, 0.2),
              background: `linear-gradient(to bottom,${alpha(
                theme.palette.background.paper,
                0.2
              )},${alpha(theme.palette.background.paper, 0.1)},transparent)`,
              rotate: '-45deg'
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: -300,
              right: -150,
              width: 600,
              height: 800,

              // backgroundColor: alpha(theme.palette.background.default, 0.2),
              background: `linear-gradient(to top,${alpha(
                theme.palette.background.paper,
                0.2
              )},${alpha(theme.palette.background.paper, 0.1)},transparent)`,
              rotate: '-45deg'
            }}
          />
          <Box sx={{ zIndex: 99 }}>
            {' '}
            <img
              src={ResetPasswordImage}
              alt='login page image'
              style={{ transform: 'scale(0.8)' }}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default ForgetPassword
