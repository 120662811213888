import PersonIcon from '@mui/icons-material/Person'
import InsightsIcon from '@mui/icons-material/Insights'
import EditIcon from '@mui/icons-material/Edit'
import VerifiedIcon from '@mui/icons-material/Verified'
import MailIcon from '@mui/icons-material/Mail'
import EmailIcon from '@mui/icons-material/Email'

export const data = [
  {
    title: 'Active Contacts',
    usage: 1222,
    total: 500000,
    progress: (1222 / 500000) * 100,
    icon: PersonIcon
  },
  {
    title: 'Lead Finder Usage Overview',
    usage: 988999,
    total: 1000000,
    progress: (988999 / 1000000) * 100,
    icon: InsightsIcon
  },
  {
    title: 'AI Writer Credits',
    usage: 30000,
    total: 60000,
    progress: (30000 / 60000) * 100,
    icon: EditIcon // Add related icon
  },
  {
    title: 'Email Verification Credits',
    usage: 10,
    total: 500,
    progress: (10 / 500) * 100,
    icon: VerifiedIcon // Add related icon
  },
  {
    title: 'Email Export Credits (per day)',
    usage: 100000,
    total: 100000,
    progress: 100,
    icon: MailIcon // Add related icon
  },
  {
    title: 'Emails per Month',
    usage: 1000000,
    total: 2000000,
    progress: (1000000 / 2000000) * 100,
    icon: EmailIcon // Add related icon
    // image: "./SettingsImages/Frame6.png",
  }
]

export const addOns = [
  {
    id: 'dfyAccounts',
    title: 'DFY (Done-for-you) Accounts Setup',
    description: 'Start instantly with pre-configured accounts and save time.',
    price: '$3 / Gsuite Account + $12 / Domain',
    buttonText: 'Buy',
    comingSoon: false,
    image: '/assets/SettingsImages/Frame1.png'
  },
  {
    id: 'leadFinder',
    title: 'Lead Finder API',
    description: 'Enhance lead generation with Lead Finder API.',
    price: 'contact sales',
    buttonText: 'Buy',
    comingSoon: false,
    image: '/assets/SettingsImages/Frame 5.png'
  },
  {
    id: 'Workspace',
    title: 'Workspace',
    description: 'Optimize your workflow with extra workspace accounts',
    price: 'price depends on the workspace plan',
    buttonText: 'Buy',
    comingSoon: false,
    image: '/assets/SettingsImages/Frame 5.png'
  },
  {
    id: 'additionalLinkedin',
    title: 'Connecting additional Linkedin accounts',
    description: 'Seamlessly add extra LinkedIn accounts with ease.',
    price: '$20 / Account',
    buttonText: 'Buy',
    comingSoon: false,
    image: '/assets/SettingsImages/Frame2.png'
  },
  {
    id: 'additionalWhatsapp',
    title: 'Connecting additional Whatsapp accounts',
    description: 'Quickly add and manage multiple WhatsApp accounts.',
    price: '',
    buttonText: 'Coming Soon',
    comingSoon: true,
    image: '/assets/SettingsImages/Frame3.png'
  },
  {
    id: 'sms',
    title: 'SMS',
    description: 'Effortlessly send messages with additional SMS capacity.',
    price: '',
    buttonText: 'Coming Soon',
    comingSoon: true,
    image: '/assets/SettingsImages/Frame4.png'
  },
  {
    id: 'websiteVisitor',
    title: 'Website Visitor Identification',
    description: 'Identify and track website visitors effortlessly with smart insights.',
    price: '',
    buttonText: 'Coming Soon',
    comingSoon: true,
    image: '/assets/SettingsImages/Frame 5.png'
  }
]
