import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { allowedDomains } from 'src/config'
import { useLazyCheckDomainQuery } from 'src/services/theme-service'

const DomainChecker = () => {
  let currentDomain
  const navigate = useNavigate()

  // for localhost
  // const [searchParams] = useSearchParams();
  // currentDomain = searchParams.get('domain')

  if (typeof window !== 'undefined') {
    currentDomain = window.location.hostname
  }

  const [checkDomain, { isError, isSuccess }] = useLazyCheckDomainQuery()

  useEffect(() => {
    const checkDomainHandler = async () => {
      try {
        if (allowedDomains.includes(currentDomain)) {
          navigate('/login')
        } else {
          checkDomain({ domain: currentDomain })
          if (isError) {
            navigate('/404')
          }
          if (isSuccess) {
            navigate('/w-login')
          }
        }
      } catch (error) {
        console.error('Error checking domain:', error)
      }
    }

    checkDomainHandler()
  }, [allowedDomains, navigate, isError, isSuccess, currentDomain])

  return null
}

export default DomainChecker
