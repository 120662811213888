import 'simplebar-react/dist/simplebar.min.css'
import { routes } from './routes'
import { Toaster } from 'react-hot-toast'
import { useEffect } from 'react'
import { config } from './config'
import Maintenance from './Maintenance'
import { useSelector } from 'react-redux'
import { MsalProvider } from '@azure/msal-react'
import { Helmet } from 'react-helmet-async'
import { ThemeInitializer } from './theme/theme-initializer'
import { parseScripts } from './utils/util'

export const App = ({ instance }) => {
  const user = useSelector((state) => state.user)
  const { theme, seo: seoData } = useSelector((state) => state.partnerDetails)

  const urlParams = new URLSearchParams(window.location.search)
  const authToken = urlParams.get('auth_token')

  if (typeof window !== 'undefined' && authToken) {
    window.localStorage.setItem('auth_token', authToken)
  }

  const maintenanceModeString = process.env.REACT_APP_MAINTENANCE_MODE
  const maintenanceMode = maintenanceModeString === 'true'

  useEffect(() => {
    if (!(maintenanceMode || user.isPartner || user.partnerAccountId)) {
      window.intercomSettings = {
        app_id: config.INTERCOM_APP_ID
      }
    }

    if (window.clarity) {
      window.clarity('set', 'userEmail', user?.email)
    } else {
      const clarityCheck = setInterval(() => {
        if (window.clarity) {
          window.clarity('set', 'userEmail', user?.email)
          clearInterval(clarityCheck)
        }
      }, 1000)
    }
  }, [user?.email])
  if (maintenanceMode) {
    return <Maintenance lead={false} />
  }
  return (
    <MsalProvider instance={instance}>
      <Helmet>
        <title>{seoData?.title}</title>
        <meta name='description' content={seoData?.description} />
        <meta property='og:title' content={seoData?.title} />
        <meta property='og:description' content={seoData?.description} />
        {/* Favicon  */}
        <link rel='apple-touch-icon' sizes='180x180' href={theme?.logo || '/apple-touch-icon.png'} />
        <link rel='icon' href={theme?.logo || '/favicon.ico'} />
        <link rel='icon' type='image/png' sizes='32x32' href={theme?.logo || '/favicon-32x32.png'} />
        <link rel='icon' type='image/png' sizes='16x16' href={theme?.logo || '/favicon-16x16.png'} />
        {parseScripts(theme?.scriptContent)?.map((script, index) => (
          <script key={index} {...script} />
        ))}
      </Helmet>
      <ThemeInitializer>

        {routes}
        <Toaster
          toastOptions={{
            duration: 2000,
            success: {
              style: {
                background: theme.colors?.main,
                color: theme.colors?.contrastText
              },
              iconTheme: {
                primary: theme.colors?.contrastText,
                secondary: theme.colors?.main
              }
            },
            error: {
              style: {
                background: 'red',
                color: theme.colors?.contrastText
              },
              iconTheme: {
                primary: theme.colors?.contrastText,
                secondary: 'red'
              }
            },
            loading: {
              style: {
                background: theme.colors?.contrastText,
                color: theme.colors?.main
              },
              iconTheme: {
                primary: theme.colors?.main,
                secondary: theme.colors?.contrastText
              }
            }
          }}
        />
      </ThemeInitializer>
    </MsalProvider>
  )
}
