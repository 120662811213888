import { Box, Button, Container, Typography, useTheme } from '@mui/material'

const NoPlanDataFound = ({ handlePlanAdd }) => {
  const theme = useTheme()
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth='md'
          sx={{
            px: 5,
            py: 14,
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Box
            sx={{
              '& img': {
                maxWidth: '100%'
              }
            }}
          >
            <img src='/assets/illustration-not-found.svg' />
          </Box>
          <Typography
            align='center'
            sx={{ my: 2 }}
            variant='h3'
            color='primary'
          >
            Nothing here!
          </Typography>
          <Typography
            align='center'
            color='text.secondary'
            variant='body2'
          >
            No Custom Plans Available!
          </Typography>
          <Button
            id='Add Plan custom'
            sx={{
              mt: 2,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
                boxShadow: 10
              },
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '18px',
              color: `${theme.palette.primary.contrastText}`,
              backgroundColor: `${theme.palette.primary.main}`,
              borderRadius: '8px',
              p: 1.5
            }}
            variant='outlined'
            size='medium'
            onClick={handlePlanAdd}
          >
            Add Plan
          </Button>
        </Container>
      </Box>
    </>
  )
}

export default NoPlanDataFound
