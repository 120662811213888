import React, { useState } from 'react'
import { Box, Button, Typography, Modal, TextField, useTheme, Grid } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import { useUpdateUserPasswordMutation } from 'src/services/partnerUser-service'
import ConfirmActionModal from './confirmActionModal'

export function ChangePassword ({ id, email, open, onClose }) {
  const theme = useTheme()
  const [updateUserPassword] = useUpdateUserPasswordMutation()
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const handleOpenModal = () => {
    setOpenConfirmModal(true)
  }

  const handleCloseModal = () => {
    setOpenConfirmModal(false)
  }

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .max(255)
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/\d/, 'Password must contain at least one number')
      .matches(/[@$!%*?&]/, 'Password must contain at least one special character')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm password is required')
  })

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: ''
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const { data } = await updateUserPassword({
          id,
          email,
          newPassword: values.newPassword
        })
        if (data?.status === 200) {
          resetForm()
          onClose()
          handleCloseModal()
          toast.success(data?.message)
        } else {
          toast.error(data?.message)
        }
      } catch (error) {
        toast.error('An error occurred. Please try again.')
      }
    }
  })

  const handleCancel = () => {
    formik.resetForm()
    onClose()
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleCancel}
        aria-labelledby='modal-modal-title'
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={style}>
          <Typography
            id='modal-modal-title'
            sx={{
              fontSize: '20px',
              fontWeight: 700,
              lineHeight: '28px',
              mb: 1,
              color: theme.palette.primary.primaryText
            }}
          >
            Change Password
          </Typography>
          <form onSubmit={(e) => e.preventDefault()}>
            <Box sx={{ mb: 2 }}>
              <TextField
                id='new-password'
                label='Enter new password'
                variant='outlined'
                name='newPassword'
                type='password'
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                margin='normal'
                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                helperText={formik.touched.newPassword && formik.errors.newPassword}
              />
              <TextField
                id='confirm-password'
                label='Enter confirm password'
                variant='outlined'
                name='confirmPassword'
                type='password'
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                margin='normal'
                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              />
            </Box>
            <Grid container sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
              <Grid item xs={5.8}>
                <Button
                  type='button'
                  onClick={handleCancel}
                  fullWidth
                  variant='outlined'
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '14px',
                    py: 2,
                    borderColor: theme.palette.primary.main,
                    borderWidth: 1,
                    color: theme.palette.primary.main
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={5.8}>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '14px',
                    py: 2,
                    backgroundColor: theme.palette.primary.main,
                    '&:hover': {
                      backgroundColor: theme.palette.primary.dark
                    }
                  }}
                  disabled={!formik.isValid || formik.isSubmitting}
                  onClick={handleOpenModal}
                >
                  Change
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
      <ConfirmActionModal
        open={openConfirmModal}
        onClose={handleCloseModal}
        title='Confirmation'
        description='Are you sure you want to Update Password?'
        confirmButtonText='Update'
        onConfirm={formik.handleSubmit}
      />
    </>
  )
}

const style = {
  position: 'relative',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
  outline: 'none'
}

export default ChangePassword
