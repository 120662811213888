import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Stack, Typography } from '@mui/material'
import { ComboBox, FilterDropdown, LockedFilter, Tag } from '../filterComponents'
import { technologies } from 'src/assets/newFiltersData'
import { CompassIcon } from 'src/icons/compass'
import { useTheme } from '@emotion/react'

export default function TechnologyFilter ({ isLocked, onChange, type, plan, isOpen, onToggle }) {
  const theme = useTheme()
  const [selectedTech, setSelectedTech] = useState([])
  const technologyData = useSelector((state) => state.search[type === 'company' ? 'companyFilter' : 'filter'].major)
  const ref = useRef({ internalStateChange: false })

  const handleSelectedLanguagesAny = useCallback((newValue, updateRedux = true) => {
    setSelectedTech(newValue)
    if (updateRedux) onChange('major', newValue)
    ref.current.internalStateChange = true
  }, [onChange])

  const clearAll = useCallback(() => {
    handleSelectedLanguagesAny([])
  }, [handleSelectedLanguagesAny])

  useEffect(() => {
    if (ref.current.internalStateChange) {
      ref.current.internalStateChange = false
      return
    }

    setSelectedTech(technologyData ?? [])
  }, [technologyData])

  const preview = (
    <>
      {(selectedTech.length > 0) &&
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
          {selectedTech.length > 0 &&
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
              <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
              {/* <Typography variant='body2' sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600, paddingLeft: 1 }}>Type of technologies</Typography> */}
              {selectedTech.map((tech, index) => (
                <Tag
                  key={index}
                  label={tech}
                  onDelete={() => handleSelectedLanguagesAny(selectedTech.filter(l => l !== tech))}
                />
              ))}
            </Box>}
        </Box>}
    </>
  )

  if (isLocked) {
    return (
      <LockedFilter
        title='Technology'
        icon={<CompassIcon sx={{ stroke: `${theme.palette.primary.main}` }} />}
        plan={plan}
      />
    )
  }

  return (
    <FilterDropdown
      title='Technology'
      filterCount={selectedTech.length}
      clearAll={clearAll}
      icon={<CompassIcon sx={{ stroke: `${theme.palette.primary.main}` }} />}
      preview={preview}
      isOpen={isOpen}
      onToggle={onToggle}
    >
      <Stack direction='column' gap={2} mt={2}>
        <Stack direction='column' gap={1}>
          {/* <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography variant='button' sx={{ fontSize: '14px', fontWeight: 500 }}>Type of technology</Typography>
            <Button variant='text' onClick={() => handleSelectedLanguagesAny([])} sx={{ padding: '0', fontSize: '13px', fontWeight: 700 }}>
              Clear
            </Button>
          </Stack> */}
          <Typography sx={{ fontWeight: 700, fontSize: '14px' }}>Technologies</Typography>
          <ComboBox
            placeholder='Search Technology...'
            options={technologies}
            value={selectedTech}
            onChange={(e, newValue) => { handleSelectedLanguagesAny(newValue) }}
            freeSolo
            multiple
            handleHomeEndKeys
          />
          {selectedTech?.length > 0 && (
            <Typography
              onClick={() => handleSelectedLanguagesAny([])}
              sx={{
                position: 'absolute',
                right: 45,
                color: 'primary.main',
                fontSize: '12px',
                fontWeight: 600,
                cursor: 'pointer'
              }}
            >
              Clear ({selectedTech?.length})
            </Typography>
          )}
        </Stack>
      </Stack>
    </FilterDropdown>
  )
}
