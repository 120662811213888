import { useEffect, useState } from 'react'
import {
  useTestSmtpImapMutation, useUpdateAccountMutation, useGetAccountsMutation, setAccounts,
  usePauseWarmupMutation,
  useEnableWarmupMutation,
  useResumeAccountMutation,
  accountUpdated
  // useGetAccountMutation
} from 'src/services/account-service.js'
import {
  Delete,
  DriveFileRenameOutline,
  Settings,
  BugReport
} from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Popover,
  Tooltip,
  Typography,
  useTheme,
  CircularProgress,
  useMediaQuery
} from '@mui/material'
import RemoveAccountDialog from '../RemoveAccountDialog'
import { AddEmailHero } from 'src/assets/AddEmailHero'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { OffCheckboxCustomIcon } from 'src/assets/general/OffCheckboxCustomIcon'
import { OnCheckboxCustomIcon } from 'src/assets/general/OnCheckboxCustomIcon'
import { EAPlayIcon } from 'src/assets/emailAccounts/EAPlayIcon'
// import { EAWarmupIcon } from 'src/assets/emailAccounts/EAWarmupIcon'
import { EAMoreIcon } from 'src/assets/emailAccounts/EAMoreIcon'
import { EATotalEmailsSent } from 'src/assets/emailAccounts/EATotalEmailsSent'
import { EATotalWarmupSent } from 'src/assets/emailAccounts/EATotalWarmupSent'
import { EAWarmupHealthPercent } from 'src/assets/emailAccounts/EAWarmupHealthPercent'
import { EATotalEmailsFreeSpamIcon } from 'src/assets/emailAccounts/EATotalEmailsFreeSpam'
import { EATotalEmailsReceived } from 'src/assets/emailAccounts/EATotalEmailsReceived'
import CustomCheckbox from '../CustomCheckbox'
import { useUpdateIntercomMutation } from 'src/services/intercom-service'
import { FaTags } from 'react-icons/fa'
import { useGetDnsVitalsMutation } from 'src/services/dns-service.js'
import { useAuth } from 'src/auth/auth'

const EmailBlock = ({
  addNew,
  account,
  dnsRecord,
  onClick,
  onDelete,
  onClickSettings,
  bulkSettingsOn,
  isAccountChecked,
  onAccountCheckChange,
  user,
  cardName
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const { canEdit } = useAuth()
  const handleClose = () => {
    setAnchorEl(null)
  }
  const id = open ? 'simple-popover' : undefined

  // const [getAccount, { isLoading: isAccountLoading }] = useGetAccountMutation()

  const [testSmtpImap, { isLoading: isSmtpImapTesting }] = useTestSmtpImapMutation()

  const [, { isLoading: loadingDNS }] = useGetDnsVitalsMutation()
  const [mouseEntered, setMouseEntered] = useState(false)

  const [openRemoveAccountDialog, setOpenRemoveAccountDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const isTab = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const isCardView = cardName === 'Card View' || isMobile || isTab
  const isTableView = cardName === 'Table View' && !isMobile && !isTab

  const handleRemoveAccountClick = () => {
    setAnchorEl(null)
    onDelete(account._id)
  }

  const handleReconnectAccountClick = () => {
    navigate('/accounts/connect?reconnect=' + account.email)
    if (account?.provider === 'microsoft_oauth') {
      if (typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {
        window.localStorage.setItem('reconnect', account.email)
      }
    };
  }

  const [enableFunctionality, setEnableFunctionality] = useState(false)
  const [testMessage, setTestMessage] = useState('')
  const [enableDomainFunctionality] = useState(false)
  const [domainMessage] = useState('')
  const handleTestAccountClick = async (resume) => {
    try {
      const timeoutPromise = delay(60000)
      const testOnly = resume ? false : true 
      const testPromise = testSmtpImap({ id: account?._id, testOnly }).unwrap()
      const response = await Promise.race([testPromise, timeoutPromise])
      // const dnsVitals =   await getDnsVitals({ accounts: [account.email] }).unwrap();
      // dispatch(accountUpdated(response?.account));
      if (!response?.responses) {
        // Timeout occurred
        toast.error('Timeout Please try again ')
        setEnableFunctionality(false)
        setIsLoading(false)
        return { messages: null }
      }
      const messages = Array.isArray(response?.responses) ? response?.responses : [response?.responses]
      // const domains = Object.keys(dnsVitals?.records);
      // const firstDomainRecord = dnsVitals?.records[domains[0]];
      // const { allPass, mx, spf, dkim, dmarc } = firstDomainRecord;
      if (resume) {
        return { messages }
      }
      messages.map((message) => {
        if (message.status_code === 200) {
          toast.success(message.message)
        } else if (message.status_code === 422) {
          toast.error(message.message)
          setTestMessage(message.message)
        } else {
          toast.error(message.message || 'An error occurred')
        }
        return null
      })

      const allMessagesSuccessful = messages.every((message) => message.status_code === 200)
      const allUnsuccessfulMessages = messages.every((message) => message.status_code === 422)

      setEnableFunctionality(allMessagesSuccessful)

      if (allMessagesSuccessful) {
        setTestMessage('SMTP/IMAP Connection Successful')
      } else if (allUnsuccessfulMessages) {
        setTestMessage('SMTP & IMAP Connection Failed')
      }

      // if (dnsVitals) {
      //   if (domains.length > 0) {
      //     setEnableDomainFunctionality(allPass);

      //     const failedChecks = [];
      //     if (!mx) failedChecks.push("MX");
      //     if (!spf) failedChecks.push("SPF");
      //     if (!dkim) failedChecks.push("DKIM");
      //     if (!dmarc) failedChecks.push("DMARC");

      //     if (allPass) {
      //       setDomainMessage("Domain Authentication Successful");
      //     } else {
      //       const failMessage = `${failedChecks.join(", ")} record(s) failed.`;
      //       setDomainMessage(failMessage);
      //     }
      //   } else {
      //     setDomainMessage("No domain records found");
      //   }
      // }
    } catch (error) {
      const message = error?.data?.error?.message || 'An error occurred during the test Please try again'
      toast.error(message)
    }
    handleClose()
    return { messages: null }
  }

  async function delay (ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  };

  const [warmupStatus, setWarmupStatus] = useState(account.warmup.status)

  useEffect(() => {
    setWarmupStatus(account.warmup.status)
  }, [account])

  // const checkDisconnectedEmails = async () => {
  //   const response = await testSmtpImap({ email: account?.email }).unwrap();
  //   const messages = Array.isArray(response) ? response : [response];
  //   const allMessagesSuccessful = messages.every((message) => message.status_code === 200);
  //   setEnableFunctionality(allMessagesSuccessful);
  //   allMessagesSuccessful ? setTestMessage("") : setTestMessage("Reconnect your account");
  // };
  // useEffect(() => {
  //   checkDisconnectedEmails();
  // },[]);

  const [enableWarmup] = useEnableWarmupMutation()
  const [updateIntercom] = useUpdateIntercomMutation()
  const [pauseWarmup] = usePauseWarmupMutation()
  const [resumeAccount] = useResumeAccountMutation()

  const handleUpdateWarmupStatus = async () => {
    if (account.status === 'paused') {
      toast.error('Please resume your account first.')
      return
    }
    if (warmupStatus === 'paused' && account.status === 'connected') {
      const toastId = toast.loading('Loading...', { duration: Infinity })
      const { message, account: updatedAccount } = await enableWarmup(account._id).unwrap()
      dispatch(accountUpdated(updatedAccount))
      setWarmupStatus('enabled')
      toast.success(message, { id: toastId, duration: 2000 })
      window.Intercom('trackEvent', 'Email account warmup initiated')
    } else if (warmupStatus === 'enabled') {
      const toastId = toast.loading('Loading...', { duration: Infinity })
      const { message, account: updatedAccount } = await pauseWarmup(account._id).unwrap()
      dispatch(accountUpdated(updatedAccount))
      setWarmupStatus('paused')
      toast.success(message, { id: toastId, duration: 2000 })
    } else {
      // show dialog
    }
    await updateIntercom({ user: user._id, attribute: 'warmedup_email_accounts' })
  }

  const handleResumeAccount = async () => {
    if (isLoading) {
      return
    }
    try {
      setIsLoading(true)
      const resume = true
      const { messages } = await handleTestAccountClick(resume)
      const allMessagesSuccessful = messages?.every((message) => message.status_code === 200)
      const has422StatusCode = messages?.some(message => message.status_code === 422)
      if (!has422StatusCode && allMessagesSuccessful) {
        const { message, account: updatedAccount } = await resumeAccount(account._id).unwrap()
        dispatch(accountUpdated(updatedAccount))
        toast.success(message)
      } else if (has422StatusCode) {
        throw new Error('Account cannot be resumed due to SMTP error.')
      }
      // else if(!allPass){
      //   toast.error('Domain Authentication error.')
      // }
    } catch (error) {
      toast.error(error?.message || 'Account cannot be resumed.')
    } finally {
      setIsLoading(false)
    }
    
  }

  const handleChangeChecked = (event) => {
    onAccountCheckChange(event.target.checked, account._id)
  }

  const handleCheckedBox = (event) => {
    event.stopPropagation()
    !addNew && !bulkSettingsOn && onAccountCheckChange(!isAccountChecked, account._id)
  }

  const [updateClick, setUpdateClick] = useState(false)
  const [, setTotal] = useState(0)
  const [search] = useState('')
  const [filter] = useState(null)
  const [limit] = useState(10)
  const [updateAccount] = useUpdateAccountMutation()
  const [getAccounts] = useGetAccountsMutation()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const [workspaceId, setWorkspaceId] = useState()
  const workspace = useSelector((state) => state.workspace)
  useEffect(() => {
    setWorkspaceId(workspace?._id)
  }, [workspace])

  const handleMouseEnter = () => {
    setIsTooltipOpen(true)
  }

  const handleMouseLeave = () => {
    setIsTooltipOpen(false)
  }

  const handleChipClick = (e) => {
    e.stopPropagation()
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  const convertToUTC = (timestamp) => {
    const utcString = new Date(timestamp).toUTCString()
    return utcString
  }

  const handleUpdateAccount = (accountId, value) => async (event) => {
    event.stopPropagation()
    await updateAccount({ id: accountId, data: { accountStatus: value } })
    handleDialogClose()
    setUpdateClick(true)
  }

  useEffect(() => {
    if (updateClick) {
      const timer = setTimeout(async () => {
        const { docs, total } = await getAccounts({ search, filter: filter?.value, limit, workspaceId }).unwrap()
        dispatch(setAccounts(docs))
        setTotal(total)
      }, 500)
      return () => clearTimeout(timer)
    }
  }, [updateClick])
  // const { totalCount, totalInboxCount, totalLabel } = useTotalCounts();

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          p: 3,
          pt: 1.5,
          borderRadius: '12px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          boxShadow: '0px 12px 15px 0px #4B71970D',
          backgroundColor: !addNew ? '#fff' : '#F2F4F6',
          '&:hover': {
            boxShadow: '0px 2px 14px -1px rgba(0, 0, 0, 0.2)'
          },
          transition: 'all 0.2s ease-in-out',
          flexDirection: 'column'
          // position: 'relative',
        }}
        onClick={(e) => {
          !bulkSettingsOn && !mouseEntered && onClick(e)
        }}
      >
        {!addNew
          ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  flexDirection: { xs: 'column-reverse', md: 'row' }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: isCardView ? '100%' : '150vw',
                    maxWidth: { xs: '100%', md: 'calc(100% - 190px)' }
                  }}
                >
                  <Checkbox
                    checked={isAccountChecked}
                    onChange={handleChangeChecked}
                    onClick={handleCheckedBox}
                    inputProps={{ 'aria-label': 'controlled' }}
                    size='small'
                    icon={<OffCheckboxCustomIcon />}
                    checkedIcon={<OnCheckboxCustomIcon />}
                    sx={{ display: bulkSettingsOn && 'none' }}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: bulkSettingsOn ? 'calc(100% - 50px)' : '100%' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        gap: '2px',
                        width: isCardView ? '100%' : { md: '39vw', lg: '30vw', xl: '36vw' }
                      }}
                    >
                      {isCardView
                        ? (
                          <Typography
                            sx={{
                              ml: !bulkSettingsOn && 1,
                              fontSize: '14px',
                              fontWeight: 700,
                              lineHeight: '18px',
                              color: `${theme.palette.primary.primaryText}`,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              width: '100%'
                            }}
                          >
                            {`${account.name.first} ${account.name.last}`}
                          </Typography>
                          )
                        : <></>}
                      <Typography
                        sx={{
                          display: 'flex',
                          ml: !bulkSettingsOn && 1,
                          fontSize: isCardView ? '13px' : '14px',
                          fontWeight: isCardView ? 400 : 600,
                          lineHeight: '18px',
                          color: isCardView ? '#8181B0' : '#14171f',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: '100%'
                        }}
                      >
                        {account.email}{' '}
                        {isTableView &&
                          (
                            account?.eEngineStatus === 'authenticationError' ||
                            account?.eEngineStatus === 'connectError' ||
                            account?.status === 'reconnect'
                          )
                          ? (
                            <Chip
                              label='Disconnected'
                              sx={{
                                fontSize: '10px',
                                fontWeight: 500,
                                lineHeight: '16px',
                                color: 'red',
                                backgroundColor: '#fff',
                                borderRadius: '8px',
                                border: ' 1px solid #FAD7DB',
                                px: 0.5,
                                // py: 1,
                                // mb:1,
                                ml: 1,
                                width: '100px'
                              }}
                              size='small'
                            />
                            )
                          : <></>}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          gap: '8px',
                          alignItems: 'center'
                        }}
                      >
                        {account?.tags.map((tag, index) => (
                          <Typography
                            key={index}
                            sx={{
                              ml: 1,
                              fontSize: '13px',
                              fontWeight: 400,
                              lineHeight: '18px',
                              color: '#8181B0',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: 'flex',
                              alignItems: 'center',
                              gap: '4px'
                            }}
                          >
                            <FaTags style={{ marginRight: '1px' }} fontSize='small' />
                            {tag?.label}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {isTableView
                  ? (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          width: '50vw',
                          maxWidth: { xs: '100%', md: 'calc(100% - 190px)' }
                        }}
                      >
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: bulkSettingsOn ? 'calc(100% - 50px)' : '100%' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: '2px',
                              width: '10vw'
                            }}
                          >
                            <Typography
                              sx={{
                                ml: !bulkSettingsOn && 1,
                                fontSize: '13px',
                                fontWeight: 700,
                                lineHeight: '18px',
                                color: '#14171f',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '100%'
                              }}
                            >
                              {account?.campaignSend ? account?.campaignSend : 0} of {account?.campaign?.dailyLimit}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          width: '50vw',
                          maxWidth: { xs: '100%', md: 'calc(100% - 190px)' }
                        }}
                      >
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: bulkSettingsOn ? 'calc(100% - 50px)' : '100%' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: '2px',
                              width: '10vw'
                            }}
                          >
                            <Typography
                              sx={{
                                ml: !bulkSettingsOn && 1,
                                fontSize: '13px',
                                fontWeight: 700,
                                lineHeight: '18px',
                                color: '#14171f',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '100%'
                              }}
                            >
                              {account?.warmupStats?.sent_count ? account?.warmupStats?.sent_count : 0}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          width: '100%',
                          maxWidth: { xs: '100%', md: 'calc(100% - 190px)' }
                        }}
                      >
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: bulkSettingsOn ? 'calc(100% - 50px)' : '100%' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: '2px',
                              width: '100%'
                            }}
                          >
                            <Typography
                              sx={{
                                ml: !bulkSettingsOn && 1,
                                fontSize: '13px',
                                fontWeight: 700,
                                lineHeight: '18px',
                                color: '#14171f',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '100%'
                              }}
                            >
                              {account?.warmupStats?.health_score || 0}%
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </>
                    )
                  : <></>}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    width: '100%'
                  }}
                >
                  {canEdit && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: { xs: 'space-between', md: 'center' },
                        alignItems: 'center',
                        width: { xs: '100%', md: 'fit-content' },
                        borderRadius: 0.75,
                        p: 1.3,
                        pr: 0
                      }}
                      onMouseEnter={() => {
                        setMouseEntered(true)
                      }}
                      onMouseLeave={() => {
                        setMouseEntered(false)
                      }}
                    >
                      {account.status === 'paused' && (
                        <>
                          {account.status === 'paused' && (
                            <>
                              <Box
                                sx={{
                                  display: { xs: 'none', sm: 'flex' },
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  '&:hover': {
                                    backgroundColor: '#f2f2f2'
                                  },
                                  borderRadius: 0.75,
                                  p: 0.5
                                }}
                                onClick={handleResumeAccount}
                              >
                                {isLoading
                                  ? (
                                    <CircularProgress size={24} />
                                    )
                                  : (
                                      account.freeUserOtherAccounts === true
                                        ? (
                                          <></>
                                          )
                                        : (
                                          <Tooltip title='Click to resume your account' placement='top' sx={{ textAlign: 'center' }} arrow>
                                            <Grid item xs={4} sm={12} md={12} px={3}>
                                              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <EAPlayIcon />
                                              </Box>
                                            </Grid>
                                          </Tooltip>
                                          )
                                    )}
                              </Box>
                            </>
                          )}
                        </>
                      )}{' '}
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          gap: 2
                        }}
                      >
                        {/* <EAWarmupIcon
                          color={
                            warmupStatus === "paused"
                              ? "#565656"
                              : warmupStatus === "disabled"
                              ? "#ff8484"
                              : warmupStatus === "enabled"
                              ? "#0071F6"
                              : "#ff8484"
                          }
                        /> */}
                        {account.status === 'paused' && (
                          <>
                            <Box
                              sx={{
                                display: { xs: 'flex', sm: 'none' },
                                justifyContent: 'center',
                                alignItems: 'center',
                                '&:hover': {
                                  backgroundColor: '#f2f2f2'
                                },
                                borderRadius: 0.75,
                                p: 0.5,
                                pl: 0,
                                ml: '-5px'
                              }}
                              onClick={handleResumeAccount}
                            >
                              {isLoading
                                ? (
                                  <Tooltip
                                    title='Testing Account. Please wait.'
                                    placement='top'
                                    sx={{ textAlign: 'center' }}
                                    arrow
                                  >
                                    <CircularProgress size={24} />
                                  </Tooltip>
                                  )
                                : (
                                    account.freeUserOtherAccounts === true
                                      ? (
                                        <></>
                                        )
                                      : (
                                        <Tooltip
                                          title='Click to resume your account'
                                          placement='top'
                                          sx={{ textAlign: 'center' }}
                                          arrow
                                        >
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <EAPlayIcon />
                                          </Box>
                                        </Tooltip>
                                        )
                                  )}
                            </Box>
                          </>
                        )}
                        <Typography
                          sx={{
                            color: '#8181B0',
                            fontSize: '13px',
                            fontWeight: 400
                            // display: { xs: "none", sm: "inline-block" },
                          }}
                        >
                          Warmup
                        </Typography>
                        {/* <Tooltip
                        title={
                          warmupStatus === "enabled"
                            ? "Pause Warmup"
                            : warmupStatus === "disabled"
                            ? "Warmup disabled for account."
                            : warmupStatus === "paused"
                            ? "Enable Warmup"
                            : null
                        }
                        placement="top"
                        sx={{ textAlign: "center" }}
                        arrow
                      > </Tooltip> */}
                        <Box
                          sx={{
                            mr: '-16px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          <Tooltip
                            title={account?.warmup?.warmupDisable ? 'Email bounce limit reached.' : 'Resume your account'}
                            placement='top'
                            sx={{ textAlign: 'center' }}
                            arrow
                            open={isTooltipOpen && (account.status === 'paused' || account.warmup.warmupDisable)}
                          >
                            <span>
                              <CustomCheckbox
                                name='warmup.basicSetting.alertBlock'
                                checked={warmupStatus === 'enabled'}
                                onChange={handleUpdateWarmupStatus}
                                disabled={account.warmup.warmupDisable === true || warmupStatus === 'disabled'}
                              />
                            </span>
                          </Tooltip>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          '&:hover': {
                            backgroundColor: '#f2f2f2'
                          },
                          borderRadius: 0.75,
                          p: 0.5
                        }}
                        onClick={handleClick}
                      >
                        <Tooltip title='' placement='top' sx={{ textAlign: 'center' }} arrow>
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <EAMoreIcon />
                          </Box>
                        </Tooltip>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
              {isCardView
                ? (
                  <>
                    <Grid container spacing={1} sx={{}}>
                      {/* {account.status === "paused" && (
                  <Grid item>
                    <Chip
                      label="Paused"
                      sx={{
                        fontSize: "13px",
                        fontWeight: 700,
                        lineHeight: "16px",
                        color: "#28287B",
                        backgroundColor: "#fff",
                        borderRadius: "8px",
                        border: "1px solid #E4E4E5",
                        px: 0.5,
                        py: 2,
                      }}
                      size="small"
                    />
                  </Grid>
                )}
                {account.status === "disconnected" && (
                  <Grid item>
                    <Chip
                      label="Disconnected"
                      // color="error"
                      sx={{
                        fontSize: "13px",
                        fontWeight: 700,
                        lineHeight: "16px",
                        color: "#FD1E36",
                        backgroundColor: "white",
                        borderRadius: "8px",
                        border: "1px solid #FD1E36",
                        px: 0.5,
                        py: 2,
                      }}
                      size="small"
                    />
                  </Grid>
                )}
                {dnsRecord && !dnsRecord.allPass && (
                  <>
                    {dnsRecord.mx || (
                      <Grid item>
                        <Chip
                          label="MX not found"
                          // color="error"
                          variant="outlined"
                          sx={{
                            fontSize: "13px",
                            fontWeight: 700,
                            lineHeight: "16px",
                            color: "#FD1E36",
                            backgroundColor: "white",
                            borderRadius: "8px",
                            border: "1px solid #FAD7DB",
                            px: 0.5,
                            py: 2,
                          }}
                          size="small"
                        />
                      </Grid>
                    )}
                    {dnsRecord.spf || (
                      <Grid item>
                        <Chip
                          label="SPF not found"
                          // color="error"
                          variant="outlined"
                          sx={{
                            fontSize: "13px",
                            fontWeight: 700,
                            lineHeight: "16px",
                            color: "#FD1E36",
                            backgroundColor: "white",
                            borderRadius: "8px",
                            border: "1px solid #FAD7DB",
                            px: 0.5,
                            py: 2,
                          }}
                          size="small"
                        />
                      </Grid>
                    )}
                    {dnsRecord.dkim || (
                      <Grid item>
                        {" "}
                        <Chip
                          label="DKIM not found"
                          // color="error"
                          variant="outlined"
                          sx={{
                            fontSize: "13px",
                            fontWeight: 700,
                            lineHeight: "16px",
                            color: "#FD1E36",
                            backgroundColor: "white",
                            borderRadius: "8px",
                            border: "1px solid #FAD7DB",
                            px: 0.5,
                            py: 2,
                          }}
                          size="small"
                        />
                      </Grid>
                    )}
                    {dnsRecord.dmarc || (
                      <Grid item>
                        {" "}
                        <Chip
                          label="DMARC not found"
                          // color="error"
                          variant="outlined"
                          sx={{
                            fontSize: "13px",
                            fontWeight: 700,
                            lineHeight: "16px",
                            color: "#FD1E36",
                            backgroundColor: "white",
                            borderRadius: "8px",
                            border: "1px solid #FAD7DB",
                            px: 0.5,
                            py: 2,
                          }}
                          size="small"
                        />
                      </Grid>
                    )}
                  </>
                )} */}
                    </Grid>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        py: 2,
                        mt: 1,
                        borderTop: `1px solid ${theme.palette.grey[200]}`
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center'
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <EATotalEmailsSent />
                        </Box>
                        <Box sx={{ ml: 1 }}>
                          <Typography
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',

                              fontSize: '20px',
                              fontWeight: 700,
                              lineHeight: '25px',
                              color: `${theme.palette.primary.primaryText}`
                            }}
                          >
                            {account?.campaignSend ? account?.campaignSend : 0}
                          </Typography>
                          <Typography sx={{ color: '#8181B0', fontSize: '13px', fontWeight: 400 }}>
                            Campaign Emails Sent Today
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        {(isSmtpImapTesting || loadingDNS) && isLoading && !isMobile
                          ? <Chip
                              label='Testing Account. Please wait.'
                              sx={{
                                fontSize: '12px',
                                fontWeight: 500,
                                lineHeight: '16px',
                                color: `${theme.palette.primary.main}`,
                                backgroundColor: '#fff',
                                borderRadius: '8px',
                                border: '1px solid #E4E4E5',
                                px: 0.5,
                                py: 2,
                                mb: 1,
                                width: '100%'
                              }}
                              size='small'
                            />
                          : ''}
                        {account?.status === 'paused' && (
                          <Box>
                            <Chip
                              label='Paused'
                              sx={{
                                fontSize: '12px',
                                fontWeight: 500,
                                lineHeight: '16px',
                                color: `${theme.palette.primary.primaryText}`,
                                backgroundColor: '#fff',
                                borderRadius: '8px',
                                border: '1px solid #E4E4E5',
                                px: 0.5,
                                py: 2,
                                mb: 1,
                                width: '100%'
                              }}
                              size='small'
                            />
                          </Box>
                        )}
                        <Grid item xs='auto'>
                          <Box>
                            {(
                              account?.eEngineStatus === 'authenticationError' ||
                              account?.eEngineStatus === 'connectError' ||
                              account?.status === 'reconnect'
                            ) &&
                              <Chip
                                label='Reconnect your account'
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: 500,
                                  lineHeight: '16px',
                                  color: 'red',
                                  backgroundColor: '#fff',
                                  borderRadius: '8px',
                                  border: ' 1px solid #FAD7DB',
                                  px: 0.5,
                                  py: 2,
                                  mb: 1,
                                  width: '100%'
                                }}
                                size='small'
                              />}
                          </Box>
                        </Grid>
                        <Grid item xs='auto'>
                          <Box>
                            {account?.eEngineStatus === 'dailyLimitReached' &&
                              <Chip
                                label='Daily Email Sending Limit Reached'
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: 500,
                                  lineHeight: '16px',
                                  color: '#856404',
                                  backgroundColor: '#fff3cd',
                                  borderRadius: '8px',
                                  border: '1px solid #ffeeba',
                                  px: 0.5,
                                  py: 2,
                                  mb: 1,
                                  width: '100%'
                                }}
                                size='small'
                              />}
                          </Box>
                        </Grid>
                        <Grid item xs='auto'>
                          <Box>
                            {testMessage &&
                              <Chip
                                label={testMessage}
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: 500,
                                  lineHeight: '16px',
                                  color: enableFunctionality ? 'green' : 'red',
                                  backgroundColor: '#fff',
                                  borderRadius: '8px',
                                  border: enableFunctionality ? ' 1px solid green' : ' 1px solid #FAD7DB',
                                  px: 0.5,
                                  py: 2,
                                  mb: 1,
                                  width: '100%'
                                }}
                                size='small'
                              />}
                          </Box>
                        </Grid>
                        <Grid item xs='auto'>
                          <Box>
                            {domainMessage &&
                              <Chip
                                label={domainMessage}
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: 500,
                                  lineHeight: '16px',
                                  color: enableDomainFunctionality ? 'green' : 'red',
                                  backgroundColor: '#fff',
                                  borderRadius: '8px',
                                  border: enableDomainFunctionality ? ' 1px solid green' : ' 1px solid #FAD7DB',
                                  px: 0.5,
                                  py: 2,
                                  mb: 1,
                                  width: '100%'
                                }}
                                size='small'
                              />}
                          </Box>
                        </Grid>
                        {account?.status === 'disconnected' && (
                          // <Box>
                          //   <Chip
                          //     label="Disconnected"
                          //     // color="error"
                          //     sx={{
                          //       fontSize: "12px",
                          //       fontWeight: 500,
                          //       lineHeight: "16px",
                          //       color: "#FD1E36",
                          //       backgroundColor: "white",
                          //       borderRadius: "8px",
                          //       border: "1px solid #FAD7DB",
                          //       px: 0.5,
                          //       py: 2,
                          //       mb:1,
                          //       width: "100%",
                          //     }}
                          //     size="small"
                          //   />
                          // </Box>
                          <Grid item xs='auto'>
                            <Box>
                              <Chip
                                label='Paused'
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: 500,
                                  lineHeight: '16px',
                                  color: `${theme.palette.primary.primaryText}`,
                                  backgroundColor: '#fff',
                                  borderRadius: '8px',
                                  border: '1px solid #E4E4E5',
                                  px: 0.5,
                                  py: 2,
                                  mb: 1,
                                  width: '100%'
                                }}
                                size='small'
                              />
                            </Box>
                          </Grid>
                        )}
                        <Grid item xs='auto'>
                          <Box>
                            {account?.accountError && (
                              <>
                                <Chip
                                  label='Error'
                                  sx={{
                                    fontSize: '12px',
                                    fontWeight: 500,
                                    lineHeight: '16px',
                                    color: '#FD1E36',
                                    backgroundColor: 'white',
                                    borderRadius: '8px',
                                    border: '1px solid #FAD7DB',
                                    px: 0.5,
                                    py: 2,
                                    mb: 1,
                                    width: '100%',
                                    cursor: 'pointer'
                                  }}
                                  size='small'
                                  onClick={handleChipClick}
                                />
                              </>
                            )}
                          </Box>
                        </Grid>
                        {dnsRecord && !dnsRecord.allPass && !dnsRecord.pending
                          ? (
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={6}>
                                  {dnsRecord.mx || (
                                    <Box sx={{ flex: 1 }}>
                                      <Chip
                                        label='MX not found'
                                        variant='outlined'
                                        sx={{
                                          fontSize: '12px',
                                          fontWeight: 500,
                                          lineHeight: '16px',
                                          color: '#FD1E36',
                                          backgroundColor: 'white',
                                          borderRadius: '8px',
                                          border: '1px solid #FAD7DB',
                                          px: 0.5,
                                          py: 2,
                                          width: '100%'
                                        }}
                                        size='small'
                                      />
                                    </Box>
                                  )}
                                </Grid>
                                <Grid item xs={6}>
                                  {dnsRecord.spf || (
                                    <Box sx={{ flex: 1 }}>
                                      <Chip
                                        label='SPF not found'
                                        variant='outlined'
                                        sx={{
                                          fontSize: '12px',
                                          fontWeight: 500,
                                          lineHeight: '16px',
                                          color: '#FD1E36',
                                          backgroundColor: 'white',
                                          borderRadius: '8px',
                                          border: '1px solid #FAD7DB',
                                          px: 0.5,
                                          py: 2,
                                          width: '100%'
                                        }}
                                        size='small'
                                      />
                                    </Box>
                                  )}
                                </Grid>
                                <Grid item xs={12}>
                                  {dnsRecord.dkim || (
                                    <Box sx={{ flex: 1 }}>
                                      <Chip
                                        label='DKIM not found'
                                        variant='outlined'
                                        sx={{
                                          fontSize: '12px',
                                          fontWeight: 500,
                                          lineHeight: '16px',
                                          color: '#FD1E36',
                                          backgroundColor: 'white',
                                          borderRadius: '8px',
                                          border: '1px solid #FAD7DB',
                                          px: 0.5,
                                          py: 2,
                                          width: '100%'
                                        }}
                                        size='small'
                                      />
                                    </Box>
                                  )}
                                </Grid>
                                <Grid item xs={12}>
                                  {dnsRecord.dmarc || (
                                    <Box sx={{ flex: 1 }}>
                                      <Chip
                                        label='DMARC not found'
                                        variant='outlined'
                                        sx={{
                                          fontSize: '12px',
                                          fontWeight: 500,
                                          lineHeight: '16px',
                                          color: '#FD1E36',
                                          backgroundColor: 'white',
                                          borderRadius: '8px',
                                          border: '1px solid #FAD7DB',
                                          px: 0.5,
                                          py: 2,
                                          width: '100%'
                                        }}
                                        size='small'
                                      />
                                    </Box>
                                  )}
                                </Grid>
                              </Grid>
                            </Box>
                            )
                          : dnsRecord?.pending && (
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={12}>
                                  <Box sx={{ flex: 1 }}>
                                    <Chip
                                      label='Domain Authentication is Pending...'
                                      variant='outlined'
                                      sx={{
                                        fontSize: '12px',
                                        fontWeight: 500,
                                        lineHeight: '16px',
                                        color: `${theme.palette.primary.primaryText}`,
                                        backgroundColor: 'white',
                                        borderRadius: '8px',
                                        border: '1px solid yellow',
                                        px: 0.5,
                                        py: 2,
                                        width: '100%'
                                      }}
                                      size='small'
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          )}
                      </Box>
                    </Box>
                    <Grid container spacing={2} columnSpacing={3}>
                      <Grid item xs={6}>
                        <Box
                          sx={{
                            width: '100%',
                            height: '100%',
                            p: 2,
                            borderRadius: '12px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            backgroundColor: '#F2F4F6',
                            flexDirection: 'column',
                            gap: 1
                          }}
                        >
                          {' '}
                          <Box>
                            {' '}
                            <EATotalWarmupSent />
                          </Box>
                          <Typography sx={{ color: '#8181B0', fontSize: '13px', fontWeight: 400 }}>
                            Warmup Emails Sent Past Week
                          </Typography>
                          <Typography
                            sx={{ fontSize: '20px', fontWeight: 700, lineHeight: '25px', color: `${theme.palette.primary.primaryText}` }}
                          >
                            {' '}
                            {account?.warmupStats?.sent_count || 0}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          sx={{
                            width: '100%',
                            height: '100%',
                            p: 2,
                            borderRadius: '12px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            backgroundColor: '#F2F4F6',
                            flexDirection: 'column',
                            gap: 1
                          }}
                        >
                          <Box>
                            <EAWarmupHealthPercent />
                          </Box>
                          <Typography sx={{ color: '#8181B0', fontSize: '13px', fontWeight: 400 }}>
                            Warmup Health Percentage
                          </Typography>
                          <Typography
                            sx={{ fontSize: '20px', fontWeight: 700, lineHeight: '25px', color: `${theme.palette.primary.primaryText}` }}
                          >
                            {account?.warmupStats?.health_score || 0}%
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={6}>
                        <Box
                          sx={{
                            width: '100%',
                            height: '100%',
                            p: 2,
                            borderRadius: '12px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            backgroundColor: '#F2F4F6',
                            flexDirection: 'column',
                            gap: 1
                          }}
                        >
                          <Box>
                            {' '}
                            <EATotalEmailsReceived />
                          </Box>
                          <Typography sx={{ color: '#8181B0', fontSize: '13px', fontWeight: 400 }}>
                            Warmup Emails Landed In Inbox Past Week
                          </Typography>
                          <Typography
                            sx={{ fontSize: '20px', fontWeight: 700, lineHeight: '25px', color: `${theme.palette.primary.primaryText}` }}
                          >
                            {' '}
                            {account?.warmupStats?.inbox_count || 0}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          sx={{
                            width: '100%',
                            height: '100%',
                            p: 2,
                            borderRadius: '12px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            backgroundColor: '#F2F4F6',
                            flexDirection: 'column',
                            gap: 1
                          }}
                        >
                          <Box>
                            {' '}
                            <EATotalEmailsFreeSpamIcon />
                          </Box>
                          <Typography sx={{ color: '#8181B0', fontSize: '13px', fontWeight: 400 }}>
                            Warmup Emails Saved From Spam Folder Past Week
                          </Typography>
                          <Typography
                            sx={{ fontSize: '20px', fontWeight: 700, lineHeight: '25px', color: `${theme.palette.primary.primaryText}` }}
                          >
                            {' '}
                            {account?.warmupStats?.spam_count || 0}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                  )
                : <></>}
              {/* <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                mt: 2,
              }}
            >
               <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  width: "35%",
                  backgroundColor: "#F2F4F6",
                  borderRadius: "8px",
                  // borderRight: "1px solid rgba(0,0,0,0.1)",
                  p: 2,
                }}
              >
                <Tooltip title="Campaign total number of emails sent today" placement="top" arrow>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      fontSize: "20px",
                      fontWeight: 700,
                      lineHeight: "25px",
                      color: "#28287B",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mb: 2,
                      }}
                    >
                      <EATotalEmailsSent />
                    </Box>
                    {account.campaignSend ? account.campaignSend : 0}
                  </Typography>
                </Tooltip>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  flexDirection: "column",
                  py: 1,
                  border: "1px solid #E4E4E5",
                  borderRadius: "8px",
                  height: "100%",
                  ml: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Tooltip
                    title="Total number of warmup emails in the past week"
                    placement="top"
                    sx={{ textAlign: "center" }}
                    arrow
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        width: "6.5ch",
                        fontSize: "14px",
                        fontWeight: 700,
                        lineHeight: "18px",
                        color: "#28287B",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          mr: 1,
                        }}
                      >
                        <EATotalWarmupSent />
                      </Box>

                      {account.warmupStats?.sent_count || 0}
                    </Typography>
                  </Tooltip>{" "}
                  <Tooltip
                    title="Total number of emails we kept out of spam past week"
                    placement="top"
                    sx={{ textAlign: "center" }}
                    arrow
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        width: "6.5ch",
                        fontSize: "14px",
                        fontWeight: 700,
                        lineHeight: "18px",
                        color: "#28287B",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          mr: 1,
                        }}
                      >
                        <EATotalEmailsFreeSpamIcon />
                      </Box>
                      {account.warmupStats?.spam_count || 0}
                    </Typography>
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    width: "100%",
                    mt: 3,
                  }}
                >
                  <Tooltip
                    title="Total number of warmup emails delivered in primary inbox past week"
                    placement="bottom"
                    sx={{ textAlign: "center" }}
                    arrow
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        width: "6.5ch",
                        fontSize: "14px",
                        fontWeight: 700,
                        lineHeight: "18px",
                        color: "#28287B",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          mr: 1,
                        }}
                      >
                        <EATotalEmailsReceived />
                      </Box>
                      {account.warmupStats?.inbox_count || 0}
                    </Typography>
                  </Tooltip>

                  <Tooltip
                    title="Warmup health percentage"
                    placement="bottom"
                    sx={{ textAlign: "center" }}
                    arrow
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        width: "6.5ch",
                        fontSize: "14px",
                        fontWeight: 700,
                        lineHeight: "18px",
                        color: "#28287B",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          mr: 1,
                        }}
                      >
                        <EAWarmupHealthPercent />
                      </Box>
                      {account.warmupStats?.health_score || 0}%
                    </Typography>
                  </Tooltip>
                </Box>
              </Box>
            </Box> */}
            </>
            )
          : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}
              >
                <AddEmailHero />
                <Typography sx={{ fontWeight: 600, color: 'rgba(0,0,0,0.5)', mt: 1 }}>
                  Add New Email Account
                </Typography>
              </Box>
            </>
            )}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      // style={{position: 'absolute'}}
      // disableScrollLock={true}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 240
          }}
        >
          <Button
            fullWidth
            sx={{
              py: 0,
              px: 0,
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              color: '#101828'
            }}
            onClick={onClickSettings}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                py: 2,
                px: 2
              }}
              onClick={() => {
                setAnchorEl(null)
              }}
            >
              <Settings sx={{ mr: 1 }} fontSize='small' />
              Adjust Settings
            </Box>
          </Button>
          {(account.status !== 'connected' ||
            account?.eEngineStatus === 'authenticationError' ||
            account?.eEngineStatus === 'connectError') && (
              <Button
                fullWidth
                sx={{
                  py: 2,
                  px: 2,

                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  color: '#101828'
                }}
                // disabled = {account.status === 'paused'}
                onClick={handleReconnectAccountClick}
              >
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <DriveFileRenameOutline sx={{ mr: 1 }} fontSize='small' />
                  Reconnect to Account
                </Box>
              </Button>
          )}
          <Button
            fullWidth
            sx={{
              py: 2,
              px: 2,
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              color: '#101828',
              '&:hover': {
                backgroundColor: '#ffd6d6'
              },
              borderRadius: 0
            }}
            // disabled = {account.status === 'paused'}
            onClick={() => {
              setOpenRemoveAccountDialog(true)
              setAnchorEl(null)
            }}
            disabled={!canEdit}
          >
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Delete sx={{ mr: 1 }} fontSize='small' />
              Delete Account
            </Box>
          </Button>
          <Button
            fullWidth
            sx={{
              py: 2,
              px: 2,
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              color: '#101828'
            }}
            disabled={account.status === 'paused'}
            onClick={() => {
              !isSmtpImapTesting && handleTestAccountClick()
            }}
          >
            {(isSmtpImapTesting || loadingDNS)
              ? (
                <>
                  <CircularProgress size={16} sx={{ mr: 1, color: `${theme.palette.primary.main}` }} thickness={5} />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    Test Account
                  </Box>
                </>
                )
              : (
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <BugReport sx={{ mr: 1 }} fontSize='small' />
                  Test Account
                </Box>
                )}
          </Button>
        </Box>
      </Popover>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        disableEnforceFocus
        maxWidth='sm'
        minWidth='xs'
        fullWidth
        sx={{ backgroundColor: 'rgba(0,0,0,0.3)' }}
      >
        {' '}
        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: 500,
              lineHeight: '16px',
              color: `${theme.palette.primary.primaryText}`,
              marginRight: 'auto'
            }}
          >
            Enable Account
          </Typography>
          <Box sx={{ m: 0, marginLeft: 'auto' }}>
            <CustomCheckbox
              onChange={handleUpdateAccount(account._id, true)}
            />
          </Box>
        </Box>

        <DialogTitle sx={{ position: 'relative' }}>Dated At: {convertToUTC(account.updatedAt)}</DialogTitle>
        <DialogContent>
          Error Message: {account.accountError}
        </DialogContent>
        <DialogActions sx={{ px: '24px', pb: 2 }}>
          <Button onClick={handleDialogClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <RemoveAccountDialog
        open={openRemoveAccountDialog}
        onClose={() => {
          setOpenRemoveAccountDialog(false)
        }}
        onClick={handleRemoveAccountClick}
      />
    </>
  )
}

export default EmailBlock
