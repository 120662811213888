import React, { useEffect, useMemo } from 'react'
import {
  Box,
  TextField,
  Typography,
  Grid,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  useTheme,
  IconButton,
  Switch,
  Badge
} from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Divider from '@mui/material/Divider'
import { EACloseIcon } from 'src/assets/emailAccounts/EACloseIcon'
import { planSettingFormFields } from 'src/config'
import { useGetPartnerUsageQuery } from 'src/services/partnerUser-service'
import { formatNumber } from 'src/utils/util'

const setDefaultvalues = (sections, usage) => {
  const defaultValues = {
    planName: '',
    buyerIntendData: false,
    advancedFilters: false,
    abTesting: false,
    aiFilters: false,
    mailboxConnection: false,
    customDomainTracking: false,
    globalBlocklist: false,
    emailWarmup: false,
    multiChannelLinkedinOutreach: false,
    unibox: false,
    aiAutoReplies: false,
    zapierIntegration: false,
    hubspotIntegration: false,
    salesforceIntegration: false,
    pipedriveIntegration: false,
    webhookIntegration: false,
    advancedApis: false
  }

  sections.forEach(section => {
    if (section.textFields && Array.isArray(section.textFields)) {
      section.textFields.forEach(field => {
        if (field && usage?.[field.name] === 0) {
          defaultValues[field.name] = 0
        }
      })
    }
  })
  return defaultValues
}

const FormField = ({ name, placeholder, type = 'number', formik, isDisabled = false }) => {
  const hasError = (formik.touched[name] || formik.submitCount > 0) && formik.errors[name]

  const handleKeyDown = (e) => {
    if (type === 'text') return
    // Allow Backspace, Delete, Tab, Escape, Enter, Arrow keys, and numeric keys
    const allowedKeys = [
      'Backspace', 'Delete', 'Tab', 'Escape', 'Enter',
      'ArrowLeft', 'ArrowRight', 'Home', 'End'
    ]
    if (allowedKeys.includes(e.key) || (e.ctrlKey && ['a', 'c', 'v', 'x'].includes(e.key.toLowerCase()))) {
      return // Allow navigation and clipboard shortcuts
    }

    // Check if the key is a digit (0-9)
    if (!/^\d$/.test(e.key)) {
      e.preventDefault() // Block non-digit keys
    }
  }

  return (
    <TextField
      fullWidth
      type={type}
      variant='outlined'
      name={name}
      placeholder={placeholder}
      value={formik.values[name]}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      onKeyDown={handleKeyDown}
      error={hasError}
      helperText={hasError ? formik.errors[name] : ''}
      disabled={isDisabled}
      sx={{
        '& div': {
          pl: 0.3,
          width: '100%'
        },
        // Remove default borders
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none'
        },
        // Style for the input element
        '& .MuiInputBase-input': {
          py: 1.3,
          fontSize: '13px',
          fontWeight: 400,
          lineHeight: '16px',
          letterSpacing: '0em',
          borderRadius: '0.7rem',
          border: (theme) =>
            hasError
              ? `1px solid ${theme.palette.error.main}`
              : '1px solid #E4E4E5',
          '&::placeholder': {
            color: 'rgba(40, 40, 123, 0.5)'
          }
        },
        // Compact helper text with ellipsis for overflow
        '& .MuiFormHelperText-root': {
          ml: 0.3,
          mt: 0.5,
          minHeight: hasError ? '1rem' : '0',
          lineHeight: '1rem',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          transition: 'min-height 0.2s ease'
        }
      }}
    />
  )
}

const generateValidationSchema = (sections, usage) => {
  const schemaFields = {}

  sections.forEach(section => {
    if (section.textFields && Array.isArray(section.textFields)) {
      section.textFields.forEach(field => {
        if (field && field.name) {
          schemaFields[field.name] = Yup.number().integer()
          if (usage?.[field.name] !== 0) {
            schemaFields[field.name] = schemaFields[field.name].required(`${field.label} is required`)
          }
        }
      })
    }

    if (section.switches) {
      section.switches.forEach(switchItem => {
        schemaFields[switchItem.name] = Yup.boolean()
          .required(`${switchItem.label} is required`)
      })
    }
  })

  schemaFields.planName = Yup.string()
    .trim()
    .required('Plan name is required')
    .min(3, 'Plan name must be at least 3 characters')
    .max(20, 'Plan name must not exceed 20 characters')

  return Yup.object(schemaFields)
}

const PlanSetting = ({ onClose, open, title, handleSubmit, usageData, isSubmitting }) => {
  const theme = useTheme()
  const { data: usage } = useGetPartnerUsageQuery()

  const defaultValues = useMemo(() => setDefaultvalues(planSettingFormFields, usage), [usage])

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: defaultValues,
    validationSchema: generateValidationSchema(planSettingFormFields, usage),
    onSubmit: async (values) => {
      const response = await handleSubmit(values)
      if (response) {
        formik.resetForm({ values: { ...defaultValues } })
      }
    }
  })

  useEffect(() => {
    if (usageData && open) {
      formik.setValues(usageData)
    } else {
      formik.setValues({
        ...defaultValues
      })
    }
  }, [usageData, open])

  const handleClose = () => {
    onClose()
    formik.resetForm({ values: { ...defaultValues } })
  }

  return (
    <>
      <Dialog onClose={handleClose} open={open} maxWidth='lg'>
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              width: '100%'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column'
              }}
            >
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '28px',
                  color: `${theme.palette.primary.primaryText}`
                }}
              >
                {title}
              </Typography>
            </Box>
            <IconButton
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              onClick={() => {
                formik.resetForm()
                onClose()
              }}
            >
              <EACloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <form
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <DialogContent>
            <Box
              maxWidth='md'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                bgcolor: 'white'
              }}
            >
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', width: '35%' }}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    lineHeight: '26px',
                    fontWeight: 600,
                    minWidth: '90px'
                  }}
                >
                  Plan Name:
                </Typography>
                <FormField
                  name='planName'
                  placeholder='Plan Name'
                  formik={formik}
                  type='text'
                />
              </Box>

              {planSettingFormFields.map((section) => (
                <React.Fragment key={section.title}>
                  <Divider sx={{ mt: 3, mb: 2 }} textAlign='center'>
                    <Typography variant='h5' color={theme.palette.primary.primaryText}>
                      {section.title}
                    </Typography>
                  </Divider>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Grid container spacing={2}>
                      {section.textFields && section.textFields.map((field) => (
                        <Grid item xs={12} md={4} key={field.name}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, mb: 1 }}>
                            <Typography
                              sx={{
                                fontSize: '16px',
                                lineHeight: '26px',
                                fontWeight: 600
                              }}
                              color={usage?.[field.name] === 0 && 'textSecondary'}
                            >
                              {field.label}
                            </Typography>
                            <Box display='inline-flex' position='relative'>
                              <Badge
                                badgeContent={formatNumber(usage?.[field.name]) ?? 0}
                                color='primary'
                                max={999}
                                sx={{
                                  '& .MuiBadge-badge': {
                                    height: 'auto !important',
                                    py: '4px !important'
                                  }
                                }}
                                overlap='rectangular'
                              />
                            </Box>
                          </Box>
                          <FormField
                            name={field.name}
                            placeholder='0'
                            formik={formik}
                            isDisabled={usage?.[field.name] === 0}
                          />
                        </Grid>
                      ))}

                      {section.switches && section.switches.map((switchItem) => (
                        <Grid item xs={12} sm={4} key={switchItem.name}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              width: 'full'
                            }}
                          >
                            <Switch
                              checked={formik.values[switchItem.name]}
                              onChange={({ target: { checked } }) => {
                                formik.setFieldValue(switchItem.name, checked)
                              }}
                              name={switchItem.name}
                              disabled={!usage?.[switchItem.name]}
                            />
                            <Typography
                              sx={{
                                fontSize: '16px',
                                lineHeight: '26px'
                              }}
                            >
                              {switchItem.label}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </React.Fragment>
              ))}
              <Box sx={{ display: 'flex', justifyContent: 'end', mt: '2rem' }}>
                <SaveButton
                  onClick={formik.handleSubmit}
                  isSubmitting={isSubmitting}
                  title={title}
                />
              </Box>
            </Box>
          </DialogContent>
        </form>
      </Dialog>
    </>
  )
}

export default PlanSetting

const SaveButton = ({ onClick, isSubmitting, title }) => {
  return (
    <Button
      sx={{
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '18px',
        py: 1,
        px: 2
      }}
      variant='contained'
      type='submit'
      onClick={onClick}
    >
      {isSubmitting
        ? (
          <>
            <CircularProgress
              color='inherit'
              size={20}
              thickness={5}
              sx={{ mr: 1 }}
            />
            Saving
          </>
          )
        : (
          <>
            {title === 'Add Plan' ? 'Save' : 'Update'}
          </>
          )}
    </Button>
  )
}
