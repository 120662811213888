import React from 'react'
import {
  Stack,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Grid,
  useTheme
} from '@mui/material'
import { useFormik } from 'formik'
import {
  useForgotPasswordMutation
} from 'src/services/auth-service.js'
import * as Yup from 'yup'
import toast from 'react-hot-toast'

export const ForgetPasswordForm = ({ whiteLabelTheme, onCancel }) => {
  const theme = useTheme()
  const [forgotPassword] = useForgotPasswordMutation()

  const formikForgotPassword = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Please enter a valid email address')
        .max(255)
        .required('Email is required.')
    }),
    onSubmit: async (values, helpers) => {
      try {
        const { message } = await forgotPassword(values).unwrap()
        toast.success(message)
        onCancel()
        formikForgotPassword.resetForm()
      } catch (err) {
        helpers.setErrors({ submit: err.data.error.message })
      }
    }
  })

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography
          sx={{
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '28px',
            color: `${theme.palette.primary.primaryText}`
          }}
        >
          {' '}
          Reset your password
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: '13px',
          fontWeight: 400,
          lineHeight: '20px',
          color: '#8181B0'
        }}
      >
        {' '}
        Please enter the email address associated with your account, and we'll dispatch a link
        to reset your password.
      </Typography>
      <Box>
        <Typography
          sx={{
            width: '100%',
            textAlign: 'left',
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: '20px',
            color: `${theme.palette.primary.primaryText}`
          }}
        >
          Email
        </Typography>
        <TextField
          autoFocus
          id='email'
          placeholder='Enter Email'
          type='email'
          fullWidth
          variant='outlined'
          error={!!(formikForgotPassword.touched.email && formikForgotPassword.errors.email)}
          helperText={formikForgotPassword.touched.email && formikForgotPassword.errors.email}
          name='email'
          onBlur={formikForgotPassword.handleBlur}
          onChange={formikForgotPassword.handleChange}
          value={formikForgotPassword.values.email}
          sx={{
            mt: 2,
            width: '100%',
            // height: 40,
            backgroundColor: 'white',
            '& div': { pl: 0.3 },
            '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
            '& div input': {
              py: 2,
              fontSize: '13px',
              fontWeight: 400,
              lineHeight: '16px',
              letterSpacing: '0em',
              '&::placeholder': {
                color: 'rgba(40, 40, 123, 0.5)'
              }
            },
            '& .MuiFormHelperText-root': { textAlign: 'right', mx: 0 }
          }}
        />{' '}
        {formikForgotPassword.errors.submit && (
          <Typography
            color='error'
            sx={{ mt: 3, width: '100%', textAlign: 'right' }}
            variant='body2'
          >
            {formikForgotPassword.errors.submit}
          </Typography>
        )}
      </Box>

      <Grid container sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
        <Grid item xs={5.8}>
          {' '}
          <Button
            fullWidth
            variant='outlined'
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '14px',
              py: 2,
              borderColor: whiteLabelTheme?.colors ? whiteLabelTheme?.colors?.main : theme.palette.primary.main,
              borderWidth: 1,
              color: whiteLabelTheme?.colors ? whiteLabelTheme?.colors?.main : theme.palette.primary.main
            }}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={5.8}>
          {' '}
          <Button
            fullWidth
            variant='contained'
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '14px',
              py: 2,
              backgroundColor: whiteLabelTheme?.colors ? whiteLabelTheme?.colors?.main : theme.palette.primary.main,
              '&:hover': {
                backgroundColor: whiteLabelTheme?.colors ? whiteLabelTheme?.colors?.dark : theme.palette.primary.dark
              }
            }}
            disabled={!formikForgotPassword.isValid}
            onClick={formikForgotPassword.handleSubmit}
          >
            {' '}
            {formikForgotPassword.isSubmitting
              ? (
                <CircularProgress size={20} color='inherit' />
                )
              : (
                <>
                  Submit
                </>
                )}
          </Button>
        </Grid>
      </Grid>
    </Stack>
  )
}
