import { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ThemeProvider, CssBaseline } from '@mui/material'
import { createTheme } from '.'
import { initializeTheme, useGetPartnerDetailsQuery } from 'src/services/theme-service'
import LoaderCircle from 'src/pages/LoaderCircle.js'
import { useGetMeQuery } from 'src/services/user-service.js'
import { getAuthToken } from 'src/services/auth-service'

export const ThemeInitializer = ({ children }) => {
  const dispatch = useDispatch()
  const shouldFetchAPI = getAuthToken()
  const { data: user, isLoading: isUserLoading } = useGetMeQuery(undefined, {
    skip: !shouldFetchAPI
  })

  const { theme, isInitialized } = useSelector((state) => state.partnerDetails)

  const shouldFetchPartnerDetails = useMemo(() => {
    if (!shouldFetchAPI) {
      return false
    }
    return Boolean(user?.isPartner || user?.partnerAccountId)
  }, [user])

  const {
    data: partnerDetails,
    isSuccess,
    isFetching
  } = useGetPartnerDetailsQuery(undefined, {
    skip: !shouldFetchPartnerDetails
  })

  // Track loading state to control when to show loader
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (isUserLoading || isFetching) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [isUserLoading, isFetching])

  useEffect(() => {
    if (!shouldFetchPartnerDetails) {
      dispatch(initializeTheme({
        theme: {
          colors: theme.colors,
          logo: theme.logo,
          companyName: theme.companyName
        },
        customDomain: null,
        seo: null
      }))
      return
    }

    if (isSuccess && partnerDetails && !!isInitialized) {
      dispatch(initializeTheme({
        theme: partnerDetails.theme,
        customDomain: partnerDetails.customDomain,
        seo: partnerDetails.seo
      }))
    }
  }, [isSuccess, partnerDetails, isInitialized, dispatch, shouldFetchPartnerDetails])

  const muiTheme = useMemo(() => {
    return createTheme({
      colorPreset: theme?.colors,
      contrast: 'high',
      themeLogo: theme?.logo,
      companyName: theme?.companyName
    })
  }, [theme])

  // Show the loader if loading, otherwise proceed with the theme initialization
  if (loading) {
    return <LoaderCircle />
  }

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}
