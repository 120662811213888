import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Stack, Typography, RadioGroup, Checkbox, FormControlLabel } from '@mui/material'
import { ComboBox, Tag, FilterDropdown, FilterAccordion, LockedFilter } from '../filterComponents'
import { KeywordsIcon } from 'src/icons/Keywords'
import { useTheme } from '@emotion/react'
import { OffCheckboxCustomIcon } from 'src/assets/general/OffCheckboxCustomIcon'
import { OnCheckboxCustomIcon } from 'src/assets/general/OnCheckboxCustomIcon'

const checkboxOfIcon = <OffCheckboxCustomIcon />
const checkboxOnIcon = <OnCheckboxCustomIcon />

export default function KeywordFilter ({ isLocked, onChange, type, plan, isOpen, onToggle }) {
  const theme = useTheme()
  const [selectedMethod, setSelectedMethod] = useState('')
  const [includedKeywords, setIncludedKeywords] = useState([])
  const [excludedKeywords, setExcludedKeywords] = useState([])
  const ref = useRef({ internalStateChange: false })
  const keywordsData = useSelector((state) => state.search[type === 'company' ? 'companyFilter' : 'filter'].skills)
  const excludedKeywordsData = useSelector((state) => state.search[type === 'company' ? 'companyFilter' : 'filter'].exclude_skills)
  const handleIncludedKeywords = useCallback((newValue, updateRedux = true) => {
    setIncludedKeywords(newValue)
    if (updateRedux) onChange('skills', newValue)
    ref.current.internalStateChange = true
  }, [onChange])

  const handleExcludedKeywords = useCallback((newValue, updateRedux = true) => {
    setExcludedKeywords(newValue)
    if (updateRedux) onChange('exclude_skills', newValue)
    ref.current.internalStateChange = true
  }, [onChange])

  const handleRadioBtn = useCallback((e) => {
    setSelectedMethod(e.target.value)
    handleIncludedKeywords([])
    handleExcludedKeywords([])
  }, [handleIncludedKeywords, handleExcludedKeywords])

  const clearAll = useCallback(() => {
    setSelectedMethod('')
    handleIncludedKeywords([])
    handleExcludedKeywords([])
  }, [handleIncludedKeywords, handleExcludedKeywords])

  useEffect(() => {
    if (ref.current.internalStateChange) {
      ref.current.internalStateChange = false
      return
    }
    setIncludedKeywords(keywordsData ?? [])
    setExcludedKeywords(excludedKeywordsData ?? [])
    setSelectedMethod(keywordsData ? 'include' : '')
  }, [keywordsData, excludedKeywordsData])

  const preview = (
    <>
      {(includedKeywords.length > 0 || excludedKeywords.length > 0) &&
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
          {includedKeywords.length > 0 &&
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
              <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
              <Typography variant='body2' sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600, paddingLeft: 1 }}>Include</Typography>
              {includedKeywords.map((lang, index) => <Tag key={index} label={lang} onDelete={() => handleIncludedKeywords(includedKeywords.filter(l => l !== lang))} />)}
            </Box>}
          {excludedKeywords.length > 0 &&
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
              <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
              <Typography variant='body2' sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600, paddingLeft: 1 }}>Exclude</Typography>
              {excludedKeywords.map((lang, index) => <Tag key={index} label={lang} onDelete={() => handleExcludedKeywords(excludedKeywords.filter(l => l !== lang))} isExclude />)}
            </Box>}
        </Box>}
    </>
  )

  if (isLocked) {
    return (
      <LockedFilter
        title={type === 'company' ? 'Company Keywords' : 'People Keywords'}
        icon={<KeywordsIcon sx={{ stroke: `${theme.palette.primary.main}` }} />}
        plan={plan}
      />
    )
  }

  return (
    <FilterDropdown
      title={type === 'company' ? 'Company Keywords' : 'People Keywords'}
      filterCount={includedKeywords.length + excludedKeywords.length}
      clearAll={clearAll}
      icon={<KeywordsIcon sx={{ stroke: `${theme.palette.primary.main}` }} />}
      preview={preview}
      isOpen={isOpen}
      onToggle={onToggle}
    >
      <Stack direction='column' gap={2} mt={2}>
        <RadioGroup
          value={selectedMethod}
          onChange={handleRadioBtn}
        >
          <Stack direction='column' gap={1}>
            {/* Included Keywords */}
            {/* <RadioBtnCollapse selected={selectedMethod} value='include' label='Include keywords'> */}
            <Typography sx={{ fontWeight: 700, fontSize: '14px' }}>Include keywords</Typography>
            <ComboBox
              freeSolo
              placeholder='e.g. Cloud AWS'
              options={[]}
              value={includedKeywords}
              onChange={(e, newValue) => { handleIncludedKeywords(newValue) }}
            />
            <FilterAccordion label='Type of keywords'>
              <Typography variant='body2' sx={{ fontSize: '14px', fontWeight: 500, color: '#101010' }}>What kind of keywords would you like to search for?</Typography>
              <CustomCheckbox label='Company name' />
              <CustomCheckbox label='Social media tags' />
              <CustomCheckbox label='Social media description' />
              <CustomCheckbox label='SEO description' />
            </FilterAccordion>
            {/* </RadioBtnCollapse> */}

            {/* Include all Keywords */}
            {/* <RadioBtnCollapse selected={selectedMethod} value='includeAll' label='Include all'>
              <ComboBox
                freeSolo
                placeholder='e.g. Cloud AWS'
                options={[]}
                value={includedKeywords}
                onChange={(e, newValue) => { handleIncludedKeywords(newValue) }}
              />
              <FilterAccordion label='Type of keywords'>
                <Typography variant='body2'>What kind of keywords would you like to search for?</Typography>
                <CustomCheckbox label='Company name' />
                <CustomCheckbox label='Social media tags' />
                <CustomCheckbox label='Social media description' />
                <CustomCheckbox label='SEO description' />
              </FilterAccordion>
            </RadioBtnCollapse> */}

            {/* Excluded Keywords */}
            {/* <RadioBtnCollapse selected={selectedMethod} value='exclude' label='Exclude keywords'> */}
            <Typography sx={{ fontWeight: 700, fontSize: '14px' }}>Exclude keywords</Typography>
            <ComboBox
              freeSolo
              placeholder='e.g. Cloud AWS'
              options={[]}
              value={excludedKeywords}
              onChange={(e, newValue) => { handleExcludedKeywords(newValue) }}
              isExclude
            />
            <FilterAccordion label='Type of keywords' sx={{ display: 'flex' }}>
              <Typography variant='body2'>What kind of keywords would you like to search for?</Typography>
              <CustomCheckbox label='Company name' />
              <CustomCheckbox label='Social media tags' />
              <CustomCheckbox label='Social media description' />
              <CustomCheckbox label='SEO description' />
            </FilterAccordion>
            {/* </RadioBtnCollapse> */}
          </Stack>
        </RadioGroup>
      </Stack>
    </FilterDropdown>
  )
}

function CustomCheckbox ({ label }) {
  return <FormControlLabel label={label} control={<Checkbox icon={checkboxOfIcon} checkedIcon={checkboxOnIcon} />} sx={{ '& .MuiFormControlLabel-label': { fontSize: '13px', fontWeight: 500, color: '#101010' } }} />
}
