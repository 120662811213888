import React, { useEffect, useState } from 'react'
import { Modal, Box, Typography, Button, TextField, useTheme, Grid } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-hot-toast'
import { useUpdateUserEmailMutation } from 'src/services/partnerUser-service'

const EmailSetting = ({ open, onClose, userId, userEmail }) => {
  const theme = useTheme()
  const [updateUserEmail, { isLoading }] = useUpdateUserEmailMutation()
  const [confirmOpen, setConfirmOpen] = useState(false)

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required')
      .test('no-child-account', 'No one with child account is allowed.', (value) => {
        return !value?.includes('+')
      })
  })

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema,
    onSubmit: async (values) => {
      const formattedEmail = values.email.trim().toLowerCase()
      try {
        const { message } = await updateUserEmail({ id: userId, email: formattedEmail }).unwrap()
        toast.success(message || 'Email updated successfully!')
        handleCloseModals()
      } catch (error) {
        toast.error(error.data.message || 'Failed to update email. Please try again.')
      }
    }
  })

  useEffect(() => {
    if (open) {
      formik.resetForm()
    }
  }, [open])

  const handleCloseModals = () => {
    setConfirmOpen(false)
    onClose()
  }

  const handleConfirm = () => {
    formik.handleSubmit()
    setConfirmOpen(false)
  }

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby='email-setting-modal-title'
        aria-describedby='email-setting-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 450,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4
          }}
        >
          <Typography
            id='email-setting-modal-title'
            sx={{
              fontSize: '20px',
              fontWeight: 700,
              lineHeight: '28px',
              color: `${theme.palette.primary.primaryText}`
            }}
          >
            {' '}
            Update User Email
          </Typography>
          <Typography id='email-setting-modal-description' sx={{ mt: 2 }}>
            Change the email for User:
            <Typography component='span' variant='h6' sx={{ ml: 2 }} color={theme.palette.primary.primaryText}>
              {userEmail}
            </Typography>
          </Typography>
          <form onSubmit={(e) => e.preventDefault()}>
            <TextField
              label='New Email'
              variant='outlined'
              fullWidth
              name='email'
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              sx={{ mt: 2 }}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <Grid container sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
              <Grid item xs={5.8}>
                <Button
                  type='button'
                  onClick={onClose}
                  fullWidth
                  variant='outlined'
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '14px',
                    py: 2,
                    borderColor: theme.palette.primary.main,
                    borderWidth: 1,
                    color: theme.palette.primary.main
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={5.8}>
                <Button
                  fullWidth
                  variant='contained'
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '14px',
                    py: 2,
                    backgroundColor: theme.palette.primary.main,
                    '&:hover': {
                      backgroundColor: theme.palette.primary.dark
                    }
                  }}
                  disabled={formik.values.email === '' || !formik.isValid || isLoading}
                  onClick={() => setConfirmOpen(true)}
                >
                  {isLoading ? 'Saving...' : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>

      <Modal
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        aria-labelledby='confirmation-modal-title'
        aria-describedby='confirmation-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4
          }}
        >
          <Typography id='confirmation-modal-title' variant='h6' component='h2'>
            Are you sure?
          </Typography>
          <Typography id='confirmation-modal-description' sx={{ mt: 2 }}>
            Do you want to update the email?
          </Typography>
          <Box container sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button
              type='button'
              onClick={() => setConfirmOpen(false)}
              variant='outlined'
              sx={{
                fontSize: '14px',
                fontWeight: 700,
                borderColor: theme.palette.primary.main,
                borderWidth: 1,
                color: theme.palette.primary.main
              }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              sx={{
                fontSize: '14px',
                fontWeight: 700,
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark
                }
              }}
              onClick={handleConfirm}
            >
              Update
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default EmailSetting
