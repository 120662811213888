import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const Loader = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: '#F2F4F6'
      }}
    >
      <CircularProgress />
    </Box>
  )
}

export default Loader
