import React from 'react'
import { Box, Dialog, DialogContent, DialogTitle, List, ListItem, Typography } from '@mui/material'

const TermsAndConditionsModal = ({ open, onClose, companyName = 'Success.ai', companyWebsite = 'www.success.ai', supportEmail = 'support@success.ai', isPartner = false }) => {
  let numbering = 1
  const termsList = [
    { text: 'In this Agreement, the following capitalised terms have the following meanings:' },
    { text: 'Agreement means these Terms and Conditions, including any attached policies, as well as any other documents referenced herein.' },
    { text: 'Business Day means any day other than a Saturday, Sunday, or public holiday, on which banks are open for business in the State.' },
    {
      text: 'Confidential Information encompasses all information of a confidential or proprietary nature, regardless of its form (whether tangible or not), that is disclosed or communicated by one party to the other during the course of this Agreement. This includes, but is not limited to, trade secrets, technical information, specifications, data, Intellectual Property Rights, marketing procedures, enablement procedures, documentation, pricing information, client and client records, as well as business, corporate, or trade information. However, Confidential Information does not include information that:',
      subList: [
        { text: '(a) Is or becomes publicly known through no fault or action of the Recipient or its personnel.' },
        { text: '(b) Is rightfully received by the Recipient from a third party without any obligation of confidentiality.' },
        { text: '(c) Was already known to the Recipient at the time the disclosing party made it available to the Recipient, except as a result of a disclosure known by the Recipient to be made in violation of an obligation of confidence.' },
        { text: '(d) Is independently developed by the Recipient without reference to the information of the disclosing party.' }
      ]
    },
    { text: 'Consequential Loss includes any indirect loss, incidental loss, consequential loss, loss of profits, loss of revenue, loss of production, loss of opportunity, loss of access to markets, loss of goodwill, loss of reputation, loss of use, any remote loss, abnormal loss, unforeseeable loss, loss of use, and/or loss or corruption of data. This also includes any loss or damage relating to business interruption or any other loss or damage suffered or incurred by a person, arising out of or in connection with this Agreement (whether involving a third party or a party to this Agreement or otherwise).' },
    { text: `Fair Use Policy denotes our Fair Use Policy available at https://${companyWebsite}/privacy, which sets forth the acceptable and fair use of our services.`, condition: !isPartner },
    { text: 'Fees encompass the fees as detailed in your Subscription Plan, which you are obligated to pay for the use of our services.' },
    { text: 'Intellectual Property Rights include all intellectual property rights, whether registrable or unregistrable, registered or unregistered, and any applications or rights of application for such rights. This includes copyrights, trademarks, trade secrets, patents, database rights, and any other proprietary rights.' },
    { text: 'Operating Environment signifies your operating environment, facilities, systems, networks, devices, equipment, hardware, software, telecommunications, and connections necessary to use our services effectively.' },
    { text: 'Permitted Purpose describes the authorized and intended use of our services, typically for your ordinary business use, as outlined in your Subscription Plan and the Fair Use Policy.' },
    { text: 'Personal Information aligns with the definition provided in the applicable Privacy Laws, and includes any information that can be used to identify an individual.' },
    { text: 'Privacy Laws represent the relevant data protection and privacy legislation, including but not limited to the Privacy Act, national laws implementing the Directive on Privacy and Electronic Communications, the General Data Protection Regulation (EU) 2016/679, and any other similar national privacy laws.' },
    { text: `Privacy Policy refers to our Privacy Policy available at https://${companyWebsite}/privacy, which outlines how we handle and protect personal information.`, condition: !isPartner },
    { text: `Service denotes the ${companyName} software, as described on our Website and the Subscription Plan, any Support Services we provide, and any other services agreed upon between the parties.` },
    { text: 'Specifications represent the specifications of the Service as outlined in the Subscription Plans, detailing its features, functionalities, and limitations.' },
    { text: `State refers to the State in which ${companyName} LLC is incorporated.` },
    { text: 'Subscription Period encompasses the period of time you choose upon purchasing the Subscription, including any renewal term as described in Clause 2.2 of this Agreement.' },
    { text: 'Subscription Plan refers to the tiered package or plan for the Service that you select, as outlined in the relevant information page on our Website, the Service itself, or other documents provided to you.' },
    { text: 'Support Services entail the Support Services specified in your Subscription Plan, or, if not specified, access to a general helpdesk during regular business hours on Business Days via a ticket system, aimed at assisting you in resolving technical issues or answering questions related to the Service during the Subscription Period.' },
    { text: 'Tax includes various forms of taxes, such as income, withholding, stamp, and transaction taxes, as well as duties, imposed by public authorities.' },
    { text: 'Third Party Service refers to any third-party software, hardware, plugin, API, gateway, payment processor, network platform, solution, database, product, or service used for providing the Service or integrating with it, and which is provided, operated, or controlled by a third party.' },
    { text: 'Unexpected Event encompasses events beyond the reasonable control of a party that hinder, prevent, or delay the performance of any obligations under this Agreement. Such events include but are not limited to natural disasters, war, lockouts, epidemics, pandemics, government regulations, and acts of terrorism.' },
    { text: 'Update signifies any updates, modifications, changes, or enhancements made to the Service, including the addition or removal of features, bug fixes, and patches.' },
    { text: 'User Data refers to any content or materials, including but not limited to Personal Information, information, data, text, graphics, photos, designs, trademarks, or any other artwork, that you upload or input into the Service or that is generated by you through the use of the Service.' },
    { text: 'Warranty or Warranties pertain to any warranties, conditions, terms, representations, statements, and promises of any nature, whether express or implied.' },
    { text: `Website represents our website, available at https://${companyWebsite}, where you can access information about our services.` },
    { text: 'You denotes any person who uses the Service and is bound by these Terms and Conditions.' }
  ]

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md'>
      <DialogTitle
        sx={{
          fontSize: '2.5rem',
          fontWeight: '700',
          textAlign: 'center',
          color: '#282876'
        }}
      >
        Terms & Conditions
      </DialogTitle>

      <DialogContent>
        <Box
          sx={{
            '&>.MuiTypography-h4': {
              marginTop: '10px'
            },
            '&>.MuiTypography-h5': {
              marginTop: '6px'
            },
            '&>.MuiTypography-body2': {
              marginTop: '4px'
            },
            '&>.MuiList-root': {
              listStyle: 'disc inside'
            },
            '& .MuiListItem-root': {
              display: 'list-item',
              paddingBlock: 0,
              fontSize: '0.875rem'
            }
          }}
        >
          <Typography variant='caption'>Last updated on 10/09/2023.</Typography>

          <Typography variant='h4'>1. Introduction</Typography>

          <List>
            <ListItem>1.1 This Agreement governs the use of the {companyName} LLC Service. By accessing or using the Service, you acknowledge your understanding and agreement to these terms.</ListItem>
            <ListItem>1.2 This Agreement is binding between you and {companyName} LLC, with its principal place of business at [Address], hereinafter referred to as "we", "us", or "our".</ListItem>
          </List>

          <Typography variant='h4'>2. Subscription and Renewal</Typography>

          <List>
            <ListItem>2.1 Your subscription begins upon successful payment and lasts for the chosen Subscription Period.</ListItem>
            <ListItem>2.2 Renewals are automatic at the end of each period unless you provide cancellation notice.</ListItem>
            <ListItem>2.3 We reserve the right to adjust pricing for the Service or any component thereof in any manner and at any time.</ListItem>
          </List>

          <Typography variant='h4'>3. Service Access and Restrictions</Typography>

          <List>
            <ListItem>3.1 We aim for continuous Service access but cannot guarantee uninterrupted availability due to potential maintenance or unforeseen issues.</ListItem>
            <ListItem>3.2 You agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of the Service without express written permission.</ListItem>
            <ListItem>3.3 We may modify, suspend, or discontinue the Service at our discretion.</ListItem>
          </List>

          <Typography variant='h4'>4. User Responsibilities</Typography>

          <List>
            <ListItem>4.1 You are responsible for maintaining the confidentiality of your account and for all activities.</ListItem>
            <ListItem>4.2 You must notify us immediately of unauthorized account use or security breaches.</ListItem>
            <ListItem>4.3 You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate.</ListItem>
          </List>

          <Typography variant='h4'>5. Service Usage and Fair Use Policy</Typography>

          <List>
            <ListItem>5.1 You must use the Service in compliance with all applicable laws and regulations.</ListItem>
            <ListItem>5.2 Misuse may result in account termination.</ListItem>
            <ListItem>5.3 We may establish usage limits, and excessive use beyond these limits may incur additional fees or restrictions.</ListItem>
          </List>

          <Typography variant='h4'>6. Support Services</Typography>

          <List>
            <ListItem>6.1 We provide Support Services during regular business hours. The scope and availability may change.</ListItem>
            <ListItem>6.2 We are not liable for delays, delivery failures, or other damages resulting from Support Services.</ListItem>
          </List>

          <Typography variant='h4'>7. Fees, Payments, and Refunds</Typography>

          <List>
            <ListItem>7.1 Fees are outlined in your Subscription Plan. Payments are due upfront.</ListItem>
            <ListItem>7.2 All fees exclude taxes, levies, or duties. You are responsible for payment of such taxes.</ListItem>
            <ListItem>7.3 Refunds are at our sole discretion and only in cases where we deem necessary.</ListItem>
          </List>

          <Typography variant='h4'>8. Updates and Modifications</Typography>

          <List>
            <ListItem>8.1 We may release updates or modifications to the Service. Some updates may be mandatory for continued access.</ListItem>
            <ListItem>8.2 Features and functionalities may be modified based on feedback, technological advancements, or business needs.</ListItem>
          </List>

          <Typography variant='h4'>9. Termination and Cancellation</Typography>

          <List>
            <ListItem>9.1 You may terminate at the end of the current Subscription Period. Post-termination, you will lose Service access.</ListItem>
            <ListItem>9.2 We reserve the right to terminate or suspend accounts for Agreement violations or other reasons at our discretion.</ListItem>
          </List>

          <Typography variant='h4'>10. Effects of Termination</Typography>

          <List>
            <ListItem>10.1 Upon termination, all rights and licenses granted to you will cease.</ListItem>
            <ListItem>10.2 Outstanding payments must be settled. No refunds will be provided for unused portions of the Subscription Period.</ListItem>
          </List>

          <Typography variant='h4'>11. Intellectual Property</Typography>

          <List>
            <ListItem>11.1 All rights, titles, and interests in the Service, including intellectual property rights, belong to {companyName} LLC.</ListItem>
            <ListItem>11.2 Feedback, comments, or suggestions regarding the Service may be used by us without obligation to you.</ListItem>
          </List>

          <Typography variant='h4'>12. User Data and Privacy</Typography>

          <List>
            <ListItem>12.1 You retain rights to data input into the Service. We will not use, disclose, or access User Data except to provide and improve the Service.</ListItem>
            <ListItem>12.2 Our collection, use, and disclosure of personal information are outlined in our Privacy Policy.</ListItem>
          </List>

          <Typography variant='h4'>13. Data Storage and Security</Typography>

          <List>
            <ListItem>13.1 We implement industry-standard security measures but cannot guarantee absolute security.</ListItem>
            <ListItem>13.2 Regular backups are conducted, but backup integrity cannot be guaranteed.</ListItem>
          </List>

          <Typography variant='h4'>14. Confidentiality</Typography>

          <List>
            <ListItem>14.1 Both parties agree to maintain confidentiality of all confidential information obtained during this Agreement's course.</ListItem>
          </List>

          <Typography variant='h4'>15. Limitation of Liability</Typography>

          <List>
            <ListItem>15.1 We shall not be liable for indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.</ListItem>
          </List>

          <Typography variant='h4'>16. Indemnification</Typography>

          <List>
            <ListItem>16.1 You agree to indemnify and hold harmless {companyName} LLC from any claims, damages, obligations, losses, liabilities, costs, and expenses arising from Service use.</ListItem>
          </List>

          <Typography variant='h4'>17. Governing Law</Typography>

          <List>
            <ListItem>17.1 This Agreement is governed by the laws of the State, without regard to its conflict of law principles.</ListItem>
          </List>

          <Typography variant='h4'>18. Miscellaneous Provisions</Typography>

          <List>
            <ListItem>18.1 This Agreement constitutes the entire agreement between parties and supersedes prior understandings related to the subject matter.</ListItem>
          </List>

          <Typography variant='h4'>19. Unexpected Events</Typography>

          <List>
            <ListItem>19.1 If an Unexpected Event prevents or delays either party from performing its obligations under this Agreement, neither party will be held liable for that delay or failure to perform.</ListItem>
            <ListItem>19.2 The affected party must promptly notify the other party of the Unexpected Event and its expected duration, and both parties will work together to find a reasonable solution.</ListItem>
          </List>

          <Typography variant='h4'>20. Third-Party Services</Typography>

          <List>
            <ListItem>20.1 The Service may integrate with or offer features based on third-party services. We are not responsible for the content, accuracy, or practices of these third-party services.</ListItem>
            <ListItem>20.2 You acknowledge that third-party services may be subject to their own terms and conditions.</ListItem>
          </List>

          <Typography variant='h4'>21. Dispute Resolution</Typography>

          <List>
            <ListItem>21.1 Any disputes arising out of this Agreement will first be attempted to be resolved through amicable negotiations.</ListItem>
            <ListItem>21.2 If negotiations fail, disputes will be settled through arbitration in the State, following the prevailing rules of arbitration.</ListItem>
          </List>

          <Typography variant='h4'>22. Amendments</Typography>

          <List>
            <ListItem>22.1 We reserve the right to modify or replace any part of this Agreement. It is your responsibility to check this Agreement periodically for changes.</ListItem>
            <ListItem>22.2 Your continued use of the Service following the posting of any changes to this Agreement constitutes acceptance of those changes.</ListItem>
          </List>

          <Typography variant='h4'>23. Waiver and Severability</Typography>

          <List>
            <ListItem>23.1 Our failure to enforce any right or provision of this Agreement will not be deemed a waiver of such right or provision.</ListItem>
            <ListItem>23.2 If any provision of this Agreement is held to be invalid or unenforceable, that provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions will remain in full force and effect.</ListItem>
          </List>

          <Typography variant='h4'>24. Assignment</Typography>

          <List>
            <ListItem>24.1 You may not assign or transfer this Agreement, by operation of law or otherwise, without our prior written consent. Any attempt by you to assign or transfer this Agreement, without such consent, will be null.</ListItem>
            <ListItem>24.2 We may assign, transfer, or delegate any of our rights and obligations without consent.</ListItem>
          </List>

          <Typography variant='h4'>25. Notices</Typography>

          <List>
            <ListItem>25.1 All notices under this Agreement will be in writing and will be deemed to have been duly given when received, if personally delivered; when receipt is electronically confirmed, if transmitted by email; or the day after being sent, if sent for next day delivery by recognized overnight delivery service.</ListItem>
          </List>

          <Typography variant='h4'>26. Feedback</Typography>

          <List>
            <ListItem>26.1 We welcome and encourage you to provide feedback, comments, and suggestions for improvements to the Service. Any feedback you submit will be considered non-confidential and non-proprietary to you.</ListItem>
          </List>

          <Typography variant='h4'>27. Entire Agreement</Typography>

          <List>
            <ListItem>27.1 This Agreement constitutes the entire agreement between the parties concerning its subject matter and supersedes all prior communications.</ListItem>
          </List>

          <Typography variant='h4'>28. Definitions</Typography>

          <List>
            {termsList.map((item, index) =>
              item.condition !== false && (
                <React.Fragment key={index}>
                  <ListItem>{`28.${numbering++} ${item.text}`}</ListItem>
                  {item.subList && (
                    <List>
                      {item.subList.map((subItem, subIndex) => (
                        <ListItem key={subIndex}>{subItem.text}</ListItem>
                      ))}
                    </List>
                  )}
                </React.Fragment>
              )
            )}
          </List>

          <Typography variant='h4'>29. Intellectual Property</Typography>
          <List>
            <ListItem>29.1 All content, design, graphics, compilation, magnetic translation, digital conversion, and other matters related to the Service are protected under applicable copyrights, trademarks, and other proprietary rights. The copying, redistribution, or publication by you of any part of the Service is strictly prohibited.</ListItem>
            <ListItem>29.2 You do not acquire ownership rights to any content or other materials viewed through the Service. The posting by {companyName} LLC of information or materials on the Service does not constitute a waiver of any right in such information and materials.</ListItem>
          </List>

          <Typography variant='h4'>30. User Responsibilities</Typography>

          <List>
            <ListItem>30.1 You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer.</ListItem>
            <ListItem>30.2 You agree to accept responsibility for all activities that occur under your account or password.</ListItem>
            <ListItem>30.3 You acknowledge that {companyName} LLC is not responsible for third-party access to your account that results from theft or misappropriation of your account.</ListItem>
          </List>

          <Typography variant='h4'>31. Termination</Typography>

          <List>
            <ListItem>31.1 We may terminate your access to the Service, without cause or notice, which may result in the forfeiture and destruction of all information associated with your account.</ListItem>
            <ListItem>31.2 If you wish to terminate your account, you may do so by following the instructions on the Service. Any fees paid to us are non-refundable, and any outstanding balance remains immediately due upon termination.</ListItem>
          </List>

          <Typography variant='h4'>32. Limitation on Liability</Typography>

          <List>
            <ListItem>32.1 In no event shall {companyName} LLC, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses.</ListItem>
            <ListItem>32.2 {companyName} LLC's total liability shall not exceed the amount paid by the user to {companyName} LLC over the 12 months preceding the claim.</ListItem>
          </List>

          <Typography variant='h4'>33. Indemnification</Typography>

          <List>
            <ListItem>33.1 You agree to defend, indemnify, and hold harmless {companyName} LLC, its parent corporation, officers, directors, employees, and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from your use of and access to the Service, or any violation of these terms.</ListItem>
          </List>

          <Typography variant='h4'>34. Governing Law</Typography>

          <List>
            <ListItem>34.1 This Agreement shall be governed by the laws of the State without regard to its conflict of law provisions. You and {companyName} LLC agree to submit to the personal and exclusive jurisdiction of the courts located within the State.</ListItem>
          </List>

          <Typography variant='h4'>35. Data Protection</Typography>

          <List>
            <ListItem>35.1 {companyName} LLC will take all necessary precautions to protect user data and ensure that data is not misused, accessed, disclosed, altered, or destroyed without authorization.</ListItem>
            <ListItem>35.2 Users have the right to access, correct, update, or request the deletion of their personal data at any time.</ListItem>
          </List>

          <Typography variant='h4'>36. Changes to the Agreement</Typography>

          <List>
            <ListItem>36.1 {companyName} LLC reserves the right to modify or replace these terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect.</ListItem>
          </List>

          <Typography variant='h4'>37. Contact Information</Typography>

          <List>
            <ListItem>37.1 For any questions about these terms, please contact us at {supportEmail}.</ListItem>
          </List>

        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default TermsAndConditionsModal
