import { Box } from '@mui/material'
import { LFJobTitles } from './leadFinder/LFFilter/LFJobTitles'
import { LFLocation } from './leadFinder/LFFilter/LFLocation'
import { LFIndustry } from './leadFinder/LFFilter/LFIndustry'
import { LFEmployees } from './leadFinder/LFFilter/LFEmployees'
import { LFTechnologies } from './leadFinder/LFFilter/LFTechnologies'
import { LFDepartment } from './leadFinder/LFFilter/LFDepartment'
import { LFName } from './leadFinder/LFFilter/LFName'
import { LFCompanyName } from './leadFinder/LFFilter/LFCompanyName'
import { Abc } from '@mui/icons-material'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
export const campaignData = [
  {
    id: 1,
    title: 'Your Campaign Title',
    created: '8 days',
    status: 'draft',
    sent: 0,
    opened: 0,
    replied: 0,
    linkClicked: 0,
    opportunities: 0,
    series: [
      {
        name: 'Total Sent',
        type: 'bar',
        data: [10, 0, 0, 0, 20, 0, 0]
      },
      {
        name: 'Total Opens',
        type: 'bar',
        data: [3, 0, 0, 0, 15, 0, 0]
      },
      {
        name: 'Total Replies',
        type: 'line',
        data: [1, 0, 0, 0, 0, 0, 0]
      }
    ]
  },
  {
    id: 2,
    title: 'Jon_Withlinks_11_05',
    created: '2 months',
    status: 'error',
    sent: 0,
    opened: 0,
    replied: 0,
    linkClicked: 0,
    opportunities: 0,
    series: [
      {
        name: 'Total Sent',
        type: 'bar',
        data: [0, 0, 0, 0, 0, 0, 0]
      },
      {
        name: 'Total Opens',
        type: 'bar',
        data: [0, 0, 0, 0, 0, 0, 0]
      },
      {
        name: 'Total Replies',
        type: 'line',
        data: [0, 0, 0, 0, 0, 0, 0]
      }
    ]
  },
  {
    id: 3,
    title: '26/04 Gmail Campaign10',
    created: '2 months',
    status: 'active',
    sent: 220,
    opened: 25,
    replied: 0,
    linkClicked: 0,
    opportunities: 0,
    series: [
      {
        name: 'Total Sent',
        type: 'bar',
        data: [0, 0, 20, 0, 0, 30, 0]
      },
      {
        name: 'Total Opens',
        type: 'bar',
        data: [0, 0, 5, 0, 0, 15, 0]
      },
      {
        name: 'Total Replies',
        type: 'line',
        data: [0, 0, 2, 0, 0, 0, 0]
      }
    ]
  },
  {
    id: 4,
    title: '26/04 Gmail Campaign09',
    created: '2 months',
    status: 'paused',
    sent: 220,
    opened: 12,
    replied: 0,
    linkClicked: 0,
    opportunities: 0,
    series: [
      {
        name: 'Total Sent',
        type: 'bar',
        data: [0, 0, 0, 0, 0, 0, 10]
      },
      {
        name: 'Total Opens',
        type: 'bar',
        data: [0, 0, 0, 0, 0, 0, 2]
      },
      {
        name: 'Total Replies',
        type: 'line',
        data: [0, 0, 0, 0, 0, 0, 0]
      }
    ]
  },
  {
    id: 5,
    title: '26/04 Gmail Campaign06',
    created: '2 months',
    status: 'completed',
    sent: 172,
    opened: 57,
    replied: 0,
    linkClicked: 0,
    opportunities: 0,
    series: [
      {
        name: 'Total Sent',
        type: 'bar',
        data: [0, 0, 150, 0, 0, 122, 0]
      },
      {
        name: 'Total Opens',
        type: 'bar',
        data: [0, 0, 75, 0, 0, 100, 0]
      },
      {
        name: 'Total Replies',
        type: 'line',
        data: [0, 0, 22, 0, 0, 15, 0]
      }
    ]
  }
]

// const TimeData = [
//   {
//     label: 'Last 3 months',
//     value: '0-90'
//   },
//   {
//     label: 'Last 6 months',
//     value: '0-180'
//   },
//   {
//     label: 'Last year',
//     value: '0-360'
//   },
//   {
//     label: 'Last 2 years',
//     value: '0-720'
//   },
//   {
//     label: 'Last 3 years',
//     value: '0-1080'
//   }
// ]

export const emailData = [
  {
    id: 1,
    email: 'annie@b2brocket.ai',
    emailsSentToday: 0,
    emailsSentPastWeek: 140,
    emailsLanded: 140,
    emailsSaved: 0,
    health: 100,
    warmup: 'pause',
    series: [
      {
        name: 'Warmup emails landed in spam',
        data: [1, 0, 0, 0, 0, 0]
      },
      {
        name: 'Warmup emails sent',
        data: [8, 9, 4, 6, 5, 6]
      }
    ]
  },
  {
    id: 2,
    email: 'danni@nftbrandsllc.com',
    emailsSentToday: 0,
    emailsSentPastWeek: 0,
    emailsLanded: 0,
    emailsSaved: 0,
    health: 0,
    warmup: 'disabled',
    series: [
      {
        name: 'Warmup emails landed in spam',
        data: [1, 0, 0, 0, 0, 0]
      },
      {
        name: 'Warmup emails sent',
        data: [8, 9, 4, 6, 5, 6]
      }
    ]
  },
  {
    id: 3,
    email: 'freddie@b2brocket.ai',
    emailsSentToday: 0,
    emailsSentPastWeek: 147,
    emailsLanded: 146,
    emailsSaved: 1,
    health: 99,
    warmup: 'enable',
    series: [
      {
        name: 'Warmup emails landed in spam',
        data: [1, 0, 4, 0, 0, 0]
      },
      {
        name: 'Warmup emails sent',
        data: [1, 5, 6, 9, 4, 2]
      }
    ]
  }
]

export const inboxData = [
  {
    id: 1,
    user: 'test1@company.io',
    date: 'Jul 3, 2023',
    content:
      'Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.'
  },
  {
    id: 2,
    user: 'test1@company.io',
    date: 'Jul 3, 2023',
    content:
      'Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.'
  },

  {
    id: 3,
    user: 'test2@company.io',
    date: 'Jul 3, 2023',
    content:
      'Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.'
  },
  {
    id: 4,
    user: 'test3@company.io',
    date: 'Jul 3, 2023',
    content:
      'Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.'
  },
  {
    id: 5,
    user: 'test4@company.io',
    date: 'Jul 3, 2023',
    content:
      'Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.'
  },
  {
    id: 6,
    user: 'test5@company.io',
    date: 'Jul 3, 2023',
    content:
      'Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.'
  },
  {
    id: 6,
    user: 'test5@company.io',
    date: 'Jul 3, 2023',
    content:
      'Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.'
  },
  {
    id: 6,
    user: 'test5@company.io',
    date: 'Jul 3, 2023',
    content:
      'Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.'
  },
  {
    id: 6,
    user: 'test5@company.io',
    date: 'Jul 3, 2023',
    content:
      'Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.'
  },
  {
    id: 6,
    user: 'test5@company.io',
    date: 'Jul 3, 2023',
    content:
      'Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.'
  },
  {
    id: 6,
    user: 'test5@company.io',
    date: 'Jul 3, 2023',
    content:
      'Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.'
  },
  {
    id: 6,
    user: 'test5@company.io',
    date: 'Jul 3, 2023',
    content:
      'Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.'
  },
  {
    id: 6,
    user: 'test5@company.io',
    date: 'Jul 3, 2023',
    content:
      'Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.'
  },
  {
    id: 6,
    user: 'test5@company.io',
    date: 'Jul 3, 2023',
    content:
      'Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.'
  },
  {
    id: 6,
    user: 'test5@company.io',
    date: 'Jul 3, 2023',
    content:
      'Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.'
  },
  {
    id: 6,
    user: 'test5@company.io',
    date: 'Jul 3, 2023',
    content:
      'Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.'
  },
  {
    id: 6,
    user: 'test5@company.io',
    date: 'Jul 3, 2023',
    content:
      'Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.'
  },
  {
    id: 6,
    user: 'test5@company.io',
    date: 'Jul 3, 2023',
    content:
      'Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.'
  },
  {
    id: 6,
    user: 'test5@company.io',
    date: 'Jul 3, 2023',
    content:
      'Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.'
  },
  {
    id: 6,
    user: 'test5@company.io',
    date: 'Jul 3, 2023',
    content:
      'Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.'
  },
  {
    id: 6,
    user: 'test5@company.io',
    date: 'Jul 3, 2023',
    content:
      'Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.'
  },
  {
    id: 6,
    user: 'test5@company.io',
    date: 'Jul 3, 2023',
    content:
      'Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.'
  },
  {
    id: 6,
    user: 'test5@company.io',
    date: 'Jul 3, 2023',
    content:
      'Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.'
  },
  {
    id: 6,
    user: 'test5@company.io',
    date: 'Jul 3, 2023',
    content:
      'Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.'
  }
]

export const selectTypes = [
  'IMAP Username',
  'IMAP Password',
  'IMAP Port',
  'IMAP Host',
  'SMTP Username',
  'SMTP Password',
  'SMTP Port',
  'SMTP Host',
  'Email',
  'First Name',
  'Last Name',
  'Reply To',
  'Daily Limit',
  'Sending gap in minutes',
  'Warmup Enabled',
  'Warmup Increment',
  'Warmup Limit',
  'Warmup | Reply Rate Percent',
  'Warmup | Read Emulation',
  'Warmup | Warm tracking domain',
  'Warmup | Random email range (min)',
  'Warmup | Random email range (max)',
  'Warmup | Open rate',
  'Warmup | Spam protection rate',
  'Warmup | Mark important rate',
  'Do not import'
]

export const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export const locationData = [
  {
    id: 0,
    label: 'All Locations',
    children: [
      'Afghanistan',
      'Åland Islands',
      'Albania',
      'Algeria',
      'American Samoa',
      'Andorra',
      'Angola',
      'Anguilla',
      'Antigua and Barbuda',
      'Argentina',
      'Armenia',
      'Aruba',
      'Australia',
      'Austria',
      'Azerbaijan',
      'Bangladesh',
      'Barbados',
      'Bahamas',
      'Bahrain',
      'Belarus',
      'Belgium',
      'Belize',
      'Benin',
      'Bermuda',
      'Bhutan',
      'Bolivia',
      'Bosnia and Herzegovina',
      'Botswana',
      'Brazil',
      'British Indian Ocean Territory',
      'British Virgin Islands',
      'Brunei Darussalam',
      'Bulgaria',
      'Burkina Faso',
      'Burma',
      'Burundi',
      'Cambodia',
      'Cameroon',
      'Canada',
      'Cape Verde',
      'Cayman Islands',
      'Central African Republic',
      'Chad',
      'Chile',
      'China',
      'Christmas Island',
      'Cocos (Keeling) Islands',
      'Colombia',
      'Comoros',
      'Congo-Brazzaville',
      'Congo-Kinshasa',
      'Cook Islands',
      'Costa Rica',
      '$_[',
      'Croatia',
      'Curaçao',
      'Cyprus',
      'Czech Republic',
      'Denmark',
      'Djibouti',
      'Dominica',
      'Dominican Republic',
      'East Timor',
      'Ecuador',
      'El Salvador',
      'Egypt',
      'Equatorial Guinea',
      'Eritrea',
      'Estonia',
      'Ethiopia',
      'Falkland Islands',
      'Faroe Islands',
      'Federated States of Micronesia',
      'Fiji',
      'Finland',
      'France',
      'French Guiana',
      'French Polynesia',
      'French Southern Lands',
      'Gabon',
      'Gambia',
      'Georgia',
      'Germany',
      'Ghana',
      'Gibraltar',
      'Greece',
      'Greenland',
      'Grenada',
      'Guadeloupe',
      'Guam',
      'Guatemala',
      'Guernsey',
      'Guinea',
      'Guinea-Bissau',
      'Guyana',
      'Haiti',
      'Heard and McDonald Islands',
      'Honduras',
      'Hong Kong',
      'Hungary',
      'Iceland',
      'India',
      'Indonesia',
      'Iraq',
      'Ireland',
      'Isle of Man',
      'Israel',
      'Italy',
      'Jamaica',
      'Japan',
      'Jersey',
      'Jordan',
      'Kazakhstan',
      'Kenya',
      'Kiribati',
      'Kuwait',
      'Kyrgyzstan',
      'Laos',
      'Latvia',
      'Lebanon',
      'Lesotho',
      'Liberia',
      'Libya',
      'Liechtenstein',
      'Lithuania',
      'Luxembourg',
      'Macau',
      'Macedonia',
      'Madagascar',
      'Malawi',
      'Malaysia',
      'Maldives',
      'Mali',
      'Malta',
      'Marshall Islands',
      'Martinique',
      'Mauritania',
      'Mauritius',
      'Mayotte',
      'Mexico',
      'Moldova',
      'Monaco',
      'Mongolia',
      'Montenegro',
      'Montserrat',
      'Morocco',
      'Mozambique',
      'Namibia',
      'Nauru',
      'Nepal',
      'Netherlands',
      'New Caledonia',
      'New Zealand',
      'Nicaragua',
      'Niger',
      'Nigeria',
      'Niue',
      'Norfolk Island',
      'Northern Mariana Islands',
      'Norway',
      'Oman',
      'Pakistan',
      'Palau',
      'Panama',
      'Papua New Guinea',
      'Paraguay',
      'Peru',
      'Philippines',
      'Pitcairn Islands',
      'Poland',
      'Portugal',
      'Puerto Rico',
      'Qatar',
      'Réunion',
      'Romania',
      'Russia',
      'Rwanda',
      'Saint Barthélemy',
      'Saint Helena',
      'Saint Kitts and Nevis',
      'Saint Lucia',
      'Saint Martin',
      'Saint Pierre and Miquelon',
      'Saint Vincent',
      'Samoa',
      'San Marino',
      'São Tomé and Príncipe',
      'Saudi Arabia',
      'Senegal',
      'Serbia',
      'Seychelles',
      'Sierra Leone',
      'Singapore',
      'Sint Maarten',
      'Slovakia',
      'Slovenia',
      'Solomon Islands',
      'Somalia',
      'South Africa',
      'South Georgia',
      'South Korea',
      'Spain',
      'Sri Lanka',
      'Sudan',
      'Suriname',
      'Svalbard and Jan Mayen',
      'Sweden',
      'Swaziland',
      'Switzerland',
      'Syria',
      'Taiwan',
      'Tajikistan',
      'Tanzania',
      'Thailand',
      'Togo',
      'Tokelau',
      'Tonga',
      'Trinidad and Tobago',
      'Tunisia',
      'Turkey',
      'Turkmenistan',
      'Turks and Caicos Islands',
      'Tuvalu',
      'Uganda',
      'Ukraine',
      'United Arab Emirates',
      'United Kingdom',
      'United States',
      'Uruguay',
      'Uzbekistan',
      'Vanuatu',
      'Vatican City',
      'Vietnam',
      'Venezuela',
      'Wallis and Futuna',
      'Western Sahara',
      'Yemen',
      'Zambia',
      'Zimbabwe'
    ]
  }
]

export const locationStateData = [
  {
    id: 0,
    label: 'All Locations',
    children: [
      ...new Set([
        'Badakhshan',
        'Badghis',
        'Baghlan',
        'Balkh',
        'Bamyan',
        'Daykundi',
        'Farah',
        'Faryab',
        'Ghazni',
        'Ghōr',
        'Helmand',
        'Herat',
        'Jowzjan',
        'Kabul',
        'Kandahar',
        'Kapisa',
        'Khost',
        'Kunar',
        'Kunduz Province',
        'Laghman',
        'Logar',
        'Nangarhar',
        'Nimruz',
        'Nuristan',
        'Paktia',
        'Paktika',
        'Panjshir',
        'Parwan',
        'Samangan',
        'Sar-e Pol',
        'Takhar',
        'Urozgan',
        'Zabul',
        'Berat County',
        'Berat District',
        'Bulqizë District',
        'Delvinë District',
        'Devoll District',
        'Dibër County',
        'Dibër District',
        'Durrës County',
        'Durrës District',
        'Elbasan County',
        'Fier County',
        'Fier District',
        'Gjirokastër County',
        'Gjirokastër District',
        'Gramsh District',
        'Has District',
        'Kavajë District',
        'Kolonjë District',
        'Korçë County',
        'Korçë District',
        'Krujë District',
        'Kuçovë District',
        'Kukës County',
        'Kukës District',
        'Kurbin District',
        'Lezhë County',
        'Lezhë District',
        'Librazhd District',
        'Lushnjë District',
        'Malësi e Madhe District',
        'Mallakastër District',
        'Mat District',
        'Mirditë District',
        'Peqin District',
        'Përmet District',
        'Pogradec District',
        'Pukë District',
        'Sarandë District',
        'Shkodër County',
        'Shkodër District',
        'Skrapar District',
        'Tepelenë District',
        'Tirana County',
        'Tirana District',
        'Tropojë District',
        'Vlorë County',
        'Vlorë District',
        'Adrar Province',
        'Aïn Defla Province',
        'Aïn Témouchent Province',
        'Algiers Province',
        'Annaba Province',
        'Batna Province',
        'Béchar Province',
        'Béjaïa Province',
        'Biskra',
        'Blida Province',
        'Bordj Bou Arréridj Province',
        'Bouïra Province',
        'Boumerdès Province',
        'Chlef Province',
        'Constantine Province',
        'Djelfa Province',
        'El Bayadh Province',
        'El Oued Province',
        'El Tarf Province',
        'Ghardaïa Province',
        'Guelma Province',
        'Illizi Province',
        'Jijel Province',
        'Khenchela Province',
        'Laghouat Province',
        "M'Sila Province",
        'Mascara Province',
        'Médéa Province',
        'Mila Province',
        'Mostaganem Province',
        'Naama Province',
        'Oran Province',
        'Ouargla Province',
        'Oum El Bouaghi Province',
        'Relizane Province',
        'Saïda Province',
        'Sétif Province',
        'Skikda Province',
        'Souk Ahras Province',
        'Tamanghasset Province',
        'Tébessa Province',
        'Tiaret Province',
        'Tindouf Province',
        'Tipasa Province',
        'Tissemsilt Province',
        'Tizi Ouzou Province',
        'Tlemcen Province',
        'Andorra la Vella',
        'Canillo',
        'Encamp',
        'Escaldes-Engordany',
        'La Massana',
        'Ordino',
        'Sant Julià de Lòria',
        'Bengo Province',
        'Benguela Province',
        'Bié Province',
        'Cabinda Province',
        'Cuando Cubango Province',
        'Cuanza Norte Province',
        'Cuanza Sul',
        'Cunene Province',
        'Huambo Province',
        'Huíla Province',
        'Luanda Province',
        'Lunda Norte Province',
        'Lunda Sul Province',
        'Malanje Province',
        'Moxico Province',
        'Uíge Province',
        'Zaire Province',
        'Barbuda',
        'Redonda',
        'Saint George Parish',
        'Saint John Parish',
        'Saint Mary Parish',
        'Saint Paul Parish',
        'Saint Peter Parish',
        'Saint Philip Parish',
        'Autonomous City Of Buenos Aires',
        'Buenos Aires Province',
        'Catamarca Province',
        'Chaco Province',
        'Chubut Province',
        'Córdoba Province',
        'Corrientes',
        'Entre Ríos Province',
        'Formosa Province',
        'Jujuy Province',
        'La Pampa',
        'La Rioja Province',
        'Mendoza',
        'Misiones Province',
        'Neuquén Province',
        'Río Negro Province',
        'Salta Province',
        'San Juan Province',
        'San Luis Province',
        'Santa Cruz Province',
        'Santa Fe Province',
        'Santiago del Estero Province',
        'Tierra del Fuego Province',
        'Tucumán Province',
        'Aragatsotn Region',
        'Ararat Province',
        'Armavir Region',
        'Gegharkunik Province',
        'Kotayk Region',
        'Lori Region',
        'Shirak Region',
        'Syunik Province',
        'Tavush Region',
        'Vayots Dzor Region',
        'Yerevan',
        'Australian Capital Territory',
        'New South Wales',
        'Northern Territory',
        'Queensland',
        'South Australia',
        'Tasmania',
        'Victoria',
        'Western Australia',
        'Burgenland',
        'Carinthia',
        'Lower Austria',
        'Salzburg',
        'Styria',
        'Tyrol',
        'Upper Austria',
        'Vienna',
        'Vorarlberg',
        'Absheron District',
        'Agdam District',
        'Agdash District',
        'Aghjabadi District',
        'Agstafa District',
        'Agsu District',
        'Astara District',
        'Babek District',
        'Baku',
        'Balakan District',
        'Barda District',
        'Beylagan District',
        'Bilasuvar District',
        'Dashkasan District',
        'Fizuli District',
        'Ganja',
        'Gədəbəy',
        'Gobustan District',
        'Goranboy District',
        'Goychay',
        'Goygol District',
        'Hajigabul District',
        'Imishli District',
        'Ismailli District',
        'Jabrayil District',
        'Jalilabad District',
        'Julfa District',
        'Kalbajar District',
        'Kangarli District',
        'Khachmaz District',
        'Khizi District',
        'Khojali District',
        'Kurdamir District',
        'Lachin District',
        'Lankaran',
        'Lankaran District',
        'Lerik District',
        'Martuni',
        'Masally District',
        'Mingachevir',
        'Nakhchivan Autonomous Republic',
        'Neftchala District',
        'Oghuz District',
        'Ordubad District',
        'Qabala District',
        'Qakh District',
        'Qazakh District',
        'Quba District',
        'Qubadli District',
        'Qusar District',
        'Saatly District',
        'Sabirabad District',
        'Sadarak District',
        'Salyan District',
        'Samukh District',
        'Shabran District',
        'Shahbuz District',
        'Shaki',
        'Shaki District',
        'Shamakhi District',
        'Shamkir District',
        'Sharur District',
        'Shirvan',
        'Shusha District',
        'Siazan District',
        'Sumqayit',
        'Tartar District',
        'Tovuz District',
        'Ujar District',
        'Yardymli District',
        'Yevlakh',
        'Yevlakh District',
        'Zangilan District',
        'Zaqatala District',
        'Zardab District',
        'Bagerhat District',
        'Bahadia',
        'Bandarban District',
        'Barguna District',
        'Barisal District',
        'Barisal Division',
        'Bhola District',
        'Bogra District',
        'Brahmanbaria District',
        'Chandpur District',
        'Chapai Nawabganj District',
        'Chittagong District',
        'Chittagong Division',
        'Chuadanga District',
        'Comilla District',
        "Cox's Bazar District",
        'Dhaka District',
        'Dhaka Division',
        'Dinajpur District',
        'Faridpur District',
        'Feni District',
        'Gaibandha District',
        'Gazipur District',
        'Gopalganj District',
        'Habiganj District',
        'Jamalpur District',
        'Jessore District',
        'Jhalokati District',
        'Jhenaidah District',
        'Joypurhat District',
        'Khagrachari District',
        'Khulna District',
        'Khulna Division',
        'Kishoreganj District',
        'Kurigram District',
        'Kushtia District',
        'Lakshmipur District',
        'Lalmonirhat District',
        'Madaripur District',
        'Meherpur District',
        'Moulvibazar District',
        'Munshiganj District',
        'Mymensingh District',
        'Mymensingh Division',
        'Naogaon District',
        'Narail District',
        'Narayanganj District',
        'Natore District',
        'Netrokona District',
        'Nilphamari District',
        'Noakhali District',
        'Pabna District',
        'Panchagarh District',
        'Patuakhali District',
        'Pirojpur District',
        'Rajbari District',
        'Rajshahi District',
        'Rajshahi Division',
        'Rangamati Hill District',
        'Rangpur District',
        'Rangpur Division',
        'Satkhira District',
        'Shariatpur District',
        'Sherpur District',
        'Sirajganj District',
        'Sunamganj District',
        'Sylhet District',
        'Sylhet Division',
        'Tangail District',
        'Thakurgaon District',
        'Christ Church',
        'Saint Andrew',
        'Saint George',
        'Saint James',
        'Saint John',
        'Saint Joseph',
        'Saint Lucy',
        'Saint Michael',
        'Saint Peter',
        'Saint Philip',
        'Saint Thomas',
        'Capital Governorate',
        'Central Governorate',
        'Muharraq Governorate',
        'Northern Governorate',
        'Southern Governorate',
        'Brest Region',
        'Gomel Region',
        'Grodno Region',
        'Minsk',
        'Minsk Region',
        'Mogilev Region',
        'Vitebsk Region',
        'Antwerp',
        'Brussels-Capital Region',
        'East Flanders',
        'Flanders',
        'Flemish Brabant',
        'Hainaut',
        'Liège',
        'Limburg',
        'Luxembourg',
        'Namur',
        'Wallonia',
        'Walloon Brabant',
        'West Flanders',
        'Belize District',
        'Cayo District',
        'Corozal District',
        'Orange Walk District',
        'Stann Creek District',
        'Toledo District',
        'Alibori Department',
        'Atakora Department',
        'Atlantique Department',
        'Borgou Department',
        'Collines Department',
        'Donga Department',
        'Kouffo Department',
        'Littoral Department',
        'Mono Department',
        'Ouémé Department',
        'Plateau Department',
        'Zou Department',
        'Devonshire Parish',
        'Hamilton Municipality',
        'Hamilton Parish',
        'Paget Parish',
        'Pembroke Parish',
        "Saint George's Municipality",
        "Saint George's Parish",
        'Sandys Parish',
        "Smith's Parish,",
        'Southampton Parish',
        'Warwick Parish',
        'Bumthang District',
        'Chukha District',
        'Dagana District',
        'Gasa District',
        'Haa District',
        'Lhuntse District',
        'Mongar District',
        'Paro District',
        'Pemagatshel District',
        'Punakha District',
        'Samdrup Jongkhar District',
        'Samtse District',
        'Sarpang District',
        'Thimphu District',
        'Trashigang District',
        'Trongsa District',
        'Tsirang District',
        'Wangdue Phodrang District',
        'Zhemgang District',
        'Beni Department',
        'Chuquisaca Department',
        'Cochabamba Department',
        'La Paz Department',
        'Oruro Department',
        'Pando Department',
        'Potosí Department',
        'Santa Cruz Department',
        'Tarija Department',
        'Bosnian Podrinje Canton',
        'Brčko District',
        'Canton 10',
        'Central Bosnia Canton',
        'Federation of Bosnia and Herzegovina',
        'Herzegovina-Neretva Canton',
        'Posavina Canton',
        'Republika Srpska',
        'Sarajevo Canton',
        'Tuzla Canton',
        'Una-Sana Canton',
        'West Herzegovina Canton',
        'Zenica-Doboj Canton',
        'Central District',
        'Ghanzi District',
        'Kgalagadi District',
        'Kgatleng District',
        'Kweneng District',
        'Ngamiland',
        'North-East District',
        'North-West District',
        'South-East District',
        'Southern District',
        'Acre',
        'Alagoas',
        'Amapá',
        'Amazonas',
        'Bahia',
        'Ceará',
        'Espírito Santo',
        'Federal District',
        'Goiás',
        'Maranhão',
        'Mato Grosso',
        'Mato Grosso do Sul',
        'Minas Gerais',
        'Pará',
        'Paraíba',
        'Paraná',
        'Pernambuco',
        'Piauí',
        'Rio de Janeiro',
        'Rio Grande do Norte',
        'Rio Grande do Sul',
        'Rondônia',
        'Roraima',
        'Santa Catarina',
        'São Paulo',
        'Sergipe',
        'Tocantins',
        'Blagoevgrad Province',
        'Burgas Province',
        'Dobrich Province',
        'Gabrovo Province',
        'Haskovo Province',
        'Kardzhali Province',
        'Kyustendil Province',
        'Lovech Province',
        'Montana Province',
        'Pazardzhik Province',
        'Pernik Province',
        'Pleven Province',
        'Plovdiv Province',
        'Razgrad Province',
        'Ruse Province',
        'Silistra Province',
        'Sliven Province',
        'Smolyan Province',
        'Sofia City Province',
        'Sofia Province',
        'Stara Zagora Province',
        'Targovishte Province',
        'Varna Province',
        'Veliko Tarnovo Province',
        'Vidin Province',
        'Vratsa Province',
        'Yambol Province',
        'Balé Province',
        'Bam Province',
        'Banwa Province',
        'Bazèga Province',
        'Boucle du Mouhoun Region',
        'Bougouriba Province',
        'Boulgou',
        'Cascades Region',
        'Centre',
        'Centre-Est Region',
        'Centre-Nord Region',
        'Centre-Ouest Region',
        'Centre-Sud Region',
        'Comoé Province',
        'Est Region',
        'Ganzourgou Province',
        'Gnagna Province',
        'Gourma Province',
        'Hauts-Bassins Region',
        'Houet Province',
        'Ioba Province',
        'Kadiogo Province',
        'Kénédougou Province',
        'Komondjari Province',
        'Kompienga Province',
        'Kossi Province',
        'Koulpélogo Province',
        'Kouritenga Province',
        'Kourwéogo Province',
        'Léraba Province',
        'Loroum Province',
        'Mouhoun',
        'Nahouri Province',
        'Namentenga Province',
        'Nayala Province',
        'Nord Region, Burkina Faso',
        'Noumbiel Province',
        'Oubritenga Province',
        'Oudalan Province',
        'Passoré Province',
        'Plateau-Central Region',
        'Poni Province',
        'Sahel Region',
        'Sanguié Province',
        'Sanmatenga Province',
        'Séno Province',
        'Sissili Province',
        'Soum Province',
        'Sourou Province',
        'Sud-Ouest Region',
        'Tapoa Province',
        'Tuy Province',
        'Yagha Province',
        'Yatenga Province',
        'Ziro Province',
        'Zondoma Province',
        'Zoundwéogo Province',
        'Bubanza Province',
        'Bujumbura Mairie Province',
        'Bujumbura Rural Province',
        'Bururi Province',
        'Cankuzo Province',
        'Cibitoke Province',
        'Gitega Province',
        'Karuzi Province',
        'Kayanza Province',
        'Kirundo Province',
        'Makamba Province',
        'Muramvya Province',
        'Muyinga Province',
        'Mwaro Province',
        'Ngozi Province',
        'Rumonge Province',
        'Rutana Province',
        'Ruyigi Province',
        'Banteay Meanchey Province',
        'Battambang Province',
        'Kampong Cham Province',
        'Kampong Chhnang Province',
        'Kampong Speu Province',
        'Kampot Province',
        'Kandal Province',
        'Kep Province',
        'Koh Kong Province',
        'Kratié Province',
        'Mondulkiri Province',
        'Oddar Meanchey Province',
        'Pailin Province',
        'Phnom Penh',
        'Preah Vihear Province',
        'Prey Veng Province',
        'Pursat Province',
        'Ratanakiri Province',
        'Siem Reap Province',
        'Sihanoukville Province',
        'Stung Treng Province',
        'Svay Rieng Province',
        'Takéo Province',
        'Adamawa',
        'East',
        'Far North',
        'Littoral',
        'North',
        'Northwest',
        'South',
        'Southwest',
        'West',
        'Alberta',
        'British Columbia',
        'Manitoba',
        'New Brunswick',
        'Newfoundland and Labrador',
        'Northwest Territories',
        'Nova Scotia',
        'Nunavut',
        'Ontario',
        'Prince Edward Island',
        'Quebec',
        'Saskatchewan',
        'Yukon',
        'Barlavento Islands',
        'Boa Vista',
        'Brava',
        'Maio Municipality',
        'Mosteiros',
        'Paul',
        'Porto Novo',
        'Praia',
        'Ribeira Brava Municipality',
        'Ribeira Grande',
        'Ribeira Grande de Santiago',
        'Sal',
        'Santa Catarina do Fogo',
        'Santa Cruz',
        'São Domingos',
        'São Filipe',
        'São Lourenço dos Órgãos',
        'São Miguel',
        'São Vicente',
        'Sotavento Islands',
        'Tarrafal',
        'Tarrafal de São Nicolau',
        'Bamingui-Bangoran Prefecture',
        'Bangui',
        'Basse-Kotto Prefecture',
        'Haut-Mbomou Prefecture',
        'Haute-Kotto Prefecture',
        'Kémo Prefecture',
        'Lobaye Prefecture',
        'Mambéré-Kadéï',
        'Mbomou Prefecture',
        'Nana-Grébizi Economic Prefecture',
        'Nana-Mambéré Prefecture',
        "Ombella-M'Poko Prefecture",
        'Ouaka Prefecture',
        'Ouham Prefecture',
        'Ouham-Pendé Prefecture',
        'Sangha-Mbaéré',
        'Vakaga Prefecture',
        'Bahr el Gazel',
        'Batha Region',
        'Borkou',
        'Ennedi Region',
        'Ennedi-Est',
        'Ennedi-Ouest',
        'Guéra Region',
        'Hadjer-Lamis',
        'Kanem Region',
        'Lac Region',
        'Logone Occidental Region',
        'Logone Oriental Region',
        'Mandoul Region',
        'Mayo-Kebbi Est Region',
        'Mayo-Kebbi Ouest Region',
        'Moyen-Chari Region',
        "N'Djamena",
        'Ouaddaï Region',
        'Salamat Region',
        'Sila Region',
        'Tandjilé Region',
        'Tibesti Region',
        'Wadi Fira Region',
        'Antofagasta Region',
        'Araucanía Region',
        'Arica y Parinacota Region',
        'Atacama Region',
        'Aysén Region',
        'Bío Bío Region',
        'Coquimbo Region',
        'Los Lagos Region',
        'Los Ríos Region',
        'Magellan and the Chilean Antarctic Region',
        'Maule Region',
        'Ñuble Region',
        "O'Higgins",
        'Santiago Metropolitan Region',
        'Tarapacá Region',
        'Valparaíso',
        'Anhui',
        'Beijing',
        'Chongqing',
        'Fujian',
        'Gansu',
        'Guangdong',
        'Guangxi Zhuang Autonomous Region',
        'Guizhou',
        'Hainan',
        'Hebei',
        'Heilongjiang',
        'Henan',
        'Hong Kong',
        'Hubei',
        'Hunan',
        'Inner Mongolia',
        'Jiangsu',
        'Jiangxi',
        'Jilin',
        'Keelung',
        'Liaoning',
        'Macau',
        'Ningxia Hui Autonomous Region',
        'Qinghai',
        'Shaanxi',
        'Shandong',
        'Shanghai',
        'Shanxi',
        'Sichuan',
        "Taiwan Province, People's Republic of China",
        'Tibet Autonomous Region',
        'Xinjiang',
        'Yunnan',
        'Zhejiang',
        'Amazonas Department',
        'Antioquia Department',
        'Arauca Department',
        'Archipelago of Saint Andréws, Providence and Saint Catalina',
        'Atlántico Department',
        'Bolívar Department',
        'Boyacá Department',
        'Caldas Department',
        'Caquetá Department',
        'Casanare Department',
        'Cauca Department',
        'Cesar Department',
        'Chocó Department',
        'Córdoba Department',
        'Cundinamarca Department',
        'Guainía Department',
        'Guaviare Department',
        'Huila Department',
        'La Guajira Department',
        'Magdalena Department',
        'Meta',
        'Nariño Department',
        'Norte de Santander Department',
        'Putumayo Department',
        'Quindío Department',
        'Risaralda Department',
        'Santander Department',
        'Sucre Department',
        'Tolima Department',
        'Valle del Cauca Department',
        'Vaupés Department',
        'Vichada Department',
        'Anjouan',
        'Grande Comore',
        'Mohéli',
        'Alajuela Province',
        'Guanacaste Province',
        'Heredia Province',
        'Limón Province',
        'Provincia de Cartago',
        'Puntarenas Province',
        'San José Province',
        'Bjelovar-Bilogora County',
        'Brod-Posavina County',
        'Dubrovnik-Neretva County',
        'Istria County',
        'Koprivnica-Križevci County',
        'Krapina-Zagorje County',
        'Lika-Senj County',
        'Međimurje County',
        'Osijek-Baranja County',
        'Požega-Slavonia County',
        'Primorje-Gorski Kotar County',
        'Šibenik-Knin County',
        'Sisak-Moslavina County',
        'Split-Dalmatia County',
        'Varaždin County',
        'Virovitica-Podravina County',
        'Vukovar-Syrmia County',
        'Zadar County',
        'Zagreb',
        'Zagreb County',
        'Famagusta District',
        'Kyrenia District',
        'Larnaca District',
        'Limassol District',
        'Nicosia District',
        'Paphos District',
        'Benešov District',
        'Beroun District',
        'Blansko District',
        'Břeclav District',
        'Brno-City District',
        'Brno-Country District',
        'Bruntál District',
        'Central Bohemian Region',
        'Česká Lípa District',
        'České Budějovice District',
        'Český Krumlov District',
        'Cheb District',
        'Chomutov District',
        'Chrudim District',
        'Děčín District',
        'Domažlice District',
        'Frýdek-Místek District',
        'Havlíčkův Brod District',
        'Hodonín District',
        'Horní Počernice',
        'Hradec Králové District',
        'Hradec Králové Region',
        'Jablonec nad Nisou District',
        'Jeseník District',
        'Jičín District',
        'Jihlava District',
        'Jindřichův Hradec District',
        'Karlovy Vary District',
        'Karlovy Vary Region',
        'Karviná District',
        'Kladno District',
        'Klatovy District',
        'Kolín District',
        'Kroměříž District',
        'Liberec District',
        'Liberec Region',
        'Litoměřice District',
        'Louny District',
        'Mělník District',
        'Mladá Boleslav District',
        'Moravian-Silesian Region',
        'Most District',
        'Náchod District',
        'Nový Jičín District',
        'Nymburk District',
        'Olomouc District',
        'Olomouc Region',
        'Opava District',
        'Ostrava-City District',
        'Pardubice District',
        'Pardubice Region',
        'Pelhřimov District',
        'Písek District',
        'Plzeň Region',
        'Plzeň-City District',
        'Plzeň-North District',
        'Plzeň-South District',
        'Prachatice District',
        'Prague',
        'Prague 1',
        'Prague 10',
        'Prague 11',
        'Prague 12',
        'Prague 13',
        'Prague 14',
        'Prague 15',
        'Prague 16',
        'Prague 2',
        'Prague 21',
        'Prague 3',
        'Prague 4',
        'Prague 5',
        'Prague 6',
        'Prague 7',
        'Prague 8',
        'Prague 9',
        'Prague-East District',
        'Prague-West District',
        'Přerov District',
        'Příbram District',
        'Prostějov District',
        'Rakovník District',
        'Rokycany District',
        'Rychnov nad Kněžnou District',
        'Semily District',
        'Sokolov District',
        'South Bohemian Region',
        'South Moravian Region',
        'Strakonice District',
        'Šumperk District',
        'Svitavy District',
        'Tábor District',
        'Tachov District',
        'Teplice District',
        'Třebíč District',
        'Trutnov District',
        'Uherské Hradiště District',
        'Ústí nad Labem District',
        'Ústí nad Labem Region',
        'Ústí nad Orlicí District',
        'Vsetín District',
        'Vyškov District',
        'Vysočina Region',
        'Žďár nad Sázavou District',
        'Zlín District',
        'Zlín Region',
        'Znojmo District',
        'Capital Region of Denmark',
        'Central Denmark Region',
        'North Denmark Region',
        'Region of Southern Denmark',
        'Region Zealand',
        'Ali Sabieh Region',
        'Arta Region',
        'Dikhil Region',
        'Djibouti',
        'Obock Region',
        'Tadjourah Region',
        'Saint Andrew Parish',
        'Saint David Parish',
        'Saint Joseph Parish',
        'Saint Luke Parish',
        'Saint Mark Parish',
        'Saint Patrick Parish',
        'Azua Province',
        'Baoruco Province',
        'Barahona Province',
        'Dajabón Province',
        'Distrito Nacional',
        'Duarte Province',
        'El Seibo Province',
        'Espaillat Province',
        'Hato Mayor Province',
        'Hermanas Mirabal Province',
        'Independencia',
        'La Altagracia Province',
        'La Romana Province',
        'La Vega Province',
        'María Trinidad Sánchez Province',
        'Monseñor Nouel Province',
        'Monte Cristi Province',
        'Monte Plata Province',
        'Pedernales Province',
        'Peravia Province',
        'Puerto Plata Province',
        'Samaná Province',
        'San Cristóbal Province',
        'San José de Ocoa Province',
        'San Pedro de Macorís',
        'Sánchez Ramírez Province',
        'Santiago Province',
        'Santiago Rodríguez Province',
        'Santo Domingo Province',
        'Valverde Province',
        'Azuay Province',
        'Bolívar Province',
        'Cañar Province',
        'Carchi Province',
        'Chimborazo Province',
        'Cotopaxi Province',
        'El Oro Province',
        'Esmeraldas',
        'Galápagos Province',
        'Guayas Province',
        'Imbabura Province',
        'Los Ríos Province',
        'Manabí Province',
        'Morona-Santiago Province',
        'Napo Province',
        'Orellana Province',
        'Pastaza Province',
        'Pichincha Province',
        'Santa Elena Province',
        'Santo Domingo de los Tsáchilas Province',
        'Sucumbíos Province',
        'Tungurahua Province',
        'Zamora-Chinchipe Province',
        'Ahuachapán Department',
        'Cabañas Department',
        'Chalatenango Department',
        'Cuscatlán Department',
        'La Libertad Department',
        'La Unión Department',
        'Morazán Department',
        'San Miguel Department',
        'San Salvador Department',
        'San Vicente Department',
        'Santa Ana Department',
        'Sonsonate Department',
        'Usulután Department',
        'Alexandria Governorate',
        'Aswan Governorate',
        'Asyut Governorate',
        'Beheira Governorate',
        'Beni Suef Governorate',
        'Cairo Governorate',
        'Dakahlia Governorate',
        'Damietta Governorate',
        'Faiyum Governorate',
        'Gharbia Governorate',
        'Giza Governorate',
        'Ismailia Governorate',
        'Kafr el-Sheikh Governorate',
        'Luxor Governorate',
        'Matrouh Governorate',
        'Minya Governorate',
        'Monufia Governorate',
        'New Valley Governorate',
        'North Sinai Governorate',
        'Port Said Governorate',
        'Qalyubia Governorate',
        'Qena Governorate',
        'Red Sea Governorate',
        'Sohag Governorate',
        'South Sinai Governorate',
        'Suez Governorate',
        'Annobón Province',
        'Bioko Norte Province',
        'Bioko Sur Province',
        'Centro Sur Province',
        'Insular Region',
        'Kié-Ntem Province',
        'Litoral Province',
        'Río Muni',
        'Wele-Nzas Province',
        'Anseba Region',
        'Debub Region',
        'Gash-Barka Region',
        'Maekel Region',
        'Northern Red Sea Region',
        'Southern Red Sea Region',
        'Harju County',
        'Hiiu County',
        'Ida-Viru County',
        'Järva County',
        'Jõgeva County',
        'Lääne County',
        'Lääne-Viru County',
        'Pärnu County',
        'Põlva County',
        'Rapla County',
        'Saare County',
        'Tartu County',
        'Valga County',
        'Viljandi County',
        'Võru County',
        'Addis Ababa',
        'Afar Region',
        'Amhara Region',
        'Benishangul-Gumuz Region',
        'Dire Dawa',
        'Gambela Region',
        'Harari Region',
        'Oromia Region',
        'Somali Region',
        "Southern Nations, Nationalities, and Peoples' Region",
        'Tigray Region',
        'Åland Islands',
        'Central Finland',
        'Central Ostrobothnia',
        'Eastern Finland Province',
        'Finland Proper',
        'Kainuu',
        'Kymenlaakso',
        'Lapland',
        'North Karelia',
        'Northern Ostrobothnia',
        'Northern Savonia',
        'Ostrobothnia',
        'Oulu Province',
        'Päijänne Tavastia',
        'Pirkanmaa',
        'Satakunta',
        'South Karelia',
        'Southern Ostrobothnia',
        'Southern Savonia',
        'Tavastia Proper',
        'Uusimaa',
        'Alo',
        'Alsace',
        'Aquitaine',
        'Auvergne',
        'Auvergne-Rhône-Alpes',
        'Bourgogne-Franche-Comté',
        'Brittany',
        'Burgundy',
        'Centre-Val de Loire',
        'Champagne-Ardenne',
        'Corsica',
        'Franche-Comté',
        'French Guiana',
        'French Polynesia',
        'Grand Est',
        'Guadeloupe',
        'Hauts-de-France',
        'Île-de-France',
        'Languedoc-Roussillon',
        'Limousin',
        'Lorraine',
        'Lower Normandy',
        'Martinique',
        'Mayotte',
        'Nord-Pas-de-Calais',
        'Normandy',
        'Nouvelle-Aquitaine',
        'Occitania',
        'Paris',
        'Pays de la Loire',
        'Picardy',
        'Poitou-Charentes',
        "Provence-Alpes-Côte d'Azur",
        'Réunion',
        'Rhône-Alpes',
        'Saint Barthélemy',
        'Saint Martin',
        'Saint Pierre and Miquelon',
        'Sigave',
        'Upper Normandy',
        'Uvea',
        'Wallis and Futuna',
        'Estuaire Province',
        'Haut-Ogooué Province',
        'Moyen-Ogooué Province',
        'Ngounié Province',
        'Nyanga Province',
        'Ogooué-Ivindo Province',
        'Ogooué-Lolo Province',
        'Ogooué-Maritime Province',
        'Woleu-Ntem Province',
        'Adjara',
        'Autonomous Republic of Abkhazia',
        'Guria',
        'Imereti',
        'Kakheti',
        'Khelvachauri Municipality',
        'Kvemo Kartli',
        'Mtskheta-Mtianeti',
        'Racha-Lechkhumi and Kvemo Svaneti',
        'Samegrelo-Zemo Svaneti',
        'Samtskhe-Javakheti',
        'Senaki Municipality',
        'Shida Kartli',
        'Tbilisi',
        'Baden-Württemberg',
        'Bavaria',
        'Berlin',
        'Brandenburg',
        'Bremen',
        'Hamburg',
        'Hesse',
        'Lower Saxony',
        'Mecklenburg-Vorpommern',
        'North Rhine-Westphalia',
        'Rhineland-Palatinate',
        'Saarland',
        'Saxony',
        'Saxony-Anhalt',
        'Schleswig-Holstein',
        'Thuringia',
        'Ashanti Region',
        'Brong-Ahafo Region',
        'Central Region',
        'Eastern Region',
        'Greater Accra Region',
        'Northern Region',
        'Upper East Region',
        'Upper West Region',
        'Volta Region',
        'Western Region',
        'Achaea Regional Unit',
        'Aetolia-Acarnania Regional Unit',
        'Arcadia Prefecture',
        'Argolis Regional Unit',
        'Attica Region',
        'Boeotia Regional Unit',
        'Central Greece Region',
        'Central Macedonia',
        'Chania Regional Unit',
        'Corfu Prefecture',
        'Corinthia Regional Unit',
        'Crete Region',
        'Drama Regional Unit',
        'East Attica Regional Unit',
        'East Macedonia and Thrace',
        'Epirus Region',
        'Euboea',
        'Grevena Prefecture',
        'Imathia Regional Unit',
        'Ioannina Regional Unit',
        'Ionian Islands Region',
        'Karditsa Regional Unit',
        'Kastoria Regional Unit',
        'Kefalonia Prefecture',
        'Kilkis Regional Unit',
        'Kozani Prefecture',
        'Laconia',
        'Larissa Prefecture',
        'Lefkada Regional Unit',
        'Pella Regional Unit',
        'Peloponnese Region',
        'Phthiotis Prefecture',
        'Preveza Prefecture',
        'Serres Prefecture',
        'South Aegean',
        'Thessaloniki Regional Unit',
        'West Greece Region',
        'West Macedonia Region',
        'Carriacou and Petite Martinique',
        'Alta Verapaz Department',
        'Baja Verapaz Department',
        'Chimaltenango Department',
        'Chiquimula Department',
        'El Progreso Department',
        'Escuintla Department',
        'Guatemala Department',
        'Huehuetenango Department',
        'Izabal Department',
        'Jalapa Department',
        'Jutiapa Department',
        'Petén Department',
        'Quetzaltenango Department',
        'Quiché Department',
        'Retalhuleu Department',
        'Sacatepéquez Department',
        'San Marcos Department',
        'Santa Rosa Department',
        'Sololá Department',
        'Suchitepéquez Department',
        'Totonicapán Department',
        'Beyla Prefecture',
        'Boffa Prefecture',
        'Boké Prefecture',
        'Boké Region',
        'Conakry',
        'Coyah Prefecture',
        'Dabola Prefecture',
        'Dalaba Prefecture',
        'Dinguiraye Prefecture',
        'Dubréka Prefecture',
        'Faranah Prefecture',
        'Forécariah Prefecture',
        'Fria Prefecture',
        'Gaoual Prefecture',
        'Guéckédou Prefecture',
        'Kankan Prefecture',
        'Kankan Region',
        'Kérouané Prefecture',
        'Kindia Prefecture',
        'Kindia Region',
        'Kissidougou Prefecture',
        'Koubia Prefecture',
        'Koundara Prefecture',
        'Kouroussa Prefecture',
        'Labé Prefecture',
        'Labé Region',
        'Lélouma Prefecture',
        'Lola Prefecture',
        'Macenta Prefecture',
        'Mali Prefecture',
        'Mamou Prefecture',
        'Mamou Region',
        'Mandiana Prefecture',
        'Nzérékoré Prefecture',
        'Nzérékoré Region',
        'Pita Prefecture',
        'Siguiri Prefecture',
        'Télimélé Prefecture',
        'Tougué Prefecture',
        'Yomou Prefecture',
        'Bafatá',
        'Biombo Region',
        'Bolama Region',
        'Cacheu Region',
        'Gabú Region',
        'Leste Province',
        'Norte Province',
        'Oio Region',
        'Quinara Region',
        'Sul Province',
        'Tombali Region',
        'Barima-Waini',
        'Cuyuni-Mazaruni',
        'Demerara-Mahaica',
        'East Berbice-Corentyne',
        'Essequibo Islands-West Demerara',
        'Mahaica-Berbice',
        'Pomeroon-Supenaam',
        'Potaro-Siparuni',
        'Upper Demerara-Berbice',
        'Upper Takutu-Upper Essequibo',
        'Artibonite',
        "Grand'Anse",
        'Nippes',
        'Nord',
        'Nord-Est',
        'Nord-Ouest',
        'Ouest',
        'Sud',
        'Sud-Est',
        'Atlántida Department',
        'Bay Islands Department',
        'Choluteca Department',
        'Colón Department',
        'Comayagua Department',
        'Copán Department',
        'Cortés Department',
        'El Paraíso Department',
        'Francisco Morazán Department',
        'Gracias a Dios Department',
        'Intibucá Department',
        'Lempira Department',
        'Ocotepeque Department',
        'Olancho Department',
        'Santa Bárbara Department',
        'Valle Department',
        'Yoro Department',
        'Bács-Kiskun County',
        'Baranya County',
        'Békés County',
        'Békéscsaba',
        'Borsod-Abaúj-Zemplén County',
        'Budapest',
        'Csongrád County',
        'Debrecen',
        'Dunaújváros',
        'Eger',
        'Érd',
        'Fejér County',
        'Győr',
        'Győr-Moson-Sopron County',
        'Hajdú-Bihar County',
        'Heves County',
        'Hódmezővásárhely',
        'Jász-Nagykun-Szolnok County',
        'Kaposvár',
        'Kecskemét',
        'Miskolc',
        'Nagykanizsa',
        'Nógrád County',
        'Nyíregyháza',
        'Pécs',
        'Pest County',
        'Salgótarján',
        'Somogy County',
        'Sopron',
        'Szabolcs-Szatmár-Bereg County',
        'Szeged',
        'Székesfehérvár',
        'Szekszárd',
        'Szolnok',
        'Szombathely',
        'Tatabánya',
        'Tolna County',
        'Vas County',
        'Veszprém',
        'Veszprém County',
        'Zala County',
        'Zalaegerszeg',
        'Capital Region',
        'Northeastern Region',
        'Northwestern Region',
        'Southern Peninsula Region',
        'Southern Region',
        'Westfjords',
        'Andaman and Nicobar Islands',
        'Andhra Pradesh',
        'Arunachal Pradesh',
        'Assam',
        'Bihar',
        'Chandigarh',
        'Chhattisgarh',
        'Dadra and Nagar Haveli',
        'Daman and Diu',
        'Delhi',
        'Goa',
        'Gujarat',
        'Haryana',
        'Himachal Pradesh',
        'Jammu and Kashmir',
        'Jharkhand',
        'Karnataka',
        'Kerala',
        'Ladakh',
        'Lakshadweep',
        'Madhya Pradesh',
        'Maharashtra',
        'Manipur',
        'Meghalaya',
        'Mizoram',
        'Nagaland',
        'Odisha',
        'Puducherry',
        'Punjab',
        'Rajasthan',
        'Sikkim',
        'Tamil Nadu',
        'Telangana',
        'Tripura',
        'Uttar Pradesh',
        'Uttarakhand',
        'West Bengal',
        'Aceh',
        'Bali',
        'Bangka Belitung Islands',
        'Banten',
        'Bengkulu',
        'Central Java',
        'Central Kalimantan',
        'Central Sulawesi',
        'East Java',
        'East Kalimantan',
        'East Nusa Tenggara',
        'Gorontalo',
        'Jakarta',
        'Jambi',
        'Kalimantan',
        'Lampung',
        'Lesser Sunda Islands',
        'Maluku',
        'Maluku Islands',
        'North Kalimantan',
        'North Maluku',
        'North Sulawesi',
        'North Sumatra',
        'Papua',
        'Riau',
        'Riau Islands',
        'South Kalimantan',
        'South Sulawesi',
        'South Sumatra',
        'Southeast Sulawesi',
        'Special Region of Yogyakarta',
        'Sulawesi',
        'Sumatra',
        'West Java',
        'West Nusa Tenggara',
        'West Papua',
        'West Sulawesi',
        'West Sumatra',
        'Al Anbar Governorate',
        'Al Muthanna Governorate',
        'Al-Qādisiyyah Governorate',
        'Babylon Governorate',
        'Baghdad Governorate',
        'Basra Governorate',
        'Dhi Qar Governorate',
        'Diyala Governorate',
        'Dohuk Governorate',
        'Erbil Governorate',
        'Karbala Governorate',
        'Kirkuk Governorate',
        'Maysan Governorate',
        'Najaf Governorate',
        'Nineveh Governorate',
        'Saladin Governorate',
        'Sulaymaniyah Governorate',
        'Wasit Governorate',
        'Connacht',
        'County Carlow',
        'County Cavan',
        'County Clare',
        'County Cork',
        'County Donegal',
        'County Dublin',
        'County Galway',
        'County Kerry',
        'County Kildare',
        'County Kilkenny',
        'County Laois',
        'County Limerick',
        'County Longford',
        'County Louth',
        'County Mayo',
        'County Meath',
        'County Monaghan',
        'County Offaly',
        'County Roscommon',
        'County Sligo',
        'County Tipperary',
        'County Waterford',
        'County Westmeath',
        'County Wexford',
        'County Wicklow',
        'Leinster',
        'Munster',
        'Ulster',
        'Haifa District',
        'Jerusalem District',
        'Northern District',
        'Tel Aviv District',
        'Abruzzo',
        'Aosta Valley',
        'Apulia',
        'Basilicata',
        'Benevento Province',
        'Calabria',
        'Campania',
        'Emilia-Romagna',
        'Friuli–Venezia Giulia',
        'Lazio',
        'Libero consorzio comunale di Agrigento',
        'Libero consorzio comunale di Caltanissetta',
        'Libero consorzio comunale di Enna',
        'Libero consorzio comunale di Ragusa',
        'Libero consorzio comunale di Siracusa',
        'Libero consorzio comunale di Trapani',
        'Liguria',
        'Lombardy',
        'Marche',
        'Metropolitan City of Bari',
        'Metropolitan City of Bologna',
        'Metropolitan City of Cagliari',
        'Metropolitan City of Catania',
        'Metropolitan City of Florence',
        'Metropolitan City of Genoa',
        'Metropolitan City of Messina',
        'Metropolitan City of Milan',
        'Metropolitan City of Naples',
        'Metropolitan City of Palermo',
        'Metropolitan City of Reggio Calabria',
        'Metropolitan City of Rome',
        'Metropolitan City of Turin',
        'Metropolitan City of Venice',
        'Molise',
        'Pesaro and Urbino Province',
        'Piedmont',
        'Province of Alessandria',
        'Province of Ancona',
        'Province of Ascoli Piceno',
        'Province of Asti',
        'Province of Avellino',
        'Province of Barletta-Andria-Trani',
        'Province of Belluno',
        'Province of Bergamo',
        'Province of Biella',
        'Province of Brescia',
        'Province of Brindisi',
        'Province of Campobasso',
        'Province of Carbonia-Iglesias',
        'Province of Caserta',
        'Province of Catanzaro',
        'Province of Chieti',
        'Province of Como',
        'Province of Cosenza',
        'Province of Cremona',
        'Province of Crotone',
        'Province of Cuneo',
        'Province of Fermo',
        'Province of Ferrara',
        'Province of Foggia',
        'Province of Forlì-Cesena',
        'Province of Frosinone',
        'Province of Gorizia',
        'Province of Grosseto',
        'Province of Imperia',
        'Province of Isernia',
        "Province of L'Aquila",
        'Province of La Spezia',
        'Province of Latina',
        'Province of Lecce',
        'Province of Lecco',
        'Province of Livorno',
        'Province of Lodi',
        'Province of Lucca',
        'Province of Macerata',
        'Province of Mantua',
        'Province of Massa and Carrara',
        'Province of Matera',
        'Province of Medio Campidano',
        'Province of Modena',
        'Province of Monza and Brianza',
        'Province of Novara',
        'Province of Nuoro',
        'Province of Ogliastra',
        'Province of Olbia-Tempio',
        'Province of Oristano',
        'Province of Padua',
        'Province of Parma',
        'Province of Pavia',
        'Province of Perugia',
        'Province of Pescara',
        'Province of Piacenza',
        'Province of Pisa',
        'Province of Pistoia',
        'Province of Pordenone',
        'Province of Potenza',
        'Province of Prato',
        'Province of Ravenna',
        'Province of Reggio Emilia',
        'Province of Rieti',
        'Province of Rimini',
        'Province of Rovigo',
        'Province of Salerno',
        'Province of Sassari',
        'Province of Savona',
        'Province of Siena',
        'Province of Sondrio',
        'Province of Taranto',
        'Province of Teramo',
        'Province of Terni',
        'Province of Treviso',
        'Province of Trieste',
        'Province of Udine',
        'Province of Varese',
        'Province of Verbano-Cusio-Ossola',
        'Province of Vercelli',
        'Province of Verona',
        'Province of Vibo Valentia',
        'Province of Vicenza',
        'Province of Viterbo',
        'Sardinia',
        'Sicily',
        'South Tyrol',
        'Trentino',
        'Trentino-South Tyrol',
        'Tuscany',
        'Umbria',
        'Veneto',
        'Clarendon Parish',
        'Hanover Parish',
        'Kingston Parish',
        'Manchester Parish',
        'Portland Parish',
        'Saint Ann Parish',
        'Saint Catherine Parish',
        'Saint Elizabeth Parish',
        'Saint James Parish',
        'Saint Thomas Parish',
        'Trelawny Parish',
        'Westmoreland Parish',
        'Aichi Prefecture',
        'Akita Prefecture',
        'Aomori Prefecture',
        'Chiba Prefecture',
        'Ehime Prefecture',
        'Fukui Prefecture',
        'Fukuoka Prefecture',
        'Fukushima Prefecture',
        'Gifu Prefecture',
        'Gunma Prefecture',
        'Hiroshima Prefecture',
        'Hokkaidō Prefecture',
        'Hyōgo Prefecture',
        'Ibaraki Prefecture',
        'Ishikawa Prefecture',
        'Iwate Prefecture',
        'Kagawa Prefecture',
        'Kagoshima Prefecture',
        'Kanagawa Prefecture',
        'Kumamoto Prefecture',
        'Kyōto Prefecture',
        'Mie Prefecture',
        'Miyagi Prefecture',
        'Miyazaki Prefecture',
        'Nagano Prefecture',
        'Nagasaki Prefecture',
        'Nara Prefecture',
        'Niigata Prefecture',
        'Ōita Prefecture',
        'Okayama Prefecture',
        'Okinawa Prefecture',
        'Ōsaka Prefecture',
        'Saga Prefecture',
        'Saitama Prefecture',
        'Shiga Prefecture',
        'Shimane Prefecture',
        'Shizuoka Prefecture',
        'Tochigi Prefecture',
        'Tokushima Prefecture',
        'Tokyo',
        'Tottori Prefecture',
        'Toyama Prefecture',
        'Wakayama Prefecture',
        'Yamagata Prefecture',
        'Yamaguchi Prefecture',
        'Yamanashi Prefecture',
        'Ajloun Governorate',
        'Amman Governorate',
        'Aqaba Governorate',
        'Balqa Governorate',
        'Irbid Governorate',
        'Jerash Governorate',
        'Karak Governorate',
        "Ma'an Governorate",
        'Madaba Governorate',
        'Mafraq Governorate',
        'Tafilah Governorate',
        'Zarqa Governorate',
        'Akmola Region',
        'Aktobe Region',
        'Almaty',
        'Almaty Region',
        'Atyrau Region',
        'Baikonur',
        'East Kazakhstan Region',
        'Jambyl Region',
        'Karaganda Region',
        'Kostanay Region',
        'Kyzylorda Region',
        'Mangystau Region',
        'North Kazakhstan Region',
        'Nur-Sultan',
        'Pavlodar Region',
        'Turkestan Region',
        'West Kazakhstan Province',
        'Baringo County',
        'Bomet County',
        'Bungoma County',
        'Busia County',
        'Central Province',
        'Coast Province',
        'Eastern Province',
        'Elgeyo-Marakwet County',
        'Embu County',
        'Garissa County',
        'Homa Bay County',
        'Isiolo County',
        'Kajiado County',
        'Kakamega County',
        'Kericho County',
        'Kiambu County',
        'Kilifi County',
        'Kirinyaga County',
        'Kisii County',
        'Kisumu County',
        'Kitui County',
        'Kwale County',
        'Laikipia County',
        'Lamu County',
        'Machakos County',
        'Makueni County',
        'Mandera County',
        'Marsabit County',
        'Meru County',
        'Migori County',
        'Mombasa County',
        'Muranga County',
        'Nairobi',
        'Nakuru District',
        'Nandi District',
        'Narok County',
        'North Eastern Province',
        'Nyamira District',
        'Nyandarua County',
        'Nyanza Province',
        'Nyeri County',
        'Rift Valley Province',
        'Samburu County',
        'Siaya County',
        'Taita–Taveta County',
        'Tana River County',
        'Tharaka Nithi County',
        'Trans-Nzoia District',
        'Turkana County',
        'Uasin Gishu District',
        'Vihiga District',
        'Wajir County',
        'West Pokot County',
        'Western Province',
        'Gilbert Islands',
        'Line Islands',
        'Phoenix Islands',
        'Haʻapai',
        'ʻEua',
        'Niuas',
        'Tongatapu',
        'Vavaʻu',
        'Arima',
        'Chaguanas',
        'Couva-Tabaquite-Talparo Regional Corporation',
        'Diego Martin Regional Corporation',
        'Eastern Tobago',
        'Penal-Debe Regional Corporation',
        'Point Fortin',
        'Port of Spain',
        'Princes Town Regional Corporation',
        'Rio Claro-Mayaro Regional Corporation',
        'San Fernando',
        'San Juan-Laventille Regional Corporation',
        'Sangre Grande Regional Corporation',
        'Siparia Regional Corporation',
        'Tunapuna-Piarco Regional Corporation',
        'Western Tobago',
        'Ariana Governorate',
        'Ben Arous Governorate',
        'Bizerte Governorate',
        'Gabès Governorate',
        'Gafsa Governorate',
        'Jendouba Governorate',
        'Kairouan Governorate',
        'Kasserine Governorate',
        'Kassrine',
        'Kebili Governorate',
        'Kef Governorate',
        'Mahdia Governorate',
        'Manouba Governorate',
        'Medenine Governorate',
        'Monastir Governorate',
        'Sfax Governorate',
        'Sidi Bouzid Governorate',
        'Siliana Governorate',
        'Sousse Governorate',
        'Tataouine Governorate',
        'Tozeur Governorate',
        'Tunis Governorate',
        'Zaghouan Governorate',
        'Adana Province',
        'Adıyaman Province',
        'Afyonkarahisar Province',
        'Ağrı Province',
        'Aksaray Province',
        'Amasya Province',
        'Ankara Province',
        'Antalya Province',
        'Ardahan Province',
        'Artvin Province',
        'Aydın Province',
        'Balıkesir Province',
        'Bartın Province',
        'Batman Province',
        'Bayburt Province',
        'Bilecik Province',
        'Bingöl Province',
        'Bitlis Province',
        'Bolu Province',
        'Burdur Province',
        'Bursa Province',
        'Çanakkale Province',
        'Çankırı Province',
        'Çorum Province',
        'Denizli Province',
        'Diyarbakır Province',
        'Düzce Province',
        'Edirne Province',
        'Elazığ Province',
        'Erzincan Province',
        'Erzurum Province',
        'Eskişehir Province',
        'Gaziantep Province',
        'Giresun Province',
        'Gümüşhane Province',
        'Hakkâri Province',
        'Hatay Province',
        'Iğdır Province',
        'Isparta Province',
        'Istanbul Province',
        'İzmir Province',
        'Kahramanmaraş Province',
        'Karabük Province',
        'Karaman Province',
        'Kars Province',
        'Kastamonu Province',
        'Kayseri Province',
        'Kilis Province',
        'Kırıkkale Province',
        'Kırklareli Province',
        'Kırşehir Province',
        'Kocaeli Province',
        'Konya Province',
        'Kütahya Province',
        'Malatya Province',
        'Manisa Province',
        'Mardin Province',
        'Mersin Province',
        'Muğla Province',
        'Muş Province',
        'Nevşehir Province',
        'Niğde Province',
        'Ordu Province',
        'Osmaniye Province',
        'Rize Province',
        'Sakarya Province',
        'Samsun Province',
        'Şanlıurfa Province',
        'Siirt Province',
        'Sinop Province',
        'Sivas Province',
        'Şırnak Province',
        'Tekirdağ Province',
        'Tokat Province',
        'Trabzon Province',
        'Tunceli Province',
        'Uşak Province',
        'Van Province',
        'Yalova Province',
        'Yozgat Province',
        'Zonguldak Province',
        'Ahal Region',
        'Ashgabat',
        'Balkan Region',
        'Daşoguz Region',
        'Lebap Region',
        'Mary Region',
        'Funafuti',
        'Nanumanga',
        'Nanumea',
        'Niutao Island Council',
        'Nui',
        'Nukufetau',
        'Nukulaelae',
        'Vaitupu',
        'Abim District',
        'Adjumani District',
        'Agago District',
        'Alebtong District',
        'Amolatar District',
        'Amudat District',
        'Amuria District',
        'Amuru District',
        'Apac District',
        'Arua District',
        'Budaka District',
        'Bududa District',
        'Bugiri District',
        'Buhweju District',
        'Buikwe District',
        'Bukedea District',
        'Bukomansimbi District',
        'Bukwo District',
        'Bulambuli District',
        'Buliisa District',
        'Bundibugyo District',
        'Bunyangabu District',
        'Bushenyi District',
        'Busia District',
        'Butaleja District',
        'Butambala District',
        'Butebo District',
        'Buvuma District',
        'Buyende District',
        'Dokolo District',
        'Gomba District',
        'Gulu District',
        'Ibanda District',
        'Iganga District',
        'Isingiro District',
        'Jinja District',
        'Kaabong District',
        'Kabale District',
        'Kabarole District',
        'Kaberamaido District',
        'Kagadi District',
        'Kakumiro District',
        'Kalangala District',
        'Kaliro District',
        'Kalungu District',
        'Kampala District',
        'Kamuli District',
        'Kamwenge District',
        'Kanungu District',
        'Kapchorwa District',
        'Kasese District',
        'Katakwi District',
        'Kayunga District',
        'Kibaale District',
        'Kiboga District',
        'Kibuku District',
        'Kiruhura District',
        'Kiryandongo District',
        'Kisoro District',
        'Kitgum District',
        'Koboko District',
        'Kole District',
        'Kotido District',
        'Kumi District',
        'Kween District',
        'Kyankwanzi District',
        'Kyegegwa District',
        'Kyenjojo District',
        'Kyotera District',
        'Lamwo District',
        'Lira District',
        'Luuka District',
        'Luwero District',
        'Lwengo District',
        'Lyantonde District',
        'Manafwa District',
        'Maracha District',
        'Masaka District',
        'Masindi District',
        'Mayuge District',
        'Mbale District',
        'Mbarara District',
        'Mitooma District',
        'Mityana District',
        'Moroto District',
        'Moyo District',
        'Mpigi District',
        'Mubende District',
        'Mukono District',
        'Nakapiripirit District',
        'Nakaseke District',
        'Nakasongola District',
        'Namayingo District',
        'Namisindwa District',
        'Namutumba District',
        'Napak District',
        'Nebbi District',
        'Ngora District',
        'Ntoroko District',
        'Ntungamo District',
        'Nwoya District',
        'Omoro District',
        'Otuke District',
        'Oyam District',
        'Pader District',
        'Pakwach District',
        'Pallisa District',
        'Rakai District',
        'Rubanda District',
        'Rubirizi District',
        'Rukiga District',
        'Rukungiri District',
        'Sembabule District',
        'Serere District',
        'Sheema District',
        'Sironko District',
        'Soroti District',
        'Tororo District',
        'Wakiso District',
        'Yumbe District',
        'Zombo District',
        'Autonomous Republic of Crimea',
        'Cherkasy Oblast',
        'Chernihiv Oblast',
        'Chernivtsi Oblast',
        'Dnipropetrovsk Oblast',
        'Donetsk Oblast',
        'Ivano-Frankivsk Oblast',
        'Kharkiv Oblast',
        'Kherson Oblast',
        'Khmelnytsky Oblast',
        'Kiev',
        'Kirovohrad Oblast',
        'Kyiv Oblast',
        'Luhansk Oblast',
        'Lviv Oblast',
        'Mykolaiv Oblast',
        'Odessa Oblast',
        'Rivne Oblast',
        'Sumy Oblast',
        'Ternopil Oblast',
        'Vinnytsia Oblast',
        'Volyn Oblast',
        'Zakarpattia Oblast',
        'Zaporizhzhya Oblast',
        'Zhytomyr Oblast',
        'Abu Dhabi Emirate',
        'Ajman Emirate',
        'Dubai',
        'Fujairah',
        'Ras al-Khaimah',
        'Sharjah Emirate',
        'Umm al-Quwain',
        'Aberdeen',
        'Aberdeenshire',
        'Angus',
        'Antrim',
        'Antrim and Newtownabbey',
        'Ards',
        'Ards and North Down',
        'Argyll and Bute',
        'Armagh City and District Council',
        'Armagh, Banbridge and Craigavon',
        'Ascension Island',
        'Ballymena Borough',
        'Ballymoney',
        'Banbridge',
        'Barnsley',
        'Bath and North East Somerset',
        'Bedford',
        'Belfast district',
        'Birmingham',
        'Blackburn with Darwen',
        'Blackpool',
        'Blaenau Gwent County Borough',
        'Bolton',
        'Bournemouth',
        'Bracknell Forest',
        'Bradford',
        'Bridgend County Borough',
        'Brighton and Hove',
        'Buckinghamshire',
        'Bury',
        'Caerphilly County Borough',
        'Calderdale',
        'Cambridgeshire',
        'Carmarthenshire',
        'Carrickfergus Borough Council',
        'Castlereagh',
        'Causeway Coast and Glens',
        'Central Bedfordshire',
        'Ceredigion',
        'Cheshire East',
        'Cheshire West and Chester',
        'City and County of Cardiff',
        'City and County of Swansea',
        'City of Bristol',
        'City of Derby',
        'City of Kingston upon Hull',
        'City of Leicester',
        'City of London',
        'City of Nottingham',
        'City of Peterborough',
        'City of Plymouth',
        'City of Portsmouth',
        'City of Southampton',
        'City of Stoke-on-Trent',
        'City of Sunderland',
        'City of Westminster',
        'City of Wolverhampton',
        'City of York',
        'Clackmannanshire',
        'Coleraine Borough Council',
        'Conwy County Borough',
        'Cookstown District Council',
        'Cornwall',
        'County Durham',
        'Coventry',
        'Craigavon Borough Council',
        'Cumbria',
        'Darlington',
        'Denbighshire',
        'Derbyshire',
        'Derry City and Strabane',
        'Derry City Council',
        'Devon',
        'Doncaster',
        'Dorset',
        'Down District Council',
        'Dudley',
        'Dumfries and Galloway',
        'Dundee',
        'Dungannon and South Tyrone Borough Council',
        'East Ayrshire',
        'East Dunbartonshire',
        'East Lothian',
        'East Renfrewshire',
        'East Riding of Yorkshire',
        'East Sussex',
        'Edinburgh',
        'England',
        'Essex',
        'Falkirk',
        'Fermanagh and Omagh',
        'Fermanagh District Council',
        'Fife',
        'Flintshire',
        'Gateshead',
        'Glasgow',
        'Gloucestershire',
        'Gwynedd',
        'Halton',
        'Hampshire',
        'Hartlepool',
        'Herefordshire',
        'Hertfordshire',
        'Highland',
        'Inverclyde',
        'Isle of Wight',
        'Isles of Scilly',
        'Kent',
        'Kirklees',
        'Knowsley',
        'Lancashire',
        'Larne Borough Council',
        'Leeds',
        'Leicestershire',
        'Limavady Borough Council',
        'Lincolnshire',
        'Lisburn and Castlereagh',
        'Lisburn City Council',
        'Liverpool',
        'London Borough of Barking and Dagenham',
        'London Borough of Barnet',
        'London Borough of Bexley',
        'London Borough of Brent',
        'London Borough of Bromley',
        'London Borough of Camden',
        'London Borough of Croydon',
        'London Borough of Ealing',
        'London Borough of Enfield',
        'London Borough of Hackney',
        'London Borough of Hammersmith and Fulham',
        'London Borough of Haringey',
        'London Borough of Harrow',
        'London Borough of Havering',
        'London Borough of Hillingdon',
        'London Borough of Hounslow',
        'London Borough of Islington',
        'London Borough of Lambeth',
        'London Borough of Lewisham',
        'London Borough of Merton',
        'London Borough of Newham',
        'London Borough of Redbridge',
        'London Borough of Richmond upon Thames',
        'London Borough of Southwark',
        'London Borough of Sutton',
        'London Borough of Tower Hamlets',
        'London Borough of Waltham Forest',
        'London Borough of Wandsworth',
        'Magherafelt District Council',
        'Manchester',
        'Medway',
        'Merthyr Tydfil County Borough',
        'Metropolitan Borough of Wigan',
        'Mid and East Antrim',
        'Mid Ulster',
        'Middlesbrough',
        'Midlothian',
        'Milton Keynes',
        'Monmouthshire',
        'Moray',
        'Moyle District Council',
        'Neath Port Talbot County Borough',
        'Newcastle upon Tyne',
        'Newport',
        'Newry and Mourne District Council',
        'Newry, Mourne and Down',
        'Newtownabbey Borough Council',
        'Norfolk',
        'North Ayrshire',
        'North Down Borough Council',
        'North East Lincolnshire',
        'North Lanarkshire',
        'North Lincolnshire',
        'North Somerset',
        'North Tyneside',
        'North Yorkshire',
        'Northamptonshire',
        'Northern Ireland',
        'Northumberland',
        'Nottinghamshire',
        'Oldham',
        'Omagh District Council',
        'Orkney Islands',
        'Outer Hebrides',
        'Oxfordshire',
        'Pembrokeshire',
        'Perth and Kinross',
        'Poole',
        'Powys',
        'Reading',
        'Redcar and Cleveland',
        'Renfrewshire',
        'Rhondda Cynon Taf',
        'Rochdale',
        'Rotherham',
        'Royal Borough of Greenwich',
        'Royal Borough of Kensington and Chelsea',
        'Royal Borough of Kingston upon Thames',
        'Rutland',
        'Saint Helena',
        'Salford',
        'Sandwell',
        'Scotland',
        'Scottish Borders',
        'Sefton',
        'Sheffield',
        'Shetland Islands',
        'Shropshire',
        'Slough',
        'Solihull',
        'Somerset',
        'South Ayrshire',
        'South Gloucestershire',
        'South Lanarkshire',
        'South Tyneside',
        'Southend-on-Sea',
        'St Helens',
        'Staffordshire',
        'Stirling',
        'Stockport',
        'Stockton-on-Tees',
        'Strabane District Council',
        'Suffolk',
        'Surrey',
        'Swindon',
        'Tameside',
        'Telford and Wrekin',
        'Thurrock',
        'Torbay',
        'Torfaen',
        'Trafford',
        'United Kingdom',
        'Vale of Glamorgan',
        'Wakefield',
        'Wales',
        'Walsall',
        'Warrington',
        'Warwickshire',
        'West Berkshire',
        'West Dunbartonshire',
        'West Lothian',
        'West Sussex',
        'Wiltshire',
        'Windsor and Maidenhead',
        'Wirral',
        'Wokingham',
        'Worcestershire',
        'Wrexham County Borough',
        'Alabama',
        'Alaska',
        'American Samoa',
        'Arizona',
        'Arkansas',
        'Baker Island',
        'California',
        'Colorado',
        'Connecticut',
        'Delaware',
        'District of Columbia',
        'Florida',
        'Georgia',
        'Guam',
        'Hawaii',
        'Howland Island',
        'Idaho',
        'Illinois',
        'Indiana',
        'Iowa',
        'Jarvis Island',
        'Johnston Atoll',
        'Kansas',
        'Kentucky',
        'Kingman Reef',
        'Louisiana',
        'Maine',
        'Maryland',
        'Massachusetts',
        'Michigan',
        'Midway Atoll',
        'Minnesota',
        'Mississippi',
        'Missouri',
        'Montana',
        'Navassa Island',
        'Nebraska',
        'Nevada',
        'New Hampshire',
        'New Jersey',
        'New Mexico',
        'New York State',
        'North Carolina',
        'North Dakota',
        'Northern Mariana Islands',
        'Ohio',
        'Oklahoma',
        'Oregon',
        'Palmyra Atoll',
        'Pennsylvania',
        'Puerto Rico',
        'Rhode Island',
        'South Carolina',
        'South Dakota',
        'Tennessee',
        'Texas',
        'United States Minor Outlying Islands',
        'United States Virgin Islands',
        'Utah',
        'Vermont',
        'Virginia',
        'Wake Island',
        'Washington',
        'West Virginia',
        'Wisconsin',
        'Wyoming',
        'Artigas Department',
        'Canelones Department',
        'Cerro Largo Department',
        'Colonia Department',
        'Durazno Department',
        'Flores Department',
        'Florida Department',
        'Lavalleja Department',
        'Maldonado Department',
        'Montevideo Department',
        'Paysandú Department',
        'Río Negro Department',
        'Rivera Department',
        'Rocha Department',
        'Salto Department',
        'San José Department',
        'Soriano Department',
        'Tacuarembó Department',
        'Treinta y Tres Department',
        'Andijan Region',
        'Bukhara Region',
        'Fergana Region',
        'Jizzakh Region',
        'Karakalpakstan',
        'Namangan Region',
        'Navoiy Region',
        'Qashqadaryo Region',
        'Samarqand Region',
        'Sirdaryo Region',
        'Surxondaryo Region',
        'Tashkent',
        'Tashkent Region',
        'Xorazm Region',
        'Malampa',
        'Penama',
        'Sanma',
        'Shefa',
        'Tafea',
        'Torba',
        'An Giang',
        'Bà Rịa-Vũng Tàu',
        'Bắc Giang',
        'Bắc Kạn',
        'Bạc Liêu',
        'Bắc Ninh',
        'Bến Tre',
        'Bình Dương',
        'Bình Định',
        'Bình Phước',
        'Bình Thuận',
        'Cà Mau',
        'Cao Bằng',
        'Da Nang',
        'Đắk Lắk',
        'Đắk Nông',
        'Điện Biên',
        'Đồng Nai',
        'Đồng Tháp',
        'Gia Lai',
        'Hà Giang',
        'Hà Nam',
        'Hà Tây',
        'Hà Tĩnh',
        'Hải Dương',
        'Haiphong',
        'Hanoi',
        'Hậu Giang',
        'Ho Chi Minh City',
        'Hòa Bình',
        'Hưng Yên',
        'Khánh Hòa',
        'Kiên Giang',
        'Kon Tum',
        'Lai Châu',
        'Lâm Đồng',
        'Lạng Sơn',
        'Lào Cai',
        'Long An',
        'Nam Định',
        'Nghệ An',
        'Ninh Bình',
        'Ninh Thuận',
        'Phú Thọ',
        'Phú Yên',
        'Quảng Bình',
        'Quảng Nam',
        'Quảng Ngãi',
        'Quảng Ninh',
        'Quảng Trị',
        'Sóc Trăng',
        'Sơn La',
        'Tây Ninh',
        'Thái Bình',
        'Thái Nguyên',
        'Thanh Hóa',
        'Thừa Thiên-Huế',
        'Tiền Giang',
        'Trà Vinh',
        'Tuyên Quang',
        'Vĩnh Long',
        'Vĩnh Phúc',
        'Yên Bái',
        'Anzoátegui',
        'Apure',
        'Aragua',
        'Barinas',
        'Bolívar',
        'Capital District',
        'Carabobo',
        'Cojedes',
        'Delta Amacuro',
        'Falcón',
        'Federal Dependencies of Venezuela',
        'Guárico',
        'Lara',
        'Mérida',
        'Miranda',
        'Monagas',
        'Nueva Esparta',
        'Portuguesa',
        'Sucre',
        'Táchira',
        'Trujillo',
        'Vargas',
        'Yaracuy',
        'Zulia',
        "'Adan Governorate",
        "'Amran Governorate",
        'Abyan Governorate',
        "Al Bayda' Governorate",
        'Al Hudaydah Governorate',
        'Al Jawf Governorate',
        'Al Mahrah Governorate',
        'Al Mahwit Governorate',
        'Dhamar Governorate',
        'Hadhramaut Governorate',
        'Hajjah Governorate',
        'Ibb Governorate',
        'Lahij Governorate',
        "Ma'rib Governorate",
        'Raymah Governorate',
        'Saada Governorate',
        "Sana'a",
        "Sana'a Governorate",
        'Shabwah Governorate',
        'Socotra Governorate',
        "Ta'izz Governorate",
        'Copperbelt Province',
        'Luapula Province',
        'Lusaka Province',
        'Muchinga Province',
        'Northern Province',
        'Northwestern Province',
        'Southern Province',
        'Bulawayo Province',
        'Harare Province',
        'Manicaland',
        'Mashonaland Central Province',
        'Mashonaland East Province',
        'Mashonaland West Province',
        'Masvingo Province',
        'Matabeleland North Province',
        'Matabeleland South Province',
        'Midlands Province',
        'Al Ahmadi Governorate',
        'Al Farwaniyah Governorate',
        'Al Jahra Governorate',
        'Hawalli Governorate',
        'Mubarak Al-Kabeer Governorate',
        'Batken Region',
        'Bishkek',
        'Chuy Region',
        'Issyk-Kul Region',
        'Jalal-Abad Region',
        'Naryn Region',
        'Osh',
        'Osh Region',
        'Talas Region',
        'Attapeu Province',
        'Bokeo Province',
        'Bolikhamsai Province',
        'Champasak Province',
        'Houaphanh Province',
        'Khammouane Province',
        'Luang Namtha Province',
        'Luang Prabang Province',
        'Oudomxay Province',
        'Phongsaly Province',
        'Sainyabuli Province',
        'Salavan Province',
        'Savannakhet Province',
        'Sekong Province',
        'Vientiane Prefecture',
        'Vientiane Province',
        'Xaisomboun',
        'Xaisomboun Province',
        'Xiangkhouang Province',
        'Aglona Municipality',
        'Aizkraukle Municipality',
        'Aizpute Municipality',
        'Aknīste Municipality',
        'Aloja Municipality',
        'Alsunga Municipality',
        'Alūksne Municipality',
        'Amata Municipality',
        'Ape Municipality',
        'Auce Municipality',
        'Babīte Municipality',
        'Baldone Municipality',
        'Baltinava Municipality',
        'Balvi Municipality',
        'Bauska Municipality',
        'Beverīna Municipality',
        'Brocēni Municipality',
        'Burtnieki Municipality',
        'Carnikava Municipality',
        'Cēsis Municipality',
        'Cesvaine Municipality',
        'Cibla Municipality',
        'Dagda Municipality',
        'Daugavpils',
        'Daugavpils Municipality',
        'Dobele Municipality',
        'Dundaga Municipality',
        'Durbe Municipality',
        'Engure Municipality',
        'Ērgļi Municipality',
        'Garkalne Municipality',
        'Grobiņa Municipality',
        'Gulbene Municipality',
        'Iecava Municipality',
        'Ikšķile Municipality',
        'Ilūkste Municipality',
        'Inčukalns Municipality',
        'Jaunjelgava Municipality',
        'Jaunpiebalga Municipality',
        'Jaunpils Municipality',
        'Jēkabpils',
        'Jēkabpils Municipality',
        'Jelgava',
        'Jelgava Municipality',
        'Jūrmala',
        'Kandava Municipality',
        'Kārsava Municipality',
        'Ķegums Municipality',
        'Ķekava Municipality',
        'Kocēni Municipality',
        'Koknese Municipality',
        'Krāslava Municipality',
        'Krimulda Municipality',
        'Krustpils Municipality',
        'Kuldīga Municipality',
        'Lielvārde Municipality',
        'Liepāja',
        'Līgatne Municipality',
        'Limbaži Municipality',
        'Līvāni Municipality',
        'Lubāna Municipality',
        'Ludza Municipality',
        'Madona Municipality',
        'Mālpils Municipality',
        'Mārupe Municipality',
        'Mazsalaca Municipality',
        'Mērsrags Municipality',
        'Naukšēni Municipality',
        'Nereta Municipality',
        'Nīca Municipality',
        'Ogre Municipality',
        'Olaine Municipality',
        'Ozolnieki Municipality',
        'Pārgauja Municipality',
        'Pāvilosta Municipality',
        'Pļaviņas Municipality',
        'Preiļi Municipality',
        'Priekule Municipality',
        'Priekuļi Municipality',
        'Rauna Municipality',
        'Rēzekne',
        'Rēzekne Municipality',
        'Riebiņi Municipality',
        'Riga',
        'Roja Municipality',
        'Ropaži Municipality',
        'Rucava Municipality',
        'Rugāji Municipality',
        'Rūjiena Municipality',
        'Rundāle Municipality',
        'Sala Municipality',
        'Salacgrīva Municipality',
        'Salaspils Municipality',
        'Saldus Municipality',
        'Saulkrasti Municipality',
        'Sēja Municipality',
        'Sigulda Municipality',
        'Skrīveri Municipality',
        'Skrunda Municipality',
        'Smiltene Municipality',
        'Stopiņi Municipality',
        'Strenči Municipality',
        'Talsi Municipality',
        'Tērvete Municipality',
        'Tukums Municipality',
        'Vaiņode Municipality',
        'Valka Municipality',
        'Valmiera',
        'Varakļāni Municipality',
        'Vārkava Municipality',
        'Vecpiebalga Municipality',
        'Vecumnieki Municipality',
        'Ventspils',
        'Ventspils Municipality',
        'Viesīte Municipality',
        'Viļaka Municipality',
        'Viļāni Municipality',
        'Zilupe Municipality',
        'Akkar Governorate',
        'Baalbek-Hermel Governorate',
        'Beirut Governorate',
        'Beqaa Governorate',
        'Mount Lebanon Governorate',
        'Nabatieh Governorate',
        'North Governorate',
        'South Governorate',
        'Berea District',
        'Butha-Buthe District',
        'Leribe District',
        'Mafeteng District',
        'Maseru District',
        "Mohale's Hoek District",
        'Mokhotlong District',
        "Qacha's Nek District",
        'Quthing District',
        'Thaba-Tseka District',
        'Bomi County',
        'Bong County',
        'Gbarpolu County',
        'Grand Bassa County',
        'Grand Cape Mount County',
        'Grand Gedeh County',
        'Grand Kru County',
        'Lofa County',
        'Margibi County',
        'Maryland County',
        'Montserrado County',
        'Nimba',
        'River Cess County',
        'River Gee County',
        'Sinoe County',
        'Al Wahat District',
        'Benghazi',
        'Derna District',
        'Ghat District',
        'Jabal al Akhdar',
        'Jabal al Gharbi District',
        'Jafara',
        'Jufra',
        'Kufra District',
        'Marj District',
        'Misrata District',
        'Murqub',
        'Murzuq District',
        'Nalut District',
        'Nuqat al Khams',
        'Sabha District',
        'Sirte District',
        'Tripoli District',
        'Wadi al Hayaa District',
        'Wadi al Shatii District',
        'Zawiya District',
        'Balzers',
        'Eschen',
        'Gamprin',
        'Mauren',
        'Planken',
        'Ruggell',
        'Schaan',
        'Schellenberg',
        'Triesen',
        'Triesenberg',
        'Vaduz',
        'Akmenė District Municipality',
        'Alytus City Municipality',
        'Alytus County',
        'Alytus District Municipality',
        'Birštonas Municipality',
        'Biržai District Municipality',
        'Druskininkai municipality',
        'Elektrėnai municipality',
        'Ignalina District Municipality',
        'Jonava District Municipality',
        'Joniškis District Municipality',
        'Jurbarkas District Municipality',
        'Kaišiadorys District Municipality',
        'Kalvarija municipality',
        'Kaunas City Municipality',
        'Kaunas County',
        'Kaunas District Municipality',
        'Kazlų Rūda municipality',
        'Kėdainiai District Municipality',
        'Kelmė District Municipality',
        'Klaipeda City Municipality',
        'Klaipėda County',
        'Klaipėda District Municipality',
        'Kretinga District Municipality',
        'Kupiškis District Municipality',
        'Lazdijai District Municipality',
        'Marijampolė County',
        'Marijampolė Municipality',
        'Mažeikiai District Municipality',
        'Molėtai District Municipality',
        'Neringa Municipality',
        'Pagėgiai municipality',
        'Pakruojis District Municipality',
        'Palanga City Municipality',
        'Panevėžys City Municipality',
        'Panevėžys County',
        'Panevėžys District Municipality',
        'Pasvalys District Municipality',
        'Plungė District Municipality',
        'Prienai District Municipality',
        'Radviliškis District Municipality',
        'Raseiniai District Municipality',
        'Rietavas municipality',
        'Rokiškis District Municipality',
        'Šakiai District Municipality',
        'Šalčininkai District Municipality',
        'Šiauliai City Municipality',
        'Šiauliai County',
        'Šiauliai District Municipality',
        'Šilalė District Municipality',
        'Šilutė District Municipality',
        'Širvintos District Municipality',
        'Skuodas District Municipality',
        'Švenčionys District Municipality',
        'Tauragė County',
        'Tauragė District Municipality',
        'Telšiai County',
        'Telšiai District Municipality',
        'Trakai District Municipality',
        'Ukmergė District Municipality',
        'Utena County',
        'Utena District Municipality',
        'Varėna District Municipality',
        'Vilkaviškis District Municipality',
        'Vilnius City Municipality',
        'Vilnius County',
        'Vilnius District Municipality',
        'Visaginas Municipality',
        'Zarasai District Municipality',
        'Canton of Capellen',
        'Canton of Clervaux',
        'Canton of Diekirch',
        'Canton of Echternach',
        'Canton of Esch-sur-Alzette',
        'Canton of Grevenmacher',
        'Canton of Luxembourg',
        'Canton of Mersch',
        'Canton of Redange',
        'Canton of Remich',
        'Canton of Vianden',
        'Canton of Wiltz',
        'Diekirch District',
        'Grevenmacher District',
        'Luxembourg District',
        'Aerodrom Municipality',
        'Aračinovo Municipality',
        'Berovo Municipality',
        'Bitola Municipality',
        'Bogdanci Municipality',
        'Bogovinje Municipality',
        'Bosilovo Municipality',
        'Brvenica Municipality',
        'Butel Municipality',
        'Čair Municipality',
        'Čaška Municipality',
        'Centar Municipality',
        'Centar Župa Municipality',
        'Češinovo-Obleševo Municipality',
        'Čučer-Sandevo Municipality',
        'Debarca Municipality',
        'Delčevo Municipality',
        'Demir Hisar Municipality',
        'Demir Kapija Municipality',
        'Dojran Municipality',
        'Dolneni Municipality',
        'Drugovo Municipality',
        'Gazi Baba Municipality',
        'Gevgelija Municipality',
        'Gjorče Petrov Municipality',
        'Gostivar Municipality',
        'Gradsko Municipality',
        'Greater Skopje',
        'Ilinden Municipality',
        'Jegunovce Municipality',
        'Karbinci',
        'Karpoš Municipality',
        'Kavadarci Municipality',
        'Kičevo Municipality',
        'Kisela Voda Municipality',
        'Kočani Municipality',
        'Konče Municipality',
        'Kratovo Municipality',
        'Kriva Palanka Municipality',
        'Krivogaštani Municipality',
        'Kruševo Municipality',
        'Kumanovo Municipality',
        'Lipkovo Municipality',
        'Lozovo Municipality',
        'Makedonska Kamenica Municipality',
        'Makedonski Brod Municipality',
        'Mavrovo and Rostuša Municipality',
        'Mogila Municipality',
        'Negotino Municipality',
        'Novaci Municipality',
        'Novo Selo Municipality',
        'Ohrid Municipality',
        'Oslomej Municipality',
        'Pehčevo Municipality',
        'Petrovec Municipality',
        'Plasnica Municipality',
        'Prilep Municipality',
        'Probištip Municipality',
        'Radoviš Municipality',
        'Rankovce Municipality',
        'Resen Municipality',
        'Rosoman Municipality',
        'Saraj Municipality',
        'Sopište Municipality',
        'Staro Nagoričane Municipality',
        'Štip Municipality',
        'Struga Municipality',
        'Strumica Municipality',
        'Studeničani Municipality',
        'Šuto Orizari Municipality',
        'Sveti Nikole Municipality',
        'Tearce Municipality',
        'Tetovo Municipality',
        'Valandovo Municipality',
        'Vasilevo Municipality',
        'Veles Municipality',
        'Vevčani Municipality',
        'Vinica Municipality',
        'Vraneštica Municipality',
        'Vrapčište Municipality',
        'Zajas Municipality',
        'Zelenikovo Municipality',
        'Želino Municipality',
        'Zrnovci Municipality',
        'Antananarivo Province',
        'Antsiranana Province',
        'Fianarantsoa Province',
        'Mahajanga Province',
        'Toamasina Province',
        'Toliara Province',
        'Balaka District',
        'Blantyre District',
        'Chikwawa District',
        'Chiradzulu District',
        'Chitipa district',
        'Dedza District',
        'Dowa District',
        'Karonga District',
        'Kasungu District',
        'Likoma District',
        'Lilongwe District',
        'Machinga District',
        'Mangochi District',
        'Mchinji District',
        'Mulanje District',
        'Mwanza District',
        'Mzimba District',
        'Nkhata Bay District',
        'Nkhotakota District',
        'Nsanje District',
        'Ntcheu District',
        'Ntchisi District',
        'Phalombe District',
        'Rumphi District',
        'Salima District',
        'Thyolo District',
        'Zomba District',
        'Johor',
        'Kedah',
        'Kelantan',
        'Kuala Lumpur',
        'Labuan',
        'Melaka',
        'Negeri Sembilan',
        'Pahang',
        'Penang',
        'Perak',
        'Perlis',
        'Putrajaya',
        'Sabah',
        'Sarawak',
        'Selangor',
        'Terengganu',
        'Addu Atoll',
        'Alif Alif Atoll',
        'Alif Dhaal Atoll',
        'Dhaalu Atoll',
        'Faafu Atoll',
        'Gaafu Alif Atoll',
        'Gaafu Dhaalu Atoll',
        'Gnaviyani Atoll',
        'Haa Alif Atoll',
        'Haa Dhaalu Atoll',
        'Kaafu Atoll',
        'Laamu Atoll',
        'Lhaviyani Atoll',
        'Malé',
        'Meemu Atoll',
        'Noonu Atoll',
        'North Central Province',
        'North Province',
        'Raa Atoll',
        'Shaviyani Atoll',
        'South Central Province',
        'South Province',
        'Thaa Atoll',
        'Upper South Province',
        'Vaavu Atoll',
        'Bamako',
        'Gao Region',
        'Kayes Region',
        'Kidal Region',
        'Koulikoro Region',
        'Ménaka Region',
        'Mopti Region',
        'Ségou Region',
        'Sikasso Region',
        'Taoudénit Region',
        'Tombouctou Region',
        'Attard',
        'Balzan',
        'Birgu',
        'Birkirkara',
        'Birżebbuġa',
        'Cospicua',
        'Dingli',
        'Fgura',
        'Floriana',
        'Fontana',
        'Għajnsielem',
        'Għarb',
        'Għargħur',
        'Għasri',
        'Għaxaq',
        'Gudja',
        'Gżira',
        'Ħamrun',
        'Iklin',
        'Kalkara',
        'Kerċem',
        'Kirkop',
        'Lija',
        'Luqa',
        'Marsa',
        'Marsaskala',
        'Marsaxlokk',
        'Mdina',
        'Mellieħa',
        'Mġarr',
        'Mosta',
        'Mqabba',
        'Msida',
        'Mtarfa',
        'Munxar',
        'Nadur',
        'Naxxar',
        'Paola',
        'Pembroke',
        'Pietà',
        'Qala',
        'Qormi',
        'Qrendi',
        'Rabat',
        'Saint Lawrence',
        'San Ġwann',
        'Sannat',
        'Santa Luċija',
        'Santa Venera',
        'Senglea',
        'Siġġiewi',
        'Sliema',
        "St. Julian's",
        "St. Paul's Bay",
        'Swieqi',
        "Ta' Xbiex",
        'Tarxien',
        'Valletta',
        'Xagħra',
        'Xewkija',
        'Xgħajra',
        'Żabbar',
        'Żebbuġ Gozo',
        'Żebbuġ Malta',
        'Żejtun',
        'Żurrieq',
        'Ralik Chain',
        'Ratak Chain',
        'Adrar Region',
        'Assaba Region',
        'Brakna Region',
        'Dakhlet Nouadhibou',
        'Gorgol Region',
        'Guidimaka Region',
        'Hodh Ech Chargui Region',
        'Hodh El Gharbi Region',
        'Inchiri Region',
        'Nouakchott-Nord Region',
        'Nouakchott-Ouest Region',
        'Nouakchott-Sud Region',
        'Tagant Region',
        'Tiris Zemmour Region',
        'Trarza Region',
        'Agaléga',
        'Beau Bassin-Rose Hill',
        'Cargados Carajos',
        'Curepipe',
        'Flacq District',
        'Grand Port District',
        'Moka District',
        'Pamplemousses District',
        'Plaines Wilhems District',
        'Port Louis',
        'Port Louis District',
        'Quatre Bornes',
        'Rivière du Rempart District',
        'Rivière Noire District',
        'Rodrigues',
        'Savanne District',
        'Vacoas-Phoenix',
        'Aguascalientes',
        'Baja California',
        'Baja California Sur',
        'Campeche',
        'Chiapas',
        'Chihuahua',
        'Coahuila',
        'Colima',
        'Durango',
        'Guanajuato',
        'Guerrero',
        'Hidalgo',
        'Jalisco',
        'México',
        'Mexico City',
        'Michoacán',
        'Morelos',
        'Nayarit',
        'Nuevo León',
        'Oaxaca',
        'Puebla',
        'Querétaro',
        'Quintana Roo',
        'San Luis Potosí',
        'Sinaloa',
        'Sonora',
        'Tabasco',
        'Tamaulipas',
        'Tlaxcala',
        'Veracruz',
        'Yucatán',
        'Zacatecas',
        'Anenii Noi District',
        'Bălți Municipality',
        'Basarabeasca District',
        'Bender Municipality',
        'Briceni District',
        'Cahul District',
        'Călărași District',
        'Cantemir District',
        'Căușeni District',
        'Chișinău Municipality',
        'Cimișlia District',
        'Criuleni District',
        'Dondușeni District',
        'Drochia District',
        'Dubăsari District',
        'Edineț District',
        'Fălești District',
        'Florești District',
        'Gagauzia',
        'Glodeni District',
        'Hîncești District',
        'Ialoveni District',
        'Nisporeni District',
        'Ocnița District',
        'Orhei District',
        'Rezina District',
        'Rîșcani District',
        'Sîngerei District',
        'Șoldănești District',
        'Soroca District',
        'Ștefan Vodă District',
        'Strășeni District',
        'Taraclia District',
        'Telenești District',
        'Transnistria autonomous territorial unit',
        'Ungheni District',
        'Arkhangai Province',
        'Bayan-Ölgii Province',
        'Bayankhongor Province',
        'Bulgan Province',
        'Darkhan-Uul Province',
        'Dornod Province',
        'Dornogovi Province',
        'Dundgovi Province',
        'Govi-Altai Province',
        'Govisümber Province',
        'Khentii Province',
        'Khovd Province',
        'Khövsgöl Province',
        'Ömnögovi Province',
        'Orkhon Province',
        'Övörkhangai Province',
        'Selenge Province',
        'Sükhbaatar Province',
        'Töv Province',
        'Uvs Province',
        'Zavkhan Province',
        'Altai Krai',
        'Altai Republic',
        'Amur Oblast',
        'Arkhangelsk',
        'Astrakhan Oblast',
        'Belgorod Oblast',
        'Bryansk Oblast',
        'Chechen Republic',
        'Chelyabinsk Oblast',
        'Chukotka Autonomous Okrug',
        'Chuvash Republic',
        'Irkutsk',
        'Ivanovo Oblast',
        'Jewish Autonomous Oblast',
        'Kabardino-Balkar Republic',
        'Kaliningrad',
        'Kaluga Oblast',
        'Kamchatka Krai',
        'Karachay-Cherkess Republic',
        'Kemerovo Oblast',
        'Khabarovsk Krai',
        'Khanty-Mansi Autonomous Okrug',
        'Kirov Oblast',
        'Komi Republic',
        'Kostroma Oblast',
        'Krasnodar Krai',
        'Krasnoyarsk Krai',
        'Kurgan Oblast',
        'Kursk Oblast',
        'Leningrad Oblast',
        'Lipetsk Oblast',
        'Magadan Oblast',
        'Mari El Republic',
        'Moscow',
        'Moscow Oblast',
        'Murmansk Oblast',
        'Nenets Autonomous Okrug',
        'Nizhny Novgorod Oblast',
        'Novgorod Oblast',
        'Novosibirsk',
        'Omsk Oblast',
        'Orenburg Oblast',
        'Oryol Oblast',
        'Penza Oblast',
        'Perm Krai',
        'Primorsky Krai',
        'Pskov Oblast',
        'Republic of Adygea',
        'Republic of Bashkortostan',
        'Republic of Buryatia',
        'Republic of Dagestan',
        'Republic of Ingushetia',
        'Republic of Kalmykia',
        'Republic of Karelia',
        'Republic of Khakassia',
        'Republic of Mordovia',
        'Republic of North Ossetia-Alania',
        'Republic of Tatarstan',
        'Rostov Oblast',
        'Ryazan Oblast',
        'Saint Petersburg',
        'Sakha Republic',
        'Sakhalin',
        'Samara Oblast',
        'Saratov Oblast',
        'Sevastopol',
        'Smolensk Oblast',
        'Stavropol Krai',
        'Sverdlovsk',
        'Tambov Oblast',
        'Tomsk Oblast',
        'Tula Oblast',
        'Tuva Republic',
        'Tver Oblast',
        'Tyumen Oblast',
        'Udmurt Republic',
        'Ulyanovsk Oblast',
        'Vladimir Oblast',
        'Vologda Oblast',
        'Voronezh Oblast',
        'Yamalo-Nenets Autonomous Okrug',
        'Yaroslavl Oblast',
        'Zabaykalsky Krai',
        'Kigali district',
        'Christ Church Nichola Town Parish',
        'Nevis',
        'Saint Anne Sandy Point Parish',
        'Saint George Gingerland Parish',
        'Saint James Windward Parish',
        'Saint John Capisterre Parish',
        'Saint John Figtree Parish',
        'Saint Kitts',
        'Saint Mary Cayon Parish',
        'Saint Paul Capisterre Parish',
        'Saint Paul Charlestown Parish',
        'Saint Peter Basseterre Parish',
        'Saint Thomas Lowland Parish',
        'Saint Thomas Middle Island Parish',
        'Trinity Palmetto Point Parish',
        'Anse la Raye Quarter',
        'Canaries',
        'Castries Quarter',
        'Choiseul Quarter',
        'Dauphin Quarter',
        'Dennery Quarter',
        'Gros Islet Quarter',
        'Laborie Quarter',
        'Micoud Quarter',
        'Praslin Quarter',
        'Soufrière Quarter',
        'Vieux Fort Quarter',
        "A'ana",
        'Aiga-i-le-Tai',
        'Atua',
        "Fa'asaleleaga",
        "Gaga'emauga",
        "Gaga'ifomauga",
        'Palauli',
        "Satupa'itea",
        'Tuamasaga',
        "Va'a-o-Fonoti",
        'Vaisigano',
        'Acquaviva',
        'Borgo Maggiore',
        'Chiesanuova',
        'Domagnano',
        'Faetano',
        'Fiorentino',
        'Montegiardino',
        'San Marino',
        'Serravalle',
        "'Asir Region",
        'Al Bahah Region',
        'Al Jawf Region',
        'Al Madinah Region',
        'Al-Qassim Region',
        "Ha'il Region",
        'Jizan Region',
        'Makkah Region',
        'Najran Region',
        'Northern Borders Region',
        'Riyadh Region',
        'Tabuk Region',
        'Dakar',
        'Diourbel Region',
        'Fatick',
        'Kaffrine',
        'Kaolack',
        'Kédougou',
        'Kolda',
        'Louga',
        'Matam',
        'Saint-Louis',
        'Sédhiou',
        'Tambacounda Region',
        'Thiès Region',
        'Ziguinchor',
        'Belgrade',
        'Bor District',
        'Braničevo District',
        'Central Banat District',
        'Jablanica District',
        'Kolubara District',
        'Mačva District',
        'Moravica District',
        'Nišava District',
        'North Bačka District',
        'North Banat District',
        'Pčinja District',
        'Pirot District',
        'Podunavlje District',
        'Pomoravlje District',
        'Rasina District',
        'Raška District',
        'South Bačka District',
        'South Banat District',
        'Srem District',
        'Šumadija District',
        'Toplica District',
        'Vojvodina',
        'West Bačka District',
        'Zaječar District',
        'Zlatibor District',
        'Anse Boileau',
        'Anse Royale',
        'Anse-aux-Pins',
        'Au Cap',
        'Baie Lazare',
        'Baie Sainte Anne',
        'Beau Vallon',
        'Bel Air',
        'Bel Ombre',
        'Cascade',
        'Glacis',
        "Grand'Anse Mahé",
        "Grand'Anse Praslin",
        'La Digue',
        'La Rivière Anglaise',
        'Les Mamelles',
        'Mont Buxton',
        'Mont Fleuri',
        'Plaisance',
        'Pointe La Rue',
        'Port Glaud',
        'Roche Caiman',
        'Saint Louis',
        'Takamaka',
        'Western Area',
        'Central Singapore Community Development Council',
        'North East Community Development Council',
        'North West Community Development Council',
        'South East Community Development Council',
        'South West Community Development Council',
        'Banská Bystrica Region',
        'Bratislava Region',
        'Košice Region',
        'Nitra Region',
        'Prešov Region',
        'Trenčín Region',
        'Trnava Region',
        'Žilina Region',
        'Ajdovščina Municipality',
        'Ankaran Municipality',
        'Beltinci Municipality',
        'Benedikt Municipality',
        'Bistrica ob Sotli Municipality',
        'Bled Municipality',
        'Bloke Municipality',
        'Bohinj Municipality',
        'Borovnica Municipality',
        'Bovec Municipality',
        'Braslovče Municipality',
        'Brda Municipality',
        'Brežice Municipality',
        'Brezovica Municipality',
        'Cankova Municipality',
        'Cerklje na Gorenjskem Municipality',
        'Cerknica Municipality',
        'Cerkno Municipality',
        'Cerkvenjak Municipality',
        'City Municipality of Celje',
        'City Municipality of Novo Mesto',
        'Črenšovci Municipality',
        'Črna na Koroškem Municipality',
        'Črnomelj Municipality',
        'Destrnik Municipality',
        'Divača Municipality',
        'Dobje Municipality',
        'Dobrepolje Municipality',
        'Dobrna Municipality',
        'Dobrova–Polhov Gradec Municipality',
        'Dobrovnik Municipality',
        'Dol pri Ljubljani Municipality',
        'Dolenjske Toplice Municipality',
        'Domžale Municipality',
        'Dornava Municipality',
        'Dravograd Municipality',
        'Duplek Municipality',
        'Gorenja Vas–Poljane Municipality',
        'Gorišnica Municipality',
        'Gorje Municipality',
        'Gornja Radgona Municipality',
        'Gornji Grad Municipality',
        'Gornji Petrovci Municipality',
        'Grad Municipality',
        'Grosuplje Municipality',
        'Hajdina Municipality',
        'Hoče–Slivnica Municipality',
        'Hodoš Municipality',
        'Horjul Municipality',
        'Hrastnik Municipality',
        'Hrpelje–Kozina Municipality',
        'Idrija Municipality',
        'Ig Municipality',
        'Ivančna Gorica Municipality',
        'Izola Municipality',
        'Jesenice Municipality',
        'Jezersko Municipality',
        'Juršinci Municipality',
        'Kamnik Municipality',
        'Kanal ob Soči Municipality',
        'Kidričevo Municipality',
        'Kobarid Municipality',
        'Kobilje Municipality',
        'Kočevje Municipality',
        'Komen Municipality',
        'Komenda Municipality',
        'Koper City Municipality',
        'Kostanjevica na Krki Municipality',
        'Kostel Municipality',
        'Kozje Municipality',
        'Kranj City Municipality',
        'Kranjska Gora Municipality',
        'Križevci Municipality',
        'Kungota',
        'Kuzma Municipality',
        'Laško Municipality',
        'Lenart Municipality',
        'Lendava Municipality',
        'Litija Municipality',
        'Ljubljana City Municipality',
        'Ljubno Municipality',
        'Ljutomer Municipality',
        'Log–Dragomer Municipality',
        'Logatec Municipality',
        'Loška Dolina Municipality',
        'Loški Potok Municipality',
        'Lovrenc na Pohorju Municipality',
        'Luče Municipality',
        'Lukovica Municipality',
        'Majšperk Municipality',
        'Makole Municipality',
        'Maribor City Municipality',
        'Markovci Municipality',
        'Medvode Municipality',
        'Mengeš Municipality',
        'Metlika Municipality',
        'Mežica Municipality',
        'Miklavž na Dravskem Polju Municipality',
        'Miren–Kostanjevica Municipality',
        'Mirna Municipality',
        'Mirna Peč Municipality',
        'Mislinja Municipality',
        'Mokronog–Trebelno Municipality',
        'Moravče Municipality',
        'Moravske Toplice Municipality',
        'Mozirje Municipality',
        'Municipality of Apače',
        'Municipality of Cirkulane',
        'Municipality of Ilirska Bistrica',
        'Municipality of Krško',
        'Municipality of Škofljica',
        'Murska Sobota City Municipality',
        'Muta Municipality',
        'Naklo Municipality',
        'Nazarje Municipality',
        'Nova Gorica City Municipality',
        'Odranci Municipality',
        'Oplotnica',
        'Ormož Municipality',
        'Osilnica Municipality',
        'Pesnica Municipality',
        'Piran Municipality',
        'Pivka Municipality',
        'Podčetrtek Municipality',
        'Podlehnik Municipality',
        'Podvelka Municipality',
        'Poljčane Municipality',
        'Polzela Municipality',
        'Postojna Municipality',
        'Prebold Municipality',
        'Preddvor Municipality',
        'Prevalje Municipality',
        'Ptuj City Municipality',
        'Puconci Municipality',
        'Rače–Fram Municipality',
        'Radeče Municipality',
        'Radenci Municipality',
        'Radlje ob Dravi Municipality',
        'Radovljica Municipality',
        'Ravne na Koroškem Municipality',
        'Razkrižje Municipality',
        'Rečica ob Savinji Municipality',
        'Renče–Vogrsko Municipality',
        'Ribnica Municipality',
        'Ribnica na Pohorju Municipality',
        'Rogaška Slatina Municipality',
        'Rogašovci Municipality',
        'Rogatec Municipality',
        'Ruše Municipality',
        'Šalovci Municipality',
        'Selnica ob Dravi Municipality',
        'Semič Municipality',
        'Šempeter–Vrtojba Municipality',
        'Šenčur Municipality',
        'Šentilj Municipality',
        'Šentjernej Municipality',
        'Šentjur Municipality',
        'Šentrupert Municipality',
        'Sevnica Municipality',
        'Sežana Municipality',
        'Škocjan Municipality',
        'Škofja Loka Municipality',
        'Slovenj Gradec City Municipality',
        'Slovenska Bistrica Municipality',
        'Slovenske Konjice Municipality',
        'Šmarje pri Jelšah Municipality',
        'Šmarješke Toplice Municipality',
        'Šmartno ob Paki Municipality',
        'Šmartno pri Litiji Municipality',
        'Sodražica Municipality',
        'Solčava Municipality',
        'Šoštanj Municipality',
        'Središče ob Dravi',
        'Starše Municipality',
        'Štore Municipality',
        'Straža Municipality',
        'Sveta Ana Municipality',
        'Sveta Trojica v Slovenskih Goricah Municipality',
        'Sveti Andraž v Slovenskih Goricah Municipality',
        'Sveti Jurij ob Ščavnici Municipality',
        'Sveti Jurij v Slovenskih Goricah Municipality',
        'Sveti Tomaž Municipality',
        'Tabor Municipality',
        'Tišina Municipality',
        'Tolmin Municipality',
        'Trbovlje Municipality',
        'Trebnje Municipality',
        'Trnovska Vas Municipality',
        'Tržič Municipality',
        'Trzin Municipality',
        'Turnišče Municipality',
        'Velika Polana Municipality',
        'Velike Lašče Municipality',
        'Veržej Municipality',
        'Videm Municipality',
        'Vipava Municipality',
        'Vitanje Municipality',
        'Vodice Municipality',
        'Vojnik Municipality',
        'Vransko Municipality',
        'Vrhnika Municipality',
        'Vuzenica Municipality',
        'Zagorje ob Savi Municipality',
        'Žalec Municipality',
        'Zavrč Municipality',
        'Železniki Municipality',
        'Žetale Municipality',
        'Žiri Municipality',
        'Žirovnica Municipality',
        'Zreče Municipality',
        'Žužemberk Municipality',
        'Choiseul Province',
        'Guadalcanal Province',
        'Honiara',
        'Isabel Province',
        'Makira-Ulawa Province',
        'Malaita Province',
        'Rennell and Bellona Province',
        'Temotu Province',
        'Awdal Region',
        'Bakool',
        'Banaadir',
        'Bari',
        'Bay',
        'Galguduud',
        'Gedo',
        'Hiran',
        'Lower Juba',
        'Lower Shebelle',
        'Middle Juba',
        'Middle Shebelle',
        'Mudug',
        'Nugal',
        'Sanaag Region',
        'Togdheer Region',
        'Eastern Cape',
        'Free State',
        'Gauteng',
        'KwaZulu-Natal',
        'Limpopo',
        'Mpumalanga',
        'North West',
        'Northern Cape',
        'Western Cape',
        'Busan',
        'Daegu',
        'Daejeon',
        'Gangwon Province',
        'Gwangju',
        'Gyeonggi Province',
        'Incheon',
        'Jeju',
        'North Chungcheong Province',
        'North Gyeongsang Province',
        'North Jeolla Province',
        'Sejong City',
        'Seoul',
        'South Chungcheong Province',
        'South Gyeongsang Province',
        'South Jeolla Province',
        'Ulsan',
        'Ampara District',
        'Anuradhapura District',
        'Badulla District',
        'Batticaloa District',
        'Colombo District',
        'Galle District',
        'Gampaha District',
        'Hambantota District',
        'Jaffna District',
        'Kalutara District',
        'Kandy District',
        'Kegalle District',
        'Kilinochchi District',
        'Mannar District',
        'Matale District',
        'Matara District',
        'Monaragala District',
        'Mullaitivu District',
        'North Western Province',
        'Nuwara Eliya District',
        'Polonnaruwa District',
        'Puttalam District',
        'Ratnapura district',
        'Sabaragamuwa Province',
        'Trincomalee District',
        'Uva Province',
        'Vavuniya District',
        'Al Jazirah',
        'Al Qadarif',
        'Blue Nile',
        'Central Darfur',
        'East Darfur',
        'Kassala',
        'Khartoum',
        'North Darfur',
        'North Kordofan',
        'Northern',
        'Red Sea',
        'River Nile',
        'Sennar',
        'South Darfur',
        'South Kordofan',
        'West Darfur',
        'West Kordofan',
        'White Nile',
        'Brokopondo District',
        'Commewijne District',
        'Coronie District',
        'Marowijne District',
        'Nickerie District',
        'Para District',
        'Paramaribo District',
        'Saramacca District',
        'Sipaliwini District',
        'Wanica District',
        'Blekinge',
        'Dalarna County',
        'Gävleborg County',
        'Gotland County',
        'Halland County',
        'Jönköping County',
        'Kalmar County',
        'Kronoberg County',
        'Norrbotten County',
        'Örebro County',
        'Östergötland County',
        'Skåne County',
        'Södermanland County',
        'Stockholm County',
        'Uppsala County',
        'Värmland County',
        'Västerbotten County',
        'Västernorrland County',
        'Västmanland County',
        'Västra Götaland County',
        'Hhohho District',
        'Lubombo District',
        'Manzini District',
        'Shiselweni District',
        'Aargau',
        'Appenzell Ausserrhoden',
        'Appenzell Innerrhoden',
        'Basel-Landschaft',
        'Basel-Stadt',
        'canton of Bern',
        'Canton of Fribourg',
        'Canton of Geneva',
        'Canton of Jura',
        'Canton of Lucerne',
        'Canton of Neuchâtel',
        'Canton of Schaffhausen',
        'Canton of Solothurn',
        'Canton of St. Gallen',
        'Canton of Valais',
        'Canton of Vaud',
        'Canton of Zug',
        'canton of Zürich',
        'Glarus',
        'Graubünden',
        'Nidwalden',
        'Obwalden',
        'Schwyz',
        'Thurgau',
        'Ticino',
        'Uri',
        'Al-Hasakah Governorate',
        'Al-Raqqah Governorate',
        'Aleppo Governorate',
        'As-Suwayda Governorate',
        'Damascus Governorate',
        'Daraa Governorate',
        'Deir ez-Zor Governorate',
        'Hama Governorate',
        'Homs Governorate',
        'Idlib Governorate',
        'Latakia Governorate',
        'Quneitra Governorate',
        'Rif Dimashq Governorate',
        'Tartus Governorate',
        'Changhua County',
        'Chiayi City',
        'Chiayi County',
        'Hsinchu',
        'Hsinchu County',
        'Hualien County',
        'Kaohsiung',
        'Kaohsiung County',
        'Kinmen',
        'Lienchiang County',
        'Miaoli County',
        'Nantou County',
        'Penghu County',
        'Pingtung County',
        'Taichung',
        'Taichung County',
        'Tainan',
        'Tainan County',
        'Taipei',
        'Taitung County',
        'Taoyuan City',
        'Yilan County',
        'Yunlin County',
        'districts of Republican Subordination',
        'Gorno-Badakhshan Autonomous Province',
        'Khatlon Province',
        'Sughd Province',
        'Arusha Region',
        'Dar es Salaam Region',
        'Dodoma Region',
        'Geita Region',
        'Iringa Region',
        'Kagera Region',
        'Katavi Region',
        'Kigoma Region',
        'Kilimanjaro Region',
        'Lindi Region',
        'Manyara Region',
        'Mara Region',
        'Morogoro Region',
        'Mtwara Region',
        'Mwanza Region',
        'Njombe Region',
        'North Pemba Region',
        'Pwani Region',
        'Rukwa Region',
        'Ruvuma Region',
        'Shinyanga Region',
        'Simiyu Region',
        'Singida Region',
        'South Pemba Region',
        'Tabora Region',
        'Tanga Region',
        'Zanzibar Central South Region',
        'Zanzibar North Region',
        'Zanzibar Urban West Region',
        'Amnat Charoen',
        'Ang Thong',
        'Bangkok',
        'Bueng Kan',
        'Buri Ram',
        'Chachoengsao',
        'Chai Nat',
        'Chanthaburi',
        'Chiang Mai',
        'Chiang Rai',
        'Chon Buri',
        'Chumphon',
        'Kalasin',
        'Kamphaeng Phet',
        'Kanchanaburi',
        'Khon Kaen',
        'Krabi',
        'Lampang',
        'Lamphun',
        'Loei',
        'Lopburi',
        'Mae Hong Son',
        'Maha Sarakham',
        'Mukdahan',
        'Nakhon Nayok',
        'Nakhon Pathom',
        'Nakhon Phanom',
        'Nakhon Ratchasima',
        'Nakhon Sawan',
        'Nakhon Si Thammarat',
        'Nan',
        'Narathiwat',
        'Nong Bua Lam Phu',
        'Nong Khai',
        'Nonthaburi',
        'Pathum Thani',
        'Pattani',
        'Pattaya',
        'Phang Nga',
        'Phatthalung',
        'Phayao',
        'Phetchabun',
        'Phetchaburi',
        'Phichit',
        'Phitsanulok',
        'Phra Nakhon Si Ayutthaya',
        'Phrae',
        'Phuket',
        'Prachin Buri',
        'Prachuap Khiri Khan',
        'Ranong',
        'Ratchaburi',
        'Rayong',
        'Roi Et',
        'Sa Kaeo',
        'Sakon Nakhon',
        'Samut Prakan',
        'Samut Sakhon',
        'Samut Songkhram',
        'Saraburi',
        'Satun',
        'Si Sa Ket',
        'Sing Buri',
        'Songkhla',
        'Sukhothai',
        'Suphanburi',
        'Surat Thani',
        'Surin',
        'Tak',
        'Trang',
        'Trat',
        'Ubon Ratchathani',
        'Udon Thani',
        'Uthai Thani',
        'Uttaradit',
        'Yala',
        'Yasothon',
        'Centrale Region',
        'Kara Region',
        'Maritime',
        'Plateaux Region',
        'Savanes Region',
        'Andrijevica Municipality',
        'Bar Municipality',
        'Berane Municipality',
        'Bijelo Polje Municipality',
        'Budva Municipality',
        'Danilovgrad Municipality',
        'Gusinje Municipality',
        'Kolašin Municipality',
        'Kotor Municipality',
        'Mojkovac Municipality',
        'Nikšić Municipality',
        'Old Royal Capital Cetinje',
        'Petnjica Municipality',
        'Plav Municipality',
        'Pljevlja Municipality',
        'Plužine Municipality',
        'Podgorica Municipality',
        'Rožaje Municipality',
        'Šavnik Municipality',
        'Tivat Municipality',
        'Ulcinj Municipality',
        'Žabljak Municipality',
        'Al Haouz Province',
        'Al Hoceïma Province',
        'Aousserd Province',
        'Assa-Zag Province',
        'Azilal Province',
        'Ben Slimane Province',
        'Béni Mellal-Khénifra',
        'Béni-Mellal Province',
        'Berkane Province',
        'Boujdour Province',
        'Boulemane Province',
        'Casablanca-Settat',
        'Chefchaouen Province',
        'Chichaoua Province',
        'Dakhla-Oued Ed-Dahab',
        'Drâa-Tafilalet',
        'El Hajeb Province',
        'El Jadida Province',
        'Errachidia Province',
        'Es Semara Province',
        'Essaouira Province',
        'Fahs Anjra Province',
        'Fès-Meknès',
        'Figuig Province',
        'Guelmim Province',
        'Guelmim-Oued Noun',
        'Ifrane Province',
        'Inezgane-Aït Melloul Prefecture',
        'Jerada Province',
        'Kelaat Sraghna Province',
        'Kénitra Province',
        'Khémisset Province',
        'Khénifra Province',
        'Khouribga Province',
        'Laâyoune Province',
        'Laâyoune-Sakia El Hamra',
        'Larache Province',
        'Marrakesh-Safi',
        'Mediouna Province',
        'Moulay Yacoub Province',
        'Nador Province',
        'Nouaceur Province',
        'Oriental',
        'Ouarzazate Province',
        'Oued Ed-Dahab Province',
        'Safi Province',
        'Sefrou Province',
        'Settat Province',
        'Shtouka Ait Baha Province',
        'Sidi Kacem Province',
        'Sidi Youssef Ben Ali',
        'Souss-Massa',
        'Tan-Tan Province',
        'Tanger-Tétouan-Al Hoceïma',
        'Taounate Province',
        'Taourirt Province',
        'Taroudant Province',
        'Tata Province',
        'Taza Province',
        'Tétouan Province',
        'Tiznit Province',
        'Zagora Province',
        'Cabo Delgado Province',
        'Gaza Province',
        'Inhambane Province',
        'Manica Province',
        'Maputo',
        'Maputo Province',
        'Nampula Province',
        'Niassa Province',
        'Sofala Province',
        'Tete Province',
        'Zambezia Province',
        'Erongo Region',
        'Hardap Region',
        'Karas Region',
        'Kavango East Region',
        'Kavango West Region',
        'Khomas Region',
        'Kunene Region',
        'Ohangwena Region',
        'Omaheke Region',
        'Omusati Region',
        'Oshana Region',
        'Oshikoto Region',
        'Otjozondjupa Region',
        'Zambezi Region',
        'Aiwo District',
        'Anabar District',
        'Anetan District',
        'Anibare District',
        'Baiti District',
        'Boe District',
        'Buada District',
        'Denigomodu District',
        'Ewa District',
        'Ijuw District',
        'Meneng District',
        'Nibok District',
        'Uaboe District',
        'Yaren District',
        'Bagmati Zone',
        'Bheri Zone',
        'Dhaulagiri Zone',
        'Eastern Development Region',
        'Far-Western Development Region',
        'Gandaki Zone',
        'Janakpur Zone',
        'Karnali Zone',
        'Kosi Zone',
        'Lumbini Zone',
        'Mahakali Zone',
        'Mechi Zone',
        'Mid-Western Region',
        'Narayani Zone',
        'Rapti Zone',
        'Sagarmatha Zone',
        'Seti Zone',
        'Drenthe',
        'Flevoland',
        'Friesland',
        'Gelderland',
        'Groningen',
        'North Brabant',
        'North Holland',
        'Overijssel',
        'South Holland',
        'Utrecht',
        'Zeeland',
        'Auckland Region',
        'Bay of Plenty Region',
        'Canterbury Region',
        'Chatham Islands',
        'Gisborne District',
        "Hawke's Bay Region",
        'Manawatu-Wanganui Region',
        'Marlborough Region',
        'Nelson Region',
        'Northland Region',
        'Otago Region',
        'Southland Region',
        'Taranaki Region',
        'Tasman District',
        'Waikato Region',
        'Wellington Region',
        'West Coast Region',
        'Boaco Department',
        'Carazo Department',
        'Chinandega Department',
        'Chontales Department',
        'Estelí Department',
        'Granada Department',
        'Jinotega Department',
        'León Department',
        'Madriz Department',
        'Managua Department',
        'Masaya Department',
        'Matagalpa Department',
        'North Caribbean Coast Autonomous Region',
        'Río San Juan Department',
        'Rivas Department',
        'South Caribbean Coast Autonomous Region',
        'Agadez Region',
        'Diffa Region',
        'Dosso Region',
        'Maradi Region',
        'Tahoua Region',
        'Tillabéri Region',
        'Zinder Region',
        'Abia State',
        'Adamawa State',
        'Akwa Ibom State',
        'Anambra State',
        'Bauchi State',
        'Bayelsa State',
        'Benue State',
        'Borno State',
        'Cross River State',
        'Delta State',
        'Ebonyi State',
        'Edo State',
        'Ekiti State',
        'Enugu State',
        'Federal Capital Territory',
        'Gombe State',
        'Imo State',
        'Jigawa State',
        'Kaduna State',
        'Kano State',
        'Katsina State',
        'Kebbi State',
        'Kogi State',
        'Kwara State',
        'Lagos State',
        'Nasarawa State',
        'Niger State',
        'Ogun State',
        'Ondo State',
        'Osun State',
        'Oyo State',
        'Plateau State',
        'Sokoto State',
        'Taraba State',
        'Yobe State',
        'Zamfara State',
        'Akershus',
        'Buskerud',
        'Finnmark',
        'Hedmark',
        'Hordaland',
        'Jan Mayen',
        'Møre og Romsdal',
        'Nord-Trøndelag',
        'Nordland',
        'Oppland',
        'Oslo',
        'Østfold',
        'Rogaland',
        'Sogn og Fjordane',
        'Sør-Trøndelag',
        'Svalbard',
        'Telemark',
        'Troms',
        'Trøndelag',
        'Vest-Agder',
        'Vestfold',
        'Ad Dakhiliyah Governorate',
        'Ad Dhahirah Governorate',
        'Al Batinah North Governorate',
        'Al Batinah Region',
        'Al Batinah South Governorate',
        'Al Buraimi Governorate',
        'Al Wusta Governorate',
        'Ash Sharqiyah North Governorate',
        'Ash Sharqiyah Region',
        'Ash Sharqiyah South Governorate',
        'Dhofar Governorate',
        'Musandam Governorate',
        'Muscat Governorate',
        'Azad Kashmir',
        'Balochistan',
        'Federally Administered Tribal Areas',
        'Gilgit-Baltistan',
        'Islamabad Capital Territory',
        'Khyber Pakhtunkhwa',
        'Sindh',
        'Aimeliik',
        'Airai',
        'Angaur',
        'Hatohobei',
        'Kayangel',
        'Koror',
        'Melekeok',
        'Ngaraard',
        'Ngarchelong',
        'Ngardmau',
        'Ngatpang',
        'Ngchesar',
        'Ngeremlengui',
        'Ngiwal',
        'Peleliu',
        'Sonsorol',
        'Bocas del Toro Province',
        'Chiriquí Province',
        'Coclé Province',
        'Colón Province',
        'Darién Province',
        'Emberá-Wounaan Comarca',
        'Guna Yala',
        'Herrera Province',
        'Los Santos Province',
        'Ngöbe-Buglé Comarca',
        'Panamá Oeste Province',
        'Panamá Province',
        'Veraguas Province',
        'Bougainville',
        'Chimbu Province',
        'East New Britain',
        'Eastern Highlands Province',
        'Enga Province',
        'Gulf',
        'Hela',
        'Jiwaka Province',
        'Madang Province',
        'Manus Province',
        'Milne Bay Province',
        'Morobe Province',
        'New Ireland Province',
        'Oro Province',
        'Port Moresby',
        'Sandaun Province',
        'Southern Highlands Province',
        'West New Britain Province',
        'Western Highlands Province',
        'Alto Paraguay Department',
        'Alto Paraná Department',
        'Amambay Department',
        'Boquerón Department',
        'Caaguazú',
        'Caazapá',
        'Canindeyú',
        'Central Department',
        'Concepción Department',
        'Cordillera Department',
        'Guairá Department',
        'Itapúa',
        'Misiones Department',
        'Ñeembucú Department',
        'Paraguarí Department',
        'Presidente Hayes Department',
        'San Pedro Department',
        'Áncash',
        'Apurímac',
        'Arequipa',
        'Ayacucho',
        'Cajamarca',
        'Callao',
        'Cusco',
        'Huancavelica',
        'Huanuco',
        'Ica',
        'Junín',
        'La Libertad',
        'Lambayeque',
        'Lima',
        'Madre de Dios',
        'Moquegua',
        'Pasco',
        'Piura',
        'Puno',
        'San Martín',
        'Tacna',
        'Tumbes',
        'Ucayali',
        'Abra',
        'Agusan del Norte',
        'Agusan del Sur',
        'Aklan',
        'Albay',
        'Antique',
        'Apayao',
        'Aurora',
        'Autonomous Region in Muslim Mindanao',
        'Basilan',
        'Bataan',
        'Batanes',
        'Batangas',
        'Benguet',
        'Bicol Region',
        'Biliran',
        'Bohol',
        'Bukidnon',
        'Bulacan',
        'Cagayan',
        'Cagayan Valley',
        'Calabarzon',
        'Camarines Norte',
        'Camarines Sur',
        'Camiguin',
        'Capiz',
        'Caraga',
        'Catanduanes',
        'Cavite',
        'Cebu',
        'Central Luzon',
        'Central Visayas',
        'Compostela Valley',
        'Cordillera Administrative Region',
        'Cotabato',
        'Davao del Norte',
        'Davao del Sur',
        'Davao Occidental',
        'Davao Oriental',
        'Davao Region',
        'Dinagat Islands',
        'Eastern Samar',
        'Eastern Visayas',
        'Guimaras',
        'Ifugao',
        'Ilocos Norte',
        'Ilocos Region',
        'Ilocos Sur',
        'Iloilo',
        'Isabela',
        'Kalinga',
        'La Union',
        'Laguna',
        'Lanao del Norte',
        'Lanao del Sur',
        'Leyte',
        'Maguindanao',
        'Marinduque',
        'Masbate',
        'Metro Manila',
        'Mimaropa',
        'Misamis Occidental',
        'Misamis Oriental',
        'Mountain Province',
        'Negros Occidental',
        'Negros Oriental',
        'Northern Mindanao',
        'Northern Samar',
        'Nueva Ecija',
        'Nueva Vizcaya',
        'Occidental Mindoro',
        'Oriental Mindoro',
        'Palawan',
        'Pampanga',
        'Pangasinan',
        'Quezon',
        'Quirino',
        'Rizal',
        'Romblon',
        'Sarangani',
        'Siquijor',
        'Soccsksargen',
        'Sorsogon',
        'South Cotabato',
        'Southern Leyte',
        'Sultan Kudarat',
        'Sulu',
        'Surigao del Norte',
        'Surigao del Sur',
        'Tarlac',
        'Tawi-Tawi',
        'Western Visayas',
        'Zambales',
        'Zamboanga del Norte',
        'Zamboanga del Sur',
        'Zamboanga Peninsula',
        'Zamboanga Sibugay',
        'Greater Poland Voivodeship',
        'Kielce',
        'Kuyavian-Pomeranian Voivodeship',
        'Lesser Poland Voivodeship',
        'Lower Silesian Voivodeship',
        'Lublin Voivodeship',
        'Lubusz Voivodeship',
        'Łódź Voivodeship',
        'Masovian Voivodeship',
        'Opole Voivodeship',
        'Podkarpackie Voivodeship',
        'Podlaskie Voivodeship',
        'Pomeranian Voivodeship',
        'Silesian Voivodeship',
        'Świętokrzyskie Voivodeship',
        'Warmian-Masurian Voivodeship',
        'West Pomeranian Voivodeship',
        'Aveiro District',
        'Azores',
        'Beja District',
        'Braga District',
        'Bragança District',
        'Castelo Branco District',
        'Coimbra District',
        'Évora District',
        'Faro District',
        'Guarda District',
        'Leiria District',
        'Lisbon District',
        'Madeira',
        'Portalegre District',
        'Porto District',
        'Santarém District',
        'Setúbal District',
        'Viana do Castelo District',
        'Vila Real District',
        'Viseu District',
        'Al Daayen',
        'Al Khor',
        'Al Rayyan Municipality',
        'Al Wakrah',
        'Al-Shahaniya',
        'Doha',
        'Madinat ash Shamal',
        'Umm Salal Municipality',
        'Alba',
        'Arad County',
        'Arges',
        'Bacău County',
        'Bihor County',
        'Bistrița-Năsăud County',
        'Botoșani County',
        'Braila',
        'Brașov County',
        'Bucharest',
        'Buzău County',
        'Călărași County',
        'Caraș-Severin County',
        'Cluj County',
        'Constanța County',
        'Covasna County',
        'Dâmbovița County',
        'Dolj County',
        'Galați County',
        'Giurgiu County',
        'Gorj County',
        'Harghita County',
        'Hunedoara County',
        'Ialomița County',
        'Iași County',
        'Ilfov County',
        'Maramureș County',
        'Mehedinți County',
        'Neamț County',
        'Olt County',
        'Prahova County',
        'Sălaj County',
        'Satu Mare County',
        'Sibiu County',
        'Suceava County',
        'Teleorman County',
        'Timiș County',
        'Tulcea County',
        'Vâlcea County',
        'Vaslui County',
        'Vrancea County'
      ])
    ]
  }
]

export const locationCityData = [
  {
    id: 0,
    label: 'All Locations',
    children: [
      ...new Set([
        'Tokyo',
        'Jakarta',
        'Delhi',
        'Guangzhou',
        'Mumbai',
        'Manila',
        'Shanghai',
        'São Paulo',
        'Seoul',
        'Mexico City',
        'Cairo',
        'New York City',
        'Dhaka',
        'Beijing',
        'Kolkāta',
        'Bangkok',
        'Shenzhen',
        'Moscow',
        'Buenos Aires',
        'Lagos',
        'Istanbul',
        'Karachi',
        'Bangalore',
        'Ho Chi Minh City',
        'Ōsaka',
        'Chengdu',
        'Tehran',
        'Kinshasa',
        'Rio de Janeiro',
        'Chennai',
        'Xi’an',
        'Lahore',
        'Chongqing',
        'Los Angeles',
        'Baoding',
        'London',
        'Paris',
        'Linyi',
        'Dongguan',
        'Hyderābād',
        'Tianjin',
        'Lima',
        'Wuhan',
        'Nanyang',
        'Hangzhou',
        'Foshan',
        'Nagoya',
        'Tongshan',
        'Luanda',
        'Zhoukou',
        'Ganzhou',
        'Kuala Lumpur',
        'Heze',
        'Quanzhou',
        'Johannesburg',
        'Chicago',
        'Nanjing',
        'Jining',
        'Hanoi',
        'Pune',
        'Fuyang',
        'Ahmedabad',
        'Bogotá',
        'Shenyang',
        'Dar es Salaam',
        'Khartoum',
        'Shangqiu',
        'Hong Kong',
        'Cangzhou',
        'Riyadh',
        'Santiago',
        'Xingtai',
        'Zhumadian',
        'Chattogram',
        'Surabaya',
        'Zhanjiang',
        'Bijie',
        'Yancheng',
        'Hengyang',
        'Zunyi',
        'Shaoyang',
        'Sūrat',
        'Shangrao',
        'Xinyang',
        'Madrid',
        'Baghdad',
        'Maoming',
        'Jieyang',
        'Miami',
        'Singapore',
        'Houston',
        'Liaocheng',
        'Huanggang',
        'Dalian',
        'Dallas',
        'Qingdao',
        'Yulin',
        'Douala',
        'Qujing',
        'Nangandao',
        'Philadelphia',
        'Pudong',
        'Toronto',
        'Zhengzhou',
        'Dezhou',
        'Nanchong',
        'Jinan',
        'Giza',
        'Nairobi',
        'Guadalajara',
        'Ankara',
        'Tai’an',
        'Langfang',
        'Dazhou',
        'Saint Petersburg',
        'Monterrey',
        'Belo Horizonte',
        'Suzhou',
        'Yongzhou',
        'Changde',
        'Xiangyang',
        'Rangoon',
        'Atlanta',
        'Washington',
        'Zhaotong',
        'Zhangzhou',
        'Melbourne',
        'Yichun',
        'Bozhou',
        'Suqian',
        'Abidjan',
        'Ji’an',
        'Guilin',
        'Pingdingshan',
        'Berlin',
        'Alexandria',
        'Mianyang',
        'Sydney',
        'Huanglongsi',
        'Barcelona',
        'Yuncheng',
        'Cape Town',
        'Changsha',
        'Jeddah',
        'Weinan',
        'Chenzhou',
        'Jiangmen',
        'Jiujiang',
        'Xinpu',
        'Yibin',
        'Huaihua',
        'Yangzhou',
        'Taizhou',
        'Kunming',
        'Yiyang',
        'Changchun',
        'Lu’an',
        'Jiangguanchi',
        'Meizhou',
        'Ürümqi',
        'Suzhou',
        'Boston',
        'İzmir',
        'Guigang',
        'Shantou',
        'Kabul',
        'Xiaoganzhan',
        'Bamako',
        'Luzhou',
        'Hefei',
        'Hengshui',
        'Fortaleza',
        'Anqing',
        'Liuzhou',
        'Zhangjiakou',
        'Zhaoqing',
        'Shijiazhuang',
        'Ningbo',
        'Qiqihar',
        'Phoenix',
        'Fuzhou',
        'Chifeng',
        'Xiaoxita',
        'Amman',
        'Chuzhou',
        'Linfen',
        'Qingyuan',
        'Xianyang',
        'Loudi',
        'Binzhou',
        'Zhuzhou',
        'Taiyuan',
        'Nanning',
        'Harbin',
        'Abuja',
        'Yokohama',
        'Suihua',
        'Zaozhuang',
        'Detroit',
        'Xiamen',
        'Neijiang',
        'Montréal',
        'Fuzhou',
        'Baicheng',
        'Wuhu',
        'Yulinshi',
        'Medan',
        'Wenzhou',
        'Changzhou',
        'Puyang',
        'Jiaozuo',
        'Nanchang',
        'Seattle',
        'Ibadan',
        'Casablanca',
        'Kumasi',
        'Deyang',
        'Busan',
        'Hohhot',
        'Hechi',
        'Algiers',
        'Tangshan',
        'Shiyan',
        'Lucknow',
        'Mashhad',
        'San Francisco',
        'Boankra',
        'Dubai',
        'Anshan',
        'Baojishi',
        'Qinzhou',
        'Guiyang',
        'Bengbu',
        'Bazhou',
        'Suining',
        'Wuxi',
        'Kotla Qasim Khan',
        'Hanzhong',
        'Putian',
        'Zhenjiang',
        'Guang’an',
        'Faisalabad',
        'Changzhi',
        'Tongren',
        'Leshan',
        'Santa Cruz de la Sierra',
        'Qinhuangdao',
        'Jaipur',
        'Xinzhou',
        'Lanzhou',
        'Wuzhou',
        'Athens',
        'San Diego',
        'Addis Ababa',
        'Taichung',
        'Huainan',
        'Guatemala City',
        'Kuwait City',
        'Budapest',
        'Qincheng',
        'Rizhao',
        'Quezon City',
        'Sanaa',
        'Tashkent',
        'Kyiv',
        'Meishan',
        'Incheon',
        'Birmingham',
        'Ningde',
        'Zhongshan',
        'Weihai',
        'Bursa',
        'Minneapolis',
        'Mbuji-Mayi',
        'Haikou',
        'Tongliao',
        'Chaoyang',
        'La Paz',
        'Pyongyang',
        'Tampa',
        'Shaoguan',
        'Heyuan',
        'Brasília',
        'Omdurman',
        'Malang',
        'Stuttgart',
        'Daqing',
        'Rome',
        'Brooklyn',
        'Kaohsiung',
        'Xiangtan',
        'Longyan',
        'Baotou',
        'Handan',
        'Jinzhou',
        'Kanpur',
        'Denver',
        'Nanping',
        'Gāzipura',
        'Shanwei',
        'Chaozhou',
        'Guayaquil',
        'Weifang',
        'Huai’an',
        'Zibo',
        'Ankang',
        'Mogadishu',
        'Munich',
        'Gulou',
        'Taipei',
        'Bekasi',
        'Damascus',
        'Sanming',
        'Yangjiang',
        'Jiamusi',
        'Luohe',
        'Medellín',
        'Dingxi',
        'Shaoxing',
        'Yantai',
        'Huizhou',
        'Lishui',
        'Xuanzhou',
        'Khowrhesht',
        'Mirzāpur',
        'Zigong',
        'Hamburg',
        'Guangyuan',
        'Cali',
        'Huangshi',
        'Xining',
        'Lusaka',
        'Ouagadougou',
        'Yaoundé',
        'Zhuhai',
        'Huludao',
        'Baoshan',
        'Mecca',
        'Vancouver',
        'Lianshan',
        'Beirut',
        'Salvador',
        'Bucharest',
        'Longba',
        'Nāgpur',
        'Queens',
        'Jilin',
        'Tieling',
        'Accra',
        'Yunfu',
        'Bekasi Kota',
        'Daegu',
        'Ghāziābād',
        'Luoyang',
        'Brisbane',
        'Anshun',
        'Riverside',
        'Yingkou',
        'Colombo',
        'Yanjiang',
        'Baku',
        'Antananarivo',
        'Mudanjiang',
        'Fukuoka',
        'Yan’an',
        'Jincheng',
        'Nantong',
        'Lincang',
        'Yuxi',
        'Las Vegas',
        'Caracas',
        'Tangerang',
        'Laibin',
        'Konya',
        'Supaul',
        'Vienna',
        'Eşfahān',
        'Baltimore',
        'Shengli',
        'Dandong',
        'Qinbaling',
        'Gaoping',
        'Awka',
        'Taizhou',
        'Ma’anshan',
        'Harare',
        'Perth',
        'St. Louis',
        'Phnom Penh',
        'Depok',
        'Stockholm',
        'Puning',
        'Huaibei',
        'Kowloon',
        'Córdoba',
        'Haiphong',
        'Zamboanga City',
        'Chongzuo',
        'Rawalpindi',
        'Portland',
        'Kano',
        'Yushan',
        'Havana',
        'Hezhou',
        'Pingliang',
        'Vadodara',
        'Manaus',
        'Qingyang',
        'San Antonio',
        'Rājkot',
        'Shangzhou',
        'Vishākhapatnam',
        'Sanmenxia',
        'Baicheng',
        'Gujranwala',
        'Aleppo',
        'Tijuana',
        'Bamenda',
        'Minsk',
        'Indore',
        'Karaj',
        'Kananga',
        'Peshawar',
        'Sapporo',
        'Sacramento',
        'Tilburg',
        'Pingxiang',
        'Ecatepec',
        'Almaty',
        'Austin',
        'Yinchuan',
        'Santos',
        'Blantyre',
        'Thāne',
        'Orlando',
        'Tainan',
        'Xiping',
        'Multan',
        'Santa Cruz',
        'Port Harcourt',
        'Jixi',
        'Fushun',
        'Warsaw',
        'Beihai',
        'Fuxin',
        'Wuwei',
        'Siping',
        'San Juan',
        'Mersin',
        'Bhopāl',
        'Mosul',
        'Lubumbashi',
        'Davao',
        'Curitiba',
        'San Jose',
        'Shuyangzha',
        'Adana',
        'Quito',
        'Pittsburgh',
        'Brazzaville',
        'Hyderabad City',
        'Diyarbakır',
        'Indianapolis',
        'Pimpri-Chinchwad',
        'Masqaţ',
        'Montevideo',
        'Shuozhou',
        'Manhattan',
        'Cincinnati',
        'Kansas City',
        'Patna',
        'Tegucigalpa',
        'Kampala',
        'Cleveland',
        'Sanzhou',
        'Changshu',
        'Heihe',
        'Conakry',
        'Ximeicun',
        'Caloocan City',
        'Masvingo',
        'Zhongli',
        'Novosibirsk',
        'Bilāspur',
        'Semarang',
        'Jingdezhen',
        'Ludhiāna',
        'Liaoyang',
        'Chengtangcun',
        'Rājshāhi',
        'Balandougou',
        'Jiangyin',
        'Valencia',
        'Āgra',
        'León de los Aldama',
        'Puebla',
        'Columbus',
        'Yopougon',
        'Hebi',
        'Shīrāz',
        'Madurai',
        'Huzhou',
        'Tabrīz',
        'Jamshedpur',
        'Maracaibo',
        'Sofia',
        'San José',
        'Prayagraj',
        'Palembang',
        'Kawasaki',
        'Kōbe',
        'Jiaxing',
        'Kigali',
        'Zhangjiajie',
        'Baiyin',
        'Guiping',
        'Lianjiang',
        'Jianguang',
        'Yucheng',
        'Xushan',
        'Panama City',
        'Nneyi-Umuleri',
        'Leizhou',
        'Gwangju',
        'Katako-Kombe',
        'Recife',
        'Nāsik',
        'Valencia',
        'Onitsha',
        'Abu Dhabi',
        'Zapopan',
        'Daejeon',
        'Bronx',
        'Yekaterinburg',
        'Huazhou',
        'Jinhua',
        'Kyōto',
        'Amsterdam',
        'Pizhou',
        'Kismaayo',
        'Yangshe',
        'Virginia Beach',
        'Dakar',
        'Goiânia',
        'Charlotte',
        'Rui’an',
        'Muscat',
        'Kharkiv',
        'Wenling',
        'Gaozhou',
        'Farīdābād',
        'Medina',
        'Khulna',
        'Ulaanbaatar',
        'Fuqing',
        'Kayseri',
        'Tel Aviv-Yafo',
        'Wuzhong',
        'Pingdu',
        'Sangereng',
        'Yangquan',
        'Samsun',
        'Yutan',
        'Copenhagen',
        'Helsinki',
        'Prague',
        'Milan',
        'Auckland',
        'Santiago',
        'Chizhou',
        'Makassar',
        'Liangshi',
        'Porto Alegre',
        'Huangshan',
        'Barranquilla',
        'Al Başrah',
        'Benxi',
        'Saitama',
        'Guarulhos',
        'Juárez',
        'Mandalay',
        'Xintai',
        'Wusong',
        'Calgary',
        'Meerut',
        'Yushu',
        'Belém',
        'Kuaidamao',
        'Huazhou',
        'Baishan',
        'Adelaide',
        'Haicheng',
        'Milwaukee',
        'Providence',
        'Jacksonville',
        'Yicheng',
        'Cacuaco',
        'Porto',
        'Rosario',
        'Canagatan',
        'Soweto',
        'Bagam',
        'Jabalpur',
        'Rucheng',
        'Huaiyin',
        'Dublin',
        'Kazan',
        'Dayan',
        'Shaoyang',
        'Balıkesir',
        'Comayagüela',
        'Laiwu',
        'Sharjah',
        'Jingling',
        'Kalyān',
        'Nizhniy Novgorod',
        'Yongcheng',
        'Sumedang',
        'Cần Thơ',
        'Brussels',
        'Suwon',
        'Yiwu',
        'Beidao',
        'Vasai-Virar',
        'Xiangshui',
        'Dadukou',
        'Campinas',
        'Lingcheng',
        'Shuangyashan',
        'Mombasa',
        'Najafgarh',
        'Xinyu',
        'Qom',
        'Hargeysa',
        'Baidoa',
        'Zhangye',
        'Vārānasi',
        'Hiroshima',
        'Chiang Mai',
        'Belgrade',
        'Maiduguri',
        'Chelyabinsk',
        'Batam Centre',
        'Rongcheng',
        'Mbandaka',
        'Doha',
        'Ahvāz',
        'Shymkent',
        'Tripoli',
        'Srīnagar',
        'Nashville',
        'Liaoyuan',
        'Aurangābād',
        'Cilacap',
        'Salt Lake City',
        'Omsk',
        'Pikine',
        'Samara',
        'Guankou',
        'Bandar Lampung',
        'Raleigh',
        'Lianyuan',
        'Rongcheng',
        'Dhanbād',
        'Nay Pyi Taw',
        'Aba',
        'Kaiyuan',
        'Zhuji',
        'Yingtan',
        'Edmonton',
        'Leiyang',
        'Ulsan',
        'Yichun',
        'Benin City',
        'Bujumbura',
        'Guyuan',
        'Xiantao',
        'Rostov',
        'Maputo',
        'Bukavu',
        'Amritsar',
        'Shagamu',
        'Yingchuan',
        'Alīgarh',
        'Santo Domingo',
        'Bogor',
        'Bishkek',
        'Tbilisi',
        'Guwāhāti',
        'Ufa',
        'Fès',
        'Mwanza',
        'Biên Hòa',
        'Mexicali',
        'Sevilla',
        'Ikare',
        'Dongtai',
        'Dingzhou',
        'Xibeijie',
        'Tamale',
        'Yuyao',
        'Hanchuan',
        'Gongzhuling',
        'N’Djamena',
        'Ubungo',
        'Cologne',
        'Krasnoyarsk',
        'Zhufeng',
        'Ezhou',
        'Astana',
        'Nezahualcóyotl',
        'Nouakchott',
        'Hāora',
        'Tongjin',
        'Xiashi',
        'Yerevan',
        'Rānchi',
        'Richmond',
        'Ciudad Nezahualcóyotl',
        'Gwalior',
        'Ottawa',
        'Zhongwei',
        'Oslo',
        'Goyang',
        'Sendai',
        'Mizhou',
        'Xishan',
        'Barquisimeto',
        'Hegang',
        'Chandīgarh',
        'Voronezh',
        'Managua',
        'Haldwāni',
        'Vijayavāda',
        'Perm',
        'Fangchenggang',
        'Jiancheng',
        'Cazenga',
        'Kisangani',
        'Shouguang',
        'Memphis',
        'São Luís',
        'Jodhpur',
        'Matola',
        'Ogbomoso',
        'Sanya',
        'Rangapukur',
        'Ashgabat',
        'Wutong',
        'Linhai',
        'Denizli',
        'Niamey',
        'Shubrā al Khaymah',
        'Wafangdian',
        'Zhongxiang',
        'Monrovia',
        'San Cristóbal',
        'Islamabad',
        'Xinyi',
        'Thủ Đức',
        'Morelia',
        'Odesa',
        'Raipur',
        'Changwon',
        'Arequipa',
        'Volgograd',
        'Zaoyang',
        'Xingyi',
        'Shuizhai',
        'Kota',
        'Quetta',
        'Quảng Hà',
        'Domaa-Ahenkro',
        'Bareilly',
        'Oklahoma City',
        'Bordeaux',
        'Xingcheng',
        'Taixing',
        'Xinhualu',
        'Lilongwe',
        'Port-au-Prince',
        'Yingcheng',
        'Al Mijlad',
        'Luocheng',
        'Pekanbaru',
        'Natal',
        'Chiba',
        'Kirkuk',
        'Hartford',
        'Huilong',
        'Wuchuan',
        'Dnipro',
        'Nārāyanganj',
        'Gqeberha',
        'Málaga',
        'Marrakech',
        'Cebu City',
        'Louisville',
        'Asmara',
        'Coimbatore',
        'Maceió',
        'Nada',
        'Taishan',
        'Teresina',
        'Solāpur',
        'Freetown',
        'Santo Domingo Este',
        'Krasnodar',
        'Vientiane',
        'Tangier',
        'Anqiu',
        'Alberta',
        'Kermānshāh',
        'Feicheng',
        'Kibanseke Première',
        'Seberang Jaya',
        'Buffalo',
        'Hubli',
        'El Alto',
        'Çankaya',
        'Hwasu-dong',
        'Setagaya',
        'Keçiören',
        'Jerusalem',
        'Khartoum North',
        'Meishan',
        'Mushin',
        'Trujillo',
        'Kitakyūshū',
        'Aguascalientes',
        'New Orleans',
        'Fort Worth',
        'Taihe',
        'Riga',
        'Xin’an',
        'Taihecun',
        'Kashgar',
        'Sŏngnam',
        'Trichinopoly',
        'Cartagena',
        'Qingzhou',
        'Naples',
        'Santiago del Estero',
        'Naucalpan de Juárez',
        'Daye',
        'Hengzhou',
        'Padang',
        'Bridgeport',
        'Owerri',
        'Zhuanghe',
        'Bobo-Dioulasso',
        'Ad Dammām',
        'Quzhou',
        'Donetsk',
        'Ashmūn',
        'Bunia',
        'Jiaozhou',
        'Campo Grande',
        'Wuchang',
        'São Gonçalo',
        'Bucaramanga',
        'Mérida',
        'Yangchun',
        'Osmangazi',
        'Esenyurt',
        'Morādābād',
        'Bangui',
        'Abeokuta',
        'Cancún',
        'Antipolo',
        'Dengtalu',
        'Taguig City',
        'Tabūk',
        'Zhoushan',
        'Tucson',
        'As Sulaymānīyah',
        'Chihuahua',
        'Klang',
        'Tiruppūr',
        'Gurgaon',
        'Ar Ramādī',
        'Hai’an',
        'Laiyang',
        'Barinas',
        'Jalandhar',
        'Marseille',
        'Kaifeng Chengguanzhen',
        'Eskişehir',
        'Gaomi',
        'Lhasa',
        'Ipoh',
        'El Paso',
        'Saltillo',
        'Dushanbe',
        'Ikeja',
        'El Dorado',
        'Cochabamba',
        'Portsmouth',
        'Tyumen',
        'Southampton',
        'Hermosillo',
        'Wuxi',
        'Leping',
        'Cheongju',
        'Shache',
        'Sale',
        'Hailun',
        'Macheng',
        'Akure',
        'Ilorin',
        'Erbil',
        'Kathmandu',
        'Saratov',
        'Iguaçu',
        'Zijinglu',
        'Turin',
        'Yuci',
        'Dehui',
        'Pietermaritzburg',
        'Durban',
        'Bhubaneshwar',
        'Denpasar',
        'Tongchuan',
        'João Pessoa',
        'Samarinda',
        'Chengxiang',
        'Rongjiawan',
        'Weichanglu',
        'Sakai',
        'Renqiu',
        'Omaha',
        'Xindi',
        'Wu’an',
        'Qingping',
        'Gaoyou',
        'São Bernardo do Campo',
        'Yiyang',
        'Hejian',
        'Puxi',
        'Bhayandar',
        'Androtsy',
        'Culiacán',
        'Cúcuta',
        'Danyang',
        'Dongyang',
        'Kraków',
        'Pasig City',
        'Thessaloníki',
        'Querétaro',
        'Palermo',
        'Xigazê',
        'Qamdo',
        'McAllen',
        'Libreville',
        'Seyhan',
        'San Pedro Sula',
        'Niigata',
        'Hempstead',
        'Leeds',
        'Hamamatsu',
        'Pointe-Noire',
        'Xiangxiang',
        'Birmingham',
        'Chaohucun',
        'Bucheon',
        'Lubango',
        'Homs',
        'Bilbao',
        'Zouping',
        'Frankfurt',
        'San Luis Potosí',
        'Dali',
        'Fuyang',
        'Khŭjand',
        'Korla',
        'Albuquerque',
        'Hamhŭng',
        'Erzurum',
        'Zagreb',
        'Al ‘Ayn',
        'Songzi',
        'Patiāla',
        'Laixi',
        'Zhongba',
        'Bahawalpur',
        'Qingnian',
        'Kaduna',
        'Winnipeg',
        'Trabzon',
        'Guangshui',
        'Baardheere',
        'Shīshgarh',
        'Nerima',
        'Sizhan',
        'Ciudad Guayana',
        'Natal',
        'Lichuan',
        'Licheng',
        'Santo André',
        'Ōta-ku',
        'Gaalkacyo',
        'Thiruvananthapuram',
        'Osasco',
        'Nampula',
        'Pretoria',
        'Kyaukse',
        'Chengguan',
        'Nehe',
        'Cabinda',
        'Kumamoto',
        'Kermān',
        'Zunhua',
        'Orūmīyeh',
        'Wugang',
        'Bağcılar',
        'Quebec City',
        'Shuangqiao',
        'Umraniye',
        'Yanggok',
        'Tshikapa',
        'Tulsa',
        'Osogbo',
        'Comodoro Rivadavia',
        'Nottingham',
        'Hamilton',
        'Langzhong',
        'Cagayan de Oro',
        'Qian’an',
        'Fresno',
        'An Najaf',
        'Cencheng',
        'Sorocaba',
        'Guli',
        'Sagamihara',
        'Pīshbar',
        'Okayama',
        'Anlu',
        'Concepción',
        'Mississauga',
        'Changsha',
        'Songyang',
        'Lviv',
        'Shihezi',
        'Vilnius',
        'Marka',
        'Enugu',
        'Valenzuela',
        'Yatou',
        'Uberlândia',
        'Xichang',
        'Zaporizhzhia',
        'Bhiwandi',
        'George Town',
        'Bristol',
        'Charleston',
        'Sahāranpur',
        'Dashiqiao',
        'Yenimahalle',
        'Warangal',
        'Nampo',
        'Dasmariñas',
        'Jaboatão',
        'Chisinau',
        'Shiliguri',
        'Boosaaso',
        'Port Moresby',
        'Latakia',
        'Rochester',
        'Ribeirão Prêto',
        'Edogawa',
        'São José dos Campos',
        'General Santos',
        'Ḩamāh',
        'Qianxi',
        'Bauchi',
        'Pendik',
        'Salem',
        'Shishi',
        'Sinnūris',
        'Cocody',
        'Miluo Chengguanzhen',
        'Lokoja',
        'Guadalupe',
        'Gaizhou',
        'Karbalā’',
        'Borvāyeh-ye Al Bū ‘Azīz',
        'City of Parañaque',
        'Leling',
        'Mamak',
        'Jianshe',
        'Tolyatti',
        'Shizuoka',
        'Jingcheng',
        'Agege',
        'Mar del Plata',
        'Zaragoza',
        'Adachi',
        'Xinmin',
        'Rasht',
        'Shanhu',
        'Zhongshu',
        'Cotonou',
        'Tasikmalaya',
        'Kochi',
        'Soledad',
        'Dhūlia',
        'Acapulco de Juárez',
        'Gorakhpur',
        'Bahār',
        'Kumul',
        'Wrocław',
        'Murcia',
        'Pinghu',
        'Guankou',
        'Łódź',
        'Guntūr',
        'Bhāngar',
        'Dayton',
        'Ch’ŏngjin',
        'Qionghu',
        'Zhaodong',
        'Puyang Chengguanzhen',
        'Bulawayo',
        'Huambo',
        'Aracaju',
        'Bacoor',
        'Wenchang',
        'Rotterdam',
        'Tlaquepaque',
        'Villavicencio',
        'Shulan',
        'Makhachkala',
        'Banjarmasin',
        'Narela',
        'Catia La Mar',
        'Al Hufūf',
        'Jalingo',
        'Sargodha',
        'Karaikandi',
        'Bouaké',
        'Lingbao Chengguanzhen',
        'Brampton',
        'Abomey-Calavi',
        'Durango',
        'Cape Coral',
        'Tondo',
        'Dayrūţ',
        'Tlalnepantla',
        'Ansan',
        'Xiping',
        'Huế',
        'Sanhe',
        'San Jose del Monte',
        'Ch’ŏnan',
        'Cuiabá',
        'Jieshou',
        'Ērer Sātā',
        'Selçuklu',
        'Suohe',
        'Guixi',
        'Izhevsk',
        'Honchō',
        'Wuxue',
        'Jaboatão dos Guararapes',
        'Yıldırım',
        'Bhāvnagar',
        'Jinghong',
        'Tengyue',
        'Mission Viejo',
        'Ruiming',
        'Qufu',
        'Sha Tin',
        'Petaling Jaya',
        'Colorado Springs',
        'Noida',
        'Xinshi',
        'Baton Rouge',
        'Manama',
        'Las Palmas',
        'Chak Forty-one',
        'Jin’e',
        'Benghazi',
        'Chuxiong',
        'Barnaul',
        'Palermo',
        'Makati City',
        'Düsseldorf',
        'Allentown',
        'Xinxing',
        'Ţūkh',
        'Glasgow',
        'Namangan',
        'Bazal’tove',
        'Al Qaţīf',
        'Bhilai',
        'Ulyanovsk',
        'Mangalore',
        'Kaihua',
        'Irkutsk',
        'Meilan',
        'Bazhou',
        'Ogden',
        'Turpan',
        'Jos',
        'Al Manşūrah',
        'Contagem',
        'Jambi',
        'Ḩalwān',
        'Provo',
        'Tân An',
        'Port-Bouët',
        'Pontianak',
        'Meihekou',
        'Jurong',
        'Bihtā',
        'Yuhuan',
        'Joinvile',
        'Feira de Santana',
        'Khabarovsk',
        'Leipzig',
        'Xinji',
        'Knoxville',
        'Ta‘izz',
        'Etimesgut',
        'Changping',
        'Tallinn',
        'Chimalhuacán',
        'Kandahār',
        'Serang',
        'Zhangshu',
        'Grand Rapids',
        'Yukarıkaraman',
        'Gothenburg',
        'Kuantan',
        'Gold Coast',
        'Kawaguchi',
        'Las Piñas City',
        'Cuttack',
        'San Miguel de Tucumán',
        'Ar Rayyān',
        'Salīmpur',
        'Malanje',
        'Columbia',
        'Kryvyi Rih',
        'Djibouti',
        'Zhuozhou',
        'Tianchang',
        'Tunis',
        'Yaroslavl',
        'Bacolod',
        'Garoua',
        'Bafoussam',
        'Haifa',
        'Raurkela',
        'Tumkūr',
        'Balikpapan',
        'Somolu',
        'Vladivostok',
        'Al Ḩillah',
        'Melikgazi',
        'Kagoshima',
        'Sihui',
        'Dortmund',
        'Irapuato',
        'Al Maḩallah al Kubrá',
        'Sialkot City',
        'Luocheng',
        'Albany',
        'Pereira',
        'Gaza',
        'Uvira',
        'Reynosa',
        'Zāhedān',
        'Cimahi',
        'Mbale',
        'Wenlan',
        'Shangzhi',
        'Essen',
        'Itabashi',
        'Shah Alam',
        'Botou',
        'Suginami-ku',
        'Tenkāsi',
        'Kingston',
        'Al Mafraq',
        'Aţ Ţā’if',
        'Port Sudan',
        'Tuxtla',
        'Dehra Dūn',
        'Xiulin',
        'Fu’an',
        'Mymensingh',
        'Hachiōji',
        'Iloilo',
        'Puente Alto',
        'Tomsk',
        'Rabat',
        'Sincan',
        'Bakersfield',
        'Kottayam',
        'Luofeng',
        'Shibīn al Qanāţir',
        'Nakuru',
        'Lingyuan',
        'Tonalá',
        'Bremen',
        'Abū Ḩummuş',
        'Irbid',
        'Macau',
        'Surrey',
        'Ciudad Bolívar',
        'Durgāpur',
        'Shenzhou',
        'New Haven',
        'Orenburg',
        'Kuiju',
        'Zhenzhou',
        'Āsansol',
        'Bokaro Steel City',
        'Dresden',
        'Bello',
        'Kolhāpur',
        'Wencheng',
        'Lanxi',
        'Dangyang',
        'Nava Raipur',
        'Kemerovo',
        'Genoa',
        'Herāt',
        'Londrina',
        'Cuautitlán Izcalli',
        'Uyo',
        'Hamadān',
        'Luanzhou',
        'Chiclayo',
        'Surakarta',
        'Novokuznetsk',
        'Ajmer',
        'Kimhae',
        'Nānded',
        'Wuhai',
        'Palma',
        'Rustenburg',
        'Amrāvati',
        'Des Moines',
        'Lisbon',
        'Yanji',
        'Huanghua',
        'Al Ḩudaydah',
        'Anyang',
        'The Hague',
        'Andijon',
        'Manchester',
        'Nellore',
        'Poznań',
        'Samarkand',
        'Xingcheng',
        'Wancheng',
        'Kaiyuan',
        'Hannover',
        'Sungai Petani',
        'Valledupar',
        'Fengcheng',
        'Muntinlupa City',
        'Ghulja',
        'Ixtapaluca',
        'Fuding',
        'Heroica Matamoros',
        'Akron',
        'Mbeya',
        'An Nāşirīyah',
        'Xiangyang',
        'Ibagué',
        'Al Ḩillah',
        'Juiz de Fora',
        'City of Calamba',
        'El Geneina',
        'Santa Cruz',
        'Ryazan',
        'Chang’an',
        'Florianópolis',
        'Nilüfer',
        'Antwerp',
        'Kassala',
        'Aksu',
        'Salta',
        'Dispur',
        'Palm Bay',
        'Naberezhnyye Chelny',
        'Gulbarga',
        'Nansana',
        'Mingguang',
        'Concord',
        'Beira',
        'Yazd',
        'Ardabīl',
        'Touba',
        'Bīkaner',
        'Gaobeidian',
        'Ndola',
        'Himeji',
        'Ailan Mubage',
        'Bandar ‘Abbās',
        'Skopje',
        'Santa Teresa del Tuy',
        'Port Said',
        'Astrakhan',
        'Kōtō-ku',
        'Ciudad Apodaca',
        'Montería',
        'Nuremberg',
        'Kitchener',
        'Yucheng',
        'Nāgercoil',
        'Agartala',
        'Soacha',
        'Buca',
        'Lyon',
        'Maipú',
        'Arāk',
        'Serra',
        'Tultitlán de Mariano Escobedo',
        'Meknès',
        'Bắc Ninh',
        'Anda',
        'Longzhou',
        'Al Fayyūm',
        'Utsunomiya',
        'Sheffield',
        'Mixco',
        'Suez',
        'Heshan',
        'Penza',
        'Loni',
        'Jiaji',
        'Santa Marta',
        'Ujjain',
        'Beining',
        'Abū Ţisht',
        'Maturín',
        'Liverpool',
        'Macapá',
        'Benguela',
        'Yicheng',
        'Al Fashn',
        'Al ‘Amārah',
        'Carrefour',
        'Campos',
        'Cadaado',
        'Encheng',
        'Bhīlwāra',
        'Bibā',
        'Wichita',
        'Leicester',
        'Newcastle',
        'Hải Dương',
        'Aden',
        'Jhānsi',
        'Matsuyama',
        'Ulhāsnagar',
        'Nagqu',
        'Kitwe',
        'Vellore',
        'Toulouse',
        'Pohang',
        'Mesa',
        'Lipetsk',
        'Duisburg',
        'Jammu',
        'Ile-Ife',
        'Homyel’',
        'Kirov',
        'Mazatlán',
        'Meicheng',
        'El Fasher',
        'Farāh',
        'Belas',
        'Talatona',
        'Nenjiang',
        'Sukkur',
        'Hsinchu',
        'Harrisburg',
        'Kaliningrad',
        'Hongjiang',
        'Qaraghandy',
        'Lapu-Lapu City',
        'Matsudo',
        'Johor Bahru',
        'Purnea',
        'Imus',
        'Niterói',
        'Beipiao',
        'Dengtacun',
        'Zhijiang',
        'Suoluntun',
        'Staten Island',
        'Chengjiao',
        'Lembok',
        'Likasi',
        'Oujda-Angad',
        'Duyun',
        'Toledo',
        'Pindi Bhattian',
        'Nyala',
        'Bissau',
        'Ichikawa',
        'Kota Bharu',
        'Yuanping',
        'Higashi-ōsaka',
        'Larkana',
        '‘Ajmān',
        'Vinh',
        'Ciudad López Mateos',
        'Cheboksary',
        'Yueqing',
        'Belgaum',
        'Caerdydd',
        'Edinburgh',
        'Brookhaven',
        'Nishinomiya-hama',
        'Karamay',
        'Worcester',
        'Kawachichō',
        'Shahe',
        'Gdańsk',
        'Sevastopol',
        'Garoowe',
        'Gaoping',
        'Villa Nueva',
        'Dunhua',
        'Lianran',
        'Akhmīm',
        'Şanlıurfa',
        'Az Zarqā’',
        'Mālegaon',
        'São José do Rio Prêto',
        'Valletta',
        'Kolwezi',
        'Jāmnagar',
        'Sylhet',
        'Ananindeua',
        'East London',
        'Berbera',
        'Jiannan',
        'Chiniot',
        'Asunción',
        'Ōita',
        'Nangong',
        'Bārdoli',
        'Eldoret',
        'Bratislava',
        'Kurashiki',
        'Al Jubayl',
        'Worthing',
        'Gaya',
        'Shekhupura',
        'Piura',
        'Vila Velha',
        'Ar Ruşayfah',
        'Jiaojiangcun',
        'Laohekou',
        'San Juan',
        'Mykolaiv',
        'Beian',
        'Fujin',
        'Küçükçekmece',
        'Mazār-e Sharīf',
        'Xiaoyi',
        'Balashikha',
        'Qingzhen',
        'Tula',
        'Ba‘qūbah',
        'Katlehong',
        'Jiangshan',
        'Buraydah',
        'Surab',
        'Kupang',
        'Ambattūr',
        'Nakhon Ratchasima',
        'Tân Uyên',
        'Constantine',
        'Longjiang',
        'Caxias do Sul',
        'Angeles City',
        'Kuqa',
        'Kanazawa',
        'Long Beach',
        'Port St. Lucie',
        'Manado Light',
        'Kartal',
        'Cranbourne',
        'Jalgaon',
        'Porto Velho',
        'Chhatarpur',
        'Fukuyama',
        'Little Rock',
        'Juba',
        'Lanús',
        'Amagasaki',
        'Kikwit',
        'Pyeongtaek',
        'Reno',
        'Kurnool',
        'Spokane',
        'Marikina City',
        'Jian’ou',
        'Huadian',
        'Melaka',
        'Manado',
        'Manizales',
        'Bornova',
        'Minzhu',
        'Demiryol',
        'Erköklü',
        'Kota Kinabalu',
        'Katsushika-ku',
        'Madison',
        'Santiago de Cuba',
        'Udaipur',
        'Kursk',
        'Mogi das Cruzes',
        'Stavropol',
        'General Trias',
        'Sirājganj',
        'Boise',
        'Bonita Springs',
        'Mariupol',
        'Eslāmshahr',
        'Piraeus',
        'Barcelona',
        'Tanbei',
        'Zürich',
        'Pingquan',
        'Ado-Ekiti',
        'Baisha',
        'Batman',
        'Yongji',
        'Esenler',
        'Rodriguez',
        'Ensenada',
        'Danjiangkou',
        'Chauddagram',
        'Kahramanmaraş',
        'San Nicolás de los Garza',
        'Taoyuan District',
        'Ndjili',
        'Mathura',
        'Pasay City',
        'Ning’an',
        'Halifax',
        'Fujisawa',
        'Ulan-Ude',
        'Denton',
        'Laval',
        'Jinchang',
        'Oakland',
        'Springfield',
        'Guangming',
        'Augusta',
        'Kâğıthane',
        'Sunch’ŏn',
        'Sāngli',
        'Avcılar',
        'Jeju',
        'Zhuangyuan',
        'Davangere',
        'Machida',
        'Sanghar',
        'Al Marāghah',
        'Bandung',
        'Kissimmee',
        'Calicut',
        'Kenitra',
        'Windhoek',
        'Huili Chengguanzhen',
        'Sidi Bouzid',
        'Bārāmati',
        'Ţanţā',
        'Ismailia',
        'Cusco',
        'Veracruz',
        'Sokoto',
        'Winston-Salem',
        'Kashiwa',
        'Al Bājūr',
        'Xunyang',
        'Malatya',
        'Yan’an Beilu',
        'Mothīhāri',
        'Aomori',
        'Akola',
        'Mandaluyong City',
        'Aves',
        'Sihŭng',
        'Burco',
        'Tver',
        'Xalapa',
        'Buenaventura',
        'London',
        'Piracicaba',
        'Yogyakarta',
        'Toyota',
        'Daloa',
        'Agadir',
        'Elazığ',
        'Uijeongbu',
        'Hpa-An',
        'Rahimyar Khan',
        'Ugep',
        'Hailin',
        'Mishan',
        'Sarajevo',
        'Seremban',
        'Zhengjiatun',
        'Lecheng',
        'Campina Grande',
        'Xicheng',
        'Pencheng',
        'Kowloon City',
        'Tirana',
        'Kushtia',
        'El Obeid',
        'Mauá',
        'Magnitogorsk',
        'Da’an',
        'Luhansk',
        'Xingren',
        'Takamatsu',
        'Arusha',
        'Fenyang',
        'Ajdābiyā',
        'Callao',
        'Awsīm',
        'Shinagawa-ku',
        'Paju',
        'Santa Rosa',
        'Bettiah',
        'Jhang City',
        'Altındağ',
        'Talā',
        'Stockton',
        'Ţalkhā',
        'Boa Vista',
        'Banjul',
        'Jayapura',
        'Toyama',
        'Sanandaj',
        'Khon Kaen',
        'Fangting',
        'Linghai',
        'Shorāpur',
        'Koumassi',
        'Betim',
        'Sochi',
        'Tinnevelly',
        'Pasto',
        'Syracuse',
        'Bellary',
        'Bhāgalpur',
        'Kisumu',
        'Zhangjiakou Shi Xuanhua Qu',
        'Maringá',
        'Kocasinan',
        'Mataram',
        'Shashemenē',
        'Zaria',
        'Kumi',
        'Wanyuan',
        'Biñan',
        'Chattanooga',
        'Jiexiu',
        'Bağlar',
        'Padiāla',
        'Đà Lạt',
        'Sham Shui Po',
        'Santa Fe',
        'Delhi Cantonment',
        'Cumaná',
        'Barura',
        'Yüreğir',
        'Nagasaki',
        'Mardan',
        'Hat Yai',
        'Salt Lake City',
        'Qazvīn',
        'Etāwa',
        'Lancaster',
        'Sonīpat',
        'Jundiaí',
        'Ivanovo',
        'Greenville',
        'Toyonaka',
        'Bogra',
        'Öskemen',
        'Gifu',
        'Maungdaw',
        'Jiangjiafan',
        'Durham',
        'Bryansk',
        'Dera Ghazi Khan',
        'Anápolis',
        'Pensacola',
        'Miyazaki',
        'Quilon',
        'Mulangodi',
        'Munro Turuttu',
        'Hirakata',
        'Sandakan',
        'Szczecin',
        'Brno',
        'Surgut',
        'Hejin',
        'Fayetteville',
        'Betsiboka',
        'Thiès',
        'Arlington',
        'Al Jahrā’',
        'Kaunas',
        'Thanh Hóa',
        'Diadema',
        'Lobito',
        'Saurimo',
        'Yola',
        'Zhugang',
        'Tāngāil',
        'Nha Trang',
        'Khayelitsha',
        'Ad Dīwānīyah',
        'Nnewi',
        'Hancheng',
        'San-Pédro',
        'Gujrat',
        'Yokosuka',
        'Tieli',
        'Asyūţ',
        'Gwoza',
        'Sampaloc',
        'Saki',
        'Bologna',
        'Aqtöbe',
        'Cilegon',
        'Uvinza',
        'Aurora',
        'Carapicuíba',
        'Ḩafr al Bāţin',
        'Zanjān',
        'Petrolina',
        'Bairia',
        'Oyo',
        'Taytay',
        'Kisenzi',
        'Bhātpāra',
        'Kūkatpalli',
        'Manisa',
        'Sirūr',
        'Tarlac City',
        'Okazaki',
        'Lianzhou',
        'Ceel Baraf',
        'Yidu',
        'Lingxi',
        'Ilesa',
        'Kākināda',
        'Savar',
        'Nuevo Laredo',
        'Bawshar',
        'Christchurch',
        'Gusau',
        'Zêtang',
        'Palu',
        'Canberra',
        'Minamisuita',
        'Tétouan',
        'Malabon',
        'Neiva',
        'Novi Sad',
        'Huancayo',
        'Celaya',
        'Ichinomiya',
        'Caruaru',
        'Sintra',
        'Hatay',
        'Iquitos',
        'Pānihāti',
        'Cainta',
        'Helixi',
        'Mamou',
        'Manukau City',
        'Itaquaquecetuba',
        'Cantonment',
        'Rohtak',
        'Gaziantep',
        'Māler Kotla',
        'Bhawana',
        'Khorramābād',
        'Lipa City',
        'Butuan',
        'Dikirnis',
        'Stoke-on-Trent',
        'Takasaki',
        'Malakwal',
        'Toyohashi',
        'Chitungwiza',
        'Gebze',
        'Cibinong',
        'Lengshuijiang',
        'Panshi',
        'Az Zubayr',
        'Oxnard',
        'Vinnytsia',
        'Indio',
        'Bharatpur',
        'Petare',
        'Nagano',
        'Huichang',
        'Keelung',
        'Bauru',
        'La Florida',
        'Nicolás Romero',
        'Jinshan',
        'Baguio City',
        'Scranton',
        'Bochum',
        'Sivas',
        'Kolga',
        'Korba',
        'Qardho',
        'Rio Branco',
        'Tecámac',
        'Alanya',
        'Mandaue City',
        'Victorville',
        'Kocaeli',
        'Warri',
        'Victoria',
        'Wŏnsan',
        'Iligan',
        'Anguo',
        'K’ebrī Dehar',
        'Coventry',
        'Kayapınar',
        'Trenton',
        'Cuenca',
        'Blumenau',
        'Nanqiao',
        'Florence',
        'Buurhakaba',
        'Bengkulu',
        'Malmö',
        'Wudalianchi',
        'Shuanghe',
        'Pétion-Ville',
        'Utrecht',
        'Sīkar',
        'Umuahia',
        'Vitsyebsk',
        'Palmira',
        'Wuppertal',
        'Hrodna',
        'Ash Shuhadā’',
        'Karūr',
        'Ponta Grossa',
        'Sasarām',
        'Taraz',
        'Cubal',
        'Luena',
        'Karnāl',
        'Yong’an',
        'Konak',
        'Minatitlán',
        'Linxia Chengguanzhen',
        'Brahmapur',
        'Chānda',
        'Caucaia',
        'Cuito',
        'Cabuyao',
        'Hongzhai',
        'Gedaref',
        'San Fernando',
        'Kawagoe',
        'Modesto',
        'Nizhniy Tagil',
        'Pokhara',
        'Villahermosa',
        'Van',
        'Mahilyow',
        'Wakayama',
        'Osh',
        'Kita-ku',
        'Gimpo',
        'Corrientes',
        'Franca',
        'Thari Mir Wah',
        'Nara',
        'Vladimir',
        'Yakeshi',
        'Nam Định',
        'Sinŭiju',
        'Oruro',
        'Cabimas',
        'Arkhangelsk',
        'Batangas',
        'Ibb',
        'Ahmadnagar',
        'Sarai Alamgir',
        'Semey',
        'Holguín',
        'Tungi',
        'Yingmen',
        'Sawrān',
        'Chita',
        'Olinda',
        'Praia Grande',
        'Dāsarhalli',
        'Huntsville',
        'Shinjuku',
        'Alicante',
        'Cariacica',
        'Varna',
        'Honolulu',
        'Antofagasta',
        'Ambon',
        'Nice',
        'Cascavel',
        'Pamukkale',
        'Canoas',
        'Takatsuki',
        'António Enes',
        'Greensboro',
        'Anaheim',
        'Plovdiv',
        'Central Coast',
        'Karşıyaka',
        'Shāhjānpur',
        'Gwagwalada',
        'Alamādi',
        'Āvadi',
        'Tanch’ŏn',
        'Khānāpur',
        'Wad Medani',
        'Kūstī',
        'Belfast',
        'Hosūr',
        'Cuddapah',
        'Nakano',
        'Ōtsu',
        'Maseru',
        'Makiivka',
        'Pavlodar',
        'Chon Buri',
        'Naga City',
        'Sarıyer',
        'Brest',
        'Meram',
        'Gómez Palacio',
        'Paulista',
        'Rājahmundry',
        'Koshigaya',
        'Vũng Tàu',
        'Jeonju',
        'Alwar',
        'Tokorozawa',
        'Sumqayıt',
        'Vitória da Conquista',
        'Simferopol',
        'Buôn Ma Thuột',
        'Serekunda',
        'Islip',
        'Cuernavaca',
        'Markham',
        'Bielefeld',
        'Uberaba',
        'Jitpur',
        'Bydgoszcz',
        'Tangdong',
        'Chinju',
        'Corpus Christi',
        'Fort Wayne',
        'Reading',
        'Randburg',
        'Matadi',
        'Bonn',
        'Iwaki',
        'Oshawa',
        'Shah Latif Town',
        'Sambalpur',
        'Fort Collins',
        'Jackson',
        'Yingzhong',
        'Santo Domingo de los Colorados',
        'Uruapan',
        'Lublin',
        'Licheng',
        'Tampere',
        'Belgorod',
        'Muzaffarpur',
        'Viña del Mar',
        'Tepic',
        'Khipro',
        'Hangu',
        'Asan',
        'Chak Jhumra',
        'Myrtle Beach',
        'Soledad de Graciano Sánchez',
        'Şalālah',
        'Santarém',
        'Yanbu',
        'Maebashi',
        'Kaluga',
        'Dumai',
        'Beylikdüzü',
        'Gəncə',
        'Asahikawa',
        'Kendari',
        'Wŏnju',
        'Birkat as Sab‘',
        'Lafia',
        'Dahūk',
        'Finglas',
        'Kāmārhāti',
        'Thái Nguyên',
        'Bamiantong',
        'Nicosia',
        'São Vicente',
        'Ribeirão das Neves',
        'Guédiawaye',
        'Ciudad Obregón',
        'São José dos Pinhais',
        'Campīernagar',
        'Phatthaya',
        'Fayetteville',
        'Debrecen',
        'Mirpur Mathelo',
        'Sultanbeyli',
        'Bijāpur',
        'Cabanatuan City',
        'Tharād',
        'Antioch',
        'Si Racha',
        'Salamanca',
        'Ratnāgiri',
        'Ulanhot',
        'Koriyama',
        'Yunzhong',
        'Roodepoort',
        'Volzhskiy',
        'Pucallpa',
        'San Pedro',
        'Pelotas',
        'Kōchi',
        'Rāmpur',
        'Kuching',
        'Cotabato',
        'Cimanggis',
        'Gonaïves',
        'Nazrēt',
        'Kikuyu',
        'Córdoba',
        'Kluang',
        'Mekele',
        'Binxian',
        'Nantes',
        'Vaughan',
        'Kaiyuan',
        'Vitória',
        'Shimoga',
        'Lansing',
        'Uíge',
        'Hotan',
        'Camagüey',
        'Taourirt',
        'San Salvador de Jujuy',
        'Kāshīpur',
        'Thủ Dầu Một',
        'Al Kūt',
        'Lexington',
        'Sukabumi',
        'Münster',
        'Menongue',
        'Mobile',
        'Godē',
        'Okene',
        'Jūnāgadh',
        'Smolensk',
        'Guasavito',
        'Asan',
        'Nukus',
        'Kaech’ŏn',
        'Tehuacán',
        'Coatzacoalcos',
        'Muhanga',
        'Youngstown',
        'Zalantun',
        'Saransk',
        'Katsina',
        'Puerto Plata',
        'Henderson',
        'Gwangmyeongni',
        'Geita',
        'Cherepovets',
        'Taubaté',
        'An Nhơn',
        'Fontibón',
        'Hāpur',
        'Kot Radha Kishan',
        'Barueri',
        'San Salvador',
        'Savannah',
        'Naha',
        'Bari',
        'Xiaoli',
        'Trichūr',
        'Mannheim',
        'Bor',
        'Cà Mau',
        'Saint-Denis',
        'San Miguelito',
        'Muar',
        'Kasur',
        'Usme',
        'Barddhamān',
        'Poughkeepsie',
        'Kingston upon Hull',
        'Binangonan',
        'Temara',
        'Attiecoubé',
        'Jiayuguan',
        'Pasir Gudang',
        'Vologda',
        'Gorgān',
        'Tanza',
        'Yakutsk',
        'Panvel',
        'Santa Ana',
        'Guarujá',
        'Nizāmābād',
        'Quy Nhơn',
        'Ḩā’il',
        'Datang',
        'Longquan',
        'Mitchells Plain',
        'Gwangju',
        'Yokkaichi',
        'Chalco',
        'Shahrīār',
        'Shahr-e Qods',
        'Kurgan',
        'Ciudad del Este',
        'Ann Arbor',
        'St. Paul',
        'Karlsruhe',
        'Safi',
        'Ciudad Benito Juárez',
        'Karawang',
        'Sariwŏn',
        'Suzano',
        'Newark',
        'Nghi Sơn',
        'Parbhani',
        'Puerto Princesa',
        'Hisar',
        'Vladikavkaz',
        'Windsor',
        'Kasugai',
        'Ciudad Santa Catarina',
        'Puerto La Cruz',
        'Fatehpur',
        'Ciudad Victoria',
        'Playa del Carmen',
        'Yeşilyurt',
        'Yonghetun',
        'Irvine',
        'Kāshān',
        'Minna',
        'Sumbawanga',
        'Orël',
        'Akita',
        'Kurume',
        'Az Zaqāzīq',
        'Podolsk',
        'Palmas',
        'Montpellier',
        'Vila Nova de Gaia',
        'Bahía Blanca',
        'Al Waqf',
        'San Bernardo',
        'San Juan del Río',
        'Armenia',
        'Augsburg',
        'Qianzhou',
        'Popayán',
        'Al Qunfudhah',
        'Yakou',
        'Newcastle',
        'Oulgaret',
        'Āwasa',
        'Ingrāj Bāzār',
        'Oaxaca',
        'Binjai',
        'Barr Eliâs',
        'Khairpur Tamewah',
        'Sucre',
        'Al ‘Ajamī',
        'Al Maţarīyah',
        'Bada Barabīl',
        'Ash Shāmīyah',
        'Oyster Bay',
        'Ar Raqqah',
        'Chakwal',
        'Ōakashichō',
        'Afyonkarahisar',
        'Dod Ballāpur',
        'Camaçari',
        'Ciudad General Escobedo',
        'Catania',
        'Jember',
        'Al Mubarraz',
        'Pekalongan',
        'Los Mochis',
        'Toshima',
        'Pachuca',
        'Yangsan',
        'Valladolid',
        'Tampico',
        'Bihār',
        'Espoo',
        'Malabo',
        'Pilar',
        'Valparaíso',
        'Cirebon',
        'Tagum',
        'Santa Rosa',
        'Darbhanga',
        'Comilla',
        'Battalgazi',
        'Sorong',
        'Shubrākhīt',
        'Floridablanca',
        'Silang',
        'Eloy Alfaro',
        'Pondokaren',
        'Sikandarābād',
        'Kafr Şaqr',
        'Vila Teixeira da Silva',
        'Pānīpat',
        'Rangpur',
        'Białystok',
        'Canton',
        'Asheville',
        'Flint',
        'Vigo',
        'Coacalco',
        'Āīzawl',
        'Bāli',
        'Bradford',
        'Mabalacat',
        'Dexing',
        'Winter Haven',
        'Graz',
        'Palni',
        'Resistencia',
        'Groznyy',
        'Chimbote',
        'Strasbourg',
        'Bergen',
        'Gatineau',
        'Surajgarha',
        'Tegal',
        'Anchorage',
        'Batna',
        'Aarhus',
        'Morioka',
        'Lincoln',
        'Hulin',
        'Hong’an',
        'Karīmnagar',
        'Santa Maria',
        'Tambov',
        'Dewās',
        'Güngören',
        'Magway',
        'Farg‘ona',
        'Concord',
        'Hugli',
        'Chunchura',
        'Sétif',
        'Sonpur',
        'Meguro',
        'Machala',
        'San Lorenzo',
        'Jersey City',
        'Ichalkaranji',
        'Punto Fijo',
        'Várzea Grande',
        'Tirupati',
        'Pathein',
        'Chernihiv',
        'Sincelejo',
        'Cluj-Napoca',
        'Springfield',
        'Sekondi',
        'Tacna',
        'Tin Shui Wai',
        'Juazeiro do Norte',
        'Al Qurnah',
        'Korhogo',
        'Bhatinda',
        'Katowice',
        'Jālna',
        'Foz do Iguaçu',
        'Bolton',
        'San Pablo',
        'Huixquilucan',
        'Plano',
        'Qillīn',
        'Croix-des-Bouquets',
        'San Juan Sacatepéquez',
        'Ljubljana',
        'Fukushima',
        'Bago',
        'Delmas',
        'Fuquan',
        'Ibaraki',
        'Shreveport',
        'Ostrava',
        'Poltava',
        'Wiesbaden',
        'Satna',
        'Sannai',
        'Huozhou',
        'Temuco',
        'Ica',
        'Tongchuanshi',
        'Jining',
        'Chuncheon',
        'Sakarya',
        'İnegöl',
        'Kaura Namoda',
        'Davenport',
        'Malārd',
        'Lubbock',
        'Lakeland',
        'Sterlitamak',
        'Bukhara',
        'Santa Ana',
        'Sumbe',
        'Mingaora',
        'Çorlu',
        'Kherson',
        'Lucena',
        'Petrópolis',
        'Mamuju',
        'Mau',
        'Nizhnevartovsk',
        'Long Xuyên',
        'Petrozavodsk',
        'Gyeongsan',
        'Bārāsat',
        'South Bend',
        'Pematangsiantar',
        'Maastricht',
        'Việt Trì',
        'Sunderland',
        'Kostroma',
        'Gagnoa',
        'Xingsha',
        'Dire Dawa',
        'Lashkar Gāh',
        'Itagüí',
        'Juliaca',
        'Chula Vista',
        'Posadas',
        'Farrukhābād',
        'Chandler',
        'Kunsan',
        'Yeosu',
        'Qarshi',
        'Saugor',
        'Khmelnytskyi',
        'Nassau',
        'Ratlām',
        'Crato',
        'Yeosu',
        'Shaowu',
        'Pasarkemis',
        'Cotia',
        'Taboão da Serra',
        'San Mateo',
        'Novorossiysk',
        'Tsu',
        'Rockford',
        'Imperatriz',
        'Los Alcarrizos',
        'Soubré',
        'Reading',
        'Székesfehérvár',
        'Majene',
        'Sumida',
        'Chopda',
        'Gabela',
        'Dayr az Zawr',
        'Iaşi',
        'Santa Maria',
        'Sartā',
        'Eugene',
        'Iksan',
        'Mỹ Tho',
        'Nguru',
        'Arnavutköy',
        'Derby',
        'Mito',
        'Kunp’o',
        'Gombe',
        'Bijiao',
        'Cherkasy',
        'Bayat',
        'Handwāra',
        'Kunduz',
        'Drug',
        'Wilmington',
        'Mönchengladbach',
        'Gijón',
        'Brāhmanbāria',
        'Santa Clarita',
        'Thái Bình',
        'Ichihara',
        'Tarija',
        'Shibīn al Kawm',
        'Plymouth',
        'Aswān',
        'Bimo',
        'Murmansk',
        'Gilbert',
        'Maradi',
        'Xiangkhoang',
        'Anantapur',
        'Adıyaman',
        'Kütahya',
        'Yoshkar-Ola',
        'Marabá',
        'Salem',
        'Saskatoon',
        'Sumaré',
        'Killeen',
        'Nagaoka',
        'Djelfa',
        'Sumy',
        'Khwazakhela',
        'Chernivtsi',
        'Suncheon',
        'Kibaha',
        'Nalchik',
        'Sfax',
        'Gent',
        'Gravataí',
        'Antsirabe',
        'Feni',
        'Engels',
        'Imphāl',
        'Taunggyi',
        'Nogales',
        'Ed Daein',
        'Dezfūl',
        'Mossoró',
        'Round Lake Beach',
        'Potosí',
        'Osmaniye',
        'Columbus',
        'Itajaí',
        'North Las Vegas',
        'Tāluqān',
        'Constanţa',
        'Luque',
        'Yao',
        'Jalālābād',
        'Nawabshah',
        'Talisay',
        'Gelsenkirchen',
        'Jagdalpur',
        'Tchibota',
        'Kafr ad Dawwār',
        'Quilmes',
        'Wollongong',
        'Zhytomyr',
        'Volta Redonda',
        'Fukui',
        'Arrah',
        'Malolos',
        'Heroica Nogales',
        'Bariadi',
        'Hong',
        'Oumé',
        'Fuchū',
        'Minato',
        'Boksburg',
        'Olongapo',
        'Quảng Ngãi',
        'Al Ḩamzah',
        'Kennewick',
        'Qo‘qon',
        'Kotri',
        'St. Petersburg',
        'Mişrātah',
        'Aydın',
        'Singa',
        'Manta',
        'Tallahassee',
        'Kakogawachō-honmachi',
        'Isparta',
        'Siverek',
        'Ndulo',
        'Antalya',
        'Huayin',
        'Hiratsuka',
        'Rāniyah',
        'Annaba',
        'Governador Valadares',
        'Khimki',
        'Ondo',
        'Etāwah',
        'Siddhirganj',
        'Horlivka',
        'Indaiatuba',
        'Bloemfontein',
        'Ấp Đa Lợi',
        'Türkmenabat',
        'Malkājgiri',
        'Vitoria-Gasteiz',
        'Germiston',
        'Nonthaburi',
        'Verona',
        'Tuzla',
        'Westminster',
        'Laredo',
        'Kuala Terengganu',
        'San Pedro Carchá',
        'Moçâmedes',
        'Irving',
        'Turmero',
        'Tokushima',
        'São Carlos',
        'Longueuil',
        'Marilao',
        'Tuni',
        'Ash Shaţrah',
        'Sab‘ al Būr',
        'Fort-de-France',
        'Mawlamyine',
        'Peoria',
        'Godomè',
        'Rāpar',
        'Samāstipur',
        'Aksaray',
        'Shinozaki',
        'Parnamirim',
        'Kızıltepe',
        'Jhenida',
        'Turku',
        'Bharatpur',
        'Aachen',
        'Begusarai',
        'Kediri',
        'Kanggye',
        'Chiayi',
        'Çekme',
        'Hakodate',
        'Tacloban',
        'Jūnāgarh',
        'Braunschweig',
        'İskenderun',
        'Pécs',
        'Sōka',
        'Nedumana',
        'Higüey',
        'Los Teques',
        'Montgomery',
        'Jinshi',
        'Wolverhampton',
        'Pointe-à-Pitre',
        'Al Fallūjah',
        'Timişoara',
        'Bata',
        'Rạch Giá',
        'Companiganj',
        'Venice',
        'Taganrog',
        'Bābol',
        'São José',
        'La Paz',
        'Al Bayḑā’',
        'Natogyi',
        'Kurmuk',
        'Râs el-Barr',
        'Kalār',
        'Bojnūrd',
        'Türkistan',
        'New Delhi',
        'Las Condes',
        'Goma',
        'Rishon LeẔiyyon',
        'Komsomol’sk-na-Amure',
        'Campeche',
        'Manzhouli',
        'Tiruvottiyūr',
        'Palangkaraya',
        'Chesapeake',
        'Burnaby',
        'Rāmnagar',
        'Limeira',
        'Carmen',
        'Gāndhīdhām',
        'Banikoara',
        'Chemnitz',
        'Salūmbar',
        'Kyŏngju',
        'Mérida',
        'Glendale',
        'Sibu',
        'Qā’em Shahr',
        'Paraná',
        'Büyükçekmece',
        'Kiel',
        'Sahiwal',
        'A Coruña',
        'Navotas',
        'Santa Clara',
        'Mehrabpur',
        'Yamagata',
        'York',
        'Khomeynī Shahr',
        'Beykoz',
        'Tsukuba-kenkyūgakuen-toshi',
        'Oruro',
        'Macaé',
        'Győr',
        'Al ‘Āshir min Ramaḑān',
        'Mahajanga',
        'Mount Lavinia',
        'Northampton',
        'Krishnarājpur',
        'Hafizabad',
        'Nelamangala',
        'Beichengqu',
        'Abertawe',
        'Syktyvkar',
        'Rivne',
        'Gdynia',
        'Nashua',
        'Barnsley',
        'Taiping',
        'Rondonópolis',
        'São José de Ribamar',
        'Puducherry',
        'Merlo',
        'Portoviejo',
        'Damanhūr',
        'Garland',
        'Kabinda',
        'Jessore',
        'Kesbewa',
        'Tripoli',
        'Fuji',
        'Eindhoven',
        'Sabzevār',
        'Dourados',
        'Bahir Dar',
        'Yoshiichō-shimobaru',
        'Myitkyina',
        'Rāmgundam',
        'Pālanpur',
        'Tuy Hòa',
        'Sasebo',
        'Sapele',
        'Birāṭnagar',
        'Pandharpur',
        'Qyzylorda',
        'St. Catharines',
        'Chigasaki',
        'Araraquara',
        'Kahama',
        'Halle',
        'Americana',
        'Zhangping',
        'Man',
        'Sete Lagoas',
        'Bānchpār',
        'Haeju',
        'Soyapango',
        'Masaurhi Buzurg',
        'Baruta',
        'Düzce',
        'Marília',
        'Katihār',
        'Scottsdale',
        'Tarapoto',
        'Atushi',
        'Abī al Khaşīb',
        'Jacareí',
        'Anju',
        'Bunkyō-ku',
        'Byatarayanpur',
        'Ahor',
        'Diaobingshancun',
        'Magdeburg',
        'Yato',
        'Matsumoto',
        'Szeged',
        'Chimoio',
        'Kasulu',
        'Elche',
        'Tarsus',
        'Ivano-Frankivsk',
        'Chōfugaoka',
        'González Catán',
        'Nyíregyháza',
        'Wuyishan',
        'Shenmu',
        'Tuticorin',
        'As Sīb',
        'Gangānagar',
        'Braşov',
        'Lafayette',
        'Āmol',
        'Stavanger',
        'Sandnes',
        'Nizhnekamsk',
        'Monclova',
        'Chishui',
        'Djougou',
        'Norfolk',
        'Mirpur Khas',
        'Lille',
        'P’yŏngsŏng-si',
        'P’yŏng-dong',
        'Centurion',
        'North Hempstead',
        'Tinkhang',
        'Rewa',
        'Pākdasht',
        'Kajang',
        'Petaẖ Tiqwa',
        'Abhā',
        'Freiburg im Breisgau',
        'Al Minyā',
        'Iseyin',
        'Central District',
        'Gaborone',
        'Arlington',
        'Juazeiro',
        'Zinder',
        'Bole',
        'Shakhty',
        'Ganda',
        'Uluberiya',
        'Bulandshahr',
        'Banda Aceh',
        'Najafābād',
        'Shibuya-ku',
        'Bayamo',
        'Limassol',
        'Borūjerd',
        'Ibb',
        'García',
        'Arapiraca',
        'Longquan',
        'Miri',
        'Maracanaú',
        'Oral',
        'Craiova',
        'Formosa',
        'Appleton',
        'Chauhanpatti',
        'Bo',
        'Mambéré',
        'Damboa',
        'Groningen',
        'Ipswich',
        'Teluk Intan',
        'San Cristóbal',
        'Cannanore',
        'Rāichūr',
        'Okara',
        'Saga',
        'Saidpur',
        'Lin’an',
        'Colombo',
        'Machilīpatnam',
        'Beni',
        'Nazipur',
        'Purwokerto',
        'Bratsk',
        'Biyalā',
        'Madan',
        'Rancagua',
        'Phú Yên',
        'Neuquén',
        'Divinópolis',
        'Qarchak',
        'Ormoc',
        'Fresnillo',
        'Dzerzhinsk',
        'Granada',
        'Sơn Tây',
        'Singkawang',
        'Zégoua',
        'Kulai',
        'Barishal',
        'Pāli',
        'Songadh',
        'Noginsk',
        'Gadda Madiral',
        'Orsk',
        'Ordu',
        'Košice',
        'Kasukabe',
        'Miriālgūda',
        'Aguadilla',
        'Envigado',
        'Haridwār',
        'Rock Hill',
        'Fremont',
        'Vizianagaram',
        'Cobán',
        'Khénifra',
        'Guantánamo',
        'Krefeld',
        'Şabyā',
        'Fargo',
        'Rāisinghnagar',
        'Uşak',
        'Pāthardi',
        'Magé',
        'Gulfport',
        'Neya',
        'Mel Pālaiyam',
        'Novo Hamburgo',
        'Ipatinga',
        'Sāqultah',
        'Choloma',
        'Kropyvnytskyi',
        'Khatīma',
        'Daşoguz',
        'Khanewal',
        'Petlād',
        'Kamianske',
        'Bremerton',
        'Ageoshimo',
        'Changhua',
        'Regina',
        'Badalona',
        'Dianbu',
        'Kolpino',
        'Rio Verde',
        'Meycauayan',
        'Presidente Prudente',
        'Green Bay',
        'Varāmīn',
        'Padangsidempuan',
        'Uacu Cungo',
        'Enterprise',
        'Hunchun',
        'Tarrasa',
        'Ternopil',
        'Kimberley',
        'Nadiād',
        'Toamasina',
        'Rennes',
        'Mutare',
        'Cikupa',
        'Ratodero',
        'Chuādānga',
        'Carlos Manuel de Céspedes',
        'Blagoveshchensk',
        'Nong’an',
        'Ōta',
        'Itaboraí',
        'Puerto Vallarta',
        'Capiatá',
        'Viamão',
        'Takarazuka',
        'Chapecó',
        'Banfield',
        'Toluca',
        'Atsugichō',
        'Paramaribo',
        'Cox’s Bāzār',
        'Bandar-e Būshehr',
        'Itapevi',
        'Staryy Oskol',
        'Probolinggo',
        'Hialeah',
        'Tanjore',
        'Ji’an Shi',
        'Angarsk',
        'Hachinohe',
        'Ijebu-Ode',
        'Velikiy Novgorod',
        'Barra do Dande',
        'Mezitli',
        'Sandton',
        'Beji',
        'Cork',
        'Dili',
        'Owo',
        'Swindon',
        'Myeik',
        'Cabo Frio',
        'Kichha',
        'Longjiang',
        'Katri',
        'Korolëv',
        'Sarıçam',
        'Guéckédou',
        'Neyshābūr',
        'Sousse',
        'Tabora',
        'Sóc Trăng',
        'Dagarua',
        'Deltona',
        'Tchaourou',
        'Rufisque',
        'San Bernardino',
        'Duekoué',
        'Gainesville',
        'Sambhal',
        'San Felipe',
        'Sāveh',
        'Mainz',
        'Santa Luzia',
        'Chí Linh',
        'La Vega',
        'Singrauliya',
        'El Jadid',
        'Kremenchuk',
        'Ashdod',
        'Prizren',
        'Spring Valley',
        'Thatta',
        'Yenişehir',
        'Al Khubar',
        'Osisioma',
        'Tacoma',
        'Tuluá',
        'Zanzibar',
        'Kafue',
        'Konibodom',
        'Petrel',
        'Messina',
        'Pangkalpinang',
        'Roanoke',
        'Bitung',
        'Santo Tomas',
        'San Miguel',
        'Sabadell',
        'Lübeck',
        'Cartagena',
        'Naihāti',
        'Arakawa',
        'Galaţi',
        'Babylon',
        'Laâyoune',
        'Butembo',
        'Oviedo',
        'Tapachula',
        'Porbandar',
        'São Leopoldo',
        'Apapa',
        'Netanya',
        'Qostanay',
        'Alor Setar',
        'Wayaobu',
        'Zielona Góra',
        'Batu',
        'Gujiao',
        'José C. Paz',
        'Yamunānagar',
        'Banjarbaru',
        'Valencia',
        'Ayacucho',
        'Wellington',
        'Peñalolén',
        'Dongxing',
        'Lutsk',
        'La Ceiba',
        'Ar Ruseris',
        'Hortolândia',
        'Pallāvaram',
        'Marg‘ilon',
        'Jiutepec',
        'Brownsville',
        'Tân An',
        'Golmud',
        'San Rafael',
        'Sidi Aïssa',
        'Khargone',
        'As Samāwah',
        'Maţraḩ',
        'Comalcalco',
        'Cúa',
        'Almere',
        'Vantaa',
        'Mokpo',
        'La Victoria',
        'Warnes',
        'Cascais',
        'Çiğli',
        'Marcory',
        'Chilas',
        'Sikasso',
        'Osan',
        'Oakville',
        'Secunderābād',
        'Sa Đéc',
        'Turbat',
        'El Tigre',
        'Bhuj',
        'Sodo',
        'Isesaki',
        'Jerez de la Frontera',
        'College Station',
        'Norwich',
        'Częstochowa',
        'Monghyr',
        'Luton',
        'Chāpra',
        'Sidi Bel Abbès',
        'Zhubei',
        'Tumaco',
        'Trondheim',
        'Mallawī',
        'Kure',
        'Kuje',
        'Bhadrāvati',
        'Taitō',
        '‘Aqrah',
        'Cao Lãnh',
        'Guadalupe',
        'Mytishchi',
        'Xigujing',
        'Irákleio',
        'Criciúma',
        'Panchkula',
        'Mirpur Bhtoro',
        'Burhānpur',
        'Olympia',
        'Oberhausen',
        'Burgas',
        'Sobral',
        'Clarksville',
        'Trece Martires City',
        'Gangneung',
        'Pagadian',
        'Quilicura',
        'Kirātot',
        'Linz',
        'Arroyo Naranjo',
        'Bukit Mertajam',
        'Dongning',
        'Richmond',
        'Rostock',
        'Kāpra',
        'Pskov',
        'Yonkers',
        'Burutu',
        'Móstoles',
        'Moreno Valley',
        'Thousand Oaks',
        'Legazpi City',
        'Portland',
        'Fontana',
        'Panabo',
        'Puerto Cabello',
        'Ich’ŏn',
        'Beersheba',
        'Bila Tserkva',
        'Santo Agostinho',
        'Oulu',
        'Luziânia',
        'Ciputat',
        'Guarenas',
        'Mohammedia',
        'Babruysk',
        'Taisheng',
        'Sunyani',
        'Lubuklinggau',
        'Ashaiman',
        'Ambāla',
        'Zamora',
        'Chungju',
        'Kasangati',
        'Kharagpur',
        'Farshūţ',
        'Monywa',
        'Nishitōkyō',
        'Robertsonpet',
        'Lyubertsy',
        'Dindigul',
        'Toledo',
        'Milton Keynes',
        'Marawi City',
        'Morogoro',
        'Rānīwāra Kalān',
        'Solihull',
        'Parakou',
        'Damietta',
        'Shimla',
        'Padova',
        'Banjar',
        'Cidade de Nacala',
        'Passo Fundo',
        'Dinājpur',
        'Hospet',
        'Islington',
        'Talca',
        'Hickory',
        'Amarillo',
        'Ifakara',
        'Pamplona',
        'Northcote',
        'Māldah',
        'San Carlos City',
        'Jiutai',
        'Phan Thiết',
        'Puqi',
        'Khān Yūnis',
        'Ongole',
        'Córdoba',
        'Brahmanpara',
        'Biskra',
        'Bingerville',
        'Betigeri',
        'Sioux Falls',
        'Mpanda',
        'Ternate',
        'Kassel',
        'Sakākā',
        'Sejong',
        'Quetzaltenango',
        'Coquimbo',
        'Tekirdağ',
        'Luzhang',
        'Kodumur',
        'Kukawa',
        'Geneva',
        'Huntington',
        'Ellore',
        'Evansville',
        'Kinh Môn',
        'Bīrjand',
        'Santa Ana',
        'Barrancabermeja',
        'La Guaira',
        'Loja',
        'Mandi Burewala',
        'Lauro de Freitas',
        'Mazabuka',
        'Deoghar',
        'Tanjungpinang',
        'Phủ Từ Sơn',
        'Huntington',
        'Chhindwāra',
        'Iringa',
        'Lingampalli',
        'Waterbury',
        'Mokameh',
        'Luxor',
        'Arica',
        'Las Tunas',
        'Qal‘at Bīshah',
        'Frisco',
        'Hà Tĩnh',
        'Richmond Hill',
        'Timayy al Imdīd',
        'Al Khums',
        'Charleroi',
        'Lorain',
        'Matsue',
        'Tarakan',
        'Loures',
        'Pingzhen',
        'Radom',
        'Petropavl',
        'Rio Claro',
        'Maroua',
        'Cajamarca',
        'Qinā',
        'Mai’Adua',
        'Hebron',
        'Puri',
        'Şidfā',
        'Soio',
        'Menemen',
        'Kalamazoo',
        'Haldia',
        'Jacobabad',
        'Khandwa',
        'Aberdeen',
        'Biysk',
        'Huacho',
        'Almería',
        'Yachiyo',
        'Nandyāl',
        'Pulimaddi',
        'Georgetown',
        'Morena',
        'Galveston',
        'Nasīm Shahr',
        'Cosenza',
        'Guacara',
        'Kabankalan',
        'Nagareyama',
        'Araçatuba',
        'Vĩnh Long',
        'Lạng Sơn',
        'Az Zāwīyah',
        'Isanlu',
        'Río Cuarto',
        'Lochau',
        'Karjat',
        'At Tājī',
        'Spartanburg',
        'Lahad Datu',
        'Drabar',
        'Madiun',
        'Santa Tecla',
        'Santa Barbara',
        'Pristina',
        'Uripa',
        'Khowy',
        'Gbadolite',
        'Huangyan',
        'Laqţah',
        'Amroha',
        'Lunga-Lunga',
        'Trieste',
        'Sunrise Manor',
        'Toruń',
        'Martapura',
        'Rānī',
        'Kankan',
        'Chakradharpur',
        'Helong',
        'Segamat',
        'Bhiwāni',
        'Bhind',
        'Huntington Beach',
        'Hobart',
        'Tocuyito',
        'Baure',
        'Higashi-Hiroshima',
        'Maricá',
        'Grand Prairie',
        'Ciudad Madero',
        'Itami',
        'Zākhū',
        'Kodaira',
        'Rajin',
        'Alcalá de Henares',
        'Parma',
        'Overland Park',
        'Huánuco',
        'Kusŏng',
        'Brescia',
        'Zipaquirá',
        'Khammam',
        'Kouribga',
        'McKinney',
        'Madhyamgram',
        'Sinop',
        'Kajo Kaji',
        'Viranşehir',
        'Ghāndīnagar',
        'Rzeszów',
        'Prato',
        'Waco',
        'Acarigua',
        'Myawadi',
        'Mwene-Ditu',
        'Karaköprü',
        'Parachinar',
        'Koronadal',
        'La Serena',
        'Maharagama',
        'San Pedro de Macorís',
        'Liège',
        'Hagerstown',
        'Suzuka',
        'Baharampur',
        'Tchitato',
        'Malema',
        'N’Zérékoré',
        'Mbarara',
        'Morbi',
        'Yuzhno-Sakhalinsk',
        'Cuautla',
        'Anseong',
        'Glendale',
        'Guanajuato',
        'Tébessa',
        'Kamirenjaku',
        'Holon',
        'San Fernando',
        'Pingtung',
        'Peterborough',
        'Tanauan',
        'Nampa',
        'Sūhāj',
        'Pāndhurnā',
        'Bené Beraq',
        'Sosnowiec',
        'Būkān',
        'Muş',
        'Fatehpur',
        'Kumagaya',
        'La Plata',
        'Banī Suwayf',
        'Lae',
        'Quelimane',
        'Hyesan',
        'Béni Mellal',
        'Jalapa',
        'Guanare',
        'Kaesŏng',
        'Nossa Senhora do Socorro',
        'Castanhal',
        'Croydon',
        'Ārba Minch’',
        'Rio Grande',
        'Gorontalo',
        'Florencia',
        'Fianarantsoa',
        'Tsing Yi Town',
        'Yamaguchi',
        'Iwo',
        'Rāe Bareli',
        'Godoy Cruz',
        'Peoria',
        'Ciudad del Carmen',
        'Bago',
        'Damān',
        'Valera',
        'Ouargla',
        'Cedar Rapids',
        'Manzanillo',
        'Malaybalay',
        'Armavir',
        'Vancouver',
        'Isidro Casanova',
        'Chibia',
        'Leganés',
        'Hino',
        'Orai',
        'Shahr-e Kord',
        'Rybinsk',
        'Jhelum',
        'Mahbūbnagar',
        'Prokopyevsk',
        'Pābna',
        'Cap-Haïtien',
        'Nova Friburgo',
        'Saddiqabad',
        'Balakovo',
        'Ngaoundéré',
        'Hagen',
        'Chŏngju',
        'Paradise',
        'Poza Rica de Hidalgo',
        'Murtazābād',
        'Rājendranagar',
        'Las Heras',
        'Odawara',
        'Abū Ghurayb',
        'Anjōmachi',
        'Araure',
        'Donostia',
        'Fuenlabrada',
        'Pinar del Río',
        'Kenema',
        'Digos',
        'El Progreso',
        'Al Ḩasakah',
        'San Francisco de Macorís',
        'Taranto',
        'Prabumulih',
        'Kishiwada',
        'Iquique',
        'Desē',
        'Gharyān',
        'Kecskemét',
        'Numazu',
        'Ratanpur',
        'Bournemouth',
        'Bhusāval',
        'Tottori',
        'Alvorada',
        'Jōetsu',
        'Chaedŏk',
        'Guatire',
        'Chilpancingo',
        'San Diego',
        'Kōfu',
        'Ucu Seles',
        'Calbayog City',
        'Kırıkkale',
        'Burlington',
        'Kielce',
        'Ocala',
        'Itabuna',
        'Kairouan',
        'Klerksdorp',
        'Pasuruan',
        'Bahraigh',
        'Ed Damazin',
        'Suva',
        'Basildon',
        'Getafe',
        'Cachoeiro de Itapemirim',
        'Potsdam',
        "St. John's",
        'Erie',
        'Umreth',
        'Dunhuang',
        'Semnān',
        'Narsingdi',
        'Newport News',
        'Temirtaū',
        'Banja Luka',
        'Ittikara',
        'Yei',
        'Mahesāna',
        'Frederick',
        'Kuchlagh',
        'Murfreesboro',
        'Ferraz de Vasconcelos',
        'Bolu',
        '‘Unayzah',
        'Pak Kret',
        'Seixal',
        'Al Qāmishlī',
        'Padalarang',
        'Modena',
        'Breda',
        'Toyokawa',
        'Vĩnh Châu',
        'Siguiri',
        'Cuango',
        'Rāiganj',
        'San Cristóbal',
        'Fernando de la Mora',
        'Trảng Bàng',
        'Aqtaū',
        'Santa Bárbara d’Oeste',
        'Batāla',
        'Niš',
        'Oradea',
        'Tumpat',
        'Malumfashi',
        'Isiro',
        'Pinrang',
        'Fort Lauderdale',
        'Jaraguá do Sul',
        'Sirsa',
        'Morsi',
        'Donghua',
        'Odense',
        'Nouméa',
        'Dinapore',
        'Sorsogon',
        'Guarapuava',
        'Tambaram',
        'Bethelsdorp',
        'Saarbrücken',
        'Kāraikkudi',
        'Shrīrāmpur',
        'Abakan',
        'Braga',
        'Epe',
        'Aurora',
        'Kelowna',
        'Plzeň',
        'Fardīs',
        'Himatnagar',
        'Kindia',
        'Tachikawa',
        'Tempe',
        'Thanhlyin',
        'Turbo',
        'La Rioja',
        'Guna',
        'Hamm',
        'Nawāda',
        'Cuauhtémoc',
        'Ploieşti',
        'Inisa',
        'Berazategui',
        'Ikot Ekpene',
        'Funtua',
        'Obuase',
        'Toulon',
        'Jaunpur',
        'Mbanza Kongo',
        'Edirne',
        'Longjing',
        'Geelong',
        'Lhokseumawe',
        'Mahād',
        'Madanapalle',
        'Danbury',
        'Palopo',
        'Petarukan',
        'Guri',
        'Singosari',
        'Quảng Yên',
        'Tongjiang',
        'Longjin',
        'Santander de Quilichao',
        'Wadlakonda',
        'Bhachāu',
        'Shivpuri',
        'Caluquembe',
        'Hosa’ina',
        'Tanjungbalai',
        'Buḍhānilkanṭha',
        'Uji',
        'Mongu',
        'Norilsk',
        'Divo',
        'Calabar',
        'Reims',
        'Dosquebradas',
        'Roxas City',
        'Masan',
        'Purwakarta',
        'Sātāra',
        'El Khroub',
        'Tiaret',
        'Cuautitlán',
        'Spring Hill',
        'Njeru',
        'Torbalı',
        'Ilhéus',
        'Al ‘Arīsh',
        'Langsa',
        'Gastonia',
        'Phillaur',
        'Quevedo',
        'Tebingtinggi',
        'Kandi',
        'Nijmegen',
        'Unnāo',
        'Dundo',
        'Meiktila',
        'Salinas',
        'Sītāpur',
        'La Pintana',
        'Ambato',
        'Almada',
        'Riobamba',
        'Kalamboli',
        'Ciudad Valles',
        'Fredericksburg',
        'Maidstone',
        'San Luis Río Colorado',
        'Letpandan',
        'Hamilton',
        'Al Juwayyidah',
        'Cianjur',
        'Ontario',
        'Ar Raḩmānīyah',
        'Wārāseonī',
        'Castellón de la Plana',
        'León',
        'Mauli',
        'Harnaut',
        'Bejaïa',
        'Elk Grove',
        'Biu',
        'Arafat',
        'Rantau Prapat',
        'Krasnogorsk',
        'Catumbela',
        'Shibirghān',
        'El Bosque',
        'Gainesville',
        'Santa María Texmelucan',
        'Matosinhos',
        'Navadwīp',
        'Salatiga',
        'Marāgheh',
        'Kotdwāra',
        'Amadora',
        'Durrës',
        'Chicoloapan',
        'Khapalu',
        'Bodrum',
        'Yangmei',
        'Pīlibhīt',
        'Sīrjān',
        'Narashino',
        'Cary',
        'Curug',
        'Rancho Cucamonga',
        'Kangqiao',
        'Timon',
        'Burgos',
        'Dagupan City',
        'Ludwigshafen',
        'Bắc Giang',
        'Alleppey',
        'Ada',
        'Habaswein',
        'Gliwice',
        'Piedras Negras',
        'Linjiang',
        'Carúpano',
        'Bida',
        'Sakura',
        'Townsville',
        'Oceanside',
        'Cuddalore',
        'Pátra',
        'Basel',
        'Tlemcen',
        'Lubao',
        'Mexico',
        'Gondal',
        'Shāhīn Shahr',
        'Syzran',
        'Deo',
        'Albacete',
        'Fenglu',
        'Keningau',
        'Akhisar',
        'Podgorica',
        'Sherbrooke',
        'Kamakurayama',
        'Hạ Long',
        'Oldenburg',
        'Silchar',
        'Chīrāla',
        'Gadag',
        'Santander',
        'Saint-Étienne',
        'Mang La',
        'Hitachi',
        'Jabālyā',
        'Polomolok',
        'Ongata Rongai',
        'Sacaba',
        'General Mariano Alvarez',
        'Mülheim',
        'Lagos de Moreno',
        'Tiruvannāmalai',
        'Kindu',
        'Kaolack',
        'Bīdar',
        'Baranavichy',
        'San Miguel',
        'Bade',
        'Talhar',
        'Jalālābād',
        'Izumo',
        'Rafaḩ',
        'Bir el Djir',
        'Ait Melloul',
        'Oeiras',
        'Alcorcón',
        'Cazanga',
        'Garden Grove',
        'Kempton Park',
        'Volgodonsk',
        'Lancaster',
        'Al Kūfah',
        'Araguaína',
        'Metro',
        'Nawsari',
        'Surigao',
        'Erciş',
        'Reggio di Calabria',
        'Makurdi',
        'Bảo Lộc',
        'Kohat',
        'Petite Rivière de l’Artibonite',
        'Ussuriysk',
        'Hemet',
        'Pembroke Pines',
        'Ibirité',
        'Hinthada',
        'Nqutu',
        'Escuintla',
        'Fusagasugá',
        'Malāyer',
        'Olsztyn',
        'Tomakomai',
        'Aplahoué',
        'Valsād',
        'Thingangyun',
        'As Suwayq',
        'Métouia',
        'Concepcion',
        'San Luis',
        'Barra Mansa',
        'Cape Coast',
        'Magwe',
        'Panama City',
        'Urayasu',
        'Puerto Montt',
        'Reggio Emilia',
        'Vallejo',
        'Al Marj',
        'Saḩāb',
        'Caluquembe',
        'Medinīpur',
        'Sŏsan',
        'Talas',
        'Minbya',
        'Chūō-ku',
        'Santa Rita',
        'Manchester',
        'Batumi',
        'Bielsko-Biała',
        'Chetumal',
        'Kamensk-Ural’skiy',
        'Nishio',
        'Medford',
        'Damoh',
        'Kalalé',
        'Kroonstad',
        'Toliara',
        'Bayamón',
        'Calabozo',
        'Baliuag',
        'Harīpur',
        'Mahābād',
        'Neyveli',
        'Bordj Bou Arreridj',
        'Mauldin',
        'Karaman',
        'Moratuwa',
        'Francisco Morato',
        'Hŭich’ŏn',
        'Ilford',
        'Kasama',
        'Gondomar',
        'Novocherkassk',
        'Malanville',
        'Bāramūla',
        'Pôrto Seguro',
        'Jamālpur',
        'Ríohacha',
        'Santa Cruz',
        'Catape',
        'Hirosaki',
        'Villa Canales',
        'Malkāpur',
        'Katha',
        'Oyama',
        'Basuo',
        'Jīnd',
        'Angra dos Reis',
        'Phan Rang-Tháp Chàm',
        'Osnabrück',
        'Itapecerica da Serra',
        'Mogok',
        'Fethiye',
        'Itu',
        'Al Qurayyāt',
        'Jutiapa',
        'Palmdale',
        'Zlatoust',
        'Norwich',
        'Chandannagar',
        'Muskegon',
        'Çerkezköy',
        'Linhares',
        'Tiantoujiao',
        'Sampit',
        'Uppsala',
        'Villanueva',
        'Ādoni',
        'Alasandigutta',
        'Tuguegarao',
        'Siirt',
        'Kuytun',
        'Leesburg',
        'Offa',
        'Ipiales',
        'Le Havre',
        'Sudbury',
        'Ratnapura',
        'Niiza',
        'Takaoka',
        'Leverkusen',
        'Kushiro',
        'Iwata',
        'Obihiro',
        'São Caetano do Sul',
        'Sawangan',
        'Béchar',
        'K’ebrī Beyah',
        'Körfez',
        'Singida',
        'Warrington',
        'Udipi',
        'Saqqez',
        'Fyzābād',
        'Kökshetaū',
        'Ebo',
        'Manavgat',
        'Fukang',
        'Idlib',
        'Sawāi Mādhopur',
        'Lajes',
        'Tenāli',
        'Cienfuegos',
        'Petropavlovsk-Kamchatskiy',
        'High Point',
        'Bocoio',
        'Abengourou',
        'Tuscaloosa',
        'Conjeeveram',
        'Hadano',
        'Chillán',
        'Abū Ḩulayfah',
        'Madhubani',
        'Arnhem',
        'Quibala',
        'Proddatūr',
        'Baubau',
        'San Martin Texmelucan de Labastida',
        'Tunja',
        'Teresópolis',
        'San Marcos',
        'Poços de Caldas',
        'Sultānpur Mazra',
        'Visalia',
        'Wāpi',
        'Blida',
        'Sidon',
        'Wau',
        'Tokat',
        'Garissa',
        'Pocheon',
        'Skikda',
        'Muridke',
        'Muzaffargarh',
        'Kebili',
        'San Fernando',
        'Turgutlu',
        'Jizzax',
        'Ungaran',
        'Kyaunggon',
        'Huddersfield',
        'Ube',
        'Bandar-e Māhshahr',
        'Nazilli',
        'Simao',
        'Anaco',
        'Marysville',
        'Gölcük',
        'Haarlem',
        'Boma',
        'Tuxtepec',
        'Sullana',
        'San Andrés Tuxtla',
        'Heidelberg',
        'Darmstadt',
        'Parnaíba',
        'Mukono',
        'Hala',
        'Godhra',
        'Rafsanjān',
        'Sariaya',
        'Multai',
        'Perugia',
        'Nador',
        'Guadalajara',
        'Tema',
        'Zemun',
        'Ndalatando',
        'Salihli',
        'Daule',
        'Rājapālaiyam',
        'Zhanlicun',
        'Pangzawl',
        'Bontang',
        'Jinggang',
        'Ash Shīḩānīyah',
        'Papantla de Olarte',
        'San Miguel del Padrón',
        'Tulancingo',
        'Tacheng',
        'Tân Phú',
        'Naic',
        'Liancheng',
        'General Roca',
        'Zaranj',
        'Al Ghardaqah',
        'Kidapawan',
        'Chittoor',
        'Bragança Paulista',
        'Solingen',
        'Merced',
        'Pindamonhangaba',
        'Hayward',
        'Split',
        'Lafayette',
        'Ziarat',
        'Girón',
        'Miyakonojō',
        'Đồng Hới',
        'Trà Vinh',
        'Khanpur',
        'Ferkessédougou',
        'Southend',
        'Ciudad Acuña',
        'José María Ezeiza',
        'Khōst',
        'Koudougou',
        'Saint-Marc',
        'Ninh Bình',
        'Bingöl',
        'Dhamār',
        'Sivakāsi',
        'Huaycan',
        'San Nicolás de los Arroyos',
        'Osorno',
        'Milagro',
        'Ceyhan',
        'Moga',
        'Ede',
        'Ekibastuz',
        'Barreiras',
        'Enschede',
        'Springfield',
        'Newport',
        'Chirchiq',
        'Dijon',
        'Jijiga',
        'Patos de Minas',
        'Budaun',
        'Ramat Gan',
        'Uttarpāra',
        'Catamarca',
        'La Laguna',
        'Daltonganj',
        'Quipungo',
        'Jequié',
        'Benoni',
        'Macuspana',
        'Zhengding',
        'Aral',
        'Cadiz',
        'Elektrostal',
        'Matsuzaka',
        'Klaipėda',
        'Ōgaki',
        'Corona',
        'Zabrze',
        'Bharūch',
        'Ilagan',
        'Abaetetuba',
        'Guimarães',
        'Ibarra',
        'Paterson',
        'Gojra',
        'Banhā',
        'Mahlaing',
        'Alexandria',
        'Calama',
        'San Miguel',
        '’s-Hertogenbosch',
        'Grenoble',
        'Amersfoort',
        'Erzincan',
        'Regensburg',
        'George',
        'Herne',
        'Bima',
        'Mandi Bahauddin',
        'Châu Đốc',
        'Severodvinsk',
        'Angers',
        'Itapetininga',
        'Xiaping',
        'Caxias',
        'Villeurbanne',
        'Nāblus',
        'Nan Zhuang',
        'Zaanstad',
        'Guaymas',
        'Enfield',
        'Kamina',
        'Molo',
        'Macon',
        'Saharsa',
        'Chaoshan',
        'Kétou',
        'Rio das Ostras',
        'Adzopé',
        'Portmore',
        'Binghamton',
        'Qalyūb',
        'Danao',
        'Marbella',
        'Ghorāhī',
        'Lakewood',
        'Bạc Liêu',
        'Capas',
        'Cẩm Phả',
        'Almetyevsk',
        'Al Miqdādīyah',
        'Vidisha',
        'Pathānkot',
        'Nowgong',
        'Ravenna',
        'Ar Ramthā',
        'Borāzjān',
        'Tigaraksa',
        'Kisi',
        'Esmeraldas',
        'Kansas City',
        'Horad Barysaw',
        'Souk Ahras',
        'Daiwanishi',
        'Chlef',
        'Pandi',
        'Camarajibe',
        'Odessa',
        'El Eulma',
        'Salzburg',
        'Thānesar',
        'Danzao',
        'Camacupa',
        'Kishangarh',
        'Ŭiwang',
        'Hanam',
        'Paderborn',
        'Zango',
        'Brăila',
        'Barrie',
        'Sunnyvale',
        'Saint-Louis',
        'Edremit',
        'Rudarpur',
        'Kitenkela',
        'Tindwāra',
        'Bandırma',
        'Nalgonda',
        'Jinghai',
        'Hitachi-Naka',
        'Lucapa',
        'Neuss',
        'Noda',
        'Santana de Parnaíba',
        'Ambikāpur',
        'Madīnat as Sādis min Uktūbar',
        'Dibrugarh',
        'Singaraja',
        'Moanda',
        'Seogwipo',
        'Palo Negro',
        'New Bedford',
        'Verāval',
        'Mogi Guaçu',
        'Hoeryŏng',
        'Abbotsford',
        'Kırşehir',
        'Tochigi',
        'Betūl Bazār',
        'Andong',
        'Bālurghāt',
        'Bytom',
        'San Jose',
        'Jorhāt',
        'Poblacion',
        'Ixtlahuaca',
        'Salavat',
        'Kariya',
        'Nevşehir',
        'Krishnanagar',
        'Dutse',
        'Newcastle',
        'Ueda',
        'Livorno',
        'Tete',
        'Vĩnh Yên',
        'Bārākpur',
        'Hollywood',
        'Sinpo',
        'Pouso Alegre',
        'Ciudad Choluteca',
        'Hòa Thành',
        'Alagoinhas',
        'Mudon',
        'Amatitlán',
        'Gulu',
        'Gwangyang',
        'South Lyon',
        'Imabari',
        'Kawashiri',
        'Oxford',
        'Bordj el Kiffan',
        'Gò Công',
        'Erdemli',
        'Gonbad-e Kāvūs',
        'Al Manāqil',
        'Shāntipur',
        'Dīla',
        'Hindupur',
        'Araucária',
        'Ipswich',
        'Matanzas',
        'Beāwar',
        'Long Khánh',
        'Bhālswa Jahangirpur',
        'Aş Şuwayḩirah as Sāḩil',
        'Tauranga',
        'Miass',
        'Erode',
        'Escondido',
        'Lake Charles',
        'Dahuaishu',
        'Minglanilla',
        'Manzanillo',
        'Chichicastenango',
        'Đức Phổ',
        'San Jose',
        'Copiapó',
        'Tafeng',
        'Mahmutlu',
        'Büyük Çakırman',
        'Buğdaylı',
        'Eminabad',
        'Kragujevac',
        'Pasadena',
        'Bellevue',
        'Logroño',
        'Delicias',
        'Talcahuano',
        'Piedecuesta',
        'Toledo',
        'Higashimurayama',
        'Ipokia',
        'Jaranwala',
        'Nāngloi Jāt',
        'Joliet',
        'Kukichūō',
        'Badajoz',
        'Champaign',
        'Fengyicun',
        'Shāhrūd',
        'Mzuzu',
        'Valle de Santiago',
        'Đồng Xoài',
        'Valdivia',
        'Gölbaşı',
        'Naogaon',
        'Kashikishi',
        'El Minié',
        'Borj Hammoud',
        'Auchi',
        'Chauk Azam',
        'Yilan',
        'Sievierodonetsk',
        'Urganch',
        'Ocumare del Tuy',
        'Willemstad',
        'Bávaro',
        'Soro',
        'Pénjamo',
        'Santa Rita',
        'Mariveles',
        'Pomona',
        'Saumlaki',
        'Villa de Álvarez',
        'Lévis',
        'Fairfield',
        'Asaba',
        'Kerch',
        'Concordia',
        'Mesquite',
        'Lashio',
        'Elkhart',
        'Bohicon',
        'Harrow',
        'Rimini',
        'Port Louis',
        'Ağrı',
        'Naperville',
        'Maīmanah',
        'Musashino',
        'Kastamonu',
        'St. George',
        'Sagay',
        'Roseville',
        '‘Ajlūn',
        'Marvdasht',
        'Melitopol',
        'Potchefstroom',
        'Coquitlam',
        'Nek’emtē',
        'Xianshuigu',
        'Nūzvīd',
        'Abbottabad',
        'Santiago',
        'Lárisa',
        'Ramapo',
        'Coro',
        'Sayama',
        'Taza',
        'Jean-Rabel',
        'Al ‘Aqabah',
        'Dundee',
        'Sitārganj',
        'Dongguazhen',
        'Topeka',
        'Al Ḩawīyah',
        'Cagliari',
        'Nîmes',
        'Lüleburgaz',
        'Consolacion',
        'Maridi',
        'Quilpué',
        'Rafael Castillo',
        'Zhangaözen',
        'Nchelenge',
        'Szombathely',
        'Kutaisi',
        'Komaki',
        'Kiambu',
        'Siem Reap',
        'Orekhovo-Borisovo Yuzhnoye',
        'Hājīpur',
        'Pesquería',
        'Kopeysk',
        'Mati',
        'Aix-en-Provence',
        'Malakal',
        'Chingola',
        'Milas',
        'La Lisa',
        'Burlington',
        'Kafr ash Shaykh',
        'Tipitapa',
        'Clermont-Ferrand',
        'Hưng Yên',
        'Tama',
        'Dongsheng',
        'Hābra',
        'Yonago',
        'Kramatorsk',
        'Pageralam',
        'Kalemie',
        'Colima',
        'Dawei',
        'Maquela do Zombo',
        'Wamba',
        'Warner Robins',
        'Cairns',
        'Xinjing',
        'Cam Ranh',
        'Bīr',
        'Florencio Varela',
        'Odintsovo',
        'Keren',
        'Dar‘ā',
        'West Bromwich',
        'Derince',
        'Gandajika',
        'Colina',
        'Dadu',
        'Pleiku',
        'Amreli',
        'Taungdwingyi',
        'Nārnaul',
        'Jyväskylä',
        'Chitaldrug',
        'Pyatigorsk',
        'Calapan',
        'Calapan',
        'Franco da Rocha',
        'Mostaganem',
        'Paço do Lumiar',
        'Etah',
        'Baní',
        'Surprise',
        'Gloucester',
        'Qūchān',
        'Vila Junqueiro',
        'Torrance',
        'Ereğli',
        'Foggia',
        'Iruma',
        'Abohar',
        'Thanatpin',
        'Miskolc',
        'Bhāndāria',
        'Teixeira de Freitas',
        'Villa de Cura',
        'Facatativá',
        'Arad',
        'Fujita',
        'Le Mans',
        'Kisaran',
        'Khairpur Mir’s',
        'Bukoba',
        'Kaithal',
        'Arayat',
        'Poole',
        'Comayagua',
        'Yima',
        'Moshi',
        'Saguenay',
        'Urdaneta',
        'Boca del Rio',
        'Odivelas',
        'Xintang',
        'Barranca',
        'Balasore',
        'Guelph',
        'Zhaxi',
        'Mehtar Lām',
        'Al Mukallā',
        'Kolomna',
        'Dar Naim',
        'Cinere',
        'Pinetown',
        'Salamanca',
        'Asaka',
        'Ramu',
        'Mallapalli',
        'Santa Maria',
        'Kakamigahara',
        'Aalborg',
        'Koiridih',
        'Ciego de Ávila',
        'Bandundu',
        'Disūq',
        'Calabayan',
        'Coimbra',
        'Ghaznī',
        'Zhaoxiang',
        'Ruse',
        'Touggourt',
        'Shillong',
        'Athens',
        'Houma',
        'Cizre',
        'Malasiqui',
        'Lleida',
        'Sannār',
        'Los Ángeles',
        'Rewāri',
        'Paghmān',
        'Birkenhead',
        'Jinjiang',
        'Tinipuka',
        'Cartago',
        'Ashikaga',
        'Stara Zagora',
        'Telford',
        'Nakhodka',
        'Columbia',
        'Huelva',
        'Garanhuns',
        'Hazāribāgh',
        'Moundou',
        'Trindade',
        'Nizip',
        'Nawābganj',
        'Toda',
        'Fullerton',
        'Lichinga',
        'Settat',
        'Bafra',
        'Bhīmavaram',
        'Negombo',
        'Olathe',
        'Okinawa',
        'Namacunde',
        'Boca Chica',
        'Altay',
        'Bahawalnagar',
        'Misato',
        'Olmaliq',
        'Moriguchi',
        'Preston',
        'Thornton',
        'York',
        'Mandsaur',
        'Jahrom',
        'Bondoukou',
        'Lausanne',
        'Bocaue',
        'Khuzdar',
        'Tepatitlán de Morelos',
        'Pemba',
        'Elbistan',
        'Bilbays',
        'Berezniki',
        'Piteşti',
        'Gweru',
        'Khasavyurt',
        'Tân Châu',
        'Numan',
        'Kamālshahr',
        'Rize',
        'Subang',
        'Villupuram',
        'Ingolstadt',
        'Las Maravillas',
        'Greeley',
        'Tabaco',
        'Fukayachō',
        'Beaumont',
        'Nsele',
        'Las Cruces',
        'Porac',
        'Mejicanos',
        'Krugersdorp',
        'Middlesbrough',
        'Shizhaobi',
        'Paranaguá',
        'Sāmarrā’',
        'Iguala de la Independencia',
        'Ozamiz City',
        'Harchandpur',
        'Yaritagua',
        'Midland',
        'Kumbakonam',
        'Metairie',
        'Torbat-e Ḩeydarīyeh',
        'Xiangcheng',
        'Batu Pahat',
        'Nāḩiyat Ghammās',
        'Peristéri',
        'Darwin',
        'Reykjavík',
        'Atbara',
        'Kanasín',
        'Giá Rai',
        'Payatas',
        'Debre Birhan',
        'La Romana',
        'Aul',
        'Brest',
        'Masaya',
        'Malambo',
        'Rubtsovsk',
        'Momostenango',
        'Pakpattan',
        'Botucatu',
        'Jicheon',
        'Tanay',
        'Blackpool',
        'Carolina',
        'Trois-Rivières',
        'Balneário de Camboriú',
        'Maykop',
        'Balkanabat',
        'Dos Hermanas',
        'Apeldoorn',
        'Pantanal',
        'West Valley City',
        'Ishizaki',
        'Çarşamba',
        'Kuwana',
        'Orange',
        'Warren',
        'Gemena',
        'Sancti Spíritus',
        'Whitby',
        'Cambridge',
        'Kolār',
        'Grand Junction',
        'Médéa',
        'Teluknaga',
        'Tarragona',
        'Koga',
        'Dipolog',
        'Dabou',
        'Tsuchiura',
        'Tyler',
        'Jacmel',
        'Chicacole',
        'Montero',
        'Koutiala',
        'Shūnan',
        'Candelaria',
        'Silivri',
        'Mthatha',
        'Gaziemir',
        'Brusque',
        'Tours',
        'Iğdır',
        'Kovrov',
        'Gunungsitoli',
        'Negage',
        'Pasadena',
        'Teófilo Otoni',
        'La Trinidad',
        'Bānkura',
        'Mandya',
        'Palhoça',
        'Norrköping',
        'Jolo',
        'Kusatsu',
        'Cunduacán',
        'Dehri',
        'Hampton',
        'Sinfra',
        'Myebon',
        'Atibaia',
        'Quillacollo',
        'Medina Estates',
        'Stockport',
        'Kanata',
        'Rangkasbitung',
        'Porto Amboim',
        'Minō',
        'Durgauti',
        'Curicó',
        'Vila Franca de Xira',
        'Igboho',
        'Gingoog',
        'Phủ Lý',
        'Marīvān',
        'Huehuetenango',
        'Barrechid',
        'San Justo',
        'Shizuishan',
        'Mainpuri',
        'Nasugbu',
        'Bloomington',
        'Quimbele',
        'Varginha',
        'Port-Gentil',
        'Carcar',
        'Qabr as Sitt',
        'Chech’ŏn',
        'Campo Largo',
        'San José del Cabo',
        'Cachoeirinha',
        'Termiz',
        'Zinacantepec',
        'Batang',
        'Bacău',
        'Norzagaray',
        'Yaizu',
        'Talaivāsal',
        'Kisarazu',
        'Ağcabədi',
        'Birgañj',
        'Dīsa',
        'Tobruk',
        'Elizabeth',
        'Ebina',
        'Floridablanca',
        'Gitega',
        'Rionegro',
        'Stamford',
        'Nkongsamba',
        'Yuma',
        'Raigarh',
        'Yalova',
        'Maia',
        'Ituzaingó',
        'Tây Ninh',
        'Kigoma',
        'Kent',
        'Miramar',
        'Andīmeshk',
        'Tizi Ouzou',
        'Agboville',
        'Siwān',
        'Maldonado',
        'Ipetumodu',
        'Shahreẕā',
        'Zābol',
        'Inazawa',
        'Caraguatatuba',
        'Pyay',
        'Silopi',
        'Djakotomé',
        'El Oued',
        'Mörön',
        'Bern',
        "Coeur d'Alene",
        'Ashqelon',
        'Minoo',
        'Mabacun',
        'Laghouat',
        'Salto',
        'Sibiu',
        'Brighton',
        'Zafarwal',
        'Londuimbali',
        'Cametá',
        'Vitória de Santo Antão',
        'Dumaguete City',
        'Navoiy',
        'Marianao',
        'Sale',
        'Luuq',
        'Ba Đồn',
        'Gubeng',
        'Daraga',
        'Veszprém',
        'Lakewood',
        'Famalicão',
        'Debre Mark’os',
        'Tiraspol',
        'Coral Springs',
        'Ruda Śląska',
        'Rybnik',
        'Idfū',
        'Tokha',
        'Sterling Heights',
        'Kalol',
        'San Andres',
        'Marituba',
        'Amiens',
        'Yunxian Chengguanzhen',
        'Ferfer',
        'Renca',
        'Ségou',
        'Jaú',
        'Tando Allahyar',
        'Thandwe',
        'Hagonoy',
        'Hassan',
        'Batu Gajah',
        'Matagalpa',
        'Lalitpur',
        'Conchalí',
        'Santa Cruz do Sul',
        'Long Bình',
        'Pitalito',
        'Bibémi',
        'Naga',
        'Porto-Novo',
        'Uppsala',
        'Yuba City',
        'Khorramshahr',
        'Tarime',
        'Crato',
        'Male',
        'Parla',
        'Milton',
        'M’Sila',
        'Srīpur',
        'Kipushi',
        'Gondiā',
        'Zhangmu Touwei',
        'Schaarbeek',
        'Luxembourg',
        'Reẖovot',
        'San Carlos',
        'São Mateus',
        'Dalūpura',
        'Mogaung',
        'Ödemiş',
        'Kingston',
        'San Luis',
        'Centro Habana',
        'Zagnanado',
        'Isahaya',
        'Yakima',
        'Adonara',
        'Talavera',
        'Rustavi',
        'Carrollton',
        'San Juan',
        'Āwarē',
        'Naryāi ka Puri',
        'Blitar',
        'Racine',
        'Karabük',
        'Palwal',
        'Ōme',
        'Chicomba',
        'Johnson City',
        'Annecy',
        'Papeete',
        'Cubatão',
        'Conselheiro Lafaiete',
        'Ji-Paraná',
        'Al Khmissat',
        'Billings',
        'Jijel',
        'Fürth',
        'Buea',
        'Apopa',
        'Itapipoca',
        'Spanish Town',
        'Lam Tin',
        'Pālghāt',
        'Atyraū',
        'Araras',
        'Maijdi',
        'Kuşadası',
        'Vlorë',
        'Quibdó',
        'Marand',
        'Bassila',
        'Thaton',
        'Zama',
        'Chittandikavundanūr',
        'Iowa City',
        'Nanqiaotou',
        'Shuangcheng',
        'Surat Thani',
        'Narita',
        'Lào Cai',
        'Trinidad',
        'Innsbruck',
        'Kozan',
        'Angono',
        'Silay',
        'Ānand',
        'Jīroft',
        'City of Isabela',
        'Nantou',
        'Los Guayos',
        'Inezgane',
        'Arcahaie',
        'Botād',
        'Abiko',
        'Tabarre',
        'Udon Thani',
        'Baiyashi',
        'Lincoln',
        'Mojokerto',
        'Dover',
        'Onomichi',
        'Lucheng',
        'Apucarana',
        'Jamundí',
        'Ergani',
        'Relizane',
        'Polokwane',
        'Aaley',
        'Cili',
        'Battambang',
        'Taldyqorghan',
        'Huejutla de Reyes',
        'Bongaigaon',
        'Bellingham',
        'Mukeriān',
        'Zwolle',
        'Girardot',
        'Vespasiano',
        'Limoges',
        'Charleston',
        'Ponnagyun',
        'Sukrah',
        'Araruama',
        'Çayırova',
        'Hanumāngarh',
        'Jetpur',
        'Arcot',
        'Kokubunji',
        'Amherst',
        'Kānchrāpāra',
        'Parepare',
        'Ciénaga',
        'Sabará',
        'Chinguar',
        'Ferrara',
        'Antsiranana',
        'Tottenham',
        'Mansa',
        'Jamaame',
        'Petapa',
        'Mataró',
        'Cholula de Rivadabia',
        'Idanre',
        'Bamban',
        'Harar',
        'Sarhari',
        'Guadalajara de Buga',
        'Dörtyol',
        'Ulm',
        'Bragança',
        'Mubi',
        'Guagua',
        'Lynchburg',
        'Songnim',
        'Bat Yam',
        'Kislovodsk',
        'Ōsaki',
        'Santo Tomas',
        'Västerås',
        'Puno',
        'Hoshangābād',
        'Táriba',
        'Fengcheng',
        'Saïda',
        'Rosario',
        'Heilbronn',
        'Chās',
        'Apatzingan de la Constitucion',
        'Tuaran',
        'Rudnyy',
        'Nefteyugansk',
        'Khanna',
        'Ahmadpur East',
        'Wazirabad',
        'Santa Clara',
        'Avrankou',
        'Vihari',
        'Guasdualito',
        'Mosquera',
        'Dongsheng',
        'Garut',
        'Votorantim',
        'Buôn Hồ',
        'Abaji',
        'Salmās',
        'Domodedovo',
        'Pforzheim',
        'Edremit',
        'Würzburg',
        'Jāzān',
        'Norman',
        'Santa Lucía Cotzumalguapa',
        'Guimba',
        'Greenville',
        'Simi Valley',
        'Latina',
        'Zhaozhou',
        'Dessalines',
        'San Ignacio',
        'Ciudad Sandino',
        'Allada',
        'Iwakuni',
        'Nagda',
        'Bam',
        'Opole',
        'Kayes',
        'Koforidua',
        'Seto',
        'Ōmiyachō',
        'Missérété',
        'Koganei',
        'Salerno',
        'Torrejón de Ardoz',
        'Saidpur',
        'Nāḩiyat Khān Banī Sa‘d',
        'Angren',
        'Pakokku',
        'Leiria',
        'Sertãozinho',
        'Hardoī',
        'Ñemby',
        'Neftekamsk',
        'Kamëz',
        'Bataysk',
        'Santa Cruz',
        'Ünye',
        'Fuyuan',
        'Duitama',
        'Gurabo al Medio',
        'Ajax',
        'Barcarena Nova',
        'Catabola',
        'Novocheboksarsk',
        'Ksar El Kebir',
        'Örebro',
        'My Drarga',
        'Fort Smith',
        'Suhum',
        'Jandira',
        'Abilene',
        'San Juan',
        'Valinhos',
        'Altamira',
        'Guntakal',
        'Mary',
        'Iizuka',
        'Pithampur',
        'Karatepe',
        'Wolfsburg',
        'Serpukhov',
        'Rosetta',
        'Arecibo',
        'Giresun',
        'Kombolcha',
        'San Fernando',
        'Shchelkovo',
        'Payakumbuh',
        'Pātan',
        'Bonao',
        'Barbacena',
        'Cam Ranh',
        'Zoetermeer',
        'Basīrhat',
        'Resende',
        'Nzega',
        'Villa Alemana',
        'Gashua',
        'Polatlı',
        'Lewisville',
        'Larache',
        'Kristiansand',
        'Az Zulfī',
        'Jilib',
        'Cerro',
        'Hālīsahar',
        'Magelang',
        'Jagādhri',
        'Tychy',
        'Salto',
        'Juticalpa',
        'Novomoskovsk',
        'Jizhou',
        'Leuwiliang',
        'Koidu',
        'Guarapari',
        'Pinsk',
        'Sunām',
        'Ouahigouya',
        'Rishra',
        'Grand-Bassam',
        'Jhārsugra',
        'Leeuwarden',
        'Pearland',
        'Bến Tre',
        'Lehigh Acres',
        'Glazoué',
        'Panevėžys',
        'Chinautla',
        'New Mirpur',
        'Serik',
        'Chía',
        'Kenosha',
        'Noksan',
        'Bajos de Haina',
        'Magalang',
        'Exeter',
        'Bimbo',
        'Pāli',
        'Leiden',
        'Kadirli',
        'Fugu',
        'Savannakhet',
        'Khrustalnyi',
        'Kaspiysk',
        'Magangué',
        'Cambridge',
        'Kirishima',
        'Cai Lậy',
        'Maicao',
        'Pobé',
        'Derbent',
        'Giugliano in Campania',
        'Pervouralsk',
        'Algeciras',
        'Paleng',
        'Santa Cruz',
        'Akçaabat',
        'Djidja',
        'Dūmā',
        'Longtian',
        'Seaside',
        'Punta Arenas',
        'Itaituba',
        'Burlington',
        'Três Lagoas',
        'Nāndgaon',
        'Orizaba',
        'Bento Gonçalves',
        'Honmachi',
        'Baraki',
        'Arvada',
        'Alwāl',
        'Gapan',
        'Kaya',
        'Ed Damer',
        'Munch’ŏn',
        'Gexianzhuang',
        'Kilifi',
        'Zacatecas',
        'Romford',
        'Dimāpur',
        'Patnos',
        'Shiyan',
        'Pati',
        'Kâhta',
        'Yuanlin',
        'Bayawan',
        'Machiques',
        'Hoima',
        'San Pedro Garza García',
        'Bayan Lepas',
        'Ciudad Hidalgo',
        'Behbahān',
        'Itatiba',
        'Cagua',
        'Barretos',
        'Ise',
        'Puerto Cortés',
        'Maina',
        'Indramayu',
        'Cherkessk',
        'Tam Kỳ',
        'Uruma',
        'Sarangāpuram',
        'Mufulira',
        'Waldorf',
        'Ereğli',
        'Independence',
        'Ciudad Ojeda',
        'Bhadrakh',
        'León',
        'Gyumri',
        'Dharmavaram',
        'Rochester',
        'Colchester',
        'Monza',
        'Sivaganga',
        'Chinandega',
        'Hội An',
        'Logan',
        'Farīdpur',
        'Springs',
        'Dorūd',
        'Kashiwara',
        'Kuopio',
        'Ondjiva',
        'Alberton',
        'Temperley',
        'Harlingen',
        'Waterloo',
        'Dordrecht',
        'Kamalia',
        'Berkeley',
        'Tsuruoka',
        'Lianhe',
        'Ar Rass',
        'Hòa Bình',
        'Doğubayazıt',
        'Port-de-Paix',
        'Upington',
        'Wuling',
        'Samandağ',
        'Chơn Thành',
        'Puruliya',
        'Mīt Ghamr',
        'Tabuk',
        'Kırıkhan',
        'Sassari',
        'Ghazīpur',
        'Ch’ungmu',
        'Apartadó',
        'Tumen',
        'Thành Phố Uông Bí',
        'Jīma',
        'Genhe',
        'Navojoa',
        'Porlamar',
        'Anderlecht',
        'Metz',
        'Winterveld',
        'Renala Khurd',
        'Chilapa de Álvarez',
        'Coatepeque',
        'Bagaha',
        'Gudiyāttam',
        'Mahuva',
        'Catchiungo',
        'Fier',
        'Clovis',
        'Kotamobagu',
        'Gurdāspur',
        'Kỳ Anh',
        'Kot Addu',
        'Round Rock',
        'Barcelos',
        'Pueblo',
        'Ramos Mejía',
        'High Wycombe',
        'Gemlik',
        'Temple',
        'Söke',
        'Khurda',
        'Delgado',
        'Poblacion',
        'Kandy',
        'Ban Bang Pu Mai',
        'Gateshead',
        'Guelma',
        'Unwana',
        'Ar Rustāq',
        'San Carlos',
        'Baraka',
        'Ghardaïa',
        'Nokha',
        'Jandrapeta',
        'Hengken',
        'Đông Hòa',
        'Gorzów Wielkopolski',
        'Terrebonne',
        'Lalo',
        'Pelabuhanratu',
        'Yelahanka',
        'Meridian',
        'Malindi',
        'Almirante Tamandaré',
        'Ebetsu',
        'Boulogne-Billancourt',
        'Moncton',
        'Yüksekova',
        'Palma Soriano',
        'An',
        'Jiangna',
        'Perpignan',
        'Pleven',
        'Fulgāzi',
        'Bergamo',
        'Shuixi',
        'Sanxi',
        'Naz̧arābād',
        'Candaba',
        'Parow',
        'Tizayuca',
        'Moḩammad Shahr',
        'Silifke',
        'Port Dickson',
        'Besançon',
        'Debre Tabor',
        'Arapongas',
        'Guaratinguetá',
        'Slough',
        'Aw Dheegle',
        'Ţūz Khūrmātū',
        'Birigui',
        'Totonicapán',
        'Göttingen',
        'Huaraz',
        'Daitōchō',
        'Darjeeling',
        'Piraquara',
        'Bet Shemesh',
        'Bismil',
        'Vihiga',
        'Pescara',
        'Miramar',
        'Sopur',
        'Nowshera',
        'Duluth',
        'Bandar-e Anzalī',
        'Bruges',
        'Yongqing',
        'Calumpit',
        'Bwana Mkubwa',
        'Butwāl',
        'Senador Canedo',
        'The Woodlands',
        'Chikmagalūr',
        'Matamoros',
        'Kadoma',
        'Guelmim',
        'Orekhovo-Zuyevo',
        'Yangliuqing',
        'Xuqiaocun',
        'Soasio',
        'Kampong Cham',
        'Dinalupihan',
        'Malita',
        'Niğde',
        'Gudivāda',
        'Bama',
        'Lahti',
        'Bottrop',
        'Quyang',
        'Ilobu',
        'Ligao',
        'Boulder',
        'Richardson',
        'Trento',
        'Nouadhibou',
        'Dongsheng',
        'Blackburn',
        'Cambridge',
        'Phagwāra',
        'Bagé',
        'Nazran',
        'Aizuwakamatsu',
        'Lodhran',
        'Tahoua',
        'Matsubara',
        'Araguari',
        'Gogounou',
        'Agadez',
        'Pudukkottai',
        'Saanich',
        'Nobeoka',
        'Charallave',
        'Uribia',
        'West Palm Beach',
        'Luanshya',
        'Banyuwangi',
        'Reutlingen',
        'Handa',
        'Kabwe',
        'La Asunción',
        'Salmān Bāk',
        'Catacamas',
        'Tangjin',
        'Midsayap',
        'Port Arthur',
        'Heroica Guaymas',
        'Munūf',
        'East Los Angeles',
        'Uruguaiana',
        'Banfora',
        'Harshin',
        'Adilābād',
        'Redding',
        'Apalit',
        'Yulu',
        'Umuarama',
        'Alcobendas',
        'Cassongue',
        'Clearwater',
        'Fāqūs',
        'Dąbrowa Górnicza',
        'Monroe',
        'Kapaklı',
        'Baripāda',
        'Soreang',
        'Kōnosu',
        'Samal',
        'Datu Odin Sinsuat',
        'Utica',
        'Manpo',
        'Nevinnomyssk',
        'Tatuí',
        'St. Cloud',
        'Mandeville',
        'Chimaltenango',
        'Orléans',
        'La Granja',
        'Erlangen',
        'Yavatmāl',
        'Titāgarh',
        'Ikoma',
        'Lira',
        'Honchō',
        'Barnāla',
        'Cheltenham',
        'Forlì',
        'Chittaurgarh',
        'West Jordan',
        'Xai-Xai',
        'Gabès',
        'Tecomán',
        'Boké',
        'Coronel',
        'Narasaraopet',
        'Siracusa',
        'Himamaylan',
        'Ocaña',
        'Ballarat',
        'Ādīgrat',
        'Dharān',
        'Smithtown',
        'Fatsa',
        'Temixco',
        'Bongao',
        'Ramenskoye',
        'Aïn Beïda',
        'Dimitrovgrad',
        'Karatsu',
        'Nagahama',
        'Târgu-Mureş',
        'Sogamoso',
        'San Tung Chung Hang',
        'Kyzyl',
        'Beppu',
        'Adjaouèrè',
        'Ra’s al Khaymah',
        'Valle de La Pascua',
        'Urasoe',
        'São Gonçalo do Amarante',
        'Sri Jayewardenepura Kotte',
        'Catanduva',
        'Várzea Paulista',
        'North Charleston',
        'San Ildefonso',
        'Linköping',
        'Formosa',
        'Tarogong',
        'Richmond',
        'Nasushiobara',
        'Nusaybin',
        'Pedro Juan Caballero',
        'Ribeirão Pires',
        'Sandvika',
        'Westminster',
        'Bremerhaven',
        'Uzhhorod',
        'Saginaw',
        'Kōenchō',
        'Kovilpatti',
        'Ar Rumaythah',
        'Kasese',
        'Bijnor',
        'Chelmsford',
        'Los Baños',
        'Puerto Madryn',
        'Koblenz',
        'El Pueblito',
        'Kailua',
        'Sliven',
        'Elbląg',
        'Igarassu',
        'Guanabacoa',
        'Aihua',
        'Mendoza',
        'Al Muḑaybī',
        'Obninsk',
        'Swabi',
        'Ciudad Guzmán',
        'Tlapacoyan',
        'Bu’aale',
        'Turbaco',
        'Niihama',
        'Al Manşūrah',
        'Brandon',
        'Amasya',
        'Ponce',
        'Rawajaya',
        'Rawasari',
        'Sano',
        'Dam Dam',
        'Las Delicias',
        'Carlsbad',
        'Lowell',
        'Qiaotou',
        'Hatsukaichi',
        'Bijeljina',
        'Maghnia',
        'Płock',
        'Shacheng',
        'Sach’on',
        'Thimphu',
        'Ariana',
        'Iriga City',
        'Borås',
        'Simões Filho',
        'Plaridel',
        'Catalão',
        'Jaramānā',
        'Kaikkudi',
        'Codó',
        'Yŏju',
        'Broken Arrow',
        'Ambovombe',
        'Sakété',
        'Elgin',
        'Alphen aan den Rijn',
        'Lugazi',
        'Machakos',
        'Rouen',
        'San Juan',
        'Bintulu',
        'Petržalka',
        'Matéri',
        'Kakegawa',
        'Saint-Denis',
        'Oktyabr’skiy',
        'Zhijiang',
        'Kuningan',
        'Helsingborg',
        'Quilengues',
        'Tawau',
        'Poá',
        'Sakiet ed Daier',
        'Īrānshahr',
        'Ifanhim',
        'Euriápolis',
        'Ndali',
        'Gresham',
        'Pul-e Khumrī',
        'Fujimino',
        'Comitán',
        'League City',
        'Mungo',
        'Guiguinto',
        'Midyat',
        'Itabira',
        'Sa-ch’on',
        'Bukittinggi',
        'Sātkhira',
        'Novyy Urengoy',
        'Manolo Fortich',
        'Sloviansk',
        'Akçakale',
        'Hikone',
        'Mangaldan',
        'Hōfu',
        'Mostar',
        'Tōkai',
        'Yessentuki',
        'Downey',
        'Sokodé',
        'Libmanan',
        'Hosan',
        'San Carlos de Bariloche',
        'Paulo Afonso',
        'Tecpán Guatemala',
        'Kazo',
        'Jönköping',
        'Bergisch Gladbach',
        'Tayabas',
        'Remscheid',
        'Carora',
        'Lomas de Zamora',
        'Balāngīr',
        'Shegaon',
        'Macul',
        'Ōshū',
        'Nkayi',
        'Higashiōmi',
        'Otaru',
        'Ciudad de la Costa',
        'Badin',
        'Kisii',
        'Santa Lucía',
        'Goth Tando Sumro',
        'Waterloo',
        'Shkodër',
        'Madrid',
        'Kaposvár',
        'Mahāsamund',
        'Trier',
        'Rājpura',
        'Hezuo',
        'La Libertad',
        'Pochuta',
        'Dassa-Zoumé',
        'Akishima',
        'Bāgalkot',
        'Osmānābād',
        'Estelí',
        'Paarl',
        'Kouandé',
        'Kaliānpur',
        'Shujālpur',
        'Sāhibganj',
        'Passos',
        'Subic',
        'Murrieta',
        'Jaén',
        'Fujimi',
        'Leominster',
        'Longview',
        'Baybay',
        'Jacksonville',
        'Ichinoseki',
        'Cádiz',
        'Pompano Beach',
        'Colatina',
        'Bou Saada',
        'Bou Saâda',
        'Bend',
        'Alkmaar',
        'Recklinghausen',
        'Mawanella',
        'Daet',
        'Nova Lima',
        'Araxá',
        'Laoag',
        'Miami Gardens',
        'Chilón',
        'Chiquimula',
        'Sabara Bangou',
        'Costa Mesa',
        'Baleraja',
        'Montreuil',
        'Villa Mercedes',
        'Sioux City',
        'Ghotki',
        'Jawhar',
        'Santamesa',
        'Kasempa',
        'Champdani',
        'São Lourenço da Mata',
        'Ḩaraḑ',
        'Jena',
        'Gafsa',
        'Ariquemes',
        'Kasuga',
        'Kamyshin',
        'Nsukka',
        'Kintampo',
        'Nandurbar',
        'Purwa Utar',
        'Tuzla',
        'Kaiyun',
        'Namur',
        'Khushab',
        'Everett',
        'Puerto Barrios',
        'Fasā',
        'Rosario',
        'Gilroy',
        'Kiffa',
        'Al Aḩad al Masāriḩah',
        'As Salamīyah',
        'Sorriso',
        'Franceville',
        'San Antonio Enchisi',
        'Quíbor',
        'Socopó',
        'Ahuachapán',
        'Manzini',
        'Masindi',
        'Muktsar',
        'Martínez de la Torre',
        'San Buenaventura',
        'Changbang',
        'Empangeni',
        'Ferozepore',
        'Bāneh',
        'Itele',
        'Rochdale',
        'Jeonghae',
        'Shirayamamachi',
        'Temecula',
        'Tubarão',
        'Sugar Land',
        'Ōmuta',
        'Chico',
        'Msaken',
        'Tinaquillo',
        'Bridgetown',
        'Wythenshawe',
        'Mancherāl',
        'Qal‘at Sukkar',
        'Pisco',
        'Retalhuleu',
        'Bernal',
        'Sutton Coldfield',
        'Vicenza',
        'Doncaster',
        'Winterthur',
        'Dali',
        'Rotherham',
        'Dera Ismail Khan',
        'Esteban Echeverría',
        'Quezon',
        'Aguachica',
        'Naujan',
        'Glan',
        'Bayugan',
        'Eau Claire',
        'Brovary',
        'Gualeguaychú',
        'Delft',
        'Walthamstow',
        'Drammen',
        'Medenine',
        'Nkpor',
        'Kamagaya',
        'Tacurong',
        'Malacatán',
        'Taoyang',
        'Béja',
        'Yŏngju',
        'Labo',
        'Sandachō',
        'Berkane',
        'Sầm Sơn',
        'White Rock',
        'Marugame',
        'Tangjia',
        'Sehore',
        'Murom',
        'Soma',
        'Handeni',
        'Balombo',
        'Talisay',
        'Taitung',
        'Bangaon',
        'Dolgoprudnyy',
        'Thunder Bay',
        'Pulilan',
        'Maxixe',
        'Kasserine',
        'Bagu Na Mohra',
        'Maţrūḩ',
        'Baia Mare',
        'Shihuajie',
        'Tondabayashichō',
        'Bà Rịa',
        'El Monte',
        'Witbank',
        'Khopoli',
        'Ferizaj',
        'Xishancun',
        'Mascara',
        'Khenchela',
        'Taungoo',
        'Móng Cái',
        'Idaho Falls',
        'Melipilla',
        'Komatsu',
        'Islāmābād',
        'Yāsūj',
        'Khardah',
        'Cawayan',
        'Reus',
        'Mopti',
        'Delta',
        'Dearborn',
        'Toowoomba',
        'Mungeli',
        'Bloomington',
        'Alto Hospicio',
        'Habikino',
        'Novoshakhtinsk',
        'Maramag',
        'Yevpatoriia',
        'Caen',
        'Nantang',
        'West Covina',
        'Tādpatri',
        'Birnin Kebbi',
        'Acharnés',
        'Yilong',
        'Sarh',
        'Sparks',
        'Mudanya',
        'An Nuhūd',
        'Žilina',
        'Zhukovskiy',
        'Itumbiara',
        'Rijeka',
        'Douliu',
        'Seversk',
        'South Fulton',
        'Baía Farta',
        'Mazhang',
        'Castelar',
        'Villa Krause',
        'Balsas',
        'Lingayen',
        'Centennial',
        'Labé',
        'Gharbara',
        'Chaman',
        'Sultānpur',
        'Joyabaj',
        'Umm Qaşr',
        'Kogon Shahri',
        'Kawit',
        'Kotmale',
        'Mineshita',
        'Shikohābād',
        'Morales',
        'Liberec',
        'Santana',
        'Shinyanga',
        'Basingstoke',
        'Jalpāiguri',
        'Manokwari',
        'Shāmli',
        'Argenteuil',
        'Sandy Springs',
        'Wa',
        'Cambé',
        'Bhilai Karanja',
        'Chongshan',
        'Ilebo',
        'La Gi',
        'Ejido',
        'Emmen',
        'Edison',
        'Reutov',
        'Banté',
        'Luján',
        'Bagong Silangan',
        'Noyabrsk',
        'Yopal',
        'Erdenet',
        'Inglewood',
        'Suriāpet',
        'Kalmunai',
        'Tajimi',
        'Chābahār',
        'Artëm',
        'Tenggarong',
        'Maipú',
        'Hillsboro',
        'Columbia',
        'Crawley',
        'Açailandia',
        'Roquetas de Mar',
        'As Safīrah',
        'Wardha',
        'La Banda',
        'Catbalogan',
        'Htison',
        'Rānībennur',
        'Burbank',
        'Longjiang',
        'Terni',
        'Mulhouse',
        'Nakhon Si Thammarat',
        'Berdiansk',
        'Toufen',
        'Kŭlob',
        'Kemalpaşa',
        'Ad Dakhla',
        'Tiaong',
        'Tarnów',
        'Carmona',
        'Dagenham',
        'El Limón',
        'Barnoi',
        'Bolzano',
        'Raba',
        'Mingəçevir',
        'Sītāmarhi',
        'Lokossa',
        'Nanaimo',
        'Sơn La',
        'São Pedro da Aldeia',
        'Contramaestre',
        'Khanty-Mansiysk',
        'Bình Hòa',
        'Atebubu',
        'Koszalin',
        'Idkū',
        'Granada',
        'Lo Barnechea',
        'Davie',
        'Kishanganj',
        'Sông Cầu',
        'Temoaya',
        'Kiryū',
        'Jataí',
        'El Cajon',
        'Erechim',
        'Tikrīt',
        'Hindaun',
        'Azare',
        'Semāri',
        'Jurupa Valley',
        'Lerma',
        'Tarīm',
        'Nova Serrana',
        'Japeri',
        'Paragominas',
        'Gangāwati',
        'Bình Long',
        'Pushkino',
        'Maluñgun',
        'Allen',
        'Šabac',
        'Charsadda',
        'Alchevsk',
        'Robāţ Karīm',
        'Auburn',
        'Renton',
        'Nautanwa',
        'Mazyr',
        'Xiva',
        'Moers',
        'Achinsk',
        'Ash Shaykh ‘Uthmān',
        'Lagarto',
        'Jamālpur',
        'Kongolo',
        'Mityana',
        'Bulan',
        'Yozgat',
        'Texcoco',
        'Nikopol',
        'Alaşehir',
        'Holland',
        'Sultan Kudarat',
        'Maranguape',
        'Ndjamba',
        'Makrāna',
        'Al Fāw',
        'Jincheng',
        'Planaltina',
        'Sheopur',
        'Kandhkot',
        'Pergamino',
        'Tagbilaran City',
        'Los Minas',
        'Parral',
        'Lavras',
        'Coronel Fabriciano',
        'Brockton',
        'Włocławek',
        'Brantford',
        'Tenancingo',
        'Presidente Franco',
        'Tuyên Quang',
        'Olanchito',
        'Sergiyev Posad',
        'Salzgitter',
        'Masbate',
        'Gödöllő',
        'Ballia',
        'Wałbrzych',
        'Barika',
        'Rio Rancho',
        'Yelets',
        'Girona',
        'Suruç',
        'Šiauliai',
        'Nancy',
        'Ourense',
        'Aquin',
        'Techiman',
        'Chorzów',
        'Tam Điệp',
        'Balanga',
        'San Mateo',
        'Gillingham',
        'Kanoya',
        'Ikeda',
        'Arzamas',
        'Muriaé',
        'Halifax',
        'Tādepallegūdem',
        'Natitingou',
        'Chatham',
        'Ourinhos',
        'Sindangan',
        'Chicoutimi',
        'Bānsbāria',
        'Tula de Allende',
        'Lida',
        'Toride',
        'Salford',
        'Rialto',
        'Ilopango',
        'Masaka',
        'Spokane Valley',
        'Saijō',
        'Charlottesville',
        'Kilinochchi',
        'Bacabal',
        'Menifee',
        'Orsha',
        'Daly City',
        'Uitenhage',
        'Biak',
        'Wigan',
        'Roncaglia',
        'Itacoatiara',
        'Berdsk',
        'Elista',
        'Yunfu',
        'Musoma',
        'Breves',
        'Buzău',
        'Mubende',
        'Francistown',
        'Lower Hutt',
        'Woodbridge',
        'Tanga',
        'Ubá',
        'Hounslow',
        'Bumba',
        'Būndi',
        'Bergama',
        'Chikushino',
        'Patos',
        'Itanhaém',
        'Aracruz',
        'San Rafael',
        'Inzai',
        'Iguatu',
        'Camboriú',
        'Miryang',
        'Tanjungpandan',
        'Santo Antônio de Jesus',
        'Bendigo',
        'Bouskoura',
        'Paniqui',
        'Amarāvati',
        'Parang',
        'Negapatam',
        'Sangju',
        'Santa Rosa',
        'Buxar',
        'Wembley',
        'Caieiras',
        'Telde',
        'Kurichchi',
        'Hinche',
        'Hōyachō',
        'Guihulñgan',
        'Saint Helens',
        'Gürsu',
        'Jirjā',
        'Noginsk',
        'Kheda',
        'Siegen',
        'Tezpur',
        'Wichita Falls',
        'Riverview',
        'Piacenza',
        'Messaad',
        'Sundarnagar',
        'Houzhuang',
        'Gütersloh',
        'Mayarí',
        'Seoni',
        'Ngong',
        'Ban Mangkon',
        'Mustafakemalpaşa',
        'Kāshmar',
        'Bahrain',
        'Aurangābād',
        'Joünié',
        'Ituiutaba',
        'Mositai',
        'Matehuala',
        'Rishīkesh',
        'Simanggang',
        'Yishi',
        'Isehara',
        'Novokuybyshevsk',
        'Shibuya',
        'Şabrātah',
        'Lahān',
        'Aflou',
        'Al Fqih Ben Çalah',
        'Fugangcun',
        'Al Jumayl',
        'Dhangaḍhi̇̄',
        'Tavşanlı',
        'Norwalk',
        'Worcester',
        'Shūshtar',
        'Tota',
        'Tando Muhammad Khan',
        'Hildesheim',
        'Kapūrthala',
        'Boryeong',
        'Al Ḩajar al Aswad',
        'Olomouc',
        'Zonguldak',
        "Lee's Summit",
        'Dhamtari',
        'Rāneswar',
        'Chishtian',
        'Longmont',
        '‘Ibrī',
        'Vacaville',
        'Nantou',
        'Ōnojō',
        'Brājarājnagar',
        'Eastbourne',
        'Sūjāngarh',
        'Highlands Ranch',
        'Ciudad Río Bravo',
        'Bhadreswar',
        'Pavlohrad',
        'Clarington',
        'Hengnan',
        'Assis',
        'Klagenfurt',
        'Chilakalūrupet',
        'San Luis de la Paz',
        'Hanau',
        'Hikkaduwa',
        'Sungai Penuh',
        'Kingsport',
        'Chaguanas',
        'Jingping',
        'Novara',
        'Kousséri',
        'Aïn Oussera',
        'Deventer',
        'San Vicente de Baracaldo',
        'Kaiserslautern',
        'KwaDukuza',
        'San Tan Valley',
        'Ngã Bảy',
        'Hồng Ngự',
        'Jeypore',
        'Zomba',
        'Daoukro',
        'Santa Cruz',
        'Itaperuna',
        'Oran',
        'Mporokoso',
        'Quincy',
        'Edinburg',
        'Kāranja',
        'Aboisso',
        'Salavan',
        'Xırdalan',
        'Saint-Jérôme',
        'Red Deer',
        'Sakado',
        'Bỉm Sơn',
        'Kefar Sava',
        'Chanwari',
        'Xinhua',
        'Sungailiat',
        'Sittwe',
        'Zheleznogorsk',
        'Concepción del Uruguay',
        'Leer',
        'Cavite City',
        'Playas de Rosarito',
        'Lynn',
        'Ahar',
        'Yên Bái',
        'Weifen',
        'Sur',
        'Port Blair',
        'Nāḩiyat al Iskandarīyah',
        'Kalyani',
        'Masjed Soleymān',
        'Calasiao',
        'Datia',
        'Kamianets-Podilskyi',
        'Itauguá',
        'Torbat-e Jām',
        'Diourbel',
        'Madhavaram',
        'Kawachinagano',
        'Libertad',
        'Shahrisabz',
        'Gangtok',
        'Vaciamadrid',
        'Boconó',
        'San Felipe del Progreso',
        'Chust',
        'Lamitan',
        'Talipao',
        'San Angelo',
        'Sabanalarga',
        'Zelënodol’sk',
        'Pyinmana',
        'Zahlé',
        'Bāmyān',
        'Mbanza-Ngungu',
        'Banzhuangcun',
        'Ra’s Ghārib',
        'Gwelej',
        'Babīlē',
        'Dzolokpuita',
        'Suramāla',
        'Navalyal',
        'Barod',
        'Vāsco Da Gāma',
        'Khambhāliya',
        'Abbigeri',
        'Kundli',
        'Ad Dujayl',
        'Mumias',
        'Luckeesarai',
        'Songea',
        'Pouytenga',
        'Sololá',
        'Mabai',
        'Debre Zeyit',
        'Dunedin',
        'Hesperia',
        'Nabire',
        'Sundsvall',
        'Kadaiyanallūr',
        'Sayaxché',
        'Ciamis',
        'Putatan',
        'Xiongzhou',
        'Urgut Shahri',
        'Fengning',
        'Bowling Green',
        'Shahdadpur',
        'Federal Way',
        'Talara',
        'Menglang',
        'Kani',
        'Cottbus',
        'Tinsukia',
        'Santa Cruz del Quiché',
        'Hualien',
        'Carmel',
        'Bismarck',
        'Campo Mourão',
        'Alaminos',
        'Xiegang',
        'Tellicherry',
        'Jinotega',
        'Itārsi',
        'Izumisano',
        'Thika',
        'Bislig',
        'Abhar',
        'Viseu',
        'Malate',
        'Ginowan',
        'Wakefield',
        'Sakata',
        'Khamīs Mushayţ',
        'Pili',
        'Pickering',
        'Hasilpur',
        'Trincomalee',
        'Riberalta',
        'Morón',
        'Fishers',
        'Kohīma',
        'Sisophon',
        'Tourcoing',
        'Vereeniging',
        'Mīāneh',
        'Heshan',
        'Paoy Paet',
        'Weldiya',
        'Nyeri',
        'Roubaix',
        'Lafayette',
        'Tobolsk',
        'Burzaco',
        'Espejo',
        'Kontagora',
        'Senahú',
        'Khambhāt',
        'Santiago de Compostela',
        'Ixmiquilpan',
        'Caldas Novas',
        'Schwerin',
        'Salihorsk',
        'Reyhanlı',
        'Itoshima',
        'Zárate',
        'Oton',
        'Manacapuru',
        'Sopron',
        'Koytendag',
        'Abreu e Lima',
        'Lorca',
        'Chipata',
        'Lethbridge',
        'Vista',
        'Chikusei',
        'Ancona',
        'Saku',
        'Silvassa',
        'Santa Cruz do Capibaribe',
        'Babahoyo',
        'Lugo',
        'M’lang',
        'Leme',
        'Las Rozas de Madrid',
        'Río Grande',
        'Boca Raton',
        'Moortebeek',
        'Chitose',
        'Bălţi',
        'Smederevo',
        'Rāmnagar',
        'Trelew',
        'Sarapul',
        'Kamloops',
        'Montego Bay',
        'Catarman',
        'Valença',
        'Saint-Jean-sur-Richelieu',
        'Marmaris',
        'Udine',
        'Janakpur',
        'Serov',
        'Nanxicun',
        'St. Augustine',
        'Paulínia',
        'Kambar',
        'Al Jammālīyah',
        'Itaúna',
        'Ipojuca',
        'Quilenda',
        'Gambēla',
        'Kallithéa',
        'Deoni Buzurg',
        'Radès',
        'Mariano Roque Alonso',
        'Hammamet',
        'Aşağıçinik',
        'Beaverton',
        'Votkinsk',
        'Goodyear',
        'San Cugat del Vallés',
        'Tartu',
        'Tsuyama',
        'Isulan',
        'Kallūru',
        'Kōnan',
        'Lajeado',
        'Adjarra',
        'Dongducheon',
        'Portsmouth',
        'Attock Khurd',
        'Nanterre',
        'Fanyang',
        'Yacuiba',
        'Vị Thanh',
        'Pernik',
        'Andria',
        'Pará de Minas',
        'Orem',
        'Worcester',
        'Modi‘in Makkabbim Re‘ut',
        'Ukhta',
        'Munakata',
        'Maribor',
        'Harnai',
        'Didim',
        'Colón',
        'Gävle',
        'Tumbes',
        'Es Senia',
        'Bodināyakkanūr',
        'Quvasoy',
        'Paletwa',
        'Caotun',
        'Tamazunchale',
        'Arauca',
        'Tela',
        'Paraíso',
        'Do Gonbadān',
        'Sumber',
        'Kāzerūn',
        'Francisco Beltrão',
        'Sangolquí',
        'Iida',
        'Votuporanga',
        'Tiddim',
        'Buin',
        'Bab Ezzouar',
        'Oldham',
        'Zhudong',
        'Sunrise',
        'Ōmura',
        'Half Way Tree',
        'Pejë',
        'Wujiaqu',
        'Harihar',
        'České Budějovice',
        'Tomohon',
        'Parintins',
        'Navapolatsk',
        'Osijek',
        'Greece',
        'Corumbá',
        'Arezzo',
        'Cáceres',
        'Vitry-sur-Seine',
        'Doudian',
        'Lysychansk',
        'Escalante',
        'Portsmouth',
        'Itabaiana',
        'Leninsk-Kuznetskiy',
        'Chārīkār',
        'Arden-Arcade',
        'Harran',
        'Lawrence',
        'Muzaffarabad',
        'Bachhraon',
        'Toms River',
        'Hammersmith',
        'Amalner',
        'Balayan',
        'Sardārshahr',
        'Kalisz',
        'Witten',
        'Longkeng',
        'Longquan',
        'Vanderbijlpark',
        'Vilhena',
        'Río Gallegos',
        'Weiyuan',
        'Caseros',
        'Cesena',
        'Tataouine',
        'Dhuliān',
        'Hadera',
        'Arifwala',
        'Sandy',
        'São Cristóvão',
        'Rayleigh',
        'Paramagudi',
        'Zerakpur',
        'Slidell',
        'Tandwa',
        'Shirē',
        'Kamisu',
        'Çubuk',
        'Burdur',
        'Candelaria',
        'Mezhdurechensk',
        'Aliağa',
        'Pesaro',
        'Erbaa',
        'Tiruchengodu',
        'Hương Thủy',
        'Mons',
        'Pedagādi',
        'Oued Zem',
        'Boli',
        'Nagīna',
        'Bogo',
        'Saint-Michel de l’Atalaye',
        'Chākdaha',
        'Shimada',
        'Closepet',
        'Jiantang',
        'Dovzhansk',
        'Birecik',
        'Emmiganūr',
        'Yalamakūru',
        'Balamban',
        'Siguatepeque',
        'Labuan',
        'Naxçıvan',
        'Rawānduz',
        'Yumbo',
        'Vāniyambādi',
        'Al Jīzah',
        'Buckeye',
        'Rubio',
        'Tiruttani',
        'Mianwali',
        'Dongping',
        'Meizichong',
        'Nāḩiyat al Karmah',
        'Moca',
        'Rincón de Romos',
        'Ocotlán',
        'Sibolga',
        'Esslingen',
        'Cereté',
        'Hemel Hempstead',
        'Livonia',
        'San Ramón',
        'San Martín Jilotepeque',
        'Williamsburg',
        'Bouna',
        'Legnica',
        'Kangan',
        'Suffolk',
        'Los Patios',
        'Compton',
        'Lecce',
        'Bath',
        'Behshahr',
        'Lopez',
        'Lingwala',
        'La Crosse',
        'Bhadohi',
        'Kanuma',
        'Gjakovë',
        'Edmond',
        'San Joaquín',
        'Gerona',
        'Carson',
        'Allinagaram',
        'Gatchina',
        'Bayan Hot',
        'Niagara Falls',
        'Villa Luzuriaga',
        'Marmagao',
        'San Marcos',
        'Pingyuanjie',
        'Greenburgh',
        'Shibata',
        'Ludwigsburg',
        'Gießen',
        'Ashiya',
        'Yishui',
        'Yi Xian',
        'Tracy',
        'Paracatu',
        'Herẕliyya',
        'Monkayo',
        'Ponta Porã',
        'Hayes',
        'Rio Largo',
        'San Fernando',
        'Azumino',
        'Prescott Valley',
        'Dhār',
        'Qormi',
        'San José del Rincón Centro',
        'Middletown',
        'Valongo',
        'Alvand',
        'Lingtang',
        'Đông Hà',
        'Menderes',
        'Chiantla',
        'Simeulu',
        'Ziftá',
        'Cape Breton',
        'Al Fujayrah',
        'Sanjō',
        'Mikhaylovsk',
        'Fall River',
        'Gera',
        'Jaén',
        'Mairiporã',
        'Lawang',
        'San Germán',
        'Hradec Králové',
        'Daanbantayan',
        'Sarov',
        'Michurinsk',
        'Monastir',
        'Wangjia',
        'Yashio',
        'Jinbi',
        'Akşehir',
        'Yotsukaidō',
        'Düren',
        'Chilliwack',
        'Santa Cruz Xoxocotlán',
        'Lemery',
        'Indanan',
        'Santa Fe',
        'Râmnicu Vâlcea',
        'Fenggang',
        'Tual',
        'Plantation',
        'Galle',
        'Mayagüez',
        'Itajubá',
        'Kwekwe',
        'Glazov',
        'Darlington',
        'New Braunfels',
        'Sidi Slimane',
        'Créteil',
        'La Marsa',
        'Ubatuba',
        'Ciudad General Belgrano',
        'Rafaela',
        'Wukari',
        'Guaíba',
        'Barra do Piraí',
        'Palimbang',
        'Nisshin',
        'Hanamaki Onsen',
        'Tübingen',
        'Boundiali',
        'La Reina',
        'Magadan',
        'Roswell',
        'Wimbledon',
        'San Sebastián de los Reyes',
        'Tatvan',
        'Kadugli',
        'Akot',
        'Tamanrasset',
        'Helmond',
        'Inagi',
        'Solikamsk',
        'Mtwara',
        'Rongwo',
        'Sablayan',
        'Azua',
        'Naju',
        'Mogi Mirim',
        'Flensburg',
        'Iserlohn',
        'São João da Boa Vista',
        'Oss',
        'Conroe',
        'Barletta',
        'Bedford',
        'South Gate',
        'Errachidia',
        'Tatakan',
        'Sepatan',
        'Kitakami',
        'Serra Talhada',
        'Contai',
        'Santa Barbara',
        'Ōbu',
        'Teziutlan',
        'Santa Monica',
        'Pardubice',
        'Coatepec',
        'La Spezia',
        'São Roque',
        'Voskresensk',
        'Kirkland',
        'Hoover',
        'Kot Kapūra',
        'Ústí nad Labem',
        'Netrakona',
        'Hove',
        'Cruzeiro do Sul',
        'Satsumasendai',
        'Shabqadar',
        'Pato Branco',
        "O'Fallon",
        'Hamilton',
        'Higashi-Matsuyama',
        'Tultepec',
        'Kakamega',
        'Raub',
        'Ţurayf',
        'Southport',
        'Mijas',
        'Ouidah',
        'Goalundo Ghāt',
        'Sinendé',
        'Linquan',
        'Phú Thọ',
        'Maracay',
        'Çaycuma',
        'Niono',
        'Grahamstown',
        'Myingyan',
        'Alafaya',
        'Brossard',
        'Satu Mare',
        'Pīrānshahr',
        'Yao',
        'Samālūţ',
        'Kārwār',
        'Mīzan Teferī',
        'Arzew',
        'Salaman',
        'Māndvi',
        'San Francisco Solano',
        'Tucuruí',
        'Kalamariá',
        'Jauharabad',
        'Kendu Bay',
        'Hilversum',
        'Rāyachoti',
        'Avaré',
        'Pāloncha',
        'Manhuaçu',
        'Caçapava',
        'Xiancun',
        'Palm Coast',
        'Alessandria',
        'Ambohimangakely',
        'Hastings',
        'Norwalk',
        'Agua Prieta',
        'Lawton',
        'Chino',
        'Lachhmangarh Sīkar',
        'Maple Ridge',
        'Miaoli',
        'Mount Pleasant',
        'Velikiye Luki',
        'Tonacatepeque',
        'Grudziądz',
        'Guercif',
        'Solwezi',
        'Cisauk',
        'Caimbambo',
        'Oudtshoorn',
        'Bauan',
        'Pantukan',
        'Pongotan',
        'Rāmagiri Udayagiri',
        'Cambambe',
        'Gwadar',
        'Mengdingjie',
        'Manteca',
        'Funza',
        'Zhezqazghan',
        'Çanakkale',
        'Katiola',
        'Westminster',
        'Biga',
        'Eslāmābād-e Gharb',
        'Chililabombwe',
        'Vanadzor',
        'Fundación',
        'Ponnāni',
        'Sahagún',
        'Arsuz',
        'Jāmtāra',
        'Florence',
        'Joplin',
        'Pinamalayan',
        'Avignon',
        'Orpington',
        'Valjevo',
        'Julu',
        'Pazardzhik',
        'Vezirköprü',
        'Mandurah',
        'Watford',
        'Poitiers',
        'São João del Rei',
        'Yoshiwara',
        'Puerto Padre',
        'Caucasia',
        'Germantown',
        'Peñaflor',
        'Imizuchō',
        'Nasatta',
        'Abomey',
        'Pollāchi',
        'Gjilan',
        'Hendek',
        'Gujar Khan',
        'Jalalpur Jattan',
        'Wajir',
        'Victorias',
        'Marudi',
        'Kāvali',
        'Aubervilliers',
        'Botoşani',
        'Darnah',
        'Möng Tun',
        'Richard-Toll',
        'Afmadow',
        'Barwaaqo',
        'Ma‘arrat an Nu‘mān',
        'Luau',
        'Necochea',
        'Tangjia',
        'Al Badrashayn',
        'Léogâne',
        'El Ejido',
        'Mihara',
        'Compostela',
        'Presidencia Roque Sáenz Peña',
        'Kiselëvsk',
        'Bayt Lāhyā',
        'Patrocínio',
        'El Puerto de Santa María',
        'Itapeva',
        'San Leandro',
        'Olavarría',
        'Dobrich',
        'Stevenage',
        'San José Pinula',
        'Sankeshwar',
        'Kōka',
        'Glyfáda',
        'Serdar',
        'Saquarema',
        'Cantaura',
        'Los Cerrillos',
        'Conda',
        'Cáceres',
        'Cheektowaga',
        'Ţarţūs',
        "Town 'n' Country",
        'Clifton',
        'Waukegan',
        'Kadiri',
        'Tonghae',
        'Zhunan',
        'Ipil',
        'Prijedor',
        'Oulad Teïma',
        'Níkaia',
        'Mestre',
        'Caracase',
        'Surallah',
        'Ciudad de Atlixco',
        'Pistoia',
        'Akyazı',
        'Torrevieja',
        'Pamekasan',
        'Ségbana',
        'Maladzyechna',
        'Bloomington',
        'Léré',
        'Avondale',
        'Maumere',
        'Phusro',
        'Polangui',
        'Banga',
        'Humpata',
        'Kalibo',
        'San Francisco',
        'Atascocita',
        'Kūhdasht',
        'Jalal-Abad',
        'Kairāna',
        'Jaworzno',
        'Kamensk-Shakhtinskiy',
        'Saundatti',
        'Kansk',
        'Shwebo',
        'Hinigaran',
        'Calabanga',
        'As Salţ',
        'Passi',
        'Colombes',
        'Murcia',
        'Bogo',
        'Aalst',
        'Lucca',
        'Missoula',
        'Hemei',
        'Pisa',
        'Wangqing',
        'Viana do Castelo',
        'Danlí',
        'Chiclana de la Frontera',
        'Fort Myers',
        'Montelíbano',
        'Ben Guerir',
        'Toviklin',
        'Chosica',
        'Paingkyon',
        'Villa María',
        'Rāsipuram',
        'Leping',
        'Najībābād',
        'Podujevë',
        'San Luis',
        'Barra do Corda',
        'Bayramaly',
        'Kimje',
        'Bhakkar',
        'Berisso',
        'Bertoua',
        'Newton',
        'La Grita',
        'Solana',
        'Aïn M’Lila',
        'Nirmal',
        'Nirāla',
        'Ootacamund',
        'Echague',
        'Aroroy',
        'Mobara',
        'Ben Arous',
        'Prosperidad',
        'Alabel',
        'Ban Laem Chabang',
        'Grimsby',
        'Lobnya',
        'Villingen-Schwenningen',
        'Jangipur',
        'Jaffna',
        'Janzūr',
        'Leshou',
        'Lawrence',
        'Muncie',
        'Sangrūr',
        'Damaturu',
        'Qiantangcun',
        'Hartlepool',
        'Al Wakrah',
        'Sassandra',
        'Sakai',
        'Newmarket',
        'Jilotepec',
        'Makilala',
        'Wislane',
        'Maiquetía',
        'Mettupālaiyam',
        'Wakiso',
        'Bromley',
        'Jumri Tilaiyā',
        'Rapid City',
        'Guanambi',
        'Jagüey Grande',
        'Baggao',
        'San Juan de los Morros',
        'Aruppukkottai',
        'Farīdkot',
        'Calauan',
        'Ceylanpınar',
        'Ama',
        'Słupsk',
        'Madgaon',
        'Baras',
        'Gitarama',
        'Ende',
        'Koidu-Bulma',
        'Palangotu Adwār',
        'Sakiet ez Zit',
        'Western Bicutan',
        'Chester',
        'Consolación del Sur',
        'Cipolletti',
        'Kpalimé',
        'Changting',
        'Maasin',
        'San Fabian',
        'Şatrovka',
        'Ratingen',
        'Midrand',
        'Denan',
        'Santa Catarina Pinula',
        'Calaca',
        'Caratinga',
        'Jamūī',
        'Middelburg',
        'Camiling',
        'Nahualá',
        'Limpio',
        'Shuangshuicun',
        'Torrente',
        'Chongoroi',
        'Chimbas',
        'Bhola',
        'Bi’r al ‘Abd',
        'Lorena',
        'Dipalpur',
        'Zwickau',
        'Troy',
        'Fulham',
        'Houndé',
        'Livermore',
        'Citrus Heights',
        'Ad Diwem',
        'Norton',
        'Bama',
        'Wulan',
        'Hawthorne',
        'Heyunkeng',
        'Mardin',
        'Kumārapālaiyam',
        'Heerlen',
        'Mechelen',
        'Cacoal',
        'Takasagochō-takasemachi',
        'Binmaley',
        'Lünen',
        'Tangxing',
        'Campana',
        'Paredes',
        'Fukuroi',
        'Widekum',
        'Winchester',
        'Taohuajiang',
        'Longonjo',
        'Dunkerque',
        'Gubkin',
        'Les Cayes',
        'Hānsi',
        'Salinas Victoria',
        'Kattagan',
        'Tiflet',
        'Springdale',
        'Cárdenas',
        'Shahdol',
        'Yoro',
        'Hamakita',
        'Unaí',
        'Clarkstown',
        'Nuneaton',
        'Anakāpalle',
        'Gravatá',
        'Nabua',
        'Tucupita',
        'Novotroitsk',
        'Tuncheng',
        'Whittier',
        'Deerfield Beach',
        'Nīmbāhera',
        'Nakhon Sawan',
        'Yaofeng',
        'Nachchāndupatti',
        'Hassi Bahbah',
        'Loznica',
        'Kalpitiya',
        'Karanganyar',
        'Navegantes',
        'Yabēlo',
        'Santa Rosa Jauregui',
        'Dingcheng',
        'Guasave',
        'Gotenba',
        'Odienné',
        'Ciudad de Melilla',
        'Bangkalan',
        'Bantayan',
        'San Antonio',
        'Decatur',
        'Batticaloa',
        'Seoni Mālwa',
        'Buzuluk',
        'Ibshawāy',
        'Settsu',
        'Silvan',
        'Sārni',
        'Aulnay-sous-Bois',
        'San Ramon',
        'Repentigny',
        'Shchëkino',
        'Bugulma',
        'Toledo',
        'Khowrāsgān',
        'Kitanagoya',
        'Vineland',
        'Shaoshanzhan',
        'Potiskum',
        'Sharūrah',
        'Kameoka',
        'Keffi',
        'Qaraçuxur',
        'Iga',
        'Chiguayante',
        'Cabiao',
        'Konstanz',
        'Kouvola',
        'Vólos',
        'Guinobatan',
        'Kharian',
        'Mission',
        'Bāsoda',
        'Longhua',
        'Taishan Houcun',
        'San Pedro Sacatepéquez',
        'Ducheng',
        'Ādwa',
        'Sekimachi',
        'Maratturai',
        'Auburn',
        'Fuengirola',
        'Redenção',
        'Karakol',
        'Lake Forest',
        'El Bayadh',
        'Mukacheve',
        'Fusui',
        'Xuddur',
        'Karād',
        'Pariaman',
        'Chinnachauku',
        'Batarasa',
        'Lugang',
        'Junín',
        'Shumen',
        'Colonie',
        'Warder',
        'Caldas',
        'Vélez-Málaga',
        'Pori',
        'Mitrovicë',
        'Jinhe',
        'Garulia',
        'Tagaytay',
        'Apaseo el Grande',
        'General Rodríguez',
        'Chiquinquirá',
        'Krishnagiri',
        'Arona',
        'Upper Darby',
        'Dapitan',
        'Takayama',
        'Grand Bourg',
        'Newport Beach',
        'Harda Khās',
        'Gurupi',
        'Maga',
        'Araripina',
        'Monte Chingolo',
        'Jastrzębie-Zdrój',
        'Puerto Maldonado',
        'Mhow',
        'Derry',
        'Santa Inês',
        'Ealing',
        'Walvisbaai',
        'Bahlā’',
        'Taxila',
        'Hövsan',
        'Luvungi',
        'Korgas',
        'Melbourne',
        'Atakpamé',
        'Woolwich',
        'Longchuan',
        'Brooklyn Park',
        'Karacabey',
        'Baabda',
        'Larnaca',
        'Prešov',
        'Bryan',
        'Sayhāt',
        'Westland',
        'Ílion',
        'Peterborough',
        'Ciudad Mante',
        'Konotop',
        'Pandacan',
        'Chirundu',
        'Túxpam de Rodríguez Cano',
        'Ijuí',
        'Napa',
        'Mohammadia',
        'Catanzaro',
        'Sumenep',
        'Tshilenge',
        'Worms',
        'Pinheiro',
        'Treviso',
        'Mādabā',
        'Khemis Miliana',
        'Yokotemachi',
        'Dhorāji',
        'Rafḩā',
        'Honiara',
        'Ushiku',
        'Tire',
        'Vīrappanchathiram',
        'Baytown',
        'Komae',
        'Santana do Livramento',
        'Sabanalarga',
        'Dmitrov',
        'New Kru Town',
        'Carpina',
        'Kaizuka',
        'Nabunturan',
        'Marl',
        'Suceava',
        'Bais',
        'Villa Altagracia',
        'Science City of Muñoz',
        'Athurugiriya',
        'Higashiyamato',
        'Ayase',
        'Bilwi',
        'Cicero',
        'Chigorodó',
        'Quixadá',
        'Concepción Tutuapa',
        'Wakō',
        'Al Hindīyah',
        'Luancheng',
        'Dārayyā',
        'Sambava',
        'Kitakōriyamachō',
        'Heṭauḍā',
        'Ath Thawrah',
        'Lāharpur',
        'Diphu',
        'Bolinao',
        'Arujá',
        'El Milia',
        'Channapatna',
        'San Baudilio de Llobregat',
        'Chita',
        'Anderson',
        'Keşan',
        'Kineshma',
        'Ermelo',
        'Zheleznogorsk',
        'Xishancun',
        'Acayucan',
        'Lucas do Rio Verde',
        'Dolisie',
        'Bhawānipatna',
        'Pilkhua',
        'Samadiāla',
        'Ho',
        'Haskovo',
        'Yeysk',
        'Franklin',
        'Matão',
        'Barahona',
        'Tiruppattūr',
        'Hamma Bouziane',
        'Versailles',
        'Moriyama',
        'Farmington Hills',
        'Nizwá',
        'Buena Park',
        'Foumban',
        'Talavera de la Reina',
        'Lafey',
        'Galway',
        'Albany',
        'Aylesbury',
        'Atambua',
        'Nazareth',
        'Taytay',
        'Phúc Yên',
        'Reşiţa',
        'La Piedad',
        'Gokulgarh',
        'São Bento do Sul',
        'Serrinha',
        'Maco',
        'Lqoliaa',
        'Pine Hills',
        'Ashford',
        'Sirsilla',
        'Como',
        'State College',
        'Boukoumbé',
        'Habiganj',
        'Lakshmīpur',
        'La Trinidad',
        'Picos',
        'Siaton',
        'Redwood City',
        'Minden',
        'Kampong Trach',
        'San Antonio',
        'Moju',
        'Ciudad de Ceuta',
        'Lelystad',
        'La Lima',
        'San Fernando',
        'Wutiancun',
        'Mingxing',
        'Mazatenango',
        'Mānsa',
        'Mabinay',
        'Busto Arsizio',
        'Nantingcun',
        'David',
        'Al Ḩayy',
        'Louga',
        'Ufeyn',
        'Ji’an',
        'Wadala Sandhuan',
        'Warwick',
        'Jackson',
        'Bayeux',
        'Stockton-on-Tees',
        'Nakatsu',
        'Brindisi',
        'Cranston',
        'Shīrvān',
        'Tanjay',
        'Chingleput',
        'Jacobina',
        'Mian Channun',
        'Manfalūţ',
        'Rivadavia',
        'Rivadavia',
        'Cruzeiro',
        'Chelghoum el Aïd',
        'Bhalwal',
        'Largo',
        'Calatrava',
        'Pontevedra',
        'Wuyi',
        'Chulucanas',
        'El Estor',
        'Edmonton',
        'Velbert',
        'Cukai',
        'Miami Beach',
        'Chaykovskiy',
        'Sabaneta',
        'Oleksandriia',
        'Owariasahi',
        'Shikokuchūō',
        'Alhambra',
        'Kuznetsk',
        'Deurne',
        'Ōmihachiman',
        'Johns Creek',
        'Puerto Iguazú',
        'Nueva Concepción',
        'Macaíba',
        'Uman',
        'Saint Albans',
        'Mountain View',
        'Quixeramobim',
        'Bekobod',
        'Tacaná',
        'Harlow',
        'Carmen',
        'Burnley',
        'Ust’-Ilimsk',
        'Salisbury',
        'Jepara',
        'Redditch',
        'Saunda',
        'Morgantown',
        'Kongjiazhuangcun',
        'Norderstedt',
        'Ashoknagar',
        'Silver Spring',
        'Ubay',
        'Yurga',
        'Bhaktapur',
        'Banco Filipino International Village',
        'Layton',
        'Bilecik',
        'Yıldız',
        'Gürgenpınarı',
        'Uzungöz',
        'Lucerne',
        'Siasi',
        'Concórdia',
        'Watampone',
        'Springfield',
        'Kātoya',
        'An Khê',
        'Paranavaí',
        'Muroran',
        'Timóteo',
        'Apizaco',
        'Hukou',
        'São Sebastião',
        'Courbevoic',
        'Purmerend',
        'Dar el Beïda',
        'Imam Qasim',
        'Lakewood',
        'Kentaū',
        'Remedios de Escalada',
        'Xicotepec de Juárez',
        'Anapa',
        'Fiumicino',
        'Afşin',
        'Shuibian',
        'Kadi',
        'Valdemoro',
        'Chapadinha',
        'Xiedian',
        'Matalam',
        'Kimitsu',
        'Grosseto',
        'Buhi',
        'Athi River',
        'Nowy Sącz',
        'Florence',
        'San José de las Lajas',
        'Pacatuba',
        'Lambunao',
        'Bulacan',
        'Novouralsk',
        'Mannārgudi',
        'Port of Spain',
        'Orhangazi',
        'Hengkou',
        'Itá',
        'Folsom',
        'Kapalong',
        'Salina Cruz',
        'Panzos',
        'Tecate',
        'Balrāmpur',
        'Baalbek',
        'Hengelo',
        'Kashiwazaki',
        'Tallaght',
        'Khamānon Kalān',
        'La Louvière',
        'Launceston',
        'Madera',
        'Dera Allahyar',
        'Gonder',
        'Campo Limpo',
        'Gaoliying Ercun',
        'New Rochelle',
        'Rafaḩ',
        'Ahenkro',
        'Yonezawa',
        'Orihuela',
        'Shiji',
        'Gobernador Gálvez',
        'San Francisco',
        'Malapatan',
        'Balçova',
        'Barili',
        'Wujindian',
        'Meybod',
        'Yanggao',
        'Bargarh',
        'Curvelo',
        'San Cristóbal Verapaz',
        'Comitancillo',
        'Seropédica',
        'Parma',
        'Terre Haute',
        'San Ramón',
        'Bulanık',
        'Samā’il',
        'Dahegām',
        'Torre del Greco',
        'Randfontein',
        'Batley',
        'Somerville',
        'Ādīgala',
        'Azov',
        'Nagaoka',
        'Calandala',
        'Panjgur',
        'Butterworth',
        'Tala',
        'Antehiroka',
        'Oum el Bouaghi',
        'Cabadbaran',
        'Béziers',
        'Tagoloan',
        'Kāmāreddipet',
        'Zuwārah',
        'Bafut',
        'Tākestān',
        'Arni',
        'Echizen',
        'Maravatío de Ocampo',
        'Lívingston',
        'Aquiraz',
        'Kumbo',
        'Bolpur',
        'Peruvancha',
        'Sint-Niklaas',
        'Flagstaff',
        'Taroudannt',
        'Namsan',
        'Gumlā',
        'Simdega',
        'San Andrés Cholula',
        'Qŭnghirot',
        'Cachoeira do Sul',
        'Boynton Beach',
        'Gamagōri',
        'Manmād',
        'Goiana',
        'Rubí',
        'Nabatîyé',
        'Masallātah',
        'Andahuaylas',
        'Kathri',
        'Jamshoro',
        'Khewra',
        'Zarzis',
        'Puerto Ayacucho',
        'San Carlos del Zulia',
        'Koktokay',
        'Piro',
        'Tall ‘Afar',
        'Homestead',
        'Scunthorpe',
        'Polatsk',
        'Newark',
        'Mīt Salsīl',
        'Bamberg',
        'Plymouth',
        'Turhal',
        'Ben Gardane',
        'Sefrou',
        'Kırklareli',
        'Pátzcuaro',
        'San Jose',
        'Drobeta-Turnu Severin',
        'Sokcho',
        'Paco',
        'Kottagūdem',
        'Idah',
        'Marsala',
        'Poblacion',
        'Anniston',
        'Piatra Neamţ',
        'Ciudad Lerdo',
        'Dessau-Rosslau',
        'Tissamaharama',
        'Akiruno',
        'Texarkana',
        'Patikul',
        'Ezpeleta',
        'Banī Mazār',
        'Mahdia',
        'Vila do Conde',
        'Krasnyi Luch',
        'Cianorte',
        'Tustin',
        'Belo Jardim',
        'Neumünster',
        'Xai',
        'Viana',
        'Eséka',
        'Döşemealtı',
        'Robles',
        'San Martín',
        'Torres Vedras',
        'Langarūd',
        'Pharr',
        'Senhor do Bonfim',
        'Afgooye',
        'Klin',
        'Dudley',
        'San Isidro',
        'Port Huron',
        'Iwamizawa',
        'Yenakiieve',
        'Mangatarem',
        'Ban Talat Rangsit',
        'Sorgun',
        'Caçador',
        'Turlock',
        'Owendo',
        'Schiedam',
        'Yalta',
        'Ban Nong Prue',
        'Drummondville',
        'Natori-shi',
        'Kawartha Lakes',
        'Lisala',
        'Évosmos',
        'Juventino Rosas',
        'Ciudad Lázaro Cárdenas',
        'Jaen',
        'Dobni Para',
        'Hannō',
        'Carmen',
        'Rancho Cordova',
        'Gokāk',
        'Ceará-Mirim',
        'The Villages',
        'Tīkamgarh',
        'Ikom',
        'Milpitas',
        'Ozërsk',
        'Būmahen',
        'Ubon Ratchathani',
        'Bahārestān',
        'Arāria',
        'Cuamba',
        'Huaral',
        'Madīnat as Sādāt',
        'Pèrèrè',
        'Alfenas',
        'Sougueur',
        'Nakatsugawa',
        'New Westminster',
        'Tiko',
        'Sesto San Giovanni',
        'Perris',
        'Bistriţa',
        'Manresa',
        'Daugavpils',
        'Upland',
        'Subulussalam',
        'Tambacounda',
        'Dome',
        'Nakhon Pathom',
        'Toboali',
        'Tierralta',
        'Maizuru',
        'Bury',
        'Alton',
        'Eastleigh',
        'Elbasan',
        'Pagbilao',
        'Villa Celina',
        'Ra’s al Khafjī',
        'Pleasanton',
        'Alīgūdarz',
        'Zaandam',
        'Mooka',
        'Arlit',
        'Skarżysko-Kamienna',
        'Dabakala',
        'Curepipe',
        'Dongchuan',
        'Hengbei',
        'Kuvango',
        'Brixton',
        'La Rochelle',
        'Taher',
        'Gyōda',
        'Sahuayo de Morelos',
        'Aveiro',
        'Bauang',
        'Dinga',
        'Iperu',
        'Varese',
        'Kendall',
        'Arkonam',
        'Delmenhorst',
        'Jonesboro',
        'Bandar Emām',
        'Bellflower',
        'Três Rios',
        'Kashiba',
        'Barreiro',
        'Battle Creek',
        'Denov',
        'Pototan',
        'Qorveh',
        'Queluz',
        'Limay',
        'Bamban',
        'Manbij',
        'Pingyi',
        'Southall',
        'Huolu',
        'San Pedro Pinula',
        'Chino Hills',
        'Péhonko',
        'Viersen',
        'Cheyenne',
        'Argao',
        'Rueil-Malmaison',
        'Al Khānkah',
        'Tanashichō',
        'Chitembo',
        'Chitemo',
        'Ilioúpoli',
        'Macabebe',
        'Kropotkin',
        'Lebanon',
        'Carmichael',
        'South Jordan',
        'Kuacjok',
        'Gandía',
        'Faranah',
        'Mandiraja Kulon',
        'Baracoa',
        'Kizugawa',
        'Godāwari̇̄',
        'Balqash',
        'Colón',
        'Essaouira',
        'Tanuku',
        'Narra',
        'Koch Bihār',
        'Chengbin',
        'Pakxé',
        'Honjō',
        'Toyomamachi-teraike',
        'Fray Bartolomé de Las Casas',
        'Rheine',
        'Hoofddorp',
        'Davis',
        'Marburg',
        'Villa Victoria',
        'Elizabethtown',
        'Linkou',
        'Birkhadem',
        'Kuniyamuttūr',
        'Kortrijk',
        'Kambam',
        'Bir el Ater',
        'Champigny-sur-Marne',
        'Brikama',
        'Ukunda',
        'Huebampo',
        'Hasselt',
        'Bebedouro',
        'Aksu',
        'Nitra',
        'Phuket',
        'Lipjan',
        'Bodhan',
        'Schaumburg',
        'Alameda',
        'Santa Rosa',
        'Zhongcheng',
        'Santa Catalina',
        'Grand-Lahou',
        'Stellenbosch',
        'Hermosa',
        'Fnidq',
        'Mengla',
        'Usol’ye-Sibirskoye',
        'Katano',
        'Hammond',
        'Tsubame',
        'Tirkākara',
        'Vorkuta',
        'Quatre Bornes',
        'Jelenia Góra',
        'Puli',
        'Caxito',
        'Pasco',
        'Latacunga',
        'Bracknell',
        'Ban Tha Khlong',
        'Cozumel',
        'Paisley',
        'Gelendzhik',
        'Pattoki',
        'Harunabad',
        'Roosendaal',
        'Aş Şuwayrah',
        'Bustos',
        'Evanston',
        'Kabacan',
        'Fukuchiyama',
        'Shūsh',
        'Lehi',
        'Alexandria',
        'Chalándri',
        'Keratsíni',
        'Umingan',
        'Pau',
        'Tecamachalco',
        'Guildford',
        'Zhlobin',
        'Talakag',
        'Nikkō',
        'Nabari',
        'Balagtas',
        'Toyooka',
        'Balkh',
        'Estepona',
        'North Port',
        'Bongabong',
        'Nagua',
        'Berbérati',
        'Santo Ângelo',
        'Ébolowa',
        'Valença',
        'Mao',
        'Lüneburg',
        'Baj Baj',
        'Veliko Tarnovo',
        'Arlington Heights',
        'Chatham',
        'Surt',
        'Shostka',
        'Balashov',
        'El Viejo',
        'Usta Muhammad',
        'Camarillo',
        'Pyapon',
        'Wyoming',
        'Dorsten',
        'Prince George',
        'Mafra',
        'E’erguna',
        'Nipāni',
        'Vinhedo',
        'Tacámbaro de Codallos',
        'Ait Ali',
        'Flower Mound',
        'Ivanteyevka',
        'Aira',
        'Caledon',
        'Bethlehem',
        'Cotuí',
        'Alcalá de Guadaira',
        'Tianguá',
        'Daxincun',
        'Dschang',
        'İdil',
        'Virac',
        'Hattiesburg',
        'Trinidad',
        'Loveland',
        'Abéché',
        'Fāzilka',
        'Khemis el Khechna',
        'Funing',
        'Armant',
        'Al Musayyib',
        'Shinkai',
        'Ibiúna',
        'Paysandú',
        'Pittsburg',
        'Kafr az Zayyāt',
        'Crateús',
        'Ninh Hòa',
        'Siedlce',
        'Sasolburg',
        'Melton',
        'Cedar Park',
        'Palencia',
        'Pozzuoli',
        'Shājāpur',
        'So-Awa',
        'Troisdorf',
        'Tuban',
        'Palmerston North',
        'Padre Hurtado',
        'Anzhero-Sudzhensk',
        'Palo',
        'Keshod',
        'Infanta',
        'East Ham',
        'Kadoma',
        'Daisen',
        'Nahāvand',
        'Bender',
        'Wenatchee',
        'Weston-super-Mare',
        'Montepuez',
        'Esteio',
        'Southfield',
        'Kahror Pakka',
        'Lins',
        'Wilhelmshaven',
        'Fancheng',
        'Molina de Segura',
        'San Ramón de la Nueva Orán',
        'Chintāmani',
        'Ryūgasaki',
        'Manaoag',
        'Rochester Hills',
        'Hammond',
        'Banská Bystrica',
        'Merouana',
        'Yanam',
        'Tailai',
        'Toba Tek Singh',
        'Villa Elisa',
        'Valdosta',
        'Houmt Souk',
        'Rulin',
        'Châteauguay',
        'Gladbeck',
        'Catacaos',
        'Kengtung',
        'Ovalle',
        'Solok',
        'Sankt Gallen',
        'Espinal',
        'Benalmádena',
        'Pilar',
        'Surendranagar',
        'Stakhanov',
        'Sentani',
        'Malappuram',
        'Kargilik',
        'Mérignac',
        'Cadereyta Jiménez',
        'Laiyuan',
        'Xaignabouli',
        'Lod',
        'Sidi Qacem',
        'Maghāghah',
        'Arta',
        'Santa Rosa',
        'Sapiranga',
        'Owensboro',
        'Umeå',
        'San Pedro',
        'Arjona',
        'Apple Valley',
        'Jocotán',
        'Aïn Temouchent',
        'Woodbury',
        'Garhi',
        'Cataguases',
        'Avilés',
        'Joensuu',
        'Jablah',
        'Ramla',
        'Três Corações',
        'Landshut',
        'Saint-Maur-des-Fossés',
        'Tindivanam',
        'Carlisle',
        'Srīvilliputtūr',
        'Kiyose',
        'Xinglong',
        'Bhadarwāh',
        'Rānāghāt',
        'Shadrinsk',
        'Cheria',
        'South Shields',
        'Kai',
        'Bonāb',
        'Villa Carlos Paz',
        'Ciudad Real',
        'Ardakān',
        'Cửa Lô',
        'Acacías',
        'Frontera',
        'Itapira',
        'Tissemsilt',
        'Pawtucket',
        'Lagoa Santa',
        'Dongguan',
        'Dhrāngadhra',
        'Kunitachi',
        'Dayr al Balaḩ',
        'Pinamungahan',
        'Antibes',
        'East Kilbride',
        'Teyateyaneng',
        'Aracati',
        'Detmold',
        'Newcastle under Lyme',
        'Mawatagama',
        'Burton upon Trent',
        'Libon',
        'Chernogorsk',
        'Belleville',
        'Telêmaco Borba',
        'St. Joseph',
        'Gangammapeta',
        'Puqiancun',
        'Dubna',
        'Iju',
        'Wisil',
        'Al Muḩarraq',
        'Khejroli',
        'Tirumangalam',
        'Pardigūda',
        'Warabi',
        'Bugallon',
        'Tocumen',
        'Cherry Hill',
        'Panggezhuang',
        'Jaorā',
        'Doral',
        'Gönen',
        'Fouchana',
        'Amakusa',
        'Tura',
        'Ambājogāi',
        'Palencia',
        'Juchitán de Zaragoza',
        'Dabra',
        'Ubud',
        'Sasagawa',
        'Darhan',
        'Darhan',
        'Pozorrubio',
        'Toffo',
        'Tiznit',
        'Esperanza',
        'Tanguiéta',
        'Tosu',
        'Moulay Abdallah',
        'Cotorro',
        'Drohobych',
        'Dalaguete',
        'Piraçununga',
        'Ouro Prêto',
        'Dover',
        'Cinisello Balsamo',
        'Missouri City',
        'Dayong',
        'Bayreuth',
        'Īṭahari̇̄',
        'Péda-Houéyogbé',
        'Katori',
        'Kandori',
        'Shancheng',
        'Bozüyük',
        'San Antonio de Los Altos',
        'Assab',
        'Gbawe',
        'Skellefteå',
        'Balingasag',
        'Saratoga Springs',
        'Arnsberg',
        'Wandiwāsh',
        'Ouaké',
        'Rāmhormoz',
        'Pocatello',
        'Bongouanou',
        'San Juan Opico',
        'Miki',
        'Oshkosh',
        'Uspantán',
        'Silao',
        'Brick',
        'New Britain',
        'Çınar',
        'Zlín',
        'Izumiōtsu',
        'Canindé',
        'Mankono',
        'Le Kram',
        'Aprilia',
        'Okegawa',
        'Meshgīn Shahr',
        'Al Qā’im',
        'Airdrie',
        'Blagoevgrad',
        'Castle Rock',
        'Chalchuapa',
        'Gūdūr',
        'Yelabuga',
        'Casoria',
        'Pedro Brand',
        'Gravesend',
        'Ra‘ananna',
        'Brookes Point',
        'Tatebayashi',
        'Lauderhill',
        'Kyōtanabe',
        'Kahan',
        'Tatsunochō-tominaga',
        'Majalengka',
        'Broomfield',
        'Sarnia',
        'Dale City',
        'Mineral’nyye Vody',
        'Vlaardingen',
        'Tamworth',
        'Samundri',
        'Dumangas',
        'Alicia',
        'Dĩ An',
        'Yurihonjō',
        'Aïn Oulmene',
        'Cunhinga',
        'Ajaccio',
        'Hāveri',
        'Castrop-Rauxel',
        'Türkoğlu',
        'Beyşehir',
        'Yegoryevsk',
        'Bolingbrook',
        'Redmond',
        'Caguas',
        'El Kef',
        'Gouda',
        'Mansfield',
        'Tefé',
        'Tarn Tāran',
        'Nandi Hills',
        'Birobidzhan',
        'Hoorn',
        'Mangalagiri',
        'João Monlevade',
        'Brandenburg',
        'Linares',
        'Ellicott City',
        'Vriddhāchalam',
        'Harrogate',
        'Copacabana',
        'Cao Bằng',
        'Târgu Jiu',
        'Sheboygan',
        'Xindian',
        'Kallakkurichchi',
        'Irecê',
        'Kasama',
        'El Hamma',
        'Kumluca',
        'Paterna',
        'Bandar-e Genāveh',
        'Tupi',
        'Mansfield',
        'Novoaltaysk',
        'Lala',
        'Kāsipālaiyam',
        'Glens Falls',
        'Troitsk',
        'Asti',
        'Inuyama',
        'Pančevo',
        'Jose Abad Santos',
        'Bagan Si Api-api',
        'Daytona Beach',
        'Reconquista',
        'Quillota',
        'Cannes',
        'Crewe',
        'Lodi',
        'Redlands',
        'Tan-Tan',
        'Fada Ngourma',
        'Ōtawara',
        'Pacajus',
        'Shakargarh',
        'Harrisonburg',
        'Ragusa',
        'Ciudadela',
        'Bula',
        'Pattukkottai',
        'Almelo',
        'Gobindgarh',
        'Nabeul',
        'Edéa',
        'Brakpan',
        'Néa Smýrni',
        'Usulután',
        'Shibukawa',
        'Luján de Cuyo',
        'Moa',
        'Berdychiv',
        'Dothan',
        'Baghlān',
        'Chaigoubu',
        'Santa Rosa de Cabal',
        'Santa Isabel do Pará',
        'Türkmenbaşy',
        'Naqadeh',
        'Çatalca',
        'San Vicente del Caguán',
        'Jinja',
        'Russas',
        'La Dorada',
        'Mackay',
        'Padre Las Casas',
        'Kostiantynivka',
        'Khomeyn',
        'Cleveland',
        'Jōyō',
        'Vsevolozhsk',
        'Tocoa',
        'Jackson',
        'Mount Vernon',
        'Jingzhou',
        'Caserta',
        'Chapayevsk',
        'Paine',
        'San Martín',
        'Catanauan',
        'Spijkenisse',
        'Södertälje',
        'Centreville',
        'Hương Trà',
        'Amparo',
        'Yafran',
        'Lappeenranta',
        'Carles',
        'Cascavel',
        'Sanwal',
        'Rio do Sul',
        'Yukuhashi',
        'Sremska Mitrovica',
        'Gaspar',
        'Mysłowice',
        'Majadahonda',
        'Zhuolu',
        'Lanxi',
        'San Dionisio',
        'Belovo',
        'Esmeraldas',
        'Weligama',
        'Nepālgañj',
        'Siruguppa',
        'Tangalla',
        'Bacacay',
        'Hekinan',
        'Ede',
        'Sipalay',
        'Aschaffenburg',
        'Dazaifu',
        'Samborondón',
        'Longkoucun',
        'Alegrete',
        'Keshan',
        'Candeias',
        'Altoona',
        'Sangāreddi',
        'Benidorm',
        'Zogbodomé',
        'Maroúsi',
        'Wood Buffalo',
        'Dambulla',
        'Goya',
        'Oroquieta',
        'Virudunagar',
        'Abancay',
        'Penafiel',
        'San Fernando',
        'Palín',
        'Turbaná',
        'Yoshikawa',
        'Santo Antônio do Descoberto',
        'El Banco',
        'Warora',
        'Casa Nova',
        'Tibati',
        'Kirovo-Chepetsk',
        'Saint-Nazaire',
        'Sault Ste. Marie',
        'Camalig',
        'Najrān',
        'Belo Tsiribihina',
        'Myaydo',
        'Al Līth',
        'Bella Vista',
        'Bailongqiaocun',
        'Colón',
        'Chaklāsi',
        'Nilanga',
        'Long Mỹ',
        'Borongan',
        'San Andrés',
        'Wenping',
        'Gumaca',
        'Stara Pazova',
        'Bocholt',
        'Araranguá',
        'Esbjerg',
        'Merzifon',
        'Goianira',
        'Chiryū',
        'Čačak',
        'Quilāndi',
        'Carpi',
        'Lüdenscheid',
        'Mun’gyŏng',
        'Ishioka',
        'Jabuticabal',
        'Calauag',
        'Castro',
        'Cajamar',
        'Framingham',
        'Camden',
        'São Sebastião do Paraíso',
        'Bouaflé',
        'Patzún',
        'Georgetown',
        'Vyborg',
        'Kabarore',
        'Sambrial',
        'Piła',
        'Dondo',
        'Shrewsbury',
        'Mānikganj',
        'Baldwin Park',
        'Florida',
        'Rocklin',
        'Porterville',
        'Ágios Dimítrios',
        'Kayes',
        'Calarcá',
        'Tarīn Kōṯ',
        'Bakıxanov',
        'Kawm Umbū',
        'Mandlā',
        'Tamarac',
        'Palmeira dos Índios',
        'Ostend',
        'Saymayl',
        'Indaial',
        'La Estrella',
        'Sonsonate',
        'Pililla',
        'Santo Tirso',
        'Gosport',
        'Parang',
        'Bartın',
        'Dias d’Ávila',
        'Lomas del Mirador',
        'Nanfengcun',
        'Salamá',
        'Biankouma',
        'Lisburn',
        'Rāyadrug',
        'Mamoudzou',
        'Glen Burnie',
        'Halmstad',
        'Le Bardo',
        'Binalbagan',
        'Shahrixon',
        'Bilāra',
        'Villa Tunari',
        'Huanchaco',
        'Campo Formoso',
        'Goa',
        'Drancy',
        'Rāyagada',
        'Blacksburg',
        'Talibon',
        'Las Piedras',
        'Ganthier',
        'Verkhnyaya Pyshma',
        'Jinsha',
        'Parappanangādi',
        'Konongo',
        'Wausau',
        'Sumter',
        'Gela',
        'Placetas',
        'Janesville',
        'Melong',
        'Fernandópolis',
        'Musashimurayama',
        'Brunswick',
        'Morong',
        'San Francisco del Rincón',
        'Gibara',
        'Ratangarh',
        'Tāndūr',
        'Bakhmut',
        'Terme',
        'Mārkāpur',
        'Bunbury',
        'Sihanoukville',
        'Mauban',
        'Tejupilco',
        'Alabang',
        'Goianésia',
        'Dublin',
        'Warzat',
        'Montecristi',
        'Ayvalık',
        'Nadi',
        'Wilmington',
        'Zográfos',
        'An Nu‘mānīyah',
        'Izalco',
        'Malaut',
        'Lowestoft',
        'Waukesha',
        'Samraong',
        'Kopargo',
        'Phitsanulok',
        'Kodungallūr',
        'Corozal',
        'Neyyāttinkara',
        'Kumanovo',
        'Dondo',
        'Fairbanks',
        'Ejura',
        'Zinjibār',
        'Sesvete',
        'Érd',
        'Zadar',
        'Fatehābād',
        'Bāpatla',
        'Kalamasseri',
        'Tumauini',
        'Izmail',
        'Ostrów Wielkopolski',
        'Lakeville',
        'St. Charles',
        'Şirvan',
        'Al Qurayyā',
        'Gardēz',
        'Cremona',
        'Pavia',
        'Rugby',
        'Badvel',
        'Loulé',
        'Redondo Beach',
        'Yinying',
        'Chiang Rai',
        'Karasu',
        'Poblacion',
        'Shujaabad',
        'Stafford',
        'Vālpārai',
        'Yambol',
        'Esperanza',
        'Djemmal',
        'Chingford',
        'Cabudare',
        'Sankaranayinār Kovil',
        'Xangongo',
        'Uxbridge',
        'Zenica',
        'Chekhov',
        'Bundaberg',
        'Sūratgarh',
        'Spring Hill',
        'Tamana',
        'Kaukhāli',
        'Sig',
        'Bayonne',
        'Coari',
        'Grand Forks',
        'Baiquan',
        'Mindelo',
        'Togoch’alē',
        'Noblesville',
        'Torremolinos',
        'Būr Fu’ād',
        'Capanema',
        'Pavia',
        'Noisy-le-Grand',
        'Yawata-shimizui',
        'Linares',
        'Aliaga',
        'Orani',
        'Dandeli',
        'Santa María La Pila',
        'Minxiong',
        'Huwei',
        'Bopa',
        'Brumado',
        'Havířov',
        'Hujra Shah Muqim',
        'Şa‘dah',
        'Ban Houayxay',
        'Nāndūra Buzurg',
        'Dimbokro',
        'Rizal',
        'Tinambac',
        'Pazarcık',
        'Guaynabo',
        'Celle',
        'San Antonio',
        'Sagunto',
        'El Paso de Robles',
        'Kabirwala',
        'Thaba Nchu',
        'North Richland Hills',
        'Maple Grove',
        'Eniwa',
        'Guzhou',
        'Gaura',
        'Mineiros',
        'Pan’an',
        'Tsurugashima',
        'Grajaú',
        'Cahama',
        'Waingapu',
        'Kempten',
        'Passaic',
        'Blaine',
        'Lubin',
        'Luodong',
        'Thakhèk',
        'Castries',
        'Nansang',
        'Al ‘Āmirāt',
        'Talamba',
        'Badhan',
        'Điện Biên Phủ',
        'Phú Quốc',
        'Longtangwan',
        'Zhanggu',
        '‘Izbat al Burj',
        'Bijaynagar',
        'Satyamangalam',
        'Madhipura',
        'Kodoli',
        'Az Zubaydīyah',
        'Lake Elsinore',
        'Mansfield',
        'Raha Tiga',
        'Raha',
        'San Antonio',
        'Nikki',
        'Fulda',
        'Avaniyāpuram',
        'Rogers',
        'Entebbe',
        'Imerintsiatosika',
        'Eskilstuna',
        'Aigáleo',
        'Rohri',
        'Sagaing',
        'Casas Adobes',
        'Qingquan',
        'Saint John',
        'Farroupilha',
        'Moquegua',
        'Yueshanwan',
        'Altamura',
        'Sherman',
        'Vushtrri',
        'Encarnación',
        'Konin',
        'Novomoskovsk',
        'Kwamhlanga',
        'Ratnanagar',
        'Villa del Rosario',
        'Amstelveen',
        'Garzón',
        'San Miguel de Allende',
        'Walnut Creek',
        'Sanlúcar de Barrameda',
        'San Juan de los Lagos',
        'Los Reyes de Salgado',
        'Basavakalyān',
        'Farīdpur',
        'Kiyosu',
        'Conway',
        'Ziguinchor',
        'Minami-Alps',
        'Uwajima',
        'Roxas',
        'Rioverde',
        'Rittō',
        'Eastvale',
        'Saint-Louis du Nord',
        'Inowrocław',
        'Somasso',
        'L’Aquila',
        'Tournai',
        'Bawku',
        'Samch’ŏk',
        'Rhondda',
        'Union City',
        'Biguaçu',
        'Michigan City',
        'Thohoyandou',
        'Poptún',
        'Sōja',
        'Tripunittura',
        'Toyoake',
        'Al Qūşīyah',
        'Alenquer',
        'Victoria',
        'Aqsū',
        'Chisec',
        'Kirdāsah',
        'Poinciana',
        'Nowrangapur',
        'Welland',
        'Kars',
        'Bitola',
        'Planeta Rica',
        'Don Carlos',
        'Bafia',
        'Cawayan',
        'Tulare',
        'Anan',
        'Limonade',
        'Limbé',
        'Shangchuankou',
        'Barra do Garças',
        'Ongjang',
        'Cuímba',
        'Torbeck',
        'Fedosiia',
        'Rongcheng',
        'Gary',
        'Ad Darb',
        'Imola',
        'Necoclí',
        'Mansehra',
        'Renk',
        'Mila',
        'Mocuba',
        'Dharmasāgaram',
        'Granby',
        'Gaithersburg',
        'San Pascual',
        'Peruíbe',
        'Kireka',
        'Kamsar',
        'Ko Samui',
        'Moriya',
        'Tanabe',
        'Mocuba',
        'Mococa',
        'Piotrków Trybunalski',
        'Varisshiyakuni',
        'Korba',
        'Huishi',
        'La Paz',
        'Yitiaoshan',
        'Bagumbayan',
        'Liuhe',
        'Pālghar',
        'La Chorrera',
        'Buenavista',
        'Lippstadt',
        'East Orange',
        'San José del Guaviare',
        'Queenstown',
        'Yunnanyi',
        'Aparri',
        'Assen',
        'Ixtaczoquitlán',
        'Aalen',
        'Wesley Chapel',
        'Ponta Delgada',
        'Purísima de Bustos',
        'Arcoverde',
        'Jacona de Plancarte',
        'Pakribarawān',
        'Al Aḩmadī',
        'Suwałki',
        'Say’ūn',
        'West Des Moines',
        'Yuriria',
        'Mineral de la Reforma',
        'Indang',
        'Sabae',
        'Alamada',
        'Isnā',
        'Venâncio Aires',
        'Požarevac',
        'Kāyankulam',
        'Velsen-Zuid',
        'Dalton',
        'Dubuque',
        'Jarabacoa',
        'Parādīp Garh',
        'Quartu Sant’Elena',
        'Issy-les-Moulineaux',
        'Valle Hermoso',
        'Bouira',
        'San Leonardo',
        'Ilkal',
        'Zapotlanejo',
        'Doboj',
        'Víctor Larco Herrera',
        'Igbanke',
        'Nihtaur',
        'Schenectady',
        'Mamungan',
        'Cabo San Lucas',
        'Southampton',
        'Kladno',
        'Castelldefels',
        'Ankeny',
        'Sanza Pombo',
        'Tangub',
        'Anjangaon',
        'Maricopa',
        'Şəki',
        'Bardi̇̄bās',
        'Cergy',
        'Puerto San José',
        'Ash Shiḩr',
        'Oriximiná',
        'Adrar',
        'Eagan',
        'Tuymazy',
        'Matara',
        'Lodja',
        'St. Albert',
        'Otukpo',
        'Franklin',
        'Swedru',
        'Nghĩa Lộ',
        'Tynemouth',
        'Sipocot',
        'Tuburan',
        'Villanueva y Geltrú',
        'Hanford',
        'Miagao',
        'Xá Muteba',
        'Bristol',
        'Cuyapo',
        'Mbaké',
        'Yorba Linda',
        'Weston',
        'Watsonville',
        'Hämeenlinna',
        'Levallois-Perret',
        'Minusinsk',
        'Fort McMurray',
        'Lindong',
        'Renukūt',
        'Nābha',
        'Ixtlahuacán de los Membrillos',
        'Putrajaya',
        'Al Buraymī',
        'San Pedro Ayampuc',
        'La Barca',
        'Yanghe',
        'Palo Alto',
        'Castillejos',
        'Antalaha',
        'Kstovo',
        'Ōdate',
        'Siuri',
        'Bodītī',
        'Januária',
        'South Hill',
        'Chengjiao Chengguanzhen',
        'Conceição do Coité',
        'Formiga',
        'Ksar el Boukhari',
        'Kamuli',
        'Rājsamand',
        'Longshan',
        'Bishnupur',
        'Cannock',
        'Dinslaken',
        'Kashiwara',
        'Pesqueira',
        'Colmar',
        'Tepotzotlán',
        'Bayombong',
        'Sant’Eufemia Lamezia',
        'Apac',
        'Shashijie',
        'Pongnam',
        'İslahiye',
        'Shawnee',
        'Modāsa',
        'Santa Barbara',
        'Banepā',
        'Youssoufia',
        'Vaasa',
        'Santa Catarina Otzolotepec',
        'Heroica Caborca',
        'Molepolole',
        'Walsall',
        'Manaure',
        'Kovel',
        'Abnūb',
        'Zigon',
        'Gauravaram',
        'Bergen op Zoom',
        'San Marcos',
        'Areguá',
        'Ayolas',
        'Herford',
        'Sausar',
        'Yuquan',
        'Taman Senai',
        'Omīdīyeh',
        'Huajing',
        'Dhenkānāl',
        'Zihuatanejo',
        'Chicacao',
        'Fuefuki',
        'Urun-Islāmpur',
        'Rolândia',
        'Calais',
        'Stargard Szczeciński',
        'Gopālganj',
        'Itapema',
        'Ankazoabokely',
        'Capelle aan den IJssel',
        'Tomé-Açu',
        'Great Falls',
        'Cuilco',
        'Tôlan̈aro',
        'Lala Musa',
        'Rüsselsheim',
        'Haverhill',
        'Āsela',
        'Sousa',
        'Union City',
        'Séguéla',
        'Shiojiri',
        'Kerpen',
        'Palatine',
        'Longview',
        'Corvallis',
        'Bay',
        'Tabatinga',
        'Wanggezhuang',
        'Washington',
        'Lushar',
        'Rockville',
        'Néa Ionía',
        'Zrenjanin',
        'Szolnok',
        'Old Bridge',
        'Dolores Hidalgo Cuna de la Independencia Nacional',
        'Liepāja',
        'Sa’ada',
        'Skokie',
        'Pikit',
        'Dhārāpuram',
        'Guruvāyūr',
        'Kashima',
        'Cedeño',
        'Jagoniguda',
        'Mīrpeta',
        'Nizhyn',
        'Lupon',
        'Phalodi',
        'Embu-Guaçu',
        'Târgovişte',
        'Middletown',
        'Nueva Guinea',
        'Acilia',
        'Veenendaal',
        'Mount Vernon',
        'Kati',
        'Güines',
        'Beypore',
        'Casper',
        'El Seibo',
        'Grays',
        'Bongabon',
        'Kissidougou',
        'Cosmópolis',
        'Janaúba',
        'Āksum',
        'Çumra',
        'Janiuay',
        'Calimera',
        'Godalming',
        'Pessac',
        'San Mateo Atenco',
        'Botolan',
        'Siddipet',
        'Bulancak',
        'Ilidža',
        'Ames',
        'Rosales',
        'Hihyā',
        'Hīt',
        'Kraljevo',
        'Bolgatanga',
        'Chiyoda-ku',
        'Viladecáns',
        'Karlskrona',
        'Karimama',
        'La Carlota',
        'San Mateo',
        'Focşani',
        'Delray Beach',
        'Nālūt',
        'Katwijk',
        'Sammamish',
        'Walton upon Thames',
        'Ramos Arizpe',
        'Aflao',
        'Karakax',
        'Novi Pazar',
        'Cabedelo',
        'Koratla',
        'Saiki',
        'Weiyuan',
        'Damba',
        'Georgiyevsk',
        'Yachimata',
        'Zacapa',
        'Cuscatancingo',
        'Guangping',
        'Vénissieux',
        'Urla',
        'Lynwood',
        'Hasanpur',
        'Opol',
        'Dundalk',
        'Bethesda',
        'Hashima',
        'Slavyansk-na-Kubani',
        'Växjö',
        'Huquan',
        'Zanhuang',
        'Morristown',
        'Reghaïa',
        'Kampung Tengah',
        'Bengkalis',
        'Virginia',
        'Juana Díaz',
        'Sahaswān',
        'Ocoyoacac',
        'Belogorsk',
        'Arāmbāgh',
        'Kazanlak',
        'Pidugurālla',
        'Massa',
        'Vidnoye',
        'Cazin',
        'Lençóis Paulista',
        'Tsuruga',
        'Ilo',
        'Oberá',
        'Genk',
        'Goz-Beida',
        'Chibok',
        'Ban Suan',
        'Oas',
        'Kankakee',
        'Dangbo',
        'Moita',
        'Agoo',
        'Fajardo',
        'Torreón',
        'El Carmen de Bolívar',
        'Madirovalo',
        'Taman Johor Jaya',
        'Puliyankudi',
        'Stupino',
        'Neuwied',
        'Lantapan',
        'Asenovgrad',
        'Viterbo',
        'Anamur',
        'Lahat',
        'Itapetinga',
        'Alpharetta',
        'Wilde',
        'Tatabánya',
        'Novi',
        'Martínez',
        'Kavála',
        'Karlstad',
        'Coron',
        'Roxas',
        'Zacatecoluca',
        'Finchley',
        'Thornton Heath',
        'Gloucester',
        'Sangamner',
        'Chegutu',
        'Kenner',
        'Kiamba',
        'Fukutsu',
        'Wamena',
        'San Remigio',
        'Gohna',
        'Pulivendla',
        'Bay City',
        'Sakrand',
        'Santo Tomé',
        'Smila',
        'Ina',
        'Collado-Villalba',
        'Victoria',
        'Surubim',
        'Menzel Temime',
        'Lutayan',
        'Tulcea',
        'Arima',
        'Weimar',
        'Angat',
        'Qiryat Gat',
        'Kirtipur',
        'Istaravshan',
        'Rio Negro',
        'South San Francisco',
        'Barreirinhas',
        'Qarqan',
        'Bom Jesus da Lapa',
        'Apex',
        'Parkersburg',
        'Xarardheere',
        'San Francisco El Alto',
        'El Prat de Llobregat',
        'San Antonio Suchitepéquez',
        'Kānhangād',
        'Xiantangcun',
        'Al Minshāh',
        'Beloretsk',
        'Los Amates',
        'Xieqiaocun',
        'Jaisalmer',
        'São Tomé',
        'Singaparna',
        'Malden',
        'Kurabūr',
        'Aïn Defla',
        'Gniezno',
        'Piripiri',
        'Castro Valley',
        'Narok',
        'Rechytsa',
        'Ishimbay',
        'São Félix do Xingu',
        'Ārān Bīdgol',
        'Bāqershahr',
        'Giddarbāha',
        'Täby',
        'Purulhá',
        'Develi',
        'Villa Curuguaty',
        'Tamlūk',
        'Hadjout',
        'Jamjamāl',
        'Sinnar',
        'Vaijāpur',
        'Jocotitlán',
        'Solano',
        'Kungur',
        'Quezon',
        'Pruszków',
        'Bozeman',
        'Birnin Konni',
        'Tigbauan',
        'Ouricuri',
        'Torquay',
        'Jagraon',
        'Alīpur Duār',
        'Pateros',
        'Dhūri',
        'Xibang',
        'Ḩalabjah',
        'Kitamoto',
        'Guider',
        'Zarechnyy',
        'Dormagen',
        'Ishim',
        'San Jose',
        'Villach',
        'Buynaksk',
        'Estância',
        'Itaberaba',
        'Rāth',
        'Villasis',
        'Zeist',
        'Mecheria',
        'Brentwood',
        'Farnborough',
        'Jiquílpan de Juárez',
        'Popondetta',
        'Saidu Sharif',
        'Bāzār-e Yakāwlang',
        'Patuakhāli',
        'Sindelfingen',
        'Matamey',
        'Embu',
        'Caripito',
        'Sumbawa Besar',
        'Bucak',
        'Maasin',
        'Keitumkawn',
        'Autlán de Navarro',
        'Busia',
        'Ioánnina',
        'La Ceja',
        'Kolonnawa',
        'Coyula',
        'Chiquimulilla',
        'Gukovo',
        'Don Torcuato',
        'Nagcarlan',
        'Palaió Fáliro',
        'Ladysmith',
        'Tomigusuku',
        'Clichy',
        'Igarapé-Miri',
        'Bordj Menaïel',
        'Gwacheon',
        'Sabinas',
        'Acaraú',
        'Ostrowiec Świętokrzyski',
        'Soloma',
        'Plauen',
        'As Suwaydā’',
        'Chipindo',
        'Waltham',
        'Faīẕābād',
        'Sangzishi',
        'Seydişehir',
        'Oued Rhiou',
        'Kensington',
        'Siemianowice Śląskie',
        'Meulaboh',
        'Marinilla',
        'Gerli',
        'Santo Domingo Tehuantepec',
        'Binga',
        'Fredericton',
        'Boston',
        'Grevenbroich',
        'Lechang',
        'Faro',
        'Pirané',
        'Narapalli',
        'Fujioka',
        'Ţūlkarm',
        'Pflugerville',
        'Isabela',
        'Hilongos',
        'Arrecife',
        'Pālitāna',
        'Roeselare',
        'Valence',
        'Rivera',
        'Rahat',
        'Itamaraju',
        'North Little Rock',
        'Al Qurayn',
        'Dharmapuri',
        'Apaseo el Alto',
        'Brentwood',
        'Sokhumi',
        'Idappādi',
        'Hiriyūr',
        'Porto Nacional',
        'Paignton',
        'Potenza',
        'Rosenheim',
        'Chunian',
        'Malvar',
        'Santiago Tianguistenco',
        'Martil',
        'Waterlooville',
        'Sirsi',
        'Sibulan',
        'Póvoa de Varzim',
        'Ash Shaykh Zuwayd',
        'Chervonohrad',
        'Saravia',
        'Seraing',
        'Głogów',
        'Atimonan',
        'Laguna Niguel',
        'Ambarawa',
        'Misantla',
        'Kodād',
        'San Clemente',
        'Alba Iulia',
        'Sangmélima',
        'Ciénaga de Oro',
        'Bertioga',
        'Vacaria',
        'Qinggang',
        'Ghardimaou',
        'Seinäjoki',
        'Tādepalle',
        'Grande Prairie',
        'Welkom',
        'Qal‘ah-ye Now',
        'San Felipe',
        'Yanagawa',
        'Niksar',
        'Pomezia',
        'Siuna',
        'Asbest',
        'Burnsville',
        'Teresa',
        'Randers',
        'Armūr',
        'Simav',
        'Ivry-sur-Seine',
        'Horad Zhodzina',
        'Guiseley',
        'Neubrandenburg',
        'Sodegaura',
        'Tiquisate',
        'Tuao',
        'Bankra',
        'Nawalgarh',
        'Spring',
        'Tupã',
        'Port Charlotte',
        'Camocim',
        'Ferrol',
        'Bognor Regis',
        'Tohāna',
        'Nieuwegein',
        'Most',
        'Penedo',
        'Santa Cruz',
        'Inhambane',
        'Songkhla',
        'Sibalom',
        'Trnava',
        'Kangbao',
        'La Línea de la Concepción',
        'Khagaul',
        'Tenri',
        'Daljā',
        'Ponferrada',
        'Magong',
        'Zarand',
        'Odendaalsrus',
        'Guamúchil',
        'Chakpi Karong',
        'Sundapālaiyam',
        'Fraijanes',
        'Bourges',
        'Jatani',
        'Chokwé',
        'Kurihara',
        'Zvornik',
        'Nawá',
        'Chincha Alta',
        'Jose Pañganiban',
        'Chik Ballāpur',
        'Guiglo',
        'Quimper',
        'Donskoy',
        'Athni',
        'Eden Prairie',
        'Dédougou',
        'Fredrikstad',
        'Paphos',
        'Capão da Canoa',
        'Hornchurch',
        'Assi Bou Nif',
        'Maidenhead',
        'Greenwood',
        'Yangqingcun',
        'Benevides',
        'Lower Merion',
        'At Tall',
        'Midoun',
        'Millcreek',
        'Yattir',
        'Asahi',
        'Slatina',
        'Bhairāhawā',
        'Badr Ḩunayn',
        'Khulayş',
        'Korydallós',
        'Pokrovsk',
        'Rundu',
        'Tajumulco',
        'Akurana',
        'Mitcham',
        'Medicine Hat',
        'Pervomaisk',
        'Feltham',
        'Itō',
        'Dhuburi',
        'Fujiidera',
        'Mirassol',
        'Bellevue',
        'Vittoria',
        'Prilep',
        'Stourbridge',
        'Coon Rapids',
        'Rowlett',
        'Mercedes',
        'Galgamuwa',
        'Ban Lam Sam Kaeo',
        'Abū Qurqāş',
        'Kitale',
        'Cajazeiras',
        'Ouled Djellal',
        'Antony',
        'Banda del Río Salí',
        'Gorno-Altaysk',
        'Volsk',
        'San Marcos',
        'Anuradhapura',
        'Svyetlahorsk',
        'Tartagal',
        'Lugano',
        'Gadwāl',
        'Tetovo',
        'Horsens',
        'Rockhampton',
        'Hamilton',
        'Lakewood',
        'Qurayyāt',
        'Florence-Graham',
        'Teoloyucan',
        'Sīdī Barānī',
        'Granollers',
        'Novaya Balakhna',
        'Nankana Sahib',
        'Visnagar',
        'Puerto Asís',
        'Nago',
        'Commerce City',
        'San Fernando',
        'Vaslui',
        'Pāmūru',
        'Çaldıran',
        'Kasongo',
        'Kaga',
        'Rochester',
        'Batatais',
        'Bossier City',
        'Miyoshi',
        'Pabianice',
        'Halton Hills',
        'Beledweyne',
        'Dewsbury',
        'Taylor',
        'Klintsy',
        'Friedrichshafen',
        'Sheikhpura',
        'Narlıdere',
        'Marina di Carrara',
        'Irún',
        'Māhdāsht',
        'La Habra',
        'Yurimaguas',
        'Campo Bom',
        'Balboa Heights',
        'Sirsi',
        'Wāshīm',
        'Calinog',
        'Mut',
        'Port Orange',
        'Palmela',
        'Rossosh',
        'Moncada',
        'Balad',
        'Itupeva',
        'Gampaha',
        'Woking',
        'Troyes',
        'Nānpāra',
        'Tân Phước Khánh',
        'Castellammare di Stabia',
        'La Seyne-sur-Mer',
        'Bansalan',
        'Champasak',
        'Bāri',
        'Pavlovskiy Posad',
        'Lingshou',
        'Qoryooley',
        'Gusang',
        'Brookline',
        'Revda',
        'Moore',
        'Koro',
        'Council Bluffs',
        'Tandag',
        'Boadilla del Monte',
        'Icó',
        'Carmen de Viboral',
        'Kapatagan',
        'Bensalem',
        'Anse à Galets',
        'Leninogorsk',
        'Sittingbourne',
        'Leander',
        'Acton',
        'Dearborn Heights',
        'Herten',
        'Nagakute',
        'Kilis',
        'Mobārakeh',
        'Portel',
        'Aïn Harrouda',
        'Rovaniemi',
        'Wenxicun',
        'Bergheim',
        'Berekum',
        'Rānipet',
        'Ambilobe',
        'Wundanyi',
        'Reston',
        'Kolding',
        'Schwäbisch Gmünd',
        'Kesamudram',
        'Villa Domínico',
        'Ras Tanura',
        'Puerto Peñasco',
        'Bainet',
        'Riosucio',
        'Shangtangcun',
        'Boryspil',
        'Tuapse',
        'Caràzinho',
        'Kolda',
        'Nagari',
        'Cristalina',
        'Zelenogorsk',
        'Napier',
        'Sherkot',
        'Tighenif',
        'Santarém',
        'Asaka',
        'Camaquã',
        'Panchari Bazar',
        'Bannu',
        'Cyuve',
        'Boituva',
        'Rāghogarh',
        'Chidambaram',
        'Twickenham',
        'Agía Paraskeví',
        'Analavory',
        'Figueira da Foz',
        'Aurora',
        'Narwāna',
        'Vigevano',
        'North Bergen',
        'Tríkala',
        'Cabaret',
        'Aurora',
        'Montebello',
        'Okha',
        'Zaraza',
        'Nova Odessa',
        'Meshkīn Dasht',
        'Naro-Fominsk',
        'San Francisco',
        'Viedma',
        'Sihushan',
        'Bhabhua',
        'Kōshizuka',
        'Manicaragua',
        'Rouiba',
        'Tendō',
        'Borj el Qoblé',
        'Pontiac',
        'Arua',
        'Nanjangūd',
        'Camotán',
        'Mongaguá',
        'Encinitas',
        'Tagajō',
        'Yongbei',
        'Menzel Bourguiba',
        'Montauban',
        'Rayong',
        'Cambanugoy',
        'Sätbayev',
        'Zengqiao',
        'Siddhapur',
        'Plato',
        'Polevskoy',
        'Mitoyo',
        'Derik',
        'Żory',
        'Kotlas',
        'São Miguel dos Campos',
        'Leszno',
        'Runcorn',
        'Nakhyaungcharīpāra',
        'Queen Creek',
        'Nkawkaw',
        'Camabatela',
        'Borisoglebsk',
        'Hita',
        'Lysva',
        'Saraburi',
        'Medford',
        'Springfield',
        'Morada Nova',
        'Naj‘ Ḩammādī',
        'Jhārgrām',
        'Afragola',
        'Santo Amaro',
        'Yangiyŭl',
        'Bezerros',
        'Sungo',
        'Penápolis',
        'Kapchagay',
        'Shiroi',
        'Offenburg',
        'Xiluodu',
        'Cortazar',
        'Praia',
        'Kolea',
        'San Juan Chamelco',
        'San Antonio del Táchira',
        'Plymouth',
        'Karonga',
        'Wrecsam',
        'Sayyid Şādiq',
        'Valle de Bravo',
        'Hendersonville',
        'Palm Harbor',
        'Hato Mayor',
        'Extremoz',
        'Pico Rivera',
        'Cayenne',
        'May Pen',
        'Santa Ana',
        'Výronas',
        'Asker',
        'Ostuncalco',
        'Ilobasco',
        'Itogon',
        'Port Coquitlam',
        'Korosten',
        'Baao',
        'La Democracia',
        'Uzunköprü',
        'Hasuda',
        'Widnes',
        'Taal',
        'Djibo',
        'Euclides da Cunha',
        'Xinmin',
        'Rāmanāthapuram',
        'Kozluk',
        'Candon',
        'Sawahlunto',
        'Gia Nghĩa',
        'Marietta',
        'Hatogaya-honchō',
        'Tunasan',
        'Wellington',
        'Garbsen',
        'Wesel',
        'Budënnovsk',
        'Vejle',
        'Tarnowskie Góry',
        'Trikonavattam',
        'Santa Catarina Ixtahuacán',
        'Sanford',
        'Kolomyia',
        'Sibay',
        'Yala',
        'Salgueiro',
        'Halvad',
        'Mateare',
        'Wels',
        'Barbalha',
        'Woodland',
        'Tauá',
        'Margate',
        'Abuyog',
        'Caldwell',
        'Coyhaique',
        'Maués',
        'Huntersville',
        'Bocaranga',
        'Ar Rastan',
        'Caicó',
        'Udamalpet',
        'Cabatuan',
        'Mirabel',
        'Santo Domingo',
        'Ellesmere Port',
        'Santa Rosa de Copán',
        'Barberena',
        'Velika Gorica',
        'Hashimoto',
        'Idiofa',
        'Bristol',
        'Olbia',
        'Neu-Ulm',
        'Nordre Fåle',
        'La Plata',
        'Ogōshi',
        'Bangor',
        'Iranduba',
        'Nekā',
        'Tulunan',
        'Hürth',
        'Adjohon',
        'Wanparti',
        'Jupiter',
        'Tafo',
        'Aisai',
        'Tsushima',
        'Itoman',
        'Soroti',
        'Unna',
        'San Rafael',
        'La Mesa',
        'Nambuangongo',
        'Los Polvorines',
        'Richland',
        'Jinoba-an',
        'Łomża',
        'Tamba',
        'Chāmrājnagar',
        'Fort Portal',
        'Pantin',
        'Burhaniye',
        'Meihuacun',
        'Yamatotakada',
        'Bethal',
        'Orion',
        'Artemisa',
        'Sarandí',
        'Kresek',
        'El Wak',
        'Villamaría',
        'Shihe',
        'Puerto Limón',
        'Calulo',
        'Galapa',
        'Cubulco',
        'Mbabane',
        'Oyem',
        'Kyustendil',
        'Huatusco',
        'Castilla',
        'Laoang',
        'Oda',
        'Ibitinga',
        'Kukarmunda',
        'Kokawa',
        'Trang',
        'Viareggio',
        'Revere',
        'Trujillo',
        'Yongyang',
        'Meriden',
        'Tigaon',
        'Matanao',
        'Atotonilco el Alto',
        'Taunton',
        'Dumraon',
        'Piscataway',
        'Fryazino',
        'Itapecuru Mirim',
        'Ełk',
        'Monterey Park',
        'Gardena',
        'Slutsk',
        'Vratsa',
        'Euless',
        'Cruz das Almas',
        'Altınözü',
        'Bor',
        'Panruti',
        'Lalmanirhat',
        'Ambanja',
        'Ciudad Arce',
        'Velampālaiyam',
        'Laeken',
        'Gubat',
        'Wallasey',
        'Yisuhe',
        'Rosario',
        'Irvington',
        'Belladère',
        'Mizusawa',
        'Clay',
        'Tirukkoyilūr',
        'San Mateo Ixtatán',
        'Parkent',
        'Paracale',
        'Yame',
        'Qoorlugud',
        'Belebey',
        'Rāzampeta',
        'Kangān',
        'Chistopol',
        'Kumertau',
        'Labinsk',
        'Nedumangād',
        'Berrouaghia',
        'Zamość',
        'Pasaje',
        'San Mariano',
        'Nioro',
        'Ābyek',
        'Samut Sakhon',
        'Mbalmayo',
        'Des Plaines',
        'Jinxing',
        'Malay',
        'Souq Sebt Oulad Nemma',
        'Towada',
        'Pirojpur',
        'Manhiça',
        'Horqueta',
        'Floriano',
        'São Borja',
        'Monte Alegre',
        'Sarāvān',
        'Tyre',
        'Faruka',
        'Buqda Caqable',
        'Oodweyne',
        'Güigüe',
        'Hurlingham',
        'Kribi',
        'Suifenhe',
        'Consuelito',
        'Baba Hassen',
        'Meskiana',
        'Afula',
        'Nahariyya',
        'Gunupur',
        'Mehnājpur',
        'Bayjī',
        'Banane',
        'Kakuma',
        'Kratie',
        'Barneveld',
        'Hamburg',
        'Obando',
        'San Marcos',
        'Çivril',
        'Bābolsar',
        'Union',
        'Thatri',
        'Urus-Martan',
        'Rubizhne',
        'Registro',
        'Chandralapādu',
        'Legnano',
        'Loughborough',
        'San Vicente del Raspeig',
        'Ponnūru',
        'West Allis',
        'Carrara',
        'Aïn Touta',
        'Arankhola',
        'Sigaboy',
        'Kathua',
        'Chambéry',
        'Ilog',
        'North Miami',
        'Özalp',
        'St. Cloud',
        'The Hammocks',
        'Escada',
        'Aranjuez',
        'Blainville',
        'North Lakhimpur',
        'Fano',
        'Andradina',
        'Langenfeld',
        'Skhirate',
        'Dongcun',
        'Euskirchen',
        'Ragay',
        'Khartsyzk',
        'Cupertino',
        'Užice',
        'Lakhdaria',
        'Taylorsville',
        'Vinukonda',
        'Alexandroúpoli',
        'Suharekë',
        'Huehuetoca',
        'Viçosa do Ceará',
        'Gohadi',
        'Kananya',
        'Greifswald',
        'Khagaria',
        'Hardenberg',
        'Matera',
        'Petaluma',
        'Bougouni',
        'Karamürsel',
        'Huajiang',
        'Sanyō-Onoda',
        'Aguacatán',
        'Sennan',
        'Guerra',
        'Date',
        'Pāchora',
        'Ouezzane',
        'Medellin',
        'Maimbung',
        'Lianzhuangcun',
        'Coroatá',
        'Limoeiro do Norte',
        'Chełm',
        'Tiptūr',
        'Altamira',
        'Kokomo',
        'Gopichettipālaiyam',
        'Givatayim',
        'Santee',
        'Alcoy',
        'Itānagar',
        'Esfarāyen',
        'Xo‘jayli Shahri',
        'Hakkari',
        'Mérida',
        'Wangzhuang',
        'Stonecrest',
        'Taunton',
        'Rzhev',
        'White Plains',
        'Montenegro',
        'Yara',
        'Shimotsuke',
        'Druzhkivka',
        'Shirakawa',
        'Tomaszów Mazowiecki',
        'Los Andes',
        'Ouinhri',
        'Morón',
        'Hua Hin',
        'Stralsund',
        'Esperanza',
        'Koga',
        'Aş Şaff',
        'Galátsi',
        'Kesennuma',
        'Garín',
        'Ruhengeri',
        'Aleksandrov',
        'Jōsō',
        'Niort',
        'Alfonso',
        'Zhoujiajing',
        'Gaibandha',
        'União dos Palmares',
        'Hyūga',
        'Dhone',
        'Irosin',
        'Neuilly-sur-Seine',
        'Sérres',
        'Zamora',
        'Irati',
        'Gannan',
        'San Francisco',
        'Trollhättan',
        'Rājgarh',
        'Kateríni',
        'San Simon',
        'Chalkída',
        'Mansalay',
        'Dāmghān',
        'Chichibu',
        'Umm al Qaywayn',
        'Antigua Guatemala',
        'Panna',
        'Palm Beach Gardens',
        'Barking',
        'Chivacoa',
        'Göppingen',
        'Constanza',
        'Saint-Louis du Sud',
        'Yevlax',
        'Mossel Bay',
        'El Palomar',
        'Tahara',
        'Upi',
        'Metapán',
        'Florida',
        'Jiguaní',
        'Petroúpoli',
        'Huauchinango',
        'Kattaqo’rg’on Shahri',
        'Anzio',
        'Motril',
        'Nirgua',
        'Chapel Hill',
        'Santa María Chiquimula',
        'Cruz Alta',
        'Cerro de Pasco',
        'Xikeng',
        'Lac-Brome',
        'Andoharanofotsy',
        'Gattaran',
        'Carvajal',
        'Parobé',
        'Sidi ech Chahmi',
        'Zahirābād',
        'Scheveningen',
        'Roxas',
        'Wani',
        'Binnāguri',
        'Ban Rangsit',
        'Canlaon',
        'Kalamáta',
        'Beccar',
        'Jackson',
        'Manouba',
        'Upleta',
        'El Salvador',
        'Narasapur',
        'Xánthi',
        'Chikuma',
        'Hoboken',
        'Kruševac',
        'Pedro Leopoldo',
        'Parker',
        'Jaguariúna',
        'Blue Springs',
        'Calatagan',
        'Baganga',
        'Faenza',
        'Viseu',
        'Izberbash',
        'Jovellanos',
        'Shoreline',
        'Dosso',
        'Baguinéda',
        'Koja',
        'St. Clair Shores',
        'Kasungu',
        'Sonabedha',
        'Pasrur',
        'Wuyang',
        'Raharpur',
        'Edgware',
        'Xinfeng',
        'Alta Floresta',
        'Lytkarino',
        'Kaş',
        'Mpondwe',
        'Tenkodogo',
        'Horizon West',
        'Ōsakasayama',
        'Frutal',
        'Pipariā',
        'San Luis',
        'Caltanissetta',
        'Gllogovc',
        'Una',
        'Balancán',
        'Ibaan',
        'Kélibia',
        'Sardhana',
        'São Gabriel',
        'Hastināpur',
        'Crotone',
        'Ma’erkang',
        'San Pedro',
        'Tianningcun',
        'Liannong',
        'Liantangcun',
        'Sarcelles',
        'Benevento',
        'Qaskeleng',
        'Trairi',
        'Przemyśl',
        'Margate',
        'Heerhugowaard',
        'San Fernando',
        'Littlehampton',
        'Sandīla',
        'Orland Park',
        'Pebane',
        'Zengcun',
        'Nyagan',
        'Acerra',
        'Ogōri',
        'Limerick',
        'Punta Alta',
        'Palompon',
        'Abington',
        'Tiruvālūr',
        'Sucun',
        'Murakami',
        'Nanjian',
        'Capenda Camulemba',
        'Ishikari',
        'Antsalova',
        'Lambayeque',
        'Doetinchem',
        'Le Blanc-Mesnil',
        'Pursat',
        'Carson City',
        'Rass el Djebel',
        'Mouscron',
        'Frankfurt (Oder)',
        'Sillod',
        'Ruislip',
        'Mielec',
        'Călăraşi',
        'Savona',
        'Naval',
        'Barotac Nuevo',
        'Temascalcingo',
        'Samaná',
        'Chivilcoy',
        'Tikhvin',
        'Halesowen',
        'Kitahiroshima',
        'Midwest City',
        'Mulbāgal',
        'North Vancouver',
        'Rouissat',
        'Bakwa-Kalonji',
        'Dapaong',
        'Maisons-Alfort',
        'Felgueiras',
        'Streatham',
        'Royal Oak',
        'Timargara',
        'Ambahikily',
        'Chibuto',
        'Meleuz',
        'Masantol',
        'Tczew',
        'Pradera',
        'Chornomorsk',
        'Santa Isabel',
        'Kawthoung',
        'Hunsūr',
        'Pānskura',
        'Nanjakkād',
        'Bowie',
        'Kan’onjichō',
        'New Corella',
        'Zempoala',
        'Hameln',
        'Kolondiéba',
        'Allanmyo',
        'Cogan',
        'Aleksin',
        'Berëzovskiy',
        'Prokhladnyy',
        'Oued Lill',
        'Nausori',
        'Glew',
        'Lorient',
        'Rāmpur Hat',
        'Mikhaylovka',
        'Dumanjog',
        'Asingan',
        'Sidi Yahya Zaer',
        'Suileng',
        'Marano di Napoli',
        'Orito',
        'Xiezhou',
        'Royal Tunbridge Wells',
        'Tikhoretsk',
        'Živinice',
        'Villejuif',
        'Ávila',
        'El Attaf',
        'Hervey Bay',
        'Kettering',
        'Bellevue',
        'Khandāla',
        'Lonāvale',
        'St. Peters',
        'Kosai',
        'General Pico',
        'Oak Lawn',
        'Mogoditshane',
        'Grand-Popo',
        'Pavlovo',
        'Salsk',
        'Stalowa Wola',
        'Gengzhuangqiaocun',
        'Towson',
        'Jerez de García Salinas',
        'Yanguancun',
        'New Plymouth',
        'Camajuaní',
        'Taquara',
        'Bilimora',
        'Kothāpet',
        'Coconut Creek',
        'Maduraivayal',
        'Tōgane',
        'Sīra',
        'Monte Plata',
        'Lucan',
        'Diriamba',
        'Milagros',
        'Choshi',
        'Santa Maria',
        'Decatur',
        'Krasnotur’insk',
        'Palāsa',
        'Tokoname',
        'Ejmiatsin',
        'Lenexa',
        'Wiwilí',
        'Guarabira',
        'Bartlett',
        'Humaitá',
        'Santiago Tuxtla',
        'Cosquín',
        'Tanauan',
        'Chintalapalli',
        'Meerbusch',
        'Lohārdagā',
        'Buluan',
        'Sinop',
        'Cuvelai',
        'Ponte Nova',
        'Richards Bay',
        'Acará',
        'South Whittier',
        'Foumbot',
        'Antsinanantsena',
        'Huaniu',
        'Qianwu',
        'Nacaome',
        'Bebington',
        'Molfetta',
        'Tieshansi',
        'Eldorado',
        'Mali',
        'Roermond',
        'Weymouth',
        'Coruripe',
        'Lake Havasu City',
        'Mācherla',
        'Boac',
        'Gümüşhane',
        'Bīmgal',
        'Nova Esperança',
        'Içara',
        'Kandukūr',
        'Tres Arroyos',
        'Saint-Hyacinthe',
        'Keonjhargarh',
        'Krymsk',
        'Riosucio',
        'Villa Hayes',
        'Aldershot',
        'Bilhorod-Dnistrovskyi',
        'Cupang',
        'Alangalang',
        'Samannūd',
        'Belize City',
        'Bulungu',
        'Uson',
        'Boufarik',
        'Ping’an',
        'Kollegāl',
        'Cosamaloapan',
        'Sarapiquí',
        'Moknine',
        'Bel Air South',
        'Unjha',
        'Benslimane',
        'Uki',
        'Nonoichi',
        'Moramanga',
        'Fréjus',
        'Borūjen',
        'Devrek',
        'Maun',
        'Fountainebleau',
        'Wylie',
        'Mafeteng',
        'Bail-Hongal',
        'Jasaan',
        'Shuya',
        'Dehdasht',
        'Semara',
        'Alvarado',
        'Baden-Baden',
        'Lunglei',
        'Neryungri',
        'Minokamo',
        'Ipele',
        'Bura',
        'Zushi',
        'Talagante',
        'Rîbniţa',
        'Nasīrabād',
        'Cerignola',
        'Ródos',
        'Madison',
        'Cuemba',
        'Tezonapa',
        'Ushuaia',
        'Ban Bang Kaeo',
        'Cachoeiras de Macacu',
        'Machang',
        'Huaixiangcun',
        'San Luis',
        'Kędzierzyn-Koźle',
        'Puerto Boyacá',
        'Azzaba',
        'Hagonoy',
        'Mora',
        'Xam Nua',
        'Limbang',
        'Dina',
        'Gūdalur',
        'Ipirá',
        'Caacupé',
        'Miragoâne',
        'Sāmalkot',
        'Karīmganj',
        'Brookhaven',
        'Acambay',
        'Santa Rosa',
        'Pinagkaisahan',
        'Bobbili',
        'Irpin',
        'Bebandem',
        'Chinhoyi',
        'Highland',
        'Fussa',
        'Fountain Valley',
        'Bowmanville',
        'Düziçi',
        'Kālna',
        'Sattenapalle',
        'Tulcán',
        'Al Hoceïma',
        'Lagonoy',
        'Basey',
        'Beauvais',
        'Kudamatsu',
        'Pau d’Alho',
        'Hod HaSharon',
        'Pirapora',
        'Barabai',
        'Muğla',
        'Vrindāvan',
        'Chini',
        'Iskitim',
        'Acámbaro',
        'Diglipur',
        'Khāsh',
        'Beni Enzar',
        'Den Helder',
        'Forest',
        'Macclesfield',
        'Pangantocan',
        'Görlitz',
        'Wellingborough',
        'Tāybād',
        'Mafra',
        'Berwyn',
        'Ar Riqqah',
        'San Pedro Sacatepéquez',
        'Lingen',
        'Ramon',
        'Ixtahuacán',
        'Limoeiro',
        'Porto Feliz',
        'Naugaon Sādāt',
        'Colomba',
        'Sendhwa',
        'Athiémé',
        'El Cerrito',
        'Bartolomé Masó',
        'Stolberg',
        'Moyobamba',
        'Bianyang',
        'Tiwi',
        'Severn',
        'Talegaon Dābhāde',
        'Tama',
        'Rafael Calzada',
        'Wote',
        'Hassa',
        'Rolim de Moura',
        'Mocoa',
        'Boudouaou',
        'Narbonne',
        'Villarrica',
        'Binalonan',
        'Longxing',
        'Sankt Augustin',
        'Sucat',
        'Albany',
        'National City',
        'Placer',
        'Paços de Ferreira',
        'Rosh Ha‘Ayin',
        'Korkuteli',
        'Lian',
        'Narammala',
        'Libungan',
        'Amarante',
        'Magsaysay',
        'Lacey',
        'Bihać',
        'Pèlèngana',
        'Esquipulas',
        'Kūt-e ‘Abdollāh',
        'Klimovsk',
        'Poonamallee',
        'Sarikei',
        'M’diq',
        'Shali',
        'Kettering',
        'Gangārāmpur',
        'Rahovec',
        'Oosterhout',
        'Hohoe',
        'Mount Prospect',
        'Arcadia',
        'Dongola',
        'Mota',
        'Mukōchō',
        'Khlong Luang',
        'Tilakpur',
        'Moncalieri',
        'Castelo Branco',
        'Mizuho',
        'Diffun',
        'Cuizhuangzi',
        'Waiblingen',
        'Tiruvallūr',
        'Mandapeta',
        'San Andrés Villa Seca',
        'Tirur',
        'Mendi',
        'Takizawa',
        'Kimilili',
        'Uman',
        'Eschweiler',
        'Bonga',
        'Pithorāgarh',
        'Kengri',
        'Huaquillas',
        'Myaungmya',
        'Intibucá',
        'Bluefields',
        'Oke-Mesi',
        'Langtang',
        'Ropar',
        'Parsippany-Troy Hills',
        'Abū Za‘bal',
        'Chiapa de Corzo',
        'Lower Bicutan',
        'General Tinio',
        'Takaishi',
        'Acul du Nord',
        'Ajodhya',
        'DeSoto',
        'Poblacion',
        'Smyrna',
        'Bungoma',
        'Longmen',
        'Khān Shaykhūn',
        'Changling',
        'Belampalli',
        'Kharar',
        'Viramgām',
        'Zarrīn Shahr',
        'Sidi Bennour',
        'Hilden',
        'Bradenton',
        'Braintree',
        'Bambang',
        'Biswān',
        'Tsévié',
        'Union',
        'Rengo',
        'Gulariyā',
        'Cuneo',
        'Umm Ruwaba',
        'Kannamangalam',
        'Royal Leamington Spa',
        'Lesosibirsk',
        'Azul',
        'Atchampeta',
        'Oulad Yaïch',
        'New Brunswick',
        'Apatity',
        'Nōgata',
        'Asadābād',
        'Tursunzoda',
        'Paombong',
        'Będzin',
        'Zile',
        'Dzerzhinskiy',
        'Nawucun',
        'Siliancun',
        'Hashtgerd',
        'Kāndi',
        'Nueva Loja',
        'Meaux',
        'Portimão',
        'Hoogeveen',
        'Nyenga',
        'Guliston',
        'Yongqing',
        'Apple Valley',
        'Iba',
        'Mulanay',
        'Kuala Kapuas',
        'Tinley Park',
        'Finote Selam',
        'Hà Giang',
        'Trapani',
        'Urbiztondo',
        'Saravena',
        'Nuevo Casas Grandes',
        'Buthidaung',
        'Wellington',
        'Raxaul',
        'Pulheim',
        'Kidderminster',
        'Shendi',
        'Sankt Pölten',
        'Opava',
        'Agrigento',
        'Chystiakove',
        'San Agustín Acasaguastlán',
        'Sarasota',
        'Barwāni',
        'Porto Alexandre',
        'Barrow in Furness',
        'Batac',
        'Villarrica',
        'Videira',
        'Qiryat Ata',
        'Hacienda Heights',
        'Chicopee',
        'Nansan',
        'Tonekābon',
        'Zalaegerszeg',
        'Biała Podlaska',
        'Xinqing',
        'Monte Mor',
        'Tatalon',
        'Ovar',
        'Sacapulas',
        'Asturias',
        'Toki',
        'Erumaippatti',
        'Khorramdarreh',
        'Langenhagen',
        'Thérmi',
        'Yamasá',
        'Taliwang',
        'West Haven',
        'Mercedes',
        'Winneba',
        'Harpanahalli',
        'Buta',
        'Midalt',
        'Herriman',
        'Umm el Faḥm',
        'Meyerton',
        'Periya Semūr',
        'Moreno',
        'Três Pontas',
        'Wangguanzhuang Sicun',
        'Nordhorn',
        'Madhupur',
        'Foligno',
        'Perth Amboy',
        'Rijswijk',
        'Porirua',
        'Pombal',
        'Chālil',
        'Pinto',
        'Verviers',
        'Colmenar Viejo',
        'Challakere',
        'Casa Grande',
        'Wuhuang',
        'Tigard',
        'Biel/Bienne',
        'Kronjo',
        'Puthiyangādi',
        'Lazarevac',
        'Nāmakkal',
        'Vranje',
        'Ibiporã',
        'Santa Cruz',
        'Hyères',
        'Stryi',
        'Linares',
        'Māngrol',
        'Shijōnawate',
        'Békéscsaba',
        'Novohrad-Volynskyi',
        'Manapla',
        'Chonthrhu',
        'Bhīmunipatnam',
        'Zgierz',
        'Olímpia',
        'Eqbālīyeh',
        'Rangewala',
        'Bobigny',
        'Apopka',
        'Ucuma',
        'Lampang',
        'Fālākāta',
        'União da Vitória',
        'Chino',
        'Canoinhas',
        'Touba',
        'Agbangnizoun',
        'Bilis Qooqaani',
        'Elenga',
        'Polūr',
        'Bhongīr',
        'Tāsgaon',
        'Bad Homburg',
        'Narutochō-mitsuishi',
        'Dompu',
        'Songjiangcun',
        'Catu',
        'Şırnak',
        'La Roche-sur-Yon',
        'Granadilla de Abona',
        'Trani',
        'Oxchuc',
        'Tequisquiapan',
        'Corby',
        'Nāndod',
        'Tivoli',
        'Southaven',
        'Imarichō-kō',
        'Acıpayam',
        'Luquembo',
        'Canterbury',
        'Zhushan',
        'Pithāpuram',
        'Queensburgh',
        'Yenişehir',
        'Jelgava',
        'Minamiuonuma',
        'Bad Salzuflen',
        'Khurai',
        'Świdnica',
        'Ŏjŏk-tong',
        'Tacuarembó',
        'Punganūru',
        'Trenčín',
        'Hidaka',
        'Piedade',
        'Barranqueras',
        'Santa Cruz',
        'Chaiwu',
        'Sarpsborg',
        'Belén de Escobar',
        'Schweinfurt',
        'Phuthaditjhaba',
        'Saruhanlı',
        'Carigara',
        'Sara',
        'Hattingen',
        'Chone',
        'Mikkeli',
        'Pila',
        'Chota',
        'Gabrovo',
        'Annaka',
        'Ghātāl',
        'Terneuzen',
        'Palmares',
        'Santa Elena',
        'Giurgiu',
        'Sāgar',
        'Huntington Park',
        'Mūndka',
        'Diamond Bar',
        'Gus’-Khrustal’nyy',
        'Jinchang',
        'Masinloc',
        'Volzhsk',
        'Bentonville',
        'Pontevedra',
        'Trento',
        'Jalapa',
        'Clamart',
        'Buderim',
        'Ortaca',
        'Douar Ain Chkef',
        'Kampen',
        'Ensenada',
        'Antanifotsy',
        'Nihonmatsu',
        'Kouri',
        'Yucaipa',
        'Capitão Poço',
        'Mbulungu',
        'Tapas',
        'Vannes',
        'Pallíni',
        'Caojia',
        'Konan',
        'San Isidro',
        'Dikwella South',
        'Medianeira',
        'Plainfield',
        'Javānrūd',
        'Azrou',
        'Shidong',
        'Shidongcun',
        'Sakurai',
        'Islāmpur',
        'Umm Qurūn',
        'Ruma',
        'Bełchatów',
        'Morsott',
        'Sidhi',
        'Rāhuri',
        'Chelles',
        'Sado',
        'Hanyū',
        'Zarafshon Shahri',
        'Manhattan',
        'San Manuel',
        'Kızılpınar',
        'Paraćin',
        'Aspen Hill',
        'Rocky Mount',
        'Cornillon',
        'Valle del Guamuez',
        'Bristol',
        'Ankadinondry-Sakay',
        'Christchurch',
        'Rotorua',
        'Peabody',
        'Don Bosco',
        'Frýdek-Místek',
        'Wetzlar',
        'Hashtpar',
        'Kāsaragod',
        'Komotiní',
        'West Sacramento',
        'Frenda',
        'Bir Ali Ben Khalifa',
        'Wayne',
        'Huesca',
        'Ixhuatlán de Madero',
        'Cajicá',
        'Louang Namtha',
        'Puttūr',
        'Mariano Acosta',
        'Jalor',
        'Tarma',
        'San Jacinto',
        'Chèddra',
        'Samāna',
        'Kunnamkulam',
        'Kentwood',
        'Palmaner',
        'Xihu',
        'Jālākāti',
        'Deolāli',
        'Lozova',
        'São Gonçalo do Amarante',
        'Prachuap Khiri Khan',
        'Ribeira do Pombal',
        'Juanjuí',
        'Tagkawayan',
        'Ghōriyān',
        'Jihong',
        'Kennedy Town',
        'Titay',
        'Puerto Libertador',
        'Minalabac',
        'Neustadt',
        'Umred',
        'Şabbāshahr',
        'Bandar-e Torkaman',
        'Hamura',
        'Chenab Nagar',
        'Sohna',
        'Colton',
        'Chilibre',
        'Cholet',
        'Vigan',
        'Manicoré',
        'San Bartolomé',
        'Chaniá',
        'An Nimāş',
        'Passau',
        'Manfredonia',
        'Cabagan',
        'Bacaadweyn',
        'Narva',
        'Bījār',
        'Iganga',
        'Impasugong',
        'Pārvatipuram',
        'Millcreek',
        'Oak Park',
        'Mansāla',
        'Ullāl',
        'Magpet',
        'Juigalpa',
        'Kitaotao',
        'Louangphabang',
        'Dholka',
        'Ottappālam',
        'Westchester',
        'Smyrna',
        'São Francisco do Sul',
        'Zongo',
        'A Yun Pa',
        'Cangola',
        'Funato',
        'Gazipaşa',
        'Upata',
        'Svobodnyy',
        'Wheaton',
        'Hadali',
        'Mora',
        'Manjeri',
        'Alcobaça',
        'Kongoussi',
        'Évry',
        'Krasnokamsk',
        'Al Majāridah',
        'Ashta',
        'Sakon Nakhon',
        'Gisenyi',
        'Lower Paxton',
        'Cambundi Catembo',
        'Évora',
        'Encarnación de Díaz',
        'Beaumont',
        'Ilindu Kothi',
        'Bisceglie',
        'Aloha',
        'Kāliyāganj',
        'Jaggayyapeta',
        'Minnetonka',
        'Cuenca',
        'Brits',
        'Morondava',
        'Liski',
        'Modica',
        'Marechal Cândido Rondon',
        'Ilgın',
        'Épinay-sur-Seine',
        'Travnik',
        'Extrema',
        'Howell',
        'Hongshandian',
        'Ajuy',
        'Pasacao',
        'Marco de Canavezes',
        'Popeşti-Leordeni',
        'Goālpāra',
        'Sibonga',
        'Dilovası',
        'Santa Lucía',
        'Zhigulevsk',
        'Pryluky',
        'Mutsu',
        'Kleve',
        'Liberia',
        'Yaguajay',
        'Shāhpur',
        'Guiuan',
        'Tutóia',
        'Pinellas Park',
        'Ahlen',
        'Granja',
        'Tōkamachi',
        'Keighley',
        'Kannapolis',
        'Gorkhā',
        'Chaozhou',
        'Itabirito',
        'Montesilvano',
        'Tame',
        'Inhumas',
        'Paramount',
        'Dongshan',
        'Amalāpuram',
        'Tokmok',
        'Açu',
        'Dangila',
        'Yangmei',
        'Bakhtiyārpur',
        'Vyksa',
        'Legionowo',
        'Arsikere',
        'San Vicente',
        'La Gomera',
        'Saint-Ouen',
        'Qingan',
        'Estancia',
        'Kabale',
        'Hamilton',
        'Dongsheng',
        'Bitonto',
        'Barbosa',
        'Patancheruvu',
        'Capivari',
        'Pinotepa',
        'Barobo',
        'Rondon do Pará',
        'Vikārābād',
        'Molave',
        'Bāruipur',
        'Beberibe',
        'Deva',
        'Hereford',
        'Dandarah',
        'Dunfermline',
        'Lucban',
        'Zaragoza',
        'Texas City',
        'San Manuel',
        'Sagua la Grande',
        'Itapagé',
        'Puttūr',
        'Novato',
        'Targovishte',
        'Edina',
        'Naka',
        'Mahmutlar',
        'Elda',
        'Piekary Śląskie',
        'Safranbolu',
        'Aurora',
        'At Tawāhī',
        'Salo',
        'San Estanislao',
        'Izumi',
        'Manglaur',
        'Beloeil',
        'Saint-Quentin',
        'Phra Nakhon Si Ayutthaya',
        'Simojovel de Allende',
        'Kadiolo',
        'Bagheria',
        'Hyosha',
        'Normal',
        'Tilhar',
        'Gudermes',
        'Bondy',
        'Xincheng',
        'Buenavista',
        'São Miguel do Guamá',
        'Xinguara',
        'Sultānganj',
        'Congonhas',
        'Tiruvalla',
        'Kandıra',
        'Mandi Dabwāli',
        'Maha Sarakham',
        'Tamiami',
        '‘Alīābād-e Katūl',
        'Clorinda',
        'Grand Island',
        'Xingcheng',
        'Siena',
        'Methuen Town',
        'Frechen',
        'Gallarate',
        'Hrazdan',
        'Timbaúba',
        'San Remo',
        'Elyria',
        'Wheaton',
        'Lorica',
        'Usa',
        'Harrismith',
        'São Francisco',
        'Malkara',
        'Ksar Chellala',
        'Bayonne',
        'Pigcawayan',
        'Ouenza',
        'Jette',
        'Lagoa da Prata',
        'Karak',
        'Madīnat Ḩamad',
        'Woerden',
        'Venkatagiri',
        'Hamtic',
        'Kelaa Kebira',
        'Paracambi',
        'Corbeil-Essonnes',
        'Wolfenbüttel',
        'Bozova',
        'Kobryn',
        'North Bay',
        'Kendale Lakes',
        'Pôrto Ferreira',
        'Quezaltepeque',
        'San José Villa de Allende',
        'Bloomfield',
        'Tramandaí',
        'Kahnūj',
        'Yaozhuangcun',
        'Minami-Sōma',
        'San Joaquin',
        'Zhangjiazhuang',
        'Zhangjiazhuangcun',
        'Bani',
        'Sihor',
        'Tizimín',
        'Brentwood',
        'Imbituba',
        'Roskilde',
        'Cagnes-sur-Mer',
        'Nerkunram',
        'Jihlava',
        'Başkale',
        'Marana',
        'Sertolovo',
        'Guernica',
        'Bad Kreuznach',
        'Neibu',
        'Velletri',
        'Cihanbeyli',
        'President Roxas',
        'Burauen',
        'Tlacotepec',
        'Vyazma',
        'An Nabk',
        'Panjakent',
        'Qabqa',
        'Bamei',
        'Thongwa',
        'Pacora',
        'Badūria',
        'Dauis',
        'El Rama',
        'Claveria',
        'Bârlad',
        'Sironj',
        'Ban Om Noi',
        'San Miguel Ixtahuacán',
        'West New York',
        'Padangpanjang',
        'Ibbenbüren',
        'Tønsberg',
        'Altrincham',
        'Coronel Oviedo',
        'Jangaon',
        'Miyakojima',
        'Charlottetown',
        'Marechal Deodoro',
        'Ibajay',
        'Daudnagar',
        'Paraíso do Tocantins',
        'Jiyyammavalasa',
        'Zalău',
        'Acajutla',
        'Antratsyt',
        'Mandamāri',
        'Lakhminia',
        'Sison',
        'Aïn Sefra',
        'Twin Falls',
        'Krasnokamensk',
        'Marondera',
        'Akbou',
        'Campo Belo',
        'Santo Estêvão',
        'Delmiro Gouveia',
        'Taquaritinga',
        'Tekkeköy',
        'Enerhodar',
        'Lancaster',
        'Óbidos',
        'Cangandala',
        'Lautoka',
        'Pula',
        'Taxco de Alarcón',
        'San Pablo',
        'São José do Rio Pardo',
        'Korāput',
        'Mudhol',
        'Avellino',
        'Pola de Siero',
        'Naguilian',
        'Victoria',
        'Horishni Plavni',
        'Florissant',
        'Ålesund',
        'Uriangato',
        'Bato',
        'El Tumbador',
        'Vaulx-en-Velin',
        'Bhajani',
        'Phaltan',
        'Shiraoka',
        'Buíque',
        'Glendora',
        'Belorechensk',
        'Acatzingo',
        'Diu',
        'Lengquancun',
        'Portici',
        'Ampanihy',
        'Todupulai',
        'Bitlis',
        'Ālbū Kamāl',
        'Chajul',
        'Pontes e Lacerda',
        'Thomazeau',
        'Tangkak',
        'Caetité',
        'Yendi',
        'Gummersbach',
        'Ambohibary',
        'Ad Dabbah',
        'Tejen',
        'Dungu',
        'Takeo',
        'Shiogama',
        'Huamantla',
        'Ibiza',
        'San Onofre',
        'Khattan',
        'Leon',
        'Cathedral City',
        'Vaisampākkal',
        'Kumarankari',
        'Al Kharj',
        'Ntoum',
        'Piaseczno',
        'Eilat',
        'Muban Saeng Bua Thong',
        'Timashevsk',
        'Santa Eulalia',
        'Mengmeng',
        'Aliso Viejo',
        'Vernon',
        'Liaquatpur',
        'Rumia',
        'San Gil',
        'Wangjiazhai',
        'El Bagre',
        'Tomiya',
        'Alytus',
        'Padre Garcia',
        'Villareal',
        'Vila Real',
        'Arwal',
        'Sevran',
        'Choma',
        'Kardzhali',
        'Melo',
        'Mollet',
        'Fontenay-sous-Bois',
        'M.Ə. Rəsulzadə',
        'Palenque',
        'Covilhã',
        'Placentia',
        'São Gabriel da Cachoeira',
        'Crosby',
        'Palmas',
        'Sherpur',
        'Burla',
        'Hoffman Estates',
        'Gādarwāra',
        'Caleta Olivia',
        'Baranoa',
        'Caldas da Rainha',
        'Caramoan',
        'Gradiška',
        'Pordenone',
        'Utrera',
        'Belle-Anse',
        'Isfara',
        'Guapimirim',
        'Tamboril',
        'Qalqīlyah',
        'Kālpi',
        'Aguadulce',
        'Girardota',
        'Civitavecchia',
        'Baião',
        'Santa Ignacia',
        'Shahr-e Bābak',
        'Alaminos',
        'Solnechnogorsk',
        'Xanxerê',
        'Mulongo',
        'Huazangsi',
        'Tijucas',
        'Irinjālakuda',
        'Menggala',
        'Kokstad',
        'Tachilek',
        'Zeralda',
        'Teramo',
        'Tirupparangunram',
        'Maubin',
        'Tulsīpur',
        'İncirliova',
        'Bandō',
        'Zangāreddigūdem',
        'Burien',
        'Barri',
        'Baham',
        'Abdul Hakim',
        'Ravensburg',
        'Râs el Oued',
        'Sombor',
        'Bantacan',
        'Böblingen',
        'Dunwoody',
        'Ensenada Berisso',
        'Viana',
        'Sūsangerd',
        'Mangochi',
        'Willenhall',
        'Peine',
        'Cerca la Source',
        'El Kelaa des Srarhna',
        'Bootle',
        'Stratford',
        'Speyer',
        'Torrelavega',
        'Uzlovaya',
        'Morrinhos',
        'Nentón',
        'Koumantou',
        'Gopālganj',
        'Boukadir',
        'Folkestone',
        '’Aïn Merane',
        'Tadmur',
        'Brandon',
        'Rastatt',
        'Samut Prakan',
        'Kalawana',
        'Sapé',
        'Lənkəran',
        'Rincón de la Victoria',
        'Kyzyl-Kyya',
        'Palm Desert',
        'Puerto Francisco de Orellana',
        'Cutervo',
        'Peranāmpattu',
        'Troy',
        'Guarne',
        'Tamalous',
        'Racibórz',
        'Rongat',
        'Wāliṅ',
        'Dartford',
        'Dabhoi',
        'Montijo',
        'Sartrouville',
        'Severomorsk',
        'Herning',
        'Salekhard',
        'Bargny',
        'Bhavāni',
        'Puthuppariyāram',
        'Mascouche',
        'Collierville',
        'Sidlaghatta',
        'Jūrmala',
        'Korçë',
        'Levittown',
        'Leopoldina',
        'Martin',
        'Tsukubamirai',
        'Fuchū',
        'Barra',
        'Brejo Santo',
        'Marpalli',
        'Bowringpet',
        'Knysna',
        'Sar-e Pul',
        'Ocampo',
        'San Narciso',
        'Aurangābād',
        'Hodal',
        'Snezhinsk',
        'Fatwa',
        'Rosemead',
        'Rosso',
        'Kot Mumin',
        'Shimencun',
        'Weymouth',
        'Segovia',
        'Kalbā',
        'Iormughanlo',
        'Umán',
        'Country Club',
        'Tucurú',
        'Massy',
        'Enid',
        'Horsham',
        'Cumbernauld',
        'Pardwāl',
        'Djamaa',
        'Cuyahoga Falls',
        'Guaxupé',
        'Tobias Barreto',
        'Esher',
        'Metpalli',
        'Kalamansig',
        'Mishawaka',
        'Columbus',
        'Andover',
        'Kirishi',
        'Juruti',
        'Babīlā',
        'Silkeborg',
        'Repalle',
        'Huaiyang',
        'Skenderaj',
        'Mabini',
        'Kyōtango',
        'Miyako',
        'Pontal',
        'Campos do Jordão',
        'Teplice',
        'Summerville',
        'Vigia',
        'Livingston',
        'Nicoya',
        'Banī Walīd',
        'Cheremkhovo',
        'Çermik',
        'Levittown',
        'Tŭrtkŭl',
        'Geyve',
        'Kuchaiburi',
        'Vyāra',
        'Whangarei',
        'Zabīd',
        'Sibsāgar',
        'Irondequoit',
        'Mahmudābād',
        'Elmshorn',
        'Grapevine',
        'Goshogawara',
        'Jarash',
        'Zumpango',
        'Marigot',
        'Covina',
        'Rio Tinto',
        'Ranchuelo',
        'Quirinópolis',
        'Beruniy',
        'Bikramganj',
        'Agrínio',
        'Göksun',
        'Mweka',
        'Chinchiná',
        'Estância Velha',
        'Sunāmganj',
        'Milford city',
        'Neath',
        'Chinnamanūr',
        'Draper',
        'Fafe',
        'Cataingan',
        'Kotka',
        'El Jem',
        'Skien',
        'Lakewood',
        'Haskah Mēnah',
        'Jaru',
        'Metema',
        'Sakaidechō',
        'Catalina Foothills',
        'Arao',
        'Ānaiyūr',
        'Kosamba',
        'La Calera',
        'Clacton-on-Sea',
        'Cava de’ Tirreni',
        'Emden',
        'Northwich',
        'Jaspur',
        'Nichinan',
        'Acireale',
        'Susono',
        'Delano',
        'Gloria',
        'El Nido',
        'Maḩmūd-e Rāqī',
        'Forbesganj',
        'San Ignacio',
        'Tebourba',
        'Hunedoara',
        'Naviraí',
        'Bas Limbé',
        'Tūyserkān',
        'Laksar',
        'Youfangcun',
        'Cabreúva',
        'El Talar de Pacheco',
        'Bar Bigha',
        'Tūndla',
        'Arles',
        'Boa Viagem',
        'Bangued',
        'Aringay',
        'Haverford',
        'Tanjombato',
        'Ampitatafika',
        'Plaisance',
        'Wao',
        'Myrnohrad',
        'Chbar Mon',
        'Ma‘ān',
        'Amulung',
        'Vargem Grande Paulista',
        'Cojutepeque',
        'Chinú',
        'Bāghpat',
        'Floreşti',
        'Peñablanca',
        'Rho',
        'Donsol',
        'Beveren',
        'Rowley Regis',
        'Artur Nogueira',
        'Bhawānīpur Rājdhām',
        'Houten',
        'Yūki',
        'Goslar',
        'Ghŭlakandoz',
        'Gombong',
        'Nes Ẕiyyona',
        'Nakagawa',
        'Palpalá',
        'Hamada',
        'Karviná',
        'Moskovskiy',
        'Adeje',
        'Gopālpur',
        'Borovichi',
        'Willich',
        'Cardona',
        'Qinhe',
        'Bang Bua Thong',
        'Scarborough',
        'Lincoln',
        'Ercolano',
        'Sour el Ghozlane',
        'Déressia',
        'Roslavl',
        'Itararé',
        'Bhadrāchalam',
        'Francisco I. Madero',
        'Sfântu-Gheorghe',
        'Tubod',
        'Biaora',
        'Sojat',
        'Yerba Buena',
        'Wolossébougou',
        'Yasu',
        'Miyoshi',
        'Hassan Abdal',
        'Murray',
        'Mazara del Vallo',
        'Leith',
        'Pamplona',
        'Heidenheim',
        'Mooresville',
        'Eger',
        'Weert',
        'Sakubva',
        'Qabb Eliâs',
        'El Ghâzîyé',
        'Aarsâl',
        'Ikot Okoro',
        'Sechura',
        'Chak Thirty-six North Branch',
        'Rukan',
        'Buni',
        'Chak Sixty-one Gugera Branch',
        'Al Wajh',
        'Abū Ḩamad',
        'Godinlabe',
        'Ceel Dheere',
        'Tukuyu',
        'Kyaliwajjala',
        'Āqchah',
        'Krems an der Donau',
        'Bandar Seri Begawan',
        'Kahemba',
        'Gutao',
        'Xiayang',
        'Dongxishan',
        'Xiazhai',
        'Jieshangya',
        'Saoula',
        '’Aïn el Turk',
        'Semera',
        'Bogoso',
        'Tammampatti',
        'Sujānpur',
        'Shiv',
        'Bhattu Kolān',
        'Downers Grove',
        'Louveira',
        'Florin',
        'Al Khārjah',
        'Sihorā',
        'Chatrā',
        'Rovigo',
        'Parāsia',
        'Satte',
        'Chake Chake',
        'Middelburg',
        'Cypress',
        'Chahār Dangeh',
        'Simraungaḍh',
        'Porvoo',
        'Ejeda',
        'Muktāgācha',
        'Janīn',
        'Shankou',
        'Al Ma‘allā’',
        'Bigadiç',
        'Erftstadt',
        'Lörrach',
        'Stouffville',
        'Hilsa',
        'Poprad',
        'Cuxhaven',
        'Tepeapulco',
        'Neyrīz',
        'Pananaw',
        'Xankəndi',
        'Boaco',
        'Nohar',
        'Gediz',
        'Gronau',
        'Ain El Aouda',
        'Östersund',
        'Ostrołęka',
        'Cerquilho Velho',
        'Hannan',
        'El Meghaïer',
        'Concepción',
        'Novovolynsk',
        'Jeffersonville',
        'Karapınar',
        'Bhainsa',
        'North Bethesda',
        'Çine',
        'Itupiranga',
        'Pontevedra',
        'Selibe Phikwe',
        'Perintalmanna',
        'Padrauna',
        'Bignay',
        'Albi',
        'Gualán',
        'Azusa',
        'Yeovil',
        'Coral Gables',
        'Ridder',
        'Wagga Wagga',
        'Canguçu',
        'Nanao',
        'Zyryanovsk',
        'Laval',
        'Perambalūr',
        'Chesterfield',
        'Irákleio',
        'Noshiromachi',
        'Coelho Neto',
        'Shawinigan',
        'Aversa',
        'Rosario',
        'Kandangan',
        'Pirot',
        'Cimitarra',
        'Sosúa',
        'McLean',
        'San Juan del Cesar',
        'Huancavelica',
        'Saint-Herblain',
        'Kizhake Chālakudi',
        'Mojo',
        'Leonberg',
        'Dumarao',
        'Sesimbra',
        'Mpigi',
        'Sulleru',
        'St. Louis Park',
        'Araklı',
        'East Honolulu',
        'San Pedro',
        'Rheda-Wiedenbrück',
        'Gao',
        'Shakhtarsk',
        'Bad Oeynhausen',
        'Peddāpuram',
        'Novovyatsk',
        'Payyoli',
        'Tomisato',
        'María la Baja',
        'East Brunswick',
        'Santa Lucía del Camino',
        'Kameyama',
        'Monte Alto',
        'Bedford',
        'Mājalgaon',
        'Noveleta',
        'Catemaco',
        'São Bento do Una',
        'Villaguay',
        'Singen',
        'Battipaglia',
        'Gennevilliers',
        'Özgön',
        'Pen-y-Bont ar Ogwr',
        'Nowshahr',
        'Prudentópolis',
        'Suzaka',
        'Prattipādu',
        'Scandicci',
        'Kiblawan',
        'Karlovac',
        'Ksar Hellal',
        'Santiago',
        'Arifiye',
        'Mulukukú',
        'Freising',
        'Sebeta',
        'Mozhga',
        'Wandan',
        'San Severo',
        'Bagumbayan',
        'Haripur',
        'Mbouda',
        'Goba',
        'Kapadvanj',
        'Mailiao',
        'Edenvale',
        'Odiongan',
        'Euclid',
        'Bergkamen',
        'Asakura',
        'Higashiura',
        'Kizlyar',
        'Joliette',
        'Buguruslan',
        'Zargar',
        'Agustín Codazzi',
        'Tuodian',
        'Lawrence',
        'Tennala',
        'Fangcun',
        'Baicheng',
        'Cintalapa de Figueroa',
        'Carmen',
        'Midori',
        'Ceres',
        'Samaniego',
        'Yong’an',
        'Straubing',
        'Qalāt',
        'Kaédi',
        'University',
        'Notsé',
        'Rāmnagar',
        'Biloxi',
        'Tunuyán',
        'Luleå',
        'San Marcos',
        'Slonim',
        'Saray',
        'Suresnes',
        'Luwero',
        'Guozhen',
        'Hikari',
        'Kefamenanu',
        'Malabang',
        'Sungurlu',
        'Yamaga',
        'El Dorado Hills',
        'Nahuizalco',
        'Pio Duran',
        'Naugachhia',
        'Ardea',
        'Lesnoy',
        'Frankenthal',
        'Karlovy Vary',
        'Vinzons',
        'La Libertad',
        'Port-Vila',
        'Bornheim',
        'Ōamishirasato',
        'Misterbianco',
        'Cerritos',
        'Nizāmpur',
        'Farajok',
        'Sông Đốc',
        'Jaggisettigūdem',
        'Dulag',
        'Rye',
        'Changyŏn',
        'Valladolid',
        'Suwa',
        'Burleson',
        'Libona',
        'Eltham',
        'Fouka',
        'Portage',
        'Rimouski',
        'Al Mayādīn',
        'Barnstable',
        'Courtenay',
        'Alcala',
        'Upplands Väsby',
        '‘Akko',
        'Kangar',
        'Dublin',
        'Panay',
        'Dumingag',
        'Nomimachi',
        'Jiménez',
        'Couva',
        'Hampstead',
        'Godda',
        'Telerghma',
        'Takeochō-takeo',
        'Empoli',
        'Tājūrā’',
        'Bādurpalle',
        'Washington',
        'Santo Domingo Suchitepéquez',
        'Saint-Priest',
        'Vertientes',
        'Waalwijk',
        'Shimotsuchō-kominami',
        'Aïn Fakroun',
        'Cacongo',
        'Vincennes',
        'Chikugo',
        'Jales',
        'Nikaweratiya',
        'Bastia',
        'Ocotal',
        'Sanare',
        'El‘ad',
        'Sesto Fiorentino',
        'Tunglangan',
        'Tucano',
        'Poway',
        'Lal-lo',
        'Cedar Hill',
        'Harderwijk',
        'Verrettes',
        'Boucan Carré',
        'Dongzhang',
        'Takahama',
        'Everett',
        'Mladenovac',
        'Koduvalli',
        'Garmsār',
        'Guntakal Junction',
        'Gooty',
        'Mantova',
        'Brejo da Madre de Deus',
        'Roman',
        'Stillwater',
        'Barendrecht',
        'Colcapirhua',
        'Amora',
        'Caraballeda',
        'Zaječar',
        'Bantvāl',
        'Barotac Viejo',
        'Angol',
        'Soest',
        'Leiktho',
        'Batajnica',
        'Titusville',
        'Namtu',
        'Yenangyaung',
        'Martigues',
        'São Joaquim da Barra',
        'Omitama',
        'Masagua',
        'Orangetown',
        'Cwmbran',
        'Inabanga',
        'Suzukawa',
        'Nyaungu',
        'Sutton in Ashfield',
        'Siocon',
        'Kīlvishāram',
        'Begoro',
        'Liujiaxia',
        'Niagara Falls',
        'Khairābād',
        '’Aïn Azel',
        'Kyle',
        'Jasdan',
        'Etterbeek',
        'Upperu',
        'Kawkareik',
        'Leesburg',
        'Pedreira',
        'Chieti',
        'Mangalapādi',
        'Kurganinsk',
        'Wakema',
        'Dollard-des-Ormeaux',
        'Asadābād',
        'West Orange',
        'Babaeski',
        'Kozlu',
        'Minalin',
        'Welwyn Garden City',
        'Damāvand',
        'Erlin',
        'Waterford',
        'Stade',
        'Collegno',
        'Beni Yakhlef',
        'Kalima',
        'San Pedro Mixtepec',
        'Alsdorf',
        'Alajuela',
        'Grasse',
        'IJmuiden',
        'Mandeville',
        'Upper Buchanan',
        'Tuba',
        'Levakant',
        'Asunción Mita',
        'Vite',
        'Kara-Balta',
        'Nong Khai',
        'Beypazarı',
        'Jiashizhuangcun',
        'Westfield',
        'Mahayag',
        'Ishigaki',
        'Berastagi',
        'Chhāgalnāiya',
        'Titao',
        'Little Elm',
        'Morden',
        'Dongshan',
        'Çan',
        'Duyên Hải',
        'Santa Rita',
        'Homnābād',
        'Joshīmath',
        'Sanmu',
        'Qulicun',
        'Dachau',
        'Smethwick',
        'Hāgere Hiywet',
        'Shuishang',
        'Florida',
        'Playas',
        'Middletown',
        'Gumia',
        'Alta Gracia',
        'Finike',
        'Kirkcaldy',
        'Shelekhov',
        'Faya',
        'Bokāro',
        'North Highlands',
        'Ami',
        'Durham',
        'Dornbirn',
        'Bacolor',
        'Balimbing',
        'Tuckahoe',
        'Wake Forest',
        'Scafati',
        'Minot',
        'Araci',
        'Roswell',
        'Nettuno',
        'Colonia del Sol',
        'Bocaiúva',
        'Aquidauana',
        'Kanye',
        'Kashira',
        'Monroe',
        'Hennef',
        'Tunduru',
        'Liutuancun',
        'Santangpai',
        'Nāngal Township',
        'Ena',
        'Monopoli',
        'Yongping',
        'Cuetzalan',
        'Ash Shaykh Badr',
        'Kasuya',
        'Iwakura',
        'Okhtyrka',
        'Basoko',
        'Sadāseopet',
        'New Washington',
        'Hoskote',
        'Barras',
        'Clondalkin',
        'Carepa',
        'Bhamo',
        'Wauwatosa',
        'Bothell',
        'Birendranagar',
        'Glenview',
        'Vila Verde',
        'Tibigan',
        'Figueras',
        'Dyero',
        'Chong Nonsi',
        'Rockwall',
        'Báguanos',
        'Golpāyegān',
        'Jalandhar Cantonment',
        'Cornwall',
        'La Reja',
        'Hørsholm',
        'Pativilca',
        'Jiquilisco',
        'Monte Santo',
        'Luwuk',
        'Donetsk',
        'Wilson',
        'Victoriaville',
        'Oranienburg',
        'Bhatkal',
        'Higashine',
        'Güroymak',
        'Águeda',
        'Ad Darwa',
        'Al Madrah Samā’il',
        'Tremembé',
        'Dūngarpur',
        'Mamburao',
        'Diamantina',
        'Rancho Santa Margarita',
        'Chandrāwāda',
        'Kamidani',
        'Zawiercie',
        'Monte Carmelo',
        'Ocosingo',
        'Vale de Cavalos',
        'Montrouge',
        'Amahai',
        'Ambatondrazaka',
        'Pabellón de Arteaga',
        'La Mirada',
        'Georgina',
        'São Benedito',
        'Tonami',
        'Antelope',
        'Hilo',
        'San Lorenzo',
        'Invercargill',
        'Longchamps',
        'Gracias',
        'Landau',
        'Ono',
        'Yumbe',
        'Chetouane',
        'Al Aaroui',
        'Gavá',
        'Hitachi-ota',
        'Dumka',
        '‘Āmūdā',
        'Yecun',
        'Kokkola',
        'Catriel',
        'Helsingør',
        'Chioggia',
        'Sogod',
        'Pampán',
        'Vich',
        'Mairena del Aljarafe',
        'Lamía',
        'San Luis Obispo',
        'Puerto Lempira',
        'Bunawan',
        'Rengam',
        'Gaocheng',
        'Okaya',
        'Buena Vista Tomatlán',
        'Aznā',
        'Egg Harbor',
        'Changanācheri',
        'Dalaman',
        'Poso',
        'Roseville',
        'Newark',
        'Dülmen',
        'Campi Bisenzio',
        'Umarkhed',
        'Serowe',
        'Liuhu',
        'Inđija',
        'Perth',
        'Zutphen',
        'Atarra',
        'La Vega',
        'Altos',
        'Osório',
        'Chaparral',
        'Malinao',
        'Nanto',
        'Lousada',
        'Villa Constitución',
        'Chenalhó',
        'Penco',
        'Voluntari',
        'Vryheid',
        'Sanski Most',
        'Barnet',
        'Sayanogorsk',
        'Wejherowo',
        'Jobabo',
        'General Martín Miguel de Güemes',
        'Sumisip',
        'Qasbat Tadla',
        'Aubagne',
        'Kifisiá',
        'Saint-Malo',
        'Azhikkōd',
        'Azhikkal',
        'Kingisepp',
        'Rio de Mouro',
        'Arsenyev',
        'Loum',
        'Mentor',
        'Talghar',
        'Ocoee',
        'Évreux',
        'São José de Mipibu',
        'San Andrés de Sotavento',
        'Mataquescuintla',
        'Gosen',
        'Snizhne',
        'Cunén',
        'Oued Sly',
        'Ayapel',
        'Vikramasingapuram',
        'Perinton',
        'Waspán',
        'Melle',
        'Jiāganj',
        'Livny',
        'Manappārai',
        'Kasumbalesa',
        'As Sa‘dīyah',
        'Rowland Heights',
        'Zhongtanying',
        'Evesham',
        'Kikugawa',
        'Douz',
        'Otradnyy',
        'Děčín',
        'La Courneuve',
        'Fort Pierce',
        'Albuera',
        'Rivoli',
        'Yabrūd',
        'Urrao',
        'Sidrolândia',
        'Capão Bonito',
        'Cumanayagua',
        'Paderno Dugnano',
        'Ayungon',
        'Pilar',
        'Brea',
        'Cantel',
        'Şowme‘eh Sarā',
        'Mattanūr',
        'Campobasso',
        'Qingyuan',
        'Zarzal',
        'Oro Valley',
        'Chaïdári',
        'Dracena',
        'Hagaribommanahalli',
        'Hà Tiên',
        'Pallipālaiyam',
        'Calimaya',
        'Corato',
        'East Providence',
        'Saranambana',
        'Dahutang',
        'Al Balyanā',
        'Banisilan',
        'Haeryong',
        'Casalnuovo di Napoli',
        'Liulin',
        'Viti',
        'Santo Domingo',
        'Jitaicun',
        'Cuilapa',
        'San Benedetto del Tronto',
        'Nandikotkūr',
        'Herzogenrath',
        'Chomutov',
        'Tomioka',
        'Esplugas de Llobregat',
        'Selu',
        'Stretford',
        'Vyshniy Volochëk',
        'Soest',
        'Maţāy',
        'Mēla Gūdalūr',
        'Martina Franca',
        'Valencia',
        'Boujad',
        'Campo Maior',
        'Borgne',
        'Kabasalan',
        'Neunkirchen',
        'Poções',
        'Lecco',
        'Banbury',
        'Beckenham',
        'Noboribetsu',
        'Blois',
        'Pearl City',
        'João Pinheiro',
        'Lørenskog',
        'Nanzhuangzhen',
        'Greenford',
        'Lohja',
        'Santiago',
        'Isabel',
        'Ayr',
        'Teotihuacan',
        'San Jacinto de Buena Fe',
        'Santa Ana Chiautempan',
        'Al Quşayr',
        'Araioses',
        'Kotelniki',
        'Wokingham',
        'Hyvinkää',
        'Rovenky',
        'Salina',
        'Lukavac',
        'Jesús Menéndez',
        'Sanuki',
        'La Jagua de Ibirico',
        'South Brunswick',
        'Taebaek',
        'Stepnogorsk',
        'Guaramirim',
        'Cologno Monzese',
        'Woodstock',
        'Punalūr',
        'Kampli',
        'Bizerte',
        'Yashan',
        'Ramat HaSharon',
        'La Caleta',
        'Kuilsrivier',
        'Norala',
        'Nellikkuppam',
        'Daxiang',
        'Ridderkerk',
        'Hioki',
        'Dongnanyanfa',
        'Padra',
        'Fujiyoshida',
        'Schwerte',
        'Hof',
        'Muara Teweh',
        'Yalvaç',
        'Wangtan',
        'Libertador General San Martín',
        'Puerto Villarroel',
        'Suluova',
        'Brive-la-Gaillarde',
        'Zarinsk',
        'Bruchsal',
        'Langford Station',
        'Beavercreek',
        'Obra',
        'Faratsiho',
        'Ḩajjah',
        'Nakai',
        'Quinte West',
        'Montevista',
        'Schagen',
        'Lucaya',
        'Aland',
        'Dori',
        'Tuy',
        'Winter Garden',
        'Puñal',
        'Ḩadīthah',
        'Potomac',
        'Lower Tungawan',
        'Świętochłowice',
        'Gryazi',
        'Vuyyūru',
        'Cordon',
        'Kadinamkulam',
        'Corroios',
        'Mānvi',
        'Jendouba',
        'Farmington',
        'San Francisco de los Romo',
        'Jeomchon',
        'Henrietta',
        'Santa Cruz do Rio Pardo',
        'Nelson',
        'Skierniewice',
        'Kita',
        'Rodgau',
        'Albstadt',
        'Río Tercero',
        'Pakenham',
        'Alcira',
        'Sardasht',
        'La Independencia',
        'Quva',
        'Charleville-Mézières',
        'São Bento',
        'Manacor',
        'Lissone',
        'Attleboro',
        'Starogard Gdański',
        'Nilambūr',
        'Shimenzhai',
        'Kilmarnock',
        'Marino',
        'Meudon',
        'Cuihua',
        'Bhālki',
        'Halle-Neustadt',
        'Pagalungan',
        'Bindura',
        'Ban Plai Bua Phatthana',
        'Starachowice',
        'Vidin',
        'Kurayoshi',
        'Farim',
        'Cobija',
        'Zhongzhai',
        'Douera',
        'Anandpur',
        'Capannori',
        'Kouré',
        'Takashima',
        'Nichelino',
        'Pilar',
        'San Lorenzo',
        'Talisay',
        'Narvacan',
        'Aranđelovac',
        'Santana do Ipanema',
        'Carcassonne',
        'Berëzovskiy',
        'Izunokuni',
        'Shimeo',
        'Huntsville',
        'Lawas',
        'Chitré',
        'Tingo María',
        'Megion',
        'Filderstadt',
        'Labutta',
        'Puerto Tejada',
        'Strongsville',
        'Zhob',
        'Seabra',
        'Tlalmanalco',
        'Choisy-le-Roi',
        'União',
        'Manono',
        'Casimiro de Abreu',
        'Eagle Mountain',
        'Noisy-le-Sec',
        'Borča',
        'Sumpango',
        'Kasaoka',
        'Bridgewater',
        'Jammalamadugu',
        'Acaxochitlán',
        'Beringen',
        'Garhwa',
        'Santurce-Antiguo',
        'San Felíu de Llobregat',
        'Prescott',
        'Sikandra Rao',
        'Cavaillon',
        'Eusébio',
        'Nova Venécia',
        'Bothaville',
        'Bünde',
        'Livry-Gargan',
        'Tombôco',
        'Nawāshahr',
        'Trenque Lauquen',
        'Gotha',
        'Rānāvāv',
        'Mairinque',
        'Bihāt',
        'Gahini',
        'Mindat',
        'Bājil',
        'Huilongcun',
        'Camp Perrin',
        'Amboasary',
        'Isiolo',
        'Ḩarastā',
        'Ban Bang Mae Nang',
        'Kikuchi',
        'Dingle',
        'San Rafael',
        'Jaguaquara',
        'Markala',
        'Santiago',
        'Coonoor',
        'Wodzisław Śląski',
        'Thoubāl',
        'Tamagawa',
        'Paraguaçu Paulista',
        'Freeport City',
        'Olive Branch',
        'Ourém',
        'Haymana',
        'Lupao',
        'Donggang',
        'Hokota',
        'Lumba-a-Bayabao',
        'Fastiv',
        'Arlington',
        'Joal-Fadiout',
        'Koupéla',
        'Campo Novo do Parecis',
        'Chepén',
        'Fellbach',
        'Arendal',
        'Stavroúpoli',
        'Dharmaragar',
        'Middletown',
        'Juína',
        'Parys',
        'Goose Creek',
        'Memmingen',
        'Tallbīsah',
        'Sicklerville',
        'Chajarí',
        'Igarapé',
        'Pākaur',
        'Settimo Torinese',
        'Shima',
        'Cheshunt',
        'Jablonec nad Nisou',
        'Shertallai',
        'Havant',
        'Aribinda',
        'Hexiwu',
        'Dodoma',
        'Phan Rí Cửa',
        'Anshan',
        'Kaufbeuren',
        'Nawai',
        'Lubny',
        'Salvador',
        'Altamonte Springs',
        'Badrashni',
        'Haltom City',
        'Ouled Moussa',
        'Borgerhout',
        'Gollalagunta',
        'Hackensack',
        'Dongsu',
        'Tantangan',
        'Dialakorodji',
        'St. Thomas',
        'Maluso',
        'Rājgarh',
        'Muradiye',
        'La Goulette',
        'Changchong',
        'Yambio',
        'Santa María Huatulco',
        'Farrukhnagar',
        'Dendermonde',
        'Puttalam',
        'Hokuto',
        'Shangpa',
        'Rosny-sous-Bois',
        'Elmhurst',
        'Mislata',
        'Torzhok',
        'Ogimachi',
        'Porangatu',
        'Jones',
        'Chattamangalam',
        'Denia',
        'Remedios',
        'Tangdukou',
        'Tindouf',
        'El Valle del Espíritu Santo',
        'Qianjiang Shequ',
        'Urbandale',
        'Kitakata',
        'Caiguantun',
        'Nueva Gerona',
        'Baiji',
        'Ascoli Piceno',
        'Kirkby',
        'Mauriti',
        'Los Banos',
        'Río Cauto',
        'Guacharachi',
        'Talagutong',
        'Kumta',
        'Busaar',
        'Littleton',
        'Cuautepec de Hinojosa',
        'Ortega',
        'Sangarébougou',
        'Veldhoven',
        'Canela',
        'Voi',
        'Kanash',
        'Warud',
        'Ashburn',
        'Salisbury',
        'Kariya',
        'Gaya',
        'Lalian',
        'Bāfq',
        'Surčin',
        'Lábrea',
        'Kerkrade',
        'Birāk',
        'Carnot',
        'Ganapathivattam',
        'East Lansing',
        'Olhão',
        'San Pelayo',
        'Fuxing',
        'West Seneca',
        'Mbulu',
        'Palayan City',
        'Jipijapa',
        'Gobārdānga',
        'Barra Velha',
        'Bountiful',
        'Erith',
        'San Antonio Abad',
        'Nazarovo',
        'San Isidro',
        'Ciudad de Huajuapam de León',
        'Keller',
        'Svitlovodsk',
        'Karmiel',
        'Vallenar',
        'Morgan Hill',
        'Gaoua',
        'Rieti',
        'Saint-Eustache',
        'Weinheim',
        'Puławy',
        'Neustadt am Rübenberge',
        'Hinckley',
        'Ruzayevka',
        'Tayug',
        'Misséni',
        'Radā‘',
        'Talence',
        'Kelo',
        'Jaraguá',
        'Webster',
        'Chimteppa',
        'Gračanica',
        'Sierra Vista',
        'Cornélio Procópio',
        'Vercelli',
        'Manchester',
        'Ashton',
        'Gaoua',
        'Drachten',
        'Araquari',
        'Medemblik',
        'Paterno',
        'Inongo',
        'Banī ‘Ubayd',
        'Presidente Dutra',
        'Belfort',
        'Garango',
        'Hassi Messaoud',
        'Sayreville',
        'Basud',
        'Surbiton',
        'Numata',
        'Puertollano',
        'Marau',
        'Massigui',
        'Montemorelos',
        'Castleford',
        'Chalungalpādam',
        'Aguilar',
        'Bingmei',
        'Aksay',
        'Nova Kakhovka',
        'Concepción',
        'Inuma',
        'Krosno',
        'Arantāngi',
        'Nāyudupet',
        'Maracaju',
        'Caeté',
        'Alfortville',
        'Haugesund',
        'Bắc Kạn',
        'Sundargarh',
        'Abqaiq',
        'Chalon-sur-Saône',
        'Odenton',
        'Prievidza',
        'Biləcəri',
        'Heusden',
        'Slavonski Brod',
        'Shchūchīnsk',
        'Lapa',
        'Cleveland Heights',
        'Pakxan',
        'Batroûn',
        'Shaḩḩāt',
        'Parma',
        'Kutum',
        'Nimule',
        'Jedeïda',
        'Baba I',
        'Mladá Boleslav',
        'Rabinal',
        'Mahālingpur',
        'Bhāyala',
        'Nalegaon',
        'Muddebihāl',
        'Kedgaon',
        'Sachīn',
        'Ālamūru',
        'Qarabulaq',
        'Dīg',
        'Hekou',
        'Tank',
        'Næstved',
        'Barnagar',
        'Brianka',
        'Marhanets',
        'Tres Valles',
        'Angadanan',
        'Timbó',
        'Acopiara',
        'Maria Aurora',
        'Gallatin',
        'Āstāneh-ye Ashrafīyeh',
        'Plainfield',
        'Nadym',
        'Yatağan',
        'Palm Springs',
        'Mount Laurel',
        'Seregno',
        'Lancaster',
        'Pederneiras',
        'Catford',
        'Tarnobrzeg',
        'Port Loko',
        'Borlänge',
        'Razgrad',
        'Charkhi Dādri',
        'Āz̄arshahr',
        'Buguias',
        'Sagua de Tánamo',
        'Shangshan',
        'Shazhou',
        'Dois Vizinhos',
        'Volkhov',
        'Guledagudda',
        'Al ‘Azīzīyah',
        'Tateyama',
        'Bolívar',
        'Riverton',
        'West Lafayette',
        'Parigi',
        'Rāmeswaram',
        'Santiago Atitlán',
        'Meoqui',
        'Xinji',
        'Port Macquarie',
        'Brentwood',
        'Lehrte',
        'Colotenango',
        'Nasipit',
        '‘Ayn al ‘Arab',
        'Barru',
        'Mochudi',
        'Santa Bárbara',
        'Merksem',
        'Falkensee',
        'Brühl',
        'Santana do Paraíso',
        'Manjuyod',
        'São Lourenço',
        'Balcarce',
        'Jidd Ḩafş',
        'Pemangkat',
        'Sakura',
        'Zwijndrecht',
        'Chichigalpa',
        'Liuquancun',
        'Xique-Xique',
        'Kimry',
        'Cutler Bay',
        'Cascina',
        'Itaberaí',
        'Worksop',
        'Salon-de-Provence',
        'San Pedro Perulapán',
        'Melūr',
        'Terracina',
        'Lake Ridge',
        'Whitney',
        'Dhupgāri',
        'Kafr al Baţţīkh',
        'Sapanca',
        'Sète',
        'Pahrump',
        'Lodi',
        'Flores',
        'Yaopu',
        'Tangjiacun',
        'Soliman',
        'Mount Pleasant',
        'Katipunan',
        'Zamalkā',
        'Bhārella',
        'Kharan',
        'Zouerate',
        'Pyu',
        'Dowlaiswaram',
        'North Lauderdale',
        'Concepcion',
        'Portugalete',
        'Dolores',
        'Conceição do Araguaia',
        'Xiluo',
        'Jacaltenango',
        'Kitgum',
        'Fairfield',
        'Sainthia',
        'Gogrial',
        'Mamanguape',
        'Grabouw',
        'Upper Bicutan',
        'Wentzville',
        'Istres',
        'Augusto Correa',
        'Tori-Bossito',
        'Alcamo',
        'Guisa',
        'Jangamguda',
        'Arboletes',
        'Cayambe',
        'Salem',
        'Mawlaik',
        'Mantes-la-Jolie',
        'Piendamó',
        'Garça',
        'San Vicente',
        'Fond du Lac',
        'Seferhisar',
        'Otwock',
        'Gori',
        'Nsawam',
        'Culasi',
        'Jalacingo',
        'Petatlán',
        'Shekhupur',
        'Masuda',
        'Tsiroanomandidy',
        'Wiener Neustadt',
        'Rohnert Park',
        'Ellenabad',
        'Banaybanay',
        'San Pascual',
        'Mankato',
        'Moorhead',
        'Radomsko',
        'Morley',
        'Karvārakundu',
        'São Sebastião do Passé',
        'Rāpūr',
        'Abū al Maţāmīr',
        'Siyāna',
        'Abovyan',
        'Angul',
        'Bacnotan',
        'Rājaldesar',
        'Robē',
        'Elmadağ',
        'Tongoma',
        'Yaoquan',
        'Santo Antônio da Platina',
        'Sandefjord',
        'Bulalacao',
        'Dalli Rājhara',
        'Houbu',
        'Svishtov',
        'Nandigāma',
        'Vlissingen',
        'Beigangwa',
        'San Jacinto',
        'San Juan y Martínez',
        'Eqlīd',
        'Kołobrzeg',
        'Iwanuma',
        'Kaimana',
        'São Miguel d’Oeste',
        'Pagsanjan',
        'Changtoushang',
        'The Colony',
        'Jisr ash Shughūr',
        'Nanjō',
        'Deinze',
        'General Mamerto Natividad',
        'Çayeli',
        'Barwāh',
        'Chauk',
        'Alaplı',
        'Pinneberg',
        'Labangan',
        'Bom Conselho',
        'Dráma',
        'Sonqor',
        'Medak',
        'Kaarst',
        'Talipparamba',
        'Huatan',
        'Manakara',
        'İznik',
        'Jora',
        'Pattani',
        'Burke',
        'Loon',
        'Saint-Brieuc',
        'Ananipalle',
        'Erkelenz',
        'Majurwa',
        'Freeport',
        'Cateel',
        'Miliana',
        'Tiberias',
        'Maitum',
        'Regla',
        'El Centro',
        'Dikili',
        'Germencik',
        'Al ‘Ayyāţ',
        'Kuybyshev',
        'Rio Grande da Serra',
        'Las Matas de Farfán',
        'Raisen',
        'Albufeira',
        'Harsīn',
        'Turnhout',
        'Shakopee',
        'Central Signal Village',
        'Wilkes-Barre',
        'Mandaon',
        'West Vancouver',
        'Hagi',
        'Daijiazhuang',
        'Tōgō',
        'Tlajomulco de Zúñiga',
        'Roghun',
        'Yartsevo',
        'Zevenaar',
        'Lompoc',
        'Hicksville',
        'Talatamaty',
        'Noordwijk',
        'Jānakammapeta',
        'Georgetown',
        'Oakland Park',
        'Lombard',
        'Otofuke',
        'Concord',
        'Sihora',
        'Dębica',
        'San Vicente de Tagua Tagua',
        'Guinayangan',
        'Al Midhnab',
        'Nocera Inferiore',
        'Murshidābād',
        'Senigallia',
        'Vilvoorde',
        'Boyabat',
        'Korogwe',
        'Ahmadpur',
        'Tinajdad',
        'Limache',
        'Seohāra',
        'Yuchengcun',
        'La Paz',
        'Mucaba',
        'San Jose',
        'Coatbridge',
        'Tarbes',
        'New Tecumseth',
        'Casale',
        'Labason',
        'Yuanli',
        'Bakamune',
        'Goshikichō-aihara-minamidani',
        'Livramento de Nossa Senhora',
        'Comendador',
        'Alès',
        'Wānkāner',
        'Loreto',
        'Wismar',
        'Châlons-en-Champagne',
        'Zongolica',
        'Etten-Leur',
        'Erkrath',
        'Addanki',
        'Berkeley',
        'Hinatuan',
        'Wallsend',
        'Merthyr Tudful',
        'Yirga ‘Alem',
        'Nidadavole',
        'Ciudad Constitución',
        'Beni Saf',
        'Xinjun',
        'Baler',
        'Bellinzona',
        'Varaždin',
        'Anekal',
        'Āzādshahr',
        'Jaro',
        'Rasskazovo',
        'Bietigheim-Bissingen',
        'Sugito',
        'Salto de Pirapora',
        'Himi',
        'Lubuk Sikaping',
        'Shyorongi',
        'Ząbki',
        'Chusovoy',
        'Badian',
        'Thun',
        'Murzuq',
        'Kottangara',
        'Pittsfield',
        'Venray',
        'Kalilangan',
        'North Brunswick',
        'Takāb',
        'Cameron Highlands',
        'Bushenyi',
        'Maştağa',
        'Bagneux',
        'Porsa',
        'Bodupāl',
        'Krasnodon',
        'Tanghin-Dassouri',
        'Alhaurín de la Torre',
        'Puteaux',
        'Rāmachandrapuram',
        'Greenacres',
        'Chembra',
        'Gifhorn',
        'Jinku',
        'Shinshiro',
        'Nijkerk',
        'San Isidro',
        'Santa Elena',
        'Raduzhnyy',
        'Roseller Lim',
        'Villeta',
        'Birsk',
        'Caluire-et-Cuire',
        'Kumatori',
        'Uruará',
        'Cové',
        'Guambog',
        'Prostějov',
        '’Aïn Arnat',
        'Zhexiang',
        'Presidente Epitácio',
        'Shōranūr',
        'Ksar',
        'Rheden',
        'Oakley',
        'Panambi',
        'Kiraz',
        'Jerada',
        'Cimerak',
        'Of',
        'Ponte de Lima',
        'Çeşme',
        'Borken',
        'Bahía Honda',
        'Linden',
        'Heinsberg',
        'Tarauacá',
        'Campbell',
        'Moises Padilla',
        'Paoskoto',
        'Al Kiswah',
        'Hokuto',
        'Danville',
        'Pedro Celestino Negrete',
        'Toboso',
        'Kikuyō',
        'Kilosa',
        'Frosinone',
        'Loreto',
        'Victoria',
        'Bolvadin',
        'Anse Rouge',
        'Puerto del Rosario',
        'Actopan',
        'De Bilt',
        'Nilothi',
        'Dounan',
        'Trelleborg',
        'Yutiancun',
        'Isla',
        'Jambusar',
        'Xihuachi',
        'Sawākin',
        'Innisfil',
        'Turda',
        'Kozáni',
        'Shināş',
        'Hueyapan de Ocampo',
        'El Cuá',
        'General Pacheco',
        'Kōttōppādam',
        'Douar Bni Malek',
        'El Asintal',
        'Villa de Zaachila',
        'Zhongbu',
        'Lota',
        'San Clemente',
        'Andes',
        'North Miami Beach',
        'Vaudreuil-Dorion',
        'Alenquer',
        'Clermont',
        'Silistra',
        'Vargem Grande',
        'San Sebastián Huehuetenango',
        'Aleksinac',
        'Konārak',
        'Paso de los Libres',
        'Ocozocoautla de Espinosa',
        'Maḩallāt',
        'Kula',
        'Khawr Fakkān',
        'San Bruno',
        'Ambāsamudram',
        'Véroia',
        'Laurel',
        'Yeola',
        'Nagaizumi',
        'Channelview',
        'Vahdat',
        'Shanshan',
        'South Upi',
        'Trujillo Alto',
        'Kapan',
        'Nagykanizsa',
        'Aguazul',
        'Darcheh',
        'Berchem',
        'Dock Sur',
        'Vergína',
        'Şān al Ḩajar al Qiblīyah',
        'Sonaguera',
        'Bāniyās',
        'Zhangliangcun',
        'Chicamán',
        'Cumaribo',
        'Maïssade',
        'Khutubi',
        'Ormond Beach',
        'Rantepao',
        'Kāyalpattanam',
        'Kobayashi',
        'Inabe',
        'Dún Dealgan',
        'Vargem Grande do Sul',
        'Sidcup',
        'Macenta',
        'Eṭ Ṭaiyiba',
        'Bakau',
        'Nettetal',
        'Taketoyo',
        'Huber Heights',
        'Rudauli',
        'Dhāka',
        'Lubao',
        'Tešanj',
        'Dunakeszi',
        'Makinohara',
        'Kamen',
        'Alcantarilla',
        'Bron',
        'Yatomi',
        'Barhiya',
        'Woonsocket',
        'Kattivākkam',
        'Kingston upon Thames',
        'Jesús María',
        'Gualeguay',
        'Hillsborough',
        'Santa Cruz',
        'Garbahaarrey',
        'Saoner',
        'Rezé',
        'Yepocapa',
        'Valenciennes',
        'Abucay',
        'Periyakulam',
        'Cuchi',
        'Lai Châu',
        'Middleton',
        'Aurich',
        'Châteauroux',
        'Jatibonico',
        'Heist-op-den-Berg',
        'Santo Antônio',
        'San Cristóbal Totonicapán',
        'Nandongcun',
        'Nandazhang',
        'Buffalo Grove',
        'Badulla',
        'Akdağmadeni',
        'Char Fasson',
        'Zhangcun',
        'Bairāgnia',
        'Sevanagala',
        'Pirthīpur',
        'Bradford West Gwillimbury',
        'Tepalcatepec',
        'Villaba',
        'Shimabara',
        'Mahbūbābād',
        'Santo Tomas',
        'Garges-lès-Gonesse',
        'Fleet',
        'Kalaiyā',
        'Longtoushan Jiezi',
        'Lucena',
        'Puerto Colombia',
        'King’s Lynn',
        'Salaberry-de-Valleyfield',
        'Chefchaouene',
        'Laguna',
        'La Mata',
        'West Babylon',
        'Ban Pet',
        'Nueva Valencia',
        'Al Badārī',
        'Limbdi',
        'Catonsville',
        'Barreiros',
        'Atenco',
        'Mrirt',
        'Itarema',
        'Altadena',
        'Bukama',
        'Naranjo',
        'Ciechanów',
        'Edmonds',
        'Lokeren',
        'Gravina in Puglia',
        'Linton Hall',
        'Thol',
        'Spanish Fork',
        'Hammam Sousse',
        'Newnan',
        'Sarıkamış',
        'Taniyama-chūō',
        'Musina',
        'Orlândia',
        'Meru',
        'Jincheng',
        'Laatzen',
        'Castres',
        'Proper Bansud',
        'Nakano',
        'Quinhámel',
        'Vineyard',
        'Evere',
        'Mengdong',
        'Cabo Bojador',
        'Kafr al Kurdī',
        'Barpeta',
        'Jefferson City',
        'Webuye',
        'Miranda',
        'Kharik',
        'Sidi Mohamed Lahmar',
        'Mondoro',
        'El Affroun',
        'Sukheke Mandi',
        'Manassas',
        'Tecoanapa',
        'Biella',
        'Woodbridge',
        'Zharkent',
        'Puyallup',
        'San Francisco Menéndez',
        'Fianga',
        'Arras',
        'Šibenik',
        'Awaji',
        'Sālār',
        'Nyunzu',
        'Hwange',
        'Simão Dias',
        'Shuilou',
        'Vera Cruz',
        'Machalí',
        'Mata de São João',
        'Panitan',
        'José de Freitas',
        'Thomassique',
        'Jājpur',
        'Sokoura',
        'Tamu',
        'Uruaçu',
        'Allūru',
        'Schertz',
        'Guarda',
        'Amberg',
        'Maroantsetra',
        'Balabac',
        'El Charco',
        'Kōta',
        'Loufan',
        'Kadalur',
        'Coppell',
        'Itapa-Ekiti',
        'Danville',
        'Mariel',
        'North Fort Myers',
        'San Giorgio a Cremano',
        'Jambughoda',
        'Columbia',
        'Dupnitsa',
        'Bairuo',
        'Shebekino',
        'Osinniki',
        'La Orotava',
        'Moline',
        'Seevetal',
        'Kārsiyāng',
        'Dilbeek',
        'Tucuran',
        'Singhanakhon',
        'Sanarate',
        'Carranglan',
        'San Rafael del Sur',
        'Santa Cruz',
        'Beverly',
        'Eisenach',
        'Si Sa Ket',
        'Midland',
        'Xidiancun',
        'Bassano del Grappa',
        'Bang Kruai',
        'Bhatpalli',
        'Santa Rita',
        'Alghero',
        'Balykchy',
        'Melun',
        'Kotabumi',
        'São Mateus do Sul',
        'Thān',
        'Acatlán de Pérez Figueroa',
        'Laungowāl',
        'Akaiwa',
        'Shenjiatun',
        'Annandale',
        'Yaguate',
        'Qā’en',
        'Ben Zakkay',
        'Rouyn-Noranda',
        'Tomé',
        'Iten',
        'Xonobod',
        'Pallisa',
        'Homburg',
        'Kasai',
        'Llanera',
        'Futtsu',
        'Coachella',
        'Coronel Suárez',
        'Alerce',
        'Polanco',
        'Dreieich',
        'Maniwa',
        'Kutno',
        'Hoddesdon',
        'Myanaung',
        'Marki',
        'Akbarpur',
        'President Quirino',
        'Woodlawn',
        'Viborg',
        'Palladam',
        'Uozu',
        'Ansbach',
        'Hilvan',
        'Liloy',
        'Fareham',
        'Meadow Woods',
        'Kārmegh',
        'Aritao',
        'Smederevska Palanka',
        'Machang',
        'Sarakhs',
        'Coram',
        'Qapshaghay',
        'Jocotepec',
        'Thionville',
        'Amontada',
        'Mozdok',
        'Belleville',
        'Nysa',
        'Morarano Chrome',
        'Safonovo',
        'Peachtree Corners',
        'Claveria',
        'Liantang',
        'Ängelholm',
        'Anakaputtur',
        'Diyadin',
        'Cortlandt',
        'Dildārnagar',
        'Cáceres',
        'Jinotepe',
        'Pardés H̱anna Karkur',
        'Kālol',
        'Monchegorsk',
        'Hollister',
        'Villa Bisonó',
        'Sbiba',
        'Bensheim',
        'Esperanza',
        'Pratāpgarh',
        'Bokhtar',
        'Coyuca de Catalán',
        'Puerto Real',
        'Paraíba do Sul',
        'Guaratuba',
        'Quesada',
        'Imperia',
        'Soran',
        'Sarāb',
        'Guaraciaba do Norte',
        'Zhovti Vody',
        'Siegburg',
        'Tineghir',
        'Madridejos',
        'Mampong',
        'San José de Bocay',
        'Miercurea-Ciuc',
        'Holly Springs',
        'Wangyuanqiao',
        'Korenovsk',
        'Nāthdwāra',
        'Dronten',
        'Pā̃chkhāl',
        'Ti̇̄npiple',
        'Santa Rosa del Sur',
        'Štip',
        'Myedu',
        'Songhuajiangcun',
        'Qiryat Moẕqin',
        'Lingquan',
        'Muttayyāpuram',
        'Vawkavysk',
        'Greenock',
        'Matnog',
        'São Lourenço do Sul',
        'Nāikankudi',
        'Mercedes',
        'Puerto Galera',
        'Zhujiacun',
        'Yangcunzai',
        'Yagoua',
        'Nuevo San Carlos',
        'Kolchugino',
        'Hódmezővásárhely',
        'Tactic',
        'Delaware',
        'Hố Nai',
        'Santa María Atzompa',
        'Tiel',
        'Ōizumi',
        'Kirchheim unter Teck',
        'Tororo',
        'Minamishimabara',
        'Amadeo',
        'Qiryat Ono',
        'Schwäbisch Hall',
        'Kibawe',
        'Ban Wat Lak Hok',
        'Larantuka',
        'Barauli',
        'Dunaújváros',
        'Beverwijk',
        'Macaúbas',
        'Montenegro',
        'Kalmar',
        'Marialva',
        'Quezon',
        'Coburg',
        'Yinggen',
        'Tadjenanet',
        'El Aïoun',
        'Urmston',
        'Coyotepec',
        'San Miguel',
        'Miura',
        'Palmares',
        'Rancho Palos Verdes',
        'Diamond Harbour',
        'Waxahachie',
        'Jardinópolis',
        'Satka',
        'Civitanova Marche',
        'Tādif',
        'Köniz',
        'Uden',
        'Sūrampatti',
        'Velasco Ibarra',
        'Kasibu',
        'Paiçandu',
        'Krasnoznamensk',
        'Beitbridge',
        'Dacun',
        'Nārāyanpet',
        'Tôrres',
        'El Consejo',
        'Naga',
        'Boucherville',
        'Santa Bárbara',
        'Fenoarivo Atsinanana',
        'Saidpur Dabra',
        'Shādegān',
        'Dandéresso',
        'Bodø',
        'Álimos',
        'Campechuela',
        'Otavalo',
        'Tavas',
        'Varash',
        'Oued Fodda',
        'Billerica',
        'Tolosa',
        'Kampong Chhnang',
        'Salinas',
        'Ust’-Kut',
        'Wunstorf',
        'Hempfield',
        'Boumerdes',
        'Pueblo Nuevo',
        'Gonzaga',
        'Litian Gezhuang',
        'Blanes',
        'Bayt al Faqīh',
        'Mableton',
        'Jáltipan de Morelos',
        'Přerov',
        'Fitchburg',
        'Gay',
        'Kenge',
        'Daram',
        'Ambohitrimanjaka',
        'Yuzawa',
        'Oqtosh Shahri',
        'Shefar‘am',
        'Le Cannet',
        'Berubāri',
        'Ayagöz',
        'Bullhead City',
        'Su-ngai Kolok',
        'Rājgīr',
        'Socorro',
        'Santa Cruz de Los Taques',
        'Shetou',
        'Sipe Sipe',
        'San Pedro Necta',
        'Dubrovnik',
        'Santa Helena',
        'Lloret de Mar',
        'Indi',
        'Amuntai',
        'Rajaori',
        'Slobozia',
        'Lindi',
        'Anda',
        'Águas Belas',
        'Patulul',
        'Yarumal',
        'Copán',
        'Strezhevoy',
        'Tagudin',
        'San Donà di Piave',
        'Nalhāti',
        'Ruteng',
        'Ventanas',
        'Sānand',
        'Puntarenas',
        'Bourg-en-Bresse',
        'Mission',
        'Puerto Gaitán',
        'Barra dos Coqueiros',
        'Poti',
        'Shimotsuma',
        'Königswinter',
        'Zugdidi',
        'Kodīnar',
        'Sagauli',
        'Golāghāt',
        'Pīleru',
        'Verkhnyaya Salda',
        'Sutton',
        'Marlboro',
        'Karabulak',
        'Sinanpaşa',
        'Llavallol',
        'Desio',
        'Nola',
        'Eberswalde',
        'Memāri',
        'Banī Suhaylā',
        'Tabogon',
        'Teaneck',
        'Svay Rieng',
        'Grove City',
        'Arcos',
        'Lusambo',
        'Mondragon',
        'Yakacık',
        'Maplewood',
        'Kendrāparha',
        'Villa Ángela',
        'Nürtingen',
        'Caerphilly',
        'Marion',
        'Nebbi',
        'Marlborough',
        'Tortuguitas',
        'San',
        'Myski',
        'Naini Tal',
        'Erzin',
        'Santa Cruz',
        'Maulavi Bāzār',
        'Bambari',
        'Germering',
        'Kitaibaraki',
        'Sena Madureira',
        'Tuchín',
        'Narathiwat',
        'Brookfield',
        'Obita',
        'Nordhausen',
        'Tezoyuca',
        'Rāmganj Mandi',
        'El Tocuyo',
        'Santo Antônio de Pádua',
        'Nurdağı',
        'Currais Novos',
        'Mahalapye',
        'Meïganga',
        'Arauquita',
        'Hückelhoven',
        'San Lorenzo',
        'Alcala',
        'Tabarka',
        'Caloundra',
        'Alcalde Díaz',
        'Bridgwater',
        'Leigh',
        'Jayamkondacholapuram',
        'Cherupulasshēri',
        'Mikołów',
        'Guácimo',
        'Hatfield',
        'Wijchen',
        'Panchimalco',
        'Mersa',
        'Niquero',
        'Chaves',
        'Fredericia',
        'Rozzano',
        'French Valley',
        'Santa Eulalia del Río',
        'Schwabach',
        'Schweizer-Reineke',
        'Maayon',
        'Lasam',
        'Hoboken',
        'Mangaratiba',
        'Bacong',
        'Constitución',
        'Shelton',
        'Túquerres',
        'Nossa Senhora da Glória',
        'Trikarpūr North',
        'Shutayil',
        'Agar',
        'San Jose de Urquico',
        'Tanxia',
        'Zákynthos',
        'Antequera',
        'Claypole',
        'Pine Bluff',
        'Peddapalli',
        'Igualada',
        'Kearny',
        'Villa Alsina',
        'Sevilla',
        'Anglet',
        'Geel',
        'Bilāsipāra',
        'Timmins',
        'Halfeti',
        'Guaduas',
        'Toritama',
        'Temryuk',
        'Matiguás',
        'Spassk-Dal’niy',
        'Paliā Kalān',
        'José Mármol',
        'Hālol',
        'Ganta',
        'Kallār',
        'Hallandale Beach',
        'Mambajao',
        'Germantown',
        'Huizen',
        'La Libertad',
        'Angoulême',
        'Ardeşen',
        'Sevilla de Niefang',
        'Rivas',
        'Ambositra',
        'Newbury',
        'Merano',
        'Bujanovac',
        'Komoro',
        'Ishaka',
        'Sumoto',
        'Tagbina',
        'Amami',
        'Pozi',
        'Arraiján',
        'Milton',
        'Tampakan',
        'Unzen',
        'Ciudad Darío',
        'Bazar-Korgon',
        'Ciudad Vieja',
        'Pyt’-Yakh',
        'Upper Hutt',
        'Gatunda',
        'Sarandë',
        'Dongyangshi',
        'Shengaocun',
        'Dongluocun',
        'Segovia',
        'Chambas',
        'Welling',
        'Woburn',
        'Beigang',
        'Hongfengcun',
        'Wujie',
        'Monterotondo',
        'Patti',
        'Esperantina',
        'Marikina Heights',
        'Lancaster',
        'Paranaíba',
        'Drogheda',
        'Bagua Grande',
        'Nanbei',
        'Dowlatābād',
        'Maddela',
        'Lijiaxiang',
        'Reynoldsburg',
        'Yingzhou Linchang',
        'Covington',
        'Salinópolis',
        'Azemmour',
        'Famagusta',
        'Buxtehude',
        'Ciudad Piar',
        'Dhamdāha',
        'Boulogne-sur-Mer',
        'Muaná',
        'Velika Plana',
        'Delījān',
        'Jalalpur Bhattian',
        'Pugachev',
        'Friendswood',
        'Talukkara',
        'Meftah',
        'Penn Hills',
        'Betun',
        'Apóstoles',
        'Weslaco',
        'Malilipot',
        'Patzicía',
        'Santo Tomas',
        "Land O' Lakes",
        'Essex',
        'Tālcher',
        'Soe',
        'Wattrelos',
        'Cuito Cuanavale',
        'Bartlett',
        'Promissão',
        'Anyuan',
        'Qādiān',
        'Fourou',
        'Mobo',
        'Pāmban',
        'Abadan',
        'Buchholz in der Nordheide',
        'Neumarkt',
        'Ahlat',
        'Saint-Germain-en-Laye',
        'Kitob',
        'Westfield',
        'Temsia',
        'Vallehermoso',
        'Swords',
        'Vavveru',
        'Elmalı',
        'Gonābād',
        'Sidi Moussa',
        'Baichigan',
        'Lobo',
        'Sassuolo',
        'Kingswood',
        'Pie de Pató',
        'Buddh Gaya',
        'Shanhe',
        'Katō',
        'Kirkkonummi',
        'Khowrmūj',
        'Annapolis',
        'Villafranca del Panadés',
        'Arys',
        'Ipiaú',
        'Dunstable',
        'DeKalb',
        'Vasto',
        'Mambusao',
        'Maragondon',
        'Cedar Falls',
        'Pirmasens',
        'Itabaianinha',
        'Qeshm',
        'Tomar',
        'Sukuta',
        'Kadūr',
        'Sint-Truiden',
        'Mundakkal',
        'Sherghāti',
        'Nanuque',
        'Veles',
        'Bury Saint Edmunds',
        'Zoumi',
        'Avezzano',
        'Artigas',
        'Ladispoli',
        'Pechora',
        'Barra de São Francisco',
        'Dayr Mawās',
        'Manalapan',
        'Santa Rita do Sapucaí',
        'Nabas',
        'Akyurt',
        'Evren',
        'Yangtangxu',
        'Yatangcun',
        'Lancaster',
        'Njombe',
        'Douar Laouamra',
        'Şəmkir',
        'Pôrto de Moz',
        'Lemgo',
        'Pilāni',
        'Remanso',
        'Siay',
        'Bayındır',
        'Nowgong',
        'Santa Maria da Boa Vista',
        'Metlili Chaamba',
        'Brighton',
        'Bayburt',
        'Ölgiy',
        'Bosconia',
        'Jānjgīr',
        'Uray',
        'Romblon',
        'Andradas',
        'Padre Las Casas',
        'Katsuren-haebaru',
        'Lordegān',
        'San Juan',
        'Villenave-d’Ornon',
        'Gap',
        'Macerata',
        'Cumbal',
        'Guariba',
        'Sovetsk',
        'Freiberg',
        'Erramvāripālem',
        'Corigliano Calabro',
        'Mińsk Mazowiecki',
        'Crystal Lake',
        'Dacun',
        'Halberstadt',
        'Lake Oswego',
        'Severna Park',
        'Pilate',
        'Sosnovoborsk',
        'Krishnarājāsāgara',
        'Rizal',
        'Leinfelden-Echterdingen',
        'Villanueva',
        'Lakeshore',
        'Ramsgate',
        'Findlay',
        'Mārākkara',
        'Huaura',
        'Aracataca',
        'Channarāyapatna',
        'Montélimar',
        'Leyte',
        'Compiègne',
        'Saratoga Springs',
        'Alexandria',
        'New Berlin',
        'Pālampur',
        'Agano',
        'Chebarkul',
        'Hofheim',
        'Reguiba',
        'Zainsk',
        'Almenara',
        'Jhābua',
        'Stains',
        'Vellakkovil',
        'Culver City',
        'Bouar',
        'Beyneū',
        'Curuçá',
        'Balaoan',
        'La Unión',
        'Rubengera',
        'Sabalgarh',
        'Sensuntepeque',
        'Molina',
        'Komono',
        'Kualaserba',
        'Indian Trail',
        'Bañga',
        'Romny',
        'Magburaka',
        'Hellevoetsluis',
        'Payabon',
        'Talas',
        'Colinas',
        'Ampana',
        'Murtajāpur',
        'Kamen’-na-Obi',
        'Peringalam',
        'Autazes',
        'Ōzu',
        'Duncanville',
        'Valley Stream',
        'Yajalón',
        'Belaya Kalitva',
        'Kardítsa',
        'Löhne',
        'Pinamar',
        'Tosya',
        'Ahaus',
        'Afogados da Ingazeira',
        'Majibacoa',
        'Hıdırbey',
        'Fonds Verrettes',
        'Clinton',
        'Camargo',
        'Merta',
        'Uttarkāshi',
        'Boa Esperança',
        'Świnoujście',
        'Schorndorf',
        'Kabuga',
        'Leramatang',
        'Itoigawa',
        'Secunda',
        'El Golea',
        'Havza',
        'Völklingen',
        'Maihar',
        'Begamganj',
        'Gagny',
        'Pedra Branca',
        'Jambe',
        'Santa Quitéria',
        'Salinas',
        'Tiruchendūr',
        'Mokolo',
        'Sejenane',
        'La Rinconada',
        'Myrhorod',
        'The Acreage',
        'Colomiers',
        'Malalag',
        'Balud',
        'Podilsk',
        'Tutayev',
        'Torre Annunziata',
        'Dohazāri',
        'Taysan',
        'Taicheng',
        'Gourcy',
        'Kīlakkarai',
        'Kālimpong',
        'Cihuatlán',
        'Gramado',
        'Romeoville',
        'Oroqen Zizhiqi',
        'Dingras',
        'Heroica Ciudad de Tlaxiaco',
        'Socorro',
        'Phulwāria',
        'Sisak',
        'Luebo',
        'Amursk',
        'Järvenpää',
        'Chiredzi',
        'Soria',
        'Longtang',
        'Raxruhá',
        'Kakrāla',
        'Bragadiru',
        'Santo Domingo',
        'Zagora',
        'Menghan',
        'Hurst',
        'Altagracia de Orituco',
        'Kunigal',
        'Mailapur',
        'Varkkallai',
        'Soavinandriana',
        'Curitibanos',
        'Montana',
        'Mayyanād',
        'Panaji',
        'Poissy',
        'Sieradz',
        'Inverness',
        'Armação dos Búzios',
        'Oraiókastro',
        'Zacualpa',
        'Post Falls',
        'Ihnāsyā al Madīnah',
        'Jbaïl',
        'Matsoandakana',
        'Bukit Gambir',
        'Bandhi',
        'Safdarabad',
        'Jalalabad',
        'Choa Saidan Shah',
        'Ranipur',
        'Lidköping',
        'Kambia',
        'Uchqŭrghon Shahri',
        'Madīnat ‘Īsá',
        'Bamessing',
        'Alashankou',
        'Strood',
        'L’Asile',
        'Chikodi',
        'Rājgarh',
        'Sindgi',
        'Wādegaon',
        'Sardulgarh',
        'Samālkha',
        'Junnar',
        'Salaiya',
        'Tāzah Khūrmātū',
        'Nicastro',
        'Valladolid',
        'Hutchinson',
        'Kasumigaura',
        'Cabatuan',
        'Yalutorovsk',
        'Lishaocun',
        'Herstal',
        'Szigetszentmiklós',
        'Fushë Kosovë',
        'Visoko',
        'Kyaukme',
        'Zimapan',
        'Yian',
        'Santa Cruz del Sur',
        'Jayrūd',
        'Sarāqib',
        'Vyshneve',
        'Xaçmaz',
        'Qiryat Bialik',
        'Brčko',
        'Zhongbai',
        'Chelsea',
        'Santiago Nonualco',
        'Barcellona-Pozzo di Gotto',
        'Ngozi',
        'Acevedo',
        'Waipahu',
        'Lynnwood',
        'Yecapixtla',
        'Sagae',
        'Winslow',
        'Kīsh',
        'Koumra',
        'Northampton',
        'Zvolen',
        'Rāmdurg',
        'Plaridel',
        'Panglao',
        'Braine-l’Alleud',
        'Tago',
        'Matías Romero',
        'Baco',
        'Pinhal',
        'Maintal',
        'Kópavogur',
        'Rauma',
        'Rovereto',
        'Lincoln Park',
        'Huamachuco',
        'Ostfildern',
        'Pamplona',
        'Oshnavīyeh',
        'Villamontes',
        'Sebdou',
        'Amudālavalasa',
        'Fort Lee',
        '’Aïn el Melh',
        'Santo Niño',
        'Cape Girardeau',
        'Atmakūr',
        'Yomitan',
        'Kırkağaç',
        'Palauig',
        'Montclair',
        'Hobbs',
        'Toukountouna',
        'San Nicolas',
        'Carini',
        'Magsaysay',
        'Benenitra',
        'Hangu',
        'Aizawa',
        'Apsheronsk',
        'Massawa',
        'Tivaouane',
        'Maarssen',
        'Draguignan',
        'Shirone',
        'Surin',
        'Novozybkov',
        'Alimodian',
        'Albano Laziale',
        'Kurobeshin',
        'Cantù',
        'Veruela',
        'Pomigliano d’Arco',
        'Caraga',
        'Burjasot',
        'Tianzhong',
        'Ettlingen',
        'Buldon',
        'Masaki',
        'Temascal',
        'Oshakati',
        'Talacogon',
        'Srebrenik',
        'Ţabas',
        'Tulun',
        'Kovvūr',
        'Líbano',
        'Fonseca',
        'Carol Stream',
        'Plant City',
        'Xisa',
        'Douai',
        'Todos Santos Cuchumatán',
        'Wageningen',
        'Nakama',
        'Poblacion',
        'Ninove',
        'Yomra',
        'Capelinha',
        'Aventura',
        'Despatch',
        'Bāsudebpur',
        'Charbagh',
        'Pärnu',
        'Tafí Viejo',
        'Vendrell',
        'Şamaxı',
        'Huanghuajie',
        'La Troncal',
        'Isfisor',
        'Villa del Carbón',
        'Skövde',
        'Manay',
        'Chachoengsao',
        'Villa de San Diego de Ubaté',
        'Jordan',
        'Lebanon',
        'Freital',
        'Djidian Kéniéba',
        'Salcedo',
        'Ihosy',
        'Streamwood',
        'Oviedo',
        'Jiaozishan',
        'Tucumã',
        'Fondi',
        'Kalingalan Caluang',
        'Ḑubā',
        'Écija',
        'Mount Juliet',
        'Farīmān',
        'São Gonçalo dos Campos',
        'Nova Viçosa',
        'Virreyes',
        'Media',
        'Ossining',
        'La Tebaida',
        'Itápolis',
        'Lilio',
        'Pachrūkha',
        'Brant',
        'Siniloan',
        'Alicia',
        'Doğanşehir',
        'San José de Ocoa',
        'Sogod',
        'San Giuliano Milanese',
        'Quincy',
        'Marratxi',
        'Asamankese',
        'Kalu Khan',
        'Lālganj',
        'Islāmpur',
        'Qiryat Yam',
        'Plasencia',
        'Presidente Venceslau',
        'Parral',
        'Whanganui',
        'Xonqa',
        'Issaquah',
        'Sanjiang',
        'Parkland',
        'Guajará-Mirim',
        'Olintepeque',
        'Khāchrod',
        'Żyrardów',
        'Abinsk',
        'Bādepalli',
        'Sijua',
        'Park Ridge',
        'Bagnolet',
        'Morshansk',
        'Kaffrine',
        'Clarin',
        'Marcq-en-Baroeul',
        'Spruce Grove',
        'Placilla de Peñuelas',
        'Seram',
        'Gradačac',
        'Pamplona',
        'Vādāsinor',
        'Abrantes',
        'Amambaí',
        'Naranjal',
        'Damulog',
        'Jacarèzinho',
        'Puerto Berrío',
        'Cocorote',
        'Cesano Maderno',
        'Sadiola',
        'Cacuso',
        'Tangpingcun',
        'Moanda',
        'Marovoay',
        'Istog',
        'Neu Isenburg',
        'Nueva Santa Rosa',
        'Niederkassel',
        'Jogbani',
        'Galkot',
        'Chojnice',
        'Cottage Grove',
        'Guaíra',
        'Takikawa',
        'Bell Gardens',
        'Oliveira',
        'Matinhos',
        'Rio Negrinho',
        'Tarazá',
        'Solan',
        'Hailākāndi',
        'Al Qā‘idah',
        'Magra',
        'Apan',
        'Tamba-Sasayama',
        'Erattukulakkada',
        'Köyceğiz',
        'Nawābganj',
        'Yuzhnoukrainsk',
        'Langen',
        'Yelizovo',
        'Ospino',
        'Ayirāpuram',
        'San Gabriel',
        'Playa Vicente',
        'Warren',
        'Heemskerk',
        'Kampot',
        'Axochiapan',
        'Androka',
        'Guemar',
        'Baikonur',
        'Visconde do Rio Branco',
        'Cacocum',
        'Masamba',
        'Marcos Paz',
        'Mibu',
        'Bayanan',
        'Security-Widefield',
        'Iesi',
        'Manggar',
        'Mettmann',
        'Grants Pass',
        'Ilmenau',
        'Cakung',
        'Keizer',
        'Idangansālai',
        'Çiftlikköy',
        'Ait Ourir',
        'Stendal',
        'Agoncillo',
        'Chittaranjan',
        'Halle',
        'Sual',
        'Penfield',
        'Moatize',
        'Roy',
        'Nueva Rosita',
        'Susurluk',
        'Pirna',
        'Huanren',
        'Narón',
        'Partāpnagar',
        'San Dionisio',
        'Sidi Bibi',
        'Weißenfels',
        'Lluchmayor',
        'Revelganj',
        'Torre-Pacheco',
        'Marinha Grande',
        'Sébékoro',
        'Camaná',
        'Mitsuke',
        'Kaseda-shirakame',
        'Amla',
        'Rtishchevo',
        'Toumodi',
        'Ambatomainty',
        'Al Mālikīyah',
        'Trofa',
        'Ramallah',
        'Bettendorf',
        'Kachkanar',
        'Cleethorpes',
        'Nuevitas',
        'Betamcherla',
        'Ciudad Melchor Múzquiz',
        'Ancud',
        'Sidi Khaled',
        'Gornji Milanovac',
        'Várzea Alegre',
        'Nongzhangjie',
        'Tromsø',
        'Pala Oua',
        'San Fernando de Henares',
        'Sayansk',
        'Sciacca',
        'La Chaux-de-Fonds',
        'Gabaldon',
        'Nabarūh',
        'Goes',
        'Mafamude',
        'São Fidélis',
        'São Raimundo Nonato',
        'Dibaya-Lubwe',
        'Nallūr',
        'Galeana',
        'Königs Wusterhausen',
        'Yangfang',
        'Brumadinho',
        'Staoueli',
        'Penumūr',
        'Westerville',
        'Caluya',
        'Wālājāpet',
        'Huangyoutang',
        'Garalo',
        'Changchunpu',
        'Juan Rodríguez Clara',
        'San Nicolas',
        'San Raimundo',
        'Tuvāgudi',
        'Empalme',
        'Draa el Mizan',
        'Cabugao',
        'Higashimatsushima',
        'Xiwanzi',
        'Royal Palm Beach',
        'Dwārka',
        'Haverstraw',
        'Birmitrapur',
        'Apache Junction',
        'Pehowa',
        'Inashiki',
        'Taşköprü',
        'Saryaghash',
        'Akçakoca',
        'Tshela',
        'Hitachiomiya',
        'São Mateus do Maranhão',
        'Navarre',
        'Ngororero',
        'Aïn Tedeles',
        'Wheeling',
        'Ohrid',
        'Lake Stevens',
        'Skelmersdale',
        'Santa Helena de Goiás',
        'Nūrpur',
        'Rexburg',
        'Pervomaisk',
        'Ermezinde',
        'Ōmagari',
        'Dubbo',
        'Nyköping',
        'Mehidpur',
        'Tipton',
        'Lambaréné',
        'Campina Grande do Sul',
        'Ban Bang Khu Lat',
        'Eccles',
        'Braintree',
        'Gujō',
        'Fasano',
        'Urbana',
        'Aborlan',
        'Shrewsbury',
        'Valencia',
        'Penalva',
        'Los Palacios',
        'Yoshinogawa',
        'Villepinte',
        'Ouro Branco',
        'Rosenberg',
        'Tinnanūr',
        'Dzhankoi',
        'Barbacoas',
        'Cajibío',
        'Los Palacios y Villafranca',
        'Pinner',
        'Monreale',
        'Taibao',
        'Great Yarmouth',
        'Paracuru',
        'Yako',
        'Real',
        'Sakuragawa',
        'Kwai Chung',
        'Vetapālem',
        'Jamindan',
        'Kamp-Lintfort',
        'Margosatubig',
        'Tonbridge',
        'Tianchang',
        'Zacatelco',
        'West Fargo',
        'Pereslavl’-Zalesskiy',
        'Paravūrkambolam',
        'Tuusula',
        'Považská Bystrica',
        'Ilkeston',
        'Shibata',
        'Mariinsk',
        'Voghera',
        'Armavir',
        'Metlaoui',
        'San José de Las Matas',
        'Ábrego',
        'San Fernando',
        'La Presa',
        'Dabola',
        'Kampung Baharu Nilai',
        'Menomonee Falls',
        'Vengat',
        'Santa Maria da Vitória',
        'Arucas',
        'Vestavia Hills',
        'Calexico',
        'Würselen',
        'Ciampino',
        'Valrico',
        'Aketi',
        'Køge',
        'Schio',
        'Leyland',
        'Ibusuki',
        'Champerico',
        'Leribe',
        'Papenburg',
        'La Vergne',
        'Ban Na Pa',
        'Sungandiancun',
        'Vangaindrano',
        'Strängnäs',
        'Achaguas',
        'Khalkhāl',
        'Aziylal',
        'Glenrothes',
        'Alingsås',
        'Santa Clara del Cobre',
        'Bərdə',
        'Klinë',
        'Rājula',
        'Atlantic City',
        'Konakovo',
        'San Andres',
        'Caibarién',
        'Nishiwaki',
        'Rosário',
        'Sarikishty',
        'Al Ghizlānīyah',
        'La Unión',
        'San Felipe Orizatlán',
        'Ingeniero Pablo Nogués',
        'Emîr Abdelkader',
        'Maasin',
        'Saint-Martin-d’Hères',
        'Coto Brus',
        'Clovis',
        'Mahādeopur',
        'Chartres',
        'Szczecinek',
        'Minas',
        'Saronno',
        'Olutanga',
        'Novodvinsk',
        'Lanyi',
        'Genç',
        'Buug',
        'Chorley',
        'Chacabuco',
        'Peachtree City',
        'Phenix City',
        'Sibaté',
        'Melmadai',
        'Buchireddipālem',
        'Shijiazhuangnan',
        'Hammam-Lif',
        'Qiaotouyi',
        'Darāw',
        'Krasnoufimsk',
        'DeLand',
        'Herne Bay',
        'Vredenburg',
        'Kaka',
        'Usinsk',
        'Fribourg',
        'Miyoshidai',
        'Nindirí',
        'Bougara',
        'Misawa',
        'Mtsensk',
        'Atamyrat',
        'Waregem',
        'Ripollet',
        'Kurchatov',
        'Xiaguanying',
        'Marcianise',
        'Steyr',
        'Patnongon',
        'Alekseyevka',
        'Mechanicsville',
        'Itaitinga',
        'Novo Horizonte',
        'Wilrijk',
        'Nigel',
        'Bayan',
        'Şarkışla',
        'Samal',
        'Chuangjian',
        'Khairtal',
        'Mmabatho',
        'Iturama',
        'Krasnyy Sulin',
        'Azzano',
        'Stanton',
        'Maule',
        'Laur',
        'Tāki',
        'Am-Timan',
        'Siyang',
        'Maibara',
        'Antsohihy',
        'Xiushui',
        'Ibara',
        'Barrancas',
        'Zavolzhye',
        'Matale',
        'Brasschaat',
        'Bat Khela',
        'Città di Castello',
        'Dicle',
        'Menglie',
        'Pitangueiras',
        'Holyoke',
        'Greven',
        'Winter Springs',
        'Américo Brasiliense',
        'Bishops Stortford',
        'Xico',
        'Mechelen-aan-de-Maas',
        'Baksan',
        'Wesseling',
        'Borbon',
        'Joué-lés-Tours',
        'Naushahro Firoz',
        'Porur',
        'Pallipram',
        'Oeiras',
        'Tayasan',
        'Zacualtipán',
        'Kehl',
        'Fereydūn Kenār',
        'Karuvambram',
        'Matozinhos',
        'Choybalsan',
        'Bautzen',
        'Owasso',
        'Bradford',
        'Prattville',
        'Cananea',
        'East Point',
        'Trujillo',
        'Campbell River',
        'Navgilem',
        'Vavuniya',
        'Quba',
        'Entre Rios',
        'Orange',
        'San Germán',
        'Shankarpur Khawās',
        'Shengang',
        'Veghel',
        'Sabang',
        'Clifton Park',
        'Savelugu',
        'Thakraha',
        'San Mateo',
        'Mapandan',
        'Prokuplje',
        'Mantingan',
        'Pacifica',
        'Bol’shoy Kamen’',
        'Bangar',
        'Dubrājpur',
        'Hot Springs',
        'Shambhunāth',
        'Gūdārah',
        'Aristóbulo del Valle',
        'Zhongdong Shequ',
        'Rūdsar',
        'Abū Qīr',
        'Gurais',
        'San Pédro Jocopilas',
        'Bagabag',
        'Dengtangcun',
        'Sidi Yahia El Gharb',
        'Sama',
        'Tlapa de Comonfort',
        'Mucuri',
        'Olot',
        'Yamen',
        'Adelanto',
        'Backnang',
        'Princeton',
        'San Juan Cancuc',
        'Qaratog',
        'Northglenn',
        'Goribidnūr',
        'Kéniéran',
        'Ali Sabieh',
        'Tupelo',
        'Hajīn',
        'Biougra',
        'La Quinta',
        'Ureña',
        'Sampués',
        'San Adrián de Besós',
        'Annemasse',
        'Raalte',
        'Srīnagar',
        'Puri',
        'Rusape',
        'Shakhtīnsk',
        'Pedro II',
        'Bitterfeld',
        'Dhanera',
        'Guayacanes',
        'Obala',
        'Andkhōy',
        'Świdnik',
        'Giyon',
        'Luuk',
        'Celje',
        'Gampola',
        'Ameca',
        'Namlea',
        'Elmont',
        'Tlalixcoyan',
        'Cerveteri',
        'Dibulla',
        'Mission Bend',
        'Tumba',
        'Gaspar Hernández',
        'Pen',
        'Bangkinang',
        'Montclair',
        'Izobil’nyy',
        'Mateus Leme',
        'Güira de Melena',
        'La Puente',
        'Santaluz',
        'Carpentersville',
        'Koboko',
        'Al Bayḑā’',
        'Al Qaryatayn',
        'Emirdağ',
        'Santa Pola',
        'Pentecoste',
        'Oleiros',
        'Cheltenham',
        'Cheyyār',
        'Pathanāmthitta',
        'Mahanoro',
        'Kaštel Stari',
        'Teijlingen',
        'Nové Zámky',
        'Tudela',
        'Belleville',
        'Ohangaron',
        'Patía',
        'Sillanwali',
        'San Antonio de Padua',
        'Charaut',
        'San Sebastián de Mariquita',
        'Kwidzyn',
        'Arnold',
        'Santo Domingo',
        'Afuá',
        'Marantao',
        'Rāni',
        'Long Eaton',
        'Prince Albert',
        'Güzelbahçe',
        'Calpulalpan',
        'Jalapa',
        'Karuhatan',
        'Manali',
        'Sun City',
        'Mixquiahuala de Juarez',
        'Mamun',
        'Haedo',
        'Coondapoor',
        'Hanumānnagar',
        'Portage',
        'Mombaça',
        'Gomoh',
        'São Francisco do Conde',
        'Yuzhnouralsk',
        'Matina',
        'San Marcelino',
        'Aklera',
        'Umi',
        'Uchaly',
        'Apia',
        'Hilton Head Island',
        'Jacundá',
        'Melchor Ocampo',
        'Yolöten',
        'Mizunami',
        'Falāvarjān',
        'Rāwatbhāta',
        'Satānā',
        'Tōkai',
        'Custódia',
        'Basavana Bāgevādi',
        'Massapê',
        'Galloway',
        'Vasylkiv',
        'Fürstenfeldbruck',
        'Villagarcía de Arosa',
        'Basista',
        'Sanchahe',
        'Les Abricots',
        'Riviera Beach',
        'Venlo',
        'Tietê',
        'Coalville',
        'Huzūrābād',
        'Monrovia',
        'Yanqi',
        'Kolongo-Bozo',
        'Benjamin Constant',
        'Attingal',
        'Hatta',
        'Malbork',
        'Foothill Farms',
        'Bom Jardim',
        'Jeremoabo',
        'Rome',
        'Uryupinsk',
        'Taounate',
        'Warendorf',
        'Stirling',
        'Su’ao',
        'Rio Brilhante',
        'Malavalli',
        'South Valley',
        'Salto del Guairá',
        'Lampa',
        'New Albany',
        'Sirinhaém',
        'Lewiston',
        'Akhtubinsk',
        'Bolesławiec',
        'Rio Branco do Sul',
        'Kranj',
        'Bamendjou',
        'Villaflores',
        'Dubno',
        'Mira',
        'Paraty',
        'Neuilly-sur-Marne',
        'Winchester',
        'Kahrīzak',
        'Gabú',
        'Greenfield',
        'Franconville',
        'Bletchley',
        'Dalnegorsk',
        'Tuxpan',
        'Perote',
        'Atalaia',
        'Georgetown',
        'Tosno',
        'Phônsavan',
        'El Bordo',
        'Allahabad',
        'Pokrov',
        'Baocheng',
        'Turiaçu',
        'Ma‘arratmişrīn',
        'Itaqui',
        'Teboulba',
        'Don Benito',
        'Tambulig',
        'Bonito',
        'Goryachiy Klyuch',
        'Leighton Buzzard',
        'Paracho de Verduzco',
        'San Carlos',
        'Souma',
        'Tuttlingen',
        'Evans',
        'San Andres',
        'Pleasant Grove',
        'Oras',
        'San Antonio',
        'Lovech',
        'Malgobek',
        'Donji Kakanj',
        'Amargosa',
        'Cansanção',
        'Porteirinha',
        'Santa Rosa de Osos',
        'Samobor',
        'Sārangpur',
        'Sandūr',
        'Yugorsk',
        'Kanigiri',
        'Kérou',
        'Oregon City',
        'Magallanes',
        'Gorinchem',
        'Airdrie',
        'Ambatofinandrahana',
        'Trou du Nord',
        'Shiji',
        'Agudos',
        'Mabuhay',
        'Selçuk',
        'Jaito',
        'Argun',
        'Savigny-sur-Orge',
        'Phulbāni',
        'Progreso',
        'Bayur',
        'Tozeur',
        'Conchagua',
        'Grimbergen',
        'Bečej',
        'Villa González',
        'Eboli',
        'Mullaittivu',
        'Blyth',
        'Fengguangcun',
        'Beckum',
        'Besni',
        'Sitangkai',
        'Luna',
        'Ataq',
        'Bartlesville',
        'Santa Cruz',
        'Al Hāshimīyah',
        'Sanankoroba',
        'Lahār',
        'Falun',
        'Dajiecun',
        'São Manuel',
        'Pilibangan',
        'Tibú',
        'Mariano Escobedo',
        'Göygöl',
        'Port Talbot',
        'Rock Island',
        'Landgraaf',
        'Česká Lípa',
        'Lydenburg',
        'Gajendragarh',
        'Jāle',
        'Yamoussoukro',
        'Paravūr Tekkumbhāgam',
        'Bouznika',
        'Jelilyüzi',
        'Mankayan',
        'Mölndal',
        'Andilamena',
        'Katsuragi',
        'Melgar',
        'Kaman',
        'Hanover Park',
        'Alapayevsk',
        'Vettūr',
        'Comapa',
        'Salvatierra',
        'Rukungiri',
        'Leavenworth',
        'Mangai',
        'Moerdijk',
        'Chunār',
        'Wangsicun',
        'Tunzi',
        'Laindon',
        'Kizilyurt',
        'Rezh',
        'Ratia',
        'Kadiyam',
        'Ciudad Manuel Doblado',
        'Minami-Bōsō',
        'Formia',
        'Silves',
        'Bantay',
        'Bahādurganj',
        'Imbatug',
        'Maşyāf',
        'Obburdon',
        'Qulsary',
        'Adjumani',
        'Qo‘ng‘irot Shahri',
        'Binəqədi',
        'Languyan',
        'Boryslav',
        'Martinez',
        'Amarante do Maranhão',
        'Sittard',
        'Long Lama',
        'Kalutara',
        'Redcar',
        'Jagdīspur',
        'Siribala',
        'Chorkŭh',
        'Prijepolje',
        'Porsgrunn',
        'Cloppenburg',
        'Sitalkuchi',
        'Tokār',
        'Kalinkavichy',
        'Llanelli',
        'Pujali',
        'Kālihāti',
        'Lesozavodsk',
        'Kampene',
        'Coesfeld',
        'Beslan',
        'Thonon-les-Bains',
        'Mol',
        'Holstebro',
        'Lagkadás',
        'Greer',
        'Suhl',
        'Bentota',
        'Mandoto',
        'Manazary',
        'Beeston',
        'Dargot',
        'Dāchepalle',
        'Kyshtym',
        'Granadero Baigorria',
        'Tucker',
        'La Ciotat',
        'Villeta',
        'Pennsauken',
        'Baishi Airikecun',
        'Santiago Sacatepéquez',
        'Dom Pedrito',
        'Stara Gora',
        'Nakodar',
        'Bucha',
        'Richmond West',
        'Nanshuicun',
        'Tabligbo',
        'Shaxi',
        'Oświęcim',
        'Lūnāvāda',
        'Ban Doi Suthep',
        'Aourir',
        'Şuhut',
        'Shengli',
        'Port-Margot',
        'Monatélé',
        'Muskogee',
        'Campos Novos',
        'Guilderland',
        'Moalboal',
        'Dālkola',
        'Bindki',
        'Colón',
        'Santa Ana',
        'Kānkuria',
        'Chaiyaphum',
        'Netishyn',
        'Chimboy Shahri',
        'Basilisa',
        'San Enrique',
        'Segrate',
        'Vavur',
        'Pingxiangcheng',
        'Small Heath',
        'Grugliasco',
        'Penticton',
        'Dagua',
        'Moncada',
        'Claremont',
        'Manucan',
        'Siyabuswa',
        'Musiri',
        'Anosiala',
        'Águilas',
        'Kearns',
        'East Meadow',
        'Pāthri',
        'Fāraskūr',
        'Échirolles',
        'San Miguel Chicaj',
        'Nowa Sól',
        'Zuojiawu',
        'Pylaía',
        'Sagnay',
        'Kesavapuram',
        'Maddaloni',
        'Mahē',
        'Missão Velha',
        'Wildomar',
        'Brighton',
        'Caimito',
        'Erding',
        'Richfield',
        'Marijampolė',
        'Magsaysay',
        'Ipueiras',
        'Agogo',
        'Elixku',
        'Qalādizay',
        'Uster',
        'Salima',
        'Camberley',
        'Kanie',
        'La Macarena',
        'Sayula',
        'Châtillon',
        'Tuensang',
        'Mulavana',
        'Coatepeque',
        'Kona',
        'Manoli',
        'Dargaz',
        'Köneürgench',
        'Lakshmeshwar',
        'Sōma',
        'Taxisco',
        'Västervik',
        'San José',
        'Houghton le Spring',
        'Zīra',
        'Wangtuan',
        'Tsubata',
        'Kāramadai',
        'Torres Novas',
        'Mandaguari',
        'Esik',
        'Yasugichō',
        'Or Yehuda',
        'Enrile',
        'Michalovce',
        'Kambove',
        'Yovon',
        'Xinpo',
        'Anserma',
        'Robertsganj',
        'Esquel',
        'Beni Khiar',
        'Estero',
        'Ikongo',
        'Thāna Bhawan',
        'Irbit',
        'Pande',
        'Risalpur Cantonment',
        'Sītākund',
        'Sorel-Tracy',
        'Lierre',
        'Ulan Hua',
        'Beloit',
        'Kulp',
        'Hojāi',
        'José Bonifácio',
        'Matan',
        'Rosário do Sul',
        'Lajedo',
        'Belalcázar',
        'Esch-sur-Alzette',
        'Whitley Bay',
        'Aurora',
        'Las Navas',
        'Demirci',
        'Kannan',
        'Makan',
        'Kidangazhi',
        'Sinsheim',
        'Rossano',
        'Mek’ī',
        'Cantanhede',
        'Denton',
        'Natick',
        'Schaffhausen',
        'Jaral del Progreso',
        'São João da Barra',
        'Ebebiyín',
        '‘Afrīn',
        'Tunduma',
        'Dabutou',
        'Nemmara',
        'Chippenham',
        'Baishan',
        'Punārakh',
        'Barra do Choça',
        'Oakton',
        'Patāmundai',
        'Bulicun',
        'Benāpol',
        'Songo',
        'Horasan',
        'Central Islip',
        'Bindé',
        'Şile',
        'Haomen',
        'Franklin',
        'Neiba',
        'Kodaikānal',
        'Dashtobod',
        'Mamfe',
        'Chelora',
        'Upper Arlington',
        'Knurów',
        'Berat',
        'Cambrils',
        'Nueve de Julio',
        'Zhetisay',
        'Ichchāpuram',
        'West Bridgford',
        'Kaniama',
        'Bossangoa',
        'Guápiles',
        'Lecherías',
        'Sonsón',
        'Arawa',
        'Mazıdağı',
        'Zinzana',
        'Tocancipá',
        'Copperas Cove',
        'Rende',
        'Pūrna',
        'Liwonde',
        'Estahbān',
        'Byumba',
        'Anse d’Hainault',
        'Andover',
        'Holubivske',
        'Simbahan',
        'Porta Westfalica',
        'Banaz',
        'Monaco',
        'Namakgale',
        'Maur',
        'Emsdetten',
        'Cegléd',
        'San Pablo',
        'Ratchaburi',
        'Sandanski',
        'Yahyalı',
        'Fuchūchō',
        'Borehamwood',
        'Barra Bonita',
        'Tomatlán',
        'Bjelovar',
        'Tooele',
        'Danihe',
        'Wołomin',
        'Tomelloso',
        'Jarosław',
        'San Juan Cotzal',
        'Mpessoba',
        'Kajaani',
        'Winsen',
        'Dinas',
        'Nargund',
        'Cabanglasan',
        'Oak Creek',
        'Cumberland',
        'Santiago Juxtlahuaca',
        'Kurtalan',
        'Spoleto',
        'Aketao',
        'Parappur',
        'Coatepec Harinas',
        'Yandian',
        'Melito di Napoli',
        'Teruel',
        'Kotma',
        'Prey Veng',
        'Tuntum',
        'San Miguel Acatán',
        'Beiya',
        'Siayan',
        'Cuenca',
        'Mühlhausen',
        'Yorktown',
        'Modugno',
        'Capalonga',
        'Randallstown',
        'Athis-Mons',
        'Isla de Maipo',
        'Yejituo',
        'Merrillville',
        'Palapye',
        'Canaman',
        'Bollate',
        'Six-Fours-les-Plages',
        'Cuyotenango',
        'Göyçay',
        'San Bernardo del Viento',
        'Gasan',
        'Voerde',
        'Mieres',
        'Onteniente',
        'Taastrup',
        'Ouled Beni Messous',
        'La Vallée de Jacmel',
        'Aparecida',
        'Madingou',
        'Chelmsford',
        'Dagami',
        'Kalāleh',
        'Nyaungdon',
        'San Luis',
        'Temple City',
        'Zacatepec',
        'Cisterna di Latina',
        'Boende',
        'Ban Mueang Na Tai',
        'Rapu-Rapu',
        'Carrollwood',
        'Nellāya',
        'Barira',
        'Petit-Trou de Nippes',
        'Pingshang',
        'Ewing',
        'Pestel',
        'Sareh Mowndeh',
        'Bālarāmpuram',
        'Amancio',
        'Mingjian',
        'Kishmat Dhanbāri',
        'Dīnhāta',
        'Dighwāra',
        'Komatsushimachō',
        'Garhākota',
        'Meppen',
        'Guáimaro',
        'Lunsar',
        'Creil',
        'Hilliard',
        'Girau do Ponciano',
        'Châtelet',
        'Al Jabāyish',
        'Frankston',
        'Apodi',
        'Gutalac',
        'Dunedin',
        'Castricum',
        'Kamenicë',
        'Berriozábal',
        'Sunbury',
        'El Plan',
        'East Kelowna',
        'Kanada',
        'Moorpark',
        'Sokol',
        'Roseville',
        'Maspalomas',
        'Piuí',
        'Pioltello',
        'Limburg',
        'Sibuco',
        'Hillerød',
        'Tamamura',
        'Matanog',
        'Claveria',
        'Masasi',
        'Vempalle',
        'Wai',
        'Virú',
        'Bombardopolis',
        'Mālpura',
        'Saparua',
        'Pihānī',
        'Guayaramerín',
        'Bel-Air',
        'Żary',
        'Shirdi',
        'Ingelheim',
        'San Carlos Sija',
        'Vohipeno',
        'Ampasina-Maningory',
        'Mugumu',
        'Bugiri',
        'Farafenni',
        'Nīm ka Thāna',
        'Taloda',
        'Egypt Lake-Leto',
        'La Libertad',
        'Rāmsar',
        'Naduvattam',
        'Chekkal',
        'Krong Kep',
        'Farmers Branch',
        'Tlokweng',
        'Arroyomolinos',
        'Boyarka',
        'Minas',
        'Pandan',
        'Sarıgöl',
        'Seika',
        'Saint-Raphaël',
        'Uto',
        'Tarikere',
        'Oued Athmenia',
        'Anloga',
        'Hellendoorn',
        'Rumonge',
        'Kamenka',
        'Marion',
        'Conflans-Sainte-Honorine',
        'Az̧ Z̧āhirīyah',
        'Ambohibary',
        'San Lorenzo de Guayubín',
        'Yangshuwa',
        'Lauderdale Lakes',
        'Villefranche-sur-Saône',
        'Mokokchūng',
        'Chillum',
        'Majhaul',
        'Kakhovka',
        'Malmesbury',
        'Daiyue',
        'San Antonio',
        'Hyde',
        'Chernyakhovsk',
        'Dinangorou',
        'Partūr',
        'Chieri',
        'Görükle',
        'Meyzieu',
        'Santa Cruz Verapaz',
        'Lyudinovo',
        'Hendon',
        'Caivano',
        'Dumbéa',
        'Vyazniki',
        'Jalārpet',
        'Dar Chabanne',
        'Maragogipe',
        'Tirkadavūr',
        'Oleśnica',
        'Beja',
        'Orangevale',
        'Falkirk',
        'Huzūrnagar',
        'Sun Prairie',
        'Sainte-Geneviève-des-Bois',
        'Ampelókipoi',
        'Karak',
        'Munro',
        'Jagna',
        'Agualva',
        'Pantnagar',
        'Cedar City',
        'Protvino',
        'Zaïo',
        'Igarapé-Açu',
        'Kāttipparutti',
        'Taozhou',
        'Tambolaka',
        'Fermo',
        'Andújar',
        'Varberg',
        'La Porte',
        'Smarhon',
        'Commack',
        'Caltagirone',
        'Azogues',
        'Crailsheim',
        'Prestea',
        'Riverhead',
        'Bad Vilbel',
        'Kalakkādu',
        'Vavatenina',
        'Burriana',
        'Tam Hiệp',
        'Svetlograd',
        'Aliança',
        'Kharagpur',
        'Nawan Shahr',
        'Pak Chong',
        'Leamington',
        'Guane',
        'Haguenau',
        'Kumo',
        'Camiri',
        'Norristown',
        'Calumet City',
        'South Miami Heights',
        'Vršac',
        'Ẕefat',
        'Numancia',
        'Santa Ana',
        'Addison',
        'Iporá',
        'Macomia',
        'Collo',
        'Usuki',
        'Shiggaon',
        'Mahna',
        'Kinel',
        'Sōsa',
        'Inver Grove Heights',
        'Graaff-Reinet',
        'Hole Narsipur',
        'Santiago de Tolú',
        'Câmara de Lobos',
        'Miyajima',
        'Gürpınar',
        'Leer',
        'Guacarí',
        'Frolovo',
        'Coventry',
        'El Mirage',
        'Goiatuba',
        'Chītāpur',
        'Richmond',
        'Miranda de Ebro',
        'Port Shepstone',
        'Awa',
        'Al ‘Aqīq',
        'Aguelmous',
        'Setouchi',
        'Zhentang',
        'Walkden',
        'Chortoq',
        'Medina',
        'Medchal',
        'Midvale',
        'Kendall West',
        'Casiguran',
        'Kenton',
        'Kula',
        'Cha-am',
        'San Andrés Itzapa',
        'Hoogvliet',
        'Narsampet',
        'Shangzhuangcun',
        'Isumi',
        'Uravakonda',
        'Savanūr',
        'Yerköy',
        'Bariri',
        'Lima',
        'Niamina',
        'Freehold',
        'Pishin',
        'Sanok',
        'Sykiés',
        'Rafiganj',
        'Aravankara',
        'Mahabo',
        'Pambujan',
        'Vitrolles',
        'Yangambi',
        'Sirohi',
        'Belluno',
        'Gahanna',
        'Lökbatan',
        'Savonlinna',
        'Olney',
        'Rifu',
        'Miahuatlán de Porfirio Díaz',
        'Guamá Abajo',
        'Torrington',
        'Şaḩneh',
        'Udhampur',
        'Gigante',
        'Sungaiselam',
        'Kaneohe',
        'Villeneuve-Saint-Georges',
        'Coevorden',
        'North Ridgeville',
        'Umarga',
        'Ringsaker',
        'Hamīrpur',
        'La Concordia',
        'Simiganj',
        'Cartagena del Chairá',
        'Woodley',
        'Cutral-Có',
        'San Juan',
        'Yayladağı',
        'Midlothian',
        'Ténès',
        'Accrington',
        'Pola',
        'Ōfunato',
        'Lugoj',
        'Bôca do Acre',
        'Tamuín',
        'Tallkalakh',
        'Sandino',
        'Salisbury',
        'Savalou',
        'Spišská Nová Ves',
        'Ulliyil',
        'Socorro',
        'Fuquay-Varina',
        'Feijó',
        'Tomobe',
        'Lage',
        'Santuario',
        'Pinerolo',
        'Matanzas',
        'Casalecchio di Reno',
        'Oakville',
        'Boyarka',
        'Dayr Ḩāfir',
        'Kundian',
        'Zorgo',
        'Bou Noura',
        'Angra do Heroísmo',
        'Aïne Draham',
        'Pampatar',
        'Buhriz',
        'Bautista',
        'Ratnapur',
        'Morong',
        'Baohezhuangcun',
        'Hamidiye',
        'Guskhara',
        'Millerovo',
        'Samokov',
        'Pilar',
        'Morohongō',
        'Unnan',
        'Yongjing',
        'Rio Real',
        'Akçadağ',
        'West Hollywood',
        'Pilão Arcado',
        'Dama',
        'Ürgüp',
        'La Palma',
        'Westmont',
        'Bragança',
        'Fruit Cove',
        'Timbuktu',
        'Kilimli',
        'Tonalá',
        'Benton',
        'Yarumal',
        'Saint-Benoît',
        'Conceição de Jacuípe',
        'Villa Adelina',
        'Barra do Bugres',
        'Tahuna',
        'Brunswick',
        'Yasynuvata',
        'Villa Ballester',
        'Newton',
        'Juban',
        'Bedēsa',
        'Kerava',
        'Mörfelden-Walldorf',
        'Ciudad de Allende',
        'Cieza',
        'El Hajeb',
        'Chakapara',
        'Quimbaya',
        'Wildwood',
        'Sūrandai',
        'Goch',
        'Aizumi',
        'Diabali',
        'Bridlington',
        'Czechowice-Dziedzice',
        'Tangancícuaro de Arista',
        'Pansol',
        'Douglasville',
        'Chandanais',
        'Voznesensk',
        'Gandara',
        'San Javier',
        'Evergem',
        'Jieshang',
        'Azuqueca de Henares',
        'Allacapan',
        'Yecla',
        'Cento',
        'Zvishavane',
        'Mirnyy',
        'Roi Et',
        'Usilampatti',
        'Baturité',
        'Sankt Ingbert',
        'Kamata',
        'Bria',
        'Winchester',
        'Tilingzhai',
        'Buenos Aires',
        'San Luis',
        'Springville',
        'Bou Salem',
        'Datteln',
        'San Luis',
        'Zaventem',
        'Praya',
        'Watertown Town',
        'Abra de Ilog',
        'Izkī',
        'Bom Jesus do Itabapoana',
        'Perunād',
        'Deggendorf',
        'Woodstock',
        'Yefremov',
        'Billingham',
        'Aktuluk',
        'Hammam Bou Hadjar',
        'Barsinghausen',
        'Soteapan',
        'Mahasolo',
        'Sulop',
        'Giddalūr',
        'Mananara Avaratra',
        'Lingig',
        'Jaguariaíva',
        'Pāppinisshēri',
        'Fair Oaks',
        'Tāzhakara',
        'Manhattan Beach',
        'Trinidad',
        'Ikalamavony',
        'Pandaul',
        'Adamantina',
        'Bibhutpur',
        'Qazax',
        'Steinfurt',
        'Rāwatsār',
        'San Juan Capistrano',
        'Puerto López',
        'Effia-Kuma',
        'Pulppatta',
        'Cassino',
        'Chrzanów',
        'Lebowakgomo',
        'Al Ḩişn',
        'Plainfield',
        'Kherrata',
        'Confresa',
        'Saint-Chamond',
        'Salinas',
        'Balasan',
        'Vinces',
        'Umarkot',
        'Casilda',
        'Yellandu',
        'Suong',
        'Río Grande',
        'Ouro Preto d’Oeste',
        'Phatthalung',
        'Ieper',
        'Bethlehem',
        'Chur',
        'Tebesbest',
        'Guimbal',
        'San Luis de Sincé',
        'San Pedro de Ycuamandiyú',
        'Unión de Reyes',
        'Munai',
        'Charqueadas',
        'Palotina',
        'Māranchēri',
        'Blagoveshchensk',
        'Kōṯah-ye ‘As̲h̲rō',
        'Maīdān Shahr',
        'Wokha',
        'Prado',
        'Mannar',
        'Awbārī',
        'Firavahana',
        'Fotadrevo',
        'Ibrā’',
        'Chakdarra',
        'Negotin',
        'Luga',
        'Ḑulay‘ Rashīd',
        'Shaqrā’',
        'Şalkhad',
        'Tall Salḩab',
        'Bagamoyo',
        'Magu',
        'Caucagua',
        'Guanta',
        'Maojiatang',
        'Cangxi',
        'Dikhil',
        'Winsford',
        'Bāpaura',
        'Chiplūn',
        'Harra',
        'Mel Nāriyappanūr',
        'Dulhanganj',
        'Al Ḩamdānīyah',
        'Shū',
        'Brugherio',
        'Owings Mills',
        'Pimenta Bueno',
        'Talanga',
        'Dimitrovgrad',
        'Meridian',
        'Oranjestad',
        'Maglaj',
        'Ōzu',
        'Ngaoundal',
        'Mlimba',
        'Francavilla Fontana',
        'Padre Bernardo',
        'Cookeville',
        'Niquelândia',
        'Shenjiabang',
        'Camoapa',
        'Ez Zahra',
        'Kaizu',
        'Chipinge',
        'Payao',
        'Northbrook',
        'Karumattampatti',
        'Aţ Ţurrah',
        'Fair Lawn',
        'Tenosique',
        'Foça',
        'Balingen',
        'Ypané',
        'Tarkwa',
        'Kahoku',
        'També',
        'Ilhabela',
        'Eşme',
        'Jiaoxi',
        'Lingsugūr',
        'Dietzenbach',
        'Shīyāli',
        'Iyo',
        'Dundee',
        'Campoalegre',
        'Nyamata',
        'Khodābandeh',
        'Tamura',
        'Slavuta',
        'Ekpé',
        'Posse',
        'Santa Cruz',
        'Armenia',
        'Ikot Abasi',
        'Santa Cruz del Norte',
        'Juara',
        'Madukkarai',
        'Piskent',
        'Châtenay-Malabry',
        'Seiyo',
        'Monroe',
        'Aznakayevo',
        'Palaiseau',
        'Jagodina',
        'Ouled Fares',
        'Victoria',
        'Ville Bonheur',
        'Richmond',
        'Mendez-Nuñez',
        'Alandatte',
        'Ewell',
        'Vadigenhalli',
        'Aguaí',
        'University City',
        'Cernusco sul Naviglio',
        'São José do Belmonte',
        'Kempen',
        'Hīrākud',
        'Munnarkōd',
        'Mannārakkāt',
        'Galapagar',
        'Parkland',
        'Arsin',
        'Villajoyosa',
        'Dragash',
        'Naryn',
        'Āllagadda',
        'Oswego',
        'Yarīm',
        'Oildale',
        'Santa Fé do Sul',
        'Sansanné-Mango',
        'Limbiate',
        'Alatyr',
        'Hammam Dalaa',
        'Khajamahalpur',
        'Uddevalla',
        'Auxerre',
        'Graham',
        'Vernier',
        'Morecambe',
        'Mason',
        'Roanne',
        'São Luís Gonzaga',
        'Montgomery Village',
        'Castelo',
        'Seelze',
        'Wermelskirchen',
        'Yhú',
        'Zhitiqara',
        'Long Beach',
        'Hinesville',
        'Safidon',
        'Shangcaiyuan',
        'Çorum',
        'Leganes',
        'Golden Glades',
        'Del Rio',
        'Kolattupuzha',
        'Saarlouis',
        'Zhujiezhen',
        'Třebíč',
        'Bagre',
        'Ayutuxtepeque',
        'Sion',
        'Goshen',
        'Gladstone',
        'Simpang Renggam',
        'University Place',
        'Randolph',
        'Zelenokumsk',
        'Osimo',
        'Azazga',
        'Dhāmnod',
        'Farafangana',
        'Bugasong',
        'Tienen',
        'Korkino',
        'Naranjito',
        'Augusta',
        'Rio Pardo',
        'Mānāmadurai',
        'Slagelse',
        'Abashiri',
        'Tortosa',
        'Butte',
        'East Gwillimbury',
        'Sarpol-e Z̄ahāb',
        'Marutharōd',
        'Andapa',
        'Anse-à-Veau',
        'Medgidia',
        'Misungwi',
        'Sidi Lakhdar',
        'Dabouziya',
        'Mugnano di Napoli',
        'Falls',
        'Phokeng',
        'Dueñas',
        'Tynaarlo',
        'Espiye',
        'Grantham',
        'Zhangziying',
        'Huntington Station',
        'Qaşr al Qarabūllī',
        'Abulug',
        'Inca',
        'Swadlincote',
        'Yangquan',
        'San Juan Despí',
        'Mildura',
        'Banda',
        'Yeonil',
        'Bugojno',
        'Cativá',
        'Bonifacio',
        'Ofunato',
        'Paithan',
        'Kalaa Srira',
        'Dāpoli',
        'Sabaneta',
        'Wedel',
        'Cañete',
        'Zweibrücken',
        'Viernheim',
        'Kulittalai',
        'Gisborne',
        'Pasni',
        'Koryazhma',
        'Bayog',
        'Bāft',
        'Mogpog',
        'Santa Maria',
        'Skhira',
        'Ahrensburg',
        'Pleasant Hill',
        'Côte-Saint-Luc',
        'Ankola',
        'Aïn Taya',
        'Lębork',
        'Manitowoc',
        'Barugo',
        'Sadda',
        'Akouda',
        'Fairborn',
        'Brzeg',
        'Ballesteros',
        'Ocuilan de Arteaga',
        'Shangzhen',
        'Bodocó',
        'Santa Fe',
        'Curuzú Cuatiá',
        'Gerāsh',
        'Nola',
        'Formigine',
        'Aloguinsan',
        'San Dimas',
        'Smolyan',
        'Nishihara',
        'Schoten',
        'Mazarrón',
        'Stow',
        'Motosu',
        'Banes',
        'Madakalavāripalli',
        'Canicattì',
        'Mâcon',
        'Hatibanda',
        'Bela Vista de Goiás',
        'Sambir',
        'Zaidpur',
        'Sochaczew',
        'Kalasin',
        'Fort Liberté',
        'Exmouth',
        'McMinnville',
        'Ye',
        'Mnasra',
        'Gotō',
        'North Shields',
        'Centenario',
        'Kuji',
        'College Park',
        'Merauke',
        'Itaporanga d’Ajuda',
        'Paxtaobod',
        'Riccione Marina',
        'Năvodari',
        'Vichuga',
        'Meppel',
        'Toretsk',
        'Santa Ana Nextlalpan',
        'Biwong',
        'Corsico',
        'Cherchell',
        'Durazno',
        'Kiryas Joel',
        'Yihezhuang',
        'Houlong',
        'São Joaquim de Bicas',
        'Lakewood Ranch',
        'Kita Chauhāttar',
        'Pueblo West',
        'Betafo',
        'Garibaldi',
        'Merseburg',
        'Heunghae',
        'Phra Phutthabat',
        'Same',
        'Tandubas',
        'Degāna',
        'La Blanca',
        'Třinec',
        'Wavre',
        'Bathurst',
        'Tábor',
        'Swakopmund',
        'Isingiro',
        'Merritt Island',
        'Derry',
        'Geldern',
        'Quilevo',
        'Araçuaí',
        'Conegliano',
        'Nandaime',
        'Licata',
        'São Pedro',
        'Shinjō',
        'Almeirim',
        'Kalarūch',
        'Pinukpuk',
        'Buchanan',
        'Lewiston',
        'Nova Cruz',
        'Lautaro',
        'Fusō',
        'Aiyappan Kōvil',
        'São Desidério',
        'Hitchin',
        'Vedāranniyam',
        'Pomerode',
        'Zhangzhengqiao',
        'Esposende',
        'Lixingcun',
        'Timberwood Park',
        'New Panamao',
        'Simunul',
        'La Huacana',
        'Sibutu',
        'Sahuarita',
        'Nāz̧erābād',
        'Baraidih',
        'Dakota Ridge',
        'Colinas do Tocantins',
        'Atami',
        'Mosonmagyaróvár',
        'General Nakar',
        'Lexington',
        'Santa Maria',
        'Isfana',
        'Gulkevichi',
        'Prairieville',
        'Le Perreux-Sur-Marne',
        'São Sebastião',
        'Pangururan',
        'Úbeda',
        'Badiadka',
        'Shāhīn Dezh',
        'Şereflikoçhisar',
        'Karoi',
        'Toumoukro',
        'Eastchester',
        'Tōon',
        'Valuyki',
        'Redmond',
        'Šid',
        'Korschenbroich',
        'Curaçá',
        'Orangeville',
        'Cariari',
        'Ābīy Ādī',
        'Tiruvūr',
        'Datang',
        'Ágioi Anárgyroi',
        'Lufkin',
        'Bor',
        'Talayan',
        'Tepeji del Río de Ocampo',
        'Tobelo',
        'Znojmo',
        'Villena',
        'Kokrajhar',
        'Risod',
        'Dimona',
        'Caldono',
        'Kornwestheim',
        'Schiltigheim',
        'Laoac East',
        'Takhemaret',
        'Almora',
        'Boğazlıyan',
        'Brejo',
        'San Agustín',
        'Shisō',
        'Spalding',
        'Balarāmpur',
        'Uonuma',
        'Cravinhos',
        'Gyapekurom',
        'Guindulman',
        'Fungurume',
        'Pingtang',
        'Pikesville',
        'Diakon',
        'Qingyang',
        'Argyroúpoli',
        'Radebeul',
        'Leduc',
        'Rainham',
        'Eastpointe',
        'Beaufort West',
        'San Manuel',
        'Çınarcık',
        'Honaz',
        'Portão',
        'Titlāgarh',
        'Cantilan',
        'Wāris Alīganj',
        'Gbarnga',
        'Deer Park',
        'Cooper City',
        'San Remigio',
        'Podgórze',
        'La Unión',
        'Lommel',
        'Pagani',
        'Vác',
        'Sint-Pieters-Leeuw',
        'Palapag',
        'São Domingos do Maranhão',
        'Puqiancun',
        'Westlake',
        'Woodridge',
        'Zeghanghane',
        'Hemer',
        'Jalajala',
        'Jāmkhed',
        'Spanaway',
        'Rūdarpur',
        'San Juan Nepomuceno',
        'Minamishiro',
        'Majagual',
        'Biberach',
        'Dīvāndarreh',
        'Oneşti',
        'Quinchía',
        'Gibraltar',
        'Ankazomiriotra',
        'Maromandia',
        'Mahasoabe',
        'Lushoto',
        'Zhegaozhen',
        'Tamra',
        'Valparaiso',
        'Letchworth',
        'American Fork',
        'Kallidaikurichi',
        'Shariff Aguak',
        'Menen',
        'San Quintin',
        'Les Mureaux',
        'Vassouras',
        'Angri',
        'Peyziwat',
        'Milaor',
        'Kignan',
        'Sharypovo',
        'Cieszyn',
        'Sibagat',
        'Acatlán de Osorio',
        'Stuhr',
        'Korsakov',
        'Lochem',
        'Almendralejo',
        'Kakata',
        'North Providence',
        'Lanciano',
        'Annigeri',
        'Uelzen',
        'San José Poaquil',
        'Uttaradit',
        'San Juan Evangelista',
        'Bassin Bleu',
        'Pulgaon',
        'Sındırgı',
        'City of Orange',
        'Starokostiantyniv',
        'Nizhneudinsk',
        'Oued el Alleug',
        'Impfondo',
        'Zhuqi',
        'Windsor',
        'Curralinho',
        'Initao',
        'Mulavūr',
        'Adrano',
        'Lupi Viejo',
        'Réo',
        'Navan',
        'Guying',
        'Timbío',
        'Gadsden',
        'Zhaitangcun',
        'Toda Bhīm',
        'Byādgi',
        'Itiúba',
        'Lianga',
        'Tabango',
        'Desamparados',
        'Sneek',
        'Palmeira',
        'Imzouren',
        'São Luís de Montes Belos',
        'Selmane',
        'Baclaran',
        'Chaodongcun',
        'Nuoro',
        'Beni Tamou',
        'Oeiras do Pará',
        'Bozdoğan',
        'Dao',
        'Walla Walla',
        'Camiri',
        'New City',
        'Bell Ville',
        'Golungo Alto',
        'Jasło',
        'San Martín Sacatepéquez',
        'Strumica',
        'Harima',
        'Waikabubak',
        'IJsselstein',
        'Parras de la Fuente',
        'Tobias Fornier',
        'Buxin',
        'Lichfield',
        'San Antonio de los Baños',
        'Río Verde Arriba',
        'Kearney',
        'Inhambupe',
        'Kadungapuram',
        'Somoto',
        'Naguilian',
        'Xinzhancun',
        'Crema',
        'Leawood',
        'Beni Slimane',
        'Baldwin',
        'Ghazaouet',
        'Braço do Norte',
        'Martinez',
        'Bangaon',
        'Vechta',
        'Puerto Rico',
        'Narsīpatnam',
        'Bogorodsk',
        'Zhmerynka',
        'Mount Lebanon',
        'Catmon',
        'Várzea da Palma',
        'Tekes',
        'Mankoeng',
        'Zacatlán',
        'Kadingilan',
        'Crown Point',
        'El Ksar',
        'Ojiya',
        'Jaguaribe',
        'Chilecito',
        'Kovin',
        'Capoocan',
        'Kaippakanchēri',
        'Carmel',
        'Āzezo',
        'Karaağaç',
        'Léo',
        'Trappes',
        'Midsalip',
        'Fallbrook',
        'Grodzisk Mazowiecki',
        'Vemalwāda',
        'Xiaotangzhuang',
        'Antiguo Cuscatlán',
        'Irvine',
        'Landi Kotal',
        'Roldanillo',
        'Somma Vesuviana',
        'Palmerston',
        'Tlanchinol',
        'Kasba',
        'Sukhoy Log',
        'Datu Paglas',
        'Kohtla-Järve',
        'Mella',
        'Moose Jaw',
        'Batobato',
        'Upper Merion',
        'Penha',
        'Villa Riva',
        'San Antero',
        'Bārhadashi̇̄',
        'Oulad Zemam',
        'San Roque',
        'Ōno',
        'Florida',
        'Lianmuqin Kancun',
        'Englewood',
        'East Lake',
        'Aranda de Duero',
        'Macuro',
        'Kōryō',
        'Chascomús',
        '‘Ajab Shīr',
        'Binche',
        'Morro do Chapéu',
        'Batouri',
        'Koh Kong',
        'Atiquizaya',
        'Rheinfelden (Baden)',
        'Masiu',
        'Sapa Sapa',
        'Côtes de Fer',
        'Elmina',
        'Sicuani',
        'Dāganbhuiya',
        'Iriba',
        'Qadsayyā',
        'Maassluis',
        'Pitanga',
        'Goldsboro',
        'Áno Liósia',
        'Aleshtar',
        'Mercedes',
        'Bagh',
        'Chāvakkād',
        'Ixtapan de la Sal',
        'Belen',
        'Aweil',
        'Port Moody',
        'Cabarroguis',
        'Acornhoek',
        'Ayorou',
        'Columbio',
        'Manganam',
        'Saint Helier',
        'Chumphon',
        'Arteijo',
        'Sidi Okba',
        'San Vicente',
        'Comé',
        'Ribeirão',
        'Gorizia',
        'Ken Caryl',
        'Leticia',
        'Niangoloko',
        'Canton',
        'Bacarra',
        'Schwedt (Oder)',
        'Warminster',
        'Manlius',
        'Corinto',
        'Chempalli',
        'Pointe-Claire',
        'Wickford',
        'Batan',
        'Fraiburgo',
        'Hlukhiv',
        'Neuchâtel',
        'Cishan',
        'Nogent-sur-Marne',
        'West Little River',
        'Olkusz',
        'Dhekiajuli',
        'Coxim',
        'Badr',
        'Houilles',
        'Chimbarongo',
        'Bad Nauheim',
        'Mangalam',
        'Poço Redondo',
        'Çukurçayır',
        'Sabanagrande',
        'Santa Ana',
        'Monteiro',
        'Vettam',
        'Paidha',
        'Madhubani',
        'Angamāli',
        'Getafe',
        'Orodara',
        'Darsi',
        'Castro',
        'Sirīpur',
        'Alabaster',
        'Mỹ Hòa',
        'Orillia',
        'Kaçanik',
        'Shōbara',
        'Ban Pak Phun',
        'Butajīra',
        'Date',
        'Geraardsbergen',
        'Paso de Ovejas',
        'Kong',
        'Riachão do Jacuípe',
        'Namhkam',
        'Asakuchi',
        'Madikeri',
        'Bell',
        'Banate',
        'Aldaya',
        'Tulum',
        'Gölbaşı',
        'Kondapalle',
        'Landskrona',
        'Iperó',
        'Tayshet',
        'Kennesaw',
        'South Riding',
        'Tavda',
        'Loughton',
        'Bafang',
        'Montluçon',
        'Glossop',
        'Vintar',
        'Dursunbey',
        'An’gang',
        'Shirakawa-tsuda',
        'Shiroishi',
        'Ronda',
        'Puyo',
        'Batcha',
        'Menlo Park',
        'Nokia',
        'Taishi',
        'Al Mindak',
        'Baltiysk',
        'Amparafaravola',
        'Bāmaur',
        'Ziniaré',
        'Dupax Del Norte',
        'Estreito',
        'Churi',
        'Kidamangalam',
        'Mohnyin',
        'João Câmara',
        'Beidou',
        'Kolín',
        'Shanawān',
        'Buenaventura Lakes',
        'Gojō',
        'Bethel Park',
        'Nowy Targ',
        'Hisuā',
        'Oława',
        'Didouche Mourad',
        'Romainville',
        'Muconda',
        'Petersburg',
        'Radnor',
        'Cottonwood Heights',
        'Perşembe',
        'Mažeikiai',
        'Xincheng',
        'Zamānia',
        'Barguna',
        'Capim Grosso',
        'Stratford',
        'Kampong Speu',
        'Kavundappādi',
        'Pivijay',
        'Castro-Urdiales',
        'Ceres',
        'Shatura',
        'Bragado',
        'Foster City',
        'Ālamat’ā',
        'Ross',
        'Geiro',
        'Guarambaré',
        'Kyrenia',
        'Kartārpur',
        'Obukhiv',
        'Borne',
        'Epe',
        'Andırın',
        'Huyton',
        'Uniondale',
        'Khvāf',
        'Lower Makefield',
        'Statesboro',
        'Furmanov',
        'Katiéna',
        'Pandami',
        'Eirunepé',
        'Puerto Cumarebo',
        'Partizansk',
        'Cranberry',
        'Jülich',
        'Gillette',
        'Totana',
        'Chuhuiv',
        'Shamsābād',
        'Baja',
        'Dipaculao',
        'Palmeira das Missões',
        'Abingdon',
        'Shikārpūr',
        'Salina',
        'Pánuco',
        'Kusapín',
        'Minamikyūshū',
        'Umaria',
        'Brooklyn Center',
        'Trowbridge',
        'Ben Ahmed',
        'Mukdahan',
        'Bugembe',
        'Kaarina',
        'Knokke-Heist',
        'Aosta',
        'Los Gatos',
        'Shima',
        'Glendale Heights',
        'Tekkebhāgam',
        'El Arrouch',
        'Chanderi',
        'Calape',
        'Bungoōno',
        'Temascalapa',
        'Mushie',
        'Tynda',
        'Kamaishi',
        'Victoria Falls',
        'Timimoun',
        'Borba',
        'Kareli',
        'Castelfranco Emilia',
        'Tutin',
        'Ban Ang Sila',
        'Kingman',
        'Taiobeiras',
        'Chester',
        '‘Aïn el Hadjel',
        'Didy',
        'San Luis',
        'Kadınhanı',
        'Wakabadai',
        'Harker Heights',
        'Touros',
        'Puebloviejo',
        'Las Mercedes',
        'Dana Point',
        'Luboń',
        'Jishi',
        'Shingū',
        'Hāngal',
        'Sarıkaya',
        'Marignane',
        'Xinbu',
        'Livingstone',
        'Ambodimanga II',
        'Vohitromby',
        'Zumbo',
        'Forbe Oroya',
        'Lomé',
        'Gumdag',
        'Gyzylgaya',
        'Osvaldo Cruz',
        'Luxitun',
        'Villafranca di Verona',
        'Hinda',
        'Turkauliyā',
        'Boxtel',
        'Hampden',
        'Forchheim',
        'Pôrto União',
        'Taguasco',
        'São Paulo de Olivença',
        'Spring Valley',
        'Sopot',
        'Shalingzicun',
        'Asse',
        'Elesvaram',
        'Bembe',
        'Dellys',
        'Örnsköldsvik',
        'Chernushka',
        'Ventspils',
        'Itamarandiba',
        'Djenné',
        'Rencun',
        'Dara',
        'Dehlorān',
        'Musikoṭ-Khalaṅgā',
        'Carapeguá',
        'Biritiba-Mirim',
        'Castelfranco Veneto',
        'Saint-Georges',
        'Dumalinao',
        'Yamanashi',
        'Massango',
        'Aravan',
        'Romans-sur-Isère',
        'Baraguá',
        'Saintard',
        'Fatehpur Sīkri',
        'Escuque',
        'Fort Erie',
        'Moninnpébougou',
        'Patrātu',
        'Sānchor',
        'Pantelimon',
        'Aksay',
        'Lampertheim',
        'Oued Zenati',
        'Kifrī',
        'Clarence',
        'Tawsalun',
        'Níjar',
        'Lohagaon',
        'Sō',
        'Nikšić',
        'Araban',
        'Watari',
        'Villanueva',
        'San Felipe',
        'Sanana',
        'Nevers',
        'Kollo',
        'Carache',
        'Kakira',
        'Tunceli',
        'Gostivar',
        'Umarkot',
        'Urbano Santos',
        'Sertânia',
        'Pilar',
        'Ārumuganeri',
        'Igrejinha',
        'Tecuci',
        'Baraawe',
        'Syracuse',
        'Teutônia',
        'Ticul',
        'Sighetu Marmaţiei',
        'Slobodskoy',
        'Buco Zau',
        'Parambil',
        'Tit Mellil',
        'Shaliuhe',
        'Franklin',
        'St. Charles',
        'Bela Cruz',
        'Rubino',
        'Delbrück',
        'Ubajara',
        'Bocas de Satinga',
        'Udaipur',
        'Sayram',
        'Xinyuan',
        'Chand Chaur',
        'Fair Oaks',
        'Achim',
        'Val-d’Or',
        'Mankada',
        'Yuzhne',
        'Puerto Escondido',
        'Balzar',
        'Příbram',
        'Barouéli',
        'Ylöjärvi',
        'Kallūr',
        'Sayula de Alemán',
        'LaSalle',
        'Ivaiporã',
        'Rodas',
        'Warwick',
        'Geraldton',
        'Trëkhgornyy',
        'Sherwood',
        'Kaysville',
        'Ansongo',
        'Vadavalli',
        'Nurlat',
        'Jaguarari',
        'Yufu',
        'Debagrām',
        'La Mesa',
        'Falmouth',
        'Silvia',
        'Cota',
        'Arbaoua',
        'Nānjikkottai',
        'São João Batista',
        'Xiaodian',
        'Vilakkudi',
        'Dobryanka',
        'Zhanjia',
        'Des Moines',
        'Iława',
        'Oğuzeli',
        'Itaíba',
        'Herrenberg',
        'City Bell',
        'Fürstenwalde',
        'Jamikunta',
        'Putla Villa de Guerrero',
        'Tārānagar',
        'Goleta',
        'Cibolo',
        'Andover',
        'El Difícil',
        'Lawrence',
        'Belhi',
        'Frederico Westphalen',
        'Lens',
        'San Lazzaro di Savena',
        'Aïn el Bya',
        'Istra',
        'Ōsawa',
        'Ostrogozhsk',
        'Mayantoc',
        'Arris',
        'Kraśnik',
        'Motherwell',
        'Mashiki',
        'Matalom',
        'Diplahan',
        'Businga',
        'Shamsābād',
        'Inzá',
        'Erraguntla',
        'Balindong',
        'Rochester',
        'Payshamba Shahri',
        'Şarköy',
        'Vyatskiye Polyany',
        'Esplanada',
        'Shimanto',
        'Aïn Bessem',
        'Mateur',
        'Saint-Médard-en-Jalles',
        'Chēmanchēri',
        'Badoc',
        'Helena',
        'Hani',
        'Kulebaki',
        'I-n-Salah',
        'Salt',
        'Caçapava do Sul',
        'Dolores',
        'Slantsy',
        'Lobougoula',
        'Minamisatsuma',
        'Elk Grove Village',
        'Spring Valley',
        'Mānwat',
        'Agen',
        'Amés',
        'Shiyeli',
        'Pombal',
        'Baiyan',
        'Garfield',
        'Yinchengpu',
        'Réthymno',
        'Nueva Italia de Ruiz',
        'Ourilândia do Norte',
        'Sotouboua',
        'Fucheng',
        'Correntina',
        'Staryy Beyneu',
        'Ferry Pass',
        'Virei',
        'Chemmaruthi',
        'Savage',
        'Turaiyūr',
        'Deurne',
        'Nouna',
        'Abbiategrasso',
        'Omaezaki',
        'Lower Macungie',
        'Dioungani',
        'Vrilíssia',
        'Bussum',
        'Beverly Hills',
        'Nedroma',
        'Plottier',
        'Desert Hot Springs',
        'Ketti',
        'Kameda-honchō',
        'Pierrefitte-sur-Seine',
        'Dumfries',
        'Oisterwijk',
        'Pires do Rio',
        'Chamtha',
        'Clearfield',
        'Dartmouth',
        'Brighouse',
        'Massantola',
        'Kiranomena',
        'Ingenio',
        'Yankou',
        'Redcliff',
        'Guararapes',
        'Kävlinge',
        'Pattanapuram',
        'Ire',
        'Geesthacht',
        'Lumbang',
        'La Virginia',
        'Sāsthānkotta',
        'Narsinghgarh',
        'San Pedro de Urabá',
        'Kalyandrug',
        'Wigston Magna',
        'Lugo',
        'Bilzen',
        'Ōharu',
        'Namerikawa',
        'Chancay',
        'Sant’Antimo',
        'Nagarote',
        'El Abiodh Sidi Cheikh',
        'Madīnat al Ḩabbānīyah',
        'Tattamangalam',
        'Sibiti',
        'Cachoeira Paulista',
        'Dracut',
        'Naumburg',
        'Curanilahue',
        'Venaria Reale',
        'Komárno',
        'Épinal',
        'Messaména',
        'Concón',
        'Chiché',
        'Peñaranda',
        'Niéna',
        'Wheat Ridge',
        'Bernburg',
        'Zarqān',
        'Zaojiao',
        'Itzehoe',
        'Jimalalud',
        'Bambadinca',
        'Boro',
        'Guanhães',
        'North Olmsted',
        'Ţafas',
        'São Bento',
        'Termoli',
        'Kangasala',
        'Jacqueville',
        'La Calera',
        'Laranjeiras do Sul',
        'Yōkaichiba',
        'Sébaco',
        'Paraipaba',
        'Şāfītā',
        'Mallig',
        'Kindi',
        'Casale Monferrato',
        'Buloqboshi',
        'Cochrane',
        'Igbaras',
        'Piombino',
        'Georgsmarienhütte',
        'Kristianstad',
        'Windsor',
        'Estrêla',
        'Didcot',
        'Nagato',
        'Primorsko-Akhtarsk',
        'Bramsche',
        'Massillon',
        'Gandu',
        'Ibaté',
        'Xangda',
        'Catubig',
        'Matões',
        'Maragogi',
        'Homa Bay',
        'Yorii',
        'Papendrecht',
        'Weatherford',
        'Alubijid',
        'Pasadena',
        'Koungou',
        'Catende',
        'Rokhaty',
        'Sint-Amandsberg',
        'Erāmala',
        'Miguel Alves',
        'Buguey',
        'Nacogdoches',
        'Kāngayam',
        'Utraula',
        'Salug',
        'Shilou',
        'Douar Oulad Hssine',
        'Pojuca',
        'Pervari',
        'Ptolemaḯda',
        'Kōnan',
        'Maputsoe',
        'Termas de Río Hondo',
        'Balimbing',
        'Caramoran',
        'Ribeira Grande',
        'Huixtla',
        'Mizuho',
        'Juneau',
        'Campo Alegre',
        "O'Fallon",
        'Ōdachō-ōda',
        'Rumbek',
        'Waiyuanshan',
        'Zapala',
        'Aguilares',
        'Guaíra',
        'Ouro Fino',
        'Kukshi',
        'Mangūr',
        'Mutki',
        'Serdobsk',
        'Quitilipi',
        'Sami',
        'Kovūr',
        'Antsohimbondrona',
        'Rādhanpur',
        'Pekin',
        'Tamparan',
        'Dhahran',
        'Uglich',
        'Remedios',
        'Florence',
        'Ciudad Sabinas Hidalgo',
        'Radolfzell am Bodensee',
        'São Miguel Arcanjo',
        'Earley',
        'Canyon Lake',
        'Rome',
        'Rahachow',
        'Munnar',
        'Fontana',
        'Chachapoyas',
        'Brasília de Minas',
        'Wernigerode',
        'Malangas',
        'Bauko',
        'Akivīdu',
        'Bizen',
        'Bezons',
        'San Donato Milanese',
        'Aiken',
        'Sakaiminato',
        'Tirebolu',
        'Marrero',
        'Bandarawela',
        'Andriba',
        'Bekoratsaka',
        'Vohilava',
        'Itampolo',
        'Ambano',
        'Ghuenke',
        'Yaguarón',
        'Santo Tomás',
        'Cabaiguán',
        'Makhdumpur',
        'Milot',
        'Aalsmeer',
        'North Cowichan',
        'Września',
        'Youwangjie',
        'Kanzakimachi-kanzaki',
        'Arzano',
        'Levice',
        'Pullman',
        'Franklin Square',
        'Purāini',
        'Mascalucia',
        'Massafra',
        'Tholikuzhi',
        'Needham',
        'Deptford',
        'Cheb',
        'Sitges',
        'La Concepción',
        'Mangalia',
        'Warwick',
        'Sergio Osmeña Sr',
        'Nísia Floresta',
        'Landecy',
        'Ganderkesee',
        'San Carlos Alzatate',
        'Nerópolis',
        'Long Branch',
        'Bexleyheath',
        'South Kingstown',
        'Quintero',
        'Bougaa',
        'Santo Antônio do Tauá',
        'Araguatins',
        'Xaxim',
        'Palamel',
        'Purattūr',
        'Polillo',
        'Southport',
        'Yenice',
        'Nilka',
        'San Pablo',
        'Santa Maria Capua Vetere',
        'Erdek',
        'Cañuelas',
        'Claveria',
        'Cacolo',
        'Avellaneda',
        'Paso del Macho',
        'Bayaguana',
        'Centereach',
        'Turicato',
        'Maryville',
        'Aix-les-Bains',
        'Kampong Thom',
        'Jyväskylän Maalaiskunta',
        'Alengād',
        'Paipa',
        'Dzierżoniów',
        'Oudenaarde',
        'Bửu Long',
        'Bahharet Oulad Ayyad',
        'São Gabriel',
        'Palmeiras de Goiás',
        'Guachavés',
        'Maur Kalān',
        'Cleburne',
        'El Oro de Hidalgo',
        'Domingos Martins',
        'Willingboro',
        'Exu',
        'Camaiore',
        'Atwater',
        'Sidi Ali',
        'Oer-Erkenschwick',
        'Remchi',
        'Pampanito',
        'Sherpur',
        'Namegata',
        'Jhanjhārpur',
        'São Gotardo',
        'Ortaköy',
        'Alamnagar',
        'Palmira',
        'Santa Rita',
        'Newburgh',
        'Velikiy Ustyug',
        'San Lucas Tolimán',
        'Plettenberg Bay',
        'Masi-Manimba',
        'Teykovo',
        'Tongxiao',
        'Stadskanaal',
        'Bagasra',
        'Gurupá',
        'San Pedro de Ribas',
        'Hassi Khelifa',
        'Gallipoli',
        'Kottaikuppam',
        'North Huntingdon',
        'Ferreñafe',
        'Montigny-le-Bretonneux',
        'Salamína',
        'Pantabangan',
        'Tubao',
        'Velliyōd',
        'Komagane',
        'Bocana de Paiwas',
        'Singia',
        'General José de San Martín',
        'Descalvado',
        'Carlsbad',
        'Yıldızeli',
        'Tekkēkara Kizhakku',
        '’Aïn Abid',
        'Duluth',
        'La Maná',
        'Tredyffrin',
        'Farrokh Shahr',
        'Sadābād',
        'Amatenango de la Frontera',
        'Al ‘Aydābī',
        'Laramie',
        'Donggou',
        'La Uruca',
        'Maebara',
        'Kochugaon',
        'Staffanstorp',
        'Bostaniçi',
        'Tuljāpur',
        'Kavār',
        'Santa Catarina Mita',
        'Sitionuevo',
        'El Palmar',
        'Bangor',
        'Oldenzaal',
        'Jaguaruana',
        'Dania Beach',
        'Lālgola',
        'Rushden',
        'Baradères',
        'Sigma',
        'Amecameca de Juárez',
        'Zhaoyu',
        'Garner',
        'Jāsim',
        'West Bend',
        'Bagrāmī',
        'Itapicuru',
        'Kumba',
        'Masur',
        'Vught',
        'Jiming',
        'Naugatuck',
        'Shimizuchō',
        'Barros Blancos',
        'Nautan Dube',
        'Rumuruti',
        'Calubian',
        'Capela',
        'Siquirres',
        'Nueva Imperial',
        'Balyqshy',
        'Morro Agudo',
        'Zentsujichó',
        'Kiryandongo',
        'Tecozautla',
        'Ecclesfield',
        'Kuroishi',
        'Jesús María',
        'Jalpatagua',
        'Jerez',
        'Chum Phae',
        'Favara',
        'Media Luna',
        'Chorbog',
        'Masatepe',
        'Mundelein',
        'Pruszcz Gdański',
        'Žepče',
        'Lloydminster',
        'Nanyuki',
        'Merida',
        'Manises',
        'Wisbech',
        'Fangyuan',
        'Darwen',
        'Holladay',
        'Lidingö',
        'Shenwan',
        'Cururupu',
        'Katagami',
        'Sosnogorsk',
        'Pehuajó',
        'Lawndale',
        'Cueto',
        'Al Khawr',
        'Thazhamel',
        'Hayama',
        'Lake Magdalene',
        'Rāhatgarh',
        'Ayabe',
        'Oak Ridge',
        'I‘zāz',
        'Mae Sot',
        'Milford Mill',
        'İvrindi',
        'Puerto Wilches',
        'Shikārpur',
        'Prosper',
        'Kurivikod',
        'Novovoronezh',
        'Buzovna',
        'Encrucijada',
        'Prestwich',
        'Mechraa Bel Ksiri',
        'Bünyan',
        'Charthāwal',
        'Knjaževac',
        'Epsom',
        'Marks',
        'Oceanside',
        'Dialoubé',
        'Colombia',
        'Qārā',
        'Samdhin',
        'Dej',
        'Sejiyŏn',
        'Kostopil',
        'Mandaguaçu',
        'Bangassou',
        'Airmadidi',
        'Parambu',
        'Savé',
        'Chambly',
        'Kalisizo',
        'Nagdha Simla',
        'Jam',
        'Trebinje',
        'Forquilhinha',
        'Nauāgarhi',
        'Cambrai',
        'Deori Khās',
        'Neuruppin',
        'Renkum',
        'Carballo',
        'Nieuw-Vennep',
        'Lemery',
        'Pindaré-Mirim',
        'Çay',
        'Wevelgem',
        'Āreka',
        'Norak',
        'Kunnatnād',
        'Neira',
        'Kolachel',
        'Cornelius',
        'Sanha',
        'Shentang',
        'L’Haÿ-les-Roses',
        'Shawnee',
        'Halden',
        'Yawatahama-shi',
        'Vellanād',
        'Ílhavo',
        'Draa Ben Khedda',
        'Buyan',
        'Navalcarnero',
        'Courcelles',
        'Plaisir',
        'Myōkō',
        'New Bern',
        'Kudymkar',
        'Colíder',
        'Bolobo',
        'Quţūr',
        'Sri Mādhopur',
        'Bagac',
        'Opelika',
        'Humenné',
        'Ithaca',
        'Hoyerswerda',
        'Villaricca',
        'Dasol',
        'Nicholasville',
        'Point Pedro',
        'Cicero',
        'Guamo',
        'Maralal',
        'Lumding',
        'Cândido Mota',
        'Norwood',
        'Waddinxveen',
        'Dimataling',
        'Mamaroneck',
        'Wenxian Chengguanzhen',
        'Odorheiu Secuiesc',
        'Kléla',
        'Diemen',
        'Hitoyoshi',
        'Monjas',
        'Kandalaksha',
        'Rio das Pedras',
        'Gongguan',
        'Pontoise',
        'Redan',
        'Tanmen',
        'Sherpur Khurd',
        'Araçoiaba da Serra',
        'Ibanda',
        'Altenburg',
        'Netivot',
        'İmişli',
        'Magsingal',
        'Yahşihan',
        'Bel Air North',
        'Río Bravo',
        'Zaqatala',
        'Eyvān',
        'Maḩallat Damanah',
        'Mograne',
        'Shimotoba',
        'Yby Yaú',
        'Port Chester',
        'Burgdorf',
        'Ennery',
        'Châtellerault',
        'Aguada de Pasajeros',
        'Ivisan',
        'Ibaraki',
        'Bandeirantes',
        'President Roxas',
        'Cuauhtémoc',
        'Vadakakarai',
        'Tiquipaya',
        'Boghni',
        'Ōiso',
        'Petrovac na Mlavi',
        'Hendrik-Ido-Ambacht',
        'Inkhil',
        'Siliana',
        'Parnarama',
        'Rillieux-la-Pape',
        'Nāspur',
        'Carangola',
        'La Verne',
        'Unchagao',
        'Esperança',
        'Barrinha',
        'Changuinola',
        'Salgótarján',
        'Valkenswaard',
        'Rosmalen',
        'Kosonsoy',
        'Poconé',
        'Itaperuçu',
        'Bittou',
        'Alba',
        'Bramhapuri',
        'Pontypridd',
        'Duanshan',
        'Shepparton',
        'Kreuztal',
        'Lobos',
        'Rutherglen',
        'Sakaraha',
        'LaGrange',
        'Dar Ould Zidouh',
        'Laguna Hills',
        'Varto',
        'Paracelis',
        'Saint Neots',
        'Siraway',
        'Khānah Sūr',
        'Buenavista',
        'Rusera',
        'Middle River',
        'Rheinberg',
        'Asilah',
        'Ciudad del Plata',
        'Podili',
        'Yaita',
        'Tongeren',
        'Gata',
        'Ambatomiady',
        'Bogoroditsk',
        'Beidaying',
        'Sovetskiy',
        'Orcutt',
        'Shrīgonda',
        'Los Altos',
        'Fomento',
        'Ngudu',
        'Kalliyasshēri',
        'West Falls Church',
        'Rosa Zarate',
        'North Royalton',
        'Tequila',
        'Socorro',
        'Espinosa',
        'Yingyangcun',
        'Lushnjë',
        'Bembèrèkè',
        'Tancítaro',
        'Chitral',
        'Danghara',
        'Khorugh',
        'Sakhnīn',
        'Gevelsberg',
        'Thiais',
        'Sabana Grande de Boyá',
        'Montebelluna',
        'Centre Wellington',
        'Vibo Valentia',
        'Sasaguri',
        'Livingston',
        'Bedworth',
        'Tewksbury',
        'Huangxicun',
        'Tanguá',
        'San Juan Ixcoy',
        'Nantan',
        'Barreirinha',
        'Sterling',
        'Cambita Garabitos',
        'Manaratsandry',
        'Vienne',
        'Vigneux-sur-Seine',
        'Malaikkal',
        'Lagos',
        'Pompeu',
        'Werl',
        'Viry-Châtillon',
        'Petroşani',
        'Hopkinsville',
        'Tairan Camp',
        'Tummapāla',
        'Mushābani',
        'Chantal',
        'Espinho',
        'Karera',
        'Dover',
        'Springfield',
        'Konséguéla',
        'Malakanagiri',
        'Rodolfo Sánchez Taboada',
        'São José do Egito',
        'Vohitrandriana',
        'Andranovory',
        'Wobulenzi',
        'Dashao',
        'San Vicente',
        'Alamogordo',
        'Burlingame',
        'Iguape',
        'Diffa',
        'Zima',
        'Itapemirim',
        'Arraial do Cabo',
        'Santa Vitória do Palmar',
        'Bethany',
        'Gīmbī',
        'Babati',
        'Lucera',
        'Yaese',
        'Lakeside',
        'Dolo Odo',
        'La Paz',
        'Dyurtyuli',
        'Irituia',
        'Arcos de la Frontera',
        'Mocímboa da Praia',
        'Tawaramoto',
        'Ballwin',
        'Tarakeswar',
        'Minas de Matahambre',
        'Rostov',
        'Wanghong Yidui',
        'Bādāmi',
        'Husainābād',
        'Saint-Laurent-du-Var',
        'Ridley',
        'Chagne',
        'Dedza',
        'Alcázar de San Juan',
        'SeaTac',
        'Chichester',
        'Săcele',
        'Mława',
        'Sabirabad',
        'Deal',
        'Jérémie',
        'West Warwick',
        'Itatiaia',
        'Cícero Dantas',
        'Canavieiras',
        'West Odessa',
        'Pichanal',
        'Flores da Cunha',
        'Okagaki',
        'North Chicago',
        'Coria del Río',
        'Kaminokawa',
        'Bairi Chak',
        'Champotón',
        'Pontefract',
        'Dreux',
        'Voorhees',
        'São Luís do Quitunde',
        'Leh',
        'Pattanakkād',
        'Ózd',
        'Culleredo',
        'San Antonio Ilotenango',
        'Sholinghur',
        'Dharampur',
        'Juanacatlán',
        'Bicester',
        'Mānāvadar',
        'Puerto de la Cruz',
        'Palaiya Āyakkudi',
        'North Andover',
        'Careiro da Várzea',
        'Lohmar',
        'Vinkovci',
        'Kalgoorlie',
        'Cholargós',
        'Westfield',
        'Capulhuac',
        'Sarayköy',
        'Nartkala',
        'Zionsville',
        'Beverley',
        'Al Ḩusaynīyah',
        'Chinnālapatti',
        'Walsrode',
        'Pattittara',
        'Arlon',
        'Friedberg',
        'Kórinthos',
        'Lohāgāra',
        'Bègles',
        'Taunusstein',
        'Vitória do Mearim',
        'Ungheni',
        'Nālchiti',
        'Ganassi',
        'Lavras da Mangabeira',
        'Qo‘rg‘ontepa',
        'Jonuta',
        'Careiro',
        'Escuinapa',
        'Nardò',
        'Santa Paula',
        'Río Blanco',
        'Southlake',
        'Barão de Cocais',
        'Busia',
        'Saratoga',
        'Weil am Rhein',
        'Carpentras',
        'Mahemdāvād',
        'Alegre',
        'Paşcani',
        'Andingcun',
        'Tsuruno',
        'San Narciso',
        'Guaranda',
        'Gadarpur',
        'Ban Sai Ma Tai',
        'Písek',
        'Ulliyeri',
        'Ferizli',
        'Tekkali',
        'Chak Five Hundred Seventy-five',
        'Udaypur Gaḍhi̇̄',
        'Einbeck',
        'San Sebastián',
        'Novoyavorovskoye',
        'San Isidro',
        'Pedana',
        'Mattathūr',
        'Além Paraíba',
        'Agblangandan',
        'Juatuba',
        'Aglipay',
        'Northport',
        'Riacho de Santana',
        'Sekiyado',
        'Dois Irmãos',
        'Tekeli',
        'Pandua',
        'Nova Russas',
        'Bābura',
        'Goussainville',
        'Treviglio',
        'San Ramón',
        'Ciudad Melchor de Mencos',
        'Czeladź',
        'Tepetlaoxtoc',
        'Cruz del Eje',
        'Bannūr',
        'Mont-de-Marsan',
        'Partinico',
        'Moche',
        'Campo Alegre de Lourdes',
        'Balatan',
        'Villiers-sur-Marne',
        'Presidente Figueiredo',
        'Gobernador Virasora',
        'Laḩij',
        'Catolé do Rocha',
        'Chūō',
        'Osterholz-Scharmbeck',
        'Gamu',
        'Newark',
        'Toribío',
        'Chengam',
        'Tabuleiro do Norte',
        'Bad Hersfeld',
        'Ennepetal',
        'San Giuliano Terme',
        'Nazaré da Mata',
        'Juquitiba',
        'Luís Correia',
        'Yunoshima',
        'Niles',
        'Burgess Hill',
        'Eagle',
        'Calafell',
        'Gurnee',
        'Santana do Acaraú',
        'Police',
        'Victoria',
        'Mataas Na Kahoy',
        'Miami Lakes',
        'Jarinu',
        'Carapó',
        'Kaelé',
        'São José da Tapera',
        'Keles',
        'Capim',
        'Taozhuangcun',
        'Diéma',
        'Cachan',
        'Banamba',
        'Uzynaghash',
        'Grottaglie',
        'Talakkād',
        'Devarshola',
        'Birtouta',
        'Aïn Kercha',
        'Buey Arriba',
        'Hafnarfjörður',
        'Sarno',
        'Illescas',
        'Bay Shore',
        'Wallkill',
        'Leusden',
        'Xingang',
        'Zug',
        'Haan',
        'Sokuluk',
        'Osmancık',
        'Olopa',
        'Suzak',
        'Founougo',
        'Liptovský Mikuláš',
        'Hayrabolu',
        'Anilao',
        'Gariadhar',
        'Parkville',
        'Ouatagouna',
        'Aungban',
        'Savigny-le-Temple',
        'Westville',
        'Fārsān',
        'Sankaramangalam',
        'Nāgāwaram',
        'Thung Song',
        'Krabi',
        'Giannitsá',
        'Stratford-upon-Avon',
        'Masho Khel',
        'Hellín',
        'Maināguri',
        'San Carlos',
        'G’ijduvon Shahri',
        'Pamukova',
        'Pittsford',
        'Pau dos Ferros',
        'Gubbio',
        'North Tonawanda',
        'Torrijos',
        'Karuvakulam',
        'Mukumbura',
        'Neuburg',
        'Santa Bárbara',
        'Lawrenceville',
        'Yanai',
        'Afonso Cláudio',
        'Motema',
        'Ar Ruḩaybah',
        'Princeton',
        'Ono',
        'New Smyrna Beach',
        'Trípoli',
        'Erie',
        'Beşiri',
        'Tchindjendje',
        'Ash Shinān',
        'Uran',
        'Maguing',
        'Bandar-e Lengeh',
        'Mandera',
        'Tskhinvali',
        'Magdalena de Kino',
        'Camamu',
        'Pontal do Paraná',
        'Rotterdam',
        'Quillabamba',
        'Bālā Kōh',
        'Carlos Barbosa',
        'Myszków',
        'Menton',
        'Kailahun',
        'Valdepeñas',
        'Friedberg',
        'Karjan',
        'Okotoks',
        'Motomiya',
        'Blagodarnyy',
        'Banlung',
        'Mūvattupula',
        'Austintown',
        'Salyan',
        'Manāwar',
        'Avola',
        'Schönebeck',
        'Samrong',
        'Amagá',
        'Sanford',
        'Ust’-Dzheguta',
        'Oristano',
        'Eldersburg',
        'Kūttānallūr',
        'Dhāri',
        'Salem',
        'Mānsa',
        'Newark upon Trent',
        'Nanyō',
        'Temascaltepec de González',
        'Żywiec',
        'Villemomble',
        'Alma',
        'Rehli',
        'Hirakawachō',
        'Huatabampo',
        'Fengrenxu',
        'Trindade',
        'Graneros',
        'Abū Şuwayr',
        'Shāhpura',
        'Sallimedu',
        'Xinpo',
        'Seguin',
        'Kessel-Lo',
        'Pothuhera',
        'Kurunegala',
        'Al ’Attawia',
        'Télimélé',
        'Ségala Mba',
        'Northolt',
        'San Salvador El Seco',
        'Chimichagua',
        'Dolores',
        'To‘raqo‘rg‘on',
        'Kondopoga',
        'Koni',
        'Liberty',
        'Bella Vista',
        'Cabrobó',
        'Malakoff',
        'Wishaw',
        'Bai Chay',
        'Chimākurti',
        'Andernach',
        'Ometepec',
        'Benguema',
        'San Gregorio de Nigua',
        'Bardejov',
        'Candelaria',
        'Jima Abajo',
        'Majidpur',
        'Drexel Heights',
        'Asprópyrgos',
        'Gaggenau',
        'Jinka',
        'Pantao-Ragat',
        'Zhedao',
        'Kasimov',
        'Las Nieves',
        'Harpenden',
        'Falou',
        'Huanta',
        'Salou',
        'Chabet el Ameur',
        'Tāramangalam',
        'Anse-à-Foleur',
        'Best',
        'Wik’ro',
        'Highland Park',
        'Lewe',
        'Uithoorn',
        'Anjad',
        'Qarataū',
        'Milford',
        'Berriane',
        'Daheba',
        'Whitstable',
        'Tsuru',
        'Crevillente',
        'Calintaan',
        'Mozhaysk',
        'Gorodets',
        'Waterloo',
        'Ndora',
        'Djendel',
        'Gorna Oryahovitsa',
        'Kaipram',
        'Middletown',
        'Kibiti',
        'Kokhma',
        'Bankass',
        'Cláudio',
        'Yverdon-les-Bains',
        'Bowling Green',
        'Periyanāyakkanpālaiyam',
        'Ypacaraí',
        'Liévin',
        'Egra',
        'Inami',
        'Joaçaba',
        'Anse à Pitre',
        'Floresta',
        'Ostuni',
        'Quezaltepeque',
        'Piagapo',
        'Taşova',
        'Kanniparamba',
        'San Giuseppe Vesuviano',
        'Candijay',
        'Marogong',
        'Mussoorie',
        'Pulupandan',
        'Planadas',
        'San Miguel',
        'Mucari',
        'Gukeng',
        'Miramar',
        'Saktī',
        'Dumalag',
        'Rury',
        'Elūr',
        'Balete',
        'Postmasburg',
        'Rahīmpur',
        'Mīnūdasht',
        'Rosignano Marittimo',
        'Oltu',
        'Bretten',
        'Muratlı',
        'Kondarangi Kīranūr',
        'Iguig',
        'Wuyuan',
        'Szekszárd',
        'Monte Patria',
        'Nethirimangalam',
        'Mārgrām',
        'Merzig',
        'Sainte-Julie',
        'Milazzo',
        'Nanchital de Lázaro Cárdenas del Río',
        'Villa Regina',
        'Sakhipur',
        'Zemoura',
        'Degeh Bur',
        'Meschede',
        'Kericho',
        'Granger',
        'Cahul',
        'Dauin',
        'Comiso',
        'Congleton',
        'Kulat',
        'Mahugaon',
        'Jamālpur',
        'Monterey',
        'Santa Maria',
        'Ārda',
        'Balungao',
        'Qahderījān',
        'Chmistâr',
        'El Fanar',
        'Bent Jbaïl',
        'Zghartā',
        'Andranomanelatra',
        'Tsitondroina',
        'Ambinanitelo',
        'Ambalavao',
        'Majuro',
        'Bourèm Guindou',
        'Du Yar',
        'Kuah',
        'Dulmial',
        'Malak Abad',
        'Malème Hodar',
        'Sali',
        'Uar Esgudud',
        'Ikoto',
        'Kafr Nubl',
        'Änew',
        'Mpwapwa',
        'Kayunga',
        'Pop',
        'Kanyobagonga',
        'Gongyefu',
        'Liaojiayuan',
        'Les Palmes',
        'Mundāhal Khurd',
        'Hasanparti',
        'Rānpur',
        'Pātri',
        'Salāya',
        'Edasshēri',
        'Mundi',
        'Padappakara',
        'Sāgwāra',
        'Tirwa',
        'Kaluvāya',
        'Masabdisa',
        'Māgadi',
        'Bāghmāri',
        'Sarpavaram',
        'Dhandhuka',
        '‘Aynkāwah',
        'Jalawlā’',
        'Mutsamudu',
        'Kotovsk',
        'Saundhonwāli',
        'Salay',
        'Pārakadavu',
        'Eshtehārd',
        'Dala',
        'Soledade',
        'Sombrio',
        'Koratgi',
        'Schwandorf',
        'Askøy',
        'Kamisato',
        'Winter Park',
        'Anchieta',
        'East Niles',
        'Chamblee',
        'Navāpur',
        'Riga',
        'Kaminoyama',
        'Zgorzelec',
        'Birūr',
        'Aarschot',
        'Kottūru',
        'Vero Beach South',
        'San Andrés del Rabanedo',
        'Holbæk',
        'Garopaba',
        'Saint-Constant',
        'Radcliffe',
        'Southgate',
        'Agno',
        'Verbania',
        'Gérakas',
        'Dalin',
        'Pind Dadan Khan',
        'Manduria',
        'La Garenne-Colombes',
        'Marhaura',
        'Banning',
        'Ragan Sur',
        'Santa Rita',
        'Mohyliv-Podilskyi',
        'Imbituva',
        'Galesburg',
        'Subotica',
        'Rietberg',
        'Carshalton',
        'Slavgorod',
        'Vyshhorod',
        'Vestal',
        'Triprangōttūr',
        'General Alvear',
        'Dandenong',
        'Mahón',
        'Plympton',
        'Pānakkudi',
        'Serra Negra',
        'Metamórfosi',
        'Algonquin',
        'Conguaco',
        'Aral',
        'Pinhão',
        'San Roque',
        'Mandalī',
        'Mabole',
        'Campo Magro',
        'Padada',
        'Balabagan',
        'Wellesley',
        'Tacuba',
        'El Idrissia',
        'Villa Dolores',
        'Al Madad',
        'Be’er Ya‘aqov',
        'San Andrés Xecul',
        'Xiaba',
        'Quiapo',
        'El Kseur',
        'Waslala',
        'Ozhūr',
        'Kabalo',
        'Gloucester',
        'Fitchburg',
        'Alacuás',
        'Ris-Orangis',
        'San Luis',
        'Motala',
        'Nutley',
        'Kodayattūr',
        'Petite Rivière de Nippes',
        'Nanga Eboko',
        'Oosterend',
        'Shibancun',
        'Dzyarzhynsk',
        'Shetang',
        'Tiruvēgapra',
        'Fleming Island',
        'Thuận Tiến',
        'Krishnarājpet',
        'Kınık',
        'Bad Zwischenahn',
        'Hakha',
        'Bontoc',
        'Talwāra',
        'Fresno',
        'Kaita',
        'Hengchun',
        'Antsampandrano',
        'East Windsor',
        'Puente-Genil',
        'Madavur',
        'Hengshuicun',
        'Ranai',
        'Dahana',
        'Râmnicu Sărat',
        'Puthupalli',
        'Santa Cruz',
        'Raytown',
        'Bulwell',
        'Durango',
        'Mount Hagen',
        'Bois-Colombes',
        'Mananasy-Tsitakondaza',
        'Charneca',
        'Ancón',
        'Nattam',
        'San Lorenzo',
        'Atascadero',
        'Morrisville',
        'Artëmovskiy',
        'Benito Soliven',
        'Bāzeh Kalāgh',
        'Chahchaheh',
        'Bozmargī',
        'Kalāteh-ye Mīr Āb',
        'Santiago do Cacém',
        'Perico',
        'Rendsburg',
        'Reghin',
        'Sardrūd',
        'Dedovsk',
        'Phú Mỹ',
        'Clichy-sous-Bois',
        'Atbasar',
        'Mbanga',
        'Yong’ancun',
        'Tabio',
        'Décines-Charpieu',
        'Devarkonda',
        'Fridley',
        'Heesch',
        'Igarapava',
        'Saint-Cloud',
        'East Fishkill',
        'Tena',
        'Summerlin South',
        'Karahrūd',
        'Makato',
        'Savanette',
        'Dagestanskiye Ogni',
        'Bayramiç',
        'Bergen',
        'Buenavista',
        'Kloof',
        'Nu‘ayjah',
        'Orchard Park',
        'Kitaakita',
        'Asha',
        'Parsuram',
        'Paragould',
        'Sursand',
        'San Jacinto',
        'Vinaroz',
        'Buriti',
        'Hosdurga',
        'Uychi',
        'Manjo',
        'Nepānagar',
        'Werve',
        'La Rinconada',
        'Pasuquin',
        'Newtownards',
        'Rota',
        'Hazleton',
        'Vlasotince',
        'Alushta',
        'Canguaretama',
        'Zaragoza',
        'Stung Treng',
        'Piraju',
        'Yingshouyingzi',
        'Rahway',
        'Pedras de Fogo',
        'Warrnambool',
        'Trutnov',
        'Toksun',
        'Bonou',
        'Synelnykove',
        'West Rembo',
        'Chatou',
        'Waltrop',
        'Oelde',
        'Monfalcone',
        'Lathrop',
        'Petrich',
        'Cruz',
        'Villa Donato Guerra',
        'Bregenz',
        'Jāmai',
        'Pati do Alferes',
        'Placer',
        'Alquízar',
        'Xinmin',
        'Matthews',
        'Chikuzen',
        'Kalpatta',
        'Mannārgudi',
        'Ağdaş',
        'Idar',
        'Veliyangōd',
        'Villa Gesell',
        'Kendal',
        'San Agustín Chahal',
        'Dayr Abū Sa‘īd',
        'Mangdongshan',
        'Kollengode',
        'Gotse Delchev',
        'South Ubian',
        'Rārōtt',
        'Barbosa',
        'Laurel',
        'Point Fortin',
        'Mashan',
        'Bourgoin-Jallieu',
        'Hajdúböszörmény',
        'Tokmak',
        'Schererville',
        'Hobart',
        'Xalatlaco',
        'Lākheri',
        'Inagawa',
        'Zamboanguita',
        'Limoeiro do Ajuru',
        'Burton',
        'Pedro Betancourt',
        'Eastern Goleta Valley',
        'Antsahalava',
        'Güstrow',
        'Chiknāyakanhalli',
        'Landsberg',
        'Maranga',
        'Johnston',
        'Purificación',
        'Vandœuvre-lès-Nancy',
        'Cambuí',
        'Sumilao',
        'Behror',
        'Olocuilta',
        'Temse',
        'Unterschleißheim',
        'Bra',
        'East Palo Alto',
        'Vallentuna',
        'Central',
        'Périgueux',
        'Nan’ao',
        'Sevenoaks',
        'Demnat',
        'N’Gaous',
        'Dongyuya',
        'Sint-Michielsgestel',
        'Duanzhuang',
        'Hutto',
        'Fountain',
        'Mīnād',
        'Garfield Heights',
        'Ja‘ār',
        'Oak Park',
        'Greenville',
        'Augustów',
        'Lalla Mimouna',
        'Kumalarang',
        'Flandes',
        'Escárcega',
        'Melrose',
        'Northfleet',
        'Arari',
        'Manticao',
        'Sankaridrug',
        'Yangshuling',
        'Fritissa',
        'Nejapa',
        'Brecht',
        'Buenos Aires',
        'Dembī Dolo',
        'Zaltbommel',
        'Charenton-le-Pont',
        'West Windsor',
        'Northeim',
        'Tournefeuille',
        'McCandless',
        'Pirakkād',
        'Juchitepec',
        'Garchitorena',
        'Ibrāhīmpatnam',
        'Mūdbidri',
        'Bujaru',
        'Ātmakūr',
        'Pueblo Nuevo Viñas',
        'Vadakku Valliyūr',
        'Uthal',
        'Krimpen aan den IJssel',
        'Tizi Gheniff',
        'Perafita',
        'Guyancourt',
        'Cramlington',
        'Texarkana',
        'Funchal',
        'Kyegegwa',
        'Golden Gate',
        'Figuil',
        'Espigão D’Oeste',
        'Budaörs',
        'Pontedera',
        'Lavezares',
        'Bagong Pag-Asa',
        'Kostomuksha',
        'Lāmerd',
        'Brownsburg',
        'Likino-Dulevo',
        'L’Arbaa Naït Irathen',
        'Bluffton',
        'Marar',
        'Santiago de Baney',
        'Bāglung',
        'Crofton',
        'Kavajë',
        'Carney',
        'Suisun City',
        'Didiéni',
        'Prainha',
        'Póvoa de Santa Iria',
        'Pilachikare',
        'Ra’s al ‘Ayn',
        'Glória do Goitá',
        'Anderson',
        'Casma',
        'Pattikonda',
        'Nalakadoddi',
        'Castelvetrano',
        'Madang',
        'Jacksonville',
        'Asipovichy',
        'Aralam',
        'Pignon',
        'Glenville',
        'Tönisvorst',
        'Shikharpur',
        'Mioveni',
        'Catarroja',
        'Guotang',
        'Pitou',
        'Yōrō',
        'Kirkwood',
        'Ski',
        'Vaihingen an der Enz',
        'Linden',
        'Swarzędz',
        'Benhao',
        'Emmen',
        'Sun City Center',
        'Arfoud',
        'Carmona',
        'San Rafael',
        'Shuangtian',
        'Magna',
        'Namaacha',
        'Chalatenango',
        'Khunti',
        'Novaya Usman’',
        'Licab',
        'Riihimäki',
        'Shibushi',
        'Oakleaf Plantation',
        'Oslob',
        'Rasrā',
        'Gates',
        'Garaimāri',
        'Perry Hall',
        'Quivicán',
        'Paratinga',
        'Cachoeira',
        'Divnogorsk',
        'Winnenden',
        'East Lake-Orient Park',
        'Bromsgrove',
        'Kōshū',
        'Udaipur',
        'Fandriana',
        'Drexel Hill',
        'Le Plessis-Robinson',
        'Saalfeld',
        'Actopan',
        'Capitán Bermúdez',
        'Maddagiri',
        'Orchards',
        'Fundão',
        'Tōmi',
        'Cottica',
        'Changzhi',
        'Dougabougou',
        'Cañada de Gómez',
        'Beldānga',
        'Esmeralda',
        'Bulung’ur Shahri',
        'Poás',
        'Ob',
        'Redondela',
        'Zapotiltic',
        'Santa Cruz Cabrália',
        'Poona-Piagapo',
        'La Source',
        'Arniquet',
        'Kamphaeng Phet',
        'Aipe',
        'Irará',
        'Draveil',
        'San Fernando',
        'Oktyabr’sk',
        'Ath',
        'Washington',
        'Blankenfelde',
        'Shaker Heights',
        'Horki',
        'Nieuwkoop',
        'Anadia',
        'Hinunangan',
        'Rāghopur',
        'Yehud',
        'Queensbury',
        'Yomou',
        'Nelson',
        'McDonough',
        'Bühl',
        'Ulundi',
        'Pallijkarani',
        'Mahendragarh',
        'Nakrekal',
        'Takanezawa',
        'São Miguel do Iguaçu',
        'Mégara',
        'Burbank',
        'Culemborg',
        'Sopó',
        'San Cristóbal',
        'Moñitos',
        'Primero de Enero',
        'Ozumba',
        'Sānkrāil',
        'Springe',
        'Kelkheim (Taunus)',
        'Englewood',
        'Bogdanovich',
        'Viacha',
        'Xiaobazi',
        'Desenzano del Garda',
        'Colgong',
        'Agde',
        'Rapallo',
        'Chili',
        'Şūrān',
        'Hisor',
        'Budaka',
        'Sherobod',
        'Cambuslang',
        'Danville',
        'La Oliva',
        'Pālpā',
        'Madīnat Zāyid',
        'Casselberry',
        'Mehlville',
        'Sonāmukhi',
        'Kālappatti',
        'Baixo Guandu',
        'Zhongwangzhuang',
        'Sexmoan',
        'Riesa',
        'Shumerlya',
        'Teapa',
        'Marigliano',
        'Maubeuge',
        'Whitehall',
        'Boxmeer',
        'Pont-y-pŵl',
        'Kitui',
        'Paripiranga',
        'Vattalkundu',
        'Chortkiv',
        'Catandica',
        'Stoughton',
        'Shiqiao',
        'Uttamapālaiyam',
        'Lugus',
        'Mirandópolis',
        'Sendjas',
        'Santo Domingo',
        'Gagarin',
        'Deodrug',
        'Johi',
        'Somotillo',
        'Tocache Nuevo',
        'Vallikunnam',
        'Kudat',
        'Togitsu',
        'Winterswijk',
        'Matouying',
        'San Marcos',
        'Benavente',
        'Reforma',
        'San Javier',
        'Hendījān',
        'Ouled Haddaj',
        'Carmo do Paranaíba',
        'Meißen',
        'Pirri',
        'Ipubi',
        'Vandiyūr',
        'Ponmana',
        'Dapa',
        'Úrsulo Galván',
        'Abasolo',
        'Senhora da Hora',
        'Ahmer el ’Aïn',
        'Ofaqim',
        'Sortöbe',
        'Timizart',
        'Gūdalūr',
        'Câmpina',
        'Humansdorp',
        'Ermont',
        'Pervomaiskyi',
        'Ystad',
        'Cervia',
        'La Dorada',
        'Tameslouht',
        'Cruces',
        'Plachēri',
        'Germī',
        'Sotteville-lès-Rouen',
        'Langley',
        'Ammi Moussa',
        'Lake in the Hills',
        'Laplace',
        'Kailāras',
        'Ḩawţat Sudayr',
        'Hajira',
        'Limoeiro de Anadia',
        'Orange',
        'Bochnia',
        'Tondela',
        'Russellville',
        'Hakmana',
        'Ampatuan',
        'Barabinsk',
        'Humberto de Campos',
        'Sibanicú',
        'Široki Brijeg',
        'Hanawa',
        'Santo Niño',
        'Pucón',
        'Greenville',
        'Haines City',
        'Fabriano',
        'Stafford',
        'Siquijor',
        'Ban Phai',
        'Jimenez',
        'La Calera',
        'Tabuelan',
        'Kuttuparamba',
        'Candelária',
        'Ariyalūr',
        'Dalfsen',
        'Nāhan',
        'Bielawa',
        'Três Marias',
        'Coromandel',
        'Petrovsk',
        'Tazmalt',
        'Rösrath',
        'Borzya',
        'Karippira',
        'Lādwa',
        'Arrentela',
        'Gidri',
        'Easton',
        'Grimsby',
        'Ponot',
        'Munsan',
        'Lacey',
        'Ruskin',
        'Mount Olive',
        'Mondlo',
        'Carinhanha',
        'Balakliia',
        'Welk’īt’ē',
        'Santa Cruz das Palmeiras',
        'Pokhrām',
        'Stockbridge',
        'Emmendingen',
        'Baleno',
        'Mathba',
        'Thabazimbi',
        'Bou Arfa',
        'Izegem',
        'Rehoboth',
        'Kūdligi',
        'Vīrapāndi',
        'Villiers-le-Bel',
        'Śrem',
        'Duzhuang',
        'Ibaiti',
        'Las Rosas',
        'Caetés',
        'Caicedonia',
        'Takahashi',
        'Babatngon',
        'Hirado',
        'Tiruvambadi',
        'San Lucas Sacatepéquez',
        'Narsimlāpet',
        'Curridabat',
        'Kamalāpuram',
        'Saikaichō-kobagō',
        'Garupá',
        'Lepe',
        'Roseaux',
        'Yoshida',
        'Bugarama',
        'Kiskunfélegyháza',
        'Asago',
        'Tarawa',
        'Pingdeng',
        'Bom Jesus',
        'Jeffersontown',
        'Ponmala',
        'Pápa',
        'Landhaura',
        'Metu',
        'Fresnes',
        'Acatenango',
        'Manatí',
        'Hanwell',
        'Premiá de Mar',
        'Stanford le Hope',
        'Zighout Youcef',
        'Umrat',
        'Martorell',
        'Misilmeri',
        'West Springfield',
        'Pilón',
        'Kozhinjampāra',
        'Bella Vista',
        'Novyi Rozdil',
        'Zhonghechang',
        'Kikinda',
        'Carauari',
        'Nongstoin',
        'Rāikot',
        'Sidi Smai’il',
        'Bixby',
        'Schwelm',
        'Independence',
        'Harrison',
        'Tanglou',
        'Tantoucun',
        'Taza',
        'Maki',
        'Lansing',
        'Chahe',
        'Höxter',
        'Andoharanomaitso',
        'Muritiba',
        'Soissons',
        'Krotoszyn',
        'Ballymena',
        'Tibiao',
        'Karakoçan',
        'Paraparaumu',
        'Mabini',
        'Pakwach',
        'Katima Mulilo',
        'Yerres',
        'Leonding',
        'Ferdows',
        'Candelaria',
        'Pilar',
        'Bang Phongphang',
        'Murlīganj',
        'Nauhata',
        'Souk et Tnine Jorf el Mellah',
        'Benicarló',
        'Esztergom',
        'Jamestown',
        'Navolato',
        'Old Harbour',
        'Santiago',
        'Doña Remedios Trinidad',
        'Geislingen an der Steige',
        'Taungup',
        'Nowy Dwór Mazowiecki',
        'Esparza',
        'San Salvador',
        'Mehdya',
        'Pitoa',
        'Oyabe',
        'Pattāmbi',
        'Sarny',
        'Nueva Concepción',
        'Mondragone',
        'Gevaş',
        'Saito',
        'Kombissiri',
        'Fenoarivobe',
        'Pawāyan',
        'Manga',
        'Ciudad Sahagun',
        'Kartaly',
        'Neduvannūr',
        'Edayikunnam',
        'Agawam',
        'İhsaniye',
        'Mirzāpur',
        'Tudela',
        'Jujutla',
        'Bom Jesus',
        'Xizhou',
        'San Carlos',
        'Tracuateua',
        'Laoaoba',
        'Mampikony',
        'Yangiyer',
        'Akkattettar',
        'Bethlehem',
        'Iúna',
        'Rancharia',
        'Simri Bakhriārpur',
        'Sottaiyampālaiyam',
        'Shangluhu',
        'Koprivnica',
        'Zhongtai',
        'Statesville',
        'Moribabougou',
        'Manampizha',
        'Munnalam',
        'Jaciara',
        'Mechernich',
        'Saugus',
        'Frome',
        'Blackrock',
        'Sabra',
        'Mouzaïa',
        'El Copey',
        'Plaine du Nord',
        'Santo Niño',
        'Baradero',
        'Brodnica',
        'Reinbek',
        'Rūdehen',
        'Basay',
        'Bridgewater',
        'Isnos',
        'Aleysk',
        'Hattersheim',
        'Azángaro',
        'Verden',
        'Bamba',
        'Nivelles',
        'Madison Heights',
        'Amaliáda',
        'Ḩarīr',
        'Monroeville',
        'Enterprise',
        'Saint-Étienne-du-Rouvray',
        'Bāgha Purāna',
        'Losal',
        'Dubăsari',
        'Ambatolampy',
        'Bin-Houyé',
        'Longaví',
        'Rajpur',
        'Heerenveen',
        'Pārdi',
        'Cajati',
        'Szentendre',
        'Matsubushi',
        'Samut Songkhram',
        'Izu',
        'Mendefera',
        'Frattamaggiore',
        'San Lorenzo de Esmeraldas',
        'Sue',
        'Pāvugada',
        'Gusev',
        'Ilave',
        'Condega',
        'Nenmem',
        'Elanjivaliseri',
        'Venezuela',
        'Puerto Leguízamo',
        'Zhailuo',
        'Frankfort',
        'Tsushima',
        'Piracuruca',
        'Wood Green',
        'Milton',
        'Oulad Hammou',
        'Conceição da Barra',
        'Selargius',
        'Mpika',
        'Coyaima',
        'Siraha',
        'Douar Oulad Aj-jabri',
        'Tha Yang',
        'Goio-Erê',
        'Kumru',
        'Castel Volturno',
        'Ouled Ben Abd el Kader',
        'Bonito Oriental',
        'Spring',
        'South Laurel',
        'Forest Hills',
        'Ludwigsfelde',
        'Baishaling',
        'Bushey',
        'Saint Bernard',
        'Almus',
        'Cártama',
        'Harūr',
        'Raseborg',
        'Ar Ruţbah',
        'Short Pump',
        'Byaroza',
        'Jaramijó',
        'Allen Park',
        'Valencia',
        'Aberdeen',
        'San Manuel',
        'Ogawa',
        'Yaldā',
        'Bānswāda',
        'Mitú',
        'Conceição das Alagoas',
        'Datu Piang',
        'Qianxucun',
        'Toli',
        'Ciro Redondo',
        'Rāmanayyapeta',
        'Mahārājpur',
        'Voúla',
        'New Iberia',
        'Sidi Lakhdar',
        'Kamateró',
        'Dieppe',
        'Pongoz',
        'Nkhotakota',
        'Aioi',
        'Ban Bang Khu Wat',
        'Villanueva',
        'Guararema',
        'Prata',
        'Passira',
        'General San Martín',
        'Baunatal',
        'Mannanchōri',
        'Clarksburg',
        'Princes Town',
        'Toucheng',
        'Kpandu',
        'Kočani',
        'Arivonimamo',
        'Brāhmana Periya Agrahāram',
        'Henstedt-Ulzburg',
        'Puvali',
        'Tarāna',
        'Kizhakkōtt',
        'Puttankulam',
        'Magog',
        'Boisbriand',
        'Taiwa',
        'Sāho',
        'Mollendo',
        'Higashikagawa',
        'Srungavarapukota',
        'Glen Ellyn',
        'Karanjiā',
        'Oltinko‘l',
        'Aïn Taoujdat',
        'Temerin',
        'Truskavets',
        'Monsey',
        'Supe',
        'Bermejo',
        'Saint-Sébastien-sur-Loire',
        'Ruy Barbosa',
        'Huanghuajing',
        'Guamal',
        'Bakhor',
        'Tablat',
        'Libacao',
        'Herentals',
        'Rio Pardo de Minas',
        'Kolambugan',
        'Adrogue',
        'Safājā',
        'Shakīso',
        'Juangriego',
        'Geilenkirchen',
        'Maravilha',
        'Qiman al ‘Arūs',
        'Vícar',
        'Northampton',
        'Lagdo',
        'Aného',
        'Ans',
        'Köşk',
        'Giżycko',
        'Bardaskan',
        'Altınova',
        'Kevelaer',
        'Königsbrunn',
        'Cuitzeo del Porvenir',
        'Temescal Valley',
        'Hājipur',
        'Edappalli',
        'Bergenfield',
        'Caucete',
        'Antur',
        'Eonyang',
        'Diangouté Kamara',
        'Cocal',
        'Sirsāganj',
        'Walnut',
        'Santo Antônio do Içá',
        'Vakfıkebir',
        'Poggibonsi',
        'Kotagiri',
        'Zacoalco de Torres',
        'Metepec',
        'Bejucal',
        'Grimma',
        'Heywood',
        'Əmircan',
        'Whitehorse',
        'Wappinger',
        'Heshancun',
        'Reda',
        'Kroměříž',
        'Ljubuški',
        'Ayun',
        'Babar',
        'Carmagnola',
        'Ouésso',
        'Pokhvistnevo',
        'Agui',
        'Dobropillia',
        'Shāhpura',
        'Arqalyq',
        'Çiftlik',
        'Lier',
        'Chatan',
        'Eagle Pass',
        'Henderson',
        'Itabela',
        'Guma',
        'Camas',
        'Dar Bel Hamri',
        'Warin Chamrap',
        'Nalayh',
        'Villaviciosa de Odón',
        'Nirasaki',
        'Khrestivka',
        'Gaotan',
        'Saldanha',
        'Obama',
        'Carrickfergus',
        'Tetela de Ocampo',
        'Sønderborg',
        'Chennamangalam',
        'Glen Cove',
        'Pinyahan',
        'Alotenango',
        'Arnstadt',
        'Cabricán',
        'Čapljina',
        'Bearsden',
        'Bhambia Bhai',
        'Udomlya',
        'La Paz Centro',
        'Cabangan',
        'Ridgecrest',
        'Sāngola',
        'Sheohar',
        'San Vicente dels Horts',
        'Neptune',
        'Dieppe',
        'Massamá',
        'Tsinjoarivo',
        'Conchal',
        'Golo-Djigbé',
        'Bom Jardim',
        'Seseña',
        'Kakiri',
        'Tara',
        'Maryland Heights',
        'Kirov',
        'Hazar',
        'Kuli',
        'Nallıhan',
        'Gyula',
        'Xochistlahuaca',
        'Nakhal',
        'Mima',
        'Qujingpu',
        'Sueca',
        'San Vicente de Chucurí',
        'Casa Branca',
        'Pô',
        'Khulm',
        'Guaimaca',
        'Wegberg',
        'Honchō',
        'Dajabón',
        'Djugu',
        'Kalwākurti',
        'East Hampton',
        'Reddish',
        'Converse',
        'Rhennouch',
        'Lincoln',
        'Devanhalli',
        'Gingee',
        'Leichlingen',
        'Villazón',
        'Khust',
        'Åkersberga',
        'Oakdale',
        'Vallauris',
        'Kefar Yona',
        'Bayang',
        'Nunspeet',
        'Navahrudak',
        'Mainit',
        'Vanves',
        'Mchinji',
        'Anajás',
        'Lomme',
        'Twentynine Palms',
        'Douentza',
        'Tainai',
        'Dübendorf',
        'Geldrop',
        'Qornet Chahouâne',
        'Sahavato',
        'Imito',
        'Sandrandahy',
        'Ambohijanahary',
        'Masanwa',
        'Bo‘ka',
        'Qianshanhong Nongchang',
        'Jiaoxiling',
        'Sāila',
        'Limeil-Brévannes',
        'Kārākurisshi',
        'Billericay',
        'Cuartero',
        'Langedijk',
        'Isser',
        'Almazora',
        'Lubok Antu',
        'Montfermeil',
        'San Felipe Jalapa de Díaz',
        'Pacho',
        'Belpasso',
        'Zeitz',
        'Yangyuhe',
        'Orlová',
        'Shaler',
        'San Andrés Sajcabajá',
        'Kasangulu',
        'Kushtagi',
        'Gragnano',
        'Shalqar',
        'San Martín de los Andes',
        'Kanavāikuli',
        'Punceres',
        'Āron',
        'Lake Jackson',
        'Dhabauli',
        'Labasa',
        'Goriar',
        'Hamar',
        'Canosa di Puglia',
        'Vilāngudi',
        'Noé',
        'Douglas',
        'Tsinjoarivo',
        'Aloran',
        'Garden City',
        'Nochistlán de Mejía',
        'Bitam',
        'Ennis',
        'Malmal',
        'Iguaba Grande',
        'Sfizef',
        'Newry',
        'Danvers',
        'Mocímboa',
        'Dahmani',
        'Łuków',
        'Bouka',
        'Ash Shajarah',
        'El Sauce',
        'Reina Mercedes Viejo',
        'Reina Mercedes',
        'Bordj el Bahri',
        'Ambohitompoina',
        'Larreynaga',
        'Majayjay',
        'Caridad',
        'Gülnar',
        'Maple Valley',
        'Gyöngyös',
        'Hiji',
        'Harelbeke',
        'Cecina',
        'Dingalan',
        'Melgaço',
        'Talusan',
        'Orvault',
        'Mytilíni',
        'Chichaoua',
        'San Isidro',
        'Zanandore',
        'Ampasimanolotra',
        'Mahaplag',
        'Quiroga',
        'Daphne',
        'Villa Luvianos',
        'Topki',
        'Sirakorola',
        'Chalhuanca',
        'Umargām',
        'Guaçuí',
        'Sabaneta',
        'Kakuda',
        'Zolotonosha',
        'Hamtramck',
        'Bārughutu',
        'Griesheim',
        'Uchturpan',
        'Muchun',
        'Sestao',
        'Sachse',
        'Traipu',
        'Wāsi',
        'Mizumaki',
        'Darlaston',
        'Belmont',
        'Nibria',
        'Magdalena',
        'Motozintla',
        'Vleuten',
        'Lohne',
        'Rockledge',
        'Māttūl',
        'Tualatin',
        'Wilmette',
        'Vadnagar',
        'Aci Catena',
        'Tlalpujahua de Rayón',
        'Williston',
        'Chato',
        'Ramsey',
        'Desnogorsk',
        'Viñales',
        'Soledad de Doblado',
        'Bongor',
        'Bernards',
        'Ivanjica',
        'Tenares',
        'Ashington',
        'Chaska',
        'Mongo',
        'Fūman',
        'Ramotswa',
        'Charkhāri',
        'Paivalike',
        'Williamsport',
        'Pirapòzinho',
        'Immokalee',
        'Monção',
        'Qibray',
        'Itapissuma',
        'Ōuda-yamaguchi',
        'Khātegaon',
        'As Sarw',
        'Gabasumdo',
        'Havran',
        'Hidrolândia',
        'Sundern',
        'Lapuyan',
        'Ocean',
        'Batken',
        'Tahla',
        'Palmar de Varela',
        'Lebrija',
        'João Alfredo',
        'Itacaré',
        'Tudiyalūr',
        'Parabiago',
        'Mogliano Veneto',
        'Dodge City',
        'San Giovanni in Persiceto',
        'Axim',
        'North Kingstown',
        'Koewarasan',
        'Borşa',
        'Kashima',
        'Santa Bárbara',
        'George Town',
        'San Miniato',
        'Shuiding',
        'Bellaa',
        'Wajimazakimachi',
        'Teltow',
        'Spinea',
        'Santa Rosa de Lima',
        'Alatri',
        'San Pedro del Pinatar',
        'Poruvakara',
        'Porto Belo',
        'Troyan',
        'Le Chesnay',
        'Tabira',
        'Aparecida do Taboado',
        'Amarpur',
        'San Pablo',
        'Brunssum',
        'Argelia',
        'Ban Chang',
        'Kitsuki',
        'Clinton',
        'New Windsor',
        'Atlautla',
        'Tuku',
        'Malargüe',
        'Lagoa Vermelha',
        'Phuntsholing',
        'Tangutūru',
        'Huntley',
        'Niuchangqiao',
        'Zongdi',
        'Mukher',
        'San Juan Guichicovi',
        'Di Linh',
        'Parsa',
        'Hikawa',
        'Mount Pleasant',
        'Leon Postigo',
        'Longtan',
        'Pachor',
        'Pādiyanallūr',
        'Yahaba',
        'Bhimbar',
        'Yemanzhelinsk',
        'Theniet el Had',
        'Quijingue',
        'Shatiancun',
        'Shilan',
        'Sucy-en-Brie',
        'Mont-Dore',
        'Maracás',
        'Baesweiler',
        'Zarechnyy',
        'Lagoa Sêca',
        'Tysons',
        'Veendam',
        'Reisterstown',
        'Pilar do Sul',
        'Contla',
        'Prior Lake',
        'Vila do Conde',
        'Assisi',
        'Soignies',
        'Ambodibonara',
        'New London',
        'Cabrero',
        'Madison',
        'Svendborg',
        'Ouled Fayet',
        'Waddān',
        'Swatara',
        'Laojiezi',
        'Hulst',
        'Câmpulung',
        'Taougrite',
        'Andenne',
        'Ouled Slama Tahta',
        'Narat',
        'Wengtiancun',
        'Wangtang',
        'Karlskoga',
        'Atharan Hazari',
        'Jauja',
        'Mulchén',
        'Homewood',
        'Mazenod',
        'Conner',
        'Pombos',
        'Fortuna Foothills',
        'Wetter (Ruhr)',
        'Kantai',
        'Sorochinsk',
        'Kobuleti',
        'Heemstede',
        'Novo Oriente',
        'Rardhu',
        'Citong',
        'Rāyamangalam',
        'Hacıqabul',
        'Ninomiya',
        'Patjirwa',
        'Teno',
        'Marion',
        'Jarrow',
        'Niimi',
        'Abarkūh',
        'Millville',
        'Witney',
        'Takahagi',
        'Apaxco de Ocampo',
        'Pitogo',
        'Daulatkhān',
        'Marshalltown',
        'Marolambo',
        'Sūlūru',
        'Sitakili',
        'Timaru',
        'Nuwara Eliya',
        'Verkhniy Ufaley',
        'İpsala',
        'Triunfo',
        'Sint-Joost-ten-Node',
        'Tazoult-Lambese',
        'Fichē',
        'McHenry',
        'Shingū',
        'Nāsriganj',
        'Supía',
        'Bourem',
        'Oiapoque',
        'Bandipura',
        'Lemon Grove',
        'Kholmsk',
        'Oulad Yaïch',
        'Tongyangdao',
        'Duayaw-Nkwanta',
        'Piraí',
        'Konnūr',
        'Ukiha',
        'Aleksandrovskoye',
        'San Carlos',
        'Águas Santas',
        'Schloß Holte-Stukenbrock',
        'Feteşti',
        'Purral',
        'Ciudad Bolívar',
        'Los Reyes de Juárez',
        'Toqsu',
        'Villa El Carmen',
        'Arar',
        'Vandikarai',
        'Maloyaroslavets',
        'Algemesí',
        'Mandirituba',
        'Malekān',
        'Sürmene',
        'Crestview',
        'Mu’tah',
        'Mililani Town',
        'Cyangugu',
        'Jardim',
        'Wangen im Allgäu',
        'Heilbron',
        'Ružomberok',
        'Guastatoya',
        'Overath',
        'Caracal',
        'Sébikhotane',
        'Butzbach',
        'Oppegård',
        'Villareal',
        'Shotley Bridge',
        'Sādri',
        'Ayyagarpet',
        'Ibipetuba',
        'Nalbāri',
        'Itaí',
        'Ashford',
        'Penukonda',
        'Leiderdorp',
        'Staraya Russa',
        'Itako',
        'Tuxpan',
        'Macau',
        'Chicago Heights',
        'Ciudad Tecún Umán',
        'Kedu',
        'Balussheri',
        'Chillán Viejo',
        'K’olīto',
        'Panauti',
        'Paouignan',
        'Korgan',
        'Strausberg',
        'Jalpan',
        'Varandarapilli',
        'Kent',
        'Green',
        'King',
        'Blerick',
        'Ikaruga',
        'Parksville',
        'Aytos',
        'Drimmelen',
        'Alvin',
        'Shtime',
        'Palanas',
        'Bihpur',
        'Balch Springs',
        'Castaños',
        'Macalelon',
        'Neenah',
        'Cuerámaro',
        'Pallippurattusēri',
        'Chiavari',
        'Navrongo',
        'Zarraga',
        'Almuñécar',
        'Savur',
        'Deer Park',
        'Nokha',
        'Piešťany',
        'Gurlan',
        'Xovos',
        'Ilchester',
        'Santo Antônio do Monte',
        'Porto Calvo',
        'Bhānder',
        'Behara',
        'Corrente',
        'Ramon Magsaysay',
        'Ehingen an der Donau',
        'Santo Amaro da Imperatriz',
        'Mason City',
        'Sipacapa',
        'Mirnyy',
        'Haliyāl',
        'Bady Bassitt',
        'Citrus Park',
        'Zalingei',
        'Grigny',
        'Pearl',
        'Choba',
        'Novi Ligure',
        'Akitakata',
        'Hamminkeln',
        'Álvares Machado',
        'Talisay',
        'New Lenox',
        'Kushva',
        'Tepehuacán de Guerrero',
        'Gubkinskiy',
        'Debiāpur',
        'Hayang',
        'Kahului',
        'Fremont',
        'Morón de la Frontera',
        'Holbrook',
        'Redenção',
        'Longton',
        'Bourzanga',
        'Calimete',
        'Ezhipram',
        'Garden City',
        'Castelli',
        'Oak Forest',
        'Mocajuba',
        'Ewa Gentry',
        'West Islip',
        'Kimbe',
        'Sihecun',
        'Boralday',
        'Merrimack',
        'Kobyłka',
        'El Abadia',
        'Taraka',
        'Paiporta',
        'Les Anglais',
        'Tauramena',
        'Kernersville',
        'Kupiansk',
        'Conception Bay South',
        'West Linn',
        'Melton Mowbray',
        'Bo’ao',
        'Nguti',
        'Buri Ram',
        'San Juan de Urabá',
        'Dargeçit',
        'Zhongshan',
        'Keswick',
        'Leimen',
        'Hohen Neuendorf',
        'Lindenhurst',
        'Jonava',
        'Pijnacker',
        'Thomasville',
        'Digboi',
        'Piçarras',
        'Dame-Marie',
        'Čakovec',
        'Parimpūdi',
        'Lambersart',
        'Aalten',
        'San Agustín Tlaxiaca',
        'Illkirch-Graffenstaden',
        'Asheboro',
        'Rappang',
        'Novi Grad',
        'Sikonge',
        'Nemuro',
        'Jeffrey’s Bay',
        'Awara',
        'Brétigny-sur-Orge',
        'Haiyang',
        'Rheinbach',
        'Lishanpu',
        'Pershotravensk',
        'Mirano',
        'Ambohijanaka',
        'Pereyaslav-Khmel’nyts’kyy',
        'Afzalpur',
        'Fort Saskatchewan',
        'Tall Rif‘at',
        'Sol’-Iletsk',
        'Long Thành',
        'Liugoucun',
        'Batgram',
        'Quakers Hill',
        'Ichikikushikino',
        'Villagrán',
        'Muchamiel',
        'Vittorio Veneto',
        'Lochearn',
        'Zomin Shaharchasi',
        'El Alia',
        'Minaçu',
        'Irimbiliyam',
        'Ágios Nikólaos',
        'Pedernales',
        'Kuzhittura',
        'Nova Olinda do Norte',
        'Nayoro',
        'Ksebia',
        'Klosterneuburg',
        'Toguéré-Koumbé',
        'Harbiye',
        'Badiangan',
        'Pasinler',
        'Wakefield',
        'Libenge',
        'Guanxi',
        'Nazaré',
        'Wiesloch',
        'San Isidro',
        'Haverhill',
        'Benicia',
        'Rāver',
        'Loutété',
        'Quezon',
        'İmamoğlu',
        'Springettsbury',
        'Märsta',
        'Sens',
        'Leisure City',
        'Plum',
        'Santa Eugenia',
        'Nizao',
        'Thetford',
        'Amatepec',
        'Catamayo',
        'Granite City',
        'Taverny',
        'Malebennūr',
        'Mādhura',
        'Wooster',
        'Ermelo',
        'Āmangal',
        'Bāgepalli',
        'Ticuantepe',
        'Alexânia',
        'Pueblo Bello',
        'Chiriguaná',
        'Marcos Juárez',
        'Pāyakarāopeta',
        'Vohipaho',
        'Tsaravary',
        'Miandrarivo',
        'Mandabe',
        'Andranomavo',
        'Antsiatsiaka',
        'Alakamisy Itenina',
        'Arandu',
        'Jaglot',
        'Haqqulobod',
        'Aragua de Barcelona',
        'Arrecifes',
        'Barbacha',
        'Paracuellos de Jarama',
        'Catió',
        'Woolwich',
        'Estarreja',
        'Kriens',
        'Belmont',
        'Tarragona',
        'Rapperswil-Jona',
        'Oullins',
        'Ourika Wawrmas',
        'Ambinanisakana',
        'Soanierana Ivongo',
        'Anajatuba',
        'Limbe',
        'Aldridge',
        'Basi',
        'Aripuanã',
        'Kapellen',
        'Kadappuram',
        'San Sebastián de Yalí',
        'Ar Rudayyif',
        'Novo Cruzeiro',
        'Gladstone',
        'Nava',
        'Ternivka',
        'Uyuni',
        'Seaford',
        'Lemoore',
        'Bouguirat',
        'Potrerillos',
        'Wassenaar',
        'Zuitou',
        'Heppenheim',
        'Fidenza',
        'São Bernardo',
        'Pfaffenhofen',
        'Werkendam',
        'Tupanatinga',
        'Albania',
        'Farnworth',
        'Moon',
        'Ureshinomachi-shimojuku',
        'Dietikon',
        'Şafāshahr',
        'Nainijor',
        'Tambo',
        'Łowicz',
        'Znamensk',
        'Pemberton',
        'Celendín',
        'Nelliyalam',
        'Qā’emīyeh',
        'Khmilnyk',
        'Margherita',
        'Maner',
        'Pınarbaşı',
        'San Andrés de la Barca',
        'Kulasekharapuram',
        'Atitalaquia',
        'Kaluđerica',
        'Karasuk',
        'San Antonio del Monte',
        'Wahga',
        'Mayskiy',
        'Lobatse',
        'Wheeling',
        'Acapetahua',
        'Bridgeton',
        'Baraúna',
        'Al Qiţena',
        'Albignasego',
        'Pāppākurichchi',
        'Pāmidi',
        'Douglas',
        'Idigny',
        'Miracema',
        'Kadambanād',
        'Alhaurín el Grande',
        'Lukula',
        'Kararān',
        'Sarayönü',
        'Bakhchysarai',
        'San Luis Jilotepeque',
        'Kızılcahamam',
        'Arerāj',
        'Union City',
        'Paducah',
        'Madattukkulam',
        'Kālikāvu',
        'Miki',
        'Feidh el Botma',
        'Gursarāi',
        'Pūnch',
        'Scicli',
        'Chíos',
        'Shoreview',
        'Nsanje',
        'Xima',
        'Bozyazı',
        'Clayton',
        'Canindé de São Francisco',
        'Majagua',
        'Vādippatti',
        'Bankāpur',
        'Shencottah',
        'Villeparisis',
        'Alitagtag',
        'Temple Terrace',
        'Santa Margarita',
        'Rambouillet',
        'Ives Estates',
        'Labrador',
        'Vilyeyka',
        'Wesselsbron',
        'Cangas',
        'Ban Phonla Krang',
        'Khānābād',
        'Bamessi',
        'Magilampupuram',
        'Fortul',
        'Tūvūr',
        'Bolton',
        'Srīnivāspur',
        'Garbagnate Milanese',
        'Trikkunnapuzha',
        'Paracuaro',
        'Margate',
        'Mirassol d’Oeste',
        'Jumilla',
        'Líšeň',
        'Cenon',
        'Solânea',
        'Tamayo',
        'Santa María de Jesús',
        'Sannois',
        '‘Abasān al Kabīrah',
        'Qarah Ẕīā’ od Dīn',
        'Thātha',
        'West Melbourne',
        'Bétera',
        'Tlaxcoapan',
        'Mahina',
        'Gadhada',
        'Bidur',
        'Vernon Hills',
        'Tamorot',
        'Meppāyyūr',
        'Magarao',
        'Malitbog',
        'Goianinha',
        'Cormeilles-en-Parisis',
        'Upper Dublin',
        'Semiluki',
        'Hennigsdorf',
        'Ḩukūmatī Baghrān',
        'Soron',
        'Santa María Ixhuatán',
        'Mégrine',
        'Alattūr',
        'Miacatlán',
        'Saguiaran',
        'Bhuban',
        'Morales',
        'Los Córdobas',
        'Circasia',
        'Kiruna',
        'Ronse',
        'Wanlaweyn',
        'Sapian',
        'Qahā',
        'Māmidālapādu',
        'Palm Springs',
        'Lamego',
        'Pirenópolis',
        'Miyanaga',
        'Piracaia',
        'Shelek',
        'Shangxiao',
        'Wellington',
        'Pitangui',
        'Quarrata',
        'Heiligenhaus',
        'Turrialba',
        'Nediyanad',
        'Ipu',
        'San Juan',
        'Auburn',
        'Ambodiangezoka',
        'Itamaracá',
        'Al Quţayfah',
        'Sacramento',
        'Birine',
        'Bad Neuenahr-Ahrweiler',
        'Nannamukku',
        'Paianía',
        'Talāja',
        'Bien Unido',
        'Segezha',
        'Samayac',
        'Highbury',
        'Kuppādi',
        'Mizque',
        'Bugho',
        'Dalsingh Sarai',
        'South Portland',
        'Kirovsk',
        'Horn Lake',
        'Las Flores',
        'Frías',
        'San Pablo',
        'Ajka',
        'Chettipālaiyam',
        'Manjacaze',
        'Betong',
        'San Felipe',
        'Sun City West',
        'Sibi',
        'Silao',
        'Rājgarh',
        'Buldan',
        'Manihāri',
        'Cuajinicuilapa',
        'Maçka',
        'Edwardsville',
        'Carrollton',
        'Lauri',
        'Plainview',
        'Propriá',
        'Moulay Bousselham',
        'Dar Chioukh',
        'Baytūnyā',
        'Aschersleben',
        'Jaguarão',
        'Chuanliaocun',
        'Newton Mearns',
        'Pantar',
        'Wieliczka',
        'Ibimirim',
        'San Benito Abad',
        'Sidi Akkacha',
        'Voinjama',
        'Ghatāro Chaturbhuj',
        'Okuta',
        'Nova Esperança',
        'South Pasadena',
        'Ratau',
        'Iheddadene',
        'Paramus',
        'Susquehanna',
        'Macrohon',
        'Lauaan',
        'Miguel Pereira',
        'Porto da Folha',
        'Sofiyivs’ka Borshchahivka',
        'Bussy-Saint-Georges',
        'Jarocin',
        'Casiguran',
        'Gioia del Colle',
        'Superior',
        'Iesolo',
        'Tarui',
        'Plainview',
        'La Teste-de-Buch',
        'Shāhganj',
        'Molíns de Rey',
        'Sanger',
        'San Alberto',
        'Lanquín',
        'Manāsa',
        'Horsham',
        'Agía Varvára',
        'Dasūya',
        'Perumbalam',
        'Nyāmti',
        'Severouralsk',
        'Cabot',
        'Zeboudja',
        'Mint Hill',
        'Mantena',
        'Sainte-Thérèse',
        'Étampes',
        'Deogarh',
        'Ituporanga',
        'Kantābānji',
        'Nacimiento',
        'Aleksandrovac',
        'Krasnoarmeysk',
        'Eureka',
        'Novoaleksandrovsk',
        'Dois Córregos',
        'La Unión',
        'Hennaya',
        'Bubong',
        'Ahmadābād',
        'Giarre',
        'Qingshan',
        'Brawley',
        'Clarence-Rockland',
        'Uchinada',
        'Shirley',
        'Charata',
        'Neckarsulm',
        'Karumāndi Chellipālaiyam',
        'Troy',
        'Elmira',
        'Sebeş',
        'Al Karnak',
        'Capão do Leão',
        'Xincun',
        'Timbiras',
        'Upper Macungie',
        'Cheadle Hulme',
        'Sokoura',
        'Madalum',
        'Marapanim',
        'Achern',
        'Kayapa',
        'Langdu',
        'Blagnac',
        'Apam',
        'Quilalí',
        'Água Preta',
        'El Monte',
        'Gördes',
        'Kaisarianí',
        'Mabini',
        'Randolph',
        'Wete',
        'Wall',
        'Pérez',
        'Lido di Iesolo',
        'Kamyshlov',
        'Makubetsu',
        'Apollo Beach',
        'Maski',
        'Oktyabrsk',
        'Tāmarakulam',
        'Nakhon Phanom',
        'Caboolture',
        'Santa Josefa',
        'Pānchla',
        'Tortona',
        'Tecolutla',
        'Peniche',
        'Mandi',
        'Māvelikara',
        'Sélibaby',
        'Lauf',
        'Mauganj',
        'Groß-Gerau',
        'Newton Aycliffe',
        'Beohāri',
        'Madruga',
        'Nādbai',
        'Dinokana',
        'Zemmouri',
        'La Prairie',
        'Gorlice',
        'Miramas',
        'Del Gallego',
        'Blenheim',
        'Owatonna',
        'Estoril',
        'Binə',
        'Mühlacker',
        'Dīnānagar',
        'Cajuru',
        'Jinju',
        'Vīrakeralam',
        'Ouled Mimoun',
        'Rangia',
        'Kirovsk',
        'Camas',
        'Balboa',
        'East Grinstead',
        'Elakādu',
        'Ashton in Makerfield',
        'Rēzekne',
        'Dalnerechensk',
        'San Jose',
        'Zottegem',
        'Bombo',
        'Dongen',
        'Gundlupēt',
        'Lagindingan',
        'East Chicago',
        'Jaltenco',
        'Vicência',
        'Reoti',
        'Panchānandapur',
        'Pandag',
        'Batavia',
        'İncesu',
        'Yuanyangzhen',
        'Poldokhtar',
        'Wyszków',
        'Tougan',
        'Sapouy',
        'Ilha Solteira',
        'Vogošća',
        'Bāgh-e Malek',
        'Pensilvania',
        'Lichtenburg',
        'Ondokuzmayıs',
        'Duptiair',
        'Elói Mendes',
        'Kirzhach',
        'Cheranallūr',
        'Parit Buntar',
        'Bergerac',
        'Tehuipango',
        'Weiterstadt',
        'Navabad',
        'San Pablo',
        'Caririaçu',
        'Clydebank',
        'Windsor',
        'Ulaangom',
        'Ubaidullāhganj',
        'Werota',
        'Siquinalá',
        'Wasco',
        'Walpole',
        'South Salt Lake',
        'Ibotirama',
        'Bingen am Rhein',
        'Nordenham',
        'Cotoca',
        'Koelwār',
        'Khā̃dbāri̇̄',
        'La Ceiba',
        'Batalha',
        'Nuevo Arraiján',
        'Aru',
        'Bozkır',
        'East Hempfield',
        'Baden',
        'Az Zabadānī',
        'Făgăraş',
        'Narasannapeta',
        'Rasiāri',
        'Sabaa Aiyoun',
        'Beramanja',
        'Saint-Bruno-de-Montarville',
        'Kaboïla',
        'Wright',
        'Yapacani',
        'Aş Şanamayn',
        'Ōra',
        'Teustepe',
        'Pooler',
        'Laranjal Paulista',
        'Semīrom',
        'Zhaicun',
        'Bayanhongor',
        'Carbonia',
        'Karpinsk',
        'Midland',
        'Infanta',
        'Mirganj',
        'Mel Bhuvanagiri',
        'Paz de Ariporo',
        'Bourdoud',
        'Lamut',
        'Kūdlu',
        'San Giovanni Rotondo',
        'Zirndorf',
        'Poro',
        'Colonia del Sacramento',
        'Buesaco',
        'Lainate',
        'Ganjing',
        'Tekkalakote',
        'Chivasso',
        'Rancho Grande',
        'Willebroek',
        'Kalfou',
        'Woodburn',
        'Muñiz',
        'Ksour Essaf',
        'Gedera',
        'Tālīkota',
        'Saumur',
        'Kiskunhalas',
        'Wilmslow',
        'Polysayevo',
        'Labytnangi',
        'Aït Faska',
        'Sogrāha',
        'Cañas',
        'Eṭ Ṭīra',
        'Iradan',
        'Vadakkanandal',
        'Buddayyakota',
        'Kottakota',
        'Guruzāla',
        'Lunel',
        'Marimba',
        'Athens',
        'Liushuquan',
        'Kętrzyn',
        'Rosario de la Frontera',
        'Flémalle-Haute',
        'Puerto Varas',
        'Budhlāda',
        'Montemor-o-Velho',
        'Shuanghe',
        'Sant’Anastasia',
        'Burlington',
        'Tavira',
        'Sanchez-Mira',
        'Suphan Buri',
        'Lice',
        'Selm',
        'Manito',
        'Jirwa',
        'Mankāchar',
        'Iskandar',
        'Baykan',
        'Forest Grove',
        'Beuningen',
        'Mortsel',
        'Imperial Beach',
        'Austin',
        'San Ignacio',
        'Mesagne',
        'Frankfort',
        'Dum Duma',
        'Suār',
        'Gūdūru',
        'Rīngas',
        'Kaous',
        'Oum Hadjer',
        'Cifuentes',
        'Perevalsk',
        'Ouled Rahmoun',
        'Dongfeng',
        'Bijbiāra',
        'Pacasmayo',
        'Çankırı',
        'Wum',
        'Santiago Papasquiaro',
        'Lockport',
        'Bāruni',
        'Vadakku Viravanallur',
        'Montichiari',
        'Gourma Rharous',
        'Tuxpan',
        'Huaquechula',
        'Itapuranga',
        'Skopin',
        'Terlizzi',
        'Kuyucak',
        'Tōno',
        'Desamparados',
        'Anosipatrana',
        'Novopavlovsk',
        'Campos Gerais',
        'Terrytown',
        'Misserghin',
        'Camanducaia',
        'Mzimba',
        'Sierra Bullones',
        'Tianyingcun',
        'Elangunnapuzha',
        'Medina',
        'Phulera',
        'Requínoa',
        'Erice',
        'Terdāl',
        'Tholen',
        'Konobougou',
        'Woodstock',
        'Winona',
        'Élancourt',
        'Dengjiazhuang',
        'Halemba',
        'Tralee',
        'Northdale',
        'Kathu',
        'Key West',
        'Chellalat el Adhaouara',
        'Dancagan',
        'Mahates',
        'Franklin',
        'Thetford Mines',
        'Odemira',
        'Tianwei',
        'Alagoa Grande',
        'La Máquina',
        'Bad Honnef am Rhein',
        'El Hadjar',
        'Casillas',
        'Keystone',
        'Jászberény',
        'Mahitsy',
        'Emmeloord',
        'Dardoq',
        'Gamboma',
        'Burntwood',
        'Zavodoukovsk',
        'Chuimatan',
        'Buenavista',
        'Ridgewood',
        'Hercules',
        'Burgos',
        'Hénin-Beaumont',
        'San Juan Atitán',
        'Renigunta',
        'Bangzha',
        'Lübbecke',
        'Barra da Estiva',
        'Hovd',
        'Skardu',
        'Bajina Bašta',
        'Fort Mill',
        'Cave Spring',
        'Idhnā',
        'Sāndi',
        'Vertou',
        'Bresso',
        'Ambatomborona',
        'Malaimbandy',
        'Mahambo',
        'Ambatofotsy',
        'Tsarazaza',
        'Poytug‘',
        'Brasiléia',
        'Majiadiancun',
        'Harsewinkel',
        'Sinait',
        'Lakshmīcharīpāra',
        'Gahmar',
        'Gaoya',
        'De Witt',
        'Rosemount',
        'Rich',
        'Dehgolān',
        'Wilsonville',
        'Upper Moreland',
        'Mangaldai',
        'Gonglang',
        'Fairland',
        'Londonderry',
        'Bom Jesus dos Perdões',
        'Montreux',
        'Dharmapuram',
        'Puerto Píritu',
        'Istmina',
        'Tiruppattūr',
        'Queimadas',
        'Curtea de Argeş',
        'Le Grand-Quevilly',
        'Rāmpur',
        'Tekman',
        'Lodi',
        'Santa Lucia',
        'Binidayan',
        'Bobon',
        'Gonesse',
        'Gölköy',
        'Palm City',
        'Giussano',
        'Ōtake',
        'Giyani',
        'Chintalapūdi',
        'Kimovsk',
        'Pando',
        'Big Spring',
        'Gressier',
        'Mathibestad',
        'Socorro',
        'Dolores',
        'Columbine',
        'São Joaquim',
        'Fleetwood',
        'Boscoreale',
        'Santa Ana',
        'Bournville',
        'Masrakh',
        'Elk River',
        'San Sebastián Coatán',
        'Putignano',
        'Gembloux',
        'Mannachchanellūr',
        'Maevatanana',
        'Santa Perpetua de Moguda',
        'Krishnāpuram',
        'Cavaillon',
        'Dano',
        'Jaynagar-Majilpur',
        'Cesenatico',
        'Grandview',
        'Azacualpa',
        'Hjørring',
        'Tubize',
        'La Garde',
        'Grevená',
        'Haoping',
        'Puerto Escondido',
        'Lučenec',
        'Mimoso do Sul',
        'San Martín',
        'El Jícaro',
        'Formosa do Rio Preto',
        'Gilarchāt',
        'Demre',
        'Jodhpur',
        'Teniente Primero Manuel Irala Fernández',
        'Chevella',
        'Bessemer',
        'El Quetzal',
        'Kibeho',
        'Noicattaro',
        'Galatina',
        'Dhrol',
        'Ōmachi',
        'Norfolk',
        'Xishan',
        'Vimercate',
        'Colleyville',
        'Vesala',
        'Jharka',
        'Marmara Ereğlisi',
        'Unterhaching',
        'Boureït',
        'Jenks',
        'Fonte Boa',
        'El Cerrito',
        'Chanhassen',
        'San Juan',
        'Ciempozuelos',
        'Santa Isabel do Rio Negro',
        'Perunkalattu',
        'Ban Pa Sak',
        'Montgomery',
        'Kotal',
        'Port Alfred',
        'Amnat Charoen',
        'Alaçam',
        'Calpe',
        'Gokarn',
        'Ciudad Altamirano',
        'Triggiano',
        'Cəlilabad',
        'Lindau',
        'Dondon',
        'Canarana I',
        'Artvin',
        'Yuanchang',
        'El Progreso',
        'Suitland',
        'Inkster',
        'Vitez',
        'Nagai',
        'Gradignan',
        'Assèmini',
        'Schleswig',
        'Andasibe',
        'San Vicente de Cañete',
        'Polasara',
        'Shenley Brook End',
        'Fort Washington',
        'Kārkala',
        'Lanaken',
        'Kulmbach',
        'Korkut',
        'Aurillac',
        'Vignola',
        'Vayakkalattu',
        'Maracanã',
        'Consett',
        'Rāman',
        'Marysville',
        'Marco',
        'Kadamalaikkundu',
        'Koko',
        'Alboraya',
        'Lādkhed',
        'Nechí',
        'Kunisakimachi-tsurugawa',
        'Le Ray',
        'Vichy',
        'Maintirano',
        'Mungaoli',
        'Chhota Udepur',
        'Tubay',
        'Rockville Centre',
        'Santo Cristo',
        'Florence',
        'Marshfield',
        'Pura',
        'Helmstedt',
        'Karavaram',
        'Conversano',
        'Koba',
        'Arapoti',
        'Wągrowiec',
        'Bishunpur Sundar',
        'Seondha',
        'Natividad',
        'Novelda',
        'Pāveh',
        'Zakopane',
        'Cherukolattur',
        'Biarritz',
        'Talisayan',
        'Chinna Salem',
        'Santa Maria',
        'Firmat',
        'Břevnov',
        'Vorkādi',
        'Halfmoon',
        'Koper',
        'Coffs Harbour',
        'San Antonio del Sur',
        'Horb am Neckar',
        'Ginan',
        'Tecax',
        'Villanueva de la Serena',
        'Béma',
        'Hasköy',
        'Yinajia',
        'Felipe Carrillo Puerto',
        'Kireyevsk',
        'Lapão',
        'Arida',
        'Lourinhã',
        'Tabina',
        'Néa Filadélfeia',
        'Mpophomeni',
        'Bandar-e Deylam',
        'Palm River-Clair Mel',
        'Ban Piang Luang',
        'Rinteln',
        'Lincoln',
        'Kłodzko',
        'New Milton',
        'Tarangnan',
        'Santeramo in Colle',
        'Oxford',
        'Lower Providence',
        'Nanpingcun',
        'Venice',
        'Jardín América',
        'Zhutailing',
        'Mogtédo',
        'Haisyn',
        'Carahue',
        'Champs-Sur-Marne',
        'Coulsdon',
        'Nova Prata',
        'Diébougou',
        'Safīpur',
        'Nāgod',
        'Scandiano',
        'Huejotzingo',
        'Ellwangen',
        'Netāpur Tānda',
        'Pereira Barreto',
        'Fenoarivo',
        'Malanguan',
        'Lamzoudia',
        'Kurchaloy',
        'Fort Beaufort',
        'Santa Lucia La Reforma',
        'Tosa',
        'Batabanó',
        'Friedrichsdorf',
        'Ligang',
        'Villanueva',
        'Tōin',
        'Pace',
        'Sagara',
        'Imatra',
        'Siqbā',
        'Kanchanaburi',
        'Oulad Said',
        'Voorschoten',
        'Doba',
        'Ḩaql',
        'Yolombó',
        'Foughala',
        'São Sebastião da Boa Vista',
        'Altavas',
        'West Chicago',
        'Phulpur',
        'Nanminda',
        'Palma',
        'Keşlə',
        'Otradnoye',
        'Geretsried',
        'Francavilla al Mare',
        'Trussville',
        'Alegria',
        'Busuanga',
        'Tumwater',
        'Moscow',
        'Igaci',
        'Utena',
        'Kāko',
        'Farnham',
        'Valdagno',
        'Migdal Ha‘Emeq',
        'Porto Sant’Elpidio',
        'Inajá',
        'Sliedrecht',
        'Warrington',
        'Velenje',
        'El Tarf',
        'Genet',
        'Mount Gambier',
        'Tarkeshwar',
        'Sirumugai',
        'Missour',
        'Chainpura',
        'Sankt Wendel',
        'Armentières',
        'Staunton',
        'Şarkîkaraağaç',
        'Bilston',
        'Balabanovo',
        'Montbéliard',
        'Nagar',
        'Haiwei',
        'Pszczyna',
        'São João Nepomuceno',
        'Bandiagara',
        'Kami',
        'Raghunāthpur',
        'Oliva',
        'Vijāpur',
        'Jericoacoara',
        'Alençon',
        'Idstein',
        'Wellington',
        'Aracoiaba',
        'Derby',
        'Abay',
        'Stevens Point',
        'Okemos',
        'Ipameri',
        'Onda',
        'Niramaruthūr',
        'Solano',
        'Cliffside Park',
        'Poranki',
        'Shāhedshahr',
        'Karayazı',
        'Oltiariq',
        'Witham',
        'Licey al Medio',
        'Rockaway',
        'Miranda',
        'Tipo-Tipo',
        'Tougué',
        'Vaterstetten',
        'Falconara Marittima',
        'Verl',
        'Salor',
        'Mar de Ajó',
        'Saintes',
        'Ayuquitan',
        'Rottweil',
        'Enna',
        'Brilon',
        'Lopez Jaena',
        'Manyoni',
        'Nettappākkam',
        'Roseto degli Abruzzi',
        'Al ‘Awwāmīyah',
        'Borçka',
        'Xenia',
        'Kocaali',
        'Ciying',
        'Mimasaka',
        'Fujikawaguchiko',
        'Santa Helena',
        'Oldbury',
        'Menaceur',
        'Treinta y Tres',
        'Wetteren',
        'Bungku',
        'Bačka Palanka',
        'Fort Hood',
        'Sobradinho',
        'Astorga',
        'Mimata',
        'Mannadipattu',
        'Kalispell',
        'Salaga',
        'Orosháza',
        'South Bradenton',
        'Mahwah',
        'Tupaciguara',
        'Veenoord',
        'Thatcham',
        'Mercer Island',
        'Exeter',
        'Vistahermosa',
        'Biłgoraj',
        'Āgaro',
        'Bathnāha',
        'Bishop Auckland',
        'Kouhu',
        'Silver Springs Shores',
        'Yany Kapu',
        'Unisan',
        'San Pedro Masahuat',
        'Inta',
        'São José do Norte',
        'West Whittier-Los Nietos',
        'Sangerhausen',
        'Moscháto',
        'Pauri',
        'Tumbao',
        'Anagé',
        'Três Passos',
        'San José',
        'Losino-Petrovskiy',
        'Brunoy',
        'Rocha',
        'Limonar',
        'Kīranūr',
        'Omigawa',
        'Ain Aicha',
        'Nyanza',
        'Szentes',
        'Caloto',
        'Reading',
        'Longbridge',
        'Vnukovo',
        'Leandro N. Alem',
        'Bloxwich',
        'Peekskill',
        'Nagtipunan',
        'Rouached',
        'Craíbas',
        'Vsetín',
        'Chengannūr',
        'Pérama',
        'Belvidere',
        'Zawyat ech Cheïkh',
        'Öhringen',
        'Miyaki',
        'Lamu',
        'Hudson',
        'Demba',
        'Maplewood',
        'Jilikŭl',
        'Showţ',
        'Newberg',
        'Coração de Jesus',
        'Turek',
        'Luzilândia',
        'Tomares',
        'Holt',
        'Eaubonne',
        'De Pere',
        'Zhongcun',
        'Salem',
        'Villeneuve-la-Garenne',
        'Diego Martin',
        'Cherbourg',
        'Roth',
        'Arriaga',
        'Nonāhi',
        'Legnago',
        'Yanaul',
        'Upminster',
        'Louis Trichardt',
        'Ozëry',
        'Ottumwa',
        'San Miguel',
        'Thal',
        'Yanyan',
        'Tarpon Springs',
        'Lennestadt',
        'Galt',
        'Vierzon',
        'Hazelwood',
        'Santo Tomás',
        'Ibirapitanga',
        'San Giovanni Lupatoto',
        'Karunāgapalli',
        'Busselton',
        'Norco',
        'Rakovski',
        'Salamanca',
        'Pinal de Amoles',
        'Yamagata',
        'Timurni',
        'Vynohradiv',
        'Jevargi',
        'Wiehl',
        'Requena',
        'Salzkotten',
        'Lafayette',
        'Harbel',
        'Ulongué',
        'Obertshausen',
        'Mill Creek East',
        'Farkhor',
        'Giv‘at Shemu’él',
        'Lengir',
        'Oupeye',
        'Kovancılar',
        'Suşehri',
        'Ula',
        'Arzignano',
        'Caledonia',
        'Belén de Umbría',
        'San Juan de Alicante',
        'Mednogorsk',
        'Denizciler',
        'La Unión',
        'Bābra',
        'Forney',
        'Camillus',
        'Budai',
        'Sebastian',
        'Tanabi',
        'Kirkby in Ashfield',
        'Kingsville',
        'Seriate',
        'Merkānam',
        'Lüdinghausen',
        'Wādi',
        'Mariano Comense',
        'Chocontá',
        'Weingarten',
        'Bouknadel',
        'Dyatkovo',
        'Kawlin',
        'Albergaria-a-Velha',
        'Elkridge',
        'Acatlán',
        'Topoľčany',
        'Reedley',
        'Cândido Sales',
        'Delitzsch',
        'Chardonnières',
        'Talugtug',
        'Camalaniugan',
        'Fengruncun',
        'Chiromo',
        'Barstow',
        'Ganshoren',
        'Almonte',
        'Ilaiyānkudi',
        'Mudgal',
        'Atlatlahucan',
        'Pfungstadt',
        'Bacoli',
        'Miahuatlán',
        'Allen',
        'Tipasa',
        'Mananjary',
        'Petershagen',
        'Wumayingcun',
        'Sandoná',
        'Sastamala',
        'Avon Lake',
        'Apiaí',
        'Workington',
        'Guéné',
        'Allendale',
        'Uruçuí',
        'Maaseik',
        'Brodósqui',
        'Somoniyon',
        'Saranga',
        'Morozovsk',
        'Taquari',
        'Albany',
        'Adra',
        'Şaydnāyā',
        'Uttaramerūr',
        'Kingswinford',
        'Kola',
        'Villarrobledo',
        'Lātehār',
        'Göle',
        'Chekfa',
        'Sarrat',
        'Erlun',
        'Piranhas',
        'Pāonta Sāhib',
        'Tirorā',
        'Norden',
        'University Park',
        'Fish Hawk',
        'Melrose Park',
        'Banhatti',
        'Walker',
        'Espelkamp',
        'Malyn',
        'El Calafate',
        'Jutaí',
        'Overijse',
        'Aridagawa',
        'Barberton',
        'Raahe',
        'Monreal',
        'El Ghiate',
        'Fatimé',
        'Vāsudevanallūr',
        'Carteret',
        'Álamo',
        'Debila',
        'Bagno a Ripoli',
        'Moses Lake',
        'Bardsīr',
        'Dedham',
        'Rhyl',
        'Gulām',
        'Sovetskaya Gavan’',
        'Kasrāwad',
        'Ditzingen',
        'Wewak',
        'Panāgar',
        'Brandon',
        'Aimorés',
        'Atkarsk',
        'Candelaria',
        'Campos Sales',
        'Otuzco',
        'Pallappatti',
        'North Tustin',
        'Santa María Tonameca',
        'Sāhibpur Kamāl',
        'Sohwal',
        'Rānia',
        'Conway',
        'Edgewood',
        'Dickinson',
        'Atuntaqui',
        'Torrelodones',
        'Mombin Crochu',
        'Saiha',
        'Novi Travnik',
        'Nāmrup',
        'Corsicana',
        'Āshkhāneh',
        'Malaṅgawā',
        'Mequon',
        'Kadavūr',
        'Baliqchi',
        'Pagudpud',
        'Hastings',
        'Iglesias',
        'Sahjanwa',
        'Rajākheri',
        'Kongsberg',
        'Santo Tomás',
        'Newport',
        'Vicuña',
        'Burē',
        'Ninohe',
        'Ibiapina',
        'Yapqan',
        'Jāfarābād',
        'Safsaf',
        'Correggio',
        'Soccorro',
        'Muskego',
        'Beersel',
        'Duiven',
        'Oegstgeest',
        'Šumperk',
        'Shrīrangapattana',
        'El Karimia',
        'Muret',
        'Armilla',
        'Bombinhas',
        'Romulus',
        'Steenbergen',
        'Seal Beach',
        'Huşi',
        'Waukee',
        'Kochubeyevskoye',
        'Lanling',
        'Nabīnagar',
        'Sohāgpur',
        'Charentsavan',
        'Camaligan',
        'Wolfsberg',
        'San Felipe',
        'Whitehaven',
        'Slavutych',
        'Datça',
        'Barwa Sāgar',
        'Droitwich',
        'Pangil',
        'Daventry',
        'Quirino',
        'Coín',
        'Saint-Ouen-l’Aumône',
        'Boussé',
        'Easton',
        'São João dos Patos',
        'Barki Ballia',
        'Nepomuceno',
        'Simria',
        'Yarmouth',
        'Olpe',
        'Trikodi',
        'Valls',
        'Hukeri',
        'Naubatpur',
        'Rāmshīr',
        'Maywood',
        'Pathiyanikunnu',
        'Sonzacate',
        'Attili',
        'Paoay',
        'Hindley',
        'Khed Brahma',
        'Vangviang',
        'Ehden',
        'Ampahana',
        'Fidirana',
        'Mahatalaky',
        'Ankazomborona',
        'Antsirabe Avaratra',
        'Bidur',
        'Catembe',
        'Nijverdal',
        'Santiago de Chuco',
        'Caballococha',
        'Dainyor',
        'Pinhal Novo',
        'Baxdo',
        'Tall Shihāb',
        'Zaouiet Sousse',
        'Kamonkoli',
        'Kibuku',
        'Sho‘rchi',
        'Araira',
        'Higuerote',
        'Drodro',
        'Dbarwa',
        'Moyalē',
        'Portishead',
        'Pitsea',
        'Tegalbuleud',
        'Ayirūrpāra',
        'Pārner',
        'Lālru',
        'Singhāna',
        'Kalamner',
        'Sojītra',
        'Mādha',
        'Chānasma',
        'Vodurivāndlagūdem',
        'Khiria Jhānsi',
        'Lādol',
        'Kali',
        'Data',
        'Malanvādi',
        'Aş Şaqlāwīyah',
        'Shaqlāwah',
        'Ash Shūnah ash Shamālīyah',
        'Moyale',
        'Simaria',
        'Nossa Senhora das Dores',
        'Tateyama',
        'Calahorra',
        'Mohale’s Hoek',
        'Béthune',
        'Sultepec',
        'Ponta de Pedras',
        'Norton Shores',
        'Kabanga',
        'Sidhaulī',
        'Westhoughton',
        'Tsaratanana',
        'Tapa',
        'Paduvari',
        'Mərdəkan',
        'Sandusky',
        'Gajwel',
        'Baindūr',
        'Plettenberg',
        'Visby',
        'La Esperanza',
        'Sugar Hill',
        'Paete',
        'Springfield',
        'Rio Preto da Eva',
        'Bad Oldesloe',
        'Yuzhang',
        'Niscemi',
        'Traralgon',
        'Cuijk',
        'Portalegre',
        'Marneuli',
        'Ixchiguán',
        'Dalupo',
        'Pinillos',
        'Termini Imerese',
        'Zheleznovodsk',
        'Mundargi',
        'Três de Maio',
        'Koumia',
        'Nainpur',
        'Hunucmá',
        'Elefsína',
        'Morton Grove',
        'Dimasalang',
        'Montalbán',
        'Dugda',
        'Broadstairs',
        'Nogi',
        'Pezinok',
        'Sand',
        'Ixhuatlancillo',
        'Üshtöbe',
        'Westchase',
        'Castelnau-le-Lez',
        'Flores Costa Cuca',
        'Minowa',
        'Quisqueya',
        'Westerlo',
        'Sora',
        'Piracanjuba',
        'Loma Linda',
        'Pedro Carbo',
        'Martinópolis',
        'Quispamsis',
        'Schmallenberg',
        'Sahasoa',
        'Meckenheim',
        'Omurtag',
        'Stein',
        'Iki',
        'Denison',
        'Villa Tapia',
        'Kobo',
        'Wasaga Beach',
        'Wujiaying',
        'Karukurti',
        'Watertown',
        'Pavlovsk',
        'Kirovsk',
        'Kālāvad',
        'Wyandotte',
        'Edavanakad',
        'Ishii',
        'Jabonga',
        'Perrysburg',
        'Solsona',
        'Fort Dodge',
        'Ādīs Zemen',
        'Rangāpāra',
        'Kuna',
        'Madūru',
        'Cherukara',
        'Valambur',
        'Anivorano Avaratra',
        'Liangwu',
        'Sprockhövel',
        'Kérkyra',
        'Rāghopur',
        'Ghoti Budrukh',
        'Santa Rita do Passa Quatro',
        'Sirmatpur',
        'Hamme',
        'Gemerek',
        'Banga',
        'Arnold',
        'Turuttikkara',
        'Carolina Forest',
        'Avon',
        'Aso',
        'Ciudad Barrios',
        'Tamboril',
        'Nazareth',
        'Uherské Hradiště',
        'Collingwood',
        'Syke',
        'Bāsopatti',
        'Piat',
        'Madaoua',
        'General Luna',
        'Riverbank',
        'Derry',
        'Poblacion',
        'Thundersley',
        'Kuttampuzha',
        'West Milford',
        'Świecie',
        'Icatu',
        'Zittau',
        'Khalāri',
        'Baarn',
        'Corralillo',
        'Ouaoula',
        'Voorst',
        'Suchitoto',
        'São Lourenço d’Oeste',
        'Gorleston-on-Sea',
        'Shivganj',
        "Bailey's Crossroads",
        'Ardmore',
        'Salanso',
        'Pamplona',
        'Soledad',
        'Tugaya',
        'Douar Olad. Salem',
        'Nar’yan-Mar',
        'Guaraí',
        'Fontenay-aux-Roses',
        'Zanesville',
        'Pepa',
        'Pweto',
        'Dodola',
        'San Benito',
        'Beifan',
        'Galdácano',
        'San Francisco Zapotitlán',
        'Penistone',
        'Kalayaan',
        'Santana',
        'Jamay',
        'Rudolstadt',
        'Tres Isletas',
        'Ban Khamen',
        'Meiningen',
        'Tuburan',
        'Bintuni',
        'Thornaby on Tees',
        'Frederickson',
        'Villanueva',
        'Scotch Plains',
        'Cambre',
        'Kulachi',
        'Medford',
        'Patian',
        'Taquaritinga do Norte',
        'Owando',
        'Cloverleaf',
        'Bāzārak',
        'Junqueiro',
        'Lutz',
        'Hertford',
        'Zumpango del Río',
        'Brasil Novo',
        'Delfzijl',
        'Bystrc',
        'Moron',
        'Dinuba',
        'Castro Alves',
        'João Lisboa',
        'Algeciras',
        'Ma‘bar',
        'Zhenbeibu',
        'Gouna',
        'Podatūrpeta',
        'Victoria',
        'G’allaorol Shahri',
        'Mairwa',
        'Santa Rosa',
        'North Potomac',
        'Oga',
        'Saltpond',
        'Kakonko',
        'Baroy',
        'Bainbridge Island',
        'Tamilisan',
        'Raška',
        'Hınıs',
        'Jacaré',
        'Pānapur',
        'Kajiado',
        'Narutō',
        'Patnāgarh',
        'Monte Cristi',
        'Stutterheim',
        'Narasingapuram',
        'Shitan',
        'Uauá',
        'Chandili',
        'Miguel Calmon',
        'Kaga Bandoro',
        'Pāsighāt',
        'Ternate',
        'Dix Hills',
        'Naduvannur',
        'Eidsvoll',
        'Larvik',
        'Warstein',
        'Greenbelt',
        'Leoben',
        'Burdeos',
        'Newton in Makerfield',
        'Las Cabras',
        'Pālakodu',
        'Oleshky',
        'Boquim',
        'Shamgarh',
        'Merelbeke',
        'Bielsk Podlaski',
        'Al Qubbah',
        'Bayonet Point',
        'El Milagro',
        'Ban Bang Phun',
        'Santa Maria do Pará',
        'Zwevegem',
        'Ridgeland',
        'Rawson',
        'Navalgund',
        'San Borja',
        'Iaçu',
        'Donggangli',
        'Waldshut-Tiengen',
        'Khashuri',
        'Madougou',
        'Pāta Kalidindi',
        'Rathenow',
        'Coral Terrace',
        'Etajima',
        'Fatoma',
        'Rāmdiri',
        'Oulad Tayeb',
        'Télagh',
        'Auburn Hills',
        'Darende',
        'Condado',
        'Carrascal',
        'Gorantla',
        'Rio Tinto',
        'Irugūr',
        'Selma',
        'Paris',
        'Biharamulo',
        'Reserva',
        'Calamar',
        'Aïn Kechera',
        'Ronnenberg',
        'Zion',
        'Sept-Îles',
        'Panglong',
        'Carlos A. Carrillo',
        'Bad Mergentheim',
        'Païta',
        'Takhli',
        'Isernhagen-Süd',
        'El Retorno',
        'Zernograd',
        'Tuzluca',
        'Karataş',
        'Tapejara',
        'Libourne',
        'Břeclav',
        'Mponela',
        'Guabiruba',
        'Vernon',
        'Stjørdal',
        'Kottapeta',
        'Wierden',
        'Valdivia',
        'Cheruvāranam',
        'Alcobaça',
        'Santo Tomas',
        'Ravānsar',
        'Sirkhandi Bhitha',
        'Clinton',
        'Westford',
        'Markkleeberg',
        'Dungra Chhota',
        'Ain Dfali',
        'Liria',
        'Malinao',
        'Watsa',
        'Chebba',
        'Cordeirópolis',
        'Amatán',
        'Centerville',
        'Homer Glen',
        'Aklvidu',
        'Zdolbuniv',
        'Qualiano',
        'Hulbuk',
        'Bereket',
        'Haren',
        'Andhra Thārhi',
        'Nij Khari',
        'Dongxiaozhai',
        'Tirumuruganpūndi',
        'Parambatt Kavu',
        'Ocara',
        'Waldkraiburg',
        'Tulsīpur',
        'Pindwāra',
        'Ấp Khánh Hòa',
        'Dhāmnagar',
        'Mahdīshahr',
        'Kaynarca',
        'Orly',
        'Riverside',
        'Hanerik',
        'Khajurāho',
        'Traun',
        'Oak Ridge',
        'Leland',
        'Neftekumsk',
        'San Agustín Loxicha',
        'Purwā',
        'Juayúa',
        'Baar',
        'Socastee',
        'Gyál',
        'Sahaspur',
        'Farmington',
        'Cartaxo',
        'Oak Harbor',
        'Sahāwar',
        'Nasukarasuyama',
        'Herndon',
        'Wetzikon',
        'Igaraçu do Tietê',
        'Attendorn',
        'Zungeru',
        'Olesa de Montserrat',
        'Vinnamāla',
        'Būndu',
        'Tamahú',
        'Felixstowe',
        'Ponca City',
        'Qabāţīyah',
        'Arad',
        'Channagiri',
        '’Aïn Boucif',
        'Vilankulo',
        'Zhuangwei',
        'Gaz',
        'Balarāmpur',
        'Garcia Hernandez',
        'Yokadouma',
        'Landover',
        'São Sebastião do Caí',
        'Três Coroas',
        'North Augusta',
        'Żagań',
        'King of Prussia',
        'Decatur',
        'Faribault',
        'Mola di Bari',
        'Armiansk',
        'North Laurel',
        'Erandio',
        'Pedra Azul',
        'Oirase',
        'Bay Point',
        'Ramain',
        'Fray Bentos',
        'Minas Novas',
        'Ashwarāopeta',
        'Herzogenaurach',
        'Tirutturaippūndi',
        'Abim',
        'Varel',
        'Garhara',
        'Panmana',
        'Itambé',
        'Stroitel',
        'Siófok',
        'Medford',
        'Rugeley',
        'Ngorkou',
        'Happy Valley',
        'Le Kremlin-Bicêtre',
        'Kings Norton',
        'Santa Ana',
        'Tbeng Meanchey',
        'Alicia',
        'Eysines',
        'Les Irois',
        'Kazincbarcika',
        'Asan',
        'Buzen',
        'Bacolod',
        'Port St. John',
        'Benbrook',
        'Putten',
        'Valente',
        'Preah Vihear',
        'Kenndié',
        'Hirrīyat Raznah',
        'Pýrgos',
        'Duncan',
        'Birkirkara',
        'Hennenman',
        'Puerto Guzmán',
        'Nagarpāra',
        'Ambohimasina',
        'West Memphis',
        'Taquarituba',
        'Ruvo di Puglia',
        'Asino',
        'Nova Xavantina',
        'Paruthūr',
        'Porto de Mós',
        'Le Bouscat',
        'Saidpur',
        'Grombalia',
        'Sagbayan',
        'Kerrville',
        'Horquetas',
        'Canhotinho',
        'Martos',
        'Las Margaritas',
        'Ballenger Creek',
        'Singarāyakonda',
        'Ladera Ranch',
        'Tadjmout',
        'Buturlinovka',
        'Pallapatti',
        'Iringal',
        'Xinxing',
        'Gose',
        'White Bear Lake',
        'Lucena',
        'Portogruaro',
        'Sarykemer',
        'Ipixuna',
        'La Paz',
        'Bijāwar',
        'Qazi Ahmad',
        'Cedros',
        'Velūr',
        'Echemmaia Est',
        'Cantanhede',
        'Bedburg',
        'Alfreton',
        'Oud-Beijerland',
        'Freudenstadt',
        'Qianwangcun',
        'Koloriāng',
        'Pinheiral',
        'Curacaví',
        'Dakṣiṇkāli̇̄',
        'Barnegat',
        'Ajim',
        'Collinsville',
        'Staßfurt',
        'Canton',
        'Rădăuţi',
        'Raisio',
        'Oulad Barhil',
        'Kiên Lương',
        'Saint-Laurent-du-Maroni',
        'Saky',
        'Baheri',
        'Almansa',
        'Mahārājgani',
        'Volksrust',
        'Zhuchangba',
        'Elmwood Park',
        'Satuba',
        'Nueva Paz',
        'Starkville',
        'South Plainfield',
        'Borre',
        'Carlow',
        'Katrineholm',
        'Westmont',
        'Wekiwa Springs',
        'Ayanavelikulangara Tekku',
        'Middleborough',
        'Palmetto Bay',
        'Senador Pompeu',
        'Noto',
        'Umga',
        'Zhangshanying',
        'São Geraldo do Araguaia',
        'Gazojak',
        'Saint-Nicolas',
        'Lukaya',
        'Tocopilla',
        'Somerset',
        'Hudson',
        'Mahaiza',
        'Mau',
        'San Juan Nepomuceno',
        'Fairfax',
        'Douar ’Ayn Dfali',
        'El Salto',
        'Valparaíso',
        'Belton',
        'El Factor',
        'Mayāng Imphāl',
        'uMhlanga Rocks',
        'Lebedyn',
        'Carutapera',
        'Mekla',
        'Nova Soure',
        'Ganzhu',
        'Vēttakkāranpudūr',
        'North Lynnwood',
        'Qiaomaichuan',
        'Haaksbergen',
        'Freha',
        'Chester-le-Street',
        'Środa Wielkopolska',
        'Pelham',
        'Jan Kempdorp',
        'El Congo',
        'Calw',
        'Los Barrios',
        'Cabrera',
        'Guatuba',
        'Gotvand',
        'Übach-Palenberg',
        'Farnley',
        'Ambalamanasy II',
        'Great Sankey',
        'Kariba',
        'Debaltseve',
        'Rodez',
        'Canarana',
        'Calliaqua',
        'Ponneri',
        'Soure',
        'Donmatías',
        'Buwenge',
        'Uchquduq Shahri',
        'Boulsa',
        'Gelemso',
        'Aioun',
        'Feriana',
        'Yukon',
        'Marín',
        'Nāravārikuppam',
        'Halikner',
        'Kauswagan',
        'Ortigueira',
        'Quatro Barras',
        'Lagonglong',
        'Fossano',
        'Maksi',
        'Mogalturru',
        'Machachi',
        'Novo Mesto',
        'Magenta',
        'San Enrique',
        'Skawina',
        'Helleland',
        'Forquilha',
        'San Fernando',
        'Golborne',
        'Caibiran',
        'Wałcz',
        'Lop Buri',
        'Les Pavillons-sous-Bois',
        'Ukkāyapalle',
        'Kosigi',
        'Jaruco',
        'Angatuba',
        'Ōguchi',
        'Yoqne‘am ‘Illit',
        'Sehnde',
        'Pingtang',
        'Velingrad',
        'Macia',
        'Liushui',
        'Heiloo',
        'Meyrin',
        'Hingham',
        'Baharly',
        'Rioblanco',
        'Itaporã',
        'Bir Jdid',
        'Zaprešić',
        'Simpsonville',
        'Marsella',
        'Isa',
        'Setti Fatma',
        'Korostyshiv',
        'Salvaterra',
        'Eisenhüttenstadt',
        'Mairena del Alcor',
        'Loha',
        'Tiruvattār',
        'Emporia',
        'Anjozorobe',
        'San Agustin',
        'Marple',
        'Vrbas',
        'Sundararaopeta',
        'Alwaye',
        'Fishkill',
        'Paraopeba',
        'Anastácio',
        'Upper Providence',
        'Saginaw',
        'Ţayyibat al Imām',
        'Bontoc',
        'Wandlitz',
        'Plymstock',
        'Bloomingdale',
        'Espera Feliz',
        'Jaisinghpur',
        'As Sulayyil',
        'Ryde',
        'Qiloane',
        'Areia Branca',
        'Cogua',
        'Monte Sião',
        'Lagoa Grande',
        'Mirandola',
        'Deyr',
        'Montevarchi',
        'Vicente López',
        'Santa María Colotepec',
        'Ormskirk',
        'Wadsworth',
        'Goiás',
        'Semënov',
        'Zaragoza',
        'Sangrāmpur',
        'Skoura',
        'Balete',
        'Quezon',
        'Rolling Meadows',
        'Solon',
        'Boo',
        'Mandan',
        'Senboku',
        'Arttuvāttala',
        'Mina',
        'Tangcun',
        'Rita',
        'Siswa',
        'Sūleswaranpatti',
        'Auburn',
        'Tegina',
        'Guapí',
        'Yanbu',
        'Sakai',
        'Bellview',
        'Columbus',
        'Buritis',
        'Sanando',
        'Jasmine Estates',
        'Saint-Lin--Laurentides',
        'Kourou',
        'Independência',
        'Pontinha',
        'Kościan',
        'Atar',
        'Veranópolis',
        'Laon',
        'Yādiki',
        'Kingston',
        'Papillion',
        'Tehri',
        'Soamanandrariny',
        'Ambatotsipihina',
        'Jequitinhonha',
        'Bcharré',
        'Ambohimandroso',
        'Lopary',
        'Antonibe',
        'Antanimieva',
        'Miarinarivo',
        'Tsiatosika',
        'Itigi',
        'Butaleja',
        'Chinobod',
        'Jalolquduq',
        'Sheghnān',
        'Tchibanga',
        'Maheshwar',
        'Payipira',
        'Caazapá',
        'Quellón',
        'Uvarovo',
        'Zolochiv',
        'Sikandarpur',
        'Jardim',
        'Cachoeira do Arari',
        'Cavinti',
        'Pennādam',
        'San Pedro',
        'Sharya',
        'Huilongping',
        'Barnstaple',
        'Burlington',
        'Laranjeiras',
        'Plainsboro',
        'General Emilio Aguinaldo',
        'Rickmansworth',
        'Kamiamakusa',
        'Icod de los Vinos',
        'Dabaga',
        'Kyonpyaw',
        'Embarcación',
        'Shāhpur',
        'Laguna Salada',
        'São Gonçalo do Sapucaí',
        'Bom Jesus',
        'Littleover',
        'San Fernando',
        'Santa Quitéria do Maranhão',
        'Newport',
        'Wil',
        'La Valette-du-Var',
        'Goirle',
        'Neduvattūr',
        'Ambohimahamasina',
        'Urucurituba',
        'Itaporanga',
        'Jüchen',
        'Arbroath',
        'Tsawwassen',
        'Bulusan',
        'São João da Ponte',
        'Belo Oriente',
        'Sighişoara',
        'Mapoteng',
        'Solin',
        'Wijk bij Duurstede',
        'Rodniki',
        'Puyappalli',
        'Jucás',
        'El Zulia',
        'Douar Bou Tlelis',
        'Ibi',
        'Koulikoro',
        'Manlio Fabio Altamirano',
        'Umbaúba',
        'Irauçuba',
        'Usia',
        'Sendamangalam',
        'Hatonuevo',
        'Kraskovo',
        'São João do Paraíso',
        'Buritizeiro',
        'Pompei',
        'San Nicolas',
        'Alfter',
        'Zwedru',
        'Porto Franco',
        'Fălticeni',
        'Tacaratu',
        'Paxtakor Shahri',
        'Brotas',
        'Mejorada del Campo',
        'Columbus',
        'Tantoyuca',
        'Tecoluca',
        'Gungu',
        'Hadžići',
        'Mātābhānga',
        'Mulgund',
        'Cibitoke',
        'Caversham',
        'Freeport',
        'Vänersborg',
        'Seveso',
        'Tata',
        'Nóqui',
        'Macaparana',
        'Iraquara',
        'Burlington',
        'Peringuzha',
        'Karian',
        'Strathroy-Caradoc',
        'Subaykhān',
        'Acton',
        'Buôn Trấp',
        'Koilkuntla',
        'Matriz de Camarajibe',
        'Dunleary',
        'Johnston',
        'Ourikela',
        'Westerstede',
        'Lihe',
        'Magallanes',
        'Herohalli',
        'Mirandela',
        'Oadby',
        'Hopa',
        'Rosemont',
        'Jurh',
        'Carás',
        'Az Zuwāydah',
        'Ksar el Hirane',
        'South Elgin',
        'Webster Groves',
        'Cranford',
        'Chanthaburi',
        'Mulakumūd',
        'Palu',
        'Limbach-Oberfrohna',
        'Cruzeiro do Oeste',
        'Mansfield',
        'Honda',
        'Annūr',
        'Makhmūr',
        'San Miguel Ocotenco',
        'Ciudad Serdán',
        'Diest',
        'Atotonilco el Grande',
        'Pão de Açúcar',
        'Willoughby',
        'Quesada',
        'Kasongo-Lunda',
        'Altea',
        'Popovo',
        'Encruzilhada do Sul',
        'Squamish',
        'Douar Ouled Ayad',
        'Novo Aripuanã',
        'Thorold',
        'Firuzoba',
        'Kalawit',
        'Carandaí',
        'Kuju',
        'Buy',
        'Dongsheng',
        'Frontignan',
        'Hungund',
        'Kumar Khad',
        'Catigbian',
        'Hodonín',
        'Zawyat an Nwaçer',
        'Montgeron',
        'Sulya',
        'Misato',
        'Bithar',
        'Farragut',
        'Thiene',
        'Highland',
        'Grootfontein',
        'Selydove',
        'El Maknassi',
        'Fada',
        'Gaojiayingcun',
        'Ituango',
        'Dākor',
        'Vallabh Vidyanagar',
        'Stepanavan',
        'El Tejar',
        'Agrestina',
        'Tirumala',
        'Villanueva',
        'Hoh Ereg',
        'Vélingara',
        'Dole',
        'Ishiki',
        'Baliguian',
        'Akwatia',
        'Basankusu',
        'Kuvandyk',
        'Manari',
        'Döbeln',
        'Verukulambu',
        'De Aar',
        'Oldebroek',
        'Kidsgrove',
        'Cullera',
        'Candoni',
        'Santa Apolonia',
        'Burgos',
        'Limbuhan',
        'Hachimantai',
        'Starnberg',
        'Elukone',
        'East Retford',
        'Lālgudi',
        'Fountain Hills',
        'Avanigadda',
        'Vizela',
        'Bīrpur',
        'Nocera Superiore',
        'Husum',
        'Huntingdon',
        'Mummidivaram',
        'Stalybridge',
        'Ōji',
        'Munster',
        'Tatarsk',
        'Fria',
        'Pout',
        'Alice Springs',
        'San Giovanni la Punta',
        'Kościerzyna',
        'Waverly',
        'Lebu',
        'Ayancık',
        'Nørresundby',
        'Puduva',
        'Aurora',
        'Dasungezhuang',
        'Devikolam',
        'Karavalūr',
        'Khenichet-sur Ouerrha',
        'Rishton',
        'Korbach',
        'Tangalan',
        'Salqīn',
        'Chinoz',
        'Stanmore',
        'Nesher',
        'Shama',
        'Sezze',
        'Ayt Mohamed',
        'Southold',
        'Miantso',
        'Maldegem',
        'Droylsden',
        'Marple',
        'San Juan Cotzocón',
        'Gelnhausen',
        'Sīlappādi',
        'Patterson',
        'Tirukkalikkunram',
        'Anūpshahr',
        'Shelbyville',
        'Cazones de Herrera',
        'Sanaur',
        'Santo Antônio do Sudoeste',
        'Muscatine',
        'Dalyoni Bolo',
        'Greenfield',
        'Borne',
        'San Lorenzo',
        'Champlin',
        'Lexington',
        'Orleães',
        'Conil de la Frontera',
        'Lubliniec',
        'Bonito',
        'Independencia',
        'Ālangulam',
        'Yellāpur',
        'Roseburg',
        'Perry Barr',
        'Conde',
        'Piraí do Sul',
        'Raritan',
        'Rajpur',
        'Dunaharaszti',
        'Ahualulco de Mercado',
        'Bang Sao Thong',
        'Chandrakona',
        'Bafilo',
        'Tecali',
        'Pitogo',
        'Taupo',
        'Bogotol',
        'Beni Amrane',
        'Berhoum',
        'Gosforth',
        'Mannamturuttu',
        'Satun',
        'Santa Gertrudes',
        'Kenmore',
        'Fresno',
        'Yesagyo',
        'Gueznaia',
        'Kalach-na-Donu',
        'Mountain House',
        'Aşkale',
        'Tapiales',
        'Ōgawara',
        'Kulgam',
        'Shamsābād',
        'Jacksonville Beach',
        'Truro',
        'Kyeintali',
        'Kébila',
        'Oostkamp',
        'Newton Abbot',
        'Ipatovo',
        'Lustenau',
        'Perungudi',
        'Ðakovo',
        'San Martín Totolán',
        'Cerro Azul',
        'Middletown',
        'Kottūru',
        'Amstetten',
        'San Ignacio de Velasco',
        'Tayga',
        'Monsefú',
        'Tamandaré',
        'Toda Rai Singh',
        'Pēravūr',
        'Montgomery',
        'Bauta',
        'Castiglione delle Stiviere',
        'Heverlee',
        'Gollapūdi',
        'Dhing',
        'Unecha',
        'Ma‘alot Tarshīḥā',
        'Codajás',
        'Luninyets',
        'Belsand',
        'Gerede',
        'Bambuí',
        'Salzwedel',
        'Bishopbriggs',
        'Kaltenkirchen',
        'São Miguel',
        'Khelil',
        'Nyūzen',
        'Xiaojiangcun',
        'Xiaping',
        'El Paso',
        'Jaleshwar',
        'Corinto',
        'Yangi Marg‘ilon',
        'Hannoversch Münden',
        'Brookings',
        'Dumaran',
        'Honāvar',
        'General Pánfilo Natera',
        'Amherstburg',
        'Nixa',
        'Funes',
        'Canalete',
        'Ban Phru',
        'Cupira',
        'Aţ Ţafīlah',
        'Watauga',
        'Marshall',
        'Sonneberg',
        'Loon op Zand',
        'Kaniv',
        'Mujuí dos Campos',
        'Muang Sing',
        'Quaraí',
        'Arukutti',
        'Miyoshi',
        'Davlekanovo',
        'Suonan',
        'Nāyanakulam',
        'Lisle',
        'Ringsted',
        'Pakil',
        'Port Laoise',
        'Jocotenango',
        'Cuyo',
        'Padre Burgos',
        'Gardner',
        'Český Těšín',
        'Farmington',
        'Griffin',
        'Mosbach',
        'Matinha',
        'Dillenburg',
        'Oued el Djemaa',
        'Koili Simra',
        'Yugawara',
        'Lensk',
        'Carmo do Cajuru',
        'Bloemendaal',
        'Chanaur',
        'Gauripur',
        'Francisco Sá',
        'Oulad Hassoune',
        'Hajdúszoboszló',
        'Khārupatia',
        'Maple Heights',
        'Maharlika Village',
        'Monte Caseros',
        'Krychaw',
        'Les Lilas',
        'Kyenjojo',
        'Bonoufla',
        'Ḑank',
        'Tapaktuan',
        'Muggiò',
        'Sanga',
        'Brody',
        'Sinj',
        'Beni Rached',
        'Madangir',
        'Kėdainiai',
        'Minamikarasuyama',
        'Chrudim',
        'Copiague',
        'L’Assomption',
        'Rāmpura',
        'Gjirokastër',
        'Nasu',
        'Bni Frassen',
        'Elburg',
        'Pilar de la Horadada',
        'Kilindoni',
        'Badnāwar',
        'Raul Soares',
        'Pissila',
        'Maychew',
        'Sattahip',
        'Kailāshahar',
        'Vega Baja',
        'Pesochin',
        'San Buenaventura',
        'Zheleznogorsk-Ilimskiy',
        'Srvanampatti',
        'Santa Rosa de Viterbo',
        'Teguise',
        'Veinticinco de Mayo',
        'Senftenberg',
        'Al ‘Azīzīyah',
        'Bình Hòa',
        'Havlíčkŭv Brod',
        'Van Buren',
        'Burhar',
        'Gālīkesh',
        'Ban Thoet Thai',
        'Svilajnac',
        'Kafr Shukr',
        'Clinton',
        'Makouda',
        'Meda',
        'Sluis',
        'Maywood',
        'Bedford',
        'Teorama',
        'Ponda',
        'Almeirim',
        'Albenga',
        'West Springfield',
        'Akkuş',
        'Giulianova',
        'Vanimēl',
        'Gamay',
        'Bultfontein',
        'Severobaykalsk',
        'Netphen',
        'Caniço',
        'Rock Springs',
        'Barbasa',
        'Beni Khalled',
        'Quảng Trị',
        'Pottstown',
        'Sredneuralsk',
        'Dighwa',
        'Sakleshpur',
        'Montecchio Maggiore',
        'Piteå',
        'Takehara',
        'Sāndwa',
        'Çağlayancerit',
        'Pindoretama',
        'Leutkirch im Allgäu',
        'Avellaneda',
        'Tazhava',
        'Madappalli',
        'Sokolo',
        'Maigo',
        'Westerly',
        'Piravanthūr',
        'Saint-Ghislain',
        'Mandāwa',
        'Antanimasaka',
        'Coronel Vivida',
        'Cide',
        'Čadca',
        'Dongshi',
        'Berehove',
        'Zemst',
        'Doctor Mora',
        'Warburg',
        'Collipulli',
        'Ambohimiadana',
        'Horgen',
        'Minja',
        'Sai Mai',
        'Dalmine',
        'Pelitli',
        'Capinzal',
        'Quedlinburg',
        'Jieshang',
        'Ron',
        'Calbiga',
        'Ikeda',
        'North Platte',
        'Bazhajiemicun',
        'Phôngsali',
        'Moskva',
        'Tecumseh',
        'Santo Tomé',
        'Tugatog',
        'Tobatí',
        'Ardahan',
        'Lormont',
        'Bordj Ghdir',
        'Niskayuna',
        'Añatuya',
        'Goumori',
        'Aldeias Altas',
        'La Cruz de Río Grande',
        'Poxoréo',
        'Carambeí',
        'San Rafael Abajo',
        'Whitefield',
        'Kedavūr',
        'Achkhoy-Martan',
        'Piastów',
        'Alhama de Murcia',
        'San José',
        'Rathfarnham',
        'Monteros',
        'Gersthofen',
        'Teodoro Sampaio',
        'Mūlampilli',
        'Camp Springs',
        'Telica',
        'Abaeté',
        'Linda',
        'Cajidiocan',
        'Tekāri',
        'Martha Lake',
        'La Higuerita',
        'La Huerta',
        'Maibog',
        'Alliston',
        'Tsaratanana',
        'Ulukışla',
        'Píritu',
        'Cockeysville',
        'Ozuluama de Mascareñas',
        'Santo Antônio de Posse',
        'Motul',
        'Mine',
        'Phetchaburi',
        'Xunjiansi',
        'Vaikam',
        'Mantua',
        'Cottage Lake',
        'Raymore',
        'Selim',
        'Kūn Puhāl',
        'Pickerington',
        'Calamba',
        'Loanda',
        'Nuku‘alofa',
        'Samrāla',
        'Sasovo',
        'Mizdah',
        'Culion',
        'Kedia',
        'Sheybān',
        'Nakashunbetsu',
        'Cambará',
        'Kolpashevo',
        'Fergus',
        'Kelīshād va Sūdarjān',
        'Pātakākāni',
        'Amarapura',
        'Sevan',
        'Manambūr',
        'Touboro',
        'Wertheim',
        'Montgomery',
        'Wareham',
        'Tranquebar',
        'Wilmington',
        'Kazhukambalam',
        'Capoterra',
        'Itabaiana',
        'Bassar',
        'Rastede',
        '‘Ālī Shahr',
        'Nova Petrópolis',
        'Vilaseca de Solcina',
        'Vukovar',
        'Ponmundam',
        'Kannamangalam Tekku',
        'Union Hill-Novelty Hill',
        'Eastmont',
        'Carmen',
        'Karben',
        'Tototlán',
        'Colón',
        'Pajapita',
        'Kouoro',
        'Gitega',
        'Tūkrah',
        'Ashland',
        'Conceição do Mato Dentro',
        'Bad Soden am Taunus',
        'Vreden',
        'Apatin',
        'Minamata',
        'Puenteareas',
        'Zarautz',
        'Sonepur',
        'Colón',
        'Ribas do Rio Pardo',
        'Ban Bang Rin',
        'Abreus',
        'Białogard',
        'Ravulapalem',
        'New Brighton',
        'Edgewater',
        'Belton',
        'Planalto',
        'Easley',
        'Shuilin',
        'Bananeiras',
        'Douar Imoukkane',
        'Puerto Concordia',
        'Soledad Atzompa',
        'Elgin',
        'Neihuzhai',
        'Trotwood',
        'Litoměřice',
        'Lalganj',
        'São Mamede de Infesta',
        'Manage',
        'Mahthi',
        'Tamiahua',
        'Oakdale',
        'Caba',
        'West Goshen',
        'Ottaviano',
        'Yásica Arriba',
        'Rafael Delgado',
        'Liberty Triangle',
        'Los Arabos',
        'Ōno',
        'Sansanding',
        'Singhāra Buzurg',
        'Fânzeres',
        'Chantilly',
        'Calabasas',
        'Vuliyattara',
        'Cartersville',
        'Sonāri',
        'Engandiyūr',
        'Eustis',
        'Roxbury',
        'Tupiza',
        'Qiryat Mal’akhi',
        'Hombori',
        'Überlingen',
        'Oxkutzkab',
        'Morris',
        'Yunak',
        'Rochefort',
        'Sederot',
        'Mujiayu',
        'Gusinoozërsk',
        'Khirkiyān',
        'Rucphen',
        'Sīwah',
        'Maisons-Laffitte',
        'Fokino',
        'Vilāchcheri',
        'Qiryat Shemona',
        'Tnine Lgharbia',
        'Apolda',
        'Vigonza',
        'Bear',
        'Saint-Dizier',
        'Chester',
        'Pallikondai',
        'Yakınca',
        'Roissy-en-Brie',
        'Orbassano',
        'Cataño',
        'Asafābād',
        'Couëron',
        'Weilheim',
        'Dom Pedro',
        'Nagykőrös',
        'Lunbei',
        'Warragul',
        'Achí',
        'Bloomington',
        'Koussané',
        'Velugodu',
        'Penwortham',
        'Youwarou',
        'Devonport',
        'Hopewell',
        'Dunajská Streda',
        'Auch',
        'Thiotte',
        'Buyende',
        'Upper Allen',
        'Bad Kissingen',
        'Koiri Bigha',
        'Fernley',
        'Mangidy',
        'Copertino',
        'Monte Alegre',
        'Bataguaçu',
        'Anjarkandi',
        'Oliveira do Bairro',
        'San Andrés de Giles',
        'Nova Milanese',
        'Lanester',
        'Frontera',
        'Shiling',
        'Tembagapura',
        'Lysander',
        'Tubungan',
        'Curumaní',
        'Kulattuppālaiyam',
        'Ladner',
        'Nový Jičín',
        'Glassboro',
        'Bad Salzungen',
        'Loos',
        'Khairā Tolā',
        'Ban Bang Krang',
        'Hudson',
        'Taylors',
        'Harborne',
        'Ka-Bungeni',
        'Carletonville',
        'Majdel Aanjar',
        'Ambohitrarivo',
        'Mahela',
        'Farahalana',
        'Analamisampy',
        'Ambohitoaka',
        'Miantsoarivo',
        'Ankaramy',
        'Marovato',
        'Ankilizato',
        'Andalatanosy',
        'Kungsbacka',
        'Xingang',
        'Gyêgu',
        'Zhaoling',
        'Rawtenstall',
        'Dindori',
        'Candiac',
        'Krasnouralsk',
        'Valencia',
        'Despotovac',
        'Wichian Buri',
        'Krasnoarmeysk',
        'Stoneham',
        'Panelas',
        'Wallenhorst',
        'Niamtougou',
        'Manlin',
        'Nagold',
        'Ivoti',
        'Mobetsu',
        'Lisse',
        'Radcliff',
        'Lengerich',
        'Vandiperiyār',
        'Watertown',
        'Lagoa',
        'Yaguachi Nuevo',
        'Tighedouine',
        'Malinalco',
        'Labuleng',
        'Essa',
        'Brent',
        'Latauna',
        'Woodlawn',
        'Sandviken',
        'Manuel B. Gonnet',
        'Minbu',
        'Encantado',
        'Frederikshavn',
        'Searcy',
        'Nikaho',
        'Paradise',
        'Moguer',
        'Dabeiba',
        'Crystal',
        'Las Breñas',
        'Scherpenheuvel',
        'Elektrogorsk',
        'Nocatee',
        'Kamabougou',
        'Manghit',
        'Christiansburg',
        'Friesoythe',
        'Kitzingen',
        'Muttupet',
        'Mékhé',
        'Hollola',
        'Abu',
        'Laguna Beach',
        'Molodohvardiisk',
        'Znamianka',
        'Nijlen',
        'Clifton',
        'Huaibaijie',
        'Loves Park',
        'Blue Island',
        'Swinton',
        'Peters',
        'Manosque',
        'Famaillá',
        'Prairie Village',
        'Bathgate',
        'Itaocara',
        'Biancavilla',
        'Pul-e ‘Alam',
        'Kilkís',
        'Dáfni',
        'Market Harborough',
        'Nyakrom',
        'Wulongpu',
        'Edewecht',
        'Dialakoroba',
        'Dokuchaievsk',
        'Keene',
        'Zduńska Wola',
        'Bhirāha',
        'Isilkul',
        'Shurugwi',
        'Kotido',
        'Vadakarai Kīl Pidāgai',
        'Ayos',
        'El Palmar',
        'Colorado',
        'Senden',
        'Dorohoi',
        'Lermontov',
        'Fontaine',
        'Trebišov',
        'Losser',
        'Vellalūr',
        'Pointe à Raquettes',
        'Ogre',
        'La Democracia',
        'Garoua Boulaï',
        'Zeewolde',
        'Ramos',
        'Kalat',
        'Luruaco',
        'Marcinelle',
        'Rivera',
        'Mahādebnagar',
        'Ekeren',
        'Neusäß',
        'Garden City',
        'Pietrasanta',
        'Viga',
        'Baile Átha Luain',
        'Guadalupe',
        'Bhadās',
        'Genzano di Roma',
        'Vale de Cambra',
        'Laupheim',
        'Araçariguama',
        'Nazca',
        'Allen',
        'Waltham Abbey',
        'Malacatancito',
        'Patacamaya',
        'Oteiza',
        'Olivet',
        'General Luna',
        'Azové',
        'Vagos',
        'Hadyach',
        'Sarandi',
        'Ventimiglia',
        'Blangmangat',
        'Krnov',
        'Arcos de Valdevez',
        'Roselle',
        'Villanueva de la Cañada',
        'Dammarie-lè-Lys',
        'Gainsborough',
        'Machesney Park',
        'Guanajay',
        'Nayāgaon',
        'Tadmaït',
        'Chitarpur',
        'Naolinco de Victoria',
        'Ecoporanga',
        'Selvazzano Dentro',
        'Qaţanā',
        'Xinsi',
        'Ahwa',
        'Erdington',
        'Katy',
        'Millbrae',
        'Nogoyá',
        'Ondangwa',
        'Pawni',
        'Torre del Mar',
        'Ataco',
        'Bressanone',
        'Gaoshu',
        'Hialeah Gardens',
        'Kagadi',
        'Balen',
        'Tadotsu',
        'Siqueira Campos',
        'Yanshanbu',
        'Barbate de Franco',
        'Camilo Ponce Enríquez',
        'Cheriāl',
        'Zunheboto',
        'Winchester',
        'Tomarza',
        'Pulimāthu',
        'Corcoran',
        'Beek en Donk',
        'Fangliao',
        'Beshariq',
        'Haywards Heath',
        'Nāḩiyat al Kifl',
        'Pitogo',
        'Kapfenberg',
        'Brambleton',
        'Pedra',
        'Frascati',
        'Cinco Saltos',
        'Pecan Grove',
        'Aybastı',
        'Achhnera',
        'Lumbatan',
        'Bormujos',
        'Gormi',
        'Velsk',
        'Cartago',
        'Sun Valley',
        'Butig',
        'Kungälv',
        'San José Ojetenam',
        'Amoucha',
        'Pariyāpuram',
        'Kavallemmāvu',
        'Kodarmā',
        'Kaler',
        'Onondaga',
        'Baravāt',
        'Saint-Lambert',
        'Kumano',
        'Duncan',
        'Herdecke',
        'Linstead',
        'Kanchika',
        'Nasrullāhganj',
        'Morrinhos',
        'Villa Park',
        'Vernāg',
        'San Felíu de Guixols',
        'San Miguel de Papasquiaro',
        'Ivato',
        'Quartier Militaire',
        'Ojinaga',
        'Gobō',
        'Vayanūr',
        'Melíssia',
        'Ashtown',
        'Keta',
        'Seva',
        'Cartagena',
        'Junction City',
        'Kalachinsk',
        'Boura',
        'Kulkent',
        'Chunakara Vadakku',
        'Curchorem',
        'Ayagawa',
        'Rāvar',
        'Arroyito',
        'Kerūr',
        'Garmdarreh',
        'Alnif',
        'Idak',
        'Rosita',
        'Asaita',
        'Watertown',
        'Byala Slatina',
        'Madira',
        'Volendam',
        'Sidi Lmokhtar',
        'Zeya',
        'Amalfi',
        'Kittanapalli',
        'Murādpur',
        'Hazel Dell',
        'Vélizy-Villacoublay',
        'Ambriz',
        'Feira Grande',
        'Nakūr',
        'Buenavista',
        'Bulle',
        'Anthem',
        'The Crossings',
        'Candler-McAfee',
        'Kluczbork',
        'Kapolei',
        'Allison Park',
        'Jarqo‘rg‘on',
        'Saint-Louis',
        'Athens',
        'Litvínov',
        'North Plainfield',
        'Goldasht',
        'Inhapim',
        'Burg',
        'Dalān',
        'Shaomi',
        'Candelaria de La Frontera',
        'Patharia',
        'Beixingzhuang',
        'Andoany',
        'Mallasamudram',
        'Tequixquiac',
        'Tassin-la-Demi-Lune',
        'Lucala',
        'Moirāng',
        'Dip',
        'Oytal',
        'Laguna de Duero',
        'Kitajima',
        'Mullingar',
        'Khaw Zar Chaung Wa',
        'Dover',
        'Moncagua',
        'Llaillay',
        'Cudahy',
        'Nanyangcun',
        'East San Gabriel',
        'Lefkáda',
        'Bagulā',
        'Tashtagol',
        'Wednesfield',
        'La Trinidad',
        'Prieto Diaz',
        'Stadthagen',
        'Pullappalli',
        'Okahandja',
        'Røyken',
        'Zedelgem',
        'Olindina',
        'Areia',
        'Beşikdüzü',
        'Valašské Meziříčí',
        'Chincholi',
        'Fucecchio',
        'Manimala',
        'Morombe',
        'Olteniţa',
        'Manzanares',
        'Barakī Barak',
        'Büdingen',
        'Sèvres',
        'Andorra la Vella',
        'Tocantinópolis',
        'Santa Fe de Antioquia',
        'Timmāpur',
        'Fairhope',
        'Montigny-lès-Cormeilles',
        'Lajas',
        'Savanna-la-Mar',
        'Hirpardangal',
        'Manki',
        'Abbeville',
        'Fleurus',
        'Farsley',
        'Parabcan',
        'Bouzeghaia',
        'Bonney Lake',
        'Warrenton',
        'Yahualica de González Gallo',
        'Valmiera',
        'Furukawa',
        'Dawmat al Jandal',
        'Sardinata',
        'Strakonice',
        'Şirvan',
        'Camacan',
        'Cholavandān',
        'Senador José Porfírio',
        'Oshwe',
        'Boriziny',
        'Los Llanos',
        'El Amria',
        'Talayolaparambu',
        'Bronnitsy',
        'Uenohara',
        'Isperih',
        'Haar',
        'Summit',
        'Souk Tlet El Gharb',
        'Bélabo',
        'Rāman Mandi',
        'East Peoria',
        'Pokhuria',
        'Letterkenny',
        'Sinjār',
        'Florida Ridge',
        'Tlacolula de Matamoros',
        'Mentana',
        'Vengattūr',
        'Kenilworth',
        'Horizon City',
        'Lempäälä',
        'Potters Bar',
        'Kafr Baţnā',
        'Medjez el Bab',
        'Melila',
        'Metzingen',
        'Bobleshwar',
        'Greenwood',
        'Zaragoza',
        'Meiwa',
        'Huautla',
        'Katangi',
        'Gölhisar',
        'Sarangani',
        'Sabana de Torres',
        'Jogipet',
        'Melchor Romero',
        'Hulikal',
        'Deuil-la-Barre',
        'Kotelnich',
        'Mutatá',
        'Cherry Hill',
        'Nadvirna',
        'Corinth',
        'Bafatá',
        'Ouani',
        'Kafr Sa‘d',
        'Roselle',
        'Vilcún',
        'Zabrat',
        'Klatovy',
        'Coração de Maria',
        'Challans',
        'Coralville',
        'San Antonio La Paz',
        'Willowbrook',
        'San Pablo Jocopilas',
        'Lazi',
        'La Madeleine',
        'Ivatsevichy',
        'Nkowakowa',
        'Sucre',
        'Chavasshēri',
        'Foleshill',
        'Mount Pearl Park',
        'Staraya Kupavna',
        'Lincoln',
        'Piddig',
        'Yuli',
        'Chatayamangalam',
        'San Juan de Aznalfarache',
        'Emiliano Zapata',
        'Mouila',
        'Khadyzhensk',
        'Kondāzhi',
        'Karnobat',
        'Caculé',
        'Baclayon',
        'Ladyzhyn',
        'Buriti Bravo',
        'Puente Nacional',
        'Gan Yavne',
        'Pullanpallikonam',
        'Naawan',
        'Torcy',
        'Esparraguera',
        'Mihama',
        'Vernon',
        'Biddeford',
        'Aymangala',
        'Bloomingdale',
        'Moyo',
        'Albertville',
        'Pirajuí',
        'Donaueschingen',
        'Naciria',
        'Ottobrunn',
        'Rancho San Diego',
        'Finnkolo',
        'Sarea Khās',
        'Junnārdev',
        'Bristol',
        'Goulburn',
        'Sibut',
        'Formby',
        'Fenyuan',
        'Sipilou',
        'Maying',
        'Kasamatsuchō',
        'Jebba',
        'Betsizaraina',
        'La Porte',
        'Pudsey',
        'Rixensart',
        'Kiboga',
        'Zhengtun',
        'Zurbāţīyah',
        'Gujan-Mestras',
        'Macas',
        'Bermo',
        'Quissamã',
        'Barreira',
        'Freire',
        'Szczytno',
        'Taishituncun',
        'Sevlievo',
        'Gonghe',
        'Hartbeespoort',
        'Káto Polemídia',
        'Mechta Ouled Oulha',
        'Lindsay',
        'Ibatiba',
        'Perundurai',
        'Montville',
        'Arouca',
        'Central Falls',
        'Rumphi',
        'Ivrea',
        'Bad Rappenau',
        'Saint-Lazare',
        'Arbi’a Tighadwiyn',
        'Diriomo',
        'Gif-sur-Yvette',
        'Sek’ot’a',
        'Sabunçu',
        'Cedro',
        'Waynesboro',
        'Loei',
        'Gurmatkāl',
        'Mendeleyevsk',
        'Carouge',
        'Lyndhurst',
        'Gīlān-e Gharb',
        'Kadiria',
        'Sāmbhar',
        'El Tortuguero',
        'Guérou',
        'Taulahā',
        'Natagaima',
        'Bhawānīgarh',
        'Ceccano',
        'Acworth',
        'Owen Sound',
        'Zvenigorod',
        'Lyman',
        'Scarborough',
        'Frauenfeld',
        'Chinameca',
        'Daxin',
        'DeBary',
        'Panniyannūr',
        'El Doncello',
        'Prāntij',
        'Yuli',
        'Gornji Vakuf',
        'Evesham',
        'Guía de Isora',
        'Bundibugyo',
        'Qorako‘l Shahri',
        'Lochristi',
        'Piúma',
        'Phichit',
        'Montilla',
        'March',
        'Roşiori de Vede',
        'Požega',
        'Magallanes',
        'Brockville',
        'Tiverton',
        'Braine-le-Comte',
        'Laja',
        'Nikolskoye',
        'Cayo Mambí',
        'Sanso',
        'Digor',
        'Pale',
        'Ruston',
        'Brushy Creek',
        'Bartoszyce',
        'Valença do Piauí',
        'Monte Aprazível',
        'Cisnădie',
        'Had Sahary',
        'Oyonnax',
        'Zhucaoying',
        'Maracena',
        'Shoeburyness',
        'Versmold',
        'Chankou',
        'Yokoshibahikari',
        'Lordelo do Ouro',
        'Acatlán',
        'Palaw',
        'Ugong',
        'Palmer',
        'Tiruttangal',
        'Senekane',
        'Hillside',
        'Telšiai',
        'Edegem',
        'Aljaraque',
        'Montereau-faut-Yonne',
        'Mountain Brook',
        'Nagla',
        'Ankireddikuntapālem',
        'Bhatpurī',
        'Chausa',
        'Marina',
        'Singampunari',
        'Itsukaichi',
        'El Hermel',
        'Karaçoban',
        'Athiringal',
        'Tervuren',
        'El Colegio',
        'Kihei',
        'Silvânia',
        'Souahlia',
        'Alangāyam',
        'Bahçe',
        'Litherland',
        'Takahata',
        'Koziatyn',
        'Kheïredine',
        'Combs-la-Ville',
        'West Carson',
        'Bignona',
        'Picasent',
        'Huangzhai',
        'Yeadon',
        'Silver Firs',
        'Ibiá',
        'Yahualica',
        'Kundgol',
        'São Filipe',
        'Sokolov',
        'Hérouville-Saint-Clair',
        'Eppingen',
        'Ingabu',
        'Metekora',
        'Radevormwald',
        'Murayama',
        'Shārūnah',
        'Świebodzice',
        'Saint John’s',
        'Dasnāpur',
        'Poggiomarino',
        'Acomb',
        'Abū Şīr Banā',
        'Khorabar',
        'Bahía de Caráquez',
        'Ortona',
        'Topola',
        'Tiruverumbūr',
        'Ilsede',
        'Zirāpur',
        'Sunny Isles Beach',
        'Samtredia',
        'Tirat Karmel',
        'West Deptford',
        'Hillegom',
        'Rioja',
        'Guazacapán',
        'Soroca',
        'Repelón',
        'Medeiros Neto',
        'Simaria',
        'Adalhāt',
        'Yavuzeli',
        'Chalmette',
        '’s-Gravenzande',
        'Surdulica',
        'Sandomierz',
        'McNair',
        'Kondalāmpatti',
        'Phularwan',
        'Pattiyūrgrāmam',
        'Las Torres de Cotillas',
        'Haderslev',
        'Gubbi',
        'Shabestar',
        'Granite Bay',
        'Gümüşhacıköy',
        'Kilkenny',
        'Bakhri',
        'Maribojoc',
        'Santo Antônio',
        'Yabu',
        'Nova Zagora',
        'Sulmona',
        'Guabo',
        'Qarazhal',
        'Flores',
        'Goleniów',
        'Mbaïki',
        'Jiangdi',
        'Chestermere',
        'Les Pennes-Mirabeau',
        'Salvaterra de Magos',
        'Batad',
        'Vellithiruthi',
        'Paraguarí',
        'Takelsa',
        'Ghriss',
        'Sarapāka',
        'Aleksandrów Łódzki',
        'Sant’Arcangelo di Romagna',
        'Ensley',
        'Bavly',
        'Vayalār',
        'Katkol',
        'Bhānvad',
        'Golden Valley',
        'West Rancho Dominguez',
        'Ban Pa Tueng',
        'World Golf Village',
        'Salcedo',
        'Guryevsk',
        'Pīrmed',
        'Waldkirch',
        'Reigate',
        'Lower',
        'Phetchabun',
        'Pingtan',
        'Kingsville',
        'Bourkika',
        'Ramona',
        'Fantino',
        'Bellavista',
        'Sahil',
        'Tabursuq',
        'Talwandi Sābo',
        'Heide',
        'Livadeiá',
        'Salémata',
        'Krolevets',
        'Qapqal',
        'Cunha',
        'Santa Fe',
        'Arroyito',
        'Namayumba',
        'Caojiachuan',
        'Doutou',
        'La Cruz',
        'Madhupur',
        'San Agustin',
        'Essex',
        'Palmital',
        'Evans',
        'Hallim',
        'Clarksville',
        'Smithfield',
        'Árgos',
        'Perāvūrani',
        'Huejúcar',
        'Pailitas',
        'Penarth',
        'Howli',
        'Montclair',
        'Ocatlán',
        'Muelle de los Bueyes',
        'Husnābād',
        'Hoogezand',
        'São José do Rio Preto',
        'Vilnohirsk',
        'Taixi',
        'Timbiquí',
        'Horley',
        'Newquay',
        'Camborne',
        'West Puente Valley',
        'Kālchīni',
        'Estcourt',
        'Eschborn',
        'Karlsfeld',
        'Moncada',
        'Yorosso',
        'Qifţ',
        'Palestrina',
        'Hastings',
        'Schwetzingen',
        'Nevyansk',
        'Imbert',
        'Parsāgarhi',
        'Ḩamīdīyeh',
        'Kommunar',
        'Gardelegen',
        'Ādampur',
        'Chhanera',
        'Itaiópolis',
        'Oxford',
        'Madamba',
        'Lorton',
        'Rimavská Sobota',
        'Polýgyros',
        'Kalýves Polygýrou',
        'Northwood',
        'Honmachi',
        'San Martín',
        'Ceres',
        'Aswāpuram',
        'Khandela',
        'Karaisalı',
        'Tiquisio',
        'Dongta',
        'Mondovì',
        'East Ridge',
        'Carbondale',
        'Ruwa',
        'Jizhuang',
        'Semikarakorsk',
        'Sapulpa',
        'Banbishancun',
        'Woensdrecht',
        'Jāwad',
        'Naregal',
        'Dubnica nad Váhom',
        'Elsdorf',
        'Corail',
        'Silvania',
        'Oxford',
        'Dioumanzana',
        'Anini-y',
        'Taveta',
        'Comacchio',
        'Meixedo',
        'Ostrów Mazowiecka',
        'Tola',
        'Greystones',
        'Uliastay',
        'Paese',
        'Priego de Córdoba',
        'Chillicothe',
        'Épernay',
        'Jugiāl',
        'Ghāt',
        'Manakambahiny',
        'Ambodimotso Atsimo',
        'Ampasimanjeva',
        'Iarintsena',
        'Andranovorivato',
        'Bemanonga',
        'Vohimasina',
        'Andilanatoby',
        'Alakamisy-Ambohimaha',
        'Ambalaroka',
        'Jafaro',
        'Ankiabe-Salohy',
        'Antsakabary',
        'Uch Sharif',
        'Lebane',
        'Huskvarna',
        'Kigumba',
        'Hacı Zeynalabdin',
        'Aoshang',
        'Atherton',
        'Clevedon',
        'Bukkarāyasamudram',
        'Machagai',
        'Unchahra',
        'Göd',
        'Kotovo',
        'Zundert',
        'Drās',
        'Gavimané',
        'Sanford',
        'Taminango',
        'Bilthoven',
        'Al Bāḩah',
        'Pitrufquén',
        'Néma',
        'Vilyuchinsk',
        'Pinhalzinho',
        'Khosrowshahr',
        'Ampanotokana',
        'Vereshchagino',
        'Iracemápolis',
        'San Rafael del Yuma',
        'Djinet',
        'Robbah',
        'Arroio do Meio',
        'Kuppam',
        'Koekelberg',
        'Vertentes',
        'Koksijde',
        'San Jose',
        'Dudelange',
        'Villa de Leyva',
        'Darien',
        'Sêrro',
        'Valinda',
        'Glauchau',
        'Chākia',
        'Pochāram',
        'Diamantino',
        'Mundamāla',
        'New Hartford',
        'Rāmantali',
        'Ḩārim',
        'Ấp Phú Mỹ',
        'Robertson',
        'Svetlyy',
        'Tornio',
        'Nakagusuku',
        'Samba Cango',
        'Mazagão',
        'Chambersburg',
        'Sandbach',
        'Chalchihuitán',
        'Axapusco',
        'Ituberá',
        'Nerja',
        'Makó',
        'Gōtsuchō',
        'St. Andrews',
        'Aichach',
        'Al Fuḩayş',
        'Krathum Baen',
        'Pascagoula',
        'Pak Tin Pa',
        'Shiroishi',
        'Bungotakada',
        'Membakut',
        'São Miguel do Araguaia',
        'Sayada',
        'Kāchhāri',
        'Darreh Shahr',
        'Árta',
        'Béguédo',
        'São Francisco de Paula',
        'Lom',
        'Muzambinho',
        'Vanthli',
        'Kueneng',
        'Póvoa de Lanhoso',
        'Pio XII',
        'Caranavi',
        'Alfafar',
        'Frameries',
        'North Salt Lake',
        'Casablanca',
        'Almoradí',
        'Shinhidaka',
        'Montmorency',
        'Maghalleen',
        'Karlovo',
        'Hillsborough',
        'Middleton',
        'New Castle',
        'Sonthofen',
        'Sainte-Foy-lès-Lyon',
        'Nogales',
        'Montigny-lès-Metz',
        'Renfrew',
        'Komló',
        'Flörsheim',
        'Gigaquit',
        'Binnish',
        'Tongzhou',
        'Kafr Qāsim',
        'Port Hueneme',
        'Rajauli',
        'Massapequa',
        'Mata Grande',
        'Lihuzhuang',
        'Mbandjok',
        'Māngrol',
        'Ōtsuki',
        'Koçarlı',
        'Los Palmitos',
        'Muhlenberg',
        'Machico',
        'Mount Pleasant',
        'Kepsut',
        'Mangalvedha',
        'Yoshioka',
        'Santa Teresa',
        'Danwān',
        'Puttige',
        'Belper',
        'Soyāgaon',
        'Columbia Heights',
        'Hayesville',
        'Horad Smalyavichy',
        'Kizhakkemanād',
        'Ginosa',
        'Sheldon',
        'Mehnatobod',
        'De Meern',
        'Ginebra',
        'Azambuja',
        'Ouardenine',
        'Ma’mūnīyeh',
        'Pallippuram',
        'Amtali',
        'Orobó',
        'Soltau',
        'Pueblo Juárez',
        'Amposta',
        'Muzhakkunnu',
        'Parsippany',
        'Klamath Falls',
        'Kreuzlingen',
        'Płońsk',
        'Looc',
        'Manor',
        'Falmouth',
        'Palmas de Monte Alto',
        'Poço Verde',
        'Siilinjärvi',
        'Santa Lucía Utatlán',
        'Yate',
        'Morinda',
        'Polkowice',
        'Charo',
        'Aḑ Ḑabyah',
        'Aḑ Ḑāli‘',
        'Avon',
        'Le Petit-Quevilly',
        'Jaynagar',
        'Magdalena',
        'Puruk Cahu',
        'Tūnēri',
        'Had Oulad Issa',
        'Ambatomanoina',
        'Hassi Maameche',
        'Langley Park',
        'Silverdale',
        'Sidi Amrane',
        'Surpur',
        'Bhānpura',
        'Gülşehir',
        'Geertruidenberg',
        'El Callao',
        'Chatra Gobraura',
        'Kayanza',
        'Sedalia',
        'Maniche',
        'Mailavaram',
        'Lakeside',
        'Selden',
        'Seia',
        'South Euclid',
        'Zara',
        'Otopeni',
        'Nøtterøy',
        'Penonomé',
        'Bato',
        'Oberwingert',
        'Shendurjana',
        'Cagwait',
        'Sarai Ranjan',
        'Chapa de Mota',
        'Puerto Triunfo',
        'Eislingen',
        'Hockenheim',
        'Bānapur',
        'Zoersel',
        'Guipos',
        'Bad Harzburg',
        'Birmingham',
        'Chimoré',
        'Anamalais',
        'Shanywathit',
        'Sori',
        'Kurikka',
        'Kalleribhāgam',
        'Massarosa',
        'Vāmanapuram',
        'Miguelópolis',
        'Comalapa',
        'Zabré',
        'Mezdra',
        'Yaojiazhuangcun',
        'Yaojiafen',
        'Erval d’Oeste',
        'Idukki',
        'Tixtla de Guerrero',
        'Vendram',
        'Moularès',
        'Wädenswil',
        'Bankoumana',
        'Caransebeş',
        'Pachino',
        'São João da Madeira',
        'Alhandra',
        'Millau',
        'Shelby',
        'Guapiaçu',
        'Port Alberni',
        'Kozakai-chō',
        'Pampierstad',
        'Tidili Masfiywat',
        'Chāgallu',
        'Sombrerete',
        'Springwater',
        'Eyl',
        'Saint-Jean-de-Braye',
        'Biggleswade',
        'Yucca Valley',
        'Chaumont',
        'Millburn',
        'Assaré',
        'Mühldorf',
        'Paramirim',
        'Kavak',
        'Qādirganj',
        'Brunswick',
        'Duarte',
        'Geseke',
        'Bad Krozingen',
        'Kadiapattanam',
        'Can-Avid',
        'Del City',
        'Pāmpur',
        'Al Karak',
        'Sirat',
        'Ocotlán de Morelos',
        'Tigbao',
        'San Luis Talpa',
        'Gallup',
        'Kangal',
        'Kopřivnice',
        'American Canyon',
        'Senda',
        'Zantiébougou',
        'Kami',
        'Ibicaraí',
        'Lindlar',
        'Bailleston',
        'Gros Islet',
        'Nangavaram',
        'Kobilo',
        'Gökçebey',
        'Nefta',
        'Himora',
        'Pāpanāsam',
        'Lohutí',
        'Jaipur Chuhar',
        'Anūppur',
        'Kānke',
        'Sarzana',
        'Lentini',
        'Ayamonte',
        'San Jacinto',
        'Rāja Pākar',
        'Tiruvankod',
        'Ōkuchi-shinohara',
        'Riachão das Neves',
        'Kumla',
        'Howick',
        'Iisalmi',
        'Tlaltenango de Sánchez Román',
        'São Miguel do Guaporé',
        'Mercato San Severino',
        'Herent',
        'Orzesze',
        'Villeneuve-sur-Lot',
        'Pushkar',
        'Yenice',
        'Zărneşti',
        'San Justo',
        'Mashiko',
        'New Hope',
        'Novopokrovka',
        'Sidi Ifni',
        'Fukagawa',
        'Sainte-Suzanne',
        'Scugog',
        'Bela Vista',
        'Jiuru',
        'Nueva Granada',
        'Nerupperichchal',
        'Lakato',
        'Amarpātan',
        'Savigliano',
        'Xinzhai',
        'Paraguaçu',
        'Vicente Noble',
        'Tamgrout',
        'Isla-Cristina',
        'Alliance',
        'San Fernando',
        'G‘azalkent',
        'Ashtarak',
        'Cuilapan de Guerrero',
        'Chapala',
        'Senaki',
        'Seynod',
        'M’Chedallah',
        'Łaziska Górne',
        'Rosario de Lerma',
        'Alcalá la Real',
        'Unity',
        'Bougado',
        'Qal’acha',
        'Spremberg',
        'Ibirubá',
        'Martí',
        'Xanten',
        'MacArthur',
        'Salcajá',
        'Chilca',
        'Mandapam',
        'Pergine Valsugana',
        'Selouane',
        'Tukwila',
        'Elverum',
        'Seligenstadt',
        'Pākāla',
        'San Nicolás',
        'Mandelieu-la-Napoule',
        'Rancheria Payau',
        'Santa Teresita',
        'Arcueil',
        'Uxbridge',
        'Truro',
        'Kalpakathukonam',
        'Stadtallendorf',
        'Fulshear',
        'Rocky River',
        'Beni Douala',
        'Colle di Val d’Elsa',
        'Pancas',
        'Galeras',
        'Mililani Mauka',
        'Cabucgayan',
        'Ashland',
        'Nāranammālpuram',
        'Kontéla',
        'Güimar',
        'Corciano',
        'Stowmarket',
        'Acatic',
        'Naqādah',
        'Thebes',
        'Payson',
        'Nong Bua Lamphu',
        'Lino Lakes',
        'Wexford',
        'Sermādevi',
        'Ladário',
        'Zerbst',
        'Deán Funes',
        'Cardito',
        'San Martín de las Pirámides',
        'Caombo',
        'Goroka',
        'Villagarzón',
        'Eckernförde',
        'Áno Sýros',
        'Chebli',
        'Bastos',
        'Aarau',
        'Alnāvar',
        'Whyalla',
        'Celina',
        'Parelhas',
        'Hazebrouck',
        'Boquira',
        'North Guwāhāti',
        'Muthutala',
        'Tanki Leendert',
        'Alta Floresta D’Oeste',
        'Holiday',
        'Jawor',
        'Nang Rong',
        'San Bernardo',
        'Allauch',
        'Artémida',
        'Günzburg',
        'Harvey',
        'Chesham',
        'Puerto Natales',
        'Rees',
        'Dickinson',
        'Trezzano sul Naviglio',
        'Takaba',
        'Pandan',
        'Aspe',
        'Kodikuthi',
        'Secaucus',
        'Richmond',
        'Mons-en-Baroeul',
        'Ambohitromby',
        'Hojambaz',
        'Gökdepe',
        'Fort St. John',
        'Madakasīra',
        'Juli',
        'Kotli',
        'El Paujíl',
        'Llorente',
        'Ozark',
        'Sihu',
        'Loncoche',
        'Ryazhsk',
        'Beni Mered',
        'Ganapavaram',
        'Laventille',
        'Paramonga',
        'Senador Guiomard',
        'Palatka',
        'Qalansuwa',
        'East Patchogue',
        'Tarko-Sale',
        'Riehen',
        'Tong’anyi',
        'Paravai',
        'Pendurti',
        'Rio Rico',
        'Osterode',
        'Bariārpur',
        'Amizmiz',
        'Waghäusel',
        'Bogandé',
        'Nhamundá',
        'Volnovakha',
        'Ust’-Katav',
        'Partizánske',
        'Thenia',
        'Fleury-les-Aubrais',
        'Saint-Michel-sur-Orge',
        'Jēkabpils',
        'Chandlers Ford',
        'Tambaú',
        'Āndippatti',
        'Eğil',
        'Mārutūru',
        'Icapuí',
        'Zossen',
        'Omutninsk',
        'Panagyurishte',
        'Trinidad',
        'Shāhbāzpur',
        'Wilmot',
        'Hoogstraten',
        'Noāmundi',
        'Merefa',
        'São João do Piauí',
        'Uruçuca',
        'Ghanzi',
        'Puchheim',
        'Four Square Mile',
        'San Bonifacio',
        'Kutná Hora',
        'Tauragė',
        'Lioua',
        'Grand Island',
        'Neuilly-Plaisance',
        'Péfki',
        'Santa Rosa de Lima',
        'Santa Bárbara',
        'Srīvaikuntam',
        'Jandaia do Sul',
        'Alejandro Korn',
        'Ashland',
        'Bayi',
        'Kant',
        'Świedbodzin',
        'Cumaral',
        'Grajewo',
        'Pāmarru',
        'Shirhatti',
        'Naas',
        'Annecy-le-Vieux',
        'Geneva',
        'Inopacan',
        'Khowai',
        'Mohiuddīnnagar',
        'Perdões',
        'Afourar',
        'Los Vilos',
        'Nogent-sur-Oise',
        'Singur',
        'Kirkintilloch',
        'Gummudipūndi',
        'Nedre Eiker',
        'Allende',
        'Masis',
        'Mantes-la-Ville',
        'Teteven',
        'Almaguer',
        'Palma di Montechiaro',
        'Pleasant Prairie',
        'Seymour',
        'Igreja Nova',
        'Senago',
        'Fortín de las Flores',
        'Achères',
        'Bilohorodka',
        'Mukilteo',
        'Ambinanindrano',
        'Fukuyoshi',
        'Westborough',
        'Iguaí',
        'Stange',
        'Bornem',
        'Silva Jardim',
        'Vecsés',
        'Hafshejān',
        'Galūgāh',
        'Zambrów',
        'Tiruppuvanam',
        'Mountlake Terrace',
        'Khotkovo',
        'Mī’ēso',
        'Metahāra',
        'Mundgod',
        'Dax',
        'South Lake Tahoe',
        'Hammanskraal',
        'Darton',
        'Kasumpti',
        'Chernogolovka',
        'Padmanābhapuram',
        'Doaba',
        'Pāmbādi',
        'Lindenwold',
        'Vicksburg',
        'Moorestown',
        'San Antonio de Areco',
        'Somers',
        'Ulus',
        'Antilla',
        'Terrier Rouge',
        'Oum Drou',
        'Mrągowo',
        'Srīperumbūdūr',
        'Sukhodilsk',
        'Youganning',
        'Yegainnyin',
        'Alcudia',
        'Lyskovo',
        'Mīt Namā',
        'Winter Gardens',
        'Sondershausen',
        'Kelkit',
        'Tubbergen',
        'Naryn',
        'Armidale',
        'São João',
        'Aralık',
        'Aiud',
        'Nirmāli',
        'Narippatta',
        'Wang Nam Yen',
        'Makulubita',
        'Artashat',
        'Akonolinga',
        'Casal di Principe',
        'Luna',
        'Kaduturutti',
        'Huy',
        'Kaikalūr',
        'Cacém',
        'Chāgalamarri',
        'Krus na Ligas',
        'South Holland',
        'Sulphur',
        'Sevilimedu',
        'Royton',
        'Dolton',
        'Clemmons',
        'Mansourah',
        'Perry',
        'Kitcharao',
        'Carthage',
        'Gauting',
        'Mabitac',
        'Belovodskoe',
        'Yorkville',
        'Pájara',
        'Wieluń',
        'Linamon',
        'Aguilares',
        'Baldwin',
        'Mossaka',
        'Nowa Ruda',
        'Agropoli',
        'Vialonga',
        'Almasi',
        'Dronfield',
        'Elmwood Park',
        'Oshoba',
        'Iruttarakonam',
        'Rolante',
        'Traunstein',
        'Nāmagiripettai',
        'Sulejówek',
        'West Pensacola',
        'Tapalpa',
        'Asni',
        'Koduvilārpatti',
        'Carrboro',
        'Ntcheu',
        'Monroe',
        'Sanxing',
        'Suwanee',
        'Aguadas',
        'Manuel Tames',
        'Lealman',
        'Bhairi̇̄',
        'Ossett',
        'Montecristo',
        'Urk',
        'Narkher',
        'Degollado',
        'Tādikombu',
        'Fierenana',
        'Dupax del Sur',
        'São Sepé',
        'Pillaiyārkuppam',
        'Bobonong',
        'Essex',
        'Kosgi',
        'Zonhoven',
        'Concepción Huista',
        'Jiangjiadong',
        'Kontich',
        'Parasi',
        'Matsuura',
        'Rose Hill',
        'Obock',
        'Tabount',
        'Varennes',
        'Lucélia',
        'Chorwād',
        'Rātu',
        'Saint-Mandé',
        'Milwaukie',
        'Caaporã',
        'Rio Maior',
        'Valle Nacional',
        'Qia’erbagecun',
        'Nāgamangala',
        'Borgomanero',
        'Boca da Mata',
        'Rupauli',
        'Šaľa',
        'Vryburg',
        'Floridia',
        'Hyde Park',
        'Shāzand',
        'Belonia',
        'Gardanne',
        'Lāthi',
        'Belas',
        'Manlleu',
        'Varadero',
        'Tiburon',
        'Penzance',
        'Sarāri',
        'Tinja',
        'Guasipati',
        'Clarin',
        'Bologoye',
        'Khromtaū',
        'Varkaus',
        'Wildeshausen',
        'Hallein',
        'Allschwil',
        'Florencia',
        'Morāsar',
        'Maracaçumé',
        'Hancha',
        'Saponé',
        'Irákleia',
        'Kanmaki',
        'Bayou Cane',
        'Loyola Heights',
        'Kannānendal',
        'Quirima',
        'Saurh',
        'Cortona',
        'Kağızman',
        'Forbach',
        'Villeneuve-le-Roi',
        'Aguinaldo',
        'Cartaya',
        'Skegness',
        'Carlos Casares',
        'Schramberg',
        'Raksaha',
        'Adigaratti',
        'Enköping',
        'Shark',
        'Suaza',
        'Vikhorevka',
        'Kokofata',
        'Ādēt',
        'San Juan de Río Coco',
        'Princesa Isabel',
        'Nogales',
        'Foley',
        'Wipperfürth',
        'Irukanni',
        'Hays',
        'Martellago',
        'Tullahoma',
        'Āmodei',
        'Bānki',
        'Conceição de Macabu',
        'Molde',
        'Chocamán',
        'Traunreut',
        'Altepexi',
        'Wülfrath',
        'Germersheim',
        'Tehata',
        'Gorgonzola',
        'El Pinar',
        'Gardner',
        'San Isidro de Lules',
        'Oued el Aneb',
        'Itapaci',
        'Sadao',
        'Tizi-n-Bechar',
        'São Marcos',
        'Seria',
        'Chopinzinho',
        'Xoxocotla',
        'Furano',
        'Harstad',
        'North Bellmore',
        'Paiganapalli',
        'Karachayevsk',
        'Phulpur',
        'Sondrio',
        'Orhei',
        'Injibara',
        'North Ogden',
        'Toguchin',
        'Imi n’Oulaoun',
        'Xinnongcun',
        'Palmeiras',
        'Behat',
        'Caxambu',
        'Paulistana',
        'San Juan de Vilasar',
        'Makakilo',
        'Saint Austell',
        'Laje',
        'Chinchali',
        'San Francisco',
        'Villapinzón',
        'Palafrugell',
        'Jangy-Nookat',
        'Ukmergė',
        'Saka',
        'Saint Paul’s Bay',
        'Joaquín V. González',
        'South Whitehall',
        'Itambacuri',
        'Park Forest',
        'Nubl',
        'Helena',
        'Oro-Medonte',
        'Józefów',
        'Praia da Vitória',
        'Mirador',
        'Caterham',
        'Sysert',
        'Porto Torres',
        'Wade Hampton',
        'São Jerônimo',
        'Groß-Umstadt',
        'Phalia',
        'Zülpich',
        'Patpāra',
        'Ariano Irpino',
        'Stephenville',
        'Klaukkala',
        'San José de Jáchal',
        'Az Zuwaytīnah',
        'Timmarāsanāyakkanūr',
        'Woodlesford',
        'Nördlingen',
        'San Víctor Abajo',
        'Fatehgarh Chūriān',
        'Mānjhi',
        'Tanhaçu',
        'Sāmba',
        'Willmar',
        'Ronda',
        'Stratton Saint Margaret',
        'Alto-Cuilo',
        'Cuilo',
        'Daddi',
        'Mangghystaū',
        'Merrick',
        'Cranendonck',
        'Sremčica',
        'Nurota',
        'Morafeno',
        'Sitampiky',
        'Ambalavato',
        'Tongobory',
        'Tsarasaotra',
        'Ambohipandrano',
        'Andolofotsy',
        'Soanindrariny',
        'Ankililoaka',
        'Tsiamalao',
        'Fiadanana',
        'Antanambao',
        'Sahamadio',
        'Miorimivalana',
        'Ambohimanambola',
        'Ampasimatera',
        'Karianga',
        'Matanga',
        'La Colonia Tovar',
        'Fengjia',
        'Dabaozi',
        'Sancoale',
        'Kudāl',
        'Anah',
        'Mandza',
        'Sidi Lahssen',
        'Lebanon',
        'Tufānganj',
        'Ţorqabeh',
        'Fredonia',
        'San Antonio Sacatepéquez',
        'Vadakkum',
        'Walia',
        'Laranjeira',
        'Cassilândia',
        'Barcelona',
        'Sokal',
        'Capela do Alto',
        'Agan',
        'Itapecerica',
        'Welench’ītī',
        'Daboh',
        'Nizāmpatam',
        'El Retén',
        'Suzzara',
        'Osmaneli',
        'Zele',
        'Fót',
        'Santa Vitória',
        'Baiheqiao',
        'Thuân An',
        'Saint-Cyr-l’École',
        'Bugalagrande',
        'Ankaraobato',
        'Hualqui',
        'Pushchino',
        'Upper St. Clair',
        'Caracaraí',
        'Carmo do Rio Claro',
        'Mont-Royal',
        'Pacatu',
        'Coreaú',
        'Hirekerūr',
        'Quela',
        'Purranque',
        'Hino',
        'Nizhniy Lomov',
        'Mineola',
        'San Cataldo',
        'Vladičin Han',
        'East Moline',
        'East Pennsboro',
        'Green Valley',
        'Öndörhaan',
        'Chaudfontaine',
        'Cuncolim',
        'Piedra Blanca',
        'Torrox',
        'Damua',
        'Punturin',
        'Saint-Genis-Laval',
        'Renens',
        'Nambiyūr',
        'Chợ Phước Hải',
        'Alagoa Nova',
        'Concepción Chiquirichapa',
        'Louisville',
        'Urussanga',
        'Fort Walton Beach',
        'Argenta',
        'Five Corners',
        'San Guillermo',
        'Vili',
        'Leskovac',
        'Aishō',
        'Guará',
        'Jericho',
        'Bni Rzine',
        'Little Egg Harbor',
        'Mössingen',
        'Esperalvillo',
        'Osa',
        'Vakkam',
        'Lučani',
        'La Unión',
        'Pudupattanam',
        'Kamamaung',
        'Senden',
        'Snellville',
        'Herborn',
        'Voiron',
        'Eeklo',
        'Monte Santo de Minas',
        'Pico Truncado',
        'Battle Ground',
        'Beaumont',
        'Texistepec',
        'Liubotyn',
        'Bālugān',
        'Germantown',
        'Brummen',
        'Ludlow',
        'Murphy',
        'Don Bosco',
        'Koāth',
        'Quixeré',
        'Ambohimanga',
        'Normanton',
        'Mulanje',
        'Sint-Katelijne-Waver',
        'Palavūr',
        'Murree',
        'Zaliohouan',
        'Kyaukpyu',
        'Saint-Gratien',
        'Sotomayor',
        'Sánchez',
        'Bordj Bounaama',
        'Bambalang',
        'Mudākkal',
        'Kara-Suu',
        'Aurād',
        'Luisiana',
        'Shōwa',
        'El Gara',
        'Oliveira do Hospital',
        'Sidi Abdelkader',
        'Buinsk',
        'Birkerød',
        'Beni Haoua',
        'Hyattsville',
        'Amdjarass',
        'Wallington',
        'Hindang',
        'Pogradec',
        'Moniquirá',
        'Olenegorsk',
        'Ovejas',
        'Carlisle',
        'Vinany',
        'Kurovskoye',
        'Sanhe',
        'Wettingen',
        'Cercado Abajo',
        'Letlhakane',
        'Musselburgh',
        'Hārohalli',
        'Arnold',
        'Luckenwalde',
        'Debark’',
        'Yachiyo',
        'Imi-n-Tanout',
        'Paldorak',
        'Wasquehal',
        'Lajinha',
        'Junqueirópolis',
        'Halluin',
        'Norton',
        'Jindřichŭv Hradec',
        'Bruchköbel',
        'Conselheiro Pena',
        'Elektrougli',
        'Gaura',
        'Castrovillari',
        'West St. Paul',
        'Murrysville',
        'Buckingham',
        'Karmana',
        'Covington',
        'São José da Laje',
        'Miches',
        'Meinerzhagen',
        'Bhainsdehi',
        'Sonāgāzi',
        'Bourg-la-Reine',
        'Beroun',
        'Lockport',
        'Stadtlohn',
        'Devirammanahalli',
        'Mariāni',
        'Grayslake',
        'Malaba',
        'Chelak',
        'Ararat',
        'Conceição da Feira',
        'Valkeakoski',
        'Banting',
        'Schortens',
        'Mill Creek',
        'Ambondro',
        'Alta',
        'Derinkuyu',
        'Ibicoara',
        'Morsang-sur-Orge',
        'Peringanād',
        'Cordeiro',
        'Veresegyház',
        'Coolbaugh',
        'Croix',
        'Jicomé',
        'Carcagente',
        'Wittmund',
        'Sestu',
        'Bronkhorstspruit',
        'Leek',
        'Samacá',
        'Algete',
        'Hörstel',
        'Gavarr',
        'Coudekerque-Branche',
        'Barhampur',
        'Springfield',
        'Bulnes',
        'Colfontaine',
        'Ul',
        'Oliveira de Azemeis',
        'Pālkonda',
        'Puduppalli Kunnam',
        'Chaves',
        'Yermal',
        'Luacano',
        'Sivaslı',
        'Illapel',
        'Valljkkod',
        'Ino',
        'Bryant',
        'Banganapalle',
        'Mino',
        'Supaul',
        'Kuknūr',
        'Sivrihisar',
        'Narwar',
        'Bandar-e Gaz',
        'Mathukumnel',
        'Leigh-on-Sea',
        'Castel San Pietro Terme',
        'Sonbarsa',
        'Tittagudi',
        'Anagni',
        'Puzol',
        'Le Creusot',
        'Monsummano',
        'Zhangatas',
        'Hanahan',
        'Iriona',
        'Hexiang',
        'Gannavaram',
        'St. John',
        'Kiseljak',
        'Achampet',
        'Moḩammadābād',
        'Boscombe',
        'Palestina de los Altos',
        'Dugulubgey',
        'Beruri',
        'Arnaud',
        'Oliveira dos Brejinhos',
        'Hanmayingcun',
        'Goodlands',
        'Lebedinovka',
        'Ennis',
        'Tolosa',
        'Elvas',
        'Parma Heights',
        'Enger',
        'Ménaka',
        'Vranov nad Topľou',
        'Werdau',
        'São João Batista',
        'Llandudno',
        'Guaratinga',
        'Nidderau',
        'Kolāras',
        'Mae Sai',
        'Luna',
        'Campestre',
        'Monte Azul',
        'Ati',
        'Olho d’Água das Flores',
        'Chithara',
        'Ozoir-la-Ferrière',
        'Dolyna',
        'Montecatini Terme',
        'São João de Pirabas',
        'Palma del Río',
        'Baroda',
        'San Julián',
        'Lennox',
        'Baie-Comeau',
        'Mäntsälä',
        'Soye',
        'Bussolengo',
        'Pozantı',
        'Failsworth',
        'Recanati',
        'Cornaredo',
        'South Milwaukee',
        'Kremenets',
        'Kirkstall',
        'Marquette',
        'Wuustwezel',
        'Steinhagen',
        'Działdowo',
        'Profesor Salvador Mazza',
        'Bozoum',
        'Waxhaw',
        'Guadalupe',
        'Lomita',
        'Schwanewede',
        'İsmayıllı',
        'Lagoa do Itaenga',
        'Mesquite',
        'Sidi Chiker',
        'Muttatōdi',
        'Givors',
        'Rosamond',
        'Fougères',
        'Ås',
        'Banaue',
        'Los Llanos de Aridane',
        'Melena del Sur',
        'Montgomery',
        'Bohumín',
        'Piazza Armerina',
        'Denain',
        'Vinjamūr',
        'Kladovo',
        'Nerekhta',
        'Riacho das Almas',
        'El Hammadia',
        'San Isidro',
        'Parramos',
        'Miandrivazo',
        'Lubalo',
        'Bagou',
        'Bad Waldsee',
        'Sidi Rahal',
        'Mitry-Mory',
        'Yanagawamachi-saiwaichō',
        'Sour',
        'Sarreguemines',
        'Kāmalāpuram',
        'Pueblo Nuevo',
        'Arroyo Seco',
        'Longjumeau',
        'Bethany',
        'Chachahuantla',
        'Pahuatlán de Valle',
        'Maraú',
        'Bockum',
        'Isernia',
        'Kleppe',
        'Schifferstadt',
        'Hardi',
        'Manhumirim',
        'Pittsburg',
        'Na Klang',
        'Hurricane',
        'Dingolfing',
        'Concarneau',
        'Crowborough',
        'Adrian',
        'Bobrov',
        'Târnăveni',
        'Tân Phong',
        'Al Qays',
        'Botevgrad',
        'Paraíso',
        'Celbridge',
        'Ghafurov',
        'Popasna',
        'Yakkabog‘',
        'Sunbāţ',
        'Tepexi de Rodríguez',
        'Patuvilāyi',
        'South St. Paul',
        'Somanya',
        'Aldan',
        'Nan',
        'Goris',
        'Manbengtang',
        'Câmpia Turzii',
        'Neufahrn bei Freising',
        'Jatāra',
        'Kawambwa',
        'Gjøvik',
        'Caravelas',
        'Nanuet',
        'Sāvda',
        'Riverview',
        'Kapay',
        'Wachtberg',
        'Mead Valley',
        'Nuenen',
        'Lillehammer',
        'Loja',
        'Kekem',
        'Baza',
        'Chapulhuacán',
        'Buftea',
        'Rosolini',
        'Toktogul',
        'Palo del Colle',
        'Romano di Lombardia',
        'Nikolsk',
        'Hashtrūd',
        'Mbala',
        'Qagan Us',
        'Foya Tangia',
        'Şā al Ḩajar',
        'Arhavi',
        'Pleasantville',
        'Dumbarton',
        'Lugait',
        '‘Adrā',
        'Chintalapalle',
        'Hlohovec',
        'Mödling',
        'Libertyville',
        'Jefferson',
        'Sultanpur',
        'Forest Lake',
        'Honnāli',
        'La Resolana',
        'Fröndenberg',
        'Zestaponi',
        'Shoreham-by-Sea',
        'Chã Grande',
        'Namioka',
        'Vakon',
        'Arsk',
        'Sunchales',
        'Gandujie',
        'Batī',
        'Colleferro',
        'Codlea',
        'Mazinde',
        'Laboulaye',
        'Guadalupe Nuevo',
        'Sultanhisar',
        'Carmen de Patagones',
        'Quemado de Güines',
        'Aomar',
        'Maniyamturuttu',
        'Torhout',
        'Bothell West',
        'Bladel',
        'Lami',
        'Sonkach',
        'Hernani',
        'Hythe',
        'La Paz',
        'Vadamadurai',
        'Madipakkam',
        'Baião',
        'Chennimalai',
        'Bala',
        'Žďár nad Sázavou',
        'Cobourg',
        'Ōhara',
        'Mustang',
        'Northfield',
        'Atoyac de Álvarez',
        'Station des Essais M.V.A.',
        'Tyrnyauz',
        'Eruh',
        'Elko',
        'Coswig',
        'Colonia General Felipe Ángeles',
        'Cauto Cristo',
        'Carquefou',
        'Trecate',
        'Ijevan',
        'Nishigō',
        'Bibai',
        'Bañolas',
        'Kozmodemyansk',
        'Baskil',
        'Horten',
        'Muhammadābād',
        'Pendembu',
        'Tielt',
        'Shimogamo',
        'Cranbrook',
        'Nellimarla',
        'Lubartów',
        'Grottaferrata',
        'Binondo',
        'San Bernardino',
        'Seydi',
        'Sapang Dalaga',
        'Nahorkatiya',
        'Khvānsār',
        'Sceaux',
        'Limerick',
        'Stevenson Ranch',
        'Capanema',
        'Hatvan',
        'Peritoró',
        'Saint-Pol-sur-Mer',
        'Lower Southampton',
        'Posušje',
        'Becerril',
        'La Celle-Saint-Cloud',
        'Hailsham',
        'Bhojpur Kadīm',
        'Uetze',
        'Kurshab',
        'Reçani',
        'Dülken',
        'Nadikūde',
        'Kaltan',
        'Magsaysay',
        'Timaná',
        'Mindouli',
        'Sant Just Desvern',
        'Golden',
        'Omagh',
        'Kizhāttūr',
        'Aïn Cheggag',
        'Kawayan',
        'Otsego',
        'Bourne',
        'Costas de Cão',
        'Caparica',
        'San Martín',
        'Taşlıçay',
        'Mường Lay',
        'Saco',
        'Paraisópolis',
        'Puerto Quito',
        'Maghull',
        'Piqua',
        'Keevallur',
        '’Aïn el Bell',
        'Fara',
        'Sidi Jaber',
        'Imperial',
        'Susaki',
        'Mealhada',
        'Cinfães',
        'Ostrov',
        'Jarājūs',
        'Vyškov',
        'Pak Phanang',
        'Huércal-Overa',
        'Itatira',
        'Baud',
        'Aígio',
        'Chepo',
        'Sandwich',
        'Roshal',
        'Bezhetsk',
        'Koipādi',
        'Yasothon',
        'Lambari',
        'Joinville-le-Pont',
        'Kuala Pembuang',
        'Bouguenais',
        'Jaboticatubas',
        'Dereli',
        'Pariyāram',
        'San Lorenzo',
        'Reggane',
        '’Aïn el Hammam',
        'Iztapa',
        'Hauppauge',
        'Monroe',
        'Follonica',
        'Greiz',
        'Montrose',
        'Donggou',
        'West Hempstead',
        'North Liberty',
        'Zaghouan',
        'Haßloch',
        'Requena',
        'Old Jamestown',
        'Painesville',
        'Newmarket',
        'Sandhurst',
        'Chokkampatti',
        'Beforona',
        'Koło',
        'Longbangcun',
        'La Cañada Flintridge',
        'Naraura',
        'Jaguaruna',
        'Barki Saria',
        'Rāmnagar Farsāhi',
        'Samboan',
        'Porto Real',
        'Reichenbach/Vogtland',
        'Alatsinainy-Bakaro',
        'Kūttampala',
        'Feltre',
        'San Pablo Tacachico',
        'Kanaya',
        'Yangping',
        'Tewkesbury',
        'Okha',
        'Cormano',
        'Rothwell',
        'Karaiyāmpudūr',
        'Frøyland',
        'Høyland',
        'Marblehead',
        'Sidney',
        'Kihihi',
        'Blankenberge',
        'Sartalillo',
        'Altamont',
        'Lannion',
        'Cugnaux',
        'Harpālpur',
        'Bratunac',
        'Neustrelitz',
        'Middle',
        'Yellowknife',
        'Makhtal',
        'Westbrook',
        'Mundra',
        'Tala Yfassene',
        'Huanuni',
        'North Amityville',
        'Kashkar-Kyshtak',
        'Dan Gorayo',
        'Grande-Synthe',
        'La Chapelle-sur-Erdre',
        'Trentola',
        'Santa María Chilchotla',
        'Chevilly-Larue',
        'Dharmkot',
        'Nesoddtangen',
        'Farāshband',
        'Fangasso',
        'Farum',
        'Baihar',
        'Salmon Creek',
        'Xincheng',
        'Santa Rosa',
        'Rawicz',
        'Katākos',
        'Senta',
        'Santa Rita',
        'Khowrzūq',
        'Las Vigas de Ramírez',
        'Chandragiri',
        'Lynbrook',
        'Gryfino',
        'Kyakhta',
        'Selendi',
        'Bellshill',
        'Tobe',
        'East Northport',
        'Duderstadt',
        'Sherwood',
        'San Martin De Porres',
        'São Felipe',
        'Marion Oaks',
        'Oroville',
        'Lalín',
        'Bīrpur',
        'Salgado',
        'Inírida',
        'Cândido Mendes',
        'Shisui',
        'Chorozinho',
        'Shāmgarh',
        'Nyon',
        'Teculután',
        'Simití',
        'Concordia',
        'Sergach',
        'Tenjo',
        'Minturno',
        'Whitpain',
        'Gopavaram',
        'Çilimli',
        'Lingolsheim',
        'Devrukh',
        'Arlington',
        'Ogden',
        'Kokopo',
        'East Hemet',
        'Pulicat',
        'Rumoi',
        'Frankfort',
        'Wodonga',
        'Luperón',
        'Parkāl',
        'Ely',
        'Ervália',
        'Rovira',
        'Bad Schwartau',
        'Vico Equense',
        'Sbeitla',
        'Chodavaram',
        'Hazlet',
        'Danao',
        'Husainpur',
        'Mar’’ina Horka',
        'Mosta',
        'South Burlington',
        'Blieskastel',
        'Jdour',
        'Aguas Zarcas',
        'Touna',
        'Rāipura',
        'Abano Terme',
        'Palisades Park',
        'Ochtrup',
        'Zhangjiazhuang',
        'Samthar',
        'Āsosa',
        'Belūr',
        'Xionglin',
        'Parnamirim',
        'Telgte',
        'Piñan',
        'Edakkunnam',
        'Le Plessis-Trévise',
        'Khilchipur',
        'Lichtenfels',
        'Gyömrő',
        'Bruges',
        'Kortenberg',
        'Ban Tha Pha',
        'Yaraka',
        'Lower Allen',
        'Gostyń',
        'Azacualpa',
        'San Francisco',
        'Sessa Aurunca',
        'Le Mée-sur-Seine',
        'Chāndpur',
        'Entroncamento',
        'Ypsilanti',
        'Rhenen',
        'Pennāgaram',
        'Mělník',
        'Tondi',
        'Stebnyk',
        'Xo‘jaobod',
        'Ağsu',
        'Ağstafa',
        'Mayluu-Suu',
        'Rivalta di Torino',
        'Pontassieve',
        'Pompéia',
        'Boromo',
        'Cournon-d’Auvergne',
        'Uruburetama',
        'South Frontenac',
        'Douar Lamrabih',
        'Puerto Viejo',
        'Reitz',
        'Pikalëvo',
        'Guácima',
        'Voyenno-Antonovka',
        'Skive',
        'Ripley',
        'Cruz Grande',
        'Ban Klang',
        'Blansko',
        'Taïbet',
        'Kotelnikovo',
        'Polonne',
        'Seaham',
        'Talayāzham',
        'Monte Alegre de Minas',
        'Abou el Hassan',
        'Peterlee',
        'Pargi',
        'Schopfheim',
        'Otegen Batyr',
        'Motatán',
        'Shafter',
        'Diondiori',
        'Sivagiri',
        'Nelkattumseval',
        'Akora',
        'Kubinka',
        'Medina',
        'Frontino',
        'Erragondapālem',
        'Río Grande',
        'Aurād Shāhjahāni',
        'Ayaviri',
        'Pantanaw',
        'Sesheke',
        'Bhitarwār',
        'Regente Feijó',
        'Kherālu',
        'Cahors',
        'Midway',
        'La Trinidad',
        'Rosas',
        'Mattigiri',
        'Alblasserdam',
        'Ālampālaiyam',
        'Dorchester',
        'Teonthar',
        'Arbutus',
        'Giengen an der Brenz',
        'Karukachal',
        'Aquidabã',
        'Portland',
        'Kürten',
        'Del Carmen',
        'Toprakkale',
        'Mayfield Heights',
        'Farakka',
        'Morristown',
        'Turmalina',
        'Pujehun',
        'Belmonte',
        'Holzminden',
        'Bela',
        'Leinefelde',
        'Villa Verde',
        'Vettikkavala',
        'Rivière-du-Loup',
        'Huanímaro',
        'Senec',
        'Halewood',
        'Comrat',
        'Plainview',
        'Archena',
        'Pallazzolo sull’Oglio',
        'Barkly West',
        'Nykøbing Falster',
        'Culpeper',
        'Gálvez',
        'Nkoteng',
        'Westminster',
        'Celorico de Basto',
        'Biləsuvar',
        'Lubań',
        'Kulpahār',
        'Montalto Uffugo',
        'Buckhall',
        'Satsuma',
        'Oberkirch',
        'La Crescenta-Montrose',
        'Medina del Campo',
        'Villa Hidalgo',
        'Agoura Hills',
        'Kwinana',
        'Denderleeuw',
        'Piatã',
        'Yabayo',
        'Şiran',
        'Douglas',
        'Lonār',
        'Vīrarājendrapet',
        'Barroso',
        'Mithi',
        'Porto Real do Colégio',
        'Skanderborg',
        'Killingworth',
        'Depālpur',
        'Sidhapa',
        'Naranjos',
        'Ėsanboy',
        'Dhorīmanna',
        'El Ancer',
        'Cabra',
        'Mahaditra',
        'Temacine',
        'Horwich',
        'Mont-Saint-Aignan',
        'Llallagua',
        'Schmalkalden',
        'Banī Murr',
        'Brezno',
        'Schroeder',
        'Rosedale',
        'Ban Wat Sala Daeng',
        'Casa de Oro-Mount Helix',
        'Nguékhokh',
        'Mesra',
        'Durbat',
        'Pita',
        'Kārtikapalli',
        'Guding',
        'Shakhunya',
        'Diguapo',
        'Buxton',
        'Ferentino',
        'Lohur',
        'Meadowbrook',
        'Bülach',
        'Teopisca',
        'Kannod',
        'Alagir',
        'Kafr Lāhā',
        'Makoua',
        'Castillo de Teayo',
        'Mutuípe',
        'São Joaquim do Monte',
        'Tettnang',
        'Bhopatpur',
        'Whitehall',
        'Harvey',
        'Yumbel',
        'Tolú Viejo',
        'Atescatempa',
        'Port Colborne',
        'Beaune',
        'Bubanza',
        'Hakui',
        'La Esmeralda',
        'Takanabe',
        'Albany',
        'Rolla',
        'Atarfe',
        'Cittadella',
        'Howard',
        'Sidi Merouane',
        'Altoona',
        'Kissa',
        'Oderzo',
        'Jiangjiehe',
        'Dumbrăviţa',
        'Tsotsin-Yurt',
        'Krasnohrad',
        'Pertuis',
        'Etzatlán',
        'Calceta',
        'Presidente Getúlio',
        'Kandakkadava',
        'Rio Formoso',
        'Gorāya',
        'Port Angeles',
        'Tupanciretã',
        'Forest Park',
        'Piove di Sacco',
        'El Ksiba',
        'Bispham',
        'Indwe',
        'Kfar Kiddé',
        'El Bâzoûrîyé',
        'Slobozia',
        'Ampasimena',
        'Mandrosohasina',
        'Ambalakirajy',
        'Rantabe',
        'Mandiavato',
        'Ambatosoratra',
        'Antsampandrano',
        'Tsararafa',
        'Analapatsy',
        'Tsarabaria',
        'Soavina Antanety',
        'Bekatra Maromiandra',
        'Haka',
        'Arvayheer',
        'Cárdenas',
        'Kuala Lipis',
        'Opuwo',
        'Bulolo',
        'Gahi Mammar',
        'Alfena',
        'Kébémer',
        'Mikumi',
        'Muheza',
        'Sultonobod',
        'Sangīn',
        'Sitrah',
        'Zemio',
        'Bamukumbit',
        'Bamumkumbit',
        'Sanjianxiang',
        'Hampton',
        'Minster',
        'Shri Mahāvīrji',
        'Tadas',
        'Asarganj',
        'Rankhandi',
        'Bhaluhār',
        'Mandi Bamora',
        'Bareja',
        'Sikka',
        'Mūnak',
        'Pīrbahora',
        'Hudli',
        'Bāzidpur Madhaul',
        'Suroth',
        'Sujnipur',
        'Marjampād',
        'Sohāna',
        'Chimthāna',
        'Mangapet',
        'Ankalgi',
        'Kothanūru',
        'Tuminkatti',
        'Nāranda',
        'Sonāimukh',
        'Baragoi',
        'Ciudad Miguel Alemán',
        '’Aïn Mabed',
        'Comăneşti',
        'Garhshankar',
        'Donauwörth',
        'Vasiana',
        'Chaville',
        'Salsomaggiore Terme',
        'Dillingen',
        'Evaz',
        'Emet',
        'Antrim',
        'Trostyanets’',
        'Chirilagua',
        'Sanrh Majhgawan',
        'Palāshi',
        'Sidi Taibi',
        'San Marcos',
        'Kumīl',
        '’Ali Ben Sliman',
        'Shōnai',
        'Shirahama',
        'Vazante',
        'Shīrūru',
        'Valderrama',
        'Valiyakumāramangalam',
        'Kadakola',
        'Vianen',
        'L’Isle-sur-la-Sorgue',
        'Matheu',
        'Tibagi',
        'Itsoseng',
        'Alpinópolis',
        'Usman',
        'Bafoulabé',
        'Altagracia',
        'Korntal-Münchingen',
        'Benicasim',
        'Kampong Thum',
        'Petrinja',
        'Ampefy',
        'Waldbröl',
        'Hudson',
        'Bhasāwar',
        'Coruche',
        'Plaisance-du-Touch',
        'Ban Tha Kham',
        'Carira',
        'Chilly-Mazarin',
        'Acquaviva delle Fonti',
        'Butare',
        'Tarhzirt',
        'Náchod',
        'Irlam',
        'Mapai',
        'Mulundo',
        'Venmani',
        'Dharampur',
        'Aït Tamlil',
        'Koskāpur',
        'Doany',
        'San Martín de la Vega',
        'Had Zraqtane',
        'Sand Springs',
        'La Mornaghia',
        'Hiddenhausen',
        'Chatiā',
        'Andāl',
        'Sīāhkal',
        'Merrifield',
        'Affton',
        'Ulcinj',
        'Dhanaula',
        'Palliman',
        'Äänekoski',
        'Burnie',
        'Merad',
        'Bichena',
        'Yuancun',
        'Novate Milanese',
        'Senapparetti',
        'Pukhrāyān',
        'Plattsburgh',
        'Saint-Augustin-de-Desmaures',
        'Bressuire',
        'Piedade',
        'Ban Tha Ton',
        'Sakae',
        'Nottuln',
        'El Arenal',
        'Cachoeirinha',
        'Zāwiyat Razīn',
        'Oregon',
        'Hunters Creek',
        'Akureyri',
        'Biddulph',
        'Saynshand',
        'Vevey',
        'Vila Rica',
        'Nejo',
        'Tolosa',
        'Mokena',
        'Crest Hill',
        'Namegawa',
        'Maple Shade',
        'Loudima Poste',
        'Málaga',
        'Kisai',
        'Nizhnyaya Tura',
        'El Paraíso',
        'Gil',
        'Guiré',
        'Andirá',
        'Kūysinjaq',
        'Brandsen',
        'Peshtera',
        'Hamina',
        'Miamisburg',
        'Senica',
        'Dagohoy',
        'Karlapālem',
        'Gherla',
        'Castilho',
        'Homewood',
        'Canelones',
        'El Castillo de La Concepción',
        'Opfikon',
        'Sacile',
        'Ibirama',
        'Calella',
        'Orašje',
        'Comapa',
        'Quepos',
        'Bara Malehra',
        'Līkak',
        'Boussu',
        'Umrāpur',
        'El Mansouria',
        'Gerlingen',
        'Zimatlán de Álvarez',
        'Kavieng',
        'Tepetlixpa',
        'Cartavio',
        'San Miguel',
        'Humanes de Madrid',
        'Ratanpur',
        'Moosburg',
        'Campamento',
        'Gubakha',
        'Kuusankoski',
        'Jagatpur',
        'Cantagalo',
        'Rechaïga',
        'Gebre Guracha',
        'Volokolamsk',
        'Stroud',
        'Peto',
        'Masterton',
        'Sint-Andries',
        'Rio Verde de Mato Grosso',
        'Ellamanda',
        'Khizrpur',
        'Kolavallúr',
        'Makurazaki',
        'Spennymoor',
        'Huntsville',
        'Broadview Heights',
        'Pradópolis',
        'Dillingen',
        'Jāmbai',
        'Atlapexco',
        'Albertville',
        'Lincolnia',
        'Garforth',
        'Alamo',
        'Dokolo',
        'Lisieux',
        'Sleaford',
        'Anghad',
        'Mannūr',
        'Kochas',
        'Kāmākhyānagar',
        'Torgau',
        'Ban Na San',
        'Hirokawa',
        'Remígio',
        'Universal City',
        'Sainte-Marthe-sur-le-Lac',
        'Agryz',
        'Stoke Gifford',
        'Wilnsdorf',
        'Kannānkurichchi',
        'Itaparica',
        'Rubiataba',
        'Nosy Varika',
        'Çekerek',
        'Puttūr',
        'Nyandoma',
        'Araripe',
        'Holden',
        'Poperinge',
        'Time',
        'Mosopa',
        'Smørumnedre',
        'Sparta',
        'Oulad Embarek',
        'Massy',
        'Vulcan',
        'Malabuyoc',
        'Cheung Chau',
        'Brandýs nad Labem-Stará Boleslav',
        'Zhaodianzi',
        'Palm Valley',
        'Kattanam',
        'Puerto Armuelles',
        'Ghatkesar',
        'Lackawanna',
        'Nogales',
        'Selby',
        'Bishunpur',
        'Ennigerloh',
        'Ryūyō',
        'San Miguel',
        'Oulad Fraj',
        'Xingangli',
        'Simonésia',
        'Lake Zurich',
        'Prudnik',
        'Poshkent',
        'Três Barras',
        'Pattamadai',
        'Calvillo',
        'Os',
        'Wittlich',
        'Lloydminster',
        'Opoczno',
        'Itatinga',
        'Assebroek',
        'Thimiri',
        'Beldaur',
        'Marsberg',
        'Cherakhera',
        'Mengdan',
        'Sarstedt',
        'Yonabaru',
        'Issaba',
        'Kanekallu',
        'Evergreen Park',
        'Crisópolis',
        'Moineşti',
        'Bachi-Yurt',
        'Yosano',
        'Claremore',
        'Viadana',
        'Nittedal',
        'Pećinci',
        'West Whiteland',
        'Cuité',
        'Hongshui',
        'Gröbenzell',
        'Shinjō',
        'Saavedra',
        'Castelo do Piauí',
        'Pichidegua',
        'Río Segundo',
        'Payimattam',
        'Mayen',
        'Echizen',
        'Schenefeld',
        'Jnane Bouih',
        'Alga',
        'Oschersleben',
        'Beltsville',
        'Kinston',
        'Bückeburg',
        'Stamford',
        'Rybnoye',
        'Babanūsah',
        'Lehāra',
        'South El Monte',
        'Suárez',
        'Colotlán',
        'San Salvo',
        'Taketa',
        'Jimaguayú',
        'Dicholi',
        'Harmanli',
        'Mokēri',
        'Buşrá ash Shām',
        'Silla',
        'Kātpādi',
        'Kanniyākumāri',
        'Shelbyville',
        'Calatayud',
        'Mougins',
        'Nambūru',
        'Orós',
        'Whitemarsh',
        'Sinacaban',
        'Maḩmūdābād Nemūneh',
        'Hajnówka',
        'Guantiankan',
        'Udalguri',
        'Korsholm',
        'Pastores',
        'Būdipuram',
        'Westmount',
        'Andaingo Gara',
        'Chiconcuac',
        'Pichor',
        'Buriti dos Lopes',
        'Komárom',
        'Silver Spring',
        'Bruz',
        'Durağan',
        'Yuanhucun',
        'Grafton',
        'Chipiona',
        'Karuizawa',
        'Kondūr',
        'Nové Mesto nad Váhom',
        'Villa Elisa',
        'Luénoufla',
        'Dayr Abū Ḩinnis',
        'Davyhulme',
        'Villabate',
        'Monroe',
        'Ahfir',
        'Nilakkottai',
        'Urumita',
        'Hanumana',
        'Rāwah',
        'Jussara',
        'Várpalota',
        'Haiger',
        'Bir Kasdali',
        'Ampère',
        'Forest Park',
        'Atasū',
        'Otjiwarongo',
        'Yahotyn',
        'Bad Aibling',
        'Arvin',
        'Rāmjībanpur',
        'Cherepanovo',
        'Lerdo de Tejada',
        'Okhmalynka',
        'Punta Gorda',
        'Bad Pyrmont',
        'Mayahaura',
        'El Coco',
        'Ilijaš',
        'Veroli',
        'Niiyama',
        'Brigham City',
        'Bondo',
        'Kutina',
        'San Juan Zitlaltepec',
        'Petrila',
        'Diinsoor',
        'Sādpur',
        'Tapauá',
        'Ischia',
        'Russell',
        'Turnu Măgurele',
        'Holtsville',
        'Xapuri',
        'Lieto',
        'Rhede',
        'Lexington',
        'Yershov',
        'Buritirama',
        'Lastra a Signa',
        'Hidalgotitlán',
        'Visaginas',
        'Venadillo',
        'Schilde',
        'Engelskirchen',
        'Liuliang',
        'Les Coteaux',
        'Bourg-lès-Valence',
        'Bequimão',
        'Jājarm',
        'Punjai Puliyampatti',
        'Caraúbas',
        'Erlanger',
        'Burnham-on-Sea',
        'Ituzaingó',
        'Barañáin',
        'Heinola',
        'Caoayan',
        'Abbots Langley',
        'Santa Teresita',
        'Suchiapa',
        'Amorebieta',
        'Aysha',
        'Atchoupa',
        'Wenden',
        'Doujing',
        'Mirfield',
        'Acıgöl',
        'Gallipoli',
        'Ansermanuevo',
        'Ballyfermot',
        'Vallam',
        'Dudinka',
        'Morwa',
        'Rizal',
        'Mogiyon',
        'Töle Bī',
        'Hermosa Beach',
        'Caraí',
        'Thamaga',
        'Huseni',
        'Pathrajolhania',
        'Tīrthahalli',
        'Guapó',
        'Jhalidā',
        'Artik',
        'Beloyarskiy',
        'Mihona',
        'Mārahra',
        'Liuguang',
        'Herceg Novi',
        'Crespo',
        'Bagaces',
        'Artik',
        'Rajaudha',
        'Eersel',
        'Eupen',
        'Candelaria',
        'Ipanema',
        'Pauini',
        'Hoxut',
        'Ibigawa',
        'Goole',
        'Bedelē',
        'Parsāhi Sirsia',
        'Arese',
        'Fukude',
        'Yusufeli',
        'Alaca',
        'Kalayapuram',
        'Āmta',
        'Telavi',
        'Mahināwān',
        'Tibba',
        'East Massapequa',
        'Akanavāritota',
        'Āsasa',
        'West Mifflin',
        'Castellana Grotte',
        'Garsfontein',
        'Ait Bousarane',
        'Ait Bouziyane',
        'São Vicente Ferrer',
        'Sjöbo',
        'Sangasso',
        'Hushihacun',
        'Terek',
        'Los Álamos',
        'El Arahal',
        'Teminabuan',
        'Oulad Salmane',
        'Katangi',
        'Callosa de Segura',
        'San Juan de Dios',
        'Hoveyzeh',
        'Deneysville',
        'Nīlgiri',
        'Rhar el Melah',
        'Capinota',
        'Orinda',
        'Albolote',
        'Cugir',
        'Eschwege',
        'Santiago',
        'Saray',
        'Borna',
        'Jesús María',
        'Mölln',
        'Müllheim',
        'Jagalūr',
        'Krishnapur',
        'Orvieto',
        'Lodeynoye Pole',
        'Hawthorne',
        'Sondiha',
        'Gogogogo',
        'Qorasuv',
        'Polonuevo',
        'Setlagode',
        'Rāmchandrapur',
        'Teghra English',
        'Angleton',
        'Narsāpur',
        'Cervantes',
        'Clifton',
        'Marihatag',
        'Kempston',
        'Hechingen',
        'Kemi',
        'Ronchin',
        'Lake Forest',
        'Sabaudia',
        'Madisonville',
        'Kos',
        'Salmon Arm',
        'Cardonal',
        'Adel',
        'Tublay',
        'Someren',
        'Santiago Texacuangos',
        'Ban Thap Kwang',
        'Bartow',
        'Gaeta',
        'Lynn Haven',
        'Kemisē',
        'Liversedge',
        'Siruma',
        'Nova Granada',
        'Kārttigappalli',
        'Vence',
        'Kirkland',
        'Ardon',
        'Dorou',
        'Sāmalāpuram',
        'Salangaippālaiyam',
        'Abrīsham',
        'Xihuangcun',
        'Weil der Stadt',
        'Sulzbach-Rosenberg',
        'Zachary',
        'Los Bajos',
        'Frutillar Alto',
        'Sipoo',
        'Sweetwater',
        'Yandrapalle',
        'Dalanzadgad',
        'Pahsara',
        'Kantharalak',
        'Pariquera-Açu',
        'Kushiro',
        'Bad Driburg',
        'Cabildo',
        'Alto Araguaia',
        'Rayevskiy',
        'Arbaoun',
        'Annaberg-Buchholz',
        'Sartell',
        'Barro',
        'Törökszentmiklós',
        'Englewood',
        'Kalghatgi',
        'Shikrapur',
        'Charef',
        'Saint-Lô',
        'Manjathala',
        'Les Côteaux',
        'Casarano',
        'Prunedale',
        'Middelkerke',
        'Heishanzuicun',
        'Seabrook',
        'Abhayāpuri',
        'Kākdwīp',
        'Boone',
        'Giovinazzo',
        'Canto do Buriti',
        'Burghausen',
        'Tajerouine',
        'Kloten',
        'Kitatajima',
        'Gōdo',
        'Saint-Fons',
        'Pastrana',
        'Juquiá',
        'Rifādpur',
        'Mukaiengaru',
        'Saré-Yamou',
        'Buruanga',
        'Paranapanema',
        'Heishuikeng',
        'Arbatache',
        'Balta',
        'Prichard',
        'Nauen',
        'Kuttyādi',
        'Great Linford',
        'Brookfield',
        'Dudu',
        'Jämsä',
        'Napindan',
        'Ban Bueng',
        'Ban Patong',
        'Mahājerān-e Kamar',
        'Oruvadālkotta',
        'Moulins',
        'José Cardel',
        'Tadian',
        'Wassenberg',
        'Allanridge',
        'Pugo',
        'San Roque',
        'Tangbian',
        'Wolfratshausen',
        'Sorada',
        'Weesp',
        'Bieruń',
        'Cajolá',
        'Corner Brook',
        'Dashtigulho',
        'Pułtusk',
        'Presidente Médici',
        'Bholsar',
        'Ban Fang Tuen',
        'Perūru',
        'Tevāram',
        'Saint-Dié-des-Vosges',
        'Kamiichi',
        'Warrensburg',
        'Faversham',
        'Stillwater',
        'New Glasgow',
        'Moaña',
        'Naples',
        'Palera',
        'Panniperumthalai',
        'Mâncio Lima',
        'San Antonio Huista',
        'Assamannur',
        'Pohādi',
        'Tash-Kömür',
        'Pottanikād',
        'Heusenstamm',
        'Dukinfield',
        'Jirkov',
        'Monte Azul Paulista',
        'Virovitica',
        'Dorval',
        'Qandala',
        'Sélestat',
        'Marechal Taumaturgo',
        'Espiritu',
        'Kapangan',
        'Sirvār',
        'Casamassima',
        'Sukumo',
        'Twinsburg',
        'Ganyesa',
        'Sudley',
        'Dolynska',
        'Seminole',
        'Douar Ait Sidi Daoud',
        'Bouanri',
        'Bhojpur Jadīd',
        'Cutlerville',
        'Iiyama',
        'Wanluan',
        'Kirovgrad',
        'Rovato',
        'Beaconsfield',
        'Tsumeb',
        'Pitimbu',
        'Sint-Gillis-Waas',
        'Mapastepec',
        'Marchena',
        'Peterhead',
        'Ashland',
        'Indargarh',
        'Haldensleben',
        'Highland',
        'Hermiston',
        'Maitland',
        'Alzey',
        'El Viso del Alcor',
        'Kudra',
        'Bakal',
        'Ferndale',
        'Cassano d’Adda',
        'Tillabéri',
        'Balintawak',
        'Bābai',
        'Cabusao',
        'Sherrelwood',
        'Henichesk',
        'Valle Vista',
        'Tonantins',
        'Realeza',
        'Águas Formosas',
        'Gilching',
        'Dračevo',
        'Soure',
        'Söğüt',
        'Araçoiaba',
        'Quartier Morin',
        'Kamwenge',
        'Sirnia',
        'Groveland',
        'Holalkere',
        'Diadi',
        'Al Brouj',
        'Ballitoville',
        'Aberdeen',
        'Roosevelt',
        'Garot',
        'Kassama',
        'Monção',
        'Ghosāi',
        'Magitang',
        'Sühbaatar',
        'Pescia',
        'Gázi',
        'Orange',
        'Vigna di Valle',
        'Atok',
        'Springboro',
        'Amboanana',
        'Khonj',
        'Harrison',
        'Eruvatti',
        'Chetichēri',
        'Kabūdarāhang',
        'Tingloy',
        'Malgrat de Mar',
        'Brie-Comte-Robert',
        'Bandar-e Kong',
        'Ecclesall',
        'Ābomsa',
        'Jakobstad',
        'Zola Predosa',
        'Texcaltitlán',
        'Puerto Morelos',
        'Jiabong',
        'Lumphat',
        'Ystrad Mynach',
        'Andrychów',
        'Lebedyan',
        'Rangra',
        'Kale',
        'Jamiltepec',
        'Devgadh Bāriya',
        'Attapu',
        'Aradíppou',
        'Sligo',
        'Lebbeke',
        'Rurrenabaque',
        'La Corredoría',
        'Binka',
        'Khamaria',
        'Gomīshān',
        'Ermita',
        'Schkeuditz',
        'Venceslau Brás',
        'Ranst',
        'Alcoy',
        'Zerong',
        'Bad Tölz',
        'Dumra',
        'Pokaran',
        'Sibila',
        'Vera',
        'Oued el Abtal',
        'Sirpur',
        'Maumelle',
        'West Manchester',
        'Badger',
        'Maghar',
        'Tinton Falls',
        'La Crau',
        'Gioia Tauro',
        'Norton',
        'Krasnogorskiy',
        'La Paz',
        'Schönefeld',
        'Ghogardīha',
        'Rosedale',
        'Deerfield',
        'Jaicós',
        'Sidi Redouane',
        'Naantali',
        'Meise',
        'Koropí',
        'Chiari',
        'Horseheads',
        'Ambatomirahavavy',
        'Essen',
        'Blankenburg',
        'Seesen',
        'Izra',
        'Gülağaç',
        'Raita',
        'Pauktaw',
        'Vila Real de Santo António',
        'Sömmerda',
        'Tiruvādi',
        'Cocos',
        'Cedar Mill',
        'Papanduva',
        'Guaranésia',
        'Durango',
        'Senguio',
        'Pitt Meadows',
        'Biswanath Chariali',
        'Gerze',
        'Reinach',
        'Ad Dir‘īyah',
        'Schwalmtal',
        'Fukusaki',
        'Diamante',
        'Sirālkoppa',
        'Schneverdingen',
        'Pidhorodne',
        'Billinghurst',
        'Kodumba',
        'Diepenbeek',
        'Passagem Franca',
        'Iramala',
        'Pljevlja',
        'Ampanety',
        'Nikolayevsk-na-Amure',
        'Trzebinia',
        'Zaouïa Aït Ishak',
        'Eitorf',
        'Ainan',
        'Vargem Alta',
        'Contenda',
        'La Ligua',
        'Shemonaīkha',
        'Knightdale',
        'Kufstein',
        'Imouzzer Kandar',
        'Chennīrkara',
        'Māvinpalli',
        'Ban Mon Pin',
        'Ashkezar',
        'Shimokizukuri',
        'Central Point',
        'Midlothian',
        'Baden',
        'San Teodoro',
        'Martín Coronado',
        'Morauna',
        'Shāhgarh',
        'La Unión',
        'Sorgues',
        'Cerqueira César',
        'Cacahoatán',
        'Uwchlan',
        'Montemurlo',
        'Gobabis',
        'White Oak',
        'Bieruń Stary',
        'Bella Unión',
        'Shāhpur',
        'Harpur',
        'Lumberton',
        'Liberal',
        'Stord',
        'Nīlambūr',
        'Lebach',
        'Taka',
        'Frimley',
        'Chautāpal',
        'Lumbayanague',
        'Anguillara Sabazia',
        'Haslett',
        'Paillaco',
        'Molesey',
        'Tavares',
        'Niagara-on-the-Lake',
        'Saugerties',
        'Mumbwa',
        'Prestatyn',
        'Butiá',
        'Pindobaçu',
        'Villefontaine',
        'Cherán',
        'Tazishan',
        'Winchester',
        'Menzel Abderhaman',
        'Aizumisato',
        'Douar Toulal',
        'Rizal',
        'Cirencester',
        'Karlshamn',
        'Ban Bueng Phra',
        'Calne',
        'Perumkulam',
        'Mahisi',
        'Cocoa',
        'Simri',
        'Nalua',
        'Dixon',
        'Ixtlahuacán del Río',
        'Scituate',
        'São José de Piranhas',
        'Sant’Antonio Abate',
        'Pruzhany',
        'Bouansa',
        'Cunco',
        'Malanday',
        'Chinna Ganjām',
        'Murugampālaiyam',
        'La Eliana',
        'Sabanitas',
        'Gentilly',
        'Hindoria',
        'Tanbaichichō',
        'Zverevo',
        'Žatec',
        'Monkseaton',
        'Préveza',
        'Pūranpur',
        'Svay Pak',
        'Sylvania',
        'Itaosy',
        'Cumberland',
        'Lejiangxiang',
        'Cimarron Hills',
        'Guaymango',
        'Warwick',
        'Dharmsāla',
        'Bukungu',
        'Kovūr',
        'Proletarsk',
        'Taquarana',
        'Winthrop',
        'Onega',
        'Royan',
        'Tirmitine',
        'Telpaneca',
        'Rahata',
        'Point Pleasant',
        'Cardedeu',
        'Sarauli',
        'Wervik',
        'Monor',
        'Morlanwelz-Mariemont',
        'Cotija de la Paz',
        'Norwood',
        'Dankov',
        'North Valley Stream',
        'West Chester',
        'Caowotan',
        'Ferguson',
        'Dadeldhurā',
        'Santa Ana Jilotzingo',
        'Sahline',
        'Spoltore',
        'Mānjha',
        'Fond des Blancs',
        'Fayetteville',
        'Fate',
        'El Bolsón',
        'Akune',
        'Água Branca',
        'Gautier',
        'Quaregnon',
        'Pfullingen',
        'Mao',
        'Lagoa de Itaenga',
        'Ellensburg',
        'Zwijndrecht',
        'Befandriana Atsimo',
        'Anjahabe',
        'Ambalanirana',
        'Ambatomena',
        'Mitsinjo',
        'Belamoty',
        'Vohimasy',
        'Ampataka',
        'Ifanirea',
        'Tanambe',
        'Ambodinonoka',
        'Miarinavaratra',
        'Ambatosia',
        'Mahazoarivo',
        'Manantenina',
        'Bezaha',
        'Ranomena',
        'Nootdorp',
        'Bajo Boquete',
        'Shovot',
        'Dūrpalli',
        'Vitré',
        'Greenfield',
        'Cafelândia',
        'Vista Hermosa de Negrete',
        'Kouloum',
        'Haukipudas',
        'Sārangpur',
        'Druten',
        'Chapada dos Guimarães',
        'Bīrpur',
        'Heredia',
        'Kosatarosh',
        'Barão do Grajaú',
        'Nārapala',
        'Sukhāsan',
        'Scorzè',
        'Radeberg',
        'Laukaa',
        'Kāveripatnam',
        'Onex',
        'Ait Ben Daoudi',
        'Caconde',
        'Boiro',
        'Siripur',
        'Koliakkod',
        'Weirton',
        'La Nucía',
        'Sarso',
        'Burscheid',
        'Acqui Terme',
        'Varidhanam',
        'Ban Na Yang',
        'Colwood',
        'Konstantynów Łódzki',
        'Chichiriviche',
        'Southbourne',
        'El Rosal',
        'Saint Peter Port',
        'Carei',
        'Sarsāwa',
        'Krommenie',
        'Utebo',
        'Makronia',
        'Ansonia',
        'San Ignacio Cerro Gordo',
        'Chilkūru',
        'Križevci',
        'Fox Crossing',
        'Pitseng',
        'Atacames',
        'Pinole',
        'Kolbermoor',
        'El Malah',
        'San Gabriel',
        'Tak Bai',
        'Guru Har Sahāi',
        'Motomachi',
        'Beni Zouli',
        'Aikaranad',
        'Augusta',
        'Neviges',
        'Casalgrande',
        'Malacky',
        'Lakhnādon',
        'Harrisburg',
        'Ayvacık',
        'Middlesex Centre',
        'Sudbury',
        'Desavilakku',
        'Monserrato',
        'Bad Berleburg',
        'Eiheiji',
        'Vera',
        'Nakasi',
        'Elamāttūr',
        'Zuidhorn',
        'Pirkkala',
        'Āb Pakhsh',
        'Prenzlau',
        'Ismailpur',
        'Kōtekāra',
        'Ranbīrsinghpura',
        'Tadó',
        'Lagoa Formosa',
        'Franklin Farm',
        'Sironko',
        'Beshkent Shahri',
        'Shirin',
        'Fakfak',
        'Mo i Rana',
        'Matteson',
        'Utazu',
        'Horsforth',
        'Somandepalle',
        'Nayāgarh',
        'Bad Reichenhall',
        'Gātāda',
        'Garðabær',
        'Madalag',
        'Ribeira Grande',
        'Amares',
        'Nanjanād',
        'San Carlos Park',
        'Guareí',
        'Ezhamkulam',
        'Sugaon',
        'Kasterlee',
        'Náousa',
        'Formoso do Araguaia',
        'Jaggampeta',
        'Pozzallo',
        'Hūn',
        'Lagawe',
        'Korsimoro',
        'Ấp Khánh Hưng',
        'Signa',
        'Malazgirt',
        'Ajacuba',
        'Massaguet',
        'Kalmthout',
        'Lake Shore',
        'Coronado',
        'Groesbeek',
        'Hemmingen',
        'Altamira',
        'Kabbūr',
        'Vīrapāndi',
        'Vārapatti',
        'Arita',
        'Alsip',
        'San Sebastián de Buenavista',
        'Araç',
        'Kottakota',
        'Duero',
        'Conceição',
        'Mont-Saint-Hilaire',
        'Naaldwijk',
        'Traiskirchen',
        'Kovylkino',
        'Cinco Ranch',
        'Weißenburg',
        'Larne',
        'Malimono',
        'Horizontina',
        'North Myrtle Beach',
        'Anzoátegui',
        'Fihaonana',
        'Guachucal',
        'Kara-Köl',
        'Oirschot',
        'Varzelândia',
        'Santa Fe Springs',
        'Zahana',
        'Dukli',
        'Ben ’Aknoûn',
        'Ronkonkoma',
        'Chełmno',
        'Nakhl-e Taqī',
        'Onalaska',
        'Acri',
        'Tradate',
        'Lakeway',
        'Kalach',
        'Cusano Milanino',
        'Bradfordville',
        'Barcelos',
        'Qitai',
        'Dzüünharaa',
        'Alzenau in Unterfranken',
        'Las Terrenas',
        'Edam',
        'Nunuñgan',
        'Khetia',
        'Somerset East',
        'São José da Coroa Grande',
        'Republic',
        'Camarate',
        'Berkovitsa',
        'Odžak',
        'Carlos Spegazzini',
        'Brownwood',
        'Milton',
        'Villa Nueva',
        'Bad Dürkheim',
        'Sebaste',
        'Ascención de Guarayos',
        'Lancing',
        'Sabalpur',
        'Marktoberdorf',
        'Vélez',
        'Purushottampur',
        'Sheridan',
        'Syosset',
        'Kalabahi',
        'Udumanthala',
        'Huehuetla',
        'Pastos Bons',
        'Sortavala',
        'Shimomura',
        'Abdurahmoni Jomí',
        'Dŭstí',
        'Hazorasp',
        'Diallassagou',
        'Ware',
        'Xinhua',
        'Hala',
        'Starobilsk',
        'East St. Louis',
        'Marshfield',
        'Owego',
        'Bremervörde',
        'Arilje',
        'Ibirataia',
        'Tocaima',
        'Lukovit',
        'Oulmes',
        'Taft',
        'Jwaneng',
        'Wisconsin Rapids',
        'Terra Santa',
        'Itajuípe',
        'Sompeta',
        'Ban Bo Haeo',
        'Heerde',
        'Springfield',
        'Antotohazo',
        'Lgov',
        'Pastavy',
        'Chowchilla',
        'Camrose',
        'Kabala',
        'Kralupy nad Vltavou',
        'Złotów',
        'Marānchi',
        'Presidente Olegário',
        'Villaquilambre',
        'Doñihue',
        'Novyy Oskol',
        'Karia Ba Mohamed',
        'Villa Rica',
        'Ayyāmpettai',
        'Durant',
        'Birnagar',
        'Bergeijk',
        'Retiro',
        'Rutherford',
        'Hopkins',
        'San Juan Ermita',
        'Nanthankulam',
        'Bonneuil-sur-Marne',
        'Kurumbapālaiyam',
        'Hopkinton',
        'Koporo-Kénié-na',
        'Kiliia',
        'Bayshore Gardens',
        'Thomasville',
        'Ngathainggyaung',
        'Palestine',
        'Harihans',
        'Altônia',
        'Wallingford Center',
        'Melmuri',
        'Riom',
        'Los Lagos',
        'San Rafael La Independencia',
        'Adliswil',
        'Schlieren',
        'Sendamaram',
        'Paglat',
        'Rufino',
        'Lansdale',
        'Gunri',
        'Görele',
        'Alfeld',
        'Phoenixville',
        'Ozorków',
        'Country Walk',
        'South San Jose Hills',
        'Tlalnelhuayocan',
        'Ključ',
        'Hem',
        'Snina',
        'Altus',
        'Ottawa',
        'Dhāriwāl',
        'Aroeiras',
        'Salamina',
        'Djidian',
        'Vadakkangara',
        'Choghādak',
        'Peduasi',
        'Aburi',
        'Qo’shko’pir',
        'Abong Mbang',
        'Lupeni',
        'Tyèlè',
        'Tsivory',
        'Balilihan',
        'Çerkeş',
        'Qaryat al Qī‘ān',
        'Valle',
        'Nefas Mewch’a',
        'Perunturuttu',
        'Andéranboukan',
        'Majdanpek',
        'Areia Branca',
        'Surovikino',
        'Middelburg',
        'Ceglie Messapico',
        'Zapote',
        'Creve Coeur',
        'Leicester',
        'Morales',
        'Martinsburg',
        'Round Lake',
        'Kinzau-Vuete',
        'Doura',
        'Blooming Grove',
        'Togba',
        'Afrânio',
        'Bensenville',
        'São Raimundo das Mangabeiras',
        'Mansa Konko',
        'Trinity',
        'Sonhauli',
        'Lakhna',
        'Almel',
        'Rishivandiyam',
        'Lelydorp',
        'Meerssen',
        'Jasidih',
        'Broughty Ferry',
        'Şammā',
        'Seagoville',
        'Kavalerovo',
        'Siyəzən',
        'Maimón',
        'Soalkuchi',
        'Molakālumuru',
        'Centerton',
        'Santa Catarina Juquila',
        'Selwyn',
        'Cambridge',
        'Elumalai',
        'San Julián',
        'St. Michael',
        'Lauālāgaon',
        'Carnaíba',
        'Kangazha',
        'Shimanovsk',
        'Telwa',
        'Adria',
        'Castaic',
        'Aldama',
        'Futog',
        'Szamotuły',
        'Basatpur',
        'Mūkkanūr',
        'Enkhuizen',
        'Ashland',
        'Bonanza',
        'Bergneustadt',
        'Kīlmangalam',
        'Mānikkal',
        'Le Puy-en-Velay',
        'Lede',
        'Mossendjo',
        'Ribeirão Branco',
        'Chākūr',
        'Manakayi',
        'Ubaíra',
        'Urucará',
        'Sanharó',
        'Tarifa',
        'Ballincollig',
        'Londerzeel',
        'Seara',
        'Knik-Fairview',
        'Vanino',
        'Uetersen',
        'Medjedel',
        'Carlos Chagas',
        'Volketswil',
        'Tillsonburg',
        'Eastwood',
        'Fairwood',
        'Tata',
        'Engenheiro Coelho',
        'Maurepas',
        'Bellwood',
        'East Dereham',
        'Onga',
        'Mullach Íde',
        'Stannington',
        'Reriutaba',
        'Concord',
        'Corinto',
        'Ajnāla',
        'Leposaviq',
        'Saylac',
        'Toshloq',
        'São Gabriel',
        'San Miguel',
        'Miyatoko',
        'Timoktene',
        'Los Alcázares',
        'Renningen',
        'Kalugumalai',
        'Jbabra',
        'Antombana',
        'Felanitx',
        'Five Forks',
        'Ploemeur',
        'Preakness',
        'Majhua',
        'Valavanūr',
        'Al Jawf',
        'Lebanon',
        'Anna',
        'Hatfield',
        'Jalakandāpuram',
        'San Mauro Torinese',
        'Colonia',
        'Skara',
        'Windham',
        'Shika',
        'Rhynern',
        'Tamri',
        'Mohana',
        'Liuma',
        'Clive',
        'Liancourt',
        'Narangba',
        'Meylan',
        'Kasongan',
        'Elizabeth City',
        'Nederland',
        'Bougtob',
        'Tomaszów Lubelski',
        'Crimmitschau',
        'Paluan',
        'San Sebastián Salitrillo',
        'Miracema do Tocantins',
        'Ban Mae Hia Nai',
        'Erumakkuzhi',
        'Zhosaly',
        'Kingsland',
        'Pizarro',
        'Tarrafal',
        'Bhadaur',
        'Shenandoah',
        'Ebbw Vale',
        'Garuva',
        'Boom',
        'Mingjiujie',
        'Leteri',
        'Wheatfield',
        'Morros',
        'Zākīyah',
        'Miracatu',
        'Priozërsk',
        'Lessines',
        'Matipó',
        'Kumbhrāj',
        'Mennzel Bou Zelfa',
        'Mellacheruvu',
        'Berea',
        'Shaogang',
        'Glinde',
        'Ban Mae Kha Tai',
        'Ferguson',
        'Sebt Gzoula',
        'Orhaneli',
        'Hochheim am Main',
        'Kothia',
        'Morro da Fumaça',
        'Kulu',
        'Arcata',
        'Colina',
        'Goyty',
        'Baena',
        'San Diego',
        'Stabroek',
        'Santander',
        'Guadix',
        'Newton',
        'Parole',
        'Sokołów Podlaski',
        'Ba',
        'Rohār',
        'Melville',
        'Oxon Hill',
        'Asunción Nochixtlán',
        'Thalwil',
        'Lodhwe',
        'Tsetserleg',
        'Tessenderlo',
        'Guanzhai',
        'Santa Cruz Zenzontepec',
        'Bilar',
        'Iselin',
        'Brook Park',
        'Härnösand',
        'Galhinna',
        'Huércal de Almería',
        'St. Marys',
        'Tādikonda',
        'Madre de Deus',
        'Anicuns',
        'Tatsuno',
        'Pālaiyampatti',
        'Hässleholm',
        'Buikwe',
        'Appley Bridge',
        'Goffstown',
        'Rawmarsh',
        'Ghedi',
        'Kivsharivka',
        'Potirendaba',
        'Bitkine',
        'Rhaude',
        'Vlotho',
        'Karcag',
        'Misato',
        'Alton',
        'Kalynivka',
        'Chubek',
        'San Lorenzo de El Escorial',
        'Busovača',
        'Schwalmstadt',
        'Staines-upon-Thames',
        'Unquillo',
        'Zagarolo',
        'Piñas',
        'Laurel',
        'San Celoni',
        'Sirvel',
        'Fushë-Krujë',
        'Kherāmeh',
        'Ajaigarh',
        'Foxborough',
        'Tinoc',
        'Giszowiec',
        'Boerne',
        'Vellmar',
        'Tirmalgiri',
        'Qaşr-e Shīrīn',
        'Sharon',
        'Bedlington',
        'Sacavém',
        'Hoppegarten',
        'Éragny',
        'Alcântara',
        'Taft',
        'La Marque',
        'Çatak',
        'Castel Maggiore',
        'Kotanchēri',
        'Kunitomi',
        'Nara',
        'Pio IX',
        'Kannapuram',
        'Oskū',
        'Hessisch Oldendorf',
        'Lagoa da Canoa',
        'Od',
        'Kāriyāpatti',
        'Mill Hill',
        'Matelândia',
        'Cognac',
        'Ngolonianasso',
        'Shimokodanaka',
        'Narlıca',
        'Coalcomán de Vázquez Pallares',
        'Haaltert',
        'Kassaro',
        'Jucuapa',
        'Achocalla',
        'Bakhmach',
        'Princeton',
        'Bönen',
        'Hellemmes-Lille',
        'Chouafa',
        'Zhangshicun',
        'Choi Hung',
        'Arroyo Grande',
        'Astrea',
        'Albert Lea',
        'Cullman',
        'Baliangao',
        'Yur’yev-Pol’skiy',
        'Lahnstein',
        'Dumjor',
        'Ponedera',
        'Johnstown',
        'Orestiáda',
        'Dover',
        'Juvisy-sur-Orge',
        'New River',
        'Abdulino',
        'Mukkūdal',
        'Trenton',
        'Loreto',
        'Vechelde',
        'Montrouis',
        'Pomáz',
        'Castelfidardo',
        'Kreminna',
        'Svatove',
        'San Vicente Pacaya',
        'Ocean Springs',
        'Fatick',
        'Kronberg',
        'Devadānappatti',
        'Basse Santa Su',
        'Unhel',
        'Winder',
        'Hardinxveld-Giessendam',
        'Amvrosiivka',
        'Foum el Anser',
        'Belo Campo',
        'Tallmadge',
        'San Blas Atempa',
        'Tilothu',
        'Nīār',
        'Konz',
        'Nordestina',
        'Fairmont',
        'Korsun-Shevchenkivskyi',
        'Quitandinha',
        'Kumārapuram',
        'Santa Marinella',
        'Langen',
        'Srikhanda',
        'North Babylon',
        'Inhapi',
        'Crowley',
        'North Bay Shore',
        'Aragarças',
        'Hārij',
        'Tinajeros',
        'Longjia',
        'Rio Maria',
        'Bhawānīpur',
        'Itapororoca',
        'Hampton',
        'Sanpaicun',
        'Bracciano',
        'East Goshen',
        'Straşeni',
        'Walcourt',
        'Greene',
        'Cesário Lange',
        'Sigmaringen',
        'Franklin Park',
        'Cuautitlán',
        'Clonmel',
        'Qatlūpur',
        'Illertissen',
        'Mumford',
        'Assa',
        'Filomeno Mata',
        'Chennevières-sur-Marne',
        'Agarpur',
        'Nytva',
        'Baependi',
        'Santiago Ixcuintla',
        'São Benedito do Rio Preto',
        'El Tránsito',
        'Olten',
        'Écully',
        'Noci',
        'Chhoti Sādri',
        'Krasyliv',
        'Newburyport',
        'Campo de la Cruz',
        'Stekene',
        'San Rafael Pie de la Cuesta',
        'Cagdianao',
        'Wörth am Rhein',
        'Louviers',
        'Saladas',
        'Ammāpettai',
        'Almondbury',
        'Redland',
        'Nāri Bhadaun',
        'Maryland City',
        'Cortes',
        'Buenavista',
        'Apastepeque',
        'Sendārappatti',
        'Capoeiras',
        'Mineros',
        'Lake Ronkonkoma',
        'Mirante do Paranapanema',
        'Daryābād',
        'Lamrasla',
        'Xintian',
        'West Haven',
        'Picuí',
        'Anandpur',
        'Rizal',
        'Novopavlovka',
        'Cran-Gévrier',
        'Pembroke',
        'Memuro-minami',
        'Sandaré',
        'Shaoyu',
        'Nuuk',
        'Skýdra',
        'Calverton',
        'Karuppur',
        'Tha Bo',
        'Taió',
        'Melzo',
        'San José Tenango',
        'Lora del Río',
        'Carrières-sous-Poissy',
        'Talata-Volonondry',
        'Riachão do Dantas',
        'Pully',
        'Pontalina',
        'Morretes',
        'Gerd Farāmarz Shāhedīyeh',
        'Abrandābād-e Shāhedīyeh',
        'Kitagata',
        'Nossombougou',
        'Nehbandān',
        'Mangualde',
        'Belém de São Francisco',
        'Lenoir',
        'Do’stlik Shahri',
        'Hadleigh',
        'Beloozërskiy',
        'Sint-Genesius-Rode',
        'Ommen',
        'Volochysk',
        'Püttlingen',
        'Bingley',
        'Rutigliano',
        'Aveiro',
        'Taung',
        'Slyudyanka',
        'Szigethalom',
        'Sakouéba',
        'Navraftor',
        'Şenkaya',
        'Panama City Beach',
        'Pinecrest',
        'Parchim',
        'Palos Hills',
        'Fomboni',
        'Santa Fe',
        'Kadaň',
        'Paraibano',
        'Rāmpur',
        'Kristiansund',
        'Mannara',
        'Rosendaël',
        'Phelan',
        'Aït Bouchta',
        'Bni Bouayach',
        'Tárrega',
        'Kasba Maker',
        'Somerset',
        'Concord',
        'Burlington',
        'Paraibuna',
        'Yupiltepeque',
        'Siloe',
        'Bideford',
        'Luz',
        'Ban Mai',
        'Monte Cristo',
        'Regensdorf',
        'Yoichi',
        'Mūlanūr',
        'Shuangluan',
        'South Orange Village',
        'Bagnols-sur-Cèze',
        'Moissy-Cramayel',
        'Staveley',
        'Asakapalle',
        'Mathurāpur',
        'Pisz',
        'Shaw',
        'Amsterdam',
        'Savaştepe',
        'Nārāyankher',
        'Monforte de Lemos',
        'Sivandipuram',
        'Santa Iria da Azóia',
        'Manchester',
        'Carrigaline',
        'Comodoro',
        'Kānp',
        'Ikniwn',
        'Calera de Tango',
        'Łęczna',
        'Centralia',
        'Fulwood',
        'Antanambao',
        'Ocean Acres',
        'Eski-Nookat',
        'Kingstowne',
        'Kottukal',
        'Júlio de Castilhos',
        'Manga',
        'Budrio',
        'Galaat el Andeless',
        'Mahiari',
        'Bad Segeberg',
        'Kurakhove',
        'Baixa Grande',
        'El Astillero',
        'Pieksämäki',
        'Bronte',
        'Pijijiapan',
        'Zielonka',
        'Ţāqah',
        'Banī Ḩasan ash Shurūq',
        'Nazaré Paulista',
        'Farias Brito',
        'Bettioua',
        'Taku',
        'Gameleira',
        'Sipacate',
        'Stillorgan',
        'Tirunāgeswaram',
        'Sāhna',
        'Filadelfia',
        'Sikhio',
        'Blindio',
        'Serrita',
        'Dagana',
        'Amaraji',
        'Kudatini',
        'Budhni',
        'Kishi',
        'Shaying',
        'Końskie',
        'Santiago de María',
        'Bet She’an',
        'North Massapequa',
        'Kiangara',
        'Ranquitte',
        'Griffith',
        'Palamós',
        'San Sebastián Tutla',
        'Udaipura',
        'Pelham',
        'Plymouth',
        'Washington',
        'Bedēsa',
        'Sarvestān',
        'Taufkirchen',
        '‘Anbarābād',
        'Rikuzen-Takata',
        'Oyama',
        'Concord',
        'Solonópole',
        'Dapi',
        'Oconomowoc',
        'Quibaxi',
        'Aldo Bonzi',
        'Mohiuddinnagar',
        'Steubenville',
        'South Fayette',
        'Warminster',
        'Gossau',
        'Sycamore',
        'Vredendal',
        'Logansport',
        'Bhadrapur',
        'Meishan',
        'Tummalapenta',
        'Redhill',
        'Laives',
        'Åsane',
        'Kottapalle',
        'Corbetta',
        'Petawawa',
        'Colonial Heights',
        'Xochiatipan de Castillo',
        'Zinvié',
        'Starodub',
        'Qishe',
        'Guayama',
        'El Achir',
        'Heckmondwike',
        'Bouarouss',
        'Lavāsān',
        'Ingá',
        'Trujillo',
        'Piatykhatky',
        'Čitluk',
        'Keskin',
        'Heusweiler',
        'Minamishibetsuchō',
        'Dobrush',
        'Pinabacdao',
        'Yoshimi',
        'Schofield Barracks',
        'Palanga',
        'Andalucía',
        'Una',
        'Tominian',
        'Gangwuzhen',
        'Portsmouth',
        'Svilengrad',
        'Beerse',
        'San Pedro',
        'Sangre Grande',
        'Canby',
        'Dumri',
        'Jora Khurd',
        'New Castle',
        'Bourbonnais',
        'Menasha',
        'Łomianki',
        'Ouankoro',
        'Louny',
        'Ciriè',
        'Pottanūr',
        'Feyẕābād',
        'Terra Roxa d’Oeste',
        'Barhampur',
        'Ambiula',
        'Raisāri',
        'Coronda',
        'Poulton le Fylde',
        'Rāmpur',
        'Convención',
        'Polohy',
        'Gāndarbal',
        'Fâches-Thumesnil',
        'Mānikpur',
        'McAlester',
        'Caldas de Montbuy',
        'Pahārpur',
        'Shorewood',
        'Campos Belos',
        'Mure',
        'Niles',
        'White Settlement',
        'Bình Minh',
        'Varjota',
        'Fort Carson',
        'Maoussa',
        'Hybla Valley',
        'Pavullo nel Frignano',
        'San Vito dei Normanni',
        'Großenhain',
        'Borgo San Lorenzo',
        'Kikube',
        'Berkhampstead',
        'Cofradía',
        'Belterra',
        'Dolný Kubín',
        'Southeast',
        'Tora',
        'Chapelle',
        'Antonina',
        'General Juan Madariaga',
        'Madalena',
        'Heguri',
        'Cudahy',
        'Calenzano',
        'Barīkot',
        'Ghouazi',
        'Água Azul do Norte',
        'Kopa',
        'East Riverdale',
        'Arājpur',
        'Wandsworth',
        'Mayorga',
        'Bobingen',
        'Kęty',
        'Ricaurte',
        'Manyas',
        'Pedra Preta',
        'São Tiago de Custoias',
        'Oroszlány',
        'Fuente-Álamo de Murcia',
        'Luganville',
        'Ismaning',
        'Tottiyam',
        'Scarsdale',
        'Bīdestān',
        'Kottá Kalidindi',
        'Santa Ana',
        'Sidi Allal Tazi',
        'Tlacoachistlahuaca',
        'Ambāla',
        'Libjo',
        'Myślenice',
        'San Vicente',
        'Bādkulla',
        'Spanish Lake',
        'Middelharnis',
        'Annakunnu',
        'Vilavūr',
        'Buadiposo-Buntong',
        'Nigrán',
        'Chodzież',
        'Taperoá',
        'Timri',
        'Whitestown',
        'Carmelo',
        'Sunbury',
        'Mwanza',
        'Abangaritos',
        'Frankenberg',
        'Bryn Mawr-Skyway',
        'Keszthely',
        'Târgu Neamţ',
        'Cristinápolis',
        'San Pedro',
        'Porto Novo',
        'Schwechat',
        'Destelbergen',
        'Gidi',
        'Valenza',
        'Piossasco',
        'Zuhres',
        'Matigou',
        'Koratagere',
        'Lakshmaneswaram',
        'Tordera',
        'Ulao',
        'Banbalah',
        'Buchen in Odenwald',
        'Tillmans Corner',
        'Fécamp',
        'Itaberá',
        'Jasien',
        'Fundão',
        'Dumont',
        'Campina Verde',
        'Toyono',
        'Marcos',
        'Marsciano',
        'Rwamagana',
        'Nipomo',
        'Soisy-sous-Montmorency',
        'Biro',
        'Ashtabula',
        'Sakri',
        'Terrell',
        'Dbaïyé',
        'Antsampanimahazo',
        'Antanambe',
        'Manampatrana',
        'Alakamisy',
        'Maroteza',
        'Bemahatazana-Belobaka',
        'Vohilengo',
        'Amboahangibe',
        'Mahazoma',
        'Ambongo',
        'Alatsinainy Ialamarina',
        'Analaiva',
        'Maroaloka',
        'Belobaka',
        'Ankofa',
        'Matsakabanja',
        'Ambovombe Afovoany',
        'Tsianisiha',
        'Beantake',
        'Behenjy',
        'Tranoroa',
        'Qal‘ah-ye Zāl',
        'General Deheza',
        'Al Hamalah',
        'Ad Dirāz',
        'Doruma',
        'Yoboki',
        'Mickleover',
        'Gondar',
        'Tankāra',
        'Ghogaon',
        'Mahraurh',
        'Tall Qaşab',
        'Athār',
        'Gorham',
        'Kamienna Góra',
        'Batié',
        'Kolonodale',
        'Serra Preta',
        'Bombon',
        'Tyāgadurgam',
        'Aci Sant’Antonio',
        'Cameron Park',
        'Wantagh',
        'Stony Plain',
        'Neftegorsk',
        'Oak Bay',
        'Hijuelas',
        'San Cristóbal Cucho',
        'Ariccia',
        'Mount Washington',
        'Sevierville',
        'Kosvik',
        'Lagoa do Carro',
        'Bourne',
        'Cuquío',
        'Sivagiri',
        'Hranice',
        'Halstenbek',
        'Sukhsena',
        'Rāmpur Tilak',
        'Rājmahal',
        'Terenure',
        'Avtury',
        'Kharabali',
        'Konaklı',
        'Dīzīcheh',
        'Antenor Navarro',
        'Melegnano',
        'North Grenville',
        'Sapeaçu',
        'Santo Anastácio',
        'Kegalle',
        'Chaita',
        'Souba',
        'Payyannūr',
        'Cohoes',
        'Ptuj',
        'Towamencin',
        'Jadcherla',
        'Torit',
        'Bodegraven',
        'Almeria',
        'Ranzan',
        'Koula',
        'Bucay',
        'Ben Daoud',
        'Erumāpālaiyam',
        'Barvāla',
        'Haren',
        'Blythe',
        'Mūkondapalli',
        'Doylestown',
        'Flora',
        'Loyalist',
        'Stilfontein',
        'Yeddumailāram',
        'Falköping',
        'Bischheim',
        'Miyauchi',
        'Madeley',
        'Jiadong',
        'Agliana',
        'Kuchinda',
        'Quipapá',
        'Tres de Mayo',
        'Manthani',
        'Burke Centre',
        'Cortes',
        'Texistepeque',
        'Ammon',
        'Gitagum',
        'Palmi',
        'Olho d’Água das Cunhãs',
        'Coltauco',
        'Kapsabet',
        'Shirosato',
        'Alice',
        'Unión de San Antonio',
        'Roulia',
        'Deux-Montagnes',
        'Ituaçu',
        'Narni',
        'Pelaya',
        'Pornic',
        'Mabéhiri',
        'Châteauneuf-les-Martigues',
        'Khokha',
        'Natchitoches',
        'Franconia',
        'Wadowice',
        'Glenvar Heights',
        'Manambaro',
        'Saatlı',
        'Nyborg',
        'Kostrzyn nad Odrą',
        'Mebane',
        'Não-Me-Toque',
        'Garches',
        'Nguigmi',
        'Filadélfia',
        'Fátima',
        'Tiffin',
        'Zapotlán del Rey',
        'Sint-Oedenrode',
        'Murambi',
        'Silvāni',
        'Pudunagaram',
        'Anoka',
        'Puquio',
        'Bafanji',
        'Sanghera',
        'Veldurti',
        'Çal',
        'Mount Eliza',
        'Yuanquan',
        'Munguía',
        'Cary',
        'Guapiara',
        'Steiner Ranch',
        'Gibsonton',
        'Carmópolis de Minas',
        'Gaoniang',
        'Santa Cruz Naranjo',
        'Vilāttikulam',
        'Selma',
        'El Bordj',
        'Ramsbottom',
        'Tolentino',
        'Patar',
        'Bay City',
        'Segué',
        'Vordingborg',
        'Sa Kaeo',
        'Pimentel',
        'Chortiátis',
        'Martūru',
        'Pachmīr',
        'Marwa',
        'Farmington',
        'San Kamphaeng',
        'Cenoví',
        'Manilva',
        'Százhalombatta',
        'Loay',
        'Hidrolândia',
        'Ourém',
        'Forst (Lausitz)',
        'Colne',
        'Sangenjo',
        'Carvin',
        'Vissannapeta',
        'Aci Castello',
        'Ambohitralanana',
        'Bekalta',
        'Shirāli',
        'Montanha',
        'Pontarlier',
        'Pôrto Grande',
        'Édessa',
        'Portsmouth',
        'Sing Buri',
        'Sidi Kada',
        'Pataskala',
        'Marabut',
        'Savignano sul Rubicone',
        'Itapiúna',
        'Kristinehamn',
        'Harper',
        'San Bartolo Tutotepec',
        'Ribera',
        'Pelahiivka',
        'Timbedgha',
        'Policoro',
        'Parede',
        'Goshaingaon',
        'Águas de Lindóia',
        'Bredene',
        'Werdohl',
        'Paks',
        'Yakacık',
        'Bhīkhi',
        'Arcore',
        'Villa Corona',
        'Mengjiacun',
        'Las Heras',
        'São Luís Gonzaga do Maranhão',
        'Carate Brianza',
        'Brownsville',
        'Blaj',
        'Freilassing',
        'Alton',
        'Kukmor',
        'Mazapil',
        'Itanhém',
        'Oberasbach',
        'Pariharpur',
        'Muttenz',
        'Steinbach',
        'Salgar',
        'Seyah Cheshmeh',
        'Santa Fe',
        'Gazantarak',
        'Fontaine-l’Évêque',
        'Feilding',
        'Dhānsāria',
        'Sobinka',
        'Herenfa',
        'Bagahi',
        'Hendaye',
        'Vennandūr',
        'San Rafael',
        'Jovellar',
        'Charleston',
        'Salaspils',
        'Delran',
        'Jucurutu',
        'Ponmana',
        'Shichigahama',
        'Santa Elena',
        'Godfrey',
        'Amīnpur',
        'San Rafael del Norte',
        'Portchester',
        'Idylwood',
        'Berlín',
        'Mankara',
        'Mooirivier',
        'Arakkapādi',
        'Cordenons',
        'Anda',
        'Khaşab',
        'Monthey',
        'Dhamaun',
        'Freudenberg',
        'North Canton',
        'Varvarin',
        'Łańcut',
        'Prachin Buri',
        'Fanipal’',
        'Tall ‘Aran',
        'Visé',
        'Somma Lombardo',
        'Ash Shaykhān',
        'Agua Blanca',
        'Lake Butler',
        'Klaeng',
        'Weilerswist',
        'Willimantic',
        'Cairu',
        'Auerbach',
        'Or ‘Aqiva',
        'Troy',
        'Lunéville',
        'Schrobenhausen',
        'Māḩiş',
        'Cahokia Heights',
        'Ostermundigen',
        'Arco',
        'North Aurora',
        'Floirac',
        'McKeesport',
        'Eslöv',
        'Dergaon',
        'Douar Oulad Mbarek',
        'Kuttālam',
        'Chitipa',
        'Köping',
        'Toba',
        'Saint-Colomban',
        'Dianópolis',
        'Dniprorudne',
        'Reni',
        'Ekazhevo',
        'Manzanares',
        'Kodiyēri',
        'Nīkshahr',
        'Liulin',
        'Laukāha',
        'Sapucaia',
        'Erjie',
        'Bad Münder am Deister',
        'Estancia Pozo Colorado',
        'Susner',
        'Békés',
        'Herzele',
        'Rockland',
        'Cesson-Sévigné',
        'Ban Mae Ngon Khilek',
        'Pirapemas',
        'Domodossola',
        'Hlybokaye',
        'Live Oak',
        'Broken Hill',
        'Dialafara',
        'Zalţan',
        'Ashton',
        'Ngora',
        'Major Isidoro',
        'Kharhiāl',
        'Miramichi',
        'Kiangan',
        'Vettavalam',
        'Vrede',
        'Miaojiaping',
        'Kalyānpur Bamaiya',
        'Kreuzau',
        'Flórina',
        'Diepholz',
        'Maripād',
        'Palanan',
        'Harwich',
        'East Lampeter',
        'Dourbali',
        'Panórama',
        'Bechem',
        'Dugo Selo',
        'Dumri',
        'Mairi',
        'Avion',
        'Webster',
        'Hope Mills',
        'Levin',
        'Torredembarra',
        'South Ockendon',
        'Moreton',
        'Pūlla',
        'Kitahiroshima',
        'Southbridge',
        'Tufanbeyli',
        'Beni Fouda',
        'Whittlesey',
        'Anacortes',
        'Duffel',
        'Brignoles',
        'Minquan',
        'Biliran',
        'El Omaria',
        'Bad Säckingen',
        'Jaguaripe',
        'Vandalūr',
        'Brenham',
        'Yumurtalık',
        'Pinehurst',
        'Tohoué',
        'Pianoro',
        'Golitsyno',
        'Tunapuna',
        'Nidda',
        'Monzón',
        'Fern Down',
        'Balaguer',
        'Tábara Arriba',
        'Fornaka',
        'Pirapora do Bom Jesus',
        'Kappiyara',
        'Fanandrana',
        'Mount Holly',
        'Bexbach',
        'Arsanjān',
        'Camano',
        'San Jose',
        'Valluvandad',
        'Vettikattiri',
        'Stowbtsy',
        'Gretna',
        'Nelidovo',
        'São Miguel do Tapuio',
        'Abaré',
        'Dingjiagouxiang',
        'Damme',
        'Stuart',
        'Jacksonville',
        'Terenos',
        'El Carmen de Chucurí',
        'Cerrillos',
        'Otrokovice',
        'Cariré',
        'Pupri',
        'Mānullahpatti',
        'Siloam Springs',
        'Woodmere',
        'Saint-Maximin-la-Sainte-Baume',
        'Inza',
        'Stegen',
        'Oborniki',
        'Ban Mae Ka Hua Thung',
        'Daimiel',
        'Asarcık',
        'Pinili',
        'La Primavera',
        'Limay',
        'Eastlake',
        'Gostynin',
        'Lobogo',
        'Longjia',
        'Nakło nad Notecią',
        'Karacasu',
        'Yayas de Viajama',
        'Colbún',
        'Benito Juárez',
        'São Francisco de Assis',
        'Vittal',
        'Ambolomadinika',
        'Sokółka',
        'San Jorge',
        'İskilip',
        'Sam Phran',
        'Totoró',
        'Omallūr',
        'Veliko Gradište',
        'Ubaitaba',
        'Zhongguyue',
        'Restrepo',
        'Barrhead',
        'Capua',
        'Chedaopo',
        'Şavşat',
        'Tijucas do Sul',
        'El Dorado',
        'Storrs',
        'Bel Air',
        'Polyarnyy',
        'Lousã',
        'New Philadelphia',
        'Pirayú',
        'Siderno Marina',
        'Raghunāthpur',
        'Bakouma',
        'Abejorral',
        'Amacuzac',
        'San Alejo',
        'Herve',
        'Boqueirão',
        'Sigus',
        'Arauco',
        'Yondó',
        'Raghunāthpur',
        'Neder-Over-Heembeek',
        'Bad Wildungen',
        'Palau',
        'Para',
        'Marcon',
        'Laatatra',
        'Srbac',
        'Manganj',
        'Putte',
        'Zamora',
        'Santos Reyes Nopala',
        'Inkollu',
        'Eldama Ravine',
        'Ajā',
        'Maesteg',
        'Saint Andrews',
        'Chakkuvarakal',
        'San Jorge',
        'Žiar nad Hronom',
        'Muḩradah',
        'Quanzhang',
        'Peruvanthānam',
        'Rāyappanpatti',
        'Zephyrhills',
        'Krishnapuram',
        'Cortland',
        'Compostela',
        'Água Branca',
        'Aït Majdane',
        'Trossingen',
        'Cerca Carvajal',
        'Boissy-Saint-Léger',
        'Catarman',
        'Alcochete',
        'Rožňava',
        'Sagrada Familia',
        'Wayne',
        'Piritiba',
        'Tak',
        'Ban Thum',
        'Sirari',
        'Barhampur',
        'Kambla',
        'Kisoro',
        'Bistāria',
        'Arroio Grande',
        'Khutāha',
        'Lemont',
        'Clemson',
        'Gömeç',
        'Pālaiyam',
        'Mawkanin',
        'Son en Breugel',
        'Penn',
        'Le Pontet',
        'Çameli',
        'Glencoe',
        'Punnayūr',
        'Colchester',
        'Morarano-Gara',
        'Waalre',
        'Lattes',
        'Aliyābād',
        'Takoma Park',
        'Koriāpatti',
        'Swellendam',
        'Yeniseysk',
        'Al ‘Ashārah',
        'Scarborough',
        'Etacheri',
        'Sona',
        'Mitake',
        'Kempele',
        'Vāyalpād',
        'Oak Grove',
        'Esperanza',
        'Shangjing',
        'Shanglingcun',
        'Harinākunda',
        'Duijiang',
        'Kirksville',
        'Esquimalt',
        'Polignano a Mare',
        'Rio Bananal',
        'Bonnyrigg',
        'Turuvekere',
        'Shirva',
        'Norcross',
        'Mistrató',
        'Dabas',
        'Attimarappatti',
        'Garching bei München',
        'Guidan Roumdji',
        'Boljoon',
        'Yeşilhisar',
        'Villorba',
        'Matagob',
        'Manīn',
        'Gunjur',
        'Krasnystaw',
        'Quedgeley',
        'Malacacheta',
        'Ivanovka',
        'Strzelce Opolskie',
        'Rocca di Papa',
        'St. Matthews',
        'Sulaco',
        'Plast',
        'Ebino',
        'Libertad',
        'Elankūr',
        'Moloacán',
        'Tenango de Doria',
        'Piratini',
        'Mendes',
        'Baxt',
        'San Antonio de Ibarra',
        'Eksambe',
        'Goodlettsville',
        'Cafelândia',
        'Buguda',
        'Kudavāsal',
        'Sahel',
        'Puerto Guzmán',
        'Īlām',
        'Puerto Rico',
        'Stockach',
        'Scott',
        'Coalinga',
        'Nümbrecht',
        'Biyahmū',
        'Ciudad Hidalgo',
        'Karukh',
        'Augustinópolis',
        'Zhoujia',
        'Bhucho Mandi',
        'Ambatolahy',
        'Villa Sarmiento',
        'Croatá',
        'Pajapan',
        'Budva',
        'Kushk',
        'Urucuia',
        'Shin-Kamigotō',
        'South Ogden',
        'El Reno',
        'Fairview',
        'Tepatlaxco',
        'Dharampuri',
        'Umirim',
        'Corbélia',
        'Vallières',
        'Anantāvūr',
        'Saluzzo',
        'Pocinhos',
        'South Venice',
        'Phayao',
        'Cafarnaum',
        'Samaná',
        'Talitay',
        'Vladimirci',
        'Cruz do Espírito Santo',
        'Bluffdale',
        'Gainesville',
        'West Columbia',
        'Guanagazapa',
        'Gautampura',
        'Morganton',
        'Karkamb',
        'Faléa',
        'Marche-en-Famenne',
        'Kiyama',
        'Dar Chaifat',
        'Veternik',
        'Chamalières',
        'Sibutao',
        'Māndvi',
        'Puurs',
        'Los Lunas',
        'Laguna Woods',
        'Bon Air',
        'Tasquillo',
        'Krasnokumskoye',
        'Rojales',
        'Suvorov',
        'Woodhouse',
        'Karachev',
        'Albino',
        'Royston',
        'Peçanha',
        'Abdullahnagar',
        'Rio Pomba',
        'Tabernes de Valldigna',
        'Nedugula',
        'Lakhnaur',
        'Rozdilna',
        'Puerto Aysén',
        'Pātan',
        'Brielle',
        'Nova Era',
        'Nīsang',
        'Lubang',
        'Montemor-o-Novo',
        'Sinzig',
        'Reota',
        'Forio',
        'Bramhall',
        'Mehrān',
        'Kahoku',
        'Derhachi',
        'Seoni Chhapāra',
        'Totutla',
        'Remagen',
        'Pescantina',
        'Anosiarivo',
        'Guaranda',
        'Santa Lucía Milpas Altas',
        'Nahāzāri',
        'Cham',
        'Kolokondé',
        'Camocim de São Félix',
        'Zaslawye',
        'Loboc',
        'Osuna',
        'Bahon',
        'Olivehurst',
        'Conyers',
        'Viradouro',
        'Murray',
        'New Castle',
        'San Isidro',
        'Colón',
        'Calayan',
        'Awlouz',
        'Babenhausen',
        'Karahia',
        'Isola Capo Rizzuto',
        'Buckingham',
        'Ummannūr',
        'Namminikara',
        'Jhagarua',
        'Beinasco',
        'Fraserpet',
        'Borda da Mata',
        'Douétiré',
        'Rio Claro',
        'Yutz',
        'Odaipatti',
        'Międzyrzecz',
        'Bolhrad',
        'Jomboy Shahri',
        'Bry-sur-Marne',
        'Mārtahalli',
        'Wahiawa',
        'Bhagabānpur',
        'Kawa',
        'Curti',
        'Ziracuaretiro',
        'Pyskowice',
        'São Domingos do Prata',
        'Abelardo Luz',
        'Wilton',
        'Çat',
        'Banni',
        'Plungė',
        'Mareth',
        'Balma',
        'Central Saanich',
        'Solrød Strand',
        'Villanueva del Pardillo',
        'Mula',
        'Louth',
        'Néa Erythraía',
        'Kurugodu',
        'Santa Maria di Sala',
        'Streetsboro',
        'Woodcrest',
        'Wangaratta',
        'Gunjāpalle',
        'Kelsterbach',
        'Nizhnyaya Salda',
        'Ban Song',
        'Arpaçay',
        'Raychikhinsk',
        'Paoua',
        'Holmdel',
        'Lalībela',
        'Mujikharf',
        'Barhan',
        'Sānwer',
        'Ayacucho',
        'Gescher',
        'Milla’ab',
        'Oued el Kheïr',
        'Betio',
        'Villa Rica',
        'Viljandi',
        'Glassmanor',
        'Ketama',
        'Bad Langensalza',
        'Sestri Levante',
        'Sainte-Catherine',
        'Lumbreras',
        'Kembhāvi',
        'Ibrāhīmpatan',
        'Skadovsk',
        'Hednesford',
        'Santa Elena',
        'Kadogawa',
        'Tonj',
        'Kamudi',
        'Valenzano',
        'Gothini',
        'Shumikha',
        'Yerrapālem',
        'Sedeh Lanjān',
        'Padre Paraíso',
        'Galaz',
        'Mori',
        'Pécel',
        'Riva del Garda',
        'New Amsterdam',
        'Guardamar del Segura',
        'Mau Dhaneshpur',
        'Betroka',
        'Krasnoslobodsk',
        'Johnstown',
        'Mudukulattūr',
        'Matam',
        'Mannō',
        'Tagana-an',
        'Dijiasuoxiang',
        'Aïn Zaouïa',
        'Uryzhar',
        'Muniz Freire',
        'El Kouif',
        'Miharu',
        'Suesca',
        'Pital',
        'Betulia',
        'Schwarzenbek',
        'Villiers',
        'Mohács',
        'Jenison',
        'As Suqaylibīyah',
        'Chadchan',
        'Pohādi',
        'Mecayapan',
        'Otacílio Costa',
        'Fort Thomas',
        'Cajamarca',
        'Schwalbach',
        'Sierpc',
        'Tíogollo',
        'Maranello',
        'Chāripāra',
        'Almolonga',
        'Namayingo',
        'Dombóvár',
        'Kanrangana',
        'Mundo Novo',
        'Ban Pong',
        'Holzwickede',
        'Sāram',
        'Poyo',
        'Chorhat',
        'Krupanj',
        'Guryevsk',
        'Barbastro',
        'Thorne',
        'Fortim',
        'Port Hope',
        'Keota',
        'Jawkatiā',
        'West Lampeter',
        'Horn-Bad Meinberg',
        'Porciúncula',
        'Pont-à-Celles',
        'Kouka',
        'Santo Antônio do Amparo',
        'Talwandi Bhai',
        'Brand',
        'Toledo',
        'Bad Münstereifel',
        'Bethpage',
        'Houghton Regis',
        'Garwolin',
        'Pollensa',
        'Amesbury',
        'Zébala',
        'Osny',
        'Baie de Henne',
        'Lebanon',
        'Thornton',
        'Kukraun',
        'Norrtälje',
        'Mūlki',
        'Arinos',
        'Sengés',
        'Sanary-sur-Mer',
        'Kulasegaram',
        'Moroni',
        'Shongzhy',
        'Hernando',
        'Staphorst',
        'Benavente',
        'Dehti',
        'Ellinikó',
        'Maigh Nuad',
        'Oskarshamn',
        'Tecolotlán',
        'Rattaphum',
        'Rancho Mirage',
        'San Juan Nonualco',
        'Stanford',
        'Baymak',
        'Capistrano',
        'G‘uzor',
        'Kinel’-Cherkassy',
        'Illizi',
        'Venturosa',
        'Heanor',
        'Friern Barnet',
        'Milton',
        'Khānpur',
        'Dharmāpuri',
        'Mariakerke',
        'Baturbāri',
        'Laurel',
        'North Druid Hills',
        'Mettuppālaiyam',
        'Stafford',
        'Gunzenhausen',
        'Marmande',
        'Shelbyville',
        'Oerlinghausen',
        'Nadugadda',
        'Bawgalegyi',
        'Les Clayes-sous-Bois',
        'Hatfield',
        'Amarante',
        'Inverness',
        'Heilbad Heiligenstadt',
        'Kangaba',
        'Ejutla de Crespo',
        'Jaguaretama',
        'Cipó',
        'Pāvumba',
        'Abadiânia',
        'Aurora',
        'Resplendor',
        'Littau',
        'Souaflia',
        'Minakami',
        'Belmopan',
        'Buford',
        'Bendorf',
        'Monselice',
        'Castelfiorentino',
        'Çukurca',
        'Bakeshiyingcun',
        'Martigny',
        'Belākoba',
        'Pisaflores',
        'Bareggio',
        'Clayton',
        'Bailén',
        'Terzigno',
        'Avenel',
        'Durleşti',
        'Carnaubal',
        'Itacarambi',
        'Kretinga',
        'Port-à-Piment',
        'Marktredwitz',
        'L’Isle-d’Abeau',
        'Raiyam',
        'Piera',
        'Chouafaa',
        'Shahritus',
        'Carmo',
        'Akassato',
        'Lérida',
        'Banabuiú',
        'Anori',
        'Digne-les-Bains',
        'Danville',
        'Badantola',
        'Araruna',
        'Glen Parva',
        'Mont-Organisé',
        'Dormentes',
        'Flowing Wells',
        'Sudipen',
        'Bāţūfah',
        'Forssa',
        'Cherakara',
        'Calhoun',
        'North Decatur',
        'Lyepyel',
        'Trstenik',
        'Bad Wörishofen',
        'Bedford',
        'Cacimba de Dentro',
        'Jouy-le-Moutier',
        'Padugupādu',
        'Neerpelt',
        'Al Ḩārah',
        'Nederweert',
        'Alcantara',
        'Shek Tong Tsui',
        'Villa Isabela',
        'Hinsdale',
        'Zandvoort',
        'Guoxing',
        'Castilleja de la Cuesta',
        'Sumé',
        'Conceição do Almeida',
        'Iati',
        'Traiguén',
        'Cluses',
        'Cottingham',
        'Calnali',
        'Bellaire',
        'Pinewood',
        'Massaranduba',
        'Peka',
        'Poção de Pedras',
        'Tizi Rached',
        'Sìnnai',
        'Konstantinovsk',
        'Itirapina',
        'Kayaralam',
        'Tanque Novo',
        'Malta',
        'Swansea',
        'Konstancin-Jeziorna',
        'Cássia',
        'Tejuçuoca',
        'Tlahuelilpan',
        'Joaquim Gomes',
        'Mayuge',
        'Bom Sucesso',
        'Sake',
        'Settiyārpatti',
        'Monsenhor Tabosa',
        'Majītha',
        'Sawankhalok',
        'Ōi',
        'Grumo Nevano',
        'San Felipe',
        'Ayinīkkād',
        'Tricase',
        'Clitheroe',
        'Grenchen',
        'Berezhany',
        'Saguday',
        'Firminy',
        'Pānchgrām',
        'Fairview Park',
        'Kusa',
        'Ipaba',
        'Figline Valdarno',
        'Graham',
        'Frederikssund',
        'Regeneração',
        'Sallanches',
        'Beckley',
        'Peso da Régua',
        'Banovići',
        'Correntes',
        'Giria',
        'Sa‘ādat Shahr',
        'Marsabit',
        'Pouso Redondo',
        'Ejea de los Caballeros',
        'Manassas Park',
        'Wādī Ḩalfā’',
        'Barrington',
        'Ilaka Atsinanana',
        'Easton',
        'Ban Phe',
        'Wang Tau Hom',
        'South Hadley',
        'Péruwelz',
        'Damous',
        'Kaukauna',
        'San Bernardino',
        'Groves',
        'Kutiyāna',
        'Manaquiri',
        'Shoufeng',
        'Ignacio de la Llave',
        'Nova Pazova',
        'Bülbülə',
        'Yizhu',
        'Tagounite',
        'Dilasag',
        'Gīnīr',
        'Taxtako‘pir',
        'Xintangcun',
        'Pottasshēri',
        'Mosbrough',
        'Santa Magdalena',
        'Braunau am Inn',
        'Kolaccheri',
        'Melfi',
        'Oswego',
        'Estelle',
        'Mata Roma',
        'San Esteban',
        'Tādigadapa',
        'Chandia',
        'Lake St. Louis',
        'Stockelsdorf',
        'Tolūprpatti',
        'Safety Harbor',
        'Laç',
        'Eutin',
        'Elias Fausto',
        'Usmānpur',
        'Denville',
        'El Segundo',
        'Peragamanna',
        'Sarotar',
        'Condeixa-a-Nova',
        'Zvenyhorodka',
        'Gudipallipādu',
        'San Rafael Cedros',
        'Sibinal',
        'Cinnaminson',
        'Púchov',
        'Xiulin',
        'Svalyava',
        'Mannukara',
        'Āyikudi',
        'Challapalle',
        'Serra Dourada',
        'Crixás',
        'Guadarrama',
        'Künzell',
        'Māvalli',
        'Upper Grand Lagoon',
        'Turinsk',
        'Cañon City',
        'Tifton',
        'Souto Soares',
        'Gavrilov-Yam',
        'Chuarrancho',
        'Paulino Neves',
        'Amfilochía',
        'Sidi Yakoub',
        'Tortum',
        'Condeúba',
        'Ramnagar',
        'Saint-Basile-le-Grand',
        'Kafr Zaytā',
        'Itarantim',
        'La Garriga',
        'West Hempfield',
        'Kibungan',
        'Ruhango',
        'Canovellas',
        'Porteiras',
        'Guernica y Luno',
        'Cáqueza',
        'Ilhota',
        'Knemis Dades',
        'Cheraro',
        'Terre Neuve',
        'Massapequa Park',
        'Kelheim',
        'Lons-le-Saunier',
        'Pernamitta',
        'Norwalk',
        'Carlentini',
        'Kitanakagusuku',
        'Grójec',
        'Bristol',
        'Gennep',
        'Ribeirópolis',
        'San Miguelito',
        'Menzelinsk',
        'Santa María',
        'Arıcak',
        'Gachancipá',
        'Addison',
        'Sinsina',
        'Pokrov',
        'Bellingham',
        'Tsararivotra',
        'Albal',
        'Milledgeville',
        'Rubeho',
        'São Simão',
        'Piranga',
        'Xinying',
        'Huntington',
        'Wadgassen',
        'Heysham',
        'Nāyakanhatti',
        'Hinode',
        'Cariús',
        'Kamenz',
        'Port Washington',
        'Karratha',
        'Macatuba',
        'Sītāmau',
        'Springfield',
        'Middletown',
        'Peñamiller',
        'Juazeirinho',
        'Avitanallur',
        'Wixom',
        'Upper Gwynedd',
        'Pendleton',
        'Brasnorte',
        'Saint-Cyr-sur-Loire',
        'Hannibal',
        'Abington',
        'Baaqlîne',
        'Marosangy',
        'Tolongoina',
        'Etrotroka',
        'Ilakatra',
        'Ambila',
        'Marofoty',
        'Ambohitrolomahitsy',
        'Lazarivo',
        'Milanoa',
        'Isoanala',
        'Amboanjo',
        'Befotaka',
        'Tsarahonenana',
        'Antanimora Atsinanana',
        'Ankarongana',
        'Ambondromisotra',
        'Ambohitsimanova',
        'Anosivelo',
        'Manombo Atsimo',
        'Sahamadio',
        'Anosibe-Ifanja',
        'Beahitse',
        'Antsahavaribe',
        'Ambohipihaonana',
        'Mandritsara',
        'Ambolidibe Atsinanana',
        'Sadabe',
        'Dzitbalché',
        'Ghonchí',
        'Yangi Mirishkor',
        'Jāyal',
        'Matauna',
        'Aḑ Ḑulū‘īyah',
        'Santo Antônio do Leverger',
        'Hindarx',
        'Alauli',
        'Saint-Égrève',
        'Peraía',
        'Naula',
        'Santo Tomás de Jánico',
        'Kakraul',
        'Tomas Oppus',
        'Itajobi',
        'Ban Rawai',
        'Ambalavayal',
        'Analanampotsy',
        'Shankar Saraiyā',
        'Kawai',
        'Clayton',
        'Sikandra',
        'Lodwar',
        'Oulad Amrane',
        'Guisborough',
        'Brunico',
        'Bayt Ūmmar',
        'Ulubey',
        'Fortuna',
        'Arai',
        'Retiro',
        'Srīkūrmam',
        'San Juan Lalana',
        'Gainesville',
        'Andranofasika',
        'Zarumilla',
        'L’Ancienne-Lorette',
        'Ibititá',
        'Kōrōth',
        'Aberdeen',
        'Rāmachandrapuran',
        'Ughara',
        'Geddes',
        'Serafina Corêa',
        'Ginsheim-Gustavsburg',
        'Viroflay',
        'Yanggezhuang',
        'Berga',
        'Meltonakkal',
        'Bishunpura',
        'Qal’at Mgouna',
        'Santana do Cariri',
        'Cercola',
        'Bou Hanifia el Hamamat',
        'Dirba',
        'Puerto Caimito',
        'Kombai',
        'Liuchuan',
        'Mers el Kebir',
        'Balmazújváros',
        'Octeville',
        'Perupālem',
        'Acoyapa',
        'Hohenems',
        'Pozoblanco',
        'Dallas',
        'Myrtle Grove',
        'Garagoa',
        'Upper Saucon',
        'Adelphi',
        'Heber',
        'Namysłów',
        'Podporozhye',
        'San José de Chiquitos',
        'Hünfeld',
        'Hampton Bays',
        'Bridgeview',
        'Ashwaubenon',
        'Sarıoğlan',
        'Chavara Grāmam',
        'Boki-Wéré',
        'Wondelgem',
        'Kottapeta',
        'Powder Springs',
        'Tibau do Sul',
        'Liuguoju',
        'Long’e',
        'Morriston',
        'Aubange',
        'Dalain Hob',
        'Tekanpur',
        'Eggenstein-Leopoldshafen',
        'Guadalupe Victoria',
        'Huruta',
        'Baikatpur',
        'Mālancha',
        'Shingūchō-shingū',
        'Carovigno',
        'Stockerau',
        'Governador Celso Ramos',
        'Glace Bay',
        'Cubellas',
        'McKinleyville',
        'Tobré',
        'Galich',
        'Alcantara',
        'Río Bueno',
        'Ginatilan',
        'Kronach',
        'Zinapécuaro',
        'Carlópolis',
        'Māri‘',
        'Fındıklı',
        'Massé',
        'Iziaslav',
        'Nāranattenvanpatti',
        'Nidgundi',
        'Sidi Daoud',
        'High Blantyre',
        'Pallikapuzha',
        'Douar Lamjaara',
        'Rāmāyampet',
        'Dehāqān',
        'Laconia',
        'Tubaran',
        'Dazhangzicun',
        'Dazhangzi',
        'Dhulkot',
        'Belle Glade',
        'Aklanpa',
        'Navalmoral de la Mata',
        'Fetromby',
        'Bruckmühl',
        'Khed',
        'Santa Teresa',
        'Colonial Park',
        'Ngara',
        'Morteros',
        'São João dos Poleiros',
        'Farmingville',
        'Vatutine',
        'Soumagne',
        'Vīrapāndi',
        'Ettaiyāpuram',
        'Mājra',
        'New Milford',
        'Miqiao',
        'Heidenau',
        'Azaourissè',
        'Chambellan',
        'Laferrière',
        'Zhukovka',
        'Ighram',
        'Bermeo',
        'Benešov',
        'La Quiaca',
        'Sada',
        'Agaram',
        'Boa Vista do Tupim',
        'Annonay',
        'Pleszew',
        'Bédigoazon',
        'Lamas',
        'Ashmyany',
        'Colesberg',
        'Canutama',
        'Thale',
        'Hosakote',
        'Marion',
        'Le Pré-Saint-Gervais',
        'Bambara-Maoundé',
        'Nerang',
        'Japaratuba',
        'Nerviano',
        'Pampa',
        'Mount Vernon',
        'Jāsk',
        'Alfajayucan',
        'Ban Tha Mai I',
        'Moraga',
        'Latifpur',
        'Sāyarpuram',
        'Linnei',
        'Korem',
        'Maltby',
        'Rāmpur Jalālpur',
        'Rubano',
        'Lerum',
        'Sliema',
        'Pulimel',
        'Janakkala',
        'Bitburg',
        'Defiance',
        'São Pedro do Sul',
        'Pran Buri',
        'Finnentrop',
        'Madanpur',
        'Braunstone',
        'Fiorano Modenese',
        'Bostonia',
        'Haslingden',
        'Auburn',
        'Boden',
        'Azeffoun',
        'Meiti',
        'Damascus',
        'North Hykeham',
        'Shāhkot',
        'Bhawānīpur',
        'Hot Springs Village',
        'Aïn Feka',
        'Xixinzhuangzhen',
        'Belaya Glina',
        'Vallegrande',
        'Wittenberge',
        'Lillerød',
        'Acharipallam',
        'Devizes',
        'Tsuiki',
        'Hrubieszów',
        'Trzcianka',
        'Montceau-les-Mines',
        'Saltash',
        'Vechūr',
        'Mattoon',
        'Upper Chichester',
        'Washougal',
        'Haslemere',
        'Achaljāmu',
        'Ratba',
        'Orăştie',
        'Brixham',
        'Yabuki',
        'Salinas de Hidalgo',
        'Gemert',
        'Bhainsoda',
        'Makīnsk',
        'Asten',
        'Dongjiangshui',
        'Saint Ives',
        'Kadikkād',
        'Iglino',
        'Long Beach',
        'Sidi Lamine',
        'Bad Lippspringe',
        'Vazhani',
        'Baronissi',
        'Minamichita',
        'Daireaux',
        'Altıntaş',
        'Kodikulam',
        'Wyckoff',
        'Cerea',
        'Keetmanshoop',
        'Rijen',
        'Busolwe',
        'Druzhba',
        'Maroochydore',
        'Astara',
        'Montornés del Vallés',
        'Beinan',
        'Ambarès-et-Lagrave',
        'Sinūnī',
        'Kaliro',
        'Puliyankunnu',
        'Marib',
        'San Julián',
        'Famy',
        'Sierre',
        'Roxana',
        'Kāmavarapukota',
        'Weehawken',
        'San Roque',
        'Cestas',
        'Tromsdalen',
        'San Francisco',
        'São Paulo do Potengi',
        'Fazakerley',
        'El Rosario',
        'Sudak',
        'Batuco',
        'Kauhava',
        'Truckee',
        'Orta Nova',
        'Pionki',
        'Villeneuve-Loubet',
        'Tsundupalle',
        'Solothurn',
        'Palmetto Estates',
        'Říčany',
        'Windlesham',
        'Vila Bela da Santíssima Trindade',
        'Latsia',
        'Buntok',
        'Sunland Park',
        'Ouaouzgane',
        'Posoltega',
        'Connahs Quay',
        '’Aïn Abessa',
        'Masamagrell',
        'Maşīf Sarsink',
        'Dhobauli',
        'Bāuria',
        'Puerto Carreño',
        'Humacao',
        'Attappampatti',
        'Eğirdir',
        'Havelock',
        'Yağlıdere',
        'Young',
        'El Piñón',
        'Nandigaon',
        'Bovisio Masciago',
        'Djouab',
        'San Miguel de Salcedo',
        'Maardu',
        'Chiva',
        'Swift Current',
        'Campo do Brito',
        'Chestnuthill',
        'Kadod',
        'Nuevo San Juan Parangaricutiro',
        'Shahbā',
        'Königstein im Taunus',
        'Thandla',
        'Kushima',
        'Santa Lucía',
        'Donna',
        'New Port Richey',
        'Chinampa de Gorostiza',
        'Água Clara',
        'Vimodrone',
        'Centerville',
        'Ribat Al Khayr',
        'Bytów',
        'Diksmuide',
        'Arakkal',
        'Yerbas Buenas',
        'Preganziol',
        'Plumstead',
        'Livinjipuram',
        'Bürstadt',
        'Sobrado de Paiva',
        'Leixlip',
        'Boysun',
        'Dēra',
        'Tengampudūr',
        'Bek’ojī',
        'Kings Park',
        'Penzberg',
        'Takhatgarh',
        'Sendurai',
        'Roche-à-Bateau',
        'Hīrna',
        'Katsuura',
        'Ponte de Sôr',
        'Băicoi',
        'Holzkirchen',
        'Bredbury',
        'Chalāla',
        'Konodimini',
        'Kukës',
        'Iluppur',
        'Fuying',
        'Fuyingzicun',
        'Dour',
        'Kodumudi',
        'Glanerbrug',
        'Klippansbruk',
        'Vincennes',
        'Aston',
        'La Vista',
        'Cabeceiras de Basto',
        'Novomichurinsk',
        'Pisticci',
        'Firestone',
        'Richmond Hill',
        'Povorino',
        'Saalfelden am Steinernen Meer',
        'Kattiyeri',
        'Gikongoro',
        'Balighattam',
        'Westchester',
        'Pôrto Acre',
        'Gonegandla',
        'Ban Wang Nok Aen',
        'Verdun',
        'Pazhayannūr',
        'Pochëp',
        'Piriyāpatna',
        'Jilotepec',
        'East Greenbush',
        'Jalpa de Méndez',
        'Saran',
        'Nässjö',
        'Rotselaar',
        'Erumād',
        'Harborcreek',
        'São Vicente Férrer',
        'Hāvi Bhauār',
        'Holly Springs',
        'La Cruz',
        'Zafra',
        'Murraysville',
        'Deoria',
        'Ocho Rios',
        'Néa Mákri',
        'Châteaurenard',
        'Wellington',
        'Ferndale',
        'Phrae',
        'Kundal',
        'Campagna',
        'Murehwa',
        'São Caetano de Odivelas',
        'Zequ',
        'Banstead',
        'Kumage',
        'Riemst',
        'Przasnysz',
        'Podalakūr',
        'Federación',
        'Bassum',
        'Kruibeke',
        'Hueytown',
        'Kakkalapalle',
        'Madrid',
        'Whickham',
        'Beaver Dam',
        'Agourai',
        'Loxstedt',
        'Zveçan',
        'Berber',
        'Grangemouth',
        'Arani',
        'Sidi Ettiji',
        'Bogatynia',
        'Tāwargeri',
        'Araçagi',
        'Ban Na Sai',
        'Calera',
        'Khirhar',
        'Keynsham',
        'Barangka',
        'Itapiranga',
        'Shiraoi',
        'Lake Mary',
        'Ittiva',
        'Xiaolongtan',
        'Salitre',
        'Glenmont',
        'Millbrook',
        'Mullānwāla',
        'Tadaoka-higashi',
        'Seeheim-Jugenheim',
        'Red Wing',
        'Añisoc',
        'Tantéga',
        'Tiahounkossi',
        'San Isidro',
        'Medicina',
        'Epitacio Huerta',
        'Cleckheaton',
        'Pratteln',
        'Micoud',
        'Bull Run',
        'Brod',
        'Douar Tabouda',
        'El Escorial',
        'La Algaba',
        'Valu lui Traian',
        'Itororó',
        'Shpola',
        'Piquet Carneiro',
        'Ad Dīs',
        'Bánovce nad Bebravou',
        'Leopoldshöhe',
        'Wurzen',
        'Bariārpur',
        'Clearlake',
        'Mariestad',
        'Sedan',
        'Bargūr',
        'Poreč',
        'Zirara',
        'Ceadîr-Lunga',
        'Noordwijkerhout',
        'Shek Wai Kok',
        'Khaniādhāna',
        'Ibipeba',
        'Mangueirinha',
        'Marienberg',
        'Reinheim',
        'Lomas de Sargentillo',
        'Getúlio Vargas',
        'Morton',
        'Kaatsheuvel',
        'Macetown',
        'Burbaliq',
        'Dilijan',
        'The Mumbles',
        'Pannaipuram',
        'Tukums',
        'Mauguio',
        'Three Lakes',
        'Rio Linda',
        'Saint-Jean-de-la-Ruelle',
        'San Juan Bautista',
        'Soumpi',
        'Wailuku',
        'Bijni',
        'Xinyuan',
        'Puerto El Triunfo',
        'Buritama',
        'Cidreira',
        'N’Goussa',
        'Country Club Hills',
        'Łask',
        'Īlkhchī',
        'Īlkhechī',
        'La Sierpe',
        'Mohan Eghu',
        'Ippy',
        'San Giovanni Valdarno',
        'Pembroke',
        'Heumen',
        'Barkot',
        'Warrington',
        'Pirque',
        'Eppelborn',
        'Lille',
        'Miyazu',
        'Tongluo',
        'Paragaticherla',
        'Humble',
        'Menomonie',
        'Lakhipur',
        'Bhai Rupa',
        'Plácido de Castro',
        'Mensora',
        'Cabaceiras do Paraguaçu',
        'Ina',
        'Guaymate',
        'Slaný',
        'Seltso',
        'Itaquitinga',
        'Domchānch',
        'Indiaroba',
        'Coaraci',
        'Santa Maria das Barreiras',
        'Ramón Santana',
        'Hønefoss',
        'West Richland',
        'Middelfart',
        'Tichi',
        'Sujina',
        'Regenstauf',
        'Cattolica',
        'Wentang',
        'Raunheim',
        'Kade',
        'Odumase',
        'Tamallalt',
        'Bogucice',
        'Ada',
        'Parali',
        'Mangrauni',
        'Segbwema',
        'Batán',
        'Marly-le-Roi',
        'Vero Beach',
        'Ahmetli',
        'Ledeberg',
        'Liangyi',
        'Ban Cho Ho',
        'Bayat',
        'Rentachintala',
        'Thị Trấn Ngải Giao',
        'Hövelhof',
        'Brackenheim',
        'Madison',
        'Mapiri',
        'Santa María Petapa',
        'San Estanislao',
        'Puerto Pilón',
        'Pedregulho',
        'Gussago',
        'Horodok',
        'Torre Maggiore',
        'Sunagawa',
        'Uchkeken',
        'Caiapônia',
        'Cassano al Ionio',
        'Thompson',
        'Ciudad Guadalupe Victoria',
        'Haji Shah',
        'Berezan',
        'Langrucun',
        'Espartinas',
        'Sant’Elpidio a Mare',
        'Chinde',
        'Al Ghāţ',
        'Qarqīn',
        'Qusar',
        'Cowley',
        'Pilāppulli',
        'Mudakkiraye',
        'East Highland Park',
        'Amba Icharua',
        'Alpignano',
        'Ukiah',
        'Słubice',
        'Fléron',
        'Shintomi',
        'Adelfia',
        'Zapotlán de Juárez',
        'La Paz',
        'Jinjicun',
        'Jinji',
        'Tafresh',
        'Hebburn',
        'Linbian',
        'Ashby de la Zouch',
        'Milanówek',
        'Ham Lake',
        'Chorbogh',
        'Jasper',
        'Grimari',
        'Bobangui',
        'Kalundborg',
        'Rāsivarai Tottam',
        'Reggello',
        'Antanambao Mahatsara',
        'Ervādi',
        'Braniewo',
        'Rājbalhāi',
        'Hillcrest Heights',
        'Warsaw',
        'Sabotsy',
        'Ban Lam Narai',
        'Barbacoas',
        'Enfield Lock',
        'Ōyodo',
        'Fairburn',
        'Escoublac',
        'Mifune',
        'Dighaun',
        'Ujre',
        'Ban Nong Han',
        'Kirchhain',
        'Dahibhāt Mādhopur',
        'Loma Plata',
        'Kilwinning',
        'Chopadandi',
        'Mohanūr',
        'Mannūr',
        'San Casciano in Val di Pesa',
        'Lake Wales',
        'Fillmore',
        'Les Herbiers',
        'Ojus',
        'Wardenburg',
        'Nanbu',
        'Tōhoku',
        'Taylor',
        'Batalha',
        'Anorí',
        'Sakardih',
        'Encruzilhada',
        'Churriana de la Vega',
        'Glenn Heights',
        'Albemarle',
        'Kozelsk',
        'Katosi',
        'Nakanoto',
        'Piancó',
        'Charqueada',
        'Ciney',
        'Cayetano Germosén',
        'Bordj Mokhtar',
        'Edmundston',
        'Cloverly',
        'Hannut',
        'Greenville',
        'Gurh',
        'Lufeng',
        'Rājnagar',
        'Auburndale',
        'Pupiales',
        'Altena',
        'Rawa Mazowiecka',
        'Belén',
        'Bilohirsk',
        'Vedelago',
        'Gasparillo',
        'Neópolis',
        'Bykhaw',
        'Boshrūyeh',
        'Pearl River',
        'San Miguel',
        'Pelhřimov',
        'Kierspe',
        'Aberdeen',
        'Peer',
        'Adjahomé',
        'Batangafo',
        'Burgdorf',
        'Goulmima',
        'Ambohimanambola',
        'Curacautín',
        'Nilaiyūr',
        'Spanish Springs',
        'Franklin',
        'Hilltown',
        'Westbury',
        'Lugu',
        'Cajari',
        'Hanover',
        'Uherský Brod',
        'Petersberg',
        'Kirsanov',
        'Islām Qal‘ah',
        'Portachuelo',
        'Baro',
        'Dyer',
        'Pudu',
        'Poing',
        'Rāikal',
        'Kurtamysh',
        'Mastchoh',
        'Belém',
        'Vienna',
        'Ntungamo',
        'Las Cabezas de San Juan',
        'Pocking',
        'Dentsville',
        'Bosobolo',
        'Toro',
        'Pamiers',
        'Hatti',
        'Mahesh Khunt',
        'Passa Quatro',
        'Kastoriá',
        'Freienbach',
        'Kesath',
        'Faxinal',
        'Gokavaram',
        'Tahlequah',
        'Outat Oulad Al Haj',
        'Poço Fundo',
        'Nova Resende',
        'Hollins',
        'Middletown',
        'Libiąż',
        'Karema',
        'Bou Hadjar',
        'Stone',
        'Khargāpur',
        'Khirpai',
        'Puan',
        'Zhongzai',
        'Radford',
        'Taohongpozhen',
        'Malnate',
        'Divriği',
        'Caridade',
        'Panukulan',
        'Pasaquina',
        'Brakel',
        'Hude',
        'Aki',
        'Moreau',
        'Angara-Débou',
        'Kingsborough',
        'Žitorađa',
        'Kirchlengern',
        'Barra de Santo Antônio',
        'Straelen',
        'Denby Dale',
        'Phirangipuram',
        'Erba',
        'San Juan',
        'Guben',
        'Ubrique',
        'Payson',
        'Sebt Aït Saghiouchen',
        'East Longmeadow',
        'Mercedes',
        'Juchique de Ferrer',
        'Arteche',
        'Bagnacavallo',
        'Fairview Heights',
        'Fujioka',
        'Bangor',
        'Fullerton',
        'Jussara',
        'Usgao',
        'Nunna',
        'Mahālandi',
        'Chettināyakkanpatti',
        'Cosautlán',
        'Jambaló',
        'Sulzbach',
        'Nova Olímpia',
        'North Arlington',
        'Rolleston',
        'Bārnia',
        'Moussoro',
        'Central',
        'Chinácota',
        'Wemmel',
        'Halver',
        'Ban Thung Tam Sao',
        'Gālivedu',
        'Kakching',
        'Großostheim',
        'Arenys de Mar',
        'Hương Canh',
        'Bad Essen',
        'Novoukrainka',
        'Kargil',
        'Grosse Pointe Woods',
        'Vadugappatti',
        'Villa San José',
        'Piru',
        'Çarşıbaşı',
        'La Falda',
        'Ouled Chebel',
        'Crawfordsville',
        'Sahatavy',
        'Muscle Shoals',
        'Erwitte',
        'Baipingshan',
        'Mount Dora',
        'Westport',
        'Sunnyside',
        'Sananduva',
        'Shuzenji',
        'Bree',
        'Yoshinogari',
        'Tiverton',
        'Elsen',
        'Bandar Murcaayo',
        'Guelendeng',
        'Srbobran',
        'Wallisellen',
        'Poplar Bluff',
        'Yuvileine',
        'Xianxi',
        'Changji',
        'Khunti Dhanaili',
        'Northbridge',
        'Bargteheide',
        'Schmelz',
        'Douglas',
        'Moroto',
        'Lyantonde',
        'Leinì',
        'Birstall',
        'Noniyā',
        'Umbertide',
        'Fort Drum',
        'Shuili',
        'Tremedal',
        'Shahriston',
        'Kodala',
        'Novoanninskiy',
        'Merchtem',
        'Hampton',
        'Arachchalūr',
        'Sohtha',
        'Mālhīpur',
        'Bacuri',
        '’Ayn Bni Mathar',
        'Acarape',
        'Barharwa Kalān',
        'Buffalo',
        'Welby',
        'São Francisco do Guaporé',
        'Birsinghpur',
        'Wiefelstede',
        'Dungannon',
        'Colle Salvetti',
        'Yorkton',
        'Zumarraga',
        'Raposos',
        'Kabo',
        'Omalūr',
        'Center Point',
        'Carcarañá',
        'Utinga',
        'Domoni',
        'Kassorola',
        'Nawnghkio',
        'Berriche',
        'Ripon',
        'San Dionisio',
        'Queensbury',
        'Madukkūr',
        'Ivanava',
        'Sabana de La Mar',
        'Nobsa',
        'General Villegas',
        'Martinsicuro',
        'Simaria',
        'Al Laţāminah',
        'San Antonio Oeste',
        'Acahay',
        'Momil',
        'Brus',
        'Yotoco',
        'Taishachō-kizukikita',
        'Jagdispur',
        'Oyten',
        'Wendlingen am Neckar',
        'Kasumi',
        'Antsahanoro',
        'Goundam',
        'Radzionków Nowy',
        'Ispica',
        'Bad Bentheim',
        'Naama',
        'Ambalabe',
        'Llanquihue',
        'Benjamín Aceval',
        'Montalvo',
        'Éghezée',
        'Bikou',
        'Az Zaydīyah',
        'Jalālpur',
        'Glen Allen',
        'Carhué',
        'Santomera',
        'Ransiki',
        'Progreso',
        'Târgu Secuiesc',
        'Manamelkudi',
        'Bālasamudram',
        'Parkway',
        'Cherān',
        'Khirbat Ghazālah',
        'Longchang',
        'Spárti',
        'Stepney',
        'Massakory',
        'Mukhtārpur Salkani',
        'Kota',
        'Fiano Romano',
        'Artesia',
        'Bethlehem',
        'Garou',
        'Tadikalapūdi',
        'San Martino Buon Albergo',
        'Chickasha',
        'Opa-locka',
        'Künzelsau',
        'Restrepo',
        'South Middleton',
        'El Peñol',
        'Giaveno',
        'Oteapan',
        'Orimattila',
        'Palma Campania',
        'Yunshan',
        'Neratovice',
        'Turgutalp',
        'Ōyamazaki',
        'Freiberg am Neckar',
        'Hermitage',
        'Kabugao',
        'Cotorra',
        'Ikast',
        'Hajdúnánás',
        'Vitthalāpuram',
        'Petrovsk-Zabaykal’skiy',
        'Çaykara',
        'Aranyaprathet',
        'Lemay',
        'Jičín',
        'Al Ḩībah',
        'Walnut Park',
        'Basford',
        'Landerneau',
        'eMuziwezinto',
        'Rožnov pod Radhoštěm',
        'Burrillville',
        'Singhwara',
        'Santa Anita',
        'Altinópolis',
        'Stahnsdorf',
        'Porumāmilla',
        'Šilutė',
        'Do‘stobod',
        'Vodil',
        'Handlová',
        'San Pedro de Lloc',
        'Xiangjiaba',
        'Gokarna',
        'Taree',
        'Khutauna',
        'Saraikela',
        'Izamal',
        'Nakhon Nayok',
        'Ansfelden',
        'Brzesko',
        'Stallings',
        'Kozienice',
        'Bandora',
        'Hostomel',
        'Roşu',
        'Mastic Beach',
        'Bīleh Savār',
        'Kanhauli Manohar',
        'Svitavy',
        'Tocantins',
        'Ampahimanga',
        'Alegria',
        'Attūr',
        'Berea',
        'Itāhri',
        'Aruvāpalam',
        'Griffith',
        'Castellaneta',
        'Harenkarspel',
        'Köprüköy',
        'Chotāla',
        'Alsfeld',
        'Riversdale',
        'Aguadulce',
        'Ségoubougou',
        'Ganeshpur',
        'Monte Carmelo',
        'West Norriton',
        'As Sukhnah',
        'Chorfa',
        'Readington',
        'Ocean Pointe',
        'Timezgana',
        'Huarmey',
        'Dyersburg',
        'South Houston',
        'Banora Point',
        'Kotoura',
        'Hibbing',
        'Talsint',
        'River Falls',
        'Bingawan',
        'Four Corners',
        'Parma',
        'Al Qbab',
        'Baharu',
        'Pujilí',
        'Rāmnagar',
        'Schlüchtern',
        'Alcañiz',
        'Guérande',
        'Milford',
        'Caldera',
        'Pielisjärvi',
        'Loimaa',
        'Āgiripalle',
        'Troutdale',
        'Bni Tajjit',
        'Babayurt',
        'Westwood',
        'Al Awjām',
        'Ban Kao',
        'Sikeston',
        'Rygge',
        'Castenaso',
        'Sayville',
        'Venustiano Carranza',
        'Voss',
        'Kittūr',
        'Tyldesley',
        'Caravaggio',
        'Springfield',
        'Sylvan Lake',
        'Mafuné',
        'Carlet',
        'Upala',
        'Santa Cruz Muluá',
        'Zegzel',
        'East Barnet',
        'Port-de-Bouc',
        'Easthampton',
        'Forks',
        'La Grange',
        'Cêrro Azul',
        'Jaypul',
        'Los Muermos',
        'Markranstädt',
        'Quarteira',
        'Noale',
        'Bang Phae',
        'Upton',
        '’Aïn Babouche',
        'Douar Azla',
        'La Grange',
        'Yeni Suraxanı',
        'Buckley',
        'Jakkampālaiyam',
        'Chennūr',
        'Ranong',
        'Ségou',
        'Bumpe',
        'Rustampur',
        'Simcoe',
        'Math Lohiyār',
        'Ustroń',
        'Kāmayakkavundanpatti',
        'Subachoque',
        'Kodinsk',
        'Jacupiranga',
        'Sultānābād',
        'Kujwa',
        'Parrita',
        'Ambarakaraka',
        'Gubin',
        'Balş',
        'Jalālābād',
        'Medjana',
        'Ubatã',
        'Chaona',
        'Cherukunnu',
        'Khusropur',
        'Saraland',
        'Tarqui',
        'El Ach',
        'Diabigué',
        'Masmouda',
        'Frogn',
        'Pilkha',
        'Bakarpur Ogairah',
        'Nonantola',
        'Bovolone',
        'Prinzapolka',
        'Canudos',
        'Kajur',
        'Nowogard',
        'Sūrappalli',
        'Panajachel',
        'Chinggil',
        'Birhana',
        'Michelstadt',
        'Hazro',
        'Nyahanga',
        'Anantapalle',
        'Ripon',
        'Ait Yaazem',
        'Chai Prakan',
        'Johnstone',
        'Wendelstein',
        'Aurāhi',
        'Aquitania',
        'Bellevue',
        'Horodok',
        'Ottūr',
        'Bouchabel',
        'Viškovo',
        'Fatipura',
        'Ottakkadai',
        'Brüggen',
        'South River',
        'Seymour',
        'Banigbé',
        'Şemdinli',
        'Großenkneten',
        'Kollūru',
        'Rāmewādi',
        'Owase',
        'Santiago Tulantepec',
        'Wohlen',
        'Domont',
        'Mecheraa Asfa',
        'Shāhpur',
        'Olecko',
        'Tankal',
        'Federal',
        'Dalachi',
        'Kalanjūr',
        'Eloy',
        'Quiindy',
        'Bhiloda',
        'Hüyük',
        'Middle Smithfield',
        'Léré',
        'Yaojia',
        'Makhu',
        'Czernica',
        'Fartura',
        'Punta Umbría',
        'Vontimitta',
        'Safo',
        'Sultonobod',
        'Tachiarai',
        'Mount Pleasant',
        'Montbrison',
        'Bādshāhpur',
        'Baléyara',
        'Sulechów',
        'Sérarou',
        'Mikkabi',
        'Ukrainka',
        'Strathroy',
        'Ayyampālaiyam',
        'Darién',
        'Marbach am Neckar',
        'Mārupe',
        'North Fayette',
        'Dickson',
        'Gardendale',
        'Ágios Ioánnis Réntis',
        'Bni Quolla',
        'Grovetown',
        'Stone Ridge',
        'Sivapuram',
        'Lady Lake',
        'Bay Village',
        'Telfs',
        'Al Ḩazm',
        'Mayilūr',
        'Scordia',
        'Ranohira',
        'Duxbury',
        'Tourlaville',
        'Agudo',
        'Penugonda',
        'Fort Mohave',
        'Qarabulaq',
        'Vauréal',
        'Hewitt',
        'Sulphur Springs',
        'Eilenburg',
        'Worcester Park',
        'Bou Djeniba',
        'Doğanhisar',
        'Mahavelona',
        'Kāyanna',
        'Samarate',
        'Mountain Home',
        'Ciudad de Huitzuco',
        'Az Zintān',
        'Cajueiro',
        'Preetz',
        'Azalea Park',
        'Zeulenroda',
        'Americus',
        'Lithia Springs',
        'Qazyan',
        'Grandville',
        'Rāhon',
        'Marahōm',
        'Khat Azakane',
        'Kasli',
        'Giruá',
        'Ya‘bad',
        'Locarno',
        'Aue',
        'Myrza-Ake',
        'Bracebridge',
        'Santa Ana',
        'Fort Hunt',
        'Sátiro Dias',
        'Orsay',
        'Rāni Shakarpura',
        'Sapatgrām',
        'Rylsk',
        'Utnūr',
        'Türkeli',
        'Blanquefort',
        'Kumaranallūr',
        'Exeter',
        'Sidi Azzouz',
        'El Rosario',
        'Degtyarsk',
        'Höganäs',
        'Sughrāin',
        'Summerside',
        'Bethulie',
        'Tsiningia',
        'Antindra',
        'Andapafito',
        'Vohilengo',
        'Manompana',
        'Bevonotra',
        'Betanty',
        'Mandrosonoro',
        'Ankazondandy',
        'Androrangavola',
        'Fiadanana',
        'Alarobia',
        'Inanantonana',
        'Ambalatany',
        'Andemaka',
        'Vohiposa',
        'Anjangoveratra',
        'Leanja',
        'Fenoarivo',
        'Fenoarivo',
        'Bekitro',
        'Ambohibe',
        'Tangainony',
        'Antsenavolo',
        'Analila',
        'Mangabe',
        'Baltit',
        'Tullinge',
        'Kafia Kingi',
        'Manafwa',
        'Buwama',
        'Sardoba',
        'Ishtixon Shahri',
        'Mông Dương',
        'Longji',
        'Koulamoutou',
        'Asāra',
        'Dandu Mailāram',
        'Al Mazār ash Shamālī',
        'Wātrāp',
        'Illingen',
        'Rumilly',
        'Chilgazí',
        'Weener',
        'Zemrane',
        'Neriyamangalam',
        'Dêngka',
        'Dianga',
        'Üsharal',
        'Caidat Sidi Boubker El Haj',
        'Youngsville',
        'Canmore',
        'East Finchley',
        'Ambohimalaza',
        'Guioyo',
        'Lewes',
        'Nanāttupārai',
        'San Cesareo',
        'The Dalles',
        'Washington',
        'Tasīl',
        'Füssen',
        'Rāiganj Bāzār',
        'Ḩammām al ‘Alīl',
        'Dublin',
        'Bathurst',
        'Kamyzyak',
        'Chornobaivka',
        'Yaypan',
        'São Lourenço da Serra',
        'St. Simons',
        'Vicente Guerrero',
        'Balneário do Rincão',
        'Saint-Amand-les-Eaux',
        'Jāwalgeri',
        'Kerman',
        'Saint-Leu-la-Forêt',
        'Kizhūr',
        'Kudali',
        'Cruz Machado',
        'Ouamri',
        'Antadinga',
        'Clarksburg',
        'Frankfort',
        'Este',
        'Barysh',
        'Yurihama',
        'Chittayankottai',
        'Neykkārappatti',
        'Mwingi',
        'Erlensee',
        'Finsterwalde',
        'Hayange',
        'Tuineje',
        'Chertsey',
        'Sukth',
        'Valavakāttumūla',
        'Obra',
        'Mohanpur',
        'Amarwāra',
        'Ambohidrapeto',
        'Bahutāl',
        'Landen',
        'Zhytkavichy',
        'Kyabé',
        'Chivolo',
        'Viera West',
        'Ghomrassen',
        'Gentbrugge',
        'L’Oulja',
        'Pātapatnam',
        'Elbeuf',
        'Tagazhi',
        'Ishidoriyachō-eso',
        'Motru',
        'Ras el Oued',
        'Mandiakui',
        'Coos Bay',
        'South Hayling',
        'Fontainebleau',
        'Valeggio sul Mincio',
        'Tarquinia',
        'Marco Island',
        'Talitsa',
        'Süchteln',
        'Despujols',
        'Summerfield',
        'Alotau',
        'Androy',
        'Stará Ľubovňa',
        'Hongsi',
        'Brahmadesam',
        'Bellmore',
        'Alabat',
        'Schwarzenberg',
        'Campanha',
        'Manatanna',
        'Codru',
        'Königslutter am Elm',
        'Gargždai',
        'Aporá',
        'Sertanópolis',
        'Xiaqiaotou',
        'Băileşti',
        'Bikin',
        'Penicuik',
        'Râşnov',
        'Plavsk',
        'Cumaru',
        'Comarapa',
        'Middleburg Heights',
        'Dunaivtsi',
        'Verneuil-sur-Seine',
        'Ban Dung',
        'Karambakkudi',
        'Lakamané',
        'Petite-Synthe',
        'Adwick le Street',
        'Alpine',
        'Quixelô',
        'Saint-Pierre-des-Corps',
        'Vlagtwedde',
        'Paso de Carrasco',
        'Sāyalkudi',
        'Suchindram',
        'Nyasvizh',
        'Hayes',
        'Alexándreia',
        'San Giovanni in Fiore',
        'Kuršumlija',
        'Lewiston',
        'Calbuco',
        'Brooklyn Park',
        'Marmeleiro',
        'Lower Salford',
        'Schaesberg',
        'Fremont',
        'Heggadadevankote',
        'Fairhaven',
        'Haddada',
        'Aladağ',
        'Piaçabuçu',
        'Bastogne',
        'Ostrov',
        'Zunil',
        'Sint-Kruis',
        'Eilendorf',
        'Walldorf',
        'Floral Park',
        'Greater Napanee',
        'Barentu',
        'Cerejeiras',
        'Luzhou',
        'Skvyra',
        'Ivdel',
        'Turffontein',
        'Vijayapuri North',
        'Quezon',
        'Wadern',
        'Gheorgheni',
        'Addlestone',
        'Vellikulangara',
        'Highland Village',
        'Lam Luk Ka',
        'Dighirpār',
        'Sertã',
        'Concorezzo',
        'Benetúser',
        'Mahires',
        'Esquipulas',
        'Davutlar',
        'Brusciano',
        'Kulhudhuffushi',
        'Beek',
        'Drensteinfurt',
        'Kishundāspur',
        'Warren',
        'Katav-Ivanovsk',
        'Udayagiri',
        'Tapejara',
        'Galván',
        'Bar',
        'Sidi Allal el Bahraoui',
        'Alvarães',
        'Villalbilla',
        'Gryfice',
        'Codroipo',
        'Mario Campos',
        'Prospect Heights',
        'Nidiyanga',
        'Vári',
        'Tupi Paulista',
        'Baghlia',
        'Hammam M’Baïls',
        'Demerval Lobão',
        'Seaford',
        'Valabhīpur',
        'Toma',
        'Sahambala',
        'Jamestown',
        'Toul',
        'Dembeni',
        'Gatesville',
        'Lonigo',
        'Tamazouzt',
        'Xiaozhengzhuang',
        'Pawai',
        'Indianola',
        'La Lucila',
        'Bonito',
        'Osowa',
        'Jataúba',
        'Gandevi',
        'Brofodoumé',
        'Chhapra Bahās',
        'Simmerath',
        'Saint-Julien-en-Genevois',
        'Dassari',
        'Borodino',
        'Taromske',
        'Odaiyakulam',
        'Overland',
        'Bruck an der Mur',
        'Vatomandry',
        'Mandialaza',
        'Hadjadj',
        'Kavarna',
        'Feke',
        'Rypin',
        'Le Pecq',
        'Hugo',
        'Międzyrzec Podlaski',
        'Zhutian',
        'Elkton',
        'Dhusar Tikāpatti',
        'Srīmushnam',
        'Seven Oaks',
        'Kodikkulam',
        'Vallirana',
        'Kadamakudi',
        'Harlingen',
        'Ascheberg',
        'Kalininsk',
        'Ban Samo Khae',
        'Port Orchard',
        'Rutland',
        'Tlaltetela',
        'Santa Rosa',
        'Tapiramutá',
        'Pewaukee',
        'Lake Country',
        'Colônia Leopoldina',
        'Taucha',
        'Ropczyce',
        'Grottammare',
        'Tanakpur',
        'Saviano',
        'Opelousas',
        'João Neiva',
        'Kallūr',
        'Kabayan',
        'Mamadysh',
        'Ash Shaddādah',
        'Kumano',
        'Batalha',
        'Serinyol',
        'Niceville',
        'Haftkel',
        'Cadoneghe',
        'Lentate sul Seveso',
        'Altay',
        'Ixhuatlán del Sureste',
        'Adrasmon',
        'Maliana',
        'Cholai',
        'Tarancón',
        'Victor',
        'Whakatane',
        'Diang',
        'Breisach am Rhein',
        'Salonta',
        'Pūluvappatti',
        'Dhāni Sukhan',
        'Centre de Flacq',
        'Antrim',
        'Belaur',
        'Kunithala',
        'Longmeadow',
        'Dhūmnagar',
        'Westbury',
        'Vardannāpet',
        'Fatehpur',
        'Ōarai',
        'Krapkowice',
        'Palagiano',
        'Waterville',
        'Shāhpura',
        'Palagonia',
        'Altopascio',
        'Kāndla Port',
        'Chethakal',
        'Mahudha',
        'Ras El Oued',
        'San Bartolomé Jocotenango',
        'Macedo de Cavaleiros',
        'Yorito',
        'Rahden',
        'Lara',
        'Ouadhia',
        'Aïn Mediouna',
        'Ramada',
        'Kovdor',
        'Miguelturra',
        'Laurentides',
        'Windham',
        'Goubellat',
        'Garrel',
        'Novo Airão',
        'Gonohe',
        'Kannampālaiyam',
        'Al Mu‘abbadah',
        'Pijiño del Carmen',
        'Mansingha',
        'Sulat',
        'Akambādam',
        'Kalanādu',
        'Rūpbās',
        'Dolores',
        'Kasimkota',
        'Uusikaupunki',
        'San Antonio Palopó',
        'Vieux Fort',
        'Patton',
        'Diafarabé',
        'Vendôme',
        'Iporã',
        'Hennebont',
        'Sonāpur',
        'Herisau',
        'Ardestān',
        'Quimavango',
        'General Pinedo',
        'Northallerton',
        'Titel',
        'Thibodaux',
        'Sered’',
        'Itatim',
        'Susanville',
        'Wildwood',
        'Santa Juliana',
        'Mirangaba',
        'Hanover',
        'Lindås',
        'Trisshilēri',
        'Usuda',
        'Toyoyama',
        'Hille',
        'Correia Pinto',
        'Guasca',
        'Puduppatti',
        'Minamiminowa',
        'Lübbenau/Spreewald',
        'Lymington',
        'Carugate',
        'Live Oak',
        'Orizona',
        'La Montañita',
        'Dūbacherla',
        'Whitewater',
        'Vynnyky',
        'Campina da Lagoa',
        'Baeza',
        'Lynden',
        'Ribnitz-Damgarten',
        'North Strabane',
        'San Jacinto Amilpas',
        'Zulte',
        'Tarūr',
        'Maruim',
        'Langenberg',
        'Eravattūr',
        'Newberry',
        'Bharanikāvu Tekku',
        'Wolf Trap',
        'Coelemu',
        'Mennecy',
        'Sāhar',
        'Steffisburg',
        'Castillo',
        'Morges',
        'Mwinilunga',
        'Pochampalli',
        'Hayden',
        'North Whitehall',
        'Southern Pines',
        'Kimwanyi',
        'Beko',
        'Dieburg',
        'Taima',
        'Hilvarenbeek',
        'Weston',
        'Niepołomice',
        'Ebersbach an der Fils',
        'Newton',
        'Si Satchanalai',
        'Turkaguda',
        'Damdama',
        'Grain Valley',
        'Buçimas',
        'Beernem',
        'Noto',
        'Aabenraa',
        'Xixucun',
        '’Aïn el Arbaa',
        'Todi',
        'Ifanadiana',
        'Azzano Decimo',
        'San Miguel',
        'Zhirnovsk',
        'Beaucaire',
        'Carterton',
        'Nova Brasilândia d’Oeste',
        'Mount Clemens',
        'Binningen',
        'Yakumo',
        'Tīrān',
        'Copparo',
        'Dhanot',
        'Renai',
        'Melonguane',
        'Namala Guimbala',
        'Bayt Saḩam',
        'Hedongcun',
        'Hartford',
        'Bububu',
        'Novouzensk',
        'Ponders End',
        'Ince-in-Makerfield',
        'Tsukumiura',
        'Colorado do Oeste',
        'Gaillac',
        'Wenwu',
        'Valmontone',
        'Bhit Bhagwānpur',
        'Kājha',
        'Dhemāji',
        'Soavina',
        'La Cruz',
        'Mitchell',
        'Mecitözü',
        'Schiffweiler',
        'Marivorahona',
        'Ərkivan',
        'Mindelheim',
        'Shchuchyn',
        'Ochiai',
        'Zaṟah Sharan',
        'Ban Tha Pho',
        'Northborough',
        'Le Vésinet',
        'Mvurwi',
        'Bengonbeyene',
        'Mezőtúr',
        'Bālkonda',
        'Nioro du Rip',
        'Câmpulung Moldovenesc',
        'Ostroh',
        'Chenlu',
        'Baile an Bhiataigh',
        'Donacarney',
        'Yazu',
        'Capilla de Guadalupe',
        'Porthcawl',
        'Leso',
        'Chubbuck',
        'Molinella',
        'Jiaojiazhuang',
        'Toui',
        'Manūjān',
        'Langenau',
        'Rājāpur',
        'Coto de Caza',
        'Nailsea',
        'Manjīl',
        'Bolotnoye',
        'Boudjima',
        'Longwood',
        'Guinguinéo',
        'Dammāj',
        'Palmitos',
        'Leopoldsburg',
        'Langenthal',
        'Loma de Cabrera',
        'Serpa',
        'Puttalam',
        'Mayoyao',
        'Ashikita',
        'Cheramkod',
        'Solapuram',
        'Pallappatti',
        'Sukhothai',
        'Porto San Giorgio',
        'Tōbetsu',
        'Drăgăşani',
        'Boppard',
        'Kara-Kulja',
        'Frecheirinha',
        'Fort Leonard Wood',
        'Tatahuicapan',
        'Rakvere',
        'Oswestry',
        'Jurāwanpur Karāri',
        'Olmué',
        'Dewangarh',
        'Mangqu',
        'Mölnlycke',
        'Narendrapatnam',
        'Kolakalūru',
        'Antarvedi',
        'Anadyr',
        'Sin-le-Noble',
        'Calumpang',
        'Lucé',
        'Chembagarāmanpudūr',
        'Bilpura',
        'Porto Empedocle',
        'Templin',
        'Ermúa',
        'Julita',
        'Shively',
        'Cumru',
        'Ponsacco',
        'Mouiat Ouennsa',
        'Sundarpur',
        'Crusinallo',
        'Dalanping',
        'Epe',
        'Shizukuishi',
        'Mezőkövesd',
        'Bhagta',
        'Bhagwāngola',
        'Galliate',
        'Pozos',
        'Princeton Meadows',
        'Red Hill',
        'Baynāla',
        'Akdepe',
        'Maullín',
        'Najasa',
        'Zevio',
        'Muhammadābād',
        'Sebt Bni Smith',
        'Cosío',
        'Ban Pang Mu',
        'São Pedro do Sul',
        'Kadaiyam',
        'Belo',
        'Kompalle',
        'Rakovník',
        'Chamgardān',
        'Yığılca',
        'Dixon',
        'Southchase',
        'Cochoapa el Grande',
        'Ernagūdem',
        'Juma Shahri',
        'Ipuã',
        'Kasagi',
        'San Miguel Dueñas',
        'Tung Tau Tsuen',
        'Miyota',
        'Vatlūru',
        'Pequannock',
        'Canapi',
        'Swinton',
        'Bad Neustadt',
        'Frei Paulo',
        'Eppelheim',
        'El Chal',
        'Edakkazhiyūr',
        'Puerto Pimentel',
        'Kežmarok',
        'Machang',
        'Jasol',
        'New Haven',
        'West Ham',
        'Einsiedeln',
        'Chittūr',
        'Sāligrāma',
        'Agrate Brianza',
        'Svetogorsk',
        'Santa Cecília',
        'Ponnamarāvati',
        'Qaryat Sulūq',
        'Scottsboro',
        'La Roda',
        'Ayaş',
        'Mỹ Lương',
        'Palhālan',
        'Shimanto',
        'Codogno',
        'Sovetsk',
        'Greenwood Village',
        'Rakhiv',
        'Souagui',
        'Kukkundūr',
        'Sānātikri',
        'Kumaralingam',
        'Bissegem',
        'Bituruna',
        'Tidjikja',
        'Anār',
        'Kyazanga',
        'Wattignies',
        'Nannestad',
        'Wanda',
        'North Reading',
        'Itariri',
        'Barod',
        'Lwengo',
        'Igaporã',
        'Sainte-Luce-sur-Loire',
        'Bredasdorp',
        'Glória',
        'Dastgerd',
        'Los Osos',
        'Rendon',
        'La Palma',
        'Aleg',
        'El Palmar',
        'Hofgeismar',
        'Edineţ',
        'Matlock',
        'Bokoro',
        'Kučevo',
        'Diapaga',
        'Lahoysk',
        'Certaldo',
        'Ebn Ziad',
        'Aldine',
        'Lezhë',
        'Palanisettipatti',
        'Batavia',
        'Sayō',
        'Sirāli',
        'Meghauna',
        'Morteẕá Gerd',
        'Zhuolan',
        'Teodoro Schmidt',
        'Saffron Walden',
        'Rossville',
        'Kifosso',
        'Bŭston',
        'Cherryland',
        'Pipra Latīf',
        'Kanan',
        'Zelenogradsk',
        'Nobres',
        'Pudtol',
        'Patilār',
        'Daigo',
        'Carthage',
        'White Oak',
        'Kurāwar',
        'Banqiao',
        'Asthānwān',
        'Casalpusterlengo',
        'Vemuladīvi',
        'Maurānwān',
        'Montijo',
        'Dejen',
        'Shipley',
        'Linguère',
        'Ain el Hadid',
        'Ternat',
        'Todmorden',
        'Skhour Rehamna',
        'Tizi-n-Tleta',
        'Tiszaújváros',
        'White Center',
        'Overpelt',
        'Funyan Bīra',
        'Maskanah',
        'Verkhnodniprovsk',
        'Pichhor',
        'Lajkovac',
        'Seekonk',
        'Nkouraba',
        'Budakeszi',
        'Isorana',
        'Ambaguio',
        'Moreni',
        'Skalica',
        'Ban Nong Kathao',
        'Fos-sur-Mer',
        'Hiệp Hòa',
        'Traverse City',
        'Mainburg',
        'Sabana Grande de Palenque',
        'Corbera de Llobregat',
        'Sarai Jattān',
        'Amboavory',
        'Sagala',
        'Baiceng',
        'Neustadt in Holstein',
        'Kyzyl-Suu',
        'Ban Duea',
        'Altunhisar',
        'Noisiel',
        'Guipavas',
        'Andover',
        'Udala',
        'Solec Kujawski',
        'Panorama',
        'Greeneville',
        'Yeşilova',
        'Montivilliers',
        'Differdange',
        'Sandhausen',
        'Turhāpatti',
        'Csongrád',
        'Phulaut',
        'Bilgi',
        'Pianezza',
        'Rosário Oeste',
        'Stapleford',
        'Soliera',
        'Kinna',
        'Mitrapur',
        'Weybridge',
        'Chilamattūru',
        'Madera',
        'Ilovaisk',
        'Kestel',
        'Ventaquemada',
        'Taperoá',
        'Santa Cruz de la Palma',
        'Knaresborough',
        'Kambadūru',
        'Bemidji',
        'Romit',
        'Broxburn',
        'Al Hammam',
        'Arumbāvūr',
        'Bois-d’Arcy',
        'Cipanas',
        'Uthai Thani',
        'Jiblah',
        'Ezzhiliga',
        'Anazzou',
        'Estrela de Alagoas',
        'Dilārpur',
        'Concepción',
        'Nedumudi',
        'Northview',
        'Gehrden',
        'Jamālpur',
        'Ala-Buka',
        'Dacheng',
        'Nymburk',
        'Nuth',
        'Tarascon',
        'Manohisoa',
        'Yankton',
        'Saint-Rambert',
        'Odanāvattam',
        'Vipparla',
        'Machelen',
        'Khao Yoi',
        'Efkarpía',
        'Buggānipalle',
        'Bruntál',
        'Dayton',
        'Clark',
        'Hartselle',
        'Tari',
        'Spittal an der Drau',
        'Robbinsville',
        'Tururu',
        'Corinto',
        'Nancagua',
        'Ezine',
        'Blomberg',
        'Sorrento',
        'Kérouané',
        'Dison',
        'Messias',
        'Glasgow',
        'Żabbar',
        'Meco',
        'Berea',
        'Chokkanāthapuram',
        'Potenza Picena',
        'Grimes',
        'Andrakata',
        'Khampat',
        'Siladon',
        'Sodāg',
        'Lāl Khatangā',
        'Khijri',
        'Nova Olinda',
        'Malhada',
        'Patos',
        'Landázuri',
        'Villa del Rosario',
        'Besana in Brianza',
        'Jackson',
        'Tendrara',
        'Jamao al Norte',
        'Seacombe',
        'Aç-çahrij',
        'Magdiwang',
        'Kürdəmir',
        'Ostashkov',
        'Butiama',
        'Fortuna',
        'Ambongamarina',
        'Ban Khek Noi',
        'Jelcz-Laskowice',
        'Samsikāpuram',
        'Bradley',
        'Raspur Patasia',
        'Fraga',
        'Strzegom',
        'Gopālnagar',
        'Ayutla de los Libres',
        'Robinson',
        'San Policarpo',
        'Homosassa Springs',
        'Cruzília',
        'Discovery Bay',
        'Victoria',
        'Putaendo',
        'Silvi Paese',
        'Thoen',
        'Baildon',
        'Kot Bhāi',
        'Mortara',
        'Krasnovishersk',
        'Trélazé',
        'Talaināyar Agrahāram',
        'Lopare',
        'Kocaköy',
        'Urandi',
        'Rājgaḍh',
        'Frattaminore',
        'Genappe',
        'Tanhuato de Guerrero',
        'Mátészalka',
        'Sava',
        'Vişeu de Sus',
        'Eden',
        'Dvůr Králové nad Labem',
        'Anao-aon',
        'San Luis del Palmar',
        'Clinton',
        'Kumaramputtūr',
        'Bambous',
        'Amatitán',
        'Moul El Bergui',
        'Alta',
        'Uvalde',
        'Bunnik',
        'Manor',
        'Castro Daire',
        'Eldorado',
        'Perivale',
        'Semra',
        'Bennington',
        'Bar',
        'Kanegasaki',
        'Gomboussougou',
        'Daisen',
        'Abaza',
        'Chhājli',
        'Winkler',
        'Chaltyr',
        'Isrāin Kalān',
        'Khamir',
        'Evans',
        'Fruitville',
        'Bad Bramstedt',
        'Haddon',
        'Ratekau',
        'Basārh',
        'Jupi',
        'Maddikera',
        'Odenthal',
        'Amānganj',
        'Undi',
        'Villa Paranacito',
        'Bandar-e Khamīr',
        'Inhuma',
        'Wombwell',
        'São João Evangelista',
        'Burbach',
        'Pihra',
        'Maydolong',
        'Atalaia do Norte',
        'Stanley',
        'Bankheri',
        'Llantrisant',
        'Veys',
        'White',
        'Poronaysk',
        'Itajibá',
        'Mottola',
        'Bhānumukkala',
        'Guerouma',
        'Franklin Park',
        'Belchertown',
        'Broxbourne',
        'Dudhgaon',
        'Uiraúna',
        'Andicun',
        'Elandakuttai',
        'Nagasu',
        'Tenafly',
        'Ladson',
        'Harper Woods',
        'Guamaré',
        'Vennesla',
        'Medrissa',
        'Belāri',
        'Shchigry',
        'Erongarícuaro',
        'Norridge',
        'Shāl',
        'Chaparral',
        'Lagoa da Confusão',
        'Yenice',
        'Maryborough',
        'Clausthal-Zellerfeld',
        'Santa Úrsula',
        'Saint-Charles-Borromée',
        'Chilcuautla',
        'Tēkkampatti',
        'Mātar',
        'Mantua',
        'Lyss',
        'Sidi Ghiles',
        'Yaransk',
        'Atyrá',
        'Ulladulla',
        'Cunit',
        'Sippola',
        'Shedbal',
        'Palmer Ranch',
        'Galanta',
        'Kālikāpur',
        'Motiong',
        'Pereiro',
        'Chemini',
        'Boumahra Ahmed',
        'Peddaboddepalle',
        'Sāhpur',
        'Yasnogorsk',
        'Udayendram',
        'Es Sebt',
        'Vincent',
        'Aïn Jemaa',
        'Beldānga',
        'Privolzhsk',
        'Yasnyy',
        'San Rafael Arriba',
        'Wittenheim',
        'Mangawān',
        'Rātan',
        'Dawlish',
        'Shimohata',
        'Rio Grande City',
        'Puréparo de Echaíz',
        'Project Six',
        'Senlis',
        'Nonkon',
        'Ober-Ramstadt',
        'Penacova',
        'Ovruch',
        'Coulommiers',
        'Boksitogorsk',
        'Bouc-Bel-Air',
        'Vail',
        'Civita Castellana',
        'Volpiano',
        'Springdale',
        'Kinross',
        'Castellarano',
        'Dengshangcun',
        'Palmview',
        'Asbury Park',
        'Pichucalco',
        'Libagon',
        'Featherstone',
        'Mexborough',
        'Melissa',
        'Glenn Dale',
        'Tazert',
        'Anosy Avaratra',
        'La Solana',
        'Marui',
        'Kriel',
        'Aramil',
        'Bétérou',
        'Itanhandu',
        'Metković',
        'Wilmington Island',
        'Kanding',
        'Pimpalgaon Rājā',
        'Mulakaledu',
        'Cowansville',
        'Conchas',
        'Upper Southampton',
        'Johnson City',
        'Rochedale',
        'Billapādu',
        'Vieiro',
        'Neston',
        'Sainte-Anne-des-Plaines',
        'Avsallar',
        'Pinhalzinho',
        'Chervonopartyzansk',
        'Támesis',
        'Charām',
        'Bidston',
        'Rive-de-Gier',
        'Vammala',
        'Bunkeflostrand',
        'Capurso',
        'Shangtianba',
        'Musāpur',
        'Saint-Brice-sous-Forêt',
        'Humayingcun',
        'Macomb',
        'Sumidouro',
        'Phillipsburg',
        'Souk Et-Tleta des Oulad Hamdane',
        'Ljungby',
        'Aravakkurichchi',
        'Tirhassaline',
        'La Apartada',
        'Francheville',
        'Château-Thierry',
        'Barrocas',
        'Česká Třebová',
        'Salesópolis',
        'Monroe',
        'Huasca de Ocampo',
        'Arnedo',
        'Chelsea',
        'Zubin Potok',
        'Hallstahammar',
        'Gagarin Shahri',
        'Northenden',
        'Pyāpali',
        'Kumiyama',
        'Davidson',
        'Chinsali',
        'Chellaston',
        'Ahram',
        'Mahaly',
        'Seshambe',
        'Gombe',
        'Mering',
        'Verwood',
        'Imerimandroso',
        'Adilcevaz',
        'Gölmarmara',
        'Bangassi-Nangou',
        'Longwy',
        'Uchiko',
        'Melouza',
        'Jangīd',
        'Tondangi',
        'Bellavista',
        'Hebli',
        'San Vito al Tagliamento',
        'Dougouni',
        'Nakanojōmachi',
        'Taşucu',
        'Azpeitia',
        'Domaniç',
        'Schwyz',
        'Xiangyuncun',
        'Beech Grove',
        'Dorfen',
        'Adjud',
        'Gulf Shores',
        'Cessnock',
        'Wakuya',
        'Montagu',
        'Fray Luis A. Beltrán',
        'Petlāwad',
        'Espumoso',
        'Seven Pagodas',
        'Salua',
        'Gayéri',
        'Pâ',
        'Niederkrüchten',
        'Redruth',
        'Cumbum',
        'Greenlawn',
        'Waremme',
        'Río San Juan',
        'Puliyūr',
        'Hathīaundha',
        'Moura',
        'Ewa Beach',
        'Sobreda',
        'Douar El Arbaa Bou Quorra',
        'Yauco',
        'Lakhaura',
        'Gaspé',
        'Meruoca',
        'Palmers Green',
        'Totteridge',
        'Nazaré',
        'Gurramkonda',
        'Kontiolahti',
        'Blaydon',
        'Kenmore',
        'San Pedro Tapanatepec',
        'Biatorbágy',
        'Kawaminami',
        'Berkley',
        'Florencia',
        'New Brighton',
        'Biritinga',
        'Kuusamo',
        'Whitman',
        'Mallāpuram',
        'Mashpee',
        'Alice',
        'Vicovu de Sus',
        'Telua',
        'Roanoke Rapids',
        'Depew',
        'Vandalia',
        'América Dourada',
        'Castiglione del Lago',
        'Weißwasser/Oberlausitz',
        'Şefaatlı',
        'Västerhaninge',
        'Horsham',
        'Kawasaki',
        'Pargas',
        'Grodzisk Wielkopolski',
        'Presidente Dutra',
        'Vesoul',
        'Ferndale',
        'Gangāpur',
        'Majiagoucha',
        'Ambohimierambe-Andranofito',
        'New Cassel',
        'Bella Vista',
        'Sansepolcro',
        'Swampscott',
        'Bayserke',
        'Raynham',
        'Ban Na Kham',
        'Warni',
        'Srīvardhan',
        'Kutchan',
        'Jaguapitã',
        'Zacualpan',
        'Klimavichy',
        'Mazagran',
        'Xihuangni',
        'Newport Pagnell',
        'Mahikeng',
        'Vize',
        'Rumst',
        'Koduman',
        'Oulad Bou Rahmoun',
        'Tamaki',
        'Gloversville',
        'Radviliškis',
        'Sakabansi',
        'Miraí',
        'Maesawa',
        'Bhoj',
        'Itki Thākurgaon',
        'Khaira',
        'Piratininga',
        'Chautham',
        'Doumanaba',
        'Tredegar',
        'Kulunda',
        'Bilaua',
        'Hendersonville',
        'Silvino Lobos',
        'Putyvl',
        'Nopala de Villagran',
        'Çifteler',
        'Pacific Grove',
        'Huitzilan',
        'Wülflingen',
        'Tonawanda',
        'Galatone',
        'Brake',
        'Ban Ton Thong Chai',
        'Babhani Bholwa',
        'Tagoloan',
        'Schriesheim',
        'Al Madāmūd',
        'Bhatranha',
        'Kastsyukovichy',
        'Changamkari',
        'Canyon',
        'Cernavodă',
        'Putaparti',
        'Kidal',
        'Capotille',
        'Sooke',
        'Tabhka Khās',
        'Hasanpur',
        'Jadia',
        'Mellila',
        'Bina',
        'Bonheiden',
        'Makapanstad',
        'Sultandağı',
        'Eggertsville',
        'Kohīr',
        'Natividade do Carangola',
        'West Park',
        'Hatton',
        'Durham',
        'Campodarsego',
        'Hasanganj',
        'Talladega',
        'Clevelândia',
        'Ipauçu',
        'Mani',
        'Peddapalle',
        'Devendranagar',
        'Hazel Park',
        'Leninsk',
        'Neustadt bei Coburg',
        'Front Royal',
        'Ut Bulag',
        'Spring Creek',
        'Bad Wurzach',
        'Olonne-sur-Mer',
        'Montargis',
        'Sabou',
        'Alvinópolis',
        'Andondabe',
        'Pedara',
        'Tatarikan',
        'Kanungu',
        'Echuca',
        'Bolivia',
        'Markgröningen',
        'Patnanungan',
        'Rizal',
        'Royse City',
        'Rajni',
        'Mineral Wells',
        'Ekma',
        'Kawagoe',
        'Perchtoldsdorf',
        'Amlash',
        'La Magdalena Tlaltelulco',
        'Pontinia',
        'Boskoop',
        'Saint-Avold',
        'Tubod',
        'Murray Bridge',
        'Langelsheim',
        'Gondomar',
        'Glasgow',
        'Revūr',
        'Kamitonda',
        'Burshtyn',
        'Ylivieska',
        'Ochanthururtha',
        'Carrières-sur-Seine',
        'El Arenal',
        'Villa Ocampo',
        'Chépica',
        'Grenzach-Wyhlen',
        'Mollerusa',
        'Frederick',
        'Contamana',
        'Monroe',
        'Tone',
        'Le Hochet',
        'Corupá',
        'Ritterhude',
        'Capela',
        'Satyāmangala',
        'Zozocolco de Hidalgo',
        'Filiaşi',
        'Fritzlar',
        'Sabbah',
        'Udiyāvara',
        'Ban Ho Mae Salong',
        'South Farmingdale',
        'Ferreiros',
        'Cuevas del Almanzora',
        'Lebanon',
        'Swallownest',
        'Midar',
        'Kutavettūr',
        'Brasilândia',
        'Bangui',
        'Ban Mae Sun Luang',
        'Puerto Salgar',
        'Islāmpur',
        'Boa Esperança do Sul',
        'Netherton',
        'Aruvikkara',
        'Lockhart',
        'Kaintragarh',
        'Hudiksvall',
        'Landsberg',
        'Konārka',
        'Shamsa',
        'Potsdam',
        'Westervoort',
        'Busko-Zdrój',
        'Issoire',
        'Andorinha',
        'Canet de Mar',
        'Cassano delle Murge',
        'Struga',
        'Dionísio Cerqueira',
        'Dembecha',
        'Naduvattam',
        'Saidpur',
        'Alpedrete',
        'Witzenhausen',
        'Wallan',
        'Novyi Buh',
        'Sárvár',
        'Barajor',
        'Pavannur',
        'Verdal',
        'California City',
        'Acala',
        'Kezi',
        'Râs Baalbek',
        'Quthing',
        'Manambondro',
        'Milenaka',
        'Soalala',
        'Ambodiriana',
        'Befasy',
        'Kopoky',
        'Ramainandro',
        'Ambinanindrano',
        'Ambatomanjaka',
        'Andranovelona',
        'Ianantsony',
        'Analamary',
        'Imanombo',
        'Beroy Atsimo',
        'Alarobia Bemaha',
        'Talata Ampano',
        'Ambatoharanana',
        'Sahave',
        'Bevoay',
        'Anahidrano',
        'Ambahive',
        'Ifatsy',
        'Ankisabe',
        'Anjoma-Ramartina',
        'Lokomby',
        'Behisatse',
        'Iharan̈a',
        'Manandona',
        'Antanimenabaka',
        'Marofototra',
        'Tsiatajavona-Ankaratra',
        'Antsoso',
        'Ambesisika',
        'Ankilimivory',
        'Antanifotsy',
        'Wān Long',
        'Kyaukmyaung',
        'Aiyetoro Gbede',
        'Amawom',
        'Rāmechhāp',
        'Bhimphedi',
        'Salyan',
        'Richmond',
        'Mian Sahib',
        'Awan Patti',
        'Koungheul',
        'Bakel',
        'Yufle',
        'Kuljibrīn',
        'Sarmadā',
        'Gammarth',
        'Özdere',
        'Kalongo',
        'Matuga',
        'Zombo',
        'Mutukula',
        'Chaguaramas',
        'Clarines',
        'Fayrōz Kōh',
        'Taywarah',
        'Barakī',
        'Spitak',
        'Villa Ojo de Agua',
        'Bāisāri',
        'Jamaica',
        'Guayos',
        'Villaviciosa',
        'Saint-Avertin',
        'Hessle',
        'Tillor Khurd',
        'Chettikulam',
        'Aivanallur',
        'Shafinagar',
        'Damalcheruvu',
        'Cortalim',
        'Majhgawān',
        'Hombal',
        'Bellatti',
        'Singhānwāla',
        'Hullahalli',
        'Muttamtura',
        'Sathamba',
        'Valattūr',
        'Nedumpura',
        'Turori',
        'Khāndhār',
        'Shirud',
        'Galatge',
        'Vasa',
        'Barţalah',
        'Amirlī',
        'Yinhua',
        'Winkfield',
        'Talpa de Allende',
        'Worthington',
        'Henderson',
        'Buda',
        'Highland Park',
        'Sanyi',
        'Shambu',
        'Huchuan',
        'Anororo',
        'Santa',
        'Şüvəlan',
        'Büttelborn',
        'Antequera',
        'Kisvárda',
        'Shklow',
        'Djambala',
        'La Gloria',
        'Pyryatyn',
        'Kaguchi',
        'Salinas da Margarida',
        'Esbiaat',
        'Sant’Arpino',
        'Calanogas',
        'Pestovo',
        'Lapseki',
        'Yelur',
        'Māndleshwar',
        'Āndipālaiyam',
        'Obando',
        'Kurichchi',
        'Belkheir',
        'Bouchegouf',
        'Texenna',
        'Petersfield',
        'Casalmaggiore',
        'Candói',
        'Távros',
        'Chirpan',
        'Porcia',
        'Castelnuovo Rangone',
        'Laurinburg',
        'Chrysoúpoli',
        'Avanos',
        'Great Falls',
        'Mastic',
        'Kenora',
        'Kenley',
        'Hārohalli',
        'Douar Souk L‘qolla',
        'Santa Brígida',
        'El Hadjira',
        'Tabant',
        'Paralímni',
        'Saint-Servan-sur-Mer',
        'West University Place',
        'Baucau',
        'Cold Lake',
        '’Tlat Bni Oukil',
        'Veliki Preslav',
        'Goluwāli',
        'Lakeland North',
        'Weigelstown',
        'Santa Luzia',
        'Metuchen',
        'Bludenz',
        'Vianópolis',
        'Ādra',
        'Paris',
        'Monkey Bay',
        'Sidi Zouine',
        'Çatalpınar',
        'Dolo',
        'Coconuco',
        'Finale Emilia',
        'Sudbury',
        'Wyke',
        'Ramonville-Saint-Agne',
        'Lambidou',
        'Vallūr',
        'Mitane',
        'Hereford',
        'Mendrisio',
        'Bissendorf',
        'Baiersbronn',
        'Joaíma',
        'Serhetabat',
        'Grande Saline',
        'Restinga Sêca',
        'Majholi',
        'Newtown',
        'Avrillé',
        'Ganjām',
        'Świdwin',
        'Kimyogarlar',
        'La Flèche',
        'Oued Jdida',
        'Schiffdorf',
        'Fairview',
        'Calkiní',
        'Chāndi',
        'Riverdale',
        'Iṭaharā',
        'Jisr ez Zarqā',
        'Malsch',
        'Ahigbé Koffikro',
        'Beckingen',
        'Chemmanam',
        'Gourrama',
        'Nalerigu',
        'Usingen',
        'East Rancho Dominguez',
        'Brooks',
        'Yellāreddi',
        'Lānjī',
        'Sembedu',
        'Lakkundi',
        'Tecuala',
        'Castañuelas',
        'Nakagawa',
        'Nerchinsk',
        'Pursa',
        'Washington',
        'Kunnumēl',
        'Krasnohorivka',
        'Escaldes-Engordany',
        'Lolotique',
        'Lālejīn',
        'Mykolaiv',
        'Kew Green',
        'Hickory Hills',
        'Lagangilang',
        'San Juan',
        'Barra do Sul',
        'Sardinal',
        'Badarpur',
        'Kela Khera',
        'Ammūr',
        'Aghbal',
        'Vinanivao',
        'Sindhnūr',
        'Aizubange',
        'Kentville',
        'Castanet-Tolosan',
        'Morlaix',
        'Poděbrady',
        'Torelló',
        'Aiyampuzha',
        'Holliston',
        'Kurate',
        'Lake Arbor',
        'Vakhsh',
        'Qumqo‘rg‘on',
        'Chapelle-lez-Herlaimont',
        'Beizhou',
        'Quan’ancun',
        'Cumayeri',
        'Tsabit',
        'Sisian',
        'Coquimatlán',
        'Naxxar',
        'Nastola',
        'Valangimān',
        'Dimiao',
        'Stolac',
        'Swansea',
        'Sassenheim',
        'Jhandāpur',
        'Sahakevo',
        'Chemax',
        'West Wickham',
        'Parapatti',
        'Cerro Maggiore',
        'Kizel',
        'Bohodukhiv',
        'Tarumã',
        'Branchburg',
        'Bacuag',
        'Przeworsk',
        'Doukouya',
        'Champāpur',
        'Highland Springs',
        'Ngolobougou',
        'Grefrath',
        'San Tomas',
        'Lidzbark Warmiński',
        'Sera',
        'Le Raincy',
        'Ālampur',
        'Oldsmar',
        'Greensburg',
        'Shepshed',
        'Nisko',
        'Hanamsāgar',
        'Fiorenzuola d’Arda',
        'Sopelana',
        'Breaza',
        'Ravels',
        'Udelnaya',
        'Tayum',
        'Harchoune',
        'Bailleul',
        'Hedehusene',
        'Boulder City',
        'Yuchi',
        'Cujubim',
        'Vellālāpuram',
        'Barharwā',
        'Ayní',
        'Grecia',
        'Tamani',
        'Kondrovo',
        'Chāilāha',
        'Drahichyn',
        'Chegdomyn',
        'Ambérieu-en-Bugey',
        'Nagarūr',
        'Red Oak',
        'Sterling',
        'Qiaoyang',
        'Mori',
        'Forest City',
        'Bromborough',
        'Berlare',
        'Salida',
        'Niquinohomo',
        'Jever',
        'Chīpurupalle',
        'Niedernhausen',
        'Madhuban',
        'Tremelo',
        'Terra Rica',
        'Gabane',
        'Belmont',
        'Avalpūndurai',
        'Koumaïra',
        'Huitán',
        'Nzalat Laadam',
        'Varnsdorf',
        'San Mateo del Mar',
        'Isny im Allgäu',
        'San Vito',
        'Bela Vista do Paraíso',
        'Haga',
        'Le Bourget',
        'Chislehurst',
        'Navashino',
        'Jinta',
        'Taurianova',
        'Timissa',
        'Illzach',
        'Botelhos',
        'Monterrey',
        'Betsukai',
        'Hariharpāra',
        'Grândola',
        'Tall Abyaḑ',
        'Hückeswagen',
        'Mercaderes',
        'Kumāramangalam',
        'Lemon Hill',
        'Cabral',
        'Vuhledar',
        'Tendūkheda',
        'Tadjourah',
        'Nevel',
        'Ponto Novo',
        'Schwabmünchen',
        'Cabañas',
        'Cherrapunji',
        'Port Antonio',
        'Kumārīpur',
        'Nenmini',
        'Fort Payne',
        'Tanque Verde',
        'Kochkor-Ata',
        'Warwick',
        'Huixcolotla',
        'Petrovaradin',
        'Cândido de Abreu',
        'Ilkley',
        'Patrocínio Paulista',
        'Józefosław',
        'Radzyń Podlaski',
        'Benipati',
        'Comox',
        'Astravyets',
        'Ighrem n’Ougdal',
        'Cerritos',
        'Buerarema',
        'Whitefish Bay',
        'Ogíjares',
        'Dehmoí',
        'La Carolina',
        'San Julian',
        'Anta',
        'Gryazovets',
        'Al Mālikīyah',
        'Weizhou',
        'Muthallath al Azraq',
        'Fameck',
        'Sullivan',
        'Devarapalle',
        'Merate',
        'Pālod',
        'Città Sant’Angelo',
        'Baguley',
        'Waunakee',
        'Kapiri Mposhi',
        'Shuichecun',
        'Dęblin',
        'Stony Point',
        'Łapy',
        'Pattensen',
        'Sozopol',
        'Āfdem',
        'Alexander City',
        'New Paltz',
        'Konina',
        'Araputanga',
        'Itamonte',
        'Fiadanana',
        'Lampa',
        'Bni Darkoul',
        'Oulad Dahmane',
        'Armagh',
        'Amasra',
        'Sanquelim',
        'Hilchenbach',
        'Montmagny',
        'Pontivy',
        'Sítio do Quinto',
        'Hanover',
        'Laterza',
        'Williamstown',
        'Tabatinga',
        'Washington',
        'Clarksdale',
        'Romsey',
        'Kamrāwān',
        'Piney Green',
        'Adams',
        'Romilly-sur-Seine',
        'Zítsa',
        'Curtorim',
        'Harhorin',
        'Hasanpur Juned',
        'Shlisselburg',
        'Selb',
        'Hudson',
        'Chitila',
        'Lummen',
        'Krasnogvardeyskoye',
        'Villers-lès-Nancy',
        'Diankabou',
        'Bo’ness',
        'Obanazawa',
        'Tavares',
        'Cherupazhasshi',
        'Much',
        'Luduş',
        'Cumpăna',
        'Haubourdin',
        'Hirono',
        'Matungao',
        'Penrith',
        'Maqat',
        'Deming',
        'Tiltil',
        'El Ateuf',
        'Vohipeno',
        'Briniamaro',
        'Balham',
        'Pincourt',
        'Mozarlândia',
        'Teignmouth',
        'Perunkolattūr',
        'Sinmpérékou',
        'Sasso Marconi',
        'Danilov',
        'Masallı',
        'Entre Rios de Minas',
        'Ijra',
        'Eshowe',
        'Friedland',
        'Banbridge',
        'Cocorná',
        'Havre de Grace',
        'Punata',
        'Dennis',
        'Driouch',
        'San Juan del Sur',
        'Törökbálint',
        'Garhpura',
        'Lilburn',
        'Larkhall',
        'Mount Vernon',
        'Mississippi Mills',
        'Ramsey',
        'Youdiou',
        'Vaddāpalli',
        'Tepetzintla',
        'Breukelen',
        'Sukhinichi',
        'Bodmin',
        'Andūrkonam',
        'Belokurikha',
        'Çiçekdağı',
        'Pilar',
        'Labiod Medjadja',
        'Sakhā',
        'Río Negro',
        'Hammonton',
        'Pindaí',
        'Chaabat el Leham',
        'Pallasovka',
        'Joubb Jannîne',
        'Pine',
        'Rellingen',
        'Roseau',
        'Great Bend',
        'Loreto',
        'Conwy',
        'Neqāb',
        'Matsushige',
        'Arzgir',
        'Greendale',
        'Isla Vista',
        'Muy Muy',
        'Bāladharmāram',
        'San Ġwann',
        'Katyr-Yurt',
        'Prestwick',
        'Tsivilsk',
        'Alagoinha',
        'Douar Oulad Driss',
        'Oulad Driss',
        'Riofrío',
        'Neustadt an der Donau',
        'Sāhit',
        'Antioch',
        'Cēsis',
        'Mediouna',
        'Troon',
        'Chhāpia',
        'Androrangavola',
        'Tarumirim',
        'Paola',
        'San Ramón',
        'Uch-Korgon',
        'Ochakiv',
        'Triunfo',
        'Purísima de la Concepción',
        'Plérin',
        'Shimubi',
        'Santa Croce sull’ Arno',
        'Cuarte de Huerva',
        'Hima',
        'Tirumalaiyampālaiyam',
        'Hooksett',
        'Gladeview',
        'Kōteshwar',
        'Harsefeld',
        'Villa Alegre',
        'Surbo',
        'Ospitaletto',
        'Nishinoomote',
        'Onklou',
        'Guilford',
        'Tlaxcala',
        'Carmen de Areco',
        'Lope de Vega',
        'Kodāngipatti',
        'Baie du Tombeau',
        'Groß-Zimmern',
        'Kurumbalūr',
        'Ankadinandriana',
        'Collecchio',
        'Coremas',
        'Masty',
        'Paramati',
        'Hershey',
        'Rubiera',
        'Owosso',
        'Melksham',
        'Mahatsinjo',
        'Ngoulemakong',
        'Dinan',
        'Ranomafana',
        'Thuin',
        'Laxou',
        'Wibsey',
        'Corridonia',
        'Bar-le-Duc',
        'Fraser',
        'Lake Stickney',
        'Mānantheri',
        'Béré',
        'Bellaa',
        'Pudur',
        'Malvik',
        'Las Guáranas',
        'Santa Marta de Tormes',
        'Saint-Omer',
        'Kushimoto',
        'Dzuunmod',
        'Kysucké Nové Mesto',
        'Condoto',
        'Ifrane',
        'Piraziz',
        'Tapes',
        'Kamalganj',
        'St. Clair',
        'Rasulpur Dhuria',
        'Złotoryja',
        'Capinópolis',
        'Mmopone',
        'Djanet',
        'At-Bashy',
        'Langwedel',
        'Malo',
        'Kujūkuri',
        'North New Hyde Park',
        'Mulakād',
        'Lognes',
        'Great Baddow',
        'Boguszów-Gorce',
        'Kaonke',
        'Sprimont',
        'Ferrier',
        'Mangamila',
        'Mineral del Monte',
        'Vubatalai',
        'Gouré',
        'Sisa',
        'Técpan de Galeana',
        'Limanowa',
        'Néa Alikarnassós',
        'Woudrichem',
        'Mahinog',
        'Bilozerske',
        'Ibitiara',
        'Tiruvāsaladi',
        'Bílina',
        'Sepīdān',
        'Terrace',
        'Ternitz',
        'Cómbita',
        'Farnborough',
        'Rosà',
        'Rio Piracicaba',
        'Teixeira',
        'Flower Hill',
        'Pinia',
        'Djebahia',
        'Misaki',
        'Mercier',
        'Parlier',
        'Lumino',
        'Skipton',
        'Chippewa Falls',
        'Narragansett',
        'Bahçesaray',
        'Dahé',
        'Abony',
        'Alangānallūr',
        'Zontecomatlán de López y Fuentes',
        'Port Glasgow',
        'Magione',
        'Vredefort',
        'Kalocsa',
        'Groveton',
        'Boston',
        'Negreşti-Oaş',
        'Cambuci',
        'Guajiquiro',
        'Kankipādu',
        'Vemulūru',
        'Roncade',
        'Kiri',
        'Plochingen',
        'Paliaturutu',
        'Bartabwa',
        'Batuan',
        'Huasuo',
        'Castellammare del Golfo',
        'Anzegem',
        'Lapua',
        'Langenselbold',
        'Hatten',
        'İspir',
        'Tullamore',
        'Lowton',
        'Carmignano',
        'Suō-Ōshima',
        'Río Branco',
        'Saint-Maurice',
        'Sinha',
        'Sveti Ivan Zelina',
        'Hanover',
        'Agidel',
        'Arroio dos Ratos',
        'Saint-Jean-de-Luz',
        'Uzun',
        'Cabarete',
        'Nova Ponte',
        'Verrières-le-Buisson',
        'Montabaur',
        'Wilbraham',
        'Holalagondi',
        'Jędrzejów',
        'Tonya',
        'Guastalla',
        'Sātyūn',
        'Villa Yapacaní',
        'Yokoshiba',
        'Kelamangalam',
        'Dinmānpur',
        'Hutchinson',
        'Sangota',
        'Palmeirais',
        'Čajetina',
        'Arnouville-lès-Gonesse',
        'Sigulda',
        'Thillangéri',
        'Tostado',
        'Balīgaon',
        'Broadlands',
        'Lucao',
        'Ząbkowice Śląskie',
        'Ágios Athanásios',
        'Lauda-Königshofen',
        'Tostedt',
        'Aleksandrovka',
        'Severínia',
        'Red Bluff',
        'Falls Church',
        'Talya',
        'Port Royal',
        'Kānjikkovil',
        'Key Biscayne',
        'Tzaneen',
        'Jitwārpur Nizāmat',
        'Pineto',
        'Wawizaght',
        'Lakkampatti',
        'Salem Lakes',
        'Burbage',
        'Edenburg',
        'Münster',
        'Palayad',
        'Akora',
        'Benfreha',
        'Paniem',
        'Breza',
        'Kottacheruvu',
        'Naivasha',
        'Qazyqurt',
        'Mocha',
        'Jastrebarsko',
        'Darpa',
        'Debar',
        'Essau',
        'Tiruppāchūr',
        'San Francisco',
        'Relangi',
        'Saint-Genis-Pouilly',
        'Belvedere Park',
        'Ban San Phak Wan Luang',
        'Loviisa',
        'Stein bei Nürnberg',
        'Thorpe Saint Andrew',
        'Andoain',
        'Bijaipur',
        'Bicas',
        'Solila',
        'Ratzeburg',
        'Clarkston',
        'Adekar Kebouche',
        'Anamorós',
        'El Paisnal',
        'Wilsdruff',
        'Biscarrosse',
        'Halwāra',
        'Altınekin',
        'Iakora',
        'Cecil',
        'Marofinaritra',
        'Gundlapelle',
        'Merimandroso',
        'Les Sables-d’Olonne',
        'Ayt ’Attou ou L’Arbi',
        'Stanytsia Luhanska',
        'Ottweiler',
        'Ban Du',
        'Pindorama',
        'Blidet Amor',
        'Australind',
        'Ust’-Ordynskiy',
        'Jun Bel',
        'Bom Jesus do Galho',
        'Arbon',
        'Bakun',
        'Siswa',
        'Abensberg',
        'Nāgireddipalli',
        'Moultrie',
        'Rio Paranaíba',
        'Falagueira',
        'Sarmīn',
        'Aïn Nouissy',
        'Kusāha',
        'Ban Kaeng',
        'Tsimlyansk',
        'Pualas',
        'Kannamanāyakkanūr',
        'Reidsville',
        'Cadaval',
        'Dargahān',
        'Middlesex',
        'Água Boa',
        'Vitomiricë',
        'Sha Kok Mei',
        'Pandhāna',
        'Herselt',
        'Minignan',
        'Corinth',
        'Anapoima',
        'Moston',
        'Sonora',
        'Saint-Fargeau',
        'Pakaryā Harsidhi',
        'Tabuse',
        'Lebanon',
        'Diéramana',
        'Rebouças',
        'Guachochi',
        'Mbuyapey',
        'Neu-Anspach',
        'Immenstadt im Allgäu',
        'Mosina',
        'Montesson',
        'Valea Lupului',
        'Olsberg',
        'McCalla',
        'Quinapundan',
        'Chelsfield',
        'Oria',
        'Aberdare',
        'Suwāsra',
        'San Martín de Loba',
        'Eberbach',
        'Farap',
        'Angus',
        'Nadisāl',
        'Gavinivāripālem',
        'Surajpura',
        'Sadhoa',
        'Sokone',
        'Oyam',
        'Guanduqiao',
        'West Lincoln',
        'Ceres',
        'Yanhewan',
        'Ouled Sidi Brahim',
        'Ludvika',
        'Tenente Portela',
        'Água Fria',
        'San Gabriel',
        'Lingāl',
        'Thornbury',
        'Morton',
        'Chinnavādampatti',
        'Santa Catalina',
        'Poço das Trincheiras',
        'Uckfield',
        'Lākho',
        'Goshen',
        'Uhingen',
        'Barhni',
        'Morris',
        'Deysbrook',
        'Harduli',
        'Ahmed Rachedi',
        'Askim',
        'Murra',
        'Madagh',
        'Boula’wane',
        'Araruna',
        'Cehegín',
        'Burglengenfeld',
        'Krasnyy Kut',
        'Flers',
        'Valenton',
        'San Carlos',
        'Babhangāwān',
        'Fujisaki',
        'Mésa Geitoniá',
        'Eisenstadt',
        'Barroquinha',
        'Nawada',
        'Buggenhout',
        'Turnov',
        'Paraíso',
        'Nampicuan',
        'Qobustan',
        'Haacht',
        'Tlayacapan',
        'Pākala',
        'Markacho',
        'Roh',
        'Huizúcar',
        'Khallikot',
        'Lockhart',
        'Santaquin',
        'Ormeau',
        'Tiruvalanjuli',
        'Sommacampagna',
        'Plumtree',
        'Ichikawamisato',
        'Armthorpe',
        'Lagunia Surajkanth',
        'Ankaramena',
        'Yangasso',
        'Lopik',
        'Sassenberg',
        'Santo Domingo Xenacoj',
        'Larena',
        'Southgate',
        'Tarlapalli',
        'Srīnagar',
        'Scottsbluff',
        'Sopetrán',
        'Jasper',
        'Santa María',
        'Shatrāna',
        'Robbinsdale',
        'Gwanda',
        'Jacaraú',
        'Narahia',
        'Bāra',
        'Neubiberg',
        'Brwinów',
        'Roquebrune-sur-Argens',
        'Asagiri',
        'Short Hills',
        'West Freehold',
        'Tavagnacco',
        'Monticello',
        'Anderson Creek',
        'Tulchyn',
        'Chenôve',
        'Nolensville',
        'Lagoa',
        'Alginet',
        'Alloa',
        'Aït Yaïch',
        'Vinci',
        'Verona',
        'Eggenfelden',
        'Chatham',
        'Black Forest',
        'Claxton Bay',
        'Pahou',
        'Jacaraci',
        'Bokod',
        'Ban Ao Nang',
        'Barranco de Loba',
        'Sirmaur',
        'Araceli',
        'Rauch',
        'Ampohibe',
        'Perai',
        'Sainte-Maxime',
        'Nether Providence',
        'Busembatia',
        'Eching',
        'Kolwāra',
        'Caln',
        'Ascensión',
        'Morroa',
        'Baqiābād',
        'Bagulin',
        'Pilisvörösvár',
        'Szarvas',
        'Satwās',
        'Aartselaar',
        'Davorlim',
        'Alūr',
        'Ulchin',
        'Dollis Hill',
        'Painan',
        'Dornakal',
        'Lavaltrie',
        'Villa Vásquez',
        'Kalývia Thorikoú',
        'Tirkarūr',
        'Tagalft',
        'Kharki',
        'Cayey',
        'Polyarnyye Zori',
        'Mantaly',
        'Korsør',
        'Barhi',
        'Spenge',
        'Vadugapatti',
        'Măgurele',
        'Gorbea',
        'Killarney',
        'Brainerd',
        'General MacArthur',
        'San Sebastián',
        'Saint-Brevin-les-Pins',
        'Château-d’Olonne',
        'Hopatcong',
        'Halawa',
        'Ghora Gali',
        'Skippack',
        'Pomorie',
        'Chik Bānavar',
        'Yéréré',
        'Valbom',
        'Paidiipalli',
        'Itaú de Minas',
        'Mutyālapalle',
        'Minneola',
        'Wymondham',
        'Nagra',
        'Haldībāri',
        'Dumas',
        'North Auburn',
        'Lindenhurst',
        'Baoshan',
        'Hetton le Hole',
        'Bo',
        'Alindao',
        'Tūprān',
        'Wangjiaxian',
        'San Antonio',
        'Alexandria',
        'Choszczno',
        'Sierra Vista Southeast',
        'Gernsbach',
        'Sorso',
        'Muqui',
        'West Bradford',
        'Chiconquiaco',
        'Edgewater',
        'Lubbeek',
        'Lohfelden',
        'Kihoku',
        'Majia',
        'Hargāwān',
        'Üzümlü',
        'Liestal',
        'San Cristóbal',
        'Stäfa',
        'Rheydt',
        'Anomabu',
        'Zaysan',
        'Mohanpur Gaughāta',
        'Yamada',
        'Küsnacht',
        'Washington Court House',
        'Maria',
        'Kārempūdi',
        'Amersham',
        'Vāsad',
        'West Derby',
        'Lake Wylie',
        'East Bridgewater',
        'Ourtzagh',
        'Puraini',
        'Kamatgi',
        'Natchez',
        'Valday',
        'Impruneta',
        'Moorestown-Lenola',
        'New Hamburg',
        'Opwijk',
        'Villa Rica',
        'Saint Joseph',
        'Zolote',
        'Tezu',
        'Ożarów Mazowiecki',
        'Elizabethton',
        'Doria Sonāpur',
        'Penuganchiprolu',
        'Ozark',
        'Sailāna',
        'Cowes',
        'West Drayton',
        'Nacozari Viejo',
        'Rosarno',
        'Churumuco de Morelos',
        'Patiāli',
        'Miena',
        'Mercerville',
        'West Nipissing / Nipissing Ouest',
        'Barela',
        'Riegelsberg',
        'Santa Rosalía',
        'Pineville',
        'Sidi Azzouz',
        'Markdorf',
        'Volgorechensk',
        'Serinhisar',
        'Artsyz',
        'Jitaúna',
        'Miho',
        'Alamo',
        'Löbau',
        'Thondiamannu',
        'Simões',
        'Tornesch',
        'Rehlingen-Siersburg',
        'Breyten',
        'Zaouiat Moulay Bouchta El Khammar',
        'Ihaddadene',
        'Niederzier',
        'Baulia',
        'Bihpuriāgaon',
        'Bradley Gardens',
        'Livingston',
        'Zhutang',
        'Jiaoxiyakou',
        'Arlington',
        'Balangiga',
        'Tianguistengo',
        'Medvezhyegorsk',
        'Narhan',
        'Bhānukumāri',
        'Wilkinsburg',
        'Alto Longá',
        'Strathmore',
        'Pont-à-Mousson',
        'Leno',
        'Hindalgi',
        'Monte Alegre de Sergipe',
        'San Andrés Timilpan',
        'Pazar',
        'Conisbrough',
        'Muttukūru',
        'Belazao',
        'Mittweida',
        'Lakhsetipet',
        'Hasami',
        'Horta',
        'Yanshuiguan',
        'Brookside',
        'Zapolyarnyy',
        'Lakinsk',
        'Mascali',
        'Anpachi',
        'Baia-Sprie',
        'Saldaña',
        'Pālda',
        'Wālājābād',
        'Vil’nyans’k',
        'Klášterec nad Ohří',
        'Rottingdean',
        'High River',
        'Gua',
        'Jori Kalān',
        'Tepecoyo',
        'Polāia Kalān',
        'Chikhli Kalān',
        'Ustka',
        'Sadovoye',
        'Kottavalasa',
        'Tenerife',
        'Méru',
        'Kizhariyūr',
        'Saddle Brook',
        'Balangkas',
        'Estremoz',
        'Saint-Paul-lès-Dax',
        'Doctor Juan Eulogio Estigarribia',
        'Kārkūdalpatti',
        'Vīraghattam',
        'Radzymin',
        'Lotte',
        'Salīmpur',
        'Peumo',
        'Iflissen',
        'Rokycany',
        'Sumbas',
        'Lengede',
        'Mūrak',
        'Māngoli',
        'Tsukawaki',
        'Amay',
        'Sarqan',
        'Santo Antônio dos Lopes',
        'Huliyār',
        'Woippy',
        'Fakola',
        'Novohrodivka',
        'Nagongera',
        'Romitan Shahri',
        'Sariosiyo',
        'Kidbrooke',
        'Roldán',
        'Kambainellūr',
        'Rock Ferry',
        'Baruun-Urt',
        'Coronel Bogado',
        'Dayr al Barshā',
        'Shirley',
        'Neutraubling',
        'Pollokshaws',
        'Iona',
        'Algarrobo',
        'Algarrobo',
        'Bad Camberg',
        'Korangal',
        'Omegna',
        'Eski Īkan',
        'Ambohimanga Atsimo',
        'Orbetello',
        'Našice',
        'Sison',
        'Chiaravalle',
        'Chard',
        'Anantarāzupeta',
        'Bariariyā',
        'Loganville',
        'Bousso',
        'Ban Don Kaeo',
        'Grafing bei München',
        'Brandermill',
        'East Greenwich',
        'Chicago Ridge',
        'Shimada',
        'Gualdo Tadino',
        'Jericho',
        'Ungutūru',
        'Benito Juárez',
        'Biały Kamień',
        'Bikkavolu',
        'Somerton',
        'El Barrio de la Soledad',
        'Puxinanã',
        'Patcham',
        'Santa Maria a Vico',
        'West Lealman',
        'Gantt',
        'Yakhroma',
        'Razan',
        'Nkhata Bay',
        'Mongeri',
        'Ban Nong Kula',
        'Ucar',
        'Ghaura',
        'Vedasandūr',
        'Bedford',
        'Laï',
        'Pak Thong Chai',
        'Ban Tha Thong',
        'Ciénega de Flores',
        'Farmington',
        'Bökönbaev',
        'Manoharpur',
        'Det Udom',
        'Saint-Gilles',
        'Mionica',
        'Bollullos par del Condado',
        'Weston',
        'Mannūr',
        'Marechal Floriano',
        'Bois-Guillaume',
        'Morokweng',
        'Poynton',
        'Ait Yazza',
        'La Carlota',
        'Chikitigarh',
        'Ben Taieb',
        'Langerwehe',
        'Haramachida',
        'Levoča',
        'Mondolfo',
        'Svrljig',
        'Imbaú',
        'Rāje',
        'Aïbongo',
        'Maria da Fé',
        'Prieska',
        'Tafrant',
        'Brazópolis',
        'Seacroft',
        'Kukdeshwar',
        'Opera',
        'Van Buren',
        'Arvika',
        'Kōdanād',
        'Wingene',
        'Horw',
        'Brühl',
        'Kelafo',
        'Greenwood',
        'Neftçala',
        'Padavēdu',
        'Manuel Ribas',
        'Sabinópolis',
        'Toubakoro',
        'Sines',
        'Saren',
        'North Mankato',
        'Knić',
        'Mahavanona',
        'Kāshti',
        'Mapanas',
        'Bainbridge',
        'Ömerli',
        'Pannimadai',
        'Zhabinka',
        'San Giorgio Ionico',
        'Forfar',
        'Nagardevla Budrukh',
        'Little Falls',
        'Bou Izakarn',
        'Al Mu‘aḑḑamīyah',
        'Uxbridge',
        'Douar Hammadi',
        'Jacksonville',
        'Ochër',
        'Borborema',
        'Elk Plain',
        'Kouroussa',
        'Auray',
        'Ishikawa',
        'Pelham',
        'Maldon',
        'Terkuvengānallūr',
        'Barkuhi',
        'Bennane',
        'Lich',
        'Caldas',
        'Crépy-en-Valois',
        'Shichinohe',
        'Aqköl',
        'Wennigsen',
        'Zeven',
        'Chebrolu',
        'Oleggio',
        'Cedar Lake',
        'Willowick',
        'Aḑ Ḑab‘ah',
        'Kearsley',
        'Venëv',
        'Mykolaivka',
        'Mittegroßefehn',
        'Wulong',
        'Luling',
        'Campos Altos',
        'Antas',
        'Tapolca',
        'Kartuzy',
        'Siddāpur',
        'Ālangudi',
        'Rescaldina',
        'Minamiaizu',
        'Fallsburg',
        'Náfplio',
        'Bensville',
        'Pānr',
        'Kaoma',
        'Bochil',
        'Assenede',
        'Adustina',
        'Midlothian',
        'Boa Esperança',
        'Maisach',
        'Mill Valley',
        'Saraykent',
        'Feldkirchen',
        'Tranås',
        'Almoloya de Alquisiras',
        'Miraíma',
        'Kawaii',
        'Haaren',
        'Oschatz',
        'Odlābāri',
        'Shintō',
        'Ayomi',
        'Kalkar',
        'Chesterton',
        'Newport',
        'Covington',
        'Tamarakulam',
        'Hobe Sound',
        'Montelupo Fiorentino',
        'Mankera',
        'Juan de Acosta',
        'Leyton',
        'Puerto Deseado',
        'Hautmont',
        'Pānsemāl',
        'Vinto',
        'Murrhardt',
        'Mackworth',
        'Grenaa',
        'Mnagueur',
        'Huron',
        'Castelsarrasin',
        'Nallagunta',
        'Tālbahat',
        'Coolidge',
        'Uslar',
        'Pirangut',
        'Bhulwāl',
        'Edingen-Neckarhausen',
        'Federal Heights',
        'Bamble',
        'Birqāsh',
        'Sanlúcar la Mayor',
        'Hythe',
        'Feucht',
        'Ashtead',
        'Bua Yai',
        'São José do Cedro',
        'Mers el Hadjad',
        'Orumanayūr',
        'Santol',
        'Mirandiba',
        'Barwon Heads',
        'Raghudebbati',
        'Forest Park',
        'Kātrāvulapalle',
        'Douarnenez',
        'Juan Aldama',
        'Antarvedipālem',
        'Dippoldiswalde',
        'Plewiska',
        'Jitwārpur Kumhra',
        'Taki',
        'Grajales',
        'Staszów',
        'Wombourn',
        'Pulūr',
        'Alto Santo',
        'Shergarh',
        'Buhuşi',
        'Kutavūr',
        'Clearview',
        'Beronono',
        'Narvik',
        'São Martinho do Bispo',
        'Sanjiang Nongchang',
        'Sun Lakes',
        'Nesebar',
        'Rambha',
        'Yuscarán',
        'Lauchhammer',
        'East Whiteland',
        'Boutilimit',
        'Ipiranga',
        'Beekman',
        'Thong Pha Phum',
        'Ústí nad Orlicí',
        'Massalubrense',
        'Kerben',
        'Massaranduba',
        'Omiš',
        'Storozhynets',
        'Astolfo Dutra',
        'Kakunodatemachi',
        'Westmont',
        'Sai Buri',
        'Shepherdsville',
        'Santiago Jocotepec',
        'Collingswood',
        'Uzhur',
        'Sur Singh',
        'Bad Ischl',
        'Ipanguaçu',
        'Villasanta',
        'Tepoztlán',
        'Belison',
        'Kīlattingal',
        'Luino',
        'Valença',
        'Gurinhém',
        'Leirvik',
        'Dallas',
        'El Rodeo',
        'Bovenden',
        'Chāprā',
        'Thônex',
        'Naţanz',
        'Matias Barbosa',
        'Landau an der Isar',
        'Port Lincoln',
        'Lakewood Park',
        'Charcas',
        'Curuá',
        'Nowy Tomyśl',
        'Noya',
        'Kisanuki',
        'Candelaria',
        'College Park',
        'Powell',
        'Siroda',
        'Geldagana',
        'Phulwār',
        'Gudūr',
        'Capitão Enéas',
        'Varde',
        'Fergus Falls',
        'Lagoa Real',
        'Pierre',
        'Sibundoy',
        'Wittstock',
        'Jacksonville',
        'Eraurā',
        'Mŭ’minobod',
        'Pélézi',
        'Baños',
        'Lachute',
        'Trepuzzi',
        'Niška Banja',
        'Valentim Gentil',
        'Kemp Mill',
        'Doranahalli',
        'Byāhatti',
        'Castel San Giovanni',
        'Oneonta',
        'Belm',
        'Bougzoul',
        'Bela',
        'Siderópolis',
        'Titiribí',
        'Hohenstein-Ernstthal',
        'Kinogitan',
        'Athens',
        'Rājim',
        'Rosemère',
        'Beilen',
        'Emerald',
        'Adakplamé',
        'Allouez',
        'El Guetar',
        'Sungai Guntung',
        'Tân Vạn',
        'Sidi Ben Adda',
        'Indiana',
        'Dunmore',
        'Aldenhoven',
        'Tamār',
        'Ershui',
        'Jodoigne',
        'Aiuaba',
        'Koundian',
        'Hidalgo',
        'Ardmore',
        'Oostakker',
        'Italva',
        'Lagoa dos Gatos',
        'Bezou',
        'Neuenkirchen',
        'Jefferson Valley-Yorktown',
        'Ponnampatti',
        'Caudry',
        'Andalgalá',
        'Bishops Cleeve',
        'Ban Mueang Nga',
        'Santiago de Anaya',
        'Rotenburg an der Fulda',
        'East Wenatchee',
        'Nantucket',
        'Sumbal',
        'Tibasosa',
        'Zazafotsy',
        'Arenoso',
        'Talwat',
        'Sjenica',
        'Nina Rodrigues',
        'Montalvânia',
        'Vammanal',
        'Arboga',
        'Grünstadt',
        'Kerugoya',
        'Pedro Afonso',
        'Bēylul',
        'Teploklyuchenka',
        'New Ulm',
        'Sāsan',
        'Ezequiel Montes',
        'Fisciano',
        'Ayni',
        'Moudjbara',
        'Pilas',
        'Thonotosassa',
        'Ahrensfelde',
        'El Socorro',
        'El Carmen de Atrato',
        'Karugamād',
        'Frunze',
        'Gouveia',
        'Chełmża',
        'Karlivka',
        'Khadra',
        'Nantwich',
        'Cojedes',
        'Mazamitla',
        'Río de Oro',
        'Berettyóújfalu',
        'Wauconda',
        'Verona',
        'Santa Catalina',
        'Itakura',
        'Sidi Abdelkarim',
        'Nelas',
        'Águas Vermelhas',
        'Daean',
        'Ostrzeszów',
        'Balassagyarmat',
        'Kottaiyūr',
        'Mbini',
        'Amāri',
        'Beloeil',
        'Cirò Marina',
        'Suknadānga',
        'Kārai',
        'Ūttukkottai',
        'Rio Azul',
        'Mignouré',
        'Barāri',
        'Primrose',
        'Itaguaçu',
        'Sharonville',
        'Hazel Grove',
        'Kīāshahr',
        'Gafanha da Nazaré',
        'Wezembeek-Oppem',
        'Petrolândia',
        'Tierra Amarilla',
        'Fāmenīn',
        'Santa Adélia',
        'Esquipulas Palo Gordo',
        'Morpeth',
        'Circleville',
        'Antohobe',
        'Lakoucun',
        'St. Helens',
        'Uttoxeter',
        'Sibirila',
        'Barjora',
        'Taguatinga',
        'Leuna',
        'Ollioules',
        'Ilampillai',
        'Hawaiian Gardens',
        'Haivoron',
        'Keāl',
        'Hokuei',
        'Turuttiyad',
        'Selmana',
        'Rānāpur',
        'Riposto',
        'Córdoba',
        'Ricaurte',
        'Solhan',
        'Marlow',
        'Alewah',
        'Fujikawa',
        'Martinho Campos',
        'Rājāsūr',
        'Busto Garolfo',
        'Candeias',
        'Beraketa',
        'Ambahita',
        'Mahavelona',
        'Marotsiraka',
        'Mahajamba',
        'Ambatomarina',
        'Antanambaobe',
        'Kalandy',
        'Bekipay',
        'Marokarima',
        'Fiadanana',
        'Mahatsinjony',
        'Vanono',
        'Talata-Vohimena',
        'Vatolatsaka',
        'Belambo',
        'Miarinarivo',
        'Beharona',
        'Ambohimitombo',
        'Tsarahasina',
        'Bevato',
        'Ankiliabo',
        'Amborondra',
        'Bealanana',
        'Sahalanona',
        'Ambodihara',
        'Ilha de Moçambique',
        'O‘nhayot',
        'Shofirkon Shahri',
        'Rafaï',
        'Takerbouzt',
        'Thouars',
        'Brahmānandapuram',
        'Tall Banāt',
        'Milhã',
        'Meilen',
        'Montfoort',
        'Persan',
        'Kamituga',
        'Beach Park',
        'Schneeberg',
        'Annāram',
        'Şaḩnāyā',
        'Cristópolis',
        'Lone Tree',
        'Tulle',
        'Powell',
        'Novoulyanovsk',
        'Coribe',
        'Koundara',
        'Bandraboua',
        'Lorsch',
        'Dubovka',
        'Pathum Thani',
        'Taiyong',
        'Shāndīz',
        'Matane',
        'Donji Vakuf',
        'Mirzāpur',
        'São João d’Aliança',
        'Kongarapalli',
        'Pāli',
        'Ambohimiera',
        'Chumpak',
        'Bebra',
        'Fleury-Mérogis',
        'East Norriton',
        'Pemberton',
        'Kuraymah',
        'Ganguvārpatti',
        'Beiwusidui',
        'Thames Centre',
        'Felixlândia',
        'Mirinzal',
        'Oud-Turnhout',
        'Jardim de Piranhas',
        'Erbach',
        'Sutihār',
        'Boha',
        'Santa Branca',
        'Kambila',
        'Mankal',
        'East Milton',
        'Söğütlü',
        'Raydah',
        'McFarland',
        'Beasain',
        'Ovidiu',
        'Merošina',
        'Thala',
        'Soledade',
        'Drapetsóna',
        'Goianápolis',
        'Sakaki',
        'Lübben (Spreewald)',
        'Canteleu',
        'Rājnagar',
        'Grass Valley',
        'Roselle Park',
        'Pacaembu',
        'Hajdúsámson',
        'Bedfordview',
        'Mjölby',
        'Kargıpınar',
        'Méridjonou',
        'Chaugāin',
        'Detva',
        'Eynesil',
        'Nova Ipixuna',
        'Alfredo Chaves',
        'McPherson',
        'Bolívar',
        'Lyndhurst',
        'Kępno',
        'Belwāra',
        'Amāha',
        'Havelock North',
        'Kanākir',
        'Bambang',
        'La Entrada',
        'Belsandi Tāra',
        'Llanera',
        'Cassina de’ Pecchi',
        'Misano Adriatico',
        'Bothell East',
        'Perdūr',
        'Warrenville',
        'Audincourt',
        'Narasingapuram',
        'Powell River',
        'Furukawamen',
        'Wells Branch',
        'Marostica',
        'Carleton Place',
        'Ichtegem',
        'Castellanza',
        'San José Acatempa',
        'Bang Racham',
        'Attibele',
        'Aracatu',
        'Sesquilé',
        'Camp Pendleton South',
        'Plumstead',
        'Brierley Hill',
        'Tsingoni',
        'Ibicuí',
        'Tarhzout',
        'Montévrain',
        'Streetly',
        'Lower Moreland',
        'Vöhringen',
        'Hunasagi',
        'Waimalu',
        'Höchstadt an der Aisch',
        'Grünberg',
        'Locorotondo',
        'Nachikatsuura',
        'Zhipingxiang',
        'Cossato',
        'Nyzhnia Krynka',
        'Khe Sanh',
        'Abre Campo',
        'Gesuba',
        'Sukma',
        'East Cleveland',
        'Julianadorp',
        'Diego de Almagro',
        'Aßlar',
        'Destin',
        'Çaybaşı',
        'Vīraganūr',
        'Temsamane',
        'Papagaios',
        'Mansidão',
        'Royal Kunia',
        'Oued Taria',
        'Alterosa',
        'Lons',
        'Maracaí',
        'Weißenhorn',
        'Huntington',
        'Berre-l’Étang',
        'Muttunāyakkanpatti',
        'Khergām',
        'Denzlingen',
        'Kelangāh',
        'Chudovo',
        'Alto Paraná',
        'Oberhaching',
        'Sankt Leon-Rot',
        'Debre Werk’',
        'Melsungen',
        'Midleton',
        'Moralzarzal',
        'Buchloe',
        'Bickley',
        'Kubādupuram',
        'Guelph/Eramosa',
        'Krishnarāyapuram',
        'Wood Dale',
        'Santo Augusto',
        'Tucacas',
        'Grão Mogol',
        'Bukedea',
        'Mogeiro',
        'Hall in Tirol',
        'Orikhiv',
        'Dumri',
        'Bhogpur',
        'Salaverry',
        'Shiloh',
        'Jīran',
        'Olean',
        'Bog Walk',
        'Galmi',
        'Meerane',
        'Tidjelabine',
        'Joaquim Pires',
        'Torrijos',
        'Polukallu',
        'Quakenbrück',
        'Rustington',
        'Tumbippādi',
        'Philippsburg',
        'Eastham',
        'Areado',
        'Simplício Mendes',
        'Makokou',
        'Cefalù',
        'Jabbeke',
        'Lauterbach',
        'Aurora',
        'Sāgar',
        'Caselle Torinese',
        'Hockessin',
        'Sido',
        'Sunset',
        'Matiçan',
        'Friesenheim',
        'Beacon',
        'Tāti',
        'New Franklin',
        'Kettering',
        'Hòa Thượng',
        'Ettenheim',
        'Torre del Campo',
        'Ping’anbao',
        'Villalba',
        'Linganaboyinacherla',
        'Alcanena',
        'Taphan Hin',
        'Hilpoltstein',
        'Ocaña',
        'Binkolo',
        'Tracunhaém',
        'Wakasa',
        'Richland',
        'Oulad Chikh',
        'Billère',
        'Porto Alegre do Norte',
        'Ibipitanga',
        'Tamalameque',
        'Kishanpur Ratwāra',
        'Artondale',
        'Polavaram',
        'Qiblaí',
        'Manoel Vitorino',
        'Tarauna',
        'Avanhandava',
        'Erbach',
        'Shtërpcë',
        'Kingstown',
        'Huangyadong',
        'Guamal',
        'Weno',
        'Waycross',
        'Luozi',
        'Saboeiro',
        'Solaro',
        'Zhashkiv',
        'Carmópolis',
        'Markt Schwaben',
        'Grand Falls',
        'Toulal',
        'Zhovkva',
        'Iskapalli',
        'Millbury',
        'Polotitlán de la Ilustración',
        'San Rafael Las Flores',
        'Amantea',
        'Bellair-Meadowbrook Terrace',
        'Parihāra',
        'Dogāchi',
        'Dryden',
        'Carapebus',
        'Ōki',
        'Itaguara',
        'Beachwood',
        'Batuan',
        'Michendorf',
        'Māndalgarh',
        'Trinitapoli',
        'Fujimi',
        'Mount Barker',
        'Kāuriya',
        'Ribeiro do Amparo',
        'Hīdaj',
        'Khmis Sidi al ’Aydi',
        'Primavera',
        'Worthington',
        'Gangaikondān',
        'Gekhi',
        'Saloá',
        'Barra do Mendes',
        'Tomiño',
        'North Battleford',
        'Paripueira',
        'Valayam',
        'Fara in Sabina',
        'Kamigōri',
        'South Sioux City',
        'Haidarnagar',
        'Vellodu',
        'Cosham',
        'Sauk Rapids',
        'Vembārpatti',
        'Strada',
        'Nules',
        'Mayrtup',
        'Pedro Velho',
        'Olympia Heights',
        'Maumee',
        'Bad Soden-Salmünster',
        'Enniskillen',
        'Khajawa',
        'Seirō',
        'Trbovlje',
        'Pigüé',
        'Clemencia',
        'Wayland',
        'Irikkūr',
        'Haßfurt',
        'Teays Valley',
        'Skoczów',
        'eManzimtoti',
        'Wanzleben',
        'Wörgl',
        'Anadia',
        'Lakeland',
        'Schwaz',
        'Colangute',
        'Forest Hill',
        'Turuvanūr',
        'Dowbarān',
        'Lieusaint',
        'Calafat',
        'Magalhães de Almeida',
        'Meckenbeuren',
        'Krumbach',
        'Littleborough',
        'Ewarton',
        'Channahon',
        'Nawāgarh',
        'Plav',
        'San Antonio de las Vueltas',
        'Pedernales',
        'Passo de Camarajibe',
        'Tako',
        'Braselton',
        'Koppies',
        'Itagi',
        'Gangavalli',
        'Bedford',
        'Graça',
        'Villas',
        'Kamnik',
        'Boyovut',
        'Tachov',
        'Unión de Tula',
        'Horodyshche',
        'Pierrelatte',
        'Kivertsi',
        'Rønne',
        'Achuapa',
        'San Juan',
        'Assaí',
        'Vargem da Roça',
        'Carmo de Minas',
        'Iguatemi',
        'Löningen',
        'Bideipur',
        'Acajutiba',
        'Rixheim',
        'Sarāyān',
        'Tibri',
        'Arbaa Laaounate',
        'Anapurus',
        'Lys-lès-Lannoy',
        'San Ġiljan',
        'Oftringen',
        'Ipswich',
        'Itapetim',
        'Hünxe',
        'Ban Bang Muang',
        'San Rafael Petzal',
        'Ban Tha Luang Lang',
        'Akropong',
        'Bellefontaine',
        'Roncq',
        'Siemiatycze',
        'Couvin',
        'Jinmingsi',
        'Rosyth',
        'Sannicandro Garganico',
        'Mont-Laurier',
        'Kupino',
        'Higashimiyoshi',
        'Yulee',
        'Madānpur',
        'Simri',
        'Ampasinambo',
        'Zhongliao',
        'Caçu',
        'Chaoyangdicun',
        'Auburn',
        'Lomma',
        'Guapé',
        'Torul',
        'Morwell',
        'Santa Luzia',
        'Gräfelfing',
        'Varedo',
        'Arugollu',
        'Berceni',
        'Mariánské Lázně',
        'Saint-Orens-de-Gameville',
        'Kirkwood',
        'Gouka',
        'Feliz',
        'Leverano',
        'Snoqualmie',
        'Tampamolón Corona',
        'Taggia',
        'Kodigenahalli',
        'Willow Grove',
        'Eppstein',
        'Kashin',
        'Singalāndāpuram',
        'McMinnville',
        'São Pedro do Piauí',
        'Gülchö',
        'Cayce',
        'Bandarbeyla',
        'Amrābād',
        'Travagliato',
        'Lipno',
        'Nehrəm',
        'Chityāl',
        'Megarine',
        'Orange Walk',
        'Frankenberg',
        'Holzgerlingen',
        'Kannivādi',
        'Waianae',
        'Douar Lehgagcha',
        'Jardín',
        'Settara',
        'Moissac',
        'Uruoca',
        'Rostamābād',
        'Central Elgin',
        'Itinga',
        'Flores de Goiás',
        'Urupês',
        'Nawāda',
        'Manavālakurichi',
        'Barghāt',
        'Msata',
        'Forster',
        'Hawick',
        'Mata',
        'Badarwās',
        'Tacaimbó',
        'Beverstedt',
        'Gopālpur',
        'Bondeno',
        'Ephrata',
        'Zarghūn Shahr',
        'Sidmouth',
        'Ermoúpoli',
        'Hazel Crest',
        'Ataléia',
        'Futrono',
        'Lyaskovets',
        'Guaraniaçu',
        'Priverno',
        'Enghien',
        'Urbino',
        'Kudachi',
        'Palkūr',
        'Bodagudipādu',
        'St. James',
        'York',
        'Angical',
        'Ibateguara',
        'Taglio',
        'Darihat',
        'Uruana',
        'Saint-Martin-de-Crau',
        'Miami Springs',
        'Malepur',
        'Nova Trento',
        'São João do Triunfo',
        'Alagoinha',
        'Milford Haven',
        'Xireg',
        'Kidlington',
        'Arcadia',
        'Sobrāon',
        'Nonoai',
        'North Fair Oaks',
        'Mistassini',
        'Tāmaraikkulam',
        'Rocky Point',
        'Wezep',
        'Nova Canaã',
        'Lamont',
        'Saugeen Shores',
        'Eichstätt',
        'Sher Chakla',
        'Marienheide',
        'Knottingley',
        'Ipecaetá',
        'Tummalapenta',
        'Moberly',
        'Mions',
        'Shorewood',
        'Kovilūr',
        'Crevalcore',
        'Cêrro Largo',
        'Ndélé',
        'Kaleyānpur',
        'Coldwater',
        'Colesville',
        'Biedenkopf',
        'Quatá',
        'Kingston',
        'South Park',
        'Montataire',
        'Toyloq Qishlog’i',
        'Sariq',
        'Papágos',
        'Mohda',
        'Presidente Kennedy',
        'Tiruppanandāl',
        'Arona',
        'Hinabangan',
        'Ingersoll',
        'Kuleshovka',
        'Auriflama',
        'Angermünde',
        'Valencia West',
        'Diaï Koura',
        'Vempalle',
        'Kraainem',
        'Boa Nova',
        'Itapagipe',
        'Uarini',
        'Las Rosas',
        'Mangha',
        'Bergen',
        'Santa Cruz de Bezana',
        'Castel San Giorgio',
        'Tarumizu',
        'Marshall',
        'San Pedro Pochutla',
        'Radomyshl',
        'Genthin',
        'Chak Husaini',
        'Schmölln',
        'Glenpool',
        'Quatis',
        'Palmito',
        'Motupe',
        'Cambados',
        'Karma',
        'Maribondo',
        'Hettstedt',
        'Kannadiparamba',
        'Copalchí',
        'Kalādgi',
        'Slobozhanske',
        'Madanpur',
        'Sansare',
        'Sale',
        'Sendenhorst',
        'Saint Ann’s Bay',
        'Yongcong',
        'Normandia',
        'Altos del Rosario',
        'Bhīmadolu',
        'Duvergé',
        'Otley',
        'Río Caribe',
        'San Ferdinando di Puglia',
        'Chiran',
        'Mucambo',
        'Poté',
        'Nova Veneza',
        'Svetlogorsk',
        'Aldama',
        'Jindayris',
        'Nikolayevsk',
        'Qantīr',
        'Avon',
        'Latham',
        'Bad Dürrheim',
        'Namīn',
        'Fiesole',
        'Warrensville Heights',
        'Xikou',
        'Isāgarh',
        'Smithfield',
        'El Crucero',
        'Saint-Jacques-de-la-Lande',
        'Chōsei',
        'Piên',
        'Barahari',
        'Ajjampur',
        'Bilaspur',
        'Glen Carbon',
        'Mirabela',
        'Retirolândia',
        'Avalēpalli',
        'San Agustín de Guadalix',
        'University Heights',
        'Jurema',
        'Domkonda',
        'Curiúva',
        'Marshall',
        'Shōdoshima',
        'Speedway',
        'Lanuza',
        'Umburanas',
        'Alexandria',
        'Helensburgh',
        'Childwall',
        'Woudenberg',
        'Pedreiras',
        'Beauharnois',
        'Kostolac',
        'El Kerma',
        'Beeville',
        'Salisbury',
        'Frei Miguelinho',
        'Abadla',
        'Marion',
        'Port Neches',
        'California',
        'Hammam el Rhezez',
        'Milovice',
        'Santa Coloma de Farnés',
        'Bunhe',
        'Manta',
        'Kunjatturu',
        'Dantewāra',
        'Uitgeest',
        'Trzebnica',
        'Pitlam',
        'Spring Garden',
        'Sampaloc',
        'Jiquiriçá',
        'Šamorín',
        'Oak Park',
        'Solonytsivka',
        'Balhāpur',
        'Rockland',
        'Bamafélé',
        'Boninal',
        'Engenheiro Caldas',
        'Allestree',
        'Ottaikkālmantapam',
        'Mahuwa Singhrai',
        'Saimbeyli',
        'Yeşilli',
        'Jaca',
        'Maglie',
        'Babhanganwa',
        'Brecksville',
        'Ihtiman',
        'Santa Luzia do Itanhy',
        'Pfullendorf',
        'Artena',
        'Shamalgan',
        'Serra Branca',
        'Baianópolis',
        'Primeira Cruz',
        'Formello',
        'Trubchevsk',
        'Orocó',
        'Brejo do Cruz',
        'Lumberton',
        'São Félix do Araguaia',
        'Mantenópolis',
        'Verín',
        'Kyzyl-Adyr',
        'Castilla La Nueva',
        'Sóller',
        'Kauhajoki',
        'Yamanobe',
        'Rožaje',
        'Tejutla',
        'Elesbão Veloso',
        'Karpi',
        'Kerepestarcsa',
        'Masangshy',
        'Combarbalá',
        'Bollène',
        'San Javier',
        'Marchtrenk',
        'Qingxicun',
        'Chengara',
        'Amboasary-Gara',
        'Dongshicuo',
        'Barßel',
        'Atner',
        'Mohania',
        'Wyandanch',
        'Marhamat',
        'Vedi',
        'Vázquez',
        'Nighoj',
        'Jannat Shahr',
        'Daniëlskuil',
        'Rāyapalle',
        'Rāzole',
        'Guerrero Negro',
        'Sisai',
        'Duvva',
        'Cañasgordas',
        'Middlewich',
        'Okinoshima',
        'New Providence',
        'Whitby',
        'Amīngarh',
        'Tapilula',
        'Doumé',
        'Ixcatepec',
        'Ráquira',
        'Łęczyca',
        'Nybro',
        'Pirallahı',
        'Camapuã',
        'Atomé-Avégamé',
        'Beaufort',
        'Ak-Dovurak',
        'Rommerskirchen',
        'Sūrān',
        'New Carrollton',
        'Pama',
        'Gonzalez',
        'West End',
        'Zhukovo',
        'San José Villanueva',
        'Wanze',
        'Neckargemünd',
        'Gondalga',
        'Dueville',
        'East Islip',
        'Piquete',
        'Marghita',
        'Cervignano del Friuli',
        'Tokoroa',
        'Upanema',
        'Patālia',
        'Royal Wootton Bassett',
        'South Stormont',
        'Bridport',
        'Gran',
        'Presidente Bernardes',
        'Lappersdorf',
        'Serui',
        'Bardstown',
        'Latiano',
        'Dattapulia',
        'Pegnitz',
        'Sharm ash Shaykh',
        'Dhāna',
        'Bécancour',
        'Winsum',
        'Palestina',
        'Newington',
        'Asperg',
        'Ashiya',
        'Talne',
        'Nālatvād',
        'Seabrook',
        'Rossington',
        'Bandar-e ‘Asalūyeh',
        'Western Springs',
        'Lāla',
        'Echelon',
        'Haselünne',
        'Hilsea',
        'Kurtkoti',
        'Stolin',
        'Nesconset',
        'Plover',
        'Clifton',
        'Bishenpur',
        'Chinique',
        'Mór',
        'Ugo',
        'Oosterzele',
        'Niaogho',
        'Rahui',
        'Karnāwad',
        'Tamanique',
        'Hola Prystan',
        'Kaharlyk',
        'Dinant',
        'Mascote',
        'Alagoinha',
        'Castelfranco di Sotto',
        'Endicott',
        'Wentorf bei Hamburg',
        'Rukhāe',
        'Martinsville',
        'Kheri Naru',
        'Lexington Park',
        'Bexley',
        'Beur',
        'Kharv-e Soflá',
        'Amriswil',
        'Thisted',
        'Wschowa',
        'Hemsworth',
        'Albertirsa',
        'Jānapādu',
        'Spaichingen',
        'Koubel-Koundia',
        'Shangping',
        '‘Alem T’ēna',
        'Rheinfelden',
        'Corabia',
        'South Charleston',
        'Bridgetown',
        'Dārat ‘Izzah',
        'San Jorge',
        'Llanes',
        'Antigua',
        'Pfarrkirchen',
        'Eatontown',
        'Tovuz',
        'Bad Salzdetfurth',
        'Borgaon',
        'Conway',
        'Vaires-sur-Marne',
        'General Belgrano',
        'Novi Iskar',
        'Calolziocorte',
        'Kafr Nabūdah',
        'Tauberbischofsheim',
        'Çayıralan',
        'Adh Dhakhīrah',
        'Nynäshamn',
        'Menaa',
        'São Ludgero',
        'Lourdes',
        'Lewisville',
        'Nova Varoš',
        'Ban Bung Kha',
        'Strabane',
        'Kodriva',
        'Głowno',
        'Hlučín',
        'Avidha',
        'Mqam at Tolba',
        'Ban Mae Kaluang',
        'Corozal',
        'Wiesmoor',
        'Calderara di Reno',
        'Kudrovo',
        'Perungala',
        'Mŭynoq',
        'Siriāri',
        'Karuvellūr',
        'Jefferson',
        'Hard',
        'Andasibe',
        'Wolfhagen',
        'Shawangunk',
        'Ganxi',
        'Chimá',
        'Kenadsa',
        'Sikat',
        'Courcouronnes',
        'Nemocón',
        'Cajabamba',
        'Hantsavichy',
        'Gárdony',
        'Chandla',
        'Khānāpur',
        'Bayt Jālā',
        'Baras',
        'Stezzano',
        'Genas',
        'El Hachimia',
        'Marudūr',
        'Squinzano',
        'Kriva Palanka',
        'Gines',
        'Gudikallu',
        'Vāghodia',
        'Chalma',
        'Schermbeck',
        'Sukhāsan',
        'Severn',
        'Afzala',
        'Fereydūnshahr',
        'Ilamatlán',
        'Strand',
        'Al Ḩā’ir',
        'Caapiranga',
        'Plouzané',
        'Bonita',
        'Itaeté',
        'Madakkathara',
        'Fontenay-le-Comte',
        'King City',
        'Avocado Heights',
        'Little Hulton',
        'Jamira',
        'Udayagiri',
        'Elanad',
        'Kesariyā',
        'Yedapalli',
        'Pālakkuzhi',
        'Stroud',
        'Bad Laasphe',
        'Chembrasshēri',
        'Sterling',
        'São João do Araguaia',
        'Dundigal',
        'Panjgirāin Kalān',
        'General Viamonte',
        'Lake Forest Park',
        'Fruita',
        'Los Alamos',
        'Nagnur',
        'Nallajerla',
        'Ascensión',
        'Manatí',
        'Gullapuram',
        'Moncks Corner',
        'San Juan de Limay',
        'Fontenay-le-Fleury',
        'Villanchirai',
        'Irondale',
        'Callaway',
        'Barro Alto',
        'Valdemorillo',
        'Mamborê',
        'Beesel',
        'Dok Kham Tai',
        'Hévié',
        'Bou Sfer',
        'Bhakua',
        'Kulharia',
        'Taneichi',
        'Sébékoro',
        'Plougastel-Daoulas',
        'Clinton',
        'São Pedro da Água Branca',
        'São Simão',
        'Kadattūr',
        'Fort Lewis',
        'Harwich',
        'San Juan de Betulia',
        'Dakit',
        'Orkney',
        'Péfka',
        'Zábřeh',
        'Englewood',
        'Uibaí',
        'Ouargaye',
        'Tergnier',
        'Mallet',
        'Tucapel',
        'San Jacinto del Cauca',
        'Chinna Mushidivāda',
        'Monteroni di Lecce',
        'Franklin Park',
        'Golyshmanovo',
        'Jucuarán',
        'Ādīs ‘Alem',
        'Avenal',
        'Versoix',
        'Anzin',
        'Germasógeia',
        'Veglie',
        'Neustadt',
        'Dahāria',
        'Ghordaur',
        'Náfpaktos',
        'Sultanpur Mor',
        'Chaddesden',
        'Kotgīr',
        'Hopewell',
        'Dinklage',
        'San Rafael',
        'Talegaon Dhamdhere',
        'Srebrenica',
        'Basāon',
        'Sault Ste. Marie',
        'Azle',
        'Tuchola',
        'Sítio do Mato',
        'Sunjiayan',
        'Vellarivalli',
        'Benaulim',
        'Melenki',
        'Richterswil',
        'Parihārpur',
        'Zawodzie',
        'Dyykan-Kyshtak',
        'Alleroy',
        'Akhty',
        'Seyitgazi',
        'Santa Bárbara',
        'Chahbounia',
        'San Juan',
        'Vieste',
        'Villa Unión',
        'Ruza',
        'Gaundrā',
        'Inawashiro',
        'Ban Don Thong',
        'Heswall',
        'Kalārdasht',
        'Mosgiel',
        'Kaabong',
        'Kaiwen',
        'Dalkeith',
        'San Juan La Laguna',
        'Amarāvati',
        'Kilgore',
        'Arklow',
        'Uppidamangalam',
        'Panapākkam',
        'Velānganni',
        'Testour',
        'Lacombe',
        'Balsa Nova',
        'Connersville',
        'Goudomp',
        'Kokri',
        'Drøbak',
        'Amblecote',
        'Arruda dos Vinhos',
        'Vikravāndi',
        'Joppatowne',
        'Targuist',
        'Kissane Ltouqi',
        'Abhwar',
        'Alsager',
        'Gien',
        'Telmar',
        'Butler',
        'Pottsville',
        'Lislique',
        'Besārh',
        'Palmetto',
        'Bouzina',
        'Sanankoro Djitoumou',
        'Aconibe',
        'Fåberg',
        'San Jacinto',
        'Atlantic Beach',
        'Urziceni',
        'Amondara',
        'Canton',
        'Chīmalapādu',
        'Gołdap',
        'Luís Antônio',
        'Sülüktü',
        'Várzea Nova',
        'San José de Maipo',
        'Ribeira Brava',
        'Paruchūru',
        'Sainte-Sophie',
        'Wake',
        'Urbana',
        'Boechout',
        'Lincoln',
        'Margraten',
        'Kawatana',
        'Feldbach',
        'Saint Budeaux',
        'Corsham',
        'Oxford',
        'Grez-Doiceau',
        'Bāgli',
        'Zollikon',
        'Calle Larga',
        'Marietta',
        'Barka Parbatta',
        'Hatoyama',
        'El Almendro',
        'Arzachena',
        'River Ridge',
        'Yengema',
        'Washington',
        'Jupiter Farms',
        'Zehak',
        'Hösbach',
        'Słupca',
        'Mangalapur',
        'Amity',
        'Qigexingcun',
        'Lake Station',
        'Makwassie',
        'Egg Buckland',
        'Mucur',
        'Tiorpāra',
        'Port-Alfred',
        'Bijai',
        'Sabana Iglesia',
        'Kotor',
        'Cadereyta',
        'Zbarazh',
        'Peabiru',
        'Nattappettai',
        'Sigli',
        'Shetpe',
        'El Carmen',
        'Vemulapūdi',
        'Carnot-Moon',
        'Payyanadam',
        'Człuchów',
        'Andrews',
        'Buli',
        'Bedburg',
        'Barlinek',
        'Oboyan',
        'Karapürçek',
        'Pazhanji',
        'Henderson',
        'Charlton',
        'Mangalāpuram',
        'Rezzato',
        'Serrolândia',
        'Yantzaza',
        'Marungūr',
        'Weilburg',
        'Rājpur',
        'Alzano Lombardo',
        'Mountain Park',
        'Kumlu',
        'Ubstadt-Weiher',
        'Chandi',
        'La Grange Park',
        'Sant’Angelo Lodigiano',
        'Sweden',
        'Hillcrest',
        'Puerto Morazán',
        'Itapuí',
        'Val-des-Monts',
        'Tórshavn',
        'Amarpura',
        'Santa Maria a Monte',
        'Ashton',
        'Dambal',
        'Blue Ash',
        'Okkerneutboom',
        'Tiruvārpu',
        'Pataili',
        'Bacup',
        'Dohta',
        'Beamsville',
        'Hanımçiftliği',
        'Broussard',
        'Saint-Amable',
        'Bachhauta',
        'Kiiminki',
        'Carluke',
        'Kiskőrös',
        'Franconia',
        'Manerbio',
        'Kuurne',
        'Santa Marcela',
        'Morąg',
        'Wąbrzeźno',
        'Dumariā',
        'Farādonbeh',
        'Kingston',
        'Shibām',
        'Auburn',
        'Coatesville',
        'Buşrá al Ḩarīr',
        'Düzköy',
        'Nosivka',
        'Ostwald',
        'Chifubu',
        'Ampthill',
        'Sidi Abd el Moumene',
        'Nārāyangarh',
        'Ban Na Chom Thian',
        'Coomera',
        'White House',
        'Ambodilazana',
        'Grimstad',
        'Velen',
        'Kāgvād',
        'Rosas',
        'Manching',
        'Saarwellingen',
        'Bouskene',
        'Torredonjimeno',
        'Makarska',
        'Kadena',
        'Woodinville',
        'Nishon Tumani',
        'Charxin',
        'Shirebrook',
        'Shiremoor',
        'Mount Vernon',
        'Berck-sur-Mer',
        'Tounfit',
        'Goh',
        'Reeuwijk',
        'Ponte San Giovanni',
        'Elfers',
        'Penrith',
        'Sirhāli Kalān',
        'Pushing',
        'Ratnāpuram',
        'Undrājavaram',
        'Clayton',
        'Dębno',
        'Maní',
        'Argentan',
        'Woodland Park',
        'Soyaniquilpan',
        'Badhoevedorp',
        'San Rafael Oriente',
        'Arendonk',
        'Weinsberg',
        'Cremlingen',
        'Oued Tlélat',
        'Padaivedu',
        'El Tabo',
        'Berezne',
        'Catemu',
        'Reggada',
        'Zehdenick',
        'Aginiparru',
        'Rogers',
        'Aguilar',
        'Bayt Sāḩūr',
        'Bocaiúva do Sul',
        'Hüllhorst',
        'Annāmalainagar',
        'Kūhbil',
        'Pānchgani',
        'Terebovlya',
        'Guillena',
        'Fatehpur',
        'Vetralla',
        'Gura Humorului',
        'Standish',
        'Ang Thong',
        'Carros',
        'Xylókastro',
        'İkizce',
        'Ettāpur',
        'Blytheville',
        'Tummanatti',
        'Montepulciano',
        'Schüttorf',
        'Thame',
        'Ergolding',
        'Ŭrtaowul',
        'West Boldon',
        'Raghunāthpur',
        'Outreau',
        'Portage La Prairie',
        'Joaquim Nabuco',
        'Buttar',
        'Żnin',
        'Sulingen',
        'Modakkurichchi',
        'Lincolnwood',
        'Sečanj',
        'Amilly',
        'Ban Chomphu Nuea',
        'Bugugoucun',
        'Skoghall',
        'Tarhūnah',
        'Tanagura',
        'Rābor',
        'North Greenbush',
        'Los Chiles',
        'Peringom',
        'Naranja',
        'Baş Göynük',
        'Linlithgow',
        'Knutsford',
        'Aldeia de Paio Pires',
        'Torquay',
        'Liangwancun',
        'Karivalamvandanallūr',
        'Doddipatla',
        'El Tablón',
        'Tala',
        'Īnderbor',
        'Orange City',
        'Kings Park West',
        'Olfen',
        'Peshkopi',
        'Arês',
        'Steamboat Springs',
        'Trophy Club',
        'Montespertoli',
        'Muragācha',
        'Khoyniki',
        'La Victoria',
        'Herbrechtingen',
        'Fehmarnsund',
        'New Kingman-Butler',
        'Loveland',
        'Dingle',
        'Sumbha',
        'Empedrado',
        'Paraíso do Norte',
        'Usumatlán',
        'Cornelius',
        'Ad Duraykīsh',
        'Kuttyattur',
        'Puerto San Martín',
        'Pé de Serra',
        'Ortaklar',
        'Chhapra',
        'Östringen',
        'Lubsko',
        'Gangelt',
        'Manivilundān',
        'Šternberk',
        'Dharmaram',
        'Beverungen',
        'Lawang Bato',
        'Ulvila',
        'Daulatnagar',
        'Moody',
        'Aïn Youcef',
        'Nanjai Kilābādi',
        'Dharphari',
        'Casandrino',
        'Casatenovo',
        'Kasumkent',
        'Borodyanka',
        'Damaishan',
        'Andrésy',
        'Perladka',
        'Ivaí',
        'Santa Juana',
        'Gvardeysk',
        'Glendale',
        'Sebt Labrikiyne',
        'Sābang',
        'Pervomaysk',
        'Afir',
        'Mahalpur',
        'Gopālasamudram',
        'Mesudiye',
        'Slobozhanske',
        'Sarria',
        'Chiroqchi',
        'Nioaque',
        'Basseterre',
        'Orangeburg',
        'Beelitz',
        'Jūla Buzarg',
        'Palos Verdes Estates',
        'Yakage',
        'Muriedas',
        'Pácora',
        'Arsali',
        'Mathurāpur',
        'Bichkunda',
        "Shin'onsen",
        'Fátima',
        'Blegny',
        'Lariano',
        'Morangis',
        'Yamato',
        'El Kansera',
        'Kathevaram',
        'Khiria',
        'San Martino di Lupari',
        'Dorado',
        'Walsall Wood',
        'Ambohidronono',
        'Bondāda',
        'Chulumani',
        'Beyla',
        'Værløse',
        'Novellara',
        'Noceto',
        'Douar Sidi Laaroussi',
        'Xaafuun',
        'Çamardı',
        'Benairia',
        'Tonakkal',
        'Guttal',
        'Kuressaare',
        'Trebur',
        'Aïn Lechiakh',
        'Tempio Pausania',
        'Southwick',
        'Las Vegas',
        'Bombarral',
        'Chenggong',
        'Bundāla',
        'Gmunden',
        'Nemours',
        'Hissāramuruvani',
        'Cheval',
        'Liedekerke',
        'Jobat',
        'Vila Pouca de Aguiar',
        'Carpinteria',
        'Ban Khlong',
        'Rio de Contas',
        'Villacidro',
        'Castelnuovo di Verona',
        'Mārathalli',
        'Zayda',
        'San Clemente',
        'Belëv',
        'Oncativo',
        'Parvomay',
        'Fraserburgh',
        'Püspökladány',
        'Cedar Grove',
        'Kingersheim',
        'Sovetskoe',
        'Auria',
        'Portland',
        'Bischofsheim',
        'Hashikami',
        'Cariamanga',
        'Santa Teresa',
        'Bagadó',
        'Mānikpur',
        'Mouvaux',
        'Weddington',
        'Newton',
        'Plaza Huincul',
        'Melilli',
        'Druskininkai',
        'Bou Arada',
        'Penamalūru',
        'La Llagosta',
        'Qal‘eh Ganj',
        'Casale sul Sile',
        'Uyar',
        'Graulhet',
        'Leżajsk',
        'Medway',
        'Hājan',
        'Canals',
        'Santa María de Ipire',
        'Ceyu',
        'Samālsar',
        'Ridgefield Park',
        'Santa Margarita',
        'Miahuatlán',
        'Vestby',
        'San Jerónimo',
        'Ban Chorakhe Samphan',
        '’Aïn Mouilah',
        'Zabaykalsk',
        'Harrison',
        'Villa Juárez',
        'Villa Sandino',
        'Mulungu do Morro',
        'Capaya',
        'Igrapiúna',
        'Mahao',
        'Matsushima',
        'Drochia',
        'Karabanovo',
        'South Glengarry',
        'Hulkoti',
        'Santa María Jalapa del Marqués',
        'Tinglayan',
        'Caerfyrddin',
        'Pell City',
        'Nako',
        'Rio do Antônio',
        'Superior',
        'Autun',
        'Ban Nong Hoi',
        'Singuilucan',
        'Głuchołazy',
        'Chañaral',
        'Vigodarzere',
        'Sharon',
        'Alovera',
        'Balya',
        'Hamworthy',
        'Bajala',
        'Asjen',
        'Mutterstadt',
        'Crispiano',
        'Bålsta',
        'Sali',
        'Pacatuba',
        'Radomir',
        'Kaua Kol',
        'Sainte-Marie',
        'Novi Bečej',
        'Latisana',
        'Trenton',
        'Bom Princípio',
        'Shīnīlē',
        'Gothva',
        'South Daytona',
        'Bensekrane',
        'North Perth',
        'Monteux',
        'Sarezzo',
        'Ketsch',
        'Qulan',
        'Kofelē',
        'Tetāri',
        'Kaspiyskiy',
        'Saktipur',
        'Ksibet el Mediouni',
        'Ba Chúc',
        'Sankt Georgen im Schwarzwald',
        'Sátoraljaújhely',
        'Ayodhyāpattanam',
        'Plattling',
        'Cicuco',
        'Chino Valley',
        'Berkeley Heights',
        'Sidmant al Jabal',
        'San Vicente',
        'General Enrique Mosconi',
        'Brieselang',
        'Dover',
        'Giporlos',
        'Alvorada D’Oeste',
        'Silago',
        'Koregaon',
        'Mahaboboka',
        'Maqsūda',
        'Tubmanburg',
        'Altos',
        'Iraci',
        'Wehr',
        'Betatao',
        'Treuchtlingen',
        'Barhī',
        'Toura',
        'Rosbach vor der Höhe',
        'Miarinarivo',
        'Brig-Glis',
        'Quattro Castella',
        'Phalaborwa',
        'Bergen',
        'Sargūr',
        'Shitāb Diāra',
        'Kanabūr',
        'Kallūr',
        'Gland',
        'Wantage',
        'Rājupālem',
        'Grand Terrace',
        'São Domingos',
        'Tirumakūdal Narsipur',
        'Pavona',
        'Mounds View',
        'Fyzabad',
        'Kozy',
        'Miranda do Corvo',
        'Mazatlán Villa de Flores',
        'Kavitāl',
        'Medapādu',
        'Lake Los Angeles',
        'La Grande',
        'Barsbüttel',
        'San Martín Zapotitlán',
        'Kottāram',
        'Mercedes Umaña',
        'Chos Malal',
        'Albatera',
        'Wollaston',
        'Taishi',
        'North Palm Beach',
        'Güçlükonak',
        'Soddy-Daisy',
        'Stoughton',
        'Wolvega',
        'Joure',
        'Pomfret',
        'Itano',
        'Harrison',
        'Al Jumaylīyah',
        'Bocholt',
        'La Vega',
        'Tōnoshō',
        'Akseki',
        'Küssnacht',
        'San Antonio del Tequendama',
        'New Hanover',
        'Phra Samut Chedi',
        'Betanzos',
        'Darłowo',
        'Ionia',
        'Horst',
        'Andaraí',
        'Caldeirão Grande',
        'Great Driffield',
        'Gex',
        'Gurlapeta',
        'Takkolam',
        'Çavdır',
        'Simijaca',
        'Chandhaus',
        'Udaipur Bithwār',
        'Kamikawa',
        'Krasnozavodsk',
        'Peddavadlapūdi',
        'Ekwāri',
        'Huguan Nongchang',
        'Nasiyanūr',
        'Hungen',
        'Motobu',
        'Oatfield',
        'Gouandé',
        'Fannūj',
        'Cherry Hill Mall',
        'Dărmăneşti',
        'Apostolove',
        'Pendlebury',
        'Goodmayes',
        'Gorakhpur',
        'Makabana',
        'Killai',
        'Scheeßel',
        'Kalikiri',
        'Pallappālaiyam',
        'Cotegipe',
        'Sudbury',
        'Jālhalli',
        'Dinbéla',
        'Adi Keyh',
        'Milnrow',
        'Ribeirão do Pinhal',
        'Paina',
        'West Carrollton',
        'Cambridge',
        'Kuruman',
        'Hajdúhadház',
        'Balua',
        'Mohgaon',
        'Norwalk',
        'Kafr ‘Awān',
        'On Top of the World Designated Place',
        'Lamaçães',
        'Muri',
        'Castlebar',
        'Rabot',
        'Balatonfüred',
        'Nidzica',
        'Alto Garças',
        'Dom Feliciano',
        'Vetlanda',
        'Godella',
        'Gamboula',
        'Forlimpopoli',
        'Kani-Bonzon',
        'Alcácer do Sal',
        'Chernigovka',
        'Afanyangan',
        'Monte Belo',
        'Danga',
        'La Crucecita',
        'Manturovo',
        'Turvo',
        'Pandireddigūdem',
        'Bedford',
        'Hadamar',
        'Fatehpur',
        'Hawaiian Paradise Park',
        'Aberystwyth',
        'Drongen',
        'Rasauli',
        'Mettet',
        'Velykodolynske',
        'Dahu',
        'Charābidya',
        'Thompson',
        'Turbihāl',
        'Palmital',
        'Olivar Bajo',
        'Olivar',
        'Rödental',
        'Tezontepec',
        'Stjørdalshalsen',
        'Lowshān',
        'Shahr-e Herāt',
        'Nova Gorica',
        'Capo d’Orlando',
        'Ajas',
        'Fernandina Beach',
        'Dārāsuram',
        'Neykkārappatti',
        'Missões',
        'Holborn',
        'San Pietro Vernotico',
        'Dayālpur Sāpha',
        'Barāon',
        'Caledon',
        'Shīn',
        'Bolongongo',
        'Neuri',
        'Dinshawāy',
        'Brackley',
        'Claremont',
        'Ubaporanga',
        'Fatehpur',
        'Bārah',
        'Candiba',
        'Tetiiv',
        'Linnich',
        'Leibnitz',
        'Peddakūrapādu',
        'Gangoli',
        'Bohmte',
        'Kalaiyamputtūr',
        'Belpukur',
        'Forrest City',
        'Ampanavoana',
        'Thara',
        'Montgomeryville',
        'Chandauli',
        'Sidhwān',
        'Kaji',
        'Wasserburg am Inn',
        'Lawaan',
        'Quzanlı',
        'Webb City',
        'Kahhalé',
        'Ambararata',
        'Marovandrika',
        'Morafeno',
        'Andreba',
        'Marovato',
        'Ampitasimo',
        'Ambohimahavelona',
        'Isaka-Ivondro',
        'Ambodiampana',
        'Andranomamy',
        'Ihorombe',
        'Mahatsinjo',
        'Antongomena-Bevary',
        'Antsirabe Afovoany',
        'Sahavalanina-Antenina',
        'Belemoka',
        'Mavorano',
        'Evato',
        'Tranovaho',
        'Amborompotsy',
        'Ambalakindresy',
        'Ambahoabe',
        'Vohitrindry',
        'Befandriana',
        'Ampanefena',
        'Ankilivalo',
        'Anjiajia',
        'Ambatondrakalavao',
        'Kirano',
        'Sakoabe',
        'Maroviro',
        'Ambakireny',
        'Tsiately',
        'Ambohitsilaozana',
        'Ambazoa',
        'Ambodisakoana',
        'Bemanevika',
        'Ambondrona',
        'Ambatolampy',
        'Bejofo',
        'Manambolo',
        'Mangindrano',
        'Ankilimalinika',
        'Sandrakatsy',
        'Marojala',
        'Ilafy',
        'Morarano',
        'Mandritsara',
        'Befeta',
        'Amboaboa',
        'Manambidala',
        'Andohajango',
        'Ḩarmah',
        'Sunne',
        'Kumi',
        'Alaverdi',
        'Neunkirchen',
        'Midsomer Norton',
        'Galashiels',
        'Jalam',
        'Ghorādongri',
        'Moviya',
        'Amauna',
        'Dammennu',
        'Timberlake',
        'Divnoye',
        'Lavello',
        'Bhalil',
        'Ksar Sghir',
        'Vieira do Minho',
        'Bocas del Toro',
        'Jennings',
        'Manalalondo',
        'Miami',
        'Sebt Bni Garfett',
        'Anandāpuram',
        'Waconia',
        'Gornalwood',
        'Lohiyār Ujain',
        'Ficarazzi',
        'Baduriātola',
        'Kottapadi',
        'Kara-Kyshtak',
        'Chhāra',
        'Chāwalhāti',
        'Nacozari de García',
        'Noyon',
        'Ridge',
        'West Monroe',
        'Korneuburg',
        'Steinkjer',
        'Paso de los Toros',
        'Zandhoven',
        'Sai Wan Ho',
        'Kibiito',
        'Ivanić-Grad',
        'Kollipara',
        'Vrbovec',
        'Bisee',
        'Chã da Alegria',
        'Antônio Prado',
        'Green Valley',
        'Yiewsley',
        'Chāng',
        'Rochester',
        'Schleiden',
        'Höör',
        'Jiānganj',
        'San Lucas',
        'Kanel',
        'Fouriesburg',
        'Pretoria-Noord',
        'Culaba',
        'Roding',
        'Sitalpur',
        'Ben',
        'Turi',
        'Barwat Pasrāin',
        'Phulwaria',
        'Bachrā',
        'Senmayachō-senmaya',
        'Larkspur',
        'An Thành B',
        'Wanderley',
        'Rovinj',
        'Chapantongo',
        'Casinhas',
        'Wickede',
        'Lingamparti',
        'Itatiaiuçu',
        'Abarán',
        'Uppāda',
        'Troy',
        'Juan de Herrera',
        'Palangarai',
        'Mizil',
        'Garhi',
        'Shenley Church End',
        'Campo de Criptana',
        'Montesarchio',
        'Cardoso Moreira',
        'Kingsbury',
        'Shanyincun',
        'Wicklow',
        'Kūdangulam',
        'Santa Ana',
        'Schodack',
        'Pingtiancun',
        'Jhaua',
        'Montefiascone',
        'Pucioasa',
        'Mogocha',
        'Flint',
        'Saposoa',
        'Mirdaul',
        'Bocşa',
        'Mulakalūru',
        'Libertad',
        'Wächtersbach',
        'Vinings',
        'Jaitpur',
        'Solana Beach',
        'Svirsk',
        'Tartarugalzinho',
        'Sant Sadurní d’Anoia',
        'Lakkavaram',
        'Carpenedolo',
        'Cherutana Tekku',
        'Brejões',
        'Pelham',
        'Maktar',
        'Shiwan',
        'Saint-André',
        'Camponogara',
        'Elandsdoorn',
        'Bou Fekrane',
        'Nossa Senhora do Livramento',
        'Gibraleón',
        'Kent',
        'Sākhmohan',
        'Grantsville',
        'Fokino',
        'Japoatã',
        'Amboise',
        'Rāmpur Shāmchand',
        'North Adams',
        'Matinilla',
        'Trebaseleghe',
        'Gomparou',
        'Morungaba',
        '’Aïn Tolba',
        'Newcastle',
        'Bni Khloug',
        'Isalo',
        'San Pablo',
        'Meadville',
        'Kachavaram',
        'Song Phi Nong',
        'Ātmakūru',
        'Qal‘at al Maḑīq',
        'Lynnfield',
        'Nova Londrina',
        'Fengdeng',
        'Byram',
        "D'Iberville",
        'Paranhos',
        'Woolton',
        'Sint-Gillis-bij-Dendermonde',
        'Tarnos',
        'Amarwā Khurd',
        'Tezze sul Brenta',
        'Valparaíso',
        'Chamical',
        'Holly Hill',
        'Buritirama',
        'Möckern',
        'Castiglion Fiorentino',
        'Bujari',
        'Berja',
        'Statte',
        'St. Ann',
        'Messadine',
        'Kalanaur',
        'Tonoshō',
        'Balbalan',
        'Odder',
        'Schinnen',
        'Anolaima',
        'Leagrave',
        'Besigheim',
        'Châteaudun',
        'Cusset',
        'Český Krumlov',
        'Quierschied',
        'Lahaina',
        'San Mateo',
        'Barra de Santa Rosa',
        'Tashi',
        'Fostoria',
        'Karāhal',
        'Dardenne Prairie',
        'Khomām',
        'Bijelo Polje',
        'Amolatar',
        'Kapchorwa',
        'Goonellabah',
        'Corat',
        'Mayenne',
        'Cliftonville',
        'Kankandighi',
        'Trent Hills',
        'Vellakkinar',
        'Tirmaigiri',
        'Loreto',
        'Chợ Mới',
        'Roßdorf',
        'Gurgunta',
        'Sisauna',
        'Bairia',
        'Kalappālangulam',
        'Monteprandone',
        'Bonneville',
        'Yuza',
        'Petrus Steyn',
        'Sim',
        'Wommelgem',
        'Apuiarés',
        'Saghar Sultānpur',
        'Bāgewādi',
        'Moreira',
        'Buckingham',
        'Vyazemskiy',
        'Esneux',
        'Elavanasūr',
        'Pentapādu Kasba',
        'Misaki',
        'Barja',
        'Mándra',
        'Kalavai',
        'Crissiumal',
        'Uzwil',
        'Ambalarondra',
        'Brønderslev',
        'Highlands',
        'Kapelle',
        'Sangão',
        'El Dorado',
        'Ricaurte',
        'Ayutla',
        'Khombole',
        'Ringnod',
        'Edattirutti',
        'Daru',
        'Neunkirchen',
        'Suzu',
        'Cidelândia',
        'Athens',
        'Suamico',
        'Moorreesburg',
        'Nurkot',
        'Frutillar',
        'Koturkolkara',
        'Keisen',
        'Mountain Home',
        'Căuşeni',
        'Kalush',
        'Lontras',
        'Palombara Sabina',
        'Harrison',
        'Kuala Kurun',
        'Smyrna',
        'Finneytown',
        'Alāyaman',
        'Colares',
        'Purén',
        'Red Bank',
        'Agutaya',
        'Tanakkangulam',
        'Woodhaven',
        'Vejer de la Frontera',
        'Chilwell',
        'Vadnais Heights',
        'Wadersloh',
        'Trail',
        'Múggia',
        'Calcinato',
        'Salisbury',
        'Akayu',
        'Pôrto Murtinho',
        'Mansāpur',
        'Rehti',
        'Mūlaikkaraippatti',
        'Ganapavaram',
        'Lanuvio',
        'Dhutauli',
        'Tehachapi',
        'Mora',
        'Ecublens',
        'Saint-André-de-Cubzac',
        'Chinnasekkadu',
        'Daparkha',
        'Nuevo Ideal',
        'Malaryta',
        'Baláo',
        'Abrera',
        'Saunshi',
        'Cheney',
        'Argentona',
        'Vedappatti',
        'Niandjila',
        'Zhongling',
        'Palmares do Sul',
        'Rahika',
        'Medfield',
        'Aldoar',
        'Bihārīganj',
        'Sidlice',
        'Acasusso',
        'Sugauna',
        'Meriç',
        'Florida City',
        'Dedemsvaart',
        'Guéret',
        'Emeryville',
        'Lansdowne',
        'Auriol',
        'Cavallino',
        'Kallād',
        'Fuldatal',
        'Janāi',
        'Meddappakkam',
        'Puerto Jiménez',
        'Bloomsburg',
        'Seclin',
        'San Pietro in Casale',
        'Terrasini Favarotta',
        'Santa Rosa de Calamuchita',
        'Kādachchinallūr',
        'Quartucciu',
        'Vicente Guerrero',
        'Herzberg am Harz',
        'Sattar',
        'Barhauna',
        'Oxapampa',
        'Sagay',
        'Santuario',
        'Kaul',
        'Palacagüina',
        'San Juan Tecuaco',
        'Spilamberto',
        'Syasstroy',
        'Canelinha',
        'Samé',
        'Raymond Terrace',
        'Mullasshēri',
        'Nieuw Nickerie',
        'Salempur',
        'Fontanafredda',
        'Cornwall',
        'Sugarland Run',
        'Juan L. Lacaze',
        'Tega Cay',
        'Nova Crixás',
        'Frederiksværk',
        'Santa Cruz Amilpas',
        'M’dhilla',
        'Leopold',
        'Arakere',
        'Nartan',
        'Castellbisbal',
        'Antonivka',
        'Bad Urach',
        'Ganga',
        'Spiez',
        'Qazmalar',
        'Buenos Aires',
        'The Nation / La Nation',
        'Bolintin Vale',
        'Nova Timboteua',
        'Ziro',
        'Pueblo Nuevo',
        'Bela Simri',
        'Bhagwānpur Khurd',
        'Douar Oulad Youssef',
        'Aš',
        'Brenes',
        'Syston',
        'Little Lever',
        'Chiatura',
        'Venkatagirikota',
        'Rosario del Tala',
        'Bāzidpur',
        'Ban Krang',
        'Bag‘dod',
        'Yozyovon',
        'Santo Tomás La Unión',
        'Govindgarh',
        'Yelmalla',
        'Rose Belle',
        'Gorokhovets',
        'General Las Heras',
        'Teror',
        'Salzano',
        'Kachchippalli',
        'Spiesen-Elversberg',
        'Chinnakkāmpālaiyam',
        'Milngavie',
        'Tucson Estates',
        'Kalecik',
        'Arohi',
        'Overlea',
        'Toukoroba',
        'Burgos',
        'Kurwa Mathiā',
        'Santa Fe',
        'Springbok',
        'Lokāpur',
        'Florham Park',
        'Kyotera',
        'Rio Casca',
        'Gaura',
        'Santa Maria do Suaçuí',
        'Rāmpurwā',
        'Concepción de Ataco',
        'Hulyaypole',
        'Mallāpur',
        'Lumberton',
        'Yavoriv',
        'Mesolóngi',
        'Kasba Tanora',
        'Santa Ana de Yacuma',
        'Juvignac',
        'Aghbala',
        'Franeker',
        'Peißenberg',
        'Jasauli',
        'Oudenbosch',
        'Twistringen',
        'Kannudaiyāmpatti',
        'Taldom',
        'Sanderstead',
        'Mudhol',
        'Kanbara',
        'Pichilemu',
        'Ocós',
        'Saint-André-les-Vergers',
        'Bayeux',
        'Ashibetsu',
        'Tizgane',
        'Donwari',
        'Nangavalli',
        'Landeh',
        'Festus',
        'Velilla de San Antonio',
        'Chojnów',
        'Loyish Shaharchasi',
        'Gavere',
        'Lagoa Dourada',
        'Sørum',
        'Harpur Bochaha',
        'Nandyālampeta',
        'Greenville',
        'Perez',
        'Huazalingo',
        'Chalfont Saint Peter',
        'Magdalena Milpas Altas',
        'Brzeg Dolny',
        'Agdangan',
        'Novo Lino',
        'Kaufungen',
        'Chodavaram',
        'Zag',
        'Perehinske',
        'San Anselmo',
        'Ban Tat',
        'Dhubaria',
        'Chāhatpur',
        'Sedriano',
        'Sarahandrano',
        'Rodeio',
        'Bariyārpur',
        'Maheswa',
        'Granarolo del l’Emilia',
        'Grover Beach',
        'Anaikal',
        'Antanandava',
        'Mahārājpur',
        'Valbonne',
        'Big Bear City',
        'Casteel',
        'Kaeng Khoi',
        'Villa San Giovanni',
        'Kouti',
        'Vadasseri',
        'Nawāda Gobindganj',
        'Barud',
        'Kolappalūr',
        'Florence',
        'Roudnice nad Labem',
        'Serra Azul',
        'Kāmrej',
        'Maroli',
        'Eufaula',
        'Adygeysk',
        'Accokeek',
        'Rokupr',
        'Keerbergen',
        'Guymon',
        'Beaufort',
        'Wietmarschen',
        'Begampur',
        'Ilicínia',
        'Dharampur Bānde',
        'Cavarzere',
        'Terrabona',
        'Amherst',
        'Ban Sop Tia',
        'Bahādurpur',
        'Kochłowice',
        'Samashki',
        'Majārhāt',
        'Chinna Gollapālem',
        'Calcinaia',
        'Mahābaleshwar',
        'Yemva',
        'Sebiston',
        'Vilanova del Camí',
        'Surkhakhi',
        'Bandlagūda',
        'Oakland',
        'San Salvador',
        'Chodov',
        'Aţ Ţūr',
        'Jāmi',
        'Almirante',
        'Gärtringen',
        'Sonbarsa',
        'Köprübaşı',
        'Sete Barras',
        'Châlette-sur-Loing',
        'Duvvūru',
        'Joanópolis',
        'Les Ponts-de-Cé',
        'Obalāpuram',
        'Sulz am Neckar',
        'Canillá',
        'Ainsdale',
        'Ivanec',
        'Mar de Espanha',
        'Richton Park',
        'Oulad Rahmoun',
        'Staryye Atagi',
        'Khorol',
        'Brus Laguna',
        'Emmiganūru',
        'San Francisco de Mostazal',
        'Laamarna',
        'Alcalá',
        'Debbache el Hadj Douadi',
        'San Antonio Aguas Calientes',
        'Bakhtāwarpur',
        'Mahtha',
        'Kirchheim bei München',
        'Varzaneh',
        'Kyōtamba',
        'Pīrnagar',
        'Bāri',
        'Annapolis Neck',
        'Hvardiiske',
        'Chaiyo',
        'Novi Pazar',
        'Krupka',
        'Playa Grande',
        'Dāmarcherla',
        'Seïada',
        'Kaspi',
        'São Félix da Marinha',
        'Pennsville',
        'Sivalārkulam',
        'Mādugula',
        'Buffelshoek',
        'Woolwich',
        'Miranorte',
        'Nirakpurpāli',
        'Serere',
        'Honggu',
        'Lymm',
        'Flitwick',
        'Zelzate',
        'Umag',
        'Patti',
        'Samāi',
        'Battulapalle',
        'Sason',
        'Puerto Ayora',
        'Caió',
        'Betton',
        'Sante Bennūr',
        'Zelenodolsk',
        'Bāsmenj',
        'Librazhd-Qendër',
        'St. Marys',
        'Brad',
        'Peraiyūr',
        'Gricignano d’Aversa',
        'Altenstadt',
        'Buharkent',
        'Chüy',
        'Mānākondūr',
        'Bamhnī',
        'Sangtŭda',
        'Ālampur Gonpura',
        'Nicosia',
        'Bou Adel',
        'Dianguirdé',
        'Dianké',
        'Challapata',
        'Santiago Amoltepec',
        'Ấp Bình Thành',
        'Feuchtwangen',
        'Mechtras',
        'Northlake',
        'Wawarsing',
        'Delémont',
        'Saint-Hilaire-de-Riez',
        'Hersbruck',
        'Maniyur',
        'Itayanagi',
        'Manorville',
        'Marysville',
        'Derventa',
        'Hedensted',
        'Daharia',
        'Luchong',
        'Cuisnahuat',
        'Brownhills',
        'Amos',
        'Beausoleil',
        'Aït Youssef Ou Ali',
        'Morazán',
        'Paradise Valley',
        'Winterberg',
        'Comines',
        'Olgiate Olona',
        'Neosho',
        'Geneva',
        'Taguaí',
        'Wilmington',
        'Ceuti',
        'Dunkirk',
        'Bad Abbach',
        'Diari',
        'Blaubeuren',
        'Mèze',
        'Shilka',
        'Shirataka',
        'Fords',
        'Vineyard',
        'Herk-de-Stad',
        'Kotturu',
        'Wickliffe',
        'Kelso',
        'Gerpinnes',
        'Pūdūru',
        'Cairo Montenotte',
        'Hornsey',
        'Tamm',
        'Mugutkhan Hubli',
        'Valverde del Camino',
        'Santo Stino di Livenza',
        'Ahermoumou',
        'Kuçovë',
        'Malmédy',
        'Balpyq Bī',
        'Pasadena Hills',
        'Malhador',
        'Lukoyanov',
        'Nakhla',
        'San Biagio di Callalta',
        'Sheopuria',
        'Gaffney',
        'Branson',
        'Waltershausen',
        'Ouzera',
        'Sinincay',
        'Takamori',
        'Bom Repouso',
        'Qiaotouba',
        'Alpen',
        'Sutton on Hull',
        'Tilāri',
        'Schongau',
        'Fulton',
        'Novhorod-Siverskyi',
        'Awantipur',
        'Newington Forest',
        'Robertsville',
        'Market Warsop',
        'Warsop',
        'Bad Windsheim',
        'Nangola',
        'Isla Mujeres',
        'Petrovske',
        'Ziama Mansouria',
        'El Menzel',
        'Al Muzayrīb',
        'Fortaleza dos Nogueiras',
        'Saltcoats',
        'Pondūru',
        'Los Hidalgos',
        'Horndean',
        'Holiday City-Berkeley',
        'Melvindale',
        'Groutville',
        'Rio Vermelho',
        'Koyulhisar',
        'Puttānattam',
        'Kākan',
        'Tenmalai',
        'De Haan',
        'North Walsham',
        'Adukam',
        'Kanniyambram',
        'San Marco in Lamis',
        'Souakene',
        'Artesia',
        'Dores do Indaiá',
        'Tomball',
        'Enumclaw',
        'Baykalsk',
        'Shchastia',
        'Jaroměř',
        'Naduhatti',
        'Costa Marques',
        'Victoria',
        'Knittelfeld',
        'Burj al ‘Arab',
        'Kādiyāmpatti',
        'Ấp Phú Hải',
        'Sirūr',
        'Bonito',
        'Castel Goffredo',
        'Teroual',
        'Maxcanú',
        'Junín de los Andes',
        'Presidente Jânio Quadros',
        'Jalpura',
        'Cunda diá Baze',
        'Simri',
        'Ulster',
        'Cedral',
        'Gürgentepe',
        'Villeneuve-lès-Avignon',
        'Motkūr',
        'Xalpatlahuac',
        'Concepción Las Minas',
        'Ottawa',
        'Summerstrand',
        'Sagopshi',
        'Parigi',
        'Rehoboth',
        'Apía',
        'Isla Ratón',
        'Buchs',
        'Steinheim',
        'Jüterbog',
        'Üllő',
        'Saka',
        'Mamakating',
        'Tamsaout',
        'Santa Bárbara de Pinto',
        'Topliţa',
        'Chariyakulam',
        'Oulad Ouchchih',
        'Yenipazar',
        'Bretzfeld',
        'Levelland',
        'Winnetka',
        'Minooka',
        'Lakeland South',
        'Kharak Kalān',
        'Magsaysay',
        'Road Town',
        'Brunsbüttel',
        'Garh Sisai',
        'Belagal',
        'Muttanampālaiyam',
        'Mendota',
        'Tagoloan',
        'Sarıveliler',
        'Luchenza',
        'Galaosiyo Shahri',
        'Eski Arab',
        'Ələt',
        'Queluz',
        'Santiago',
        'Angādikkal Tekkekara',
        'Hessisch Lichtenau',
        'Puerto López',
        'Yzeure',
        'Canet-en-Roussillon',
        'Hosir',
        'Yuzha',
        'Stupava',
        'Lamphun',
        'Khagra',
        'Grenada',
        'Wetaskiwin',
        'Diéli',
        'Brown Deer',
        'New Germany',
        'Dossenheim',
        'Mīnākshipuram',
        'Sandy',
        'Ibiraçu',
        'Jeppe’s Reef',
        'Chropaczów',
        'Zimnicea',
        'Viotá',
        'Cevicos',
        'Ban Tom Klang',
        'Kortemark',
        'Vilsbiburg',
        'Mahuākherāganj',
        'Koppal',
        'Hayashima',
        'Găeşti',
        'General Acha',
        'Sangar',
        'Wordsley',
        'Granville',
        'Lindsay',
        'Jensen Beach',
        'Chandwā',
        'San Vicente',
        'Lamballe',
        'Vatra Dornei',
        'San Rafael',
        'Tuzdybastaū',
        'Wieringerwerf',
        'Pago Pago',
        'Apricena',
        'Dabat',
        'Dingman',
        'Vidauban',
        'Karedu',
        'Dongyuancun',
        'Osečina',
        'Adré',
        'Saint-Jean-de-Védas',
        'Kamalāpuram',
        'Āppukkudal',
        'Chok Chai',
        'Vasylivka',
        'Duartina',
        'Gueltat Sidi Saad',
        'Tirukkāttuppalli',
        'Kanra',
        'El Fuerte',
        'Campbelltown',
        'Lipari',
        'Tərtər',
        'Quirihue',
        'Niefern-Öschelbronn',
        'Salobreña',
        'Croxley Green',
        'Upper',
        'Ancuabe',
        'Fene',
        'Prymorskyi',
        'Olen',
        'Lucena',
        'Maba',
        'Vohimarina',
        'Birni',
        'Cloquet',
        'Progreso',
        'Wadhraf',
        'Rochefort',
        'Mandrāmo',
        'Rong Kwang',
        'Murud',
        'Nohsa',
        'Parasurāmpūr',
        'Diamou',
        'Calanasan',
        'Cuers',
        'Armadale',
        'Pilich',
        'Marikal',
        'Devāpur',
        'Osiān',
        'Sankt Veit an der Glan',
        'Viera East',
        'Fonsorbes',
        'Pajo',
        'Lakhipur',
        'Dysselsdorp',
        'Sabugal',
        'Petrov Val',
        'Rantoul',
        'Monte Quemado',
        'Hirschaid',
        'Kwīhā',
        'Monóvar',
        'Cascades',
        'Brunswick',
        'Ban Laem',
        'Chai Nat',
        'Conceição do Rio Verde',
        'Makuyu',
        'Baraboo',
        'Lüderitz',
        'La Carlota',
        'Palos de la Frontera',
        'Kawamata',
        'Petushki',
        'Sarare',
        'Emstek',
        'North Smithfield',
        'Avrig',
        'Nakhtarāna',
        'Varazze',
        'Kantang',
        'Jiuduhe',
        'Turki',
        'Hisarcık',
        'Röthenbach an der Pegnitz',
        'Preußisch Oldendorf',
        'Mablethorpe',
        'Chiampo',
        'Betma',
        'Mahrail',
        'Brock Hall',
        'Māyamānkurichchi',
        'Bacalar',
        'Chikura',
        'Fortuna',
        'Lake Norman of Catawba',
        'Villa Sola de Vega',
        'Hansot',
        'Justice',
        'Iconha',
        'Montegranaro',
        'Tola Khadda',
        'Fort Atkinson',
        'Montividiu',
        'Eloxochitlán',
        'Santo Niño',
        'Champādānga',
        'Kewatgāwān',
        'Ereymentaū',
        'West Grey',
        'Ladenburg',
        'Ialoveni',
        'Teulada',
        'Asbury Lake',
        'Zella-Mehlis',
        'Creutzwald',
        'Kłobuck',
        'Gladenbach',
        'Goražde',
        'Buchach',
        'Fort Bliss',
        'Campinorte',
        'Borgo San Dalmazzo',
        'Qārah',
        'Marabella',
        'Kashasha',
        'Beydağ',
        'Pilar',
        'Edemissen',
        'Gumani',
        'Sāvalgi',
        'Borger',
        'Wolsztyn',
        'Pannaikkādu',
        'Falmouth',
        'Sattēgālam',
        'Elkhotovo',
        'Campo Alegre',
        'Stony Brook',
        'Miary',
        'Włodawa',
        'Panj',
        'Culfa',
        'Usmānpur',
        'Dhanupra',
        'Sarasota Springs',
        'Anjahambe',
        'Paithān Kawai',
        'Kameshkovo',
        'Xiaping',
        'Giánnouli',
        'Rionero in Vulture',
        'Goldenrod',
        'Codegua',
        'Nanzhai',
        'Coringa',
        'Pütürge',
        'Wolverton',
        'Zuvvaladinne',
        'Gosaingaon',
        'Hînceşti',
        'Uludere',
        'Canegrate',
        'Baūyrzhan Momyshuly',
        'Georgetown',
        'Kingsburg',
        'Negotino',
        'Devsar',
        'Laarne',
        'Grave',
        'El Adjiba',
        'Saint David’s',
        'Beaumont',
        'Vikāsnagar',
        'Ālwārkurichchi',
        'Xavantes',
        'Alto do Rodrigues',
        'Kotwāpatti Rāmpur',
        'Rozhyshche',
        'Bang Phlat',
        'Ingeniero Maschwitz',
        'Okinawa Número Uno',
        'Neuenburg am Rhein',
        '’Aïn el Assel',
        'Boone',
        'Jerome',
        'Ocna Mureş',
        'Santañy',
        'Domahani',
        'Nāysar',
        'Thair',
        'Poquoson',
        'Mariental',
        'Kamin-Kashyrskyi',
        'Tiruppālai',
        'Chabāl Kalān',
        'Lana',
        'Kathāniān',
        'Pidigan',
        'La Argentina',
        'San Miguel Panán',
        'Arhribs',
        'Upper Uwchlan',
        'Kuttiyēri',
        'Andernos-les-Bains',
        'Shāhpura',
        'Bheja',
        'Zaladanki',
        'Logan',
        'Made',
        'Raamsdonksveer',
        'Robinson',
        'Sedro-Woolley',
        'Rānranagudipeta',
        'Canóvanas',
        'Lakshmīnārāyanapuram',
        'Somerville',
        'Matatiele',
        'Santa Ana Maya',
        'Voerendaal',
        'Ban Bang Non',
        'Parker',
        'Sîngerei',
        'Bryne',
        'Mira',
        'Hacılar',
        'Sylacauga',
        'North St. Paul',
        'Kuhsān',
        'Costessey',
        'Baker',
        'Chaval',
        'Mongat',
        'Barton upon Irwell',
        'Pātiram',
        'Eyvānekey',
        'eXobho',
        'Ounagha',
        'Reshuijie',
        'Xinyingheyan',
        'Cetinje',
        'Sântana',
        'Busogo',
        'Iferhounene',
        'Pont-du-Château',
        'San Bartolo',
        'Putussibau',
        'Guruvarājukuppam',
        'El Tambo',
        'Cromer',
        'Refahiye',
        'Santana do Matos',
        'Nakskov',
        'Bickenhill',
        'Haddonfield',
        'Highland City',
        'Rozenburg',
        'Vernouillet',
        'Engenheiro Beltrão',
        'Macará',
        'Sillamäe',
        'Bad Sassendorf',
        'Graben-Neudorf',
        'Bandi̇̄pur',
        'Uren',
        'Tilbury',
        'Chigwell',
        'Castelnaudary',
        'Anna Regina',
        'Ahirauliyā',
        'Harrai',
        'Fagnano Olona',
        'Bagnolo Mella',
        'Port Washington',
        'Kobiri',
        'Zonnebeke',
        'Boumia',
        'Sátão',
        'Gamarra',
        'Čelákovice',
        'Prairie Ridge',
        'Ozatlán',
        'Bhanas Hivre',
        'Sangān',
        'San Marino',
        'Upper Montclair',
        'Finspång',
        'Kanoni',
        'Hanumantanpatti',
        'Wimauma',
        'Nelmadūr',
        'Villa Literno',
        'Shichuanxiang',
        'Gravatal',
        'Bischwiller',
        'Matsukawa',
        'Kānkon',
        'Gogui',
        'Goiatins',
        'Los Santos',
        'Gohpur',
        'Viterbo',
        'Koszutka',
        'Bandalli',
        'Balchik',
        'Kallupatti',
        'Campiglia Marittima',
        'Sint-Michiels',
        'Bairia',
        'Velten',
        'Dhansura',
        'Oshikango',
        'Guática',
        'Pueblo Rico',
        'Palmer',
        'Ambatomainty',
        'Titara',
        'Massena',
        'Provadia',
        'Jequeri',
        'Chachagüí',
        'Warman',
        'Gulshan',
        'Taiynsha',
        'El Ayote',
        'El Ayote',
        'Mallappulasseri',
        'Los Bellosos',
        'Hersham',
        'Maglód',
        'Huodoushancun',
        'Hizan',
        'Gaildorf',
        'Hullatti',
        'Bayona',
        'Grammichele',
        'Madiama',
        'Meerzorg',
        'Aywaille',
        'Kadiana',
        'Vallejuelo',
        'Escanaba',
        'Boussé',
        'Kolattūr',
        'Mehnatobod',
        'Kegeyli Shahar',
        'Mango',
        'Las Lomitas',
        'Prilly',
        'Hagenow',
        'Maraiyūr',
        'Hinundayan',
        'Siki',
        'Kika',
        'Neerijnen',
        'Edgewood',
        'Koluszki',
        'Xinbocun',
        'Mikashevichy',
        'Xinchangcun',
        'Ponnai',
        'Buttāyagūdem',
        'Arcozelo',
        'Trogir',
        'Sarābleh',
        'Ambinanynony',
        'Khvalynsk',
        'Payyanpalli',
        'Calvizzano',
        'Poço Branco',
        'Titisee-Neustadt',
        'Riverview',
        'Kalingiyam',
        'Brignais',
        'Triel-sur-Seine',
        'Orzinuovi',
        'Amelia',
        'San Pedro Jicayán',
        'Ebersberg',
        'Ammavārikuppam',
        'Kamtaul',
        'El Aouana',
        'Bollnäs',
        'Tendūkheda',
        'Chennampatti',
        'Boortmeerbeek',
        'Quinta de Tilcoco',
        'Kallamalai',
        'Lyelchytsy',
        'Pira',
        'Spondon',
        'Attanūr',
        'Anzola dell’Emilia',
        'Panjīpāra',
        'College',
        'Lewisburg',
        'Las Parejas',
        'Sher',
        'Złocieniec',
        'Estepa',
        'Derby',
        'Santa Ana',
        'Saboyá',
        'Arma',
        'Shamsābād',
        'Conneaut',
        'Asfarvarīn',
        'Tyngsborough',
        'Avigliana',
        'Nanakuli',
        'Martinsville',
        'Ilmajoki',
        'Olagadam',
        'Oissel',
        'Villafranca de los Barros',
        'Dokkum',
        'Ribeirão Claro',
        'San Antonio',
        'Issum',
        'Seravezza',
        'Vardenis',
        'Dharhara',
        'Bad Freienwalde',
        'Aubenas',
        'Mi‘rabah',
        'Sarrebourg',
        'Moka',
        'Essenbach',
        'L’Union',
        'Forécariah',
        'Ichora',
        'Coseley',
        'Sever do Vouga',
        'Fojnica',
        'Torroella de Montgrí',
        'Porto Recanati',
        'Higashiagatsuma',
        'Gonzales',
        'Taraclia',
        'Biliaivka',
        'Pola de Laviana',
        'Ierápetra',
        'Reẕvānshahr',
        'Piano di Sorrento',
        'Arluno',
        'Tüp',
        'Nitte',
        'Bayanaūyl',
        'Sydney Mines',
        'Gaimersheim',
        'Nobeji',
        'Vobkent Shahri',
        'Ovenden',
        'Tenambākkam',
        'Chepstow',
        'Sabinov',
        'Drouin',
        'Dubak',
        'Blackfoot',
        'Kladanj',
        'Koskāpur',
        'Cobh',
        'Lye',
        'Payariq Shahri',
        'Jork',
        'Herrin',
        'Patchogue',
        'Rinconada de Malloa',
        'Alampur',
        'Oulad Fares',
        'San Miguel Xoxtla',
        'Kataysk',
        'Union',
        'Alkhan-Kala',
        'Razlog',
        'Bad Fallingbostel',
        'Capdepera',
        'Mandalgovĭ',
        'Tiszavasvári',
        'Huai Yot',
        'Mutoko',
        'Bourem Inali',
        'Nong Khae',
        'Bandwār',
        'Amersfoort',
        'Kalajoki',
        'Laguna Paiva',
        'Foča',
        'Maqu',
        'Vysoké Mýto',
        'Pontecorvo',
        'Soubala',
        'Mecatlán',
        'Yoko',
        'Oulad Aïssa',
        'Bozkurt',
        'Aphaur',
        'Leonforte',
        'Jaidte Lbatma',
        'Ḩawsh al Baḩdalīyah',
        'Periyakoduveri',
        'Boumalne',
        'Dehqonobod',
        'Valencia',
        'Dragør',
        'Diamniadio',
        'Enkesen',
        'Kibuye',
        'Manchester',
        'El Carmen',
        'Kálymnos',
        'Kāttukkottai',
        'Desri',
        'Bulgan',
        'Capul',
        'Navelim',
        'Dawson Creek',
        'Shuangxianxiang',
        'Teotepeque',
        'Valga',
        'Jānkinagar',
        'Sidney',
        'Harnes',
        'Aubergenville',
        'Bodaybo',
        'Mende',
        'Crestwood',
        'Dasraha Bhogrājpur',
        'Cottonwood',
        'North Castle',
        'Sędziszów Małopolski',
        'Cambuquira',
        'Quimperlé',
        'Cypress Lake',
        'Bou Arkoub',
        'Anklam',
        'Ocean Pines',
        'Kotia',
        'Río Grande',
        'Gravenhurst',
        'Enniscorthy',
        'Emiliano Zapata',
        'Heliópolis',
        'Sūreshjān',
        'Shamaldy-Say',
        'Lake City',
        'Nkheila',
        'Wernau',
        'Chettipulam',
        'Pandalkudi',
        'Frohburg',
        'Biassono',
        'Makeni',
        'Aj Jourf',
        'Susa',
        'Hasanpur',
        'Tonyrefail',
        'Garden City',
        'Madison',
        'Obikiik',
        'Qorao‘zak',
        'G’oliblar Qishlog’i',
        'Giffnock',
        'Prince Rupert',
        'Sacacoyo',
        'Vöcklabruck',
        'La Motte-Servolex',
        'La Chapelle-Saint-Luc',
        'Kakching Khunou',
        'Paikpar',
        'Sendafa',
        'Ar Rommani',
        'Acarlar',
        'Atmākūr',
        'Webster',
        'Lavagna',
        'Jefferson Hills',
        'Veurne',
        'McComb',
        'Bom Retiro do Sul',
        'Nawāda',
        'Kot Shamir',
        'Rivarolo Canavese',
        'Narman',
        'Arbeláez',
        'Sidi Makhlouf',
        'Charmahīn',
        'Leeds',
        'Ain Kansara',
        'Pont-Sainte-Maxence',
        'New Mills',
        'Melavāyi',
        'Guisser',
        'Sala',
        'Cananéia',
        'Pebberu',
        'Gandorhun',
        'Acalá del Río',
        'Lavínia',
        'German Flatts',
        'Claye-Souilly',
        'Lodhīkheda',
        'Eraniel',
        'Kasaishi',
        'Penal',
        'Teningen',
        'Bouca',
        'Haslev',
        'Thatto Heath',
        'Benisa',
        'Hathwān',
        'Streator',
        'Soklogbo',
        'Pendências',
        'Home Gardens',
        'Doctor Phillips',
        'Erumaippatti',
        'Yenkuvārigūdem',
        'Lower Pottsgrove',
        'Fairfax Station',
        'Kafr Rūmā',
        'Baikunthpur',
        'Kulattūr',
        'Wołów',
        'Newman',
        'Gharbia',
        'Polās',
        'Burladingen',
        'Sevūr',
        'Baños',
        'Hatillo de Loba',
        'Ludwigslust',
        'Harrow on the Hill',
        'Béjar',
        'Dagmāra',
        'Spearfish',
        'Dinkelsbühl',
        'Pantepec',
        'Córdoba',
        'Razua',
        'Jerônimo Monteiro',
        'Kamalnagar',
        'Villa Unión',
        'Beyləqan',
        'Cicciano',
        'Doi Lo',
        'Perth East',
        'New Britain',
        'Tzintzuntzán',
        'Godohou',
        'Neuhausen auf den Fildern',
        'Nurhak',
        'Teixeiras',
        'Gandara West',
        'Anderlues',
        'Gavardo',
        'Commerce',
        'Oulad Hamdane',
        'Ban Tap Tao',
        'Belén',
        'Abū Dīs',
        'Juchipila',
        'Marumori',
        'Malloussa',
        'Alucra',
        'Chasiv Yar',
        'Laichingen',
        'Pāiker',
        'Mekra',
        'Mahespur',
        'Khujner',
        'West Deer',
        'West Plains',
        'Senjān',
        'Eral',
        'Pital',
        'Ogulin',
        'Ouénou',
        'Kallanai',
        'Zābolī',
        'Boloso',
        'Effingham',
        'Doddanahalli',
        'Manamodu',
        'Engenheiro Paulo de Frontin',
        'Spresiano',
        'El Campo',
        'Porto Valter',
        'Lila',
        'Selston',
        'Ban Huai So Nuea',
        'Carnaubeira da Penha',
        'Neralakaje',
        'Narala',
        'Scotts Valley',
        'Cuichapa',
        'Nordstemmen',
        'Linkenheim-Hochstetten',
        'Tempoal de Sánchez',
        'Sala Consilina',
        'Beatrice',
        'Itiquira',
        'La Palma',
        'Sidi Aoun',
        'North Saanich',
        'Ramabitsa',
        'Kinrooi',
        'Zetel',
        'Newhaven',
        'Gopālnagar',
        'Chāmarru',
        'Presidencia de la Plaza',
        'Morbegno',
        'Hattem',
        'Steinheim am der Murr',
        'Bhachhi Asli',
        'Karrāpur',
        'Affoltern am Albis',
        'Apen',
        'Emboscada',
        'Barra do Ribeiro',
        'Maxhütte-Haidhof',
        'Mesker-Yurt',
        'Warwick',
        'Sernovodsk',
        'Hassi Fedoul',
        'Bully-les-Mines',
        'Ammāpettai',
        'Dharmastala',
        'Pathra',
        'Újfehértó',
        'Bampūr',
        'Haaren',
        'Obernai',
        'Birstall',
        'Puduvayal',
        'Malone',
        'Currimao',
        'Chêne-Bougeries',
        'Krasnyy Yar',
        'Fómeque',
        'Hanūr',
        'Cedarburg',
        'Bom Lugar',
        'Arara',
        'Tsuruta',
        'Yakoma',
        'Chichli',
        'Okabechō-okabe',
        'Anao',
        'Hengshan',
        'Kumirimora',
        'Ghogha',
        'Lauriyā Nandangarh',
        'Rottofreno',
        'Josefina',
        'Bershad',
        'Vrchlabí',
        'Profondeville',
        'Kolanpāk',
        'Yakouren',
        'Koriukivka',
        'Brejinho',
        'Kottagudi Muttanād',
        'Montecorvino Rovella',
        'Karaburun',
        'Paxtaobod',
        'Kuyganyor',
        'Andijon',
        'Yangiariq',
        'Sabbavaram',
        'Belwa',
        'Lobería',
        'Paranatama',
        'Sogndal',
        'Loiyo',
        'Concepción Batres',
        'Cristais',
        'Santa Leopoldina',
        'Rudrūr',
        'Diamond Springs',
        'Bukkapatnam',
        'Tamalpais-Homestead Valley',
        'Lake Tapps',
        'Te Awamutu',
        'Mariyammanahalli',
        'Dorandā',
        'Lewisboro',
        'Kafr Buhum',
        'Port Victoria',
        'La Magdalena Chichicaspa',
        'Unguía',
        'North Merrick',
        'Adelaide',
        'Hnivan',
        'Hobro',
        'Turki',
        'Namchi',
        'Russi',
        'Châteaubriant',
        'Karimkunnum',
        'Malvinas Argentinas',
        'Saraiya',
        'San Mauro Pascoli',
        'Vulcăneşti',
        'Oftersheim',
        'Richland',
        'Santo Domingo',
        '’Aïn Kerma',
        'Bashtanka',
        'Nevele',
        'Kuttūr',
        'Kewanee',
        'Caledonia',
        'Pianiga',
        'Pasca',
        'Rognac',
        'Bhaisālotan',
        'Katagon',
        'Uppukkottai',
        'Wrentham',
        'Moss Point',
        'Vylgort',
        'Osterhofen',
        'Prévost',
        'Tömük',
        'Rüti',
        'Ghorādal',
        'Sakawa',
        'Tiadiaye',
        'Rafína',
        'Olton',
        'Srīnagar',
        'Tioribougou',
        'Nemili',
        'Villamartín',
        'Cheadle',
        'Bhankarpur',
        'Riedisheim',
        'Bonyhád',
        'New Kensington',
        'Le Grand-Saconnex',
        'Santa Elena',
        'Matāla',
        'Kayattār',
        'Brattleboro',
        'Puerto Nare',
        'Kōttāya',
        'Vannivedu',
        'Na Wa',
        'Sagon',
        'Tholey',
        'Vandithāvalam',
        'Grafton',
        'Botlikh',
        'Khonobod',
        'Huétor Vega',
        'Claiborne',
        'Ebéjico',
        'Raipur',
        'Encamp',
        'Barentin',
        'Choctaw',
        'Dranesville',
        'Gajhara',
        'Błonie',
        'Morab',
        'Oyón',
        'Liperi',
        'Rāghopur',
        'Samādh Bhai',
        'Wasilków',
        'Rielasingen-Worblingen',
        'Marquetalia',
        'Arganil',
        'Gyomaendrőd',
        'Kesarimangalam',
        'Ban Bo Luang',
        'Cocotitlán',
        'Havixbeck',
        'Panzgām',
        'Grumo Appula',
        'Tranent',
        'Eklahra',
        'Barsaun',
        'As Sars',
        'Mucugê',
        'Xiushuicun',
        'Muthuswāmipuram',
        'Minabe',
        'Sainte-Adèle',
        'Ban Bang Sai',
        'Ban Noen Phoem',
        'Sapna',
        'Sainte-Agathe-des-Monts',
        'Justo Daract',
        'Mulug',
        'Marsaskala',
        'Meitingen',
        'Bømlo',
        'Camp Verde',
        'Strzelin',
        'Salgado de São Félix',
        'Pachchaimalaiyankottai',
        'Mangarwāra',
        'Eura',
        'Māndu',
        'El Sobrante',
        'Bela Palanka',
        'Bamber Bridge',
        'Macedonia',
        'Hagfors',
        'Lakeside',
        'Socuéllamos',
        'Ampasimbe',
        'Cherlak',
        'Antri',
        'Pornichet',
        'Velair',
        'Riviera Beach',
        'Ishkāshim',
        'Kuzhippilli',
        'Hassi el Ghella',
        'Kharar',
        'Saclepea',
        'Brzeziny',
        'Kamianka-Dniprovska',
        'Kök-Janggak',
        'Gornyak',
        'North Middleton',
        'Maili',
        'Choró',
        'Chāmpāhāti',
        'San Prisco',
        'Wyndham',
        'Quesnel',
        'Citlaltépec',
        'Yecuatla',
        'Perleberg',
        'Huntington',
        'Gistel',
        'Santa Filomena',
        'Pulakurti',
        'Sonupur',
        'Punnila',
        'Yedtare',
        'New Baltimore',
        'Jericó',
        'Saint-Cyr-sur-Mer',
        'Bharwelī',
        'Mitchellville',
        'Tahannawt',
        'Kinkala',
        'Mayate',
        'San Carlos Yautepec',
        'Datoda',
        'Waikanae',
        'Głubczyce',
        'Gulbahor',
        'Taltal',
        'El Marsa',
        'Montrose',
        'Mānegaon',
        'Lenyenye',
        'Tring',
        'Crossville',
        'Umm ar Rizam',
        'Mattenhof',
        'Benoy',
        'Lehre',
        'Gierałtowice',
        'Münchenstein',
        'Sablé-sur-Sarthe',
        'Kīrippatti',
        'Centralia',
        'Acandí',
        'Bhairāpura',
        'Anndevarapeta',
        'Myrtle Grove',
        'Diré',
        'Villars-sur-Glâne',
        'Sassenburg',
        'Pillānallūr',
        'Ōsako',
        'Kosjerić',
        'Steenokkerzeel',
        'Mostardas',
        'North Tidworth',
        'Narhat',
        'Zlatograd',
        'Si Mustapha',
        'Albox',
        'Sedgley',
        'Baozhong',
        'Mmadinare',
        'Glen Rock',
        'Durmersheim',
        'Portales',
        'Lower Gwynedd',
        'Ban Muang Ngam',
        'Pāpampeta',
        'Ganapatipālaiyam',
        'Paal',
        'Ipaumirim',
        'Miesbach',
        'Louvres',
        'Mugalivakkam',
        'Birdāban',
        'Kodusseri',
        'Dalby',
        'Mettlach',
        'La Queue-en-Brie',
        'Beaconsfield',
        'Motta Sant’Anastasia',
        'Solliès-Pont',
        'Rāmpur Kudarkatti',
        'Nihāl Singhwāla',
        'Borgosesia',
        'Hasbrouck Heights',
        'Bridgnorth',
        'Viswanāthaperi',
        'Bhagwānpur Desua',
        'Unebichō',
        'East Renton Highlands',
        'Iwate',
        'Uzyn',
        'Caimito',
        'Washington',
        'Piketberg',
        'Ugento',
        'Făleşti',
        'Pasłęk',
        'Kalaidasht',
        'Nova Laranjeiras',
        'Kesabpur',
        'Valley Falls',
        'Tábua',
        'Dhaula',
        'Fenton',
        'Liuba',
        'Sinalunga',
        'Woodward',
        'Filandia',
        'Le Chambon-Feugerolles',
        'Khānpur',
        'Al Majma‘ah',
        'Poranga',
        'Curití',
        'Ciudad-Rodrigo',
        'São Francisco do Maranhão',
        'Oiba',
        'Bishnāh',
        'Bharokhara',
        'Pharkia',
        'Ponte da Barca',
        'Hōdatsushimizu',
        'Mays Chapel',
        'Arroio do Tigre',
        'Jacala',
        'Destrehan',
        'Gardnerville Ranchos',
        'Nāttarampalli',
        'Tall Dhahab',
        'Wolgast',
        'Võru',
        'Raismes',
        'Walton-on-the-Naze',
        'Chalgeri',
        'Eiras',
        'Solofra',
        'Nilo Peçanha',
        'Porto',
        'Bangāwān',
        'Ban Pae',
        'Erfelek',
        'Goner',
        'Toropets',
        'Novalukoml’',
        'Bentley',
        'Rosdorf',
        'Snihurivka',
        'Karagwe',
        'Gbanhi',
        'Lajia',
        'Honeygo',
        'Vălenii de Munte',
        'Alavus',
        'Berwick-Upon-Tweed',
        'Sarmastpur',
        'Vilāngurichchi',
        'North Lebanon',
        'Hwlffordd',
        'Pyrzyce',
        'Rocca Priora',
        'Baxiangshan',
        'Manne Ekeli',
        'Bāgra',
        'St. Peter',
        'Mato Verde',
        'Pittalavānipālem',
        'Steha',
        'Kongsvinger',
        'Imassogo',
        'Mettuppālaiyam',
        'Kārvetnagar',
        'Paramanandal',
        'Iapu',
        'Sabaur',
        'Celebration',
        'Ronneby',
        'Villa Berthet',
        'Edamon',
        'Sławno',
        'Assomada',
        'Gulni',
        'Liberty Lake',
        'Rosaryville',
        'Theux',
        'Marly',
        'Chudamani',
        'Utehia',
        'River Edge',
        'Huité',
        'Kumarkhāli',
        'Nariman',
        'Key Largo',
        'Beyne-Heusay',
        'Velivennu',
        'San José El Ídolo',
        'Yeadon',
        'Occhiobello',
        'Balha',
        'Fort Campbell North',
        'Saddlebrooke',
        'Budakalász',
        'Tomar do Geru',
        'Sahtāh',
        'Monfort Heights',
        'Al Fayd',
        'Les Îles-de-la-Madeleine',
        'Opmeer',
        'Monte San Giovanni Campano',
        'Raffadali',
        'Eureka',
        'Sam',
        'Verde Village',
        'Jardim Alegre',
        'Fuensalida',
        'Warka',
        'Singhbāri',
        'Viyapuram',
        'San Gennaro Vesuviano',
        'Basantpur',
        'Islamey',
        'El Carmen',
        'Keimoes',
        'Hasroûn',
        'El Qâa',
        'Chaqra',
        'Bteghrîne',
        'Qoubaiyat',
        'Ambolomoty',
        'Fanambana',
        'Ivoamba',
        'Ankafina Tsarafidy',
        'Ambohimandroso',
        'Belobaka',
        'Andonabe',
        'Sorombo',
        'Itondy',
        'Iarinarivo',
        'Saharefo',
        'Vohindava',
        'Beandrarezona',
        'Andranovao',
        'Sendrisoa',
        'Maromiandra',
        'Ambalavero',
        'Bekapaika',
        'Anorombato',
        'Ambaliha',
        'Ambohinamboarina',
        'Anjanazana',
        'Andrembesoa',
        'Mahazoarivo',
        'Anjiamangirana I',
        'Nosiarina',
        'Antanamalaza',
        'Andramy',
        'Basibasy',
        'Ampondra',
        'Antaritarika',
        'Befody',
        'Maromby',
        'Omatjete',
        'Saidpur',
        'Umm Badr',
        'Buqkoosaar',
        'Kévé',
        'Bilovodsk',
        'Ḩukūmatī Gīzāb',
        'Jajce',
        'Karrānah',
        'Ngou',
        'Tefam',
        'Mettingen',
        'Wells',
        'Madina do Boé',
        'Princetown',
        'Petit-Goâve',
        'Huvin Hippargi',
        'Khānda',
        'Pinneli',
        'Dahivel',
        'Jai',
        'Rāmachandrapuram',
        'Sadalgi',
        'Halgar',
        'Arni ka Khera',
        'Pramatam',
        'Cecchina',
        'Weatherford',
        'View Park-Windsor Hills',
        'Floreşti',
        'Sokouhoué',
        'Oberschleißheim',
        'Urakawa',
        'Somerset',
        'Barberton',
        'Cimişlia',
        'Ārutla',
        'Talen',
        'Arkansas City',
        'Manuel Urbano',
        'Galvarino',
        'Boskovice',
        'Gladstone',
        'Somersworth',
        'Węgrów',
        'Doranāla',
        'Wang Saphung',
        'Mugdampalli',
        'Krasnousol’skiy',
        'Bolaños de Calatrava',
        'Alajuelita',
        'Mikkelin Maalaiskunta',
        'Volosovo',
        'Martuni',
        'Shiotachō-matsusaki',
        'Rapho',
        'Husepur',
        'Winton',
        'Nagykáta',
        'Tundhul',
        'Msambweni',
        'Yazıhan',
        'Escazú',
        'Benifayó',
        'Kaviti',
        'Arth',
        'Iwanai',
        'Ifs',
        'Minehead',
        'Pāta Putrela',
        'Klazienaveen',
        'Boldājī',
        'Tabocas do Brejo Velho',
        'Oulad Daoud',
        'Ain Beida',
        'Tryavna',
        'Gundelfingen',
        'Arakvāz-e Malekshāhī',
        'Belampona',
        'Siruvāchchūr',
        'Minnāl',
        'Kuangfu',
        'Rāsingapuram',
        'Hathauri',
        'Erbaocun',
        'Santiago del Teide',
        'Marauatpur',
        'Kyzyl-Kyshtak',
        'Star',
        'Hadim',
        'Erkner',
        'Eraclea',
        'Kasaji',
        'Murnau am Staffelsee',
        'Froha',
        'Nueva Era',
        'Mālthone',
        'Bernalda',
        'Mariana',
        'Sremska Kamenica',
        'Tetagunta',
        'Fenton',
        'Souq Jamaa Fdalate',
        'Welver',
        'Stidia',
        'Tāran',
        'Palos Heights',
        'Bissorã',
        'Béna',
        'Tigzirt',
        'Niagadina',
        'Saksohāra',
        'Ballston',
        'Virālippatti',
        'Leominster',
        'Awānkh',
        'Terranuova Bracciolini',
        'Kountouri',
        'Paispamba',
        'Provins',
        'Phước Long',
        'Karaund',
        'Adalaj',
        'Ibaretama',
        'Pudūr',
        'Sidi Yahia',
        'Selimpaşa',
        'Norīa',
        'Vijayāpati',
        'Spencer',
        'Jiajin',
        'Arrigorriaga',
        'Umurlu',
        'Blaricum',
        'Tori-Cada',
        'Jadayāmpālaiyam',
        'Nong Bua',
        'Sondho Dullāh',
        'Konāje',
        'Trezzo sull’Adda',
        'Pecica',
        'Seberi',
        'Amarāpuuram',
        'El Parral',
        'Avesta',
        'Mykhailivka',
        'Darabani',
        'Guidel',
        'Umri',
        'Aït I’yach',
        'Zlaté Moravce',
        'Joaquim Távora',
        'South Miami',
        'Goulds',
        'Alijó',
        'Kozloduy',
        'Caño Martin Peña',
        'Dalmatovo',
        'Governador Dix-Sept Rosado',
        'Sheerness',
        'Khem Karan',
        'Enns',
        'Cinisi',
        'Lienz',
        'Don Sak',
        'Şalpazarı',
        'Distracción',
        'Lagoa do Ouro',
        'Rijkevorsel',
        'Parappukara',
        'Pfäffikon',
        'Richmond',
        'Bačka Topola',
        'Dhanauri',
        'Manchester',
        'Matmata',
        'Tectitán',
        'Pánuco de Coronado',
        'Totogalpa',
        'Çüngüş',
        'Andrelândia',
        'Heddesheim',
        'Saint-Avé',
        'Sai Kung Tuk',
        'Chaital',
        'Pedda Adsarlapalli',
        'Tosashimizu',
        'Schoonhoven',
        'Bueu',
        'Vehkalahti',
        'Mullurkara',
        'Patarrá',
        'Athol',
        'Gisors',
        'Sandridge',
        'Sansalé',
        'Red Bank',
        'Tizi Nisly',
        '‘Anadān',
        'Pacé',
        'Mandasa',
        'Gig Harbor',
        'Bilauri',
        'Hātod',
        'Martinsville',
        'Taché',
        'Poselikha',
        'Brejetuba',
        'Wellington North',
        'Zhdanivka',
        'Paramankurichi',
        'St. Andrews',
        'Fort Bonifacio',
        'Edattala',
        'Grand Baie',
        'Lalmatie',
        'Aheqi',
        'Harrislee',
        'Bairo',
        'Huanian',
        'Kotla',
        'Kronshagen',
        'Tanakallu',
        'Kirikera',
        'Mamarappatti',
        'Halstead',
        'Bound Brook',
        'Williams Lake',
        'Chāndpura',
        'Box Elder',
        'Żebbuġ',
        'Ried im Innkreis',
        'Craponne',
        'Tabontabon',
        'Ambātturai',
        'East Bethel',
        'Oqqo‘rg‘on',
        'Qorashina',
        'Bargoed',
        'Ajjanahalli',
        'Chinna Orampādu',
        'Kampenhout',
        'Bhansia',
        'Baohe',
        'Enamadala',
        'Zemamra',
        'Halásztelek',
        'Arkadak',
        'Wanding',
        'Zumbagua',
        'Belūr',
        'Gundugolanu',
        'Talant',
        'Hamilton Square',
        'Mamqān',
        'Aragoiânia',
        'Shamshernagar',
        'Pakra',
        'Neustadt',
        'Góra Kalwaria',
        'São João',
        'Sultanhanı',
        'Coussé',
        'Dudley',
        'Rutherford',
        'Cachoeira de Minas',
        'Dom Basílio',
        'Nagar',
        'Lauria Inferiore',
        'Kagamino',
        'Ojuelos de Jalisco',
        'Imaruí',
        'Mayilādi',
        'San Severino Marche',
        'Hattian Bala',
        'Gander',
        'Novaya Lyalya',
        'Kamlāpur',
        'Puliyampatti',
        'Benaguacil',
        'Gerstetten',
        'Prattipādu',
        'Jaltenango',
        'Onet Village',
        'Archdale',
        'Maisaka',
        'Badamdar',
        'Rushall',
        'Niles',
        'Comasagua',
        'Linganore',
        'Mahazoarivo',
        'Möhnesee',
        'Épinay-sous-Sénart',
        'Florange',
        'Mehsāri',
        'Bernissart',
        'Snaresbrook',
        'Altavilla Vicentina',
        'Iwaka',
        'San Fernando',
        'Eichenau',
        'Oulad Amrane el Mekki',
        'El Arba Des Bir Lenni',
        'Yuxiaguan',
        'Xincheng',
        'Rudrāngi',
        'Sant’Agata di Militello',
        'Brunn am Gebirge',
        'Monschau',
        'Vanimo',
        'Abasolo',
        'Levashi',
        'La Puebla del Río',
        'Olgiate Comasco',
        'Merāl',
        'Salem',
        'Lake Elmo',
        'Malar',
        'Iacanga',
        'Ban',
        'Laurel',
        'Big Lake',
        'Aguasay',
        'Lissegazoun',
        'Richland',
        'San Juan del Rio del Centauro del Norte',
        'Rankweil',
        'Lauenburg',
        'Eckington',
        'Khajuri',
        'Whistler',
        'Horodnia',
        'Hoyland Nether',
        'Chainpur',
        'Monte Compatri',
        'Çamaş',
        'Ivybridge',
        'Freeport',
        'São Gonçalo do Rio Abaixo',
        'Zriba-Village',
        'Imaculada',
        'Āvadattūr',
        'Miller Place',
        'Recreo',
        'Sixaola',
        'Vendas Novas',
        'Gbéroubouè',
        'L’Isle-Adam',
        'Pedda Vegi',
        'Dendulūru',
        'Sant’Ambrogio di Valpolicella',
        'Myrne',
        'Monte di Procida',
        'Lapinig',
        'Bad Vöslau',
        'Palmilla',
        'Dishāshah',
        'Brighton',
        'Péonga',
        'Pokrovskoye',
        'Vellallūr',
        'Torton',
        'Biltine',
        'Bībīpet',
        'Lizzanello',
        'Kālipatnam',
        'Wallington',
        'Middleburg',
        'Angalakurichchi',
        'Chợ Lách',
        'Trentham',
        'Chero',
        'Bordentown',
        'Wolnzach',
        'Son Servera',
        'Lahra Muhabbat',
        'Guābāri',
        'Itāmāti',
        'Sirsia Hanumānganj',
        'Licínio de Almeida',
        'Paulista',
        'Locri',
        'Andraitx',
        'Bataredh',
        'Spilimbergo',
        'Bibbiena',
        'Mangpa',
        'Raesfeld',
        'Tiddas',
        'San Andrés de Llevaneras',
        'Ratanpur',
        'Hirao',
        'Alum Rock',
        'Premnagar',
        'Bou Merdès',
        'La Tour-de-Peilz',
        'Areal',
        'Hemsbach',
        'San Pedro La Laguna',
        'Korahia',
        'Ōiwa',
        'Pilis',
        'Endwell',
        'Rasūlpur',
        'Vecchiano',
        'Ukrainsk',
        'Poulsbo',
        'Oestrich-Winkel',
        'Baranzate',
        'North Union',
        'Groß-Enzersdorf',
        'Camposampiero',
        'Chilonga',
        'Tapiratiba',
        'Leingarten',
        'Lauffen am Neckar',
        'Wath upon Dearne',
        'Toualet',
        'Barokhar',
        'Sidi Amer El Hadi',
        'Mountougoula',
        'Wargal',
        'Menfi',
        'Jataìzinho',
        'Langgöns',
        'Walldürn',
        'Barrafranca',
        'Befandefa',
        'Tīkar',
        'Holalu',
        'Senirkent',
        'Castle Pines',
        'Bocaina',
        'Dolo Bay',
        'Yakushima',
        'Portela',
        'Mboro',
        'Sonosari',
        'Château-Gontier',
        'Street',
        'Elizabethtown',
        'South Yarmouth',
        'Virgem da Lapa',
        'Oberderdingen',
        'Parsa',
        'Winfield',
        'Picayune',
        'Oswaldtwistle',
        'Kākalūr',
        'Chaplygin',
        'Ghabāghib',
        'Thogapalle',
        'Muscoy',
        'Serra do Salitre',
        'Evanston',
        'Jalalaqsi',
        'Hobyo',
        'Beladi',
        'Little Chute',
        'Portoferraio',
        'Barapiré',
        'Deerlijk',
        'Gulfport',
        'Oued Cheham',
        'Novi Marof',
        'Sânpetru',
        'Neuenrade',
        'Fenoughil',
        'Oued Seguin',
        'Maizières-lès-Metz',
        'Susegana',
        'Le Relecq-Kerhuon',
        'Srīrāmpuram',
        'Khānaqīn',
        'Mountain Top',
        'Santa Cruz Michapa',
        'Axixá',
        'Somain',
        'Zaō',
        'Muping',
        'Minden',
        'Spreitenbach',
        'Kete Krachi',
        'Tortoreto',
        'Biknūr',
        'Tiny',
        'Zwönitz',
        'Ryūō',
        'Comalapa',
        'Cafayate',
        'Aougrout',
        'Vegarai',
        'Roda',
        'Hauzenberg',
        'Ngerengere',
        'Ronchi dei Legionari',
        'Portomaggiore',
        'Oak Hills',
        'Villecresnes',
        'Kehen',
        'Fiume Veneto',
        'Baitoa',
        'Yeniçiftlik',
        'Pritzwalk',
        'Leutenbach',
        'Santa Ana',
        'Chiautla de Tapia',
        'Sidi Ladjel',
        'Olivenza',
        'Tarrytown',
        'Bechloul',
        'Sahasmal',
        'Strunino',
        'Vauvert',
        'Khānsāhibpuram',
        'Market Drayton',
        'Borgaro Torinese',
        'Alamedin',
        'Putnam Valley',
        'Negēlē',
        'Green River',
        'Pragatinagar',
        'Burgthann',
        'São Gonçalo do Pará',
        'Kankaanpää',
        'Allūr',
        'San Pedro',
        'Sīpālakottai',
        'Kotha Gurū',
        'Bitetto',
        'Show Low',
        'Amherst',
        'Baranivka',
        'Brotas de Macaúbas',
        'Guttenberg',
        'Mack',
        'Drolshagen',
        'Campos del Puerto',
        'Cogolin',
        'Hankey',
        'Söderhamn',
        'Tifni',
        'Batočina',
        'Picaña',
        'Owk',
        'Agcogon',
        'Tornquist',
        'Weston',
        'Jaitwār',
        'Kornepādu',
        'Alken',
        'Annakāttumūla',
        'Pastpār',
        'Richfield',
        'Largo',
        'Gillingham',
        'Sher Muhammadpuram',
        'Mapleton',
        'Bopfingen',
        'Hawkesbury',
        'Blace',
        'Elektrėnai',
        'Kuchinarai',
        'Monción',
        'Pößneck',
        'Bad Dürrenberg',
        'Agadi',
        'José María Morelos',
        'Waldniel',
        'Tiruvādānai',
        'Rājāram',
        'Padinjāremuri',
        'Murukondapādu',
        'Ganapavaram',
        'Lawrenceburg',
        'Abhia',
        'Edéia',
        'Chināval',
        'Tolcayuca',
        'Francofonte',
        'Bajiao',
        'Cernay',
        'Konand',
        'Bilopillya',
        'New Baltimore',
        'Rossmoor',
        'Kamianka',
        'Ovidiopol',
        'An Cabhán',
        'Bajestān',
        'Mương Theng',
        'Serravalle Pistoiese',
        'Carignan',
        'Magdagachi',
        'Ban Phan Don',
        'Scharbeutz',
        'Lindenberg im Allgäu',
        'Vasylkivka',
        'Medina Sidonia',
        'Chāltābāria',
        'Vengavasal',
        'Pallejá',
        'Yamamoto',
        'Lunenburg',
        'San Nicolás de los Ranchos',
        'General Cabrera',
        'Trinidad',
        'Septèmes-les-Vallons',
        'Hackney',
        'Chintapalle',
        'Río Colorado',
        'Dināra',
        'Chaumont-Gistoux',
        'Zhetibay',
        'Altstätten',
        'Hardiyā',
        'Sveti Nikole',
        'Mestrino',
        'East Greenwich',
        'Los Alamitos',
        'Mutia',
        'Crowley',
        'Ambatofisaka II',
        'Cossimbāzār',
        'Shady Hills',
        'Irupi',
        'Kőszeg',
        'Golub-Dobrzyń',
        'Baghauni',
        'Appingedam',
        'Guatajiagua',
        'Krujë',
        'Deutschlandsberg',
        'Nellipoyil',
        'Sandy',
        'Carmen',
        'Villefranche-de-Rouergue',
        'Carmiano',
        'Soalandy',
        'Aleksandrów Kujawski',
        'Chūndal',
        'Puente Nacional',
        'Zayukovo',
        'Auta',
        'Brookhaven',
        'Milford',
        'Alegría',
        'Piranhas',
        'Wetherby',
        'Nandimandalam',
        'Sääminki',
        'Spring Lake',
        'Ubaí',
        'Macusani',
        'Costa de Caparica',
        'Sindalakkundu',
        'Malkā',
        'Lantana',
        'Hanover',
        'Bithān',
        'Sowerby Bridge',
        'Semri',
        'Lantana',
        'Rattihalli',
        'Lansing',
        'Lower Burrell',
        'Bol',
        'Nakasongola',
        'Gatumba',
        'Chakai',
        'Geisenheim',
        'Tamezmout',
        'Sengurichchi',
        'Ūjhāna',
        'Rangsdorf',
        'Olhanpur',
        'Douglas',
        'Risca',
        'Tibbar',
        'Elma',
        'Ringwood',
        'Kissing',
        'Vellār',
        'Zārach',
        'Ibirá',
        'Waltikon',
        'Nova Gradiška',
        'Ángel R. Cabada',
        'Óbidos',
        'Hildburghausen',
        'Barkāgaon',
        'James Island',
        'Schwaigern',
        'Rostam Kolā',
        'Dolinsk',
        'Lom Sak',
        'Jacó',
        'Irmo',
        'São Bento do Sapucaí',
        'Luís Alves',
        'Maliāl',
        'Mallikkundam',
        'Izola',
        'Peiting',
        'Hollabrunn',
        'Conceição do Castelo',
        'Billerbeck',
        'Canton',
        'Seven Hills',
        'Saint-Pierre-du-Perray',
        'Venkidanga',
        'Tirodi',
        'Torri di Quartesolo',
        'Ulverston',
        'Sothgaon',
        'Cornedo Vicentino',
        'Signal Hill',
        'Center',
        'Kibi',
        'Bellmawr',
        'Udachnyy',
        'Peligros',
        'Elland',
        'Prudhoe',
        'Kiskunmajsa',
        'Vilandai',
        'Bokākhāt',
        'Alahina',
        'Bolsover',
        'Oliva',
        'Franklin',
        'Randaberg',
        'Škofja Loka',
        'Talakād',
        'Fgura',
        'São Sebastião do Uatumã',
        'Kautālam',
        'Limburgerhof',
        'Keolāri',
        'Nellipāka',
        'Kāranchedu',
        'Vermillion',
        'Befotaka',
        'Bayyanagūdem',
        'Dent',
        'Elavalli',
        'Chittāttukara',
        'Bullas',
        'Tiszakécske',
        'Narasāpuram',
        'Hallbergmoos',
        'Bryn',
        'Lanham',
        'Woodmere',
        'Sassenage',
        'Ponte San Pietro',
        'Marotta',
        'Bagaura',
        'Stokke',
        'Sandy',
        'Berlaar',
        'Bhainsahi',
        'Jardim do Seridó',
        'Zofingen',
        'San Pedro Ixcatlán',
        'Lajosmizse',
        'La Riviera',
        'Raubling',
        'Nandavaram',
        'Panamaram',
        'Yirol',
        'Sukurhutu',
        'Hidaka',
        'Lakri',
        'Jangy-Kyshtak',
        'Lessogou',
        'Mixtla de Altamirano',
        'Samesi',
        'Isaszeg',
        'Fatehābād',
        'Newport East',
        'Emmaus',
        'Norton',
        'Bucyrus',
        'Isaka',
        'Ansião',
        'Kāttakampāla',
        'Isāpur',
        'Mitai',
        'Mendota Heights',
        'Brock',
        'Mount Sinai',
        'Carver',
        'Cowdenbeath',
        'Zaoqiao',
        'Kizhakkanela',
        'Miami Shores',
        'L’Île-Perrot',
        'Durbuy',
        'Dhanaura',
        'Kunkalagunta',
        'Azcoitia',
        'Lundazi',
        'Candelaria',
        'Bolbec',
        'Sonāda',
        'Hemiksem',
        'Karmauli',
        'Siur',
        'Nzeto',
        'Guaiçara',
        'Thiers',
        'Knin',
        'Half Moon Bay',
        'Angicos',
        'Utiel',
        'Diao’ecun',
        'La Esperanza',
        'Motegi',
        'Tlachichilco',
        'Dérassi',
        'Burnham',
        'Ermenek',
        'Dammapeta',
        'Weiz',
        'Pongode',
        'Chebrolu',
        'Kallakkudi',
        'Pepperell',
        'Lakeville',
        'Porecatu',
        'Bayou Blue',
        'Tepetzintla',
        'Ostrhauderfehn',
        'Ichinomiya',
        'Nawā Nagar Nizāmat',
        'Wakefield',
        'Lubaczów',
        'Henley on Thames',
        'Middle Valley',
        'Lenvik',
        'Annappes',
        'Ban Mae Tuen',
        'Māhta',
        'Nallūr',
        'Santa Cruz Itundujia',
        'Moulay Driss Zerhoun',
        'Balaxanı',
        'Ielmo Marinho',
        'Kotra',
        'Summerland',
        'Mala Vyska',
        'Baghambarpur',
        'Saint-Gaudens',
        'Manabo',
        'Ibicuitinga',
        'Xexéu',
        'Castelló de Ampurias',
        'Ziyodin Shaharchasi',
        'Piraúba',
        'Ontario',
        'Veyrier',
        'Rokiškis',
        'Ambohimahasoa',
        'Sint Anthonis',
        'Betmangala',
        'Geisenfeld',
        'Qaşr-e Qand',
        'Kem',
        'Rāyavaram',
        'Kalmiuske',
        'Ascot',
        'Sunninghill',
        'Wolmirstedt',
        'Gararu',
        'Abertillery',
        'Sewāi',
        'Kolnād',
        'Cadale',
        'Cruzeiro do Sul',
        'Tafaraoui',
        'Wallingford',
        'Schiller Park',
        'Fuente de Oro',
        'Devipattinam',
        'Gansbaai',
        'Shuinancun',
        'Ādūru',
        'Santa Comba Dão',
        'Jiquipilas',
        'Cangas de Narcea',
        'Renāpur',
        'Hartsville',
        'Likhoslavl',
        'Mömbris',
        'Guraahai',
        'Chatham',
        'Phon',
        'Bassersdorf',
        'Halsūr',
        'Ankli',
        'Huandacareo',
        'Zero Branco',
        'Markham',
        'Lawrenceburg',
        'Loreto',
        'Santa Lucía',
        'Chambray-lès-Tours',
        'Sablan',
        'Ross-Bétio',
        'Tummalacheruvu',
        'Weeze',
        'Sākib',
        'St. Clements',
        'Khimlāsa',
        'Morūr',
        'Casteldaccia',
        'Gainza',
        'Santa Lucía',
        'Vadakkumbāgam',
        'Mangabe',
        'Kurabalakota',
        'Novoīshīmskīy',
        'Roessleville',
        'Yercaud',
        'Minnehaha',
        'Moss Bluff',
        'Oulad Friha',
        'Santa Genoveva de Docordó',
        'San Josecito',
        'Wichelen',
        'Oulad Hamdane',
        'Patterson',
        'Chegem Vtoroy',
        'Ipaporanga',
        'Town and Country',
        'View Royal',
        'Nazareth',
        'Melle',
        'Gopālapuram',
        'Le Haillan',
        'Naīgarhi',
        'Jethuli',
        'Castenedolo',
        'Bahādarpur',
        'Pettaivāyttalai',
        'Jarābulus',
        'Haradok',
        'Jīdigunta',
        'Okuizumo',
        'Ban Mae Kham Lang Wat',
        'Ban Wiang Phan',
        'Caraguatay',
        'Schwieberdingen',
        'River Forest',
        'Santa Cruz da Baixa Verde',
        'Oñate',
        'Isola della Scala',
        'Pampas',
        'La Unión',
        'Kennebunk',
        'Eijsden',
        'Landsmeer',
        'Nieuwpoort',
        'Gold Canyon',
        'Gardere',
        'Puerto Suárez',
        'Überherrn',
        'Loutráki',
        'Ghal Kalān',
        'Kushmanchi',
        'Severnyy',
        'Navarro',
        'Kopervik',
        'Yangiqo‘rg‘on',
        'Xiaoba',
        'Meßstetten',
        'Ghinda’e',
        'Yorktown',
        'Mistelbach',
        'Silves',
        'Sathmalpur',
        'Khandpara',
        'Forest',
        'Belgrave',
        'Araçás',
        'Perches',
        'Gulgam',
        'Cisneros',
        'Esperanza',
        'Anatolí',
        'Stuarts Draft',
        'Molteno',
        'Kundiawa',
        'Leāma',
        'Espita',
        'Itainópolis',
        'Perumbakkam',
        'Tadapurambākkam',
        'Esil',
        'Saint-Estève',
        'Dąbrowa Tarnowska',
        'Krosūru',
        'Sussex',
        'Derdara',
        'Bugongi',
        'Broome',
        'Carmo da Cachoeira',
        'Amelia',
        'Patera',
        'Downpatrick',
        'Ghambiraopet',
        'Port Lavaca',
        'Wanstead',
        'Muroto-misakicho',
        'Platteville',
        'Den Chai',
        'Chennūr',
        'Khorramābād',
        'Chintalavādi',
        'Nyazepetrovsk',
        'Holešov',
        'Labbaikkudikkādu',
        'Sidi Kasem',
        'Kibungo',
        'Dharmāram',
        'Mangalkot',
        'Port Morant',
        'Matca',
        'Pieve di Soligo',
        'Lamosina',
        'Weinfelden',
        'Sinzheim',
        'Nālwār',
        'Roma',
        'Taïneste',
        'Krapina',
        'Kantilo',
        'Saint Helena Bay',
        'Doda',
        'Mīt Damsīs',
        'Sunkarevu',
        'Bāhāgalpur',
        'Ichhāpur',
        'Smithfield',
        'Norfolk',
        'Vohilava',
        'Moparipālaiyam',
        'Maniago',
        'Okpo',
        'Nandipeta',
        'Ekerö',
        'Quatipuru',
        'Budha Thēh',
        'Golet',
        'Stelle',
        'Nīdāmangalam',
        'Epazoyucan',
        'Sahri',
        'Ingurti',
        'Ramena',
        'Calheta',
        'Noeux-les-Mines',
        'Doddappanāyakkanūr',
        'Shāhpur Baghauni',
        'Giffoni Valle Piana',
        'Cholpon-Ata',
        'Loyalsock',
        'Olintla',
        'Jucati',
        'Velké Meziříčí',
        'Gorey',
        'Kundurpi',
        'Drawsko Pomorskie',
        'Raun',
        'Blundellsands',
        'Cachoeira Alta',
        'Desanagi',
        'Mirik',
        'Grosse Pointe Park',
        'Dolores',
        'San Carlos de Guaroa',
        'Hlaingbwe',
        'Valdivia',
        'Brislington',
        'Orchha',
        'Kathu',
        'Doukombo',
        'Sagada',
        'Nova Odesa',
        'Coronel Dorrego',
        'Belp',
        'San Lázaro',
        'Pākkam',
        'Piprāhi',
        'Żejtun',
        'Newcastle',
        'Sultānpur',
        'Xuân Trùng',
        'Burley',
        'Radnevo',
        'Four Corners',
        'Badou',
        'Hàng Trạm',
        'Slatina',
        'Al M’aziz',
        'Estiva',
        'Gospić',
        'Mexico',
        'Dzouz',
        'Beniel',
        'Fort Morgan',
        'Liubashivka',
        'Dang‘ara',
        'Liman',
        'Strombeek-Bever',
        'Kaboua',
        'Unterföhring',
        'Ostbevern',
        'Elgóibar',
        'Lençóis',
        '’Aïn Roua',
        'Devikāpuram',
        'Taviano',
        'Nova Ubiratã',
        'Rabat',
        'Barhi',
        'Chauny',
        'Baruāri',
        'Lovington',
        'Bouabout',
        'Sonāpur',
        'Kemigawa',
        'Elk City',
        'Kamalāpuram',
        'Cherdakly',
        'Mercogliano',
        'Oskaloosa',
        'Aginskoye',
        'Zeuthen',
        'Atmākūr',
        'Montmagny',
        'Ihumwa',
        'Guayabal',
        'Wittingen',
        'Mount Fletcher',
        'Tawnza',
        'Oxelösund',
        'Coswig',
        'Salisbury',
        'Sathiāla',
        'Farmington',
        'Ridgefield',
        'Moyamba',
        'Lokhvytsya',
        'Tinogasta',
        'Nový Bor',
        'Westerkappeln',
        'San Gregorio di Catania',
        'Leon Valley',
        'Revúca',
        'Diósd',
        'Bānk',
        'Peringōttukurusshi',
        'Yakymivka',
        'Gaurihar Khāliqnagar',
        'Healdsburg',
        'Fatehpur Shāhbāz',
        'Alto Alegre dos Parecis',
        'Myronivka',
        'Jamsaut',
        'Pānāpur Langa',
        'Lindon',
        'Alfonsine',
        'East Glenville',
        'Palestina',
        'Bek-Abad',
        'Rača',
        'Sárbogárd',
        'Satyavedu',
        'Kuriyama',
        'Götzis',
        'Tadley',
        'Kavaratti',
        'Valea Adâncă',
        'Lebon Régis',
        'Vengikkal',
        'Poldasht',
        'Bath',
        'Sun Village',
        'Gonubie',
        'Pirangi',
        'Sinkolo',
        'Kishanganj',
        'Muna',
        'El Ançor',
        'Mios',
        'Hœnheim',
        'Kāri',
        'Arasūr',
        'Rupenaguntla',
        'Shanmukhasundarapuram',
        'Vlasenica',
        'Cidade Gaúcha',
        'Prudente de Morais',
        'Agamé',
        'Bhabānipur',
        'Ridgefield',
        'Fuller Heights',
        'Ḑurumā',
        'Mariehamn',
        'Loran',
        'Ninga',
        'Kalakada',
        'Rengāli',
        'Kashaf',
        'Douar Trougout',
        'Libertad',
        'Vedène',
        'Eningen unter Achalm',
        'Fourmies',
        'Khāngāon',
        'Maruttuvakkudi',
        'Burām',
        'Vitry-le-François',
        'Culcheth',
        'Shingbwiyang',
        'Mogilno',
        'Egelsbach',
        'Pike Creek Valley',
        'Ahlaf',
        'Cocentaina',
        'Chakwai',
        'Wilton Manors',
        'Pontiac',
        'Bellegarde-sur-Valserine',
        'Jódar',
        'Cantley',
        'Esmoriz',
        'Paro',
        'Chitauria',
        'Florestópolis',
        'Ouro Branco',
        'Gräfenhainichen',
        'Sidi Moussa Ben Ali',
        'Kirchhundem',
        'Odatturai',
        'El Khemis des Beni Chegdal',
        'Ban Saeo',
        'Satuek',
        'Montanhas',
        'Trostberg an der Alz',
        'Baroni Khurd',
        'North Wantagh',
        'Covington',
        'Sidéradougou',
        'Cumandá',
        'La Londe-les-Maures',
        'Milići',
        'New Garden',
        'University of California-Santa Barbara',
        'Sonoita',
        'Enghien-les-Bains',
        'Port Salerno',
        'Ajijic',
        'Dhalaa',
        'Erin',
        'Amdel',
        'Varzobkala',
        'Saubara',
        'Vlašim',
        'Harsum',
        'Lendinara',
        'Nikel',
        'Caetanópolis',
        'Gloucester City',
        'Campbellsville',
        'Egersund',
        'Puerto Rico',
        'Barahkurwa',
        'Gandhāri',
        'Woods Cross',
        'Tlacolulan',
        'Hato Corozal',
        'Méricourt',
        'Holyhead',
        'Dobbs Ferry',
        'Greensburg',
        'Er Regueb',
        'Bad Schwalbach',
        'La Libertad',
        'Shāhpur',
        'Ramallo',
        "Notre-Dame-de-l'Île-Perrot",
        'San José de Guaribe',
        'Mogogelo',
        'Kumari',
        'Stradella',
        'Koori',
        'Dorog',
        'Kuse',
        'Pallipattu',
        'Ain Legdah',
        'Malo Crniće',
        'Höhenkirchen-Siegertsbrunn',
        'Bridgeton',
        'Vakhrusheve',
        'Nemyriv',
        'Velimeşe',
        'Laqraqra',
        'Kinattukkadavu',
        'Hanover',
        'Dautphe',
        'Kondhāli',
        'Sorsk',
        'Haydock',
        'Casièr',
        'Sanniquellie',
        'Yuryuzan',
        'Qax',
        'Naliya',
        'Bytča',
        'Cadolzburg',
        'Jimaní',
        'Tourougoumbé',
        'Mahāgama',
        'Kostinbrod',
        'Guinagourou',
        'Sales Oliveira',
        'Pothia',
        'Senduriā',
        'Rāibāri Mahuawa',
        'Ulricehamn',
        'Florennes',
        'Sào Amaro das Brotas',
        'Cabo Verde',
        'Barro Alto',
        'Sociedad',
        'Lopon',
        'Pratāparāmpuram',
        'Tysvær',
        'Lumaco',
        'Majdal Shams',
        'Gänserndorf',
        'Scottburgh',
        'Pelileo',
        'Laren',
        'Buzhum',
        'Dawley',
        'Saint-Cyprien',
        'Rostraver',
        'Plan-de-Cuques',
        'Babayevo',
        'Byerazino',
        'Gundrājukuppam',
        'Wealdstone',
        'San Michele al Tagliamento',
        'Enebakk',
        'São João das Lampas',
        'Montegrotto Terme',
        'Saverne',
        'Yāllūru',
        'Greetland',
        'Higashiizu',
        'Kincardine',
        'Marktheidenfeld',
        'Nyírbátor',
        'Simrāhi',
        'Clawson',
        'Douar Sgarta',
        'Saint-Junien',
        'Newport',
        'Eldorado',
        'Sidi Tabet',
        'Guachetá',
        'Yvetot',
        'Falimāri',
        'Oak Grove',
        'East Grand Rapids',
        'Anjuna',
        'Lakhipur',
        'Chekmagush',
        'Hejiaji',
        'Grünwald',
        'Konakondla',
        'Campobello di Mazara',
        'Lijiacha',
        'Samayanallūr',
        'Halavāgalu',
        'Fulton',
        'Buckhurst Hill',
        'Baghmaria',
        'Harji',
        'Bind',
        'Hirnyk',
        'Onoto',
        'Santa Fé',
        'Sarjāpur',
        'Snyder',
        'Rosario de Mora',
        'Campo do Meio',
        'Sālamedu',
        'Pīr Maker',
        'Santa Clara',
        'Sevilla',
        'Staden',
        'Harrow Weald',
        'Suffern',
        'Gornozavodsk',
        'Mancha Real',
        'Great Bookham',
        'Little Bookham',
        'Lahfayr',
        'Bānsbāri',
        'Hamilton',
        'Boudenib',
        'Buuhoodle',
        'Uchchangidurgam',
        'Kochkor',
        'Morgan City',
        'Tilmi',
        'Musile di Piave',
        'Elliot Lake',
        'Douar Oulad Naoual',
        'Pelsall',
        'Woodbury',
        'Recke',
        'Kamiita',
        'Humahuaca',
        'Alcântaras',
        'Ploufragan',
        'Bhagwatpur',
        'Biddupur',
        'Puyehue',
        'Zambrano',
        'Parora',
        'Okmulgee',
        'Castalla',
        'Baghra',
        'Ātharga',
        'Wells',
        'Resende',
        'Yangirabot',
        'San José del Fragua',
        'Lonate Pozzolo',
        'Progress',
        'Arumbākkam',
        'Meghraj',
        'Altdorf',
        'Jamhor',
        'Pachāhi',
        'Manpaur',
        'Zierikzee',
        'Lyuban',
        'Sidi Baizid',
        'Carnoustie',
        'Gardone Val Trompia',
        'Belén',
        'Bankya',
        'Rudersberg',
        'Phulparās',
        'Manappakkam',
        'Kalinagar',
        'Newtown',
        'Bromont',
        'Engen',
        'Parvatgiri',
        'Perāmpuzha',
        'Medulla',
        'Zaragoza',
        'Bargaon',
        'Bishunpur',
        'Monte Sant’Angelo',
        'Rodenbach',
        'Akola',
        'Catskill',
        'Spencer',
        'Klyetsk',
        'Talaigua Nuevo',
        'Bataiporã',
        'Rothenburg ob der Tauber',
        'Charouine',
        'Maltby',
        'Ochsenfurt',
        'Vaal Reefs',
        'Chkalovsk',
        'Cardoso',
        'Handewitt',
        'Taurisano',
        'Tegueste',
        'Nalambūr',
        'Rheinau',
        'Knowsley',
        'Calatrava',
        'Cherlagandlapālem',
        'Pimenteiras',
        'Lamsabih',
        'Capaci',
        'Ouaklim Oukider',
        'Teniet el Abed',
        'Holbrook',
        'Domneşti',
        'Kurgunta',
        'Somerville',
        'Welzheim',
        'Dharmaj',
        'Cocoa Beach',
        'Yanahuanca',
        'Gustavsberg',
        'Waidhofen an der Ybbs',
        'Machados',
        'Humpolec',
        'Tököl',
        'Besalampy',
        'Gouvêa',
        'Nußloch',
        'Ialysós',
        'Natshal',
        'Pemmpéréna',
        'El Quisco',
        'Santa Rosa de Viterbo',
        'Pāpireddippatti',
        'Dugny',
        'Tiruvennanallūr',
        'Langarivo',
        'Māshyāl',
        'Rio Novo do Sul',
        'Ban Bang Lamung',
        'Bārīgarh',
        'Virton',
        'Tabapuã',
        'Ravenna',
        'Lardero',
        'Masinigudi',
        'Capitán Bado',
        'Varadarājampettai',
        'Taylorville',
        'Tosagua',
        'Capitán Sarmiento',
        'Leatherhead',
        'Gracemere',
        'Bitritto',
        'Mărăşeşti',
        'Oxted',
        'Burslem',
        'Drezna',
        'Pionerskiy',
        'Mülheim-Kärlich',
        'Worb',
        'Ténenkou',
        'Bruay-sur-l’Escaut',
        'Tāzhakudi',
        'Janów Lubelski',
        'Hipperholme',
        'Edasseri',
        'Dimmbal',
        'Zimna Voda',
        'Naryn',
        'Saint-Germain-lès-Arpajon',
        'Arnprior',
        'Tudela',
        'Villa Purificación',
        'Denkendorf',
        'Honmachi',
        'Ikhlāspur',
        'Sabana Yegua',
        'Jem’at Oulad ’Abbou',
        'Periya Soragai',
        'Lafrayta',
        'Sebba',
        'Inhassoro',
        'Lieksa',
        'Caldogno',
        'Miasskoye',
        'Ichinohe',
        'Estiva Gerbi',
        'Gualcince',
        'Batemans Bay',
        'Caotan',
        'Singura',
        'Commune Sidi Youssef Ben Ahmed',
        'El Ghourdane',
        'Arslanbob',
        'Drochtersen',
        'Shiloh',
        'Bad Nenndorf',
        'Panpuli',
        'Teano',
        'Getulina',
        'Chorleywood',
        'Antsambalahy',
        'Golfito',
        'Bátonyterenye',
        'Tikota',
        'Amatenango del Valle',
        'Ban Kat',
        'Stamboliyski',
        'Meru',
        'Svitlodarsk',
        'Pedro de Toledo',
        'Arpajon',
        'Norwell',
        'Dourdan',
        'Limavady',
        'Stanwell',
        'De Doorns',
        'Beldibi',
        'North Dundas',
        'Eichenzell',
        'Tramore',
        'Lakeland Highlands',
        'Pipariya',
        'Somavārappatti',
        'Biri',
        'Majanji',
        'Sajószentpéter',
        'Kanhāipur',
        'Caimanera',
        'Bhilavadi',
        'Seneffe',
        'Dammartin-en-Goële',
        'Iskapālem',
        'Curimatá',
        'Coveñas',
        'Astley',
        'Fallersleben',
        'Riebeeckstad',
        'Simeria',
        'Sinor',
        'Asthal Bohar',
        'Anderson',
        'Caetano',
        'Bicske',
        'Samahuta',
        'Burtonwood',
        'Bānāvar',
        'Trecastagni',
        'Lowes Island',
        'Manvel',
        'Derecik',
        'Kūshk',
        'Kuřim',
        'La Roche-sur-Foron',
        'Sīrpanandal',
        'Santa Cruz',
        'Maryport',
        'Bara Belun',
        'De Witt',
        'Ventersburg',
        'Søgne',
        'Beclean',
        'Largs',
        'Westphalia',
        'Ocean City',
        'Wellesley',
        'Sosenskiy',
        'Edd',
        'Arcachon',
        'Brooklyn',
        'Boultham',
        'Tāmba',
        'Datiāna',
        'Kovūrupalli',
        'Sant’Ilario d’Enza',
        'Hillsborough',
        'Niasso',
        'North Valley',
        'Jādopur Shukul',
        'Lansing',
        'Groton',
        'Poko',
        'Nārāyanavanam',
        'El Playón',
        'Kin',
        'Two Rivers',
        'Jamaat Shaim',
        'Roncador',
        'Deodha',
        'Tetyushi',
        'Lang Suan',
        'Priolo Gargallo',
        'Quebrangulo',
        'Farciennes',
        'Pulpí',
        'Malkanūr',
        'Alburquerque',
        'São João do Manhuaçu',
        'Scalea',
        'Kings Mountain',
        'Sahsaul',
        'Möglingen',
        'Barrington',
        'Willistown',
        'Touama',
        'Prien am Chiemsee',
        'Las Charcas',
        'Saint-Jean',
        'Kingsnorth',
        'Makhmālpur',
        'Alpu',
        'Männedorf',
        'Eranāpuram',
        'Greasley',
        'Cranleigh',
        'Westwood',
        'Retie',
        'Oregon',
        'Kpandae',
        'Chintakunta',
        'Bramhabarada',
        'Bardīha Turki',
        'Attippattu',
        'Gethaura',
        'Kishunpur',
        'San Pablo Atlazalpan',
        'Akjoujt',
        'Sankt Johann im Pongau',
        'Pueblo Viejo',
        'Lavandevīl',
        'Kourouma',
        'Derbisek',
        'Hagen im Bremischen',
        'Beniaján',
        'Tentena',
        'Bora',
        'Boguchany',
        'Betânia',
        'Mineiros do Tietê',
        'Resende Costa',
        'Mountain Ash',
        'Inzago',
        'Werther',
        'Lauingen',
        'Porto-Vecchio',
        'Moyuta',
        'Pasaco',
        'Monteforte Irpino',
        'Sidi Abdellah Ben Taazizt',
        'Anenecuilco',
        'Ortakent',
        'Rahiār Kunchi',
        'Saint Ives',
        'Crayford',
        'West Point',
        'Koog aan de Zaan',
        'Amarchinta',
        'Jagdīshpur',
        'Lincolnton',
        'Aratuba',
        'Antônio Carlos',
        'Herbolzheim',
        'Hormigueros',
        'Gravelines',
        'Belauncha',
        'Vaḩdattīyeh',
        'Korablino',
        'Kassa',
        'Djemmorah',
        'Amala',
        'Granbury',
        'Kaset Wisai',
        'Vanj',
        'Castle Bromwich',
        'Wanaque',
        'Chulym',
        'Kédougou',
        'Hamadānak',
        'Miechów',
        'Kursavka',
        'Şaydā',
        'Sanzana',
        'Holíč',
        'Pinheiro Machado',
        'Mannarai',
        'Hambantota',
        'Marquette-lès-Lille',
        'Dhakaich',
        'Talupula',
        'Abbeville',
        'González',
        'Rānti',
        'Emba',
        'Prachatice',
        'Diez',
        'Montechiarugolo',
        'Nembro',
        'Schöningen',
        'Beni Ounif',
        'Khargrām',
        'Isselburg',
        'Temamatla',
        'Tittachcheri',
        'Kummarapurugupālem',
        'Sonseca',
        'Lathasepura',
        'Carbonera',
        'Ichikai',
        'Hanover',
        'Zvenigovo',
        'Guilherand',
        'Bom Jesus',
        'Laukaria',
        'Lommedalen',
        'Būdili',
        'Tagami',
        'Northbrook',
        'Namli',
        'Kalasa',
        'Nelson',
        'Iygli',
        'Lloró',
        'Sanjiaocheng',
        'Tissa',
        'Valkeala',
        'Sarāb-e Tāveh-ye ‘Olyā',
        'Borjomi',
        'Sirgora',
        'Chamestān',
        'Storm Lake',
        'Ladysmith',
        'Chikni',
        'Bhisho',
        'Pihuamo',
        'Faxinal dos Guedes',
        'Tournon-sur-Rhône',
        'Shahmīrzād',
        'Kazarman',
        'Boiling Springs',
        'Feldkirchen-Westerham',
        'Mpraeso',
        'Busumbala',
        'Tādepalle',
        'Siniscola',
        'Nambour',
        'Guntupalle',
        'Damonojodi',
        'Ārambākkam',
        'Pacoti',
        'Linslade',
        'Berkley',
        'Ilfracombe',
        'Prescot',
        'Bucheya',
        'Barka Gaon',
        'Montopoli in Val d’Arno',
        'Los Almácigos',
        'Hernando',
        'Capilla del Monte',
        'Armstrong',
        'Kranenburg',
        'Parbata',
        'Salaya',
        'Wootton',
        'Malkhaid',
        'Aurelino Leal',
        'Hinwil',
        'Pūngulam',
        'Harsinghpur',
        'Kawadgaon',
        'Baryshivka',
        'Rychnov nad Kněžnou',
        'Chikkārampālaiyam',
        'Satellite Beach',
        'Mhangura',
        'Leek',
        'Moreira Sales',
        'Vaux-le-Pénil',
        'Góra',
        'Arvand Kenār',
        'Arvand Kenār',
        'Hoeilaart',
        'Grobbendonk',
        'Belzig',
        'Kuzuculu',
        'Seringueiras',
        'Westtown',
        'Tucson Mountains',
        'Coldstream',
        'Galbois',
        'Stonehaven',
        'New Port Richey East',
        'Potukonda',
        'Douar Jwalla',
        'Romanshorn',
        'Boujediane',
        'Nāgalāpuram',
        'Douar Ain Maatouf',
        'Rasaunk',
        'Penne',
        'Marginea',
        'Zakamensk',
        'Palukudoddi',
        'Târgu Lăpuş',
        'Wellington',
        'Batesville',
        'San José de Aerocuar',
        'Säter',
        'Kumbadāje',
        'Margherita di Savoia',
        'Piedmont',
        'Elon',
        'Zaouiet Says',
        'Bollullos de la Mitación',
        'Creazzo',
        'Padre Burgos',
        'Vosselaar',
        'Capela de Santana',
        'Areiópolis',
        'Dorking',
        'Grand Rapids',
        'Prymorsk',
        'Cacequi',
        'Bernardino de Campos',
        'Cherry Creek',
        'Sada',
        'Chikha',
        'Honiton',
        'Urbana',
        'Inwood',
        'Mangalmé',
        'Gerasdorf bei Wien',
        'North Lindenhurst',
        'Tambaga',
        'Baley',
        'Kriftel',
        'Aylestone',
        'Bhandārso',
        'Sierra Madre',
        'Zaragoza',
        'Villepreux',
        'Aukštieji Paneriai',
        'Somnāha',
        'Hauterive',
        'Begogo',
        'Antônio Cardoso',
        'Finale Ligure',
        'Planura',
        'Bailin',
        'Mortugaba',
        'Waldkirchen',
        'Balve',
        'Sihaul',
        'Sonbāri',
        'Bannewitz',
        'Mayūreswar',
        'Okhargara',
        'Birch Bay',
        'Atru',
        'Pulsano',
        'Cerese',
        'Lloyd',
        'Barbadanes',
        'Cesson',
        'Dorridge',
        'Olaippatti',
        'Kirensk',
        'Stollberg',
        'Hexham',
        'Cisternino',
        'Enfida',
        'Saādatpur Aguāni',
        'Waipio',
        'Guebwiller',
        'Novska',
        'Banagi',
        'Bālehonnūr',
        'Oberwil',
        'Pongalūr',
        'Kīlkottai',
        'Écaussinnes-Lalaing',
        'Kanamadi',
        'Udawantnagar',
        'Kurman',
        'Fīnch’a’ā',
        'Murapāka',
        'Manatí',
        'Roscoe',
        'Ambinanin’i Sakaleona',
        'Evington',
        'Achankovil',
        'Jamapa',
        'Sheron',
        'Iazizatene',
        'Braunfels',
        'Kowdalli',
        'Valmadrera',
        'Burr Ridge',
        'Fagersta',
        'Palmerston',
        'Kumharsan',
        'De Panne',
        'Brugg',
        'Quintanar de la Orden',
        'Orono',
        'Itanhomi',
        'Panhar',
        'Trofaiach',
        'Southborough',
        'Sultānpur',
        'Camisano Vicentino',
        'Elwood',
        'Congaz',
        'Shahrinav',
        'Mortād',
        'Kachnār',
        'White City',
        'Langeloop',
        'Puerto Caicedo',
        'Uničov',
        'Candelaria',
        'São José do Campestre',
        'Nandayure',
        'Nawalpur',
        'Morant Bay',
        'Matsuo',
        'Mineral de Angangueo',
        'Hasbergen',
        'Iver',
        'Iuiú',
        'Saint-Saulve',
        'Warren',
        'Gengenbach',
        'Kāttupputtūr',
        'La Massana',
        'Phulgāchhi',
        'Plano',
        'Kall',
        'Oporapa',
        'Salcedo',
        'Alto Parnaíba',
        'Ikkādu',
        'Waupun',
        'Vila Frescainha',
        'Tha Chang',
        'An Phú',
        'Caorle',
        'Canyon Lake',
        'Sorontona',
        'Firoza',
        'Murska Sobota',
        'Marilândia',
        'Comarnic',
        'Peri-Mirim',
        'Yamanouchi',
        'Mierlo',
        '’Aïn Taghrout',
        'Ollerton',
        'Port Wentworth',
        'Hatwāns',
        'Kārīz',
        'East Hanover',
        'Chuguyevka',
        'Nazyvayevsk',
        'San Bernardo',
        'Castelvetro di Modena',
        'Capitão de Campos',
        'Georgian Bluffs',
        'Oud-Heverlee',
        'Nāgojanahalli',
        'Parapuã',
        'Duggirāla',
        'Ararendá',
        'Gucheng',
        'Vilyuysk',
        'Illintsi',
        'Antônio Carlos',
        'Ksar Sbahi',
        'Biganos',
        'Yargatti',
        'Bishunpur',
        'Alcoa',
        'Potosí',
        'Fortuna',
        'Raceland',
        'Melsele',
        'Calçado',
        'Fairview',
        'Bang Ban',
        'Chassieu',
        'Bhattiprolu',
        'Ban Wang Daeng',
        'Panguipulli',
        'Wulingshancun',
        'San Luis de La Loma',
        'Si Wilai',
        'Terku Valliyūr',
        'Aydıncık',
        'Herrsching am Ammersee',
        'Kariat Ben Aouda',
        'Céu Azul',
        'Bagamanoc',
        'El Trébol',
        'Divinolândia',
        'Gachetá',
        'Canandaigua',
        'Juruaia',
        'Pedersöre',
        'Congonhal',
        'Şā’īn Qal‘eh',
        'Iguaraci',
        'Rāmanāyakkanpālaiyam',
        'Cleveland',
        'Belén de los Andaquíes',
        'Pāta Ellamilli',
        'Moorslede',
        'Paullo',
        'Narimanov',
        'Piazzola sul Brenta',
        'Konnūr',
        'Pirapetinga',
        'Basaithi',
        'Wen’anyi',
        'Pontchâteau',
        'Río Cuarto',
        'Arenzano',
        'Citrus Springs',
        'Berane',
        'Lālsaraia',
        'Dayālpur',
        'Karebilachi',
        'Codigoro',
        'Nossa Senhora dos Milagres',
        'Hebri',
        'Elchūru',
        'Asahi',
        'Clayton',
        'Merriam',
        'Niémasson',
        'El Águila',
        'Týrnavos',
        'Szprotawa',
        'Passy',
        'Union City',
        'Kać',
        'Dois Riachos',
        'Stiring-Wendel',
        'Selma',
        'Wyomissing',
        'Santa Mariana',
        'Barton upon Humber',
        'Cologno al Serio',
        'Lake Grove',
        'Leicester',
        'Shamunpet',
        'Marinette',
        'Doesburg',
        'Mahīn',
        'Nörvenich',
        'Panazol',
        'Kaniyambādi',
        'Villa La Angostura',
        'Longtaixiang',
        'Planegg',
        'Pēnagam',
        'General Carneiro',
        'La Calamine',
        'Timperley',
        'Whitchurch',
        'Great Wyrley',
        'San Giustino',
        'Great Neck',
        'Jayaque',
        'Korb',
        'Dalippur',
        'Lower Saucon',
        'Mainvilliers',
        'Hāthidāh Buzurg',
        'Bangramanjēshvara',
        'Rutesheim',
        'Devanakavundanūr',
        'La Trinitaria',
        'San Carlos Centro',
        'Floresta Azul',
        'Aba',
        'Shankarpalli',
        'Chhabila',
        'Kadūr Sāhib',
        'Coshocton',
        'Monmouth',
        'Mandal',
        'Zirə',
        'Āwash',
        'Le Luc',
        'Nanpala',
        'Pharāha',
        'Pompton Lakes',
        'Novoazovsk',
        'Thanh Xuân',
        'Lansdowne',
        'Moita Bonita',
        'Wangjiabian',
        'Larbert',
        'Sturgis',
        'Brzeszcze',
        'Fagundes',
        'Glyká Nerá',
        'Boguchar',
        'Montlouis-sur-Loire',
        'Ballymoney',
        'Kisara',
        'Epping',
        'Dumri',
        'Mādhopur',
        'Petal',
        'De Pinte',
        'Riorges',
        'Mukāsi Pidāriyūr',
        'Andacollo',
        'Magnolia',
        'Ráckeve',
        'Puliyara',
        'Joquicingo',
        'Jacinto',
        'Möhlin',
        'Bithlo',
        'Feira',
        'São Sebastião de Lagoa de Roça',
        'La Esperanza',
        'Chornomorske',
        'Sankhavaram',
        'Sakkamapatti',
        'Bottesford',
        'Konen Agrahār',
        'Hikawadai',
        'Yuasa',
        'Lindome',
        'Columbia',
        'Summit',
        'Athy',
        'Koch',
        'Perali',
        'Tezoatlán de Segura y Luna',
        'Aperibé',
        'Kenduadīh',
        'Murgod',
        'Rezina',
        'Phanat Nikhom',
        'Tonse West',
        'Delvāda',
        'Anguera',
        'Hornsby Bend',
        'Fanlu',
        'Riedlingen',
        'Waihee-Waiehu',
        'Cold Springs',
        'Veľký Krtíš',
        'Sosnovka',
        'São Félix',
        'Tekkumbāgam',
        'Bertinoro',
        'Milicz',
        'Botuporã',
        'Gernsheim',
        'Maromme',
        'Amha',
        'Qal‘at an Nakhl',
        'Kongupatti',
        'Ichikawa',
        'Tirumayam',
        'Balingoan',
        'Rolla',
        'Fredericksburg',
        'Brumunddal',
        'Corinda',
        'Carmo da Mata',
        'Weyburn',
        'Ekuvukeni',
        'El Hamel',
        'Tavistock',
        'Allāhdurg',
        'Velddrif',
        'Kalyazin',
        'Nedelišće',
        'Sannieshof',
        'Itatuba',
        'Bir Ghbalou',
        'Greentree',
        'Gloucester Point',
        'Mawu',
        'Mangalam',
        'Antanandava',
        'Kiwoko',
        'Allonnes',
        'La Palma',
        'Waterloo',
        'Chatteris',
        'Sarauni Kalān',
        'Fairview Shores',
        'Xinyaoshang',
        'Vijes',
        'Domažlice',
        'Patu',
        'Waynesboro',
        'Damme',
        'Wālūr',
        'Dolhasca',
        'Franklin Lakes',
        'Chinna Annalūru',
        'Sebring',
        'Regen',
        'Solymár',
        'Saucillo',
        'Shalushka',
        'Siyāna',
        'Älmhult',
        'Lorch',
        'Chauki',
        'Collegedale',
        'La Tuque',
        'Norwich',
        'Abasingammedda',
        'Morafeno',
        'Ambotaka',
        'Kalafotsy',
        'Antsoha',
        'Maroambihy',
        'Voloina',
        'Ambatomasina',
        'Ambodiampana',
        'Antsakanalabe',
        'Antsahabe',
        'Antakotako',
        'Tsararano',
        'Mahazony',
        'Fotsialanana',
        'Ambinanindovoka',
        'Ranomafana',
        'Ankavandra',
        'Manambolosy',
        'Ambohidranandriana',
        'Tsinjomitondraka',
        'Amporoforo',
        'Ambodimangavolo',
        'Analamitsivalana',
        'Bevata',
        'Antsambahara',
        'Androndrona Anava',
        'Sampona',
        'Marolinta',
        'Andranomeva',
        'Miandrarivo',
        'Ambodimanary',
        'Maroamalona',
        'Marovantaza',
        'Marotandrano',
        'Antanandava',
        'Efatsy-Anandroza',
        'Manandroy',
        'Tranomaro',
        'Vinaninkarena',
        'Soaserana',
        'Soamanova',
        'Loikaw',
        'Side',
        'Cerro Corá',
        'Zhamog',
        'Pingcha',
        'Mahād',
        'Al ‘Amādīyah',
        'Santa Flavia',
        'Burlington',
        'Karabash',
        'Itiruçu',
        'Saint-Martin-Boulogne',
        'Byelaazyorsk',
        'Lyndon',
        'Oak Ridge',
        'Edlapādu',
        'Bāgnān',
        'Jagannāthpur',
        'Shagonar',
        'Agua de Dios',
        'Srīpur',
        'Patori',
        'Vengapalli',
        'Farkhâna',
        'Llandybie',
        'Matino',
        'Issoudun',
        'Westview',
        'Dhilwān Kalān',
        'Springdale',
        'Meaford',
        'Komorniki',
        'Churchdown',
        'Guspini',
        'Ribeirão Bonito',
        'Schkopau',
        'Ma’ai',
        'Bardipuram',
        'Mānsinghpur Bijrauli',
        'Summerfield',
        'Myjava',
        'Akyaka',
        'Dayr ‘Aţīyah',
        'Sabalito',
        'Wildau',
        'Nagar Nahusa',
        'Argostóli',
        'Colwyn Bay',
        'Santa Cruz Balanyá',
        'Honwāda',
        'North Logan',
        'Belousovo',
        'Paraúna',
        'Duas Barras',
        'Santa Croce Camerina',
        'Burkburnett',
        'Citrus',
        'Fao Rai',
        'Pingtouchuanxiang',
        'Bargas',
        'Sendurai',
        'Paura Madan Singh',
        'Warden',
        'Cudworth',
        'Singoli',
        'Junín',
        'Pebble Creek',
        'Chicureo Abajo',
        'Oakham',
        'Yelpur',
        'Sesto Calende',
        'Totowa',
        'Easttown',
        'Adjala-Tosorontio',
        'Koronowo',
        'Əhmədli',
        'Piraí do Norte',
        'Xintianfeng',
        'Celina',
        'Manaíra',
        'Staryya Darohi',
        'Rekovac',
        'Punjai Turaiyāmpālaiyam',
        'Cáchira',
        'Aş Şabbūrah',
        'Bīrpur',
        'Mallagunta',
        'Satoshō',
        'Takad Sahel',
        'Tixkokob',
        'Acanceh',
        'Haigerloch',
        'Cabanillas del Campo',
        'Bāwāli',
        'North Branch',
        'Champua',
        'Vochaïkó',
        'Bhangha',
        'Campagnano di Roma',
        'Sarafand',
        'Ongwediva',
        'Guiratinga',
        'Mêdog',
        'Zhydachiv',
        'Três Cachoeiras',
        'Rāmabhadrapuram',
        'Álamos',
        'Vengūr',
        'Masakkavundanchettipālaiyam',
        'Peschiera del Garda',
        'Grand Haven',
        'Volda',
        'Mēmunda',
        'Boekel',
        'Szydłowiec',
        'Rogoźno',
        'Quimilí',
        'Dek’emhāre',
        'Matias Olímpio',
        'Tarrá',
        'El Tarra',
        'Eisenberg',
        'Rüthen',
        'Hellesdon',
        'Nacajuca',
        'Punitaqui',
        'Adendorf',
        'Neuhof',
        'Keles',
        'Highgate',
        'Tirupporūr',
        'Iioka',
        'Little Ferry',
        'Cunha Porã',
        'Burgkirchen an der Alz',
        'Tortolì',
        'Lézignan-Corbières',
        'Longford',
        'Canton',
        'Bishunpur Hakīmābād',
        'Palsud',
        'Rosario',
        'Borsbeek',
        'Devgeri',
        'Satai',
        'Al Ghayz̧ah',
        'Ibiraci',
        'Ūttukkuli',
        'Mahmūda',
        'Völkermarkt',
        'Lichtenstein',
        'Nao Kothi',
        'Cepagatti',
        'Timahdit',
        'Vadamugam Vellodu',
        'Pottireddippatti',
        'Ruisui',
        'Chevigny-Saint-Sauveur',
        'Nallamada',
        'Cacaopera',
        'Ebreichsdorf',
        'Ḩaşşeh',
        'Pleasant View',
        'Hamilton Township',
        'Kalavapūdi',
        'Pompton Plains',
        'Belgrade',
        'Rialma',
        'Ban Yang Hom',
        'Vashon',
        'Rokytne',
        'Quilombo',
        'Itamarati',
        'Rumburk',
        'The Hills',
        'Pedappai',
        'Mena',
        'Semuto',
        'Nādendla',
        'Bni Drar',
        'Texcatepec',
        'Vimmerby',
        'Cantagalo',
        'Kabīrpur',
        'Garmeh',
        'Shōō',
        'Beachwood',
        'Bedford Heights',
        'Corbas',
        'Mazzarino',
        'Sângeorz-Băi',
        'Attard',
        'Malden',
        'Étaples',
        'Kaniyūr',
        'Chatham',
        'Ørsta',
        'Malente',
        'Chāoke',
        'Valley Center',
        'Ingelmunster',
        'Swarna',
        'Donzdorf',
        'Karakthal',
        'West Caldwell',
        'Stratford',
        'Minamiise',
        'Sisia',
        'Hakone',
        'Sankhavaram',
        'Chinnatadāgam',
        'Bariārpur Kāndh',
        'Basbiti',
        'Madna',
        'Memphis',
        'Wustermark',
        'Grandview',
        'Ponteland',
        'Mossley',
        'Ferney-Voltaire',
        'Hollinwood',
        'Kuttappatti',
        'Pellezzano',
        'Barberino di Mugello',
        'Troy',
        'Berriozar',
        'Tibubeneng',
        'Brandon',
        'Wronki',
        'Guéoul',
        'Meliana',
        'Sontha',
        'Altensteig',
        'Karlsdorf-Neuthard',
        'College',
        'Blitta',
        'Tizi',
        'Prineville',
        'Arniya',
        'Venosa',
        'Timberlane',
        'Bueno Brandão',
        'Groaíras',
        'Rincon',
        'Kamen’-Rybolov',
        'Kavaklıdere',
        'Kenzingen',
        'Costeşti',
        'Zasechnoye',
        'Meulebeke',
        'Krosno Odrzańskie',
        'Oued Sebbah',
        'Keila',
        'Ogose',
        'Lehman',
        'Yairipok',
        'Koilakh',
        'Roque Pérez',
        'Chenango',
        'Muswellbrook',
        'Khānjahānpur',
        'Sangrām',
        'Nishi',
        'Pocono',
        'Bobrynets',
        'Osterwieck',
        'Karikād',
        'Chiltiupán',
        'Gages Lake',
        'Passa e Fica',
        'Tarwāra',
        'Minamisanriku',
        'Delhi',
        'Imeni Chapayeva',
        'Bobrovytsia',
        'Xudat',
        'Castel Mella',
        'Nyakosoba',
        'Gilbués',
        'Santoña',
        'Kodmiāl',
        'Manville',
        'Berthoud',
        'Alto Rio Doce',
        'Kiratpur Rājārām',
        'Haysville',
        'Soyaló',
        'Great Harwood',
        'Hardia',
        'Erraguntla',
        'Ghanpur',
        'Qaşr-e Qomsheh',
        'Terryville',
        'Bernardo de Irigoyen',
        'Wisła',
        'Sairé',
        'Yacopí',
        'Langenzenn',
        'Erstein',
        'Fife',
        'Triangle',
        'Kandry',
        'San Roque',
        'Gottmadingen',
        'Glückstadt',
        'Beni Abbès',
        'Inverurie',
        'Garden City',
        'Gentio do Ouro',
        'Coroaci',
        'Yungay',
        'La Puebla de Cazalla',
        'Lincoln Park',
        'Mesyagutovo',
        'Hammelburg',
        'Nova Vodolaha',
        'Itarana',
        'Conceição dos Ouros',
        'Rāmbilli',
        'Chandūr',
        'Węgorzewo',
        'Mae Wang',
        'Bofete',
        'Harri',
        'Garkha',
        'Petrovka',
        'Serravalle',
        'Sahoria Subhai',
        'Khān Bebīn',
        'Nivala',
        'Castelginest',
        'Siversk',
        'Rush',
        'Pallarimangalam',
        'Schönaich',
        'Kothri Kalān',
        'Shing',
        'Santa Rosa de Río Primero',
        'Figuig',
        'Anenii Noi',
        'Herxheim',
        'Estanzuela',
        'Pānetha',
        'Guthrie',
        'Sant Joan de Vilatorrada',
        'Sagarejo',
        'Ankasakasabe',
        'Arouca',
        'Governador Lindenberg',
        'Puck',
        'Arapgir',
        'Neman',
        'Lichtenau',
        'Avelino Lopes',
        'Rāmchandarpur',
        'Rio dos Cedros',
        'Phrai Bueng',
        'Guantingzhan',
        'Bistān',
        'Tanippādi',
        'Amjhera',
        'Mataili Khemchand',
        'Burrel',
        'Etoumbi',
        'Davos',
        'Bondoufle',
        'Peru',
        'Geldermalsen',
        'Khorol',
        'Sanjiangkou',
        'Mogotes',
        'Soham',
        'Whitburn',
        'Wan Tau Tong',
        'Nirpur',
        'Malvern',
        'Worth',
        'Sarmiento',
        'Ukal',
        'Shamsābād',
        'Belmonte Mezzagno',
        'Adda-Douéni',
        'Wantage',
        'Risch',
        'Hambühren',
        'Chirak',
        'Rianxo',
        'Rainhill',
        'Mudki',
        'Jõhvi',
        'Grand Gosier',
        'Venafro',
        'Asahi',
        'Onna',
        'Pīr Bakrān',
        'Estevan',
        'Anuppampattu',
        'Tiruppālaikudi',
        'Gol Tappeh',
        'Laredo',
        'Venmani Padinjāra',
        'Dolbeau',
        'Pālakollu',
        'Maryānaj',
        'Martinópole',
        'Steinbach am Taunus',
        'Sucúa',
        'Aurāhi',
        'Kapaa',
        'Travilah',
        'Brunete',
        'Kyjov',
        'Pātrasāer',
        'Yellayapālem',
        'Phibun Mangsahan',
        'Danville',
        'Villa Corzo',
        'Pattanam',
        'Nauheim',
        'Afonso Bezerra',
        'Ghagga',
        'Kulgo',
        'Avintes',
        'Amarpur',
        'Vargaūr',
        'Ngorongoro',
        'Tatarbunary',
        'Cassá de la Selva',
        'Khlung',
        'Mount Kisco',
        'Urubici',
        'Lake Monticello',
        'Atchison',
        'Pottipuram',
        'South Dundas',
        'Dabiya',
        'Roseira',
        'Centenário do Sul',
        'Pua',
        'Mutlūru',
        'Chiyoda',
        'Khaţţāb',
        'Sítio Novo de Goiás',
        'São Jerônimo da Serra',
        'Igaratinga',
        'Lavaur',
        'Earlestown',
        'Khasanya',
        'Buenavista',
        'Boizenburg',
        'Jitwārpur Chauth',
        'Reguengos de Monsaraz',
        'Amnéville',
        'Jauli',
        'Van Wert',
        'Rājghāt Garail',
        'Probištip',
        'Laranja da Terra',
        'Gamharia',
        'Kurwār',
        'Snovsk',
        'Manevychi',
        'Rāmpur Kalān',
        'Żurrieq',
        'Martin',
        'São Luís do Curu',
        'Bānu Chhapra',
        'Sugarmill Woods',
        'Hudson',
        'Union Park',
        'Sušice',
        'East Cocalico',
        'Amaturá',
        'Sérékali',
        'Oliveirinha',
        'Dário Meira',
        'Stephanskirchen',
        'Biggin Hill',
        'Kurikuppi',
        'Montgomery',
        'Alberique',
        'Cividale del Friuli',
        'Sohwāl',
        'San Valentino Torio',
        'Yuncos',
        'Hejamādi',
        'Alipur',
        'Olivença',
        'Taufkirchen',
        'Mülsen',
        'Keza',
        'Shepton Mallet',
        'Kaurihār',
        'Călăraşi',
        'Bālupur',
        'Ugrinovci',
        'Bileća',
        'Rejiche',
        'Holmen',
        'Le Pont-de-Claix',
        'Chhātāpur',
        'Andhana',
        'Tyāmagondal',
        'Borshchiv',
        'Gympie',
        'Matomou',
        'Darmahā',
        'Eugenópolis',
        'Icononzo',
        'Pālamedu',
        'Māngobandar',
        'Abbigeri',
        'Castano Primo',
        'Amuru',
        'Eski Yakkabog‘',
        'Tallimarjon Shahri',
        'Bouchagroun',
        'Methil',
        'Bāra',
        'Terra Boa',
        'Wambrechies',
        'Fox Lake',
        'Panaon',
        'Papraur',
        'Kara-Tash',
        'South Union',
        'Guaraciaba',
        'Scorniceşti',
        'Barbosa Ferraz',
        'Capena',
        'Río Segundo',
        'Cornate d’Adda',
        'Muzaffarnagar',
        'Scaggsville',
        'Timonium',
        'Argelès-sur-Mer',
        'Wooburn',
        'Zafarābād',
        'Arakawa',
        'Wald-Michelbach',
        'Binbrook',
        'Totolapan',
        'Elmira',
        'Seonār',
        'Marreddipalli',
        'Paduma',
        'Telkap',
        'Kuppachchipālaiyam',
        'Ambohidanerana',
        'Flixton',
        'Villa Nougues',
        'Los Corrales de Buelna',
        'Nurmo',
        'Sanson',
        'Kondakomarla',
        'Ravutulapūdi',
        'Emiliano Zapata',
        'Ousseltia',
        'Thouaré-sur-Loire',
        'Miyato',
        'Cantillana',
        'Ānavatti',
        'Belle Chasse',
        'Bougoula',
        'Aleksandrovsk',
        'Annoeullin',
        'Tamiang Layang',
        'Sītalpur',
        'Bolivar',
        'Parkstone',
        'Paceco',
        'Ovada',
        'Guaraci',
        'Nekarikallu',
        'Cave',
        'Chiramanangād',
        'Nilavārappatti',
        'Guaitarilla',
        'Matulji',
        'Bhanghi',
        'Nuvem',
        'Raymond',
        'Villamarchante',
        'Nāranāpuram',
        'Itamogi',
        'Nīrkunnam',
        'Akhnūr',
        'Pipalrawān',
        'Bhaurādah',
        'Waasmunster',
        'Errahalli',
        'Punjai Kālāmangalam',
        'Ban Bang Yai',
        'Gounarou',
        'DeForest',
        'Russellville',
        'Suyo',
        'Morro Bay',
        'Valentigney',
        'Mutukūru',
        'Langhirano',
        'Kirchseeon',
        'Qal‘eh Chan‘ān',
        'Bāsht',
        'Gleisdorf',
        'Agouna',
        'Khrystynivka',
        'Vire',
        'Sarsai Nāwar',
        'Kūhbanān',
        'Gori',
        'Épinay-sur-Orge',
        'Kājhi Hridenagar',
        'Jhundo',
        'Burgos',
        'Ñiquén',
        'Santo Domingo',
        'Antanandehibe',
        'Jarjanāz',
        'Kuruvambalam',
        'Rudra Nagar',
        'Del Aire',
        'Lulhaul',
        'Sāmbre',
        'Odugattūr',
        'Pragadavaram',
        'Kīlrājakularāman',
        'Alakamisy Anativato',
        'Ilıcaköy',
        'East Bakersfield',
        'Villa Unión',
        'St. Albans',
        'Bendougouba',
        'Iramaia',
        'Barnoldswick',
        'Tiszafüred',
        'Moda',
        'Sunadkuppi',
        'Lyngdal',
        'Fürth',
        'Suhāgi',
        'Kattāri',
        'Ogano',
        'Doi Saket',
        'Imi Mokorn',
        'Kuttattuppatti',
        'Jaladurgam',
        'Jackson',
        'Kelilalina',
        'Wang Sombun',
        'Kleinblittersdorf',
        'Kingri',
        'Babhnoul',
        'Komarolu',
        'Lieshout',
        'Déville-lès-Rouen',
        'Lehigh',
        'Itacurubí de la Cordillera',
        'Capela do Alto Alegre',
        'Goasi',
        'Placerville',
        'Mogwase',
        'Campo Largo',
        'Na Sceirí',
        'Racale',
        'Juruá',
        'Fondettes',
        'Sárospatak',
        'Karuvelampatti',
        'Pādiyūr',
        'Munhall',
        'Freeport',
        'Selsey',
        'Brdovec',
        'Crestwood',
        'Chanteloup-les-Vignes',
        'Kumāravādi',
        'Isola del Liri',
        'West Hanover',
        'Borovsk',
        'Pia',
        'Chandreru',
        'Riverton',
        'Nandasmo',
        'Bhālpatti',
        'Rāybāg',
        'Vellavādanparappu',
        'Vidalia',
        'Santa María',
        'Martinengo',
        'Motta di Livenza',
        'Middle Island',
        'Linthicum',
        'Tuktukan',
        'Choachí',
        'Surazh',
        'Chapeltique',
        'Le Pradet',
        'Royston',
        'Kerāi',
        'Le Taillan-Médoc',
        'La Palma del Condado',
        'Deh Bakrī',
        'Uropá',
        'Gaohucun',
        'Bad Orb',
        'Panamarattuppatti',
        'Maniamkulam',
        'Monte San Pietro',
        'Westwood Lakes',
        'Douar Bouchfaa',
        'Amesbury',
        'Forestville',
        'Andovoranto',
        'Ankarabato',
        'Taimali',
        'Rogatica',
        'Bāzid Chak Kasturi',
        'Lowell',
        'Zwettl',
        'Rājānagaram',
        'Grezzana',
        'Santa Bárbara',
        'Praskoveya',
        'Zavitinsk',
        'Ripoll',
        'Voisins-le-Bretonneux',
        'Sapahi',
        'Edwards',
        'Săcueni',
        'Asālem',
        'Fujino',
        'Milton',
        'Alagappapuram',
        'Ōtsuchi',
        'Ubalá',
        'Gülbaar',
        'Dharmavaram',
        'Sharg‘un',
        'Campolongo Maggiore',
        'Novomyrhorod',
        'Ymittós',
        'Ormesby',
        'Diss',
        'Īmani',
        'Meiwa',
        'Richmond Heights',
        'Qamīnis',
        'Villers-la-Ville',
        'Xiaozui',
        'Parnera',
        'Tigrāna',
        'Nandiala',
        'Thevūr',
        'Sivrice',
        'Louvain-la-Neuve',
        'Candiota',
        'Ichnia',
        'Frenštát pod Radhoštěm',
        'Enumulapalle',
        'Léognan',
        'Sütçüler',
        'Raeren',
        'Tamarana',
        'Rewtith',
        'Tanant',
        'Chittārkottal',
        'Sidhap Kalān',
        'Pleasanton',
        'Waggaman',
        'Arbaa Sahel',
        'Sesori',
        'Diedorf',
        'Göynücek',
        'Botticino Sera',
        'Santa Catarina Ayotzingo',
        'Sonoma',
        'Morbach',
        'Jyllinge',
        'Bou Khadra',
        'Marudūr',
        'Loano',
        'Shŭrobod',
        'Aduku',
        'Lwakhakha',
        'Bog’ot',
        'Əliabad',
        'Mara Rosa',
        'Ross on Wye',
        'Marcali',
        'Galsi',
        'Tafalla',
        'Sangam',
        'Qal‘eh Tall',
        'Malibu',
        'Pine Hill',
        'Ambohinihaonana',
        'Borgloon',
        'Desborough',
        'Tolna',
        'San Felice sul Panaro',
        'Potomac Park',
        'Rancho Mission Viejo',
        'Sam Ko',
        'Villahermosa',
        'Tarboro',
        'Nadezhda',
        'Şebin Karahisar',
        'Bestwig',
        'Chettipālaiyam',
        'Milattūr',
        'Ville-d’Avray',
        'Kurdi',
        'Madison Heights',
        'Saray',
        'Hatch End',
        'Sangalbahīta',
        'Trevignano',
        'Maryville',
        'Doraville',
        'Bāghūz Fawqānī',
        'Bogalusa',
        'San Juan',
        'Sidi Namane',
        'Chesterfield',
        'Lachhmīpur',
        'Sahuli',
        'Dallgow-Döberitz',
        'Santa Ana Huista',
        'Medesano',
        'Hartford',
        'Lyons',
        'Bischofswerda',
        'Querência do Norte',
        'Darfield',
        'Gairtganj',
        'Corning',
        'Iretama',
        'Orthez',
        'Jhundpura',
        'Taouloukoult',
        'Peralillo',
        'Gambettola',
        'Matsuda-sōryō',
        'Baetov',
        'Darby',
        'Newberry',
        'Agdz',
        'Tisma',
        'Victoria',
        'Tālsur',
        'Kattipūdi',
        'Yampil',
        'Valadares',
        'Trets',
        'Knowle',
        'Cerreto Guidi',
        'Powdersville',
        'Fô-Bouré',
        'Primavera',
        'Uzda',
        'Sebt Ait Ikkou',
        'Mathigeri',
        'Graçanicë',
        'Bela Crkva',
        'Cazzago San Martino',
        'Iwai',
        'Fountain Inn',
        'Huétor-Tájar',
        'Bhaur',
        'Dakhrām',
        'Maynard',
        'Lohariandava',
        'Ardrossan',
        'Konganāpuram',
        'Hemāvati',
        'Darb-e Behesht',
        'Clute',
        'Cricova',
        'Itārhi',
        'Singapperumālkovil',
        'Katra',
        'Besagarahalli',
        'Hosahalli',
        'Anoviara',
        'Jeseník',
        'Kivistö',
        'Kānkōl',
        'Sālotgi',
        'Dakhān',
        'Cameri',
        'Capim Branco',
        'Broomall',
        'São Brás de Alportel',
        'Lexington',
        'Betsiaka',
        'La Homa',
        'Monte Alegre do Piauí',
        'Aylesford',
        'Chuqung',
        'Joutseno',
        'Chettimangurichchi',
        'Ponnampatti',
        'Al Atārib',
        'Gürün',
        'Yeghvard',
        'Plan-les-Ouates',
        'Aulla',
        'Puranāttukara',
        'Kāla Diāra',
        'Malapannanagudi',
        'Leninskoe',
        'Myers Corner',
        'Nakonde',
        'Chantilly',
        'Kuršėnai',
        'Lamhadi',
        'Bharra',
        'Teus',
        'Daganzo de Arriba',
        'Beaumont',
        'Myślibórz',
        'Ponte Serrada',
        'West Haverstraw',
        'Antsaravibe',
        'Nowa Dęba',
        'Santo André',
        'Novotroitske',
        'Corocoro',
        'Rāmāreddi',
        'Cookstown',
        'Essex Junction',
        'Hrebinka',
        'Santa Terezinha de Goiás',
        'Kadwa',
        'Sant’Antìoco',
        'Filipstad',
        'Tinchlik',
        'Coité do Nóia',
        'Gudibanda',
        'Ambato',
        'Veselí nad Moravou',
        'Vienna',
        'Yuzhno-Sukhokumsk',
        'Gobardhanpur Kanāp',
        'Karadichittūr',
        'Galena Park',
        'Atlit',
        'Amāyan',
        'Friendly',
        'Almoloya',
        'Laćarak',
        'Ruppichteroth',
        'Sedaví',
        'Bellefontaine Neighbors',
        'Pernes-les-Fontaines',
        'Kumbhāri',
        'Sirugudi',
        'Maghra',
        'Felsberg',
        'Ngaputaw',
        'Asfour',
        'Hajeb el Aïoun',
        'San Nicolás',
        'Lambton Shores',
        'Delareyville',
        'Nueva Helvecia',
        'Barmstedt',
        'Bel Air',
        'Sânnicolau Mare',
        'Svedala',
        'Lubawa',
        'Emsbüren',
        'Escuintla',
        'Jacinto Machado',
        'Jiwachhpur',
        'Kendall Park',
        'Little Canada',
        'Chinnamandem',
        'Adohoun',
        'Pāma',
        'Monroe',
        'Blackhawk',
        'Dinagat',
        'Mount Airy',
        'Kralendijk',
        'Schleusingen',
        'Paimio',
        'Groot-Brakrivier',
        'Villa Jaragua',
        'Opatija',
        'Hanson',
        'North Dumfries',
        'Hetane',
        'Altamirano',
        'Dießen am Ammersee',
        'Longbenton',
        'Nakayama',
        'Kilibo',
        'Oloron-Sainte-Marie',
        'Usuppūr',
        'Partāp Tānr',
        'Kanur',
        'Mittahalli',
        'Urcos',
        'Bouhmama',
        'Avigliano',
        'Itzer',
        'Lyakhavichy',
        'Cadelbosco di Sopra',
        'Calçoene',
        'Standerton',
        'Jilava',
        'Ouédo-Aguéko',
        'Antardipa',
        'Canápolis',
        'Ghattu',
        'Khilok',
        'Höllviken',
        'Felton',
        'Englefield Green',
        'Sabnima',
        'Munnūru',
        'Toundout',
        'San Ignacio Cohuirimpo',
        'Lunca Cetăţuii',
        'Pires Ferreira',
        'Barharia',
        'Nanticoke',
        'Mantasoa',
        'Küçükkuyu',
        'Aranda',
        'Massa Lombarda',
        'Bajpe',
        'Abadou',
        'Malaya Vishera',
        'Tubará',
        'Aïn Zora',
        'Khovaling',
        'Küçük Dalyan',
        'Urucânia',
        'Pont-Saint-Esprit',
        'Kingsteignton',
        'Killiney',
        'Khokri Kalān',
        'Tangerhütte',
        'Sokhodewara',
        'Douglass',
        'Acari',
        'West Perrine',
        'Ironton',
        'Pinto',
        'İnebolu',
        'Akbarpur',
        'Al Maḩwīt',
        'Peschanokopskoye',
        'La Maddalena',
        'Gölpazarı',
        'Lake Barcroft',
        'Miro Khan',
        'Karpuzlu',
        'Lymanka',
        'Monte Dourado',
        'Ghoswāri',
        'Dasai',
        'Kabira',
        'Kurhani',
        'Sumner',
        'Ninheira',
        'Aït Hani',
        'Hoogland',
        'Kremenki',
        'Rānko',
        'Buşayrā',
        'Camden',
        'Vysokovsk',
        'Murtosa',
        'Tamanar',
        'Iaciara',
        'Linluo',
        'Böhl-Iggelheim',
        'Vellipālaiyam',
        'Cottage Grove',
        'Fairview',
        'Maida Babhangāwān',
        'Sonakhal',
        'San Gaspar Ixchil',
        'Kartāl',
        'Kombai',
        'Had Laaounate',
        'Jablanica',
        'São João de Ver',
        'Songo',
        'Chorrochó',
        'Jaguari',
        'Yacuanquer',
        'Tenango del Aire',
        'Grijó',
        'Kimpese',
        'Trofarello',
        'Tourza',
        'Pasil',
        'Abergele',
        'Sirūr Tājband',
        'Lejanías',
        'Acate',
        'Nakagawa',
        'Wendell',
        'Amtar',
        'Rocafuerte',
        'Raghunāthpur',
        'Bodippatti',
        'Paricônia',
        '’Aïn Fekan',
        'Zapotitlán',
        'Yazoo City',
        'Kiełczów',
        'Kolárovo',
        'Lagoa do Mato',
        'Jurbarkas',
        'Schönwalde-Siedlung',
        'Sawādah',
        'Shāmpur',
        'Tekkāttūr',
        'Aerzen',
        'Alachua',
        'Airway Heights',
        'Mulungu',
        'Pôrto Firme',
        'Lenzburg',
        'Longuenesse',
        'Gajiginhālu',
        'Daşkəsən',
        'Catanduvas',
        'Scottdale',
        'Tarazona de Aragón',
        'Kasavanampatti',
        'Lakeland Village',
        'Vouzela',
        'Monsenhor Gil',
        'Pūliguntā',
        'Yorkshire',
        'Corumbá de Goiás',
        'Flămânzi',
        'Ban Ko',
        'São Tiago',
        'Bangshang',
        'Neuenhaus',
        'Briançon',
        'Flers-lez-Lille',
        'Trabia',
        'Ambohitromby',
        'Ambolotarakely',
        'Ascope',
        'Koppāka',
        'Acushnet',
        'Bad Iburg',
        'Las Matas de Santa Cruz',
        'Kiến Giang',
        'Millington',
        'Kamikawa',
        'Kanagichō',
        'Puente de Piedra',
        'Pachauth',
        'Pella',
        'Campton Hills',
        'Sawla',
        'Damascus',
        'Chitagá',
        'Pélissanne',
        'Lohārda',
        'Darauli',
        'Kuiyibagecun',
        'Fenglin',
        'Tinqueux',
        'Nave',
        'Don Galo',
        'Beverly Hills',
        'Weinböhla',
        'Le Mars',
        'Martensville',
        '‘Utaybah',
        'Sihma',
        'Monument',
        'Máncora',
        'Ada',
        'Paddhari',
        'Usmate Velate',
        'Niel',
        'São José do Calçado',
        'Büdelsdorf',
        'Mathila',
        'Sweetwater',
        'Excelsior Springs',
        'Mokrisset',
        'Sabaneta de Yásica',
        'Tarabha',
        'Sadovoye',
        'Tilougguit',
        'Phaphot',
        'Kulriān',
        'Red Bank',
        'Yoko',
        'Bir Ben Laabed',
        'Urrugne',
        'Paranã',
        'Chervyen',
        'Wendeburg',
        'Sambalhera',
        'Bischofshofen',
        'Ormesson-sur-Marne',
        'Werlte',
        'Lasht-e Neshā',
        'Shiloh',
        'Marotaolana',
        'Grevesmühlen',
        'Torpa',
        'Madathapatti',
        'Montalegre',
        'Saint-Gély-du-Fesc',
        'Arāvelli',
        'Chota Mollakhāli',
        'Jhonkar',
        'Antenetibe',
        'Apt',
        'Pallattūr',
        'San Juanito',
        'Torotoro',
        'Southwick',
        'Maevka',
        'Weingarten',
        'Nāgambhotlapālem',
        'The Pinery',
        'Zmeinogorsk',
        'Mfou',
        'Leninaul',
        'Kākhandiki',
        'River Grove',
        'Pineville',
        'Mouans-Sartoux',
        'Tāmarankottai',
        'North Bellport',
        'Rabta',
        'Sidi Bousber',
        'Garag',
        'Riverdale',
        'Pazaryeri',
        'Reiskirchen',
        'Reading',
        'Mapleton',
        'Cotacachi',
        'Alcora',
        'Tárnok',
        'Skilloúnta',
        'Alderwood Manor',
        'Dhilwan',
        'Tulshia',
        'Karukkalvādi',
        'Saks',
        'Wanderlândia',
        'La Virgen',
        'Frontera',
        'Benedito Novo',
        'Recreio',
        'Flawil',
        'Felida',
        'Rasebetsane',
        'Parczew',
        'Sahuria',
        'Nāhargarh',
        'Buttar Khurd',
        'Montale',
        'Cedro',
        'Xambioá',
        'Paittūr',
        'Simpelveld',
        'Gölyaka',
        'Flöha',
        'New Albany',
        'Sainte-Savine',
        'Pierre-Bénite',
        'Herīs',
        'Naganuma',
        'Fairview',
        'Loudoun Valley Estates',
        'Forest Acres',
        'Shumanay',
        'Perūr',
        'Vargem',
        'Boudouaou el Bahri',
        'Stansbury Park',
        'Plankstadt',
        'Hilter',
        'Al Karak',
        'Neuhausen am Rheinfall',
        'Obuse',
        'Winterville',
        'Burgau',
        'Bagrīnagar',
        'Monmouth',
        'Nallippālaiyam',
        'Surinam',
        'Santiago Chimaltenango',
        'San Josecito',
        'Murata',
        'Morsand',
        'Soquel',
        'Imst',
        'Andonabe Atsimo',
        'Heule',
        'Ertvelde',
        'Cristino Castro',
        'Tierra Colorada',
        'Čelić',
        'Agramonte',
        'Riesi',
        'Jiménez',
        'San Ricardo',
        'Ban Thung Khao Phuang',
        'Usmat Shaharchasi',
        'Dehqonobod',
        'Nuriston',
        'Bibala',
        'Court-Saint-Étienne',
        'Poção',
        'Angostura',
        'Vembūr',
        'Balwa',
        'Sāmākhiāli',
        'Yedappalli',
        'Kamifurano',
        'Rājāpur',
        'Paina',
        'Presidente Vargas',
        'Markt Indersdorf',
        'Candelaria Loxicha',
        'Susuz',
        'Rio do Pires',
        'Sadon',
        'Lighthouse Point',
        'Buriti Alegre',
        'Bad Wildbad',
        'Kibichūō',
        'Hillcrest',
        'Delta',
        'Marck',
        'Bhopālia',
        'Naini',
        'Adalpur',
        'Devanakonda',
        'Nowe Miasto Lubawskie',
        'Krishnamsettipalle',
        'Heath',
        'Santa Maria de Itabira',
        'Griñón',
        'Gudlavalleru',
        'Hōki',
        'Kearney',
        'Olëkminsk',
        'Soledar',
        'Likiškiai',
        'Regidor',
        'Whitnash',
        'Souq Sebt Says',
        'Chencha',
        'Duraiswāmipuram',
        'Begampur',
        'Ingeniero White',
        'Rājpur Kalān',
        'Gommern',
        'Bonham',
        'Ryhope',
        'Shelton',
        'Ouolodo',
        'El Paraíso',
        'Coacoatzintla',
        'Leidschendam',
        'Attleborough',
        'Somers Point',
        'Sidi Abdallah',
        'Alfredo Wagner',
        'Buraan',
        'Ban Lueak',
        'Lake Hopatcong',
        'Bellmead',
        'Pitkyaranta',
        'Folomana',
        'Las Veredas',
        'Saúde',
        'La Escala',
        'Héricourt',
        'Ambhua',
        'Ontario',
        'Bolekhiv',
        'Cacimbinhas',
        'Arkalochóri',
        'Burgstädt',
        'Clermont',
        'Tiruvalam',
        'Machulishchy',
        'Rokunohe',
        'Española',
        'Hulshout',
        'Taormina',
        'Palatka',
        'Nová Dubnica',
        'Corzuela',
        'Luanco',
        'Balua Rāmpur',
        'Tokigawa',
        'Ambahy',
        'Čáslav',
        'Krèmiss',
        'Bordj Okhriss',
        'Al Musayfirah',
        'Dáli',
        'Hartswater',
        'Belāo',
        'Dighāwāni',
        'Santiago Tangamandapio',
        'Novodnistrovsk',
        'Itaipé',
        'Wepangandla',
        'Vaddepalli',
        'Dhanwār',
        'Gemona del Friuli',
        'Bluffton',
        'Macajuba',
        'Vermilion',
        'Floresta',
        'Olbernhau',
        'Friedeburg',
        'Holbeach',
        'Waimea',
        'East Bradford',
        'Karahallı',
        'Vikrutamāla',
        'Burhia Dhanghatta',
        'Lake Morton-Berrydale',
        'Chalco',
        'Teotlaltzingo',
        'Santa Margarita de Mombúy',
        'Pleasant Hill',
        'Gökçe',
        'Palestina',
        'Lollar',
        'Villers-Cotterêts',
        'Kopparam',
        'Pedras de Maria da Cruz',
        'Launceston',
        'Lakhnā',
        'Standish',
        'Khurmi',
        'Phai Sali',
        'Valpoy',
        'Portage',
        'Minanba',
        'Santiago',
        'Sylva',
        'Flat Rock',
        'Zuyevka',
        'Braine-le-Château',
        'Da',
        'Catanduvas',
        'Vieux-Condé',
        'Bakwa',
        'Lonkly',
        'Fervedouro',
        'El Chol',
        'Biržai',
        'Catunda',
        'Burton Latimer',
        'Saidapet',
        'Plaine Magnien',
        'Timrå',
        'Muquém de São Francisco',
        'Wildberg',
        'Sudogda',
        'Valley',
        'Santa Teresinha',
        'São Sebastião da Grama',
        'Litomyšl',
        'Ulft',
        'Thilogne',
        'Mochizuki',
        'Minobu',
        'Wapienica',
        'Ivankiv',
        'Nova Europa',
        'Koth',
        'Laukāha',
        'Abay',
        'Silverton',
        'Sidi Boushab',
        'Yelm',
        'Manakana',
        'Kāmthi',
        'Dumri',
        'Sisai',
        'Bālia',
        'Dnestrovsc',
        'Yeşilköy',
        'Karkamış',
        'Zawyat Ahançal',
        'Antsahadinta',
        'Choyr',
        'Difficult Run',
        'Le Locle',
        'Eccleston',
        'Melito di Porto Salvo',
        'Plattekill',
        'Fatehpur',
        'Vengānellūr',
        'Ibiassucê',
        'Kiso',
        'Lovejoy',
        'Králŭv Dvŭr',
        'Tarare',
        'Awfouss',
        'Estaimpuis',
        'Takon',
        'Suhr',
        'Labin',
        'Warr Acres',
        'Sotkamo',
        'Fatehpur',
        'Kamalāpuram',
        'Portes-lès-Valence',
        'Worcester',
        'Nevelsk',
        'Southborough',
        'South Lebanon',
        'Darling',
        'Rifle',
        'Firminópolis',
        'Nideggen',
        'Valatt',
        'Nagtala',
        'Roca Sales',
        'Coriano',
        'Libānggaon',
        'Nahulingo',
        'Jurbise',
        'Salò',
        'Wabash',
        'Simbach am Inn',
        'Smithville',
        'Rawdon',
        'Bogué',
        'Ciechocinek',
        'Altenberge',
        'Chandera',
        'Kragerø',
        'Trat',
        'Dona Inês',
        'Shenjiaba',
        'Phangnga',
        'Kalanak',
        'Fairmont',
        'Waterford',
        'Oulad Slim',
        'Târgu Ocna',
        'Prestonpans',
        'Kingston',
        'Grafton',
        'Scartho',
        'Māchalpur',
        'Cunha Alta',
        'Aydıncık',
        'Corral de Bustos',
        'Telsang',
        'Bāghīn',
        'Belaya Kholunitsa',
        'Uraí',
        'Planalto',
        'Oued Amlil',
        'Villeneuve-lès-Maguelone',
        'Thorigny-sur-Marne',
        'Bradford-on-Avon',
        'Barhi',
        'Prakhon Chai',
        'Northfield',
        'Kotharpettai',
        'Kihō',
        'Scituate',
        'Opglabbeek',
        'Podstrana',
        'Marlton',
        'Koffiefontein',
        'Nordkirchen',
        'Kodūru',
        'Ban Ueam',
        'Plombières',
        'Braunsbedra',
        'Cislago',
        'Fayzobod',
        'Masindi Port',
        'Terra Alta',
        'Banta',
        'Cadillac',
        'Brikcha',
        'Croissy-sur-Seine',
        'Mount Vista',
        'Waverly',
        'Hithadhoo',
        'Hachīrūd',
        'Montecchio Emilia',
        'Fairmount',
        'Santiago Suchilquitongo',
        'Kamianka-Buzka',
        'Sāīnkhera',
        'Fanjā’',
        'Great Dunmow',
        'Charlton Kings',
        'Sleepy Hollow',
        'Cuatro Ciénegas de Carranza',
        'Lemmer',
        'Demmin',
        'Mādhopur',
        'Velappādi',
        'Eriyodu',
        'Gateway',
        'Turmānīn',
        'Kangaroo Flat',
        'Uchoa',
        'Narkatpalli',
        'Neustadt',
        'Jhāua',
        'Farmersville',
        'Suchanino',
        'Puerto Quijarro',
        'Palestina',
        'Tripurāntakam',
        'Leones',
        'Santa Clara La Laguna',
        'Nossen',
        'Jigani',
        'Coronel Freitas',
        'Kaufering',
        'Gelves',
        'Mātsavaram',
        'Dougba',
        'Ickenham',
        'Alaçatı',
        'Gokinepalle',
        'Molango',
        'Constantina',
        'Morinville',
        'Senhora dos Remédios',
        'Caém',
        'Paramoti',
        'Telkapalli',
        'Nakasato',
        'Spout Springs',
        'Gayāspur',
        'Kennett',
        'Cholchol',
        'Faradābād',
        'Jālihalli',
        'Truşeni',
        'Zdzieszowice',
        'Capitólio',
        'Akim Swedru',
        'Pāraippatti',
        'Sangrāmpur',
        'Comstock Park',
        'Spa',
        'Belém de Maria',
        'Altusried',
        'Galion',
        'Fasintsara',
        'Steinfeld',
        'Desuri',
        'Nūkān',
        'Ban Non Sombun',
        'Nidamānūru',
        'Pilar',
        'Mercês',
        'Nalgora',
        'Ordubad',
        'Neuenstadt am Kocher',
        'Mādhopur Hazāri',
        'Raghunāthpur',
        'Winchendon',
        'Douar Echbanat',
        'Conceição da Aparecida',
        'Steinau an der Straße',
        'Sidi Brahim',
        'Phek',
        'Millstone',
        'Czarnków',
        'Mānkur',
        'Sarapuí',
        'Villeneuve-Tolosane',
        'Gharyāla',
        'Plymouth',
        'Sarnen',
        'Haikoucun',
        'Puduppatti',
        'Lima',
        'Nova Olinda',
        'Atripalda',
        'Klipphausen',
        'Kottapuram',
        'Cori',
        'Calimesa',
        'Tnine Sidi Lyamani',
        'Libanté',
        'Augustdorf',
        'Kalgi',
        'Bommārbettu',
        'Momanpet',
        'Corleone',
        'Ortaköy',
        'Ambalanūr',
        'Peravali',
        'Itabirinha de Mantena',
        'Nieder-Olm',
        'Fakirtaki',
        'Shasta Lake',
        'Tavriisk',
        'Bassian',
        'Taksimo',
        'Anröchte',
        'Phon Charoen',
        'Itri',
        'Dan',
        'Jaguaribara',
        'Phulmālik',
        'Bonate di Sopra',
        'Blandford Forum',
        'Khawaspur',
        'Banga',
        'Kemin',
        'Arkadelphia',
        'Fairview',
        'Bārah',
        'Kelandis',
        'Scotchtown',
        'Aesch',
        'Siktiāhi',
        'Hisar',
        'Garden City',
        'Krasnoilsk',
        'Rio do Fogo',
        'Kiklah',
        'Nijoní',
        'Sartana',
        'Gourock',
        'Hirehadagalli',
        'Rounia',
        'Hârlău',
        'Ravanusa',
        'El Refugio',
        'Mallan',
        'Coroneo',
        'Vellatūru',
        'Woodlyn',
        'Delportshoop',
        'Māruteru',
        'Kiridh',
        'Srikrishnapur',
        'Villacarrillo',
        'Shahrak-e Enqelāb',
        'María Pinto',
        'Brikama Ba',
        'Mānikpur',
        'Silea',
        'Canatlán',
        'Jeumont',
        'Mokarrampur',
        'Konkavāripalle',
        'Itapé',
        'Cheruvannūr',
        'Northampton',
        'Brewster',
        'Hoek van Holland',
        'Salempur',
        'Ganga Sāgar',
        'Førde',
        'Natonin',
        'Palmeiras',
        'Steinen',
        'Chestnut Ridge',
        'Aulendorf',
        'Cabestany',
        'Sonwān',
        'Atessa',
        'São Luís do Paraitinga',
        'Tirkha',
        'White Marsh',
        'Garrucha',
        'Caspe',
        'Letychiv',
        'Bowral',
        'Trumbull Center',
        'São Miguel das Matas',
        'Wittelsheim',
        'Levanger',
        'Dăbuleni',
        'Magas',
        'Thạnh Phú',
        'Firou',
        'Aydarken',
        'Benalla',
        'Ahogbeya',
        'Matina',
        'Ben Nasseur',
        'Teddington',
        'Oppatavādi',
        'Ban Dan Na Kham',
        'Melres',
        'Gagnef',
        'Santo Antônio do Jacinto',
        'São Domingos',
        'Qārī Kolā-ye Araţeh',
        'Exeter',
        'El Jicaral',
        'Alvorada do Sul',
        'Erlenbach am Main',
        'Ratnagiri',
        'Oneida',
        'Sirakoro',
        'Ananás',
        'Pintadas',
        'Inhangapi',
        'La Riche',
        'Babhniyāwān',
        'Watervliet',
        'Beni Oulid',
        'Vytegra',
        'Meerhout',
        'Weilheim an der Teck',
        'Vila Franca do Campo',
        'Buzdyak',
        'Itaueira',
        'Wallaceburg',
        'Àrvorezinha',
        'El Álamo',
        'Villebon-sur-Yvette',
        'Riano',
        'Alexandria',
        'Astorga',
        'Arimalam',
        'Appenweier',
        'Stranraer',
        'Ranchos',
        'Höchst im Odenwald',
        'Harsola',
        'Dombāchcheri',
        'Hariāna',
        'Kāmepalle',
        'Newport',
        'Caracol',
        'Berwick',
        'Teocuitatlán de Corona',
        'Karuzi',
        'Peñarroya-Pueblonuevo',
        'Yādavolu',
        'Āzamnagar',
        'Chong-Aryk',
        'Geneseo',
        'Etropole',
        'London',
        'Burtonsville',
        'São Romão',
        'Mildenhall',
        'Clay',
        'West Vero Corridor',
        'Blackfalds',
        'Virginópolis',
        'Úmbita',
        'Aghbalou n’Kerdous',
        'Agua Blanca Iturbide',
        'Amurrio',
        'Bacliff',
        'Wood River',
        'General Salgado',
        'Pola de Lena',
        'Rāmchandrapur',
        'Brookdale',
        'Tyukalinsk',
        'Solim',
        'Paris',
        'Bounaamane',
        'Haspra',
        'Qiushanxiang',
        'Auhar Sheikh',
        'Motibennur',
        'Chester',
        'Riolândia',
        'Rodelas',
        'Southampton',
        'Kurşunlu',
        'Buriti do Tocantins',
        'Khiram',
        'South Huntington',
        'Waynesville',
        'Queens',
        'Jaqma',
        'Großburgwedel',
        'Moldava nad Bodvou',
        'Mwaya',
        'Maurilândia',
        'Jordânia',
        'Qanliko‘l',
        'Pyetrykaw',
        'Broadstone',
        'Lakkireddipalle',
        'Trajano de Morais',
        'Merzenich',
        'Limoux',
        'Knezha',
        'Baran',
        'Caparrapí',
        'Bel Imour',
        'Sabana Grande',
        'Raymondville',
        'Örkelljunga',
        'Santa Inês',
        'Kangning',
        'Birsfelden',
        'Rancho Arriba',
        'Kalamūla',
        'Boldeşti-Scăeni',
        'Alto Paraíso de Goiás',
        'Rubim',
        'Nāna',
        'Shahar Telpa',
        'Bad Lauterberg',
        'Locate di Triulzi',
        'Versailles',
        'Murrells Inlet',
        'Armanāz',
        'Bonfinópolis',
        'Bordj Zemoura',
        'Lyuboml’',
        'Kirchlinteln',
        'Castelli Calepio',
        'Ouroeste',
        'Ratauli',
        'Castelnovo ne’ Monti',
        'Argayash',
        'Morsbach',
        'Balaungi',
        'Zāwal',
        'Fort Madison',
        'Dalgān',
        'Vilkaviškis',
        'Jordbro',
        'Seridó',
        'Lenoir City',
        'Forestville',
        'Santana',
        'Ma‘rabā',
        'Ekinözü',
        'Guimarães',
        'Billdal',
        'Lutry',
        'Międzychód',
        'Srīsailain',
        'Simarwāra Durgāpur',
        'Arraias',
        'Lishuping',
        'Zaozërnyy',
        'Terra de Areia',
        '’Aïn Tellout',
        'Spitalfields',
        'Rāyen',
        'Hecelchakán',
        'Irineópolis',
        'Cheam',
        'Arlöv',
        'Schalksmühle',
        'Jankampet',
        'Tangermünde',
        'Kandrāwān',
        'La Plata',
        'Bandrele',
        'Sidi Bou Ali',
        'San José de Feliciano',
        'São Geraldo',
        'São Carlos',
        'Sant’Agata de’ Goti',
        'Sidi Embarek',
        'Candelaria',
        'Ganvié',
        'Venecia',
        'Shirako',
        'Itapitanga',
        'Bāgh-e Bahādorān',
        'Oppeano',
        'Ban Wisit',
        'Bogen',
        'San Maurizio Canavese',
        'Selkirk',
        'Saint-Félicien',
        'Kambarka',
        'La Trinité',
        'Mallapuram',
        'Dora',
        'Nanbu',
        'Potiraguá',
        'Salvatierra de Miño',
        'Mehdauli',
        'Peddāpuram',
        'Meghaul',
        'Marina del Rey',
        'Tello',
        'Bela',
        'Tremonton',
        'Gafour',
        'Tekkēkara',
        'Kīlminnal',
        'Gaunivāripalle',
        'Tabubil',
        'Guadalupe',
        'Ullūr',
        'Carroll',
        'Estreito de Câmara de Lobos',
        'Bhikkiwind Uttār',
        'Mel Seval',
        'Pine Ridge',
        'Lenguazaque',
        'Somireddipalle',
        'Māli',
        'Dar Si Aissa',
        'Villa Elisa',
        'Ludlow',
        'Reddipalle',
        'Tipp City',
        'Jaltocan',
        'Ertil',
        'Saudade',
        'Alcaudete',
        'Appleton',
        'Rockport',
        'Chivhu',
        'Goldenstedt',
        'Indalvai',
        'Tenente Ananias Gomes',
        'Axixá do Tocantins',
        'Nazária',
        'Lahstedt',
        'North Lakes',
        'Oliveira de Frades',
        'Rio Acima',
        'Ericeira',
        'Tettuppatti',
        'Alpine',
        'Hopetown',
        'Westonaria',
        'Kut Chap',
        'Fronteiras',
        'Jaipur',
        'Chavinda',
        'Gerzat',
        'Hämeenkyrö',
        'Gohuma Bairia',
        'Querfurt',
        'Blumberg',
        'Sint-Lievens-Houtem',
        'Maxaranguape',
        'Popovača',
        'Körmend',
        'Shahmīrpet',
        'Tārar',
        'Barai',
        'Siparia',
        'Enriquillo',
        'Milton',
        'Whitestown',
        'Elkhorn',
        'Bonito de Santa Fé',
        'Kirchzarten',
        'Bougaribaya',
        'Lonquimay',
        'Ostercappeln',
        'Fenggeling',
        'Goldbach',
        'North Bend',
        'Puerto Santander',
        'Süßen',
        'Alberobello',
        'Boaz',
        'East Whittier',
        'Murowana Goślina',
        'Jaqueira',
        'Nayānagar',
        'Rovinari',
        'Davenport',
        'Robstown',
        'Sgamna',
        'Badru Khān',
        'Induno Olona',
        'Galimuyod',
        'Santa Teresinha (2)',
        'Vairampatti',
        'San Marzano sul Sarno',
        'Catarina',
        'Palmácia',
        'Lūgovoy',
        'Santa Isabel Ishuatán',
        'Angelim',
        'Vila Muriqui',
        'Maheshrām',
        'San Diego Country Estates',
        'Jean-Mermoz',
        'Avranches',
        'Khair Khān',
        'Vellur',
        'Sucupira do Norte',
        'Spelle',
        'Brumath',
        'Ksar Lmajaz',
        'Pfastatt',
        'Kīramangalam',
        'Appārāopeta',
        'Königsbach-Stein',
        'Borgampād',
        'New Hyde Park',
        'Marawī',
        'Pūvalūr',
        'East Setauket',
        'Olifantshoek',
        'Agudos do Sul',
        'Bernex',
        'Rotonda',
        'Głogów Małopolski',
        'Santa Maria Madalena',
        'Chantepie',
        'Pārtibanūr',
        'Minatitlán',
        'Gislaved',
        'Igarapé Grande',
        'Treillières',
        'Raibhīr',
        'Vigasio',
        'Concordia Sagittaria',
        'Akbez',
        'Samux',
        'Governador Archer',
        'Lemington',
        'Jītpur',
        'Muttam',
        'Isola Vicentina',
        'Roberval',
        'Canápolis',
        'Villanueva de Arosa',
        'Miribel',
        'Monte San Juan',
        'Potunūru',
        'Duque Bacelar',
        'Betzdorf',
        'Luathaha',
        'Āhiro',
        'Benyahia Abderrahmane',
        'Koila Dewa',
        'São Miguel de Touros',
        'Halfway',
        'Néo Karlovási',
        'Tha Muang',
        'Dinard',
        'Alguazas',
        'La Chapelle-Saint-Mesmin',
        'Vadakku Ariyanāyakipuram',
        'Kowary',
        'Dangriga',
        'Algarrobo',
        'Milford',
        'Alhendín',
        'Năsăud',
        'Campo Redondo',
        'Santa María',
        'Csömör',
        'Multi',
        'Highland',
        'Tanggemu Nongchang',
        'Nāgasamudram',
        'Divisópolis',
        'Lystrup',
        'Igny',
        'Pingree Grove',
        'Moimenta da Beira',
        'Pântano Grande',
        'Duga Resa',
        'Kandulāpuram',
        'Minamiaso',
        'Charalá',
        'Zumaia',
        'Aubière',
        'Snodland',
        'Ramacca',
        'Oignies',
        'Cordele',
        'Tlalixtac de Cabrera',
        'Cedral',
        'Varre-Sai',
        'Centralina',
        'Huittinen',
        'Douchy-les-Mines',
        'Rideau Lakes',
        'Bonthe',
        'Vejen',
        'Borzna',
        'Medina',
        'Pôrto Esperidião',
        'Flowood',
        'Ouangani',
        'Mosquera',
        'Miāni',
        'Columbia',
        'Fort Oglethorpe',
        'Liberty',
        'Pintuyan',
        'Shyroke',
        'Kastav',
        'Rāmannapeta',
        'Oudewater',
        'Çanta',
        'Tefenni',
        'Navabad',
        'Chavkandak',
        'Kazo',
        'Ellon',
        'Alpena',
        'Sechelt',
        'Tetela del Volcán',
        'Rehburg-Loccum',
        'Courrières',
        'Cortês',
        'Garhi',
        'Inkerman',
        'Bosanska Krupa',
        'Florânia',
        'Tarashcha',
        'Beilngries',
        'Douar Mzoura',
        'New Silksworth',
        'Kottapālem',
        'Barāgaon',
        'Tokunoshima',
        'Carencro',
        'Niebüll',
        'Eleşkirt',
        'Arataca',
        'Claymont',
        'Phra Pradaeng',
        'Radyvyliv',
        'Al Jazīrah al Ḩamrā’',
        'Pescaria Brava',
        'Narasingam',
        'Barāhi',
        'Lake Arrowhead',
        'Jemaat Oulad Mhamed',
        'San Blas',
        'São José do Jacuípe',
        'Mirante',
        'Blain',
        'Gudofredo Viana',
        'Carmo do Rio Verde',
        'Potosí',
        'Venkatāpuram',
        'Celano',
        'Randazzo',
        'Balangkayan',
        'Załęże',
        'Rainbow City',
        'Astoria',
        'Biandanshan',
        'Poirino',
        'Pechea',
        'Chaungtha',
        'Nanfang',
        'Carice',
        'Jimbolia',
        'Münchberg',
        'Amgachia',
        'Birao',
        'Angwāli',
        'Aliganj',
        'Kothi',
        'Darwa',
        'Akil',
        'Kaynaşlı',
        'Vera Cruz',
        'Shirbadgi',
        'Halgūr',
        'Kendallville',
        'Băcioi',
        'Olovo',
        'Gaillard',
        'Fort William',
        'Mikhaylov',
        'Remada',
        'Chikni',
        'Surla',
        'Birkenfeld',
        'Jhakhra',
        'Pānchi',
        'Jandiāla',
        'Kulundu',
        'Kranuan',
        'Jesenice',
        'Madridejos',
        'Ajjipuram',
        'Pipra Naurangiā',
        'Bellamkonda',
        'Svidník',
        'Ban Bong Tai',
        'Marlton',
        'Bela Vista de Minas',
        'Canteras',
        'Bomporto',
        'Evander',
        'Mörlenbach',
        'Cedartown',
        'Korolevo',
        'Fanzhao',
        'Lābhgaon',
        'Katālpur',
        'Ban Bang Toei',
        'Roddam',
        'Biskupiec',
        'Balikumbat',
        'Bleicherode',
        'Foz',
        'Vorsma',
        'Itapiranga',
        'San Pablo',
        'Ware',
        'Mexicaltzingo',
        'Tut',
        'Zelfana',
        'Calasparra',
        'Jādupatti',
        'Port Townsend',
        'Neuville-en-Ferrain',
        'Bordighera',
        'Castleton',
        'Bois-des-Filion',
        'Rogerstone',
        'New Richmond',
        'Ambalavao',
        'Toca',
        'Sobral de Monte Agraço',
        'Pabégou',
        'Togamalai',
        'Penn',
        'Corte Madera',
        'Vūtukūru',
        'Msila',
        'Sidi Abdelaziz',
        'Akhaltsikhe',
        'Batán',
        'Narot Mehra',
        'Shioya',
        'Higashikagura',
        'Aïn Zohra',
        'Valaparla',
        'Kilkunda',
        'Hazle',
        'Opochka',
        'Teplodar',
        'Maur',
        'Tiruchchuli',
        'Kanteru',
        'Telnāl',
        'Arcola',
        'Molalla',
        'Pilar',
        'Choele Choel',
        'Werneck',
        'Lenggries',
        'Masanasa',
        'Caudete',
        'Aizenay',
        'Kāza',
        'Msoga',
        'Busca',
        'Ban Klang',
        'Paris',
        'Gakuch',
        'Zhengdong',
        'Ouled Rabah',
        'Parempuyre',
        'Visbek',
        'Whitchurch',
        'Mungod',
        'Periyanegamam',
        'Vernal',
        'Jefferson',
        'Franklin',
        'Bibbiano',
        'Alella',
        'Ban Pa Hung',
        'Great Missenden',
        'North Gates',
        'Za’roura',
        'Divonne-les-Bains',
        'Néo Psychikó',
        'Villasāgar',
        'San Felice Circeo',
        'Cabo Rojo',
        'Montignies-le-Tilleul',
        'Okondja',
        'Caudebec-lès-Elbeuf',
        'Rignano Flaminio',
        'Hermantown',
        'Elattūr',
        'Iizuna',
        'Liminka',
        'Corfe Mullen',
        'Fílippoi',
        'Dhanur Kalyānwādi',
        'Snohomish',
        'Siswār',
        'Haldipur',
        'Urlaţi',
        'Aigle',
        'Rompicherla',
        'Solindābād',
        'Rāparla',
        'Hire Megalageri',
        'Qovlar',
        'Potavaram',
        'Hobart',
        'Greenville',
        'Plaridel',
        'Kirkel',
        'Serebryansk',
        'Scherpenzeel',
        'Génova',
        'Deūlgaon Māhi',
        'Auterive',
        'Serra Caiada',
        'Bilga',
        'Kondalahalli',
        'Bhagirathpur',
        'Hillsdale',
        'Doorn',
        'Borim',
        'Mawai',
        'Garden Acres',
        'Monaragala',
        'Pocklington',
        'Beccles',
        'Manglūr',
        'Sirigeri',
        'Ankily',
        'Eidsberg',
        'Zavolzhsk',
        'Cajapió',
        'Belagola',
        'Sūlagiri',
        'Yermolino',
        'Oggaz',
        'Meymand',
        'Kabataş',
        'Altötting',
        'Granada',
        'Meuselwitz',
        'Rettanai',
        'Hipparga',
        'Begowāl',
        'Nāthpur',
        'Sedico',
        'Hull',
        'Fuveau',
        'Aroāli',
        'Rāsak',
        'Tolbazy',
        'Torre Santa Susanna',
        'Sylva',
        'Verkhniy Tagil',
        'Vaddādi',
        'Kalakeri',
        'Anandnagar',
        'Horti',
        'Ardal',
        'Corcuera',
        'Komorowice',
        'Struer',
        'Safford',
        'Masdi',
        'Kondakindi Agrahāram',
        'Adigappādi',
        'Hackettstown',
        'Warrenton',
        'Garliava',
        'Marondry',
        'Uchtepa Qishlog’i',
        'Bandixon',
        'Isabela',
        'Baker City',
        'North Glengarry',
        'Türkan',
        'Gunbarrel',
        'Videle',
        'M’Chouneche',
        'Raghunāthpur',
        'Tutzing',
        'Mori',
        'Harrison',
        'Zérizer',
        'Buxerolles',
        'Daruvar',
        'Mashhad Rīzeh',
        'Perumuchchi',
        'Parnaguá',
        'Kittery',
        'Syców',
        'San Simón',
        'Gambissara',
        'Cuapiaxtla de Madero',
        'Havanūr',
        'Nizza Monferrato',
        'Nkokonjeru',
        'Jomasho‘y',
        'Shohimardon',
        'Naftalan',
        'Forres',
        'Chauki Hasan Chauki Makhdum',
        'Glenwood Springs',
        'Rokkasho',
        'Nogliki',
        'Sarız',
        'Roboré',
        'Fouesnant',
        'Padiham',
        'Machchand',
        'Shāhpur Chaumukhi',
        'Muurame',
        'La Misión',
        'Talugai',
        'Korosavāda',
        'Progress Village',
        'South Huron',
        'Guémoukouraba',
        'Kumçatı',
        'Sahidganj',
        'Marieville',
        'Kadalādi',
        'Procida',
        'Cairo',
        'Candelaria',
        'Worsley',
        'Ekamba',
        'Zhatay',
        'Nong Ki',
        'Quartz Hill',
        'Warfield',
        'Jangalapalle',
        'Advi Devalpalli',
        'Brandywine',
        'Mellieħa',
        'Kapyl',
        'Erutukada',
        'Cambridge',
        'Miḩqan',
        'Hardās Bigha',
        'Karapa',
        'Ōnan',
        'Alexandria',
        'Vaals',
        'Kafr Takhārīm',
        'Kele',
        'Padugaipattu',
        'Littleton',
        'Roßtal',
        'Gignac-la-Nerthe',
        'Spanish Fort',
        'Primeiro de Maio',
        'Fiuggi',
        'Cody',
        'Zuidlaren',
        'Bingham',
        'Kilsyth',
        'Thikriwāla',
        'Chichihualco',
        'São Sebastião do Maranhão',
        'Hongliuwan',
        'Tympáki',
        'Pińczów',
        'Umarizal',
        'Weil im Schönbuch',
        'Abergavenny',
        'Ráth Tó',
        'Eumseong',
        'Penetanguishene',
        'Santa Catarina Masahuat',
        'Hozin',
        'Saladoblanco',
        'Kouinine',
        'Cabañaquinta',
        'Mondeville',
        'Mālīnagar',
        'Sapkyo',
        'Rio Vista',
        'Naters',
        'Saint-Max',
        'Kuroshio',
        'La Grange',
        'Williston',
        'Sax',
        'Ghatāwān',
        'Novi di Modena',
        'San Elizario',
        'Çeltik',
        'Khaira',
        'Roelofarendsveen',
        'Bieber',
        'Binéfar',
        'Cabriès',
        'Yaotsu',
        'Atoka',
        'East Rockaway',
        'Villerupt',
        'Bee Ridge',
        'Neves',
        'San Vicente de Castellet',
        'Mesrā',
        'Badia Polesine',
        'Waldwick',
        'Stropkov',
        'La Leonesa',
        'Göytəpə',
        'Ouédo',
        'Porangaba',
        'Kasba',
        'Howell',
        'Antanananivo',
        'Mondaí',
        'Bāgalūr',
        'Vrnjačka Banja',
        'Kidira',
        'Avelgem',
        'Swieqi',
        'Valozhyn',
        'Willstätt',
        'Mazamet',
        'Lake Hiawatha',
        'La’tamna',
        'Ban Bu Sung',
        'Mstsislaw',
        'Heubach',
        'Kwale',
        'West Point',
        'Olaine',
        'Bobenheim-Roxheim',
        'Were Īlu',
        'Auchel',
        'Kosh-Agach',
        'Sung Noen',
        'Struthers',
        'Jafra',
        'Jocoro',
        'Radlett',
        'Browns Mills',
        'Clinton',
        'Orange Lake',
        'Dudhpura',
        'Shāhpur Undi',
        'Bāra Khurd',
        'Mocharim',
        'Alassio',
        'Scenic Oaks',
        'Burgum',
        'Parthenay',
        'Mold',
        'Rupāna',
        'Abjīj',
        'Macerata Campania',
        'Jiji',
        'Călan',
        'Kisújszállás',
        'Dhāmua',
        'Taghbalt',
        'San Ignacio de Moxo',
        'Rüdesheim am Rhein',
        '’Aïn Naga',
        'Dalāwarpur',
        'Begijnendijk',
        'Satipo',
        'Verucchio',
        'Krivodanovka',
        'Sāha',
        'Rangasamudram',
        'Vange',
        'Lysá nad Labem',
        'La Bañeza',
        'Takahama',
        'Blackwells Mills',
        'Namorona',
        'Levski',
        'Méry-sur-Oise',
        'Al Hāmah',
        'Vysokyi',
        'Schotten',
        'Oakbrook',
        'İliç',
        'Francisville',
        'Ciudad Tula',
        'Chapeltown',
        'Lydney',
        'Taiyūr',
        'Gauli Palāsiya',
        'Petmanhalli',
        'Pájaros',
        'Elgin',
        'Büyükorhan',
        'Tinkoni',
        'Lepākshi',
        'Sakuho',
        'Makaha',
        'Mikuszowice',
        'Mengibar',
        'Lipova',
        'Maywood',
        'Tiszaföldvár',
        'Glocester',
        'Zaniéna',
        'Abaiara',
        'Nallikodūr',
        'Sermoneta',
        'Cross Lanes',
        'Parilla',
        'Ikryanoye',
        'Bou’nane',
        'Vembaditālam',
        'Cheat Lake',
        'Mahārājapuram',
        'Baldeogarh',
        'Volpago del Montello',
        'Tay',
        'Zaggota',
        'Olevsk',
        'Houthulst',
        'Suthālia',
        'Puduparambubhāgam',
        'Başmakçı',
        'Sirugamani',
        'Nikaidō-kaminoshōchō',
        'Massi',
        'Hājīpur',
        'Red Hook',
        'Ganapavaram',
        'Castlegar',
        'Lauterach',
        'Munagapāka',
        'Bela',
        'Goito',
        'Benemérito',
        'Novooleksiivka',
        'Tomboutou',
        'Pānrepatti',
        'Lālmunia Munhāra',
        'Kūcheşfahān',
        'Bierbeek',
        'Steinhaus',
        'Kaldenkirchen',
        'Vernouillet',
        'Southwater',
        'Kirundo',
        'Kīl Perambalūr',
        'Harrisonville',
        'Dumont',
        'Canelli',
        'Toukoto',
        'Kingaroy',
        'Airmont',
        'East Rutherford',
        'Kuldīga',
        'Corozal',
        'Reedsburg',
        'An Nayrab',
        'Grosse Pointe Farms',
        'Vernon',
        'Vuktyl',
        'George Mason',
        'Trebbin',
        'Belpāra',
        'Cavan Monaghan',
        'Huejuquilla el Alto',
        'Ramiriquí',
        'Montignoso',
        'Chesapeake Ranch Estates',
        'Pajacuarán',
        'San Pedro Huamelula',
        'Dylym',
        'Dhangaraha',
        'Manahari̇̄',
        'Pakka Kalān',
        'Sīlamalai',
        'Juripiranga',
        'Mayfield',
        'Roslyn',
        'Fort Meade',
        'Piedrahita',
        'Saint-Laurent-de-la-Salanque',
        'Dagiāpāra',
        'North Versailles',
        'Platón Sánchez',
        'Strasshof an der Nordbahn',
        'Vandamettu',
        'Dores de Campos',
        'Le Beausset',
        'Capodrise',
        'Moba',
        'Suaita',
        'Alajärvi',
        'Senanga',
        'Zaruma',
        'Saint-Loubès',
        'Lowell',
        'Villiersdorp',
        'Horbury',
        'Tiri',
        'Amwa Majhār',
        'Soahany',
        'Haider Khel',
        'Krynica',
        'Shengping',
        'Kolagallu',
        'Srîfa',
        'Chartoûn',
        'Qâna',
        'Amioûn',
        'Râs el Metn',
        'Ed Dâmoûr',
        'Butha-Buthe',
        'Ghadāmis',
        'Belavabary',
        'Mahabako',
        'Boanamary',
        'Morafeno',
        'Esira',
        'Sandravinany',
        'Bedidy',
        'Vohitrafeno',
        'Daraina',
        'Masiaboay',
        'Ambatomivary',
        'Ambodisikidy',
        'Ambohimiarivo',
        'Bekodoka',
        'Maroharatra',
        'Ambararatabe',
        'Ambatomifanongoa',
        'Ambohitrambo',
        'Ebelo',
        'Tsararano',
        'Analalava',
        'Lohafary',
        'Antsoantany',
        'Ambovonomby',
        'Isahara',
        'Ambodivoara',
        'Vodiriana',
        'Ambohimahazo',
        'Ambodimadiro',
        'Andranambolava',
        'Marosakoa',
        'Amborompotsy',
        'Soavimbahoaka',
        'Erada',
        'Mahabe',
        'Mahabo-Mananivo',
        'Miary-Taheza',
        'Ankadindambo',
        'Antaretra',
        'Betrandraka',
        'Amparihy',
        'Tanamarina',
        'Sahanivotry-Manandona',
        'Andranomenatsa',
        'Ambodimandresy',
        'Anontsibe-Sakalava',
        'Amparihitsokatra',
        'Ambatolava',
        'Ankerana',
        'Sihanamaro',
        'Vinanitelo',
        'Vinanitelo',
        'Ifarantsa',
        'Miarinarivo',
        'Ampasimazava',
        'Vohitany',
        'Vohitsaoka',
        'Andranopasy',
        'Beheloka',
        'Ankirondro',
        'Tamponala',
        'Ambatolahy',
        'Katsepy',
        'Vondrozo',
        'Tanambao-Daoud',
        'Sahatona-Tamboharivo',
        'Beanana',
        'Soatanana',
        'Ampitahana',
        'Anosimparihy',
        'Vatana',
        'Ambalanjanakomby',
        'Zoma-Bealoka',
        'Jangany',
        'Ianapera',
        'Ambahatrazo',
        'Fanjakana',
        'Mora',
        'Putao',
        'China',
        'Padang Besar',
        'Závora',
        'Outjo',
        'Al Mazyūnah',
        'Surmon Chogga Grong',
        'Basla',
        'Gadani',
        'Bunji',
        'Ghota Fatehgarh',
        'Setúbal',
        'Diabougou',
        'Koumpentoum',
        'Gadoon',
        'Murgap',
        'Khunays',
        'Kadama',
        'Bukomansimbi',
        'Xishrov',
        'Oyim',
        'Tujg',
        'Boladı',
        'Fatikchari',
        'Puerto America',
        'Mboki',
        'Gar',
        'Ziketan',
        'Sola',
        'Mataguá',
        'Morayra',
        'Karaga',
        'Rebola',
        'Pont Sondé',
        'Haria',
        'Waghāi',
        'Ghargaon',
        'Deh',
        'Medarametla',
        'Hanzviur',
        'Amsin',
        'Muttalakanpatti',
        'Isnapuram',
        'Karumūlaikkal',
        'Dhalai',
        'Nidamalūru',
        'Budwan',
        'Khānpur',
        'Venkatāpuram',
        'Chhimluang',
        'Dhīrwās',
        'Siddarāmpuram',
        'Reha Mota',
        'Paravākkottai',
        'Mevāni',
        'Chhatarpur',
        'Thariāl',
        'Dewal Thal',
        'Lakshmīpuram',
        'Gāndhali',
        'Fatehgarh',
        'Kambhāladinne',
        'Kerwāda',
        'Ulipuram',
        'Kochhor',
        'Betnoti',
        'Bālāgām',
        'Mangalam',
        'Sotik',
        'Kargi',
        'Kourani',
        'Kolno',
        'Rāmamangalam',
        'Bishunpur',
        'Wahlstedt',
        'Pierrelaye',
        'Bomareddipalli',
        'Czersk Pomorski',
        'Aniche',
        'Rakhwāri',
        'Atri',
        'Melendugno',
        'Bad Frankenhausen',
        'Mengen',
        'Soyaux',
        'Digora',
        'Downham Market',
        'Vilpatti',
        'San Marcos',
        'Euxton',
        'Erraballa',
        'Pāikpāra',
        'Fatehābād',
        'Lambesc',
        'Yādwād',
        'Monteriggioni',
        'Marton',
        'Ketugrām',
        'Marshall',
        'Älta',
        'Tarhjicht',
        'Lillers',
        'Praia do Carvoeiro',
        'Stenungsund',
        'Rangamāti',
        'Barkly East',
        'Pavlikeni',
        'Salemi',
        'Kinnelon',
        'Ben N’Choud',
        'Fateha',
        'Kengarai',
        'Pullambādi',
        'Chada',
        'Dilra',
        'Aşağı Ayıblı',
        'Gudensberg',
        'Inungūr',
        'Simri',
        'Budd Lake',
        'Maḩajjah',
        'Lapinlahti',
        'Galleh Dār',
        'Lenīnskīy',
        'Brentwood',
        'Gūlyam',
        'Hosur',
        'Woodbury',
        'Independence',
        'Conway',
        'Chambly',
        'Crikvenica',
        'Itikalapalle',
        'Cape Canaveral',
        'Paināl',
        'Madhuban',
        'Penn Forest',
        'Sulęcin',
        'Wood-Ridge',
        'Fairmount',
        'Yueyaquan',
        'Dettingen an der Erms',
        'Cedar Hills',
        'El Cairo',
        'Shildon',
        'Kanyāna',
        'Maḩalleh-ye Shīrīnū',
        'Ainring',
        'Ringkøbing',
        'Tansandra',
        'Fairfield',
        'Bolsward',
        'Hamilton',
        'Eybens',
        'Bishopstoke',
        'Erenler',
        'Valdobbiadene',
        'Quinto di Treviso',
        'Walker Mill',
        'Marysville',
        'San Nicolas Buenos Aires',
        'São Tomé',
        'Inami',
        'Tsunō',
        'Willoughby Hills',
        'Přelouč',
        'Ottendorf-Okrilla',
        'Marly',
        'Bhawānīpur',
        'Barano d’Ischia',
        'Boundji',
        'Bananal',
        'Dumri',
        'Māmobihāt',
        'Colts Neck',
        'East Liverpool',
        'Casino',
        'Kottaipatti',
        'Tzucacab',
        'Gaolingcun',
        'Sîngera',
        'Tân Sơn',
        'Dujiashigou',
        'Vegachí',
        'Erraguntlakota',
        'Detroit Lakes',
        'Altenholz',
        'Cuesmes',
        'Aberaman',
        'Tokkavādi',
        'Hiranai',
        'Los Ríos',
        'Kishunpur',
        'Kambaliyampatti',
        'Şūfīān',
        'Iwashita',
        'Anamã',
        'Chākicherla',
        'San Calixto',
        'Birchington',
        'Judenburg',
        'Sagurē',
        'Nairn',
        'Makale',
        'Sūndekuppam',
        'Kapasiāwān',
        'Woodburn',
        'Padinjārebāgam',
        'Khāndsa',
        'Bastak',
        'Aībak',
        'Bertem',
        'Friedrichsthal',
        'Mahem',
        'Siechnice',
        'Sankt Andrä',
        'Handsworth',
        'Conselve',
        'Ettimadai',
        'Khurān Milik',
        'Ratne',
        'Kawara',
        'Bastrop',
        'DuPont',
        'Halls',
        'Souama',
        'Saharbani',
        'Muskegon Heights',
        'Koloti',
        'Leppävirta',
        'Kapuvár',
        'Anáhuac',
        'Fallston',
        'Kargopol',
        'Aich',
        'Bad König',
        'Oelsnitz',
        'Sorkheh',
        'Picnic Point',
        'Hooglede',
        'Coal',
        'Fiľakovo',
        'Verkhivtseve',
        'Qufādah',
        'Sellersburg',
        'Arauá',
        'Torrejón de la Calzada',
        'Winfield',
        'Birni Lafia',
        'Puttige',
        'Ennamangalam',
        'Cuicatlan',
        'Heeze',
        'Aboso',
        'Chavusy',
        'Tamzoura',
        'Bhado Khara',
        'Ban Ngao',
        'Payerne',
        'Bad Bevensen',
        'Balatonalmádi',
        'Sparta',
        'Mount Holly',
        'Snezhnogorsk',
        'Kamalasai',
        'Sniatyn',
        'Thị Trấn Mậu A',
        'San Antonio',
        'Consuegra',
        'Aldenham',
        'Bellavista',
        'Ócsa',
        'Erquelinnes',
        'Caraíbas',
        'Nariār',
        'Fino Mornasco',
        'P’yŏngch’ang',
        'Pachchāmpālaiyam',
        'Dubove',
        'Pôrto Xavier',
        'Ammanabrolu',
        'Gossau',
        'Padilla',
        'Kenafif',
        'Coycoyan de las Flores',
        'Bad Ems',
        'Ipupiara',
        'Dongou',
        'La Sierra',
        'Marpingen',
        'Burscough',
        'Kamdoli',
        'Santa Maria',
        'Nohfelden',
        'Kraaipan',
        'Waltenhofen',
        'Mīāndasht',
        'Mulungu',
        'Telwa',
        'Hingyon',
        'La Pointe',
        'Akalāpura',
        'Telpur',
        'Kinālūr',
        'Santa Clara',
        'Fosses',
        'Lālganj',
        'Khāpdeh',
        'Bucine',
        'Morden',
        'Birkenau',
        'Karimunjawa',
        'Kalimala',
        'Kadrābād',
        'Parwāha',
        'Villas',
        'Farsund',
        'Grossos',
        'Ţālkhvoncheh',
        'Heves',
        'Vedurupāvalūru',
        'Rockcreek',
        'Ban Pong Yaeng Nai',
        'Oldenburg in Holstein',
        'Kargahiā Purab',
        'Nanzhou',
        'Maxéville',
        'Bajwāra',
        'Capitola',
        'Welkenraedt',
        'Dongcha',
        'Angalakudūru Malepalle',
        'Rye Brook',
        'Temiskaming Shores',
        'Urzhum',
        'Nové Město na Moravě',
        'Dharmavaram',
        'Minnāmpalli',
        'Jilotlán de los Dolores',
        'Novgorodskoye',
        'Setubinha',
        'Bekkaria',
        'Balderton',
        'Pavittiram',
        'Fair Oaks Ranch',
        'Honwāda',
        'Saarijärvi',
        'Barni',
        'Ellisville',
        'Varzedo',
        'Pipraun',
        'Mussomeli',
        'Uniontown',
        'Strzelce Krajeńskie',
        'Liskeard',
        'Pūdimadaka',
        'Dar El Kebdani',
        'Mandello del Lario',
        'Pokrovske',
        'Manduri',
        'Epanomí',
        'Kunnūr',
        'Karajgi',
        'San Isidro',
        'Polistena',
        'Arroyohondo',
        'Sidi Rahhal',
        'Boufatis',
        'Kaunra',
        'Vaiano',
        'Phak Hai',
        'Holsbeek',
        'Glanmire',
        'Bharhopur',
        'Lomazzo',
        'Coweta',
        'Kaith',
        'Ogdensburg',
        'Maceira',
        'Latifpur',
        'Zefýri',
        'Nāvinipatti',
        'Panjāb',
        'Sursee',
        'Baldock',
        'Kirkland',
        'Matadepera',
        'Carmaux',
        'Suhiya',
        'Kourimat',
        'Maisenhausen',
        'Pachalum',
        'Shankarampet',
        'Tonse East',
        'Nenagh',
        'Wāngi',
        'Kümmersbruck',
        'Bilehra',
        'Mission',
        'Pliezhausen',
        'Wilsele',
        'Huldenberg',
        'Schlitz',
        'Dera Baba Nanak',
        'Ippagūdem',
        'Asola',
        'Golden Hills',
        'Rödinghausen',
        'Brinkmann',
        'Frýdlant nad Ostravicí',
        'Diavatá',
        'Siruvalūr',
        'Urgnano',
        'Miadanandriana',
        'Messíni',
        'Baghānt',
        'Jettihalli',
        'Köflach',
        'Ivančice',
        'Worth',
        'Belmont',
        'Sūknah',
        'Coello',
        'Pizarra',
        'Gudgeri',
        'Souleï',
        'Namakadu',
        'Campi Salentina',
        'River Vale',
        'Qiziltepa',
        'Muthābana',
        'Bhadsara',
        'Saquisilí',
        'St. Augustine Shores',
        'Mohlanapeng',
        'Kaimāti',
        'Kadanganeri',
        'Chintapalle',
        'Gurwaliā Biswās',
        'Hinton',
        'Itapeva',
        'Petorca',
        'Sultānpur',
        'Saint-Sauveur',
        'Ankazotsifantatra',
        'Vardenik',
        'Biot',
        'Beckett Ridge',
        'Ga-Kgapane',
        'Kaiken',
        'Ploërmel',
        'Meldola',
        'Wapakoneta',
        'Trancoso',
        'Hosahalli',
        'Ech Chaïbat',
        'Chupaca',
        'Barahra',
        'Margarita',
        'Baltāra',
        'Kösching',
        'Heilsbronn',
        'Krasnyy Yar',
        'Widhwidh',
        'Ban Pha Bong',
        'Vétraz-Monthoux',
        'Foum Jam’a',
        'Ban Phan Chali',
        'Beneditinos',
        'Nayāgaon',
        'Reddigūdem',
        'Heerlerbaan',
        'Ruoqiang',
        'Santa María de Palautordera',
        'Kanjiža',
        'Saint-Pierre-du-Mont',
        'Qāzigund',
        'Douar Snada',
        'Kushnarënkovo',
        'Metsamor',
        'Alcorta',
        'Ouled Brahim',
        'Montigny-en-Gohelle',
        'Chegūr',
        'Ommangi',
        'Varna',
        'Stocksbridge',
        'San Miguel Sigüilá',
        'Sarkeghāṭ',
        'Krzeszowice',
        'Keokuk',
        'Therwil',
        'Lajes',
        'Pālepalli',
        'Iwamuro-onsen',
        'Greencastle',
        'Berd',
        'Swan Hill',
        'Muttattuteruvu',
        'Boudinar',
        'Ntorosso',
        'Roche-la-Molière',
        'Poggio a Caiano',
        'Richmond Heights',
        'Sauk Village',
        'Ambodiriana',
        'Belomorsk',
        'Laakirchen',
        'Vettaikkāraniruppu',
        'Celldömölk',
        'Lingampet',
        'Mahisānrh',
        'Geylegphug',
        'Independent Hill',
        'Nueva Palmira',
        'Majhgawān',
        'Tanichchiyam',
        'Outa Bouabane',
        'Zinkiv',
        'Paradarāmi',
        'Humlebæk',
        'Abram',
        'Bērikai',
        'Ciudad de Loreto',
        'Aranzazu',
        'Mittenwalde',
        'Dandoli',
        'Huichapan',
        'Ii',
        'Sovicille',
        'Nhandeara',
        'Narasimharājapura',
        'Sweet Home',
        'Grigny',
        'Fundeni',
        'Rodynske',
        'Pailón',
        'Renaico',
        'Norosí',
        'Fuente Palmera',
        'Feyzin',
        'DeRidder',
        'Juru',
        'Achchippatti',
        'Kadoli',
        'Santo Stefano di Magra',
        'Cuencamé de Ceniceros',
        'Altofonte',
        'Plains',
        'Mariluz',
        'Tīgaon',
        'Anjahamana',
        'Lambertville',
        'Eerbeek',
        'Berezivka',
        'Barcs',
        'Surīr',
        'Hochdorf',
        'Bisingen',
        'Tūlin',
        'Boriguma',
        'Mosjøen',
        'Tha Mai',
        'Merchweiler',
        'Katarmāla',
        'Cavriago',
        'Sturbridge',
        'Kobeliaky',
        'Chimay',
        'Hānsa',
        'Dindanko',
        'Masquefa',
        'Khariāl',
        'Kokiladānga',
        'Pedda Kotayalanka',
        'Itamukkala',
        'Kaimūh',
        'Shāhzādpur',
        'Manubolu',
        'Partanna',
        'Qarabalyq',
        'Saint-Grégoire',
        'Patsanda',
        'Philipstown',
        'Makariv',
        'Chandankiāri',
        'Ozieri',
        'Fort Salonga',
        'Carregal do Sal',
        'Touwu',
        'Karghar',
        'Vigonovo',
        'Succasunna',
        'Tekit',
        'Bardmoor',
        'Çıldır',
        'Aramari',
        'Cachipay',
        'Tolmezzo',
        'Sieverne',
        'Parkes',
        'Etchojoa',
        'Iraiyūr',
        'Pushpattūr',
        'Terku Narippaiyūr',
        'Bīrpur Bārāpatti Pindraun',
        'Peru',
        'Mudgee',
        'Bockenem',
        'Kodivalasa',
        'Kamikita-kita',
        'Ribadeo',
        'Couzeix',
        'Basso',
        'Toudja',
        'Gangāpur Athar',
        'Suntar',
        'Berilo',
        'Ilsfeld',
        'Pādarti',
        'Nisarpur',
        'Loudonville',
        'Kusugal',
        'Guia Lopes da Laguna',
        'Alfaro',
        'Nödinge-Nol',
        'Jixian',
        'Pithaura',
        'Baragaon',
        'Mogoşoaia',
        'San Rafael Obrajuelo',
        'Nāgulapādu',
        'Amjhār',
        'Castelletto sopra Ticino',
        'Jesup',
        'San Vicente',
        'Jennings',
        'Nong Wua So',
        'Píllaro',
        'Indūrti',
        'Morrovalle',
        'Oulad ’Azzouz',
        'Gorha',
        'Little River',
        'El Espinar',
        'Plainville',
        'Serafimovskiy',
        'Caldicot',
        'General Alvear',
        'Socotá',
        'Haapsalu',
        'Kiáto',
        'Apiúna',
        'Pasewalk',
        'Vettweiß',
        'Chuhal',
        'Kostrzyń',
        'Santa Bárbara',
        'Kouarfa',
        'Samabouro',
        'Altlandsberg',
        'Köngen',
        'Aurāhi',
        'Basāpatna',
        'Pasupatikovil',
        'Jerissa',
        'Sasaima',
        'Nordwalde',
        'Nūrpur',
        'Kalas',
        'Vlist',
        'Banino',
        'San Sebastián',
        'Coamo',
        'Vidor',
        'Sande',
        'Totma',
        'Portet-sur-Garonne',
        'Arden Hills',
        'Pālkot',
        'San Vendemiano',
        'Grey Highlands',
        'Dumri',
        'Gangaur',
        'Sorab',
        'Ban Mae Sam Laep',
        'Kinhālu',
        'Kalyānpur',
        'Paravāda',
        'Bergambacht',
        'Laubach',
        'Kīl Vālūr',
        'Keshwāri',
        'Alden',
        'Djangoa',
        'Olmos',
        'Drezdenko',
        'Rasht',
        'Hönow',
        'Mānushmuria',
        'Mae O',
        'Bauska',
        'Imlil',
        'Pisac',
        'Mikun',
        'Velpūru',
        'College Place',
        'Reşadiye',
        'Bad Liebenzell',
        'Sukhsena',
        'Boscotrecase',
        'Pleasant Valley',
        'Sabana Larga',
        'Madera Acres',
        'Dorogobuzh',
        'Altmünster',
        'Silvārpatti',
        'Khathjari',
        'Hawera',
        'Łobez',
        'Santa Rosa',
        'Dangcheng',
        'Visselhövede',
        'Alamosa',
        'Bukowno',
        'Veppattūr',
        'Kominato',
        'Lincoln City',
        'Magstadt',
        'Seysses',
        'Avon Park',
        'Chevy Chase',
        'Marathon',
        'Tiztoutine',
        'Trescore Balneario',
        'Bagnolo in Piano',
        'Bay St. Louis',
        'Alcarraz',
        'Choppington',
        'Chak Thāt',
        'Bāg',
        'Beverly Hills',
        'Nyurba',
        'El Roble',
        'Kippax',
        'Bamora',
        'Ghāriyah al Gharbīyah',
        'Valpovo',
        'Kami-kawabe',
        'Alkhan-Yurt',
        'Gökçeada',
        'Tottington',
        'Kotli Ablu',
        'Mora',
        'Jhitkahiyā',
        'Kiban',
        'Albert',
        'Alagarai',
        'Koini',
        'Terrace Heights',
        'Helston',
        'Rute',
        'Neshannock',
        'Štúrovo',
        'Chom Thong',
        'Bueng Khong Long',
        'Ronda Alta',
        'Dodworth',
        'Charne',
        'Gangania',
        'Nueva Toltén',
        'San Fernando',
        'San Fernando',
        'Bavānāt',
        'Muddanūru',
        'Biei',
        'Repatriación',
        'Nallamadu',
        'Rāmnagar Bankat',
        'Jalālpur',
        'Semri',
        'Derazhnia',
        'Oulunsalo',
        'Heath',
        'Miastko',
        'Figeac',
        'Stonegate',
        'Farako',
        'Xincun',
        'Manalūrpettai',
        'Cittanova',
        'Urdorf',
        'Pakri',
        'Vālāntaravai',
        'Tesalia',
        'Pepinster',
        'Sugbongkogon',
        'Perket',
        'Karankot',
        'Garrison',
        'Puerto Tirol',
        'Zell am See',
        'Wölfersheim',
        'Loudéac',
        'Montemarciano',
        'Mahīnāthpur',
        'Keansburg',
        'Plüderhausen',
        'Çamoluk',
        'Barracão',
        'El Molar',
        'Washington',
        'Agatogba',
        'Wald',
        'Voreppe',
        'Kanchanpur',
        'Jaisinghnagar',
        'Kāttāgaram',
        'Green Cove Springs',
        'Gangūru',
        'Salkhua',
        'Fultondale',
        'Ncora',
        'Gaszowice',
        'Pobiedziska',
        'Ingré',
        'Erravaram',
        'Aramangalam',
        'Kolnūr',
        'Xiba',
        'Oberstdorf',
        'Shepperton',
        'Pembroke Dock',
        'Gorom-Gorom',
        'Dhamsāin',
        'Croydon',
        'Mesetas',
        'Sant’Egidio alla Vibrata',
        'Grigiškės',
        'Tadla',
        'Glencoe',
        'Włoszczowa',
        'Sankt Johann in Tirol',
        'Chegurumomadi',
        'Jagatpur',
        'Monmouth Junction',
        'Suzdal',
        'Byalynichy',
        'Sātulūru',
        'Shāhganj',
        'Ambinanintromby',
        'Lakha Nëvre',
        'Ghabrah',
        'Dubliany',
        'Alayor',
        'Tsaramasoandro',
        'Liesveld',
        'Radekhiv',
        'Felpham',
        'Beiuş',
        'Hacarí',
        'Urlāha',
        'Yamaguchi',
        'Puerto Nariño',
        'Dulce Nombre de Jesús',
        'Undavalli',
        'Dhanwāda',
        'Andrainjato',
        'Fandrandava',
        'Ban Mae Chedi',
        'Basavilbaso',
        'Skidal’',
        'Sirdala',
        'Hunasamaranhalli',
        'Ciudad Cuauhtémoc',
        'Ribeirão do Largo',
        'Bad Breisig',
        'Cobham',
        'Sedona',
        'Brownsville',
        'Ain Karma',
        'Novyye Atagi',
        'Redentora',
        'Twist',
        'Darnétal',
        'Raynes Park',
        'Lykóvrysi',
        'Chausa',
        'Murungattoluvu',
        'Birżebbuġa',
        'Fürstenau',
        'Osthofen',
        'Ghusiya',
        'Motīpur',
        'Eagle Point',
        'Everswinkel',
        'Semmarikulan',
        'Calca',
        'Quiculungo',
        'Giesen',
        'Bou Nouh',
        'Kaithinia',
        'Non Sung',
        'Bom Jesus da Serra',
        'Lágos',
        'Gambolò',
        'Moyogalpa',
        'Aleksandrov Gay',
        'Alto Piquiri',
        'Kitee',
        'Rangasamudram',
        'San Giorgio del Sannio',
        'San Pedro',
        'Grand-Couronne',
        'Cambridge',
        'Kusumha',
        'Hadiāya',
        'East Brandywine',
        'East St. Paul',
        'Nova Floresta',
        'Atlapadu',
        'Rāmpur',
        'Nolinsk',
        'Drăgăneşti-Olt',
        'Chiang Klang',
        'Morrisville',
        'Seforong',
        'Thomaston',
        'Vesele',
        'Karattuppālaiyam',
        'Elambalūr',
        'Zāhed Shahr',
        'Terralba',
        'Decatur',
        'Oudenburg',
        'Idanha-a-Nova',
        'Ledegem',
        'Tayakou',
        'Bareh',
        'Kakkat',
        'Tivim',
        'Aghbalou Aqourar',
        'Mahuver',
        'Carnaubais',
        'La Ferté-sous-Jouarre',
        'Panganiban',
        'Bondues',
        'Tellār',
        'Ingleside',
        'Portland',
        'Estavayer-le-Lac',
        'Litovel',
        'Qahjāvarestān',
        'Shchuchye',
        'Wijnegem',
        'Pine Lake Park',
        'Haraiyā',
        'Ayvacık',
        'Oberhausen-Rheinhausen',
        'Haraiyā',
        'Aqadyr',
        'Fredonia',
        'Lanškroun',
        'Kottampatti',
        'Sundarapāndiyam',
        'Poggio Renatico',
        'Zengjiaba',
        'Worpswede',
        'Lāpangā',
        'Mentone',
        'Wakoro',
        'Vitorino',
        'Serris',
        'Douar Lehouifrat',
        'Ranomafana',
        'Volodarsk',
        'Tiruvengadam',
        'Harvard',
        'Kokologo',
        'Periyapuliyūr',
        'Kannāndahalli',
        'Vanipenta',
        'Gołuchów',
        'Sovata',
        'Lovendegem',
        'Punnavalli',
        'Belsara',
        'Bararam',
        'Rāmpura',
        'Lake Mohawk',
        'Mount Evelyn',
        'Schwaikheim',
        'Pipra Dewās',
        'Madhubani',
        'Āttūrkuppam',
        'Eksjö',
        'Polorós',
        'Purkersdorf',
        'Genemuiden',
        'Huari',
        'Chilanga',
        'Sirka',
        'Wadgira',
        'Tungāvi',
        'Flowery Branch',
        'Mae Ai',
        'Imías',
        'Asuke',
        'Dassel',
        'Zafargarh',
        'Rombas',
        'Pāra',
        'Rolesville',
        'Aklim',
        'Pai Bigha',
        'Sant Julià de Lòria',
        'Neu Bleckede',
        'Rhosllanerchrugog',
        'Kokoszki',
        'Dodóni',
        'Latteri',
        'Cypress Gardens',
        'White Horse',
        'Kola',
        'Imām Şāḩib',
        'Talachyn',
        'Wittenbach',
        'Loreto',
        'Pakhtaobod',
        'Neves Paulista',
        'Belalcázar',
        'Gorgāb',
        'Oostzaan',
        'Sigtuna',
        'Ban Bueng Kok',
        'Sidi Ahmed El Khadir',
        'Santamāgulūru',
        'Mohdra',
        'Malhārgarh',
        'Velakkuttai',
        'Raseiniai',
        'Guifões',
        'Saint-Lys',
        'Pórto Ráfti',
        'Country Club',
        'Hoeselt',
        'Moravská Třebová',
        'Bohechío',
        'Eidson Road',
        'Sävja',
        'Avalūrpet',
        'Santo Tomás de los Plátanos',
        'Pueblo Viejo',
        'Saint-Vith',
        'Tanaina',
        'Stoneham-et-Tewkesbury',
        'Fuli',
        'Ventania',
        'Concordia',
        'Lentvaris',
        'Amritpur',
        'Klötze',
        'Benkovac',
        'Csorna',
        'Sunbury',
        'Hyrum',
        'Alfred and Plantagenet',
        'Alberdi',
        'Großröhrsdorf',
        'Borgo',
        'Pullūru',
        'Pondalūru',
        'Perumbalam',
        'Jibou',
        'Ak-Suu',
        'Thap Khlo',
        'Ban Kang',
        'Ingichka',
        'Brandis',
        'Makri',
        'Piprai',
        'Mońki',
        'Chuy',
        'Lambeth',
        'Pindra',
        'Crosia',
        'Lurate Caccivio',
        'Madanpur',
        'Dināra',
        'Ottappidāram',
        'Castellamonte',
        'Zoudjamé',
        'Tuta',
        'Lindesberg',
        'Canonsburg',
        'San Gregorio Atzompa',
        'Pathāri',
        'Pechory',
        'Inácio Martins',
        'Mākhar',
        '’Aïn Leuh',
        'Kenzhe',
        'Donabate',
        'Lisbon',
        'Tepetlán',
        'Zumárraga',
        'Middleton',
        'Valsequillo de Gran Canaria',
        'Villarrubia de los Ojos',
        'Parmānpur',
        'Laligam',
        'Velpūru',
        'Bithauli',
        'Mount Airy',
        'Langar',
        'Mānrar',
        'Oued Laou',
        'Huachipato',
        'Revel',
        'San Francisco la Unión',
        'Čepin',
        'Orotina',
        'Pinos Puente',
        'Aleşd',
        'Thepaha Rāja Rām',
        'Reddippatti',
        'Pareo',
        'Palafolls',
        'Karīmpur',
        'Kishanpūra Kalān',
        'San Juan del Puerto',
        'Luza',
        'Kadriye',
        'La Farlède',
        'Dundankop',
        'Byarozawka',
        'Bradwell',
        'Kannamangalam',
        'Dahua',
        'Masku',
        'Talapalli',
        'Salmānshahr',
        'Jucuruçu',
        'Arques',
        'Inverell',
        'Bni Boufrah',
        'Barahbatta',
        'Merville',
        'Dandkhora',
        'Pike Road',
        'Palmares Paulista',
        'Sallaumines',
        'Hardia',
        'Tiruvambalapuram',
        'Konidena',
        'Silver City',
        'Comala',
        'Nova Bassano',
        'Dhorgaon',
        'Sangonera la Verde',
        'University of Virginia',
        'Tuam',
        'Simrol',
        'Le Muy',
        'Countryside',
        'Mont-Tremblant',
        'Saint-Doulchard',
        'Ikkarai Boluvāmpatti',
        'Delčevo',
        'Tirúa',
        'Akabira',
        'Glenfield',
        'Nanjundāpuram',
        'Kataha',
        'Torihama',
        'Ban Dong Mada',
        'Immingham',
        'Beltangadi',
        'Ban Lao Yao',
        'Frøn',
        'Willow Street',
        'Pereshchepyne',
        'Bendrahallī',
        'Piploda',
        'Kathūrah',
        'San Lorenzo della Costa',
        'Venecia',
        'Adamankottai',
        'Nueva Esparta',
        'Santa Ana',
        'Bhāgsar',
        'Broni',
        'Argelato',
        'Orange Cove',
        'Veitshöchheim',
        'Racconigi',
        'Dombasle-sur-Meurthe',
        'Brewer',
        'Monett',
        'Morehead City',
        'Godhra',
        'Mādāri Hāt',
        'Filadelfia',
        'Dobre Miasto',
        'El Haouaria',
        'Banská Štiavnica',
        'Bochaha',
        'Kudowa-Zdrój',
        'Pinhel',
        'Itasca',
        'Armutlu',
        'Uracoa',
        'Gundi',
        'Nambutalai',
        'Colonia Nicolich',
        'Kauniainen',
        'Selfoss',
        'Kushijima',
        'St. Stephens',
        'Campo Erê',
        'Bastrop',
        'Aweitancun',
        'Sigatoka',
        'Bernay',
        'Sabangan',
        'Caldas de Reyes',
        'Anantpur',
        'Bilāspur',
        'Sturgeon Bay',
        'Saint-Rémy-de-Provence',
        'Bluefield',
        'Port Elgin',
        'Nowy Dwór Gdański',
        'Flexeiras',
        'Saint-Zotique',
        'Krasnogvardeyskoye',
        'Tadhwa Nandpur',
        'Babhantoli',
        'South Strabane',
        'Widnau',
        'Südlohn',
        'Grado',
        'Neuville-lès-Dieppe',
        'Hockley',
        'Datori',
        'Caernarfon',
        'Boves',
        'Saint-Raymond',
        'Oulad Imloul',
        'Zadonsk',
        'Middleton',
        'Fair Oaks',
        'Kfar Aabîda',
        'Władysławowo',
        'Ohrdruf',
        'Masar',
        'Bowen',
        'Alcanar',
        'Rāmpur',
        'Epalinges',
        'Nersingen',
        'Fernán-Núñez',
        'Gāndlapenta',
        'Agareb',
        'Harīpur',
        'Mangalam',
        'Tila',
        'Ćuprija',
        'Izium',
        'Muyinga',
        'Thogadūru',
        'Kirlampūdi',
        'Tottenham',
        'Cuéllar',
        'Herkimer',
        'Mortágua',
        'Dasso',
        'Butler',
        'Yazıkonak',
        'Postojna',
        'Dessel',
        'Sannicandro di Bari',
        'Sandy Hook',
        'Branquinha',
        'Guateque',
        'Bhawānandpur',
        'Nakaechi',
        'Gibsons',
        'Levokumskoye',
        'Eschenbach',
        'North College Hill',
        'Jessup',
        'Swanage',
        'Hindoli',
        'Nurobod Shahri',
        'Yarm',
        'Hemau',
        'Khesht',
        'Oil City',
        'Hartland',
        'Yalagüina',
        'Târgu Frumos',
        'Sofiivka',
        'Bálsamo',
        'Hlinsko',
        'Petua',
        'Ḩās',
        'Dobhāwān',
        'Szigetvár',
        'Middletown',
        'Xicoténcatl',
        'Ban Nam Dip Luang',
        'Höchberg',
        'Gonikoppal',
        'Chavuttahalli',
        'Trzebiatów',
        'Chilpur',
        'Ferros',
        'Beaumont-sur-Oise',
        'Kudayattūr',
        'Manhattan',
        'Agoué',
        'Cavalcante',
        '’Aïn Kihal',
        'Soanpeta',
        'Jacinto City',
        'Leers',
        'Majali',
        'Wiang Sa',
        'Sūlibele',
        'Casaluce',
        'Grimmen',
        'Sarahs',
        'Henderson',
        'Lüchow',
        'Aire-sur-la-Lys',
        'Pāchhāpur',
        'Banāso',
        'Greenville',
        'Valerik',
        'Wielsbeke',
        'Ratnahalli',
        'Ekma',
        'Puduppattanam',
        'Cingoli',
        'Southport',
        'Bou Zemou',
        'Shiyuan',
        'Ramree',
        'Rellivalasa',
        'Tālavādi',
        'Osterburg',
        'Kensington',
        'Buñol',
        'Perumbālai',
        'Pagidyāla',
        'Avanāshipālaiyam',
        'Lizzano',
        'Bourg-de-Péage',
        'Aytré',
        'Vasiliká',
        'New Square',
        'Saint-Sulpice-la-Pointe',
        'Nāgathān',
        'Photharam',
        'Lago Ranco',
        'Schulzendorf',
        'Gamharia',
        'Bladensburg',
        'Villa Aldama',
        'Petrolina de Goiás',
        'Ézanville',
        'Cuervos',
        'Fauske',
        'Ban Wiang Ka Long',
        'Parūr',
        'Indianola',
        'Sarmera',
        'Jaisinghnagar',
        'Cesa',
        'Aiea',
        'Sānampūdi',
        'Bakaly',
        'Gawān',
        'Ittikelakunta',
        'Mae Rim',
        'Mizhhiria',
        'Bolívar',
        'Kochgāwān',
        'Oulad Ayyad',
        'Karczew',
        'Miltenberg',
        'Nandamūru',
        'Topsham',
        'McKee City',
        'Bilozerka',
        'Daulatpur',
        'Girard',
        'West Glens Falls',
        'Roanoke',
        'Sitebe',
        'Erdőkertes',
        'Parsons',
        'Szubin',
        'Maliaño',
        'Savignano sul Panaro',
        'Sorbolo',
        'Borogani',
        'Šurany',
        'Baretha',
        'LaSalle',
        'Pérols',
        'Chansolme',
        'Kayyngdy',
        'La Paz',
        'Xiada',
        'Adesar',
        'Miānpur Dubauli',
        'Koranampatti',
        'Traversetolo',
        'Lititz',
        'Palankottai',
        'Bisaria',
        'Zymohiria',
        'Paranacity',
        'Semri',
        'Dombarovskiy',
        'Vareš',
        'Parauli',
        'Vīrapāndiyanpattanam',
        'Rathdrum',
        'Pine Castle',
        'Lower Swatara',
        'Basse-Goulaine',
        'Dumra',
        'Kamargani',
        'Velyka Dymerka',
        'Boxley',
        'Vemulanarva',
        'Wepener',
        'Lincoln Village',
        'Port Perry',
        'Bad Gandersheim',
        'Nāzira',
        'Sevilla La Nueva',
        'Kondaparti',
        'Fontoura Xavier',
        'Ekalbehri',
        'Pleasant Grove',
        'Sirsa',
        'Vardhamānkota',
        'Amherst',
        'Teixeira Soares',
        'Gobindpura',
        'Dākpatthar',
        'Kannūlu',
        'Sāngi',
        'Boulder Hill',
        'Fitampito',
        'Sālehpur',
        'Arrapalli',
        'Matawan',
        'Igrim',
        'Mahomet',
        'Elizabethtown-Kitley',
        'Harding',
        'Ashukino',
        'Cunday',
        'Thīkri',
        'Dabhaura',
        'Naruār',
        'Greenwood',
        'Paso Canoas',
        'Kadıköy',
        'Rauco',
        'Canoas',
        'Berchha',
        'Rāmasingavaram',
        'Gangādhar',
        'Bjärred',
        'Chita',
        'Lorgues',
        'Lototla',
        'Nieuw-Lekkerland',
        'Dodarasinakere',
        'Conselice',
        'Ehringshausen',
        'El Cacao',
        'Capitán Mauricio José Troche',
        'Chaplynka',
        'Hohenhameln',
        'Mīnākshipuram',
        'Kanhai',
        'Udburu',
        'Listowel',
        'Kakamas',
        'Mezőberény',
        'Khundāwandpur',
        'Volterra',
        'Bethel',
        'Jinshui',
        'Māmā Khēl',
        'Newburn',
        'Le Rheu',
        'Navani',
        'Ekhari',
        'Khāspur',
        'Maravilha',
        'Wilkau-Haßlau',
        'Colmenarejo',
        'Jalkaura',
        'Hillsborough',
        'Bohemia',
        'Chanal',
        'Piedras Blancas',
        'Barskoon',
        'Menzel Kamel',
        'Jianshi',
        'Crosne',
        'Kaikaram',
        'Vatakemuri',
        'Rāmpur Rajwa',
        'Crystal Beach',
        'Tounfafi',
        'Pagqên',
        'Pedra Badejo',
        'Somvārpet',
        'Yamakita',
        'Falam',
        'Santa Rosa del Peñón',
        'Psychikó',
        'Mallampalli',
        'Xinpi',
        'Meridianville',
        'Tatoufet',
        'Barwādih',
        'Madhopur',
        'Castel Bolognese',
        'Tomah',
        'Ankadimanga',
        'Santa María',
        'Środa Śląska',
        'La Reina',
        'Raun',
        'Middletown',
        'Mooresville',
        'Lescar',
        'Cuorgnè',
        'Esopus',
        'Atāri',
        'Burayevo',
        'Sebnitz',
        'Tadworth',
        'Zawyat Sidi Ben Hamdoun',
        'Eurajoki',
        'Mānbāzār',
        'Valaiyāmpattu',
        'Pahārpur',
        'Bargersville',
        'Águia Branca',
        'Fairview',
        'Cape Elizabeth',
        'Puigcerdá',
        'Madhura',
        'Steger',
        'Tlahualilo de Zaragoza',
        'Basco',
        'Shevington',
        'Smiths Falls',
        'Soldato-Aleksandrovskoye',
        'Worsborough',
        'Castelfranco di Sopra',
        'Seybaplaya',
        'Sztum',
        'Janāpul',
        'Ādivāla',
        'Bni Gmil',
        'Salcea',
        'Byureghavan',
        'Sendamangalam',
        'Ban Nong Tong',
        'Săbăoani',
        'Kalladai',
        'Grinnell',
        'Oosterwolde',
        'Sikandarpur',
        'Manchenahalli',
        'Rāmpur Parhat',
        'Höhr-Grenzhausen',
        'Nagyatád',
        'Khagam',
        'Vəndam',
        'London Colney',
        'Trovagunta',
        'Amityville',
        'Elhovo',
        'Vaprio d’Adda',
        'Bougou',
        'Kujri',
        'Ānjukulippatti',
        'Aleksandrovsk-Sakhalinskiy',
        'Garlasco',
        'Wagner',
        'Dhāntola',
        'Arenápolis',
        'Hickam Housing',
        'Lorraine',
        'Douar Messassa',
        'Mūḩ Ḩasan',
        'Sonsoro',
        'Luckau',
        'San Sebastián de la Gomera',
        'Bisignano',
        'Ngaparou',
        'Tambura',
        'Bulisa',
        'Jingjiazhuang',
        'Bāgor',
        'Nathāna',
        'Rānigaon',
        'Mangrāwān',
        'Louisville',
        'Iowa Colony',
        'Dagbé',
        'Großhansdorf',
        'South Abington',
        'Fochville',
        'Gaada',
        'Muddāda',
        'Ilāmi',
        'Devmaudaldal',
        'Oakwood',
        'Əliabad',
        'Pedro Luro',
        'Saldus',
        'Sokotindji',
        'Tixter',
        'Bāghduma',
        'Kanchanadit',
        'Emsworth',
        'Kaithwār',
        'Valtoha',
        'Saltsjöbaden',
        'Madhuban Bediban',
        'Néa Artáki',
        'Karath',
        'Mehdīpur',
        'Åstorp',
        'Ramara',
        'Ivangorod',
        'Maizal',
        'Llantwit Major',
        'Nārāyanraopet',
        'Carácuaro',
        'Hesarghatta',
        'Malaudh',
        'Berlin',
        'Rudnya',
        'Katigang',
        'Nadimpālem',
        'Luçon',
        'Deh-e Shū',
        'Ekchāri',
        'Virālimalai',
        'Nova Veneza',
        'Tamentit',
        'Tepperumālnallūr',
        'Durgi',
        'Saūmalköl',
        'Pryor Creek',
        'Corella',
        'Cherniakhiv',
        'The Village',
        'Periya Pattanam',
        'Columbia City',
        'Zhangping',
        'Ilsenburg',
        'Quincy-sous-Sénart',
        'Gūdalūr',
        'Bimun',
        'Molagavalli',
        'Santa María Jacatepec',
        'Highfields',
        'Senmanat',
        'Léguevin',
        'Fairfield',
        'Foix',
        'Musāpur',
        'Malaimāchchampatti',
        'Isrāna',
        'Ban Krot',
        'Sundarsi',
        'Notre-Dame-des-Prairies',
        'Puszczykowo',
        'Wertingen',
        'Bewdley',
        'Anan’evo',
        'Helena-West Helena',
        'Berkine',
        'Molbergen',
        'Cervelló',
        'Elūrpatti',
        'Asahni',
        'Vallet',
        'Kemberg',
        'Rafelbuñol',
        'Marne',
        'Alāwalpur',
        'Salem',
        'Woodfield',
        'Casca',
        'Töging am Inn',
        'Cherasco',
        'Leeds and the Thousand Islands',
        'Dényékoro',
        'Nehoiu',
        'Uppūr',
        'Koufália',
        'Halen',
        'Quixabeira',
        'Inole',
        'Bridge City',
        'Brockton',
        'Costeşti',
        'Syurte',
        'Nyzhnohirskyi',
        'Cambira',
        'Saint-Barthélemy-d’Anjou',
        'Saint-Amand-Montrond',
        'Gangāpur',
        'Sultan-Yangiyurt',
        'Legnaro',
        'Runkel',
        'Hohenmölsen',
        'Frouzins',
        'Tabernes Blanques',
        'Mareno di Piave',
        'El Amim',
        'Burela de Cabo',
        'Ban Sathan',
        'Cervera',
        'Gold',
        'Clarksville',
        'Kranídi',
        'Kerap',
        'Shahrak-e Ja‘farīyeh',
        'Dolianova',
        'Winchester',
        'Laurentian Valley',
        'Nittenau',
        'Idumbāvanam',
        'Bijeraghogarh',
        'Nārsingi',
        'Shira',
        'Güney',
        'Khāwad',
        'Erikolam',
        'Kadanādu',
        'Ćićevac',
        'Negrine',
        'South Normanton',
        'Killamarsh',
        'Tissaf',
        'Kommūru',
        'Gonghaur',
        'Novi Banovci',
        'Dachengzicun',
        'Wau',
        'Aksay',
        'Jisrayn',
        'Deruta',
        'Tavarede',
        'Raitar',
        'Monnickendam',
        'Jantho',
        'Eunice',
        'Rorschach',
        'Tarmount',
        'Dhānga',
        'Kankanālapalle',
        'Adolfo Gonzáles Chaves',
        'Cosne sur Loire',
        'Bezliudivka',
        'Pipra',
        'Alma',
        'Assi-Ben Okba',
        'Anaconda',
        'Tissint',
        'Ban Bang Phlap',
        'Villacañas',
        'Dānesfahān',
        'Borovskoy',
        'Banikane',
        'San Juanito de Escobedo',
        'Villa Cañás',
        'Wiener Neudorf',
        'Chewara',
        'Elne',
        'Yutsa',
        'Olivares',
        'Harlākhi',
        'Rāsol',
        'Ghosrāwān',
        'Saidoke',
        'Huinca Renancó',
        'Braslaw',
        'Medleri',
        'Madeira',
        'Ban San Pong',
        'Abra Pampa',
        'Segorbe',
        'Lerici',
        'Dubrovytsya',
        'Mohelnice',
        'Khānpur Khairanti',
        'Bairiyā',
        'Hertzogville',
        'Santa Monica',
        'Odobeşti',
        'Åhus',
        'Soubakaniédougou',
        'Sabiñánigo',
        'Elūrupādu',
        'Lugoff',
        'Carneirinho',
        'Teisendorf',
        'Brockworth',
        'Aurahi',
        'Dibrāghani',
        'Vadakēthara',
        'Ghanīpur Bejha',
        'Fishersville',
        'Sidi El Hattab',
        'Basni',
        'Mono',
        'Ipiranga do Piauí',
        'Tocina',
        'Būdalūr',
        'Anjēhalli',
        'Naurhiya',
        'Andergrove',
        'Embrach',
        'Radstock',
        'Sādiqpur Maraul',
        'Bendarhalli',
        'Simarbani',
        'Sivamalai',
        'Glenshaw',
        'Estrêla d’Oeste',
        'Carqueiranne',
        'Rochelle',
        'San Francisco Libre',
        'An Châu',
        'Jawāsa',
        'Bobil',
        'Sarpamāri',
        'Nirna',
        'Barga',
        'Coral Hills',
        'Bystrzyca Kłodzka',
        'Iāwar',
        'Kharī',
        'Cavriglia',
        'Aschheim',
        'Arenys de Munt',
        'Halachó',
        'Ngọc Sơn',
        'Sa Pa',
        'Muhammadganj',
        'Dharir',
        'Ostróda',
        'Dunblane',
        'Kallayi',
        'Mohanpur',
        'Gümüşova',
        'Benbutucun',
        'Kurort Steinbach-Hallenberg',
        'Orivesi',
        'San Giovanni in Marignano',
        'Anísio de Abreu',
        'Kovilpatti',
        'Siano',
        'Bellinzago Novarese',
        'Chahār Borj-e Qadīm',
        'Elmas',
        'Aniva',
        'Flossmoor',
        'San Juan Bautista',
        'Hani i Elezit',
        'Voitsberg',
        'Danau Kändimarg',
        'Ramdeora',
        'Mechanicsburg',
        'Cusseta',
        'Altdorf',
        'Ponneri',
        'Mutis',
        'Thames Ditton',
        'Hayle',
        'Newmarket',
        'Rāmkali',
        'Recco',
        'Woodway',
        'Dushanovë',
        'Pettāmpālaiyam',
        'Krasnoslobodsk',
        'Devarāpalle',
        'Uppalaguptam',
        'Piombino Dese',
        'Kapelle-op-den-Bos',
        'Makamba',
        'Condé-sur-l’Escaut',
        'Pokotylivka',
        'Negrete',
        'Singhāna',
        'Taisar',
        'Hazrat Shiura',
        'Denham Springs',
        'Manchester',
        'Hsenwi',
        'Novopskov',
        'Resana',
        'Magnago',
        'Cetraro',
        'Sint Willebrord',
        'Capriolo',
        'Gammasa',
        'Tazarka',
        'Saint-Philbert-de-Grand-Lieu',
        'Köse',
        'Närpes',
        'Jamunāmukh',
        'Vempatti',
        'Ja‘farīyeh',
        'Oakville',
        'Mhâjâr',
        'Magny-les-Hameaux',
        'Ukwā',
        'Gaurdah',
        'Cutro',
        'Verkhneyarkeyevo',
        'Horsell',
        'Florence',
        'Middlesborough',
        'Ashland',
        'Kungsängen',
        'Kadimetla',
        'Grigoriopol',
        'Nepi',
        'Curepto',
        'Saint-Jean-le-Blanc',
        'Raunds',
        'Marvast',
        'São Jorge d’Oeste',
        'Nové Město nad Metují',
        'Salzhemmendorf',
        'Jhabrera',
        'Sarakkayhalli',
        'Cermenate',
        'Kharagbani',
        'Kākarāti',
        'Acton',
        'Dymka',
        'Umbrete',
        'San Giorgio di Piano',
        'Rangāpuram',
        'Jarville-la-Malgrange',
        'Tirubhuvane',
        'Helena Valley Southeast',
        'Topoloveni',
        'Le Passage',
        'Broadwater',
        'Pūmalakkundu',
        'Bakhariā',
        'Douar Oulad Bouziane',
        'Pustomyty',
        'Ichenhausen',
        'Ukhāi Purbāri Patti',
        'Maḑāyā',
        'Sierning',
        'Audenge',
        'Zacualpan de Amilpas',
        'Kargat',
        'Pau Brasil',
        'Mēga',
        'Barjhar',
        'Hamīra',
        'Ecatzingo',
        'Zorneding',
        'Rehau',
        'Werneuchen',
        'Hathaura',
        'Chhapera',
        'Tivat',
        'Locust Grove',
        'Frankfort Square',
        'Langnau',
        'Petersberg',
        'Tandarāmpattu',
        'Baisuhalli',
        'Barahpur',
        'Lānghnaj',
        'Shahr-e Majlesī',
        'Eemnes',
        'Punta Indio',
        'Eisenberg',
        'Tiruvāduturai',
        'Muppālla',
        'Burlington',
        'Hastings',
        'Opalenica',
        'Sebastião Laranjeiras',
        'Obernkirchen',
        'Tadangam',
        'Diamondhead',
        'Maraial',
        'Miajadas',
        'Hattula',
        'Molsheim',
        'Mīrjāveh',
        'Ubbergen',
        'Wallerfangen',
        'Tassera',
        'Wilnecote',
        'Southside',
        'Ntchisi',
        'Schlangen',
        'Bāgchīni',
        'Mahālgaon',
        'Canal Winchester',
        'Sutton',
        'Bāyaram',
        'Kirangūr',
        'Awans',
        'Lutterworth',
        'Chinnāmpālaiyam',
        'Ruffano',
        'Warren',
        'Adelsdorf',
        'Aidlingen',
        'Gorē',
        'Ameskroud',
        'Orlu',
        'Savenay',
        'Queensferry',
        'Sherborne',
        'Asudapuram',
        'Igaratá',
        'Aucamville',
        'Chilmil',
        'Kolkwitz',
        'Siteía',
        'Chiman',
        'Oraviţa',
        'Modra',
        'Palhano',
        'Schaafheim',
        'Valréas',
        'Qutubpur',
        'East Stroudsburg',
        'Suan',
        'Barpathār',
        'Kalaīkunda',
        'Laanoussar',
        'Harqalah',
        'San Agustín de las Juntas',
        'Santa Comba',
        'Néa Moudaniá',
        'Lādhuka',
        'Yezhi',
        'Nogent-le-Rotrou',
        'Chandwārā',
        'Bhogāpuram',
        'Verkhneuralsk',
        'Sankt Valentin',
        'Pirpirituba',
        'Wehrheim',
        'Lançon-Provence',
        'Doctor Arroyo',
        'Parol',
        'Barrington',
        'Minano',
        'Meadowbrook',
        'Rum',
        'Tonneins',
        'Nattakkādaiyūr',
        'Kātūria',
        'Takkali',
        'South Amboy',
        'Torrinha',
        'Água Branca',
        'Mallappādi',
        'Santa Teresa di Riva',
        'Crestline',
        'Charter Oak',
        'Blachownia',
        'Ban Khi Lek',
        'Macomer',
        'Rodeo',
        'Zwenkau',
        'St. Anthony',
        'Havre',
        'Tres Ríos',
        'Ouistreham',
        'Kesli',
        'Manteswar',
        'Nūlivedu',
        'Sainte-Julienne',
        'Heiligenhafen',
        'Sheffield',
        'Chinnakkavundanūr',
        'Jāffar Khānpet',
        'Boddikūrapādu',
        'Dowlatābād',
        'Harpur',
        'Tarawān',
        'Pelāgor',
        'Nort-sur-Erdre',
        'Moore',
        'Studénka',
        'Samdrup Jongkhar',
        'Bruchhausen-Vilsen',
        'Irthlingborough',
        'Sujāpur',
        'Vanzago',
        'Gatteo',
        'Concepcion',
        'Chupinguaia',
        'L’Isle-Jourdain',
        'Sosale',
        'Kaleybar',
        'Zafferana Etnea',
        'Fort Stewart',
        'Lubuagan',
        'Pesca',
        'Hagondange',
        'Borna',
        'Hasanpura',
        'Nāgāyalanka',
        'Kopong',
        'Lac des Oiseaux',
        'Unāo',
        'Telkathu',
        'Kalvārpatti',
        'Oued Essalem',
        'Maihma Sarja',
        'Jahāngīrpur Sālkhani',
        'Bull Mountain',
        'Moisei',
        'Peixe',
        'Baisa',
        'Angola',
        'Santa María de Cayón',
        'Laghzawna',
        'Adaklı',
        'Ehningen',
        'Timmāpuram',
        'Boali',
        'Shanhūr',
        'San Pablo Huixtepec',
        'Imielin',
        'Rudraprayāg',
        'Jānpur',
        'Moḩammad Yār',
        'Riverdale',
        'Monserrat',
        'Redon',
        'Chiankī',
        'Bang Khla',
        'Rosario',
        'Ouled Rached',
        'Ralla',
        'Pangunattam',
        'Altınyayla',
        'Dornstadt',
        'Quakertown',
        'East Franklin',
        'Nor Hachn',
        'Torgelow',
        'Rupahi',
        'Choix',
        'Quirino',
        'Sədərək',
        'Pfedelbach',
        'Shimizu',
        'Saint-Jean-d’Illac',
        'Hualañe',
        'Patchūr',
        'Mariyādau',
        'Khajuri',
        'Satravāda',
        'Qualicum Beach',
        'Nieuwleusen',
        'Chechen-Aul',
        'San José Guayabal',
        'Frodsham',
        'Polegate',
        'Pasrāha',
        'Plymouth',
        'Ilvesheim',
        'Drākshārāma',
        'Livron-sur-Drôme',
        'Fársala',
        'Urangānpatti',
        'Gadzhiyevo',
        'Mumaradikop',
        'Barros Cassal',
        'Honganur',
        'Porto de Pedras',
        'Rosário do Catete',
        'Le Crès',
        'Isua',
        'Bo‘z',
        'Magdalena',
        'Shendē',
        'Kanajanahalli',
        'Khandāich',
        'Ikeda',
        'Ecorse',
        'Skidaway Island',
        'Newfane',
        'Malahide',
        'Titu',
        'Poienile de sub Munte',
        'Perwez',
        'Modavāndisatyamangalam',
        'Kharahara',
        'Gassino Torinese',
        'Kißlegg',
        'Ledbury',
        'Síndos',
        'Kruszwica',
        'Juprelle',
        'Tecklenburg',
        'La Jigua',
        'Dahi',
        'Mīlājerd',
        'St. Francis',
        'Tysmenytsia',
        'Betania',
        'Asārhi',
        'Bernalillo',
        'Jandaíra',
        'Marano Vicentino',
        'Ventnor City',
        'Bad Liebenwerda',
        'Lagunia Raghukanth',
        'Kollankulam',
        'Mabeskraal',
        'Analaroa',
        'Kunnattūr',
        'Uppalapādu',
        'Ban Bo Phlap',
        'San Pancrazio Salentino',
        'Jackson',
        'Bethalto',
        'Frickenhausen',
        'Bagnara Calabra',
        'Moldova Nouă',
        'Nakhon Thai',
        'Herculândia',
        'Chicholi',
        'Punta del Este',
        'Pozo Almonte',
        'Laurens',
        'Elsenfeld',
        'Catral',
        'Majhariyā',
        'Bhachhi',
        'Āltūn Kawbrī',
        'Nakoushi',
        'Thakurainia',
        'Almagro',
        'Coleford',
        'Charlotte',
        'Leonia',
        'Mascota',
        'Skwierzyna',
        'Abūz̄ar-e Ghaffārī',
        'Gaggiano',
        'Nakao',
        'Mound',
        'Crigglestone',
        'Teplohirsk',
        'Ānandpur',
        'Gainrha',
        'Goldach',
        'Clermont-l’Hérault',
        'Deokali',
        'Joghtāy',
        'Washington',
        'Rājapūdi',
        'Punnaikkāyal',
        'Wasilla',
        'Güneysınır',
        'Oppicherla',
        'Merrill',
        'Freystadt',
        'Pāppampatti',
        'Tlahuiltepa',
        'Niederhasli',
        'Macedon',
        'Padinska Skela',
        'Didymóteicho',
        'Alukkuli',
        'Chilakhāna',
        'Khotyn',
        'Walworth',
        'Ochsenhausen',
        'Piru',
        'Rāmpur',
        'Douar Oulad Sidi Moussa',
        'Aïn el Hadjar',
        'Launaguet',
        'Sidi Lahsene',
        'Rîşcani',
        'Barei',
        'Rinópolis',
        'Piamonte',
        'Weißenthurm',
        'Edelény',
        'Itiki',
        'Barnāon',
        'Picture Rocks',
        'Aljustrel',
        'Fairfield Glade',
        'Pokrovsk',
        'Sucre',
        'Bojacá',
        'Pedda Tumbalam',
        'Las Tablas',
        'Velykyi Bychkiv',
        'Bridgeport',
        'Risaralda',
        'Bures-sur-Yvette',
        'Lansdowne',
        'Granada',
        'Borgholzhausen',
        'Muro del Alcoy',
        'Escaudain',
        'Augusta',
        'San Pedro Nonualco',
        'Gomaringen',
        'Towcester',
        'Pasian di Prato',
        'Rensselaer',
        'Sugar Grove',
        'Kudelstaart',
        'Keuruu',
        'Ganāram',
        'Algūn',
        'Samalpur',
        'Douar Ezzerarda',
        'Schübelbach',
        'Niesky',
        'San José La Arada',
        'Gandikunta',
        'Vittuone',
        'Manzanares el Real',
        'Bendapūdi',
        'Katteragandla',
        'Sītānagaram',
        'Ban Son Loi',
        'Ratangarh',
        'Mātar',
        'Magalia',
        'Haiku-Pauwela',
        'Diouna',
        'Ħamrun',
        'Capilla del Señor',
        'Benahavís',
        'Southwick',
        'Zavyalovo',
        'Nizhniye Sergi',
        'Smoline',
        'Wunsiedel',
        'Fallon',
        'Otumba',
        'Mels',
        'Ahmadpur',
        'Harbatpur',
        'Loria',
        'Lesquin',
        'Chunakhali',
        'Montescaglioso',
        'Talata-Angavo',
        'Chala',
        'Reshetylivka',
        'Mādhopur',
        'Fatao',
        'Paray-le-Monial',
        'Belley',
        'Barleben',
        'Rain',
        'Iarpur',
        'Raipur Buzurg',
        'Kawai',
        'Hailey',
        'Oulad Cherif',
        'Bandio',
        'Romang',
        'Tanakoub',
        'Santa Isabel Cholula',
        'Gooik',
        'Zoubiria',
        'Māmidipalli',
        'Aranya Kalān',
        'Nūtakki',
        'Tanamarina-Sakay',
        'Momchilgrad',
        'Mirante da Serra',
        'Tasso',
        'Jūraqān',
        'Kahla',
        'Nossa Senhora Aparecida',
        'Dielheim',
        'Sandalpur',
        'Bahābād',
        'Rethen',
        'Giardini',
        'Neuenkirchen',
        'Stevenston',
        'Karsaut',
        'Vallapuram',
        'Ryki',
        'Brugnera',
        'Philippeville',
        'Carrillos',
        'La Victoria de Acentejo',
        'Bowdon',
        'Kondrukota',
        'Vaikuntam',
        'Castelleone',
        'Keşap',
        'San Lorenzo de Descardazar',
        'Kaujalgi',
        'Lesnoy Gorodok',
        'Hlyboka',
        'La Bruyère',
        'São Domingos',
        'Fazendinha',
        'I-n-Amenas',
        'Riverside',
        'Aslanapa',
        'Kalanchak',
        'Baía da Traição',
        'Kondayampālaiyam',
        'Tišnov',
        'Jājireddigūdem',
        'White Meadow Lake',
        'Oromocto',
        'Borja',
        'Clay Cross',
        'Bude',
        'Shannon',
        'Gudimūlakhandrika',
        'Bad Laer',
        'Baluntaicun',
        'Gandhwāni',
        'Vallahbhāpuram',
        'Badagabettu',
        'Gangaura Behra',
        'Washington Terrace',
        'Japaratinga',
        'Antônio Dias',
        'Mejillones',
        'Joigny',
        'Inverigo',
        'Mikhaylovskoye',
        'Badnor',
        'Nonea',
        'Saraunja',
        'Kandiyankovil',
        'Ottappārai',
        'Swāmimalai',
        'Évian-les-Bains',
        'Totnes',
        'Mount Pleasant',
        'Melgaço',
        'Babadag',
        'Nong Kung Si',
        'Ghattupal',
        'Sholaqqorghan',
        'Altenbeken',
        'Kandel',
        'Kharika',
        'Kodakkal',
        'Castrolibero',
        'Qarqaraly',
        'Shawano',
        'Cape St. Claire',
        'Kannavam',
        'Pong Nam Ron',
        'Pudukkottai',
        'Ban Kham Pom',
        'Pohrebyshche',
        'Jambukuttaippatti',
        'Chokkalingapuram',
        'Bādanahatti',
        'Covasna',
        'Aniskino',
        'Llagostera',
        'Sūrak',
        'Waseca',
        'Dhāmnod',
        "O'Hara",
        'Gnarrenburg',
        'Golbāf',
        'Palm Beach',
        'Falan',
        'Elsfleth',
        'Arlesheim',
        'Mont-Saint-Martin',
        'Cullercoats',
        'Vadavālam',
        'Irungalūr',
        'Xalqobod',
        'Qorovulbozor',
        'Mudhol',
        'Gothurutha',
        'Rāmāyipatti',
        'Araújos',
        'Saßnitz',
        'Laheji',
        'Freetown',
        'Paredes de Coura',
        'Tiana',
        'Spáta',
        'Aliquippa',
        'San Fausto de Campcentellas',
        'Bīkē',
        'Dāmargidda',
        'Panthersville',
        'Une',
        'Jamhra',
        'Susāri',
        'Valkurti',
        'La Cruz',
        'Kodigenahalli',
        'Blackstone',
        'Whitecourt',
        'San Lorenzo',
        'Nowra',
        'Tenedla',
        'Leichi',
        'Landivisiau',
        'Ban Chang Phuak',
        'Muli',
        'Vignate',
        'Çobanlar',
        'Støvring',
        'Shirguppi',
        'Chalkāri',
        'Jogaili',
        'Māmidipalli',
        'As Sidrah',
        'Podstepki',
        'Tibaná',
        'Tepe-Korgon',
        'Richmond Heights',
        'Santa María Ajoloapan',
        'Capbreton',
        'Tilehurst',
        'Chikkāla',
        'Sewa',
        'Aberbargoed',
        'Olds',
        'Suwannaphum',
        'Jagannādapuram',
        'Himmatpura',
        'Rājepur',
        'Sukkāmpatti',
        'Roux',
        'Canela Baja',
        'Cistérniga',
        'Tiptree',
        'Conshohocken',
        'Salisbury',
        'Sidi Bou Othmane',
        'Hirehalli',
        'Maidencreek',
        'Guntramsdorf',
        'Harpur Bhindi',
        'Vega Alta',
        'Cêrro Grande',
        'Alsbach-Hähnlein',
        'Douar El Mellaliyine',
        'Arceburgo',
        'Galten',
        'Mādepalli',
        'Nelali',
        'Ḩorr-e Rīāḩī',
        'Grants',
        'Ahuimanu',
        'Willowbrook',
        'Elze',
        'Hacine',
        'Yatton',
        'Budhma',
        'Nashtīfān',
        'Obukhivka',
        'Itapebi',
        'Yaxley',
        'Ivins',
        'Rockingham',
        'Maków Mazowiecki',
        'Murfatlar',
        'Tuskegee',
        'Binisalem',
        'Onchan',
        'Gūdalūr',
        'Waiuku',
        'Ban Wat Chan',
        'Jerez de los Caballeros',
        'Solsona',
        'Sarkad',
        'Ban Yaeng',
        'Trittau',
        'Biblis',
        'Dörverden',
        'Marale',
        'Bangaon',
        'Matelica',
        'Verdejante',
        'Xiangping',
        'Ville-la-Grand',
        'Woodbury',
        'Littleport',
        'Monte Rico',
        'Boekenhouthoek',
        'Foammulah',
        'Pencoed',
        'West Manheim',
        'Wa',
        'Dommasandra',
        'Waterford',
        'Corumbaíba',
        'Waldfeucht',
        'Micco',
        'Flanders',
        'Irshava',
        'Yvoir',
        'Abalessa',
        'Incline Village',
        'Springs',
        'Žabalj',
        'Gədəbəy',
        'Palomares del Río',
        'Reggiolo',
        'Beauraing',
        'Ifigha',
        'Op',
        'Ussel',
        'Banbhāg',
        'Bariariya Tola Rājpur',
        'Entre Ijuís',
        'Beauchamp',
        'Halgeri',
        'Santo Domingo Petapa',
        'Gualaquiza',
        'San Fructuoso de Bagés',
        'Sungal',
        'Pilikōdu',
        'Wysokie Mazowieckie',
        'Yeşilyurt',
        'Fateh Nangal',
        'Mikhaylovka',
        'Zörbig',
        'Županja',
        'Imotski',
        'Piripá',
        'Grünheide',
        'Kyritz',
        'Morubāgalu',
        'Anjūr',
        'Nārāyanpur',
        'Cachoeira dos Índios',
        'Vallendar',
        'Belakvādi',
        'Ražanj',
        'Buenópolis',
        'Chinnāyagūdem',
        'Felino',
        'Lavis',
        'Al Bardīyah',
        'Sângeorgiu de Mureş',
        'Sabie',
        'Gondizalves',
        'Ust’-Nera',
        'Kondūru',
        'Sansa',
        'Veinticinco de Diciembre',
        'Mangasamudram',
        'Bichura',
        'Sauzal',
        'Brandon',
        'Maserada sul Piave',
        'Japurá',
        'Chesterfield',
        'Santa Lucia di Piave',
        'Kaithāhi',
        'Bir Tam Tam',
        'Dāmu',
        'Sibkund',
        'Nerinjippettai',
        'Seven Corners',
        'Audubon',
        'Helotes',
        'Des Peres',
        'Cajvana',
        'Chadan',
        'Caluco',
        'Solita',
        'Oakland',
        'Copceac',
        'Lagbé',
        'Pleternica',
        'Dihri',
        'Bhargaon',
        'Forestdale',
        'Huron East',
        'Stryzhavka',
        'Delta',
        'Bilenke',
        'Laufenburg (Baden)',
        'Pepillo Salcedo',
        'Guano',
        'Gavirate',
        'Ipuiúna',
        'Smithville',
        'Tecoh',
        'Ban Wang Krachae',
        'Nagykálló',
        'Tafersit',
        'Ciudad Insurgentes',
        'Cajobi',
        'Mālingaon',
        'North Codorus',
        'West Athens',
        'Dazhuangzi',
        'Haddington',
        'Yenmangandla',
        'Govindapalle',
        'Malalbergo',
        'Perkasie',
        'Mwaline al Oued',
        'Lanta',
        'Bāsudebpur',
        'Mokri',
        'Mendig',
        'Jandola',
        'San Manuel Chaparrón',
        'Maserà di Padova',
        'Elsmere',
        'Denekamp',
        'Montoro',
        'Umburetama',
        'Crawford',
        'Marlboro Village',
        'Amatlán de los Reyes',
        'Hostivice',
        'Shanklin',
        'Middlebury',
        'Chtiba',
        'Peresecina',
        'Olesno',
        'Raipur',
        'Chitrāda',
        'Nandiyālam',
        'Currumbin',
        'Rothrist',
        'Guatapé',
        'Tettu',
        'Temperance',
        'Sunset Hills',
        'Piranguinho',
        'Palocabildo',
        'Groenlo',
        'Dodji-Bata',
        'Bamaiya Harlāl',
        'Killimangalam',
        'Bad Schussenried',
        'Kongnolli',
        'East Grand Forks',
        'Hurzuf',
        'Alawandi',
        'Kaglipur',
        'Koheda',
        'Dospat',
        'San Jorge',
        'San Jorge',
        'Sauce',
        'Phagu',
        'Sihāli Jāgīr',
        'Eshkanān',
        'Miramar Beach',
        'Tiburon',
        'Kudūru',
        'Istrana',
        'Horodenka',
        'Mata Verde',
        'Nechmeya',
        'Dumri',
        'Pattīswaram',
        'Stillwater',
        'Tunari',
        'Zeydābād',
        'Fujisawachō-niinuma',
        'Nizhniy Odes',
        'Montes Altos',
        'Beuvry',
        'Hawthorn Woods',
        'Zarbdor Shaharchasi',
        'André Fernandes',
        'Reinfeld',
        'Miryal',
        'Odayārpatti',
        'Herenthout',
        'Carmen de Carupa',
        'Chotěboř',
        'Prabhāt Pattan',
        'Fairless Hills',
        'Flemington',
        'Brejolândia',
        'Loeches',
        'Uchti',
        'Satghara',
        'Dallas',
        'Weare',
        'Embalse',
        'San Zenón',
        'Tekpanja',
        'Steynsrus',
        'Rebordosa',
        'Govindāpuram',
        'Kod',
        'Cresskill',
        'Kottapālem',
        'McFarland',
        'Westampton',
        'Chaukhata',
        'Belek',
        'Datian',
        'Rutland',
        'Oborniki Śląskie',
        'Tarusa',
        'Cameron',
        'Benton Harbor',
        'Iguidiy',
        'Hirske',
        'Katakwi',
        'Nalbach',
        'Usworth',
        'Gok',
        'Orosi',
        'Kharovsk',
        'Ouédémè',
        'Consacá',
        'Rivesaltes',
        'Guryongpo',
        'Rada Tilly',
        'Rincão',
        'Park Ridge',
        'McCordsville',
        'Rondon',
        'Ambara',
        'Springfield',
        'Navoloki',
        'Tineo',
        'Urbach',
        'Coatetelco',
        'Fehrbellin',
        'Pithiviers',
        'Sogām',
        'Zeerust',
        'Kenār',
        'Riverside',
        'Bonnievale',
        'La Matanza de Acentejo',
        'Dāvulūru',
        'Nagaoki',
        'Bemarivo',
        'Logatec',
        'Campamento',
        'Calamar',
        'Suances',
        'Chillicothe',
        'Greytown',
        'Ouarégou',
        'Portlethen',
        'Kiskunlacháza',
        'Koila Belwā',
        'Yeldūrti',
        'Huntertown',
        'Marinka',
        'August',
        'Mendon',
        'Dasaut',
        'Umreth',
        'Hokur Badasgom',
        'Haripura',
        'Sidi Ouassay',
        'Paraparaumu Beach',
        'Lynwood',
        'New Scotland',
        'Kożuchów',
        'Bimāwān',
        'Absecon',
        'Olalapādi',
        'Mauji',
        'Yui',
        'Beecher',
        'Gtarna',
        'Majhaulia',
        'Periyamuttūr',
        'Little Falls',
        'Hussepur',
        'Dāita',
        'Midland',
        'Velampatti',
        'Hooper',
        'Vinsady',
        'Brejão',
        'Bāgeshwar',
        'Andiyappanūr',
        'Commerce',
        'Dinnington',
        'Mirzānagar',
        'Banārūyeh',
        'Kanasānapalle',
        'Gurmia',
        'Carignano',
        'Newark',
        'Cedar Hills',
        'Ban Tha Phra',
        'Mainaschaff',
        'Jāmunia',
        'Karayılan',
        'Mendicino',
        'Jumlā',
        'Großbeeren',
        'Pontardulais',
        'Suganwān',
        'Fiumefreddo di Sicilia',
        'Notodden',
        'Kirchberg',
        'Mosciano Sant’Angelo',
        'Sinaia',
        'Sibilia',
        'Pedda Muppāram',
        'Marāi Kalān',
        'Mbuzini',
        'Sergiyevsk',
        'Luís Gomes',
        'Hagaranahalli',
        'Townsend',
        'Fatehpur Bāla',
        'Valley Cottage',
        'Nykøbing Mors',
        'Viravāda',
        'Gidha',
        'Ghāt Borūl',
        'Ālampur',
        'Vinhais',
        'Carregado',
        'Paulo Lopes',
        'Morada Nova de Minas',
        'Auerbach',
        'Chalástra',
        'Chāndpur',
        'Åmål',
        'Pūttai',
        'Orte',
        'Kawara',
        'Berndorf',
        'Adjido',
        'Íquira',
        'Magny-le-Hongre',
        'Venkatādripālem',
        'Oggiono',
        'Economy',
        'Ewo',
        'Campobello di Licata',
        'Sarauni',
        'Wollert',
        'Saraiya',
        'Uppugunduru',
        'Strehaia',
        'Scionzier',
        'Harrodsburg',
        'Country Club Estates',
        'Roxborough Park',
        'Tāla',
        'Lacchiarella',
        'Toccoa',
        'Northwest Harborcreek',
        'Chaponost',
        'São Francisco',
        'Januário Cicco',
        'Udarband',
        'Ayanikkād',
        'Salto Grande',
        'Bonhill',
        'Lanark',
        'Kegen',
        'Bemiss',
        'Colac',
        'Intich’o',
        'Mohon',
        'Mānoke',
        'Poiana Mare',
        'La Florida',
        'Mamnūr',
        'Borgosatollo',
        'Orange Park',
        'Abū Khashab',
        'Khāsbalanda',
        'Livno',
        'Zwiesel',
        'Baniré Koré',
        'Berching',
        'Bolokhovo',
        'Harahan',
        'Andilana Avaratra',
        'La Ravoire',
        'Janhapāra',
        'Fossombrone',
        'Breckerfeld',
        'Rye',
        'Podenzano',
        'San Sebastián',
        'Sanger',
        'Ban Ngio Ngam',
        'Santa Sylvina',
        'Leven',
        'Warkan',
        'Dadrewa',
        'Old Orchard Beach',
        'Verkhnyaya Tura',
        'Chārakunda',
        'Monteroni d’Arbia',
        'Iijima',
        'Méridiala',
        'Travis Ranch',
        'Bérégadougou',
        'Aadorf',
        'Tājpur',
        'Villa Castelli',
        'Nová Paka',
        'Bockhorn',
        'Breinigsville',
        'Ādamī Tulu',
        'Gohi Bishunpur',
        'Lapeer',
        'Waldheim',
        'Ksar Belezma',
        'North Merritt Island',
        'Çayırlı',
        'Westerland',
        'Doberlug-Kirchhain',
        'Furth im Wald',
        'Highland Park',
        'Bougival',
        'Les Sorinières',
        'Porto Tolle',
        'Dunbar',
        'Nanzhuang',
        'Dharampur',
        'Monticello Conte Otto',
        'Tuscumbia',
        'Velyki Luchky',
        'Nhân Trạch',
        'Kurichedu',
        'Kannāl',
        'Katsuyama',
        'Booneville',
        'Pullach im Isartal',
        'Hemmoor',
        'Pivnichne',
        'Sint-Martens-Lennik',
        'Rāmpur Khajuriyā',
        'Douglas',
        'Ampasimpotsy-Gara',
        'Ceelbuur',
        'Coqueiral',
        'Manatuto',
        'Barwell',
        'Sāhāpur',
        'Westwood',
        'Odžaci',
        'Zalishchyky',
        'Beckwith',
        'Arico el Nuevo',
        'Blairgowrie',
        'Cupar',
        'Nādol',
        'Bala Cynwyd',
        'Muhos',
        'Tomblaine',
        'Kaniwāra',
        'Māli',
        'Foiano della Chiana',
        'Gretna',
        'Moman Barodiya',
        'Hampstead',
        'Neustadt',
        'San Francisco',
        'Ban Wang Pradu',
        'Karlıova',
        'San Ignacio',
        'Mandīshah',
        'Bābai Kalān',
        'Aplao',
        'Estanzuelas',
        'Coronel Du Graty',
        'Lakhna',
        'Dhauni',
        'Madhurāpur',
        'Bāsdeopur',
        'Hatti Mattūr',
        'Nārsingi',
        'Akat Amnuai',
        'Treia',
        'Craig',
        'Kaeng Khro',
        'Mburucuyá',
        'Hārua',
        'Gerstungen',
        'Labrador City',
        'Argudan',
        'Brand-Erbisdorf',
        'Morānha',
        'Blacklick Estates',
        'Derby',
        'Kasane',
        'Caxias',
        'Rio del Mar',
        'Burgos',
        'Tutrakan',
        'Mishrikot',
        'Perondi',
        'Seneca Falls',
        'Rafard',
        'Furtwangen im Schwarzwald',
        'Le Teich',
        'Venturina',
        'Lunner',
        'Tha Luang',
        'Chanco',
        'Chanco',
        'Kambaneri Pudukkudi',
        'Crvenka',
        'Mādhavaram',
        'Lago Vista',
        'Aïn el Mediour',
        'Chiţcani',
        'Maropaika',
        'Morafeno',
        'Soamahamanina',
        'Ambodivoanio',
        'Tsimafana',
        'Sahatsiho-Ambohimanjaka',
        'Ranopiso',
        'Ivandrika',
        'Marotolana',
        'Ambatoria',
        'Lanivo',
        'Sarasambo',
        'Antambohobe',
        'Ambalajia',
        'Nato',
        'Mahamaibe',
        'Mitanty',
        'Salobe',
        'Ambariokorano',
        'Vohilava',
        'Vatananto',
        'Iara',
        'Ampary',
        'Ambalaromba',
        'Ambatoharanana',
        'Befotaka',
        'Soamanonga',
        'Bemaharivo',
        'Anteza',
        'Bekopaka',
        'Antaly',
        'Anjialava',
        'Ankarana-Miraihina',
        'Tsaratanana',
        'Antsaidoha-Bebao',
        'Nosibe',
        'Soanierana',
        'Ambatolahy',
        'Soanenga',
        'Mahabo',
        'Manampaneva',
        'Manja',
        'Ambinanin’ Andravory',
        'Belinta',
        'Marovatolena',
        'Morarano',
        'Antsahavaribe',
        'Antseza',
        'Andribavontsona',
        'Ankiliabo',
        'Antanankambano',
        'Alakamisy-Ambohimahazo',
        'Benato-Toby',
        'Ankirihitra',
        'Antsatramidola',
        'Amboronabo',
        'Manevy',
        'Beparasy',
        'Tandrano',
        'Fierenana',
        'Ambarimaninga',
        'Ambodimahabibo',
        '’s-Gravendeel',
        'Dhībān',
        'Dahbed',
        'Qahramon',
        'Lo Miranda',
        'Bovingdon',
        'Diabugu',
        'Jangalapalli',
        'Urpāar',
        'Lohara',
        'Rohera',
        'Ugamedi',
        'Tikar',
        'Gisborne',
        'Carneiros',
        'Marcação',
        'Puerto Octay',
        'Patūt',
        'Hetanpur',
        'Kalicherla',
        'Rarz',
        'San Pedro Atocpan',
        'Turín',
        'Mbamba Bay',
        'Neuötting',
        'Dholbāja',
        'Novyi Svit',
        'Zschopau',
        'Almusafes',
        'Hirehāluhosahalli',
        'Lake Park',
        'Glens Falls North',
        'Guichen',
        'Rājod',
        'Olney',
        'Pomichna',
        'Panasapādu',
        'Druento',
        'Yelnya',
        'Lamorlaye',
        'Settivāripalle',
        'Peddannavāripalle',
        'Ixtapa Zihuatanejo',
        'Podu Iloaiei',
        'Perl',
        'Veauche',
        'Harleysville',
        'Säffle',
        'Icaraíma',
        'Devanāngurichchi',
        'Gacko',
        'Kāndra',
        'Rice Lake',
        'Had Dra',
        'Apahida',
        'Kozova',
        'Iscuandé',
        'Gudlūru',
        'Chundale',
        'Shelburne',
        'Ardooie',
        'Togou',
        'Dulce Nombre de María',
        'Santana do Manhuaçu',
        'Wenzenbach',
        'Kanchanpalli',
        'East Nottingham',
        'Foum Zguid',
        'Urdinarrain',
        'Bassenge',
        'Denbigh',
        'Hirayama',
        'Kosum Phisai',
        'Arboledas',
        'Telaprolu',
        'Yarmouth',
        'Almargem',
        'Tiou',
        'Seydunganallūr',
        'Chilón',
        'Kirs',
        'Puerto Lleras',
        'Hilzingen',
        'Khiriāwān',
        'Pulaski',
        'Yapraklı',
        'Brooksville',
        'Lopatcong',
        'Stanley',
        'San Francisco Ixhuatan',
        'Acobamba',
        'Kattamūru',
        'Bayabas',
        'Akpassi',
        'Hosūru',
        'Sanganakallu',
        'Pulimākkal',
        'Taber',
        'Huasco',
        'Sarzeau',
        'Pedda Pendyāla',
        'Higuera de Zaragoza',
        'Leisure World',
        'Uspenka',
        'Linares',
        'Ponnāda',
        'Bhirua',
        'Barwān',
        'Grabels',
        'Iseo',
        'Saline',
        'Tepechitlán',
        'Dzhalka',
        'Pena Forte',
        'Yekāmbarakuppam',
        'Fitzgerald',
        'Evergreen',
        'Lerma',
        'Thung Sai',
        'Roztoky',
        'Talevad',
        'Mahamda',
        'Pararia',
        'Tulbagh',
        'Valea lui Mihai',
        'Ianca',
        'Vrhnika',
        'Borgoricco',
        'Kelso',
        'Miradouro',
        'Lolokhur',
        'Perkiomen',
        'Al Yādūdah',
        'Neuried',
        'Fort Irwin',
        'Tzitzio',
        'Saint Sampson',
        'Kharsāwān',
        'Siachoque',
        'Cloverdale',
        'Plainedge',
        'Ortenberg',
        'Cheste',
        'Mudgere',
        'Park Forest Village',
        'Aver-o-Mar',
        'Gonfreville-l’Orcher',
        'Grenade',
        'Tovāla',
        'Fredensborg',
        'Sileby',
        'Segni',
        'Nuquí',
        'Ūnagatla',
        'Sheffield Lake',
        'Sarenja',
        'Gāzulapalle',
        'Oak Grove',
        'Hlobyne',
        'Rio das Flores',
        'Wangdue Phodrang',
        'Villamediana de Iregua',
        'Sānchi',
        'San Francisco Chimalpa',
        'Borskoye',
        'Fuldabrück',
        'Zacháro',
        'Bayyavaram',
        'Kusmaul',
        'Tvrdošín',
        'Velden am Wörthersee',
        'Yaguará',
        'Noventa Vicentina',
        'Bazimini',
        'Donnacona',
        'Sucha Beskidzka',
        'Emirgazi',
        'Mnichovo Hradiště',
        'Karadge',
        'Phulhara',
        'Vanavāsi',
        'Groß Kreutz',
        'Ouled Rahou',
        'Sambhu Chak',
        'Bawāna',
        'Aviano',
        'Villa Aberastain',
        'Angelópolis',
        'Ben Chicao',
        'Cébazat',
        'Bāba Bakāla',
        'Bloomingdale',
        'Natuba',
        'Bhelsi',
        'Jasauli Patti',
        'Dalavāypattanam',
        'West Donegal',
        'Haledon',
        'Attnang-Puchheim',
        'Séné',
        'Rāmnagar',
        'Indian Harbour Beach',
        'Czarna Białostocka',
        'Yasenivskyi',
        'Açucena',
        'Chitvel',
        'Winslow',
        'Nanmucun',
        'Ferryhill',
        'Munagāla',
        'Harwood Heights',
        'Colméia',
        'Tena',
        'Plön',
        'Corgao',
        'Bikkatti',
        'Esanai',
        'Mālior',
        'Kotabommāli',
        'Yellanda',
        'Colorno',
        'Peebles',
        'Broughton Astley',
        'Bogota',
        'Almoloya del Río',
        'Viale',
        'Tshabong',
        'Bad Lauchstädt',
        'Borio',
        'Nawāda',
        'Rāmgarha',
        'Belma',
        'Juncos',
        'Montagnana',
        'Ban Nikhom Phatthana',
        'Caputira',
        'Sampgaon',
        'Kafr Sajnah',
        'Khagaur',
        'Ladue',
        'Beaver Falls',
        'Chinna Kalaiyamputtūr',
        'Bestensee',
        'Glastonbury',
        'Monteforte d’Alpone',
        'Thap Than',
        'Seddouk Oufella',
        'Guttikonda',
        'Qashyr',
        'Bni Sidel',
        'La Junta',
        'West Caln',
        'Hürtgenwald',
        'Nunchía',
        'Purcellville',
        'Edgewater',
        'Zhur',
        'Neuhaus am Rennweg',
        'Shahr-e Pīr',
        'Florø',
        'Monastyryshche',
        'Horokhiv',
        'Monistrol-sur-Loire',
        'Kovvali',
        'Gouvieux',
        'Balvādi',
        'Castelnuovo Berardenga',
        'Vila Nova de Cerveira',
        'Sai Ngam',
        'Cholavaram',
        'Kara-Bak',
        'Thị Trấn Đồng Đăng',
        'Libonik',
        'Morières-lès-Avignon',
        'Edgewater Park',
        'Mikhaylovsk',
        'Rāmapattanam',
        'Hope',
        'Chirongui',
        'Zawyat Sidi al Mekki',
        'Suoyarvi',
        'Terra Nova',
        'Eslohe',
        'Mangalpur Gudaria',
        'Nānguneri',
        'Māldah',
        'Somero',
        'Khorāgāchhi',
        'Upper Leacock',
        'Village St. George',
        'Santa María del Tule',
        'Bayport',
        'Fair Lakes',
        'Filottrano',
        'Kui Buri',
        'Florida',
        'Weilmünster',
        'Barhagarh',
        'Asolo',
        'Penaballi',
        'Kilānkundal',
        'Sępólno Krajeńskie',
        'Serafimovich',
        'Redlands',
        'Dashouping',
        'Eceabat',
        'Santa Isabel do Ivaí',
        'Ingenbohl',
        'Brownfield',
        'Argelia',
        'Dayr as Sanqūrīyah',
        'Hernando',
        'Polička',
        'Dumri',
        'Periyapōdu',
        'Kodikkulam',
        'Santa Cruz Atizapán',
        'Osicala',
        'Corman Park No. 344',
        'Virgínia',
        'Felling',
        'Great Cornard',
        'Segarai',
        'Litchfield Beach',
        'Clinton',
        'Almagro',
        'Basāha',
        'Channubanda',
        'Plymouth',
        'Topchikha',
        'Helsinge',
        'Ayas',
        'Enkakād',
        'Bhadwār',
        'Nueva Guadalupe',
        'Kulu',
        'Devnya',
        'Belvedere Marittimo',
        'Makhambet',
        'St. Pete Beach',
        'Kratovo',
        'Lakhanāpuram',
        'Rock Falls',
        'Palmeira d’Oeste',
        'Árgos Orestikó',
        'Potangal',
        'Tullukuttināyakkanūr',
        'Florstadt',
        'Le Teil',
        'Caister-on-Sea',
        'Wanaka',
        'Amuria',
        'Yangiobod',
        'Vadasīkarambattu',
        'Karor',
        'Sandpoint',
        'Nangis',
        'Orting',
        'Pudozh',
        'Le Portel',
        'Caldwell',
        'San José de Gracia',
        'Ibirapuã',
        'Punjai Lakkāpuram',
        'Kishtwār',
        'Maddūr',
        'Matias Cardoso',
        'Bösel',
        'Wābāgai',
        'Prienai',
        'Crozet',
        'Tepetitlan',
        'San Marzano di San Giuseppe',
        'Kanhauli',
        'Oststeinbek',
        'Sandiacre',
        'Tummalacheruvu',
        'Douar Ait Taleb',
        'Zunilito',
        'Whippany',
        'Napoleon',
        'Tiachiv',
        'Ấp Tân Ngãi',
        'Shoshong',
        'Allāhpur',
        'Barhauna',
        'Tarcento',
        'Tlumach',
        'Holywell',
        'Melpanaikkādu',
        'Bīdkhūn',
        'Ladan Kara',
        'Chachersk',
        'Francisco Caballero Álvarez',
        'Metsemotlhaba',
        'Partick',
        'Belišće',
        'Tharīke',
        'Bargara',
        'North Londonderry',
        'L’Epiphanie',
        'San Antonio',
        'Ifield',
        'Rewāhi',
        'Mansfield',
        'Rio Claro',
        'Carmen de Apicalá',
        'Sucre',
        'Palangavāngudi',
        'Halikko',
        'Majhariyā Sheikh',
        'Edgemere',
        'Liteni',
        'Oberriet',
        'Kurichchi',
        'Polakala',
        'Pomona',
        'Makaya',
        'Gurmaila',
        'Teruel',
        'Hallstadt',
        'Silleda',
        'Alwa Tirunagari',
        'Kulpsville',
        'Zuchwil',
        'Khem Karan Saray',
        'Bālumāth',
        'Nānan',
        'Bhagatpur',
        'Padakanti',
        'Alvarado',
        'Breuillet',
        'Kharsod B',
        'Mosrāh',
        'Camenca',
        'Hohenbrunn',
        'Khandrauli',
        'Thief River Falls',
        'Imilchil',
        'Bhatauliā',
        'Āgadāllanka',
        'Minerbio',
        'Pearsall',
        'Diamou',
        'Tataltepec de Valdés',
        'Quétigny',
        'Ghorbanki',
        'Unterägeri',
        'La Fare-les-Oliviers',
        'Irigny',
        'Mallāram',
        'Gangapatnam',
        'Dāra',
        'Iskourane',
        'Msemrir',
        'Hunduan',
        'Rabo de Peixe',
        'Papara',
        'Schleiz',
        'Kambūr',
        'Golakpur',
        'Chiranellūr',
        'Cumberland Hill',
        'Greenville',
        'Ait Ikkou',
        'Neya',
        'Maipú',
        'Aināpur',
        'Katahra',
        'Kanakpur',
        'Teolo',
        'Collingdale',
        'West Perth',
        'Ayapango',
        'Yakakent',
        'Yelsk',
        'Ratanpur',
        'Kusterdingen',
        'Mannamangalam',
        'Muriyād',
        'Ponta do Sol',
        'Tasso Fragoso',
        'Kéllé',
        'Sorisole',
        'Al Abraq',
        'Pullalacheruvu',
        'Sirikonda',
        'Jogiāra',
        'Gondauli',
        'Viagrande',
        'Rocas de Santo Domingo',
        'Striano',
        'Yanchep',
        'González',
        'Woodmoor',
        'Collier',
        'Japurá',
        'Le Thor',
        'Kuvshinovo',
        'Achacachi',
        'Ak’ordat',
        'Vannikkonendal',
        'Reni',
        'Pandino',
        'Bellheim',
        'El Valle',
        'Bellerive-sur-Allier',
        'Ilarionove',
        'Amtala',
        'Sulahpet',
        'East York',
        'Poteau',
        'Gilgit',
        'Kilchberg',
        'Mandalavādi',
        'Sakaddi',
        'Australia',
        'Bersenbrück',
        'Keora',
        'Kadiyadda',
        'Khetko',
        'Aït Ouaoumana',
        'Hlevakha',
        'Landquart',
        'Golbey',
        'Shankarpur',
        'Agadir Melloul',
        'Fontaine-lès-Dijon',
        'Malangām',
        'Aradeo',
        'Moka',
        'Ban Pong Tao',
        'Jaimalpura',
        'Karariyā',
        'Rhymney',
        'Tirumalaippatti',
        'Sewāri',
        'Kālkuni',
        'Duvvūru',
        'Nimmekal',
        'Casorate Primo',
        'Zoeterwoude',
        'Şabanözü',
        'Volkach',
        'Bansang',
        'Drazinda',
        'Nellutla',
        'Pallippatti',
        'Coffeyville',
        'Caturama',
        'Madnūr',
        'Andalusia',
        'Chak Habib',
        'Pā’īn Chāf',
        'Highland Park',
        'Naunhof',
        'Dayton',
        'Smižany',
        'Ban Noen Kum Nueng',
        'Ban Muang Kham',
        'Vinjam',
        'Psyzh',
        'Sæby',
        'Finestrat',
        'Timmapuram',
        'Madanāncheri',
        'Machadodorp',
        'El Realejo',
        'Povarovo',
        'Armazém',
        'Ospina',
        'Steinheim am Albuch',
        'Seneca',
        'Potengi',
        'Pedrinhas',
        'Urânia',
        'Sānwas',
        'Moslem Ebn-e ‘Aqīl',
        'Signal Mountain',
        'Campbellton',
        'Al Buwayḑah',
        'Bīrpur',
        'Cortez',
        'San Juan de la Costa',
        'Dobříš',
        'Maysville',
        'Piriápolis',
        'Qızılhacılı',
        'Heek',
        'Cumnock',
        'Ablu',
        'Tāmganj',
        'Maria Enzersdorf',
        'Kressbronn am Bodensee',
        'Sanatoga',
        'Dardilly',
        'Hamsāvaram',
        'Tirano',
        'Kaï',
        'Côn Đảo',
        'Memmelsdorf',
        'Gibsonville',
        'Hucclecote',
        'Montmeló',
        'Mahāgaon',
        'Reyes',
        'Baía Formosa',
        'Neuenhof',
        'Satwār',
        'Rāmgarh',
        'Missaglia',
        'Bouati Mahmoud',
        'Sabáudia',
        'Vert-Saint-Denis',
        'Pleasant Hill',
        'Trujillo',
        'Jonnagiri',
        'Chakla Waini',
        'Kodavatipūdi',
        'Sekimachi',
        'Fort Valley',
        'Amarzgane',
        'Entrerríos',
        'Toppenish',
        'Summit',
        'Baciu',
        'Valasa',
        'Kukraun',
        'Carbondale',
        'Ḩīsh',
        'Bāsmanpur',
        'Vadapalanji',
        'Albinea',
        'Adigoppula',
        'Soresina',
        'Mahela',
        'Takua Pa',
        'São Sebastião do Curral',
        'Upper Makefield',
        'Baramandougou',
        'Yacimiento Río Turbio',
        'Rangvāsa',
        'Ban Si Don Chai',
        'Oak Hills Place',
        'San Martín de Valdeiglesias',
        'Sarlat-la-Canéda',
        'Mānsong',
        'Takouta',
        'Hoyo de Manzanares',
        'Paramé',
        'Bārun',
        'Sorala',
        'Jolfā',
        'Porto Santo Stefano',
        'Mokhotlong',
        'Kampong Tunah Jambu',
        'Boucau',
        'Bolszewo',
        'Mühlhausen',
        'Chécy',
        'Bainbridge',
        'Ineu',
        'Hünenberg',
        'Mālipākar',
        'Mousoulou',
        'San Miguel',
        'Lusca',
        'Ala',
        'Tagapul-an',
        'Pitman',
        'Soltsy',
        'Rrëshen',
        'Lovosice',
        'Iklod',
        'Dhobipet',
        'Alvorada',
        'Ranjal',
        'Bernolákovo',
        'Beni Hassane',
        'Angor',
        'Tausa',
        'Obernburg am Main',
        'Tillaivilāgam',
        'Clayton',
        'North Haledon',
        'G‘ozg‘on',
        'Dehri',
        'Taragi',
        'Veľké Kapušany',
        'La Ferté-Bernard',
        'Dunavarsány',
        'Pittsgrove',
        'London Grove',
        'Ulstein',
        'Cristuru Secuiesc',
        'Rouvroy',
        'Penugolanu',
        'Vanduvāncheri',
        'San Ignacio',
        'Madhuban',
        'Bommayapālaiyam',
        'Uta',
        'Santa María Xadani',
        'Gopālpur',
        'Maisaram',
        'Ballenstedt',
        'Gudibanda',
        'Baikunthapur',
        'Ntossoni',
        'Strijen',
        'Haţeg',
        'Lichtervelde',
        'Entraigues-sur-la-Sorgue',
        'Puraini',
        'Chīchkah',
        'Abcoude',
        'Gudivāda',
        'Rautara',
        'Engerwitzdorf',
        'Lontra',
        'Quéven',
        'Jeannette',
        'Bridgewater',
        'Kukrahill',
        'Falla',
        'Nagykovácsi',
        'Miyār',
        'Hassi Berkane',
        'Kryzhopil',
        'Brimington',
        'Varatanapalli',
        'Gholia Kalān',
        'Porcari',
        'Treze Tílias',
        'El Outaya',
        'Karis',
        'Khānāpur',
        'Glencoe',
        'Belozërsk',
        'Tlagasana',
        'Soeda',
        'Toulou',
        'Sowān',
        'Madhubani',
        'La Loggia',
        'Ban Sai Yoi',
        'Koekelare',
        'Dharāwat',
        'Schuylkill',
        'Cofimvaba',
        'Lienen',
        'Costa Volpino',
        'Sodankylä',
        'Essey-lès-Nancy',
        'Belsh',
        'Chahana',
        'Garsekurti',
        'Bhāsaula Dānāpur',
        'Boonton',
        'Saidia',
        'Cherry Hinton',
        'Annan',
        'Nueva Ocotepeque',
        'Kampel',
        'Waldenbuch',
        'Bachchannapet',
        'Yelandūr',
        'Kūnimedu',
        'Moe',
        'Garching an der Alz',
        'La Mujer',
        'Bundehra',
        'Barth',
        'Monforte del Cid',
        'Cinderford',
        'Greenville',
        'Cowley',
        'Adiyakkamangalam',
        'Komījān',
        'Clanton',
        'Néa Michanióna',
        'Deodora',
        'Bath',
        'Alíartos',
        'Somarasampettai',
        'Kusumbe',
        'Mahopac',
        'Tiqqi',
        'Pereiras',
        'Efringen-Kirchen',
        'Poisy',
        'Gessate',
        'Meadow Lakes',
        'Amberomanga',
        'Brevik',
        'Luzzi',
        'Presque Isle',
        'Paratdiha',
        'Bee Cave',
        'Coaldale',
        'Muturkha',
        'Gorodoviki',
        'Minyar',
        'Cunupia',
        'Vicentinópolis',
        'Kirrāyach',
        'Chartiers',
        'Taşkent',
        'Hirni',
        'Chinna Mupparam',
        'Boukhralfa',
        'Srīrāmapuram',
        'Hardiya',
        'Aragona',
        'Pizzo',
        'Großenlüder',
        'Amtala',
        'Īdupugallu',
        'Monmouth',
        'Spring Valley Lake',
        'Bertrix',
        'Rāmpatti',
        'Vidapanakallu',
        'Āshtīān',
        'Wesley Chapel',
        'Marrupa',
        'Zapatoca',
        'Abirāmam',
        'Ōtaki',
        'Perry Heights',
        'Mascoutah',
        'Borgentreich',
        'Cockermouth',
        'Borgo a Buggiano',
        'Galela',
        'Zūlakallu',
        'Pukkulam',
        'Kibaya',
        'Fully',
        'Pa Mok',
        'Ottobeuren',
        'Saint-Paul-Trois-Châteaux',
        'Rāni Sāwargaon',
        'San Pedro de Coche',
        'Riacho dos Machados',
        'Hombrechtikon',
        'Pacuarito',
        'Vohburg an der Donau',
        'Aulnoye-Aymeries',
        'Crest',
        'Hiramandalam',
        'Windsor',
        'Mariinskiy Posad',
        'Dāmal',
        'Būdamangalam',
        'Stony Brook University',
        'Natividade',
        'Lachen',
        'Angichettippālaiyam',
        'Brighton',
        'Habo',
        'Daping',
        'Banak',
        'San Juan Ixcaquixtla',
        'Lenox',
        'Pasivedalajimma',
        'Sremski Karlovci',
        'Tilvalli',
        'Buenavista',
        'Sant’Angelo in Lizzola',
        'Mozzate',
        'Coccaglio',
        'Shopokov',
        'Bouhlou',
        'Afumaţi',
        'Sānrha',
        'Castagneto Carducci',
        'Civitella in Val di Chiana',
        'Summit View',
        'Marlborough',
        'Rudewa',
        'Sonsbeck',
        'Roccastrada',
        'Wervershoof',
        'Tanudan',
        'Stary Sącz',
        'Lamarão',
        'Jabera',
        'Ranod',
        'Franklin',
        'Leopoldo de Bulhões',
        'Kanavāypudūr',
        'Rahta',
        'Pararia',
        'Höshööt',
        'Koror',
        'Uzundere',
        'Biederitz',
        'Notre-Dame-de-Gravenchon',
        'Murillo',
        'Porkhov',
        'Teranikallu',
        'Dhanauli',
        'Nam Som',
        'Río Jiménez',
        'Wingles',
        'Pullānvidudi',
        'Vadakādu',
        'Hoquiam',
        'Corona de Tucson',
        'Comendador Levy Gasparian',
        'Périgny',
        'Saint-Jean-de-Monts',
        'Hakka',
        'Pedda Nindrakolanu',
        'Parabita',
        'Doornkop',
        'Lichana',
        'Rudravaram',
        'Hollymead',
        'Krompachy',
        'Sanjāt',
        'Jackson',
        'Queimada Nova',
        'Hârşova',
        'Meßkirch',
        'Mālaimārpuram',
        'Mirchpur',
        'Aktepe',
        'Wattwil',
        'Bussy',
        'Rauenberg',
        'Moranbah',
        'Igana',
        'Bhui',
        'Ekangar Sarai',
        'Velakalnattam',
        'Bharno',
        'Ellicott',
        'Gundumāl',
        'Folignano',
        'Karuveppampatti',
        'Sātgāchia',
        'Hostotipaquillo',
        'Gremyachinsk',
        'Bad Bergzabern',
        'Löwenberg',
        'Jalālkhera',
        'Maiquinique',
        'Herzberg',
        'Majra',
        'Obertraubling',
        'Lillebonne',
        'Nāgaiyampatti',
        'Faro',
        'San Juan de Arama',
        'Càbras',
        'Carlosama',
        'Roetgen',
        'Saint-Claude',
        'Tsrār Sharīf',
        'Pendekallu',
        'Graham',
        'Galmaarden',
        'Nārona',
        'Montalto di Castro',
        'Port Jervis',
        'Gendou',
        'Stará Turá',
        'Buved',
        'Rāgampet',
        'Pont-Rouge',
        'Volchansk',
        'Güneysu',
        'Pillutla',
        'Siġġiewi',
        'Cutrofiano',
        'Hualaihué',
        'Junín',
        'Vīrapalle',
        'Wyoming',
        'Aransas Pass',
        'Onnaing',
        'La Chapelle d’Armentières',
        'Navappatti',
        'South Londonderry',
        'Ustrzyki Dolne',
        'Punnappatti',
        'Ponte Buggianese',
        'Santanópolis',
        'Margny-lès-Compiègne',
        'Iaboutene',
        'Akālgarh',
        'Angallu',
        'Barbana',
        'Bommagondanahalli',
        'Sarbīsheh',
        'Inékar',
        'Azīzpur Chānde',
        'Tomeşti',
        'Velaux',
        'Uchen',
        'Chanute',
        'Talsi',
        'Gigmoto',
        'Al Quway‘īyah',
        'Ouando',
        'Bishunpur',
        'Besozzo',
        'Hamlin',
        'Kolbuszowa',
        'Perevoz',
        'Califórnia',
        'Sohta',
        'Doiwāla',
        'São José do Cerrito',
        'Choceň',
        'Gangājalghāti',
        'Blanchard',
        'Anthony',
        'Summit Park',
        'Nanzhangcheng',
        'Dunn Loring',
        'Tleta Taghramt',
        'Paola',
        'Gyümai',
        'Bhānuvalli',
        'Roccapiemonte',
        'Champlain',
        'Sidi el Mokhfi',
        'Lázaro Cárdenas',
        'San Sebastian',
        'Janglot',
        'Sidi Dahbi',
        'Havsa',
        'Aşağı Quşçu',
        'Iles',
        'Richterich',
        'Lake Villa',
        'Bududa',
        'Jondor Shaharchasi',
        'Tashir',
        'Siklós',
        'Sini',
        'Freeport',
        'Volodymyrets',
        'Pardanjān',
        'Ogden',
        'Innsbrook',
        'Zawiat Moulay Brahim',
        'Koppunur',
        'Vadacheri',
        'Raia',
        'Pulivalam',
        'Flero',
        'Caprino Veronese',
        'Coaticook',
        'Marcy',
        'Phimai',
        'Ortaköy',
        'Simón Bolívar',
        'Tonk Khurd',
        'Gonzaga',
        'Karadipāra',
        'Roverbella',
        'Audubon',
        'Stăuceni',
        'Pokhraira',
        'Pāppākudi',
        'Torre Boldone',
        'Cullinan',
        'Måløv',
        'Nāttarasankottai',
        'Indūrti',
        'Solebury',
        'Mirdoddi',
        'Conthey',
        'Wietze',
        'Mrakovo',
        'São Pedro do Ivaí',
        'Ergoldsbach',
        'Goworowo',
        'Binfield',
        'Dharhwa',
        'Heusden',
        'Heikendorf',
        'Le Petit-Couronne',
        'Gadaul',
        'Katrīdih',
        'Painkulam',
        'Koshanam',
        'Lequile',
        'Uglegorsk',
        'Limeira d’Oeste',
        'Newstead',
        'Stainz',
        'Titz',
        'Zanica',
        'Aigues-Mortes',
        'Azandarīān',
        'Oxford',
        'Duchcov',
        'Monte Escobedo',
        'Murājpur',
        'Bāgalvād',
        'Gobindpur',
        'Savoy',
        'Cheviot',
        'Kaldsletta',
        'Katueté',
        'Tordesillas',
        'Kambhampādu',
        'Hillview',
        'Pyālakurti',
        'Kursaha',
        'Brandizzo',
        'Sangaree',
        'Embrun',
        'Çamlıyayla',
        'Suttamalli',
        'Rompicherla',
        'Kottūr',
        'Kalinagar',
        'Pibrac',
        'Tecumseh',
        'Aratuípe',
        'Marilândia do Sul',
        'Tilarán',
        'Chetma',
        'Chākand',
        'Guntapalli',
        'Castellabate',
        'Glodeni',
        'Longvic',
        'Teotitlán',
        'River Road',
        'Catuípe',
        'Kilcock',
        'Aberdeen',
        'Briceño',
        'Flieden',
        'Roquevaire',
        'Herseh Chhīna',
        'Bhatkhori',
        'Parasbani',
        'Triuggio',
        'Ter Apel',
        'Al Qardāḩah',
        'Marāveh Tappeh',
        'Minto',
        'Malhada de Pedras',
        'Montlhéry',
        'Atherstone',
        'Olmsted Falls',
        'Bryan',
        'Chełmek',
        'Nörten-Hardenberg',
        'Coutras',
        'Ōwani',
        'Wharton',
        'Heartland',
        'Avabodji',
        'Passo do Sertão',
        'Altavilla Milicia',
        'North Cornwall',
        'Baxter',
        'Meine',
        'Domérat',
        'Amiāwār',
        'Fort Mitchell',
        'Yang Talat',
        'Jujhārpur',
        'Mustafābād',
        'Rodeiro',
        'L’Île-Saint-Denis',
        'Pathrāha',
        'San Sebastiano al Vesuvio',
        'Perryton',
        'Lempdes',
        'Ōhata',
        'Hirson',
        'Harīke',
        'Benner',
        'Gillitts',
        'Kościelisko',
        'Chintakommadinne',
        'Barvynkove',
        'Paula Cândido',
        'Chyhyryn',
        'Nagdah',
        'Clarendon Hills',
        'Abū Ḩardūb',
        'Demirözü',
        'Aars',
        'Tumberi',
        'Dilāwarpur',
        'Karavan',
        'Najrīj',
        'Zabok',
        'Belhatti',
        'Ballina',
        'Tirschenreuth',
        'Fort Knox',
        'Campos Lindos',
        'Eduttavāynattam',
        'Bikrampur Bānde',
        'Park Hills',
        'Ankatafa',
        'Sokyriany',
        'Chak Pahār',
        'Village Green-Green Ridge',
        'Highland Heights',
        'Deutsch-Wagram',
        'Adjarra',
        'Untergruppenbach',
        'Løgten',
        'Onda',
        'Bedum',
        'Farob',
        'Seosaeng',
        'Le Loroux-Bottereau',
        'Isbergues',
        'Vodice',
        'Salar',
        'Lihue',
        'Tudela de Duero',
        'Thāthūpur',
        'Vanukūru',
        'Kottadindulu',
        'Warrenton',
        'Sint-Job-in-’t-Goor',
        'Ḩalāwah',
        'Ōuda-daitō',
        'Gulf Hills',
        'Timmendorfer Strand',
        'La Libertad',
        'Bishamagiri',
        'Pa Sang',
        'Redlynch',
        'Artigues-près-Bordeaux',
        'Dirusumarru',
        'Serramazzoni',
        'Dobele',
        'Nansio',
        'Nandnāwān',
        'Bovalino Marina',
        'Guntersville',
        'Covington',
        'Sollefteå',
        'Saint-Chamas',
        'Chamonix-Mont-Blanc',
        'San Bartolomé Milpas Altas',
        'Lint',
        'Vergiate',
        'Buckie',
        'Sant’Agnello',
        'Budenheim',
        'Sobhāpur',
        'Anykščiai',
        'Rakitovo',
        'Kāoni',
        'Mahadipur',
        'Lourosa',
        'Niederwerrn',
        'Nayāgaon',
        'Bisaul',
        'Panfilovka',
        'La Pêche',
        '’s-Heerenberg',
        'Bouaiche',
        'Chapel en le Frith',
        'Pinjranwān',
        'Digar',
        'Maina',
        'Beibu',
        'Solotvyno',
        'Zuera',
        'Rangwāsa',
        'Manteno',
        'Wiang Haeng',
        'Slateng Dua',
        'Parsād',
        'Maddūr',
        'Tokatippa',
        'Long Hill',
        'Pantelhó',
        'Corella',
        'Ulātu',
        'Gokhulāpur',
        'Gubden',
        'Belo Vale',
        'Monte Alegre do Sul',
        'Kīlakkurichchi',
        'Kattirippulam',
        'Rājhanpur',
        'Cradock',
        'Chop',
        'Salinas',
        'Le Poiré-sur-Vie',
        'Sa‘īdī',
        'East Donegal',
        'Vyetka',
        'Midalam',
        'Khandauli',
        'Shahrak-e Pārs',
        'Smithfield',
        'Sredets',
        'Garešnica',
        'Fort Riley',
        'Ustyuzhna',
        'Beutelsbach',
        'Sande',
        'Basrūr',
        'Mannegudam',
        'Chettiyapatti',
        'Nierstein',
        'Stansted Mountfitchet',
        'Hakubachō',
        'Hamilton',
        'Ouro Verde',
        'Canford Cliffs',
        'Segaon',
        'Martano',
        'Mechanicstown',
        'Ferreira do Zêzere',
        'Tārazu',
        'Panjampatti',
        'Hathiākān',
        'Trissino',
        'Buda-Kashalyova',
        'Rāiparthi',
        'Hire Vadvatti',
        'Soverato Marina',
        'Kanchanpur',
        'Moribila',
        'Tibro',
        'Māgam',
        'Saidābād',
        'Chānp',
        'Petilia Policastro',
        'Templeton',
        'Arealva',
        'Santa Cruz do Monte Castelo',
        'Bremgarten',
        'Mānpur',
        'Pirojgarh',
        'Verkhoturye',
        'Whitwick',
        'Piliscsaba',
        'San Ignacio',
        'Barntrup',
        'Vostochnyy',
        'Corbin',
        'Ammanford',
        'New Ross',
        'Antargangi',
        'Tombos',
        'Aurisina',
        'Kappeln',
        'Kharod',
        'Carneys Point',
        'Oulad Khallouf',
        'Oulad Khallouf',
        'Teghra',
        'Socorro',
        'Laishevo',
        'Achchampeta',
        'Nariño',
        'Karkkila',
        'Khaur',
        'Skowhegan',
        'Amalou',
        'Pasaul',
        'Jainagar',
        'Sīlaiyampatti',
        'Kanavāypatti',
        'Bientina',
        'Mudichchur',
        'Shyamnagar',
        'Zaandijk',
        'Nakaseke',
        'Schwarzenbruck',
        'Nefasīt',
        'Chamba',
        'Velim',
        'Jagannāthpur',
        'Kennedy',
        'Citrus Hills',
        'Ueckermünde',
        'Hāta',
        'Bad Rothenfelde',
        'Mauléon',
        'Seffner',
        'Egg',
        'Egg',
        'La Belleza',
        'Peralta',
        'Bālakrishnanpatti',
        'Serramanna',
        'Aibonito',
        'Sopot',
        'Amingaon',
        'Pariyāri',
        'Kennett',
        'Kozlovka',
        'Koila',
        'Vráble',
        'Miyada',
        'La Huerta',
        'Żuromin',
        'Dobrada',
        'Haibach',
        'Bryans Road',
        'Lancaster',
        'Monona',
        'Quezalguaque',
        'La Cruz',
        'Kanyākulam',
        'Āmudālapalle',
        'Piliv',
        'Rayón',
        'Verkhniye Achaluki',
        'Jalhay',
        'Yellāreddi',
        'Kennedale',
        'Plabennec',
        'Roussillon',
        'Srīrangāpur',
        'Merksplas',
        'Anantasāgaram',
        'San Lorenzo',
        'Swissvale',
        'Eden Isle',
        'Gundūr',
        'Bargaon',
        'Adalar',
        'Rava-Rus’ka',
        'Pallappālaiyam',
        'Mareeba',
        'Viechtach',
        'El Ghomri',
        'Vinica',
        'Ardatov',
        'Vif',
        'Tarxien',
        'Lakhzazra',
        'Gidan Idèr',
        'Heroldsberg',
        'Thorigné-Fouillard',
        'Jagta',
        'Othello',
        'Gering',
        'Kızılcaşar',
        'Dékanmé',
        'Monte Porzio Catone',
        'Gonzales',
        'Tamza',
        'Tougouni',
        'Yasinia',
        'Moss Vale',
        'Vícam Pueblo',
        'Liubymivka',
        'Nuevo Paysandú',
        'Sinimbu',
        'Ouled Abbes',
        'Kalaun',
        'Yalí',
        'Waltham Cross',
        'Trebisacce',
        'Bandamūrlanka',
        'El Espinal',
        'Ahumada',
        'Fällanden',
        'Karpenísi',
        'Kāliganj',
        'Hosuru',
        'Seyyedān',
        'La Grande-Motte',
        'Nunihāt',
        'Hale Dyāmavvanahalli',
        'Vīrapperumānallūr',
        'Torre de Moncorvo',
        'Štětí',
        'Bucksburn',
        'Nettādahalli',
        'Rāni Sāgar',
        'Masandra',
        'Lālam',
        'Lamesa',
        'Reinosa',
        'Janzé',
        'Kirk of Shotts',
        'Charuānwān',
        'Shiddāpūr',
        'Ban Ratchakrut',
        'Babhangaon',
        'Bundāla',
        'Gamail',
        'Lāndupdīh',
        'Locogahoué',
        'Biberist',
        'Castelnuovo di Porto',
        'Coahuitlán',
        'Sèmèrè',
        'Vaucresson',
        'Obersiggenthal',
        'Millis',
        'Anjahamarina',
        'Kamień Pomorski',
        'Na Yung',
        'Youghal',
        'Dharmājigūdem',
        'Castel Gandolfo',
        'Oak Island',
        'Navipet',
        'Vicopisano',
        'Shediac',
        'Loenen',
        'Bushtyno',
        'Hořice',
        'Pöytyä',
        'Midutūru',
        'San Pedro Ixtlahuaca',
        'Verkhniy Mamon',
        'Usiacurí',
        'Sompting',
        'Kings Grant',
        'Reeuwijksebrug',
        'Tanmpègré',
        'Chāpalamadugu',
        'Lambarkiyine',
        'Ayotoxco de Guerrero',
        'Nerubaiske',
        'Pedreguer',
        'Dāla',
        'Raonta',
        'Paloma Creek South',
        'Dougoufé',
        'Brembate',
        'Merrydale',
        'Gretz-Armainvilliers',
        'Buba',
        'Harewa',
        'Lohna',
        'Independence',
        'Closter',
        'Takiéta',
        'Kodaimangalam',
        'Yaragol',
        'Modachchūr',
        'Nerk’in Getashen',
        'Ergué-Gabéric',
        'East Leake',
        'Sakhua',
        'Nesārg',
        'Vairichettipālaiyam',
        'Nūlvi',
        'Arab',
        'Conewago',
        'Ad Darbāsīyah',
        'Staufenberg',
        'Sukand',
        'Troina',
        'Monte San Savino',
        'San Fernando',
        'Dachne',
        'Fürstenfeld',
        'Colindres',
        'Pattanam',
        'Iwaizumi',
        'Dunn',
        'Muzo',
        'Tādināda',
        'Tifra',
        'Pāta Uppāl',
        'West Long Branch',
        'Barton',
        'Ḩadībū',
        'Imsida',
        'Pochinok',
        'Pásztó',
        'Yamkanmardi',
        'Novoselitskoye',
        'Dubovskoye',
        'Wissen',
        'Siegsdorf',
        'Aqsū',
        'Milton',
        'Bad Endorf',
        'Mücheln',
        'Glenwood',
        'Lipki',
        'Privas',
        'Reddiyapatti',
        'Kolumalapalle',
        'Arizona City',
        'Karmaskaly',
        'Taftanāz',
        'Bacobampo',
        'Alfonso Castañeda',
        'Santiponce',
        'Zogno',
        'Bifeng',
        'Ağlasun',
        'Kandern',
        'Tsallagundla',
        'Puran Bigha',
        'Kandanāti',
        'Clusone',
        'Ortuella',
        'Talwandi Chaudhriān',
        'Nāngal Chaudhri',
        'Perryville',
        'Chak Thathi',
        'Tālakulam',
        'Guria',
        'Folsom',
        'Ban Charoen Mueang',
        'Meltham',
        'York',
        'Ban Wat Phrik',
        'Marcolândia',
        'Rottenburg an der Laaber',
        'Karuppūr',
        'Bartica',
        'Dattapāra',
        'Hernani',
        'Sulakyurt',
        'Mangala',
        'Bānki',
        'Poninguinim',
        'Bushkill',
        'Chamusca',
        'Worplesdon',
        'Montecito',
        'Keregodu',
        'Hongtuliang',
        'Khajuri',
        'Montague',
        'Sečovce',
        'Nonnweiler',
        'Penkridge',
        'Severance',
        'Malverne',
        'Ararica',
        'Nossa Senhora dos Remédios',
        'Kasāp',
        'Phopnār Kalān',
        'Southampton',
        'Möckmühl',
        'La Salvetat-Saint-Gilles',
        'Sallisaw',
        'Le Mont-sur-Lausanne',
        'Nijgaon Parānpur',
        'Kosiv',
        'Clayton le Moors',
        'Pokrovka',
        'Lewistown',
        'Lanco',
        'Nawānagar',
        'Ra’s al Ma‘arrah',
        'Qorovul',
        'Woltersdorf',
        'Hundested',
        'Laitila',
        'Cam',
        'Thāndewāla',
        'Khutha Baijnāth',
        'Kandanūr',
        'Medikunda',
        'San Isidro',
        'Pérenchies',
        'Andanappettai',
        'Delavan',
        'Olamzé',
        'Neuenbürg',
        'Kond Rūd',
        'Poniatowa',
        'Reichelsheim',
        'Möser',
        'Hanko',
        'Oakengates',
        'Schnaittach',
        'Perungulam',
        'Dodvad',
        'Silvārpatti',
        'Santa Margherita Ligure',
        'Wieruszów',
        'Hāthāpur',
        'Rāmpatti',
        'Evergreen',
        'Lycksele',
        'Uedem',
        'Thaon-les-Vosges',
        'Sutherlin',
        'Richland Hills',
        'Carbonita',
        'Sautron',
        'Bedwas',
        'Sāgarpur',
        'Darsur',
        'Whitehouse',
        'Hueyotlipan',
        'Takaharu',
        'Kuchai Kot',
        'Boshof',
        'West Auckland',
        'Chermen',
        'El Dovio',
        'Mandalapalle',
        'Pachrukhi',
        'Repala',
        'Tionk Essil',
        'Zhujiagua',
        'Liffré',
        'Pedda Penki',
        'Orşova',
        'Biloziria',
        'Nottampatti',
        'Altoona',
        'Old Forge',
        'Lieşti',
        'Carlton Colville',
        'Dala',
        'Hausjärvi',
        'Kalloní',
        'Campogalliano',
        'Rignano sull’Arno',
        'West Earl',
        'Dobanovci',
        'Staufen im Breisgau',
        'Saint-Vallier',
        'Nālikkalpatti',
        'Chinaur',
        'Munnelli',
        'Nalās',
        'Tarīchar Kalān',
        'Setana',
        'Saruu',
        'Rakai',
        'Biryusinsk',
        'Chapaev',
        'Basarabeasca',
        'Namsos',
        'Joniškis',
        'Orocué',
        'Zərdab',
        'As Sallūm',
        'Gramsh',
        'Būlaevo',
        'Jesenice',
        'Stans',
        'Gadžin Han',
        'Gunnedah',
        'Kaišiadorys',
        'Golubac',
        'Pazin',
        'Sorø',
        'Cowra',
        'Moengo',
        'Olovyannaya',
        'Slovenska Bistrica',
        'Rubirizi',
        'Ayr',
        'Pampa del Infierno',
        'Tweed Heads',
        'Naujoji Akmenė',
        'Shar',
        'Kočevje',
        'Koné',
        'Gleno',
        'Putina',
        'Paide',
        'Aguelhok',
        'Hammerfest',
        'Beočin',
        'Qusmuryn',
        'Osakarovka',
        'Dimitrovgrad',
        'Varėna',
        'Charters Towers',
        'Montpelier',
        'Gżira',
        'Kontcha',
        'Oldeani',
        'Nisporeni',
        'Sokobanja',
        'Ciudad Cortés',
        'Greymouth',
        'Katoomba',
        'Obluchye',
        'Amapá',
        'Sharbaqty',
        'Port Maria',
        'Alebtong',
        'San Julián',
        'Monaghan',
        'Auki',
        'Dilolo',
        'Sembabule',
        'Ch’osan-ŭp',
        'Bentiu',
        'Falmouth',
        'Ertis',
        'Maryborough',
        'Iqaluit',
        'Luba',
        'Kalabo',
        'Young',
        'Grosuplje',
        'Qazaly',
        'Bayghanīn',
        'Lascano',
        'Heyin',
        'Ludza',
        'Yeghegnadzor',
        'Yardımlı',
        'Mtskheta',
        'Guadalupe',
        'Kibale',
        'Jacareacanga',
        'Bairnsdale',
        'San Pablo Villa de Mitla',
        'Castillos',
        'Kemijärvi',
        'Kelmė',
        'Sen Monorom',
        'Gaoual',
        'Zhänibek',
        'Bački Petrovac',
        'Leova',
        'Leeton',
        'Coracora',
        'Kirkwall',
        'Goranboy',
        'Ādaži',
        'Nangan',
        'Aiquile',
        'Ravne na Koroškem',
        'Luân Châu',
        'Atherton',
        'Aračinovo',
        'Briceni',
        'Lerik',
        'Thames',
        'Puerto Baquerizo Moreno',
        'Bossembele',
        'Kičevo',
        'Mongomo',
        'Slovenj Gradec',
        'Tranqueras',
        'Teleneşti',
        'Bestöbe',
        'Obo',
        'Mobaye',
        'Tobyl',
        'Lapovo',
        'Ruyigi',
        'Dowa',
        'Novobërdë',
        'Donduşeni',
        'Debe',
        'Ştefan Vodă',
        'Zambezi',
        'Moree',
        'Skovorodino',
        'Diekirch',
        'In Guezzam',
        'Wick',
        'Thyolo',
        'Rabaul',
        'Ararat',
        'Oğuz',
        'Kapoeta',
        'Krāslava',
        'Kerikeri',
        'Novi Kneževac',
        'Kieta',
        'Aizkraukle',
        'Bongaree',
        'Nicoadala',
        'Librazhd',
        'Santa Venera',
        'Hola',
        'Līvāni',
        'Victoria',
        'Kiruhura',
        'Kiama',
        'Lerwick',
        'Zholymbet',
        'Borgo Maggiore',
        'Obiliq',
        'Brežice',
        'Ajdovščina',
        'Šalčininkai',
        'Forbes',
        'Nata',
        'Khandyga',
        'Gulbene',
        'Charagua',
        'Kishkeneköl',
        'Magugpo Poblacion',
        'Criuleni',
        'Limbaži',
        'Litija',
        'Madona',
        'Trindade',
        'Carnarvon',
        'Awjilah',
        'Seymour',
        'Makarov',
        'Port Augusta',
        'Mazoe',
        'Ros Comáin',
        'Kerema',
        'Northam',
        'Cliza',
        'Mae Hong Son',
        'Roma',
        'Bogatić',
        'Oficina María Elena',
        'Newman',
        'Ingeniero Guillermo N. Juárez',
        'Cooma',
        'Port Saint John’s',
        'Ndendé',
        'Zouar',
        'Deniliquin',
        'Pasvalys',
        'Melut',
        'Comandante Luis Piedra Buena',
        'Siteki',
        'Medveđa',
        'Sal Rei',
        'San Carlos',
        'Dalaba',
        'Yeppoon',
        'Verkhnevilyuysk',
        'Lorengau',
        'Derzhavīnsk',
        'Omaruru',
        'Vanrhynsdorp',
        'Alūksne',
        'Punakha',
        'Ingeniero Jacobacci',
        'Bir Anzarane',
        'Jakar',
        'Phalombe',
        'Queanbeyan',
        'Tumut',
        'Kavadarci',
        'Palikir',
        'Moss',
        'Ub',
        'Kupiškis',
        'Espargos',
        'Gizo',
        'Bella Vista',
        'Veintiocho de Noviembre',
        'Umba',
        'Mengeš',
        'Bač',
        'Junik',
        'Viqueque',
        'Yamba',
        'Kolonia',
        'Alausí',
        'Cəbrayıl',
        'Dinguiraye',
        'San Javier',
        'Sežana',
        'Funafuti',
        'Zagorje',
        'Chepes',
        'Lucea',
        'Maltahöhe',
        'Mitoma',
        'Gyangzê',
        'Schaan',
        'Glarus',
        'Tazovskiy',
        'Radovljica',
        'Veinticinco de Mayo',
        'Preiļi',
        'Luqa',
        'Përmet',
        'Zarasai',
        'Trakai',
        'Şoldăneşti',
        'Echternach',
        'Mundybash',
        'Kaitaia',
        'Rutana',
        'Berovo',
        'Idrija',
        'Širvintos',
        'Ranillug',
        'Lobamba',
        'Aiyomojok',
        'Molėtai',
        'Biloela',
        'Piggs Peak',
        'Appenzell',
        'Stratford',
        'Uncia',
        'Marigot',
        'Tiksi',
        'Xocavənd',
        'Vaduz',
        'Masunga',
        'Cacheu',
        'Balvi',
        'Nieuw Amsterdam',
        'Chonchi',
        'Stawell',
        'Hermanus',
        'Babək',
        'Sisimiut',
        'Muisne',
        'Vossevangen',
        'Okhotsk',
        'Fort-Shevchenko',
        'Mwatate',
        'Põlva',
        'Eenhana',
        'Byron Bay',
        'Mamushë',
        'Kazlų Rūda',
        'Namanga',
        'Narrabri',
        'Črnomelj',
        'General Conesa',
        'Petnjica',
        'San Antonio de los Cobres',
        'Mali',
        'Mali Iđoš',
        'Muramvya',
        'Tura',
        'Šakiai',
        'Goondiwindi',
        'Ouadda',
        'San Quintín',
        'Wiltz',
        'Thaba-Tseka',
        'Richmond',
        'Kratovo',
        'Kovačica',
        'Cospicua',
        'Saint-Pierre',
        'Cobram',
        'San Ramón',
        'Medvode',
        'Witu',
        'San Matías',
        'Rapla',
        'Skuodas',
        'Bajram Curri',
        'Bilibino',
        'Hohenau',
        'Napak',
        'Torghay',
        'Triesen',
        'Albina',
        'Otavi',
        'Tarrafal',
        'Thinadhoo',
        'Jõgeva',
        'Mayumba',
        'Kalangala',
        'Jinzhong',
        'Canillo',
        'Slovenske Konjice',
        'Danilovgrad',
        'Liquiçá',
        'Chernyshevskiy',
        'Karibib',
        'Villa del Rosario',
        'Smiltene',
        'Rogaška Slatina',
        'Roatán',
        'Ķekava',
        'Punta Gorda',
        'McMinns Lagoon',
        'Scone',
        'Palé',
        'Žalec',
        'Puerto Casado',
        'Singleton',
        'Qaşr al Farāfirah',
        'Ignalina',
        'Grevenmacher',
        'Samtse',
        'Igarka',
        'Gevgelija',
        'Wonthaggi',
        'Hrastnik',
        'Sémbé',
        'Lithgow',
        'Valdez',
        'Šentjur',
        'Ust’-Kamchatsk',
        'Bled',
        'Mitzic',
        'Vadsø',
        'Mékambo',
        'Xagħra',
        'Vrapčište',
        'Ordino',
        'Irig',
        'Bolama',
        'Albury',
        'Brownsweg',
        'Turukhansk',
        'Tuzi',
        'Carrick on Shannon',
        'Ponta do Sol',
        'Ilulissat',
        'Bagdarin',
        'Halba',
        'Għaxaq',
        'Fdérik',
        'Čoka',
        'Šilalė',
        'Svolvær',
        'Komatipoort',
        'Radoviš',
        'Klaksvík',
        'Westport',
        'Finnsnes',
        'Balzers',
        'Sangar',
        'Betanzos',
        'Bongandanga',
        'Khatanga',
        'Prevalje',
        'Perito Moreno',
        'Outapi',
        'Valka',
        'Opovo',
        'Otar',
        'Sevnica',
        'Kununurra',
        'Nadur',
        'Camargo',
        'Fuerte Olimpo',
        'Gobernador Gregores',
        'Bueng Kan',
        'Pakruojis',
        'Švenčionys',
        'Bururi',
        'Al Qaşr',
        'Eschen',
        'Victorica',
        'Şuşa',
        'Qıvraq',
        'Susuman',
        'Mauren',
        'Marsa',
        'Karasburg',
        'Samaipata',
        'Magdalena',
        'Saryshaghan',
        'Tepelenë',
        'Ingham',
        'Ilirska Bistrica',
        'Saint George’s',
        'Dehiba',
        'Nwoya',
        'Bekily',
        'Comandante Fontana',
        'Narrogin',
        'Batagay',
        'Black River',
        'Kuala Belait',
        'Victor Harbor',
        'I-n-Amguel',
        'Ruše',
        'La Palma',
        'Omsukchan',
        'Novyy Uoyan',
        'Manjimup',
        'Calheta de São Miguel',
        'Kruševo',
        'Naifaru',
        'Bensonville',
        'Berri',
        'Port Hedland',
        'Las Lajas',
        'Wabag',
        'Pevek',
        'Çorovodë',
        'San Marino',
        'Kalvarija',
        'Pietà',
        'Cerknica',
        'Remich',
        'El Maitén',
        'Avarua',
        'Manica',
        'Aliwal North',
        'Balzan',
        'Qobustan',
        'Robertsport',
        'Karmah an Nuzul',
        'Trebnje',
        'Trzin',
        'Oranjemund',
        'Bethanie',
        'Bîr Mogreïn',
        'Lazdijai',
        'Butalangu',
        'Neiafu',
        'Vitim',
        'P’ungsan',
        'Esperanza',
        'Plandište',
        'Sicasica',
        'Vergara',
        'Miklavž na Dravskem Polju',
        'Deçan',
        'Omuthiya',
        'Piran',
        'Ağdam',
        'Teseney',
        'Ersekë',
        'Ulaan-Uul',
        'Cherskiy',
        'Grand Turk',
        'Padilla',
        'Lavumisa',
        'Šempeter pri Gorici',
        'Massenya',
        'Palana',
        'Tržič',
        'Žiri',
        'Pembroke',
        'Makedonski Brod',
        'Katanning',
        'Imġarr',
        'Zyryanka',
        'Cankuzo',
        'De-Kastri',
        'Tessalit',
        'Ribnica',
        'Dingli',
        'Pukë',
        'São João dos Angolares',
        'Mojkovac',
        'Janjanbureh',
        'Lismore',
        'Villa Ygatimí',
        'Domagnano',
        'Merimbula',
        'Marsaxlokk',
        'Kirkenes',
        'Tolmin',
        'Ceduna',
        'Port Douglas',
        'Mongar',
        'Ligonha',
        'Paita',
        'Kirakira',
        'La Paloma',
        'Srednekolymsk',
        'Wallaroo',
        'Proserpine',
        'Uspallata',
        'Alibunar',
        'Cantemir',
        'Darregueira',
        'Kaberamaido',
        'Zhigansk',
        'Malishevë',
        'Trancas',
        'Bukachacha',
        'Ugol’nyye Kopi',
        'Lukulu',
        'Fish Town',
        'Clare',
        'Turangi',
        'Imqabba',
        'Krasnogorsk',
        'Xewkija',
        'Weipa',
        'Laško',
        'Pofadder',
        'Lenart v Slovenskih Goricah',
        'Smithton',
        'Demir Kapija',
        'The Valley',
        'Mezen',
        'Rietavas',
        'Ljutomer',
        'Juradó',
        'Domžale',
        'Metlika',
        'Rørvik',
        'Ankaran',
        'Brezovica',
        'Għajnsielem',
        'Teeli',
        'Sinnamary',
        'Mežica',
        'Evinayong',
        'Brandfort',
        'Ocniţa',
        'Kudahuvadhoo',
        'Saulkrasti',
        'Iklin',
        'Colonia',
        'Šenčur',
        'Golubovci',
        'Birštonas',
        'Dravograd',
        'Gornja Radgona',
        'Ainaro',
        'Lija',
        'Železniki',
        'Aasiaat',
        'Mopipi',
        'Ust’-Maya',
        'Porto Inglês',
        'Arroyos y Esteros',
        'Qaqortoq',
        'Tearce',
        'Trashigang',
        'Ulbroka',
        'Škofljica',
        'Abaí',
        'Taoudenni',
        'Kärdla',
        'Kalkara',
        'Tifariti',
        'Mahibadhoo',
        'San Lorenzo',
        'Lethem',
        'Gudja',
        'Saranpaul',
        'Al Jaghbūb',
        'Żebbuġ',
        'Lendava',
        'Bogdanci',
        'Rogašovci',
        'Šoštanj',
        'Zreče',
        'Sowa Town',
        'Bopolu',
        'Žitište',
        'Hokitika',
        'Nautla',
        'Tom Price',
        'Radlje ob Dravi',
        'Bordertown',
        'Villalonga',
        'Viligili',
        'Mangbwalu',
        'Groningen',
        'São Domingos',
        'Buala',
        'Entre Ríos',
        'Río Mayo',
        'Cochrane',
        'Senglea',
        'Għargħur',
        'Qrendi',
        'Kerewan',
        'Hlatikulu',
        'Saint-Georges',
        'Longreach',
        'Trashi Yangtse',
        'Barclayville',
        'Kolašin',
        'Vila Velha',
        'Urubamba',
        'Trongsa',
        'Rače',
        'Borovnica',
        'Eydhafushi',
        'Triesenberg',
        'Vittoriosa',
        'Rodeo',
        'Ísafjörður',
        'Donegal',
        'Sauðárkrókur',
        'Tofol',
        'Cestos City',
        'Imtarfa',
        'Mkokotoni',
        'Chumbicha',
        'Mahdia',
        'Kllokot',
        'Rosoman',
        'Charleville',
        'Fiorentino',
        'Provideniya',
        'Baltasar Brum',
        'Cloncurry',
        'Exmouth',
        'Chokurdakh',
        'Nauta',
        'Mariscal José Félix Estigarribia',
        'Capitol Hill',
        'Severo-Kuril’sk',
        'Brokopondo',
        'Vojnik',
        'Aiguá',
        'Tarabuco',
        'Quime',
        'Demir Hisar',
        'Beltinci',
        'Al ‘Alamayn',
        'Höfn',
        'Jaqué',
        'Nida',
        'Merredin',
        'El Dorado',
        'Karungu',
        'Vevčani',
        'Polzela',
        'Bloemhof',
        'Sohano',
        'Zhemgang',
        'Boffa',
        'Egilsstaðir',
        'Coroico',
        'Saskylakh',
        'Krško',
        'Gustavia',
        'Ypejhú',
        'Ruggell',
        'Toltén',
        'Muta',
        'Sveta Ana',
        'Qala',
        'Lehututu',
        'Şahbuz',
        'Štore',
        'Te Anau',
        'Egvekinot',
        'Ig',
        'Marādah',
        'Roura',
        'Onverwacht',
        'Gradsko',
        'Desaguadero',
        'Sorata',
        'Mwenga',
        'El Manteco',
        'Stanley',
        'Kaikoura',
        'Floriana',
        'Ivančna Gorica',
        'José Batlle y Ordóñez',
        'Črna na Koroškem',
        'Puerto Villamil',
        'Kirkop',
        'Laçın',
        'Radenci',
        'Vianden',
        'Totness',
        'Cidade Velha',
        'Acquaviva',
        'Pozo Colorado',
        'Baures',
        'Safi',
        'Apolo',
        'Sannat',
        'Spodnje Hoče',
        'Funadhoo',
        'Vipava',
        'Same',
        'Esperance',
        'Pivka',
        'Omboué',
        'Mozirje',
        'Manadhoo',
        'Evensk',
        'Pukekohe East',
        'Waitakere',
        'Waitangi',
        'Semič',
        'Ambrolauri',
        'Damongo',
        'Konza',
        'Altata',
        'Heydərabad',
        'Djibloho',
        'Sofifi',
        'Afega',
        'Radeče',
        'Valandovo',
        'Lovrenc na Pohorju',
        'Capellen',
        'Tasiilaq',
        'Scottsdale',
        'Ormož',
        'Borgarnes',
        'Katwe',
        'Straža',
        'Kerċem',
        'Žabljak',
        'Abunã',
        'Amudat',
        'Mount Barker',
        'Philipsburg',
        'Maitland',
        'Taedong',
        'Krivogaštani',
        'Mislinja',
        'Beringovskiy',
        'Balakən',
        'Ta’ Xbiex',
        'Novyy Port',
        'Naklo',
        'Queenstown',
        'Bohinjska Bistrica',
        'Nokaneng',
        'Šmarje',
        'Longyearbyen',
        'Nasir',
        'Partesh',
        'Divača',
        'Xgħajra',
        'Cerklje na Gorenjskem',
        'Vodice',
        'Varakļāni',
        'Spodnji Duplek',
        'Pehčevo',
        'Ropaži',
        'Gusinje',
        'Tabor',
        'Gamprin',
        'Pārūn',
        'Puerto Williams',
        'Cuevo',
        'Capitán Pablo Lagerenza',
        'Odranci',
        'Lifford',
        'Prebold',
        'Flying Fish Cove',
        'Zgornja Kungota',
        'Xızı',
        'Bovec',
        'Plasnica',
        'Chiradzulu',
        'Alto Río Senguer',
        'Sierra Colorada',
        'Rogatec',
        'Għarb',
        'Iracoubo',
        'Bourke',
        'Zrnovci',
        'Oktyabr’skiy',
        'Kipili',
        'Ungoofaaru',
        'Vuzenica',
        'Ust’-Kuyga',
        'Eldikan',
        'Tumby Bay',
        'Turnišče',
        'Miren',
        'Chibemba',
        'Alexander Bay',
        'Halls Creek',
        'Tajarhī',
        'Artëmovsk',
        'Nova Crnja',
        'Lokwabe',
        'Clervaux',
        'Dragomer',
        'Munxar',
        'Kranjska Gora',
        'Šentjernej',
        'Peterborough',
        'Cerkno',
        'Oplotnica',
        'Machinga',
        'Port Denison',
        'Tsau',
        'Uummannaq',
        'Xocalı',
        'Šmartno',
        'Penola',
        'Mirna',
        'Kazachye',
        'Nakhodka',
        'Selnica ob Dravi',
        'Kingston South East',
        'Nyimba',
        'Veymandoo',
        'Lubutu',
        'Wagin',
        'Fulacunda',
        'Paamiut',
        'Greytown',
        'Bala Cangamba',
        'Tarutung',
        'Santo António',
        'Novaci',
        'Bosilovo',
        'Safotu',
        'Kalbarri',
        'Villa Rumipal',
        'Dornava',
        'Mogila',
        'Kidričevo',
        'Katherine',
        'Mabaruma',
        'Tulagi',
        'Novo Selo',
        'Barcaldine',
        'Villa Martín Colchak',
        'Ubombo',
        'Regedor Quissico',
        'Çeleken',
        'Isangel',
        'Buluko',
        'Leulumoega',
        'Faetano',
        'Horjul',
        'Ağdam',
        'Črenšovci',
        'Daga',
        'Los Blancos',
        'Kanal',
        'Asau',
        'Gorenja Vas',
        'Lavrentiya',
        'Puerto Acosta',
        'Verkhoyansk',
        'Mirbāţ',
        'Ñacunday',
        'Poljčane',
        'Dikson',
        'Uad Damran',
        'Plužine',
        'Ljubno',
        'Susques',
        'Upernavik',
        'Schellenberg',
        'Chumikan',
        'Innisfail',
        'Klyuchi',
        'Kobarid',
        'Qasigiannguit',
        'Benedikt',
        'Mazatán',
        'Hagåtña',
        'Fulin',
        'Andrijevica',
        'Oranjestad',
        'Mata-Utu',
        'Ouyen',
        'Gornji Grad',
        'Mirna Peč',
        'Hughenden',
        'Haya',
        'Cowell',
        'Yélimané',
        'Središče ob Dravi',
        'General Eugenio A. Garay',
        'Montegiardino',
        'Streaky Bay',
        'Moravče',
        'Príncipe da Beira',
        'Lufilufi',
        'Dobrovnik',
        'Daraj',
        'Fontana',
        'Dobrova',
        'Ayan',
        'Konče',
        'Shamva',
        'Laverton',
        'Veržej',
        'Komenda',
        'Dolenjske Toplice',
        'Nazarje',
        'Rostuša',
        'Hoskins',
        'Velika Polana',
        'Luanza',
        'Meningie',
        'Vitanje',
        'Gorišnica',
        'Winton',
        'Hamilton',
        'Yulara',
        'Gingin',
        'Jegunovce',
        'Ozurgeti',
        'Pesnica',
        'Sodražica',
        'Godhavn',
        'Stari Trg',
        'Preddvor',
        'Vatican City',
        'Charaña',
        'Onslow',
        'Zgornja Hajdina',
        'Bicheno',
        'Omolon',
        'Vailoa',
        'Starše',
        'Muhembo',
        'Sveta Trojica v Slovenskih Goricah',
        'Moravske-Toplice',
        'San Lawrenz',
        'Yaren',
        'Yerëma',
        'Wyndham',
        'Rankovce',
        'Comallo',
        'Velike Lašče',
        'Jamestown',
        'Hvalba',
        'Zhilinda',
        'Satadougou',
        'Lakatoro',
        'Mokronog',
        'Roebourne',
        'Manily',
        'Zhaltyr',
        'Sopište',
        'Kostanjevica na Krki',
        'Pannawonica',
        'Linxi',
        'Ituni',
        'Meekatharra',
        'Obleševo',
        'Qubadlı',
        'Leonora',
        'Massangena',
        'Gawler',
        'Qaanaaq',
        'Komen',
        'Šmartno',
        'Kozje',
        'Puconci',
        'Vasilevo',
        'Calatrava',
        'Bangar',
        'Grad',
        'Uelen',
        'Nkurenkuru',
        'Tigoa',
        'Villa O’Higgins',
        'Kimba',
        'Majšperk',
        'Dibaya',
        'Panda',
        'Gastre',
        'Saleaula',
        'Kəlbəcər',
        'Alofi',
        'Quilpie',
        'Videm',
        'Podčetrtek',
        'Karbinci',
        'Sabaya',
        'Mikhalkino',
        'Oatlands',
        'Zgornje Jezersko',
        'Chiramba',
        'Norseman',
        'Lata',
        'Llica',
        'Mereeg',
        'Telsen',
        'Apače',
        'Kobilje',
        'Wilcannia',
        'Dobrna',
        'Zgornje Gorje',
        'Calenga',
        'Southern Cross',
        'Lozovo',
        'Rečica',
        'Caluula',
        'Tournavista',
        'Felidhoo',
        'Tmassah',
        'Puerto Pinasco',
        'Oymyakon',
        'Tchitado',
        'Yakossi',
        'Križevci',
        'Markovci',
        'Staro Nagoričane',
        'Šmarješke Toplice',
        'Karumba',
        'Planken',
        'Kempsey',
        'Mount Magnet',
        'Vreed-en-Hoop',
        'The Bottom',
        'Richmond',
        'Kullorsuaq',
        'Cirkulane',
        'Videm pri Ptuju',
        'Woomera',
        'Brvenica',
        'Dhuusamarreeb',
        'Skopun',
        'Morawa',
        'Lukovica',
        'Theodore',
        'Crna Trava',
        'Kuzma',
        'Eidsvold',
        'Għasri',
        'Buabidi',
        'Montes Claros',
        'Cankova',
        'Hvannasund',
        'Tsavo',
        'Sherlovaya Gora',
        'Gornji Petrovci',
        'Tišina',
        'Ribnica',
        'Luče',
        'Nizhneyansk',
        'Tandil',
        'Espungabera',
        'Šalovci',
        'Brades',
        'Juršinci',
        'Podlehnik',
        'Braslovče',
        'Toconao',
        'Trnovska Vas',
        'Šavnik',
        'Rinconada',
        'Jurovski Dol',
        'Three Springs',
        'Centar Župa',
        'Hrib-Loški Potok',
        'Ravensthorpe',
        'Scoresbysund',
        'Kingston',
        'Vitomarci',
        'Burubaytal',
        'Dobrovo',
        'Leava',
        'Pine Creek',
        'Keflavík',
        'Šentrupert',
        'Basse-Terre',
        'Umm al ‘Abīd',
        'Dolneni',
        'Araouane',
        'Halfmoon Bay',
        'Bugrino',
        'Shoyna',
        'Buur Gaabo',
        'Podvelka',
        'Put’ Lenina',
        'Cazombo',
        'Belčišta',
        'Enurmino',
        'Porkeri',
        'Nova Vas',
        'Yaupi',
        'Imdina',
        'Ikela',
        'Amderma',
        'Hodoš',
        'Dol',
        'Čučer-Sandevo',
        'Zelenikovo',
        'Melekeok',
        'Ngerulmud',
        'Andamooka',
        'Tomaž pri Ormožu',
        'Tasiusaq',
        'Adelaide River',
        'Kulusuk',
        'Burketown',
        'Bistrica ob Sotli',
        'Škocjan',
        'Kanyato',
        'Amau',
        'Kairaki',
        'Georgetown',
        'Makole',
        'Boulia',
        'Sveti Jurij',
        'Solčava',
        'Carnarvon',
        'Thargomindah',
        'Destrnik',
        'Kraulshavn',
        'Tamworth',
        'Lusanga',
        'Hurdiyo',
        'Port Pirie',
        'Korf',
        'Androka',
        'Cerkvenjak',
        'Ivanhoe',
        'Al Qunayţirah',
        'Camooweal',
        'Bafwasende',
        'Progress',
        'Razkrižje',
        'Buton',
        'Bifoun',
        'Kangersuatsiaq',
        'Narsarsuaq',
        'Bedourie',
        'Petrovec',
        'Mount Isa',
        'Fort Wellington',
        'Dobje',
        'Punta Prieta',
        'Il’pyrskiy',
        'Birdsville',
        'Star Dojran',
        'Želino',
        'Windorah',
        'Al ‘Uqaylah',
        'Lemsid',
        'Mukhomornoye',
        'Vorontsovo',
        'Grytviken',
        'Füzuli',
        'Venado Tuerto',
        'Fámjin',
        'Osilnica',
        'Piso Firme',
        'Studeničani',
        'Pagėgiai',
        'Savissivik',
        'Adamstown',
        'Samamea',
        'Cauquenes',
        'Rocafuerte',
        'Bogovinje',
        'Kovda',
        'Cuya',
        'Vransko',
        'Zillah',
        'Chegga',
        'Djado',
        'Gaigirgordub',
        'Andoas',
        'Puca Urco',
        'Soldado Bartra',
        'Güeppí',
        'Matochkin Shar',
        'Siglan',
        'Omchak',
        'Shalaurova',
        'Khorgo',
        'Tiyerbes',
        'Peregrebnoye',
        'Komsa',
        'Gyda',
        'Khakhar',
        'Menkerya',
        'Ust’-Nyukzha',
        'Zvëzdnyy',
        'Pakhachi',
        'Indiga',
        'Starorybnoye',
        'Laryak',
        'Ulkan',
        'Strelka',
        'Chagda',
        'Sagastyr',
        'Zemlya Bunge',
        'Trofimovsk',
        'Tunguskhaya',
        'Agapa',
        'Podkamennaya Tunguska',
        'Tukchi',
        'Varnek',
        'Numto',
        'Ust’-Olenëk',
        'Bol’sheretsk',
        'Olenëk',
        'Utkholok',
        'Yessey',
        'Karamken',
        'Kostel',
        'Puerto Heath',
        'Lagunas',
        'Barnīs',
        'Gamba',
        'Nord',
        'Timmiarmiut',
        'Ambarchik',
        'Kingoonya',
        'Sabhā',
        '‘Amrān',
        'Al Jabīn',
        'Nelspruit',
        'Lupane',
        'Anouvông',
        'Xékong',
        'Phôn-Hông',
        'Qacha’s Nek',
        'Mersch',
        'Redange-sur-Attert',
        'Idrī',
        'Cocieri',
        'Lipkovo',
        'Ilinden',
        'Resen',
        'Makedonska Kamenica',
        'Dalandzadgad',
        'Tevragh Zeina',
        'Plymouth',
        'Santa Luċija',
        'Rasdhoo',
        'Muli',
        'Dhihdhoo',
        'Fonadhoo',
        'Nilandhoo',
        'Thulusdhoo',
        'Balaka',
        'Neno',
        'Chikwawa',
        'Usakos',
        'Wé',
        'Abakaliki',
        'Yenagoa',
        'Isemi-Ile',
        'Şūr',
        'Haymā’',
        'Şuḩār',
        'Unión Chocó',
        'Sieyik',
        'Kurumul',
        'Buka',
        'Pili',
        'Tabuk',
        'San Jose',
        'Santa Cruz',
        'Koronadal',
        'Az̧ Z̧a‘āyin',
        'Umm Şalāl ‘Alī',
        'Madīnat ash Shamāl',
        'Bosilegrad',
        'Žagubica',
        'Požega',
        'Doljevac',
        'Boljevac',
        'Ljubovija',
        'Babušnica',
        'Preševo',
        'Ljig',
        'Mali Zvornik',
        'Priboj',
        'Bojnik',
        'Koceljeva',
        'Žabari',
        'Trgovište',
        'Nordvik',
        'Logashkino',
        'Taro',
        'Rabak',
        'El Fula',
        'Edinburgh of the Seven Seas',
        'Georgetown',
        'Žetale',
        'Šentilj',
        'Žužemberk',
        'Zavrč',
        'Chiesanuova',
        'Sédhiou',
        'Ceerigaabo',
        'Laascaanood',
        'Boorama',
        'Nhlangano',
        'Pala',
        'Bardaï',
        'Kara',
        'Ban Huai Hin',
        'Lospalos',
        'Aileu',
        'Pante Macassar',
        'Suai',
        'Aranguez',
        'Banqiao',
        'Mahonda',
        'Vwawa',
        'Koani',
        'Namutumba',
        'Maracha',
        'Namayingo',
        'Luuka Town',
        'Kasanda',
        'Kinoni',
        'Busesa',
        'Bulambuli',
        'Ntoroko',
        'Otuke',
        'Bupoto',
        'Agago',
        'Kitamilo',
        'Nsiika',
        'Kalaki',
        'Kasaali',
        'Nakapiripirit',
        'Pader',
        'Kakumiro',
        'Mparo',
        'Lamwo',
        'Kyankwanzi',
        'Ntara',
        'Bukwo',
        'Butebo',
        'Binyin',
        'Palenga',
        'Kibingo',
        'Kole',
        'Nabilatuk',
        'Rubanda',
        'Kalungu',
        'Kon Tum',
        'Đà Nẵng',
        'Sola',
        'Saratamata',
        'Safotulafai',
        'Mulifanua',
        'Satupa‘itea',
        'Sharan',
        'Nīlī',
        'Dəvəçi',
        'Şərur',
        'Qəbələ',
        'Isale',
        'Dogbo',
        'Tutong',
        'San Rafael',
        'Sarpang',
        'Pemagatshel',
        'Tsimasham',
        'Gasa',
        'Haa',
        'Lhuentse',
        'Tsirang',
        'Loango',
        'Bangolo',
        'Chuquicamata',
        'Panying',
        'Chengde',
        'Nanyangcun',
        'Huinan',
        'Picos',
        'João Teves',
        'Igreja',
        'Ribeira Brava',
        'Nova Sintra',
        'Pombas',
        'Cova Figueira',
        'Erfurt',
        'Sandur',
        'Fuglafjørður',
        'Hov',
        'Vágur',
        'Saltangará',
        'Kvívík',
        'Sumba',
        'Viðareiði',
        'Norðragøta',
        'Toftir',
        'Kirkja',
        'Eiði',
        'Sandavágur',
        'Skúvoy',
        'Skálavík',
        'Sørvágur',
        'Vestmanna',
        'Strendur',
        'Tvøroyri',
        'Húsavík',
        'Kunoy',
        'Oyrarbakki',
        'Goaso',
        'Dambai',
        'Sefwi Wiawso',
        'Kanifing',
        'King Edward Point',
        'Tanjung Selor',
        'Trim',
        'Jaitpura',
        'Navsāri',
        'Tonk',
        'Hubli',
        'Sārī',
        'Īlām',
        'Nyamira',
        'Siaya',
        'Murang’a',
        'Ol Kalou',
        'Sotik Post',
        'Kapenguria',
        'Kabarnet',
        'Migori',
        'Pailin',
        'Ta Khmau',
        'Sariwŏn-si',
        'Munha-dong',
        'Sil-li',
        'Muan',
        'Hongseong',
        'Charlotte Amalie'
      ])
    ]
  }
]
export const locations = [
  'US > States',
  'Alabama, US',
  'Alaska, US',
  'Arizona, US',
  'Arkansas, US',
  'California, US',
  'Colorado, US',
  'Connecticut,',
  'Delaware, US',
  'Florida, US',
  'Georgia, US',
  'Hawaii, US',
  'Idaho, US',
  'Illinois, US',
  'Indiana, US',
  'lowa, US',
  'Kansas, US',
  'Kentucky, US',
  'Louisiana, US',
  'Maine, US',
  'Maryland, US',
  'Massachusetts, US',
  'Michigan, US',
  'Minnesota, US',
  'Mississippi, US',
  'Missouri, US',
  'Montana, US',
  'Nebraska, US',
  'Nevada, US',
  'New Hampshire, US',
  'New Jersey, US',
  'New Mexico, US',
  'New York, US',
  'North Carolina, US',
  'North Dakota, US',
  'Ohio, US',
  'Oklahoma, US',
  'Oregon, US',
  'Pennsylvania, US',
  'Rhode Island, US',
  'South Carolina, US',
  'South Dakota, US',
  'Tennessee, US',
  'Texas, US',
  'Utah, US',
  'Vermont, US',
  'Virginia, US',
  'Washington, US',
  'West Virginia, US',
  'Wisconsin, US',
  'Wyoming, US',
  'District of Columbia',
  'American Samoa',
  'Canal Zone',
  'Guam',
  'Puerto Rico',
  'Virgin Islands',
  'US Metro Areas (217)',
  'AK > Anchorage Area',
  'AK > Fairbanks Area',
  'AL > Birmingham Area',
  'AL> Anniston Area',
  'AL > Dothan Area',
  'AL > Huntsville Area',
  'AL > Mobile Area',
  'AL > Montgomery Area',
  'AL > Tuscaloosa Area',
  'AR > Fort Smith Area',
  'AR > Jonesboro Area',
  'AZ > Phoenix Area',
  'CA > Eureka Area',
  'CA > Fresno Area',
  'CA > Irvine Area',
  'AR > Little Rock Area',
  'AZ > Tucson Area',
  'AZ > Yuma Area',
  'CA > Bakersfield Area',
  'CA > Chico Area',
  'CA > Eureka Area',
  'CA > Fresno Area',
  'CA> Los Angeles Area',
  'CA > Monterey Bay Area',
  'CA > Irvine Area',
  'CA> Palm Springs Area',
  'CA > Sacramento Area',
  'CA> San Diego Area',
  'CA > San Francisco Area',
  'CA > San Jose Area',
  'CA > Santa Barbara Area',
  'CO > Denver Area',
  'FL> Fort Myers Area',
  'CO Colorado Springs Area',
  'CO > Grand Junction Area',
  'CT > Hartford Area',
  'DC > Washington Area',
  'FL > Gainesville Area',
  'FL > Jacksonville Area',
  'FL Miami Area',
  'FL> Orlando Area',
  'FL> Panama City Area',
  'FL> Tallahassee Area',
  'FL> Tampa Bay Area',
  'FL> West Palm Beach Area',
  'GA> Albany Area',
  'GA > Atlanta Area',
  'GA> Augusta Area',
  'GA > Columbus Area',
  'GA> Macon Area',
  'GA > Savannah Area',
  'HI > Honolulu Area',
  'IA > Cedar Rapids Area',
  'IA > Davenport Area',
  'IA > Des Moines Area',
  'IA > Ottumwa Area',
  'IA > Sioux City Area',
  'ID > Boise City Area',
  'ID > Idaho Falls Area',
  'ID> Twin Falls Area',
  'IL > Chicago Area',
  'IL > Peoria Area',
  'IL> Quincy Area',
  'IN > Lafayette Area',
  'KS > Topeka Area',
  'IL > Rockford Area',
  'IL > Springfield Area',
  'IN > Evansville Area',
  'IN > Fort Wayne Area',
  'IN > Indianapolis Area',
  'IN > South Bend Area',
  'IN > Terre Haute Area',
  'KS > Wichita Area',
  'KY > Bowling Green Area',
  'KY > Lexington Area',
  'KY > Louisville Area',
  'KY > Paducah Area',
  'LA > Alexandria Area',
  'LA > Baton Rouge Area',
  'LA > Lafayette Area',
  'LA > Lake Charles Area',
  'LA > Monroe Area',
  'LA > New Orleans Area',
  'LA > Shreveport Area',
  'MA > Boston Area',
  'MD > Baltimore Area',
  'ME > Presquelsle Area',
  'MA > Springfield Area',
  'MD > Salisbury Area',
  'ME > Bangor Area',
  'ME > Portland Area',
  'MI > Alpena Area',
  'MI > Detroit Area',
  'MI > Flint Area',
  'MI > Grand Rapids Area',
  'MI > Lansing Area',
  'MI> Marquette Area',
  'MI > Traverse City Area',
  'MN > Duluth Area',
  'MN > Mankato Area',
  'MS > Columbus Area',
  'MN > Minneapolis Area',
  'MN > Rochester Area',
  'MO > Jefferson City Area',
  'MO > Joplin Area',
  'MO> Kansas City Area',
  'MO > Saint Joseph Area',
  'MO > Saint Louis Area',
  'MO > Springfield Area',
  'MS > Biloxi Area',
  'MS > Greenville Area',
  'MS > Hattiesburg Area',
  'MS > Jackson Area',
  'MS > Meridian Area',
  'MT > Billings Area',
  'MT > Butte Area',
  'MT > Glendive Area',
  'MT > Great Falls Area',
  'MT > Helena Area',
  'MT > Missoula Area',
  'NC > Charlotte Area',
  'NC > Greenville Area',
  'NC > Raleigh Area',
  'NC > Wilmington Area',
  'NC > Winston Salem Area',
  'ND > Bismarck Area',
  'ND > Fargo Area',
  'NE > Lincoln Area',
  'NE> North Platte Area',
  'NE > Omaha Area',
  'NM > Albuquerque Area',
  'NV > Las Vegas Area',
  'NV > Reno Area',
  'NY > Albany Area',
  'NY > Binghamton Area',
  'NY > Buffalo Area',
  'NY > Elmira Area',
  'NY > New York Area',
  'NY > Rochester Area',
  'NY > Syracuse Area',
  'NY > Utica Area',
  'NY > Watertown Area',
  'OH > Cincinnati Area',
  'OH > Cleveland Area',
  'OH > Columbus Area',
  'OH > Dayton Area',
  'OH > Lima Area',
  'OH > Toledo Area',
  'OH > Wheeling Area',
  'OH > Youngstown Area',
  'OH > Zanesville Area',
  'OK > Ardmore Area',
  'OK Oklahoma City Area',
  'OK > Tulsa Area',
  'OR > Eugene Area',
  'PA > Erie Area',
  'PA > Johnstown Area',
  'PA > Pittsburgh Area',
  'PA > Wilkes Barre Area',
  'PR Mayaguez Area',
  'PR > Ponce Area',
  'RI > Providence Area',
  'SC > Columbia Area',
  'SC> Florence Area',
  'OR > Bend Area',
  'OR > Medford Area',
  'OR > Portland Area',
  'PA> Harrisburg Area',
  'PA > Philadelphia Area',
  'PR > Aguadilla Area',
  'PR > San Juan Area',
  'SC > Charleston Area',
  'SC> Greenville Area',
  'SD > Rapid City Area',
  'SD > Sioux Falls Area',
  'TN> Chattanooga Area',
  'TN > Jackson Area',
  'TN > Knoxville Area',
  'TN > Memphis Area',
  'TN > Nashville Area',
  'TN> Tri Cities Area',
  'TX > Abilene Area',
  'TX > Amarillo Area',
  'TX > Austin Area',
  'TX > Beaumont Area',
  'TX > Bryan Area',
  'TX > Corpus Christi Area',
  'TX > Dallas Area',
  'TX > El Paso Area',
  'TX> Harlingen Area',
  'TX > Houston Area',
  'TX > Laredo Area',
  'TX > Lubbock Area',
  'TX > Odessa Area',
  'TX> San Antonio Area',
  'TX > Tyler Area',
  'TX > Wichita Falls Area',
  'TX San Angelo Area',
  'TX > Victoria Area',
  'VA > Hampton Roads Area',
  'VA > Roanoke Area',
  'UT > Salt Lake City Area',
  'VA > Charlottesville Area',
  'VA > Harrisonburg Area',
  'VA > Richmond Area',
  'VT > Burlington Area',
  'WA > Seattle Area',
  'WA > Spokane Area',
  'WA > Tri Cities Area',
  'WI> Green Bay Area',
  'WI > La Crosse Area',
  'WI > Madison Area',
  'WI > Milwaukee Area',
  'WI > Wausau Area',
  'WV > Bluefield Area',
  'WV > Charleston Area',
  'WV > Clarksburg Area',
  'WV > Parkersburg Area',
  'WY > Casper Area',
  'WY > Cheyenne Area',
  'CAN> Provinces (13)',
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Yukon',
  'CAN > Metro Areas (34)',
  'AB > Calgary Area',
  'AB Edmonton Area',
  'BC Abbotsford Area',
  'BC > Kelowna Area',
  'BC > Victoria Area',
  'MB Winnipeg Area',
  'NB> Moncton Area',
  'NB> St. John Area',
  'NL> St. John Area',
  'NS > Halifax Area',
  'ON > Barrie Area',
  'ON > London Area',
  'ON > Ottawa Area',
  'BC > Vancouver Area',
  'NT>Yellowknife Area',
  'ON > Brantford Area',
  'ON > Guelph Area',
  'ON> Hamilton Area',
  'ON > Kingston Area',
  'ON > Kitchener Area',
  'ON > London Area',
  'ON > Ottawa Area',
  'ON> Peterborough Area',
  'ON> St. Catherines Area',
  'ON > Sudbury Area',
  'ON > Thunder Bay Area',
  'ON > Toronto Area',
  'ON > Windsor Area',
  'PEI > Charlottetown Area',
  'QC > Chicoutimi Area',
  'QC > Montreal Area',
  'QC > Quebec Area',
  'QC > Sherbrooke Area',
  'QC > Trois Rivieres Area',
  'SK > Regina Area',
  'SK> Saskatoon Area',
  'Africa (52)',
  'Algeria',
  'Angola',
  'Benin',
  'Botswana',
  'Burkina Faso',
  'Burundi',
  'Cameroon',
  'Cape Verde',
  'Central African Republic',
  'Chad',
  'Comoros',
  'Congo (DRC)',
  'Congo (Republic)',
  'Djibouti',
  'Egypt',
  'Equatorial Guinea',
  'Eritrea',
  'Ethiopia',
  'Gabon',
  'Gambia',
  'Ghana',
  'Guinea',
  'Guinea-Bissau',
  'Kenya',
  'Lesotho',
  'Liberia',
  'Libya',
  'Madagascar',
  'Malawi',
  'Mali',
  'Mauritania',
  'Mauritius',
  'Morocco',
  'Mozambique',
  'Namibia',
  'Niger',
  'Nigeria',
  'Rwanda',
  'Sao Tome and Principe',
  'Senegal',
  'Seychelles',
  'Sierra Leone',
  'Somalia',
  'South Africa',
  'Sudan',
  'Swaziland',
  'Tanzania',
  'Togo',
  'Tunisia',
  'Uganda',
  'Zambia',
  'Zimbabwe',
  'Asia (45)',
  'Afghanistan',
  'Armenia',
  'Azerbaijan',
  'Bahrain',
  'Bangladesh',
  'Bhutan',
  'Brunei Darussalam',
  'Cambodia',
  'China',
  'Georgia (C)',
  'India',
  'Indonesia',
  'Iran',
  'Israel',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Lebanon',
  'Malaysia',
  'Maldives',
  'Mongolia',
  'Myanmar',
  'Nepal',
  'North Korea',
  'Oman',
  'Pakistan',
  'Philippines',
  'Qatar',
  'Saudi Arabia',
  'Singapore',
  'South Korea',
  'Sri Lanka',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Laos',
  'Lebanon',
  'Malaysia',
  'Maldives',
  'Mongolia',
  'Myanmar',
  'Nepal',
  'North Korea',
  'Oman',
  'Pakistan',
  'Philippines',
  'Qatar',
  'Saudi Arabia',
  'Singapore',
  'South Korea',
  'Sri Lanka',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Europe (42)',
  'Albania',
  'Andorra',
  'Austria',
  'Belarus',
  'Belgium',
  'Bosnia Herzegovina',
  'Bulgaria',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'Hungary',
  'Iceland',
  'Ireland',
  'Italy',
  'Latvia',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macedonia (FYROM)',
  'Malta',
  'Moldova',
  'Monaco',
  'Netherlands',
  'Norway',
  'Poland',
  'Portugal',
  'Romania',
  'Russia',
  'San Marino',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
  'Switzerland',
  'Ukraine',
  'United Kingdom',
  'North America (22)',
  'Antigua and Barbuda',
  'Bahamas',
  'Barbados',
  'Belize',
  'Canada',
  'Costa Rica',
  'Cuba',
  'Dominica',
  'Dominican Republic',
  'El Salvador',
  'Grenada',
  'Guatemala',
  'Haiti',
  'Honduras',
  'Jamaica',
  'Mexico',
  'Nicaragua',
  'Panama',
  'Saint Kitts and Nevis',
  'Saint Vincent and the Grenadines',
  'Trinidad and Tobago',
  'United States',
  'Oceania (11)',
  'Australia',
  'Cook Islands',
  'Fiji',
  'Kiribati',
  'New Zealand',
  'Papua New Guinea',
  'Samoa',
  'Solomon Islands',
  'Timor-Leste',
  'Tonga',
  'Vanuatu',
  'South America (12)',
  'Australia',
  'Cook Islands',
  'Fiji',
  'Kiribati',
  'New Zealand',
  'Papua New Guinea',
  'Samoa',
  'Solomon Islands',
  'Timor-Leste',
  'Tonga',
  'Vanuatu'
]

export const Department = [
  'Agriculture',
  'Dairy',
  'Fishery',
  'Farming',
  'Food Production',
  'Mining & Metals',
  'Oil & Energy',
  'Paper & Forest Products',
  'Ranching',
  'Animation',
  'Arts & Crafts',
  'Broadcast Media',
  'Entertainment',
  'Fine Art',
  'Gambling & Casinos',
  'Graphic Design',
  'Motion Pictures & Film',
  'Museums & Institutions',
  'Music',
  'Performing Arts',
  'Photography',
  'Publishing',
  'Recreational Facilities & Services',
  'Sports',
  'Sporting Goods',
  'Accounting',
  'Banking',
  'Business Supplies & Equipment',
  'Capital Markets',
  'Financial Services',
  'Fund-Raising',
  'Insurance',
  'Investment Banking',
  'Investment Management',
  'Management Consulting',
  'Professional Training & Coaching',
  'Venture Capital & Private Equity',
  'Broadcast Media',
  'Newspapers',
  'Online Media',
  'Publishing',
  'Architecture & Planning',
  'Building Materials',
  'Civil Engineering',
  'Commercial Real Estate',
  'Construction',
  'Real Estate',
  'Apparel & Fashion',
  'Automotive',
  'Consumer Electronics',
  'Consumer Goods',
  'Consumer Services',
  'Cosmetics',
  'Furniture',
  'Luxury Goods & Jewelry',
  'Retail',
  'Supermarkets',
  'Wine & Spirits',
  'E-Learning',
  'Education Management',
  'Higher Education',
  'Libraries',
  'Primary/Secondary Education',
  'Professional Training & Coaching',
  'Aerospace',
  'Aviation & Aerospace',
  'Chemicals',
  'Electrical/Electronic Manufacturing',
  'Glass, Ceramics & Concrete',
  'Industrial Automation',
  'Machinery',
  'Mechanical or Industrial Engineering',
  'Nanotechnology',
  'Paralegal',
  'Plastics',
  'Printing',
  'Railroad Manufacture',
  'Semiconductors',
  'Shipbuilding',
  'Civic & Social Organization',
  'Government Administration',
  'Government Relations',
  'International Affairs',
  'Judiciary',
  'Law Enforcement',
  'Legislative Office',
  'Political Organization',
  'Public Policy',
  'Public Safety',
  'Alternative Medicine',
  'Health, Wellness & Fitness',
  'Hospital & Health Care',
  'Individual & Family Services',
  'Medical Devices',
  'Medical Practice',
  'Mental Health Care',
  'Pharmaceuticals',
  'Veterinary',
  'Airlines/Aviation',
  'Hospitality',
  'Leisure, Travel & Tourism',
  'Restaurants',
  'Human Resources',
  'Staffing & Recruiting',
  'Computer & Network Security',
  'Computer Games',
  'Computer Hardware',
  'Computer Networking',
  'Computer Software',
  'Information Services',
  'Information Technology & Services',
  'Internet',
  'Online Media',
  'Alternative Dispute Resolution',
  'Law Practice',
  'Legal Services',
  'Building Materials',
  'Consumer Goods',
  'Food & Beverages',
  'Furniture',
  'Glass, Ceramics & Concrete',
  'Machinery',
  'Packaging & Containers',
  'Paper & Forest Products',
  'Plastics',
  'Market Research',
  'Marketing & Advertising',
  'Public Relations & Communications',
  'Nonprofit Organization Management',
  'Philanthropy',
  'Think Tanks',
  'Executive Office',
  'Management Consulting',
  'Program Development',
  'Biotechnology',
  'Environmental Services',
  'Nanotechnology',
  'Research',
  'Defense & Space',
  'Security & Investigations',
  'Commercial Real Estate',
  'Import & Export',
  'International Trade & Development',
  'Logistics & Supply Chain',
  'Outsourcing/Offshoring',
  'Package/Freight Delivery',
  'Transportation/Trucking/Railroad',
  'Warehousing',
  'Wholesale',
  'Oil & Energy',
  'Renewables & Environment',
  'Utilities',
  'Design',
  'Events Services',
  'Facilities Services',
  'Fine Art',
  'Performing Arts',
  'Photography',
  'Printing',
  'Restaurants',
  'Translation & Localization',
  'Writing & Editing',
  'Roofing',
  'Plumbing'
]

export const ManagementLevels = [
  'Owner',
  'Founder',
  'C suite',
  'Partner',
  'Vp',
  'Head',
  'Director',
  'Manager',
  'Senior',
  'Entry',
  'Intern'
]

export const Intent = [
  'AgriTech',
  'Air Quality',
  'Bioproduction',
  'Chromatography',
  'Clinical & Diagnostics',
  'Instruments',
  'Lab Automation',
  'Lab Data Management & Analysis',
  'Life Science',
  'Mass Spectrometry',
  'Microbiology',
  'Plasticware and Supplies',
  'Radiation Detection & Measurement',
  'Water Quality',
  'Budgeting, Planning & Strategy',
  'Compliance & Governance',
  'Expansion',
  'Funding & Loans',
  'Operations',
  'Other',
  'Sales',
  'Aerospace',
  'Business Services',
  'eCommerce',
  'Education',
  'Entertainment',
  'Event Management',
  'Financial',
  'Health',
  'HR',
  'Insurance',
  'Logistics',
  'Machine Learning & Artificial Intelligence',
  'Manufacturing',
  'Media & Advertising',
  'Retail',
  'Social',
  'Technology',
  'Telecommunications',
  'Travel',
  'Device Connectivity',
  'Gadgets',
  'Mobile',
  'Personal Computer',
  'Productivity Software',
  'Search Engine',
  'Security',
  'Tablets & Readers',
  'Trends',
  'Video',
  'Web Browser',
  'Construction',
  'Energy',
  'Engineering',
  'Personal Protective Equipment (PPE)',
  'Standards & Regulatory',
  'Supply Chain',
  'Tools & Electronics',
  'Events & Conferences',
  'Accounting',
  'Business Finance',
  'Commodity',
  'Controls & Standards',
  'Corporate Finance',
  'Finance IT',
  'Personal Finance',
  'Professional Services',
  'Trading & Investing',
  'Transactions & Payments',
  'Agency/Dept',
  'Urban Planning',
  'Administration',
  'Benefits',
  'Disease Control',
  'Government Regulations',
  'Health Insurance',
  'Health Tech',
  'Marketing',
  'Medical Association',
  'Medical Education',
  'Medical Research',
  'Medical Specialty',
  'Medical Testing',
  'Medical Treatment',
  'Patient Management',
  'Diversity',
  'Employee Services',
  'HR Tech',
  'Labor Relations',
  'Leadership & Strategy',
  'Legal & Regulatory',
  'Payroll & Comp',
  'Performance',
  'Place of Work',
  'Policy & Culture',
  'Programs and Services',
  'Recruitment, Hiring & Onboarding',
  'Staff Administration',
  'Staff Departure',
  'Training & Development',
  'Wellness and Safety',
  'Banking',
  'Business Structure',
  'Civil',
  'Consumer',
  'Contracts',
  'Copyright',
  'Dispute Resolution',
  'Immigration',
  'Intellectual Property',
  'Labor',
  'Landmark Cases',
  'LawTech',
  'Legal Services',
  'Taxation',
  'Trade',
  'Ad Tech',
  'Agencies',
  'Branding',
  'Campaigns',
  'Channels & Types',
  'Content',
  'Creativity Software',
  'CRM',
  'Demand Generation',
  'Email Marketing',
  'Search Marketing',
  'Strategy & Analysis',
  'Website Publishing',
  'Design Engineering',
  'Energy & Construction',
  'Customer Service',
  'General',
  'Retail Management',
  'Retail Technology',
  'Analytics & Reporting',
  'APIs & Services',
  'Business Solutions',
  'Certifications',
  'Cloud',
  'Data Center',
  'Data Management',
  'Database',
  'Desktop',
  'Document Management',
  'Email',
  'Emerging Tech',
  'Enterprise',
  'Gaming',
  'Hardware',
  'IT Management',
  'Jail & Prison',
  'Messaging',
  'Monitoring',
  'Networking',
  'Operating System',
  'Printing',
  'Privacy',
  'Product Development & QA',
  'Programming Languages',
  'Robotics',
  'Servers',
  'Software Engineering',
  'Storage',
  'Translation & Language',
  'Virtualization',
  'Web',
  'Wireless',
  'Hotels & Accommodations',
  'Transportation'
]

export const IntentTopic = [
  {
    id: 0,
    label: 'APIs & Services',
    children: [
      ...new Set([
        'API Design',
        'API Gateway',
        'API Management',
        'API Management Platform',
        'API Monetization',
        'API Security',
        'API Testing',
        'API-First Approach',
        'Agency Management System',
        'Android API',
        'Application Integration',
        'Application Platform',
        'Application Programming Interface (API)',
        'Application Services',
        'Authentication',
        'B2B Firmographic APIs',
        'Batch Processing / Batch Services',
        'Customer Experience as a Service (CXaaS)',
        'Developer Portal',
        'Digital Integration Hub',
        'Distributed Computing',
        'Electronic Data Interchange (EDI)',
        'Electronic Data Transfer (EDT)',
        'Event Automation',
        'Interoperability',
        'Java Message Service (JMS)',
        'Job Scheduling / Workload Automation',
        'MMS API',
        'Mail Transfer Agent (MFA)',
        'Metaverse as a Service (MaaS)',
        'Microfrontends',
        'Microservices',
        'Microsoft Technology Stack',
        'Middleware',
        'Nylas Platform',
        'OData (Open Data Protocol)',
        'OpenStack',
        'Operator Connect',
        'Payroll-as-a-Service API',
        'Platform as a Service (PaaS)',
        'Platforms',
        'REST',
        'Remote Automation',
        'Restaurant reservation software',
        'Restful API',
        'SMS API',
        'Salesforce Release Management',
        'Simple Object Access Protocol (SOAP)',
        'SimpleTexting',
        'Systems Integration',
        'Systems Integrators',
        'Unified Extensible Firmware Interface (UEFI)',
        'Vert.x',
        'Voice API',
        'iOS API'
      ])
    ]
  },
  {
    id: 1,
    label: 'Accounting',
    children: [
      ...new Set([
        '401k Audit',
        'ASC 606: Revenue from Contracts with Customers',
        'Account Overdrafts',
        'Account Reconciliation',
        'Accounting',
        'Accounting Audits',
        'Accounting Automation',
        'Accounting Compliance',
        'Accounting Consolidation',
        'Accounting Journals Entries',
        'Accounting Process',
        'Accounting Software',
        'Accounts Payable',
        'Accounts Receivable',
        'Accounts Receivable / Accounts Payable',
        'Accounts Receivable Financing',
        'Activity-Based Costing (ABC)',
        'Allocation Entries',
        'Asset Classification',
        'Audit Planning',
        'Audit Risk',
        'Audit Trail',
        'Audits',
        'Automated Invoice Processing',
        'Balance Sheet Reconciliation',
        'Bank Reconciliation',
        'Capital Allowance',
        'Capital Expenditure',
        'Client Accounting Services (CAS)',
        'Cloud Accounting',
        'Committee of Sponsoring Organizations (COSO)',
        'Company Audits',
        'Complex Accounting',
        'Construction Accounting',
        'Cost Accounting',
        'Cost Allocation',
        'Cost Structure',
        'Cost of Capital',
        'Cost of Goods Sold (COGS)',
        'Country by Country (CbC) Reporting',
        'Credit Card Reconciliations',
        'Credit Derivatives',
        'Credit Management',
        'Current Liabilities',
        'Days Sales Outstanding (DSO)',
        'Deferral Method',
        'Disclosure management',
        'Distributed Ledgers',
        'Donor Management',
        'Double-Entry Bookkeeping',
        'Energy Tax Credit',
        'External Audit',
        'FIFO Accounting (First In, First Out)',
        'Financial Accounting',
        'Fund Accounting',
        'General Ledger',
        'Generally Accepted Accounting Principles (GAAP)',
        'Gross Margin',
        'Hedge Accounting',
        'Income Statement',
        'Intercompany Accounting',
        'Internal Audit',
        'International Financial Reporting Standards (IFRS)',
        'Inventory Cost',
        'Inventory Turnover',
        'Invoice Processing',
        'Invoice Scanning',
        'LIFO Accounting (Last In, First Out)',
        'Leasing Accounting',
        'Multi-Currency Accounting',
        'Net Profit Margin',
        'Operating Expense',
        'Operating Margin',
        'Opportunity Cost',
        'Out of Pocket Cost',
        'Outsourced Accounting',
        'Payroll Expense',
        'Project Accounting',
        'Proportional Amortization Method',
        'Purchase Order',
        'Quick Ratio (Acid-Test Ratio)',
        'Recurring Journal Entries',
        'Revenue Recognition',
        'Revenue Reporting',
        'SAF-T (Standard Audit File for Tax)',
        'Small Business Accounting',
        'Software as a Service (SaaS) Based Accounting',
        'Suspense Accounts',
        'Sweep Account',
        'Tax Equity',
        'Tax Equity Investing',
        'Tax Planning',
        'Web Experience Management',
        'Working Capital',
        'Write Off'
      ])
    ]
  },
  {
    id: 2,
    label: 'Ad Tech',
    children: [
      ...new Set([
        'Ad Blocking',
        'Ad Exchange',
        'Ad Fraud',
        'Ad Impression',
        'Ad Inventory',
        'Ad Networks',
        'Ad Servers',
        'Ad Testing',
        'Ad Verification',
        'Addressable TV Advertising',
        'Agency Trading Desk',
        'Amazon DSP',
        'Audience Data',
        'Audience Management Platform',
        'Conversion Tracking',
        'Cookie Sync/Matching',
        'Creative Optimization',
        'Cross Device Tracking',
        'Custom Audiences',
        'Data Acquisition',
        'Data Management Platform (DMP)',
        'Demand Side Platform (DSP)',
        'Digital Out-of-Home (DOOH) Advertising',
        'Direct-Response Advertising',
        'Display Advertising',
        'DoubleClick Bid Manager',
        'DoubleClick Search (DS)',
        'First-Party Data',
        'Frequency Capping',
        'Geo-Targeting',
        'Google Ads',
        'Hashed Emails (HEMs)',
        'Header Bidding',
        'Inbound Marketing Software',
        'Instacart Advertising',
        'Intent Data',
        'Interactive Advertising',
        'Internet Marketing Automation',
        'Lead Generation Software',
        'LinkedIn Sponsored Content',
        'LinkedIn Sponsored Messages',
        'LinkedIn Sponsored Updates',
        'Marketing Automation Tools',
        'Marketing Email',
        'Marketing Management Software',
        'Marketing ROI',
        'Marketing Reporting',
        'Marketing Tools',
        'OTT Advertising',
        'Online Lead Generation',
        'Outbound Marketing',
        'Page Revenue Per Thousand Impressions (RPM)',
        'Pixels',
        'Predictive Lead Scoring',
        'Pricing Best Practices',
        'Pricing Software',
        'Private Marketplace (PMP)',
        'Product Feeds',
        'Programmatic',
        'Programmatic Buying',
        'Programmatic Guaranteed',
        'Real-Time Bidding (RTB)',
        'Reddit Advertising',
        'Remnant Inventory',
        'Retargeting',
        'Rich Media',
        'Roadblocking',
        'SA360',
        'Sales and Marketing Automation',
        'Second-Party Data',
        'Sell Side Platform (SSP)',
        'Sticky Ads',
        'Tag Management',
        'Third-Party Data',
        'TikTok Advertising',
        'Twitter Ads',
        'Video Advertising',
        'Walmart Advertising',
        'Web Beacons',
        'Web Marketing'
      ])
    ]
  },
  {
    id: 3,
    label: 'Administration',
    children: [
      ...new Set([
        'Advisory Board',
        'Bridge Patient Portal',
        'Certified Community BH Clinic (CCBHC)',
        'Clinical Collaboration',
        'Clinical Communication',
        'Clinical Data Archiving',
        'Clinical Documentation Improvement (CDI)',
        'Current Procedural Terminology (CPT)',
        'Emergency Medical Services (EMS) Billing',
        'Healthcare Accreditation',
        'Healthcare Administration',
        'Healthcare Agency',
        'Healthcare Analytics',
        'Healthcare Contact Center',
        'Healthcare Financial Benchmarking',
        'Healthcare Price Transparency',
        'Healthcare Productivity',
        'Healthcare Providers',
        'Healthcare Real Estate',
        'Healthcare Revenue Cycle',
        'Healthcare Strategic Planning',
        'Healthcare Workforce Shortage',
        'Healthcare and Practice Management',
        'Hospital Budgeting',
        "Integrated Delivery Networks (IDN's)",
        'Investigator Meeting',
        'Laboratory Billing Software',
        'Laboratory Reimbursement',
        'Laboratory Revenue Cycle Management',
        'Long-Range Planning',
        'ManagementPlus',
        'Medical Billing',
        'Medical Coding',
        'Medical Device Capital Planning',
        'Medical Equipment Management Plan',
        'Medical Supply Chain',
        'Nursing Shortage',
        'Outsourced Laboratory Billing',
        'Outsourced Medical Billing',
        'Patient Advocacy',
        'Patient Collections',
        'Patient Payments',
        'Pay for Performance',
        'Payment Integrity',
        'Pharmaceutical Distribution',
        'Pharmacy Benefits Manager (PBM)',
        'Physician Compensation',
        'Physician Scheduling',
        'Preventable Emergency Department (ED) Visits',
        'Program of All-Inclusive Care for the Elderly (PACE)',
        'Resolving Complex Accounts',
        'Revenue Cycle Management (RCM)',
        'Risk Adjustment',
        'School-Based Health'
      ])
    ]
  },
  {
    id: 4,
    label: 'Aerospace',
    children: [
      ...new Set([
        'Aer Lingus',
        'Air Force Research Laboratory',
        'Airbus',
        'Boeing (BA)',
        'Embraer',
        'FLYHT Aerospace Solutions Ltd.',
        'Fugro',
        'General Dynamics (GD)',
        'International Jet Management (IJM)',
        'Jet Aviation',
        'Lockheed Martin (LMT)',
        'Lufthansa Technik',
        'Luxaviation',
        'MJET',
        'Northrop Grumman',
        'Raytheon (RTN)',
        'SpaceX',
        'TAG Aviation',
        'UnionPay'
      ])
    ]
  },
  {
    id: 5,
    label: 'Agencies',
    children: [
      ...new Set([
        'Advertising Agencies',
        'Advertising Solutions',
        'Amazon Consulting Agency',
        'Branding Agency',
        'Creative Agency',
        'Digital Agency',
        'Naming Agency',
        'PR Agency',
        'Public Relations',
        'Traditional Agency',
        'Video Agency'
      ])
    ]
  },
  {
    id: 6,
    label: 'Agency/Dept',
    children: [
      ...new Set([
        'Advanced Research Projects Agency (ARPA)',
        'American Farm Bureau Federation (AFBF)',
        'Centers for Medicare and Medicaid Services (CMS)',
        'Committee On Foreign Investment In The United States (CFIUS)',
        'Congressional Review Act (CRA)',
        'Consumer Financial Protection Bureau (CFPB)',
        'Corrective Action Preventive Action (CAPA)',
        'Customs',
        'Defense Advanced Research Projects Agency (DARPA)',
        'Defense Information Systems Agency (DISA)',
        'Department of Homeland Security (DHS)',
        'Department of Housing and Urban Development (HUD)',
        'Department of Justice (DOJ)',
        'Drug Enforcement Administration (DEA)',
        'Economic Development Authority of Western Nevada (EDAWN)',
        'Federal Aviation Administration (FAA)',
        'Federal Bureau of Investigation (FBI)',
        'Federal Communications Commission (FCC)',
        'Federal Emergency Management Agency (FEMA)',
        'Federal Housing Administration (FHA)',
        'Federal Housing Finance Agency (FHFA)',
        'Federal Information Processing Standards (FIPS)',
        'Federal Information Security Management Act (FISMA)',
        'Federal Motor Carrier Safety Administration',
        'Federal Risk and Authorization Management Program (FedRAMP)',
        'Financial Crimes Enforcement Network (FinCEN)',
        'Financial Crisis Inquiry Commission (FCIC)',
        'Florida Department of Law Enforcement (FDLE)',
        'Food and Drug Administration (FDA or USFDA)',
        'Foreign Intelligence Surveillance Act (FISA)',
        'General Services Administration (GSA)',
        'Government Accountability Office (GAO)',
        'Government National Mortgage Association (GNMA)',
        'Government Procurement',
        'Gramm-Leach-Bliley Act (GLBA)',
        'International Organization for Standardization (ISO)',
        'Interpol',
        'Joint Terrorism Task Force (JTTF)',
        'Ministry of Corporate Affairs (MCA)',
        'National Aeronautics and Space Administration (NASA)',
        'National Institute of Standards & Technology (NIST)',
        'National Labor Relations Board (NLRB)',
        'National Railroad Passenger Corporation (AMTRAK)',
        'National Security Agency (NSA)',
        'National Weather Service (NOAA)',
        'New Payments Platform (NPP)',
        'Office for Civil Rights (OCR)',
        'Office of Foreign Assets Control (OFAC)',
        'Office of Inspector General (OIG)',
        'Office of The Comptroller of the Currency (OCC)',
        'Organisation for Economic Co-operation and Development (OECD)',
        'Securities And Exchange Commission (SEC)',
        'Securities and Exchange Board of India (SEBI)',
        'Transport for London',
        'United States Border Patrol',
        'United States Department of Defense',
        'United States Department of Energy (DOE)',
        'United States Department of Homeland Security (DHS)',
        'United States Postal Service (USPS)',
        'United States Secret Service (USSS)',
        'United States Supreme Court'
      ])
    ]
  },
  {
    id: 7,
    label: 'AgriTech',
    children: [
      ...new Set([
        'Botany',
        'Cellular Agriculture',
        'Controlled Environment Agriculture (CEA)',
        'Dentrification',
        'Desalination',
        'Dicamba',
        'Elemental Analysis',
        'Forage',
        'Genetic Engineering',
        'Herbicide Tolerance',
        'Insect Resistance',
        'Irrigation System Maintenance',
        'Lab-Grown Meat',
        'Manure',
        'Microorganisms',
        'Micropropagation',
        'Molecular Diagnostics',
        'Molecular Markers',
        'Mutagenesis',
        'Nitrogen',
        'Organic Foods',
        'Palmer Amaranth',
        'Plant Tissue Culture',
        'Polyploidy',
        'Pyrethroids',
        'RNA Interface',
        'Smart Farming',
        'Soil Analysis',
        'Soybean Cyst Nematode',
        'Transgenics',
        'Tree Care',
        'VOC analysis'
      ])
    ]
  },
  {
    id: 8,
    label: 'Air Quality',
    children: [
      ...new Set([
        'Activated Carbon Filtration',
        'Air Pollution',
        'Blue Hydrogen',
        'Carbon Dioxide (CO2)',
        'Carbon Monoxide Poisoning',
        'Dust Collection Systems',
        'Flue gas',
        'Greenhouse Gas',
        'Indoor Air Quality',
        'Liquid Hydrogen (LH2)',
        'Low Carbon',
        'Low-Carbon Ammonia',
        'Nox',
        'Particulate Matter',
        'Pollution Control',
        'Scrubbers',
        'Stripe Climate'
      ])
    ]
  },
  {
    id: 9,
    label: 'Analytics & Reporting',
    children: [
      ...new Set([
        'Actionable Insights',
        'Ad Hoc Analytics',
        'Advanced Analytics',
        'Agile Business Intelligence (BI)',
        'Amazon QuickSight',
        'Analytics Consulting',
        'Analytics Software',
        'Analytics as a service (AaaS)',
        'Augmented Consumer',
        'Big Data',
        'Big Data Analytics',
        'Business Intelligence',
        'Business Intelligence Analytics',
        'Business intelligence platform',
        'Cluster Analysis',
        'Cognitive Analytics',
        'Comparative Analysis',
        'Complex Event Processing (CEP)',
        'Connection Analytics',
        'Contextual Analytics',
        'Correlation Analysis',
        'Dashboard',
        'Data Analytics',
        'Data Hub',
        'Data Management Software',
        'Data Management System',
        'Data Mining',
        'Data Science',
        'Data Visualization',
        'Dealership Management System',
        'Descriptive Analytics',
        'Digital Assessment',
        'Digital Shelf Analytics',
        'Embedded Analytics',
        'Embedded Business Intelligence',
        'Embedded Reporting',
        'Enterprise Data Management',
        'Enterprise Reporting',
        'Enterprise Value (EV)',
        'Feature Engineering',
        'Google Data Studio',
        'Headless BI',
        'Identity Analytics',
        'Infrastructure Performance Layer',
        'Interactive Visualization',
        'Linear Regression',
        'Meter Data Analytics',
        'Monitor and Analyze Performance',
        'Multivariate Testing',
        'Network Analytics',
        'OLAP',
        'Operational BI',
        'Operational Intelligence (OI)',
        'Oracle Analytics Cloud (OAC)',
        'PR Measurement',
        'Performance Indicator',
        'Predictive Analytics',
        'Price Optimization',
        'Pricing Analytics',
        'Process Mining',
        'Profitability Management',
        'Project Dashboards',
        'Real Estate Data & Analytics',
        'Real-Time Analytics',
        'Reporting Software',
        'Scalability',
        'Self-Service Analytics',
        'Single Customer View (SCV)',
        'Smart Analytics',
        'Social Media Analytics (SMA)',
        'Software as a Service (SaaS) Analytics',
        'Streaming Analytics',
        'Text Analytics',
        'Time Analytics',
        'Topological Data Analysis (TDA)',
        'Visual Analytics',
        'Warehouse Management Solutions',
        'Workforce Analytics',
        'Zoho Analytics'
      ])
    ]
  },
  {
    id: 10,
    label: 'Banking',
    children: [
      ...new Set([
        'Anti-Money Laundering',
        'Bank Secrecy Act (BSA)',
        'Bankruptcy Protection',
        'Basel II',
        'Chapter 11 Bankruptcy (Reorganization)',
        'Chapter 13 Bankruptcy',
        'Chapter 7 Bankruptcy (Liquidation)',
        'Community Reinvestment Act (CRA)',
        'Conveyance',
        'Cramdown',
        'Custodian Bank',
        'Dodd-Frank Act Compliance',
        'FINRA Compliance',
        'Fair Credit Reporting Act (FCRA)',
        'Fair and Accurate Credit Transactions Act (FACTA)',
        'Federal Home Loan Banks (FHLBanks)',
        'Federal Reserve System',
        'Financial Action Task Force (FATF)',
        'Forbearance Agreement',
        'Fourth EU Anti-Money Laundering Directive',
        'Home Mortgage Disclosure Act (HMDA)',
        'Know Your Customer (KYC)',
        'Loan Workout',
        'Money mule',
        'SAFER Act (Secure And Fair Enforcement Regulation Banking Act)',
        'Subrogation',
        'Transaction Monitoring',
        'Troubled Asset Relief Program (TARP)'
      ])
    ]
  },
  {
    id: 11,
    label: 'Benefits',
    children: [
      ...new Set([
        'Adoption Benefits',
        'Annual Enrollment',
        'Benefits Administration',
        'Benefits Management',
        'COBRA Administration',
        'Cycle to Work',
        'Defined Benefit Plans',
        'Defined Contribution Plans',
        'Employee Benefits',
        'Employee Benefits Broker',
        'Employee Benefits Communications',
        'Employee Discounts',
        'Employee Meals',
        'Employee Stock Ownership Plan (ESOP)',
        'Employee Stock Purchase Plan (ESPP)',
        'Executive Physicals/Health',
        'Family and Medical Leave',
        'Flexible Spending Accounts (FSA)',
        'Food / Cafeteria Plans',
        'Health Care',
        'Health Reimbursement Account',
        'Health Reimbursement Account (HRA)',
        'Health Savings Accounts (HSA)',
        'Incentive Program',
        'Incentive Stock Options (ISO)',
        'Income Replacement Benefits (IRBs)',
        'Keogh Plans',
        'Paid Time Off',
        'Part Time Benefits',
        'Pension Risk Transfer',
        'Pensions / Retirement Benefits',
        'Retirement / Pre-Retirement Planning',
        'Retirement Plan Administration',
        'Retirement Savings / 401(k)',
        'Retirement Trends',
        'Safe Harbor 401(k)',
        'Social Security',
        'Summary Plan Description (SPD)',
        'Target Retirement',
        'Third Party Administrators (TPAs)',
        'Tuition Assistance',
        'Vision Care',
        'Voluntary Benefits',
        'Wage and Salary Administration',
        'Wellness Benefits',
        'Work-Life Integration'
      ])
    ]
  },
  {
    id: 12,
    label: 'Bioproduction',
    children: [
      ...new Set([
        'Absorption',
        'Adherent Cells',
        'Adsorption',
        'Agarose',
        'Antibody Production',
        'Biochar',
        'Biofouling',
        'Biohazard Waste',
        'Bioprocessing',
        'Biosecurity',
        'Cell Culture',
        'Contamination',
        'Contract Research Organization',
        'Distillation',
        'Fierce 15',
        'Good Manufacturing Practice (GMP)',
        'High Viscosity Flow',
        'Passaging Cells',
        'Process Analytical Technology',
        'Stem Cell',
        'Synthetic Biology'
      ])
    ]
  },
  {
    id: 13,
    label: 'Branding',
    children: [
      ...new Set([
        'Adaptive Design',
        'Brand Ambassadors',
        'Brand Awareness',
        'Brand Building Strategy',
        'Brand Design',
        'Brand Equity',
        'Brand Health',
        'Brand Kit',
        'Brand Loyalty',
        'Brand Management',
        'Brand Positioning',
        'Brand Protection',
        'Brand Safety',
        'Brand Strategy',
        'Brand Suitability',
        'Brand Tracker',
        'Brand Voice',
        'Branded Apparel',
        'Connected Customer',
        'Custom Swag Design',
        'Employer Branding',
        'Fundraiser Merchandise',
        'Logo Creation',
        'Personal Branding',
        'Product Lining',
        'Rebranding'
      ])
    ]
  },
  {
    id: 14,
    label: 'Budgeting, Planning & Strategy',
    children: [
      ...new Set([
        'Accelerators',
        'Account Planning',
        'Account-Based Experience (ABX)',
        'Action Plan Management',
        'After Market Processes',
        'Aftersales Management',
        'Asset Reservations',
        'Assortment Planning',
        'Base erosion and profit shifting (BEPS)',
        'Behavioral Analytics',
        'Business Activity Monitoring (BAM)',
        'Business Analytics',
        'Business Collaboration',
        'Business Communications',
        'Business Enablement',
        'Business Exit',
        'Business Expansion',
        'Business Metrics',
        'Business Model',
        'Business Model Canvas',
        'Business Network',
        'Business Plan',
        'Business Planning',
        'Business Process Automation (BPA)',
        'Business Process Management (BPM)',
        'Business Process Outsourcing (BPO)',
        'Business Process Reengineering (BPR)',
        'Business Process as a Service (BPaaS)',
        'Business Resiliency',
        'Business Transformation',
        'Business Valuation',
        'Cash Flow Analysis',
        'Centralized Planning',
        'Chamber of Commerce',
        'Change Management',
        'Collaborative Innovation',
        'Competitive / SWOT Analysis',
        'Competitive Advantage',
        'Competitive Intelligence',
        'Concurrent Engineering',
        'Connected Retail',
        'Consumption Forecasting',
        'Corporate Performance Management (CPM)',
        'Corporate gifting',
        'Cost Management',
        'Cost of Carry',
        'Cost-Containment',
        'Data Driven Strategy',
        'Demand Planning Technology',
        'Demand Sensing',
        'Design Thinking',
        'Digital Process',
        'Digital Transformation',
        'Discrete Manufacturing ERP',
        'Domestic Content Adder',
        'Downsizing',
        'ERP Controls',
        'Economic Forecasting',
        'Enhanced Due Diligence (EDD)',
        'Enterprise Performance Management (EPM)',
        'Enterprise Resource Planning (ERP)',
        'Enterprise Risk Management',
        'Exception Management',
        'Failure Analysis',
        'Faster Development Cycles',
        'Financial Management',
        'Fixed price',
        'Food Enterprise Resource Planning (ERP)',
        'Forecast Value-Add',
        'Gross Revenue',
        'IBM Planning Analytics',
        'Idea-to-Market Processes',
        'Integrated Business Planning',
        'Integrated ERP',
        'Integrated Risk Management',
        'Integrated Route-to-Market',
        'Inventory Management',
        'Kickoff',
        'Know Your Business (KYB)',
        'Lead to Cash',
        'Lean Organization',
        'Lean Project Management',
        'M&A Advisory',
        'Managed Mobility Services',
        'Management By Objectives (MBO)',
        'Management Fundamentals',
        'Management Reporting',
        'Market Opportunities Report',
        'Master Data Management (MDM)',
        'Metaverse as a Strategy',
        'Middle Market Business',
        'Money Management',
        'Operational Resilience',
        'Opportunity Zones (OZs)',
        'Organizational Initiative',
        'Organizational Restructuring',
        'PEST Analysis',
        'People Analytics',
        'Planned Maintenance',
        'Planning and Forecasting',
        'Portfolio Analytics',
        'Post Modern ERP',
        'Practice Management',
        'Predictive Maintenance (PdM)',
        'Prescriptive Analytics',
        'Price-to-Earnings Ratio (P/E)',
        'Process Discovery',
        'Process Excellence',
        'Product Cost Management',
        'Product Discovery',
        'Product Innovation',
        'Product Segmentation',
        'Project Controls',
        'Project Delivery Method',
        'Project Planning',
        'Project Reporting',
        'Project Tracking',
        'Project Workflow',
        'Real-Time Collaboration',
        'Referral Program',
        'Request for Information (RFI)',
        'Resource Allocation',
        'Resource Tracking',
        'Retail Activity Optimization (RAO)',
        'Return on Assets (ROA)',
        'Return on Equity (ROE)',
        'Revenue Enablement',
        'Revenue Optimization',
        'Risk Analysis',
        'Risk Transformation',
        'Rolling Forecasts',
        'Sale leaseback',
        'Sale of Business',
        'Scenario Planning',
        'Selling a Company',
        'Service-centric',
        'Servitization',
        'Social, Mobile, Analytics, Cloud (SMAC)',
        'Space Optimization',
        'Special Purpose Acquisition Company (SPAC)',
        'Stakeholder Analysis',
        'Statistical Analysis',
        'Statistical Forecasting',
        'Strategic Planning',
        'Strategic Sourcing',
        'Strategy Consulting',
        'Sustainable Business Growth',
        'Tactical Planning',
        'Telecommunications Expense Management (TEM)',
        'The Blue Economy',
        'The Green Transition',
        'Transition Planning',
        'Value Creation Services',
        'Value Innovation Strategy',
        'Variance Analysis',
        'Visual Merchandising',
        'Warranty Management',
        'Workforce Optimization',
        'Workplace Strategy'
      ])
    ]
  },
  {
    id: 15,
    label: 'Business Finance',
    children: [
      ...new Set([
        'Agreed-Upon Procedures',
        'Annual Recurring Revenue, (ARR)',
        'Asset Management Software',
        'Asset-Based Lending',
        'Automobile Financing',
        'Average Order Value (AOV)',
        'Aviation Financing',
        'Bankruptcy / Bankruptcy Law',
        'Billing and Invoicing',
        'Budgeting',
        'Business Checking',
        'Business Credit Cards',
        'Business Line of Credit',
        'Business Loans',
        'Business Payment Solutions',
        'Business Philanthropy',
        'Business Real Estate Financing',
        'Business Savings Account',
        'Buy-Sell Agreement',
        'Capital Project',
        'Cash Flow',
        'Co-Branded Card',
        'Commercial Mortgages',
        'Commercial Paper',
        'Commercial and Real Estate Financing',
        'Compensation Management',
        'Contract Lifecycle Management',
        'Contract for Difference',
        'Cost Control',
        'Cost Efficiency',
        'Credit and Collections',
        'Crowdfunding',
        'Customer Financing',
        'Early Payment Discount',
        'Energy Financing',
        'Enterprise Spend Management',
        'Expense Fraud',
        'Expense Management',
        'Expense Reporting',
        'Factoring',
        'Financial Analysis',
        'Financial Forecasting',
        'Financial Health',
        'Financial Inclusion',
        'Financial Management Services',
        'Financial Reporting',
        'Financial Services',
        'Financial Statements',
        'Funding Management',
        'Instant Payment',
        'Interchange Fee',
        'Inventory Finance',
        'Investment Banking',
        'Line of Credit',
        'Merchant Account',
        'Merchant Cash Advances',
        'Monthly Recurring Revenue (MRR)',
        'Outsourced Controller',
        'PayPal Business Loan',
        'Premium Tax Credit',
        'Price To Sales Ratio (PSR)',
        'Project Assurance',
        'Project Finance',
        'Promotional Budget',
        'Proxy Statement',
        'Quarterly Business Review (QBR)',
        'Rationalization',
        'Rebate Administration',
        'Retail Banking',
        'Retail Finance',
        'Reverse Factoring',
        'Risk Assessment',
        'Risk Participation',
        'Small Business Administration (SBA) lending',
        'Small Business Loans',
        'Structured Products',
        'Supply Chain Financing',
        'Syndicated Loans',
        'Tail Spend',
        'Term Loans',
        'Total Cost of Ownership',
        'Trade Finance',
        'Trade Services',
        'Transaction Banking',
        'Treasury / Cash / Risk Management',
        'Underwriting',
        'Venture Capital (VC)',
        'Working Capital Finance',
        'Working Capital Management',
        'Zero-Based Budgeting (ZBB)'
      ])
    ]
  },
  {
    id: 16,
    label: 'Business Services',
    children: [
      ...new Set([
        '128 Technology',
        '3DExperience',
        '4imprint (FOUR)',
        '8K Miles',
        'ABM Industries',
        'ABS Group',
        'ACI Worldwide (ACIW)',
        'ACS Solutions',
        'AECOM Technology (ACM)',
        'AKA Enterprise Solutions',
        'AMC Technology',
        'ARCAT, Inc.',
        'ARCOS, Inc.',
        'AWC, Inc.',
        'AWS Lambda',
        'AWS Security',
        'Abila',
        'Accenture (ACN)',
        'Accruent',
        'Acquia',
        'Actian',
        'ActionIQ, Inc.',
        'Acuity, Inc.',
        'Acumatica',
        'Acumen Solutions',
        'Acxiom',
        'Adams and Reese LLP',
        'Adaptive Insights',
        'Addepar',
        'Aderant',
        'Administrative Resource Options (ARO)',
        'Adobe Analytics',
        'Adobe Experience Cloud',
        'Adobe Target',
        'Advanced',
        'Adyen',
        'Aerospike',
        'Agile CRM Inc',
        'Agile Trailblazers',
        'AgileThought, Inc.',
        'Aimia',
        'Aircall',
        'Akeneo',
        'Akerman LLP',
        'Akin Gump Strauss Hauer & Feld LLP',
        'Alexander Group',
        'Alexander Mann',
        'Algar Tech',
        'Algolia',
        'Alianza, Inc.',
        'Alix Partners',
        'Allbound',
        'Allego',
        'AlphaSights',
        'Alston & Bird LLP',
        'Altimetrik',
        'Altus Group Limited',
        'Alvarez & Marsal',
        'Amadeus',
        'Amazon Business',
        'Amazon CloudWatch',
        'Amazon/AWS CloudTrail',
        'Ambition',
        'American Express Global Business Travel',
        'Amperity',
        'Amplitude',
        'Anaplan',
        'Ankura',
        'Annalect',
        'AnswerForce',
        'Apache ActiveMQ',
        'Apache Oozie',
        'Apexon',
        'Apigee',
        'Appfolio',
        'Appinio',
        'Apple Business Chat',
        'Apple Business Essentials',
        'Apple Business Manager',
        'Apple Music for Business',
        'Apptio',
        'Apptio TargetProcess',
        'Arbela Technologies',
        'Arise Virtual Solutions',
        'Armstrong Teasdale LLP',
        'Arrowstream Inc',
        'Artech Information Systems LLC',
        'Arthur D Little',
        'Arvato',
        'Ascend Technologies, LLC',
        'Ascentium Capital',
        'Assent Compliance',
        'Astadia',
        'Astreya',
        'Atlassian (TEAM)',
        'Atomic Data',
        'Attain',
        'Attest Technologies Limited',
        'Attra',
        'Audigent',
        'AuditBoard',
        'Aunalytics, Inc.',
        'Automatic Data Processing (ADP)',
        'AutomotiveMastermind',
        'Avalara',
        'Avasant, LLC',
        'Avention',
        'Avtex',
        'Axiom Global Inc.',
        'Axway',
        'Azure Data Share',
        'Azure IoT Central',
        'Azure Logic Apps',
        'Azure Monitor',
        'Azure Synapse',
        'BCforward',
        'BDO Digital',
        'BOARD',
        'BOC Group',
        'Backupify',
        'Bain Management Consulting',
        'Bain and Company',
        'Baker & Hostetler LLP',
        'Baker & McKenzie',
        'Baker Botts LLP',
        'Ballard Spahr LLP',
        'Balto Software, Inc.',
        'Barnes & Thornburg LLP',
        'Barracuda Networks',
        'Basis Global Technologies, Inc.',
        'BearingPoint, Inc',
        'Beekeeper.io',
        'Berkeley Research Group',
        'Berry Appleman & Leiden',
        'Besedo',
        'BetterUp',
        'BetterWorks',
        'BeyondTrust',
        'BigMachines',
        'Bigtincan',
        'Birch Grove Software, Inc.',
        'Bird (MessageBird)',
        'Birlasoft Limited',
        'Birst',
        'BitSight',
        'Blackbaud (BLKB)',
        'Blank Rome LLP',
        'Blast Analytics',
        'Blockedge',
        'Bloomberg L.P.',
        'Bloomerang',
        'Blue Mountain Quality Resources, Inc.',
        'BlueCat Networks',
        'BlueConic',
        'BlueKai (Oracle Data Cloud)',
        'BlueVenn Inc.',
        'Bluewolf',
        'Bond Brand Loyalty',
        'Bond, Schoeneck & King PLLC',
        'Bonitasoft, S.A.',
        'Bounteous',
        'Bracewell LLP',
        'BrandMaker',
        'Brandwatch',
        'Braze',
        'Brennan IT',
        'Bridg',
        'Bridge Partners',
        'Brierley',
        'Brightedge',
        'Brightpearl',
        'Brother International',
        'Brown Rudnick LLP',
        'Bruner Corporation',
        'Bryan Cave Leighton Paisner LLP',
        'Buchalter Nemer LLP',
        'Buchanan, Ingersoll & Rooney PC',
        'Buckley LLP',
        'Buildium',
        'Bullhorn',
        'Burr & Forman LLP',
        'BusinessOptix Group, Inc.',
        'Butler Snow LLP',
        'CAKE.com Inc.',
        'CBIZ',
        'CI Radar',
        'CI&T',
        'CIENCE Technologies, Inc.',
        'CLC Lodging',
        'CM.com',
        'CPT Global Limited (CGO.AX)',
        'CRM Next',
        'CSG International',
        'Cadwalader, Wickersham & Taft LLP',
        'Cahill Gordon & Reindel LLP',
        'Caissa',
        'Calabrio',
        'Calero',
        'Callbox, Inc.',
        'Calliduscloud',
        'Canberra Data Centres (CDC)',
        'Canopy',
        'Canto',
        'Capita plc',
        'Captio',
        'Cardinal Path',
        'Catalyst Cloud',
        'Catapult Systems',
        'Cdnetworks',
        'Cegid',
        'Celonis',
        'Census',
        'Center for Creative Leadership',
        'Certify',
        'Chainalytics',
        'Challenger Sales',
        'Challenger, Gray & Christmas',
        'ChannelMix',
        'Chapman and Cutler LLP',
        'Chargeback Gurus',
        'Chargebacks 911',
        'CharterUP',
        'Chartis Group',
        'CheckMarket',
        'Chief Outsiders',
        'Choate, Hall & Stewart LLP',
        'Chrome River',
        'Cincom',
        'Cirrus Insight',
        'Cisco Collaboration',
        'Cisco Spark',
        'Citation Professional Solutions',
        'Citrin Cooperman Advisors LLC',
        'Civeo Pty Ltd',
        'CiviCRM',
        'CivicPlus',
        'Clark Hill PLC',
        'Clearbit',
        'Cleary Gottlieb Steen & Hamilton LLP',
        'CleverTap',
        'ClientPay',
        'Clio - Legal Practice Management Software',
        'Clockify',
        'CloudJumper',
        'Cloudbees',
        'Cloudpractice Pty Ltd',
        'Clutch',
        'CoSo Cloud',
        'Coconut Software',
        'Coegi',
        'Cognism Limited',
        'Cohesity',
        'Collaborative Solutions',
        'Collinson Group',
        'Columbus IT',
        'ComAround',
        'Comarch',
        'Comcast Business',
        'Commet',
        'Commio',
        'Concur Travel',
        'Conductor',
        'Conectys',
        'Confluence',
        'Confluent',
        'Connect Assist',
        'ConnectALL',
        'ConnectWise',
        'Connext Global Solutions',
        'ConsenSys',
        'Contact Center Compliance (DNC.com)',
        'Contract Logix',
        'ContractPodAi',
        'Control Risks',
        'Convergint',
        'Cooley LLP',
        'CoreHR',
        'Corelogic',
        'Corporate Visions',
        'Course5',
        'Covington & Burling LLP',
        'Coyote Analytics',
        "Cozen O'Connor",
        'Cravath, Swaine & Moore LLP',
        'Crawford & Company',
        'Crayon',
        'Creatio',
        'CreatorIQ',
        'Crestwood Associates',
        'Croner Group',
        'Crowell & Moring LLP',
        'Cummins',
        'Customer 360',
        'Cyient Limited',
        'Cytora Limited',
        'D&B Hoovers',
        'D&H Distributing',
        'DCR Workforce',
        'DLA Piper',
        'DSD Business Systems',
        'Dale Carnegie & Associates',
        'Data Axle Inc.',
        'Datacom Group Limited',
        'Datagroup SE',
        'Datalogix',
        'Datalytyx',
        'Davis Polk & Wardwell LLP',
        'Davis Wright Tremaine LLP',
        'Day Pitney LLP',
        'Deacom',
        'Debevoise & Plimpton LLP',
        'Dechert LLP',
        'Decision Inc Australia',
        'Dell Boomi',
        'Deloitte Digital Pty Ltd.',
        'Deloitte Monitor',
        'Deltek Costpoint',
        'Denodo Technologies',
        'Development Dimensions International (DDI)',
        'Dialogtech',
        'Dickinson Wright PLLC',
        'DigitalRoute',
        'Diligent Board Portal',
        'Dinsmore & Shohl LLP',
        'Dixa',
        'DocSend',
        'Documentum',
        'DoorLoop, Inc.',
        'Dorsey and Whitney LLP',
        'Drift',
        'Duane Morris LLP',
        'Duetto Research, Inc.',
        'Dynamics 365',
        'E-Builder',
        'ECA Partners',
        'ECS',
        'ELM Solutions',
        'EMSI',
        'ESIS, Inc.',
        'ESW Capital',
        'EX Squared',
        'EXL',
        'EasySend Ltd.',
        'EcoVadis',
        'Edenred',
        'EdgeConneX, Inc.',
        'EdgeVerve',
        'Egon Zehnder',
        'Elevate Services',
        'Elite',
        'EllisDon',
        'Emarsys',
        'EmployBridge',
        'Emtec Inc.',
        'EnSilo',
        'Enablon',
        'Encompass Corporation',
        'Ensono',
        'Entelo',
        'Enterprise Iron',
        'Epiq',
        'Equal Experts (EE)',
        'Equiniti Group Limited (EQN)',
        'Equinix (EQIX)',
        'Equipnet',
        'EraCLM',
        'Esko',
        'Etech Global Services, LLC',
        'Ethan Group',
        'Everest Group',
        'Everis',
        'Everstage',
        'Evolve IP',
        'Evolytics, LLC',
        'Exela',
        'Expandi Limited',
        'Expanse',
        'Expensify',
        'Experian (EXPN)',
        'Experian Marketing Services',
        'Experlogix, LLC',
        'Exterro',
        'Extole',
        'FICO',
        'FTI Consulting (FCN)',
        'Faegre Drinker Biddle and Reath',
        'Feefo',
        'Fenwick & West',
        'Finn Partners, Inc.',
        'Firmex Inc.',
        'Firstsource Solutions Ltd (FSL)',
        'Fitch Solutions',
        'Five9',
        'Fleet Card',
        'Fleetmatics',
        'Fluence Technologies',
        'Flywheel Digital',
        'Foley & Lardner LLP',
        'Foley Hoag LLP',
        'Folloze',
        'Fonteva',
        'Force Management',
        'Forkable',
        'Foundever',
        'Fox Rothschild LLP',
        'FranklinCovey',
        'Freedom Graphic Systems',
        'FreshBooks',
        'Freshdesk',
        'Fried, Frank, Harris, Shriver & Jacobson LLP',
        'FrontStream',
        'Frost & Sullivan',
        'Frost Brown Todd LLP',
        'FullStory',
        'Fusion Risk Management',
        'Fusion5',
        'FusionPro',
        'G Suite',
        'GBTEC Software AG',
        'GEP Worldwide',
        'GK Horizons',
        'GMC Software',
        'Gallup',
        'Gartner (IT)',
        'Genpact (G)',
        'Geotab',
        'Gerson Lehrman Group',
        'Getronics',
        'GhostDraft, Inc.',
        'Gibson, Dunn and Crutcher LLP',
        'Gilbarco',
        'Gladly',
        'Glint',
        'Global Shop Solutions',
        'Globant',
        'Gmail',
        'Gmail For Business',
        'Goldberg Segalla',
        'GoodData',
        'Goodwin Procter LLP',
        'Google Apps',
        'Google Calendar',
        'Google Docs',
        'Google Drive',
        'Google For Work',
        'Google Forms',
        'Google Sheets',
        'Google Slides',
        'Gordon Rees Scully Mansukhani LLP',
        'Gorgias',
        'Gray, Inc.',
        'Green Hills Software',
        'Greenberg Traurig, LLP',
        'Greenspoon Marder LLP',
        'Groove',
        'GrowthSpace',
        'Gryphon Networks',
        'Guidepoint',
        'Guild Education',
        'Gupshup',
        'H5 Data Centers',
        'HCL Software',
        'HFS Research Ltd.',
        'HG Data',
        'HGData',
        'HH Global',
        'HTC Global Services',
        'HVR',
        'Halo Branded Solutions',
        'Hannon Hill',
        'Hannon Hill (Cascade)',
        'Hanson Bridgett LLP',
        'HappyCo, Inc.',
        'HappyFox Inc.',
        'Harbour IT',
        'Haynes and Boone LLP',
        'Heap',
        'Heidrick & Struggles',
        'Help Scout',
        'HelpSystems',
        'Hexaware',
        'Hibu',
        'HighJump',
        'HighQ',
        'Higher Logic',
        'Hinckley, Allen & Snyder LLP',
        'Hinshaw & Culbertson LLP',
        'Hitachi Vantara',
        'Hive',
        'Hodgson Russ LLP',
        'Hogan Lovells',
        'Holland & Hart LLP',
        'Holland & Knight LLP',
        'Honigman Miller Schwartz and Cohn LLP',
        'HooYu',
        'Hoopla',
        'Horizontal Digital',
        'Host Analytics',
        'HotSchedules',
        'Hudson Supplies Inc.',
        'Hughes Hubbard & Reed LLP',
        'Hull',
        'Hunter & Bard',
        'Hunton Andrews Kurth LLP',
        'Huron Consulting Group',
        'Husch Blackwell',
        'Hyland',
        'IBM Cognos',
        'IBM Emptoris',
        'IBM SPSS',
        'IBM Websphere Commerce',
        'IBM Websphere Portal Server',
        'ICF Next',
        'IDBS',
        'IDology, Inc.',
        'IMImobile Limited',
        'ION',
        'IQNavigator',
        'ISG (Information Services Group)',
        'ISNetworld',
        'IST Management Services',
        'IWG plc',
        'Ice Miller LLP',
        'Icrossing',
        'Ideagen Ltd',
        'Ignite Selling, Inc.',
        'Imagine Software',
        'Impact Tech, Inc.',
        'Impetus Technologies',
        'Impexium Corporation',
        'Imprivata',
        'InRiver',
        'Incentive Solutions',
        'Infinite Lambda',
        'InfoTrust',
        'Information Resources, Incorporated (IRI)',
        'Infosys (INFY)',
        'Ingram Micro Cloud',
        'Inkling',
        'Innovation Network Corporation of Japan (INCJ)',
        'Innovation Refunds',
        'InsightSquared',
        'Insightsoftware',
        'Instagram Analytics',
        'Instawork',
        'IntApp',
        'Integrate',
        'Intelligence Node Consulting Ltd',
        'Intelligent Audit',
        'InterCall',
        'International SOS',
        'Intouch Insight (INX)',
        'IntouchCX',
        'Investis Digital Limited',
        'Ipsos',
        'Ipsos (IPS)',
        'IronClad',
        'Ivanti',
        'J-Startup',
        'JIRA',
        'JMARK',
        'JPG Global',
        'JRNI',
        'Jabil (JBL)',
        'Jackson Lewis LLP',
        'Jackson Walker LLP',
        'Japan External Trade Organization (JETRO)',
        'Jedox',
        'Jellyfish',
        'Jenner & Block LLP',
        'Jira Align',
        'JobsOhio',
        'Jones Day',
        'Jouve',
        'Judy Diamond Associates, Inc.',
        'Justt',
        'K&L Gates LLP',
        'K2 Integrity',
        'KUBRA',
        'Kahuna',
        'Kainos (KNOS)',
        'Kaleido',
        'Katten Muchin Rosenman LLP',
        'Kaufman Hall',
        'Keap',
        'Keppel Corporation Limited (KPELY) (KPLM.SI) (BN4)',
        'Keynote',
        'Kharon',
        'Kibo',
        'Kilpatrick Townsend & Stockton LLP',
        'Kimble',
        'Kinaxis',
        'King & Spalding LLP',
        'Kirkland & Ellis LLP',
        'Klient',
        'Kobie',
        'Kobre & Kim LLP',
        'Koerber',
        'Konecta Group',
        'Korea Investment Corporation (KIC)',
        'Korn Ferry',
        'Kroll',
        'Krow PSA',
        'Kryon Systems',
        'Kustomer',
        'Kutak Rock LLP',
        'Kyndryl',
        'LEK Consulting',
        'LMNTRIX',
        'LS Direct',
        'LTIMindtree (LTIM)',
        'Language IO',
        'Latham & Watkins',
        'Lathrop GPM LLP',
        'LawPay',
        'Lead Genius',
        'Lead411',
        'LeadIQ',
        'Leadfeeder',
        'Legion Technologies',
        'Legisway',
        'LevelJump',
        'Lewis Brisbois Bisgaard & Smith LLP',
        'Lexer',
        'Life Cycle Engineering',
        'Liferay',
        'Lighthouse Global',
        'Lilt',
        'Lime Rock Partners',
        'LinkedIn Sales Navigator',
        'Linnworks',
        'Litmus',
        'Littler Mendelson P.C.',
        'LiveAction',
        'LiveChat',
        'LiveOps',
        'LiveRamp',
        'Lockbox Payment Processing',
        'Locke Lord LLP',
        'Loeb & Loeb LLP',
        'Lola.com',
        'Lone Star Controls',
        'Long International, Inc.',
        'Loox',
        'Lotame',
        'Lotus Notes',
        'Lowenstein Sandler LLP',
        'Lucas Systems',
        'Lumine Group',
        'Lusha Systems Inc.',
        'Lyons Consulting Group',
        'MNTN Inc.',
        'MRV Communications',
        'MVF Global',
        'MYOB',
        'Mach49 LLC',
        'MadCap Central',
        'MadCap Flare',
        'MadCap Software, Inc.',
        'Magna5',
        'Magnolia CMS',
        'Majorel',
        'Manatt, Phelps & Phillips, LLP',
        'Manpower Group Solutions',
        'MapD',
        'Mapp Cloud',
        'MarketOne International',
        'MarketSource Inc.',
        'Marketplacer',
        'Marketstar',
        'Marshall Dennehey Warner Coleman & Goggin, P.C.',
        'Maru Group Limited',
        'Mavenlink',
        'Mayer Brown LLP',
        'McDermott Will & Emery LLP',
        'McGuireWoods LLP',
        'McKinsey & Company',
        'MediaBeacon',
        'Mediarithmics',
        'MemberSuite, Inc.',
        'Men&Mice ehf.',
        'Mendix',
        'Mentor Mate',
        'Merchant Services',
        'MeshPay US, Inc.',
        'Metadata.io',
        'Metaswitch Networks',
        'MetricStream',
        'Michael Best & Friedrich LLP',
        'Micro Focus',
        'Micros Systems',
        'Microsoft Access',
        'Microsoft Bookings',
        'Microsoft CSP',
        'Microsoft Dynamics CRM',
        'Microsoft Excel',
        'Microsoft Exchange',
        'Microsoft Field Service',
        'Microsoft Forefront Identity Manager',
        'Microsoft Forefront Unified Access Gateway',
        'Microsoft Lync',
        'Microsoft Office',
        'Microsoft OneNote',
        'Microsoft Outlook',
        'Microsoft PowerApps',
        'Microsoft PowerPoint',
        'Microsoft Project',
        'Microsoft Publisher',
        'Microsoft SharePoint',
        'Microsoft Teams',
        'Microsoft Word',
        'Mighty Networks',
        'Milbank LLP',
        'Miles & Stockbridge P.C.',
        'Millennium Technology',
        'Milliman',
        'Mindgruve',
        'Mirakl',
        'Mitratech',
        'Mixmax',
        'Mixpanel',
        'MoEngage',
        'Mobile Cause',
        'MomentFeed',
        "Moody's Analytics",
        'Moore & Van Allen PLLC',
        'Morae',
        'Morgan, Lewis & Bockius LLP',
        'Morris, Manning & Martin, LLP',
        'Morrison & Foerster LLP',
        'Mortgage Capital Trading',
        'Movate Technologies',
        'Movista',
        'Mu Sigma',
        'N3',
        'NAKIVO, Inc.',
        'NAVEX Global',
        'NEXTDC Limited',
        'NICE Actimize',
        'NPD Group',
        'Nakisa',
        'Narrative.io',
        'Natural Insight',
        'NaviStone',
        'Navigant (NCI)',
        'Navisworks',
        'Nelson Mullins Riley & Scarborough LLP',
        'Neo Technology',
        'Neolane',
        'Nerdery',
        'Net at Work',
        'Netchex',
        'Netenrich',
        'Netscout',
        'Neustar',
        'NewtonX',
        'Nextaff Group, LLC',
        'Nexus Controls',
        'NimbleAMS',
        'Nixon Peabody LLP',
        'Northplains',
        'Northspyre Inc.',
        'Norton Rose Fulbright LLP',
        'Ntiva, Inc.',
        'Nuage Networks',
        'Nuxeo',
        "O'Melveny & Myers LLP",
        'O9 Solutions',
        'ONEDC',
        'OSG',
        'Ocrolus Inc.',
        'Office Mobile',
        'Office Online',
        'Office for Mac',
        'Ogier',
        'Ogletree, Deakins, Nash, Smoak & Stewart, P.C.',
        'Okendo',
        'Okera',
        'Okta (OKTA)',
        'Oktopost',
        'Oliver Wyman',
        'Omedym, LLC',
        'Ometria',
        'On-Demand Group (ODG)',
        'OnBase',
        'One Identity',
        'OneLook, Inc.',
        'OneNeck IT',
        'OneTrust',
        'Onspring',
        'OpenCorporates Ltd.',
        'OpenText Corp.',
        'Options Travel',
        'Oracle Documaker',
        'Oracle E-Business Suite',
        'Oracle ERP Cloud',
        'Oracle Endeca Information Discovery',
        'Oracle Financials Cloud',
        'Oracle Marketing Cloud',
        'Orange Cyberdefense',
        'Orion ERP',
        'Orion Innovation',
        'Orion Practice Management',
        'Orrick, Herrington & Sutcliffe LLP',
        'Outlook.com',
        'Outsystems',
        'OwnBackup',
        'PA Consulting Group',
        'PK Global',
        'PMG Worldwide, LLC',
        'PRO Unlimited',
        'PSB Insights, LLC',
        'PT Visionet Internasional',
        'PTC Windchill',
        'Pandadoc',
        'Pandora for Business',
        'Parametric Technology Corporation',
        'Pariveda Solutions, Inc.',
        'Parley Pro',
        'Pathlight',
        'Patterson Belknap Webb & Tyler LLP',
        'Paul Hastings LLP',
        'Paul, Weiss, Rifkind, Wharton & Garrison LLP',
        'Paychex (PAYX)',
        'Paycor',
        'Payfactors',
        'Paymo LLC',
        'Payscale',
        'Paytronix',
        'Pendo',
        'People.ai',
        'PeopleCert',
        'PeopleNet',
        'Percussion',
        'PerfectLaw',
        'Perficient, Inc.',
        'Perkins Coie LLP',
        'Permutive',
        'Persistent Systems',
        'Personiv',
        'Perx Technologies',
        'Phelps Dunbar LLP',
        'Phocas',
        'Photoshelter',
        'Pillsbury Winthrop Shaw Pittman LLP',
        'PinMeTo',
        'Pipefy',
        'Pitcher Partners',
        'Platform Science',
        'Port53',
        'Porter Wright Morris & Arthur LLP',
        'PostBeyond',
        'Power BI',
        'Power BI for Office 365',
        'PowerChord',
        'PowerObjects',
        'Prager Metis International LLC',
        'Predix',
        'PriceShape',
        'PricewaterhouseCoopers (PwC)',
        'ProLaw',
        'ProcessMaker',
        'Productsup',
        'Proficio',
        'Progress Software Corporation',
        'Proskauer Rose LLP',
        'Protech Associates Inc.',
        'Protiviti',
        'Pryor Cashman LLP',
        'Publicis Sapient',
        'Pulsar Platform',
        'PwC New Zealand',
        'Pyramid Analytics',
        'QBurst',
        'QPR',
        'QX Global Group',
        'Quadient',
        'QualityLogic',
        'Quantilope',
        'Quantivate',
        'Quantum Workplace',
        'Quarles & Brady LLP',
        'Quess Corp Limited',
        'Quinn Emanuel',
        'Quisitive Technology Solutions, Inc.',
        'R Systems',
        'RELX',
        'RSA Archer',
        'RadarFirst',
        'RafterOne',
        'RainMaker Software',
        'Rally Software',
        'Randall-Reilly',
        'ReadyCloud, LLC',
        'Recruiter.com, Inc.',
        'Red Ventures',
        'Redfin',
        'Redpoint Global',
        'Redscan',
        'Reed Smith LLP',
        'Reflektive',
        'Regalix',
        'Repsly',
        'Reputation.Com Inc',
        'RescueTime',
        'Resolver',
        'Resources Global Professionals (RGP)',
        'Restaurant Technologies Inc',
        'Reveal Group',
        'Reviews.io',
        'Rewardstream (REW)',
        'Reynolds and Reynolds',
        'Ricoh USA',
        'RingLead',
        'Ripple',
        'Rizing',
        'Robins Kaplan LLP',
        'Robinson & Cole LLP',
        'Rock Solid Technologies',
        'Roland Berger',
        'RollWorks',
        'Ropes & Gray',
        'RudderStack Inc.',
        'Russell Reynolds',
        'SADA',
        'SAGlobal',
        'SALESmanago',
        'SAP Analytics Cloud',
        'SAP Asset Intelligence Network',
        'SAP BTP (Business Technology Platform)',
        'SAP Business ByDesign',
        'SAP Business One',
        'SAP CDP (Customer Data Platform)',
        'SAP CPQ (Configure, Price, Quote)',
        'SAP Commerce Cloud',
        'SAP Customer Data Management',
        'SAP ERP',
        'SAP Logistics Business Network',
        'SAP Marketing Cloud',
        'SAP Sales Cloud',
        'SAS (Statistical Analysis System)',
        'SCREEN Americas',
        'SGS & Co',
        'SHI International',
        'SLK Global',
        'SMG',
        'SPINS',
        'SULLIVAN & CROMWELL LLP',
        'Sage Business Cloud',
        'Sage X3',
        'SalesDirector.ai',
        'SalesIntel',
        'Salesforce CPQ (Configure, Price, Quote)',
        'Salesforce Chatter',
        'Salesforce Commerce Cloud',
        'Salesforce Community Cloud',
        'Salesforce Data Cloud',
        'Salesforce Field Service',
        'Salesforce High Velocity Sales',
        'Salesforce Management System',
        'Salesforce Marketing Cloud',
        'Salesforce Service Cloud',
        'Salsify',
        'Samsung SDS',
        'Sandler Training',
        'SaneBox, Inc.',
        'Sayari Labs, Inc.',
        'Schulte Roth & Zabel LLP',
        'Scoro',
        'Screendragon',
        'Secured Signing',
        'Seidler Equity Partners',
        'Seismic',
        'Sequretek',
        'ServiceSource (SREV)',
        'ServiceTitan',
        'SessionM',
        'Seyfarth Shaw LLP',
        'ShareVault',
        'Sharebite',
        'Sharpen',
        'Shearman & Sterling LLP',
        'Sheppard, Mullin, Richter & Hampton LLP',
        'Sherman & Howard L.L.C.',
        'Shinydocs Corporation',
        'Shook, Hardy & Bacon L.L.P.',
        'Showpad',
        'Shufti Pro',
        'Shumaker, Loop & Kendrick, LLP',
        'Sia Partners',
        'Sidley Austin LLP',
        'Siemens Opcenter',
        'Siemens Teamcenter',
        'Sierra-Cedar',
        'Sift',
        'Signal',
        'SignalWire Inc.',
        'Signavio',
        'Silverline',
        'Simpli.Fi',
        'Simplus',
        'Simply Business',
        'Simpson Thacher & Bartlett LLP',
        'SingleStone',
        'SirionLabs, Inc.',
        'SiriusDecisions',
        'Site24x7',
        'Sitecore',
        'Skadden, Arps',
        'Skience',
        'Skillsoft',
        'Skuuudle',
        'SkyNRG B.V.',
        'Skype for Business',
        'Skyward',
        'Slack',
        'Slalom',
        'Slimstock',
        'Slintel',
        'Smart Communications',
        'SmartCloud, LLC',
        'Smartsheet',
        'Socius Insurance Services, Inc.',
        'Socure',
        'Sodexo',
        'Sofon',
        'SoftExpert',
        'Solimar',
        'Sopro LLC',
        'SourceMap',
        'Spacewell',
        'Sparkfly',
        'Spencer Stuart',
        'Sphera',
        'Spiff',
        'Splashtop',
        'Spreetail',
        'Spring Boot',
        'Spryker',
        'Squire Patton Boggs LLP',
        'Staffbase',
        'Stampli',
        'Stantec',
        'Staples Advantage',
        'Startek',
        'Steptoe & Johnson LLP',
        'Stewart Title',
        'Stinson LLP',
        'Stitch Labs',
        'Stoel Rives LLP',
        'Strategy&',
        'Stratix',
        'Sullivan & Worcester LLP',
        'Sumo Logic',
        'SunGard',
        'Superhuman Labs, Inc.',
        'Superscript',
        'SurePoint',
        'Survey Monkey',
        'Swell Rewards',
        'Sync',
        'Syncron',
        'Syndigo',
        'Synnex (SNX)',
        'TM1',
        'TOPO',
        'TOTVS',
        'TRG Screen',
        'TTEC (TTEC)',
        'Tacton',
        'Taft Stettinius & Hollister LLP',
        'Talkdesk',
        'Tally',
        'Tatari.TV',
        'Taulia',
        'TeamLease Services Limited',
        'TeamSupport',
        'TeamViewer',
        'Teikametrics',
        'TeleSign',
        'Televerde',
        'Tellius',
        'Telnyx',
        'Telus International',
        'Tenfold',
        'Tervene',
        'Textline, Inc.',
        'The Access Group',
        'The Arena Group Holdings, Inc.',
        'The Barbarian Group',
        'The CFO Alliance',
        'The Dingley Press',
        'The Hackett Group, Inc.',
        'The Lacek Group',
        'The Missing Link',
        'Thompson Coburn LLP',
        'Thompson Hine LLP',
        'Thomson Elite 3E',
        'Thought Industries',
        'ThreatConnect Inc.',
        'ThreatQuotient',
        'Thryv',
        'Time by Ping',
        'TimeTrade',
        'Timeslips',
        'Tipalti Inc.',
        'Toggl',
        'Toggl Plan',
        'Toggl Track',
        'Toluna',
        'Top Down Systems Corporation',
        'Topflight Corporation',
        'Totango',
        'Touchcast',
        'TraceGains, Inc.',
        'Traction on Demand',
        'Tradeshift',
        'Transaction Network Services',
        'Transcom WorldWide AB',
        'Transparent BPO',
        'Transperfect Inc',
        'TravelBank',
        'Treasury Intelligence Solutions',
        'Trellance, Inc.',
        'Trello',
        'Trendalytics',
        'Trianz',
        'Tribe Dynamics',
        'Tricentis',
        'TriplePoint',
        'Troutman Pepper Hamilton Sanders LLP.',
        'Trulioo',
        'Trustwave',
        'Tugboat Logic',
        'Tulip Interfaces Inc.',
        'Turtl',
        'Twist',
        'Twitter Analytics',
        'UJET',
        'UTokyo IPC',
        'Uberflip',
        'Ultimate Billing',
        'Unanet',
        'United Language Group',
        'UnitedLex',
        'Unleashed Software',
        'UpGuard',
        'VSA',
        'VVDN Technologies',
        'VXI Global Solutions',
        'Valorem Reply',
        'Valtech',
        'VanillaSoft',
        'Vantage Data Centers',
        'VantagePoint',
        'Vasion',
        'Vedder Price P.C.',
        'Veeqo Ltd',
        'Vena Solutions',
        'Venable LLP',
        'Vendavo',
        'Venminder, Inc.',
        'Verafin',
        'Verenia',
        'Vericast Corp',
        'Veriforce, LLC',
        'Verint',
        'Verisk',
        'Verizon One Talk',
        'Versa Networks',
        'Versent',
        'Vertiv',
        'Vinson & Elkins LLP',
        'Vistex',
        'Vital4',
        'Voiteq',
        'Volaris Group',
        'Vology',
        'Vouched',
        'Vungle',
        'W.B. Mason Co., Inc.',
        'WBT Systems Limited',
        'WPS Office',
        'WSP',
        'Wachtell, Lipton, Rosen & Katz LLP',
        'WalkMe',
        'Walmart Business+',
        'WebEngage',
        'WebEx',
        'Webhelp',
        'Weborama',
        'Webtrends',
        'Weil, Gotshal & Manges LLP',
        'West Monroe',
        'Westcon-Comstor',
        'Western Union Business Solutions',
        'Wex Inc. (WEX)',
        'White & Case LLP',
        'Widen',
        'Wiley Rein LLP',
        'Williams & Connolly',
        'Williams Mullen',
        'Williams Scotsman, Inc.',
        'Willkie Farr & Gallagher LLP',
        'Wilmer Cutler Pickering Hale and Dorr LLP',
        'Wilson Elser Moskowitz Edelman & Dicker LLP',
        'Wilson Sonsini Goodrich & Rosati, Professional Corporation',
        'Windstream',
        'Wingman',
        'Winmo',
        'Winstead PC',
        'Winston & Strawn LLP',
        'Wiser Solutions',
        'WolfPAC',
        'Wolters Kluwer',
        'Work Market Inc',
        'WorkBoard, Inc.',
        'WorkJam',
        'WorkNest',
        'Workato',
        'Workday Adaptive Planning',
        'Workforce Software',
        'Workfront',
        'Working Solutions, Inc.',
        'Workiva Wdesk',
        'Workplace by Facebook',
        'World Software Corporation (Worldox)',
        'XANT',
        'Xactly',
        'Xebia',
        'Xigent Solutions, LLC',
        'Xoriant',
        'YRC Freight',
        'YesWare',
        'Yottaa',
        'YouGov',
        'YourMembership.com, Inc.',
        'Zaptic',
        'Zendesk (ZEN)',
        'Zensar Technologies (ZENSARTECH)',
        'Zeta Global',
        'Zinklar.com',
        'Zintro',
        'Zoot Enterprises',
        'Zylo',
        'Zywave, Inc.',
        'aPriori',
        'americaneagle.com',
        'cPanel',
        'dunnhumby',
        'e-Spirit',
        'ezCater, Inc.',
        'iGrafx, LLC',
        'iT360 Ltd.',
        'iTechArt Group',
        'ibex',
        'inContact',
        'inSided',
        'kipi.bi',
        'lemlist',
        'miEdge',
        'mnemonic.io',
        'netfiles GmbH'
      ])
    ]
  },
  {
    id: 17,
    label: 'Business Solutions',
    children: [
      ...new Set([
        '4PL',
        'AT&T Content and Entertainment',
        'AWS Identity and Access Management',
        'Aerial Mapping',
        'Aladdin',
        'Application Platform as a Service (aPaaS)',
        'Appointment Scheduling Software',
        'AppointmentPlus',
        'Association Membership System',
        'Automated Contract Management',
        'Automated Customer Onboarding',
        'Avaya IP Office™',
        'Azure AD Entitlement',
        'B2B Marketplace',
        'B2B Secure Collaboration',
        'B2B Technology',
        'Bar Coding',
        'Barcode Software',
        'Batch scheduling software',
        'Building Management System (BMS)',
        'Business Central Stock Control',
        'Business Growth Software',
        'Business Integration',
        'CELUM',
        'Centralized Knowledge',
        'Chat Tool',
        'ClickUp',
        'Cloud Communication Platforms',
        'Cloud Digital Asset Management',
        'CloudApp',
        'Collaboration Portal',
        'Commercial Off-The-Shelf (COTS)',
        'Compliance Automation',
        'Computerized Maintenance Management System (CMMS)',
        'Connected Worker Platforms',
        'Contact Center Outsourcing',
        'Contact Center Solutions',
        'Containerization',
        'Content Integration',
        'Continuous Process',
        'Corporate Website',
        'Crowdsourcing',
        'Customer Communication Management Software',
        'Customer Experience Platform',
        'Customer Portal',
        'Customer Satisfaction Score',
        'Customer Self Service',
        'Customer Self Service Tools',
        'Customer Service Live Chat',
        'Customer Service Platform',
        'Cyber-Physical System (CPS)',
        'Data as a Service (DaaS)',
        'Deal Management',
        'Decision Engine',
        'Device-as-a-Service (DaaS)',
        'Digital Customer Service',
        'Digital Experience Platform (DXP)',
        'Digital Factory',
        'Digital Work Instructions',
        'Digital Workspace',
        'Distribution Management Software',
        'Dynamics 365 Inventory Management',
        'Embedded Voice',
        'End User Computing',
        'End User Transformation',
        'Enterprise Contact Center',
        'Equinix Connect',
        'Equinix Metal',
        'Equinix Network Edge',
        'Equities Management Software',
        'Event Management Software',
        'Executive Information Systems',
        'Facial Recognition',
        'Field Service Automation',
        'Google Business Profile',
        'Help Desk / Service Desk',
        'Help Desk Software',
        'Homeowners Association (HOA) Management Software',
        'Hosted Contact Center',
        'Hybrid Deployment',
        'IP Address Management (IPAM)',
        'Incredibuild',
        'Inside Sales',
        'Institutional Asset Management Platform',
        'Insured Portal',
        'Integrated Workplace Management Software (IWMS)',
        'Intelligent Automation',
        'Issue Tracking System',
        'Kintone',
        'Kiosks',
        'Logistics and Procurement',
        'Managed Service Provider',
        'Media Asset Management',
        'Microsoft Dynamics Business Central',
        'Microsoft Sales Accelerator',
        'Mid-Market Technology',
        'Mobile Workforce Automation',
        'Notion',
        'Open Source Audit',
        'Open Source Security',
        'Oracle Cloud Enterprise Performance Management (EPM)',
        'Oracle Subscription Management',
        'Outsourced IT Services',
        'Partner Management Software',
        'Partner Relationship Management (PRM)',
        'Patient Scheduling Software',
        'Point of Sale (POS) System',
        'Portal Solutions',
        'Predictive Apps',
        'Presentation Software',
        'Process Control',
        'Process Improvement',
        'Process Simulation',
        'Procurement Solutions',
        'Professional Services Automation',
        'Program Management',
        'Project Management Software',
        'Property Management Software',
        'Property and Casualty (P&C) Insurance Software',
        'PunchOut catalog',
        'QuickBooks',
        'RISE with SAP',
        'Real Time Applications',
        'Real-Time Data Processing',
        'Remote Assistance',
        'Remote Surveys',
        'Rent Property',
        'Reporting Automation',
        'Request For Proposal (RFP)',
        'Risk Automation',
        'Risk Management Software',
        'SAP Business Network',
        'SAP Business Planning and Consolidation (SAP BPC)',
        'SAP Hyperscalers',
        'SAP on Google GCP',
        'SaaS Backup',
        'SaaS Management Platform (SMP)',
        'Sales Dialing Automation',
        'Salesforce Scheduler',
        'Self Service Portal',
        'Self-Service Technology',
        'Services Procurement',
        'Shipping Software',
        'Sitefinity',
        'Small Business Printing',
        'Smart Retail',
        'Software Broker',
        'Software as a Service (SaaS)',
        'Space and Move Management Software',
        'Speech Recognition',
        'Splunk Light',
        'Splunk Next',
        'Splunk On-Call',
        'Stock Keeping Unit (SKU)',
        'System Center',
        'Technical and Competitive IT (TaCIT)',
        'Tendering Software',
        'Third-Party Support',
        'Ticketing Software',
        'Tour Operator Software',
        'Unify Contact Center',
        'Universal Product Code (UPC)',
        'VMS',
        'VMware Tanzu',
        'Vendor Managed Inventory (VMI)',
        'Vendor Management System',
        'Vertical Applications',
        'Visitor Management',
        'Voice Recognition',
        'Warehouse Management System',
        'Work Management',
        'Workflow Automation',
        'Workflow Software',
        'Workplace Technology',
        'eProcurement',
        'iAuditor'
      ])
    ]
  },
  {
    id: 18,
    label: 'Business Structure',
    children: [
      ...new Set([
        'Benefit Corporation',
        'Carbon Border Adjustment Mechanism',
        'Cooperative',
        'Corporate Mid-Market',
        'Corporate Restructuring',
        'Corporate Sustainability Reporting Directive',
        'Corporation',
        'Digital Market Act (DMA)',
        'German Supply Chain Act',
        'Limited Liability Company (LLC)',
        'Limited Liability Partnership (LLP)',
        'Limited Partnership',
        'MORE Act (Marijuana Opportunity Reinvestment and Expungement Act)',
        'Nonprofit Corporation',
        'Partnership',
        'Private Company',
        'Registered Agent',
        'S Corporation',
        "Shareholders' Agreement",
        'Sole Proprietorship'
      ])
    ]
  },
  {
    id: 19,
    label: 'CRM',
    children: [
      ...new Set([
        'Average Handle Time (AHT)',
        'Buyer Centricity',
        'Buyer Journey',
        'CRM Best Practices',
        'CRM Management',
        'CRM Retargeting',
        'CRM Software',
        'Channel Partner Program',
        'Churn Rate',
        'Connected Experience',
        'Customer Advocacy',
        'Customer Attrition',
        'Customer Behavior',
        'Customer Centricity',
        'Customer Communications',
        'Customer Data',
        'Customer Data Integration',
        'Customer Data Management',
        'Customer Data Platform',
        'Customer Experience Management',
        'Customer Experience and Engagement',
        'Customer Facing',
        'Customer Insight',
        'Customer Intelligence (CI)',
        'Customer Intent',
        'Customer Interaction Management',
        'Customer Journey',
        'Customer Journey Analytics',
        'Customer Journey Map',
        'Customer Lifecycle',
        'Customer Lifetime Value (CLV)',
        'Customer Loyalty',
        'Customer Profiling',
        'Customer Referrals',
        'Customer Relationship Management (CRM)',
        'Customer Retention',
        'Customer Review',
        'Customer Satisfaction',
        'Customer Segmentation',
        'Customer Service Automation',
        'Customer Success Management',
        'Customer Support Analytics',
        'Digital Experience',
        'Email Management Software',
        'First Call Resolution (FCR)',
        'High-Value Customer',
        'LexisNexis InterAction',
        'Loyalty Management',
        'Marketing Automation',
        'Mobile App Engagement',
        'Net Promoter',
        'Omnichannel',
        'Potential Customer',
        'Progressive Profile',
        'Relationship Marketing',
        'Remarketing',
        'Reputation Management',
        'Reputation Monitoring',
        'Retail Therapy',
        'Revenue Management',
        'Sales Force Automation (SFA)',
        'Salesforce Consulting',
        'Salesforce Developer',
        'Salesforce Partners',
        'Social Relationship Management',
        'Voice of the Customer',
        'XRM'
      ])
    ]
  },
  {
    id: 20,
    label: 'Campaigns',
    children: [
      ...new Set([
        'Ad Copy',
        'Adobe Campaign',
        'Advertising Campaign',
        'Branding',
        'Campaign Management',
        'Conversion Marketing',
        'Creative Services',
        'Daily Deals',
        'Demographics',
        'Direct Response (DR)',
        'Display and Video 360',
        'Dynamic Creative',
        'Gift with Purchase (GwP)',
        'Marketing Campaign',
        'National Cyber Security Awareness Month (NCSAM)',
        'Online Video Marketing',
        'Pay Per Click (PPC)',
        'QR Code',
        'Sales Samples',
        'Sponsorship'
      ])
    ]
  },
  {
    id: 21,
    label: 'Certifications',
    children: [
      ...new Set([
        'Certificate Authority (CA)',
        'Certificate Management',
        'Certificate Signing Request (CSR)',
        'Cisco Certification',
        'Microsoft Certification',
        'PMP'
      ])
    ]
  },
  {
    id: 22,
    label: 'Channels & Types',
    children: [
      ...new Set([
        'Account-Based Marketing (ABM)',
        'Account-Based Sales Development (ABSD)',
        'Advertising',
        'Advocacy Programs',
        'Affiliate Marketing',
        'Augmented Reality (AR)',
        'Automated Marketing',
        'B2B Marketing',
        'Bounce Rate',
        'Brick and Mortar (B&M)',
        'Broadcast',
        'Call To Action (CTA)',
        'Campus Technology',
        'Cause Marketing',
        'Channel Conflict',
        'Channel Management',
        'Channel Management Software',
        'Channel Marketing',
        'Channel Partner Management',
        'Channel Program',
        'Channel Recruitment',
        'Closed-Loop Marketing (CLM)',
        'Cloud Marketing',
        'Cloud-Based Digital Signage',
        'Consumer Behavior',
        'Consumer Marketing',
        'Daily Active Users (DAU)',
        'Data-Driven Marketing',
        'Digital',
        'Digital Marketing',
        'Digital Media',
        'Digital Place-Based Advertising',
        'Digital Signage',
        'Digital Signage Experts Group',
        'Dimensional Marketing',
        'Direct Mail',
        'Direct Marketing',
        'Direct-to-Consumer Advertising (DTC advertising)',
        'Ecommerce Marketing',
        'Engagement Platform',
        'Exact Match',
        'Experiential Marketing',
        'Face-to-face Interaction',
        'Field Marketing',
        'Guerrilla Marketing',
        'In-Image Advertising',
        'Inbound Marketing',
        'Influencer Marketing',
        'Integrated Marketing',
        'Interactive Marketing',
        'Interactive Video Marketing',
        'International Marketing',
        'Internet Marketing',
        'LCD Digital Signage',
        'LED Billboards',
        'LED Digital Signage',
        'LED Message Display',
        'Loan Marketing',
        'Local Marketing',
        'Location-Based Marketing',
        'Mail Order',
        'Marketing Collaboration',
        'Marketing Communications',
        'Marketing Programs',
        'Marketing Spending',
        'Media Attribution',
        'Mobile',
        'Mobile Marketing',
        'Modern Marketing',
        'Mortgage Marketing',
        'Multi-Touch Attribution',
        'Multichannel Marketing',
        'Native Advertising',
        'Networked Digital Signage',
        'Niche Marketing',
        'One-To-One Marketing',
        'Online',
        'Out of Home',
        'Outdoor Digital Advertising',
        'Outdoor Digital Displays',
        'Outdoor Display',
        'Outsource Marketing',
        'People-Based Marketing',
        'Performance Marketing',
        'Point-of-Sale Digital Signage',
        'Pre-Roll Video',
        'Print',
        'Product Marketing',
        'Radio',
        'Radio Advertising',
        'Retail Digital Displays',
        'Retail Digital Signage',
        'Retail Marketing',
        'Satellite Television',
        'Scent Marketing',
        'Semi-Outdoor Display',
        'Services Marketing',
        'Shopper Marketing',
        'Small Business Marketing',
        'Social Media Optimization (SMO)',
        'Streaming Media',
        'Telemarketing',
        'Television',
        'Touch Screen Digital Signage',
        'Traditional Marketing',
        'Unified Commerce',
        'Unique Selling Proposition (USP)',
        'Vertical Market',
        'Video Billboard',
        'Video Digital Signage',
        'Video Marketing ROI',
        'Video Marketing Strategy',
        'Video Wall',
        'Virtual Events',
        'Visual Display',
        'Washington Technology',
        'Web Banner',
        'Word of Mouth'
      ])
    ]
  },
  {
    id: 23,
    label: 'Chromatography',
    children: [
      ...new Set([
        'Chromatography Consumables',
        'Chromatography Software',
        'Gas Chromatograph',
        'Gas Chromatography',
        'High-Performance Liquid Chromatography (HPLC)',
        'Ion Chromatography'
      ])
    ]
  },
  {
    id: 24,
    label: 'Civil',
    children: [
      ...new Set([
        'Aboriginal Law',
        'Administrative Law',
        'Admiralty',
        'Advertising & Marketing Law',
        'Amusement Park Injury',
        'Automobile Accident',
        'Aviation Accidents',
        'Aviation Law',
        'Beneficial Owner',
        'Beneficiary',
        'Business Torts',
        'Civil Appeal',
        'Civil Procedure',
        'Civil Rights',
        'Class Action',
        'Collective Redress',
        'Competition Law',
        'Computer & IT Law',
        'Construction Defect',
        'Construction Law',
        'Consumer Protection',
        'Corporate Commercial Law',
        'Corporate Finance & Securities Law',
        'Corporate Risk',
        'Criminal Law',
        'Damage Assessments',
        'Defamation',
        'Deposition',
        'Derivative Instruments',
        'Disability Law',
        'Discovery',
        'Driver fatigue',
        'Education Law',
        'Elderlaw',
        'Entertainment Law',
        'Executor',
        'Expert Witness',
        'Family Law',
        'Fiduciary Assessment',
        'Fiduciary Duty',
        'Financial Institutions Regulatory Law',
        'Franchise Law',
        'Heir',
        'Infrastructure Law',
        'Insolvency Law',
        'Intestacy Laws',
        'Judgments',
        'Landlord Tenant Law',
        'Lein',
        'Libel',
        'Life Sciences & Health Law',
        'Living Will',
        'Mass Torts',
        'Mining Law',
        'Negligence',
        'Not-for-Profit Law',
        'Personal Injury',
        'Premises Liability',
        'Probate',
        'Product Liability Litigation',
        'Professional Liability',
        'Professional Malpractice',
        'Property Development Law',
        'Property Leasing',
        'Public Record',
        'Punitive Damages',
        'Records Management',
        'Regulatory and Public Law Litigation',
        'Shipping & Maritime Law',
        'Slander',
        'Small Estate Administration',
        'Sustainability Accounting Standards Board',
        'Sustainable Development Goals',
        'Sustainable Finance Disclosure Regulation',
        'Telecommunications Law',
        'Toxic Torts',
        'White Collar Crime',
        'Wrongful Death'
      ])
    ]
  },
  {
    id: 25,
    label: 'Clinical & Diagnostics',
    children: [
      ...new Set([
        'Anatomical Pathology',
        'Anti-infectives',
        'Biobank',
        'Bioelectrical Impedance Analysis (BIA)',
        'Biologic License Application (BLA)',
        'Biologics Drug Development',
        'Cancer Research',
        'Cell Therapy',
        'Clinical Data Management',
        'Clinical Pharmacology',
        'Clinical Study Design',
        'Clinical Trial Simulation',
        'Cytology',
        'Diagnostic Imaging',
        'Disease',
        'Drug Abuse',
        'Drug Development',
        'Drug Discovery',
        'Drug Pipeline',
        'Drug Recovery',
        'Genetic Analysis',
        'Global Value Dossier',
        'Health Economics Outcomes Research (HEOR)',
        'High-throughput Screening (HTS)',
        'Histology',
        'Impurity Testing',
        'Investigational New Drug (IND)',
        'Laboratory Automation',
        'Laboratory Glassware',
        'Market Access',
        'Medical Test',
        'Model-Informed Drug Development (MIDD)',
        'Overdose',
        'PK/PD Analysis',
        'Pharma 4.0',
        'Pharmaceutical Litigation',
        'Pharmacodynamics',
        'Pharmacogenomics',
        'Pharmacokinetics',
        'Pharmacometrics',
        'Physiologically-Based Pharmacokinetics (PBPK)',
        'Quantitative Systems Pharmacology (QSP)'
      ])
    ]
  },
  {
    id: 26,
    label: 'Cloud',
    children: [
      ...new Set([
        'AT&T Cloud',
        'AWS Marketplace',
        'AWS Outposts',
        'Autoscaling',
        'Azure Marketplace',
        'Backup as a Service (BaaS)',
        'Bare-Metal Cloud',
        'Big Data-as-a-Service (BDaaS)',
        'Cloud Access Security Broker (CASB)',
        'Cloud Applications',
        'Cloud Architect',
        'Cloud Automation',
        'Cloud Backbone',
        'Cloud Backup / Recovery',
        'Cloud Co-Selling',
        'Cloud Communications',
        'Cloud Compliance',
        'Cloud Computing',
        'Cloud Cost Optimization',
        'Cloud Data',
        'Cloud ERP',
        'Cloud FinOps',
        'Cloud Hosting',
        'Cloud IDE',
        'Cloud Infrastructure',
        'Cloud Integration',
        'Cloud Managed Networking',
        'Cloud Management',
        'Cloud Management Platform',
        'Cloud Marketplace',
        'Cloud Marketplace Metering',
        'Cloud Marketplace Software',
        'Cloud Migration',
        'Cloud Native Application Protection Platform (CNAPP)',
        'Cloud OS',
        'Cloud Onboarding',
        'Cloud Orchestration',
        'Cloud Providers',
        'Cloud Provisioning',
        'Cloud Security',
        'Cloud Security Posture Management (CSPM)',
        'Cloud Server',
        'Cloud Services',
        'Cloud Services Brokerage',
        'Cloud Storage',
        'Cloud Strategy',
        'Cloud VC',
        'Cloud Visibility',
        'Cloud Workload Protection Platform (CWPP)',
        'Cloud as a Service',
        'Cloud-Based Analytics',
        'Cloud-Based Unified Communications',
        'Cloud-Connected Audio',
        'Cloud-Native Application Development',
        'Cloud-Native Modernization',
        'Cloud-Native Security',
        'Communications Platform as a Service (CPaaS)',
        'Contact Center as a Service (CCaaS)',
        'Containers',
        'Data Fabric',
        'Data Lake',
        'Data Protection as a Service (DPaas)',
        'Dell APEX',
        'Desktop as a Service (DaaS)',
        'Digital Transaction',
        'Digital Transaction Management (DTM)',
        'Disaster Recovery as a Service (DRaaS)',
        'Enterprise Mobility Suite',
        'FIFO (First In, First Out)',
        'GCP Marketplace',
        'Google Cloud IoT Core',
        'HP Cloud Services',
        'Hosted Application',
        'Hybrid Applications',
        'Hybrid Cloud',
        'Hybrid Cloud Architecture',
        'IT as a Service (ITaaS)',
        'Identity as a Service (IDaaS)',
        'Integrated Cloud Services',
        'Multicloud',
        'Native Cloud Application (NCA)',
        'Private Cloud',
        'Public Cloud',
        'Red Hat Cloud Suite',
        'Red Hat Marketplace',
        'Reference Architecture',
        'Remote Procedure Call (RPC)',
        'Sales Cloud',
        'SolidFire',
        'Spot by NetApp',
        'Velero',
        'Virtual Private Cloud',
        'Web-Scale IT'
      ])
    ]
  },
  {
    id: 27,
    label: 'Commodity',
    children: [
      ...new Set([
        'Aluminum',
        'Brent Crude',
        'Canola',
        'Coal',
        'Cocoa',
        'Coffee',
        'Commodity Channel Index',
        'Copper',
        'Corn',
        'Cotton',
        'Crude Oil',
        'Dairy',
        'Ethanol',
        'Exchange-Traded Commodities (ETC)',
        'Feeder Cattle',
        'Fruit',
        'Gasoil',
        'Gasoline',
        'Gold',
        'Gold Spot',
        'Grapes',
        'Heating Oil',
        'Hemp',
        'Hops',
        'Iron Ore',
        'Kerosene',
        'Lean Hogs',
        'Light Sweet Crude Oil',
        'Live Cattle',
        'Maize',
        'Minor Bulks',
        'Natural Gas',
        'Nickel',
        'Oats',
        'Peanuts',
        'Platinum',
        'Poultry',
        'RBOB Gasoline',
        'Rice',
        'Rubber',
        'Silver',
        'Sorghum',
        'Soybean',
        'Soybean Meal',
        'Soybean Oil',
        'Sugar beets',
        'Vegetables',
        'WTI Crude Oil',
        'Wheat'
      ])
    ]
  },
  {
    id: 28,
    label: 'Compliance & Governance',
    children: [
      ...new Set([
        'ADA Compliance Services',
        'American Data Privacy and Protection Act',
        'Board Governance',
        'Board of Directors',
        'Business Law',
        'Business Licenses',
        'California Privacy Rights Act (CPRA)',
        'California Proposition 65',
        'Canadian Anti-Spam Legislation (CASL)',
        'Compliance',
        'Compliance Audit',
        'Compliance Management',
        'Corporate Documents',
        'Corporate Governance',
        'Corporate Responsibility',
        'Customer Due Diligence (CDD)',
        'Customs-Trade Partnership Against Terrorism (C-TPAT)',
        'Data Compliance',
        'Data Subject Access Request (DSAR)',
        'Do Not Call (DNC)',
        'Driver Verification',
        'ESG Assurance',
        'ESG Reporting',
        'EU REACH',
        'Enterprise Data Governance',
        'FCPA Compliance',
        'FISMA',
        'Forestry Certification',
        'Global Business Services (GBS)',
        'Global In-House Centers (GICs)',
        'Governance Strategy',
        'Governance, Risk Management, and Compliance (GRC)',
        'Internal Investigations',
        'Investigation Software',
        'NERC CIP Compliance',
        'NIST Compliance',
        'OSFI Review',
        'POPs Regulation',
        'Records and Information Management (RIM)',
        'Regulatory Compliance',
        'SEC Regulation',
        'SOC 1',
        'SOC II Compliance',
        'Service Level Agreement (SLA)',
        'Service Organization Controls (SOC)',
        'Standard Communication Protocols',
        'Statutory Reporting',
        'Sustainable Compliance Management',
        'Telemarketing Sales Rule',
        'Toxic Substances Control Act (TSCA)',
        'Transparency Report',
        'USA PATRIOT Act',
        'Unit Investment Trust (UIT)'
      ])
    ]
  },
  {
    id: 29,
    label: 'Construction',
    children: [
      ...new Set([
        '4D Scheduling',
        'Aeroseal Technology',
        'Air Duct Cleaning',
        'Appliances',
        'Architectural Design',
        'Asphalt Roofing',
        'Automatic Doors',
        'Autonomous Building',
        'Backhoe Loaders',
        'Bathroom',
        'Bathroom Installation',
        'Build to Rent (BTR)',
        'Building Automation',
        'Building Envelope Sealing',
        'Building Envelope Solutions',
        'Bulk Building Materials',
        'Bulldozers',
        'Carpet Cleaning',
        'Commercial Building',
        'Commercial Caulking Services',
        'Commercial Painting',
        'Commercial Window Systems',
        'Commercial Worksite',
        'Compact Track Loaders',
        'Concrete Maintenance',
        'Concrete Reinforcement',
        'Construction',
        'Construction Documentation',
        'Construction Equipment',
        'Construction Layout',
        'Construction Loan',
        'Construction Planning',
        'Construction Software',
        'Construction Standards',
        'Construction Technology',
        'Construction camera',
        'Construction inspection',
        'Corrosion Protection for Utility Structures',
        'Crawler Loaders',
        'Davit Arm',
        'Decontamination',
        'Dehumidification Services',
        'Delay in Completion',
        'Demolition',
        'Design-build (D-B)',
        'Digital Building Transformation',
        'Dive Support Vessel (DSV)',
        'Door Installation',
        'Downhole Tools',
        'Drilling Rigs',
        'Drilling Vessels',
        'Dryer Vent Cleaning',
        'Duct Sealing Services',
        'Dump Trucks',
        'EPC Contractor',
        'Earthmoving Machinery',
        'Electrical Fixtures',
        'Electrical Installation',
        'Emergency Board-Up Services',
        'Emergency Management',
        'Epoxy Floor Coating Services',
        'Excavators',
        'Facade Cladding Materials',
        'Faucet Installation',
        'Faucets',
        'Field Service Management',
        'Field Trailers',
        'Fire Damage',
        'Fire Doors',
        'Fire Inspection',
        'Fire Panel',
        'Flooring',
        'Flooring Installation',
        'Framing',
        'General Contractor',
        'Glass Fronts',
        'Glass Walls',
        'Golf Course Maintenance',
        'HVAC (Heating, Ventilation, and Air Conditioning)',
        'HVAC Electrification',
        'HVAC Installation',
        'HVAC Maintenance',
        'High Bay Lights',
        'High Strength Steel',
        'High-Speed Doors',
        'Home Decor',
        'Impact Resistant Doors',
        'Industrial Hygiene',
        'Industrial Painting (Coating)',
        'Industrialized Construction',
        'Infrastructure Resilience Solutions',
        'Insulation Products',
        'Intelligent Transportation System',
        'Interior Design',
        'Jack Up Rigs',
        'KNX',
        'Kitchen Remodeling',
        'Landscape Architecture',
        'Lifting Equipment',
        'Lighting Fixtures',
        'Lighting Installation',
        'Loaders',
        'Main Automation Contractor',
        'Masonry',
        'Membrane Roofing',
        'Mobile Lightning Tower',
        'Modular Building',
        'Modular Construction',
        'Modular Walls',
        'Mold Removal',
        'Motor Graders',
        'Multifamily Residential',
        'Occupant Well-Being',
        'Office Build',
        'Outdoor Living',
        'Paving Services',
        'Pipeline and Cable Laying',
        'Plain Carbon Steel',
        'Plumbing',
        'Pole Attachment Management',
        'Polished Concrete',
        'Post-Construction Cleaning',
        'Prefabricated Walls',
        'Property Management',
        'Punch List',
        'Quarry & Aggregate',
        'Reconstruction',
        'Remote Accommodations',
        'Rework',
        'Roof Tarping',
        'Roofing Consultants',
        'Roofing Mastics',
        'Scraper Systems',
        'Semi-Submersibles',
        'Senior Housing',
        'Service Dispatch',
        'Shipyards',
        'Site Container',
        'Site Preparation',
        'Site Selection Analysis',
        'Skid Steer Loader',
        'Smart Buildings',
        'Smart Stadium',
        'Smoke Damage',
        'Snow Removal Equipment',
        'Steel Detailing',
        'Steel Framing Supplies',
        'Storm Damage',
        'Submittals',
        'Surface Mining',
        'Sustainable Construction',
        'Sustainable Waste Management',
        'Temporary Building',
        'Total Station',
        'Tractor Loaders',
        'Tractors',
        'Traffic Management',
        'Trash Services',
        'Truck Loaders',
        'Vegetation Management Services',
        'Ventilation System Optimization',
        'Walk-in Cooler Doors',
        'Walk-in Freezer Doors',
        'Waste Equipment',
        'Water Damage',
        'Water Extraction Services',
        'Waterproofing Products',
        'Welfare Unit',
        'Wheel Loaders',
        'Window Installation',
        'Window Treatment Installation',
        'Work Order Management',
        'Work Platforms'
      ])
    ]
  },
  {
    id: 30,
    label: 'Consumer',
    children: [
      ...new Set([
        'Antitrust',
        'California Age-Appropriate Design Code Act (CA AADC)',
        'California Consumer Privacy Act (CCPA)',
        "Children's Online Privacy Protection Act (COPPA)",
        'Colorado Privacy Act (CPA)',
        'Connecticut Data Privacy Act (CTDPA)',
        'Consumer Goods',
        'Family Educational Rights and Privacy Act (FERPA)',
        'General Data Protection Regulation (GDPR)',
        'Magnuson-Moss Warranty Act',
        'Personal Information Protection and Electronic Documents Act (PIPEDA)',
        'Personally Identifiable Information (PII)',
        'Product Liability',
        'SHIELD Act',
        'Telephone Consumer Protection Act (TCPA)',
        'Truth In Lending Act (TILA)',
        'Utah Consumer Privacy Act (UCPA)',
        'Virginia Consumer Data Protection Act (VCDPA)',
        'Warranty'
      ])
    ]
  },
  {
    id: 31,
    label: 'Content',
    children: [
      ...new Set([
        'Above the Fold',
        'CMS Tools',
        'Content Aggregation',
        'Content Creation Tools',
        'Content Governance',
        'Content Ingestion',
        'Content Licensing',
        'Content Management',
        'Content Management System (CMS)',
        'Content Marketing',
        'Content Marketing Analytics',
        'Content Personalization',
        'Content Planning',
        'Content Playout',
        'Content Recommendation',
        'Content Strategy',
        'Copywriting',
        'Corporate Communications',
        'Design Template',
        'Dynamic Video',
        'Employee Generated Content (EGC)',
        'Enterprise Content Management',
        'Headless CMS',
        'Live Content Feed',
        'Media Management Systems',
        'Press Kits',
        'Press Releases',
        'Print Collateral',
        'Social Content Management',
        'Target Audience',
        'Television Production',
        'User Experience (UX)',
        'Video Translation',
        'Visual Communication',
        'WYSIWYG'
      ])
    ]
  },
  {
    id: 32,
    label: 'Contracts',
    children: [
      ...new Set([
        'Alternative Fee Arrangement',
        'Alternative Investment Fund Managers Directive (AIFMD)',
        'Breach of Contract',
        'Certificate of Competency',
        'Contract Management',
        'Contract Repository',
        'Contract Review',
        'Contractual Disputes',
        'Due Diligence',
        'Engineering, Procurement, and Construction (EPC)',
        'Equal Credit Opportunity Act',
        'Escrow Agent',
        'Exclusion Clause',
        'False Claims Act',
        'Force Majeure',
        'Implied Terms',
        'Injunction',
        'Licensee',
        'Licensor',
        'Managed Review',
        'Master Service Agreement (MSA)',
        'Non-Disclosure Agreement (NDA)',
        'Oral Contract',
        'Prime Contractor',
        'Retail Lease',
        'Sales Contract',
        'Smart Contracts',
        'Software Escrow',
        'Software Vendor',
        'Source Code Escrow',
        'Time and Materials (T&M)'
      ])
    ]
  },
  {
    id: 33,
    label: 'Controls & Standards',
    children: [
      ...new Set([
        '1031 Exchange',
        '1035 Exchange',
        'BSA Compliance',
        'CFT (Counter Terrorist Financing)',
        'COSO Framework',
        'Common Reporting Standard (CRS)',
        'Compliance Tools',
        'Corporate Transparency Act (CTA)',
        'Cost Accounting Standards (CAS)',
        'Credit Reports',
        'DCAA Compliance',
        'ISO20022',
        'Interbank Offered Rates (IBORs)',
        'Quantitative Easing (QE)',
        'Regulation Best Interest (Reg BI)',
        'Section 385'
      ])
    ]
  },
  {
    id: 34,
    label: 'Copyright',
    children: [
      ...new Set([
        'Copyrights'
      ])
    ]
  },
  {
    id: 35,
    label: 'Corporate Finance',
    children: [
      ...new Set([
        'Amortization',
        'Asset Sale',
        'Bottom Line',
        'Business Continuity Planning',
        'Capital Budgeting',
        'Capital Equipment Financing',
        'Capital Injection',
        'Carve-Out',
        'Cash Flow Management',
        'Cash Management Tools',
        'Clawbacks',
        'Co-Investment',
        'Commercial Card',
        'Commercial Lending',
        'Commercial Loans',
        'Corporate Bond',
        'Corporate Credit',
        'Corporate Financing',
        'Corporate Tax',
        'Corporate Trust',
        'Credit Rating Agency (CRA)',
        'Cross Border Mergers and Acquisitions',
        'Debenture',
        'Debt Restructuring',
        'Debt-Service Coverage Ratio (DSCR)',
        'Deferred Income',
        'Depreciable Assets',
        'Depreciation',
        'Discounted Cash Flow (DCF)',
        'Divestment',
        'EBITDA',
        'Earnings Per Share (EPS)',
        'Economy',
        'Exponential Moving Average (EMA)',
        'Financial Audits',
        'Financial Close',
        'Financial Close Process',
        'Global Minimum Corporate Tax (GMCT)',
        'Globalization',
        'Insolvency',
        'Intangible Assets',
        'Internal Controls',
        'Internal Rate of Return (IRR)',
        'Inversion',
        'Investor Relations',
        'Labor Cost Optimization',
        'Leveraged Buyout',
        'Market Capitalization',
        'Mergers & Acquisitions Due Diligence',
        'Mergers and Acquisitions',
        'Mezzanine Finance',
        'Mid-Market Finance',
        'Moving Average',
        'Negative Amortization',
        'Outstanding Shares',
        'Payroll Services',
        'Post-Merger Integration',
        'Prebankruptcy Planning',
        'Prepackaged Bankruptcy',
        'Recapitalization',
        'Record to Report (R2R)',
        'Reorganization',
        'Return On Capital (ROC)',
        'Share Buybacks',
        'Special-Purpose Entity (SPE)',
        'Substantive Consolidation',
        'Valuation'
      ])
    ]
  },
  {
    id: 36,
    label: 'Creativity Software',
    children: [
      ...new Set([
        'Design Software',
        'Graphic Design',
        'Image Editing Software',
        'Image Licensing',
        'Photoshop',
        'Thumbnail',
        'Video Editing Software'
      ])
    ]
  },
  {
    id: 37,
    label: 'Customer Service',
    children: [
      ...new Set([
        'Delivery',
        'Headless Customer Communication Management',
        'Line Busting',
        'Service Cancellation'
      ])
    ]
  },
  {
    id: 38,
    label: 'Data Center',
    children: [
      ...new Set([
        'Application Infrastructure',
        'Automated Infrastructure Management (AIM)',
        'Availability Zones',
        'Block Storage',
        'Carbon Management',
        'Colocation',
        'Converged Infrastructure',
        'Cooling Systems',
        'CtrlS',
        'Data Center',
        'Data Center Automation',
        'Data Center Consolidation',
        'Data Center Design',
        'Data Center Equipment',
        'Data Center Infrastructure Management (DCIM)',
        'Data Center Interconnect',
        'Data Center Management',
        'Data Center Migration',
        'Data Center Power',
        'Data Center Strategy',
        'Data Center Sustainability',
        'Data Mart',
        'Disaster Planning',
        'Disaster Recovery',
        'Environmental Controls',
        'Environmental Management',
        'Equinix Data Centers',
        'Industrial Edge Management System',
        'Infrastructure',
        'Infrastructure Management',
        'Infrastructure as a Service (IaaS)',
        'Liquid Cooling',
        'Lumen Data Center',
        'Micro Data Center',
        'Modular Data Center',
        'Multi-Tenant Data Centers (MTDCs)',
        'Power Distribution',
        'Power Supply',
        'Power Usage Effectiveness (PUE)',
        'Power and Cooling',
        'Row-Based Cooling',
        'Smart Data Center',
        'Software-Defined Data Center',
        'UPS Systems'
      ])
    ]
  },
  {
    id: 39,
    label: 'Data Management',
    children: [
      ...new Set([
        'Air-Gapped Backup',
        'Alternative Data',
        'Amazon Athena',
        'Anonymization',
        'Apache Druid',
        'Apache Iceberg',
        'Association Management Software (AMS)',
        'Automatic Image Annotation',
        'B2B Data',
        'Backup Software',
        'Backup and Recovery',
        'Behavioral Data',
        'Bit Error Rate Tester (BERT)',
        'CRM Data Automation',
        'Cache',
        'Cascading',
        'Change Data Capture',
        'Cloud Repatriation',
        'Commercial Internet of Things',
        'Consolidate Data',
        'Content Curation',
        'DR Orchestration',
        'Data Annotation',
        'Data Append',
        'Data Application',
        'Data Architecture',
        'Data Archival Strategy',
        'Data Auditing',
        'Data Automation',
        'Data Catalogue',
        'Data Classification',
        'Data Cleansing / Data Scrubbing',
        'Data Consolidation',
        'Data Convergence',
        'Data Culling',
        'Data Deduplication',
        'Data Discovery',
        'Data Distribution',
        'Data Drift',
        'Data Engineering',
        'Data Enrichment',
        'Data Entry',
        'Data Exchanges',
        'Data Extraction',
        'Data Forensics',
        'Data Infrastructure Modernization',
        'Data Integration',
        'Data Integrity',
        'Data Labeling',
        'Data Lakehouse',
        'Data Lifecycle Management',
        'Data Loss',
        'Data Loss Prevention',
        'Data Management Strategy',
        'Data Mapping',
        'Data Mesh',
        'Data Migration',
        'Data Model',
        'Data Modernization',
        'Data Monetization',
        'Data Pipeline',
        'Data Platform',
        'Data Prefilling',
        'Data Preparation',
        'Data Preservation',
        'Data Privacy and Protection',
        'Data Processing',
        'Data Profiling',
        'Data Protection',
        'Data Protection Strategy',
        'Data Quality',
        'Data Reduction',
        'Data Replication',
        'Data Replication and Mirroring',
        'Data Retention',
        'Data Sampling',
        'Data Sharing',
        'Data Silos',
        'Data Strategy Framework',
        'Data Strategy Roadmap',
        'Data Streams',
        'Data Structures',
        'Data Synchronization',
        'Data Transformation',
        'Data Validation',
        'Data Vaulting',
        'Data Virtualization',
        'Data Warehouse Cloud',
        'Data Wrangling',
        'DataOps',
        'Differential Privacy',
        'Digital Asset',
        'Digital Asset Management (DAM) Software',
        'Digital Engineering',
        'Environmental Data',
        'Execution Graph',
        'Extract Load Transform (ELT)',
        'Financial Data',
        'Google BigQuery',
        'High-Density Pods',
        'Industrial Internet of Things',
        'Information Architecture (IA)',
        'Lift and Shift',
        'Machine Data',
        'Master Data',
        'Metadata Management',
        'Mobile data collection (MDC)',
        'Object Storage',
        'Object-Relational Mapping (ORM)',
        'Observability Data Pipeline',
        'Online Transaction Processing (OLTP)',
        'Open vSwitch (OVS)',
        'Point-In-Time Recovery',
        'Product Information Management (PIM)',
        'Protocol Analyzer',
        'Pseudonymization',
        'Real-Time Data',
        'Recovery Point Objective (RPO)',
        'Reference Data Management',
        'Relationship Intelligence',
        'Remote Sensing',
        'Repository Model',
        'Screen Scraping',
        'Search and Retrieval',
        'SharePoint Migration',
        'Single Source of Truth (SSOT)',
        'Snowflake Marketplace',
        'Spatial Data Infrastructure',
        'Stream Processing',
        'Streaming Data',
        'Tape Backup',
        'Telemetry',
        'Test Data Management',
        'Transactional Data',
        'Vmware Site Recovery Manager'
      ])
    ]
  },
  {
    id: 40,
    label: 'Database',
    children: [
      ...new Set([
        'ACID (Atomicity, Consistency, Isolation, Durability)',
        'Analytic Database',
        'Boolean Operators',
        'Cloud Database',
        'DBeaver',
        'Data Connectors',
        'Database Administration',
        'Database Audit',
        'Database Development',
        'Database Migration and Conversion',
        'Database Security',
        'Database Servers',
        'Database Solutions',
        'Databases',
        'Distributed Database',
        'Electronically Stored Information (ESI)',
        'Enterprise Database',
        'Extract, Transform, and Load (ETL)',
        'Graph Databases',
        'Historian',
        'In-Database Analytics',
        'In-Memory Technology',
        'Interactive SQL',
        'Key-Value Stores',
        'LIFO (Last In, First Out)',
        'Managed Database',
        'MariaDB',
        'MySQL',
        'NewSQL',
        'NoSQL',
        'Open Database Connectivity (ODBC)',
        'Open RAN Integration',
        'Open Source Database',
        'Operational Data Store (ODS)',
        'OrientDB',
        'PI Historian',
        'Pivot Table',
        'Pivotal HAWQ',
        'Presto',
        'Query Optimization',
        'Redis',
        'Relational Database Management System (RDBMS)',
        'RethinkDB',
        'SQL',
        'Secondary storage',
        'The Work Number',
        'Time Series Database',
        'Unstructured Data'
      ])
    ]
  },
  {
    id: 41,
    label: 'Demand Generation',
    children: [
      ...new Set([
        'Customer Acquisition',
        'Customer Delight',
        'Demand Generation',
        'Demand Generation Consulting',
        'Demand Planning',
        'Lead Generation',
        'Lead Generation Consulting',
        'Lead Generation Services',
        'Lead Management',
        'Lead Nurturing',
        'Lead Quality',
        'Lead Routing',
        'Lead Scoring',
        'Lead Tracking',
        'Lead to Account Matching',
        'Marketing Funnel',
        'Marketing Leads',
        'Price Sensitivity'
      ])
    ]
  },
  {
    id: 42,
    label: 'Design Engineering',
    children: [
      ...new Set([
        'Inclusive Design'
      ])
    ]
  },
  {
    id: 43,
    label: 'Desktop',
    children: [
      ...new Set([
        'Desktop Apps',
        'Desktop Environment',
        'Desktop Management',
        'End User Digital'
      ])
    ]
  },
  {
    id: 44,
    label: 'Device Connectivity',
    children: [
      ...new Set([
        'Bluetooth',
        'Dongle',
        'Firewire',
        'USB (Universal Serial Bus)',
        'WiFi'
      ])
    ]
  },
  {
    id: 45,
    label: 'Disease Control',
    children: [
      ...new Set([
        'COVID-19 Screening',
        'Contact Tracing',
        'Hospital-Acquired Condition (HAC)',
        'Infection Control',
        'Medical Waste',
        'Patient Education',
        'Population Health Management',
        'Public Health',
        'Sharps Disposal',
        'Suicide Prevention'
      ])
    ]
  },
  {
    id: 46,
    label: 'Dispute Resolution',
    children: [
      ...new Set([
        'Alternative Dispute Resolution',
        'Arbitration',
        'Arbitration / Mediation / Dispute Resolution',
        'Baseball Arbitration',
        'Fee Disputes',
        'Loss Adjustment Expense (LAE)',
        'Mediation',
        'Mini-Trial',
        'Negotiation',
        'Property Repair Estimates',
        'Summary Jury Trial'
      ])
    ]
  },
  {
    id: 47,
    label: 'Diversity',
    children: [
      ...new Set([
        'Accessible Living',
        'Age Discrimination',
        'Disability',
        'Diversity',
        'Diversity & Inclusion Programs',
        'Diversity Recruiting',
        'Diversity Services',
        'Economic Inequality',
        'Ethnicity / National Origin',
        'Gender Equality',
        'Gender Gap',
        'Language Diversity',
        'Religion, Belief and Spirituality',
        'Sex Discrimination',
        'Sexual Orientation',
        'Women Empowerment',
        'Women in Maritime'
      ])
    ]
  },
  {
    id: 48,
    label: 'Document Management',
    children: [
      ...new Set([
        'Batch Document Processing',
        'Catalyst Insight',
        'Digital Document Management',
        'Digital Document Software/System/Solution',
        'Digital Signature Technology',
        'Document Capture',
        'Document Control',
        'Document Imaging',
        'Document Management Systems (DMS)',
        'Document Rationalization',
        'Document Retrieval',
        'Document Review',
        'Document Security',
        'Document Sharing',
        'Document Shredding',
        'Document Storage',
        'Document Verification',
        'Dynamic-Link Library (DLL)',
        'E-Signature Software',
        'Electronic Document',
        'Enterprise Document Management',
        'File Transfer Management',
        'Forms Management',
        'Graphics Interchange Format (GIF)',
        'Intelligent Agreement Management',
        'Joint Photographic Experts Group (JPEG)',
        'PDF annotation',
        'Portable Document Format (PDF)',
        'Revv',
        'Rich Text Format (RTF)',
        'Right Signature',
        'Synthesizing Content',
        'Tagged Image File Format (TIFF)',
        'Technology Assisted Review',
        'Text annotation',
        'Video Files',
        'Video Hosting',
        'Virtual Data Room',
        'Wireframing',
        'eNotary',
        'eNote',
        'eRecording',
        'eVault'
      ])
    ]
  },
  {
    id: 49,
    label: 'Education',
    children: [
      ...new Set([
        '2U, Inc.',
        '360Learning',
        'ACS Ventures',
        'ATI Testing',
        'Accredible',
        'Alchemy Systems',
        'Alpine Testing Solutions',
        'American Student Assistance',
        'Amplify',
        'Anthology Inc',
        'ApplyBoard',
        'Arcadia University',
        'Blackboard',
        'Brightly',
        'Cambium Learning',
        'Capella University',
        'Carnegie Dartlet LLC',
        'Caveon',
        'Chegg, Inc.',
        'Cloud Campus',
        'College Board',
        'Course Hero',
        'Coursera',
        'Credly',
        'Curriculum Associates',
        'Destiny Solutions Inc.',
        'Digiexam',
        'Domestika',
        'EAB',
        'Eduventures',
        'Ellucian',
        'Encoura, LLC',
        'Eruditus',
        'Examity',
        'Flatiron School LLC',
        'Futurelearn',
        'Gray Associates',
        'Halifax Consulting',
        'Health Education Systems Incorporated (HESI)',
        'Inspera',
        'Instructure',
        'Internet Testing Systems (ITS)',
        'Jenzabar',
        'Kaplan',
        'Ken Blanchard Companies',
        'Khan Academy',
        'KinderCare Learning Centers, LLC',
        'LearnUpon',
        'Learning Care Group, Inc.',
        'Learning Pool',
        'Learning Tree International',
        'Learnster AB',
        'Liaison International',
        'Linewize',
        'Linewize Ltd.',
        'LinkedIn Learning',
        'Magoosh',
        'McGraw-Hill Professional',
        'NPTEL (National Programme on Technology Enhanced Learning)',
        'Niche.com',
        'North Park University',
        "O'Reilly Media, Inc.",
        'OpenSesame',
        'Othot, Inc.',
        'PSI',
        'Panorama Education',
        'Paradigm Testing',
        'Pearson VUE',
        'Platzi',
        'PowerSchool Holdings, Inc. (PWSC)',
        'PowerSchool Naviance',
        'ProQuest',
        'Procare Software',
        'ProctorU',
        'Prometric',
        'Questionmark',
        'Ruffalo Noel Levitz',
        'SANS Institute',
        'SWAYAM (Study Webs of Active-Learning for Young Aspiring Minds)',
        'Sales Hacker',
        'Sana Labs AB',
        'Scantron',
        'Simplilearn',
        'Strasz',
        'Strayer University',
        'SweetRush',
        'TCS iON',
        'TargetX.com',
        'TeachTown, Inc.',
        'The Princeton Review',
        'ThriveDX SaaS Ltd.',
        'TopClass LMS',
        'Udacity',
        'Udemy',
        'University of Phoenix (UoPX)',
        'Wikimedia Foundation',
        'Wikipedia',
        'WorkRamp',
        'Wyzant',
        'Yanka Industries, Inc. (Masterclass)',
        'edX'
      ])
    ]
  },
  {
    id: 50,
    label: 'Email',
    children: [
      ...new Set([
        'Autoresponder',
        'Bounced Email',
        'Business Email Compromise (BEC)',
        'Domain Keys Identified Mail (DKIM)',
        'Domain-based Message Authentication, Reporting and Conformance (DMARC)',
        'Email Archiving',
        'Email Authentication',
        'Email Encryption',
        'Email Parsing',
        'Email Security',
        'Email Servers',
        'Email Tracking',
        'Hosted Exchange',
        'Mail Servers',
        'Mailing List',
        'Spam Filtering',
        'TXT Record',
        'Visual Voicemail'
      ])
    ]
  },
  {
    id: 51,
    label: 'Email Marketing',
    children: [
      ...new Set([
        'Amazon SES',
        'CAN-SPAM',
        'Constant Contact',
        'Double Opt-In',
        'Drip Marketing',
        'Email Campaign',
        'Email Campaigns',
        'Email Deliverability',
        'Email Hygiene',
        'Email List Management',
        'Email Marketing',
        'Email Marketing Automation',
        'Email Marketing Services',
        'Email Marketing Software',
        'Email Marketing System',
        'Email Marketing Tools',
        'Email Open Rates',
        'Email Personalization',
        'Email Service Providers',
        'Email Signature Marketing',
        'Global Email Marketing',
        'MailChimp',
        'Newsletter',
        'Opt-Out',
        'Permission Email Marketing',
        'Sender Policy Framework (SPF)',
        'Spam'
      ])
    ]
  },
  {
    id: 52,
    label: 'Emerging Tech',
    children: [
      ...new Set([
        '3D Engines',
        '3D Printing',
        '3D Scanners',
        '4D Radar',
        'AGVs (Automated Guided Vehicles)',
        'AI Content Curation',
        'AI-Generated Answers',
        'AI-Powered Search',
        'Decision Manager',
        'Deepfake',
        'Digital Disruption',
        'Digital Holograms',
        'Digital Learning',
        'Digital Twin',
        'Disk Imaging',
        'Disruptive Technologies',
        'District0x',
        'EV Charging Station',
        'Edge-Computing Model',
        'Electric Vehicles (EV)',
        'Emerging Technologies',
        'Exascale Computing',
        'Human-AI Collaboration',
        'Hybrid AI',
        'Hybrid Vehicle',
        'IT/OT Convergence',
        'Immersive Avatars',
        'Industry 5.0',
        'Machine Ethics',
        'Mirrorworlds',
        'Motion Control',
        'Motion Graphics',
        'Multimodal AI',
        'Optical Character Recognition (OCR)',
        'Projection Mapping',
        'Projection Technology',
        'Propulsion Technology',
        'Raster Graphics',
        'Real-Time 3D',
        'Realtime 3D Visualization',
        'Remote Terminal Unit',
        'Robotic Process Automation (RPA)',
        'Smart Bed',
        'Smart Cities',
        'Smart Home Technology',
        'Smart TV',
        'Stablecoins',
        'Storyboarding',
        'SuperRare',
        'Unmanned Systems',
        'Visual Artificial Intelligence',
        'Visual Effects (VFX)'
      ])
    ]
  },
  {
    id: 53,
    label: 'Employee Services',
    children: [
      ...new Set([
        '401(k) Advisor',
        'Applicable Large Employer (ALE)',
        'Child Care',
        'Community Management',
        'Conflict Resolution',
        'Counseling',
        'Employee Assistance',
        'Employee Attrition',
        'Employee Burnout',
        'Employee Engagement',
        'Employee Handbooks',
        'Employee Management',
        'Employee Relationship Management',
        'Employee Resource Groups (ERGs)',
        'Employee Satisfaction',
        'Employee Voice',
        'Employment Conditions',
        'Employment Projections',
        'Employment Services',
        'Ergonomics',
        'Future of Work',
        'Grievances',
        'Health and Safety',
        'Issue Management',
        'Micromanagement',
        'Outplacement',
        'Retirement Planning',
        'Self Funded Health Plans',
        'Social Recognition',
        'Workplace Service Delivery'
      ])
    ]
  },
  {
    id: 54,
    label: 'Energy',
    children: [
      ...new Set([
        '24/7 Clean Energy',
        'Advanced Distribution Management System (ADMS)',
        'Agriculture',
        'Air-Side Economizers',
        'Alternative Fuels',
        'Alternative-Fuel Vehicles',
        'Ammonia Fuel',
        'Arc Fault',
        'Battery Energy Storage Systems (BESS)',
        'Battery Power',
        'Battery Recycling',
        'Battery Room',
        'Battery Safety',
        'Biofuel',
        'Biomass',
        'Blackout Prevention',
        'Bulk Fuel Delivery',
        'Bus Fleet Electrification',
        'Carbon Accounting',
        'Carbon Capture and Sequestration',
        'Carbon Credits',
        'Carbon Footprint',
        'Carbon Footprint Reduction',
        'Carbon Intensity Indicator (CII)',
        'Carbon Leakage',
        'Carbon Marketplace',
        'Carbon Mineralization',
        'Carbon Offset',
        'Carbon Pricing',
        'Climate Change',
        'Climate-Smart Agriculture',
        'Commercial Electrical Distribution',
        'Commercial Generator Maintenance',
        'Community Solar',
        'Computational Fluid Dynamics (CFD)',
        'Conventional Charging',
        'Cool Roof',
        'Decarbonization',
        'Demand Response',
        'Demand-Side Management',
        'Depot Charging',
        'Diesel Particulate Filter (DPF)',
        'Direct Air Capture (DAC)',
        'Distributed Energy Resources (DERs)',
        'Distribution Board',
        'Dual Fuel Engine',
        'EV Charging Infrastructure',
        'Electric Forklifts',
        'Electric Propulsion',
        'Electrical Substation',
        'Electrical pumps',
        'Electro Mobility (e-mobility)',
        'Emergency Lighting Systems',
        'Emission Cutting Fuel',
        'Emission Standards',
        'Emissions',
        'Energy Attribute Certificates (EACs)',
        'Energy Audit',
        'Energy Community',
        'Energy Consulting',
        'Energy Consumption',
        'Energy Efficiency',
        'Energy Management',
        'Energy Management Software',
        'Energy Procurement',
        'Energy Storage',
        'Energy Transition',
        'Energy-Efficient Lighting Products',
        'Environmental Services',
        'Evaporative Cooling',
        'Fuel Cells',
        'Fuel Efficiency',
        'Fuel Management',
        'Generators',
        'Geothermal',
        'Global Warming',
        'Green Architecture',
        'Green Computing',
        'Green House Gas (GHG) Emissions',
        'Green Hydrogen',
        'Green Marketing',
        'Greenhouse Gases',
        'Grid Constraints',
        'Grid Edge',
        'Grid Resilience Services',
        'Grid Stability',
        'Guarantee of Origin (GO)',
        'HVAC Energy Conservation',
        'HVAC Retrofitting Services',
        'Hybrid Propulsion',
        'Hydraulic Fracturing (Fracking)',
        'Hydroelectric',
        'Hydrogen Refueling Station',
        'Hydrogen Transport',
        'Hydronics',
        'Industrial Generator Maintenance',
        'Industrial Uninterruptible Power Supply (UPS)',
        'Insulator Inspection',
        'LED Lighting Upgrades',
        'Lead-Acid Battery Maintenance',
        'Leadership In Energy and Environmental Design (LEED)',
        'Lighting Control',
        'Liquified Natural Gas (LNG)',
        'Low Sulphur',
        'Low Voltage Network',
        'Low Voltage Systems',
        'Marine Gas Oil',
        'Medium Voltage Systems',
        'Microgrids',
        'Natural Gas Processing',
        'Net Zero',
        'Nuclear Energy',
        'Oil & Gas',
        'On-site Energy Generation',
        'Onshore Power Supply (OPS)',
        'Opportunity charging',
        'Outage Management System',
        'Photovoltaics',
        'Portable DC Charging',
        'Power Measurement',
        'Power Monitoring',
        'Power Purchase Agreements',
        'Power Quality',
        'Power Reliability',
        'Power Transmission',
        'Recycling',
        'Refinery',
        'Renewable Energy',
        'Renewable Energy Certificate',
        'Renewable Energy Conversion',
        'Renewable Energy Credits',
        'Smart Grid',
        'Smart Metering',
        'Solar Farm',
        'Solar Inverter',
        'Solar Maintenance',
        'Solar Panels',
        'Solar Racking',
        'Solar-plus-storage',
        'Strategic Energy Management (SEM)',
        'Street Lighting',
        'Substation Automation',
        'Sustainable Energy',
        'Transition Fuel',
        'Utility Bill Automation',
        'Utility Bill Management',
        'Virtual Power Purchase Agreement (VPPA)',
        'Wind Power',
        'Wind Propulsion',
        'Wind Turbines',
        'Windmills'
      ])
    ]
  },
  {
    id: 55,
    label: 'Energy & Construction',
    children: [
      ...new Set([
        'Contractor'
      ])
    ]
  },
  {
    id: 56,
    label: 'Engineering',
    children: [
      ...new Set([
        '3D CAD',
        '3D Design Engineering',
        '3D Modeling',
        'Aerospace Engineering',
        'Artificial Lift',
        'Building Information Modeling (BIM)',
        'CNC Engineering',
        'Electrical Engineering and Planning',
        'Finite Element Analysis',
        'Front-End Engineering Design (FEED)',
        'Geotechnical Engineering',
        'Hardware-in-the-Loop (HIL)',
        'Infrastructure Design',
        'Injection Molded Prototypes',
        'Materials Science',
        'Mechanical System',
        'Mechanical, Electrical and Plumbing (MEP)',
        'Metallurgy',
        'Model Based Systems Engineering (MBSE)',
        'Offshore Maritime Activities',
        'Oil Viscosity',
        'Piping and Instrumentation Diagram (P&ID)',
        'Power Factor',
        'Process Engineering',
        'Rendering',
        'Ship Operations',
        'Signal-to-Noise Ratio (SNR)',
        'Structural Engineering',
        'Tight Tolerances',
        'Value Analysis and Value Engineering (VAVE)'
      ])
    ]
  },
  {
    id: 57,
    label: 'Enterprise',
    children: [
      ...new Set([
        'Applecare for Enterprise',
        'Applications Architecture',
        'Demand Management',
        'Digital Enterprise',
        'Digital Process Technologies',
        'ERP Implementation',
        'Enterprise AI',
        'Enterprise Agile Requirements',
        'Enterprise Application Development',
        'Enterprise Application Integration',
        'Enterprise Application Security',
        'Enterprise Applications',
        'Enterprise Architecture',
        'Enterprise Asset Management',
        'Enterprise Collaboration',
        'Enterprise Computing',
        'Enterprise Information Integration / Metadata Management',
        'Enterprise Information Management (EIM)',
        'Enterprise Infrastructure',
        'Enterprise Integration Platform',
        'Enterprise Labeling Software',
        'Enterprise Mobility',
        'Enterprise Mobility Management (EMM)',
        'Enterprise Performance Management / Balanced Scorecard',
        'Enterprise Service Bus',
        'Enterprise Software',
        'Enterprise Systems Management',
        'Enterprise Visualization',
        'Enterprise Voice',
        'Enterprise Wearables',
        'Hybrid IT',
        'Hyperscale Computing',
        'IT Project Portfolio Management',
        'Industrial High-Performance Computing',
        'Mainframe Modernization',
        'NetSuite ERP',
        'Seamless Integration',
        'Service Catalog',
        'Unified Operations'
      ])
    ]
  },
  {
    id: 58,
    label: 'Entertainment',
    children: [
      ...new Set([
        'Activision Blizzard, Inc.',
        'Amazon Fire TV',
        'Amazon Prime',
        'Android TV',
        'ByteDance',
        'Call Of Duty (COD)',
        'Chromecast',
        'Google TV',
        'Gran Turismo',
        'Hulu',
        'ImageSound',
        'Kinect',
        'LG Television',
        'Microsoft Xbox',
        'Netflix (NFLX)',
        'Nintendo Co., Ltd.',
        'Nintendo Wii',
        'Oculus Rift',
        'Pandora Media Inc.',
        'Paramount Home Entertainment',
        'Pixar Animation Studios',
        'PlayNetwork',
        'PlayStation VR',
        'PlayStation Vue',
        'Roku, Inc.',
        'Sony Interactive Entertainment',
        'Sony Playstation',
        'Stingray',
        'The Walt Disney Company',
        'VLC Media Player',
        'Vevo',
        'Windows Media Player',
        'YoYo Games',
        'YouTube',
        'iHeartRadio'
      ])
    ]
  },
  {
    id: 59,
    label: 'Event Management',
    children: [
      ...new Set([
        '6Connex',
        'AirPlus International Inc',
        'Airmeet Inc.',
        'Audience Engagement',
        'Aventri',
        'Bizzabo',
        'Cannes Lions',
        'Corporate Entertainment',
        'Cvent',
        'Doubledutch',
        'Engagez',
        'Event Apps',
        'Event Audio/Visual',
        'Event Catering',
        'Event Negotiations',
        'Event Planning',
        'Event Promotional Items',
        'Event Registration',
        'Event Site Selection',
        'EventMobi',
        'Eventbrite',
        'Goldcast, Inc.',
        'Hopin',
        'Hubilo',
        'Hybrid Events',
        'IBM InterConnect',
        'InEvent',
        'Launch Events',
        'LinkedIn Talent Connect',
        'Livestorm Inc.',
        'Mobile Event App',
        'Oracle Open World',
        'PheedLoop',
        'Reed Exhibitions',
        'Run the World',
        'Salesforce Connections',
        'Security screening',
        'Shop.org',
        'Social Tables',
        'SpotMe',
        'Swapcard',
        'Swoogo LLC',
        'Weapons detection',
        'Whova',
        'vFairs'
      ])
    ]
  },
  {
    id: 60,
    label: 'Events & Conferences',
    children: [
      ...new Set([
        'Ad-Tech Conferences',
        'Adobe Summit',
        'Autodesk University (AU)',
        'B2B Marketing Exchange',
        'Ban-The-Box',
        'Bike to Work Day',
        'Black Hat',
        'CES',
        'CFO Summit',
        'CRM Evolution',
        'Consumer Electronics Show (CES)',
        'Corporate Counsel & Compliance Exchange',
        'Digital Mortgage Conference',
        'Dreamforce',
        'ERE Recruiting Conference',
        'Enterprise Risk Management Conference',
        'Forbes Womens Summit',
        'Forrester Events',
        'GMA Legal Conference',
        'Gartner Events',
        'Global Finance and Credit Conference',
        'GreenBuild',
        'HRO Conference',
        'HRO Forum',
        'Hannover Messe',
        'HubSpot INBOUND',
        'IAA Mobility',
        'IQPC Telecom B2B Conferences',
        'ISC West Conference',
        'Inkjet Summit',
        'Intellectual Property Law Conference',
        'International Association for Contract and Commercial Management (IACCM)',
        'Korn Ferry Tour',
        'LegalTech Show',
        'MIT Sustainability Summit',
        'Marketing Conferences',
        'Marketo Marketing Nation Summit',
        'Microsoft Ignite',
        'Microsoft Inspire',
        'Microsoft Worldwide Partner Conference (WPC)',
        'Mobile World Congress',
        'NASCC: The Steel Conference',
        'National Automobile Dealers Association (NADA) Show',
        'NeoCon',
        'Outdoor Retailer',
        'Print + ePublishing Conference (PePcon)',
        'Printing United',
        'RSA Conference',
        'Realize LIVE',
        'SAP Sapphire',
        'SaasStr Annual',
        'Sales Conferences',
        'Shoptalk',
        'SiriusDecisions Summit',
        'Small Business Expo',
        'Social Media Strategies Summit',
        'Society for Human Resource Management (SHRM)',
        'South By Southwest (SXSW)',
        'Symposium',
        'TED (Technology, Entertainment, Design)',
        'TechCrunch Disrupt',
        'The Crunchies Award',
        'VMWorld',
        'Virtual Conferences',
        'Web Summit',
        'Women in Law Conferences',
        'Workforce Planning & Analytics Conference',
        'World Business Forum',
        'i4CP Conference'
      ])
    ]
  },
  {
    id: 61,
    label: 'Expansion',
    children: [
      ...new Set([
        'Atlanta, Georgia',
        'Austin, Texas',
        'Best Places to Live',
        'Boca Raton, Florida',
        'Boise, Idaho',
        'Build to Suit',
        'Business Development',
        'Business Retention',
        'Buying a Company',
        'Cambridge, Massachusetts',
        'Chicago, Illinois',
        'Columbus, Ohio',
        'Community Revitalization',
        'Commute Time',
        'Company Relocation',
        'Corporate Relocation',
        'Cost of Living',
        'Cost of Living Calculator',
        'Denver, Colorado',
        'Des Moines, Iowa',
        'Downtown Office Space',
        'Dubai, United Arab Emirates',
        'Economic Diversification',
        'Emerging Tech Cities',
        'Fittest Cities',
        'Happiness Index',
        'Inclusive Development',
        'Inclusive Growth',
        'Incubators',
        'International Economic Development Council (IEDC)',
        'International Expansion',
        'Irvine, California',
        'Job Creation',
        'Las Vegas, Nevada',
        'Local Partnerships',
        'Miami, Florida',
        'Montana',
        'Nashville, Tennessee',
        'New Office Building',
        'New York, New York',
        'Northern Sea Route',
        'Office Expansion',
        'Office Lease',
        'Office Move',
        'Office Space',
        'Office Space Trends',
        'Oklahoma City, Oklahoma',
        'Raleigh/Durham, North Carolina',
        'Reno, Nevada',
        'Salt Lake City, Utah',
        'San Francisco, California',
        'Seattle, Washington',
        'Site Selectors',
        'Tenant Rep',
        'Tulsa, Oklahoma'
      ])
    ]
  },
  {
    id: 62,
    label: 'Finance IT',
    children: [
      ...new Set([
        'AP Automation',
        'ATM Security',
        'BNY Eagle',
        'Bank Statement Processing Automation',
        'Banking Data Warehouse',
        'Banking Software',
        'Banking as a Service (BaaS)',
        'Charles River',
        'Check Processing',
        'Collateral Management Software',
        'Contactless Payment',
        'Core Banking System',
        'Debt Collection Software',
        'Decentralized Finance',
        'Digital Account Opening',
        'Digital Banking',
        'Digital Monitoring Products (DMP)',
        'Digital Payment',
        'Digital Wealth Management Platform',
        'EZE Eclipse',
        'Electronic Billing',
        'Electronic Invoicing',
        'Family Office Software',
        'FinTech',
        'Finacle',
        'Financial Portal',
        'Financial Systems',
        'Fixed Income Investment Management Software',
        'Hedge Fund Management Software',
        'Investment Book of Record',
        'Investment Performance Measurement Software',
        'Investment Risk Management System',
        'Loan Management System',
        'Open Banking',
        'Payment Platforms',
        'Payments API',
        'Platform Banking',
        'Portfolio Management System (PMS)',
        'RegTech',
        'Remote Deposit Capture (RDC)',
        'Remote Teller',
        'Salesforce Billing',
        'Society for Worldwide Interbank Financial Telecommunication (SWIFT)',
        'Treasury Management System',
        'Universal Service Fund (USF)',
        'Video Banking',
        'Wealthtech',
        'eClosing'
      ])
    ]
  },
  {
    id: 63,
    label: 'Financial',
    children: [
      ...new Set([
        '2Checkout',
        '3C/PlanetPayments',
        '3G Direct Pay',
        'ABN AMRO',
        'AML RightSource, LLC',
        'APEX Collateral',
        'ARC Advisory Group',
        'ARCA',
        'AT Kearney',
        'ATM Solutions',
        'AXA',
        'Abacum Inc.',
        'Abel Noser',
        'Accel',
        'Accertify',
        'Access Softek, Inc.',
        'AccountingDepartment.com',
        'Acorns',
        'Advent International',
        'Advent Software',
        'Affirm',
        'Afterpay',
        'Airbase',
        'Airwallex',
        'Alantra',
        'Aldrich',
        'Alipay',
        'Alkami Technology (ALKT)',
        'Allegiance Capital Corporation',
        'Allianz',
        'Allspring Global Investments',
        'Ally Financial (ALLY)',
        'Alpine Investors',
        'Amazon Pay',
        'America First Credit Union',
        'American Express (AXP)',
        'American Funds',
        'American Legislative Exchange Council (ALEC)',
        'American Securities',
        'Ameriprise Financial (AMP)',
        'Anchin',
        'Apax',
        'Apollo Global Management (APO)',
        'Apple Pay',
        'Apple Pay Later',
        'Aprio',
        'Apruve',
        'Arma Partners',
        'Armanino',
        'Aronson',
        'Arrow Global',
        'Arrowroot Capital',
        'Ascensus',
        'AssetMark',
        'Association of Banks in Singapore',
        'Assurance IQ',
        'Athene',
        'Atlantix Partners',
        'Atom Technologies',
        'Atome',
        'Australia and New Zealand Banking Group (ANZ)',
        'Australian Securities Exchange (ASX)',
        'AvidXchange',
        'BB&T Corporation',
        'BBVA Compass Bank',
        'BDO USA',
        'BDO Unibank, Inc.',
        'BG Capital',
        'BKD',
        'BMI Research',
        'BMO Harris Bank',
        'BNP Paribas',
        'BNY Mellon',
        'BOK Financial Corp (BOKF)',
        'BPM',
        'BSE',
        'BTIG, LLC',
        'Bain Capital',
        'Baker Newman & Noyes',
        'Baker Tilly',
        'Banco Central do Brasil (BCB)',
        'Bank for International Settlements',
        'Bank of America (BAC)',
        'Bank of China',
        'Bank of Montreal (BMO)',
        'Bank of the West',
        'Banno',
        'Barchart.com, Inc.',
        'Barclays (BCS)',
        'Barings',
        'Basware (HEL:BAS1V)',
        'Battery Ventures',
        'Bennett Thrasher',
        'Berdon',
        'Berkery, Noyes & Co.',
        'Berkowitz Pollack Brant',
        'Berkshire Energy',
        'BestEx Research',
        'Betterment',
        'Biglari Holdings Inc. (BH)',
        'Bill.com',
        'BillingPlatform',
        'Billtrust',
        'Black Knight',
        'BlackLine',
        'BlackRock (BLK)',
        'Blackstone (BX)',
        'Blend',
        'Block, Inc.',
        'Bloomberg Terminal',
        'Blue & Co.',
        'BlueSnap',
        'BlueVine',
        'Blueprint Equity',
        'Boku',
        'BookKeeping Express (BKE)',
        'BoostUp',
        'Boston Consulting Group',
        'Braintree',
        'Brex',
        'BrightScope, Inc.',
        'Brightflag',
        'Broadridge (BR)',
        'Brookfield',
        'Brown Smith Wallace',
        'Brown, Edwards & Co.',
        'Bytecoin (BCN)',
        'CAN Capital',
        'CASHU',
        'CC Avenue',
        'CCV',
        'CDPQ',
        'CIMA',
        'CIT Bank',
        'CLA',
        'CME Group Inc.',
        'CNO Financial Group',
        'CRMNEXT, Inc.',
        'CSG Partners',
        'CVC Capital Partners',
        'Cain Brothers',
        'Cambridge Global Payments',
        'Canadian Imperial Bank of Commerce (CIBC)',
        'Canadian Western Bank',
        'Cantor Fitzgerald',
        'Cap Gemini (CAP)',
        'Capital Group',
        'Capital One Financial (COF)',
        'Capital One Investing',
        'Capital One Spark Cash',
        'Capital One Spark Classic',
        'Capital One Trade Credit',
        'Capital One Venture X Business',
        'Capstone Partners',
        'CardConnect',
        'Cardtronics',
        'Carlyle (CG)',
        'Carr, Riggs & Ingram',
        'Carrick',
        'Cashfree',
        'Catalyst Investors',
        'Center ID Corp.',
        'Cetelem',
        'Charles Schwab (SCHW)',
        'Chase Bank',
        'Chatham Financial',
        'Checkbook.io',
        'Checkout.com',
        'Checkpoint Catalyst',
        'Cherry Bekaert',
        'Chicago Board Options Exchange (CBOE)',
        'Citadel',
        'Citcon',
        'Citibanamex',
        'Citibank',
        'Citigroup (C)',
        'Citizens Bank',
        'City National Bank',
        'Clark, Schaefer, Hackett & Co.',
        'Clayton, Dubilier & Rice',
        'Clearly Payments',
        'Clearpay',
        'Clearwater Analytics, LLC',
        'Close Brother Group PLC',
        'Code Red',
        'Cofidis',
        'Cohen & Co.',
        'CohnReznick',
        'Comerica Bank (CMA)',
        'Commercetools',
        'Commodity Futures Trading Commission (CFTC)',
        'Commonwealth Bank Australia (CBA)',
        'Concur Expense',
        'Concur Technologies',
        'Convergent',
        'Corpay',
        'Corporate Finance Associates',
        'Corporate Group, Inc.',
        'Coupa (COUP)',
        'Credit Key',
        'Credit Mutuel',
        'Credit Suisse Group',
        'Creditsafe',
        'Crisil',
        'Crowe',
        'Crédit Agricole Group',
        'Cullen/Frost Bankers Inc',
        'Currencies Direct',
        'CurrencyFair',
        'DBS Bank Ltd.',
        'DBS Paylah',
        'DBSI',
        'DC Advisory',
        'DLL Group',
        'DZ Bank',
        'DailyPay',
        'Danske Bank',
        'Dash',
        'Dealogic',
        'Deloitte',
        'Deluxe',
        'Desjardins',
        'Deutsche Bank (DB)',
        'Deutsche Börse',
        'Diebold Nixdorf',
        'Digital Insight',
        'Digital Insurance LLC',
        'Dimensional Fund Advisors',
        'Discover Financial Services (DFS)',
        'Divvy',
        'Dixon Hughes Goodman',
        'Docupace Technologies',
        'Donnelley Financial Solutions, Inc.',
        'DotPay',
        'Dwolla',
        'E*Trade (ETFC)',
        'ECX Emissions',
        'EQT (EQT)',
        'Early Warning Services, LLC',
        'Earnest',
        'Eaton Vance',
        'Ebury',
        'Edison Partners',
        'Edward Jones',
        'Eide Bailly',
        'EisnerAmper',
        'Elavon',
        'Elliot Management',
        'Elliott Davis',
        'Elliptic',
        'Emburse',
        'Employee Benefits Institute of America (EBIA)',
        'Endava',
        'Energy Capital',
        'Enfusion (ENFN)',
        'Equifax (EFX)',
        'Ernst & Young',
        'Essentia Analytics',
        'Ethereum (ETH)',
        'Euromonitor',
        'Europa Partners',
        'European Central Bank',
        'European Investment Bank (EIB)',
        'Even',
        'Evercore (EVR)',
        'FIS',
        'FNZ Group',
        'FTV Capital',
        'FactSet',
        'Factory of the Future',
        'Fannie Mae (FNMA)',
        'Fattmerchant',
        'FavePay',
        'Feathercoin (FTC)',
        'Federated Hermes, Inc. (FHI)',
        'Fedwire',
        'Fidelity',
        'Fidelity Institutional Asset Management (FIAM LLC)',
        'Fidelity Investments',
        'Fidessa',
        'Fifth Third Bank',
        'Financial Accounting Standards Board (FASB)',
        'Financial IT',
        'Financial Industry Regulatory Authority (FINRA)',
        "Financière de l'Odet",
        'Financo',
        'Finastra',
        'Finicity',
        'Finsight Group, Inc.',
        'First American Financial Corporation',
        'First Data (FDC)',
        'First Horizon Bank',
        'First Republic Bank',
        'Fisher Investments',
        'Fitch',
        'Five Elms Capital',
        'Five Star Bank',
        'FleetCor Technologies',
        'FloQast, Inc.',
        'Floa',
        'Flywire',
        'Forex Trading Platforms',
        'Forrester',
        'Francisco Partners',
        'Franklin Resources, Inc. (BEN)',
        'Franklin Templeton',
        'Freddie Mac (FMCC)',
        'FreeAgent',
        'Friedman',
        'Fuelman',
        'Fulcrum',
        'Fundbox',
        'Fundera',
        'Funding Circle',
        'GDS Link',
        'GI Partners',
        'GIC Private Limited',
        'GLG',
        'GLORY',
        'GMO Payment Gateway (TYO)',
        'GSO Capital Partners',
        'GTCR',
        'GTreasury',
        'Gant Travel',
        'General Atlantic',
        'General Catalyst',
        'Genstar',
        'Geode Capital Management',
        'Getnet',
        'Giropay',
        'Global Atlantic Financial Group Limited',
        'Global Infrastructure Partners',
        'Global Payments (GPN)',
        'Global Payments Inc',
        'GoFundMe',
        'Golden Gate Capital',
        'Goldman Sachs (GS)',
        'Google Pay',
        'Google Wallet',
        'Governmental Accounting Standards Board (GASB)',
        'GrabPay',
        'Grant Thornton',
        'Grassi & Co.',
        'Gravity Payments',
        'Great Hill Partners',
        'GreatAmerica Financial Services',
        'Green Dot',
        'GreenSky',
        'Growth Street Partners',
        'Guggenheim Partners',
        'Guidepost',
        'H&R Block, Inc.',
        'HGGC',
        'HORNE',
        'HSBC Bank',
        'Happay',
        'Hearsay',
        'Helcim',
        'Hellman & Friedman',
        'HiPay',
        'Highland Capital Partners',
        'Hill, Barth & King (HBK)',
        'Home Capital Group Inc. (OTCMKTS:HMCBF)',
        'HyperPay',
        'Hyperwallet',
        'ICAP',
        'ICONIQ',
        'IDG',
        'IHS Markit (INFO)',
        'ING Group (ING)',
        'INTL FCStone',
        'ISACA',
        'Imperial Capital',
        'InDebted',
        'Ingenico',
        'Innovis',
        'Insight Venture Partners',
        'Instamed',
        'Instamojo',
        'Intercontinental Exchange (ICE)',
        'International Accounting Standards Board (IASB)',
        'Invesco',
        'InvestCloud, Inc.',
        'Investment Technology Group (ITG)',
        'Investors Bank',
        'JMI Equity',
        'JPMorgan Chase (JPM)',
        'Jack Henry & Associates Inc (JKHY)',
        'Jaggaer',
        'Japan Exchange Group',
        'Japan Post Bank',
        'Jumio',
        'Juniper Square',
        'KKR',
        'KLR',
        'KPMG',
        'Kabbage',
        'Katz, Sapper & Miller',
        'Keefe, Bruyette, & Woods',
        'Kemper CPA Group',
        'KeyBank',
        'Kickstarter',
        'Klarna',
        'Kohlberg & Company',
        'Kratos Capital',
        'Kruze Consulting, Inc.',
        'Kyriba',
        'LBMC',
        'LLR Partners',
        "LLoyd's Bank PLC",
        'Ladenburg Thalmann',
        'Lakeland Bank',
        'Landmark Credit Union',
        'Laurel Road',
        'LayBuy',
        'Lazard (LAZ)',
        'LendingClub',
        'Lendio',
        'LightStream',
        'Lincoln Financial Group',
        'Lincoln International',
        'Litecoin (LTC)',
        'Lument',
        'Lumin Digital',
        'Lutz',
        'Lyra',
        'M&T Bank (MTB)',
        'M33 Growth',
        'MERSCORP',
        'MGO',
        'MISMO',
        'MX Technologies, Inc.',
        'Macquarie Global Services (USA) LLC',
        'Madison Dearborn',
        'Mainsail Partners',
        'Marathon Capital',
        'Marcum',
        'MarketAxess',
        'MarketInvoice',
        'Marlin & Associates',
        'Marlin Equity',
        'MasterCard (MA)',
        'Mastercard Advisors',
        'Mauldin & Jenkins',
        'Mazars USA',
        'Mercado Pago',
        'Mercatus',
        'Merrill Edge',
        'Merrill Lynch',
        'MetaQuotes Software',
        'Mirus Capital Advisors, Inc.',
        'Mitsubishi UFJ Financial Group',
        'Mizuho Financial Group',
        'Modern Treasury',
        'Monarch Capital Partners',
        'Moneris',
        'Monero (XMR)',
        'MoneyGuide',
        'Moneycorp',
        'MonitorBase',
        "Moody's Investors Service",
        'Morgan Stanley (MS)',
        'Morningstar Ratings',
        'Morningstar, Inc.',
        'Moss Adams',
        'NASDAQ',
        'NKSFB',
        'NMI',
        'NYSE',
        'Namecoin (NMC)',
        'Narmi Inc.',
        'NatWest Group',
        'National Australia Bank (NAB)',
        'National Funding',
        'National Payments Corporation of India (NPCI)',
        'Navigatr Group',
        'Needham & Co.',
        'Nelnet',
        'Nest Seekers International',
        'Nets',
        'Network International',
        'Neuberger Berman',
        'New Mountain Capital',
        'New Zealand Exchange (NZX)',
        'NewSpring',
        'Nomura',
        'Nordic Capital',
        'North American Bancard, LLC',
        'Northern Trust',
        'Northwestern Mutual',
        'Norwest Venture Partners',
        'Nuvei',
        'Nymbus, Inc.',
        'OFX',
        'Oaktree Capital Management (OAK)',
        'Octopus Group (Octopus Capital Limited)',
        'OnDeck Capital',
        'One Inc',
        'Oney',
        'OpenSea',
        'Opus Capita',
        'Oracle Hyperion',
        'Orion Advisor Services LLC',
        'Oti',
        'PCE Investment Bankers, Inc.',
        'PIB Group Limited',
        'PIMCO',
        'PKF O’Connor Davies',
        'PNC Financial Services Group (PNC)',
        'PPCoin(PPC)',
        'PYA',
        'PagSeguro',
        'Pamplona Capital Management',
        'Panmure Gordon',
        'Partnership for Carbon Accounting Financials (PCAF)',
        'Pathward Financial, Inc.',
        'PayDollar',
        'PayMe',
        'PayNow',
        'PayPal (PYPL)',
        'PayPal Credit',
        'PayPal Pay Later',
        'PayPal Pay in 4',
        'PayPal Working Capital',
        'PaySimple',
        'PayU',
        'Paya',
        'Payactiv',
        'Paymetric',
        'Payoneer',
        'Payroll Vault',
        'Paystand',
        'Peak Matrix',
        "People's United Bank",
        'Permira',
        'Personetics Ltd.',
        'Piemonte Holding',
        'Pinnacle Financial Partners Inc (PNFP)',
        'Place Technology',
        'Plaid',
        'Planful',
        'Plante Moran',
        'Platinum Equity',
        'Pleo',
        'Polaris Partners',
        'Primecoin (XPM)',
        'Primus',
        'Private Company Council (PPC)',
        'Prophix',
        'Providence Equity Partners',
        'Prudential Financial (PRU)',
        'Przelewy24',
        'Pyramid Consulting',
        'Q2',
        'QNB Group (Qatar National Bank (Q.P.S.C.))',
        'Qatar Investment Authority (QIA)',
        'QuickBooks Payroll',
        'RKL',
        'RSM US',
        'Rabobank',
        'Rakuten Pay',
        'Ramp',
        'Raymond James',
        'Razorpay',
        'Rea & Associates',
        'Refinitiv',
        'Regions Financial (RF)',
        'Rehmann',
        'Remitly',
        'Retail Capital (Credibly)',
        'Revolution Growth',
        'Ripple (XRP)',
        'River Cities Capital',
        'Riverwood Capital',
        'Rockland Trust',
        'Roth Capital Partners',
        'Royal Bank of Canada (RBC)',
        'Russell Investments',
        'S&P Global (SPGI)',
        'SAP Treasury',
        'SEI Investments Company',
        'SS&C Eze',
        'ST Telemedia (Singapore Technologies)',
        'SVA CPAs',
        'Sage Software, Inc.',
        'Salesforce Financial Services',
        'Samsung Pay',
        'Santander Bank',
        'Saudi Central Bank',
        'Scalapay',
        'Scale Venture Partners',
        'Schneider Downs',
        'Scotiabank',
        'Scottrade',
        'Seiler',
        'Sensedia',
        'Sequoia Capital',
        'Serrala',
        'Sezzle',
        'Sharebuilder-ING Direct',
        'Shop Pay',
        'SierraConstellation Partners LLC',
        'Sikich',
        'Silicon Valley Bank',
        'Silver Lake Partners',
        'Siris Capital Group',
        'Skrill',
        'SkyOne Federal Credit Union',
        'Slope Finance',
        'Small Business Financial Exchange (SBFE)',
        'SmartBooks',
        'Societe Generale (EPA)',
        'Sofi',
        'Sofinco',
        'Sovos',
        'Spectrum Equity',
        'Spendesk',
        'Spreedly, Inc.',
        'Square',
        'Square, Inc.',
        "Standard & Poor's",
        'Standard Chartered',
        'State Bank of India (SBI)',
        'State Street (STT)',
        'State Street Alpha',
        'State Street Global Advisors (SSGA)',
        'Stephens, Inc.',
        'Stifel Financial',
        'StoneCo',
        'StoneX Group Inc.',
        'Stripes Group',
        'Sumitomo Mitsui Trust Holdings',
        'Summit Partners',
        'SunTrust Banks (STI)',
        'Sunstone Partners',
        'Susquehanna Growth Equity',
        'Swift GPI',
        'Synchrony (SYF)',
        'Synovus Financial Corp (SNV)',
        'T. Rowe Price (TROW)',
        'TA Associates',
        'TCV',
        'TD Ameritrade (AMTD)',
        'TD Bank (TD)',
        'TIAA',
        'TMF Group',
        'TORA',
        'TPG Capital',
        'TSYS (TSS)',
        'TVC Capital',
        'TWINT AG',
        'Tab Bank',
        'Tagetik',
        'Tamale RMS',
        'Telr',
        'Temenos',
        'Texas Capital Bank',
        'The Bonadio Group',
        'The Brink’s Company',
        'The Clearing House Payments Company LLC',
        'The Huntington National Bank',
        'Thoma Bravo',
        'Thomas H. Lee Partners',
        'Thomson Reuters Checkpoint',
        'Tidemark',
        'TimePayment',
        'Tink',
        'Total Expert',
        'TransUnion (TRU)',
        'Transamerica Retirement Solutions',
        'TransferWise',
        'Tricon Residential (TCN)',
        'Trintech',
        'Tritium Partners',
        'Trovata, Inc',
        'True Wind',
        'Truist',
        'TurboTax',
        'Turnkey Lender',
        'UBS AG',
        'UHNW Client Services',
        'UK Export Finance (UKEF)',
        'UMB Bank',
        'US Bancorp',
        'US Bank',
        'Umpqua Bank (UMPQ)',
        'UniCredit',
        'Unified Payments Interface (UPI)',
        'Union Bank',
        'United Services Automobile Association (USAA)',
        'Updata Partners',
        'VAT IT',
        'Vanguard',
        'VantageScore',
        'Vector Capital',
        'Veem',
        'VeriFone',
        'Veritas Capital',
        'Vertex Inc. (VERX)',
        'Vestar Capital Partners',
        'Virtu Financial Inc.',
        'Visa (V)',
        'Visa Dispute Monitoring Program (VDMP)',
        'Visa Fraud Monitoring',
        'Vista Equity Partners',
        'Volante',
        'Volition Capital',
        'Voya Financial',
        'Warburg Pincus',
        'Warren Averett',
        'Wave Financial',
        'Wavecrest',
        'Wayflyer',
        'WeChat Pay',
        'WePay',
        'Wealth Enhancement Group',
        'Wealth Management',
        'Wealthfront',
        'Weaver',
        'Wellington Management Company',
        'Wells Fargo (WFC)',
        'Western Asset Management Company',
        'Westpac (WBC)',
        'Whitley Penn',
        'Windcave',
        'Wipfli',
        'Wolf & Co.',
        'World Bank',
        'WorldFirst',
        'Worldpay',
        'Xendit',
        'YayPay',
        'Zacks Investment Research, Inc.',
        'Zcash (ZEC)',
        'Zelle',
        'Ziegler',
        'Zions Bancorporation (ZION)',
        'ZipPay',
        'Zoho Books',
        'Zopa',
        'Zurich Insurance',
        'Zycus',
        'dLocal LLP',
        'eCapital',
        'eFront',
        'ePlus',
        'hoolah',
        'nCino'
      ])
    ]
  },
  {
    id: 64,
    label: 'Funding & Loans',
    children: [
      ...new Set([
        'Business Banking',
        'Business Credit',
        'Business Funding',
        'Equipment Loans',
        'Financial Turnaround',
        'Invoice Financing',
        'Microloan',
        'Pre-qualification',
        'Program-Related Investments (PRIs)',
        'Purchasing Card',
        'Return on Marketing Investment'
      ])
    ]
  },
  {
    id: 65,
    label: 'Gadgets',
    children: [
      ...new Set([
        'Acoustic Camera',
        'DSLR Cameras',
        'Digital Cameras',
        'Digital Photo Frame',
        'Fitness and Health Device',
        'Google Nest',
        'Home Entertainment',
        'Human-Centric Lighting',
        'Radar Detector',
        'Robotic Vacuum Cleaner',
        'Smart Watch'
      ])
    ]
  },
  {
    id: 66,
    label: 'Gaming',
    children: [
      ...new Set([
        'Cloud Gaming',
        'Computer-Aided Design (CAD)',
        'Console Games',
        'Game Makers',
        'Gaming Design',
        'Gaming Solutions',
        'Independent Games',
        'Massively Multiplayer Online Game',
        'Mobile Gaming',
        'Online Gaming',
        'PC Games',
        'Parsec',
        'Smartphone and Tablet Games',
        'Social and Online Games',
        'Virtual Worlds'
      ])
    ]
  },
  {
    id: 67,
    label: 'General',
    children: [
      ...new Set([
        'BOPIS',
        'Click and Collect',
        'Clienteling',
        'Clothes Hangers',
        'Consumer Packaged Goods (CPG)',
        'Convex Safety Mirrors',
        'Custom Point-of-Purchase Displays',
        'Display Case',
        'Ergonomic Computer Chair',
        'In-Store Music',
        'Mannequin',
        'On-Shelf Availability (OSA)',
        'Pegboard Gondola',
        'People Counting',
        'Pop-up Retail',
        'Private Label Products',
        'Product Labeling',
        'Retail',
        'Retail Conversion Rate',
        'Retail Inventory',
        'Shopper Traffic',
        'Simulated Cameras'
      ])
    ]
  },
  {
    id: 68,
    label: 'Government Regulations',
    children: [
      ...new Set([
        'Affordable Care Act (ACA)',
        'CARES Act',
        'Drug Quality and Security Act',
        'Environment of Care Regulatory Compliance',
        'HIPAA Compliance',
        'HIPAA Compliant Call Answering',
        'HIPAA Compliant Messaging',
        'Health Care Law',
        'Health Information Privacy (HIPAA)',
        'Health Information Technology for Economic and Clinical Health Act (HITECH Act)',
        'Hearing Healthcare Regulatory',
        'Hierarchical Condition Categories (HCC)',
        'Medical Malpractice',
        'Medical Privacy',
        'Medicare Access and CHIP Reauthorization Act (MACRA)',
        'Protected Health Information (PHI)',
        'Sunshine Act',
        'Value-Based Care (VBC)'
      ])
    ]
  },
  {
    id: 69,
    label: 'HR',
    children: [
      ...new Set([
        '15Five',
        '7shifts',
        'ADP RPO',
        'ADP Workforce Now',
        'Accurate Background',
        'Achievers',
        'Actalent',
        'Adecco',
        'Aerotek',
        'AgileOne',
        'Alight Solutions',
        'Allegis Global Solutions',
        'Allegis Group',
        "America's Best 401K",
        'AppVault',
        'Appcast',
        'Aquent',
        'Aston Carter',
        'Atlas Technology Solutions, Inc.',
        'Avionte',
        'Bambee',
        'BambooHR',
        'Bayard Advertising',
        'Beacon Hill Staffing Group',
        'Blueboard',
        'Brazen',
        'BroadBean',
        'Bswift',
        'Businessolver',
        'CareerCircle',
        'Careerbuilder.com',
        'Ceridian (CDAY)',
        'Ceridian Dayforce',
        'Cielo',
        'ClearCompany.com',
        'Clio',
        'Cloudpay',
        'CoachHub Inc.',
        'CodeScreen',
        'CodeSignal',
        'CodeSubmit',
        'Codility',
        'Collabera',
        'Comparably',
        'Conduent',
        'Cornerstone OnDemand (CSOD)',
        'Criteria Corp',
        'Cross Country Medical Staffing Network (CCMSN)',
        'Culture Amp',
        'Darwinbox',
        'Deel, Inc.',
        'Deem',
        'Deputy',
        'DevSkills',
        'DirectPath',
        'DoSelect',
        'Eden Workplace',
        'Eightfold',
        'Eliassen Group',
        'Employ, Inc.',
        'Employee Family Protection (EFP)',
        'Employee Navigator',
        'Employee central',
        'Employment Hero',
        'Empower Retirement',
        'Empyrean',
        'Equifax Workforce Solutions',
        'Equus Software',
        'Ettain Group',
        'Express Employment Professionals',
        'Fetcher',
        'Filtered.ai',
        'ForUsAll',
        'Fuel50',
        'GI GROUP HOLDING S.p.A.',
        'Geektastic',
        'Genesis HR Solutions',
        'Glassdoor',
        'Global Upside, Inc.',
        'Globalization Partners, Inc.',
        'Google Hire',
        'Greenhouse Software',
        'Guideline',
        'HR360.com',
        'HSA Bank',
        'Handshake',
        'Harver',
        'Hays',
        'HealthEquity',
        'HireRight',
        'Hogan Assessments',
        'Homebase',
        'Hudson RPO',
        'Human Interest',
        'Hundred5',
        'IBM Kenexa',
        'Indeed',
        'Insight Global',
        'Insperity',
        'Jobbatical OU',
        'JustWorks',
        'Kadence Inc.',
        'Kazoo HR',
        'Kelly Staffing Services',
        'Kforce',
        'Koru',
        'Kronos',
        'LSI Staffing',
        'Lee Hecht Harrison',
        'Lever',
        'LinkedIn Recruiter',
        'LinkedIn Talent Insights',
        'Lucas James Talent Partners',
        'Lumesse',
        'Maximus (MMS)',
        'McQuaig',
        'Mercer',
        'Mercer Mettl',
        'Mettl',
        'Mindtickle Inc.',
        'Modern Hire',
        'Monster.com',
        'Morneau Shepell',
        'MyHub',
        'Namely',
        'O.C. Tanner',
        'Oasis Outsourcing',
        'OfficeSpace Software',
        'OneDigital Health and Benefits',
        'Oracle HCM Cloud',
        'Papaya Global',
        'PartnerComm, Inc.',
        'Paycom (PAYC)',
        'Paylocity',
        'Paytime',
        'Peakon',
        'PeopleDoc',
        'PeopleFluent',
        'PeopleReady',
        'PeopleScout',
        'Personio SE & Co.',
        'Phenom People',
        'Planday',
        'Plum Talent Management',
        'PowerToFly',
        'Predictive Index',
        'RUN Powered by ADP',
        'Randstad Holding',
        'Randstad Sourceright',
        'Ranstad',
        'Recruitee',
        'Recruitics',
        'Relode',
        'Reward Gateway',
        'Right Management',
        'RiseSmart',
        'Robert Half',
        'SD Worx',
        'SHL',
        'Saba Software',
        'SafeGuard World International LLC',
        'Safeguard Global',
        'Salary.com',
        'SecureVision',
        'Seekout.io',
        'Select International',
        'Serko',
        'Seven Step RPO',
        'Shiftboard',
        'Silkroad Technology Inc',
        'SimplyHired',
        'SmartRecruiters',
        'Snagajob',
        'Snap Enroll',
        'Sociabble',
        'Spherion',
        'Steele Benefit Services',
        'Sterling Check',
        'SumTotal Systems',
        'Symphony Talent',
        'TEKSystems',
        'TMP Worldwide',
        'TalentReef',
        'Taleo',
        'TempWorks Software',
        'TestGorilla',
        'The Muse',
        'Toggl Hire',
        'Topia',
        'Totaljobs',
        'TriNet (TriNet Group Inc)',
        'TrueBlue, Inc.',
        'Truework',
        'UltiPro',
        'Upwork',
        'VBA',
        'Vaco',
        'Velocity Global',
        'Vensure Employer Services',
        'Vibe HCM',
        'Virgin Pulse',
        'Visier',
        'WageWorks',
        'Wayup Inc',
        'Web Benefits Design',
        'Winston Benefits',
        'Wonderlic',
        'Workhuman',
        'Workmarket',
        'Workplace Solutions',
        'WorldatWork',
        'Yoh',
        'Zenefits',
        'Zoho Recruit',
        'eFacility',
        'eSkill',
        'iCIMS',
        'iSolved'
      ])
    ]
  },
  {
    id: 70,
    label: 'HR Tech',
    children: [
      ...new Set([
        'Computer-Based Training',
        'Corporate Portals',
        'Employee Attendance Software',
        'Employee Life Cycle',
        'Employee Onboarding Software',
        'Employee Portal',
        'FirstUp',
        'Group Calendars',
        'HR Automation',
        'HRIS (Human Resource Information Systems)',
        'Human Capital Management (HCM) Software',
        'Human Resource Technology',
        'Human Resources Management System (HRMS)',
        'Job Task Analysis (JTA)',
        'Labor Management Software',
        'Labor Market Analytics',
        'Learning Management Systems',
        'LinkedIn Jobs',
        'LinkedIn Talent Solutions (LTS)',
        'Microsoft Viva',
        'Payroll System',
        'Remote Administration',
        'SAP Employee Experience Management',
        'Scheduling Systems',
        'Shift Management Software',
        'SuccessFactors',
        'Teleconferencing',
        'Time Management Software',
        'Virtual Interview',
        'Workforce Management Software'
      ])
    ]
  },
  {
    id: 71,
    label: 'Hardware',
    children: [
      ...new Set([
        '4K (Display)',
        '4K2K',
        '4k Media Transfer',
        '5K (Display)',
        'AI Chips',
        'AI PC (Artificial Intelligence Personal Computer)',
        'AR Hardware',
        'ASIC Design',
        'Adapter Cards',
        'Application-Specific Integrated Circuits (ASICs)',
        'Automatic Identification and Capture (AIDC)',
        'Backward Compatible',
        'Battery management system (BMS)',
        'Bridge',
        'Cable Assemblies',
        'Central Processing Unit (CPU)',
        'Chip Design',
        'Chip Shortage',
        'Chiplet',
        'Circuit Design',
        'Compute Module',
        'Conference Phones',
        'Contactor',
        'Coprocessor',
        'Curved Monitor',
        'Cyclic Redundancy Check (CRC)',
        'Data Diode',
        'Data Processing Unit (DPU)',
        'Desk Phone',
        'Digital Light Processing (DLP)',
        'Digital Versatile Disc (DVD)',
        'DisplayPort',
        'Double Data Rate (DDR)',
        'Dual Monitors',
        'Eco-Friendly Printers',
        'Electronic Benefits Transfer (EBT)',
        'Electronic Component',
        'Electronic Logging Device (ELD)',
        'Electronic Numerical Integrator and Calculator (ENIAC)',
        'Encrypted USB',
        'Enterprise Headset',
        'Ereader',
        'Fan-out Wafer-Level Packaging',
        'Flexible PCB',
        'GPU Clusters',
        'Graphics Processing Unit (GPU)',
        'HDMI (High-Definition Multimedia Interface)',
        'Hard Drives',
        'Hardware',
        'Hardware Design',
        'Hardware Engineering',
        'Hardware Failures',
        'Hardware Infrastructure',
        'Hardware Innovation',
        'Hardware Resources',
        'Hardware/Peripherals',
        'High Bandwidth Memory (HBM)',
        'Hub',
        'Hybrid Computer',
        'In-Plane Switching (IPS)',
        'Inductor',
        'Industrial Computer',
        'Inertial Navigation System',
        'Interactive Projector',
        'Interactive Whiteboards',
        'LED Displays',
        'Laptop Shortage',
        'Large-Format Display',
        'Limit Switch',
        'Load Balancer',
        'M1',
        'Mainframes',
        'Membrane Switch',
        'Mobile Projector',
        'Monitors',
        'Motherboard',
        'Motion Sensor',
        'Multi-Function Printing',
        'Multi-Touch Displays',
        'Multimedia Monitor',
        'Multiplexers',
        'OLED Display',
        'Office Printers',
        'Optical Drives',
        'PCB Design',
        'PCB Manufacturing',
        'Panel PC',
        'Passive Components',
        'Peripheral Component Interconnect (PCI) Express',
        'Phone Charging Station',
        'Plasma Display',
        'Plastic Optical Fiber (POF)',
        'Pneumatic Tube',
        'Position Sensors',
        'Power Cable',
        'Power Connectors',
        'Power Electronics',
        'Pressure Sensors',
        'Printed Electronics',
        'Printers',
        'Printing Solutions',
        'Processing Equipment',
        'Processors',
        'Projector',
        'Public Address System (PA system)',
        'Quantum Computing',
        'RFID',
        'Radio Frequency (RF) & Microwave PCBs',
        'Radio Frequency Integrated Circuits (RFIC)',
        'Reduced Instruction Set Computer (RISC)',
        'Relays',
        'Resistors',
        'Rotary Encoders',
        'Rugged Tablet',
        'Scanner',
        'Semiconductor IP',
        'Serial ATA (SATA)',
        'Set-Top Box (STB)',
        'Single-Board Computer (SBC)',
        'Smart Board',
        'Smart Cards',
        'Supercomputing',
        'Switched-Mode Power Supply (SMPS)',
        'System On a Chip (SoC)',
        'System-on-Module (SoM)',
        'Tachograph',
        'Torque Sensors',
        'Touch Monitor',
        'Touch Overlay',
        'Touch Screen Monitor',
        'Transformers',
        'Transparent Displays',
        'Transparent LCD',
        'Tricorder',
        'True Wireless Earbuds',
        'Ultrasonic Sensors',
        'Unidirectional Gateway',
        'Used Hardware',
        'Vibration Sensors',
        'Von Neumann Architecture',
        'Waterproof Computer',
        'Webcam',
        'Wire Harnessing',
        'Workstations',
        'eCard',
        'vPro Chip'
      ])
    ]
  },
  {
    id: 72,
    label: 'Health',
    children: [
      ...new Set([
        'ABILITY Network',
        'AbbVie (ABBV)',
        'Abbott Laboratories (ABT)',
        'Ableto',
        'Access Healthcare Services',
        'Access TeleCare',
        'AccessOne',
        'Accolade',
        'Adventist Health',
        'Agfa HealthCare',
        'Agilent Technologies',
        'Alayacare',
        'Allscripts',
        'Alluma',
        'Alphabet (Holding Company)',
        'Altair Health',
        'Ambra Health',
        'Ambry Genetics',
        'AmerisourceBergen (ABC)',
        'Amgen (AMGN)',
        'Amion',
        'Amwell',
        'AngioDynamics',
        'Anytime Fitness Franchisor',
        'Aptitude Health',
        'Arc Healthcare, LLC',
        'Arcadia',
        'Artisight, Inc.',
        'Astellas Pharma (ALPMY)',
        'AstraZeneca (AZN)',
        'Athenahealth',
        'Atrius Health, Inc.',
        'Auris Health',
        'AvaSure',
        'Availity',
        'Axxess Technology Solutions, Inc.',
        'Azalea Health',
        'B-SOFT Co.,Ltd.',
        'Baxter International Inc.',
        'Baylor Genetics',
        'Beacon Health Options',
        'Big Health',
        'BioNTech (BNTX)',
        'Boehringer Ingelheim Corporation',
        'Bolder Healthcare Solutions',
        'Boston Scientific (BSX)',
        'BrainCheck, Inc.',
        'Bravo Wellness',
        'Brightree',
        'Bristol Myers Squibb (BMY)',
        'CHG Healthcare',
        'CMR Surgical',
        'CVS Health (CVS)',
        'Cardinal Health (CAH)',
        'Care.ai',
        'CareCloud',
        'CareView Communications, Inc.',
        'Caregility Corporation',
        'Carenet Health',
        'Carrot Fertility',
        'Castlight Health',
        'Cedar',
        'Cedar Gate Technologies',
        'Centene Corp.',
        'Centricity',
        'Cepheid',
        'Cerner',
        'Change Healthcare',
        'Cigna (CI)',
        'Clarify Health Solutions',
        'Clarisonic',
        'Clearwater Compliance LLC',
        'College of Healthcare Information Management Executives',
        'Collette Health, Inc.',
        'Community Health Systems (CYH)',
        'Compliancy Group',
        'CompuGroup Medical (CGM)',
        'Compulink',
        'Conversa Health',
        'Cotiviti',
        'Credible Behavioral Health',
        'DSM',
        'Danaher',
        'Dignity Health',
        'Dolbey Systems',
        'Doxy.Me',
        'DrChrono',
        'Dräger',
        'Elekta',
        'Elevance Health (ELV)',
        'Eli Lilly',
        'Emergent BioSolutions Inc.',
        'Ensemble Health',
        'Envera Health',
        'Envista',
        'Epic.com',
        'Episource',
        'Equum Medical',
        'Evernorth',
        'Exos',
        'Experian Health',
        'Express Scripts',
        'Fentanyl',
        'Fitbit',
        'FlexCare Medical Staffing',
        'Fluxa Inc.',
        'Forcura LLC',
        'Fusion Narrate',
        'GE Healthcare',
        'GHX',
        'GSK',
        'Geisinger Health System',
        'GeneDx, LLC',
        'Genentech',
        'George Clinical',
        'Get Real Health',
        'Gilead Sciences (GILD)',
        'Ginger.io',
        'GlaxoSmithKline Plc',
        'GoMo Health',
        "Gold's Gym International, Inc.",
        'GoodLife Fitness Centres Inc.',
        'Grand Rounds',
        'Greenlight Guru',
        'Greenway Health',
        'HBCS',
        'HCA Healthcare',
        'HITRUST',
        'HL7',
        'Haemonetics Corporation',
        'Health Resources and Services Administration',
        'HealthCatalyst',
        'HealthEC',
        'HealthEdge',
        'HealthFitness',
        'HealthStream',
        'HealthTap',
        'HealthTrust Performance Group',
        'Healthgrades',
        'Henry Schein',
        'Highmark Health',
        'Hikma Pharmaceuticals plc',
        'Hillrom',
        'Hinge Health',
        'Homecare Homebase, LLC',
        'Humana',
        'IMS Health (Q)',
        'Illumina',
        'InSync',
        'Indivior PLC',
        'Innovaccer',
        'Insight Timer',
        'Inspirata',
        'InteliChart, LLC',
        'Intermountain Healthcare',
        'Iqvia (IQV)',
        'Jackson Laboratory',
        'Janssen Pharmaceuticals',
        'Johnson Health Tech (JHT)',
        'Joint Commission International (JCI)',
        'Kaiser Permanente',
        'Kareo',
        'Kimberly-Clark (KMB)',
        'KingFisher Apex System',
        'Klara',
        'Kyruus',
        'Life Fitness',
        'Life Image',
        'Lightbeam Health Solutions',
        'Linus Health',
        'Livongo',
        'Loyale',
        'Luma Health',
        'Lyra Health',
        'MCG Health',
        'MD Live',
        'Magellan Health Services',
        'Marathon Health',
        'Matrix Fitness',
        'MatrixCare',
        'Maven Clinic',
        'Maxwell Healthcare Associates, LLC',
        'McKesson',
        'MedAssist',
        'MedBridge',
        'MedData',
        'Medecision, Inc.',
        'MediSys',
        'Medical Device Management',
        'Meditech',
        'Medline Industries, Inc.',
        'Mend',
        'Merck (MRK)',
        'Meridian Bioscience, Inc. (VIVO)',
        'MindWell Center, LLP',
        'Mindray',
        'Mindstrong Health',
        'Mobile Heartbeat',
        'Modern Health',
        'Moderna',
        'Modernizing Medicine',
        'Molina Healthcare',
        'Naloxone',
        'National Government Services',
        'Netsmart',
        'NextGen Healthcare',
        'Nextech',
        'Nihon Kohden',
        'Nordic Naturals',
        'Notable Health',
        'Novartis (NVS)',
        'OODA Health',
        'OPVEE',
        'Omada Health',
        'Omnicell',
        'Oncology Analytics',
        'Oncomed Pharmaceuticals Inc (OMED)',
        'Optum',
        'Oracle Health Lifesciences',
        'Ortho-Clinical Diagnostics, Inc.',
        'Oscar Health (OSCR)',
        'Ovia Health',
        'Owens & Minor',
        'PPD, Inc. (PPD)',
        'Parallon',
        'PatientCo',
        'PatientWorks',
        'Patterson Dental',
        'Patterson Vet',
        'Peloton Interactive, Inc.',
        'PerkinElmer',
        'Perrigo Company (PRGO)',
        'Philips Healthcare',
        'Phreesia',
        'Planet Fitness, Inc. (PLNT)',
        'Planned Parenthood',
        'PointClickCare',
        'Porzio Life Sciences',
        'Practice Fusion',
        'Precision for Medicine',
        'Precor Incorporated',
        'Premera Blue Cross',
        'Press Ganey',
        'PreventionGenetics',
        'Providence Health & Services',
        'Qualifacts',
        'Quantum Health',
        'R&D Systems',
        'R1 RCM',
        'Radiology Operations Command Center (ROCC)',
        'Radix Health',
        'Randox Laboratories',
        'Real Chemistry',
        'Rectangle Health',
        'Redox',
        'Regeneron Pharmaceuticals (REGN)',
        'RelayHealth',
        'Relias',
        'Roche',
        'SE Healthcare',
        'SR Health',
        'SUVODA LLC',
        'Salesforce Health Cloud',
        'Sanofi',
        'Sectra',
        'SelectHealth',
        'Sg2',
        'Sharecare',
        'ShiftWise',
        'Siemens Healthineers',
        'Simple Habit',
        'Simplee',
        'Solutionreach',
        'Sonic Boom Wellness',
        'Spark Health',
        'Spok (SPOK)',
        'Spring Health',
        'SteadyMD Inc.',
        'Stericycle',
        'Stryker Corporation',
        'Syneos Health',
        'TRX Training',
        'Takeda (TAK)',
        'Talkspace',
        'Tecan',
        'TeleTracking Technologies, Inc.',
        'Telmediq',
        'Tenet Healthcare',
        'Thrive Global',
        'Titan Medical',
        'Tivity Health',
        'Trinity Health Corporation',
        'Trizetto',
        'Tytocare',
        'UnitedHealthcare (UHC)',
        'Veeva Systems',
        'Vertex Pharmaceuticals',
        'Vida Health',
        'Vivify Health',
        'Vizient',
        'WELL Health',
        'Wakunaga of America',
        'Walgreens (WBA)',
        'Wavemark',
        'Waystar',
        'WellSky Corporation',
        'Xtend Healthcare',
        'Yosi, Inc.',
        'ZOLL Medical Corporation',
        'ZocDoc',
        'bioMérieux SA',
        'bright.md',
        'eClinical Works',
        'eHealth Ontario',
        'eVisit',
        'nThrive'
      ])
    ]
  },
  {
    id: 73,
    label: 'Health Insurance',
    children: [
      ...new Set([
        'Accident Insurance',
        'Accountable Care Organizations (ACO)',
        'Acute Care',
        'Appeals and Grievances',
        'Bundled Payments',
        'Claim Denial',
        'Claims Auditing / Analysis',
        'Claims Processing',
        'Critical Illness Insurance',
        'Exclusive Provider Organization (EPO)',
        'Fiscal Agent Services',
        'Health Care Costs',
        'Health Care Quality',
        'Health Maintenance Organization (HMO)',
        'Healthcare / Medical Lien',
        'Healthcare.gov',
        'Hospital Indemnity Insurance',
        'Hybrid LTC',
        'Insurance Eligibility and Verification',
        'Laboratory Claims',
        'Large Group Health Plan',
        'Medical Reimbursement',
        'Medicare Advantage',
        'Medicare Supplement',
        'Preferred Provider Organization (PPO)',
        'Prescription Drug Coverage',
        'Primary Care',
        'Primary Care Case Management (PCCM)',
        'Primary Care Physician (PCP)',
        'RADV',
        'Retiree Health Coverage'
      ])
    ]
  },
  {
    id: 74,
    label: 'Health Tech',
    children: [
      ...new Set([
        'Advanced Visualization',
        'Appointment Management',
        'Asynchronous Telehealth',
        'Automated External Defibrillator (AED)',
        'Bedside Technology',
        'COVID-19 App',
        'COVID-19 Exposure Notification',
        'Clinical Decision Support (CDS)',
        'Clinical Trial Management System (CTMS)',
        'Computer-Assisted Coding (CAC)',
        'Computerized Physician Order Entry (CPOE)',
        'Connected Health',
        'Contract Research Organization (CRO)',
        'Datamonitor',
        'Decision Support Systems',
        'Defibrillator Monitors',
        'Digital Therapeutics',
        'Elastography',
        'Electrocardiography (EKG)',
        'Electroencephalography (EEG)',
        'Electronic Common Technical Document (eCTD)',
        'Electronic Data Capture (EDC)',
        'Electronic Health Record (EHR)',
        'Electronic Health Record (EHR) Replacement',
        'Electronic Medical Record (EMR)',
        'Electronic Prescriptions for Controlled Substances (EPCS)',
        'Electronic Trial Master File (eTMF)',
        'Electronic Visit Verification (EVV)',
        'Endoscopy',
        'Enterprise Master Patient Index (EMPI)',
        'Fluoroscopy',
        'Good Clinical Practice (GCP)',
        'HL 7 Interface',
        'Health IT Consulting',
        'Health Information Exchange (HIE)',
        'Healthcare Big Data',
        'Healthcare Digital Front Door',
        'Healthcare Interoperability',
        'Healthcare Pagers',
        'Healthcare Technology Management (HTM)',
        'Hearing Healthcare Technology',
        'ICD-10',
        'ICD-10 Conversion',
        'In Vitro Diagnostics (IVD)',
        'Internet of Healthcare things (IOHT)',
        'MRI (Magnetic Resonance Imaging)',
        'Magnetoencephalography',
        'Medial Implants',
        'Medicaid Enterprise Certification Toolkit (MCET)',
        'Medicaid Information Technology Architecture (MITA)',
        'Medicaid Management Information System (MMIS)',
        'Medical Device User Interface',
        'Medical Devices',
        'Medical Devices Enterprise Resource Planning (ERP)',
        'Medical Equipment',
        'Medical Image Sharing / Exchange',
        'Medical Imaging',
        'Medical Technology',
        'Medical Tubing',
        'Mobile Health (mHealth)',
        'Nuclear Medicine',
        'Nurse Call Systems',
        'PACS / Radiology / Enterprise Image',
        'PET (Positron Emission Tomography)',
        'Patient Administration',
        'Patient Data',
        'Patient Portals',
        'Pharmacy Management Systems',
        'Picture Archiving Communication Systems (PACS)',
        'Quality Management System (QMS)',
        'Radiological Information System (RIS)',
        'Remote-Patient Monitoring',
        'Revenue Cycle Analytics',
        'Risk-based Monitoring',
        'Robot Assisted Surgery',
        'SPECT (Single-Photon Emission Computed Tomography)',
        'Software as a Medical Device (SaMD)',
        'Standard Operating Procedure (SOP)',
        'Synchronous Telehealth',
        'Tele-ICU',
        'Telecare',
        'Teledentistry',
        'Teledermatology',
        'Telehealth',
        'Telemedicine',
        'Telemedicine Carts',
        'Telemedicine Software',
        'Telepsychiatry',
        'Teleradiology',
        'Telestroke',
        'Ultrasonography',
        'Vendor Neutral Archive (VNA)',
        'Virtual Hospitals',
        'Wearable Medical Device',
        'Wireless Health',
        'X-Ray CT (X-Ray Computed Tomography)',
        'e-Forms',
        'e-Health',
        'e-Prescribing',
        'eICU'
      ])
    ]
  },
  {
    id: 75,
    label: 'Hotels & Accommodations',
    children: [
      ...new Set([
        'Boutique Hotels',
        'Hotel Eviction',
        'Hotel Management Company',
        'Hotel Marketing',
        'Hotel Voucher'
      ])
    ]
  },
  {
    id: 76,
    label: 'IT Management',
    children: [
      ...new Set([
        'Application Acceleration',
        'Application Management',
        'Application Offboarding',
        'Application Onboarding',
        'Application Performance Monitoring (APM)',
        'Bi-Modal IT',
        'Business Continuity',
        'Business Relationship Management (BRM)',
        'Centralized Computing',
        'Change and Release Management',
        'Charge Back',
        'Choice Architecture',
        'Computacenter',
        'Container Orchestration',
        'Continuous Availability',
        'Database as a Service (DBaaS)',
        'Deployment Options',
        'Digitalization',
        'Disparate Systems',
        'Domain Expertise',
        'Downtime',
        'ELK Stack',
        'Email Management',
        'Event Management',
        'Global Website Performance',
        'Hybrid IT Environments',
        'IT Asset Discovery',
        'IT Asset Disposition (ITAD)',
        'IT Asset Management',
        'IT Careers',
        'IT Consulting',
        'IT Cost Management',
        'IT Efficiency',
        'IT Industry',
        'IT Infrastructure',
        'IT Leadership',
        'IT Management',
        'IT Operations',
        'IT Operations Management',
        'IT Optimization',
        'IT Outsourcing',
        'IT Portfolio Management',
        'IT Service Management',
        'IT Service Optimization (ITSO)',
        'IT Spending',
        'IT Staff Augmentation',
        'IT Strategy',
        'IT Support',
        'IT Transformation',
        'IT process automation (ITPA)',
        'ITIL',
        'Incident and Problem Management',
        'Information Governance',
        'Infrastructure Monitoring',
        'Infrastructure Provisioning',
        'Infrastructure as code (IaC)',
        'Innovation',
        'Integration Platform as a Service (iPaaS)',
        'Lenovo Truscale Infrastructure Services',
        'Load Testing',
        'Log Management',
        'MACH Architecture',
        'Managed IT Service',
        'Managed Services',
        'Mobile Application Management',
        'Obsolescence Management',
        'Offshore Software R&D',
        'On-Premise Applications',
        'Patch',
        'Recovery Time Objective (RTO)',
        'Remote Monitoring and Management Software',
        'Routing and Switching',
        'SaaS Operations Management',
        'Service Delivery',
        'Service Management',
        'Smarter Computing',
        'Software Compliance',
        'Software Development Outsourcing',
        'Software Licensing',
        'Software Version Management',
        'Sustainability',
        'Sustainability Reporting',
        'Sustainable IT',
        'System Architecture',
        'System Management Software',
        'Systems Management',
        'Technical Support (Tech Support)',
        'Technology Design & Architecture',
        'Technology Stack',
        'Thin Client',
        'User Management',
        'Virtual Collaboration',
        'Virtualization Management',
        'Visual Solutions',
        'Website Performance',
        'Zero Client'
      ])
    ]
  },
  {
    id: 77,
    label: 'Immigration',
    children: [
      ...new Set([
        'Adjustment Interview',
        'Citizen',
        'Conditional Resident',
        'Consulate',
        'E-Verify',
        'Embassy',
        'Immigration',
        'Immigration Law',
        'Request For Evidence (RFE)',
        'Visas'
      ])
    ]
  },
  {
    id: 78,
    label: 'Instruments',
    children: [
      ...new Set([
        'Air Ionizer',
        'Alkaline Electrolyzer',
        'Autoclave',
        'Body Composition Analyzer',
        'Coordinate Measurement Machine (CMM)',
        'Cryogenic Storage',
        'Cryopreservation',
        'Digital Microscope',
        'Dynabeads Magnetic Beads',
        'Endotoxin-Free Plasmid Kit',
        'Environmental Chambers',
        'Fluorescence Microscope',
        'PEM Electrolyzer',
        'Spin Column Kit',
        'Stereomicroscope',
        'Thermal Cyclers'
      ])
    ]
  },
  {
    id: 79,
    label: 'Insurance',
    children: [
      ...new Set([
        'AON (AON)',
        'Accidental Death and Dismemberment Insurance (AD&D)',
        'Acuity Insurance',
        'AdvantageGo',
        'Aetna (AET)',
        'Aflac (AFL)',
        'Alliant',
        'Allstate (ALL)',
        'Alternative Risk Transfer (ART)',
        'AmTrust Financial',
        'American International Group (AIG)',
        'Ameritas',
        'Amica Insurance',
        'Anthem (ANTM)',
        'Aon',
        'Arch Capital Group',
        'Assignment of Benefits',
        'Assurant',
        'Assured Partners',
        'Asurion',
        'Auto Insurance',
        'Average Adjusters',
        'Avetta, LLC',
        'Aviva',
        'Axa XL',
        'BMS Group Limited',
        'Bad-Faith Claim',
        'Beazley Group',
        'Blue Cross and Blue Shield Association (BCBSA)',
        'Blue Cross and Blue Shield of Louisiana',
        'Blue Shield of California',
        'Brown & Brown Insurance',
        "Builder's Risk Insurance",
        'Business Insurance',
        'Business Owners Policy (BOP)',
        'CNA Insurance',
        'COBRA (Health Insurance Continuation)',
        'COUNTRY Financial',
        'CUNA Mutual Group',
        'Cancer Insurance',
        'Cannabis Insurance',
        'Captives',
        'CareFirst BlueCross BlueShield',
        'Cargo Insurance',
        'Casualty Insurance',
        'Certificate of Insurance (COI)',
        'Chubb',
        'Claims Adjuster',
        'Claims Management',
        'Colonial Life & Accident Insurance Company',
        'Commercial Auto Insurance',
        'Commercial Insurance',
        'Commercial Lines',
        'Concirrus',
        'Condo Insurance',
        'Construction Insurance',
        'Coterie',
        'Cottingham & Butler',
        'CoverWallet',
        'Credit Insurance',
        'Crop Insurance',
        'Cyber Insurance Risk Management',
        'Cyber Liability Insurance',
        'Death Benefit',
        'Dental Insurance',
        'Deposit Insurance',
        'Device Protection',
        'Directors and Officers Liability Insurance',
        'Disability Insurance',
        'E&S Specialty Lines',
        'EPIC Insurance Brokers',
        'Economical Insurance',
        'Embedded Insurance',
        'Employee Retirement Income Security Act of 1974 (ERISA)',
        'Employment Practice Liability Insurance (EPLI)',
        'Environmental Insurance',
        'Errors & Omissions (E&O)',
        'Esurance',
        'Expatriate Insurance',
        'FM Global',
        'FMG Insurance Limited',
        'Farmers Insurance',
        'Feasibility Analysis',
        'Federal Deposit Insurance Corporation (FDIC)',
        'Federal Insurance Contributions Act (FICA)',
        'Federato Inc.',
        'Flood Coverage',
        'Fortegra Financial',
        'Freight Claim',
        'GEICO',
        'Gallagher (AJG)',
        'General Liability Insurance',
        'Great American Insurance Group',
        'Group Captive',
        'Group Insurance Program',
        'Group Life Insurance',
        'HUB International',
        'Hartford Steam Boiler',
        'Health Insurance',
        'High Deductible Health Plan (HDHP)',
        'Hiscox Business Insurance',
        'Homeowners Insurance',
        'Honan Insurance Group Pty Ltd',
        'Hull Insurance',
        'Identity Theft Insurance',
        'Income protection insurance',
        'Independent Health',
        'Insurable Interest',
        'Insurance Adjuster',
        'Insurance Broker',
        'Insurance Carrier',
        'Insurance Consulting and Technology',
        'Insurance Products',
        'Insurance Quantified',
        'Insurance Underwriter',
        'Insurance: Voluntary Benefits (Home, Auto, etc.)',
        'Insurity',
        'Insurtech',
        'JLT Group',
        'John Hancock',
        'Kemper (KMPR)',
        'Legal Plans',
        'Lenders Mortgage Insurance (LMI)',
        'Liberty Mutual Insurance',
        'Life Insurance',
        'Lincoln National (LNC)',
        "Lloyd's of London",
        'Lockton',
        'Loss Control',
        'Managing General Agent',
        'Marine Insurance',
        'Marsh',
        'Marsh & McLennan Companies (MMC)',
        'MassMutual',
        'Mergers & Acquisitions (M&A) Insurance',
        'MetLife (MET)',
        'Minimum Essential Coverage',
        'Munich Re',
        'Mutual of Omaha',
        'NFP',
        'NJM Insurance Group',
        'Nationwide',
        'Net Zero Insurance Alliance (NZIA)',
        'New York Life Insurance',
        'Next Insurance',
        'P&I Insurance',
        'Pacific Life',
        'Parametric Insurance',
        'Penn Mutual',
        'Pension Risk',
        'Permanent Life Insurance',
        'Personal Lines Insurance',
        'Pet Insurance',
        'Policy Administration',
        'Political Risk Insurance',
        'Premium-Only-Plan (POP)',
        'Professional Liability Insurance',
        'Progressive (PGR)',
        'Property Damage',
        'Property Insurance',
        'Protected Cell',
        'QBE Insurance',
        'RSA Insurance',
        'Reinsurance',
        'Renters Insurance',
        'Reputational Risk',
        'Retail Insurance',
        'Risk Appetite',
        'Risk Management',
        'Risk Modeling',
        'Risk Placement Services, Inc.',
        'Risk Strategies',
        'STARR',
        'Safeco Insurance',
        'Self-Insurance',
        'Sentry Insurance',
        'Sexual Abuse and Molestation Insurance',
        'Small Business Insurance',
        'Solvency II Directive 2009',
        'Sompo International',
        'State Auto',
        'State Farm',
        'Structured Settlements',
        'Subcontractor Default Insurance (SDI)',
        'Supplemental Health Insurance',
        'Surplus Lines Insurance',
        'Swiss Re Group',
        'Term Life Insurance',
        'The Hartford',
        'The Mutual Fire Insurance Company of British Columbia',
        'Thimble',
        'Title Insurance',
        'Total and Permanent Disability (TPD) Insurance',
        'Transamerica',
        'Travelers',
        'Trucking Insurance',
        'USI',
        'Umbrella Liability Insurance',
        'Unemployment Insurance',
        'UnitedHealth Group (UNH)',
        'Universal Life Insurance',
        'Unum (UNM)',
        'Vendor Risk Management (VRM)',
        'Vision Insurance',
        'War Risk Insurance',
        'Warranty & Indemnity (W&I) Insurance',
        'West Bend Mutual Insurance',
        'Whole Life Insurance',
        'Willis Towers Watson (WLTW)',
        'Woodruff Sawyer',
        'Work Opportunity Tax Credit (WOTC)'
      ])
    ]
  },
  {
    id: 80,
    label: 'Intellectual Property',
    children: [
      ...new Set([
        'Blackout Period',
        'Certificate of Registration',
        'Certification Mark',
        'Commerce Clause',
        'Copyright',
        'Digital Millennium Copyright Act (DMCA)',
        'Digital Rights Management (DRM)',
        'Examining Attorney',
        'IP Docketing',
        'IP Management',
        'Infringement',
        'Intellectual Property',
        'Intellectual Property Law',
        'Intellectual Property Lawyer',
        'PROTECT IP Act (PIPA)',
        'PTAB Litigation',
        'Patent Counseling',
        'Patent License Agreement',
        'Patent Litigation',
        'Patent Pending',
        'Patent Reform',
        'Patent and Trademark Office',
        'Patents',
        'Prior Art',
        'Trade Secrets',
        'Trademark',
        'Trademark Litigation',
        'Trademark Management',
        'Trademark Office',
        'Video Game Patent'
      ])
    ]
  },
  {
    id: 81,
    label: 'Jail & Prison',
    children: [
      ...new Set([
        'Video Visitation'
      ])
    ]
  },
  {
    id: 82,
    label: 'Lab Automation',
    children: [
      ...new Set([
        'Centrifuge',
        'Chillers',
        'Circulators',
        'Electronic Lab Notebook (ELN)',
        'Hot Plate',
        'Laboratory Robotics',
        'Magnetic Stirrer',
        'Mass Customization',
        'Periodic Operating Point (POP) Analysis',
        'Rheometer',
        'Vacuum Pumps',
        'Viscometer',
        'Water Purification'
      ])
    ]
  },
  {
    id: 83,
    label: 'Lab Data Management & Analysis',
    children: [
      ...new Set([
        'Laboratory Informatics',
        'Laboratory Information Management Systems (LIMS)',
        'Laboratory Quality Control',
        'QC Inspection',
        'Scientific Data Management System (SDMS)'
      ])
    ]
  },
  {
    id: 84,
    label: 'Labor',
    children: [
      ...new Set([
        '403(b)',
        'Accessibility for Ontarians with Disabilities Act (AODA)',
        'Affirmative Action',
        'Age Discrimination in Employment Act (ADEA)',
        'California Family Rights Act (CFRA)',
        'California Pregnancy Disability Leave',
        'Child Labor',
        'Disabilities (ADA)',
        'Discrimination',
        'Employment Law',
        'Employment Practices Liability',
        'Equal Employment Opportunity (EEO)',
        'Fair Labor Standards Act (FLSA)',
        'Form 5500 Series',
        'Garnishment',
        'Global Employment Law',
        'Harassment',
        'Labor Union',
        'Laws and Regulations',
        'Leave of Absence (FMLA)',
        'Lost Wages',
        'Minimum Wage',
        'Occupational Safety and Health Act of 1970',
        'Overtime Pay',
        'The Fair Labor Standards Act of 1938',
        'Top Hat Plan',
        'Unemployment',
        'Whistleblower Laws',
        "Workers' Compensation",
        'Workplace Bullying',
        'Workplace Injury',
        'Workplace Investigations',
        'Wrongful Employment Termination'
      ])
    ]
  },
  {
    id: 85,
    label: 'Labor Relations',
    children: [
      ...new Set([
        'Collective Bargaining',
        'Employment Contracts',
        'Global Workforce Management',
        'Labor Relations',
        'Overtime',
        'Unions'
      ])
    ]
  },
  {
    id: 86,
    label: 'Landmark Cases',
    children: [
      ...new Set([
        'Brown v. Board of Education',
        'Dred Scott v. Sandford',
        'Gibbons v. Ogden',
        'Gideon v. Wainwright',
        'Korematsu v. United States',
        'Lochner v. New York',
        'Mapp v. Ohio',
        'Marbury v. Madison',
        'McCulloch v. Maryland',
        'Miranda v. Arizona',
        'Plessy v. Ferguson',
        'Roe v. Wade',
        'United States v. Nixon'
      ])
    ]
  },
  {
    id: 87,
    label: 'LawTech',
    children: [
      ...new Set([
        'Artificial Intelligence Compliance',
        'Claims Automation',
        'Computer Forensics',
        'DeNIST',
        'Document Review Platform',
        'E-Discovery Sanctions',
        'Electronic Discovery Reference Model (EDRM)',
        'Electronic Evidence',
        'EnCase',
        'Forensic Collection',
        'Forensic Imaging',
        'Forensically Sound Collection',
        'Law Practice Management Software',
        'Legal Analytics',
        'Legal Matter Management Software',
        'Legal Spend Management',
        'Loophole',
        'eDiscovery'
      ])
    ]
  },
  {
    id: 88,
    label: 'Leadership & Strategy',
    children: [
      ...new Set([
        'Centers of Excellence (CoEs)',
        'Employee Advocacy',
        'Facilities Management',
        'Frontline Leadership',
        'Generational Difference',
        'Global Mobility',
        'Group Leaders',
        'HR Outsourcing',
        'HR Professionals',
        'HR Strategy',
        'Human Capital Management',
        'Human Resource Management',
        'Job Analysis',
        'Leadership Assessment',
        'Leadership Strategies',
        'Management Development',
        'Operational Assessment',
        'Organizational Assessment',
        'Organizational Change Management',
        'Organizational Structure',
        'Proactive Management',
        'Quality Management',
        'Strategic Management',
        'Student Organization Management',
        'Talent Mobility',
        'Workforce Management',
        'Workforce Mobility',
        'Workforce Planning',
        'Workstyle Profiling',
        'Workstyle Transformation'
      ])
    ]
  },
  {
    id: 89,
    label: 'Legal & Regulatory',
    children: [
      ...new Set([
        'EOC Compliance',
        'Military Service (USERRA)',
        'Reporting and Disclosure',
        'Return to Work',
        'Right to Disconnect',
        'Sexual Harassment',
        'Transfer of Undertakings (Protection of Employment) Regulations (TUPE)'
      ])
    ]
  },
  {
    id: 90,
    label: 'Legal Services',
    children: [
      ...new Set([
        'Alternative Legal Service Providers',
        'Attorney Fees',
        'Bar Associations',
        'Chain of Custody',
        'Claimant Education',
        'Claims Handling',
        'Commercial Litigation',
        'Commercial Real Estate Lawyer',
        'Court Reporting',
        'Defense Attorney',
        'Early Case Assessment',
        'Enterprise Legal Management (ELM)',
        'Forensics',
        'Insurance Defense',
        'Intent to Use',
        'Law Practice Optimization/Legal Practice Optimization (LPO)',
        'Legal',
        'Legal Aid',
        'Legal Bill Review (LBR)',
        'Legal Client Intake',
        'Legal Education',
        'Legal Ethics and Attorney Discipline',
        'Legal Hold',
        'Legal Malpractice',
        'Legal Matter Management',
        'Legal Operations',
        'Legal Practice Management',
        'Legal Process Outsourcing',
        'Legal Professional Privilege',
        'Legal Project Management',
        'Legal Research',
        'Legitimate Interest',
        'Litigation',
        'M&A Lawyer',
        'Meet and Confer',
        'Paralegal',
        'Patent Agent',
        'Practice of Law',
        'Privilege Log',
        'Pro Bono',
        'Process Service',
        'Proportionality',
        'Safe Harbor',
        'Spoliation of Evidence',
        'Trial Prep / Trial Strategy',
        'Women in Law'
      ])
    ]
  },
  {
    id: 91,
    label: 'Life Science',
    children: [
      ...new Set([
        'Antibodies',
        'Antibody discovery',
        'Artificial Organs',
        'Automated Purification',
        'CRISPR-Associated Protein 9 (CRISPR)',
        'Cell Isolation',
        'Cell Line',
        'Cell Processing System',
        'Cell Separation',
        'Cell Suspension',
        'Cell Therapy Manufacturing',
        'Cell Wash',
        'Cell line development',
        'Cloning',
        'DNA',
        'DNA Extraction',
        'DNA Ladder',
        'DNA Polymerase',
        'Enzyme Inhibitor',
        'Enzymes',
        'Gene Expression',
        'Genome Editing',
        'Genomic DNA Extraction',
        'Hybrid',
        'Immune Cells',
        'Immune System',
        'Induced pluripotent stem cells (ipscs)',
        'Ligase',
        'Live Cell Imaging',
        'Magnetic Cell Separation',
        'Nuclear Magnetic Resonance',
        'Oligonucleotide',
        'Plasmid DNA Purification',
        'Preclinical Imaging',
        'Protein',
        'RNA',
        'RNA Polymerase',
        'RNA Sequencing',
        'Real-time Polymerase Chain Reaction',
        'Recombinant Protein',
        'Restriction Enzyme',
        'Single-Cell Analysis',
        'Stable cell line',
        'T cells',
        'eDNA'
      ])
    ]
  },
  {
    id: 92,
    label: 'Logistics',
    children: [
      ...new Set([
        '3Gtms, Inc.',
        'ABF Freight System',
        'AGR Dynamics',
        'AIT Worldwide',
        'ALG Worldwide Logistics',
        'ATCO Ltd.',
        'AZAP',
        'AfterShip',
        'AirGateway GmbH',
        'Airways Freight',
        'Allen Lund Company',
        'Alpega Group',
        'Alpega TMS',
        'Amazon Logistics',
        'Ampol Limited (ALD.AX)',
        'Apex Logistics International Inc.',
        'Aramex Group',
        'Aravo Solutions, Inc.',
        'ArcBest',
        'Arrive Logistics',
        'Ascent Global Logistics',
        'Aspen Technology Software',
        'Australia Post',
        'Austrian Post (Post AG)',
        'Autodesk Construction Solutions (ACS)',
        'Automotive Resources International (ARI)',
        'Azuga',
        'BNSF Logistics',
        'Bamboo Rose',
        'Belgian Post Group',
        'BluJay',
        'Blue Dart Express Limited',
        'BlueGrace',
        'Bluejay',
        'Bringg',
        'Buildertrend',
        'C.H. Robinson',
        'CEVA Logistics',
        'CMD Group',
        'CMiC',
        'Canada Post Corporation',
        'Cardinal Logistics',
        'Chronopost',
        'CoConstruct',
        'Colibri',
        'Complemar',
        'Con-Way Freight',
        'ConstructConnect',
        'Convoy',
        'Coyote Logistics',
        'Creative Logistics Solutions (CLS)',
        'DB Schenker',
        'DCL Logistics',
        'DGX-Dependable Global Express, Inc.',
        'DHL Express',
        'DHX–Dependable Hawaiian Express, Inc.',
        'DP World',
        'DSV',
        'Dachser',
        'Day & Ross Inc.',
        'Demand Solutions',
        'Descartes Transportation Management',
        'Diesel Direct',
        'Doddle',
        'Drivewyze',
        'EA Logistics',
        'EasyPost',
        'EazyStock',
        'Echo Global Logistics',
        'Ecom Express',
        'Element Fleet Management',
        'Elemica, Inc.',
        'England Logistics',
        'Enterprise Truck Rental',
        'Esker',
        'Estes Express Lines',
        'Estes Forwarding Worldwide',
        'Eurosender',
        'Expeditors (Expeditors International of Washington)',
        'Express Mail Service (EMS)',
        'FedEx International Economy',
        'FedEx International Priority',
        'FedEx SenseAware',
        'Fedex (FDX)',
        'Fleet Complete',
        'Fleetio',
        'Flexe, Inc.',
        'Flexport',
        'Flowspace, Inc.',
        'FourKites',
        'Freight Center Inc.',
        'Freightos',
        'Freightquote',
        'Fulfillment Strategies International (FSI)',
        'FuturMaster',
        'GEODIS',
        'GPS Insight',
        'General Logistics Systems B.V. (GLS)',
        'Geopost',
        'GlobalTranz Enterprises LLC',
        'Graybar',
        'Green Mountain Technology',
        'HAVI',
        'HCSS',
        'HD Supply (HDS)',
        'Hapag-Lloyd AG (HPGLY)',
        'Happy Returns',
        'Hellmann Worldwide Logistics SE & Co. KG',
        'Holland Regional',
        'Hot Shot Services',
        'Hub Group',
        'ICAT Logistics',
        'ID Logistics',
        'IDrive Logistics',
        'InPost S.A.',
        'Incora',
        'Instafuel LLC',
        'Interline Brands',
        'International Chamber of Shipping (ICS)',
        'Intertanko',
        'J.B. Hunt (JBHT)',
        'JAS Worldwide, Inc.',
        'JDA Software',
        'Keelvar',
        'KeepTruckin',
        'Kenco',
        'Kerry Logistics Network Limited (0636.HK)',
        'Knight-Swift Transportation Holdings Inc.',
        'Kuebix',
        'Kuehne+Nagel Group',
        'La Poste',
        'Legacy Supply Chain',
        'LifeSaver',
        'Logility',
        'Logistics Networks',
        'Lytx',
        'Maersk',
        'MasonHub, Inc.',
        'Masternaut Limited',
        'Matson Logistics',
        'Menlo Logistics',
        'Merchants Fleet',
        'MercuryGate',
        'Microsoft Dynamics ERP',
        'Mondial Relay',
        'NETSTOCK',
        'NFI',
        'Narvar',
        'Navitrans',
        'NexTraq LLC',
        'ODW Logistics, Inc.',
        'OEConnection LLC',
        'OHL (Ozburn-Hessey Logistics)',
        'Odyssey Logistics and Technology',
        'Old Dominion Freight Line (ODFL)',
        'Omni Logistics',
        'Omnitracs',
        'On Center Software',
        'Oracle Aconex',
        'Orbcomm',
        'Panalpina',
        'Parcel Connect',
        'Parcelforce Worldwide',
        'Pedigree Technologies',
        'Pegasus Logistics Group',
        'Penske Logistics',
        'Penske Truck Leasing',
        'Pilot Freight Services',
        'Pos Malaysia Berhad',
        'PostNL (PNL)',
        'PostNord AB',
        'Poste Italiane',
        'Posten Norge AS',
        'PrePass',
        'ProTrans',
        'Project44',
        'Proship Inc.',
        'R+L Carriers',
        'RELEX Solutions',
        'Radiant Global Logistics',
        'Ramco Systems',
        'Red Stag Fulfillment, LLC',
        'Redteam',
        'Redwood Logistics',
        'Return Rabbit',
        'Route4Me',
        'Routific',
        'Royal Mail Group Limited',
        'Ryder',
        'SF Express Co., Ltd.',
        'SSI Schaefer',
        'Saddle Creek Logistics Services',
        'SafeRide',
        'Saia, Inc. (SAIA)',
        'SambaSafety',
        'Saturn Freight Systems',
        'Savage Services',
        'Scan Global Logistics',
        'Schneider',
        'Scout RFP',
        'Seko Logistics',
        'Seller Fulfilled Prime',
        'Shell TapUp',
        'ShipBob, Inc.',
        'ShipHero LLC',
        'ShipMonk',
        'Shipfusion Inc.',
        'Shippeo',
        'Shippo',
        'Shipwire',
        'SkuVault Core',
        'SpeedCommerce',
        'Spireon',
        'Stamps.com',
        'Steam Logistics',
        'Stord, Inc.',
        'Syft',
        'TNT Express',
        'Team Worldwide',
        'Technology Group International (TGI)',
        'Tecsys',
        'TenderEasy',
        'TenderEasy AB',
        'Toll Group',
        'TomTom Telematics',
        'ToolsGroup',
        'Total Quality Logistics (TQL)',
        'Track-POD',
        'Transdev',
        'Transplace',
        'Transporeon',
        'Transportation Impact',
        'U-Haul (UHAL)',
        'U.S. Xpress, Inc (USX)',
        'UK Mail',
        'Uber for Business',
        'Uline',
        'United Parcel Service (UPS)',
        'Varsity Logistics',
        'Vekia',
        'Verizon Connect',
        'Viewpoint',
        'Ware2Go Inc.',
        'WeDriveU, Inc.',
        'Werner Final Mile',
        'WestRock',
        'Woodland Group',
        'World Courier',
        'Worldwide Express',
        'XPO Logistics (XPO)',
        'Yamato Holdings Co., Ltd.',
        'Yodel Delivery Network Limited',
        'ZTO Express (Cayman) Inc. (ZTO)',
        'eDriving',
        'eFulfillment Service',
        'eSUB',
        'uShip'
      ])
    ]
  },
  {
    id: 93,
    label: 'Machine Learning & Artificial Intelligence',
    children: [
      ...new Set([
        'AI Automation',
        'AI Bias',
        'AI Content Generation',
        'AI Governance',
        'AI Model Catalog',
        'AI Model Error Analysis',
        'AI Monitoring',
        'AI Notetaker',
        'AI Pricing Optimization',
        'AI for Customer Service',
        'AI for Recruiting',
        'AI/ML Operationalization',
        'AI21 Labs',
        'AR VR Development Services',
        'AWS DeepLens',
        'AWS DeepRacer',
        'AWS Glue',
        'AWS Inferentia',
        'AWS Panorama',
        'Accura Scan',
        'Aceinna',
        'Acuant',
        'Ada',
        'Adaptive E-Textbooks',
        'Adgorithms',
        'Adobe Illustrator',
        'Adobe Lightroom',
        'Adobe Podcast',
        'Adobe Sensei',
        'Adobe XD',
        'Aible',
        'Aira Tech',
        'Alation',
        'Aleph Alpha',
        'Algolia Recommend',
        'Algorithmia',
        'AlphaSense',
        'Amazon Bedrock',
        'Amazon Elastic Inference',
        'Amazon Elastic Kubernetes Service (EKS)',
        'Amazon Forecast',
        'Amazon Kendra',
        'Amazon Machine Learning',
        'Amazon Macie',
        'Amazon SageMaker',
        'Amazon Textract',
        'Amplified Intelligence',
        'Amplifire',
        'Anaconda',
        'Anomaly Detection',
        'AntWorks',
        'Anthropic',
        'Anthropic Claude',
        'Appen',
        'Applica',
        'Aquant',
        'Argo AI',
        'Arize',
        'Artificial Hallucination',
        'Artificial Intelligence',
        'Artificial Intelligence Supply Chain',
        'Artificial Intelligence for IT Operations (AIOps)',
        'Artificial Intelligence in Banking',
        'Artificial Intelligence in Sales',
        'Artificial Solutions (ASAI)',
        'Astute Solutions',
        'Ataccama',
        'Attivio',
        'Augmented Analytics',
        'AuthenticID',
        'Autodesk Build',
        'Automation Anywhere - IQ Bot',
        'Aviso',
        'Avoma',
        'Awake Security',
        'Azure Kubernetes Service (AKS)',
        'Azure Machine Learning',
        'Azure OpenAI Service',
        'Azure for ML',
        'BERT',
        'BMC TrueSight',
        'Backpropagation',
        'Baidu',
        'Banking Analytics',
        'Base Operations',
        'Bill C-27 (Digital Charter Implementation Act)',
        'Blockchain Wallets',
        'Bolster.ai',
        'Boost.ai',
        'Botify',
        'CAI Software',
        'Captiv8',
        'Centers for Data Science and Artificial Intelligence (CDSAi)',
        'Character.AI',
        'Charli AI',
        'ChatBots',
        'Chatsonic',
        'Chatter Research',
        'Chorus',
        'Clari',
        'ClosedLoop.ai, Inc.',
        'Cloudfactory Limited',
        'Cobiro',
        'Cogito',
        'Cognira, Inc.',
        'Cognitive Computing',
        'Cognizant Technology Solutions Corp (CTSH)',
        'Cohere Inc.',
        'Collective[i]',
        'Computer Vision',
        'Constructor.io',
        'Contextual Intelligence',
        'Conversation Intelligence',
        'Conversational AI',
        'Conversational Marketing',
        'CopyAI',
        'Corporate Metaverse',
        'Craiyon',
        'Creative Virtual',
        'DICEUS',
        'Darktrace',
        'DataFox',
        'Dataiku',
        'Datamatics',
        'Dataminr',
        'Decibel Insight',
        'Decision Intelligence',
        'Deep Instinct',
        'Deep Learning',
        'DeepHow',
        'DeepMind',
        'DigitalGenius',
        'Dynamic Yield',
        'Eigen',
        'Encoders',
        'Enghouse Systems',
        'Enlyft',
        'Etiya',
        'Everstream Analytics',
        'Evisort Inc.',
        'Evolution AI',
        'Expert Systems',
        'Explainability',
        'Ezoic',
        'FRONTEO',
        'Face Detection',
        'Facial Liveness Detection',
        'Faire',
        'Farmers Business Network',
        'Fayrix',
        'Feature Store',
        'Federated Learning (FL)',
        'Finn AI',
        'Focal Systems',
        'FogHorn Systems',
        'Foundation Models',
        'Fractal Analytics',
        'Frame AI',
        'Freight Network',
        'GFT Group (GFT)',
        'Generative AI for Developers',
        'Generative AI in Sales',
        'Generative Artificial Intelligence (Generative AI)',
        'Generative Pre-Trained Transformer (GPT)',
        'Github Copilot',
        'Gloat',
        'Gong',
        'Google Bard',
        'Google Cloud AI Platform',
        'Google Cloud AutoML',
        'Google Contact Center AI',
        'Google Kubernetes Engine (GKE)',
        'Google PaLM',
        'Google Tensorflow',
        'Grooper',
        'H2O.ai',
        'HackerRank',
        'Hakkoda',
        'Harness',
        'Healthcare AI',
        'High Performance Computing',
        'HighRadius',
        'Hippo Holdings Inc. (HIPO)',
        'HireVue',
        'HiveQL',
        'Hugging Face',
        'Humanz',
        'Hybrid Blockchain',
        'Hybrid Intelligence',
        'Hyperscience Platform',
        'IBM Security MaaS360',
        'IBM Tririga',
        'IBM Watson Assistant',
        'IBM Watson Health',
        'IPRO',
        'IPSoft',
        'Image Recognition',
        'Imense',
        'InData Labs',
        'Indico',
        'Inference Corporation',
        'Infrrd Inc.',
        'Inky Technology',
        'Insider',
        'Intelligent Document Processing',
        'Intelligent Virtual Assistants (IVA)',
        'Interos',
        'Irreverent Labs',
        'Iterative.ai',
        'Jade Global',
        'Jvion, Inc.',
        'Kili Technology',
        'Klevu',
        'Konstanz Information Miner (KNIME)',
        'Kore.ai',
        'Kubeflow',
        'Kubernetes',
        'Kubernetes Security Posture Management (KSPM)',
        'L&T Technology Services Limited (LTTS)',
        'LLaMA (Large Language Model Meta AI)',
        'LLamasoft',
        'Labelbox',
        'Large Language Model (LLM)',
        'Lark Health',
        'Lastline',
        'Lead Matching',
        'Lemonade',
        'Limited Memory AI',
        'Logz.io',
        'MLflow',
        'Machine Learning',
        'Machine Learning Ops (MLOps)',
        'MapReduce',
        'Marchex',
        'Marine Artificial Intelligence',
        'Marlabs',
        'Med-PaLM',
        'Microblink',
        'Microsoft Copilot',
        'Milvus',
        'Mindverse',
        'Mixed Reality',
        'Model Drift',
        'Model Governance',
        'Model Inventory',
        'Model Lineage',
        'Model Management',
        'Model Marketplace',
        'Model Monitoring',
        'Model Performance',
        'Model Portfolio',
        'Model Registry',
        'Model Validation',
        'Model Version Control',
        'ModelOp',
        'ModelOps',
        'MosaicML',
        'NVIDIA NeMo',
        'Natural Language Generation (NLG)',
        'Natural Language Understanding (NLU)',
        'Nauto',
        'Neo4j',
        'Neural Networks',
        'Neural Processing Unit (NPU)',
        'Novetta Solutions',
        'Novo Labs',
        'Nvidia (NVDA)',
        'Olive AI, Inc.',
        'Omilia',
        'Onclusive',
        'One Network',
        'Onfido',
        'OpenAI',
        'OpenAI Codex',
        'Oracle Autonomous Database',
        'Orbita',
        'Overfitting',
        'PSSC Labs',
        'Pachama',
        'Pai',
        'PandoLogic',
        'Paperspace',
        'Paradox',
        'ParlAI',
        'Partnerize',
        'Pattern Recognition',
        'Pega GenAI',
        'Perplexity AI',
        'Pinecone Systems, Inc.',
        'Predictive Medicine',
        'Predictive Modeling',
        'Prescriptive Forecasting',
        'Prompt Engineering',
        'Pros Holdings, Inc (PRO)',
        'PublicRelay',
        'Punchh',
        'PySpark',
        'Pype',
        'Python',
        'Qentelli',
        'Quantcast',
        'Quantiphi',
        'QuantumBlack',
        'R',
        'RESTful API Modeling Language (RAML)',
        'RPA Tech',
        'Radley Corporation',
        'RapidMiner',
        'Red Oak Strategic',
        'Reinforcement Learning',
        'Renesas',
        'Resolve Systems',
        'Retrieval-Augmented Generation (RAG)',
        'Riskified',
        'Rossum AI',
        'Rytr',
        'SAI Global',
        'SAP Intelligent Technologies',
        'Salesforce AI Cloud',
        'Salesforce Einstein',
        'Samsara',
        'Scale AI',
        'Scanbot SDK',
        'Scheduling Automation',
        'Seamless.AI',
        'Seenapse',
        'Seldon',
        'SetSail',
        'Shadow Mode',
        'Signal AI',
        'Signals Analytics',
        'Sinequa.com',
        'SmartAction',
        'Social Native',
        'SoftBank Robotics Group Corp. (SBRG)',
        'Stability AI',
        'Stable Diffusion',
        'Stem, Inc.',
        'Supervised Learning',
        'SupportLogic',
        'Symphony Industrial AI',
        'Synthesis AI',
        'Synthetic Data',
        'TabNine Inc.',
        'Tekion',
        'Teladoc',
        'Teletrac Navman',
        'Tempus Labs, Inc',
        'Tencent',
        'TextCortex',
        'ThisWay Global',
        'Tiger Analytics',
        'Transfer Learning',
        'Translate.com',
        'TruEra',
        'TrueAccord',
        'UST',
        'UiPath Clipboard AI',
        'Unbabel Inc.',
        'Unicepta',
        'Unsupervised Learning',
        'Uplift Modeling',
        'Uptake',
        'Vector Database',
        'Vectra',
        'Verbit',
        'Verta',
        'Vertex AI',
        'Vidado',
        'Virtual Reality (VR)',
        'Viz.ai, Inc.',
        'Vue.ai',
        'WhyLabs',
        'Word Embeddings',
        'WorkFusion',
        'Writesonic',
        'Xometry',
        'You.com',
        'landing.ai',
        'rct AI',
        'riskmethods',
        'upGrad'
      ])
    ]
  },
  {
    id: 94,
    label: 'Manufacturing',
    children: [
      ...new Set([
        '3D Printer Fires',
        '3M (MMM)',
        '3M Fall Protection',
        '4D Printing',
        '5-axis Machining',
        'A-SAFE USA',
        'AB Graphic International',
        'AB Volvo',
        'ABB',
        'ABC Technologies B.V.',
        'ACS880',
        'AGCO',
        'AHF Products',
        'ANDRITZ AG (ANDR.VI)',
        'ARMS Reliability',
        'AVT Reliability',
        'Accumold',
        'Additive Manufacturing',
        'Advanced Ceramics',
        'Advanced Driver-Assistance Systems (ADAS)',
        'Advanced Instruments',
        'Advanced Manufacturing',
        'Advanced Micro Devices (AMD)',
        'Aegis Software',
        'Aerospace',
        'Aerospace Machining',
        'Aerospace Manufacturing',
        'Aftermarket',
        'Agile Manufacturing',
        'Agilent (A)',
        'Air Compressor',
        'Aircraft Manufacturing',
        'Algeco',
        'Alice & Bob',
        'Align Technology, Inc.',
        'Allied Electronics & Automation',
        'Allied Reliability',
        'Allocation',
        'Alltemated Inc.',
        'Alpha Wire',
        'Altech Corp',
        'Aluminum Melting Furnace',
        'Aluminum Porosity',
        'Amana',
        'Amazon Freight',
        'Amazon Packaging',
        'Amcor',
        'Analog Devices',
        'Anglo American plc',
        'Anodizing',
        'Ansell',
        'Apex Plastics',
        'Apex Tool Group',
        'Applied Materials, Inc.',
        'AptarGroup, Inc.',
        'Arena Solutions',
        'Armstrong Flooring Inc (AFI)',
        'Articulated Forklift',
        'AsusTek Computer (ASUS)',
        'Atkore Inc',
        'AutoQuotes (AQ)',
        'AutoStore',
        'Automated Warehouse',
        'Automation Modularization',
        'Automation Standardization',
        'Automotive Plastic Parts',
        'Autonomous Maintenance',
        'Auxiliary Power Unit (APU)',
        'Avery Dennison',
        'Azbil Corporation',
        'B&K Precision',
        'BCA Technologies',
        'BITO',
        'BMC Switzerland',
        'BP (BP)',
        'Badger Meter',
        'Baker Hughes',
        'Barry-Wehmiller Group, Inc.',
        'Batch Reactor',
        'Battery Manufacturing',
        'Beckman Coulter',
        'Bel Fuse (BELFA)',
        'Belden',
        'Belimo',
        'Benjamin Moore & Co.',
        'Beumer Group',
        'Biamp',
        'Bill of Materials (BOM)',
        'Bio-Rad Laboratories',
        'Bio-Techne',
        'Blue Ridge Global',
        'Bobst',
        'Boeing 737',
        'Boeing 777',
        'Boeing 787',
        'Boiler',
        'Bosch',
        'Bose Corporation',
        'Boyd Corporation',
        'Broadcom Limited',
        'Bruker Corporation',
        'Building Column Protection',
        'Burner Management System',
        'Burns & McDonnell, Inc',
        'CIS Configurator',
        'CNC Turning',
        'CNH Industrial N.V',
        'CPower',
        'CRFS',
        'Calix Ceramic Solutions',
        'Calvary Robotics',
        'Canon',
        'Carbon Fiber',
        'Carrier Global Corporation',
        'Carter Machinery',
        'Cascades Inc',
        'Castrol',
        'Caterpillar Inc.',
        'Central Vacuum Systems',
        'CeramTec',
        'CertaPro Painters, Ltd.',
        'Chemical Plant Automation',
        'Chemical Storage Tanks',
        'Cintas Corporation (CTAS)',
        'Cirrus Logic (CRUS)',
        'Climeworks AG',
        'Cognex',
        'Cold-formed steel (CFS)',
        'Commercial Door Repair',
        'Commercial Power Washing Services',
        'Commercial Roof Washing Services',
        'Commercial Wood Rot Repair Services',
        'Concept2, Inc.',
        'Condition Based Maintenance',
        'Condition Monitoring',
        'Configit',
        'Configure One',
        'Connected Enterprise',
        'Connected Factory',
        'Connexion',
        'Consumer Packaged Goods (CPG) Software',
        'Continental Tire the Americas , LLC',
        'Continuous Manufacturing (CM)',
        'Contract Manufacturer (CM)',
        'Contract Manufacturing Software',
        'Contract Packaging',
        'Control Panel Builder',
        'Cooper Lighting, LLC',
        'Corrective Repair Training',
        'Corrosion Resistant Coatings',
        'Creation Technologies',
        'Crown',
        'Custom Manufacturing Solutions',
        'Custom Plastic Extrusion',
        'Custom Plastic Fabrication',
        'Custom Sign',
        'DCS Migration',
        'DERMALOG Identification Systems GmbH',
        'DS Smith',
        'Daifuku',
        'Daikin Industries, Ltd.',
        'Daimler Truck AG',
        'Danfoss',
        'Data Collection System (DCS)',
        'Daubert Cromwell LLC',
        'Dealer Tire, LLC',
        'Deck & Hydraulics',
        'Dedicated Computing',
        'Deere & Company',
        'Delogue',
        'Dematic',
        'Design for Manufacturability',
        'Design for Manufacturability (DFM)',
        'Desoutter Industrial Tools',
        'DeviceNet',
        'Diamond Green Diesel LLC (DGD)',
        'Diamond Offshore',
        'Diaphragm Seal System',
        'Die Casting',
        'Digi-Key',
        'Digital Fabrication',
        'Digital Lumens, Inc. (DL)',
        'Digital Manufacturing',
        'Digital Thread',
        'Discrete Automation',
        'Discrete Manufacturing Industry',
        'Distech Controls Inc.',
        'Domino',
        'Dover Corporation',
        'DuPont',
        'Duro Labs, Inc.',
        'Durst',
        'Dynamic Systems Inc. (DSI)',
        'Dyson',
        'E Tech Group',
        'E-commerce Packaging',
        'ENGIE',
        'EPOS',
        'EZ Factory',
        'Eco Packaging',
        'Electrolytic Plating',
        'Electronic Batch Recording',
        'Electronics Manufacturing Services',
        'Elm Electrical, Inc.',
        'Embedded Intelligence',
        'Emerson Electric (EMR)',
        'Enel',
        'Enel X',
        'Engineered Corrosion Solutions LLC',
        'Engineered Fall Protection',
        'Eni S.p.A.',
        'Enovia',
        'Epic Coater',
        'Epson',
        'Equator',
        'Etch Resist',
        'Everidge, Inc.',
        'Evonik Industries',
        'Evoqua Water Technologies LLC',
        'Exelon Corporation',
        'FANUC America',
        'FAULHABER',
        'FMC Technologies Inc',
        'Fabrication Solutions',
        'Fall Protection Systems',
        'Fast Radius',
        'Fastenal (FAST)',
        'Fathom Digital Manufacturing Corporation',
        'Federal Equipment Company',
        'Fendt',
        'Ferguson Enterprises',
        'Fisher Construction Group',
        'Fleet Management',
        'Flexible Lifeline Systems',
        'Flexible Manufacturing',
        'Fluke Corporation',
        'Fluor',
        'Foam Packaging Solutions',
        'Food Manufacturing',
        'Food Processing',
        'Ford Motor Company',
        'Ford Pro',
        'ForeFront Power',
        'Forklift Rental',
        'Freeport McMoRan',
        'Freight Management',
        'Frigidaire',
        'Fuchs',
        'Fulcrum Bioenergy, Inc.',
        'GE Appliances',
        'GE Vernova Inc.',
        'GEA Heating & Refrigeration Technologies',
        'GEP',
        'GOJO Industries',
        'GarrettCom',
        'General Mills (GIS)',
        'General Motors (GM)',
        'Georgia-Pacific',
        'Gerflor Group',
        'Giga Press',
        'Gilbane Building Company',
        'Gorman-Rupp',
        'Gray Construction',
        'Gregory Poole Lift Systems',
        'Griffco Design/Build, Inc.',
        'Grinding Services',
        'Grundfos',
        'Guardian Fall Protection',
        'Gymshark',
        'HAAS CNC',
        'HTI Plastics',
        'HZO',
        'Hach Company',
        'Halliburton',
        'Hamamatsu Photonics',
        'Harman International Industries',
        'Harris and Bruno',
        'Heavy Fuel Oil (HFO)',
        'High Pressure Die Casting (HPDC)',
        'Hikvision',
        'Hilti',
        'Hokuyo',
        'Honda Motor Co., Ltd.',
        'Honeywell / Miller Fall Protection',
        'Hopper Vehicle',
        'Horizontal Boring Services',
        'Hot Rolling',
        'Hotpoint',
        'Hubbell Incorporated (HUBB)',
        'Hydraulic Filtration Solutions',
        'Hyosung',
        'Hyster-Yale Group',
        'I-Care',
        'I/O Module',
        'ITT Goulds Pumps',
        'Iberdrola, S.A.',
        'Igloo Products Corp.',
        'Immersion Plating',
        'Imperial Oil',
        'Impossible Foods',
        'Index of Industrial Production (IIP)',
        'Industrial Analytics',
        'Industrial Chemical Cleaning',
        'Industrial Communication',
        'Industrial Control Systems (ICS)',
        'Industrial Edge Management',
        'Industrial Electronic Supply',
        'Industrial Internet of Things (IIoT)',
        'Industrial IoT Platform',
        'Industry 4.0',
        'Infineon',
        'Inovance Technology Europe GmbH',
        'Institute of Electrical and Electronics Engineers (IEEE)',
        'Intelligrated',
        'Interface',
        'International Paper',
        'International Thermal Systems (ITS)',
        'Interstates',
        'Inventory Reduction',
        'Inventory Replenishment',
        'JBL',
        'JJISCO, Inc.',
        'JKR',
        'JN White',
        'Jabil Inc.',
        'Jabra',
        'Janoschka',
        'Johnson Controls',
        'Johnson Matthey Plc',
        'Joining Technology',
        'Jungheinrich',
        'Just-In-Time Manufacturing',
        'KNAPP',
        'KNF Group',
        'KUKA AG',
        'Kee Safety',
        'Keithley Instruments',
        'Keyence',
        'Keysight Technologies (KEYS)',
        'Kia Corporation',
        'Kiewit',
        'Kinematics',
        'Koenig & Bauer AG',
        'Kohler Co.',
        'Konica Minolta Business Solutions',
        'Krohne Group',
        'Kyocera Corporation',
        'Körber',
        'L.S. Starrett',
        'LG Chem',
        'LG Electronics (LGLD)',
        'LNG Retrofit',
        'Label Gator',
        'Labor Management',
        'Landing Gear Systems',
        'Landis+Gyr',
        'Lauterbach GmbH',
        'Lean / Six-Sigma',
        'Lean Manufacturing',
        'Lectra',
        'LeddarTech',
        'Leica Microsystems',
        'Lennox International Inc.',
        'Lenze',
        'LevelTen Energy',
        'Lexmark',
        'Lightweighting',
        'Linear Motor',
        'LiquidSpring, LLC',
        'Lithium-Ion Battery Cell Production',
        'Littelfuse (LFUS)',
        'Logitech',
        'Low Sulfur Fuel Oil (LSFO)',
        'Low Voltage Motor',
        'Lubricant Manufacturing',
        'Lubricants',
        'Lumenis',
        'MBO Postpress Solutions',
        'MIE Trak Pro',
        'MSA Safety',
        'Machine Monitoring',
        'Machine Tool Automation',
        'Machine Tool Digitalization',
        'Machining Service',
        'Magid Glove',
        'MakerBot',
        'Makino',
        'Makita',
        'Mannington Commercial',
        'Manufacturing Analytics',
        'Manufacturing Cost Reduction',
        'Manufacturing Efficiency',
        'Manufacturing Execution System',
        'Manufacturing Operations',
        'Manufacturing Software',
        'Manufacturing Training',
        'Manufacturing operations management (MOM)',
        'Markforged',
        'Mars, Incorporated',
        'Mass Flow Meters',
        'Material Handling',
        'Material Planning',
        'Material Requirements Planning (MRP)',
        'Materials Handling Equipment',
        'Materials Management',
        'Materials Manufacturing',
        'Materials Technology',
        'Matrix Service Company (MSC) (MTRX.OQ)',
        'Mazak',
        'McCormick & Company',
        'McMaster-Carr',
        'Mean Time To Repair (MTTR)',
        'Measurement Specialties Inc.',
        'Meat Processing',
        'Mecalux',
        'Medical Device Manufacturing',
        'Medical Molding',
        'Medical Packaging',
        'Metal Fabrication',
        'Metal Forming',
        'Metallix Refining Inc.',
        'Mezzanines',
        'Michelin',
        'MicroD',
        'Micromolding',
        'Milliken & Company',
        'Milwaukee Tool',
        'Miniaturization',
        'Mitsubishi Electric',
        'Mitutoyo',
        'Modular Process Skid System',
        'Molex',
        'Mondelez International',
        'Mondi plc',
        'Motion Amplification',
        'Motor Oil Viscosity Grades',
        'Mouser Electronics',
        'Moving Walkway',
        'Moxa Inc.',
        'Murata Manufacturing',
        'N.F. Smith & Associates',
        'NanaWall Systems, Inc.',
        'Nanoparticles',
        'Napco Security Technologies, Inc.',
        'National Electrical Manufacturers Association',
        'National Grid plc',
        'Neste Oyj',
        'New Hope Energy',
        'Newark Electronics',
        'Newbuilding',
        'NextEra Energy, Inc.',
        'Nikola Corporation',
        'Nikon',
        'Nilfisk Inc.',
        'Nippon Bearing Co., Ltd.',
        'Nitto, Inc.',
        'Nord Drivesystems',
        'Nucor',
        'ON Semiconductor',
        'Ohio Power Tool',
        'Oil Filtration Systems',
        'Oil Mist Lubrication',
        'Oil Spill Response Vessel',
        'Oki Electric Industry Co., Ltd.',
        'Okuma',
        'Olympus Corporation',
        'Omron',
        'On-time Delivery',
        'Onsite Fleet Fueling',
        'Opcenter Execution Pharma',
        'Optical Gaging Products (OGP)',
        'Orbel Corporation',
        'Oriental Motor',
        'Original Equipment Manufacturer (OEM)',
        'Orora',
        'Overall Equipment Effectiveness (OEE)',
        'Pacific States Petroleum',
        'Package Specifications',
        'Package Testing',
        'Packaging Automation',
        'Packaging Corporation of America',
        'Packaging Design',
        'Packaging Services',
        'Packaging Strategy',
        'Packaging System',
        'Packsize',
        'Pallets',
        'Paper Converting Machine Company (PCMC)',
        'Paperless Manufacturing',
        'Parsable',
        'Parts Cleaning',
        'Pelco, Inc.',
        'Pentair Plc',
        'Pepperl & Fuchs',
        'PepsiCo',
        'Petro-Canada',
        'Pharmaceutical Manufacturing',
        'Phononic',
        'Pilot Plant Automation',
        'Plant Maintenance',
        'Plant Management',
        'Plant Operations',
        'Plastic Packaging',
        'Poka',
        'Polaris Inc.',
        'Poppin',
        'Porex Corporation',
        'Ports & Terminals',
        'PowerFlex',
        'PowerFlex 4',
        'PowerFlex 40',
        'PowerFlex 525',
        'PowerFlex 70',
        'PowerFlex 700',
        'PowerFlex 755',
        'PowerFlex 755T',
        'Powertrain Technology',
        'Pratt Industries',
        'Precision Injection Molding',
        'Precision Micro',
        'Preem AB',
        'Prefabrication',
        'Premier Modular',
        'Prescriptive Maintenance',
        'Preventative Maintentance Training',
        'Preventive Maintenance Services',
        'Pricefx',
        'Process Instrumentation',
        'Process Manufacturing',
        'Process Optimization',
        'Process Piping',
        'Product Assembly',
        'Product Damage',
        'Product Failures',
        'Product Formulation',
        'Product Recalls',
        'Production Execution',
        'Production Management',
        'Production Monitoring',
        'Production Planning',
        'Programmable Logic Controller (PLC)',
        'Promach',
        'Propane',
        'Propane Tank',
        'Protolabs, Inc.',
        'QatarEnergy',
        'Quadion LLC',
        'Quality Vision International (QVI)',
        'QuietKat',
        'REXEL',
        'RIEGL Laser Measurement Systems',
        'RIGOL Technologies',
        'ROHM Co., Ltd (ROHCY)',
        'RS Components',
        'RS Group plc',
        'Racking Protection',
        'Raymond',
        'Recycling Software',
        'Redzone Production Systems',
        'Refrigerated Warehouse',
        'Relex',
        'Remote Diagnostics',
        'Renewable Energy Group, Inc.',
        'Retread',
        'Return Packaging',
        'Reusable Packaging',
        'Right to Know / Hazard Communication',
        'Rigid Lifelines',
        'Rite-Hite',
        'Rittal',
        'Robert Bosch',
        'Robotic palletizing',
        'Rockwell Automation',
        'Rotary indexers',
        'Rystad Energy',
        'S.C. Johnson & Son, Inc.',
        'SEW Eurodrive',
        'SEW-Eurodrive, Inc.',
        'SIMOTICS Motors',
        'SINAMICS',
        'SINAMICS G115D',
        'SK Hynix',
        'SLC 500',
        'SMC Corporation of America',
        'SSE plc',
        'STIHL',
        'STMicroelectronics',
        'SUPCON Group Co., Ltd.',
        'Saint-Gobain Medical',
        'Salesforce Manufacturing Cloud',
        'Samsung',
        'Saudi Basic Industries Corporation',
        'Schaeffler AG',
        'Scheduling',
        'Schneider Altivar',
        'Schweitzer Engineering Laboratories',
        'Scrap Melting',
        'Seadrill',
        'Semiconductor Manufacturing',
        'Sensus',
        'Service Yard and Dock Protection',
        'Servo Motors',
        'Sharp USA',
        'Shell',
        'Shell Recharge',
        'Sherwin-Williams',
        'Shimadzu Corporation',
        'Ship Grounding',
        'Ship Refit',
        'Shop Floor Control',
        'Short Lifecycle Inventory',
        'Shrinkflation',
        'Shurtape Technologies, LLC',
        'Sick AG',
        'Siemens Digital Industries Software',
        'Siemens NX',
        'Siemens Process Instrumentation',
        'Siemens Simcenter',
        'Silicon Laboratories, Inc. (SLAB)',
        'Silvaco Group, Inc.',
        'Single-Use Plastics',
        'Sinumerik',
        'Skyworks Solutions, Inc.',
        'Slack Fill',
        'Smart Factory',
        'Smart Manufacturing',
        'Smart Textiles',
        'Smurfit Kappa Group',
        'SolidWorks Plastics',
        'Solidigm',
        'Solumina',
        'Sonoco Products Company (SON)',
        'Sortimo',
        'Spare Parts Logistics',
        'Specialized Packaging',
        'Spray Equipment & Service Center',
        'Stanford Research Systems',
        'Stanley Black & Decker',
        'Star Refrigeration',
        'Statistical Process Control',
        'Steel Dynamics',
        'Steel Pickling',
        'Steel Technologies',
        'Stellantis',
        'Stora Enso',
        'Store Supply Warehouse',
        'Stovetop Firestop',
        'Stratasys Ltd',
        'Subsea Communications (SubCom)',
        'Surface-Mount Technology',
        'Suspension system',
        'Sustainable Labeling',
        'Sustainable Manufacturing',
        'Sustainable Packaging',
        'Swisslog',
        'TCI Products, Inc.',
        'TDK',
        'TE Connectivity',
        'TGW',
        'TSMC',
        'TTI Inc.',
        'Tape and Reel',
        'Tarkett',
        'Tec Lighting',
        'Tektronix (TEK)',
        'Teledyne LeCroy',
        'Teledyne Optech',
        'Teleflex',
        'Tennant Company',
        'Tetra Pak',
        'Textile Recycling',
        'The Kraft Heinz Company',
        'The Stellar Group, Inc.',
        'The Timken Company',
        'Thermoforming Services',
        'Ti Cold',
        'Tilsner Carton Company',
        'Tire',
        'Toolmaking',
        'Tormach',
        'Toshiba Corporation',
        'Total',
        'TotalEnergies SE',
        'Toyota Forklifts',
        'Traceability',
        'Trackmobile LLC',
        'Trane Inc.',
        'Transfer Multisort Elektronik (TME)',
        'Transocean',
        'Turbomachinery Control',
        'UL Solutions HOMER',
        'Underfill',
        'Unique Device Identifier (UDI)',
        'United Rentals',
        'Unity and Unreal Software and Training',
        'Universal Engraving Inc.',
        'Upcycling',
        'Urenco',
        'VACCO Industries Inc.',
        'Vacuum Evaporation Systems',
        'Vacuum Furnaces',
        'Vacuum Pump Maintenance Services',
        'Valaris',
        'Valero Energy Corporation',
        'Value Stream Management',
        'Valvoline Global',
        'Vanderlande',
        'Vapor Corrosion Inhibitor (VCI)',
        'Velodyne Lidar Inc',
        'Veralto Corporation',
        'Vexos',
        'Virtual Commissioning',
        'Virtual Manufacturing Training',
        'Vision Engineering',
        'Volkswagen AG',
        'Volvo Trucks',
        'W. L. Gore & Associates, Inc.',
        'WEG',
        'WFS Corp',
        'WFX PLM',
        'WIN Semiconductors Corp (3105.TWO)',
        'Warehouse Execution',
        'Warehouse Slotting',
        'Waste Oil',
        'Waste Reduction',
        'Weir Group',
        'Welding',
        'Well Intervention',
        'Western Digital (WDC)',
        'Weyerhaeuser',
        'Whirlpool Corporation',
        'Windemuller',
        'Wirtgen Group',
        'Work Uniform Suppliers',
        'World Fashion Exchange (WFX)',
        'World Kinect',
        'World of Asphalt',
        'Worthington Industries',
        'Xeikon B.V.',
        'Xpedition Enterprise',
        'YETI',
        'Yamaha',
        'Yaskawa',
        'Yazaki North America',
        'Yokogawa',
        'Yoshi',
        'ams AG',
        'eFUEL',
        'factory automation',
        'igus',
        'nVent Electric Plc',
        'onsemi (ON)'
      ])
    ]
  },
  {
    id: 95,
    label: 'Marketing',
    children: [
      ...new Set([
        'HCP (Healthcare Professionals) Engagement',
        'Healthcare Advertising',
        'Pharmaceutical Marketing'
      ])
    ]
  },
  {
    id: 96,
    label: 'Mass Spectrometry',
    children: [
      ...new Set([
        'Anion Analysis',
        'Gas Chromatography-Mass Spectrometry (GC-MS)',
        'Inductively Coupled Plasma Mass Spectrometry (ICP-MS)',
        'Liquid Chromatography-Mass Spectrometry (LC-MS)',
        'Mass Spectrometer',
        'Raman Spectroscopy',
        'Time-of-flight Mass Spectrometry'
      ])
    ]
  },
  {
    id: 97,
    label: 'Media & Advertising',
    children: [
      ...new Set([
        '1105 Media Inc',
        '24-7 Press Release',
        '3Q Digital',
        '6Sense',
        '72andSunny Partners LLC',
        'ACCESSWIRE',
        'ACTIVATE',
        'AKQA',
        'ALM Media',
        'Acquisio',
        'Ad Fontes Media, Inc.',
        'AdSense',
        'AdTheorent, Inc.',
        'AdWords',
        'AddThis',
        'Adestra',
        'Adlucent',
        'Admatic',
        'Admitad',
        'Adobe Acrobat',
        'Adobe Advertising Cloud',
        'Adobe Creative Cloud',
        'Adobe Creative Suite',
        'Adobe Experience Manager (AEM)',
        'Adobe Fireworks',
        'Adobe Flash',
        'Adobe Marketing Cloud (AMC)',
        'Adobe Reader',
        'Adobe Stock',
        'Adroll',
        'Advice Local',
        'Agility PR Solutions',
        'Air Transport World',
        'Alchemy One',
        'Alethea Group',
        'Amazon Cloudfront',
        'Amazon Marketing Services (AMS)',
        'American Litho, Inc.',
        'Amobee',
        'Amplience',
        'Antavo',
        'Anthem',
        'Apple Search Ads',
        'Ascent360',
        'Avenue C.',
        'Aviation Week Network',
        'Avid Technology Inc',
        'BBC',
        'BCM Group',
        'BEEF',
        'BWM',
        'Bailey Brand Consulting',
        'Barkley',
        'Bastion Collective',
        'Bear Meets Eagle on Fire',
        'Belkins Inc.',
        'Big Red Rooster',
        'Birdeye',
        'Bizible',
        'Blast Radius',
        'Blue Sky Media',
        'BlueShift Labs',
        'BombBomb',
        'Bounce Exchange',
        'Brand Consulting Group, Inc.',
        'BrandImage',
        'Brandbassador',
        'Brandfolder',
        'Brandmuscle',
        'BrightFunnel',
        'Browsi',
        'Bulletproof',
        'Business Wire',
        'Business.com',
        'Buyer Advertising',
        'Bynder',
        'CARMA',
        'CBS (CBS)',
        'CBX',
        'Cadastra',
        'Campanja',
        'Canva',
        'Carat',
        'Cardlytics',
        'Catalina Marketing Corporation',
        'Celtra',
        'Centro',
        'Chicken Soup for the Soul Entertainment, Inc. (CSSE)',
        'ChiefNation',
        'Ciceron',
        'Cincopa',
        'Cision',
        'City Stamp Works',
        'Claritas',
        'ClickBank',
        'Cloudimage',
        'Code and Theory',
        'Cohley',
        'Comcast (CMCSA)',
        'Commission Junction',
        'Compulse',
        'Contently',
        'Coremedia',
        'CoverageBook',
        'Crealytics',
        'Criteo',
        'Curiosity Advertising',
        'DC BLOX Inc.',
        'DMW Direct',
        'DataPath, Inc.',
        'Datorama',
        'Definition 6',
        'DeltaX',
        'Demandbase',
        'Dentsu',
        'Dentsu Creative',
        'Dentsu X',
        'Derse',
        'Design Bridge',
        'Digitas',
        'Doceree',
        'Dotdigital (DOTD)',
        'Dragon Rouge',
        'Droga5, LLC',
        'EIN Presswire',
        'Edelman',
        'Edge Creative',
        'Effectv',
        'Endeavor Business Media',
        'Enero Group',
        'Engine Group',
        'Epsilon',
        'FCB/SIX',
        'Facebook Advertising',
        'Facebook Audience Network',
        'Facebook Mobile Ads',
        'Facebook Pixel',
        'Figma, Inc.',
        'FreeWheel',
        'Frontier Australia',
        'Funnel',
        'GRIN',
        'Gainsight',
        'Gannett Co., Inc.',
        'GetResponse',
        'GetsmartContent',
        'Getty Images',
        'Gilson Graphics',
        'GlobeNewswire',
        'Goodway Group',
        'Google Ad Manager',
        'Google AdMob',
        'Google AdX',
        'Google Analytics',
        'Google Open Bidding (OB)',
        'Government Executive Media Group',
        'Grabyo',
        'GroupM',
        'Gumlet',
        'Hanlon Creative',
        'Havas',
        'Hawkeye',
        'Headley Media',
        'Hearts & Science LLC',
        'Heinz Marketing',
        'Hubspot (HUBS)',
        'Huge, LLC',
        'Hulu Advertising',
        'IAB (Interactive Advertising Bureau)',
        'IDEO',
        'INDG',
        'IZEA',
        'IgnitionOne',
        'ImageKit',
        'InMobi',
        'Indago Digital',
        'Indicia Worldwide',
        'Industry Week',
        'Influitive',
        'Infopro Digital',
        'Inmar Intelligence',
        'InnerWorkings (INWK)',
        'IntelliAd',
        'Interbrand',
        'Interpublic Group (IPG)',
        'Involved Media',
        'Isentia Group Pty Limited',
        'Iterable',
        'Jagran Prakashan Ltd',
        'Japs-Olson Company',
        'Jasper AI, Inc.',
        'Job Portraits',
        'John Wiley & Sons Inc',
        'Just Global, Inc.',
        'KERN',
        'Kaleidoscope',
        'Kantar MEDIA',
        'Kenshoo',
        'Kepler Group',
        'Kinesso',
        'LG Ads',
        'LPK',
        'Landor',
        'LeanPlum',
        'Listrak',
        'LocalSearch',
        'Love Media',
        'Loyal Agency',
        'Lytics',
        'M&C Saatchi Group',
        'MJV',
        'MKTG',
        'MPS Ltd.',
        'MRM McCann',
        'Madison Logic',
        'Magellan',
        'Mailgun',
        'Mapp',
        'Marin Software (MRIN)',
        'Match & Wood',
        'McKenzie Partners',
        'Media.Monks',
        'MediaOcean',
        'Mediahub',
        'Meltwater',
        'Mention Me Limited',
        'Merkle',
        'MessageGears, LLC',
        'Metric Theory',
        'Microsoft Advertising',
        'Mighty Fudge',
        'Mintigo',
        'Moat',
        'Mood Media',
        'Muck Rack',
        'Mullenlowe',
        'Mux',
        'NAS Recruitment',
        'NBCUniversal Media, LLC.',
        'Nanigans',
        'Netcore Cloud',
        'New Engen',
        'NewsGuard Technologies, Inc.',
        'Newswire',
        'Nielsen Holdings',
        'Nielsen Ratings',
        'NinjaCat',
        'Notified (by Intrado)',
        'OMD Worldwide',
        'Obvious.ly',
        'Octane11, Inc.',
        'Olapic',
        'Omnicom Group (OMC)',
        'OptiMine Software',
        'Optimole',
        'Ortto',
        'Outbrain',
        'Overdrive Interactive',
        'PCM Inc',
        'PR Newswire',
        'Pardot',
        'Pathmatics',
        'Pearlfisher',
        'PepperType',
        'Pepperjam',
        'Percolate',
        'Performics',
        'Perigord',
        'Periscope',
        'Piktochart',
        'Politico',
        'Power Digital Marketing',
        'Premion',
        'Prezi',
        'Prime Focus Limited (PFL) (PFOCUS)',
        'Prime Focus Technologies (PFT)',
        'Prisma Campaigns Inc.',
        'Propel PRM',
        'Prowly',
        'Publicis',
        'Publicis Groupe',
        'QuanticMind',
        'Quantum Group',
        'QuickPivot',
        'Quotient Technology, Inc',
        'R-Pac',
        'R.R. Donnelley',
        'R/GA Media Group, Inc.',
        'RAPP',
        'RTB House',
        'Radian6',
        'Rapid Media',
        'ReachLocal',
        'Responsys',
        'RhythmOne',
        'Rio SEO',
        'RocketData',
        'RocketROI',
        'Roku Advertising',
        'SE Ranking',
        'SG360',
        'SGK',
        'SOCi',
        'Sandy Alexander',
        'SearchForce',
        'Sellics',
        'SendGrid',
        'Sendinblue',
        'Shaker Recruitment Marketing',
        'Shutterstock',
        'Siegel+Gale',
        'Silverpop',
        'Simon Data',
        'SinglePlatform',
        'SintecMedia Ltd.',
        'Sky Media UK',
        'Smartly.io',
        'Social Soup',
        'Solocal',
        'Sounds True',
        'Spark',
        'Spark Foundry',
        'SparkPost',
        'Special Australia',
        'Specialty Print Communications (SPC)',
        'SponsorUnited, Inc.',
        'SpotX',
        'Sprout Social',
        'Stackadapt',
        'Stein IAS',
        'Strum Agency',
        'Sun Branding',
        'Switch Digital',
        'Synthesio',
        'TVsquared',
        'Tagger',
        'TapClicks',
        'Taylor Corporation',
        'Tealium',
        'The Core Agency',
        'The Economist Group',
        'The General Store',
        'Thinkerbell',
        'Thomas Reuters (TRI)',
        'Thomson Reuters (TRI)',
        'Thumbor',
        'TikTok For Business',
        'TikTok Marketing',
        'Tinuiti Inc.',
        'Today Digital',
        'Tracy Locke',
        'Trend.io',
        'Triblio',
        'Troika Media Group (TRKA)',
        'True Agency',
        'Uberall',
        'Unbounce',
        'Vault49',
        'Venables Bell & Partners',
        'Vengo Labs',
        'Viacom (VIA)',
        'Vibes',
        'Videology',
        'Visme',
        'Vivaldi Partners Inc.',
        'WPP',
        'WhichPLM',
        'Whitespark',
        'Wolff Olins Limited',
        'WordStream',
        'Wowza Media Systems',
        'Wowza Streaming Cloud',
        'Wpromote',
        'Wunderkind Corporation',
        'Wunderman Thompson',
        'Xaxis',
        'Yext (YEXT)',
        'YouTube Advertising',
        'YuJa',
        'Zappi',
        'Zift Solutions',
        'Zmags',
        'ZypMedia',
        'adm Group Limited',
        'bGenius',
        'cClearly',
        'censhare',
        'eReleases',
        'futurebrand',
        'iCrowdNewswire',
        'iHeartMedia',
        'iProspect',
        'tvScientific, Inc.',
        'vidIQ'
      ])
    ]
  },
  {
    id: 98,
    label: 'Medical Association',
    children: [
      ...new Set([
        'American Academy of Family Physicians (AAFP)',
        'American Academy of Physician Assistants (AAPA)',
        'American Association of Clinical Endocrinologists (AACE)',
        'American Association of Nurse Practitioners (AANP)',
        'American College of Physicians (ACP)',
        'American Diabetes Association (ADA)',
        'American Medical Association (AMA)',
        'American Telemedicine Association',
        'Centers for Disease Control and Prevention(CDC)'
      ])
    ]
  },
  {
    id: 99,
    label: 'Medical Education',
    children: [
      ...new Set([
        'Audiology Education',
        'Competency Based Medical Education',
        'Continuing Medical Education',
        'DAT Preparation',
        'Dental Admission Test (DAT)',
        'Diabetes Education',
        'MCAT Preparation',
        'Medical Collect Admission Test (MCAT)',
        'NCCN Guidelines',
        'USMLE Test Preparation'
      ])
    ]
  },
  {
    id: 100,
    label: 'Medical Research',
    children: [
      ...new Set([
        'ABO Grouping',
        'Bioinformatics',
        'Biopharmaceuticals',
        'Biostatistics',
        'Biotechnology',
        'Blotting',
        'Cancer Genomics',
        'Cell Counting',
        'Cell Sorting',
        'Chemical Research',
        'Chromatography',
        'Clinical Diagnostics',
        'Clinical Trials',
        'Contract Development and Manufacturing Company (CDMO)',
        'Coronavirus (COVID-19)',
        'DNA Sequencing',
        'Electrophoresis',
        'Epidemiology',
        'Epigenetics',
        'Evidence-Based Design (EBD)',
        'Flow Cytometry',
        'Fluorometry',
        'Gene Sequencing',
        'Genetics',
        'Genomic Medicine',
        'Genomics',
        'Health Analytics',
        'Heredity (Genetic Inheritance)',
        'Integrated Health',
        'Laboratory Equipment',
        'Medical Diagnosis',
        'Mesothelioma',
        'Microbiology',
        'Microbiome',
        'Molecular Biology',
        'Monoclonal Antibody Purification',
        'Mouse Models',
        'Population Pharmacokinetics (PopPK)',
        'Precision Medicine',
        'Protein Methods',
        'Protein Purification',
        'Proteomics',
        'Real-Time Polymerase Chain Reaction (Real-Time PCR)',
        'Spectrophotometry',
        'Toxicokinetics',
        'Transfection',
        'Virus Inactivation',
        'Virus Removal'
      ])
    ]
  },
  {
    id: 101,
    label: 'Medical Specialty',
    children: [
      ...new Set([
        'Adolescent Medicine',
        'Alternative Medicine',
        "Alzheimer's Disease",
        'Ambulatory Surgery Centers',
        'Anesthesiology',
        'Audiology',
        'Bone & Joint Health',
        'Cardiology',
        'Cardiothoracic',
        'Central Nervous System (CNS)',
        'Children & Infant Nutrition',
        'Cognitive Health',
        'Critical Care Medicine',
        'Cryptogenic Stroke',
        'Dementia',
        'Dentistry',
        'Dermatology',
        'Emergency Medical Technician (EMT)',
        'Emergency Medicine',
        'Emergency Nurses',
        'Endocrinology',
        'Family Medicine',
        'Fertility Clinics',
        'First Aid',
        'Forensic Nurses',
        'Gastroenterology',
        'Gene Therapy',
        'Geriatrics',
        'Gut Health',
        'Gynecology / Obstetrics (OB/GYN)',
        'Hearing Disorders',
        'Hearing Healthcare',
        'Heart Health',
        'Hematology',
        'Hepatology',
        'Home Care',
        'Intensive Care Unit (ICU)',
        'Internal Medicine',
        'Intracranial  Hemmorrhage',
        'Ischemic Stroke',
        'Maternal Health',
        'Maternal-Fetal Medicine',
        'Medical Aesthetics',
        'Mental Health',
        'Microscopy',
        'Neonatology',
        'Nephrology',
        'Neurology',
        'Nutricosmetics',
        'Occupational Medicine',
        'Oncology',
        'Ophthalmology',
        'Oral and Maxillofacial',
        'Orthopedics',
        'Osteopathy',
        'Otolaryngology',
        'Pathology',
        'Pediatric Audiology',
        'Pediatrics',
        'Personalized Nutrition',
        'Phlebology',
        'Plastic and Reconstructive Surgery',
        'Podiatry',
        'Post-traumatic Stress Disorder (PTSD)',
        'Psychiatry',
        'Pulmonary Disease / Medicine',
        'Radiology',
        'Rehabilitation Nurses',
        'Rheumatology',
        'Rural Health',
        'Skin Health',
        'Spectroscopy',
        'Sports Medicine',
        'Sports Nutrition',
        'Sudden Cardiac Arrest',
        'Travel Nurse',
        'Urology',
        'Vascular',
        'Weight Management',
        "Women's Health"
      ])
    ]
  },
  {
    id: 102,
    label: 'Medical Testing',
    children: [
      ...new Set([
        'Anti-CCP (Anti-Cyclic Citrullinated Peptide) Test',
        'Antinuclear Antibody (ANA) Testing',
        'Blood-Borne Disease Assays',
        'C-Reactive Protein (CRP) Test',
        'Cognitive Assessment',
        'Complement Fixation Test',
        'Complete Blood Count (CBC)',
        'Erythrocyte Sedimentation Rate (ESR Rate)',
        'Eye Exam',
        'Glucose Testing',
        'Microbiology Testing',
        'Multiplex Immunoassays',
        'Newborn Screening',
        'Oral Drug Test',
        'Substance Abuse Evaluations'
      ])
    ]
  },
  {
    id: 103,
    label: 'Medical Treatment',
    children: [
      ...new Set([
        'APRISO',
        'Abbreviated New Drug Application (ANDA)',
        'Adjuvants',
        'Ambulatory Healthcare',
        'Anaplasmosis',
        'Angiogenesis Inhibitors',
        'Angiography',
        'Antimicrobial Resistance (AMR)',
        'Ashwagandha',
        'Autoimmune Diseases',
        'Avastin (Bevacizumab)',
        'Ayurveda',
        'Botanicals',
        'Brand Name Drugs',
        'Bydureon',
        'Byetta',
        'CAR-T Cell Therapy',
        'Canagliflozin',
        'Certified Physician Order Entry (CPOE)',
        'Clinical Workflows',
        'Cobimetinib',
        'Comirnaty COVID-19 Vaccine',
        'Cyramza (Ramucirumab)',
        'Dangerous Drugs',
        'Dipeptidyl Peptidase-4 (DPP-4)',
        'Drug Diversion',
        'Eliquis (apixaban)',
        'Empagliflozin',
        'Enbrel (etanercept)',
        'Entyvio (vedolizumab)',
        'Farxiga',
        'Fire and Rescue Services',
        'GARDASIL 9',
        'Generic Drugs',
        'Gilenya (fingolimod)',
        'Glimepiride',
        'Glucose',
        'Hearing Loss Treatment',
        'Hearing Rehabilitation',
        'Humira (adalimumab)',
        'Imbruvica (ibrutinib)',
        'Immunization',
        'Insulin',
        'Invokamet',
        'Invokana',
        'Janumet',
        'Januvia',
        'Jardiance',
        'Jentadueto',
        'Keytruda (pembrolizumab)',
        'Kombiglyze',
        'Lewy Body Dementia',
        'Lynparza (Olaparib)',
        'Medical Procedure',
        'Medical Supplies',
        'Medicinal Cannabis',
        'Metformin',
        'New Drug Application (NDA)',
        'Ocrevus (ocrelizumab)',
        'Omega-3s',
        'Onglyza',
        'Opdivo (nivolumab)',
        'Paclitaxel',
        'Pain Management',
        'Pediatrics Drug Development',
        'Prevnar 13',
        'REGEN-COV',
        'REVLIMID (lenalidomide)',
        'Radiopharmaceutical',
        'Remicade (infliximab)',
        'Skyrizi (Risankizumab)',
        'Spikevax COVID-19 Vaccine',
        'Stem Cell Therapy',
        'Stivarga',
        'Sulfonylurea (SU)',
        'Therapeutic Massage',
        'Traditional Chinese Medicine',
        'Tradjenta',
        'Trastuzumab (Herceptin)',
        'Vaccination',
        'Vaxzevria',
        'Vectibix',
        'Veklury (remdesivir)',
        'Victoza',
        'Wound Care Products',
        'Xarelto (rivaroxaban)',
        'Xtandi (Enzalutamide)'
      ])
    ]
  },
  {
    id: 104,
    label: 'Messaging',
    children: [
      ...new Set([
        'Audio Conferencing',
        'Chatbot',
        'Content Sharing',
        'Effective Communication',
        'Instant Messaging',
        'Instant Payment Notification (IPN)',
        'Integrated Messaging',
        'Mass Notification System',
        'Messaging Solutions',
        'Presentation Sharing',
        'Rich Communication Services (RCS)',
        'Secure Instant Messaging',
        'Video Conferencing',
        'Web Conferencing',
        'Webcasting',
        'Webinars'
      ])
    ]
  },
  {
    id: 105,
    label: 'Microbiology',
    children: [
      ...new Set([
        'Antimicrobial',
        'Atomization',
        'Bioburden Testing',
        'Biochemistry',
        'Biotinylation',
        'Chromium-6',
        'CyTOF',
        'Electrolysis',
        'Food Analytical Testing',
        'Food Safety',
        'Hazard Analysis and Critical Control Points',
        'Lipidomics',
        'Metabolomics',
        'Microbial Quality Control',
        'Microbial Testing',
        'Microbiological Culture',
        'Microfluidics',
        'Mycobacterium',
        'Next Generation Sequencing',
        'Rapid Microbial Methods',
        'Sterilization',
        'Streptavidin',
        'Trace Contaminants',
        'UVC Light Sterilization'
      ])
    ]
  },
  {
    id: 106,
    label: 'Mobile',
    children: [
      ...new Set([
        'Android Apps',
        'Android Push Notifications',
        'App Analytics Companies',
        'App Development',
        'App Localization',
        'App Optimization',
        'App Store',
        'App Store Optimization (ASO)',
        'Bring Your Own Device (BYOD)',
        'Call Analytics',
        'Constant Bit Rate Service (CBR)',
        'Cost Per Install (CPI)',
        'Data Roaming',
        'Enterprise Mobile Security',
        'Geofencing',
        'In-App Advertising',
        'In-App Messaging',
        'Mobile / Wireless',
        'Mobile 2.0',
        'Mobile Advertising',
        'Mobile App Advertising Performance',
        'Mobile App Analytics',
        'Mobile App Automation',
        'Mobile App Conversion',
        'Mobile App Inbox',
        'Mobile App Retargeting',
        'Mobile App User Experience',
        'Mobile App User Interface',
        'Mobile Application Development',
        'Mobile Application Performance',
        'Mobile Apps',
        'Mobile Audience Targeting',
        'Mobile CRM',
        'Mobile Collaboration',
        'Mobile Commerce',
        'Mobile Computing',
        'Mobile Connectivity',
        'Mobile Content Management',
        'Mobile Device Management',
        'Mobile Field Sales / Wireless Connectivity',
        'Mobile First',
        'Mobile Integration',
        'Mobile Interactions',
        'Mobile Operating System (Mobile OS)',
        'Mobile Optimization',
        'Mobile Payments',
        'Mobile Predictive Analytics',
        'Mobile Push',
        'Mobile Security',
        'Mobile Security Management',
        'Mobile Technology',
        'Mobile Testing',
        'Mobile Wallet',
        'Mobile Web Development',
        'Mobile Workers',
        'Mobility Management',
        'Monthly Active Users (MAU)',
        'Pay Per Call',
        'Rich Push Notifications',
        'SMS (Short Message Service)',
        'Smart Devices',
        'Smartphone',
        'Subscriber Identity Module (SIM)',
        'Texting',
        'Touch Screen',
        'Ultra-Mobile PC',
        'Uninstall Tracking',
        'Windows Mobile',
        'iOS App Marketing',
        'iOS Apps',
        'iOS Push Notifications'
      ])
    ]
  },
  {
    id: 107,
    label: 'Monitoring',
    children: [
      ...new Set([
        'Application Availability',
        'Automated Optical Inspection',
        'Central Station Monitoring',
        'Continuous Controls Monitoring',
        'File Integrity Monitoring (FIM)',
        'GitOps',
        'Machine Vision',
        'Monitoring',
        'Nagios',
        'Operational Technology (OT)',
        'PRTG',
        'Proactive Monitoring',
        'Proximity Sensor',
        'Real User Monitoring (RUM)',
        'Reliability-Centered Maintenance (RCM)',
        'Security Monitoring',
        'Sensors, Test & Measurement',
        'Smart Sensor',
        'Spectrum Monitoring',
        'Synthetic Monitoring',
        'System Center Operations Manager (SCOM)',
        'System Monitors',
        'Temperature Sensor',
        'Troubleshooting',
        'Virtual Machine Monitor',
        'Visual Inspection'
      ])
    ]
  },
  {
    id: 108,
    label: 'Networking',
    children: [
      ...new Set([
        '3G',
        '400G',
        '4G',
        '4G Backup',
        '5G',
        '800G',
        'AT&T 5G',
        'AT&T Internet of Things',
        'AWS Direct Connect',
        'AWS Global Accelerator',
        'AWS NAT Gateway',
        'Aggregator',
        'All-IP',
        'Application Aware Network',
        'Application Layer',
        'Asymmetric Digital Subscriber Line (ADSL)',
        'Authoring System',
        'Azure Express Route',
        'Azure NAT Gateway',
        'Azure PrivateLink',
        'Azure Virtual WAN',
        'Backhaul',
        'Bandwidth',
        'Bandwidth as a Service',
        'Border Gateway Protocol (BGP)',
        'Broadband',
        'Broadband Over Power Lines (BPL)',
        'Business Broadband',
        'Business Ethernet Network',
        'Cat-M',
        'Coaxial Cables',
        'Communications Satellite',
        'Content Caching',
        'Content Delivery Network (CDN)',
        'Content Filtering',
        'Critical Asset Tracking',
        'Customer-Premises Equipment (CPE)',
        'Dark Fiber',
        'Data Center Networking',
        'Data Networks',
        'Data Transmission',
        'Decentralization',
        'Dedicated Gig Internet',
        'Deep Fiber Deployment',
        'Deep Network Observability',
        'Deep Packet Inspection (DPI)',
        'Desktop Sharing',
        'Digital Subscriber Line (DSL)',
        'Digital Substation',
        'Dynamic Host Configuration Protocol (DHCP)',
        'EtherNet/IP Network',
        'Ethernet',
        'Ethernet Network',
        'Ethernet Network Port',
        'Ethernet Network Provider',
        'Ethernet Network Services',
        'Ethernet Networking',
        'Ethernet Networking Solutions',
        'Ethernet Over Copper (EoC)',
        'Extranets',
        'Fabric Connect',
        'Fault Tolerance',
        'Fiber Connected Buildings',
        'Fiber Optics',
        'Fiber Raceway',
        'Fiber to the Curb (FTTC)',
        'Fiber to the Premises (FTTP)',
        'Fibre Channel',
        'File Transfer Protocol (FTP)',
        'Fog Computing',
        'Gigabit Internet',
        'Gigabit Networking',
        'Gigabit Services',
        'Gigabits Per Second',
        'Global Distribution System (GDS)',
        'Global Wide Area Network',
        'Grid Computing',
        'HP ProCurve',
        'Hacktivism',
        'High Availability',
        'High Speed Networks',
        'Highway Addressable Remote Transducer (HART)Protocol',
        'Home Networking',
        'Honeypot Network',
        'Hybrid Fiber Coaxial (HFC)',
        'Hybrid Wide Area Networking (WAN)',
        'Hyperconnectivity',
        'IP Filtering',
        'IP Networks',
        'IP Storage',
        'IP VPN',
        'In-Building Connectivity',
        'Indicators of Compromise (IOC)',
        'Industrial WiFi',
        'Infiniband (IB)',
        'Integrated Services Digital Network (ISDN)',
        'Intelligent Routing',
        'Internet Control Message Protocol (ICMP)',
        'Internet Exchange Points',
        'Internet Protocol Private Branch Exchange (IP PBX)',
        'Internet Protocol Television (IPTV)',
        'Internet Protocol Version 4 (IPv4)',
        'Internet Protocol Version 6 (IPv6)',
        'Internet Relay Chat (IRC)',
        'Internet Speed Test',
        'Internet Traffic',
        'Internet Transit',
        'Internet infrastructure',
        'Intranets',
        'Java Management Extensions (JMX)',
        'LTE CAT M1',
        'Last Mile',
        'Lateral Movement',
        'LayerOne',
        'Lightweight Directory Access Protocol',
        'Local Area Networking (LAN)',
        'Long Term Evolution (LTE)',
        'Low Latency Ethernet',
        'Low Power Wide Area Network',
        'MOST (Media Oriented Systems Transport)',
        'Managed Hosting Services',
        'Mesh Networking',
        'Message Queuing Telemetry Transport (MQTT)',
        'Metro Ethernet',
        'Microwave Backhaul',
        'Mobile Broadband',
        'Multi-Vendor Network Management',
        'Multimedia over Cable Alliance (MoCA)',
        'Narrowband',
        'Narrowband IoT',
        'NetFlow Analyzer',
        'Network Access Control (NAC)',
        'Network Address Translation (NAT)',
        'Network Architecture',
        'Network Attached Storage',
        'Network Automation',
        'Network Best Practices',
        'Network Bottleneck',
        'Network Congestion',
        'Network Connectivity',
        'Network Convergence',
        'Network Densification',
        'Network Encryption',
        'Network Forensics',
        'Network Functions Virtualization (NFV)',
        'Network Hardware',
        'Network Infrastructure',
        'Network Latency',
        'Network Load Balancing (NLB)',
        'Network Management',
        'Network Modernization',
        'Network Monitoring',
        'Network Neutrality',
        'Network Observability',
        'Network Operations Center (NOC)',
        'Network Optimization',
        'Network Packet Brokers (NPBs)',
        'Network Performance',
        'Network Perimeter Security',
        'Network Printing',
        'Network Protocols',
        'Network Redundancy',
        'Network Security',
        'Network Security Appliance',
        'Network Switch',
        'Network TAP',
        'Network Time Protocol',
        'Network Topology',
        'Network Traffic Analysis',
        'Network Transformation',
        'Network Visibility',
        'Network as a Service',
        'Networking / Telecommunications',
        'Networking Applications',
        'Networking Solutions',
        'Open Shortest Path First (OSPF)',
        'OpenVPN',
        'Optical Networking',
        'Optical Transport Network (OTN)',
        'Orbi',
        'Out-Of-Band Management',
        'Outdoor Cell Site',
        'Overlay Network',
        'PAM 4',
        'Packet Switching',
        'Peer to Peer',
        'Perfect Forward Secrecy (PFS)',
        'Petabyte',
        'Point of Presence (POP)',
        'Point-to-Point Tunneling Protocol (PPTP)',
        'Port 25',
        'Post Office Protocol (POP)',
        'Power over Ethernet (PoE)',
        'Primary Rate Interface (PRI)',
        'Private LTE',
        'Public-Key Infrastructure (PKI)',
        'RDMA over Converged Ethernet (RoCE)',
        'Remote Access',
        'Remote Desktop Protocol (RDP)',
        'Remote Monitoring',
        'Remote Network Management',
        'Remote Networking',
        'Routers',
        'Routing Protocols',
        'RunBooks',
        'SD-Branch',
        'SMTP Relay Services',
        'Satellite Broadband',
        'Secure Shell (SSH)',
        'Serializer/Deserializer (SerDes)',
        'Server Message Block (SMB)',
        'Service Management and Orchestration (SMO)',
        'Session Border Controller (SBC)',
        'Session Initiation Protocol (SIP)',
        'Simple Mail Transfer Protocol (SMTP)',
        'Simple Network Management Protocol (SNMP)',
        'Small Cells',
        'Software-Defined Networking (SDN)',
        'Software-Defined Wide Area Network (SD-WAN)',
        'Subnet',
        'Switching',
        'Synchronous Optical Networking (SONET)',
        'TCP/IP Protocol',
        'Terminal / Network Computers',
        'Terminal Emulators',
        'Tether',
        'Timestamp',
        'Tunneling',
        'Universal Customer Premise Equipment (uCPE)',
        'Universal Plug and Play (UPnP)',
        'User Datagram Protocol (UDP)',
        'VPN',
        'Verizon Fios',
        'Very Small Aperture Terminal (VSAT)',
        'Very-high-bit-rate Digital Subscriber Line (VDSL)',
        'Virtual LAN',
        'Virtual Prototyping',
        'Voice Revenue',
        'WAN Acceleration',
        'Wavelength Division Multiplexing (WDM)',
        'Wide Area Networking (WAN)',
        'WinSCP',
        'Wireshark',
        'Zero Touch Provisioning (ZTP)',
        'Zero Trust Network Access (ZTNA)',
        'iSCSI',
        'vRouter'
      ])
    ]
  },
  {
    id: 109,
    label: 'Operating System',
    children: [
      ...new Set([
        'Active Directory Certificate Services (ADCS)',
        'Application Modernization',
        'Immutable Infrastructure',
        'Inter-Process Communication (IPC)',
        'Linux',
        'Linux Lite',
        'Operating System',
        'Privilege Escalation',
        'Real Time Operating Systems (RTOS)',
        'Red Hat Enterprise Linux',
        'Repair and Recovery',
        'Trusted Execution Environment'
      ])
    ]
  },
  {
    id: 110,
    label: 'Operations',
    children: [
      ...new Set([
        'Artwork Management',
        'Asset Tracking',
        'Automated Workflow',
        'Back Office Managed Services',
        'Building Security',
        'Burglar Alarms',
        'Business Outcomes',
        'Business Process Analysis (BPA)',
        'Cannabis Cultivation',
        'Cannabis Edibles Manufacturing',
        'Cannabis Extraction',
        'Centralize vs. De-Centralize',
        'Cloud Contact Center',
        'CoWorking',
        'Collaboration Lounge',
        'Collaborative Spaces',
        'Collaborative Workspace',
        'Commercial Perimeter Protection',
        'Commercial Security',
        'Company values',
        'Conformance Checking',
        'Construction Management',
        'Continuous Performance Management',
        'Contractor Management Software',
        'Corporate Language Training',
        'Corrective and Preventive Action',
        'Correspondence Management',
        'Cost Savings',
        'Curbside Pickup',
        'Digital Client Onboarding',
        'Digital Process Automation',
        'Driver Risk Assessment',
        'ERP Transformation',
        'Electronic Article Surveillance',
        'Emergency Communication System',
        'Enterprise Digital Transformation',
        'Enterprise Services',
        'Equipment Leasing',
        'Fire Detection',
        'Fire Monitoring',
        'Fleet Cards',
        'Fleet Maintenance',
        'Fleet Procurement',
        'Food Processing Operations',
        'Freight Forwarder',
        'Gemba walk',
        'Goal Management',
        'Grey Fleet',
        'Hybrid Customer Experience',
        'ISO 9001',
        'Inbound Calls',
        'Industrial Cooling',
        'Integrated Talent Management',
        'Internal Communications',
        'Inventory Control',
        'Invoice Management',
        'Logistics optimization',
        'Loss Prevention',
        'Managed Print Services',
        'Mass Notification Solutions',
        'Mission Critical',
        'Multi-Channel Communication',
        'Objectives and Key Results',
        'Office Remodel',
        'Oilfield Services',
        'Omnichannel Customer Service',
        'Operating Cost',
        'Operational Efficiency',
        'Operational Risk Management',
        'Operations Consulting',
        'Operations Optimization',
        'Order To Cash',
        'Out-Of-Stock (OOS)',
        'Outsourced Customer Service',
        'Outsourced Operations',
        'Partner Relationships',
        'Predictive Process Monitoring',
        'Process Analytics',
        'Process Compliance',
        'Process Intelligence',
        'Process Mapping',
        'Process Modeling',
        'Producer Management System',
        'Product Catalog',
        'Product Experience Management',
        'Profitability Analysis',
        'Quorum',
        'Recurring revenue',
        'Remote Office',
        'Repeat Business',
        'Requisition',
        'Restaurant Management',
        'Sales And Operations Planning',
        'Shared Service',
        'Shuttle Bus Services',
        'Small Business Solutions',
        'Smart Operations',
        'Smart Safes',
        'Social Customer Service',
        'Source Tagging',
        'Spare Parts Inventory',
        'Subscription Management',
        'Supply Chain Control Tower',
        'Supply Chain Visibility',
        'Target Operating Model',
        'Technician scheduling',
        'Tenant Screening',
        'Third-Party Risk Management',
        'Treasury Management',
        'Use Case',
        'Value-Added Services',
        'Vehicle Tracking',
        'Vendor Management Portal',
        'Vendor Management Program',
        'Video Surveillance',
        'Warehouse Picking',
        'Yield Optimization',
        'vCISO'
      ])
    ]
  },
  {
    id: 111,
    label: 'Other',
    children: [
      ...new Set([
        '1pointfive',
        '2.5D Animation',
        '2D Animation',
        '3D Animation',
        '3Degrees',
        '401(k) Fiduciary',
        'A10 Networks (ATEN)',
        'AAA (American Automobile Association, Inc.)',
        'AAdvantage',
        'ACARS',
        'ACH Payments',
        'ADT',
        'ADT Commercial LLC',
        'AI Safety',
        'AI Transparency',
        'AI for DevOps',
        'AI for Marketing',
        'AIS Software for PC',
        'AIS Vessel Finder',
        'ASIS International',
        'AT&T Internet and Networking',
        'ATM Migration',
        'ATM Skimming',
        'Abatement',
        'AccessData',
        'Accessible Travel',
        'Acoustical Surfaces, Inc.',
        'Acoustics',
        'Acumatica Cloud ERP',
        'Address Book',
        'Address Verification',
        'Adverse Media',
        'Aegis Energy Services',
        'Aemetis, Inc.',
        'Affordable Care Act',
        'Age Verification',
        'Agile Certification',
        'Agile Coaches',
        'Agile Manifesto',
        'Agile Methodology',
        'Agile Sprint',
        'Agile Transformation',
        'Agile at Scale',
        'Agrana',
        'Agribusiness',
        'Air Cargo',
        'Air Defense',
        'Air Liquide',
        'Aircraft Livery',
        'Allied Universal',
        'Altria',
        'Amazon Accelerator',
        'Amdocs (DOX)',
        'Ameresco',
        'American Institute of Architects (AIA)',
        'Americold Realty Trust, Inc.',
        'Amsterdam Airport Schiphol',
        'Anheuser-Busch',
        'Annotation Acquisition Campaign',
        'Anthesis Group Ltd.',
        'App Monetization',
        'Application Service Provider',
        'Application Software',
        'Aramark',
        'Arbor Networks',
        'ArcSight',
        'Arcadia Data',
        'Archer Daniels Midland',
        'Arup Group',
        'Asset Protection',
        'Assistive Technology',
        'Auction Software',
        'Audience Development',
        'Audio Editing Software',
        'AutoFi',
        'AutoZone, Inc.',
        'Autodesk Revit',
        'Automated Maintenance',
        'Automation Control System (ACS)',
        'Automotive Compliance',
        'Automotive Digital Marketing',
        'Automotive Inventory Management',
        'Automotive Market Data',
        'Automotive Service Software',
        'Automotive marketing',
        'Autotrader',
        'Avianva',
        'B2B Travel Portal',
        'BASF',
        'BIMCO',
        'BMW Motorrad',
        'BNSF Railway',
        'Background Music for Business',
        'Background Music for Hotel',
        'Balcones Resources, Inc.',
        'Bar Music System',
        'Basemaps',
        'Bayhorse Silver Inc.',
        'Bayshore Recycling Corp. (BRC)',
        'Beacon Recycling, Inc.',
        'Behavioral Finance',
        'Belfor, Inc.',
        'Belt and Road Initiative',
        'BenefitBump',
        'Berkshire Hathaway',
        'Beverage Manufacturing ERP',
        'Big Blue',
        'Bilingual Call Answering Services',
        'Biometrics',
        'Blackhawk Network',
        'Blackstone Real Estate Income Trust (BREIT)',
        'Blueprint',
        'Bluesource',
        'Bond Market',
        'Booster Fuels',
        'Bounce Back Programs',
        'Bradford Networks',
        'Branch Transformation',
        'Branch of the Future',
        'Bright Horizons Family Solutions Inc. (BFAM)',
        'British Standards Institution',
        'Broadstone Net Lease',
        'Brochure Design',
        'Building Advisor',
        'Building Twin',
        'BuildingConnected',
        'BuildingSMART',
        'Bunge Limited',
        'Bureau Veritas',
        'Bureau of Labor Statistics (BLS)',
        'Bureau van Dijk',
        'Business / Travel Expenses',
        'Business Card',
        'Business Card Scanning',
        'Business Case',
        'Business Identity Theft',
        'Business Shipping Solutions',
        'Business Signage',
        'Business Transition',
        'Bust Out Fraud',
        'Buyers Edge',
        'C-suite',
        'CARNET',
        'CATIA',
        'CBRE (CB Richard Ellis Group)',
        'CDK Global',
        'CHOOOSE',
        'CIBT Inc.',
        'CJIS Compliance',
        'COPE (Corporate-Owned Personally Enabled)',
        'CSAT Score',
        'CSO',
        'California Waste Recovery Systems',
        'Call Overflow Answering Services',
        'Campaign Finance',
        'CannaBusiness',
        'Cannabis Compliance',
        'Cannabis Software',
        'Canteen',
        'Capital Paper Recycling, Inc.',
        'CarGurus',
        'Caraustar Industries, Inc.',
        'Carbon Capture',
        'Carbon Disclosure Project',
        'Carbon Engineering',
        'Carbon Neutral',
        'Carbonfund.org',
        'Care for Kids',
        'Cargill',
        'Case IH',
        'Casella Waste Systems, Inc. (CWST)',
        'Cash-in-transit',
        'Catalog Management',
        'Catastrophe Insurance',
        'Center for Strategic and International Studies (CSIS)',
        'Centrica',
        'Centrify',
        'Certified Translations',
        'Charm Industrial',
        'Chartered Financial Analyst (CFA)',
        'ChatOps',
        'Check Point',
        'Checkr',
        'Chevron (CVX)',
        'Chief Information Security Officer (CISO)',
        'Childcare Management Software',
        'China Cyber Security Law',
        'Choose Your Own Device (CYOD)',
        'CipherCloud',
        'Circular Economy',
        'Citizen development',
        'City Pantry',
        'Citysearch',
        'Claims Management Software',
        'Clean Air Task Force',
        'Clearloop',
        'Climate Neutral',
        'ClimatePartner GmbH',
        'Cloverly',
        'Club Procure',
        'Cobra',
        'Coldwell Banker',
        'Coldwell Solar',
        'Colgate-Palmolive Company',
        'Collaborative Content',
        'Collaborative Work Management',
        'Colliers International',
        'Color Rendering Index (CRI)',
        'Combat Systems',
        'Combined Resources Inc.',
        'Comm100',
        'Command Center',
        'Commercial Landscaping',
        'Commercial Real Estate Broker',
        'Common Information Model (CIM)',
        'Compactor Management Company',
        'Company Car',
        'Company Merchandise',
        'Company Swag Stores',
        'Compass',
        'Compliance Assistance Program (CAP)',
        'Compliance Central',
        'Computer Aided Engineering',
        'Concentric',
        'Conferences',
        'ConocoPhillips',
        'Consilio LLC',
        'Consolidated Concepts',
        'Construction ERP',
        'Construction Management Software',
        'Consumer Finance',
        'Consumer Product Safety Commission',
        'Consumer Spending',
        'Consumerization of IT',
        'Content Commerce',
        'Content Moderation',
        'Continuous improvement process (CIP)',
        'Contract Administration',
        'Core Systems',
        'Corero',
        'Corporate Retreats',
        'Corporate Social Responsibility (CSR)',
        'Cost Per Lead (CPL)',
        'Covanta Holding Corp.',
        'Cox Automotive',
        'Cox Enterprises',
        'Crane Aerospace & Electronics',
        'Cresa',
        'Criminal Records',
        'Crisis communication',
        'Crowdin',
        'CrunchTime',
        'Curriculog',
        'Cushman & Wakefield',
        'Custom Apparel',
        'Custom Company Swag',
        'Customer / Consumer Survey',
        'Customer Alliance',
        'Customer Education',
        'Customer Effort Score',
        'Customer Enablement',
        'Customer Engagement',
        'Customer Lifetime Value',
        'Customer Needs',
        'Customer Support',
        'Customer service plan',
        'Cyber Essentials (CE)',
        'CyrusOne, Inc. (CONE)',
        'DAC Group',
        'DAO Frameworks',
        'DAO Tooling',
        'DNV',
        'Dana Incorporated',
        'DarkTower',
        'DashPass',
        'Data Clean Rooms',
        'Data Philanthropy',
        'Data Privacy Day',
        'Data Protection Impact Assessment',
        'Data Stewardship',
        'Data Universal Numbering System (DUNS)',
        'DataShield Corporation',
        'Dazn',
        'Dealer Inspire',
        'Dealer.com',
        'Dealertrack',
        'Decentralized Autonomous Organization (DAO)',
        'Defeasance',
        'Delivery.com LLC',
        'DeltaV',
        'Department Of Labor (DOL)',
        'Developer Advocacy',
        'Device Enrollment',
        'Diageo plc (DEO) (DGE.L)',
        'Digital Building Lifecycle',
        'Digital Businesses',
        'Digital Convergence',
        'Digital Economy',
        'Digital Elevation Models (DEMs)',
        'Digital Innovation',
        'Digital Realty (DLR)',
        'Digital Shadows',
        'Digital Signal Processing',
        'Digital Sustainability',
        'Digital Transformation Companies',
        'Diligent ESG',
        'Dining Alliance',
        'Directory Services',
        'Discount Strategy',
        'Distil Networks',
        'Distributed Control System',
        'Distributed Energy Resources Management System (DERMS)',
        'Distributed Solar Development, LLC',
        'Divestiture',
        'Dominion Dealer Solutions',
        'DoorDash',
        'Dow Jones Sustainability Index (DJSI)',
        'Drag and Drop',
        'Drax Group (Drax) (DRX.L)',
        'Driving Records',
        'Due Diligence Questionnaire',
        'Dunkin Donuts',
        'Duo',
        'Duty of Care',
        'Dynamic Scheduling',
        'E-rate',
        'E-waste Management',
        'E.ON Energy',
        'EBSCO Information Services',
        'EDF Renewable Energy, Inc.',
        'ERP Vendors',
        'Earned Media',
        'Eco-Cycle Inc.',
        'Ecolab Inc.',
        'Economic Downturn',
        'Economic Sustainability',
        'EdTech (Education Technology)',
        'Edison Energy',
        'Electric Bike',
        'Electronic Journals',
        'Electronic Title',
        'Electronic Warfare (EW)',
        'Elgin Recycling',
        'Emergency Housing Voucher (EHV) Program',
        'Eminent domain',
        'Employee Engagement Survey',
        'Emterra Group Inc.',
        'Energy Transfer',
        'Engineer to Order / Make to Order',
        'Enhanced Vessel Traffic Management System (EVTMS)',
        'Entegra',
        'Enterprise Products Partners L.P.',
        'Entrata, Inc.',
        'Environment Claims',
        'Environmental Protection Agency (EPA)',
        'Everlaw',
        'Evident.Io',
        'Exchange Bidding',
        'Executive Coaching',
        'Executive Education',
        'Executive News Briefings',
        'Exiger',
        'Expert Network',
        'Exponential Growth',
        'Exponential Organizations',
        'ExxonMobil (XOM)',
        'F&I Menu',
        'F-gas Regulation',
        'FDA Form 483',
        'FDA Warning Letters',
        'FM:Systems',
        'FRCP 37(e)',
        'FUJIFILM Data Management Solutions Pty Ltd',
        'FUJIFILM Holdings Corporation (FUJIY)',
        'FactoryTalk',
        'Family-Owned Businesses',
        'Federal Financial Institutions Examination Council (FFIEC)',
        'Federal Open Market Committee (FOMC)',
        'Fedora Project',
        'Field Service Software',
        'Finance Transformation',
        'Finance and Accounting Outsourcing',
        'Financial crime',
        'Fineline Graphics',
        'Fire Extinguishers',
        'Fire protection',
        'Fire sprinklers',
        'First Notification of Loss',
        'First Onsite Property Restoration',
        'Flashpoint',
        'Fleet Maintenance Software',
        'Flight Booking Platforms',
        'Food Service',
        'Foodbuy',
        'Forcepoint',
        'ForeScout',
        'Forest Stewardship Council (FSC)',
        'Fragomen, Del Rey, Bernsen & Loewy, LLP',
        'Franchise Marketing',
        'Free Spirit',
        'Freedom of Information Act (FOIA)',
        'Freightliner eCascadia',
        'Frontier Climate',
        'GFL Environmental Inc. (GFL)',
        'GOGA',
        'GPS',
        'GS1 US',
        'Gamification',
        'Gantt Chart',
        'Gas Transmission Systems, Inc. (GTS)',
        'General Electric (GE)',
        'General Mill Supply Co.',
        'Generative Design',
        'Geographic Information Systems',
        'Geolocation',
        'Geospatial Data',
        'GfK',
        'Global Reporting Initiative',
        'Global Thermostat',
        'Global Trash Solutions (GTS)',
        'Global Vessel Tracking',
        'GoShare',
        'Google Earth',
        'GovCon ERP',
        'Government Contractors',
        'Government IT',
        'Government Translation Services',
        'Grainger (GWW)',
        'Grant Management',
        'Grant Management Software',
        'Great Lakes Label, LLC',
        'Green IT',
        'Green Mountain Power Corporation',
        'Gregory FCA',
        'Gross Domestic Product (GDP)',
        'Group Travel',
        'Group-IB',
        'Grubhub',
        'Grubhub Corporate Accounts',
        'Guinness World Records',
        'HIMA Group',
        'HMI Software',
        'HR Portal',
        'HTTP Live Streaming (HLS)',
        'Hanover Research',
        'Headspace',
        'Health Canada Cannabis Compliance',
        'High Dynamic Range (HDR)',
        'Higher education software',
        'Holiday Fraud',
        'Holiday Season',
        'Honeywell (HON)',
        'Horizon Healthcare Staffing',
        'Hospitality Property',
        'Hotwire, Inc.',
        'Human-Machine Interface (HMI)',
        'Humanitarian Travel',
        'Huron Paper Stock',
        'Hyperautomation',
        'Hyperscience',
        'IAG',
        'ID Scanning',
        'IDEA Compliance',
        'INDYCAR',
        'INTERCARGO',
        'IP Migration',
        'IT Ecosystems',
        'IT Governance, Risk and Compliance',
        'IT/OT Integration',
        'Identity Graph',
        'Immersive Experience',
        'Immigrant/Migrant Integration',
        'Immigration and Customs Enforcement (ICE)',
        'Imperial Brands PLC',
        'Implementation Services',
        'Incentive Travel',
        'Inclusive Hospitality Solutions',
        'Inflation',
        'Inflation Reduction Act',
        'Infoblox',
        'Informa',
        'Information Assurance',
        'Information Lifecycle Management',
        'Information Technology',
        'Inspection process',
        'Inspire Brands',
        'Instacart',
        'Instructional design (ID)',
        'Instructional software',
        'Integreon, Inc.',
        'Intel 471',
        'IntelliCAD',
        'Intelligent Personal Assistant',
        'Intelligent Vehicles',
        'Interactive Content',
        'Interactive Teller (ITM)',
        'Interactive Vending Machine',
        'Interactive Voice Response',
        'Interactive Voice Response (IVR)',
        'Intergovernmental Agreements (IGAs)',
        'International Interior Design Association (IIDA)',
        'International Maritime Organization (IMO)',
        'International Mobile Equipment Identity (IMEI)',
        'International Monetary Fund (IMF)',
        'Internet Forum',
        'Invalid Traffic (IVT)',
        'JLL',
        'Janitorial',
        'John Crane Inc.',
        'John Lewis Partnership Plc',
        'Johnson & Johnson',
        'Journalist Database',
        'Junk Removal',
        'JustAnswer',
        'Kangarootime',
        'Kantar',
        'Karius',
        'Kelley Blue Book',
        'Kerry Group (KYGA)',
        'Kindbody',
        'Kinside',
        'Knotel',
        'Knowledge Graph',
        'Knowledge capture',
        'Koch Industries',
        'Kruger Inc.',
        'Kuiper Systems LLC',
        'LG Corporation',
        'LIDAR scanning',
        'LOT Network',
        'LSC Communications',
        'Labeling automation',
        'Labware',
        'Landsat Imagery',
        'Landscape Design',
        'Language Interpretation',
        'Language Testing',
        'LanguageLine Solutions',
        'Lawn care software',
        'Leadership Coaching',
        'Leadership Development',
        'Learning Experience Platform (LEP/LXP)',
        'Lease Management System/Software',
        'Legacy Data',
        'Legal Answering Services',
        'Legal Hold Software',
        'Lennar',
        'Lessard Design Inc',
        'Lex Mundi',
        'LexisNexis',
        'LexisNexis Counsellink',
        'License Plate Readers',
        'Linus Torvalds',
        'Lionbridge',
        'Liquid Death',
        'Litify, LLC',
        'Litigation Funding',
        'Live Streaming',
        'LiveVox, Inc.',
        'Living Lab',
        'LoadUp Technologies, LLC.',
        'Loan Origination',
        'Loan Origination Software',
        'Lobbying',
        'Local Government',
        'Location Analytics',
        'Location Data',
        'Location-Based Services',
        'Loews Corporation',
        'LogPlot',
        'LogiMAT 2020',
        'Logistics Providers',
        'Lokalise',
        'Long-Range Identification and Tracking',
        'Lonza',
        'Louis Padnos Iron & Metal Company',
        'Loyalty Liability',
        'Loyalty Points Program',
        'Loyalty Program',
        'Loyalty Punch Card',
        'Luxer One',
        'M&A Consulting',
        'M&A Support',
        'M365/Microsoft 365',
        'ML Platform',
        'MRC Accredation',
        'MRI Software',
        'MSC Industrial Direct Co., Inc. (MSM)',
        'Machine Control',
        'Machine-to-Machine (M2M)',
        'Maintel Holdings Plc',
        'Maintenance, Repair and Overhaul (MRO)',
        'Major, Lindsey & Africa, LLC',
        'Manheim',
        'Mapping',
        'Margin Management',
        'Marine Technology',
        'MarineTraffic',
        'Maritime Training Companies',
        'Market Sizing',
        'Marketing Enablement',
        'Marketing Information Systems',
        'Marketing Maturity',
        'Marketing Skills',
        'Marketing Software',
        'Marketing Technology',
        'Markley Group',
        'Material Bank',
        'Mazda',
        'McAfee, Inc.',
        'McAllister & Quinn',
        "McDonald's",
        'Media Production',
        'Media Relations',
        'Medical office buildings (MOBs)',
        'Medtronic',
        'Membership Management',
        'Menasha Packaging Company, LLC',
        'Mentoring Software',
        'Metaverse Consulting',
        'Metro Waste Authority',
        'Metrology',
        'Micro Branch',
        'Micro Services Architecture',
        'Microsoft Licensing',
        'Microsoft Services Provider License Agreement (SPLA)',
        'Midwest Industrial Supply, Inc.',
        'Milk Stork',
        'Millennium Recycling, Inc.',
        'MilliporeSigma',
        'Mineral Processing',
        "Ming's Recycling Corporation",
        'Mishcon de Reya LLP',
        'Mobile Industry Processor Interface (MIPI) Alliance',
        'Mobile Mapping',
        'Mobile Workforce',
        'Mobility Aids',
        'Molecular Devices, LLC',
        'Moodle',
        'Mortgage Servicing Communications',
        'Mortgage Servicing Software',
        'Motion Industries',
        'Multi-brand Loyalty',
        'Multiplay',
        'Music for Coffee Shops',
        'Music for Restaurants',
        'Mystery Shopping',
        'NASDAQ Streaming',
        'NEOM Company',
        'NOV',
        'National Automobile Dealers Association (NADA)',
        'Natural Capital Partners',
        'Net Lease',
        'Neuromorphic Computing',
        'Newmark',
        'NielsenIQ',
        'Nike',
        'Nisos',
        'Noom',
        'Northstar Recycling Company, Inc.',
        'Notice of Proposed Rulemaking (NPRM)',
        'Nsfocus (SHE)',
        'OAM Equipment Solutions',
        'OCLC, Inc.',
        'OPC',
        'OPEC (Organization of the Petroleum Exporting Countries)',
        'Object Detection',
        'Object Management Group',
        'Observability',
        'Occupational Safety and Health Administration (OSHA)',
        'Octopus Energy Group (Octopus Energy LTD)',
        'Odor Removal',
        'Office Depot',
        'Office Furniture',
        'Omnilert LLC',
        'On-the-job training (OJT)',
        'OneLogin',
        'Online Ordering',
        'Open Data',
        'Open Invention Network',
        'Open Microcredentials',
        'Open Source Digital Signage Software',
        'OpenGov',
        'Optical Comparators',
        'Oracle Agile',
        'Ordermark',
        'Organization Consulting',
        'Organizational Simplification',
        'Out-of-State Registration',
        'Out-of-State Title',
        'Outsourced Logistics',
        'Outsourcing Renewals',
        'Owned and Operated Media (O&O)',
        'PCE, Inc.',
        'PGA',
        'PGA Tour',
        'POWER Engineers, Inc.',
        'PROCAS, LLC',
        'PTC Creo',
        'Paddy Power Betfair',
        'Pall Corporation',
        'Panic Devices',
        'PaperCut',
        'Parent Communication',
        'Partner Referrals',
        'Partner enablement',
        'Pas-X',
        'PatientSafe',
        'Paul Davis Restoration, Inc.',
        'Payment Network',
        'Pedestrian Protection',
        'PeerSpot Ltd.',
        'People risk',
        'PeproTech',
        'PerfectServe',
        'Performance Food Group',
        'Performance Testing',
        'Persona',
        'Perspecta',
        'Pest control software',
        'Pexapark',
        'Pfizer Inc.',
        'Phillips 66 (PSX)',
        'Photography Studio Rental',
        'Photonics',
        'Pick to Light',
        'Pivot Energy Inc.',
        'PlanGrid',
        'Planogram',
        'Planting',
        'Plug Load Management',
        'Poland Developers',
        'Poland Outsourcing',
        'Political Action Committee (PAC)',
        'Political Violence',
        'Politically Exposed Person (PEP)',
        'Pool service software',
        'Port Automation',
        'Port Optimisation',
        'Powerfleet Inc',
        'Presentations',
        'Preview Pane',
        'Printful',
        'Procare Solutions',
        'Procore Technologies',
        'Procurement Consulting',
        'Product Photography',
        'Product Reviews',
        'Production Tax Credit (PTC)',
        'Professional Videographer',
        'Progyny',
        'Project Management Office (PMO)',
        'Promotion Marketing',
        'Proof of Concept Software',
        'Proofpoint',
        'Proper Hospitality, LLC',
        'Property Technology',
        'Proposal Management',
        'Public Investment Fund of Saudi Arabia (PIF)',
        'Public Records Request',
        'Public Speaking',
        'Pulse Secure',
        'Purchasing Power Parity (PPP)',
        'Puro.earth',
        'Q4 Inc.',
        'Qatar Financial Centre (QFC)',
        'Qatar Free Zones Authority (QFZA)',
        'Qatar Tourism (QT)',
        'Qiagen',
        'Qintel',
        'Qualified Longevity Annuity Contract (QLAC)',
        'Quality Of Service',
        'Queue Management System',
        'Quincy Recycle Paper, Inc.',
        'Quintessentially (UK) Ltd.',
        'Quixel Mixer',
        'R&D Tax Credit',
        'R.Stahl Inc.',
        'RAND Corporation',
        'RPA Bot Migration',
        'Radware (RDWR)',
        'Ram ProMaster EV',
        'Rapid Rewards',
        'Rating Agency',
        'Rational DOORS',
        'Raven Industries',
        'Real Estate Investment Trusts',
        'Real estate development',
        'RealPage',
        'Recession',
        'Recology Inc.',
        'Recommerce',
        'Reconnaissance & Surveillance',
        'Records Retention',
        'Recovery Funds',
        'Recycle Track Systems, Inc. (RTS)',
        'Recycling Management Resources LLC',
        'Redlock',
        'Regulations',
        'Rent Relief',
        'Republic Services',
        'Repurposing',
        'Research Triangle Institute',
        'Resideo Technologies, Inc',
        'Reskilling',
        'Restaurant Management Software',
        'Revel Systems',
        'Revenue Assurance',
        'Revenue Intelligence',
        'Reverse Auction',
        'Reverse Logistics Group (RLG)',
        'Reynolds American Inc.',
        'Right To Be Forgotten',
        'Risk Retention',
        'RoadRunner Recycling Inc.',
        'Roadster',
        'RoboVent',
        'Rockbot',
        'Rocket Lawyer',
        'Rohde & Schwarz',
        'RouteOne',
        'Royal Commission into Aged Care Quality and Safety',
        'Royal Cup',
        'Royal Dutch Shell PLC',
        'Royal Oak Recycling',
        'SAP License Management',
        'SDL',
        'SECURE 2.0',
        'SLA Management',
        'SUEZ Group',
        'SaaS Governance',
        'Safe',
        'Sales Promotions',
        'Samsung Electronics',
        'Sanctions Screening',
        'Sartorius AG',
        'Satellite Automatic Identification System (AIS)',
        'Satellite Imagery',
        'Satellite Vessel Tracking',
        'Savills',
        'Schindler Holding Ltd.',
        'Schlumberger',
        'Schneider Electric',
        'Schrems II',
        'Schupan & Sons Inc.',
        'Seamless North America LLC',
        'Searchlight Security',
        'Seed to Sale',
        'Seeding',
        'Self-paced learning',
        'Sendoso',
        'Senior Care marketing',
        'Service Management Software',
        'ServiceChannel',
        'ServiceMaster, Inc.',
        'Servpro, Inc.',
        'Share of Wallet',
        'Sharing Economy',
        'Ship Mortgage',
        'Ship Software and IT Solutions',
        'Shipbroking',
        'Shipping Finance',
        'ShotSpotter',
        'Sign Repair',
        'Signage Effectiveness',
        'Simulation Software',
        'Single-family rentals (SFRs)',
        'SiriusXM',
        'Skip tracing',
        'Skybox Security',
        'Skytrac Systems Ltd.',
        'Sliding Glass Doors',
        'Small Business Networks',
        'Small and Medium Enterprise (SMB)',
        'Smarketing',
        'Smart Beta',
        'Smart Connected Products',
        'Smart Lighting Technology',
        'Smart Port',
        'Software-Defined Radio (SDR)',
        'Sophos',
        'Sound Masking',
        'South Pole',
        'Space Analytics',
        'Space Management',
        'Space Reservations',
        'Special Assets',
        'Speech Processing Solutions (SPS)',
        'Spire Vessel Tracking',
        'Sports Sponsorships',
        'Spotify',
        'Spotify Business',
        'Stack Overflow',
        'Stakeholder Management',
        'Standard work',
        'Starbucks',
        'Startups',
        'State / Local / Municipal',
        'State of Charge (SoC)',
        'Statement of Work (SOW)',
        'Statkraft',
        'Sterling Bay',
        'Stockholm Exergi',
        'Stranded Assets',
        'Strategic Materials, Inc.',
        'Student Engagement Platform',
        'Sublease',
        'Subsurface utility engineering',
        'Summit Carbon Solutions',
        'Suncor Energy',
        'Supra',
        'Sustainable Travel',
        'Swire Group',
        'Swiss Post',
        'Switch',
        'Synopsys',
        'System Administration',
        'Talent Agility',
        'Talkwalker',
        'Tax Automation',
        'Tax Credit',
        'Team Cymru',
        'Technic Inc.',
        'Technographics',
        'Technology Consulting',
        'Technology Investments',
        'Telecom Audit',
        'Teller Cash Recycler',
        'Tenable',
        'Tennessee Industrial Electronics, LLC (TIE)',
        'Terminal Operators',
        'TerraCycle, Inc.',
        'Texas Recycling, Inc.',
        'The Coca-Cola Company',
        'The Emmes Company, LLC',
        'The Great Firewall of China',
        'The Ontario Municipal Employees Retirement System (OMERS)',
        'The Procter & Gamble Company',
        'The Smart Cube',
        'Third Party Risk Assessment',
        'Third Party Risk Management Policy',
        'Thomas Scientific',
        'TikTok Shop',
        'Tillage',
        'Title Registration',
        'Togetherall',
        'Total Cost of Risk',
        'Total Rewards',
        'Tourism marketing',
        'Trackers',
        'Trade Compliance',
        'Trade Shows / Events',
        'Trade-In Titles',
        'Training Impact & Effectiveness',
        'Transferable Tax Credit',
        'Transifex',
        'Travel & Hospitality Loyalty',
        'Travel & Hospitality Translation Services',
        'Travel Agency',
        'Travel Discounts',
        'Travel Fraud',
        'Travel Insurance',
        'Travel Management',
        'Travel Marketing',
        'Travel Rewards',
        'Travel Time',
        'TreadReader',
        'Trend Micro',
        'TrueBlue',
        'TrueCar',
        'Twist Bioscience Corporation',
        'Tyson Foods, Inc.',
        'UAS Detection',
        'UL Solutions',
        'US Ecology, Inc.',
        'US Foods',
        'Uber Eats',
        'Ukraine Outsourcing',
        'Ultra High Definition Broadcast',
        'Unified ID 2.0',
        'Unilever (UL)',
        'UnionWare',
        'Universal EV Charging Station',
        'Usage-based billing',
        'User Identification',
        'Utilities Software',
        'Utility Computing',
        'Uyghur Forced Labor Prevention Act (UFLPA)',
        'VWR International',
        'Vacations',
        'Value Proposition',
        'Vandalism and Graffiti Cleanup',
        'Vaults',
        'Vehicle Remarketing',
        'Vehicle Valuations',
        'Vendor Document Collection',
        'Vendor Financial Health',
        'Vendor Issue Management',
        'Vendor Onboarding',
        'Vendor Privacy Risk',
        'Vendor Profiling',
        'Vendor Questionaire',
        'Vendor SOC Reports',
        'Vendor Termination',
        'Veolia Environnement S.A. (VIE) (VEOEY)',
        'Vessel AIS',
        'Vessel Monitoring',
        'Vessel Position Tracking',
        'Virtual Assistant',
        'Vistage Worldwide',
        'Visual instructions',
        'Vitac Corporation',
        'Voice Picking',
        'Volume Pricing Program',
        'Volvo VNR Electric',
        'WIN Waste Innovations',
        'Walgreens Boots Alliance, Inc.',
        'Waste Connections, Inc. (WCN)',
        'Waste Harmonics, LLC',
        'Waste Management (WM)',
        'Waste Pro USA',
        'Waste from Electrical and Electronic Equipment (WEEE)',
        'Water Industry',
        'Water Management',
        'WeWork',
        'Weapons Systems',
        'Weatherford',
        'Welocalize',
        'Wheelchair-Accessible Vehicles',
        'Whistleblowing Hotline',
        'Whistleblowing Services',
        'Winnie',
        'Winters Bros. Waste Systems',
        'Wire Fraud',
        'Word Processor',
        'World Economic Forum',
        'World Fuel Services Corporation (WFS)',
        'World-Check',
        'Xively',
        'Xtime',
        'Xylem Inc.',
        'Yamana Gold Inc. (AUY)',
        'Yard Management Software',
        'Zillow, Inc.',
        'Zscaler',
        'eClerx',
        'eDiscovery Software',
        'eSourcing',
        'eTendering',
        'gig economy',
        'iOFFICE',
        'photogrammetry',
        'vAuto'
      ])
    ]
  },
  {
    id: 112,
    label: 'Patient Management',
    children: [
      ...new Set([
        'Alarm Fatigue',
        'Biosimilar Biological Products',
        'Care Coordination',
        'Case Management',
        'Chronic Disease Management (CDM)',
        'Clinical Integration',
        'Clinical Services',
        'Consent Management',
        'Continuity of Care',
        'Digital Patient Intake',
        'Discharge Planning',
        'Environments for Aging',
        'Fall Detection',
        'Fall Prevention',
        'Functional Assessment',
        'Functional Movement Systems (FMS)',
        'Health iPASS',
        'Healthcare Effectiveness Data and Information Set (HEDIS)',
        'Hospice Care',
        'Hospital Consumer Assessment of Healthcare Providers and Systems (HCAHPS)',
        'Long Term Care (LTC)',
        'Managed Care',
        'Meaningful Use',
        'MedFusion',
        'Medical Errors and Prevention',
        'Medical Practice',
        'Medicare',
        'Medication Dispensing',
        'Medication Order',
        'Merge RIS',
        'Mild Cognitive Impairment (MCI)',
        'Non-Emergency Medical Transportation (NEMT)',
        'Patient Adherence',
        'Patient Care',
        'Patient Communication System',
        'Patient Engagement',
        'Patient Experiences',
        'Patient Identifier',
        'Patient Journey',
        'Patient Matching',
        'Patient Monitoring',
        'Patient Safety',
        'Patient Satisfaction',
        'Patient-Controlled Analgesia (PCA)',
        'Patient-Reported Outcomes (PROs)',
        'Patient/Medical Record',
        'Personal Health Records (PHR)',
        'Pharmaceutical Drugs',
        'Pharmaceuticals',
        'Pharmacy / Prescription Systems',
        'Physical Therapy',
        'Physician Quality Reporting System (PQRS)',
        'Physician Satisfaction',
        'Prescription Drug Monitoring Program (PDMP)',
        'Prescriptions',
        'Readmissions',
        'Respiratory',
        'Retail Pharmacies',
        'Smart Hospitals',
        'Social Determinants of Health (SDOH)',
        'Supplemental Nutrition Assistance Program (SNAP)',
        'Transplantation',
        'Utilization Management (UM)',
        'Wellness Programs'
      ])
    ]
  },
  {
    id: 113,
    label: 'Payroll & Comp',
    children: [
      ...new Set([
        'Automated Payroll',
        'Bonus Payments',
        'Compensation',
        'Compensation Administration',
        'Early Wage Access',
        'Employee Contribution',
        'Equal Pay / Comparable Worth',
        'Executive Compensation',
        'Global Payroll',
        'International Payroll Service',
        'Job Costing',
        'National Payroll Week (NPW)',
        'Online Time Clock',
        'Pay Advance',
        'Pay Card',
        'Pay On Demand',
        'Paychecks',
        'Payroll',
        'Payroll Management',
        'Payroll Outsourcing',
        'Payroll Software',
        'Professional Employer Organization (PEO)',
        'Salaried Employee',
        'Salary Benchmarking',
        'Salary Negotiation',
        'Salary Range',
        'Salary Sacrifice',
        'Salary Survey',
        'Wage Theft'
      ])
    ]
  },
  {
    id: 114,
    label: 'Performance',
    children: [
      ...new Set([
        'Constructive Feedback',
        'Demotion',
        'Discipline',
        'Employee Incentives',
        'Employee Performance',
        'Employee Recognition',
        'Employee Rewards',
        'Job Evaluation',
        'Performance Appraisal',
        'Performance Enablement',
        'Performance Improvement Plan (PIP)',
        'Performance Management',
        'Performance Measurement',
        'Poor Performance',
        'Productivity',
        'Promotion',
        'Timesheet',
        'Workload Management'
      ])
    ]
  },
  {
    id: 115,
    label: 'Personal Computer',
    children: [
      ...new Set([
        '2-in-1 PCs',
        'All-in-One PCs',
        'B2C Technology',
        'Blade Computer',
        'Desktop Computer',
        'Laptop',
        'Mini PC',
        'Panasonic Toughbook'
      ])
    ]
  },
  {
    id: 116,
    label: 'Personal Finance',
    children: [
      ...new Set([
        '401k',
        'Adverse Credit Mortgage',
        'Alternative Banking',
        'Alternative Lending',
        'Annual Percentage Rate (APR)',
        'Annuities',
        'Auto Loans',
        'Automated Investment Services (Robo-Advisors)',
        'Bridge Loan',
        'Buy-to-Let Mortgage',
        'CD (Certificate of Deposit)',
        'Capital Gains',
        'Charitable Giving',
        'Checking Account',
        'College Savings Plans',
        'Consumer Credit',
        'Coverdell Education Savings Account (ESA)',
        'Credit Card Fraud',
        'Credit Cards',
        'Credit Risk',
        'Creditworthiness',
        'Dealer Credit Application',
        'Debit Card',
        'Debt Management Plan',
        'Debt Relief',
        'Debt Settlement',
        'Debt-To-Income Ratio (DTI)',
        'Deferred Annuities',
        'Deposit Accounts',
        'Digital Lending',
        'Digital Mortgage',
        'Direct Deposit',
        'Emergency Fund',
        'Endowments',
        'Equity',
        'Estate Planning',
        'FICO Credit Score',
        'Family Financial Planning',
        'Financial Advisors',
        'Financial Security',
        'Financial Service Providers',
        'Financial Wellness',
        'Fixed Annuities',
        'Fixed Deposit',
        'Fixed, Determinable, Annual, Periodical (FDAP) Income',
        'Fixed-Rate Mortgage (FRM)',
        'Foreclosure',
        'Green Mortgage',
        'Health Savings Account (HSA)',
        'Home Equity Line of Credit (HELOC)',
        'Home Equity Loan',
        'Home Loan Refinancing',
        'In-Plan Income',
        'Individual Retirement Account (IRA)',
        'Investing',
        'Investment Strategy and Planning',
        'Later Life Lending',
        'Legacy Planning',
        'Loan Agreement',
        'Loans',
        'Managed Portfolios',
        'Mobile Banking',
        'Money Market',
        'Monthly Installments',
        'Mortgage',
        'Mortgage Fraud',
        'Multicurrency Accounts',
        'Mutual Funds',
        'Non-QM Loan',
        'Online Banking',
        'Online and Mobile Investing',
        'Overdraft Protection',
        'P2P Lending (Social Lending)',
        'Payment Security',
        'Personal Finance',
        'Portfolio Management',
        'Premium Financing',
        'Prepaid Debit Card',
        'Prepayment Penalty',
        'Recreation Loans (ATVs, Boats, RVs)',
        'Refinancing',
        'Registered Investment Advisor (RIA)',
        'Remote Deposit',
        'Required Minimum Distribution (RMD)',
        'Retirement Income',
        'Revolving Credit',
        'Rollover IRA',
        'Roth IRA',
        'SEP IRA / 401k',
        'Savings Account',
        'Secured Credit Cards',
        'Short Sale',
        'Specialist Lending',
        'Stable Value Fund',
        'Stocks',
        'Student Loans',
        'Target-Date Funds',
        'Tax Preparation',
        'Taxation of Annuities',
        'Traditional IRA',
        'Trusts',
        'Variable Annuities',
        'Wholesale Lending',
        'Wills',
        'Wire Transfer',
        'eChecking Accounts'
      ])
    ]
  },
  {
    id: 117,
    label: 'Personal Protective Equipment (PPE)',
    children: [
      ...new Set([
        'Active Fall Protection',
        'Anchorage Connector',
        'Construction Safety Helmets',
        'Equipment Failure',
        'Eye, Face and Head',
        'Fall Arrest',
        'Fall Protection',
        'Fall Restraint',
        'Foot Protection',
        'Freestanding Guardrail',
        'Guardrail',
        'Hand Protection',
        'Hearing Protection',
        'High-Visibility Safety Apparel',
        'Horizontal Lifeline Systems',
        'Industrial Respiratory Protection',
        'Lockout Products',
        'Mining Safety Equipment',
        'Nitrile Gloves',
        'Personal Protection Equipment',
        'Protective Clothing',
        'Respirators',
        'Rigid Rail Overhead Fall Protection',
        'Rooftop Crossover Platforms',
        'Rooftop Walkway with Guardrail',
        'Safe Access Solutions',
        'Safety Eyewear',
        'Safety Shower & Eyewash Station',
        'Safety Supplies',
        'Self Closing Gates',
        'Skylight & Hatch Railing Systems',
        'Spill Containment Products',
        'Traffic Safety Equipment',
        'Vertical Lifeline Systems'
      ])
    ]
  },
  {
    id: 118,
    label: 'Place of Work',
    children: [
      ...new Set([
        'Breakroom Furniture',
        'Breakroom Solutions',
        'Business Park',
        'Business Trip Compliance',
        'Commuting',
        'Corporate Housing',
        'Digital Employee Experience (DEX)',
        'Dynamic Workplace',
        'Employee Stress',
        'Facilities',
        'Hot Desking',
        'Hoteling',
        'Hybrid Workplace',
        'Industrial Park',
        'Meeting Rooms',
        'Modern Workplace',
        'Phone Booth',
        'Relocation',
        'Remote Working',
        'Team Workspace',
        'Telecommuting and Remote Workers',
        'User-Centric Workplace',
        'Warehouse',
        'Workplace Modernization'
      ])
    ]
  },
  {
    id: 119,
    label: 'Plasticware and Supplies',
    children: [
      ...new Set([
        'Cell Growth Medium',
        'Filtration',
        'Petri Dish',
        'Phenol Red',
        'Pipette',
        'Pleated Filter Elements',
        'Tubes',
        'Vials'
      ])
    ]
  },
  {
    id: 120,
    label: 'Policy & Culture',
    children: [
      ...new Set([
        'Absence Management',
        'Buddy Punching',
        'Corporate Culture',
        'Corporate Dining',
        'Corporate Wellness',
        'Cultural Adaptation',
        'Dynamic Case Management (DCM)',
        'Effective Practices',
        'Employee Involvement',
        'Employee Retention',
        'Employee Surveys',
        'Ethics',
        'Face-to-Face Conversation',
        'Flexible Working',
        'Full-Time Equivalent (FTE)',
        'Hours of Work',
        'Incentive Compensation',
        'Internships',
        'Organizational Culture',
        'Peer-based learning',
        'People Enablement',
        'People-centric',
        'Pipeline Management',
        'Policies and Practices',
        'Policy',
        'Policy Management',
        'Recognition and Rewards Platform',
        'Sick Leave',
        'Social Collaboration',
        'Team Communication',
        'Transportation Management',
        'Usage Policies',
        'Wellness',
        'Workation',
        'Work–Life Balance'
      ])
    ]
  },
  {
    id: 121,
    label: 'Printing',
    children: [
      ...new Set([
        'Binder-Jetting',
        'Catalog Printing',
        'Commercial Printing',
        'Custom Printing',
        'Digital Labels',
        'Digital Printing',
        'Enterprise Printing',
        'Envelope Printing',
        'Flexography',
        'High Volume Printing',
        'High Yield Toner',
        'High-Speed Inkjet',
        'In-Line Finishing',
        'In-Vehicle Infotainment (IVI)',
        'Ink Cartridge',
        'Inkjet Press',
        'Inkjet Printing',
        'Laser Printers',
        'Laser-Sintering',
        'Mobile Printing',
        'Narrow Web',
        'Offset Printing',
        'Photocopier',
        'Pre-press/Repro',
        'Print On Demand (POD)',
        'Print Quality',
        'Print Quality Management (PQM)',
        'Printer Cost Per Page',
        'Printer Fleet Management',
        'Printer Security',
        'Printing Supplies',
        'Publication Printing',
        'Refilled Cartridges',
        'Remanufactured Cartridges',
        'Satellite Constellation',
        'Security Printing',
        'Thermal Printing',
        'Toner Cartidge',
        'Transactional Printing',
        'UV Inkjet',
        'Variable Data'
      ])
    ]
  },
  {
    id: 122,
    label: 'Privacy',
    children: [
      ...new Set([
        'Consent Management Platform',
        'De-identification',
        'Identity Replacement Technology',
        'Microsoft Entra',
        'Privacy Policy',
        'Tokenization'
      ])
    ]
  },
  {
    id: 123,
    label: 'Product Development & QA',
    children: [
      ...new Set([
        'Adaptive Project Management and Reporting',
        'Agile Analytics',
        'Agile CMS',
        'Agile Software Development',
        'Agile Tools',
        'Agile Training',
        'Application Lifecycle Management',
        'Arena PLM',
        'Blind Benchmarking',
        'Blue-Green Deployment',
        'Central Location Test (CLT)',
        'Defect Tracking',
        'Defective Product',
        'Deployment Automation',
        'Digital Workflow',
        'Digital Workforce',
        'Earned Value Management (EVM)',
        'End-of-Life (EOL)',
        'Feature Flags',
        'Functional Testing',
        'Human-Centered Design',
        'Human-Machine Interface (HMI) Design',
        'In Home Usage Testing (IHUT)',
        'Iterative Prototyping',
        'Iterative Software Development',
        'Kanban',
        'Mean Time Between Failures (MTBF)',
        'Microsoft Most Valuable Professional (MVP)',
        'Minimum Viable Product (MVP)',
        'Nonprofit Software',
        'PRINCE2',
        'Planning and Design',
        'Product Configurators',
        'Product Data Management',
        'Product Development and Design',
        'Product Information Management',
        'Product Lifecycle Management',
        'Product Management',
        'Product Prototyping',
        'Project Collaboration',
        'Project Management',
        'Project Scheduling',
        'Proof of Concept (PoC)',
        'Quality Assurance',
        'Rapid Application Development (RAD)',
        'Rapid Prototyping',
        'Rational Unified Process (RUP)',
        'Recipe Management System',
        'Requirement Traceability',
        'Requirements Management',
        'Research and Development / Test',
        'Retrospectives',
        'Scaled Agile Framework (SAFe)',
        'Scrum Training',
        'Sensory Research',
        'Software Requirements',
        'Software Testing',
        'Stage Gate Process',
        'Technology Research',
        'Test Case Management',
        'Testing and Analysis',
        'Usability',
        'Usability Testing',
        'Waterfall',
        'White Box Testing',
        'Work Breakdown Structure (WBS)'
      ])
    ]
  },
  {
    id: 124,
    label: 'Productivity Software',
    children: [
      ...new Set([
        'Comma-Separated Values (CSV)',
        'File Sharing',
        'Office 365',
        'Presentation Applications',
        'Unified Messaging'
      ])
    ]
  },
  {
    id: 125,
    label: 'Professional Services',
    children: [
      ...new Set([
        'ACH Manager',
        'ACH Solutions',
        'Account Aggregation',
        'Account Updater',
        'Advisory Services',
        'Banking Core',
        'Brand Acquisition',
        'Broker-Dealer',
        'Brokerage Services',
        'Business Consulting Services',
        'Capital Advisory',
        'Credit Unions',
        'Deal Advisory',
        'Digital Consulting',
        'Finance Insurance',
        'Financial Planning',
        'Independent Broker-Dealer',
        'Independent Financial Practice',
        'Instant Card Issuance',
        'Markets and Markets',
        'Oil and Gas Consulting',
        'Open Architecture',
        'Outsourced CFO Services',
        'Personal Wealth Management',
        'Real Estate Consulting',
        'Same Day ACH'
      ])
    ]
  },
  {
    id: 126,
    label: 'Programming Languages',
    children: [
      ...new Set([
        '.NET',
        'ASP.NET',
        'AngularJS',
        'Aspect-Oriented Programming (AOP)',
        'Bash',
        'C#',
        'C++',
        'COBOL',
        'COBOL Conversion',
        'Cascading Style Sheets(CSS)',
        'Django',
        'Elixir',
        'Email Template',
        'Express.js',
        'Fortran',
        'GraphQL',
        'HTML 5',
        'Java',
        'Java Database Connectivity (JDBC)',
        'JavaScript',
        'Keyhole Markup Language (KML)',
        'MEAN',
        'MERN',
        'Node.js',
        'Object-Oriented Programming (OOP)',
        'Objective-C',
        'PHP',
        'Perl',
        'Pig',
        'Postgres',
        'PowerShell',
        'Programming Languages',
        'Query language',
        'React JS',
        'React Native',
        'Regular Expression',
        'Ruby',
        'Ruby on Rails',
        'SPARQL',
        'Scala',
        'Scratch',
        'Scripting',
        'Security Access Markup Language (SAML)',
        'Security Assertion Markup Language (SAML)',
        'Swift (Apple OS)',
        'VBScript',
        'XML',
        'XQuery'
      ])
    ]
  },
  {
    id: 127,
    label: 'Programs and Services',
    children: [
      ...new Set([
        'Active Shooter Preparedness',
        'Corporate Volunteer Programs',
        'Hiring Event',
        'Insourcing',
        'Management Consulting',
        'Offshoring',
        'Outsourcing',
        'Personnel Research / Survey Results',
        'Relocation Services',
        'Sustainability Programs'
      ])
    ]
  },
  {
    id: 128,
    label: 'Radiation Detection & Measurement',
    children: [
      ...new Set([
        'Dirty Bomb',
        'Dosimetry',
        'Environmental Monitoring',
        'Geiger Counter',
        'Neutron Generators',
        'Radiation Contamination',
        'X-Ray Diffraction',
        'X-Ray Fluorescence',
        'X-ray'
      ])
    ]
  },
  {
    id: 129,
    label: 'Recruitment, Hiring & Onboarding',
    children: [
      ...new Set([
        'Active candidate',
        'Applicant Tracking',
        'Applicant Tracking Systems (ATS)',
        'Background Checks',
        'Background Investigations',
        'Call Center Hiring',
        'Candidate Ghosting',
        'Candidate Relationship Management',
        'Candidate Sourcing',
        'Career page',
        'College Job Fair',
        'Contingent Staffing / Employment Agencies',
        'Contract Employee',
        'Cost-Per-Hire',
        'Cover Letters',
        'Credentialing / Profiling Systems',
        'Diverse candidate',
        'Employee Referrals',
        'Employee Screening',
        'Employee Welcome Kits',
        'Employee testimonial',
        'Employer of Record (EOR)',
        'Employment Agencies',
        'Employment Applications',
        'Employment Background',
        'Entry-Level Jobs',
        'Executive Search',
        'Fractional CMO',
        'Gen Z workforce',
        'Generation Z Recruiting',
        'Hiring',
        'Hiring Outsourcing',
        'Hiring Rules',
        'Hourly Hiring',
        'International HR Service',
        'Job Board',
        'Job Descriptions',
        'Job Fair',
        'Job Interview',
        'Job Market',
        'Job Posting',
        'Manufacturing Labor Skills Gap',
        'Millennial Workforce',
        'Offboarding',
        'Onboarding',
        'Online Recruiting',
        'Orientation',
        'Outsourced HR',
        'Passive candidate',
        'Pre-Employment and Employee Testing',
        'Quality of Hire',
        'Recruiting',
        'Recruiting / Career Management',
        'Recruiting Software / Services',
        'Recruiting Technology',
        'Recruitment Advertising',
        'Recruitment Cost',
        'Recruitment Marketing Solutions',
        'Recruitment Outsourcing',
        'References',
        'Remote onboarding',
        'Resume',
        'Resume Screening',
        'Sales Hiring',
        'Security Guard and Officer Services',
        'Student Recruitment',
        'Talent Acquisition',
        'Talent Supply',
        'Talent Supply Chain Management',
        'Virtual Recruiting Events',
        'Volume Recruiting'
      ])
    ]
  },
  {
    id: 130,
    label: 'Retail',
    children: [
      ...new Set([
        'Adidas AG',
        'Albertsons',
        'Aptos',
        'Arcadia Group',
        'Associated British Foods plc',
        'Barnes & Noble, Inc.',
        'Borderfree',
        'Brandboom',
        'Centric Planning',
        'Centric Pricing',
        'Centric Visual Boards',
        'Checkpoint Systems',
        'Continental Tyres',
        'Costco Wholesale Corporation',
        'DeSL',
        'Eyebobs',
        'Farm Fresh To You',
        'First Insight, Inc.',
        'Foresight Retail',
        'GameStop (GME)',
        'Home Depot (HD)',
        'Impact Analytics',
        'IndiaMART InterMESH Ltd',
        'Joor',
        'Kroger',
        'Kubix Link',
        'Le New Black',
        'LensCrafters',
        'Look Optic, LLC',
        'Luxottica Group S.p.A.',
        'National Retail Federation (NRF)',
        'NewStore',
        'NuORDER Inc.',
        'ORDRE',
        'Orion Software Inc',
        'Reece',
        'Retail Pro',
        'Rite Aid (RAD)',
        'ShopperTrak',
        'Sidecar',
        'SparkFun Electronics, Inc.',
        'Staples (SPLS)',
        'Stylitics Inc.',
        'Surefront',
        'Sysco Corporation',
        'Target Corporation',
        'Teamwork Commerce',
        'The Gap, Inc.',
        'Unbxd',
        'V12 Retail Finance',
        'Visionworks of America, Inc.',
        'Walmart Inc.',
        'Warby Parker',
        'Zenni Optical, Inc.'
      ])
    ]
  },
  {
    id: 131,
    label: 'Retail Management',
    children: [
      ...new Set([
        'Category Management',
        'Demand Forecasting',
        'Display Audits',
        'Merchandise Planning',
        'Multi Channel Inventory Management',
        'Retail Execution',
        'SKU Proliferation',
        'SKU Rationalization',
        'Store Transformation'
      ])
    ]
  },
  {
    id: 132,
    label: 'Retail Technology',
    children: [
      ...new Set([
        'Cart Abandonment',
        'Digital Retailing',
        'Endless Aisle',
        'Oracle Retail',
        'Retail Analytics',
        'Retail media network'
      ])
    ]
  },
  {
    id: 133,
    label: 'Robotics',
    children: [
      ...new Set([
        'Actuators',
        'Autonomous Driving Solutions',
        'CNC Automation',
        'Cognitive Robotics',
        'Collaborative Robots',
        'Drones',
        'Industrial Automation',
        'Industrial Robots',
        'Industrial Transformation',
        'Nanorobotics',
        'Robot Locomotion',
        'Robotic Sensing',
        'Robots',
        'Swarm Robotics',
        'Zero Moment Point'
      ])
    ]
  },
  {
    id: 134,
    label: 'Sales',
    children: [
      ...new Set([
        'Agile Sales',
        'Amazon Selling',
        'Ancillary Sales',
        'Asset Purchase Agreement (APA)',
        'At-Risk Customer',
        'B2B Inbound Sales',
        'B2B Sales Acceleration',
        'B2B Sales Cycle',
        'B2B Sales Transformation',
        'Buyer Needs',
        'Call Center',
        'Channel Enablement',
        'Channel Optimization',
        'Close rate',
        'Cold Calling',
        'Commissions',
        'Compensation Management Software',
        'Competitive Pricing',
        'Configure Price Quote (CPQ)',
        'Contact Management',
        'Contract Negotiations',
        'Contract Renewal',
        'Conversational Sales',
        'Conversion',
        'Cost of sales',
        'Cross-Selling',
        'Customer Service Best Practices',
        'Data Driven Sales',
        'Deal Review',
        'Digital Sales',
        'Discovery Call',
        'Dynamic Discounting',
        'Dynamic Negotiations',
        'Dynamic Pricing',
        'Effective Selling',
        'End-to-End Ecommerce',
        'Fast-Moving Consumer Goods (FMCG)',
        'Group Purchasing Organizations (GPOs)',
        'Guided Selling',
        'Identity Resolution',
        'In-Market Accounts',
        'Inbound Sales Funnel',
        'Indirect Sales',
        'Key Account Management',
        'Lifecycle Pricing',
        'MEDDIC',
        'Market Day Supply',
        'Market Development Funds (MDF)',
        'Markups',
        'Merchandising',
        'Mid-Funnel Sales Content',
        'Mobile Sales Enablement',
        'Money Back Guarantee',
        'Mutual Action Plan',
        'OTE (On-target Earnings)',
        'Omnichannel Experience',
        'Online Merchandising',
        'Online Sales',
        'Outbound Sales',
        'Outcome-Based Selling',
        'Pipeline Marketing',
        'Pipeline reviews',
        'Preference Management',
        "President's Club",
        'Product Investment',
        'Product Launch',
        'Product Manager',
        'Product Planning',
        'Product Requirements',
        'Promotional Items',
        'Propensity Score',
        'Proposal / Quote Generation',
        'Purchase and Sale Agreement (PSA)',
        'Quote-to-Cash',
        'Real-time Trigger',
        'Remote Sales',
        'Renewal Automation',
        'Request for Proposal (RFP) Automation',
        'Request for Proposal (RFP) Software',
        'Retail Management',
        'Revenue Growth Management',
        'Revenue Operations (RevOps)',
        'Route-to-Market',
        'SPIN Selling',
        'Sales Acceleration',
        'Sales Analytics',
        'Sales Automation',
        'Sales Call',
        'Sales Channels',
        'Sales Coaching',
        'Sales Commissions',
        'Sales Compensation',
        'Sales Content Effectiveness',
        'Sales Content Management',
        'Sales Content Strategy',
        'Sales Conversion Rate',
        'Sales Cycle',
        'Sales Cycle Optimization',
        'Sales Data',
        'Sales Development',
        'Sales Effectiveness',
        'Sales Enablement',
        'Sales Enablement Software',
        'Sales Engagement',
        'Sales Excellence',
        'Sales Force Effectiveness',
        'Sales Forecasting',
        'Sales Funnel',
        'Sales Goals',
        'Sales Growth',
        'Sales Incentives',
        'Sales Intelligence',
        'Sales Kit',
        'Sales Leads',
        'Sales Management',
        'Sales Management Training',
        'Sales Mapping',
        'Sales Messaging',
        'Sales Methodologies',
        'Sales Model',
        'Sales Onboarding',
        'Sales Operations',
        'Sales Order',
        'Sales Organization',
        'Sales Outsourcing',
        'Sales Performance',
        'Sales Performance Management',
        'Sales Performance Metrics',
        'Sales Pipeline',
        'Sales Plan',
        'Sales Playbooks',
        'Sales Portal',
        'Sales Process',
        'Sales Process Automation',
        'Sales Productivity',
        'Sales Proposal Automation Software',
        'Sales Proposals & Quotes',
        'Sales Prospecting',
        'Sales Readiness',
        'Sales Report',
        'Sales Revenue',
        'Sales Software',
        'Sales Strategy',
        'Sales Structure',
        'Sales Territory',
        'Sales Territory Mapping',
        'Sales Training',
        'Sales Transformation',
        'Sales and Operations Planning (S&OP)',
        'Sales and marketing alignment',
        'Sales quota',
        'Social Selling',
        'Statement of Work Software',
        'Team Cadences',
        'Team Sequences',
        'Territory Management',
        'Territory Planning',
        'Third-Party Vendors',
        'Total Addressable Market',
        'Trade Promotions Management',
        'Trade Spend Optimization',
        'Trade-In Program',
        'Upselling',
        'Value-Added Reseller (VAR)',
        'Vehicle Auction',
        'Web Strategy',
        'eCommerce'
      ])
    ]
  },
  {
    id: 135,
    label: 'Search Engine',
    children: [
      ...new Set([
        'Elasticsearch',
        'Search Engines'
      ])
    ]
  },
  {
    id: 136,
    label: 'Search Marketing',
    children: [
      ...new Set([
        'Enterprise Search',
        'International Search Engine Optimization (SEO)',
        'Internet Search',
        'Keywords',
        'Multilingual SEO Services',
        'Search Engine Marketing (SEM)',
        'Search Engine Optimization (SEO)',
        'Search Retargeting'
      ])
    ]
  },
  {
    id: 137,
    label: 'Security',
    children: [
      ...new Set([
        'AS2',
        'AWS Inspector',
        'Access and Information Protection',
        'Account Takeover',
        'Active Cyber Defense',
        'Active Directory Federation Services (ADFS)',
        'Adaptive Access',
        'Address Space Layout Randomization (ASLR)',
        'Advanced Persistent Threat (APT)',
        'Advanced Threat Protection (ATP)',
        'Agentless Security',
        'Alarm Management',
        'Alarm Monitoring',
        'Alert Management',
        'Anti Spam',
        'Anti Spyware',
        'Anti Virus',
        'Anti-spoofing',
        'Apache Tomcat',
        'AppLocker',
        'Application Security',
        'Application Security Posture Management (ASPM)',
        'Attack Surface',
        'Attack Vector',
        'Azure Network Watcher',
        'Azure Sphere',
        'Backdoor',
        'Blacklist',
        'Blue Light Emergency Phones',
        'Blue Team',
        'Body-Worn Cameras',
        'Border Security Monitoring',
        'Bot Attacks',
        'Bot Management',
        'Botnet',
        'Breach and Attack Simulation',
        'Browser Isolation',
        'Brute Force Attacks',
        'Card Security Code (CSC)',
        'Card Verification Value (CVV)',
        'Carding',
        'Certified Information Systems Security Professional (CISSP)',
        'Check Point Software Technologies',
        'Cisco Secure Access Service Edge (SASE)',
        'Clickjacking',
        'Cloud Based Access Control',
        'Cloud Infrastructure Entitlement Management (CIEM)',
        'Cloud Infrastructure Security',
        'Cloud Workload Security',
        'Common Access Card (CAC)',
        'Common Criteria (CC)',
        'Common Vulnerabilities and Exposures (CVE)',
        'Computer Access Security Broker (CASB)',
        'Computer Hacking',
        'Computer Virus',
        'Conficker',
        'Content Disarm and Reconstruction',
        'Content Threat Removal',
        'Corporate Reputation Protection',
        'Corporate Security',
        'Counter-Drone Technology',
        'Credential Stuffing',
        'Crimeware',
        'Cross-Site Scripting',
        'Cryptography',
        'Cryptojacking',
        'Cyber & Intelligence',
        'Cyber Asset Attack Surface Management',
        'Cyber Crime Investigation',
        'Cyber Maturity Model Certification (CMMC)',
        'Cyber Range',
        'Cyber Resilience Act (CRA)',
        'Cyber Resiliency',
        'Cyber Risk Management',
        'Cyber Risk Quantification',
        'Cyber Security',
        'Cyber Security Framework',
        'Cyber Threats',
        'Cyber Vault',
        'Cyberattack',
        'Cybercrime',
        'Cybersecurity Regulation',
        'Cyberstalking',
        'Cyberwarfare',
        'DDoS Mitigation',
        'Dashlane',
        'Data Breach',
        'Data Center Security',
        'Data Encryption',
        'Data Execution Prevention (DEP)',
        'Data Exfiltration',
        'Data Masking',
        'Data Protection Officer (DPO)',
        'Data Security',
        'Data Theft',
        'Defense In Depth',
        'DevSecOps',
        'Device Fingerprinting',
        'Device Security',
        'Digital Identity Management',
        'Distributed Denial-of-Service (DDoS)',
        'Dridex',
        'Drift as Code',
        'ERP Security',
        'Electronic Identity Verification (eIDV)',
        'Email Verification',
        'Embedded System Security',
        'Encryption',
        'Encryption Key Management',
        'End User Security',
        'End-to-end security',
        'Endpoint Data Loss Prevention',
        'Endpoint Detection and Response',
        'Endpoint Management',
        'Endpoint Protection Platform (EPP)',
        'Endpoint Security',
        'Enterprise Firewall',
        'Entitlement Management',
        'Executive Protection',
        'Executive Threat Assessment',
        'Exploit Kit',
        'Extended Detection and Response (XDR)',
        'Fake Account Creation',
        'File Sanitation',
        'Firewall',
        'Fortinet Security Fabric',
        'Fraud Detection and Prevention',
        'Fraud Protection',
        'Frictionless Authentication',
        'GPS Spoofing',
        'Google Authenticator',
        'Google Chronicle',
        'Gunshot Detection',
        'Hardrive Encryption',
        'Hardware Security Module (HSM)',
        'Hash Function',
        'Hijacking',
        'Homomorphic Encryption',
        'Honeypot',
        'Honeypot Cyber Security',
        'Honeytoken',
        'IBM Security QRadar',
        'IBM Security SOAR',
        'IEC 61850',
        'IEC 62304',
        'IOMT Security',
        'IPSec',
        'ISO 13485:2016',
        'ISO/IEC 27001:2013',
        'Identity Access Management (IAM)',
        'Identity Governance and Administration (IGA)',
        'Identity Management',
        'Identity Proofing',
        'Identity Providers (IdP)',
        'Identity Theft',
        'Identity Threat Detection & Response (ITDR)',
        'Incident & Breach Management',
        'Information Security',
        'Insider Threat',
        'Integrated Security System',
        'Internet Fraud',
        'Internet Security',
        'Intrusion Detection',
        'Intrusion Prevention',
        'IoT Security',
        'Kerberos',
        'Keystroke Logging',
        'Knowledge-Based Authentication (KBA)',
        'Least Privilege',
        'Live Scan Fingerprinting',
        'Loyalty Point Fraud',
        'MD5 Hash',
        'MITRE ATT&CK',
        'Malicious Domains',
        'Malware Analysis',
        'Malware Attacks',
        'Malware Detection',
        'Malware Threats',
        'Malware and Vulnerabilities',
        'Man in the Middle Attack',
        'Managed Detection and Response (MDR)',
        'Managed Security Incident and Event Management (SIEM)',
        'Managed Security Services',
        'Medical Device Security',
        'Message Digest 5 (MD5)',
        'Microsegmentation',
        'Microsegmentation Software',
        'Multi-Cloud Security',
        'Multifactor Authentication',
        'Mutual Transport Layer Security (mTLS)',
        'NIS2',
        'Network Detection and Response (NDR)',
        'Next-Generation Firewall (NGFW)',
        'One Time Password',
        'Open-Source Intelligence (OSINT)',
        'OpenID',
        'OpenSSL',
        'Operational Technology (OT) Cyber Security',
        'Out-of-Band Authentication',
        'PCI Compliance',
        'Password Management',
        'Password Protection',
        'Passwordless Identity',
        'Penetration Testing',
        'Perimeter Intrusion Detection System (PIDS)',
        'Phishing',
        'Physical Security',
        'Playbook',
        'Point-to-Point Encryption (P2PE)',
        'Positive Pay',
        'Potentially Unwanted Program (PUP)',
        'Private Keys',
        'Privileged Access Management (PAM)',
        'Privileged Identity management (PIM)',
        'Professionals Verification',
        'Protective Intelligence',
        'Purple Team',
        'Quantum Cryptography',
        'Ransomware',
        'Ransomware as a Service (RaaS)',
        'Red Team',
        'Remediation',
        'Remediation as Code',
        'Remote Access Trojan (RAT)',
        'Resilient Control System',
        'Retina Network Security Scanner',
        'Role-Based Access Control (RBAC)',
        'Runtime Application Self-Protection (RASP)',
        'SAP Vulnerabilities',
        'SIM Swap',
        'SOC 2',
        'SSL VPN',
        'SYN Flood',
        'SaaS Security Posture Management (SSPM)',
        'Same Origin Policy (SOP)',
        'Sandboxing',
        'Scalper Bot',
        'Scareware',
        'SecOps',
        'Secrets Management',
        'Secure Access Service Edge (SASE)',
        'Secure File Transfer',
        'Secure Service Edge (SSE)',
        'Secure Sockets Layer (SSL)',
        'Secure Supervisory Control and Data Acquisition (SCADA)',
        'Secure Voice',
        'Secure Web Gateway (SWG)',
        'Security Analytics',
        'Security Architecture',
        'Security Automation',
        'Security Awareness Training',
        'Security Benchmarking',
        'Security Breaches',
        'Security Camera',
        'Security Compliance',
        'Security Configuration',
        'Security Consulting',
        'Security Event',
        'Security Forensics',
        'Security Hygiene',
        'Security Information and Event Management (SIEM)',
        'Security Intelligence',
        'Security Management',
        'Security Operations',
        'Security Operations Center (SOC)',
        'Security Orchestration',
        'Security Orchestration Automated Response (SOAR)',
        'Security Patches',
        'Security Policies',
        'Security Questionnaire',
        'Security Solutions',
        'Security Standards Council',
        'Security Storage',
        'Security Threats',
        'Security Tools',
        'Security Validation',
        'Security as Code',
        'Security as a Service',
        'Sensitive Data',
        'Server Farms',
        'Shadow IT',
        'Single Digital Identity',
        'Single Sign On (SSO)',
        'Smishing',
        'Software Composition Analysis',
        'Software Defined Perimeter (SDP)',
        'Software Supply Chain Security',
        'SolarWinds Hack',
        'Space Traffic Management (STM)',
        'Spear Phishing',
        'Spyware',
        'Steganography',
        'Strong Authentication',
        'Strong Encryption',
        'Student Verification',
        'Suricata',
        'Synthetic Fraud',
        'Synthetic ID',
        'Tactics, Techniques and Procedures (TTPs)',
        'Technical Surveillance Countermeasures (TCSM)',
        'Technology Security',
        'Third Party Risk Management Software',
        'Threat Actor',
        'Threat Detection',
        'Threat Intelligence',
        'Threat Prevention',
        'Transparent Data Encryption (TDE)',
        'Transport Layer Security (TLS)',
        'Two-Factor Authentication',
        'Umbrella',
        'Unified Threat Management',
        'Unified endpoint management (UEM)',
        'Unmanned Traffic Management (UTM)',
        'Urgent/11',
        'User Account Control (UAC)',
        'User Behavior Analytics (UBA)',
        'User and Entity Behavior Analytics (UEBA)',
        'Vendor Risk Management Lifecycle',
        'Vendor Risk Monitoring',
        'Vendor Security Assessment (VSA)',
        'Voltage SecureData Enterprise',
        'Vulnerability Assessment',
        'Vulnerability Management',
        'Vulnerability Scanning',
        'Web Application Firewall (WAF)',
        'Web Application Security',
        'Web Filter',
        'Web Scraping',
        'Website Defacement',
        'Whitelisting',
        'Workbook',
        'Worms',
        'Zeek',
        'Zero Trust',
        'Zero-Day Attack',
        'Zero-Day Threat',
        'eIDAS'
      ])
    ]
  },
  {
    id: 138,
    label: 'Servers',
    children: [
      ...new Set([
        'AI Servers',
        'Application Server',
        'Bare Metal Server',
        'Blade Servers',
        'CPU Usage',
        'Capacity Requirement Planning (CRP)',
        'Capacity Utilization',
        'Clustering',
        'Communication Server',
        'Cyber Threat Hunting',
        'Dedicated Hosting',
        'Dedicated IP Address',
        'Dedicated Server Hosting',
        'Dedicated Servers',
        'Directory Servers',
        'Entry-Level Servers',
        'Exchange Server',
        'Fax Server',
        'File Servers',
        'FileZilla',
        'Game Server',
        'Gunicorn',
        'HAProxy',
        'HPE ProLiant',
        'Incident Response',
        'Index Servers',
        'Infrastructure Automation',
        'Infrastructure Deployment',
        'Internet Information Services (IIS)',
        'Jenkins',
        'Linux Servers',
        'Load Balancing',
        'Media Server',
        'Microserver',
        'Microsoft Team Foundation Server (TFS)',
        'Proxy Server',
        'Rack Servers',
        'Reverse Proxy',
        'Search Servers',
        'Server Automation',
        'Server Environments',
        'Server Failure',
        'Server Hardware',
        'Server Hosting',
        'Server Infrastructure',
        'Server Management',
        'Server Migration',
        'Server Performance',
        'Server Power',
        'Server Provisioning',
        'Server Resources',
        'Server Security',
        'Server Software',
        'Servers',
        'System Center Configuration Manager (SCCM)',
        'Terminal Server',
        'Tower Servers',
        'Virtual Hosting'
      ])
    ]
  },
  {
    id: 139,
    label: 'Social',
    children: [
      ...new Set([
        'Amazon DynamoDB',
        'Cultivate',
        'DSMN8',
        'Digital Engagement',
        'Enterprise Social Networking',
        'EveryoneSocial',
        'Flickr',
        'Flipboard',
        'FourSquare',
        'Global Social Media Marketing',
        'Google Groups',
        'Google+',
        'Hashtag',
        'Hootsuite',
        'Influenster',
        'Instagram',
        'Integrated Social Marketing',
        'LinkedIn',
        'LinkedIn Marketing Solutions (LMS)',
        'LinkedIn Outreach',
        'Meetup',
        'MySpace',
        'Online Meetings',
        'Pinterest',
        'Podcasting',
        'Polymail',
        'Qzone',
        'Reddit',
        'SEO/SEM Retargeting',
        'Sina Weibo (WB)',
        'Skype',
        'Snapchat',
        'Social Activation',
        'Social Analytics',
        'Social Business',
        'Social Content and Apps',
        'Social Engagement',
        'Social Graph',
        'Social Listening',
        'Social Media',
        'Social Media Intelligence',
        'Social Media Marketing',
        'Social Media Monitoring',
        'Social Media Sharing',
        'Social Networking',
        'Social Publishing',
        'Social Retargeting',
        'Social Verification',
        'SocialFlow',
        'Sprinklr',
        'The Michigan Economic Development Corporation (MEDC)',
        'Tumblr',
        'Twitter (TWTR)',
        'Vine',
        'Virtual Meeting',
        'WhatsApp',
        'WordPress',
        'Yammer',
        'Yelp'
      ])
    ]
  },
  {
    id: 140,
    label: 'Software Engineering',
    children: [
      ...new Set([
        '2D Animation Software',
        '3D Animation Software',
        'AD Delegation',
        'Access Control',
        'Accessibility Testing Tools',
        'Agile Management',
        'Agile Testing',
        'Apache Superset',
        'Application Delivery',
        'Application Development',
        'Application Migration',
        'Application Performance',
        'Application Release Automation',
        'Argo Project',
        'Assembler',
        'Attribute-Based Access Control (ABAC)',
        'Automation Testing',
        'Azure DevOps Server',
        'Backend as a Service (BaaS)',
        'Bugsnag',
        'Build Management',
        'CI/CD',
        'Canary Mode',
        'Ceph',
        'Chaos Engineering',
        'Closed Platforms',
        'Cocos2d',
        'Code Refactoring',
        'Code Search',
        'Code Signing',
        'Codeless Test Automation',
        'Collaboration Software',
        'Command Line Interface',
        'Composable Commerce',
        'Composable Technology',
        'Compute',
        'Computer Science',
        'Configuration Files',
        'Configuration Management',
        'Configuration Options',
        'Contact Management Software',
        'Container Monitoring',
        'Continuous Delivery',
        'Continuous Integration',
        'Cross-Platform Development',
        'Custom Software Development',
        'DORA Metrics',
        'Debugging',
        'Debugging tools',
        'Development Operations (Devops)',
        'Embedded Software',
        'Emulator Testing',
        'Error Handling',
        'Event-Driven Architecture (EDA)',
        'Flutter',
        'Framework',
        'GNU General Public License',
        'Game Development',
        'Git',
        'GitHub Actions',
        'Graphical User Interface (GUI)',
        'Graphite',
        'Hackathon',
        'In-House Development',
        'In-Memory Data Grid (IMDG)',
        'Independent Software Vendor (ISV)',
        'Integrated Development Environments',
        'JFrog',
        'Jboss',
        'Knowledge Base Software',
        'Knowledge Management Software',
        'Laravel',
        'Log Files',
        'Low-Code',
        'Manual Testing',
        'Migration',
        'Mission Assurance',
        'Mobile App Development',
        'Mobile Development Tools',
        'Model–View–Controller (MVC)',
        'Multitenancy',
        'Natural Language Processing',
        'Neptune',
        'No-Code Publishing',
        'Open JDK',
        'Open Platforms',
        'Open Policy Agent (OPA)',
        'Open Source',
        'OpenLDAP',
        'Over-the-Air (OTA)',
        'PTC ThingWorx',
        'Password Reset',
        'Password Vault',
        'Patch Management',
        'Performance Engineering',
        'Portal Software',
        'Puppet',
        'Quality Engineering',
        'Quote And Proposal Software',
        'Quote Software',
        'RStudio',
        'Real Device Cloud',
        'Revision Management',
        'Robot Software',
        'Root Cause Analysis (RCA)',
        'SHA-256 (Secure Hash Algorithm)',
        'Salesforce Backup and Recovery',
        'Salesforce DevOps',
        'Scrum of Scrums',
        'Sentry.io',
        'Serialization',
        'Serverless Architecture',
        'Service Oriented Architecture (SOA)',
        'Siemens Solid Edge',
        'Siemens Xcelerator',
        'Single Point Of Failure (SPOF)',
        'Single Tenancy',
        'Site Reliability Engineering (SRE)',
        'Snowpark',
        'Software Asset Management',
        'Software Bill of Materials (SBOMs)',
        'Software Binaries',
        'Software Components',
        'Software Configuration Management',
        'Software Deployment',
        'Software Design',
        'Software Developers',
        'Software Development',
        'Software Development Kit',
        'Software Development Lifecycle',
        'Software Migration',
        'Software Quality',
        'Software Testing Outsourcing',
        'Software Widget',
        'Software of Unknown Provenance (SOUP Software)',
        'Software-Defined Infrastructure',
        'Source Code Analysis',
        'Source Code Control',
        'Sourcegraph',
        'Sparkplug',
        'Storm Spout',
        'Sysdig',
        'Syslog',
        'System Configuration (SC)',
        'Systems Development Life Cycle (SDLC)',
        'Talent Management Software',
        'Task Management',
        'Technical debt',
        'Test Orchestration',
        'Travis CI',
        'Unified Modeling Language (UML)',
        'User Interface (UI)',
        'Version Control',
        'VersionOne',
        'Web Application Development',
        'Zabbix'
      ])
    ]
  },
  {
    id: 141,
    label: 'Staff Administration',
    children: [
      ...new Set([
        'Biometric Time Clock',
        'Confidential Information',
        'Contingent Staffing',
        'Contingent Workers',
        'Contingent Workforce Management',
        'Employee Communications',
        'Employee Data',
        'Employee Scheduling',
        'Employee Scheduling Software',
        'Enrollment Management',
        'Extended Workforce System',
        'FTE Reduction',
        'Freelancer',
        'Frontline Workforce Management',
        'Full-Time Employee',
        'HR Metrics',
        'Independent Contractors',
        'Investigations',
        'Lone Worker',
        'Nearshore Software Development',
        'Nearshoring',
        'Offshore Software Development',
        'On-Call Scheduling',
        'Open Enrollment',
        'Part-Time Employees',
        'Personnel / HR Management',
        'Personnel Administration',
        'Staff Management',
        'Staffing',
        'Student Information System (SIS)',
        'Time Management',
        'Time Tracking',
        'Time and Attendance',
        'Time-Keeping',
        'Work Stoppages',
        'Workforce Productivity'
      ])
    ]
  },
  {
    id: 142,
    label: 'Staff Departure',
    children: [
      ...new Set([
        'Exit Interviews',
        'Job Satisfaction',
        'Layoff',
        'Leave Management',
        'Severance Pay',
        'Succession',
        'Succession Planning',
        'Termination',
        'Turnover'
      ])
    ]
  },
  {
    id: 143,
    label: 'Standards & Regulatory',
    children: [
      ...new Set([
        'Arc Flash Study',
        'Asset Criticality',
        'Asset Hierarchy',
        'Asset Survey',
        'Ballast Water Management',
        'Biocompatibility Testing',
        "Brexit's Impact on Ports",
        'CHIPS and Science Act',
        'California Rule 21',
        'China Restriction of Hazardous Substances (RoHS)',
        'Classification Society',
        'Climate Pledge',
        'Conflict Minerals',
        'Customs Clearance',
        'DNP3',
        'DOT Compliance',
        'DOT Inspections',
        'Drone Inspection',
        'ESG Advisory Services',
        'EU Arctic Policy',
        'Elevator Inspection',
        'Elevator Maintenance',
        'Elevator Phones',
        'Elevator Services',
        'Emergency Spill Response',
        'Energy Efficiency Existing Ship Index (EEXI)',
        'Energy Law',
        'Environment, Health and Safety (HSSE)',
        'Environmental Consultants',
        'Environmental Impact',
        'Environmental Law',
        'Environmental Liability',
        'Equipment as a Service (EaaS)',
        'Explosion Protection',
        'Explosion proof',
        'FMCSA Compliance',
        'Fall Risk Assessments',
        'Flag State Control',
        'Fuel Levy',
        'Grounding systems',
        'Groundwater monitoring',
        'Halogen-Free',
        'Hazardous Waste Management',
        'Hydrology',
        'IATF 16949:2016',
        'IMO 2020',
        'IMO Polar Code',
        'ISA-88',
        'ISO 14001:2015',
        'ISO 22000:2018',
        'ISO 22301:2019',
        'ISO 45001:2018',
        'ISO 50001:2018',
        'ISO/IEC 20000-1:2018',
        'In Vitro Diagnostic Regulation (IVDR)',
        'Incident Command System (ICS)',
        'Infrared (IR) Inspection',
        'International Documentation',
        'International Electrotechnical Commission (IEC)',
        'International Energy Conservation Code',
        'Intrinsic safety (IS)',
        'Inventory of Hazardous Materials (IHM)',
        'Labeling Regulations',
        'Land contamination',
        'Life Cycle Assessment (LCA)',
        'Low Carbon Fuel Standard',
        'Machine Safety',
        'Marine Casualty',
        'Maritime Labour Convention',
        'Maritime Regulation',
        'Maritime Safety',
        'Master Planning',
        "Mechanic's Lien",
        'Medical Device Regulations (MDR)',
        'Monitoring, Reporting and Verification (MRV)',
        'Multi-Point Inspection',
        'Nature-Based Solutions (NBS)',
        'Net Metering',
        'Net Metering 3.0',
        'North American Arctic Policy',
        'Operator Care',
        'Photovoltaic (PV) system performance',
        'Port Authority',
        'Port State Control',
        'Poseidon Principles',
        'Power Line Inspection',
        'Process Safety',
        'Refrigeration Servicing',
        'Renewable Fuel Standard',
        'Responsible Recycling (R2)',
        'Restricted Substances',
        'Restriction of Hazardous Substances (RoHS)',
        'Safety Signs',
        'Sales & Operational Execution (S&OE)',
        'Science Based Targets initiative (SBTi)',
        'Ship Registration',
        'Site Survey',
        'Smelter Verification',
        'Standards of Training, Certification, and Watchkeeping (STCW)',
        'Sustainable Groundwater Management Act (SGMA)',
        'Total Waste Management',
        'UL 1741',
        'Utility Inspection',
        'Zero Carbon Shipping'
      ])
    ]
  },
  {
    id: 144,
    label: 'Storage',
    children: [
      ...new Set([
        'All Flash Array',
        'All-flash storage',
        'Archiving',
        'Capacity Management',
        'Classification',
        'Compressors',
        'Consolidate NAS Footprint',
        'Content / Document Management',
        'Data Storage',
        'Data Warehouse',
        'Direct Attached Storage (DAS)',
        'Disk Storage',
        'Disk-Based Backup and Storage / RAID',
        'Distributed File System',
        'Distribution and Storage',
        'Enterprise Storage Solutions',
        'External Hard Drive',
        'External Storage',
        'Fibre Channel over Ethernet (FCoE)',
        'File Classification',
        'Flash Drive',
        'Flash Storage',
        'Full Disk Encryption',
        'HashiCorp Vault',
        'Hot Storage',
        'Inline Deduplication',
        'Input/Output Operations Per Second (IOPS)',
        'Master Boot Record (MBR)',
        'Memory Card',
        'NAND Flash Memory',
        'Optical Storage Media',
        'Random Access Memory (RAM)',
        'Red Hat Ceph Storage',
        'SSD Storage',
        'Sample Libraries',
        'Scale-Out Storage',
        'Shared Drive',
        'Shift-Left Security',
        'Solid State Drive (SSD)',
        'Storage Area Networks (SAN)',
        'Storage Capacity',
        'Storage Capacity Management',
        'Storage Consolidation',
        'Storage Devices',
        'Storage Hardware',
        'Storage Management',
        'Storage Networking',
        'Storage Performance',
        'Storage Resources',
        'Storage Security',
        'Storage Software',
        'Storage Solutions',
        'Storage Virtualization',
        'Storage as a Service',
        'Tape Drives and Libraries',
        'Team Drives',
        'Tiered Storage',
        'Windows File Server Migration'
      ])
    ]
  },
  {
    id: 145,
    label: 'Strategy & Analysis',
    children: [
      ...new Set([
        'Acquisition Strategy',
        'Ad Viewability',
        'Advocacy Marketing',
        'Alliance Marketing',
        'Analyst Relations',
        'Attention/Engagement Analytics',
        'Attribution Models',
        'Audience Measurement',
        'Audience Segmentation',
        'Benchmarking',
        'Brand Storytelling',
        'Click-Through Rate (CTR)',
        'Clickstream Analytics',
        'Co-Registration',
        'Communications Strategy',
        'Competitive Content Analysis',
        'Concept Testing',
        'Contextual Targeting',
        'Conversion Rate Optimization (CRO)',
        'Cost Per Action (CPA)',
        'Cross-Cultural Marketing',
        'Customer Acquisition Cost (CAC)',
        'Customer Advisory Board (CAB)',
        'Customer Analytics',
        'Data Collaboration',
        'Digital Strategy',
        'Display Advertising Metrics',
        'Distributed Marketing',
        'E-Business Strategy',
        'Economic Growth',
        'Enterprise Feedback Management (EFM)',
        'Global Marketing',
        'Global Procurement',
        'Go to Market',
        'Hyperlocal',
        'Hyperpersonalization',
        'In-Store Analytics',
        'Intent Marketing',
        'Key Opinion Leader (KOL)',
        'Loyalty Analytics',
        'Loyalty Marketing',
        'Magic Quadrant (MQ)',
        'Market Development',
        'Market Entry Strategy',
        'Market Expansion',
        'Market Intelligence',
        'Market Penetration',
        'Market Research',
        'Market Segmentation',
        'Market Share',
        'Marketing Analytics',
        'Marketing Attribution',
        'Marketing Budgets',
        'Marketing Challenges',
        'Marketing Dashboards',
        'Marketing Ecosystem',
        'Marketing Effectiveness',
        'Marketing Initiatives',
        'Marketing KPI',
        'Marketing Management',
        'Marketing Messaging',
        'Marketing Mix',
        'Marketing Mix Modeling',
        'Marketing Operations',
        'Marketing Optimization',
        'Marketing Performance',
        'Marketing Performance Measurement',
        'Marketing Plans',
        'Marketing Research',
        'Marketing Resource Management',
        'Marketing Strategy',
        'Marketing Tips',
        'Marketing Workflow',
        'Measure Impact',
        'Media Planning',
        'Multi-level Marketing',
        'Multicultural Marketing',
        'Omnichannel Analytics',
        'Operational Excellence',
        'Paid Search',
        'Partnership Marketing',
        'Precision Marketing',
        'Pricing Strategy',
        'Procure-to-Pay',
        'Product Strategy',
        'Product/Market Fit (PMF)',
        'Proof of Play',
        'Purchase Frequency',
        'ROI Analysis',
        'Real Time Personalization',
        'Real-Time Reporting',
        'Return on Ad Spend',
        'Sales Dashboards',
        'Sentiment Analysis',
        'Site-Side Optimization',
        'Solution Selling',
        'Spend Management',
        'Spend Under Management',
        'Survey Data',
        'Thought Leadership',
        'Time To Market (TTM)',
        'Vision Statements',
        'Visual Marketing',
        'Win / Loss Analysis'
      ])
    ]
  },
  {
    id: 146,
    label: 'Supply Chain',
    children: [
      ...new Set([
        'A-Frame',
        'Adaptive Signal Control',
        'Aframax',
        'After-Sales Logistics',
        'Airfreight',
        'Arctic Shipping Infrastructure',
        'Automated Storage and Retrieval System (AS/RS)',
        'Autonomous Shipping',
        'Ballast Water',
        'Batch Pick',
        'Box Truck Leasing',
        'Buffer Stocks',
        'Bunker Tanker',
        'Bunkering',
        'Capacity Planning',
        'Capesize',
        'Carbon Taxes',
        'Cargo Rental',
        'Carrier Diversification',
        'Central Procurement',
        'Charger Management Software (CMS)',
        'Chassis Providers',
        'Chemical Distribution Software',
        'Chemical Tankers',
        'Cloud Logistics',
        'Cold Chain',
        'Cold Storage',
        'Collaborative Commerce',
        'Commercial Vehicles',
        'Commodity Management',
        'Compliance Monitoring',
        'Container Drayage',
        'Container Leasing',
        'Container Ship Panamax',
        'Container Shipping',
        'Containership Fires',
        'Control System Upgrade',
        'Cooperative Purchasing',
        'Cost Per Mile (CPM)',
        'Courier',
        'Crane Automation',
        'Cross Dock',
        'Custom Packaging',
        'Customs Audit',
        'Dangerous Goods Services',
        'Defect Management',
        'Delivery Services',
        'Digital Freight Matching',
        'Digital Shipping',
        'Direct Sourcing',
        'Direct to Store Delivery',
        'Distribution Center',
        'Distribution ERP Software',
        'Distribution Network Analysis',
        'Domestic Shipping',
        'Door-to-Airport',
        'Door-to-Door Shipping',
        'Dray Services',
        'Driver Vehicle Inspection Report (DVIR)',
        'Dropshipping',
        'Dry Bulk Charter',
        'Dry Cargo',
        'Ecommerce Fulfillment',
        'Electric Fleet Vehicles',
        'Electric and Hybrid Ships',
        'Emergency Warehousing',
        'Enterprise Labeling',
        'Ethical Sourcing',
        'Exceptional Transports',
        'Excess Stock Reduction',
        'Express Shipping',
        'FSO & FPSO',
        'Factory Supply Chain Solutions',
        'Feederships',
        'Final Mile',
        'First-Party Logistics Provider (1PL)',
        'Flatbed',
        'Fleet Analysis',
        'Fleet Inspections',
        'Fleet Toll Management',
        'Food Distribution Software',
        'Freezer Storage',
        'Freight Audit and Payment',
        'Freight Broker',
        'Freight Consolidation',
        'Freight Optimization',
        'Freight Rate Analysis & Benchmarking',
        'Freight Rates',
        'Freight Shipping',
        'Fulfillment Center',
        'Fulfillment Services',
        'Fulfillment by Amazon',
        'Full Container',
        'Full Truckload Shipping (FTL)',
        'Geared Bulker',
        'Global Supply Chain',
        'Glycol Systems',
        'Goods to Person',
        'Gooseneck',
        'Ground Shipping',
        'Handysize',
        'Heavy Lift Vessels',
        'Inbound Logistics',
        'Industrial Shelving',
        'Inland Delivery',
        'Intermodal Services',
        'International Shipping',
        'Inventory Availability',
        'Inventory Optimization',
        'Inventory Planning',
        'Kitting',
        'LNG Carrier',
        'Labeling Systems',
        'Less than Container Load (LCL)',
        'Less-than-Truckload (LTL)',
        'Life-Gate',
        'Local Courier',
        'Logistics 4.0',
        'Logistics Software',
        'Long Lead Time',
        'Managed Labor',
        'Managed Transportation',
        'Marine Propulsion',
        'Master Supplier',
        'Medical Device Shipping',
        'Medical Transport Van Rental',
        'Megamax',
        'Mobile Diesel Mechanic',
        'Mobile Fueling',
        'Mode Optimization',
        'Multichannel Distribution',
        'Neo-Panamax',
        'Network Design',
        'New Product Introduction',
        'Next Flight Out (NFO)',
        'Ocean Transport',
        'Offshore Development Center',
        'Omnichannel Fulfillment',
        'On Board Courrier (OBC)',
        'On-Demand Delivery',
        'Online Shipping',
        'Order Fulfillment',
        'Order Management',
        'Order Picking',
        'Outbound Logistics',
        'Overnight Shipping',
        'Overstocking',
        'Package Tracking',
        'Packaging & Labeling',
        'Packing Supplies',
        'Pallet Delivery',
        'Pallet Racking',
        'Panama Canal',
        'Panama Canal Transit Reservation System',
        'Parcel Audit',
        'Parcel Rate Shopping',
        'Parcel Shipping',
        'Parcel Spend Management (PSM)',
        'Periodic Inspections',
        'Pick and Pack',
        'Picture Proof Delivery',
        'Port Services',
        'Pouch Sorter',
        'Pre-Trip Inspections',
        'Product Tankers',
        'Purchasing',
        'Quality Control',
        'Real Time Visibility',
        'Reefer',
        'Refrigerated Transportation',
        'Regional Shipping',
        'Return Authorization',
        'Return Center',
        'Returns Management',
        'Reverse Logistics',
        'Robotic Picking',
        'Route Optimization',
        'Safety Stock',
        'Same Day Shipping',
        'Seafarers',
        'Ship Chartering',
        'Ship Leasing',
        'Ship Management & Crewing',
        'Ship Navigation',
        'Shipment ETA',
        'Shipment Visibility',
        'Shipping',
        'Shipping Insurance',
        'Shipping Lines',
        'Short Sea Shipping',
        'Shuttle Systems',
        'Small Package Delivery',
        'Small Parts Storage',
        'Source-to-Pay (S2P)',
        'Special Services',
        'Spot Rates',
        'Step Deck',
        'Stock Replenishment',
        'Suezmax',
        'Supplier Collaboration',
        'Supplier Lifecycle Management',
        'Supplier Network',
        'Supplier Performance Management',
        'Supplier Relationship Management (SRM)',
        'Supplier Relationships',
        'Supplier Risk Management',
        'Supplier Self-Service',
        'Supply Chain',
        'Supply Chain Analytics',
        'Supply Chain Audit',
        'Supply Chain Automation',
        'Supply Chain Collaboration Software',
        'Supply Chain Demand Planning',
        'Supply Chain Design',
        'Supply Chain Execution',
        'Supply Chain Integration',
        'Supply Chain Logistics',
        'Supply Chain Management',
        'Supply Chain Network',
        'Supply Chain Network Design',
        'Supply Chain Network Optimization',
        'Supply Chain Optimization',
        'Supply Chain Procurement',
        'Supply Chain Resiliency',
        'Supply Chain Risk',
        'Supply Chain Security',
        'Supply Chain Transparency',
        'Supply Chain Transportation',
        'Supply Management',
        'Supramax',
        'Sustainable Logistics',
        'Sustainable Product Development',
        'Sustainable Supply Chain',
        'Temperature Controlled Shipping',
        'Tendering',
        'Terminal Operating System',
        'Third-Party Logistics Provider (3PL)',
        'Time-Critical Shipping',
        'Tradeshow Freight',
        'Trailer Loading',
        'Transload Services',
        'Transpacific Trade',
        'Transport Special Solutions',
        'Transportation Management System (TMS)',
        'Transportation Procurement',
        'Transportation Security',
        'Transportation Strategy',
        'Transportation Technology',
        'Truck Tolling',
        'Uber Freight',
        'Ultra Large Container Ship (ULCS)',
        'Ultramax',
        'Urgent Cargo',
        'Value Added Distribution',
        'Value Added Warehousing',
        'Van Leasing',
        'Vehicle Inventory Acquisition',
        'Vendor Consolidation',
        'Vertical Lift Module',
        'Very Large Crude Cargo (VLCC)',
        'Very Narrow Aisle (VNA) Racking',
        'Warehouse Labor',
        'Warehousing and Distribution',
        'Weight Station',
        'White Glove Deliveries',
        'Wholesale Inventory',
        'Workday Strategic Sourcing'
      ])
    ]
  },
  {
    id: 147,
    label: 'Tablets & Readers',
    children: [
      ...new Set([
        'Tablets'
      ])
    ]
  },
  {
    id: 148,
    label: 'Taxation',
    children: [
      ...new Set([
        '529 College Plans',
        'Accrual Method',
        'Ad Valorem Tax',
        'Alternative Minimum Tax (AMT)',
        'Automatic Exchange of Information (AEOI)',
        'Business Start-Up Costs',
        'Cash Method',
        'Child Tax Credit',
        'Commodity Tax & Customs Law',
        'Communications Tax',
        'Direct Tax',
        'Double Taxation',
        'Effective Tax Rate (ETR)',
        'Employee Retention Credit (ERC)',
        'Employer Identification Number',
        'Employer Tax',
        'Employment Eligibility Verification (I-9)',
        'Environmental Tax',
        'Estate and Gift Taxes',
        'Estimated Tax',
        'Excise Tax',
        'Exemption Certificate',
        'Federal Unemployment (FUTA) Tax',
        'Fiscal Tax Year',
        'Foreign Account Tax Compliance Act (FATCA)',
        'Form 1099-MISC',
        'Form W-2',
        'Form W-4',
        'Fuel Tax',
        'General Anti-Avoidance Rule (GAAR)',
        'Goods and Service Tax (GST)',
        'Household Employee',
        'Income Tax',
        'Income Tax Return',
        'Indirect Tax',
        'Inheritance Tax',
        'Internal Revenue Service (IRS)',
        'International Tax',
        'Land Value Tax (LVT)',
        'Low Income Taxpayer Clinics (LITCs)',
        'Making Tax Digital (MTD)',
        'Manufacturers Tax',
        'Medicare Tax',
        'Occupational Tax',
        'Pay-As-You-Go Tax',
        'Progressive Tax',
        'Property Tax',
        'Qualified Business Income (QBI)',
        'Real Estate Taxes',
        'Recordkeeping',
        'Sales Tax',
        'Sales Tax Audit',
        'Sarbanes Oxley Compliance (SOX)',
        'Self-employment Tax',
        'Social Security Number (SSN)',
        'Social Security Tax',
        'Stamp Duty',
        'State and Local Tax',
        'Tax Advantage',
        'Tax Avoidance',
        'Tax Compliance',
        'Tax Consolidation',
        'Tax Cuts and Jobs Act (TCJA)',
        'Tax Deduction',
        'Tax Deferral',
        'Tax Evasion',
        'Tax Exemption',
        'Tax Identification Number (TIN)',
        'Tax Liability',
        'Tax Penalty',
        'Tax Provision',
        'Tax Reform',
        'Tax Savings',
        'Tax Transformation',
        'Tax Treaty',
        'Tax Withholdings',
        'Tax Year',
        'Taxes',
        'Taxpayer Advocate Service',
        'Transfer Pricing',
        'Use Tax',
        'Value-Added Tax (VAT)',
        'Volunteer Income Tax Assistance (VITA)',
        'Wagering Tax',
        'Withholding'
      ])
    ]
  },
  {
    id: 149,
    label: 'Technology',
    children: [
      ...new Set([
        '10pearls, llc',
        '1Password',
        '21Vianet (VNET)',
        '24-7 Intouch',
        '2nd Watch',
        '365 Data Centers',
        '3DEXCITE',
        '3DExperience Works',
        '3DS Max',
        '3Pillar Global, Inc.',
        '3Play Media',
        '50Wheel',
        '7Summits',
        '7thonline, Inc.',
        'ABBYY',
        'ABBYY FineReader',
        'ACTICO',
        'ACTIVE Network',
        'AEye, Inc.',
        'AGI Laboratory',
        'AMAG',
        'AML Insights',
        'AML Risk Manager',
        'ANYbotics',
        'APA Engineering',
        'APCON, Inc.',
        'ASG Technologies',
        'AT&T Cybersecurity',
        'AT&T Mobility',
        'AT&T Netbond',
        'AU10TIX',
        'AVI-SPL',
        'AWS Amplify',
        'AWS Data Pipeline',
        'AWS Elastic Beanstalk',
        'AWS Greengrass',
        'AWS IoT',
        'AWS OpsWorks',
        'AWS RoboMaker',
        'AWS Sagemaker Ground Truth',
        'AWS Storage Gateway',
        'AWS Transit Gateway',
        'Abacode',
        'Abacus',
        'Abaqus Inc.',
        'Absolute.com',
        'Absorb LMS',
        'Accelera Solutions',
        'Accellion',
        'Accent Technologies',
        'Accolite Digital',
        'Accuity',
        'Ace Cloud Hosting',
        'Acer',
        'Acer TravelMate',
        'AchieveIt',
        'Aconex',
        'Acronis',
        'Active Directory',
        'ActiveCampaign, LLC',
        'Actsoft, Inc.',
        'Actuate AI',
        'Acuity Scheduling',
        'AdCellerant',
        'AdColony',
        'AdaniConneX',
        'Adapt.io',
        'Adenza',
        'Adobe Aero',
        'Adobe Document Cloud',
        'Adobe Express',
        'Adobe Firefly',
        'Adobe Sign',
        'Adobe Systems (ADBE)',
        'Advanced Technology Group',
        'AdvancedMD',
        'Adventr (Ossum Technology Inc.)',
        'Aerohive',
        'Aerohive Networks',
        'Agadia Systems, Inc.',
        'Agency Revolution',
        'AgentSync',
        'AgileBlue',
        'Agility CMS',
        'Agility Multichannel',
        'Agiloft Inc.',
        'Agilysys (AGYS)',
        'Agora.io',
        'Aha! Labs Inc.',
        'AiBUY Inc.',
        'AirTrunk',
        'Airslate',
        'Airtable',
        'Akamai Technologies',
        'Akumina, Inc.',
        'Akvelon',
        'Alanda Software',
        'Alcatel',
        'Alcatel-Lucent',
        'Aldec Inc.',
        'Alert Logic',
        'AlertMedia',
        'Alertus',
        'Alfresco Software',
        'AlgoSec, Inc.',
        'Alibaba Cloud',
        'Alibaba.Com, Inc.',
        'Alienware',
        'Aligned Data Centers',
        'Alithya (ALYA)',
        'Alkira',
        'All for One Group SE',
        'Allied Telesis',
        'Alooma',
        'Alorica',
        'Alpha Software Corporation',
        'Alphawave IP Group plc',
        'Altair',
        'Altair HyperWorks',
        'Altametrics',
        'Alteryx (AYX)',
        'Altify',
        'Altium Limited',
        'Amazon (AMZN)',
        'Amazon Aurora',
        'Amazon Chime',
        'Amazon CodeGuru',
        'Amazon Cognito',
        'Amazon Data Exchange',
        'Amazon Elastic Block Storage (EBS)',
        'Amazon Elastic Compute Cloud (EC2)',
        'Amazon Elastic MapReduce (EMR)',
        'Amazon Kinesis',
        'Amazon Lex',
        'Amazon Lookout for Vision',
        'Amazon Monitron',
        'Amazon Personalize',
        'Amazon Polly',
        'Amazon Redshift',
        'Amazon Redshift ML',
        'Amazon Rekognition',
        'Amazon Relational Database Service (Amazon RDS)',
        'Amazon S3',
        'Amazon S3 Glacier',
        'Amazon Simple Notification Service (SNS)',
        'Amazon Titan',
        'Amazon Transcribe',
        'Amazon Virtual Private Cloud (VPC)',
        'Amazon Web Services (AWS)',
        'Amazon/AWS GuardDuty',
        'Ambient AI',
        'Amper Technologies',
        'Amsive',
        'Anaconda, Inc.',
        'Anaqua',
        'Android',
        'Annex Cloud',
        'Anomali',
        'Ansible',
        'Ansys',
        'Anyline GmbH',
        'Apache Avro',
        'Apache Cassandra',
        'Apache CouchDB',
        'Apache Drill',
        'Apache Flume',
        'Apache HBase',
        'Apache HCatalog',
        'Apache HTTP Server',
        'Apache Impala',
        'Apache Kafka',
        'Apache Lucene',
        'Apache Mahout',
        'Apache SPOT',
        'Apache Software Foundation',
        'Apache Solr',
        'Apache Spark',
        'Apache Sqoop',
        'Apache Subversion',
        'Apache ZooKeeper',
        'Aperia',
        'Apex Code',
        'Apex Systems',
        'ApexSQL LLC',
        'Apiiro Ltd.',
        'Apogee Corporation',
        'Apollo GraphQL',
        'Apollo.io',
        'App Dynamics',
        'AppAnnie',
        'AppDynamics',
        'AppDynamics Database Monitoring',
        'AppNexus',
        'AppRiver',
        'AppSense',
        'AppSheet',
        'AppViewX',
        'AppZen, Inc.',
        'Appcelerator',
        'Appflow',
        'Appgate',
        'Appian (APPN)',
        'Appian Corporation (APPN)',
        'Applanix',
        'Applause',
        'Apple (AAPL)',
        'Apple ARKit',
        'Apple File System (APFS)',
        'Apple M2',
        'Apple Mac Studio',
        'Apple MacBook Air',
        'Apple MacBook Pro',
        'Apple Vision Pro',
        'Apple iPad',
        'Applied Predictive Technologies (APT)',
        'Applied Systems',
        'Applovin',
        'Apps Associates LLC',
        'Appsee',
        'Appsflyer',
        'Apptio Cloudability',
        'Appy Pie LLP',
        'Aprimo',
        'Apstra',
        'Aptean',
        'Apttus',
        'Aqua Data Studio',
        'Aqua Security',
        'AquaFold, Inc.',
        'ArangoDB',
        'Aras',
        'Aratek Biometrics',
        'ArcGIS',
        'ArcGIS Utility Network',
        'Archive360',
        'Arctic Wolf',
        'Arculus Holdings, LLC',
        'Argo Data',
        'Argyle',
        'Aria Systems',
        'Arista Networks (ANET)',
        'Arm Holdings (ARM)',
        'Armis',
        'Armor Defense Inc.',
        'Armorcode',
        'Arrow Electronics (ARW)',
        'Arrow Electronics Inc.',
        'Artificial Labs Limited',
        'Aruba Networks',
        'Aryaka Networks',
        'Asana',
        'Aspire',
        'Asset Panda',
        'Astronomer',
        'Asus ZenBook',
        'AtScale',
        'Atea ASA',
        'Atera',
        'Aternity',
        'AtlasX',
        'Atmail',
        'Atos (ATOS)',
        'Atos Unify',
        'AttackIQ, Inc.',
        'Attivo Networks',
        'Attunity',
        'AudioEye',
        'Augmentir',
        'Augury',
        'Australian Data Centres (ADC)',
        'Auth0',
        'Authorize.Net',
        'Auto/Mate',
        'AutoCAD',
        'AutoGrid Systems, Inc. (AutoGrid)',
        'AutoRABIT',
        'Autodesk (ADSK)',
        'Autodesk Arnold',
        'Autodesk AutoCAD LT',
        'Autodesk CFD',
        'Autodesk Design Review',
        'Autodesk Drive',
        'Autodesk Factory Design Utilities',
        'Autodesk FeatureCAM',
        'Autodesk Forge',
        'Autodesk Fusion 360',
        'Autodesk Inventor',
        'Autodesk VRED',
        'Autodesk Vault',
        'Automated Insights',
        'Automation Anywhere',
        'Automattic Inc.',
        'Autosoft',
        'Autotask',
        'Avanade',
        'Avast',
        'Avature',
        'AvePoint, Inc.',
        'Avenga',
        'Avertium',
        'Aveva',
        'Avi Networks',
        'Aviatrix',
        'Avigilon',
        'Avnet (AVT)',
        'Axians AB',
        'Axio',
        'Axiom EPM',
        'Axis',
        'Axis Security, Inc.',
        'Axonius',
        'Ayla Networks',
        'Azentio Software',
        'Azure Active Directory',
        'Azure Blob Storage',
        'Azure Content Delivery Network (CDN)',
        'Azure Cosmos DB',
        'Azure Data Factory',
        'Azure Data Lake',
        'Azure Files',
        'Azure HDInsight',
        'Azure SQL Data Warehouse',
        'Azure Site Recovery',
        'BAE Systems (BA)',
        'BAE Systems Applied Intelligence',
        'BCM One',
        'BIG RED GROUP',
        'BIM 360',
        'BIOVIA',
        'BMC',
        'BMC Helix Platform',
        'BQE Software',
        'BTC Business Technology Consulting AG',
        'BairesDev',
        'Bamboo',
        'Bamboobox',
        'Base2 Corporation',
        'Basecamp',
        'Bazaarvoice',
        'Beacon Technology',
        'Beamery Inc.',
        'BeatRoute Innovations Pvt. Ltd',
        'Bechtle AG',
        'Beckhoff Automation LLC',
        'Becton, Dickinson and Company',
        'Beeline',
        'Behavox',
        'Benefitfocus',
        'Bentley Systems',
        'Berbix',
        'Berkeley DB (BDB)',
        'Beta Family',
        'BetterCloud',
        'Betty Blocks',
        'Beyond Security',
        'Big Switch Networks',
        'BigCommerce',
        'BigID.com',
        'BigMarker',
        'Binary Defense Systems, Inc.',
        'Bing',
        'BioTrack',
        'BirchStreet Systems',
        'Bishop Fox',
        'Bitbucket',
        'Bitdefender',
        'Bitglass',
        'Bitly',
        'Bitmovin',
        'Bitwarden Inc.',
        'Bizagi',
        'Black Box',
        'Black Duck',
        'Black Kite',
        'BlackBeltHelp',
        'BlackBerry',
        'BlackBerry QNX',
        'Blackpoint Holdings, LLC',
        'Blaze Systems Corporation',
        'Blazeclan Technologies',
        'BlenderBot 2.0',
        'Blocksi Inc.',
        'Bloomberg AIM',
        'Bloomreach',
        'BluVector, Inc.',
        'Blue Coat Systems, Inc.',
        'Blue Jeans Network',
        'Blue Light Card',
        'Blue Medora',
        'Blue Prism (PRSM)',
        'Blue Turtle Technologies',
        'Blue Yonder',
        'BlueCherry',
        'BlueVoyant',
        'BlueWillow.AI',
        'Bluebeam',
        'Bluecore',
        'Bluehost',
        'Bluestone PIM',
        'Blueworks Live',
        'Board International',
        'Bombora',
        'Bomgar',
        'Book Like A Boss',
        'Booking Bug',
        'Boost Mobile',
        'Booz Allen Hamilton',
        'Bottomline Technologies',
        'Box',
        'Box Sign',
        'Boxcast',
        'Brainloop',
        'Brainshark',
        'Brainspace',
        'Branch',
        'Bricata LLC',
        'Bricsys',
        'Bridgecrew',
        'Bright Pattern',
        'Bright Security',
        'BrightLocal',
        'Brightcove (BCOV)',
        'Brinqa, Inc.',
        'Brivo',
        'Brocade Communications Systems',
        'Bubble (Bubble.io)',
        'Buddy Media',
        'Bugcrowd',
        'BuildOps',
        'Builder.ai',
        'Built Technologies, Inc.',
        'ButterCMS',
        'C3.ai',
        'C3IoT',
        'CA Technologies (CA)',
        'CACI International Inc.',
        'CADENAS PARTsolutions',
        'CALDERA',
        'CBI Cybersecurity',
        'CBInsights',
        'CCC Intelligent Solutions (CCCS)',
        'CCH Axcess',
        'CDW (CDW)',
        'CEB',
        'CGI',
        'CGS',
        'CODICO GmbH',
        'COMSOL',
        'COMSOL Multiphysics',
        'CONTENS Software GmbH',
        'CPA Global',
        'CS DISCO',
        'CYME International',
        'Cadence Design Systems (CDNS)',
        'Calendly',
        'Calibermind',
        'Callidus Cloud (CALD)',
        'Callsign',
        'Calsoft',
        'Cameyo',
        'Camunda',
        'Cancom SE (COK)',
        'Canonical',
        'Capgemini',
        'Capillary Technologies',
        'Capterra',
        'Carahsoft',
        'Carbon Black',
        'Carbonite',
        'CargoWise',
        'Carpe Data',
        'Cartegraph',
        'Casepoint',
        'Cask NX',
        'Caspio, Inc.',
        'Cass Information Systems',
        'Cast Software (CAS)',
        'Catchpoint',
        'Cato Networks',
        'Cayosoft, Inc.',
        'Celestica Inc. (CLS)',
        'Celigo, Inc.',
        'Cengage Group',
        'Censornet',
        'CentOS',
        'CenterCode',
        'Centiro',
        'CentralNic Group PLC',
        'Centric Software',
        'Ceros',
        'Chainalysis',
        'Chaos Group',
        'Chaos Group (Vray)',
        'Chaos Software Ltd.',
        'ChargePoint',
        'Chargebee',
        'ChatGPT',
        'ChatGPT-3',
        'ChatGPT-4',
        'Chayora',
        'Checkmarx',
        'Cheetah Digital',
        'Chef',
        'Cherwell Software',
        'Chetu',
        'Chili Piper',
        'Chindata Group Holdings Limited (CD)',
        'Choozle, Inc.',
        'Chroma Inc.',
        'Chrome',
        'Chromebook',
        'Chronus LLC',
        'ChurnZero, Inc.',
        'Cigniti Technologies Limited',
        'Ciklum',
        'Cin7',
        'CircleCI',
        'Cisco ACI',
        'Cisco DNA Center',
        'Cisco DevNet',
        'Cisco IOS',
        'Cisco ISE',
        'Cisco Meraki',
        'Cisco Systems (CSCO)',
        'Cisco Unified Computing System',
        'Citrix (CTXS)',
        'Citus Data, Inc.',
        'Cityworks',
        'Civil 3D',
        'Civo',
        'Clarabridge',
        'Claranet',
        'Clarivate',
        'Claroty',
        'Clayco, Inc',
        'CleanSpeak',
        'Cleanshelf',
        'ClearCube',
        'ClearDATA',
        'ClearDB',
        'ClearOne',
        'ClearSale',
        'ClearSlide',
        'ClearTax',
        'Clearpath Robotics Inc.',
        'Clearswift',
        'Clearwell',
        'ClickSWITCH',
        'Clickatell',
        'Clicktale',
        'Clicktivated Video, Inc.',
        'Cloud Academy, Inc.',
        'Cloud Elements',
        'Cloud Essentials',
        'Cloud Native Computing Foundation',
        'Cloud Security Alliance (CSA)',
        'CloudFlare',
        'CloudHQ (cloudhq.net)',
        'CloudNine',
        'CloudSense',
        'CloudSuite',
        'Cloudcraft Inc.',
        'Cloudera',
        'Cloudinary',
        'CoStar Group',
        'Coalfire',
        'CobbleStone Software',
        'Cockroach Labs, Inc.',
        'Coda',
        'Code Dx',
        'Codefresh',
        'Codeship',
        'Cofense',
        'Coforge Limited',
        'Cognizant (CTSH)',
        'Cognosante',
        'Coinbase',
        'CollabNet',
        'Collibra',
        'Collins Aerospace',
        'Cologix, Inc.',
        'Colt DCS',
        'Comcast X1 Platform',
        'Comdata',
        'Comm-Works',
        'Community Brands',
        'Commvault (CVLT)',
        'Comodo Security Solutions',
        'Company Surge',
        'Compass Datacenters',
        'Compeat',
        'CompuCom',
        'Computer Packages (CPI)',
        'Computer Sciences Corporation (CSC)',
        'Computer Services, Inc. (CSI)',
        'Comtech Xicom Technology, Inc.',
        'Comviva Technologies Limited',
        'Concentrix',
        'Conexiom',
        'Confirmit',
        'Conga',
        'Consensus Sales, Inc.',
        'Constellation Software',
        'Content Guru Limited',
        'Content+Cloud',
        'Contentful',
        'Contentsquare',
        'Contentstack',
        'Contino',
        'Control4',
        'ControlScan',
        'ControlUp Technologies, Ltd.',
        'Converge Technology Solutions',
        'ConvergeOne',
        'Convex Labs',
        'ConveyThis',
        'Cook Security Group',
        'Cooler Screens Inc.',
        'Copado',
        'Cordial',
        'Core dna',
        'CorelDRAW',
        'Corelight',
        'Coresite',
        'Cortex',
        'Cosmos DB',
        'CouchBase',
        'Coveo',
        'Coyote Software',
        'Cradlepoint',
        'Craft.co',
        'Crawford Technologies , Inc.',
        'Cray (CRAY)',
        'Creative Realities Interactive',
        'Crestron',
        'Criteo (CRTO)',
        'Critical Start, Inc.',
        'CrowdFlower',
        'CrowdStrike',
        'Crownpeak',
        'Crunchbase',
        'Cumulus Networks',
        'Curalate',
        'CyCognito Ltd',
        'Cyara',
        'Cybage',
        'CyberArk',
        'CyberCX',
        'CyberProof Inc.',
        'CyberSaint, Inc.',
        'Cyberbit Limited',
        'Cybereason',
        'Cybersource',
        'Cyble',
        'Cycode Ltd.',
        'Cyderes',
        'Cylance',
        'Cymulate Ltd.',
        'Cynet',
        'Cyren (CYRN)',
        'Cysiv',
        'Cytiva',
        'Cyxtera Technologies',
        'D2iQ, Inc.',
        'D3 Security',
        'DADO',
        'DAS42',
        'DBI Software',
        'DCI Data Centers (DCI)',
        'DELL EMC (EMC)',
        'DIgSILENT GmbH',
        'DNN Software',
        'DNSFilter',
        'DRB Systems, Inc.',
        'DTEX Systems',
        'DWD Technology Group',
        'DXC Technology (DXC)',
        'Daon',
        'DartPoints',
        'DashThis',
        'Dassault',
        'Dassault Systems (DSY)',
        'Data Foundry',
        'Data Theorem',
        'DataBank',
        'DataDome',
        'DataGrip',
        'DataRemote',
        'DataRobot',
        'DataStax',
        'DataXu',
        'Databricks',
        'Datacenter Operating System (DC/OS)',
        'Datacert',
        'Datadog (DDOG)',
        'Datadog Database Monitoring',
        'Datanyze',
        'Datascience.com',
        'Datasite LLC',
        'Datawatch (DWCH)',
        'DatoCMS',
        'Datto',
        'Dcode',
        'DealSignal',
        'DealerSocket',
        'Decentraland',
        'Decisions',
        'DeepL',
        'Deepwatch Inc.',
        'DefectDojo Inc.',
        'Defendify, Inc.',
        'DefenseStorm',
        'Delinea, Inc.',
        'Dell EMC Isilon',
        'Dell Inspiron',
        'Dell Latitude',
        'Dell Optiplex',
        'Dell PowerEdge',
        'Dell PowerFlex',
        'Dell PowerScale',
        'Dell PowerStore',
        'Dell Precision',
        'Dell Technologies',
        'Dell XPS',
        'Delta Electronics, Inc.',
        'Deltek',
        'Demandware',
        'Demisto',
        'Demostack',
        'Dennis Group',
        'Deque',
        'Derivative Path',
        'Descartes Labs',
        'Descartes Systems Group Inc',
        'DesktopReady',
        'Digi International',
        'DigiCert',
        'Digital Guardian',
        'Digital Hands',
        'Digital Map Products',
        'Digital Onboarding',
        'Digital River',
        'DigitalOcean',
        'Diligent Corporation',
        'Dimension Data',
        'DiscoverOrg',
        'Discuss.io',
        'Docebo',
        'Docker',
        'DocuSign',
        'DocuTech',
        'Dodge Data & Analytics, Inc.',
        'Dome9',
        'Dominion VUE',
        'Domino Data Lab',
        'Domo',
        'Domotz',
        'DonorPerfect',
        'Dooly',
        'Dormakaba (DOKA)',
        'DoubleVerify',
        'Dozuki',
        'DraftSight',
        'Dragos',
        'Drata',
        'DreamHost',
        'Dremio',
        'Drivemode',
        'Drooms',
        'Dropbox',
        'Druva',
        'Dubber Corporation Limited',
        'Duck Creek Technologies',
        'Duda',
        'Dude Solutions',
        'Dun & Bradstreet (D&B)',
        'Dynamic Signal',
        'Dynamo Software',
        'Dynamsoft',
        'Dynata',
        'Dynatrace',
        'E2open',
        'EIS Group',
        'ELCA Group',
        'ENow Software',
        'EON Reality',
        'EPAM Systems',
        'ESET',
        'ESO Solutions, Inc.',
        'ETAP (Operation Technology, Inc.)',
        'ETAS',
        'EVO:RAIL',
        'Eagle Eye',
        'EarthCam',
        'EarthSoft',
        'EastNets',
        'Easy Metrics Inc.',
        'EasyVista',
        'Eat App',
        'Eaton',
        'Ebix',
        'Ebsta',
        'Eclipse Foundation',
        'Eclipse IDE',
        'EcoOnline',
        'Edge Centres',
        'EfficientIP',
        'Egnyte',
        'Eka Software Solutions',
        'Ekahau',
        'Ekata',
        'Elastic',
        'Elastic Path',
        'Electric Cloud',
        'Electrify America',
        'Electro Rent Corporation',
        'Electronics For Imaging, Inc.',
        'Eleks',
        'Eleven Labs Inc.',
        'Eleveo a.s.',
        'Ellie Mae',
        'Eloqua',
        'Emerging Compounds Treatment Technologies',
        'Emerson Ovation',
        'Endpoint Protector',
        'Endpoint Protector by CoSoSys',
        'Endurance International Group',
        'Energy Solutions International (ESI)',
        'Engagio',
        'Engineering Ingegneria Informatica S.p.A.',
        'Enhanced Mitigation Experience Toolkit (EMET)',
        'Enphase Energy, Inc.',
        'Ensemble Video',
        'Ensighten',
        'Entech Engineering, Inc.',
        'EnterpriseDB',
        'Entrust',
        'Enverus',
        'Envestnet Inc',
        'Envizi',
        'Ephesoft Inc.',
        'Epic Bridges',
        'Epic Caboodle',
        'Epic Cadence',
        'Epic Clarity',
        'Epic Games',
        'Epic Hyperdrive',
        'Epic Hyperspace',
        'Epicor MES',
        'Epicor Software (EPIC)',
        'Episerver',
        'Epsilon PeopleCloud',
        'Ericsson',
        'Ermetic',
        'Esri',
        'EthosData',
        'Eurotech, Inc.',
        'Event Store',
        'Everbridge (EVBG)',
        'Evernote',
        'Everstring',
        'Eveve',
        'Evident ID',
        'Evoke Technologies',
        'Evolv Technology',
        'Evoque Data Centers',
        'Exabeam',
        'Exact Software',
        'ExactTarget',
        'Exotel',
        'Expedient',
        'Expel',
        'Exponea',
        'Exponential-e Ltd.',
        'Extensiv (3PL Central LLC)',
        'ExtraHop Networks',
        'Extreme Networks (EXTR)',
        'Extron Electronics',
        'Eyeota',
        'F-Secure',
        'F24',
        'F5',
        'FAIR Institute',
        'FICO TONBELLER',
        'FIDO2',
        'FORCAM',
        'Facebook (FB)',
        'FairWarning',
        'Fareportal',
        'Fast Identity Online (FIDO)',
        'Fastly',
        'FatWire',
        'Featurespace',
        'Fiddler',
        'Fidelis Cybersecurity, Inc.',
        'Field Nation',
        'Fieldglass',
        'Fieldguide, Inc.',
        'Fiix',
        'Filestack',
        'FinancialForce',
        'Finxact',
        'Finzly',
        'FireEye',
        'FireMon, LLC',
        'FireScope Inc. dba Matrix42',
        'Firebase',
        'Firebolt',
        'Firefox',
        'Fiserv (FISV)',
        'Fishbowl',
        'Fivetran',
        'FlairsTech',
        'Fleet Logistics',
        'FlexPod',
        'Flexential Inc.',
        'Flexera',
        'Flextrack',
        'Flosum, Inc.',
        'Fluix Limited',
        'Ford E-Telematics',
        'ForeSee',
        'Forex VPS',
        'ForgeRock',
        'FormAssembly Inc.',
        'FormFree',
        'FormStack',
        'Forma.ai',
        'Forte Group',
        'Forter',
        'Fortify',
        'Fortinet',
        'Fortive Corporation',
        'Fortna Inc.',
        'Foundry (Modo)',
        'Four Winds Interactive',
        'Fox-IT',
        'Frami.io',
        'Free Software Foundation, Inc. (FSF)',
        'FreePoint Technologies',
        'Frequence, Inc.',
        'Freshsales',
        'Freshworks',
        'Fujifilm Holdings Corporation',
        'Fujitsu',
        'Full Circle Insights',
        'FullStack Labs',
        'Fusion 360',
        'Fusion92',
        'FuzeBox',
        'Fyle',
        'G4S Plc',
        'GAVS Technologies',
        'GDS Holdings Limited',
        'GEP SMART',
        'GIMP (GNU Image Manipulation Program)',
        'GNU',
        'GTranslate',
        'Gaggle',
        'Galvanize',
        'GameMaker (YoYo Games)',
        'GameSparks',
        'Garland Technology LLC',
        'Gearset',
        'Gemalto',
        'General Dynamics Information Technology (GDIT)',
        'GeoComply',
        'Getac Technology Corp',
        'Giant Swarm',
        'GigaSpaces',
        'Gigamon (GIMO)',
        'Gigya',
        'GitHub',
        'GitHub Enterprise',
        'GitLab',
        'Glassbox',
        'Glasswall Solutions',
        'Glean',
        'Global Switch',
        'GlobalData Plc',
        'GlobalLogic',
        'Globalgig',
        'Go1',
        'GoDaddy',
        'GoSecure',
        'GoToMeeting',
        'Godot',
        'GoodTime.io',
        'Google (GOOG)',
        'Google Cloud',
        'Google Cloud Bigtable',
        'Google Cloud Composer',
        'Google Cloud Dataflow',
        'Google Cloud Functions',
        'Google Cloud Marketplace',
        'Google Cloud Messaging (GCM)',
        'Google Cloud Storage',
        'Google Cloud Text-to-Speech',
        'Google Compute Engine (GCE)',
        'Google Dataproc',
        'Google Dialogflow',
        'Google Files',
        'Google Firebase',
        'Google Firebase Crashlytics',
        'Google Glass',
        'Google Maps',
        'Google Marketing Platform',
        'Google Nexus',
        'Google Search',
        'Google Sites',
        'Google Tesseract',
        'Google Translate',
        'GovX',
        'Grab Holdings Inc.',
        'Grafana',
        'Granicus',
        'Grass Valley USA, LLC',
        'Graylog',
        'Great Bay Software',
        'Green Datacenter',
        'Green Hat',
        'GroundTruth',
        'GroupLink Corporation',
        'GroupSense',
        'Gryphon.ai',
        'Guidance Software',
        'Guidewire (GWRE)',
        'Gusto',
        'HCL Technologies (HCLTECH)',
        'HDFS',
        'HERE Technologies',
        'HID Global',
        'HP Dragonfly',
        'HP EliteBook',
        'HP Inc.',
        'HP Jet Intelligence',
        'HP JetAdvantage',
        'HP LaserJet',
        'HP OfficeJet',
        'HP PageWide',
        'HP ProBook',
        'HP Spectre',
        'HP SureSupply',
        'HP Wolf Security',
        'HP Zbook',
        'HP ePrint',
        'HPE 3PAR StoreServ',
        'HPE Alletra',
        'HPE GreenLake',
        'HPE Primera',
        'HPE Synergy',
        'HPE Vertica',
        'HTC',
        'HYAS',
        'HYCU',
        'HackerEarth',
        'HackerOne',
        'Hadoop',
        'Hadoop Distributed File System (HDFS)',
        'Hanu Software Solutions, Inc.',
        'Hanwha',
        'Hapara',
        'Happiest Minds Technologies Limited (NSE: HAPPSTMNDS)',
        'Harris Computer',
        'HashiCorp',
        'HashiCorp Terraform',
        'Hazelcast',
        'Heimdal Security',
        'Helion OpenStack',
        'Helium 10',
        'HelloSign',
        'Helpshift',
        'Herjavec Group',
        'Hevo',
        'Hewlett Packard Enterprise (HPE)',
        'Hewlett-Packard (HPQ)',
        'Hexagon',
        'Hexagon Manufacturing Intelligence',
        'HiMama',
        'HighGear',
        'Highspot',
        'Hirschmann',
        'Hitachi',
        'Honeycomb.io',
        'Honeywell TDC',
        'Hopper Inc.',
        'Horizon3 AI, Inc.',
        'Hornbill',
        'Hortonworks',
        'HostGator',
        'Hostway',
        'Hotel Engine, Inc.',
        'HotelTonight (Hotel Tonight, LLC)',
        'Hotjar',
        'Housecall Pro',
        'Huawei Phones',
        'Huntsman Security',
        'Hybris',
        'HyperFlex',
        'Hyperledger',
        'Hyperproof, Inc.',
        'Hypertec Group',
        'IBA Group',
        'IBM (IBM)',
        'IBM Bluemix',
        'IBM Cloud Services',
        'IBM Cloudant',
        'IBM Databand',
        'IBM Datacap',
        'IBM Datastage',
        'IBM Environmental Intelligence Suite',
        'IBM InfoSphere DataStage',
        'IBM Instana Observability',
        'IBM Maximo',
        'IBM NS1 Connect',
        'IBM Netezza',
        'IBM OpenPages',
        'IBM Security Guardium',
        'IBM Security ReaQta',
        'IBM Security Verify',
        'IBM SoftLayer',
        'IBM Spectrum Fusion',
        'IBM Storage Fusion',
        'IBM Turbonomic Application',
        'IBM Watson',
        'IBM Z',
        'IBM watsonx Assistant',
        'IBM watsonx Code Assistant',
        'IBM watsonx Orchestrate',
        'IBM watsonx.ai',
        'IBM watsonx.data',
        'IBM watsonx.governance',
        'ID Analytics',
        'ID R&D',
        'ID.ME',
        'IDEMIA Group',
        'IDERA',
        'IDnow',
        'IEC Electronics Corp.',
        'IFS',
        'INTURN',
        'IONOS',
        'IQGeo Group plc',
        'IQMS',
        'IRI',
        'ITC Infotech',
        'Iamgold (IAG)',
        'Icertis',
        'Igloo Software',
        'Illuminate Education, Inc.',
        'Illumio',
        'Illusive Networks',
        'ImageTrend, Inc.',
        'Immuta',
        'Impact Networking',
        'Imperva',
        'Improbable',
        'InComm',
        'InEight, Inc.',
        'InMoment',
        'InMotion',
        'InMotion Housing',
        'Inbenta',
        'Incapsula',
        'Incapsulate',
        'Incode Technologies',
        'Indegene',
        'Indeni',
        'Index Engines',
        'Index Solutions',
        'Indium Software, Inc.',
        'Indra Sistemas, S.A.',
        'Inetum',
        'Infinidat LTD.',
        'Infinite Computer Solutions',
        'Infinite Electronics, LLC',
        'InfluxDB',
        'InfluxData',
        'InfoSum Limited',
        'InfoSystems',
        'Infogain',
        'Infor',
        'Infor CloudSuite',
        'Informatica',
        'Information Builders',
        'Infovista',
        'Infraworks',
        'Ingersoll Rand (IR)',
        'Ingram Micro',
        'InnoDB',
        'InnoSenT GmbH',
        'Innovatrics',
        'Innoviz Technologies Ltd. (INVZ)',
        'InsideView',
        'Insidesales.com',
        'Insight Enterprises (NSIT)',
        'Insite Software',
        'Inspur',
        'Instabase',
        'Instana',
        'Instanda',
        'Instart',
        'Instartlogic',
        'Intacct',
        'Integral Ad Science (IAS)',
        'Intel (INTC)',
        'Intel Corporation',
        'Intel Joule',
        'Intel Xeon',
        'IntelEx',
        'Intellect Design Arena Limited (INTELLECT.NS)(INEE.NS)',
        'Intelligent Printer Data Stream (IPDS)',
        'Intellimize',
        'Interact Software',
        'Intercom',
        'Intermedia Cloud Communications',
        'Internap Corporation (INAP)',
        'International Data Corporation',
        'Internet Explorer',
        'Intershop',
        'Intersystems',
        'Interxion (INXN)',
        'Intrado',
        'Intralink',
        'Intuit (INTU)',
        'Inventor',
        'Investis',
        'Ipro Tech',
        'Ipswitch, Inc.',
        'Iron Mountain (IRM)',
        'IronOrbit',
        'IronSource',
        'Ivalua',
        'Ixia',
        'J2 Global, Inc. (JCOM)',
        'JD Edwards',
        'JW Player',
        'Jahia',
        'Jama Software Inc',
        'Jamf',
        'Jasper Chat',
        'JetBrains',
        'Jetpack Workflow',
        'Jiffy.ai',
        'Jile',
        'Jitterbit',
        'Jive (JIVE)',
        'Jive Communications',
        'Jobvite',
        'Join.me',
        'Joyent',
        'Judge.me',
        'Jumpcloud',
        'Jungle Scout',
        'Juniper Networks',
        'K2',
        'KDDI Corporation (KDDIY)',
        'KDS',
        'KERV Interactive (Grabit Interactive Media Inc.)',
        'KLDiscovery',
        'KPIT Technologies Limited (KPIT)',
        'KVH Industries (KVHI)',
        'KWI',
        'Kaggle',
        'Kajima',
        'Kaltura',
        'Kandji',
        'Kantata',
        'Kaseya',
        'Kasisto',
        'Kaspersky Lab',
        'Kasten',
        'Kastle Systems',
        'Kayak Software Corporation',
        'Kayako Limited',
        'Keeper Security, Inc.',
        'Keepit',
        'Kenna Security',
        'Kentico',
        'Kentik',
        'KeyedIn',
        'Keyfactor',
        'Keytree',
        'Khoros',
        'Kibana',
        'Kickfire',
        'Kindle',
        'Kindle Fire',
        'Kinetica',
        'Kingdee',
        'Kingsoft Corporation',
        'Kissflow',
        'Kiuwan',
        'Klaviyo',
        'Knoema',
        'KnowBe4',
        'Kodak',
        'Kofax',
        'Komori Corporation',
        'Kondukto Inc.',
        'Kony',
        'Kordia Ltd',
        'Kount',
        'Kraken Technologies',
        'Krux',
        'Kryon',
        'L-3 Communications (LLL)',
        'LASCOM',
        'LG G3 C118 / C125',
        'LG Mobile',
        'LTi Technology Solutions',
        'Label Gator Brand',
        'Lacework',
        'Landbot',
        'LangChain Inc.',
        'Larsen & Toubro Infotech (LTI)',
        'Laserfiche',
        'LastPass',
        'Later',
        'Lattice Engines',
        'LaunchDarkly',
        'Lead Forensics',
        'Leading Edge Data Centres',
        'Leadspace',
        'LeanData',
        'LearnWorlds',
        'Leaseweb',
        'Legal Files Software Inc.',
        'Legit Security Ltd.',
        'Leica Geosystems',
        'Leidos',
        'LemonStand',
        'LenelS2',
        'Lenovo (HKG)',
        'Lenovo ThinkBook',
        'Lenovo ThinkPad',
        'Lenovo ThinkStation',
        'Lenses.io',
        'Lepide',
        'Lessonly',
        'Level 3 Communications (LVLT)',
        'Levi, Ray & Shoup, Inc.',
        'Libris',
        'LifeLock',
        'Liferay, Inc.',
        'Lifesize',
        'Lightricks',
        'Lightspeed Commerce',
        'Lightspeed Systems',
        'Lightspeed Technologies, Inc.',
        'Limble CMMS',
        'Limelight Networks (LLNW)',
        'LinkSquares, Inc.',
        'LinkedIn Pulse',
        'Linode',
        'Linux Foundation',
        'Linxup',
        'Lion Studios',
        'Liquid Web',
        'LiquidPlanner',
        'ListenLoop',
        'Lithium Technologies',
        'Litmos',
        'LivePerson',
        'LiveTiles Limited (LVT)',
        'Lob',
        'Localytics',
        'Locana',
        'Locus Robotics',
        'LogMeIn',
        'LogMeIn (LOGM)',
        'Logentries',
        'Loggly',
        'Logi Analytics',
        'LogicGate',
        'LogicManager',
        'LogicMonitor',
        'Login Radius',
        'Logrhythm',
        'Logstash',
        'Looker',
        'LookingGlass Cyber',
        'Lookout',
        'Loominance',
        'LucaNet',
        'Lucid Software Inc.',
        'Lumberg Automation',
        'Lumina Analytics',
        'Luminar Technologies Inc',
        'Luminus Devices',
        'Luxoft Holding, Inc',
        'Luxology, LLC',
        'M-Files',
        'MAXON Computer',
        'MDSL',
        'MEGA International',
        'MITRE',
        'MPulse Software, Inc.',
        'MSC Software',
        'MSP360',
        'MTX Group',
        'Mac OS X',
        'Macintosh',
        'Mad Mobile',
        'Magic Leap',
        'Magic Software Enterprises',
        'Magnolia International Ltd.',
        'Mailbird, Inc.',
        'MaintainX',
        'Maintec',
        'Malwarebytes',
        'Malwarebytes Inc.',
        'ManageEngine',
        'Mandiant',
        'MangoApps',
        'Manhattan Associates',
        'MapR',
        'Mapbox',
        'Maplesoft',
        'MarkLogic',
        'Marketo',
        'Masergy Communications, Inc.',
        'Mashery',
        'MasterControl',
        'MathWorks',
        'Mathcad',
        'Matillion',
        'Matterport',
        'MaxMind',
        'Maxar',
        'Maxon (Cinema 4D)',
        'Maya',
        'McAfee MVISION',
        'Mechanical Turk',
        'Medallia',
        'Media Temple',
        'MediaBox',
        'MediaMath',
        'MediaPlatform',
        'Mediafly',
        'Medidata Solutions',
        'Medius',
        'MemSQL',
        'Meniga',
        'MentorCity',
        'Mercury Systems',
        'Messagepoint',
        'Meta 4',
        'Meta AI',
        'Meta Platforms, Inc.',
        'Mews Systems',
        'Mi9 Retail',
        'Micro Focus (MFGP)',
        'MicroStrategy (MSTR)',
        'Microland Limited',
        'Micron Technology (MU)',
        'Microsoft (MSFT)',
        'Microsoft Azure',
        'Microsoft Azure App Service',
        'Microsoft Azure Form Recognizer',
        'Microsoft Azure Purview',
        'Microsoft Azure Sentinel',
        'Microsoft BizTalk',
        'Microsoft Cloud App Security',
        'Microsoft Defender',
        'Microsoft Dynamics AX',
        'Microsoft Edge (Spartan)',
        'Microsoft Enterprise Mobility + Security (EMS)',
        'Microsoft Fabric',
        'Microsoft Hyper-V',
        'Microsoft Mesh',
        'Microsoft SQL Server',
        'Microsoft Security Essentials (MSE)',
        'Microsoft Silverlight',
        'Microsoft Surface',
        'Microsoft Systems Center',
        'Microsoft Teams Direct Routing',
        'Microsoft TechNet',
        'Microsoft Translator',
        'Microsoft Virtual Machine Manager',
        'Microsoft Visio',
        'Microsoft Visual Studio',
        'Microsoft Windows',
        'Midaxo',
        'Milestone',
        'Miller Heiman Group',
        'Mimecast',
        'MindSphere',
        'Mindtree (MINDTREE)',
        'Mirantis',
        'Miro',
        'Mission Secure',
        'Mist Systems, Inc',
        'Mistral AI',
        'Mistral Large',
        'Mitchell International',
        'Mitek',
        'Mize',
        'MoPub',
        'Mobileiron',
        'Mobileum',
        'Mobily',
        'Model N',
        'Modern Systems',
        'Modus',
        'Moldflow',
        'Momentive',
        'Monday.Com',
        'MongoDB',
        'Monotype Imagine Holdings Inc.',
        'MoovWeb',
        'Morpheus Data, LLC',
        'Motion Intelligence',
        'Motive Technologies, Inc.',
        'Motorola Solutions (MSI)',
        'MouseFlow',
        'Moveworks',
        'Moz',
        'Mozilla',
        'Mphasis Limited (MPHASIS)',
        'MuleSoft',
        'MyGate',
        'NCC Group',
        'NCR Corporation',
        'NCR Corporation (NCR)',
        'NComputing',
        'NEC',
        'NETGEAR',
        'NEXL',
        'NEXTEP Systems',
        'NI LabVIEW',
        'NTT Data',
        'NTT Ltd.',
        'NVIDIA DGX',
        'NVM Express',
        'Nagarro',
        'Namecheap',
        'Narrative Science',
        'National Instruments (NATI)',
        'Naukri RMS',
        'Navicat',
        'Navisite',
        'Nearmap',
        'Nearpod Inc.',
        'Nectar',
        'Nefeli Networks',
        'NeoSOFT Technologies',
        'Net IQ',
        'Net at Work, Inc.',
        'NetApp (NTAP)',
        'NetBrain Technologies',
        'NetCracker Technology Corporation',
        'NetDocuments',
        'NetSuite',
        'NetVizura',
        'NetWeaver',
        'NetWise Data, LLC',
        'Netcall',
        'Netezza',
        'Netmagic',
        'Netskope',
        'Network Solutions',
        'Netwrix',
        'Neuro-ID',
        'Neusoft',
        'New Era Technology',
        'New Relic',
        'NewVoiceMedia',
        'Newfold Digital',
        'Newgen',
        'Next I.T.',
        'Next Think',
        'Nextopia',
        'Nextpoint',
        'Nexum',
        'Nexus',
        'Nexusguard',
        'Nginx',
        'NiFi',
        'Nice Systems',
        'Nimble',
        'Nintex',
        'Nomic Networks',
        'Nook',
        'NopCommerce',
        'Nordic Semiconductor ASA (NOD.OL)',
        'Noris Network AG',
        'Notarize',
        'NovAtel',
        'Noventiq Holdings (NVIQ)',
        'NowSecure',
        'Nozomi Networks',
        'Nuance (NUAN)',
        'Nucleus Security Inc.',
        'Nuix',
        'Numecent',
        'Numerator Insights',
        'Nutanix',
        'Nuvolo',
        'Nvidia Corporation',
        'Nylas',
        'ON24',
        'ONE Discovery',
        'ONEPOINT Projects',
        'OPSWAT',
        'OS X El Capitan',
        'OS X Mavericks',
        'OS X Yosemite',
        'OSF Digital',
        'OSI PI',
        'OSISoft',
        'OTN Systems',
        'OVHcloud',
        'Obsidian Security, Inc.',
        'Ocean.io',
        'Ocrolus',
        'Olark',
        'Olo',
        'OnLogic',
        'OnSolve',
        'OneDrive',
        'OneNeck IT Solutions',
        'OneSignal',
        'OneSource Virtual, Inc. (OSV)',
        'OneSpan',
        'OneStream',
        'Onfleet, Inc.',
        'Onit',
        'Onshape',
        'Onstream Media (ONSM)',
        'Ontic',
        'Ontinue, Inc.',
        'Ooma AirDial',
        'Ooyala (OYLA)',
        'Open Mind Technologies',
        'Open Path',
        'Open Systems',
        'OpenCart',
        'OpenShift Container Platform',
        'OpenTable',
        'OpenText (OTEX)',
        'OpenText Intelligent Capture',
        'Openprise',
        'Opera',
        'Opera Software',
        'Optable Technologies Inc.',
        'Optimizely',
        'OptimoRoute Inc.',
        'Optimove',
        'Optiv',
        'Oracle (ORCL)',
        'Oracle Blockchain Service',
        'Oracle Cloud Infrastructure',
        'Oracle Database',
        'Oracle Database 12c',
        'Oracle Enterprise Manager',
        'Oracle Exadata',
        'Oracle Maxymiser',
        'Oracle Multitenant',
        'Oracle Real Application Clusters (RAC)',
        'Oracle SQL Developer',
        'Oracle Supply Chain Management (SCM)',
        'Oracle WebCenter',
        'Orbital Insight',
        'Orca Security',
        'Orchard Software Corporation',
        'Origami Risk',
        'Ouster, Inc. (OUST)',
        'Outreach.io',
        'OvrC',
        'Owl Cyber Defense',
        'Ox Security',
        'OxBlue',
        'P2 Energy Solutions',
        'PCS Software, Inc.',
        'PDI Software',
        'PGP (Pretty Good Privacy)',
        'PGi',
        'PIPE-FLO',
        'PL/SQL',
        'POPi/o',
        'PTC (PTC)',
        'Packet.com',
        'Paessler',
        'Pagerduty',
        'Palo Alto Networks',
        'Palo Alto Networks Prisma',
        'Palo Alto Software, Inc.',
        'Panasonic (PCRFY)',
        'Panoply',
        'Panopto',
        'Pantheon Systems',
        'Paper Education Company Inc.',
        'Paperless Parts, Inc.',
        'ParAccel',
        'Parallels',
        'Parasoft',
        'Park Place Technologies',
        'Parker Hannifin (NYSE: PH)',
        'Parsons Corporation',
        'Patriot One Technologies',
        'Patrix',
        'Pax8',
        'Paxata',
        'PayPal Holdings, Inc (PYPL)',
        'Peak AI',
        'PebblePost Inc.',
        'Pegasystems (PEGA)',
        'Pentaho',
        'Pentera',
        'People Tech Group',
        'PeopleSoft',
        'Pepper Content',
        'Perceptive Pixel',
        'Percona',
        'Percona Monitoring and Management (PMM)',
        'Perfecto Mobile',
        'Perforce',
        'Perimeter 81',
        'Personnel Data Systems, Inc',
        'PgBouncer',
        'Philips (NYSE)',
        'Photon',
        'Pico Quantitative Trading LLC (PQT)',
        'Pico Technology Limited',
        'Picus Security',
        'Pilgrim Software',
        'Pimcore',
        'PinDrop',
        'Ping Identity',
        'Pingdom',
        'Pinwheel',
        'Pitchbook',
        'Pitney Bowes (PBI)',
        'Pivotal Greenplum',
        'Pivotal Software',
        'Pixar (Renderman)',
        'Pixlr Pte Ltd',
        'PlanetTogether',
        'Planon',
        'Plansource',
        'Plantronics, Inc.',
        'Planview',
        'Platform.sh',
        'Plauti',
        'Playwire',
        'Plex',
        'Plivo Inc.',
        'Plug Power',
        'Pluralsight',
        'Podium',
        'PolyAI',
        'Polycom (PLCM)',
        'Pondurance LLC',
        'Portworx',
        'Positive Technologies',
        'PostGIS',
        'Postgres Enterprise Manager',
        'PowWowNow',
        'Power i (aka iSeries, AS/400)',
        'PowerMTA',
        'Powerlinx, Inc.',
        'Praetorian',
        'Precisely',
        'PremierColor',
        'PremiumSoft CyberTech Ltd.',
        'PrestaShop',
        'Princeton Digital Group (PDG)',
        'Prismic',
        'Privitar',
        'ProArch',
        'ProSoft Technology',
        'Proactis',
        'ProcessUnity, Inc.',
        'Productiv',
        'Profisee Group, Inc.',
        'Programetrix',
        'Prometheus',
        'Proofpoint.com',
        'Propel Software Solutions',
        'PropertyWare',
        'Propy',
        'Prosimo',
        'Protegrity',
        'Proterra Inc.',
        'Proxi.id',
        'Pure Storage',
        'Q-Less',
        'Q-Nomy',
        'QA Ltd.',
        'QA Mentor',
        'QAD',
        'QRadar',
        'QTS Data Centers',
        'Qdrant',
        'Qlik',
        'Qlik Sense',
        'Qloo',
        'Qorvo (QRVO)',
        'Qualia',
        'Qualitest',
        'Quality Technology Services (QTS)',
        'Qualtrics',
        'Qualys',
        'Quantum Metric',
        'Quark',
        'Quest',
        'Quest Software',
        'Quickbase.com',
        'Quickpage',
        'Quinnox',
        'Quip',
        'Quiq',
        'Qumu',
        'Quora',
        'Quorum Software',
        'R2 Unified Technologies',
        'RES Software',
        'RO Innovation',
        'RSA',
        'RSA NetWitness',
        'RabbitMQ',
        'Raccoon Holdings, Inc.',
        'Rackspace',
        'Radius Intelligence',
        'Rainforest QA',
        'Rambus Inc.',
        'Rancher',
        'Rand Worldwide',
        'Randori',
        'Rapid Ratings',
        'Rapid7',
        'RapidScale',
        'Rapt Media',
        'Raptor Technologies',
        'Raspberry Pi',
        'Raspberry Pi Foundation',
        'Rave Mobile Safety',
        'Raygun',
        'RazorSQL',
        'Recap Pro 3D',
        'Recorded Future',
        'Recurly',
        'Red Canary',
        'Red Hat OpenShift',
        'Red Hat Satellite',
        'Red Hat Software',
        'RedEye',
        'Redgate SQL Monitor',
        'Redgate Software',
        'Redis Enterprise',
        'Regie.ai',
        'Regula',
        'Relativity',
        'RelativityOne',
        'ReliaQuest',
        'Reliable Software',
        'Reltio, Inc.',
        'Renaissance Learning, Inc.',
        'Replicon',
        'Rescale',
        'Resecurity',
        'Resilinc Corporation',
        'Resource Guru',
        'Resy',
        'Retail Solutions Inc (RSi)',
        'Retool, Inc.',
        'Revature',
        'Reveal(x)',
        'Right Networks',
        'RightNow',
        'Rimini Street (RMNI)',
        'RiskIQ',
        'RiskRecon',
        'Riverbed (RVBD)',
        'Riverbed Technology',
        'Riversand',
        'RockWare',
        'Rocket Software',
        'RocketReach',
        'Rocketfuel (FUEL)',
        'Rollbar',
        'Rootstock Software',
        'Rubrik',
        'Ruckus Networks',
        'S&P Capital IQ Pro',
        'S/4HANA',
        'S3 Technologies',
        'SAIC',
        'SAP',
        'SAP Ariba',
        'SAP BusinessObjects',
        'SAP Extended Warehouse Management (EWM)',
        'SAP Fiori',
        'SAP HANA',
        'SAP IQ',
        'SAP Leonardo',
        'SAP MDG (Master Data Governance)',
        'SAP Product Lifecycle Management',
        'SAP Road Maps',
        'SAP Transportation Management',
        'SAS Institute',
        'SBG Systems',
        'SCYTHE',
        'SDL plc',
        'SEMrush',
        'SEOClarity',
        'SEON Technologies Ltd',
        'SIMBA Chain',
        'SOFTSWISS',
        'SOS Inventory Software LLC',
        'SS&C Technologies',
        'ST Engineering',
        'STACK Infrastructure',
        'STORIS, Inc.',
        'STT GDC Pte. Ltd.',
        'SUSE',
        'SYSPRO',
        'SYSTRAN',
        'Saama',
        'Sabey Data Center Properties LLC',
        'Safaricom',
        'SafeBreach',
        'SafeNet',
        'Safefood 360',
        'SafetyChain',
        'SafetyCulture',
        'Sage (SGE)',
        'SailPoint.com',
        'Sailthru',
        'SalesLoft',
        'Salesforce (CRM)',
        'Salesforce Appexchange',
        'Salesforce DX',
        'Salesforce Lightning',
        'SaltStack',
        'Salto',
        'Samsung Galaxy',
        'Samsung Galaxy Tab',
        'Samsung Knox',
        'SanDisk (SNDK)',
        'Sandler Partners',
        'Sandvine Corp',
        'Sanity.io',
        'Sapling HR',
        'Sauce Labs',
        'Saviynt',
        'Scandit',
        'Scene7',
        'Schott AG',
        'ScienceLogic',
        'ScienceSoft Inc.',
        'Scoutbee',
        'Scratchpad',
        'Scrut Automation',
        'Seagate Technology (STX)',
        'Searchmetrics',
        'Sectigo',
        'Secude',
        'SecureWorks Corp (SCWX)',
        'Secureframe',
        'Security 101',
        'SecurityScorecard',
        'SecurityTrails',
        'Securly, Inc.',
        'Securonix',
        'SeeClickFix',
        'Seemplicity',
        'Segment',
        'Seidor',
        'Seiki Systems',
        'Selligent',
        'Semarchy',
        'Semcasting, Inc.',
        'Sencha',
        'Sendbird',
        'Sensory, Inc.',
        'SentinelOne',
        'Seqera Labs',
        'Sera-Brynn',
        'Serenova',
        'ServInt',
        'ServiceMax',
        'ServiceNow',
        'ServiceTrade',
        'SessionCam',
        'SevenRooms',
        'Shape Security',
        'ShareFile',
        'SharpSpring',
        'SheerID',
        'Shells.com',
        'SherWeb',
        'ShiftLeft Inc.',
        'Shopify',
        'Shopify (SHOP)',
        'Shotgun',
        'SideFX (Houdini)',
        'SideFX Software',
        'Siebel Systems',
        'Siemens',
        'Siemens Advanta',
        'Sigfox',
        'Sigma Computing',
        'Signant Health',
        'SiliconExpert',
        'SimScale',
        'SimilarWeb',
        'SimpliVity',
        'Simpplr, Inc.',
        'Simulia',
        'Sinqia (SQIA3)',
        'Sirius Computer Solutions',
        'Sisense',
        'SiteDocs',
        'Siteimprove',
        'Sitel',
        'Sizmek',
        'SkyKick',
        'Skyhigh Security',
        'Skyswitch',
        'Slack Technologies, Inc.',
        'Smarsh',
        'SmartFocus',
        'SmartForms',
        'SmartIQ',
        'Smartcat',
        'Smartling',
        'Smartsheet Inc.',
        'Smartsoft International',
        'Smartzer Ltd',
        'SmugMug',
        'SnapLogic',
        'Snapdocs, Inc.',
        'SnowPlow',
        'Snowflake',
        'Snyk',
        'SoSafe GmbH',
        'Social Solutions',
        'SoftLayer',
        'SoftServe',
        'Softchoice',
        'Software AG (SOW)',
        'Software Mind',
        'Sogeti',
        'SolarWinds Database Performance Analyzer (DPA)',
        'Solaris',
        'Solarwinds',
        'SolidWorks',
        'SolidWorks PDM',
        'Solink',
        'Solr',
        'SonarQube',
        'SonarSource',
        'Sonata Software Limited',
        'Sonatype',
        'Sonda S.A.',
        'Sonder',
        'Sonicwall',
        'Sonnet Software',
        'Sonrai',
        'Sony (SNE)',
        'Sopra Steria (SOP)',
        'Soul Machines',
        'Spark Streaming',
        'Sparklight',
        'Sparton',
        'SpearTip, LLC',
        'Specright',
        'Spectralink Corporation',
        'Spectrum Enterprise',
        'SpeedCurve',
        'Spiceworks',
        'Spinnaker',
        'Spinnaker Support',
        'Spirable',
        'Split',
        'Splunk',
        'Spring CM',
        'Squarespace',
        'StackPath',
        'StaffingNation',
        'Stanley',
        'Star Navigation Systems Group Ltd. (SNAVF)',
        'Starburst',
        'Stealthwatch',
        'Stefanini Group',
        'Sterling Commerce',
        'Stibo Systems',
        'StoneFly, Inc.',
        'StorageCraft',
        'Storyblok',
        'Stratus Technologies',
        'StreamSets Inc.',
        'Streamlit, Inc.',
        'Stylelabs',
        'Styra',
        'Subsea 7 (SUBCY)',
        'Substance Alchemist & Designer (Adobe)',
        'SugarCRM',
        'SumSub',
        'SumUp',
        'SundaySky',
        'Sungard Availability Services',
        'Super Micro Computer, Inc.',
        'Super.com (SPCB:US)',
        'Supersonic',
        'Supply Wisdom',
        'SupplyOn',
        'Suralink, Inc.',
        'Surface Hub',
        'Surveying And Mapping, LLC (SAM)',
        'Sutherland',
        'Suzy, Inc.',
        'Swiftype',
        'Swimlane',
        'SwipeGuide',
        'Swrve',
        'Sykes',
        'Symantec (SYMC)',
        'Symbeo',
        'Symphony',
        'Synack',
        'Synertrade',
        'Synoptek',
        'Syntax Systems Ltd.',
        'T-Systems',
        'TCDI',
        'TCI International, Inc.',
        'TIBCO Software (TIBX)',
        'TIBCO Spotfire',
        'TIE Kinetix',
        'TO THE NEW',
        'TOA Technologies',
        'TRUCE Software',
        'Tableau',
        'Taboola',
        'Tailscale',
        'Talend (TLND)',
        'TalentLMS',
        'TalkingData',
        'Tamr',
        'Tangoe',
        'Tanium',
        'Tapcart Inc.',
        'Tapfin',
        'TaskUs',
        'Tata Communications',
        'Tata Consultancy Services (TCS)',
        'Tata Elxsi Ltd',
        'Tavant',
        'Tealeaf',
        'TeamCity',
        'Teamtailor',
        'Tech Data (TECD)',
        'Tech Mahindra Limited (TECHM)',
        'TechTarget (TTGT)',
        'Technology One',
        'Techwave',
        'Telecom Brokerage Inc',
        'Teleperformance',
        'Telesoft Technologies',
        'Telligent',
        'Ten Thousand Coffees (10KC)',
        'Teradata (TDC)',
        'TermSheet',
        'Terminus',
        'Tesla (TSLA)',
        'Texas Instruments (TXN)',
        'TextLimit',
        'Thales',
        'The Haskell Company',
        'The Open Group',
        'The Sage Group plc',
        'The Tor Project, Inc.',
        'The Trade Desk (TTD)',
        'The Weather Company',
        'Thermal Wave Imaging Inc.',
        'Thermo Fisher Scientific',
        'Thomson Reuters Accounting CS',
        'Thomson Reuters GoFileRoom',
        'Thomson Reuters ONESOURCE',
        'Thomson Reuters Onvio',
        'Thomson Reuters Practice CS',
        'Thoughtspot',
        'Thoughtworks (TWKS)',
        'ThousandEyes',
        'ThreadFix',
        'Threat Stack',
        'ThreatWarrior',
        'Thunderbolt',
        'Thycotic',
        'TierPoint',
        'Tieto Corporation',
        'Tietoevry',
        'TigerText',
        'TmaxSoft',
        'Toad by Quest Software',
        'Toast',
        'Tock',
        'Tofino Security',
        'TomTom North America Inc.',
        'Tonic.ai',
        'Tonomus',
        'Topcon',
        'Tor',
        'Torii',
        'Toshiba Digital Solutions Corporation',
        'Toshiba Global Commerce Solutions',
        'Totally Integrated Automation Portal (TIA Portal)',
        'Tovuti, Inc.',
        'Traceable',
        'Tracelink',
        'Track-It!',
        'TradeIndia (Infocom Network Private Limited.)',
        'TransAct Technologies',
        'Transact-SQL (T-SQL)',
        'Transmit Security',
        'Tray.io',
        'Treasure Data',
        'Tredence',
        'Trellix',
        'TrendKite',
        'Triconex Safety Systems',
        'Trifacta',
        'Trimble',
        'Tripwire Inc',
        'Tromzo, Inc.',
        'True Influence',
        'TrueCommerce',
        'TrueLook',
        'Truepic, Inc.',
        'TrustArc.com',
        'TrustPilot',
        'Trusted Shops',
        'TubeMogul',
        'Tufin',
        'Turner Construction Company',
        'Twilio',
        'TwineSocial',
        'Twingate',
        'Two Hat',
        'TyMetrix',
        'Tyco (TYC)',
        'Tyler Technologies (TYL)',
        'Ubiquiti Networks',
        'Ubuntu',
        'UiPath',
        'Ultimaker',
        'Ultimate Kronos Group (UKG)',
        'Ultimate Software (ULTI)',
        'UltraSharp',
        'Unica',
        'Unicom Engineering',
        'Unily',
        'Unisys (UIS)',
        'Unit4',
        'United Security Providers',
        'United Technologies (UTX)',
        'Unitrends Inc.',
        'Unity Technologies',
        'Universal Robots',
        'Unix',
        'Unreal Engine',
        'UpKeep',
        'Upland Kapost',
        'Upland Software',
        'Upserve',
        'Uptycs, Inc.',
        'Urban Airship',
        'Usabilla',
        'UserTesting',
        'UserVoice',
        'UserZoom',
        'V-Soft Consulting',
        'V2 Cloud',
        'VALL-E',
        'VCA Software LLC',
        'VCE Company',
        'VMWare Cloud',
        'VMware',
        'VMware ESX',
        'VMware NSX',
        'VMware Workspace ONE',
        'VMware vRealize',
        'VPN Technologies',
        'VSPEX',
        'VTEX',
        'Validity',
        'ValueLabs',
        'Vanta',
        'Varicent Software Inc.',
        'Varonis.com',
        'Varsity Tutors LLC',
        'Vector Security',
        'Veeam Software',
        'VeloCloud',
        'Venafi',
        'Venmo',
        'Veracode',
        'VeriStor',
        'Veridas',
        'Veriff',
        'Verio',
        'Verisign (VRSN)',
        'Veritas',
        'Verizon Digital Media Services',
        'Verkada',
        'Vertafore',
        'Vespa (Vespa AI)',
        'Vesseltracker',
        'Veterans Advantage',
        'ViPR',
        'Viavi',
        'VideoLAN',
        'Vidyard',
        'ViewSonic',
        'Viewgol',
        'Vimeo',
        'Violin Memory',
        'Viptela',
        'VirtuStream',
        'Virtual Instruments',
        'Virtuozzo',
        'Virtusa',
        'Vision33, Inc.',
        'Visionet',
        'Visma Software',
        'Visual Basic',
        'Voalte',
        'Vocollect',
        'Vocus Group Limited',
        'Voice Products',
        'VoltDB',
        'Volterra',
        'Volusion',
        'Vonage',
        'Vontier',
        'Voodoo',
        'VoxPro',
        'Voxware',
        'Vulcan Cyber Ltd.',
        'Vultr',
        'WAGO Corporation',
        'WNS Global Services (WNS)',
        'WP Engine',
        'WSO2',
        'Wabbi',
        'Walmart Global Tech',
        'Wandera',
        'Wasabi',
        'WatchGuard',
        'Waterfall Security',
        'Watson IoT Platform',
        'Wavicle Data Solutions',
        'Waymo LLC',
        'Wayport',
        'WeTransfer',
        'Weaviate, B.V.',
        'Web Development Company',
        'Web.com',
        'WebFleet',
        'Webgains Ltd',
        'Webroot',
        'Weebly',
        'Western Computer',
        'Westlaw',
        'Whatfix',
        'WhatsUp Gold',
        'White Ops',
        'WiMAX (Worldwide Interoperability for Microwave Access)',
        'Wibu-Systems',
        'Wind River',
        'Windows 10',
        'Windows 11',
        'Windows 7',
        'Windows 8',
        'Windows Deployment Services',
        'Windows Hello',
        'Windows Intune',
        'Windows Management Instrumentation (WMI)',
        'Windows Migration',
        'Windows Operating System',
        'Windows Phone',
        'Windows Server',
        'Windows Server 2003 EOS',
        'Windows VPS',
        'Windows Vista',
        'Windows XP',
        'Wipro (WIT)',
        'WireWheel',
        'WiseTech Global Limited (WTCHF)',
        'Wistia',
        'Wiz',
        'Wolfram Research, Inc.',
        'Wolt Enterprises Oy',
        'Wonderware',
        'WordPress VIP',
        'WorkWave',
        'Workday Inc (WDAY)',
        'Workiva',
        'Worksoft',
        'Workspot',
        'Workvivo Limited',
        'World Wide Technology (WTT)',
        'Wowza',
        'Wrike',
        'X86 Servers',
        'XM Cyber',
        'XMPie',
        'XONA',
        'XOi Technologies',
        'Xamarin',
        'XebiaLabs',
        'XenDesktop',
        'XenServer',
        'Xero (XRO)',
        'Xero Limited',
        'Xerox (XRX)',
        'Xoxoday',
        'Xsens',
        'XtremeIO',
        'YASH Technologies',
        'Yahoo Inc.',
        'Yahoo Search',
        'Yandex (YNDX)',
        'Yardi',
        'Yellowbrick Data',
        'Yellowfin',
        'YonYou',
        'Yondr Group',
        'Yotta Data Services Private Limited',
        'YubiKey',
        'Yubico AB',
        'YugabyteDB',
        'ZEDO',
        'ZKTECO CO., LTD.',
        'Zaius',
        'Zapier',
        'Zayo',
        'Zebra Technologies (ZBRA)',
        'Zen Internet',
        'ZeroEyes Inc.',
        'ZeroFox',
        'Zerto.com',
        'Zesty.io',
        'Zettle',
        'Zilliz, Inc.',
        'Zimbra',
        'Zimit',
        'Zimperium',
        'Zingle',
        'Zoho',
        'ZoneAlarm',
        'Zoom',
        'Zoominfo',
        'Zscaler Internet Access (ZIA)',
        'Zscaler Private Access (ZPA)',
        'Zuken',
        'Zymplify',
        'accessiBe',
        'data.world',
        'dbForge Studio',
        'dbt',
        'e-Zest Solutions',
        'eBay (EBAY)',
        'eG Innovations, Inc.',
        'eGain',
        'eInfochips',
        'eMaint',
        'eOriginal',
        'eScribe',
        'eSentire',
        'eXelate',
        'eero',
        'enosix, Inc.',
        'hyperMILL',
        'i-Sprint',
        'iAPPS',
        'iBeta',
        'iCloud',
        'iManage',
        'iOS',
        'iPhone',
        'iPoint',
        'iProov',
        'iSpot.tv, Inc.',
        'iT1 Source',
        'iTopia',
        'iTron',
        'iXsystems, Inc.',
        'iboss',
        'mParticle',
        'mPulse',
        'macOS Sierra',
        'noHold',
        'osTicket',
        'pgAdmin',
        'q.beyond AG',
        'smartShift',
        'softgarden',
        'tvOS',
        'vCita, Inc.',
        'xMatters'
      ])
    ]
  },
  {
    id: 150,
    label: 'Telecommunications',
    children: [
      ...new Set([
        '8x8 (EGHT)',
        'AT&T (T)',
        'AT&T Voice and Collaboration',
        'Aeris Communications',
        'Air Traffic Control Systems (ATCS)',
        'Algar',
        'Altice (ATUS)',
        'Altice International',
        'Amazon Connect',
        'Anritsu Corporation (TYO)',
        'Arkadin',
        'Aspect',
        'Attentive Mobile',
        'AudioCodes, Ltd.',
        'Automated Attendant',
        'Automatic Call Distribution (ACD)',
        'Automatic Telephone Dialing System',
        'Avaya',
        'Average Revenue Per User (ARPU)',
        'Avoxi',
        'Axiata',
        'Axiata (AXIATA)',
        'BT Global Services',
        'BT Group plc',
        'Bandwidth (BAND)',
        'Belgacom (PROX)',
        'Bell Canada',
        'Bharti Airtel (BHARTIARTL)',
        'Biscom',
        'Blueface',
        'Blueprint Software Systems',
        'Bring Your Own Carrier (BYOC)',
        'BroadSoft',
        'C-RAN (Cloud-RAN)',
        'CDMA',
        'Calix, Inc.',
        'Call Center Management',
        'Call Center Software',
        'Call Detail Record (CDR)',
        'Call Recordings',
        'Call Tracking',
        'CenturyLink (CTL)',
        'Charter Communications',
        'China Mobile (CHL)',
        'China Telecom (CHA)',
        'China Telecom Americas Corporation',
        'China Unicom (CHU)',
        'Ciena Corporation',
        'Citizens Broadband Radio Service (CBRS)',
        'Claro',
        'Cloud VoIP',
        'Cogeco',
        'Cogent Communications',
        'Comcast (CCV)',
        'Comcast Business VoiceEdge',
        'Commscope (COMM)',
        'Communication Channel',
        'Communications Service Provider (CSP)',
        'Communications Systems',
        'Computer Telephony Integration (CTI)',
        'Consolidated Communications',
        'CoreDial',
        'Crown Castle Inc. (CCI)',
        'Customer Proprietary Network Information (CPNI)',
        'Daisy Group',
        'Data Over Cable Service Interface Specification (DOCSIS)',
        'Deutsche Telekom (DTE)',
        'DialAmerica',
        'DialPad',
        'Digital Wholesale Solutions (DWS)',
        'DirecTV (DTV)',
        'Dish Network (DISH)',
        'Distributed Antenna Systems (DAS)',
        'Dixons Carphone Plc',
        'EE Limited',
        'Eastlink',
        'Elastic SIP Trunking',
        'Electromagnetic Radiation (EMR)',
        'Emirates Telecommunications (Etisalat)',
        'End-to-End Encryption (E2EE)',
        'Enhanced 9-1-1(E911)',
        'Enterprise Network',
        'FTTA (Fiber to the Antenna)',
        'FTTH (Fiber to the Home)',
        'FTTN (Fiber to the Node)',
        'FTTx (Fiber to the X)',
        'Fax Management',
        'FirstNet',
        'Fixed Mobile Convergence (FMC)',
        'Flowroute',
        'Freedom Mobile',
        'Frontier Communications Corporation',
        'GSM',
        'GTT Communications Inc (GTTNQ)',
        'Genesys',
        'Global SIM',
        'Google Hangouts',
        'Granite Telecom',
        'Grasshopper',
        'Headend Facility',
        'HipChat',
        'Hosted Private Branch Exchange (Hosted PBX)',
        'Hosted VoIP',
        'Huawei',
        'Hutchison 3G UK Ltd (Three UK)',
        'IDT Corporation (IDT)',
        'IP Faxing',
        'IP Multimedia Subsystem (IMS)',
        'IP Telephony',
        'Indoor Cell Site',
        'Infobip',
        'Information and Communication Technology (ICT)',
        'Inteliquent',
        'Intelsat',
        'Invoca',
        'Jitter Measurement',
        'Kings III',
        'Kore Wireless',
        'Leased Line',
        'Least-Cost Routing',
        'Liberty Broadband',
        'Liquidware',
        'Low Earth Orbit (LEO)',
        'Lucent',
        'Lumen Technologies',
        'Lumos Networks Corp.',
        'Lynk Global, Inc.',
        'MACH Networks',
        'MTN Group (MTN)',
        'Macquarie Telecom',
        'Managed IP Telephony',
        'MarketSpark',
        'Master Agent',
        'MetTel',
        'MetroPCS',
        'Mitel (MITL)',
        'Mobile Communications America',
        'Mobile High-Definition Link (MHL)',
        'Multimedia Messaging',
        'Multiprotocol Label Switching (MPLS)',
        'Multiprotocol Label Switching (MPLS) Alternative',
        'NTT Communications',
        'Near Field Communication (NFC)',
        'Nexmo',
        'Nextiva',
        'Nippon Telegraph and Telephone Corporation (NTT) (NTTYY)',
        'Nokia (NOK)',
        'Nortel',
        'One New Zealand Group Limited',
        'Ooma, Inc.',
        'Ooredoo QPSC (ORDS.QA)',
        'OpenRan',
        'Optical Distribution Frames (ODF)',
        'Optus',
        'Orange Business Services',
        'Orange SA (ORAN)',
        'PCCW Limited (PCCWY)',
        'Passive Optical Network (PON)',
        'PatientPoint, LLC',
        'Phone Call Processing',
        'Phone System',
        'Plain Old Telephone System (POTS)',
        'PlusNet',
        'Power Control',
        'Power-Line Communication (PLC)',
        'Predictive Dialing',
        'Private Branch Exchange (PBX)',
        'Private Label VoIP',
        'Provisioning',
        'Public Switched Telephone Network (PSTN)',
        'Quadrature Signals',
        'Qualcomm (QCOM)',
        'RAN Intelligent Controller (RIC)',
        'RF Spectrum Analyzer Software',
        'Radio Frequency Connector',
        'Radio Interference',
        'Radio Monitoring',
        'Real-Time Communications (RTC)',
        'Reliance Communications (RCOM)',
        'Remote PHY',
        'Remote Radio Units (RRUs)',
        'Ribbon Communications',
        'Rich Communication Services (RCS) Messaging',
        'RingCentral (RNG)',
        'Rogers',
        'SAC Wireless',
        'SES S.A.',
        'SIGINT Intelligence',
        'SITA',
        'Sakon',
        'SaskTel',
        'Saudi Telecom Company',
        'Session Border Controller',
        'Shaw',
        'ShoreTel',
        'Sify (SIFY)',
        'Signal Integrity',
        'Silver Peak',
        'Sinch',
        'Singtel',
        'Sip Trunking',
        'Sky UK Limited',
        'Soft Phone',
        'Sorenson Communications, Inc.',
        'Spark New Zealand Limited',
        'Spectrum Analysis',
        'Spirent (SPT)',
        'Sprint (S)',
        'StarLeaf',
        'Structured Cabling',
        'Sunrise Communications Group (SRCG)',
        'Sunrise LLC',
        'Swisscom (SCMN)',
        'Syniverse (SVR)',
        'T-Mobile (TMUS)',
        'TKH',
        'TPG Telecom Limited',
        'TalkTalk Group (TALK)',
        'Telarus',
        'Telecom',
        'Telecom Cost Control',
        'Telecommunications Equipment',
        'Telefonica SA',
        'Telefónica UK Limited (O2)',
        'Telekom Deutschland GmbH',
        'Telematics',
        'Telephone & Data Systems (TDS)',
        'Telephony / CTI / VOIP',
        'Telit',
        'Telkom South Africa (TKG)',
        'Telstra (TLS)',
        'Telus',
        'Tesco Mobile Limited',
        'The Office of Communications (Ofcom)',
        'TigerConnect',
        'Time Warner (TWX)',
        'Time Warner Cable (TWC)',
        'Tulip Telecom',
        'UberConference',
        'Ultra Wideband',
        'Unified Communications',
        'Unified Communications as a Service (UCaaS)',
        'Verizon (VZ)',
        'Viasat, Inc.',
        'Video Conferencing Software',
        'Videotron',
        'Virgin Media (VMED)',
        'Virtual Network Operator (VNO)',
        'Vivo Brazil',
        'Vocera (VCRA)',
        'Vodacom (VOD)',
        'Vodafone Group (VOD)',
        'Voice Biometrics',
        'Voice Communication Control System (VCCS)',
        'Voice Outsourcing',
        'Voice Over IP',
        'Voice Search',
        'Vox Telecom',
        'White Label Communications',
        'Wholesale VoIP',
        'Windstream (WIN)',
        'eSIM',
        'giffgaff Limited',
        'iD Mobile Limited'
      ])
    ]
  },
  {
    id: 151,
    label: 'Tools & Electronics',
    children: [
      ...new Set([
        '2.5D Packaging',
        'AC Motors',
        'Accelerometer',
        'Adhesives',
        'Aerospace Tooling',
        'Air Coolers',
        'Air Source Heat Pumps (ASHP)',
        'Altimeter',
        'Analog-to-Digital Converter (ADC)',
        'Avionics',
        'Backplane',
        'Ball Screw',
        'Batteries',
        'Belt Scales',
        'Bilge Pump',
        'Booster Pump',
        'Carbon Dioxide Analyzer',
        'Carrier Tape',
        'Chemical Mechanical Polishing (CMP)',
        'Circuit Breaker',
        'Circuit Protection',
        'Circulator Pump',
        'Clamp-on Flow Meters',
        'Cobot',
        'Combustible Gas Detector',
        'Combustion Analyzer',
        'Condensers',
        'Conductivity Sensors',
        'Continuous Level Measurement',
        'Cooktop Stoves',
        'Coriolis Flow Meters',
        'Couplings',
        'DC / DC Converters',
        'DC Breaker',
        'DC Drives',
        'DC Motors',
        'Dash Cam',
        'Decoupling Capacitor',
        'Design Systems',
        'Differential Pressure (DP) Transmitters',
        'Digital Integrated Circuits',
        'Digital-to-Analog Converter (DAC)',
        'Dock Plates',
        'Dry Type Transformer',
        'Drycoolers',
        'Dynamic Positioning',
        'Electric Meter',
        'Electromagnetic Flow Meters',
        'Electronic Chart Display and Information System (ECDIS)',
        'Electronic Control Unit (ECU)',
        'Electronic Design Automation (EDA)',
        'Electronic Recycling',
        'Electronic Warfare',
        'Electronics Equipment',
        'Electrostatic Discharge (ESD)',
        'Field-Programmable Gate Arrays (FPGAs)',
        'Fire Stop',
        'Fixed Point Gas Detectors',
        'Free Chlorine Analyzer',
        'Frequency Counter',
        'Function Generator',
        'Gas Meter',
        'Gas Pressure Regulator',
        'Gas Sensor',
        'Gearbox',
        'Gearmotors',
        'Grinder Pump',
        'Handheld Oscilloscopes',
        'Heat Exchanger',
        'Heat Recovery Systems',
        'Heterogeneous Integration',
        'Hot Water Circulating Pump',
        'Hydraulic Cylinders',
        'Hydraulic Valve',
        'Hydrogen Analyzers',
        'Hygienic Pressure Transmitters',
        'Industrial Air Compression',
        'Industrial Air Filters',
        'Industrial Cabling',
        'Industrial Condition Monitoring',
        'Industrial Conveyor',
        'Industrial Floor Scrubbers',
        'Industrial Floor Sweepers',
        'Industrial Furnace',
        'Industrial Medium/High-Voltage Motors',
        'Industrial Refrigeration',
        'Industrial Software',
        'Infrared (IR) Camera',
        'Input/Output Modules',
        'Integrated Circuit (IC)',
        'Laser Gas Analyzer',
        'Level Controller',
        'Level Sensor',
        'Level Transmitter',
        'Light-Emitting Diodes (LEDs)',
        'Linear Actuators',
        'Liquid Analyzers',
        'Liquid-Crystal Display (LCD)',
        'Load Cells',
        'Loading Dock',
        'Loading Dock Leveler',
        'Logic Analyzer',
        'MOSFET (Metal-oxide semiconductor field-effect transistor)',
        'Machine Tool Systems',
        'Magnetic Flow Meters',
        'Mass Flow Transmitters',
        'Methane Analyzer',
        'Microcontrollers',
        'Microelectromechanical Systems (MEMS)',
        'Microprocessors',
        'Microwave & Radio Frequency',
        'Motor Control Center (MCC)',
        'Non-Contacting level Transmitter',
        'Non-Invasive Flow Meter',
        'Op-Amps (Operational Amplifiers)',
        'Open Path Gas Detectors',
        'Operator Training Simulator',
        'Oscilloscope Probe',
        'Oscilloscopes',
        'Oxidation Reduction Potential (ORP) Sensors',
        'Oxygen Analyzer',
        'Phase-Locked Loop (PLL)',
        'Platform Scale',
        'Positive Displacement',
        'Power Management Integrated Circuits (PMICs)',
        'Power System Simulation',
        'Pressure Transducer',
        'Printed Circuit Board (PCB)',
        'Propane Tank Monitor',
        'Radar Level Transmitter',
        'Radio-Frequency Identification (RFID)',
        'Real-Time Location Systems (RTLS)',
        'Rollup Doors',
        'Sanitation Supplies',
        'Semiconductor Test System',
        'Servo Drive',
        'Sewage Pump',
        'Ship Equipment & Fixtures',
        'Signal Generator',
        'Solar Water Pump',
        'Solenoid Valve',
        'Solid State Breaker',
        'Solids Flow Meters',
        'Source Measure Unit',
        'Spectrum Analyzers',
        'Stepper Drives',
        'Stream Stripping',
        'Submersible pump',
        'Sump Pump',
        'Switchgear',
        'System in Package (SiP)',
        'Terminal Block',
        'Three-Dimensional Integrated Circuit (3D IC)',
        'Tire Scanner',
        'Trailer Restraint',
        'Transponder',
        'Twisted Pair',
        'Ultrasonic Flow Meter',
        'Ultrasonic Gas Leak Detector',
        'Valve Actuators',
        'Valve Positioners',
        'Variable Frequency Drive (VFD)',
        'Vector Signal Analyzer',
        'Vehicle-to-Everything (V2X)',
        'Vertical Mill',
        'Vortex Flow Meters',
        'Water Source Heat Pumps (WSHP)',
        'Waveform Generator',
        'pH meters'
      ])
    ]
  },
  {
    id: 152,
    label: 'Trade',
    children: [
      ...new Set([
        'Absolute Advantage',
        'Anti-Bribery',
        'Anti-Corruption',
        'Barrier to Trade',
        'Barriers to Entry',
        'Bribery',
        'Canon Law',
        'Capitol Controls',
        'Commercial Trade',
        'Comparative Advantage',
        'Conflict Of Interest (COI)',
        'Corruption',
        'Cross-Border Transactions',
        'Customs Broking',
        'Dispute Resolution',
        'Domestic Goods',
        'Exchange Rate',
        'Exports',
        'Externalities',
        'Federal Trade Commission (FTC)',
        'Foreign Corrupt Practices Act (FCPA)',
        'Free Trade',
        'Free-Trade Area',
        'General Agreement on Tariffs and Trade (GATT)',
        'General Average',
        'Imports',
        'International Trade Administration',
        'International Trade Regulation',
        'Maritime Piracy',
        'Market Economy',
        'Most Favored Nation Principle',
        'Non-Tariff Barrier',
        'Single Market',
        'Special Economic Zone (SEZ)',
        'Tariff Concession',
        'World Trade Organization (WTO)'
      ])
    ]
  },
  {
    id: 153,
    label: 'Trading & Investing',
    children: [
      ...new Set([
        'Absolute Return Strategies',
        'Accredited Investor',
        'Alternative Investment',
        'Angel Investors',
        'Arbitrage',
        'Asset Lifecycle Management (ALM)',
        'Asset Management',
        'Asset Performance Management',
        'Asset-Backed Securities (ABS)',
        'Assets Under Management (AUM)',
        'Australian Dollar (Currency)',
        'Average Directional Movement Index (ADX)',
        'Balanced Funds',
        'Balanced Portfolio',
        'Basis Point',
        'Bearer Bonds',
        'Best Execution',
        'Bond ETFs',
        'Bond Fund',
        'Bond Yield',
        'Bonds',
        'Book Value',
        'Brokerage Account',
        'CAC 40 Index',
        'Capital Markets',
        'Capital Preservation',
        'Capitalization Rates (Cap Rates)',
        'Carried Interest',
        'Chicago Board of Trade (CBOT)',
        'Climate Risk Analytics (CRA)',
        'Closed-End Funds',
        'Collateralized Debt Obligations (CDO)',
        'Collateralized Loan Obligation (CLO)',
        'Collective Investment Trusts (CIT)',
        'Commercial Real Estate',
        'Commodities',
        'Commodity ETFs',
        'Commodity Risk',
        'Competitive Bid',
        'Compound Annual Growth Rate (CAGR)',
        'Consumer Price Index',
        'Corporate Venture',
        'Coupon Bonds',
        'Credit Default Swaps',
        'Currency Futures',
        'Currency Risk',
        'Customs Tariffs',
        'DAX Index (DAX)',
        'Debit Spread',
        'Defined Contribution Plan Fiduciary',
        'Derivatives Market',
        'Discretionary Accounts',
        'Discretionary Investment Management',
        'Dividend Reinvestment Plan (DRIP)',
        'Dividends',
        'Dow Jones Industrial Average (DJIA)',
        'Duration Management',
        'Dynamic Asset Allocation',
        'ESG Investing',
        'ETFs (Exchange Traded Funds)',
        'Efficient-Market Hypothesis (EMH)',
        'Electronic Trading',
        'Emerging Market Debt',
        'Emerging Markets',
        'Emissions Trading / Cap and Trade',
        'Enterprise Software Investors',
        'Environmental, Social and Governance (ESG)',
        'Euro (Currency)',
        'Euro Stoxx 50 (SX5E)',
        'Euronext',
        'Exchange-Traded Note (ETN)',
        'Exchange-Traded Products (ETP)',
        'Expense Ratio',
        'FOREX',
        'Financial Performance',
        'Financial Risk',
        'Financial Times Stock Exchange 100 Share Index (FTSE 100)',
        'Fixed Asset Management',
        'Fixed Income',
        'Fixed Indexed Annuities',
        'Foreign Direct Investment (FDI)',
        'Foreign Exchange Spot Transaction',
        'Foreign Portfolio Investment (FPI)',
        'Foreign-Exchange Options',
        'Forex Swap',
        'Forex Trading Strategies',
        'Founder Liquidity',
        'Fund of Funds',
        'Futures',
        'Global Equity Funds',
        'Global Investing',
        'Global Macro Investing',
        'Global Markets',
        'Growth Equity',
        'Hang Seng Index',
        'Hedge Funds',
        'Hedging',
        'Hedging Strategy',
        'High Yield Bonds',
        'High Yield Savings',
        'Home Country Bias',
        'Hong Kong Stock Exchange',
        'Hybrid Annuities',
        'Impact Investing',
        'Income Annuities',
        'Index Funds',
        'Indexed Annuities',
        'Initial Public Offering (IPO)',
        'Institutional Investing',
        'Interest Expense',
        'Interest Rate',
        'Interest Rate Hedging',
        'Interest Rate Risk',
        'Interest Rate Swap',
        'Interval funds',
        'Inverse ETF',
        'Investment Criteria',
        'Investment Outsourcing',
        'Investment Property Loan',
        'Investment Protection',
        'Investment Software',
        'Investment Tax Credit (ITC)',
        'LIBOR',
        'Large-Cap Stocks',
        'Leveraged ETF',
        'Liability-Driven Investing',
        'Liquidity Management',
        'Liquidity Risk',
        'London Stock Exchange',
        'Long Short Investment Strategy',
        'Luxury Real Estate / High-End Real Estate',
        'MSCI EAFE Index',
        'Margin Call',
        'Market Data',
        'Market Order',
        'Market Volatility',
        'Market Volatility Index (VIX)',
        'Micro Lot (Forex)',
        'Mobile Trading',
        'Money Flow Index (MFI)',
        'Money Market Funds',
        'Mortgage-Backed Securities (MBS)',
        'Multi-Asset Investing',
        'Municipal Bonds',
        'Net Asset Value (NAV)',
        'Net Food Commodities Outflow',
        'Nikkei 225 Index',
        'Non-Fungible Token (NFT)',
        'OTC Bulletin Board',
        'Offshore Investment',
        'Online / Web Trading',
        'Online Brokerage',
        'Option Strategies',
        'Options',
        'Order & Execution Management System (OEMS)',
        'Outsourced Chief Investment Officer (OCIO)',
        'Over-the-Counter Markets',
        'Payouts',
        'Pip Value',
        'Point to Point',
        'Portable Alpha',
        'Portfolio',
        'Portfolio Construction',
        'Portfolio Holdings',
        'Pound Sterling (Currency)',
        'Preferred Stock',
        'Prime Brokerage',
        'Prime Lending',
        'Principal Protected Investments',
        'Private Equity',
        'Private Investment Funds',
        'Pump and Dump (P&D)',
        'Qualified Default Investment Alternative (QDIA)',
        'REITs (Real Estate Investment Trusts)',
        'RILAs (Registered Indexed-Linked Annuity)',
        'Real Estate',
        'Real Estate Investments',
        'Regional Comprehensive Economic Partnership (RCEP)',
        'Relative Strength Index (RSI)',
        'Renewable Investments',
        'Repurchase Agreement(RP)',
        'Retail Investors',
        'Revenue Bond',
        'Royalties',
        'Russell 2000 Index',
        'S&P 500 Index (SPX)',
        'Safe Haven',
        'Secondary Offering',
        'Securitization',
        'Serial Bonds',
        'Shanghai Stock Exchange',
        'Shenzhen Stock Exchange',
        'Short Selling',
        'Short-Term Investments',
        'Single-Stock ETF',
        'Sinking Fund',
        'Solar Investments',
        'Sovereign Wealth Fund',
        'Stock Chart',
        'Stock Market Crash',
        'Stock Market Index',
        'Stop-Loss Order',
        'Strike Price (Exercise Price)',
        'Structured Finance',
        'Subordinated Debt',
        'Subprime Lending',
        'Surety Bonds',
        'Sustainable Investing',
        'Tactical Trading',
        'Tax Deferred Accumulation',
        'Technical Trading',
        'Thematic ETF',
        'Tracking Stock',
        'Trade Credit',
        'Trade Notes',
        'Trade Repository',
        'Tranches',
        'Transaction Cost Analysis (TCA)',
        'Treasury Bills',
        'Treasury Bonds',
        'Treasury Inflation Protected Securities (TIPS)',
        'Treasury Notes',
        'Treasury Stock',
        'Trigger Rates',
        'U.S. Dollar (Currency)',
        'Utility Asset Management',
        'Wholesale Price Index (WPI)',
        'Wirehouses',
        'Yen (Currency)',
        'Zero-Coupon Bonds'
      ])
    ]
  },
  {
    id: 154,
    label: 'Training & Development',
    children: [
      ...new Set([
        'AI-Powered Coaching',
        'Academic Dishonesty',
        'Academic Integrity',
        'Accreditation Higher Education',
        'Active learning',
        'Adaptive Learning',
        'Adult Learning',
        'Apprenticeship',
        'Assessment / Assessment Tools',
        'Business Education',
        'Career Coach',
        'Career Counseling',
        'Career Development',
        'Career Exploration',
        'Career Management',
        'Career Training Program',
        'Certificate Programs',
        'Certification Assessments',
        'Class and Course Schedule Planning',
        'Classroom Management',
        'Co-Creation',
        'Co-Curricular',
        'Coaching & Mentoring',
        'Competencies',
        'Compliance Training',
        'Conflict Management',
        'Corporate Training',
        'Corporate Universities',
        'Cultural Training',
        'Curriculum Management',
        'Curriculum Mapping',
        'Custom LMS',
        'Data Literacy',
        'Digital Badging',
        'Digital Upskilling',
        'Distance Learning',
        'Education / Training',
        'Education Intervention',
        'Emotional Intelligence',
        'Employee Development',
        'Employee Training',
        'English Language Arts (ELA) Educator Resources',
        'Essential Skills',
        'Executive MBA',
        'Experiential Learning',
        'Extended Enterprise LMS',
        'Formative assessment',
        'GMAT Test Preparation',
        'GRE Test Preparation',
        'Group Coaching',
        'Guided Pathways',
        'HR Professional Development',
        'Inmate Education',
        'Instructor-Led Training (ILT)',
        'Knowledge Management',
        'LSAT Preparation',
        'Leadership Training',
        'Learning Management',
        'Learning Pathways',
        'MBA Programs',
        'Management and Executive Development',
        'Mentoring',
        'Motivation',
        'Needs Assessment',
        'Optional Practical Training (OPT)',
        'Organizational Development',
        'Organizational Learning',
        'Performance Support Tools',
        'Performance-Based Testing',
        'Personal Development',
        'Practice Tests',
        'Proctoring',
        'Professional Development',
        'Professional Networking',
        'Psychometrics',
        'Reliability Training',
        'Resource Management',
        'SAT Preparation',
        'Seminars and Educational Programs',
        'Sharable Content Object Reference Model (SCORM)',
        'Social Learning',
        'Standardized Testing',
        'Student Retention/Remediation in Higher Education',
        'Subject-Matter Expert (SME)',
        'Summative assessment',
        'Talent Development',
        'Talent Management',
        'Team Building',
        'Trailhead',
        'Training Materials & Methods',
        'Training and Development',
        'White Label LMS',
        'Workforce Readiness',
        'eLearning'
      ])
    ]
  },
  {
    id: 155,
    label: 'Transactions & Payments',
    children: [
      ...new Set([
        '3-D Secure',
        'Address Verification Service (AVS)',
        'Asset Tokenization',
        'Attended Payment Terminal',
        'Automated Teller Machine (ATM)',
        'Automatic Bill Payment',
        'Bank Identification Number (BIN)',
        'Base Currency',
        'Bill Payment / Automated Clearing House (ACH)',
        'Bitcoin',
        'Blockchain',
        'Blockchain Technology',
        'Boleto',
        'Business Debit Card',
        'Buy Now Pay Later',
        'Campus Card',
        'Card Not Present (CNP)',
        'Cashback',
        'Charge Card',
        'Chargeback',
        'Chase Mobile Checkout Plus',
        'Chip Reader',
        'Clover Flex',
        'Clover Mini',
        'Credit Card Origination',
        'Credit Card Processing',
        'Credit Card Reader',
        'Credit Card Rewards',
        'Credit as a Service (CaaS)',
        'Cryptocurrency',
        'Decline Salvage',
        'Deposit Automation',
        'Digital Token',
        'Digital Wallets',
        'Direct Debit',
        'Documentary Collections',
        'Dynamic Currency Conversion',
        'EMV (Payment System)',
        'Electronic Funds Transfer (EFT)',
        'Electronic Point of Sale (EPOS)',
        'Equated Monthly Installment (EMI)',
        'Excessive Chargeback Program (ECP)',
        'Excessive Fraud Merchant (EFM) Compliance Program',
        'FedNow',
        'Financial Transaction',
        'Foreign Currency Transactions',
        'Fundraising',
        'ISV Payments',
        'Initial Coin Offering (ICO)',
        'International Money Transfers',
        'Invoicing',
        'Lease Pass Through',
        'Letter of Credit',
        'Loan payments',
        'Lump Sum',
        'Merchant Discount Rate (MDR)',
        'Merit-Based Incentive Payment System (MIPS)',
        'Micropayment',
        'Mobile Point of Sale (mPOS)',
        'Money Transfer Service',
        'National Electronic Funds Transfer (NEFT)',
        'Non-Sufficient Funds (NSF)',
        'P2P Payments',
        'PSD2',
        'Payment Application Data Security Standard (PA-DSS)',
        'Payment Automation',
        'Payment Declines',
        'Payment Fraud',
        'Payment Gateway',
        'Payment Hub',
        'Payment Integration',
        'Payment Optimization',
        'Payment Plan',
        'Payment Service Provider (PSP)',
        'Payment Solutions',
        'Payment Terminal',
        'Payments Referral Partner',
        'Payments Revenue Share',
        'Point of Sale Financing',
        'Prompt Payment',
        'Purchase-to-Pay (P2P)',
        'Real-Time Gross Settlement (RTGS)',
        'Real-Time Payments (RTP)',
        'Recurring Billing',
        'Recurring Payment',
        'Remittances',
        'SAP Point of Sale (SAP POS)',
        'Self-Checkout',
        'Single Euro Payments Area (SEPA)',
        'Smart Routing',
        'Split Payment',
        'Square Reader',
        'Square Register',
        'Square Stand',
        'Square Terminal',
        'Statutory Interest',
        'TRA Exemptions',
        'Telephone Banking',
        'Unattended Payments',
        'Virtual Accounts',
        'Virtual Cards',
        'Virtual Payment Solutions',
        'VisaNet',
        'XRP Token'
      ])
    ]
  },
  {
    id: 156,
    label: 'Translation & Language',
    children: [
      ...new Set([
        'Audio Description',
        'Bablic Translation',
        'Closed Captioning',
        'Document Translation',
        'Enterprise Translation Software',
        'Financial Translation services',
        'Gaming Translation Services',
        'Google Website Translator',
        'Handwriting Recognition (HWR)',
        'Internationalization (i18n)',
        'Language Service Provider (LSP)',
        'Legal Translation Services',
        'Linguistic Quality Assurance (LQA)',
        'Linguistic Validation',
        'Localization',
        'Localization Project Management',
        'Loco Translate',
        'Machine Translation (Automated Translation)',
        'Marketing Translations',
        'Medical Device Translation Services',
        'Multilanguage',
        'Multilingual Desktop Publishing (DTP)',
        'Multilingual Speech Recognition',
        'Neural Machine Translation (NMT)',
        'Polylang',
        'Professional Translation',
        'Redaction',
        'Secure Translation Services',
        'Software Translation',
        'Spatial Audio',
        'Speaker Verifcation',
        'Subtitling',
        'Technical Translation Services',
        'Terminology Management',
        'Text-to-Speech (TTS)',
        'Transcreation',
        'Transcription',
        'Translation Management Software',
        'Translation Memory',
        'Transliteration',
        'Transposh',
        'Video Localization',
        'WPML',
        'Website Localization Services',
        'Website Translation',
        'Weglot',
        'eLearning Localization'
      ])
    ]
  },
  {
    id: 157,
    label: 'Transportation',
    children: [
      ...new Set([
        'Air Charters',
        'Airline Route Expansion',
        'Business Aircraft',
        'Business Class',
        'Business Mileage',
        'Business Travel',
        'Economy Class Flights',
        'First Class',
        'Main Cabin',
        'Mileage Allowance',
        'MileagePlus',
        'Private Jet Charters',
        'SkyMiles',
        'Transportation',
        'Transportation Security Administration (TSA)',
        'Transportation Services'
      ])
    ]
  },
  {
    id: 158,
    label: 'Travel',
    children: [
      ...new Set([
        'Ace Group International, LLC',
        'Airbnb',
        'Alaska Airlines',
        'American Airlines Group Inc.',
        'American Hotel & Lodging Association',
        'Amex GBT (GBTG)',
        'Auberge Resorts Collection',
        'BCD Travel',
        'Booking.com for Business',
        'British Airways',
        'Business Air',
        'CWT Business Travel Management',
        'Capital One Spark Miles',
        'Choice Hotels International, Inc.',
        'Corporate Travel Management (CTMLF)',
        'Days Inn Worldwide, Inc.',
        'Delta Air Lines Inc.',
        'Egencia',
        'Expedia (EXPE)',
        'Four Seasons Hotels and Resorts',
        'Frontier Airlines',
        'Hertz Global Holdings',
        'Hilton Worldwide Holdings Inc.',
        'Hotels.com',
        'Howard Johnson International, Inc. (Howard Johnson by Wyndham)',
        'Hyatt Hotels Corporation',
        'Iberia',
        'InterContinental Hotels Group',
        'Internova Travel Group',
        'Intown Suites Management',
        'JDV by Hyatt (Joie de Vivre Hotels)',
        'JetBlue Airways Corporation',
        'Kimpton Hotel & Restaurant Group, LLC',
        'La Quinta by Wyndham',
        'Loews Hotels, Inc.',
        'Lyft',
        'Maritz',
        'Marriott Hotels',
        'Motel 6',
        'Oneworld',
        'Orbitz',
        'Palantir',
        'Priceline.com',
        'RLH Corporation (Red Lion Hotels Corporation)',
        'Red Roof Inn, Inc.',
        'Southwest Airlines Co.',
        'Spirit Airlines',
        'The Ritz-Carlton Hotel Company, L.L.C.',
        'TravelPerk',
        'Travelocity',
        'TripActions',
        'TripAdvisor',
        'Trivago N.V.',
        'Trump Hotels',
        'Tzell Travel Group',
        'Uber',
        'United Airlines',
        'Vrbo',
        'WoodSpring Suites',
        'World Travel Holdings, Inc.',
        'Wyndam Hotels & Resorts'
      ])
    ]
  },
  {
    id: 159,
    label: 'Trends',
    children: [
      ...new Set([
        'Ambient Intelligence',
        'Cognitive IoT',
        'Connected Cars',
        'Connected World',
        'Digital Health',
        'Internet of Everything (IoE)',
        'Internet of Things (IoT)',
        'Smart Home',
        'Wearable Technology'
      ])
    ]
  },
  {
    id: 160,
    label: 'Urban Planning',
    children: [
      ...new Set([
        'Affordable Housing',
        'Assisted Living',
        'Chief Resilience Officers',
        'City Procurement',
        'Civic Engagement',
        'Civic Technology',
        'Connected Cities',
        'Disaster Management',
        'NYC Zoning',
        'Occupational Safety & Health (OH&S)',
        'Residential Rental Property',
        'Resilient Cities',
        'Student Housing',
        'Sustainable Urban Development',
        'Traffic Control System',
        'Transportation Connectivity',
        'Urban Infrastructure',
        'Urban Mobility',
        'Urban Planning',
        'Urban Resilience',
        'Urban Sustainability',
        'Water and Sanitation',
        'Wayfinding',
        'Workforce Housing',
        'Zoning',
        'inclusive urban development'
      ])
    ]
  },
  {
    id: 161,
    label: 'Video',
    children: [
      ...new Set([
        'AV over IP',
        'Animated Videos',
        'Animation Services',
        'Automatic Video Measurement Systems',
        'Blu-ray',
        'Brand Video',
        'Corporate Video',
        'Digital Terrestrial Television (DTT)',
        'Digital Video Recorder (DVR)',
        'Dubbing',
        'Enterprise Video Platform (EVP)',
        'Explainer Video',
        'GoToWebinar',
        'High Definition (HD)',
        'Interactive Video',
        'Live Video Encoding',
        'Managed Video Delivery',
        'Online Video',
        'Online Video Platform',
        'Organic Light-Emitting Diode (OLED)',
        'Personalised Video',
        'Product Video',
        'Production Workflow',
        'Promotional Video',
        'Quad High Definition (QHD)',
        'Recruitment Video',
        'Shoppable Video',
        'Telepresence',
        'Ultra High Definition (UHD)',
        'Ultra High Definition Content',
        'Ultra High Definition Media Player',
        'Video Analytics',
        'Video Annotation',
        'Video Commerce',
        'Video Content',
        'Video Engagement',
        'Video Identity Verification',
        'Video Intelligence',
        'Video Interaction',
        'Video Management Software (VMS)',
        'Video Monetization',
        'Video Optimization',
        'Video Player',
        'Video Production Services',
        'Video SEO',
        'Video Streaming',
        'Video Transcoding',
        'Video Transmission',
        'Video as a Service (VaaS)',
        'Video-on-Demand',
        'Video-on-Demand Delivery'
      ])
    ]
  },
  {
    id: 162,
    label: 'Virtualization',
    children: [
      ...new Set([
        'Bare-Metal Hypervisor (Native Hypervisor)',
        'Computer Generated Imagery (CGI)',
        'Data Orchestration',
        'Digital Goods',
        'HCIA (Hyper-Converged Infrastructure Appliance)',
        'Hyper-Converged Infrastructure',
        'Industrial Metaverse',
        'Kernel-based Virtual Machine (KVM)',
        'Metaverse',
        'Network Virtualization',
        'Render Farm',
        'Roaming Profiles',
        'Server Virtualization',
        'Spatial Computing',
        'VPS Hosting',
        'Virtual Appliance',
        'Virtual Classroom',
        'Virtual Data Center',
        'Virtual Design and Construction',
        'Virtual Desktop Infrastructure (VDI)',
        'Virtual Desktops',
        'Virtual Firewall',
        'Virtual Infrastructure',
        'Virtual Land',
        'Virtual Machines',
        'Virtual Managed Services',
        'Virtual Office',
        'Virtual Platforms',
        'Virtual Servers',
        'Virtual Showroom',
        'Virtual Systems Administrator (VSA)',
        'Virtual Tour',
        'Virtual Wallet',
        'VirtualBox',
        'Virtualization'
      ])
    ]
  },
  {
    id: 163,
    label: 'Water Quality',
    children: [
      ...new Set([
        'Calorimetry',
        'Coliform Bacteria',
        'Containerized Wastewater System',
        'Containerized Water System',
        'Decentralized Wastewater System',
        'Drinking Water',
        'Groundwater Quality & Testing',
        'Lift Station',
        'Liquid Filtration Solutions',
        'Marine Salvage',
        'Oil Spill',
        'Onsite Wastewater System',
        'Portable Water System',
        'Ship-Based Pollution',
        'Turbidity',
        'Wastewater',
        'Wastewater Surveillance',
        'Water Flow Meter',
        'Water Meter',
        'Water Pollution',
        'Water Quality',
        'Water Treatment',
        'Zero Waste'
      ])
    ]
  },
  {
    id: 164,
    label: 'Web',
    children: [
      ...new Set([
        '301 Redirect',
        '3rd Party Cookies',
        '404 Error',
        'A/B Testing',
        'Ajax (Asynchronous JavaScript and XML)',
        'Application Security Testing (AST)',
        'BACnet',
        'Back-End Development',
        'Backend-as-a-Service (BaaS)',
        'Bandwidth/DIA',
        'CNAME Record',
        'Card Sorting',
        'Configurable Portals',
        'Connected TV (CTV)',
        'Content Syndication',
        'Cookie Deprecation',
        'Cookieless',
        'Core Web Vitals',
        'Cross-Origin Resource Sharing (CORS)',
        'Dedicated Internet Access',
        'Designated Internet Access',
        'Digital Magazines',
        'Digital Publishing',
        'Do Not Track (DNT)',
        'Domain Name System (DNS)',
        'Drupal',
        'Drupal Hosting',
        'Dynamic Application Security Testing (DAST)',
        'Edge Hosting',
        'Email Translation Service',
        'Enterprise Tag Management',
        'Everything as a service',
        'Exit Intent',
        'Favicon',
        'Fiddler Everywhere',
        'First Input Delay (FID)',
        'Front-End Development',
        'Gatsby',
        'Google Chat',
        'Google Meet',
        'Google Optimize',
        'Google Surveys',
        'Google Tag Manager',
        'HTML Code Editor',
        'Heat Maps',
        'High-Throughput Satellite (HTS)',
        'Hosting Platform',
        'IP Address',
        'Inbound Link',
        'Industrial Internet',
        'Instant Search',
        'Internal Search',
        'Internet Access',
        'Internet Bundles',
        'Internet Engineering Task Force (IETF)',
        'Internet Service',
        'JSON',
        'JSON Web Token (JWT)',
        'Joomla',
        'Linux Hosting',
        'Man In The Browser (MITB)',
        'Meta Tags',
        'Mobile Internet',
        'Next.js',
        'Nuxt',
        'OAuth 2',
        'Online Betting',
        'Online Community',
        'Open Web Application Security Project (OWASP)',
        'Opt In Management',
        'Over-the-Top Platform',
        'POST (HTTP)',
        'Portfolio Website',
        'Privacy Sandbox',
        'Private Browsing',
        'Progressive Web Applications (PWA)',
        'Really Simple Syndication (RSS)',
        'Recommendation Engine',
        'Relational Search',
        'Replatform Website',
        'Search Results Page Design',
        'Session Replay',
        'Single-Page Application (SPA)',
        'Static Application Security Testing (SAST)',
        'Strapi',
        'Talkpoint',
        'Top Level Domain',
        'Uniform Resource Locator',
        'WSDL (Web Services Description Language)',
        'Walled Garden',
        'Web Acceleration',
        'Web Accessibility',
        'Web Application Testing',
        'Web Applications',
        'Web Browsers',
        'Web Content Accessibility Guidelines (WCAG)',
        'Web Content Management',
        'Web Development',
        'Web Forms',
        'Web Portal',
        'Web Servers',
        'Web Service Management',
        'Web Service Security',
        'Web Sphere',
        'Web Traffic',
        'Web3',
        'WebAuthN',
        'WebRTC',
        'Webhook',
        'Website Content Localization',
        'Website Monetization',
        'Website Monitoring',
        'Website Navigation',
        'Website Tagging',
        'cURL',
        'dotCMS',
        'eCommerce Site Search',
        'iBooks',
        'jQuery'
      ])
    ]
  },
  {
    id: 165,
    label: 'Web Browser',
    children: [
      ...new Set([
        'Flash Player'
      ])
    ]
  },
  {
    id: 166,
    label: 'Website Publishing',
    children: [
      ...new Set([
        'Accelerated Mobile Pages (AMP)',
        'Blog Comments',
        'Blogging',
        'Conversion Funnel',
        'Corporate Blogging',
        'Landing Pages',
        'Multilingual Website',
        'Responsive Animations',
        'Site Management',
        'Site Personalization',
        'Style Guide',
        'User-Generated Content',
        'Video Blogs',
        'Web Analytics',
        'Web Content',
        'Web Copy',
        'Web Site Hosting',
        'Website Builders',
        'Website Design'
      ])
    ]
  },
  {
    id: 167,
    label: 'Wellness and Safety',
    children: [
      ...new Set([
        'Calm Business',
        'Counseling / Employee Assistance Programs (EAPs)',
        'Drug and Alcohol Testing / Treatment',
        'Emergency Response',
        'Employee Safety',
        'Fire Safety',
        'Health Promotion / Recreation / Wellness Benefits',
        'On-Site Medical Care',
        'Risk Management Services',
        'Safety Integrity Level (SIL)',
        'Seafarer Wellbeing',
        'Social and Emotional Learning',
        'Workplace Safety'
      ])
    ]
  },
  {
    id: 168,
    label: 'Wireless',
    children: [
      ...new Set([
        '802.11ax',
        'Fixed Wireless',
        'Guest WiFi',
        'Multi-Access Edge Computing (MEC)',
        'Passpoint',
        'Wi-Fi 6',
        'Wireless Application Protocol (WAP)',
        'Wireless Communications',
        'Wireless Hardware',
        'Wireless Infrastructure',
        'Wireless LAN (WLAN)',
        'Wireless Messaging',
        'Wireless Networking',
        'Wireless Printing',
        'Wireless Security',
        'Wireless Service Providers'
      ])
    ]
  },
  {
    id: 169,
    label: 'eCommerce',
    children: [
      ...new Set([
        '3dcart',
        'Accelerated Checkout',
        'Amazon Seller Central',
        'AsiaPay',
        'Astound Commerce',
        'B2B E-Commerce',
        'B2C eCommerce',
        'Bank Card',
        'Booking Holdings Inc.',
        'Carta',
        'ChannelAdvisor (ECOM)',
        'Checkout Optimization',
        'CloudCraze',
        'Clover',
        'CommerceHub',
        'Conversational Commerce',
        'Creator Economy',
        'DHL Supply Chain North America',
        'DTC E-Commerce',
        'E-Commerce Development',
        'E-Commerce Logistics',
        'E-Commerce Refunds & Returns',
        'Ecommerce Accelerator',
        'Ecommerce Fraud',
        'Ecommerce Migration',
        'Enterprise Ecommerce',
        'False Declines',
        'FedEx Supply Chain',
        'Feed Management Software',
        'Flipkart Private Limited',
        'Friendly Fraud',
        'Google Feed Management',
        'Groupby',
        'Groupon',
        'IBM Blockchain',
        'Instagram Shopping Feed',
        'KonaKart',
        'LevelUp',
        'Liquidity Services',
        'Listing Optimization Tool',
        'LivingSocial Inc.',
        'Loop Returns',
        'Magento',
        'Magento 2',
        'Meesho',
        'Merchant e-Solutions',
        'Multi Marketplace Listing Software',
        'Odoo',
        'Online Checkout',
        'Online Marketplace',
        'Oracle Commerce Platform',
        'PFSweb (PFSW)',
        'PayPal Checkout',
        'PayTM',
        'Payment Processing',
        'Personalized Recommendations',
        'Pitney Bowes Ecommerce',
        'PowerReviews',
        'Poynt',
        'Qubit',
        'Radial',
        'Rakuten',
        'Recommender System',
        'SAP Upscale Commerce',
        'SPS Commerce',
        'Scalefast',
        'Searchspring',
        'ShopKeep',
        'Shopify Plus',
        'Shopify Returns Management',
        'Shopper Recognition',
        'Shopping Cart Software',
        'Signifyd',
        'Smile.io',
        'Stripe',
        'Student Beans',
        'Symphony Commerce',
        'The Hut Group',
        'UNiDAYS',
        'UPS Supply Chain Solutions',
        'Verifone (PAY)',
        'Vistaprint',
        'Webstore',
        'WooCommerce',
        'Worldscale',
        'Yotpo',
        'Zuora',
        'eWay',
        'nChannel'
      ])
    ]
  }
]

export const industryData = [
  'Accounting',
  'Agriculture',
  'Airlines/Aviation',
  'Alternative Dispute Resolution',
  'Alternative Medicine',
  'Animation',
  'Apparel & Fashion',
  'Architecture & Planning',
  'Arts & Crafts',
  'Automotive',
  'Aviation & Aerospace',
  'Banking',
  'Biotechnology',
  'Broadcast Media',
  'Building Materials',
  'Business Supplies & Equipment',
  'Capital Markets',
  'Chemicals',
  'Civic & Social Organization',
  'Civil Engineering',
  'Commercial Real Estate',
  'Computer & Network Security',
  'Computer Games',
  'Computer Hardware',
  'Computer Networking',
  'Computer Software',
  'Construction',
  'Consumer Electronics',
  'Consumer Goods',
  'Consumer Services',
  'Cosmetics',
  'Dairy',
  'Defense & Space',
  'Design',
  'E-Learning',
  'Education Management',
  'Electrical/Electronic Manufacturing',
  'Entertainment',
  'Environmental Services',
  'Events Services',
  'Executive Office',
  'Facilities Services',
  'Farming',
  'Financial Services',
  'Fine Art',
  'Fishery',
  'Food & Beverages',
  'Food Production',
  'Fund-Raising',
  'Furniture',
  'Gambling & Casinos',
  'Glass, Ceramics & Concrete',
  'Government Administration',
  'Government Relations',
  'Graphic Design',
  'Health, Wellness & Fitness',
  'Higher Education',
  'Hospital & Health Care',
  'Hospitality',
  'Human Resources',
  'Import & Export',
  'Individual & Family Services',
  'Industrial Automation',
  'Information Services',
  'Information Technology & Services',
  'Insurance',
  'International Affairs',
  'International Trade & Development',
  'Internet',
  'Investment Banking',
  'Investment Management',
  'Judiciary',
  'Law Enforcement',
  'Law Practice',
  'Legal Services',
  'Legislative Office',
  'Leisure, Travel & Tourism',
  'Libraries',
  'Logistics & Supply Chain',
  'Luxury Goods & Jewelry',
  'Machinery',
  'Management Consulting',
  'Maritime',
  'Market Research',
  'Marketing & Advertising',
  'Mechanical or Industrial Engineering',
  'Media Production',
  'Medical Devices',
  'Medical Practices',
  'Mental Health Care',
  'Military',
  'Mining & Metals',
  'Motion Pictures & Film',
  'Museums & Institutions',
  'Music',
  'Nanotechnology',
  'Newspapers',
  'Nonprofit Organization Management',
  'Oil & Energy',
  'Online Media',
  'Outsourcing/Offshoring',
  'Package/Freight Delivery',
  'Packaging & Containers',
  'Paper & Forest Products',
  'Performing Arts',
  'Pharmaceuticals',
  'Paralegal',
  'Philanthropy',
  'Photography',
  'Plastics',
  'Political Organization',
  'Primary/Secondary Education',
  'Printing',
  'Professional Training & Coaching',
  'Program Development',
  'Public Policy',
  'Public Relations & Communications',
  'Public Safety',
  'Publishing',
  'Railroad Manufacture',
  'Ranching',
  'Real Estate',
  'Recreational Facilities & Services',
  'Religious Institutions',
  'Renewables & Environment',
  'Research',
  'Restaurants',
  'Retail',
  'Security & Investigations',
  'Semiconductors',
  'Shipbuilding',
  'Sporting Goods',
  'Sports',
  'Staffing & Recruiting',
  'Supermarkets',
  'Telecommunications',
  'Textiles',
  'Think Tanks',
  'Tobacco',
  'Translation & Localization',
  'Transportation/Trucking/Railroad',
  'Utilities',
  'Venture Capital & Private Equity',
  'Veterinary',
  'Warehousing',
  'Wholesale',
  'Wine & Spirits',
  'Wireless',
  'Writing & Editing'
]

export const name = []

export const degreeData = ['Bachelors', 'Masters', 'Associates', 'High School', 'Doctorate']

export const majorData = [
  {
    id: 0,
    label: 'Score',
    children: [
      ...new Set([
        'High',
        'Medium',
        'Low'
      ])
    ]
  }
]

export const jobTitles = [
  'manager',
  'project manager',
  'teacher',
  'owner',
  'student',
  'director',
  'software engineer',
  'consultant',
  'account manager',
  'engineer',
  'professor',
  'sales manager',
  'sales',
  'partner',
  'associate',
  'president',
  'administrative assistant',
  'supervisor',
  'general manager',
  'realtor',
  'chief executive officer (ceo)',
  'chief operating officer (coo)',
  'chief financial officer (cfo)',
  'chief marketing officer (cmo)',
  'chief technology officer (cto)',
  'chief information officer (cio)',
  'chief sales officer (cso)',
  'chief revenue officer (cro)',
  'senior management',
  'vice president of sales',
  'vice president of marketing',
  'vice president of operations',
  'vice president of finance',
  'vice president of technology',
  'vice president of business development',
  'mid-level management',
  'sales director',
  'marketing director',
  'operations director',
  'finance director',
  'product manager',
  'business development manager',
  'it manager',
  'customer success manager',
  'specialized roles',
  'lead generation specialist',
  'digital marketing manager',
  'content marketing manager',
  'data analyst',
  'sales enablement manager',
  'product marketing manager',
  'brand manager',
  'administrative and support',
  'executive assistant',
  'office manager',
  'sales and business development',
  'account executive',
  'sales operations manager',
  'sales development representative (sdr)',
  'business development representative (bdr)',
  'regional sales manager',
  'enterprise sales manager',
  'channel sales manager',
  'sales strategy manager',
  'marketing',
  'growth marketing manager',
  'digital advertising specialist',
  'seo specialist',
  'ppc specialist',
  'email marketing manager',
  'marketing operations manager',
  'marketing analytics manager',
  'event marketing manager',
  'social media manager',
  'public relations manager',
  'product and development',
  'product development manager',
  'product owner',
  'product director',
  'ux/ui designer',
  'technical product manager',
  'engineering manager',
  'head of product',
  'innovation manager',
  'finance and administration',
  'controller',
  'treasurer',
  'finance manager',
  'accounting manager',
  'financial analyst',
  'audit manager',
  'compliance officer',
  'operations',
  'operations manager',
  'supply chain manager',
  'logistics manager',
  'procurement manager',
  'facilities manager',
  'quality assurance manager',
  'customer and support',
  'customer support manager',
  'customer experience manager',
  'client success manager',
  'technical support specialist',
  'customer service representative',
  'human resources',
  'hr director',
  'hr manager',
  'talent acquisition manager',
  'recruitment specialist',
  'learning and development manager',
  'compensation and benefits manager',
  'technology and it',
  'it director',
  'network administrator',
  'systems administrator',
  'database administrator',
  'cloud solutions architect',
  'it support specialist',
  'cybersecurity specialist',
  'legal and compliance',
  'general counsel',
  'corporate counsel',
  'compliance manager',
  'legal advisor',
  'contract manager'
]

export const skills = []
export const yearsOfExperience = [
  '10000',
  '50000',
  '100000',
  '500000',
  '1000000',
  '5000000',
  '10000000',
  '50000000',
  '100000000',
  '500000000',
  '1000000000'
]
export const companyName = []

export const employeeCountCompany = [
  '1', '10',
  '11', '50',
  '51', '100',
  '101', '500',
  '501', '1000',
  '1001', '5000',
  '5001', '10000',
  '10001', '25000',
  '25000'
]

export const employeeCount = [
  '1 , 10',
  '11 , 50',
  '51 , 100',
  '101 , 500',
  '501 , 1000',
  '1001 , 5000',
  '5001 , 10000',
  '10001 , 25000',
  '25000'
]

export const revenue = [
  {
    label: '$1M',
    value: '1000000'
  },
  {
    label: '$5M',
    value: '5000000'
  },
  {
    label: '$10M',
    value: '10000000'
  },
  {
    label: '$50M',
    value: '50000000'
  },
  {
    label: '$100M',
    value: '100000000'
  },
  {
    label: '$500M',
    value: '500000000'
  },
  {
    label: '$1B',
    value: '1000000000'
  },
  {
    label: '$5B',
    value: '5000000000'
  }
]

export const employeeGrowth = [
  {
    label: '-100% -> 50%',
    value: '~100-~50'
  },
  {
    label: '-50% -> 0%',
    value: '~50-0'
  },
  {
    label: '0% -> 25%',
    value: '0-25'
  },
  {
    label: '25% -> 50%',
    value: '25-50'
  },
  {
    label: '50% -> 100%',
    value: '50-100'
  },
  {
    label: '100%+',
    value: '100<'
  }
]

export const departments = [
  {
    id: 0,
    label: 'All Departments',
    children: [
      'Human Resources (HR)',
      'Finance',
      'Information Technology (IT)',
      'Marketing',
      'Sales',
      'Operations',
      'Customer Support',
      'Legal',
      'Research & Development (R&D)',
      'Procurement/Purchasing',
      'Administration',
      'Public Relations (PR)',
      'Executive',
      'Product Management',
      'Business Development'
    ]
  }
]

export const languages = [
  'Abkhazian',
  'Afar',
  'Afrikaans',
  'Akan',
  'Albanian',
  'Amharic',
  'Arabic',
  'Aragonese',
  'Armenian',
  'Assamese',
  'Avaric',
  'Avestan',
  'Aymara',
  'Azerbaijani',
  'Bambara',
  'Bashkir',
  'Basque',
  'Belarusian',
  'Bengali',
  'Bihari languages',
  'Bislama',
  'Bosnian',
  'Breton',
  'Bulgarian',
  'Burmese',
  'Catalan, Valencian',
  'Central Khmer',
  'Chamorro',
  'Chechen',
  'Chichewa, Chewa, Nyanja',
  'Chinese',
  'Church Slavonic, Old Bulgarian, Old Church Slavonic',
  'Chuvash',
  'Cornish',
  'Corsican',
  'Cree',
  'Croatian',
  'Czech',
  'Danish',
  'Divehi, Dhivehi, Maldivian',
  'Dutch, Flemish',
  'Dzongkha',
  'English',
  'Esperanto',
  'Estonian',
  'Ewe',
  'Faroese',
  'Fijian',
  'Finnish',
  'French',
  'Fulah',
  'Gaelic, Scottish Gaelic',
  'Galician',
  'Ganda',
  'Georgian',
  'German',
  'Gikuyu, Kikuyu',
  'Greek (Modern)',
  'Greenlandic, Kalaallisut',
  'Guarani',
  'Gujarati',
  'Haitian, Haitian Creole',
  'Hausa',
  'Hebrew',
  'Herero',
  'Hindi',
  'Hiri Motu',
  'Hungarian',
  'Icelandic',
  'Ido',
  'Igbo',
  'Indonesian',
  'Interlingua (International Auxiliary Language Association)',
  'Interlingue',
  'Inuktitut',
  'Inupiaq',
  'Irish',
  'Italian',
  'Japanese',
  'Javanese',
  'Kannada',
  'Kanuri',
  'Kashmiri',
  'Kazakh',
  'Kinyarwanda',
  'Komi',
  'Kongo',
  'Korean',
  'Kwanyama, Kuanyama',
  'Kurdish',
  'Kyrgyz',
  'Lao',
  'Latin',
  'Latvian',
  'Letzeburgesch, Luxembourgish',
  'Limburgish, Limburgan, Limburger',
  'Lingala',
  'Lithuanian',
  'Luba-Katanga',
  'Macedonian',
  'Malagasy',
  'Malay',
  'Malayalam',
  'Maltese',
  'Manx',
  'Maori',
  'Marathi',
  'Marshallese',
  'Moldovan, Moldavian, Romanian',
  'Mongolian',
  'Nauru',
  'Navajo, Navaho',
  'Northern Ndebele',
  'Ndonga',
  'Nepali',
  'Northern Sami',
  'Norwegian',
  'Norwegian Bokmål',
  'Norwegian Nynorsk',
  'Nuosu, Sichuan Yi',
  'Occitan (post 1500)',
  'Ojibwa',
  'Oriya',
  'Oromo',
  'Ossetian, Ossetic',
  'Pali',
  'Panjabi, Punjabi',
  'Pashto, Pushto',
  'Persian',
  'Polish',
  'Portuguese',
  'Quechua',
  'Romansh',
  'Rundi',
  'Russian',
  'Samoan',
  'Sango',
  'Sanskrit',
  'Sardinian',
  'Serbian',
  'Shona',
  'Sindhi',
  'Sinhala, Sinhalese',
  'Slovak',
  'Slovenian',
  'Somali',
  'Sotho, Southern',
  'South Ndebele',
  'Spanish, Castilian',
  'Sundanese',
  'Swahili',
  'Swati',
  'Swedish',
  'Tagalog',
  'Tahitian',
  'Tajik',
  'Tamil',
  'Tatar',
  'Telugu',
  'Thai',
  'Tibetan',
  'Tigrinya',
  'Tonga (Tonga Islands)',
  'Tsonga',
  'Tswana',
  'Turkish',
  'Turkmen',
  'Twi',
  'Uighur, Uyghur',
  'Ukrainian',
  'Urdu',
  'Uzbek',
  'Venda',
  'Vietnamese',
  'Volap_k',
  'Walloon',
  'Welsh',
  'Western Frisian',
  'Wolof',
  'Xhosa',
  'Yiddish',
  'Yoruba',
  'Zhuang, Chuang',
  'Zulu'
]

export const emailTypes = ['Professional Email', 'Personal Email']
export const companyLists = []
export const education = []
export const contactInfo = []
export const socialLink = []
export const keywords = []
export const exclude = []

export const filterIndustryList = [
  // {
  //   name: "domain",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFCompanyName sx={{}} />
  //     </Box>
  //   ),
  //   label: "Domain",
  //   placeHolder: "Domain",
  // },
  {
    name: 'geo',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <LFLocation sx={{}} />
      </Box>
    ),
    label: 'Country',
    placeHolder: 'Country',
    menuItems: locationData,
    select: true
  },
  {
    name: 'nGeo',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <LFLocation sx={{}} />
      </Box>
    ),
    label: 'State',
    placeHolder: 'State',
    menuItems: locationStateData,
    select: true
  },
  {
    title: '',
    name: 'cGeo',
    placeHolder: 'City',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <LFLocation sx={{}} />
      </Box>
    ),
    label: 'City',
    menuItems: locationCityData,
    select: true
  },
  {
    name: 'name',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <LFCompanyName sx={{}} />
      </Box>
    ),
    label: 'Company Name',
    placeHolder: 'Company Name'
  },
  {
    name: 'founded_year',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <LFCompanyName sx={{}} />
      </Box>
    ),
    label: 'Founded Year',
    placeHolder: 'Founded Year'
  },
  {
    name: 'crunchbase_url',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <LFCompanyName sx={{}} />
      </Box>
    ),
    label: 'Crunchbase URL',
    placeHolder: 'Crunchbase URL'
  },
  {
    name: 'linkedin_url',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <LFCompanyName sx={{}} />
      </Box>
    ),
    label: 'Linkedin URL',
    placeHolder: 'Linkedin URL'
  },
  {
    name: 'keyword',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <Abc sx={{ color: '#28287B' }} />
      </Box>
    ),
    label: 'Organization Id',
    placeHolder: 'organization Id'
  },
  {
    name: 'industry',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <LFIndustry sx={{}} />
      </Box>
    ),
    label: 'Industry',
    placeHolder: 'Industry',
    select: true,
    suggestion: true,
    suggestionName: 'company_industry',
    menuItems: industryData
  },

  // {
  //   name: "sic_code",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFKeywords sx={{}} />
  //     </Box>
  //   ),
  //   select: true,
  //   menuItems: [],
  //   label: "SIC Code",
  //   suggestion: false,
  //   placeHolder: "Enter SIC code",
  // },
  // {
  //   name: "naics_code",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFKeywords sx={{}} />
  //     </Box>
  //   ),
  //   select: true,
  //   menuItems: [],
  //   label: "NAICS Code",
  //   suggestion: false,
  //   placeHolder: "Enter NAICS code",
  // },
  {
    name: 'employees',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <LFEmployees sx={{}} />
      </Box>
    ),
    label: 'Employee Count',
    placeHolder: 'Employee Count',
    select: true,
    menuItems: employeeCount
  },
  // {
  //   name: "growth",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFEmployees sx={{}} />
  //     </Box>
  //   ),
  //   label: "Employee Growth",
  //   placeHolder: "Employee Growth",
  //   select: true,
  //   menuItems: employeeGrowth,
  // },
  // {
  //   name: "revenue",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFRevenue sx={{}} />
  //     </Box>
  //   ),
  //   label: "Revenue",
  //   placeHolder:"Revenue",
  //   select: true,
  //   menuItems: revenue,
  // },
  {
    name: 'techstack',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <LFTechnologies sx={{ color: '#28287B' }} />
      </Box>
    ),
    label: 'Technology',
    placeHolder: 'Technology',
    select: true,
    suggestion: true,
    menuItems: [
      'Programming Languages',
      'JavaScript',
      'Python',
      'Java',
      'Ruby',
      'PHP',
      'C#',
      'Go',
      'Swift',
      'Kotlin',
      'TypeScript',
      'Web Technologies & Frameworks',
      'React',
      'Angular',
      'Vue.js',
      'jQuery',
      'Django',
      'Flask',
      'Ruby on Rails',
      'Laravel',
      'Spring',
      'ASP.NET',
      'Databases',
      'MySQL',
      'PostgreSQL',
      'MongoDB',
      'Redis',
      'SQLite',
      'Elasticsearch',
      'Cassandra',
      'DynamoDB',
      'Firebase',
      'Cloud Services',
      'AWS',
      'Google Cloud',
      'Azure',
      'Heroku',
      'DigitalOcean',
      'Cloudflare',
      'Kubernetes',
      'Docker',
      'DevOps & CI/CD',
      'Jenkins',
      'Travis CI',
      'CircleCI',
      'GitLab CI',
      'Ansible',
      'Terraform',
      'Chef',
      'Puppet',
      'GitHub Actions',
      'CRM & Marketing Tools',
      'Salesforce',
      'HubSpot',
      'Marketo',
      'Pipedrive',
      'Zoho CRM',
      'Intercom',
      'E-commerce Platforms',
      'Shopify',
      'WooCommerce',
      'Magento',
      'BigCommerce',
      'Mobile Development',
      'React Native',
      'Flutter',
      'Ionic',
      'Swift',
      'Kotlin',
      'Xamarin',
      'Analytics & BI Tools',
      'Google Analytics',
      'Tableau',
      'Power BI',
      'Mixpanel',
      'Segment',
      'Looker',
      'Email & Communication Platforms',
      'SendGrid',
      'Mailchimp',
      'Twilio',
      'Slack',
      'Zendesk',
      'Content Management Systems (CMS)',
      'WordPress',
      'Drupal',
      'Joomla',
      'Contentful',
      'Wix',
      'Cybersecurity Tools',
      'Cloudflare',
      'Palo Alto Networks',
      'Norton',
      'Okta',
      'Splunk'
    ]
  },
  {
    title: '',
    name: 'intentTopic',
    placeHolder: 'Intent Topics',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <LFLocation sx={{}} />
      </Box>
    ),
    label: 'Intent Topics',
    menuItems: IntentTopic,
    select: true
  },
  {
    name: 'intentScore',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <LFDepartment sx={{}} />
      </Box>
    ),
    select: true,
    menuItems: majorData,
    label: 'Intent Score',
    suggestion: true,
    placeHolder: 'Intent Score'
  }
]

export const filterList = [
  // Person Info.
  {
    title: '',
    name: 'geo',
    placeHolder: 'Country',
    // icon: (
    //   <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
    //     <LFLocation sx={{}} />
    //   </Box>
    // ),
    label: 'Country',
    menuItems: locationData,
    select: true
  },
  {
    title: '',
    name: 'oGeo',
    placeHolder: 'States',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <LFLocation sx={{}} />
      </Box>
    ),
    label: 'States',
    menuItems: locationStateData,
    select: true
  },
  {
    title: '',
    name: 'cGeo',
    placeHolder: 'City',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <LFLocation sx={{}} />
      </Box>
    ),
    label: 'City',
    menuItems: locationCityData,
    select: true
  },
  {
    title: '',
    name: 'name',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <LFName sx={{ color: '#28287B' }} />
      </Box>
    ),
    label: 'Name',
    placeHolder: 'Enter name of the person...'
  },
  {
    title: '',
    name: 'current_title',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <LFJobTitles sx={{}} />
      </Box>
    ),
    label: 'Job Titles',
    placeHolder: 'Job Titles',
    menuItems: jobTitles,
    suggestion: true,
    select: true
  },
  {
    title: '',
    name: 'major',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <MailOutlineIcon sx={{ color: '#000000', opacity: 0.7 }} />
      </Box>
    ),
    select: true,
    menuItems: [
      'Programming Languages',
      'JavaScript',
      'Python',
      'Java',
      'Ruby',
      'PHP',
      'C#',
      'Go',
      'Swift',
      'Kotlin',
      'TypeScript',
      'Web Technologies & Frameworks',
      'React',
      'Angular',
      'Vue.js',
      'jQuery',
      'Django',
      'Flask',
      'Ruby on Rails',
      'Laravel',
      'Spring',
      'ASP.NET',
      'Databases',
      'MySQL',
      'PostgreSQL',
      'MongoDB',
      'Redis',
      'SQLite',
      'Elasticsearch',
      'Cassandra',
      'DynamoDB',
      'Firebase',
      'Cloud Services',
      'AWS',
      'Google Cloud',
      'Azure',
      'Heroku',
      'DigitalOcean',
      'Cloudflare',
      'Kubernetes',
      'Docker',
      'DevOps & CI/CD',
      'Jenkins',
      'Travis CI',
      'CircleCI',
      'GitLab CI',
      'Ansible',
      'Terraform',
      'Chef',
      'Puppet',
      'GitHub Actions',
      'CRM & Marketing Tools',
      'Salesforce',
      'HubSpot',
      'Marketo',
      'Pipedrive',
      'Zoho CRM',
      'Intercom',
      'E-commerce Platforms',
      'Shopify',
      'WooCommerce',
      'Magento',
      'BigCommerce',
      'Mobile Development',
      'React Native',
      'Flutter',
      'Ionic',
      'Swift',
      'Kotlin',
      'Xamarin',
      'Analytics & BI Tools',
      'Google Analytics',
      'Tableau',
      'Power BI',
      'Mixpanel',
      'Segment',
      'Looker',
      'Email & Communication Platforms',
      'SendGrid',
      'Mailchimp',
      'Twilio',
      'Slack',
      'Zendesk',
      'Content Management Systems (CMS)',
      'WordPress',
      'Drupal',
      'Joomla',
      'Contentful',
      'Wix',
      'Cybersecurity Tools',
      'Cloudflare',
      'Palo Alto Networks',
      'Norton',
      'Okta',
      'Splunk'
    ],
    label: 'Technologies',
    suggestion: true,
    placeHolder: 'Technologies'
  },
  {
    title: '',
    name: 'language',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <MailOutlineIcon sx={{ color: '#000000', opacity: 0.7 }} />
      </Box>
    ),
    select: true,
    menuItems: languages,
    label: 'Languages',
    suggestion: true,
    placeHolder: 'Languages'
  },
  {
    title: '',
    name: 'company_name',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <LFName sx={{ color: '#28287B' }} />
      </Box>
    ),
    label: 'Company Name',
    placeHolder: 'Enter name of the company...'
  },
  {
    title: '',
    name: 'industry_name',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <LFName sx={{ color: '#28287B' }} />
      </Box>
    ),
    menuItems: [...new Set(Department)],
    label: 'Company Industry',
    placeHolder: 'Company Industry...',
    select: true
  },
  {
    title: '',
    name: 'link',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <LFTechnologies sx={{}} />
      </Box>
    ),
    label: 'Domain',
    placeHolder: 'Domain'
  },
  // {
  //   name: "km",
  //   label: "KM",
  //   menuItems: marks,
  //   select: false,
  // },
  {
    title: '',
    name: 'LinkedIn',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <LFDepartment sx={{}} />
      </Box>
    ),
    label: 'LinkedIn Url',
    placeHolder: 'LinkedIn Url'

  },
  // {
  //   name: "job_change_range_days",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFLocation sx={{}} />
  //     </Box>
  //   ),
  //   label: "Changed Jobs Within",
  //   placeHolder: "Changed Jobs Within",
  //   menuItems: TimeData,
  //   select: true,
  // },
  // {
  //   name: "skills",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFKeywords sx={{}} />
  //     </Box>
  //   ),
  //   select: true,
  //   menuItems: [],
  //   label: "Skills",
  //   suggestion: true,
  //   placeHolder: "Skills",
  // },
  {
    name: 'years_experience',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        {/* <LFLookalikeDomain sx={{}} /> */}
      </Box>
    ),
    label: 'Total Funding',
    placeHolder: 'Total Funding',
    select: true,
    menuItems: yearsOfExperience
  },
  // {
  //   name: "employer",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFCompanyName sx={{}} />
  //     </Box>
  //   ),
  //   select: true,
  //   menuItems: [],
  //   label: "Company Name or Domain",
  //   suggestion: true,
  //   placeHolder: "Company Name or Domain",
  // },
  // {
  //   name: "include_past",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFCompanyName sx={{}} />
  //     </Box>
  //   ),
  //   label: "Include Past Employers",
  //   placeHolder: "Include Past Employers",
  // },
  {
    title: '',
    name: 'company_size',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <LFEmployees sx={{}} />
      </Box>
    ),
    label: 'Employee Count',
    placeHolder: 'Employee Count',
    select: true,
    menuItems: employeeCount
  },
  {
    name: 'revenue',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        {/* <LFRevenue sx={{}} /> */}
      </Box>
    ),
    label: 'Annual Revenue',
    placeHolder: 'Annual Revenue',
    select: true,
    menuItems: revenue
  },
  // {
  //   name: "company_industry",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFIndustry sx={{}} />
  //     </Box>
  //   ),
  //   label: "Industry",
  //   placeHolder: "Industry",
  //   select: true,
  //   suggestion: true,
  //   menuItems: industryData,
  // },
  // {
  //   name: "company_name",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFIndustry sx={{}} />
  //     </Box>
  //   ),
  //   label: "Company List",
  //   select: true,
  //   menuItems: ['CSV Upload'],
  // },
  /* {
    name: "email_type",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFJobTitles sx={{}} />
      </Box>
    ),
    label: "Email Type",
    menuItems: emailTypes,
    select: true,
  }, */
  // {
  //   name: "school",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFDepartment sx={{}} />
  //     </Box>
  //   ),
  //   select: true,
  //   label: "Education School",
  //   placeHolder: "Education School",
  //   menuItems: [],
  //   suggestion: true,
  // },
  // {
  //   name: "email",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <BadgeOutlined sx={{ color: "#28287B" }} />
  //     </Box>
  //   ),
  //   label: "Contact Info",
  //   placeHolder: "Enter email of the person...",
  // },
  // {
  //   name: "link",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFTechnologies sx={{}} />
  //     </Box>
  //   ),
  //   label: "Social",
  //   placeHolder: "Enter Linkedin or Twitter url...",
  // },
  // {
  //   title: "",
  //   name: "keyword",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <Abc sx={{ color: "#28287B" }} />
  //     </Box>
  //   ),
  //   label: "Organization Id",
  //   placeHolder: "Organization Id",
  // },
  {
    title: '',
    menuItems: [
      'departments',
      'education campus',
      'education major',
      'education specialization',
      'employment history description',
      'employement history start date',
      'employment history title',
      'languages',
      'title'
    ],
    name: 'skills',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <LFName sx={{ color: '#28287B' }} />
      </Box>
    ),
    select: true,
    suggestion: true,
    label: 'Keywords',
    placeHolder: 'Enter Keywords...'
  },
  // {
  //   name: "exclude_keyword",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <BackspaceOutlined sx={{ color: "#28287B" }} />
  //     </Box>
  //   ),
  //   label: "Exclude",
  //   placeHolder: "Exclude",
  // },
  // {
  //   title: "",
  //   name: "school",
  //   icon: (
  //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
  //       <LFManagementLevels sx={{}} />
  //     </Box>
  //   ),
  //   label: "Intent",
  //   placeHolder: "Intent",
  //   menuItems: Intent,
  //   select: true,
  // },
  {
    title: '',
    name: 'intentTopic',
    placeHolder: 'Intent Topics',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <LFLocation sx={{}} />
      </Box>
    ),
    label: 'Intent Topics',
    menuItems: IntentTopic,
    select: true
  },
  {
    name: 'intentScore',
    icon: (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <LFDepartment sx={{}} />
      </Box>
    ),
    select: true,
    menuItems: majorData,
    label: 'Intent Score',
    suggestion: true,
    placeHolder: 'Intent Score'
  }
]

export const timingIntervals = [
  '0:00 AM',
  '0:30 AM',
  '1:00 AM',
  '1:30 AM',
  '2:00 AM',
  '2:30 AM',
  '3:00 AM',
  '3:30 AM',
  '4:00 AM',
  '4:30 AM',
  '5:00 AM',
  '5:30 AM',
  '6:00 AM',
  '6:30 AM',
  '7:00 AM',
  '7:30 AM',
  '8:00 AM',
  '8:30 AM',
  '9:00 AM',
  '9:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '1:00 PM',
  '1:30 PM',
  '2:00 PM',
  '2:30 PM',
  '3:00 PM',
  '3:30 PM',
  '4:00 PM',
  '4:30 PM',
  '5:00 PM',
  '5:30 PM',
  '6:00 PM',
  '6:30 PM',
  '7:00 PM',
  '7:30 PM',
  '8:00 PM',
  '8:30 PM',
  '9:00 PM',
  '9:30 PM',
  '10:00 PM',
  '10:30 PM',
  '11:00 PM',
  '11:30 PM'
]

const timezones = [
  {
    offset: 'GMT-12:00',
    name: 'Etc/GMT-12'
  },
  {
    offset: 'GMT-11:00',
    name: 'Etc/GMT-11'
  },
  {
    offset: 'GMT-11:00',
    name: 'Pacific/Midway'
  },
  {
    offset: 'GMT-10:00',
    name: 'America/Adak'
  },
  {
    offset: 'GMT-09:00',
    name: 'America/Anchorage'
  },
  {
    offset: 'GMT-09:00',
    name: 'Pacific/Gambier'
  },
  {
    offset: 'GMT-08:00',
    name: 'America/Dawson_Creek'
  },
  {
    offset: 'GMT-08:00',
    name: 'America/Ensenada'
  },
  {
    offset: 'GMT-08:00',
    name: 'America/Los_Angeles'
  },
  {
    offset: 'GMT-07:00',
    name: 'America/Chihuahua'
  },
  {
    offset: 'GMT-07:00',
    name: 'America/Denver'
  },
  {
    offset: 'GMT-06:00',
    name: 'America/Belize'
  },
  {
    offset: 'GMT-06:00',
    name: 'America/Cancun'
  },
  {
    offset: 'GMT-06:00',
    name: 'America/Chicago'
  },
  {
    offset: 'GMT-06:00',
    name: 'Chile/EasterIsland'
  },
  {
    offset: 'GMT-05:00',
    name: 'America/Bogota'
  },
  {
    offset: 'GMT-05:00',
    name: 'America/Havana'
  },
  {
    offset: 'GMT-05:00',
    name: 'America/New_York'
  },
  {
    offset: 'GMT-04:30',
    name: 'America/Caracas'
  },
  {
    offset: 'GMT-04:00',
    name: 'America/Campo_Grande'
  },
  {
    offset: 'GMT-04:00',
    name: 'America/Glace_Bay'
  },
  {
    offset: 'GMT-04:00',
    name: 'America/Goose_Bay'
  },
  {
    offset: 'GMT-04:00',
    name: 'America/Santiago'
  },
  {
    offset: 'GMT-04:00',
    name: 'America/La_Paz'
  },
  {
    offset: 'GMT-03:00',
    name: 'America/Argentina/Buenos_Aires'
  },
  {
    offset: 'GMT-03:00',
    name: 'America/Montevideo'
  },
  {
    offset: 'GMT-03:00',
    name: 'America/Araguaina'
  },
  {
    offset: 'GMT-03:00',
    name: 'America/Godthab'
  },
  {
    offset: 'GMT-03:00',
    name: 'America/Miquelon'
  },
  {
    offset: 'GMT-03:00',
    name: 'America/Sao_Paulo'
  },
  {
    offset: 'GMT-03:30',
    name: 'America/St_Johns'
  },
  {
    offset: 'GMT-02:00',
    name: 'America/Noronha'
  },
  {
    offset: 'GMT-01:00',
    name: 'Atlantic/Cape_Verde'
  },
  {
    offset: 'GMT',
    name: 'Europe/Belfast'
  },
  {
    offset: 'GMT',
    name: 'Africa/Abidjan'
  },
  {
    offset: 'GMT',
    name: 'Europe/Dublin'
  },
  {
    offset: 'GMT',
    name: 'Europe/Lisbon'
  },
  {
    offset: 'GMT',
    name: 'Europe/London'
  },
  {
    offset: 'UTC',
    name: 'UTC'
  },
  {
    offset: 'GMT+01:00',
    name: 'Africa/Algiers'
  },
  {
    offset: 'GMT+01:00',
    name: 'Africa/Windhoek'
  },
  {
    offset: 'GMT+01:00',
    name: 'Atlantic/Azores'
  },
  {
    offset: 'GMT+01:00',
    name: 'Atlantic/Stanley'
  },
  {
    offset: 'GMT+01:00',
    name: 'Europe/Amsterdam'
  },
  {
    offset: 'GMT+01:00',
    name: 'Europe/Belgrade'
  },
  {
    offset: 'GMT+01:00',
    name: 'Europe/Brussels'
  },
  {
    offset: 'GMT+02:00',
    name: 'Africa/Cairo'
  },
  {
    offset: 'GMT+02:00',
    name: 'Africa/Blantyre'
  },
  {
    offset: 'GMT+02:00',
    name: 'Asia/Beirut'
  },
  {
    offset: 'GMT+02:00',
    name: 'Asia/Damascus'
  },
  {
    offset: 'GMT+02:00',
    name: 'Asia/Gaza'
  },
  {
    offset: 'GMT+02:00',
    name: 'Asia/Jerusalem'
  },
  {
    offset: 'GMT+03:00',
    name: 'Africa/Addis_Ababa'
  },
  {
    offset: 'GMT+03:00',
    name: 'Asia/Riyadh89'
  },
  {
    offset: 'GMT+03:00',
    name: 'Europe/Minsk'
  },
  {
    offset: 'GMT+03:30',
    name: 'Asia/Tehran'
  },
  {
    offset: 'GMT+04:00',
    name: 'Asia/Dubai'
  },
  {
    offset: 'GMT+04:00',
    name: 'Asia/Yerevan'
  },
  {
    offset: 'GMT+04:00',
    name: 'Europe/Moscow'
  },
  {
    offset: 'GMT+04:30',
    name: 'Asia/Kabul'
  },
  {
    offset: 'GMT+05:00',
    name: 'Asia/Tashkent'
  },
  {
    offset: 'GMT+05:30',
    name: 'Asia/Kolkata'
  },
  {
    offset: 'GMT+05:45',
    name: 'Asia/Katmandu'
  },
  {
    offset: 'GMT+06:00',
    name: 'Asia/Dhaka'
  },
  {
    offset: 'GMT+06:00',
    name: 'Asia/Yekaterinburg'
  },
  {
    offset: 'GMT+06:30',
    name: 'Asia/Rangoon'
  },
  {
    offset: 'GMT+07:00',
    name: 'Asia/Bangkok'
  },
  {
    offset: 'GMT+07:00',
    name: 'Asia/Novosibirsk'
  },
  {
    offset: 'GMT+08:00',
    name: 'Etc/GMT+8'
  },
  {
    offset: 'GMT+08:00',
    name: 'Asia/Hong_Kong'
  },
  {
    offset: 'GMT+08:00',
    name: 'Asia/Krasnoyarsk'
  },
  {
    offset: 'GMT+08:00',
    name: 'Australia/Perth'
  },
  {
    offset: 'GMT+08:45',
    name: 'Australia/Eucla'
  },
  {
    offset: 'GMT+09:00',
    name: 'Asia/Irkutsk'
  },
  {
    offset: 'GMT+09:00',
    name: 'Asia/Seoul'
  },
  {
    offset: 'GMT+09:00',
    name: 'Asia/Tokyo'
  },
  {
    offset: 'GMT+09:30',
    name: 'Australia/Adelaide'
  },
  {
    offset: 'GMT+09:30',
    name: 'Australia/Darwin'
  },
  {
    offset: 'GMT+09:30',
    name: 'Pacific/Marquesas'
  },
  {
    offset: 'GMT+10:00',
    name: 'Etc/GMT+10'
  },
  {
    offset: 'GMT+10:00',
    name: 'Australia/Brisbane'
  },
  {
    offset: 'GMT+10:00',
    name: 'Australia/Hobart'
  },
  {
    offset: 'GMT+10:00',
    name: 'Asia/Yakutsk'
  },
  {
    offset: 'GMT+10:30',
    name: 'Australia/Lord_Howe'
  },
  {
    offset: 'GMT+11:00',
    name: 'Asia/Vladivostok'
  },
  {
    offset: 'GMT+11:30',
    name: 'Pacific/Norfolk'
  },
  {
    offset: 'GMT+12:00',
    name: 'Etc/GMT+12'
  },
  {
    offset: 'GMT+12:00',
    name: 'Asia/Anadyr'
  },
  {
    offset: 'GMT+12:00',
    name: 'Asia/Magadan'
  },
  {
    offset: 'GMT+12:00',
    name: 'Pacific/Auckland'
  },
  {
    offset: 'GMT+12:45',
    name: 'Pacific/Chatham'
  },
  {
    offset: 'GMT+13:00',
    name: 'Pacific/Tongatapu'
  },
  {
    offset: 'GMT+14:00',
    name: 'Pacific/Kiritimati'
  }
]

export const timezonesConcatenated = timezones.map((item) => `${item.name} (${item.offset})`)

export const timezoneObj = {
  'Pacific/Niue': '(GMT-11:00) Niue',
  'Pacific/Pago_Pago': '(GMT-11:00) Pago Pago',
  'Pacific/Honolulu': '(GMT-10:00) Hawaii Time',
  'Pacific/Rarotonga': '(GMT-10:00) Rarotonga',
  'Pacific/Tahiti': '(GMT-10:00) Tahiti',
  'Pacific/Marquesas': '(GMT-09:30) Marquesas',
  'America/Anchorage': '(GMT-09:00) Alaska Time',
  'Pacific/Gambier': '(GMT-09:00) Gambier',
  'America/Los_Angeles': '(GMT-08:00) Pacific Time',
  'America/Tijuana': '(GMT-08:00) Pacific Time - Tijuana',
  'America/Vancouver': '(GMT-08:00) Pacific Time - Vancouver',
  'America/Whitehorse': '(GMT-08:00) Pacific Time - Whitehorse',
  'Pacific/Pitcairn': '(GMT-08:00) Pitcairn',
  'America/Dawson_Creek': '(GMT-07:00) Mountain Time - Dawson Creek',
  'America/Denver': '(GMT-07:00) Mountain Time',
  'America/Edmonton': '(GMT-07:00) Mountain Time - Edmonton',
  'America/Hermosillo': '(GMT-07:00) Mountain Time - Hermosillo',
  'America/Mazatlan': '(GMT-07:00) Mountain Time - Chihuahua, Mazatlan',
  'America/Phoenix': '(GMT-07:00) Mountain Time - Arizona',
  'America/Yellowknife': '(GMT-07:00) Mountain Time - Yellowknife',
  'America/Belize': '(GMT-06:00) Belize',
  'America/Chicago': '(GMT-06:00) Central Time',
  'America/Costa_Rica': '(GMT-06:00) Costa Rica',
  'America/El_Salvador': '(GMT-06:00) El Salvador',
  'America/Guatemala': '(GMT-06:00) Guatemala',
  'America/Managua': '(GMT-06:00) Managua',
  'America/Mexico_City': '(GMT-06:00) Central Time - Mexico City',
  'America/Regina': '(GMT-06:00) Central Time - Regina',
  'America/Tegucigalpa': '(GMT-06:00) Central Time - Tegucigalpa',
  'America/Winnipeg': '(GMT-06:00) Central Time - Winnipeg',
  'Pacific/Galapagos': '(GMT-06:00) Galapagos',
  'America/Bogota': '(GMT-05:00) Bogota',
  'America/Cancun': '(GMT-05:00) America Cancun',
  'America/Cayman': '(GMT-05:00) Cayman',
  'America/Guayaquil': '(GMT-05:00) Guayaquil',
  'America/Havana': '(GMT-05:00) Havana',
  'America/Iqaluit': '(GMT-05:00) Eastern Time - Iqaluit',
  'America/Jamaica': '(GMT-05:00) Jamaica',
  'America/Lima': '(GMT-05:00) Lima',
  'America/Nassau': '(GMT-05:00) Nassau',
  'America/New_York': '(GMT-05:00) Eastern Time',
  'America/Panama': '(GMT-05:00) Panama',
  'America/Port-au-Prince': '(GMT-05:00) Port-au-Prince',
  'America/Rio_Branco': '(GMT-05:00) Rio Branco',
  'America/Toronto': '(GMT-05:00) Eastern Time - Toronto',
  'Pacific/Easter': '(GMT-05:00) Easter Island',
  'America/Caracas': '(GMT-04:00) Caracas',
  'America/Asuncion': '(GMT-03:00) Asuncion',
  'America/Barbados': '(GMT-04:00) Barbados',
  'America/Boa_Vista': '(GMT-04:00) Boa Vista',
  'America/Campo_Grande': '(GMT-03:00) Campo Grande',
  'America/Cuiaba': '(GMT-03:00) Cuiaba',
  'America/Curacao': '(GMT-04:00) Curacao',
  'America/Grand_Turk': '(GMT-04:00) Grand Turk',
  'America/Guyana': '(GMT-04:00) Guyana',
  'America/Halifax': '(GMT-04:00) Atlantic Time - Halifax',
  'America/La_Paz': '(GMT-04:00) La Paz',
  'America/Manaus': '(GMT-04:00) Manaus',
  'America/Martinique': '(GMT-04:00) Martinique',
  'America/Port_of_Spain': '(GMT-04:00) Port of Spain',
  'America/Porto_Velho': '(GMT-04:00) Porto Velho',
  'America/Puerto_Rico': '(GMT-04:00) Puerto Rico',
  'America/Santo_Domingo': '(GMT-04:00) Santo Domingo',
  'America/Thule': '(GMT-04:00) Thule',
  'Atlantic/Bermuda': '(GMT-04:00) Bermuda',
  'America/St_Johns': '(GMT-03:30) Newfoundland Time - St. Johns',
  'America/Araguaina': '(GMT-03:00) Araguaina',
  'America/Argentina/Buenos_Aires': '(GMT-03:00) Buenos Aires',
  'America/Bahia': '(GMT-03:00) Salvador',
  'America/Belem': '(GMT-03:00) Belem',
  'America/Cayenne': '(GMT-03:00) Cayenne',
  'America/Fortaleza': '(GMT-03:00) Fortaleza',
  'America/Godthab': '(GMT-03:00) Godthab',
  'America/Maceio': '(GMT-03:00) Maceio',
  'America/Miquelon': '(GMT-03:00) Miquelon',
  'America/Montevideo': '(GMT-03:00) Montevideo',
  'America/Paramaribo': '(GMT-03:00) Paramaribo',
  'America/Recife': '(GMT-03:00) Recife',
  'America/Santiago': '(GMT-03:00) Santiago',
  'America/Sao_Paulo': '(GMT-03:00) Sao Paulo',
  'Antarctica/Palmer': '(GMT-03:00) Palmer',
  'Antarctica/Rothera': '(GMT-03:00) Rothera',
  'Atlantic/Stanley': '(GMT-03:00) Stanley',
  'America/Noronha': '(GMT-02:00) Noronha',
  'Atlantic/South_Georgia': '(GMT-02:00) South Georgia',
  'America/Scoresbysund': '(GMT-01:00) Scoresbysund',
  'Atlantic/Azores': '(GMT-01:00) Azores',
  'Atlantic/Cape_Verde': '(GMT-01:00) Cape Verde',
  'Africa/Abidjan': '(GMT+00:00) Abidjan',
  'Africa/Accra': '(GMT+00:00) Accra',
  'Africa/Bissau': '(GMT+00:00) Bissau',
  'Africa/Casablanca': '(GMT+00:00) Casablanca',
  'Africa/El_Aaiun': '(GMT+00:00) El Aaiun',
  'Africa/Monrovia': '(GMT+00:00) Monrovia',
  'America/Danmarkshavn': '(GMT+00:00) Danmarkshavn',
  'Atlantic/Canary': '(GMT+00:00) Canary Islands',
  'Atlantic/Faroe': '(GMT+00:00) Faeroe',
  'Atlantic/Reykjavik': '(GMT+00:00) Reykjavik',
  'Etc/GMT': '(GMT+00:00) GMT (no daylight saving)',
  'Europe/Dublin': '(GMT+00:00) Dublin',
  'Europe/Lisbon': '(GMT+00:00) Lisbon',
  'Europe/London': '(GMT+00:00) London',
  'Africa/Algiers': '(GMT+01:00) Algiers',
  'Africa/Ceuta': '(GMT+01:00) Ceuta',
  'Africa/Lagos': '(GMT+01:00) Lagos',
  'Africa/Ndjamena': '(GMT+01:00) Ndjamena',
  'Africa/Tunis': '(GMT+01:00) Tunis',
  'Africa/Windhoek': '(GMT+02:00) Windhoek',
  'Europe/Amsterdam': '(GMT+01:00) Amsterdam',
  'Europe/Andorra': '(GMT+01:00) Andorra',
  'Europe/Belgrade': '(GMT+01:00) Central European Time - Belgrade',
  'Europe/Berlin': '(GMT+01:00) Berlin',
  'Europe/Brussels': '(GMT+01:00) Brussels',
  'Europe/Budapest': '(GMT+01:00) Budapest',
  'Europe/Copenhagen': '(GMT+01:00) Copenhagen',
  'Europe/Gibraltar': '(GMT+01:00) Gibraltar',
  'Europe/Luxembourg': '(GMT+01:00) Luxembourg',
  'Europe/Madrid': '(GMT+01:00) Madrid',
  'Europe/Malta': '(GMT+01:00) Malta',
  'Europe/Monaco': '(GMT+01:00) Monaco',
  'Europe/Oslo': '(GMT+01:00) Oslo',
  'Europe/Paris': '(GMT+01:00) Paris',
  'Europe/Prague': '(GMT+01:00) Central European Time - Prague',
  'Europe/Rome': '(GMT+01:00) Rome',
  'Europe/Stockholm': '(GMT+01:00) Stockholm',
  'Europe/Tirane': '(GMT+01:00) Tirane',
  'Europe/Vienna': '(GMT+01:00) Vienna',
  'Europe/Warsaw': '(GMT+01:00) Warsaw',
  'Europe/Zurich': '(GMT+01:00) Zurich',
  'Africa/Cairo': '(GMT+02:00) Cairo',
  'Africa/Johannesburg': '(GMT+02:00) Johannesburg',
  'Africa/Maputo': '(GMT+02:00) Maputo',
  'Africa/Tripoli': '(GMT+02:00) Tripoli',
  'Asia/Amman': '(GMT+02:00) Amman',
  'Asia/Beirut': '(GMT+02:00) Beirut',
  'Asia/Damascus': '(GMT+02:00) Damascus',
  'Asia/Gaza': '(GMT+02:00) Gaza',
  'Asia/Jerusalem': '(GMT+02:00) Jerusalem',
  'Asia/Nicosia': '(GMT+02:00) Nicosia',
  'Europe/Athens': '(GMT+02:00) Athens',
  'Europe/Bucharest': '(GMT+02:00) Bucharest',
  'Europe/Chisinau': '(GMT+02:00) Chisinau',
  'Europe/Helsinki': '(GMT+02:00) Helsinki',
  'Europe/Istanbul': '(GMT+03:00) Istanbul',
  'Europe/Kaliningrad': '(GMT+02:00) Moscow-01 - Kaliningrad',
  'Europe/Kyiv': '(GMT+02:00) Kyiv',
  'Europe/Riga': '(GMT+02:00) Riga',
  'Europe/Sofia': '(GMT+02:00) Sofia',
  'Europe/Tallinn': '(GMT+02:00) Tallinn',
  'Europe/Vilnius': '(GMT+02:00) Vilnius',
  'Africa/Khartoum': '(GMT+03:00) Khartoum',
  'Africa/Nairobi': '(GMT+03:00) Nairobi',
  'Antarctica/Syowa': '(GMT+03:00) Syowa',
  'Asia/Baghdad': '(GMT+03:00) Baghdad',
  'Asia/Qatar': '(GMT+03:00) Qatar',
  'Asia/Riyadh': '(GMT+03:00) Riyadh',
  'Europe/Minsk': '(GMT+03:00) Minsk',
  'Europe/Moscow': '(GMT+03:00) Moscow+00 - Moscow',
  'Asia/Tehran': '(GMT+03:30) Tehran',
  'Asia/Baku': '(GMT+04:00) Baku',
  'Asia/Dubai': '(GMT+04:00) Dubai',
  'Asia/Tbilisi': '(GMT+04:00) Tbilisi',
  'Asia/Yerevan': '(GMT+04:00) Yerevan',
  'Europe/Samara': '(GMT+04:00) Moscow+01 - Samara',
  'Indian/Mahe': '(GMT+04:00) Mahe',
  'Indian/Mauritius': '(GMT+04:00) Mauritius',
  'Indian/Reunion': '(GMT+04:00) Reunion',
  'Asia/Kabul': '(GMT+04:30) Kabul',
  'Antarctica/Mawson': '(GMT+05:00) Mawson',
  'Asia/Aqtau': '(GMT+05:00) Aqtau',
  'Asia/Aqtobe': '(GMT+05:00) Aqtobe',
  'Asia/Ashgabat': '(GMT+05:00) Ashgabat',
  'Asia/Dushanbe': '(GMT+05:00) Dushanbe',
  'Asia/Karachi': '(GMT+05:00) Karachi',
  'Asia/Tashkent': '(GMT+05:00) Tashkent',
  'Asia/Yekaterinburg': '(GMT+05:00) Moscow+02 - Yekaterinburg',
  'Indian/Kerguelen': '(GMT+05:00) Kerguelen',
  'Indian/Maldives': '(GMT+05:00) Maldives',
  'Asia/Calcutta': '(GMT+05:30) India Standard Time',
  'Asia/Colombo': '(GMT+05:30) Colombo',
  'Asia/Katmandu': '(GMT+05:45) Katmandu',
  'Antarctica/Vostok': '(GMT+06:00) Vostok',
  'Asia/Almaty': '(GMT+06:00) Almaty',
  'Asia/Bishkek': '(GMT+06:00) Bishkek',
  'Asia/Dhaka': '(GMT+06:00) Dhaka',
  'Asia/Omsk': '(GMT+06:00) Moscow+03 - Omsk, Novosibirsk',
  'Asia/Thimphu': '(GMT+06:00) Thimphu',
  'Indian/Chagos': '(GMT+06:00) Chagos',
  'Asia/Rangoon': '(GMT+06:30) Rangoon',
  'Indian/Cocos': '(GMT+06:30) Cocos',
  'Antarctica/Davis': '(GMT+07:00) Davis',
  'Asia/Bangkok': '(GMT+07:00) Bangkok',
  'Asia/Hovd': '(GMT+07:00) Hovd',
  'Asia/Jakarta': '(GMT+07:00) Jakarta',
  'Asia/Krasnoyarsk': '(GMT+07:00) Moscow+04 - Krasnoyarsk',
  'Asia/Saigon': '(GMT+07:00) Hanoi',
  'Asia/Ho_Chi_Minh': '(GMT+07:00) Ho Chi Minh',
  'Indian/Christmas': '(GMT+07:00) Christmas',
  'Antarctica/Casey': '(GMT+08:00) Casey',
  'Asia/Brunei': '(GMT+08:00) Brunei',
  'Asia/Choibalsan': '(GMT+08:00) Choibalsan',
  'Asia/Hong_Kong': '(GMT+08:00) Hong Kong',
  'Asia/Irkutsk': '(GMT+08:00) Moscow+05 - Irkutsk',
  'Asia/Kuala_Lumpur': '(GMT+08:00) Kuala Lumpur',
  'Asia/Macau': '(GMT+08:00) Macau',
  'Asia/Makassar': '(GMT+08:00) Makassar',
  'Asia/Manila': '(GMT+08:00) Manila',
  'Asia/Shanghai': '(GMT+08:00) China Time - Beijing',
  'Asia/Singapore': '(GMT+08:00) Singapore',
  'Asia/Taipei': '(GMT+08:00) Taipei',
  'Asia/Ulaanbaatar': '(GMT+08:00) Ulaanbaatar',
  'Australia/Perth': '(GMT+08:00) Western Time - Perth',
  'Asia/Pyongyang': '(GMT+08:30) Pyongyang',
  'Asia/Dili': '(GMT+09:00) Dili',
  'Asia/Jayapura': '(GMT+09:00) Jayapura',
  'Asia/Seoul': '(GMT+09:00) Seoul',
  'Asia/Tokyo': '(GMT+09:00) Tokyo',
  'Asia/Yakutsk': '(GMT+09:00) Moscow+06 - Yakutsk',
  'Pacific/Palau': '(GMT+09:00) Palau',
  'Australia/Adelaide': '(GMT+10:30) Central Time - Adelaide',
  'Australia/Darwin': '(GMT+09:30) Central Time - Darwin',
  'Antarctica/DumontDUrville': "(GMT+10:00) Dumont D'Urville",
  'Asia/Magadan': '(GMT+10:00) Moscow+07 - Magadan',
  'Asia/Vladivostok': '(GMT+10:00) Moscow+07 - Vladivostok',
  'Australia/Brisbane': '(GMT+10:00) Eastern Time - Brisbane',
  'Asia/Yuzhno-Sakhalinsk': '(GMT+11:00) Moscow+08 - Yuzhno-Sakhalinsk',
  'Australia/Hobart': '(GMT+11:00) Eastern Time - Hobart',
  'Australia/Sydney': '(GMT+11:00) Eastern Time - Melbourne, Sydney',
  'Pacific/Chuuk': '(GMT+10:00) Truk',
  'Pacific/Guam': '(GMT+10:00) Guam',
  'Pacific/Port_Moresby': '(GMT+10:00) Port Moresby',
  'Pacific/Efate': '(GMT+11:00) Efate',
  'Pacific/Guadalcanal': '(GMT+11:00) Guadalcanal',
  'Pacific/Kosrae': '(GMT+11:00) Kosrae',
  'Pacific/Norfolk': '(GMT+11:00) Norfolk',
  'Pacific/Noumea': '(GMT+11:00) Noumea',
  'Pacific/Pohnpei': '(GMT+11:00) Ponape',
  'Asia/Kamchatka': '(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy',
  'Pacific/Auckland': '(GMT+13:00) Auckland',
  'Pacific/Fiji': '(GMT+13:00) Fiji',
  'Pacific/Funafuti': '(GMT+12:00) Funafuti',
  'Pacific/Kwajalein': '(GMT+12:00) Kwajalein',
  'Pacific/Majuro': '(GMT+12:00) Majuro',
  'Pacific/Nauru': '(GMT+12:00) Nauru',
  'Pacific/Tarawa': '(GMT+12:00) Tarawa',
  'Pacific/Wake': '(GMT+12:00) Wake',
  'Pacific/Wallis': '(GMT+12:00) Wallis',
  'Pacific/Apia': '(GMT+14:00) Apia',
  'Pacific/Enderbury': '(GMT+13:00) Enderbury',
  'Pacific/Fakaofo': '(GMT+13:00) Fakaofo',
  'Pacific/Tongatapu': '(GMT+13:00) Tongatapu',
  'Pacific/Kiritimati': '(GMT+14:00) Kiritimati'
}
const keys = Object.keys(timezoneObj)
export const timezoneArray = keys.map((key) => {
  return { value: key + ' ' + timezoneObj[key].split(' ')[0], label: timezoneObj[key] }
})
