import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { Email as EmailIcon } from '@mui/icons-material'
import { Logo } from 'src/components/logo'
// import { motion } from "framer-motion"

const ContactSalesDialog = ({ open, onClose, addon }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      PaperProps={{
        style: {
          borderRadius: fullScreen ? 0 : 16,
          maxWidth: 500
        }
      }}
    >
      <DialogTitle>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant='h6'>Contact Sales</Typography>
          <Box sx={{ height: 40, width: 'auto' }}>
            <Logo
              sx={{ color: '#103776' }}
              logoTextColor='#103776'
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography variant='body1'>
            Interested in our {addon.title}? Contact our sales team for more information and pricing details.
          </Typography>
        </Box>
        <Box display='flex' alignItems='center' justifyContent='center'>
          <EmailIcon color='primary' sx={{ mr: 1 }} />
          <Typography variant='body1' color='primary'>
            mailto:Viktor@success.ai
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ContactSalesDialog
