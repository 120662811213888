import React, { useEffect, useState, useRef, useMemo, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import checkSpamWords from 'src/components/campaigns/emailChecker/utils/checkSpamTree'
import { toast } from 'react-hot-toast'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Drawer,
  useMediaQuery,
  MenuItem,
  Avatar,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Autocomplete,
  Select,
  FormControl,
  InputLabel,
  Link
} from '@mui/material'
import {
  CloseOutlined,
  ArrowDropDown,
  Close,
  PersonOff,
  MailOutline
} from '@mui/icons-material'
import {
  useCreateLabelMutation,
  useGetCampaignVariablesQuery
} from 'src/services/campaign-service'
import { useGetAccountsMutation } from 'src/services/account-service.js'
import {
  useGetCampaignEmailsMutation,
  useGetCampaignEmailsReplyMutation,
  useSendReplyEmailMutation,
  useSendForwardMailMutation,
  useDeleteThreadMutation,
  useOpenedEmailMutation,
  setActualTotalCount,
  useGetSentEmailsMutation
} from 'src/services/unibox-service.js'
import {
  useRemoveLeadsMutation,
  useMoveToCampaignMutation,
  useUpdateLeadMutation
} from 'src/services/leads-service'

import {
  useSaveToHubspotMutation,
  useGetDealPipelinesQuery,
  useGetHubspotIntegrationQuery
} from 'src/services/integration-service.js'

import PropTypes from 'prop-types'
import { OpenAiIcon } from 'src/assets/general/OpenAiIcon'
import {
  useWriteEmailMutation,
  useOptimizeEmailMutation,
  useLazyGetCampaignNamesQuery,
  useGetAllLabelsQuery,
  useGetCampaignsPaginationMutation
} from 'src/services/campaign-service.js'
import CustomCounterProgress from 'src/components/campaigns/emailChecker/CustomCounterProgress'
import EmailContent from './emailContent.js'
import InboxColumn from './inboxColumn.js'
import EmailList from './emailList.js'
import ReactQuill from 'react-quill'
import QuillToolbar, { modules, formats } from 'src/components/campaigns/QuillToolbar.js'
import 'react-quill/dist/quill.snow.css'
import HeaderWithPopover from 'src/components/HeaderWithPopover.js'
import { useAuth } from 'src/auth/auth.js'
import { useGetMeAndWorkspaceQuery } from 'src/services/user-service.js'
// import { handleDrop, handlePaste } from 'src/components/campaigns/commonFunctions.js'
import InputAdornment from '@mui/material/InputAdornment'

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pl: { xs: 0, md: 3 } }}>
          <Typography sx={{ border: '1px solid #E4E4E5', borderRadius: '12px' }}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

const [maxSubjectCount, maxWordCount, maxReadingTime, maxLinks, maxQuestions, maxSpams] = [
  15, 500, 210, 3, 4, 7
]

function CustomTabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ width: '100%' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

const Page = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const [openAllCampaigns, setOpenAllCampaigns] = useState(false)
  const [openAllInboxes, setOpenAllInboxes] = useState(false)
  const [inboxSelectedID, setInboxSelectedID] = useState('')
  const [searchCampaign, setSearchCampaign] = useState('')
  const [searchStatusLabel, setSearchStatusLabel] = useState('')
  const [mailReplies, setMailReplies] = useState([])
  const [currentEmail, setCurrentEmail] = useState()
  const [campaignEmails, setCampaignEmails] = useState([])
  const [sentEmails, setSentEmails] = useState([])
  const [currentCampaign, setCurrentCampaign] = useState(null)
  const [currentAccount, setCurrentAccount] = useState(null)
  const [currentStatus, setCurrentStatus] = useState(null)
  const [currentReply, setCurrentReply] = useState({})
  const [forwardEmail, setForwardEmail] = useState('')
  const [currentForward, setCurrentForward] = useState({})
  const [replyId, setReplyId] = useState({})
  const [page, setPage] = useState(1)
  const [sentPage, setSentPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [sentLimit, setSentLimit] = useState(20)
  const [totalCampaignsEmails, setTotalCampaignsEmails] = useState(0)
  const [totalSentEmails, setTotalSentEmails] = useState(0)
  const [accounts, setAccounts] = useState([])
  const [totalAccounts, setTotalAccounts] = useState(0)
  const [campaignData, setCampaignData] = useState([])
  const [totalCampaigns, setTotalCampaigns] = useState(0)
  const [filter] = useState('Last 07 Days')
  const [refresh, setRefresh] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleLimitChange = (event) => {
    setLimit(event.target.value)
    setPage(1)
  }

  const handleSentLimitChange = (event) => {
    setSentLimit(event.target.value)
    setSentPage(1)
  }
  const [writeEmail, { isLoading: isWriteEmailLoading }] = useWriteEmailMutation()

  const [value, setValue] = React.useState(1)
  const [showAll, setShowAll] = useState(true)
  const [loadMore, setLoadMore] = useState(false)

  const [firstName, setFirstName] = useState('')
  const [email, setEmail] = useState('')
  const [pipelineOptions, setPipelineOptions] = useState([])
  const [stageOptions, setStageOptions] = useState([{ id: 'NoPipelineSelected', label: '-- Select Pipeline --', displayOrder: -1 }])
  const [dealDetails, setDealDetails] = useState({
    amount: 1000,
    dealPipeLine: '',
    pipeLineStage: '',
    leadStatus: '',
    dealname: ''
  })

  const [selectedPipeline, setSelectedPipeline] = useState('NoPipelineSelected')
  const [selectedStage, setSelectedStage] = useState('NoPipelineSelected')

  const [removeLeadOptions, setRemoveLeadOptions] = useState({
    allCampaign: false,
    allDomain: false,
    blocklist: false
  })

  const [statusType, setStatusType] = useState('')
  const statusLabelRef = useRef()

  const [openLeadsDetailsDialog, setOpenLeadsDetailsDialog] = useState(false)
  const [openSentToHubspotDialog, setOpenSentToHubspotDialog] = useState(false)
  const [openRemoveLeadDialog, setOpenRemoveLeadDialog] = useState(false)
  const [openDeleteThreadDialog, setOpenDeleteThreadDialog] = useState(false)
  const [isMoveToCampaignDialogOpen, setIsMoveToCampaignDialogOpen] = useState(false)
  const [openAddStatusDialog, setOpenAddStatusDialog] = useState(false)
  const [moreData, setMoreData] = useState(false)
  const [campaignDataCount, setCampaignDataCount] = useState(0)
  const [searchCampaignLoad, setSearchCampaignLoad] = useState(false)
  const [searchAccountLoad, setSearchAccountLoad] = useState(false)
  const [unread, setUnread] = useState(false)
  const [sent, setSent] = useState(false)
  const [deleteThread] = useDeleteThreadMutation()
  const [removeLeads] = useRemoveLeadsMutation()
  const [createLabel, { isLoading: isCreatingLabel }] = useCreateLabelMutation()
  const [updateLead] = useUpdateLeadMutation()
  const [saveToHubspot] = useSaveToHubspotMutation()
  const [openedEmail] = useOpenedEmailMutation()

  const currentUrl = window.location.href
  // const result = currentUrl.match(/^https:\/\/[^\/]+/)
  const result = currentUrl.match(/^https:\/\/[^/]+/)
  const extractedBaseUrl = result ? result[0] : null

  const handleEmailClick = (i) => {
    updateSelectedEmail(i._id)
    if (i.portal_email_opened === false) {
      handlePortalEmailOpened(i._id, true)
    }
    setReplyId(i)
  }

  const handleSentEmailClick = (i) => {
    updateSelectedEmail(i._id, i.campaign_email_id._id)
    setReplyId(i)
  }

  const handleCloseEditor = () => {
    setCurrentEmail(null)
  }

  function getInitials (firstName = '', lastName = '') {
    const firstInitial = firstName?.substring(0, 1).toUpperCase()
    const lastInitial = lastName?.substring(0, 1).toUpperCase()

    return `${firstInitial}${lastInitial}`
  }
  const handleClickOpenLeadsDetailsDialog = async () => {
    setEmail(currentEmail?.leads?.email)
    setFirstName(currentEmail?.leads?.firstName)
    setOpenLeadsDetailsDialog(true)
  }

  const handleClickOpenSendToHubspotDialog = async () => {
    setOpenSentToHubspotDialog(true)
  }

  const handleCloseSentToHubspotDialog = () => {
    setDealDetails({
      amount: 1000,
      dealPipeLine: '',
      pipeLineStage: '',
      leadStatus: '',
      dealname: ''
    })
    setSelectedPipeline('NoPipelineSelected')
    setSelectedStage('NoPipelineSelected')
    setStageOptions([{ id: 'NoPipelineSelected', label: '-- Select Pipeline --', displayOrder: -1 }])
    setOpenSentToHubspotDialog(false)
  }

  const handleSentToHubspotDeal = async () => {
    try {
      const { message } = await saveToHubspot({
        id: currentEmail?._id,
        amount: dealDetails.amount,
        deal_pipeline: dealDetails.dealPipeLine,
        pipeline_stage: dealDetails.pipeLineStage,
        lead_status: dealDetails.leadStatus,
        dealname: dealDetails.dealname
      }).unwrap()
      toast.success(message)
      handleCloseSentToHubspotDialog()
    } catch (err) {
      toast.error(err.data.error.message)
    }
  }

  const handleDealPipelineChange = (e) => {
    try {
      setSelectedPipeline(e.target.value)

      if (e.target.value !== 'NoPipelineSelected') {
        const dealData = pipelineOptions.filter((data) => { return data.id === e.target.value })
        setSelectedStage('NoStageSelected')
        setDealDetails((prevDetails) => ({
          ...prevDetails,
          dealPipeLine: e.target.value
        }))
        setStageOptions([{ id: 'NoStageSelected', label: '-- Select Deal Stage --', displayOrder: -1 }, ...dealData[0].stages])
      } else {
        setSelectedStage('NoPipelineSelected')
        setStageOptions([{ id: 'NoPipelineSelected', label: '-- Select Pipeline --', displayOrder: -1 }])
      }
    } catch (err) {
      toast.error(err.data.error.message)
    }
  }

  const handleDealStageChange = (e) => {
    setSelectedStage(e.target.value)

    setDealDetails((prevDetails) => ({
      ...prevDetails,
      pipeLineStage: e.target.value
    }))
  }

  const handleCloseLeadsDetailsDialog = () => {
    setEmail('')
    setFirstName('')
    setOpenLeadsDetailsDialog(false)
  }
  const handleClickOpenRemoveLeadDialog = (value) => {
    setOpenRemoveLeadDialog(true)
  }
  const handleCloseRemoveLeadDialog = () => {
    setOpenRemoveLeadDialog(false)
  }

  const handleClickOpenDeleteThreadDialog = (value) => {
    setOpenDeleteThreadDialog(true)
  }
  const handleDeleteThreadClick = async () => {
    const { message } = await deleteThread(inboxSelectedID).unwrap()
    toast.success(message)
    setOpenDeleteThreadDialog(false)
    fetchUpdatedData()
  }
  const handleLeadEdit = async () => {
    try {
      const { message } = await updateLead({
        id: currentEmail?.leads?._id,
        data: {
          firstName,
          email
        }
      }).unwrap()
      toast.success(message)
      handleCloseLeadsDetailsDialog()
      fetchUpdatedData()
    } catch (err) {
      toast.error(err.data.error.message)
    }
  }
  const handleRemoveLeadsClick = async () => {
    const body = {
      email: currentEmail?.leads?.email,
      allDomain: removeLeadOptions.allDomain,
      allCampaign: removeLeadOptions.allCampaign,
      blocklist: removeLeadOptions.blocklist,
      workspace: workspace?._id
    }
    const { message } = await removeLeads(body).unwrap()
    toast.success(message)
    setOpenRemoveLeadDialog(false)
    fetchUpdatedData()
  }
  const handleCreateLabel = async () => {
    const [name, type] = [statusLabelRef.current?.value, statusType]

    if (!name.trim() || !type.trim()) {
      toast.error('Please Add Label & Status Type')
      return
    }
    const data = { name, type }
    const { message } = await createLabel(data).unwrap()
    toast.success(message)
    refetchLabels()
    setOpenAddStatusDialog(false)
  }
  const handleCloseDeleteThreadDialog = () => {
    setOpenDeleteThreadDialog(false)
  }

  const handleClickOpenAddStatusDialog = (value) => {
    setOpenAddStatusDialog(true)
  }
  const handleCloseAddStatusDialog = () => {
    setOpenAddStatusDialog(false)
  }

  //  // move to Campaign
  const [selectedCampaign, setSelectedCampaign] = useState(null)

  const [fetchCampaignNames, { data: campaignNames }] = useLazyGetCampaignNamesQuery()
  const [moveToCampaign, { isLoading: isMovingToCampaign }] = useMoveToCampaignMutation()

  const handleClickOpenMoveToCampaignDialog = (value) => {
    fetchCampaignNames()
    setIsMoveToCampaignDialogOpen(true)
  }
  const handleMoveToCampaignDialogClose = () => {
    setIsMoveToCampaignDialogOpen(false)
  }

  const handleMoveToCampaignDialogSave = async () => {
    setIsMoveToCampaignDialogOpen(false)
    const { message } = await moveToCampaign({
      id: selectedCampaign,
      data: { leads: [currentEmail?.leads?._id] }
    }).unwrap()
    toast.success(message)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const [sendForward, { isLoading: isLoadingForward }] = useSendForwardMailMutation()

  const sendForwardMail = async () => {
    if (loadMore) return
    try {
      setLoadMore(true)
      const { message } = await sendForward({
        body: {
          ...currentForward,
          forwardEmail
        }
      }).unwrap()
      toast.success(message)
      setForwardPopupOpen(false)
      setForwardEmail('')
      setLoadMore(false)
    } catch (err) {
      setLoadMore(false)
      toast.error(err.data.error.message)
    }
  }
  const handleSearchStatusChange = (event) => {
    setSearchStatusLabel(event.target.value)
  }
  const handleSearchCampaignChange = (event) => {
    setSearchCampaign(event.target.value)
  }
  const [searchAccount, setSearchAccount] = useState('')
  const handleSearchAccountChange = (event) => {
    setSearchAccount(event.target.value)
  }
  const handleClickAccount = (id) => {
    setCurrentCampaign(null)
    setCurrentStatus(null)
    setCurrentAccount(id)
    setUnread(false)
    setSent(false)
  }
  const handleClickCampaign = (id) => {
    setCurrentCampaign(id)
    setCurrentAccount(null)
    setCurrentStatus(null)
    setUnread(false)
    setSent(false)
  }
  const handleClickStatus = (id) => {
    setCurrentStatus(id)
    setCurrentCampaign(null)
    setCurrentAccount(null)
    setUnread(false)
    setSent(false)
  }

  const [getCampaigns, { isLoading: isCampaignsLoading }] = useGetCampaignsPaginationMutation()

  const [, setLoadCampaignData] = useState(false)

  const handleReload = () => {
    setLoadCampaignData(true)
    if (typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {
      window.localStorage.setItem('loadData', true)
    }
  }

  const {
    data: statusLabels,
    isFetching: isLabelsLoading,
    refetch: refetchLabels
  } = useGetAllLabelsQuery()

  const containsText = (text, searchText) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1
  const displayedOptions = useMemo(
    () => statusLabels?.labels.filter((option) => containsText(option.name, searchStatusLabel)),
    [searchStatusLabel, statusLabels?.labels]
  )

  const [sendReply, { isLoading: isSendingReply }] = useSendReplyEmailMutation()

  const sendReplyEmail = async () => {
    if (loadMore) return
    try {
      setLoadMore(true)
      const { message } = await sendReply({
        body: {
          subject: editorSubject,
          body: editorContent,
          ...currentReply
        }
      }).unwrap()
      toast.success(message)
      setEditorSubject('')
      setEditorContent('')
      setIsEditorDialogOpen(false)
      setLoadMore(false)
    } catch (err) {
      setLoadMore(false)
      setIsEditorDialogOpen(false)
      toast.error(err.data.error.message)
    }
  }

  const removeLeadingBrTags = (emailContent) => {
    return emailContent.replace(/^(<br\s*\/?>\s*)+/, '')
  }
  const { data: variables } = useGetCampaignVariablesQuery(currentReply?.campaignId)

  const getEmailBodyFromPrompt = async (
    prompt,
    defaultValue = " Use my variables defined inside 'variables' array after the 'message' instead of your variables, also use {{ }} instead of [] , do not use any variables from outside and only use 'senderVariables' for regards",
    senderVariables = "['senderName', 'signature']"
  ) => {
    try {
      if (prompt === '') toast.error('Template body cannot be empty.')
      else {
        const myData = variables
        const dataList = myData.map((item) => item.value)
        const customVariables = dataList.join('\n')
        let body = await writeEmail({
          prompt:
            defaultValue +
            ' ' +
            'message: ' +
            prompt +
            'variables: [' +
            customVariables +
            ']' +
            senderVariables
        }).unwrap()
        const dearPattern = /dear/i
        const dearMatch = body.match(dearPattern)
        const startIndex = dearMatch ? dearMatch.index : 0
        if (!dearMatch) {
          body = body.replace(/Subject:.*?<br>/i, '').replace(/Email:.*?<br>/i, '')
        }
        body = body.substring(startIndex)
        setEditorContent(removeLeadingBrTags(body))
      }
    } catch (err) {
      toast.error(err.data.error.message)
    }
  }

  const getAllCampaigns = async () => {
    try {
      if (workspaceId) {
        const limit = 10
        const offset = campaignData?.length
        const res = await getCampaigns({
          search: searchCampaign,
          offset,
          limit,
          unibox: true,
          workspaceId
        }).unwrap()
        if (searchCampaign !== '' && (moreData === false || searchCampaignLoad === false)) {
          setCampaignData(res?.docs)
          setSearchCampaignLoad(true)
        } else {
          if (searchCampaign === '' && searchCampaignLoad) {
            setCampaignData([])
            setSearchCampaignLoad(false)
          }
          setCampaignData(prevAccounts => [...(prevAccounts || []), ...(res?.docs || [])])
          setMoreData(false)
        }
        setTotalCampaigns(res?.total)
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error)
    }
  }

  const getUpdatedCampaigns = async (name) => {
    try {
      if (workspaceId) {
        const res = await getCampaigns({ search: name, unibox: true, workspaceId })
        const updatedCampaign = res.data.docs.find(doc => doc.unreadCount !== 0)
        if (updatedCampaign) {
          const updatedCampaigns = campaignData.map(campaign => {
            if (campaign._id === updatedCampaign._id) {
              return updatedCampaign
            } else {
              return campaign
            }
          })
          setCampaignData(updatedCampaigns)
        }
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error)
    }
  }

  const [workspaceId, setWorkspaceId] = useState()
  const workspace = useSelector((state) => state.workspace)
  useEffect(() => {
    setWorkspaceId(workspace?._id)
  }, [workspace])

  useEffect(() => {
    getAllCampaigns()
  }, [searchCampaign, refresh, workspaceId])
  const [getAccounts, { isLoading: isAccountsLoading }] = useGetAccountsMutation()

  const getAllAccounts = async () => {
    try {
      if (workspaceId) {
        const limit = 10
        const offset = accounts?.length
        const res = await getAccounts({ search: searchAccount, limit, offset, unibox: true, workspaceId })
        if (searchAccount !== '' && (moreData === false || searchAccountLoad === false)) {
          setAccounts(res?.data?.docs)
          setSearchAccountLoad(true)
        } else {
          if (searchAccount === '' && searchAccountLoad) {
            setAccounts([])
            setSearchAccountLoad(false)
          }
          setAccounts(prevAccounts => [...(prevAccounts || []), ...(res?.data?.docs || [])])
          setMoreData(false)
        }
        setTotalAccounts(res?.data?.total)
      }
    } catch (error) {
      console.error('Error fetching accounts:', error)
    }
  }

  const getUpdatedAccounts = async (email) => {
    try {
      const res = await getAccounts({ search: email, unibox: true, workspaceId })
      const updatedAccount = res?.data?.docs[0]
      if (updatedAccount) {
        const updatedAccounts = accounts.map(account => {
          if (account._id === updatedAccount._id) {
            return updatedAccount
          } else {
            return account
          }
        })
        setAccounts(updatedAccounts)
      }
    } catch (error) {
      console.error('Error fetching accounts:', error)
    }
  }

  useEffect(() => {
    getAllAccounts()
  }, [searchAccount, refresh, workspaceId])

  const updateSelectedEmail = async function (id, campaignEmailId = null) {
    let res
    try {
      setMailReplies([])
      setCurrentEmail()
      campaignEmailId ? setInboxSelectedID(campaignEmailId) : setInboxSelectedID(id)

      const combinedEmails = [...(campaignEmails || []), ...(sentEmails || [])]
      const currentEmail = combinedEmails?.find((doc) => {
        if (doc._id === id) {
          return doc
        }
        return false
      })
      setCurrentEmail(currentEmail)
      if (campaignEmailId) {
        res = await getCampaignEmailsReply({ campaignEmailId })
      } else {
        res = await getCampaignEmailsReply({ campaignEmailId: id })
      }
      const filteredDocs = res?.data?.docs?.filter(doc => doc?.isReplied === false) || []
      setMailReplies(res?.data?.docs || [])
      setCurrentReply(filteredDocs[filteredDocs.length - 1])
    } catch (error) {
      console.error(`An error occurred while updating the selected email: ${error.message}`)
    }
  }
  async function handlePortalEmailOpened (campaignEmailId, value) {
    try {
      const { name, email, unreadEmailCount } = await openedEmail({
        id: campaignEmailId,
        body: {
          value
        }
      }).unwrap()
      EmailUpdatedData()
      dispatch(setActualTotalCount(unreadEmailCount))
      getUpdatedAccounts(email)
      getUpdatedCampaigns(name)
      if (value === false) {
        setCurrentEmail('')
      }
    } catch (error) {
      console.error(`An error occurred while opening the email: ${error.message}`)
    }
  }

  const [getCampaignEmailsReply, { isLoading: isCampaignsEmailReplyLoading }] =
    useGetCampaignEmailsReplyMutation()
  const [getSentEmails, { isLoading: isSentEmailsLoading }] = useGetSentEmailsMutation()

  const offset = campaignEmails?.length
  const sentOffset = sentEmails?.length

  useEffect(() => {
    const fetchEmails = () => {
      if (sent) {
        setSentEmails([])
        setCurrentEmail()
        const object = {}
        object.offset = sentOffset * (sentPage - 1)
        object.perPage = sentLimit
        object.workspaceId = workspaceId
        if (workspaceId) {
          getSentEmails(object).then((res) => {
            setSentEmails(res?.data?.docs)
            setTotalSentEmails(res?.data?.totalSentEmailCount)
          })
        }
      }
    }

    fetchEmails()
  }, [sent, sentLimit, sentPage, refresh, workspaceId])

  useEffect(() => {
    setCampaignEmails([])
    setCurrentEmail()
    const object = {}
    if (currentCampaign) {
      object.campaignId = currentCampaign
    }
    if (currentAccount) {
      object.accountId = currentAccount
    }
    if (currentStatus) {
      object.label = currentStatus
    }
    if (unread) {
      object.unread = unread
    }
    object.offset = offset * (page - 1)
    object.perPage = limit
    object.workspaceId = workspaceId
    // object.time_period = filterValue;
    if (workspaceId) {
      getCampaignEmails(object).then((res) => {
        setTotalCampaignsEmails(res?.data?.totalEmailCount)
        setCampaignEmails(res?.data?.docs)
      })
    }
  }, [currentCampaign, loadMore, currentAccount, showAll, currentStatus, limit, workspaceId, page, filter, unread, refresh])

  const fetchUpdatedData = () => {
    const object = {}
    if (currentCampaign) {
      object.campaignId = currentCampaign
    }
    if (currentAccount) {
      object.accountId = currentAccount
    }
    if (currentStatus) {
      object.label = currentStatus
    }
    if (unread) {
      object.unread = unread
    }
    object.workspaceId = workspaceId
    // object.time_period = filterValue;
    const updateEmails = (getEmailsFunction, setEmailsFunction) => {
      getEmailsFunction(object).then((res) => {
        const emails = res?.data?.docs
        setEmailsFunction(emails)
        const updatedCurrentEmail = emails.find((e) => e._id === currentEmail._id)
        setCurrentEmail(updatedCurrentEmail)
      })
    }
    if (!sent) {
      updateEmails(getCampaignEmails, setCampaignEmails)
    } else {
      updateEmails(getSentEmails, setSentEmails)
    }
  }

  const EmailUpdatedData = () => {
    const object = {
      offset: offset * (page - 1),
      perPage: limit,
      workspaceId,
      ...(currentCampaign && { campaignId: currentCampaign }),
      ...(currentAccount && { accountId: currentAccount }),
      ...(currentStatus && { label: currentStatus }),
      ...(unread && { unread })
    }

    getCampaignEmails(object).then(res => {
      const updatedEmails = res?.data?.docs || []

      setCampaignEmails(prevEmails =>
        prevEmails.map(email => {
          const updatedEmail = updatedEmails.find(updated => updated._id === email._id)
          if (updatedEmail && updatedEmail.portal_email_opened !== email.portal_email_opened) {
            return { ...email, portal_email_opened: updatedEmail.portal_email_opened }
          }
          return email
        })
      )
    })
  }

  const [getCampaignEmails, { isLoading: isCampaignsEmailLoading }] =
    useGetCampaignEmailsMutation()

  const parseBodyContent = ({ body, trim = true }) => {
    // Create a temporary DOM element to decode HTML entities
    const tempElement = document.createElement('div')
    tempElement.innerHTML = body
    const decodedString = tempElement.textContent || tempElement.innerText

    // Remove HTML tags
    const plainString = decodedString.replace(/<[^>]+>/g, '').replace(/p\.\w+\s*{[^}]*}|\s*span\.\w+\s*{[^}]*}/g, '')

    if (!trim) {
      return plainString
    }

    return plainString.length <= 110 ? plainString : plainString.substring(0, 110 - 3) + '...'
  }

  const [isEditorDialogOpen, setIsEditorDialogOpen] = useState(false)
  const [editorSubject, setEditorSubject] = useState('')
  const [editorContent, setEditorContent] = useState('')
  const [, setCursorLoaction] = useState(1)
  const [wordCount, setWordCount] = useState(0)
  const [subjectCount, setSubjectCount] = useState(0)
  const [spamCount, setSpamCount] = useState(0)
  const [questionCount, setQuestionCount] = useState(0)
  const [readingTime, setReadingTime] = useState(0)
  const [urlCount, setUrlCount] = useState(0)
  const quillEditorRef = useRef(null)

  // useEffect(() => {
  //   const attachEventListeners = (editor) => {
  //     const editorContainer = editor.root;
  //     const handleDocumentDrop = (e) => {
  //       e.preventDefault();
  //       e.stopPropagation();
  //     };

  //     editorContainer?.addEventListener('paste', (e) =>
  //       handlePaste(e, quillEditorRef, FILE_SIZE_LIMIT, toast)
  //     );
  //     editorContainer?.addEventListener('drop', (e) =>
  //       handleDrop(e, quillEditorRef, FILE_SIZE_LIMIT, toast)
  //     );

  //     document.addEventListener('drop', handleDocumentDrop);
  //     document.addEventListener('dragover', (e) => e.preventDefault());
  //     return () => {
  //       editorContainer?.removeEventListener('paste', (e) =>
  //         handlePaste(e, quillEditorRef, FILE_SIZE_LIMIT, toast)
  //       );
  //       editorContainer?.removeEventListener('drop', (e) =>
  //         handleDrop(e, quillEditorRef, FILE_SIZE_LIMIT, toast)
  //       );
  //       document.removeEventListener('drop', handleDocumentDrop);
  //     };
  //   };

  //   const quill = quillEditorRef?.current?.getEditor();

  //   if (!quill) {
  //     const intervalId = setInterval(() => {
  //       const editorInstance = quillEditorRef?.current?.getEditor();
  //       if (editorInstance) {
  //         attachEventListeners(editorInstance);
  //         clearInterval(intervalId);
  //       }
  //     }, 100);
  //     return () => clearInterval(intervalId);
  //   } else {
  //     attachEventListeners(quill);
  //   }
  // }, [quillEditorRef]);

  const handleEditClick = () => {
    setEditorSubject(`${currentReply?.subject}`)
    setEditorContent('')
    setIsEditorDialogOpen(true)
  }

  const [optimizeEmail, { isLoading: isOptimizeEmailLoading }] = useOptimizeEmailMutation()
  const handleOptimizeClick = async () => {
    if (!editorContent) return toast.error('Template body cannot be empty.')
    try {
      const optimized = await optimizeEmail({
        email: editorContent + 'Do not use [] use {{ }}'
      }).unwrap()
      setEditorContent(removeLeadingBrTags(optimized))
    } catch (err) {
      toast.error(err.data.error.message)
    }
  }
  const handleEditorClick = () => {
    setCursorLoaction(1)
  }
  const [forwardPopupOpen, setForwardPopupOpen] = useState(false)
  const handleForwardClick = () => {
    setCurrentForward(currentEmail)
    setForwardPopupOpen(true)
  }

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const [inboxTabsOpen, setInboxTabsOpen] = useState(false)
  const [, setTotalLabel] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [, setTotalInboxCount] = useState(0)

  useEffect(() => {
    let inboxCount = 0

    accounts?.forEach((i) => {
      inboxCount = inboxCount + i.unreadCount
    })

    let count = 0

    statusLabels?.labels?.forEach((i) => {
      count = count + i.unread_count
    })

    setTotalCount(campaignDataCount)
    setTotalInboxCount(inboxCount)
    setTotalLabel(count)
  }, [campaignData, accounts, statusLabels, refresh])

  const actualTotalCount = totalCount

  const firstUpdate = useRef(true)
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    dispatch(setActualTotalCount(actualTotalCount))
  }, [actualTotalCount, dispatch])

  const [, setTempOpenModal] = useState(false)

  // function highlightSpam(spamArray) {
  //   const iframe = document.getElementsByClassName("tox-edit-area__iframe")[0];
  //   var box = iframe.contentWindow.document.getElementById("tinymce");

  //   let text = box.innerHTML;
  //   text = text.replace(
  //     /(<span class="spam-word" style="border-bottom:3px solid red;">|<\/span>)/gim,
  //     ""
  //   );

  //   let newText = text;
  //   for (let i = 0; i < spamArray.length; i++) {
  //     const regex = new RegExp(`\\b${spamArray[i]}\\b`, "gi");

  //     newText = newText.replace(
  //       regex,
  //       '<span class="spam-word" style="border-bottom:3px solid red;">$&</span>'
  //     );
  //   }

  //   box.innerHTML = newText;
  //   return;
  // }

  function highlightSpam (spamArray) {
    // Get the Quill editor content
    const editor = document.getElementsByClassName('ql-editor')[0]
    let text = editor.innerHTML

    // Remove previous highlights
    text = text.replace(/(<span class="sw" style="border-bottom:3px solid red;">|<\/span>)/gi, '')

    // Highlight new spam words
    let newText = text
    spamArray.forEach(spamWord => {
      // Escape special characters in the spam word
      const escapedWord = spamWord.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      const regex = new RegExp(`\\b${escapedWord}\\b`, 'gi')

      newText = newText.replace(regex, match => `<span class="sw" style="border-bottom:3px solid red;">${match}</span>`)
    })
    editor.innerHTML = newText
  }

  function handleReadingTime (paragraph, wordsPerMinute = 200) {
    const wordsArray = paragraph?.trim()?.split(/\s+/)
    const totalWords = wordsArray?.length
    const readingTimeMinutes = totalWords / wordsPerMinute
    const readingTime = Math.ceil(readingTimeMinutes * 60)
    return readingTime
  }

  function handleQuestions (paragraph) {
    const questionMarks = paragraph?.match(/\?+/g)
    return questionMarks ? questionMarks?.length : 0
  }

  function handleSpamCount (subject, paragraph) {
    const stringToCheck = paragraph + ' ' + subject
    const spamObj = checkSpamWords(stringToCheck)

    highlightSpam(spamObj.spam)
    return spamObj.count
  }

  const handleCountUrlLength = (content) => {
    if (typeof window !== 'undefined') {
      const parser = new window.DOMParser()
      const doc = parser.parseFromString(content, 'text/html')
      const links = doc.querySelectorAll('a')
      const totalLinksCount = links.length
      return totalLinksCount
    }
  }

  function handleSubmit (event) {
    event.preventDefault()
    const subjectCountBar = editorSubject
    const wordCountBar = editorContent
    setSubjectCount(subjectCountBar.split(/\s+/).filter(Boolean).length)
    setWordCount(wordCountBar.split(/\s+/).filter(Boolean).length)
    setReadingTime(() => handleReadingTime(wordCountBar))
    // setUrlCount(() => handleUrlCount(wordCountBar));
    setQuestionCount(() => handleQuestions(wordCountBar))
    setSpamCount(() => handleSpamCount(subjectCountBar, wordCountBar))
    const urlCountResult = handleCountUrlLength(editorContent)
    setUrlCount(urlCountResult)
  }
  const handleRefresh = () => {
    setRefresh(!refresh)
  }

  const containerStyle = {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '36vh',
    padding: '1px',
    boxSizing: 'border-box'
  }

  const editorStyle = {
    width: '100%',
    height: '100%'
  }

  const handleOpenTemplateModal = () => {
    setTempOpenModal(true)
  }

  // const [workspace, setWorkspace] = useState(null);

  const handleWorkspaceChange = (newWorkspace) => {
    // setWorkspace(newWorkspace);
  }

  const { canEdit } = useAuth()

  const { data } = useGetMeAndWorkspaceQuery()
  const { data: HubSpotIntegrationData } = useGetHubspotIntegrationQuery()
  const { data: pipelines } = useGetDealPipelinesQuery()

  useEffect(() => {
    if (pipelines?.data?.results) {
      setPipelineOptions([
        { id: 'NoPipelineSelected', label: '-- Select Pipeline --', displayOrder: -1 },
        ...pipelines.data.results
      ])
    }
  }, [pipelines])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          width: '100%',
          height: '100%'
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: 'calc(100vh - 134px)',
            px: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          <HeaderWithPopover title='InboxHub' onWorkspaceChange={handleWorkspaceChange} selectedTimeline={data?.workspace} />
          {workspace?.enterprise && !workspace?.analytics?.uiForUnibox && (
            <Typography
              sx={{
                fontSize: '15px',
                fontWeight: 700,
                lineHeight: '16.38px',
                color: `${theme.palette.primary.main}`,
                mt: 3
              }}
            >
              Purchase the InboxHub UI Plan to manage your inboxHub process effectively.
            </Typography>
          )}
          {workspace._id && ((workspace?.analytics?.uiForUnibox && workspace?.enterprise) || !workspace?.enterprise) && (
            <Grid container columnSpacing={1} sx={{ height: '100%', mt: 4 }}>
              <Drawer
                open={isSmDown ? inboxTabsOpen : false}
                variant='temporary'
                onClose={() => setInboxTabsOpen(false)}
                sx={{
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: { sm: '300px', xs: '100%' }
                  }
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    zIndex: 1
                  }}
                >
                  <IconButton onClick={() => setInboxTabsOpen(false)}>
                    <CloseOutlined />
                  </IconButton>
                </Box>
                <InboxColumn
                  value={value}
                  handleChange={handleChange}
                  isCampaignsLoading={isCampaignsLoading}
                  currentCampaign={currentCampaign}
                  setCurrentCampaign={setCurrentCampaign}
                  handleClickCampaign={handleClickCampaign}
                  showAll={showAll}
                  setShowAll={setShowAll}
                  handleSearchAccountChange={handleSearchAccountChange}
                  handleSearchCampaignChange={handleSearchCampaignChange}
                  handleSearchStatusChange={handleSearchStatusChange}
                  campaignData={campaignData}
                  totalCampaigns={totalCampaigns}
                  accounts={accounts}
                  totalAccounts={totalAccounts}
                  getAllCampaigns={getAllCampaigns}
                  getAllAccounts={getAllAccounts}
                  isAccountsLoading={isAccountsLoading}
                  currentAccount={currentAccount}
                  setCurrentAccount={setCurrentAccount}
                  isLabelsLoading={isLabelsLoading}
                  statusLabels={displayedOptions}
                  currentStatus={currentStatus}
                  setCurrentStatus={setCurrentStatus}
                  handleClickStatus={handleClickStatus}
                  handleClickAccount={handleClickAccount}
                  setOpenAllInboxes={setOpenAllInboxes}
                  setOpenAllCampaigns={setOpenAllCampaigns}
                  openAllCampaigns={openAllCampaigns}
                  openAllInboxes={openAllInboxes}
                  theme={theme}
                  setInboxTabsOpen={setInboxTabsOpen}
                  handleClickOpenAddStatusDialog={handleClickOpenAddStatusDialog}
                  searchAccount={searchAccount}
                  setSearchAccount={setSearchAccount}
                  searchCampaign={searchCampaign}
                  setSearchCampaign={setSearchCampaign}
                  setMoreData={setMoreData}
                  setUnread={setUnread}
                  setSent={setSent}
                />
              </Drawer>
              <Grid item xs={2} sm={4} md={4} lg={2} sx={{ height: '100%', display: { xs: 'none', sm: 'block' } }}>
                <InboxColumn
                  value={value}
                  handleChange={handleChange}
                  isCampaignsLoading={isCampaignsLoading}
                  currentCampaign={currentCampaign}
                  setCurrentCampaign={setCurrentCampaign}
                  handleClickCampaign={handleClickCampaign}
                  showAll={showAll}
                  setShowAll={setShowAll}
                  handleSearchAccountChange={handleSearchAccountChange}
                  handleSearchCampaignChange={handleSearchCampaignChange}
                  handleSearchStatusChange={handleSearchStatusChange}
                  campaignData={campaignData}
                  totalCampaigns={totalCampaigns}
                  accounts={accounts}
                  getAllCampaigns={getAllCampaigns}
                  totalAccounts={totalAccounts}
                  getAllAccounts={getAllAccounts}
                  isAccountsLoading={isAccountsLoading}
                  currentAccount={currentAccount}
                  setCurrentAccount={setCurrentAccount}
                  isLabelsLoading={isLabelsLoading}
                  statusLabels={displayedOptions}
                  currentStatus={currentStatus}
                  setCurrentStatus={setCurrentStatus}
                  handleClickStatus={handleClickStatus}
                  handleClickAccount={handleClickAccount}
                  setOpenAllInboxes={setOpenAllInboxes}
                  setOpenAllCampaigns={setOpenAllCampaigns}
                  openAllCampaigns={openAllCampaigns}
                  openAllInboxes={openAllInboxes}
                  theme={theme}
                  setInboxTabsOpen={setInboxTabsOpen}
                  handleClickOpenAddStatusDialog={handleClickOpenAddStatusDialog}
                  searchAccount={searchAccount}
                  setSearchAccount={setSearchAccount}
                  searchCampaign={searchCampaign}
                  setSearchCampaign={setSearchCampaign}
                  setMoreData={setMoreData}
                  unread={unread}
                  setUnread={setUnread}
                  sent={sent}
                  setSent={setSent}
                />
              </Grid>
              <Grid item xs={12} sm={8} md={8} lg={4} sx={{ height: '100%' }}>
                {currentEmail && isMobile
                  ? (
                    <EmailContent
                      email={currentEmail}
                      mailReplies={mailReplies}
                      isCampaignsEmailReplyLoading={isCampaignsEmailReplyLoading}
                      handleClick={handleClick}
                      handleCloseEditor={handleCloseEditor}
                      handleEditClick={handleEditClick}
                      handleForwardClick={handleForwardClick}
                      handleClose={handleClose}
                      open={open}
                      anchorEl={anchorEl}
                      theme={theme}
                      handleClickOpenLeadsDetailsDialog={handleClickOpenLeadsDetailsDialog}
                      handleClickOpenSendToHubspotDialog={handleClickOpenSendToHubspotDialog}
                      handleClickOpenRemoveLeadDialog={handleClickOpenRemoveLeadDialog}
                      handleClickOpenDeleteThreadDialog={handleClickOpenDeleteThreadDialog}
                      handleClickOpenMoveToCampaignDialog={handleClickOpenMoveToCampaignDialog}
                      handlePortalEmailOpened={handlePortalEmailOpened}
                      replyId={replyId}
                      handleReload={handleReload}
                      isLabelsLoading={isLabelsLoading}
                      statusLabels={statusLabels}
                      inboxSelectedID={inboxSelectedID}
                      fetchUpdatedData={fetchUpdatedData}
                      handleClickOpenAddStatusDialog={handleClickOpenAddStatusDialog}
                      sent={sent}
                    />
                    )
                  : (
                    <Box>
                      {!sent
                        ? (
                          <EmailList
                            campaignEmails={campaignEmails}
                            isCampaignsEmailLoading={isCampaignsEmailLoading}
                            inboxSelectedID={inboxSelectedID}
                            handleEmailClick={handleEmailClick}
                            page={page}
                            setPage={setPage}
                            totalCampaignsEmails={totalCampaignsEmails}
                            limit={limit}
                            handleLimitChange={handleLimitChange}
                            parseBodyContent={parseBodyContent}
                            unread={unread}
                            handleRefresh={handleRefresh}
                            handleClickOpenAddStatusDialog={handleClickOpenAddStatusDialog}
                            EmailUpdatedData={EmailUpdatedData}
                            handleSearchStatusChange={handleSearchStatusChange}
                            items={statusLabels}
                            showCreateButton
                            createButtonText='Create New Label'
                            searchPlaceholder='Search by status'
                            onCreateButtonClick={handleClickOpenAddStatusDialog}
                            currentStatus={currentStatus}
                            setCurrentStatus={setCurrentStatus}
                            setCurrentEmail={setCurrentEmail}
                            handleClickOpenRemoveLeadDialog={handleClickOpenRemoveLeadDialog}
                            campaignData={campaignData}
                            setCampaignData={setCampaignData}
                            campaignDataCount={campaignDataCount}
                            setCampaignDataCount={setCampaignDataCount}
                            accounts={accounts}
                            setAccounts={setAccounts}
                            removeLeadOptions={removeLeadOptions}
                            setRemoveLeadOptions={setRemoveLeadOptions}
                            canEdit={canEdit}
                          />
                          )
                        : (
                          <EmailList
                            campaignEmails={sentEmails}
                            isCampaignsEmailLoading={isSentEmailsLoading}
                            inboxSelectedID={inboxSelectedID}
                            handleEmailClick={handleSentEmailClick}
                            page={sentPage}
                            setPage={setSentPage}
                            totalCampaignsEmails={totalSentEmails}
                            limit={sentLimit}
                            handleLimitChange={handleSentLimitChange}
                            parseBodyContent={parseBodyContent}
                            sent={sent}
                            handleRefresh={handleRefresh}
                            handleClickOpenAddStatusDialog={handleClickOpenAddStatusDialog}
                            EmailUpdatedData={EmailUpdatedData}
                            handleSearchStatusChange={handleSearchStatusChange}
                            items={statusLabels}
                            showCreateButton
                            createButtonText='Create New Label'
                            searchPlaceholder='Search by status'
                            onCreateButtonClick={handleClickOpenAddStatusDialog}
                            currentStatus={currentStatus}
                            setCurrentStatus={setCurrentStatus}
                            setCurrentEmail={setCurrentEmail}
                            handleClickOpenRemoveLeadDialog={handleClickOpenRemoveLeadDialog}
                            campaignData={campaignData}
                            setCampaignData={setCampaignData}
                            campaignDataCount={campaignDataCount}
                            setCampaignDataCount={setCampaignDataCount}
                            accounts={accounts}
                            setAccounts={setAccounts}
                            removeLeadOptions={removeLeadOptions}
                            setRemoveLeadOptions={setRemoveLeadOptions}
                          />
                          )}
                    </Box>
                    )}
              </Grid>
              <Grid item xs={6} sm={4} lg={6} sx={{ height: '100%', display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>
                {currentEmail
                  ? (
                    <EmailContent
                      email={currentEmail}
                      mailReplies={mailReplies}
                      isCampaignsEmailReplyLoading={isCampaignsEmailReplyLoading}
                      handleClick={handleClick}
                      handleCloseEditor={handleCloseEditor}
                      handleEditClick={handleEditClick}
                      handleForwardClick={handleForwardClick}
                      handleClose={handleClose}
                      open={open}
                      anchorEl={anchorEl}
                      theme={theme}
                      handleClickOpenLeadsDetailsDialog={handleClickOpenLeadsDetailsDialog}
                      handleClickOpenSendToHubspotDialog={handleClickOpenSendToHubspotDialog}
                      handleClickOpenRemoveLeadDialog={handleClickOpenRemoveLeadDialog}
                      handleClickOpenDeleteThreadDialog={handleClickOpenDeleteThreadDialog}
                      handleClickOpenMoveToCampaignDialog={handleClickOpenMoveToCampaignDialog}
                      handlePortalEmailOpened={handlePortalEmailOpened}
                      replyId={replyId}
                      handleReload={handleReload}
                      isLabelsLoading={isLabelsLoading}
                      statusLabels={statusLabels}
                      inboxSelectedID={inboxSelectedID}
                      fetchUpdatedData={fetchUpdatedData}
                      handleClickOpenAddStatusDialog={handleClickOpenAddStatusDialog}
                      sent={sent}
                      handleRefresh={handleRefresh}
                      canEdit={canEdit}
                    />
                    )
                  : (
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        color: `${theme.palette.primary.primaryText}`
                      }}
                    >
                      <MailOutline sx={{ fontSize: '80px', mb: 2 }} />
                      <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>Select an email to view</Typography>
                    </Box>
                    )}
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
      <Dialog
        open={isEditorDialogOpen}
        onClose={() => setIsEditorDialogOpen(false)}
        fullWidth
        maxWidth='md'
        sx={{ backgroundColor: 'rgba(4, 4, 30, 0.5)' }}
        disableEnforceFocus
        fullScreen={isMobile}
      >
        <DialogTitle
          sx={{
            textAlign: 'center',
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '25px',
            letterSpacing: '0em',
            color: `${theme.palette.primary.primaryText}`,
            position: 'relative'
          }}
        >
          <Typography sx={{ fontSize: '20px', fontWeight: '700' }}>Send reply email</Typography>
          <IconButton
            sx={{ position: 'absolute', right: 0, top: 0 }}
            onClick={() => setIsEditorDialogOpen(false)}
          >
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: '100%',
              borderRadius: 2,
              border: '1px solid rgba(0,0,0,0.1)',
              p: 2,
              pb: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <form onSubmit={() => { }} style={{ width: '100%' }} variant='primary'>
              <Box
                sx={{
                  width: '100%',

                  justifyContent: 'center',
                  alignContent: 'center',
                  display: 'flex'
                }}
              >
                <Grid
                  container
                  // spacing={3}
                  maxWidth='md'
                  sx={{ position: 'relative', ml: 0, mt: 0 }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                      px: 2,
                      py: 1,
                      backgroundColor: theme.palette.grey[100],
                      borderRadius: '16px'
                    }}
                  >
                    <Grid item xs={12} md={8} sx={{ borderRadius: '10px' }}>
                      <Stack spacing={2}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%'
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '13px',
                              fontWeight: 700,
                              lineHeight: '16px',
                              color: `${theme.palette.primary.primaryText}`,
                              mr: 2
                            }}
                          >
                            Subject:
                          </Typography>
                          <TextField
                            fullWidth
                            variant='outlined'
                            sx={{
                              '& div input': {
                                border: 'none',
                                fontWeight: 600
                              },
                              '& div fieldset': {
                                border: 'none'
                              }
                            }}
                            placeholder='Your subject'
                            name='subject'
                            value={editorSubject}
                            readOnly
                            onClick={() => { }}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={4} sx={{ display: { xs: 'none', md: 'block' } }}>
                      <Box
                        sx={{
                          // borderLeft: "1px solid rgba(0,0,0,0.1)",
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          pl: 1,
                          width: '100%',
                          height: '100%'
                        }}
                      >
                        <Tooltip title='' arrow placement='top'>
                          <Button
                            onClick={() => {
                              getEmailBodyFromPrompt(editorContent)
                            }}
                            variant='outlined'
                            sx={{
                              mr: 1,
                              borderColor: `${theme.palette.primary.primaryText}`
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 24,
                                height: 24
                              }}
                            >
                              {isWriteEmailLoading
                                ? (
                                  <CircularProgress size={16} thickness={5} />
                                  )
                                : (
                                  <OpenAiIcon />
                                  )}
                            </Box>
                          </Button>
                        </Tooltip>

                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Button
                            variant='contained'
                            sx={{
                              cursor: 'pointer'
                            }}
                            disabled={isSendingReply}
                            onClick={sendReplyEmail}
                          >
                            {isSendingReply ? <CircularProgress size={22} /> : <> Send </>}
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  {isMobile && (
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          // borderLeft: "1px solid rgba(0,0,0,0.1)",
                          display: 'flex',
                          justifyContent: { xs: 'space-between', sm: 'flex-end' },
                          alignItems: 'center',

                          width: '100%',
                          height: '100%',
                          my: 0.5
                        }}
                      >
                        <Tooltip title='' arrow placement='top'>
                          <Button
                            onClick={() => {
                              getEmailBodyFromPrompt(editorContent)
                            }}
                            variant='outlined'
                            sx={{
                              mr: 1,
                              borderColor: `${theme.palette.primary.primaryText}`
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 24,
                                height: 24
                              }}
                            >
                              {isWriteEmailLoading
                                ? (
                                  <CircularProgress size={16} thickness={5} />
                                  )
                                : (
                                  <OpenAiIcon />
                                  )}
                            </Box>
                          </Button>
                        </Tooltip>

                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Button
                            variant='contained'
                            sx={{
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0
                            }}
                            disabled={isSendingReply}
                            onClick={sendReplyEmail}
                          >
                            {isSendingReply ? <CircularProgress size={22} /> : <> Send </>}
                          </Button>
                          <Button
                            variant='contained'
                            sx={{
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                              px: 0.5,
                              py: '8.3px',
                              minWidth: 'auto'
                            }}
                          >
                            <ArrowDropDown fontSize='small' />
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  )}

                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{ py: 1, minHeight: { xs: '500px', sm: 'fit-content' } }}
                  >
                    {/* <Editor
                      apiKey={config.TINYMCE_EDITOR_API}
                      onEditorChange={(value) => {
                        setEditorContent(value);
                        hasMoreThanFiveWords(value);
                      }}
                      onClick={handleEditorClick}
                      value={editorContent}
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      init={{
                        height: "100%",
                        selector: "textarea",
                        init_instance_callback: function (editor) {
                          var freeTiny = document.querySelector(".tox .tox-notification--in");
                          if (freeTiny) {
                            freeTiny.style.display = "none";
                          }
                          const statusBarTextContainer = document.querySelector(
                            ".tox .tox-statusbar__text-container"
                          );
                          statusBarTextContainer.style.display = "none";
                          const statusBar = document.querySelector(".tox .tox-statusbar");
                          statusBar.style.border = "none";
                        },
                        menubar: false,
                        plugins: [
                          "mentions advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media paste code help wordcount",
                          "autolink",
                          "link", "emoticons"
                        ],
                        toolbar:
                          "undo redo | formatselect | " +
                          "bold italic backcolor | link | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | emoticons",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        emoticons_append: {
                          custom_mind_explode: {
                            keywords: ["brain", "mind", "explode", "blown"],
                            char: "🤯",
                          },
                        },
                      }}
                    /> */}
                    <QuillToolbar onCustomButtonClick={handleOpenTemplateModal} />
                    <Box style={containerStyle}>
                      <ReactQuill
                        ref={quillEditorRef}
                        modules={modules}
                        formats={formats}
                        theme='snow'
                        value={editorContent.replace(/<p>Subject:[^<]*<br><br>/, '')}
                        onChange={(value) => {
                          const modifiedHtml = value.replace(
                            /href="\.\.\//,
                            `href="${extractedBaseUrl}/`
                          )
                          setEditorContent(modifiedHtml)
                        }}
                        style={editorStyle}
                        onFocus={handleEditorClick}
                      />
                    </Box>
                    <Box style={{ marginTop: 50 }}>
                      <textarea
                        className='ql-html-editor'
                        style={{
                          zIndex: +1,
                          marginTop: !isMobile ? '25%' : '60%',
                          height: '100%',
                          width: '100%',
                          // margin: '0px',
                          background: 'rgb(29, 29, 29)',
                          boxSizing: 'border-box',
                          color: 'rgb(204, 204, 204)',
                          fontSize: '15px',
                          outline: 'none',
                          padding: '20px',
                          lineHeight: '24px',
                          fontFamily: 'Consolas, Menlo, Monaco, "Courier New", monospace',
                          position: 'absolute',
                          top: '0',
                          border: 'none',
                          display: 'none'
                        }}
                      />
                    </Box>

                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Stack spacing={3} sx={{ p: 2 }}>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 700,
                          lineHeight: '20px',
                          color: `${theme.palette.primary.primaryText}`
                        }}
                      >
                        Email template insights
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          flexDirection: 'column'
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%'
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '13px',
                              fontWeight: 700,
                              lineHeight: '16px',
                              color: `${theme.palette.primary.primaryText}`
                            }}
                          >
                            Subject Count
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '13px',
                              fontWeight: 700,
                              lineHeight: '16px',
                              color: '#8181B0'
                            }}
                          >
                            {subjectCount}
                          </Typography>
                        </Box>

                        <CustomCounterProgress
                          countOf={subjectCount}
                          maxCountOf={maxSubjectCount}
                          minRange={3}
                          maxRange={5}
                          barColor='#00bcd4'
                        />
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          flexDirection: 'column'
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%'
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '13px',
                              fontWeight: 700,
                              lineHeight: '16px',
                              color: `${theme.palette.primary.primaryText}`
                            }}
                          >
                            Word Count
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '13px',
                              fontWeight: 700,
                              lineHeight: '16px',
                              color: '#8181B0'
                            }}
                          >
                            {wordCount}
                          </Typography>
                        </Box>

                        <CustomCounterProgress
                          countOf={wordCount}
                          maxCountOf={maxWordCount}
                          minRange={16}
                          maxRange={150}
                          barColor='#ffc400'
                        />
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          flexDirection: 'column'
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%'
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '13px',
                              fontWeight: 700,
                              lineHeight: '16px',
                              color: `${theme.palette.primary.primaryText}`
                            }}
                          >
                            Reading time
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '13px',
                              fontWeight: 700,
                              lineHeight: '16px',
                              color: '#8181B0'
                            }}
                          >
                            {readingTime}
                          </Typography>
                        </Box>

                        <CustomCounterProgress
                          countOf={readingTime}
                          maxCountOf={maxReadingTime}
                          minRange={11}
                          maxRange={60}
                          barColor='#4caf50'
                        />
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          flexDirection: 'column'
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%'
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '13px',
                              fontWeight: 700,
                              lineHeight: '16px',
                              color: `${theme.palette.primary.primaryText}`
                            }}
                          >
                            URL Count
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '13px',
                              fontWeight: 700,
                              lineHeight: '16px',
                              color: '#8181B0'
                            }}
                          >
                            {urlCount}
                          </Typography>
                        </Box>

                        <CustomCounterProgress
                          countOf={urlCount}
                          maxCountOf={maxLinks}
                          minRange={0}
                          maxRange={1}
                          barColor='#6d1b7b'
                        />
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          flexDirection: 'column'
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%'
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '13px',
                              fontWeight: 700,
                              lineHeight: '16px',
                              color: `${theme.palette.primary.primaryText}`
                            }}
                          >
                            Question Count
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '13px',
                              fontWeight: 700,
                              lineHeight: '16px',
                              color: '#8181B0'
                            }}
                          >
                            {questionCount}
                          </Typography>
                        </Box>

                        <CustomCounterProgress
                          countOf={questionCount}
                          maxCountOf={maxQuestions}
                          minRange={0}
                          maxRange={2}
                          barColor='#e040fb'
                        />
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          flexDirection: 'column'
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%'
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '13px',
                              fontWeight: 700,
                              lineHeight: '16px',
                              color: `${theme.palette.primary.primaryText}`
                            }}
                          >
                            Spam word count
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '13px',
                              fontWeight: 700,
                              lineHeight: '16px',
                              color: '#8181B0'
                            }}
                          >
                            {spamCount}
                          </Typography>
                        </Box>

                        <CustomCounterProgress
                          countOf={spamCount}
                          maxCountOf={maxSpams}
                          minRange={0}
                          maxRange={15}
                          barColor='#ff5722'
                        />
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          mt: 1,
                          width: '100%'
                        }}
                      >
                        <Button
                          sx={{ px: 2, width: '30px' }}
                          color='primary'
                          variant='outlined'
                          type='submit'
                          id='submit-btn'
                          onClick={handleSubmit}
                          disabled={editorContent === ''}
                        >
                          Check
                        </Button>
                        {editorContent && (
                          <Button
                            sx={{ px: 1, py: 1, width: 'auto', ml: 2 }}
                            color='primary'
                            variant='contained'
                            type='Button'
                            onClick={handleOptimizeClick}
                            disabled={isOptimizeEmailLoading}
                          >
                            {isOptimizeEmailLoading
                              ? (
                                <CircularProgress size={25} thickness={5} />
                                )
                              : (
                                  'Optimize'
                                )}
                          </Button>
                        )}
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={forwardPopupOpen}
        onClose={() => setForwardPopupOpen(false)}
        fullWidth
        maxWidth='sm'
        sx={{ backgroundColor: 'rgba(4, 4, 30, 0.5)' }}
        disableEnforceFocus
      >
        <DialogTitle>Forward to</DialogTitle>
        <DialogContent>
          <TextField
            id='name'
            placeholder='Enter email'
            type='email'
            fullWidth
            variant='outlined'
            autoComplete='off'
            value={forwardEmail}
            onChange={(event) => {
              setForwardEmail(event.target.value)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={isLoadingForward} onClick={sendForwardMail}>
            {' '}
            {isLoadingForward ? <CircularProgress /> : <> Send </>}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openLeadsDetailsDialog}
        onClose={handleCloseLeadsDetailsDialog}
        fullWidth
        maxWidth='sm'
        sx={{ backgroundColor: 'rgba(4, 4, 30, 0.5)' }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            p: 3,
            flexDirection: 'column'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <Typography
              sx={{
                fontSize: '20px',
                fontWeight: 700,
                lineHeight: '28px',
                letterSpacing: '0em',
                color: `${theme.palette.primary.primaryText}`
              }}
            >
              Lead Details
            </Typography>
            <IconButton onClick={handleCloseLeadsDetailsDialog}>
              <Close />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              mt: 2
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Avatar sx={{ width: 40, height: 40, backgroundColor: 'rgba(4, 4, 30, 0.1)' }}>
                <Typography
                  sx={{
                    fontSize: '13px',
                    fontWeight: 700,
                    lineHeight: '16px',
                    letterSpacing: '0em',
                    color: `${theme.palette.primary.primaryText}`
                  }}
                >
                  {getInitials(currentEmail?.leads?.firstName, currentEmail?.leads?.lastName)}
                </Typography>
              </Avatar>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  ml: 2,
                  height: '100%'
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '18px',
                    letterSpacing: '0em',
                    color: `${theme.palette.primary.primaryText}`
                  }}
                >
                  {currentEmail?.leads?.email}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Typography
            sx={{
              width: '100%',
              textAlign: 'left',
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: '20px',
              color: `${theme.palette.primary.primaryText}`,
              mt: 3
            }}
          >
            Email
          </Typography>
          <TextField
            fullWidth
            placeholder='Email'
            variant='outlined'
            sx={{
              mt: 2,
              width: '100%',
              backgroundColor: 'white',
              '& div': { pl: 0.3 },
              '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
              '& div input': {
                py: 2,
                fontSize: '13px',
                fontWeight: 400,
                lineHeight: '16px',
                letterSpacing: '0em',
                '&::placeholder': {
                  color: 'rgba(40, 40, 123, 0.5)'
                }
              }
            }}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <Typography
            sx={{
              width: '100%',
              textAlign: 'left',
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: '20px',
              color: `${theme.palette.primary.primaryText}`,
              mt: 2
            }}
          >
            firstName
          </Typography>
          <TextField
            fullWidth
            placeholder='First Name'
            variant='outlined'
            sx={{
              mt: 2,
              width: '100%',
              backgroundColor: 'white',
              '& div': { pl: 0.3 },
              '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
              '& div input': {
                py: 2,
                fontSize: '13px',
                fontWeight: 400,
                lineHeight: '16px',
                letterSpacing: '0em',
                '&::placeholder': {
                  color: 'rgba(40, 40, 123, 0.5)'
                }
              }
            }}
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              mt: 2,
              width: '100%'
            }}
          >
            <Button onClick={() => { handleLeadEdit() }} variant='contained'>
              Save
            </Button>
          </Box>
        </Box>
      </Dialog>

      <Dialog
        open={openSentToHubspotDialog}
        onClose={handleCloseSentToHubspotDialog}
        fullWidth
        maxWidth='sm'
        sx={{ backgroundColor: 'rgba(4, 4, 30, 0.5)' }}
      >
        {HubSpotIntegrationData?.data
          ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                p: 3,
                flexDirection: 'column'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontWeight: 700,
                    lineHeight: '28px',
                    letterSpacing: '0em',
                    color: `${theme.palette.primary.primaryText}`
                  }}
                >
                  Deal Details
                </Typography>
                <IconButton onClick={handleCloseSentToHubspotDialog}>
                  <Close />
                </IconButton>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  width: '100%',
                  mt: 3
                }}
              >
                <Box sx={{ width: '48%' }}>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 700,
                      lineHeight: '20px',
                      color: `${theme.palette.primary.primaryText}`,
                      mb: 1
                    }}
                  >
                    Email
                  </Typography>
                  <TextField
                    placeholder='Email'
                    variant='outlined'
                    fullWidth
                    disabled
                    sx={{
                      backgroundColor: 'white',
                      '& div': { pl: 0.3 },
                      '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                      '& div input': {
                        py: 2,
                        fontSize: '13px',
                        fontWeight: 400,
                        lineHeight: '16px',
                        '&::placeholder': {
                          color: 'rgba(40, 40, 123, 0.5)'
                        }
                      }
                    }}
                    value={currentEmail?.leads?.email}
                  />
                </Box>

                <Box sx={{ width: '48%' }}>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 700,
                      lineHeight: '20px',
                      color: `${theme.palette.primary.primaryText}`,
                      mb: 1
                    }}
                  >
                    First Name
                  </Typography>
                  <TextField
                    placeholder='First Name'
                    variant='outlined'
                    fullWidth
                    disabled
                    sx={{
                      backgroundColor: 'white',
                      '& div': { pl: 0.3 },
                      '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                      '& div input': {
                        py: 2,
                        fontSize: '13px',
                        fontWeight: 400,
                        lineHeight: '16px',
                        '&::placeholder': {
                          color: 'rgba(40, 40, 123, 0.5)'
                        }
                      }
                    }}
                    value={`${currentEmail?.leads?.firstName || ''} ${currentEmail?.leads?.lastName || ''}`.trim() || 'Unknown Name'}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  width: '100%',
                  mt: 3
                }}
              >

                <Box sx={{ width: '48%' }}>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 700,
                      lineHeight: '20px',
                      color: `${theme.palette.primary.primaryText}`,
                      mb: 1
                    }}
                  >
                    Deal Name
                  </Typography>
                  <TextField
                    variant='outlined'
                    fullWidth
                    sx={{
                      backgroundColor: 'white',
                      '& div': { pl: 0.3 },
                      '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                      '& div input': {
                        py: 2,
                        fontSize: '13px',
                        fontWeight: 400,
                        lineHeight: '16px'
                      }
                    }}
                    onChange={(e) => setDealDetails((prevDetails) => ({
                      ...prevDetails,
                      dealname: e.target.value
                    }))}
                  />
                </Box>

                <Box sx={{ width: '48%' }}>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 700,
                      lineHeight: '20px',
                      color: `${theme.palette.primary.primaryText}`,
                      mb: 1
                    }}
                  >
                    Amount
                  </Typography>
                  <TextField
                    type='number'
                    variant='outlined'
                    fullWidth
                    sx={{
                      backgroundColor: 'white',
                      '& div': { pl: 0.3 },
                      '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                      '& div input': {
                        py: 2,
                        fontSize: '13px',
                        fontWeight: 400,
                        lineHeight: '16px'
                      }
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      inputProps: { min: 1 }
                    }}
                    defaultValue={1000}
                    onChange={(e) => setDealDetails((prevDetails) => ({
                      ...prevDetails,
                      amount: e.target.value
                    }))}
                  />
                </Box>

              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  width: '100%',
                  mt: 3
                }}
              >

                <Box sx={{ width: '48%' }}>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 700,
                      lineHeight: '20px',
                      color: `${theme.palette.primary.primaryText}`,
                      mb: 1
                    }}
                  >
                    Deal PipeLine
                  </Typography>
                  <Select
                    fullWidth
                    value={selectedPipeline}
                    onChange={(e) => handleDealPipelineChange(e)}
                    sx={{
                      backgroundColor: 'white',
                      '& fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                      '& .MuiSelect-select': {
                        py: 1.5,
                        fontSize: '13px',
                        fontWeight: 400,
                        color: `${theme.palette.primary.primaryText}`
                      }
                    }}
                  >
                    {pipelineOptions.map((data) => (
                      <MenuItem key={data?.displayOrder} value={data?.id}>{data?.label}</MenuItem>
                    ))}
                  </Select>
                </Box>

                <Box sx={{ width: '48%' }}>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 700,
                      lineHeight: '20px',
                      color: `${theme.palette.primary.primaryText}`,
                      mb: 1
                    }}
                  >
                    Deal Stage
                  </Typography>
                  <Select
                    fullWidth
                    value={selectedStage}
                    onChange={(e) => { handleDealStageChange(e) }}
                    sx={{
                      backgroundColor: 'white',
                      '& fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                      '& .MuiSelect-select': {
                        py: 1.5,
                        fontSize: '13px',
                        fontWeight: 400,
                        color: `${theme.palette.primary.primaryText}`
                      }
                    }}
                  >
                    {stageOptions.map((data) => (
                      <MenuItem key={data?.displayOrder} value={data?.id}>{data?.label}</MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  mt: 2,
                  width: '100%'
                }}
              >
                <Button onClick={handleSentToHubspotDeal} variant='contained' sx={{ mr: 2 }}>
                  Save
                </Button>
                <Button variant='outlined' onClick={handleCloseSentToHubspotDialog}>
                  Cancel
                </Button>
              </Box>

            </Box>
            )
          : (
            <>
              <DialogTitle>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '20px',
                      fontWeight: 700,
                      lineHeight: '28px',
                      letterSpacing: '0em',
                      color: `${theme.palette.primary.primaryText}`
                    }}
                  >
                    Deal Details
                  </Typography>
                  <IconButton onClick={handleCloseSentToHubspotDialog}>
                    <Close />
                  </IconButton>
                </Box>
              </DialogTitle>
              <DialogContent>
                No Hubspot Integration Found Please Integrate Success.ai App With Hubspot To Use This Feature.
                <Link
                  href='https://app.hubspot.com/login'
                  color='primary'
                  underline='none'
                  target='_blank' rel='noreferrer'
                >
                  Start Integration
                </Link>
              </DialogContent>
              <DialogActions>
                <Button variant='outlined' onClick={handleCloseSentToHubspotDialog}>
                  Cancel
                </Button>
              </DialogActions>
            </>
            )}
      </Dialog>

      <Dialog
        open={openRemoveLeadDialog}
        onClose={handleCloseRemoveLeadDialog}
        fullWidth
        maxWidth='sm'
        sx={{ backgroundColor: 'rgba(4, 4, 30, 0.5)' }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: 1,
            px: 4,
            pt: 4
          }}
        >
          <PersonOff />
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 700,
              lineHeight: '28px',
              letterSpacing: '0em',
              color: `${theme.palette.primary.primaryText}`
            }}
          >
            Remove Lead
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ px: 4 }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    '&.MuiCheckbox-root .MuiSvgIcon-root': { color: theme.palette.primary.main }
                  }}
                  checked={removeLeadOptions.allDomain}
                  onChange={(e) =>
                    setRemoveLeadOptions({ ...removeLeadOptions, allDomain: e.target.checked })}
                />
              }
              sx={{
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '18px',
                letterSpacing: '0em',
                color: `${theme.palette.primary.primaryText}`,
                my: { xs: 1, sm: 0 }
              }}
              label='Remove all leads from the same company
              (email domain)'
            />
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    '&.MuiCheckbox-root .MuiSvgIcon-root': { color: theme.palette.primary.main }
                  }}
                  checked={removeLeadOptions.allCampaign}
                  onChange={(e) =>
                    setRemoveLeadOptions({ ...removeLeadOptions, allCampaign: e.target.checked })}
                />
              }
              sx={{
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '18px',
                letterSpacing: '0em',
                color: `${theme.palette.primary.primaryText}`,
                my: { xs: 1, sm: 0 }
              }}
              label='Remove from all campaigns'
            />
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    '&.MuiCheckbox-root .MuiSvgIcon-root': { color: theme.palette.primary.main }
                  }}
                  checked={removeLeadOptions.blocklist}
                  onChange={(e) =>
                    setRemoveLeadOptions({ ...removeLeadOptions, blocklist: e.target.checked })}
                />
              }
              sx={{
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '18px',
                letterSpacing: '0em',
                color: `${theme.palette.primary.primaryText}`,
                my: { xs: 1, sm: 0 }
              }}
              label='Add email to blocklist'
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleCloseRemoveLeadDialog}>
            Cancel
          </Button>
          <Button variant='contained' onClick={handleRemoveLeadsClick} color='error'>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteThreadDialog}
        onClose={handleCloseDeleteThreadDialog}
        fullWidth
        maxWidth='sm'
        sx={{ backgroundColor: 'rgba(4, 4, 30, 0.5)' }}
      >
        <DialogTitle>
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 700,
              lineHeight: '28px',
              letterSpacing: '0em',
              color: `${theme.palette.primary.primaryText}`
            }}
          >
            Delete Thread
          </Typography>
        </DialogTitle>
        <DialogContent>
          Are you sure you want to delete all the emails in this thread? The emails will still be in
          your inbox, but will no longer be visible.
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleCloseDeleteThreadDialog}>
            Cancel
          </Button>
          <Button variant='contained' onClick={handleDeleteThreadClick} color='error'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isMoveToCampaignDialogOpen}
        onClose={handleMoveToCampaignDialogClose}
        sx={{
          backgroundColor: 'rgba(4, 4, 30, 0.5)'
        }}
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle
          sx={{
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '28px',
            color: `${theme.palette.primary.primaryText}`
          }}
        >
          Move to Campaign
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            freeSolo
            id='checkboxes-tags-demo'
            options={campaignNames?.filter((c) => c._id !== currentEmail?.campaign_id) || []}
            getOptionLabel={(option) => option.name || ''}
            renderOption={(props, option) => (
              <li
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  px: 0
                }}
                {...props}
              >
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    color: `${theme.palette.primary.primaryText}`
                  }}
                >
                  {option.name}
                </Typography>
              </li>
            )}
            renderTags={(value) => (
              <Box
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '90%',
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '18px',
                  letterSpacing: '0px',
                  color: `${theme.palette.primary.primaryText}`
                }}
              >
                {value}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Select Campaign'
                variant='outlined'
                sx={{
                  backgroundColor: 'white',
                  '& div': { pl: 0.3 },
                  '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                  '& div input': {
                    py: 2,
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: '16px',
                    letterSpacing: '0em',
                    '&::placeholder': {
                      color: 'rgba(40, 40, 123, 0.5)'
                    }
                  },
                  '& label': {
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '18px',
                    letterSpacing: '0px',
                    color: `${theme.palette.primary.primaryText}`
                  }
                }}
                name='location'
              />
            )}
            sx={{ width: '100%', mt: 2 }}
            onChange={(e, option) => setSelectedCampaign(option?._id)}
          />
        </DialogContent>
        <DialogActions sx={{ mb: 3, mx: 2 }}>
          <Button onClick={handleMoveToCampaignDialogClose} variant='outlined' fullWidth>
            Cancel
          </Button>
          <Button
            variant='contained'
            fullWidth
            disabled={!selectedCampaign}
            onClick={handleMoveToCampaignDialogSave}
          >
            {isMovingToCampaign
              ? (
                <CircularProgress size={20} sx={{ color: 'white' }} />
                )
              : (
                  'Move to Campaign'
                )}{' '}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openAddStatusDialog}
        onClose={handleCloseAddStatusDialog}
        fullWidth
        maxWidth='sm'
        sx={{ backgroundColor: 'rgba(4, 4, 30, 0.5)' }}
      >
        <DialogTitle>
          <Typography>Create Lead Label</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label='Label*'
            variant='outlined'
            sx={{
              mt: 2,
              width: '100%',
              backgroundColor: 'white',
              '& div': { pl: 0.3 },
              '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
              '& div input': {
                py: 2,
                fontSize: '13px',
                fontWeight: 400,
                lineHeight: '16px',
                letterSpacing: '0em',
                '&::placeholder': {
                  color: 'rgba(40, 40, 123, 0.5)'
                }
              }
            }}
            inputRef={statusLabelRef}
          // value={statusLabelName}
          // onChange={(e) => {
          //   e.preventDefault();
          //   setStatusLabelName(e.target.value);
          // }}
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id='demo-simple-select-label'>Select interest status*</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              label='Select interest status*'
              onChange={(e) => setStatusType(e.target.value)}
              // sx={{ width: "100%", mt: 2 }}
              placeholder='select interest status'
              value={statusType}
            >
              <MenuItem value='positive'>Positive</MenuItem>
              <MenuItem value='negative'>Negative</MenuItem>
              <MenuItem value='neutral'>Neutral</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleCloseAddStatusDialog}>
            Cancel
          </Button>
          <Button variant='contained' onClick={handleCreateLabel} disabled={isCreatingLabel}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Page
