import React, { useState } from 'react'
import {
  Box,
  Avatar,
  Typography,
  Collapse,
  Tooltip,
  Button,
  CircularProgress
} from '@mui/material'
import { LFVerifiedBadge } from 'src/assets/leadFinder/LFVerifiedBadge'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { LFWeb } from 'src/assets/leadFinder/LFWeb'
import { maskEmail } from 'src/utils/util'
import SearchIcon from '@mui/icons-material/Search'

export const PersonCompanyTab = ({
  user,
  activeTab,
  companiesTab,
  handleAddToCampaignClick,
  isConfirmingLeads,
  handleEmployeesSearchClick
}) => {
  const [isContactInfoOpen, setIsContactInfoOpen] = useState(true)
  const [isPrincipalInfoOpen, setIsPrincipalInfoOpen] = useState(true)
  const handleEmailClick = (email) => {
    if (email) {
      window.location.href = `mailto:${email}`
    }
  }

  const getPhoneNumber = (phone) => {
    return phone?.raw_number || phone?.sanitized_number || 'N/A'
  }

  const handlePhoneClick = (phone) => {
    const phoneNumber = phone?.raw_number || phone?.sanitized_number
    if (phoneNumber) {
      window.location.href = `tel:${phoneNumber}`
    }
  }
  return (
    <>
      {companiesTab
        ? (
          <Box mt={2}>
            <Box sx={{ padding: '0px 20px' }}>
              <Box display='flex' alignItems='center' mb={2}>
                <Avatar
                  src={user?.logo_url || 'https://avatar.iran.liara.run/public/boy?username=Ash'}
                  alt='User'
                  sx={{ width: 50, height: 50, mr: 2 }}
                />
                <Box sx={{ flex: 1 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2
                    }}
                  >
                    <Typography variant='h6' sx={{ fontWeight: '700' }}>
                      {user.name}
                    </Typography>
                  </Box>
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    display='flex'
                    alignItems='center'
                  >
                    {user?.website_url}
                  </Typography>
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    display='flex'
                    alignItems='center'
                    sx={{
                      color: '#8181B0',
                      fontWeight: '500'
                    }}
                  >
                    {user?.industry}
                  </Typography>
                </Box>
                {/* Search Employees Button */}
                <Box display='flex' alignItems='flex-start'>
                  <Tooltip title='Search Employees'>
                    <Button
                      variant='contained'
                      onClick={() => handleEmployeesSearchClick({
                        row: {
                          primary_domain: user?.website_url,
                          name: user?.name
                        }
                      })}
                      startIcon={<SearchIcon />}
                      sx={{
                        textTransform: 'none',
                        borderRadius: '8px',
                        backgroundColor: '#3F50F8',
                        color: 'white',
                        fontWeight: '500',
                        padding: '8px 16px',
                        fontSize: '14px',
                        '&:hover': {
                          backgroundColor: '#2F3BC9'
                        }
                      }}
                    >
                      Search Employees
                    </Button>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                onClick={() => setIsPrincipalInfoOpen(!isPrincipalInfoOpen)}
                sx={{
                  cursor: 'pointer',
                  background: '#F2F4F6',
                  overflow: 'hidden',
                  padding: '5px 20px'
                }}
              >
                <Box display='flex' alignItems='center' sx={{ gap: 2 }}>
                  <Typography variant='subtitle1' fontWeight='bold'>
                    Company Informations
                  </Typography>
                </Box>
                {isPrincipalInfoOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Box>
              <Collapse in={isPrincipalInfoOpen}>
                <Box mt={1} pl={2}>
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '12px'
                      }}
                    >
                      <Typography
                        variant='body2'
                        sx={{
                          marginBottom: '5px'
                        }}
                      >
                        <strong>Company Name</strong>
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography
                          component='span'
                          sx={{
                            display: 'inline-block',
                            backgroundColor: '#E7F0FF',
                            color: '#3F50F8',
                            borderRadius: '4px',
                            padding: '2px 8px',
                            maxWidth: 'fit-content',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {user?.name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '12px'
                      }}
                    >
                      <Typography
                        variant='body2'
                        sx={{
                          marginBottom: '5px'
                        }}
                      >
                        <strong>Website</strong>
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography
                          component='span'
                          sx={{
                            display: 'inline-block',
                            backgroundColor: '#E7F0FF',
                            color: '#3F50F8',
                            borderRadius: '4px',
                            padding: '2px 8px',
                            maxWidth: 'fit-content',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {user?.website_url}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '12px'
                      }}
                    >
                      <Typography
                        variant='body2'
                        sx={{
                          marginBottom: '5px'
                        }}
                      >
                        <strong>LinkedIn Url</strong>
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography
                          component='span'
                          sx={{
                            display: 'inline-block',
                            backgroundColor: '#E7F0FF',
                            color: '#3F50F8',
                            borderRadius: '4px',
                            padding: '2px 8px',
                            maxWidth: 'fit-content',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {user?.linkedin_url}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '12px'
                      }}
                    >
                      <Typography
                        variant='body2'
                        sx={{
                          marginBottom: '5px'
                        }}
                      >
                        <strong>Company Location</strong>
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography
                          component='span'
                          sx={{
                            display: 'inline-block',
                            backgroundColor: '#E7F0FF',
                            color: '#3F50F8',
                            borderRadius: '4px',
                            padding: '2px 8px',
                            maxWidth: 'fit-content',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {`${user?.city}, ${user?.country}`}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '12px'
                      }}
                    >
                      <Typography
                        variant='body2'
                        sx={{
                          marginBottom: '5px'
                        }}
                      >
                        <strong>Industry</strong>
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                        <Typography
                          component='span'
                          sx={{
                            display: 'inline-block',
                            backgroundColor: '#E7F0FF',
                            color: '#3F50F8',
                            borderRadius: '4px',
                            padding: '2px 8px',
                            maxWidth: 'fit-content',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {user?.industry}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '12px'
                      }}
                    >
                      <Typography
                        variant='body2'
                        sx={{
                          marginBottom: '5px'
                        }}
                      >
                        <strong>Employee Count</strong>
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                        <Typography
                          component='span'
                          sx={{
                            display: 'inline-block',
                            backgroundColor: '#E7F0FF',
                            color: '#3F50F8',
                            borderRadius: '4px',
                            padding: '2px 8px',
                            maxWidth: 'fit-content',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {user?.estimated_num_employees}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '12px'
                      }}
                    >
                      <Typography
                        variant='body2'
                        sx={{
                          marginBottom: '5px'
                        }}
                      >
                        <strong>Company Keywords</strong>
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                        {user?.keywords?.map((keyword, index) => (
                          <Typography
                            component='span'
                            sx={{
                              display: 'inline-block',
                              backgroundColor: '#E7F0FF',
                              color: '#3F50F8',
                              borderRadius: '4px',
                              padding: '2px 8px',
                              maxWidth: 'fit-content',
                              whiteSpace: 'nowrap'
                            }}
                            key={index}
                          >
                            {keyword || 'N/A'}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '12px'
                      }}
                    >
                      <Typography
                        variant='body2'
                        sx={{
                          marginBottom: '5px'
                        }}
                      >
                        <strong>Funding</strong>
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                        <Typography
                          component='span'
                          sx={{
                            display: 'inline-block',
                            backgroundColor: '#E7F0FF',
                            color: '#3F50F8',
                            borderRadius: '4px',
                            padding: '2px 8px',
                            maxWidth: 'fit-content',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {user?.total_funding}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '12px'
                      }}
                    >
                      <Typography
                        variant='body2'
                        sx={{
                          marginBottom: '5px'
                        }}
                      >
                        <strong>Technologies</strong>
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                        {user?.technology_names?.map((tech, index) => (
                          <Typography
                            component='span'
                            sx={{
                              display: 'inline-block',
                              backgroundColor: '#E7F0FF',
                              color: '#3F50F8',
                              borderRadius: '4px',
                              padding: '2px 8px',
                              maxWidth: 'fit-content'
                            }}
                            key={index}
                          >
                            {tech}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Collapse>
            </Box>
          </Box>
          )
        : (
          <Box mt={2}>
            <Box sx={{ padding: '0px 20px' }}>
              <Box display='flex' alignItems='center' mb={2}>
                <Avatar
                  src={
                  activeTab === 'company'
                    ? user?.organization?.logo_url
                    : user.photo_url || 'https://avatar.iran.liara.run/public/boy?username=Ash'
                }
                  alt='User'
                  sx={{ width: 50, height: 50, mr: 2 }}
                />
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2
                    }}
                  >
                    <Typography variant='h6' sx={{ fontWeight: '700' }}>
                      {activeTab === 'company' ? user?.organization?.name : user.name}
                    </Typography>
                    {activeTab === 'person' && user.email_status === 'verified' && (
                      <LFVerifiedBadge />
                    )}
                  </Box>
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    display='flex'
                    alignItems='center'
                  >
                    {activeTab === 'company' ? user?.organization?.website_url : user.email}
                  </Typography>
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    display='flex'
                    alignItems='center'
                    sx={{
                      color: '#8181B0',
                      fontWeight: '500'
                    }}
                  >
                    {activeTab === 'company' ? user?.organization?.industry : user.title}
                  </Typography>
                </Box>
              </Box>
              {activeTab === 'person' && (
                <Box display='flex' alignItems='center' mb={2}>
                  <Tooltip title='Add to Campaign'>
                    <Button
                      variant='outlined'
                      onClick={handleAddToCampaignClick}
                      sx={{
                        mr: 1,
                        textTransform: 'none',
                        border: '1px solid #D1D5DB',
                        borderRadius: '8px',
                        color: '#6B7280',
                        fontWeight: '500',
                        padding: '8px 12px',
                        backgroundColor: 'white',
                        '&:hover': {
                          backgroundColor: user.email ? '#F9FAFB' : 'white'
                        }
                      }}
                    >
                      {isConfirmingLeads
                        ? (
                          <CircularProgress size={20} sx={{ color: 'white' }} />
                          )
                        : (
                            'Add to Campaign'
                          )}
                    </Button>
                  </Tooltip>

                  {/* <Tooltip title='Add to List'>
                <Button
                  variant='outlined'
                  onClick={handleAddToListClick}
                  sx={{
                    mr: 1,
                    textTransform: 'none',
                    border: '1px solid #D1D5DB',
                    borderRadius: '8px',
                    color: '#6B7280',
                    fontWeight: '500',
                    padding: '8px 12px',
                    backgroundColor: 'white',
                    '&:hover': {
                      backgroundColor: 'white'
                    }
                  }}
                >
                  Add to List
                </Button>
              </Tooltip> */}
                </Box>
              )}
            </Box>
            {activeTab === 'person' && (
              <Box>
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  onClick={() => setIsContactInfoOpen(!isContactInfoOpen)}
                  sx={{
                    cursor: 'pointer',
                    background: '#F2F4F6',
                    overflow: 'hidden',
                    padding: '5px 20px'
                  }}
                >
                  <Box display='flex' alignItems='center' sx={{ gap: 2 }}>
                    <Typography variant='subtitle1' fontWeight='bold'>
                      Contact Information
                    </Typography>
                  </Box>
                  {isContactInfoOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Box>
                <Collapse in={isContactInfoOpen}>
                  <Box mt={1} pl={2}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '12px'
                      }}
                    >
                      <Typography
                        variant='body2'
                        sx={{
                          marginBottom: '5px'
                        }}
                      >
                        <strong>Email</strong>
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography
                          component='span'
                          sx={{
                            display: 'inline-block',
                            backgroundColor: '#E7F0FF',
                            color: '#3F50F8',
                            borderRadius: '4px',
                            padding: '2px 8px',
                            maxWidth: 'fit-content',
                            whiteSpace: 'nowrap'
                          }}
                        // onClick={() => window.location.href = `mailto:${user.email}`}
                          onClick={() => handleEmailClick(user.email)}
                          aria-label={`Email: ${user.email || 'Not available'}`}
                        >
                          {user.email ? maskEmail(user.email) : '****@****.com'}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '12px'
                      }}
                    >
                      <Typography
                        variant='body2'
                        sx={{
                          marginBottom: '5px'
                        }}
                      >
                        <strong>Phone Number</strong>
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {user?.phone_numbers?.map((phone, index) => (
                          <Typography
                            component='span'
                            sx={{
                              display: 'inline-block',
                              backgroundColor: '#E7F0FF',
                              color: '#3F50F8',
                              borderRadius: '4px',
                              padding: '2px 8px',
                              maxWidth: 'fit-content',
                              whiteSpace: 'nowrap',
                              userSelect: 'none',
                              filter: 'blur(4px)'
                            }}
                            key={index}
                            onClick={() => handlePhoneClick(phone)} // Use the handlePhoneClick function
                            aria-label={`Call ${getPhoneNumber(phone)}`}
                          >
                            {getPhoneNumber(phone)}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '12px'
                      }}
                    >
                      <Typography
                        variant='body2'
                        sx={{
                          marginBottom: '5px'
                        }}
                      >
                        <strong>LinkedIn Url</strong>
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography
                          component='span'
                          sx={{
                            display: 'inline-block',
                            backgroundColor: '#E7F0FF',
                            color: '#3F50F8',
                            borderRadius: '4px',
                            padding: '2px 8px',
                            maxWidth: 'fit-content',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {user.linkedin_url}
                        </Typography>
                        <a
                          sx={{ color: '#3F50F8' }}
                          onClick={() => window.open(user.linkedin_url, '_blank')}
                        >
                          <LFWeb />
                        </a>
                      </Box>
                    </Box>
                  </Box>
                </Collapse>
              </Box>
            )}
            <Box>
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                onClick={() => setIsPrincipalInfoOpen(!isPrincipalInfoOpen)}
                sx={{
                  cursor: 'pointer',
                  background: '#F2F4F6',
                  overflow: 'hidden',
                  padding: '5px 20px'
                }}
              >
                <Box display='flex' alignItems='center' sx={{ gap: 2 }}>
                  <Typography variant='subtitle1' fontWeight='bold'>
                    {activeTab === 'company' ? 'Company Informations' : 'Additional Details'}
                  </Typography>
                </Box>
                {isPrincipalInfoOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Box>
              <Collapse in={isPrincipalInfoOpen}>
                <Box mt={1} pl={2}>
                  {activeTab === 'person' && (
                    <Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: '12px'
                        }}
                      >
                        <Typography
                          variant='body2'
                          sx={{
                            marginBottom: '5px'
                          }}
                        >
                          <strong>Company Name</strong>
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Typography
                            component='span'
                            sx={{
                              display: 'inline-block',
                              backgroundColor: '#E7F0FF',
                              color: '#3F50F8',
                              borderRadius: '4px',
                              padding: '2px 8px',
                              maxWidth: 'fit-content',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {user?.organization?.name}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: '12px'
                        }}
                      >
                        <Typography
                          variant='body2'
                          sx={{
                            marginBottom: '5px'
                          }}
                        >
                          <strong>Expertise</strong>
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Typography
                            component='span'
                            sx={{
                              display: 'inline-block',
                              backgroundColor: '#E7F0FF',
                              color: '#3F50F8',
                              borderRadius: '4px',
                              padding: '2px 8px',
                              maxWidth: 'fit-content',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {user?.seniority}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: '12px'
                        }}
                      >
                        <Typography
                          variant='body2'
                          sx={{
                            marginBottom: '5px'
                          }}
                        >
                          <strong>Languages</strong>
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          {user?.languages?.map((language, index) => (
                            <Typography
                              component='span'
                              sx={{
                                display: 'inline-block',
                                backgroundColor: '#E7F0FF',
                                color: '#3F50F8',
                                borderRadius: '4px',
                                padding: '2px 8px',
                                maxWidth: 'fit-content',
                                whiteSpace: 'nowrap'
                              }}
                              key={index}
                            >
                              {language || 'N/A'}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: '12px'
                        }}
                      >
                        <Typography
                          variant='body2'
                          sx={{
                            marginBottom: '5px'
                          }}
                        >
                          <strong>Summary</strong>
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Typography
                            component='span'
                            sx={{
                              display: 'inline-block',
                              backgroundColor: '#E7F0FF',
                              color: '#3F50F8',
                              borderRadius: '4px',
                              padding: '2px 8px',
                              maxWidth: 'fit-content'
                              // whiteSpace: 'nowrap'
                            }}
                          >
                            {user?.headline}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: '12px'
                        }}
                      >
                        <Typography
                          variant='body2'
                          sx={{
                            marginBottom: '5px'
                          }}
                        >
                          <strong>Location</strong>
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Typography
                            component='span'
                            sx={{
                              display: 'inline-block',
                              backgroundColor: '#E7F0FF',
                              color: '#3F50F8',
                              borderRadius: '4px',
                              padding: '2px 8px',
                              maxWidth: 'fit-content',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {[user?.city, user?.country].filter(Boolean).join(', ')}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: '12px',
                          width: 'max-content',
                          maxWidth: '100%',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        <Typography
                          variant='body2'
                          sx={{
                            marginBottom: '5px'
                          }}
                        >
                          <strong>Education</strong>
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                          {user?.education?.map((edu, index) => (
                            <Typography
                              component='span'
                              sx={{
                                display: 'inline-block',
                                backgroundColor: '#E7F0FF',
                                color: '#3F50F8',
                                borderRadius: '4px',
                                padding: '2px 8px',
                                whiteSpace: 'pre-wrap'
                              }}
                              key={index}
                            >
                              {edu.specialization || 'N/A'}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                      {user?.employment_history && (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginBottom: '12px',
                            width: 'max-content',
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                        >
                          <Typography
                            variant='body2'
                            sx={{
                              marginBottom: '5px'
                            }}
                          >
                            <strong>Employment history</strong>
                          </Typography>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                            {user?.employment_history?.map((emp, index) => (
                              <Typography
                                component='span'
                                sx={{
                                  display: 'flex',
                                  backgroundColor: '#E7F0FF',
                                  color: '#3F50F8',
                                  borderRadius: '4px',
                                  padding: '2px 8px',
                                  whiteSpace: 'nowrap'
                                }}
                                key={index}
                              >
                                {emp.title || 'N/A'}
                              </Typography>
                            ))}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  )}
                  {activeTab === 'company' && (
                    <Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: '12px'
                        }}
                      >
                        <Typography
                          variant='body2'
                          sx={{
                            marginBottom: '5px'
                          }}
                        >
                          <strong>Company Name</strong>
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Typography
                            component='span'
                            sx={{
                              display: 'inline-block',
                              backgroundColor: '#E7F0FF',
                              color: '#3F50F8',
                              borderRadius: '4px',
                              padding: '2px 8px',
                              maxWidth: 'fit-content',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {user?.organization?.name}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: '12px'
                        }}
                      >
                        <Typography
                          variant='body2'
                          sx={{
                            marginBottom: '5px'
                          }}
                        >
                          <strong>Website</strong>
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Typography
                            component='span'
                            sx={{
                              display: 'inline-block',
                              backgroundColor: '#E7F0FF',
                              color: '#3F50F8',
                              borderRadius: '4px',
                              padding: '2px 8px',
                              maxWidth: 'fit-content',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {user?.organization?.website_url}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: '12px'
                        }}
                      >
                        <Typography
                          variant='body2'
                          sx={{
                            marginBottom: '5px'
                          }}
                        >
                          <strong>LinkedIn Url</strong>
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Typography
                            component='span'
                            sx={{
                              display: 'inline-block',
                              backgroundColor: '#E7F0FF',
                              color: '#3F50F8',
                              borderRadius: '4px',
                              padding: '2px 8px',
                              maxWidth: 'fit-content',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {user?.organization?.linkedin_url}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: '12px'
                        }}
                      >
                        <Typography
                          variant='body2'
                          sx={{
                            marginBottom: '5px'
                          }}
                        >
                          <strong>Company Location</strong>
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Typography
                            component='span'
                            sx={{
                              display: 'inline-block',
                              backgroundColor: '#E7F0FF',
                              color: '#3F50F8',
                              borderRadius: '4px',
                              padding: '2px 8px',
                              maxWidth: 'fit-content',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {`${user?.organization?.city}, ${user?.organization?.country}`}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: '12px'
                        }}
                      >
                        <Typography
                          variant='body2'
                          sx={{
                            marginBottom: '5px'
                          }}
                        >
                          <strong>Industry</strong>
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                          <Typography
                            component='span'
                            sx={{
                              display: 'inline-block',
                              backgroundColor: '#E7F0FF',
                              color: '#3F50F8',
                              borderRadius: '4px',
                              padding: '2px 8px',
                              maxWidth: 'fit-content',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {user?.organization?.industry}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: '12px'
                        }}
                      >
                        <Typography
                          variant='body2'
                          sx={{
                            marginBottom: '5px'
                          }}
                        >
                          <strong>Employee Count</strong>
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                          <Typography
                            component='span'
                            sx={{
                              display: 'inline-block',
                              backgroundColor: '#E7F0FF',
                              color: '#3F50F8',
                              borderRadius: '4px',
                              padding: '2px 8px',
                              maxWidth: 'fit-content',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {user?.organization?.estimated_num_employees}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: '12px'
                        }}
                      >
                        <Typography
                          variant='body2'
                          sx={{
                            marginBottom: '5px'
                          }}
                        >
                          <strong>Company Keywords</strong>
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                          {user?.organization?.keywords?.map((keyword, index) => (
                            <Typography
                              component='span'
                              sx={{
                                display: 'inline-block',
                                backgroundColor: '#E7F0FF',
                                color: '#3F50F8',
                                borderRadius: '4px',
                                padding: '2px 8px',
                                maxWidth: 'fit-content',
                                whiteSpace: 'nowrap'
                              }}
                              key={index}
                            >
                              {keyword || 'N/A'}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: '12px'
                        }}
                      >
                        <Typography
                          variant='body2'
                          sx={{
                            marginBottom: '5px'
                          }}
                        >
                          <strong>Funding</strong>
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                          <Typography
                            component='span'
                            sx={{
                              display: 'inline-block',
                              backgroundColor: '#E7F0FF',
                              color: '#3F50F8',
                              borderRadius: '4px',
                              padding: '2px 8px',
                              maxWidth: 'fit-content',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {user?.organization?.total_funding}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: '12px'
                        }}
                      >
                        <Typography
                          variant='body2'
                          sx={{
                            marginBottom: '5px'
                          }}
                        >
                          <strong>Technologies</strong>
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                          {user?.organization?.technology_names?.map((tech, index) => (
                            <Typography
                              component='span'
                              sx={{
                                display: 'inline-block',
                                backgroundColor: '#E7F0FF',
                                color: '#3F50F8',
                                borderRadius: '4px',
                                padding: '2px 8px',
                                maxWidth: 'fit-content'
                              }}
                              key={index}
                            >
                              {tech}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Collapse>
            </Box>
          </Box>
          )}
    </>
  )
}
