import React, { useState } from 'react'
import {
  Box,
  Button,
  useTheme,
  IconButton,
  Typography,
  Grid,
  Dialog
} from '@mui/material'
import { Plus } from 'src/assets/general/Plus'
import PlanSetting from 'src/components/planSetting'
import { useCreateCustomPlanMutation, useDeleteCustomPlanMutation, useGetCustomPlansQuery, useUpdateCustomPlanMutation } from 'src/services/customPlan-service'
import { useSelector } from 'react-redux'
import { EditIcon } from 'src/assets/general/EditIcon'
import { DeleteIcon } from 'src/assets/general/DeleteIcon'
import toast from 'react-hot-toast'
import { CheckCircleIcon } from 'src/assets/general/CheckCircleIcon'
import { CloseOutlined } from '@mui/icons-material'
import NoPlanDataFound from 'src/components/NoPlanDataFound'
import { planSettingFormFields } from 'src/config'
import LoaderCircle from './LoaderCircle'

const Features = ({ usage, theme }) => {
  const generateFeatureList = (section) => {
    const features = []

    // Add text fields with their values
    if (section.textFields) {
      features.push(
        ...section.textFields.map(({ label, name }) => ({
          icon: <CheckCircleIcon color={theme.palette.primary.main} />,
          title: label,
          text: usage[name]
        }))
      )
    }

    // Add switches only if they are enabled
    if (section.switches) {
      features.push(
        ...section.switches
          .filter(({ name }) => usage[name]) // Include only active features
          .map(({ label }) => ({
            icon: <CheckCircleIcon color={theme.palette.primary.main} />,
            text: label
          }))
      )
    }

    return features
  }

  return (
    <Box>
      <Typography
        sx={{
          fontWeight: '700',
          fontSize: '16px',
          marginBottom: '16px',
          color: theme.palette.primary.primaryText
        }}
      >
        Features Included
      </Typography>

      {planSettingFormFields.map((section, index) => {
        const hasData =
          (section.textFields && section.textFields.some((field) => usage[field.name])) ||
          (section.switches && section.switches.some((field) => usage[field.name]))

        if (!hasData) {
          return null // Skip rendering this section if it has no data
        }

        const featureList = generateFeatureList(section)
        return (
          <Box key={index} sx={{ marginBottom: '24px' }}>
            <Typography
              sx={{
                fontWeight: '700',
                fontSize: '14px',
                marginBottom: '8px',
                color: theme.palette.primary.primaryText
              }}
            >
              {section.title}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 2,
                justifyContent: 'space-between'
              }}
            >
              {featureList.map((feature, idx) => (
                <Box
                  key={idx}
                  sx={{
                    flex: {
                      xs: '1 1 100%',
                      sm: '1 1 calc(50% - 16px)'
                      // lg: '1 1 calc(33.33% - 16px)',
                    },
                    minWidth: '150px',
                    maxWidth: '400px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  <Box
                    sx={{
                      width: '24px',
                      height: '24px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: `${theme.palette.primary.main}`
                    }}
                  >
                    {feature.icon}
                  </Box>

                  {/* Text Section */}
                  <Typography
                    sx={{
                      fontSize: '13px',
                      fontWeight: '500',
                      color: theme.palette.primary.primaryText
                    }}
                  >
                    {feature.title && (
                      <span style={{ fontWeight: '700' }}>{feature.title}: </span>
                    )}
                    {feature.text}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

export default function WhiteLabelPlans () {
  const theme = useTheme()
  const user = useSelector((state) => state.user)
  const [deleteCustomPlan] = useDeleteCustomPlanMutation()
  const [createCustomPlan] = useCreateCustomPlanMutation()
  const [updateCustomPlan] = useUpdateCustomPlanMutation()
  const { data: plansData, refetch, isLoading } = useGetCustomPlansQuery({ PartnerId: user._id })
  const [openAddPlanDialog, setOpenAddPlanDialog] = useState(false)
  const [currentPlan, setCurrentPlan] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [open, setOpen] = useState(false)
  const [planId, setPlanId] = useState(null)

  const handleOpen = (id) => {
    setOpen(true)
    setPlanId(id)
  }

  const handleClose = () => {
    setOpen(false)
    setPlanId(null)
  }

  const handleClickOpenAddDialog = (plan) => {
    setCurrentPlan(plan)
    setOpenAddPlanDialog(true)
  }

  const handleCloseAddDialog = () => {
    setOpenAddPlanDialog(false)
  }

  const handlePlanCreate = async (planData) => {
    setIsSubmitting(true)
    try {
      await createCustomPlan(planData).unwrap()
      toast.success('Custom Plan Created Successfully')
      refetch()
    } catch ({ data: error }) {
      toast.error(`${error?.status || 'Error'}: ${error?.message || 'Falied to create Plan'}`)
      return false
    } finally {
      setIsSubmitting(false)
    }
    handleCloseAddDialog()
    return true
  }

  const handlePlanUpdate = async ({ createdBy, updatedAt, createdAt, __v, remainingSubPartners, ...planData }) => {
    setIsSubmitting(true)
    try {
      await updateCustomPlan(planData).unwrap()
      toast.success('Custom Plan Updated Successfully.')
      refetch()
    } catch ({ data: error }) {
      toast.error(`${error?.status || 'Error'}: ${error?.message || 'Falied to Update Plan'}`)
      return false
    } finally {
      setIsSubmitting(false)
    }
    handleCloseAddDialog()
    return true
  }

  const handlePlanDelete = async () => {
    setIsSubmitting(true)
    try {
      await deleteCustomPlan(planId).unwrap()
      toast.success('Custom Plan Deleted Successfully.')
      refetch()
    } catch ({ data: error }) {
      toast.error(`${error.status || 'Error'}: ${error.message || 'Falied to Delete Plan'}`)
      return
    } finally {
      setIsSubmitting(false)
    }
    handleClose()
  }

  const handlePlanSubmit = (values) => {
    let response
    if (currentPlan) {
      response = handlePlanUpdate(values)
    } else {
      response = handlePlanCreate(values)
    }
    return response
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          width: '100%',
          height: '100%'
        }}
      >
        <Box
          sx={{
            width: '90%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              width: { xs: '100%', xl: 'calc(100% + 90px)' },
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px solid #E4E4E5',
              pb: 2,
              ml: { xs: '0px', lg: '-10px' },
              mb: 3
            }}
          >
            <Typography
              sx={{
                color: '#28287B',
                fontSize: '32px',
                fontWeight: 700,
                lineHeight: '40px',
                letterSpacing: '0px'
              }}
            >
              Plans
            </Typography>
          </Box>
          {isLoading
            ? <LoaderCircle />
            : (!plansData || plansData?.customPlan?.length === 0)
                ? (
                  <Box
                    sx={{
                      width: 1,
                      height: '70vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '2rem'
                    }}
                  >
                    <NoPlanDataFound handlePlanAdd={() => handleClickOpenAddDialog(null)} />
                  </Box>

                  )
                : (
                  <>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
                      <Box sx={{ marginLeft: 'auto' }}>
                        <Button
                          id='Add Plan'
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            '&:hover': {
                              backgroundColor: theme.palette.primary.dark,
                              boxShadow: 10
                            },
                            textAlign: 'left',
                            fontSize: '14px',
                            fontWeight: 700,
                            lineHeight: '18px',
                            letterSpacing: '0em',
                            color: 'white',
                            backgroundColor: `${theme.palette.primary.main}`,
                            borderRadius: '8px',
                            px: 0.5,
                            py: 1.5,
                            pr: '18px'
                          }}
                          variant='outlined'
                          size='small'
                          onClick={() => handleClickOpenAddDialog(null)}
                        >
                          <Box
                            sx={{ mr: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                          >
                            <Plus />
                          </Box>
                          Add Plan
                        </Button>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2, borderRadius: '4px' }}>
                        <Grid container columnSpacing={3} rowSpacing={2} sx={{ mt: 2 }}>
                          {plansData && plansData?.customPlan.map((plan, index) => (
                            <Grid key={index} item xs={12} sm={6} md={4}>
                              <Box
                                sx={{
                                  width: '100%',
                                  height: '100%',
                                  borderRadius: '8px',
                                  border: `1px solid ${theme?.palette.primary.main}`,
                                  p: 2,
                                  display: 'flex',
                                  flexDirection: 'column'
                                }}
                              >
                                <Box
                                  sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    borderRadius: '12px',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: '18px',
                                      fontWeight: 700,
                                      lineHeight: '26px',
                                      color: `${theme.palette.primary.primaryText}`
                                    }}
                                  >
                                    {plan.planName}
                                  </Typography>
                                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <IconButton onClick={() => handleClickOpenAddDialog(plan)}>
                                      <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleOpen(plan?._id)}>
                                      <DeleteIcon />
                                    </IconButton>
                                  </Box>
                                </Box>
                                <Features type='warmup' plan='growth' usage={plan} theme={theme} />
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </Box>
                  </>)}
        </Box>
      </Box>
      <PlanSetting
        open={openAddPlanDialog}
        onClose={handleCloseAddDialog}
        usageData={currentPlan}
        title={currentPlan ? 'Update Plan' : 'Add Plan'}
        handleSubmit={(values) => handlePlanSubmit(values)}
        isSubmitting={isSubmitting}
      />
      <Dialog
        onClose={handleClose}
        open={open}
        sx={{
          backdropFilter: 'blur(1px) sepia(3%)'
        }}
        PaperProps={{ sx: { borderRadius: '1rem', width: '22%', minWidth: '300px' } }}
      >
        <Box sx={{ width: '100%', height: '100%', p: 3 }}>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-Between', alignItems: 'center', borderBottom: '2px solid rgb(228, 228, 229)' }}
          >
            <Typography
              variant='h4'
              component='h4'
              sx={{
                color: theme.palette.primary.primaryText,
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                pb: '4px'
              }}
            >
              Confirmation
            </Typography>

            <CloseOutlined sx={{ cursor: 'pointer', fontSize: '1.5rem' }} onClick={handleClose} />
          </Box>
          <Box sx={{ mb: '16px' }}>
            <Typography sx={{ fontSize: '17px', padding: '20px 0px', color: theme.palette.primary.primaryText }}>
              Are you sure you want to delete ?
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
            <Button
              type='button'
              onClick={handleClose}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                border: 'none',
                padding: '4px 12px',
                fontSize: '14px',
                cursor: 'pointer',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'box-shadow 0.3s',
                '&:hover': {
                  boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
                  backgroundColor: theme.palette.primary.dark
                }
              }}
            >
              Close
            </Button>
            <Button
              type='button'
              onClick={handlePlanDelete}
              sx={{
                backgroundColor: '#ff0000',
                color: '#ffffff',
                border: 'none',
                padding: '4px 12px',
                fontSize: '14px',
                cursor: 'pointer',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'box-shadow 0.3s',
                '&:hover': {
                  boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
                  backgroundColor: '#ff0000'
                }
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}
